import { z } from 'zod';

import { FundNameForPresentation } from 'features/roboAdvice/adviceSession/shared/constants';

const flowOrderAdviceInformation = {
  adviceInformation: 'adviceInformation',
  purposeAndRisk: 'purposeAndRisk',
  knowledgeAndExperience: 'knowledgeAndExperience',
  financialSituation: 'financialSituation',
  sustainability: 'sustainability'
} as const;

const flowOrderOrderExecution = {
  orderInformation: 'orderInformation',
  orderInformationPage2: 'orderInformationPage2',
  instruments: 'instruments',
  targetMarketAssessment: 'targetMarketAssessment'
} as const;

export const AddNewClientModes = {
  none: 'none',
  all: 'all',
  admin: 'admin'
} as const;

export const tenantSettingsSchema = z.object({
  fundNameForPresentation: z.nativeEnum(FundNameForPresentation),
  flowOrderAdviceInformation: z.record(
    z.nativeEnum(flowOrderAdviceInformation),
    z.number()
  ),
  flowOrderOrderExecution: z.record(
    z.nativeEnum(flowOrderOrderExecution),
    z.number()
  ),
  qipSynchEndpoint: z.string().nullable(),
  addNewClientMode: z.nativeEnum(AddNewClientModes),
  preMeetingSurveyEnabled: z.boolean(),
  investorAuthentication: z.string(),
  advisoryFlow: z.object({
    enabled: z.boolean()
  })
});

export type TenantSettings = z.infer<typeof tenantSettingsSchema>;

export const defaultTenantSettings: TenantSettings = {
  fundNameForPresentation: FundNameForPresentation.fundStandardName,
  flowOrderAdviceInformation: {
    adviceInformation: 1,
    purposeAndRisk: 2,
    knowledgeAndExperience: 3,
    financialSituation: 4,
    sustainability: 5
  },
  flowOrderOrderExecution: {
    orderInformation: 1,
    orderInformationPage2: 2,
    instruments: 3,
    targetMarketAssessment: 4
  },
  qipSynchEndpoint: '',
  addNewClientMode: AddNewClientModes.all,
  preMeetingSurveyEnabled: false,
  investorAuthentication: 'otp',
  advisoryFlow: {
    enabled: true
  }
};
