import axios, { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { reminderSendEmail, reminderSendSms } from '../../../main/api';
import { usePreMeetingSurveyForm } from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/form';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

const useSendReminder = () => {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const i18n = useI18n();
  const dispatch = useDispatch();

  const sendReminder = async (sessionId: string) => {
    try {
      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        undefined
      );

      const { values: formValues } = usePreMeetingSurveyForm.getState();

      const requestsToMake: Promise<AxiosResponse>[] = [];
      if (formValues.isSmsToCustomerEnabled && formValues.investorPhoneNumber) {
        requestsToMake.push(
          reminderSendSms(accessToken, undefined, {
            message: formValues.smsContent,
            recipient: formValues.investorPhoneNumber,
            sessionId
          })
        );
      }

      if (formValues.isEmailToCustomerEnabled && formValues.investorEmail) {
        requestsToMake.push(
          reminderSendEmail(accessToken, undefined, {
            message: formValues.emailContent,
            recipient: formValues.investorEmail,
            sessionId: sessionId,
            messageSubject: i18n(
              'roboAdvice.client.preMeetingSurvey.emailSubject'
            )
          })
        );
      }

      await Promise.all(requestsToMake);

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n(
            'roboAdvice.client.preMeetingSurvey.sendReminderSuccessMessage'
          ),
          type: NotificationTypes.success
        })
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.client.preMeetingSurvey.sendReminderErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );
      }
    }
  };

  return sendReminder;
};

export default useSendReminder;
