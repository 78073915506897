import axios from 'axios';
import { isNil } from 'ramda';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  CustomDocument,
  useCustomDownloadStore
} from '../services/customDownloadStore';
import { createDownloadReport } from 'features/roboAdvice/adviceSession/proposal/api';
import { saveFile } from 'features/roboAdvice/adviceSession/proposal/utils';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export const useDownloadReportDocuments = () => {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = useRef<any>();
  const { clientId } = useParams<any>();
  const dispatch = useDispatch();
  const i18n = useI18n();

  const downloadReportDocuments = async (
    adviceSessionId: string,
    files: CustomDocument[]
  ) => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    if (useCustomDownloadStore.getState().areDocumentsLoading) {
      return;
    }

    try {
      useCustomDownloadStore.getState().setAreDocumentsLoading(true);
      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const filesToDownload = [
        ...files.filter(f => f.documentType === 'report'),
        ...files
          .filter(f => f.documentType !== 'report')
          .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
      ];
      const version = files.find(f => f.documentType === 'report')?.version;

      const downloadDocumentsResponse = await createDownloadReport(
        accessToken,
        cancelTokenSource.token,
        clientId,
        adviceSessionId,
        {
          files: filesToDownload.map(({ documentId, documentType }) => ({
            documentId,
            documentType
          })),
          version: isNil(version) ? undefined : version
        }
      );

      saveFile(
        new Blob([downloadDocumentsResponse.data], {
          type: 'application/pdf; charset=utf-8'
        }),
        'Report.pdf'
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.client.adviceSessionsTable.errorWhileDownloadingReportDocuments'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    } finally {
      useCustomDownloadStore.getState().setAreDocumentsLoading(false);
    }
  };

  return downloadReportDocuments;
};
