import { useSelector } from 'react-redux';

import { getAlignmentCriteriaFields } from '../../../sustainability/components/utils';
import { usePageStore } from '../../services/pageStore';
import { useReadSustainability } from './useReadSustainability';
import {
  useReadDataListener,
  PageStatuses
} from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { useAdviceSessionStore } from 'features/roboAdvice/adviceSession/shared/services/adviceSessionStore';
import {
  useGoalsStore,
  selectors
} from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { useSustainabilityStore } from 'features/roboAdvice/adviceSession/sustainability';
import SustainabilityVisual from 'features/shared/analyticsComponents/sustainability/visual';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';

const Sustainability = ({ isSelected }) => {
  const selectedGoalsIds = useGoalsStore<string[]>(
    selectors.getSelectedGoalsIds
  );
  const { sustainabilityData } = useSustainabilityStore();
  const {
    pageStatuses: { readSustainability: pageStatus }
  } = usePageStore();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const customerConfig = useCustomerConfig();
  const { customAttributesData } = useAdviceSessionStore();

  const alignmentCriteriaFields = getAlignmentCriteriaFields({
    customerConfig
  });
  const sustainabilityPreference =
    customerConfig.roboAdvice.sustainability.sustainabilityPreference.config;

  useReadDataListener(pageStatus, isSelected, useReadSustainability());

  return (
    <SustainabilityVisual
      sustainabilityData={sustainabilityData}
      selectedGoalsIds={selectedGoalsIds}
      alignmentCriteriaFields={alignmentCriteriaFields}
      sustainabilityPreference={sustainabilityPreference}
      cultureCode={cultureCode}
      isLoading={pageStatus === PageStatuses.pending}
      isError={pageStatus === PageStatuses.failed}
      customAttributesData={customAttributesData}
    />
  );
};

export default Sustainability;
