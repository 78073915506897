import * as R from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  RoboAdviceClientsListPages,
  RoboAdviceClientPages,
  RoboAdviceAdviceSessionPages,
  NewClientId
} from '../../../shared/constants';
import { useClientsListStore } from '../services/clientsListStore';
import Table from './table';
import { useCleanupPage } from './useCleanupPage';
import { useInitPage } from './useInitPage';
import { ReactComponent as QuantfolioSymbol } from 'assets/quantfolioSymbol.svg';
import { AddNewClientModes } from 'defaults/defaultTenantSettings';
import Breadcrumbs from 'features/shared/components/breadcrumbs';
import ButtonLink from 'features/shared/components/button/buttonLink';
import Link from 'features/shared/components/link/index.js';
import { BigTabs, BigTab } from 'features/shared/components/tabs/big';
import SearchInput from 'features/shared/components/textInput/search.js';
import { LayoutBreakpoints } from 'features/shared/constants/layoutBreakpoints';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { useQuery } from 'features/sharedModules/reactRouter/components/useQuery';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

// Temporary solution until types added to SearchInput
const AnySearchInput = SearchInput as any;

// Temporary solution until types added to BigTab
const AnyBigTab = BigTab as any;

const useStyles = createUseStyles({
  header: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  backToSessionLink: {
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight
  },
  tabs: {
    marginBottom: Spacing.spacing02
  },
  toolbar: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginBottom: '20px'
  },
  addClientLinkContainer: {
    flex: '0 0 auto',
    marginRight: '30px',

    '&:hover svg': {
      opacity: 0.5
    }
  },
  searchInputContainer: {
    flex: '0 1 auto',
    width: '500px',
    marginLeft: 'auto'
  },
  searchInput: {
    width: '100%'
  },
  [`@media (max-width: ${LayoutBreakpoints.desktopMin}px)`]: {
    searchInputContainer: {
      width: '272px'
    }
  },
  quantfolioSymbol: {
    width: '30px',
    borderRadius: '5px',
    transition: 'opacity 0.2s ease-in-out'
  }
});

const ClientsList = () => {
  const i18n = useI18n();
  const classes = useStyles();
  const history = useHistory();
  const { pageId } = useParams<any>();
  const query = useQuery();
  const clientsListStore = useClientsListStore();
  const initPage = useInitPage();
  const cleanupPage = useCleanupPage();
  const {
    tenantSettings: { addNewClientMode }
  } = useCustomerConfig();
  const isUserAdmin: boolean = useSelector(sessionSelectors.getIsCustomerAdmin);
  const isUserQfEmployee: boolean = useSelector(
    sessionSelectors.getIsCustomerQfEmployee
  );

  React.useEffect(() => {
    initPage();

    return () => {
      cleanupPage();
    };
  }, []);

  const breadcrumbsItems = [
    {
      title: i18n('roboAdvice.breadcrumbs.advisorSolution'),
      to: routeTemplates.roboAdviceClientsList.build(
        RoboAdviceClientsListPages.allClients
      )
    }
  ];

  const addClientButtonOptions = React.useMemo(() => {
    switch (addNewClientMode) {
      case AddNewClientModes.all:
        return {
          isButtonVisible: true,
          isQLogoVisible: false
        };
      case AddNewClientModes.none:
        if (isUserQfEmployee) {
          return {
            isButtonVisible: true,
            isQLogoVisible: true
          };
        } else {
          return {
            isButtonVisible: false,
            isQLogoVisible: false
          };
        }
      case AddNewClientModes.admin:
        if (isUserAdmin) {
          return {
            isButtonVisible: true,
            isQLogoVisible: false
          };
        } else if (isUserQfEmployee) {
          return {
            isButtonVisible: true,
            isQLogoVisible: true
          };
        } else {
          return {
            isButtonVisible: false,
            isQLogoVisible: false
          };
        }
      default:
        return {
          isButtonVisible: true,
          isQLogoVisible: false
        };
    }
  }, [isUserAdmin, isUserQfEmployee, addNewClientMode]);

  return (
    <div data-testid={'clients-list-page'}>
      <div className={classes.header}>
        <Breadcrumbs
          items={
            pageId === RoboAdviceClientsListPages.myClients
              ? [
                  ...breadcrumbsItems,
                  {
                    title: i18n('roboAdvice.breadcrumbs.clients')
                  }
                ]
              : breadcrumbsItems
          }
        />
        {!R.isNil(query.clientType) &&
          !R.isNil(query.clientId) &&
          !R.isNil(query.adviceSessionId) && (
            <Link
              className={classes.backToSessionLink}
              to={routeTemplates.roboAdviceAdviceSession.build(
                query.clientType,
                query.clientId,
                query.adviceSessionId,
                RoboAdviceAdviceSessionPages.adviceInformation
              )}
            >
              {i18n('roboAdvice.shared.backToTheSession')}
            </Link>
          )}
      </div>
      <BigTabs
        rootClassName={classes.tabs}
        value={pageId}
        onChange={(event, newPageId) => {
          history.push(
            routeTemplates.roboAdviceClientsList.build(newPageId, query)
          );
        }}
      >
        <AnyBigTab
          value={RoboAdviceClientsListPages.allClients}
          label={i18n('roboAdvice.clientsList.allClients')}
        />
        <AnyBigTab
          value={RoboAdviceClientsListPages.myClients}
          label={i18n('roboAdvice.clientsList.myClients')}
        />
      </BigTabs>
      <div className={classes.toolbar}>
        {addClientButtonOptions.isButtonVisible && (
          <div className={classes.addClientLinkContainer}>
            <ButtonLink
              to={routeTemplates.roboAdviceClient.build(
                null,
                NewClientId,
                RoboAdviceClientPages.clientInformation,
                {
                  clientType: query.clientType,
                  clientId: query.clientId,
                  adviceSessionId: query.adviceSessionId
                }
              )}
            >
              {addClientButtonOptions.isQLogoVisible && (
                <QuantfolioSymbol className={classes.quantfolioSymbol} />
              )}
              + {i18n('roboAdvice.clientsList.addClient')}
            </ButtonLink>
          </div>
        )}
        <div className={classes.searchInputContainer}>
          <AnySearchInput
            className={classes.searchInput}
            placeholder={i18n('roboAdvice.clientsList.searchClient')}
            value={clientsListStore.search}
            onChange={event => {
              clientsListStore.setSearch(event.target.value);
            }}
          />
        </div>
      </div>
      <Table />
    </div>
  );
};

export default ClientsList;
