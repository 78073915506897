import classNames from 'classnames';
import * as R from 'ramda';
import React from 'react';

import { Statuses, useSessionStore } from '../../session/services/sessionStore';
import Poppover from '../../shared/components/popover.js';
import { ReferenceNodeProxy } from '../../shared/components/referenceNodeProxy.js';
import InitialRiskScoreModal from './initialRiskScoreModal';
import KnowledgeAndExperienceDetailsPopover from './knowledgeAndExperienceDetailsPopover.js';
import PurposeAndRiskDetailsTooltip from './purposeAndRiskDetailsTooltip.js';
import RiskDetailsPopover from './riskDetailsPopover.js';
import TextReference from './textReference';
import {
  useGoalsStore,
  selectors
} from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

const borderRadius = {
  [BorderRadiusTypes.rounded]: '15px',
  [BorderRadiusTypes.sharp]: '5px'
};

const useStyles = createUseStyles(theme => ({
  container: {
    backgroundColor: theme.boxBackgroundColor_2,
    borderRadius: borderRadius[theme.borderRadiusType],
    display: 'flex',
    padding: '12px',
    gap: Spacing.spacing00
  },
  section: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    fontSize: Typography.body3.size,
    lineHeight: Typography.body3.lineHeight,
    color: theme.secondaryColor,
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  value: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    fontWeight: FontWeights.medium,
    textAlign: 'center'
  },
  mathSymbol: {
    flex: 1,
    marginTop: '18px'
  }
}));

export const RiskScore = () => {
  const classes = useStyles();
  const theme = useTheme();
  const i18n = useI18n();
  const sessionStore = useSessionStore();
  const isRiskScoreSettingsLoading =
    sessionStore.sessionStatuses.readRiskScoreSettings === Statuses.pending;
  const {
    roboAdvice: {
      riskScore: { isRiskFinderEnabled }
    }
  } = useCustomerConfig();

  const containerRef = React.useRef();
  const referenceNode = React.useRef(new ReferenceNodeProxy(containerRef));

  const initialRiskScore = useGoalsStore(selectors.getInitialRiskScore);
  const finalRiskScore = useGoalsStore(selectors.getFinalRiskScore);
  const adjustment = useGoalsStore(selectors.getAdjustmentScore);

  const adjustmentToDisplay = React.useMemo(() => {
    return R.isNil(adjustment) ? (
      <span>&nbsp;</span>
    ) : adjustment.minRisk === adjustment.maxRisk ? (
      <span
        style={{
          color:
            adjustment.minRisk >= 0
              ? theme.successColor
              : theme.errorNegativeColor
        }}
      >
        {Math.abs(adjustment.minRisk)}
      </span>
    ) : (
      <>
        <span
          style={{
            color:
              adjustment.minRisk >= 0
                ? theme.successColor
                : theme.errorNegativeColor
          }}
        >
          {Math.abs(adjustment.minRisk)}
        </span>{' '}
        -{' '}
        <span
          style={{
            color:
              adjustment.maxRisk >= 0
                ? theme.successColor
                : theme.errorNegativeColor
          }}
        >
          {Math.abs(adjustment.maxRisk)}
        </span>
      </>
    );
  }, [adjustment, theme.errorNegativeColor, theme.successColor]);

  return (
    <div ref={containerRef} className={classes.container}>
      <div className={classes.section}>
        <div className={classes.header}>
          {i18n('roboAdvice.riskScore.initialRiskScore')}
        </div>
        <div className={classes.value}>
          {isRiskFinderEnabled ? (
            <InitialRiskScoreModal initialRiskScore={initialRiskScore} />
          ) : (
            <>
              <Poppover
                isLoading={isRiskScoreSettingsLoading}
                referenceDataId={'purpose-and-risk-details-ref'}
                popperDataId={'purpose-and-risk-details-popper'}
                reference={({ ref, referenceDataId, onClick }) => (
                  <TextReference
                    forwardedRef={() => {
                      ref(referenceNode.current);
                    }}
                    referenceDataId={referenceDataId}
                    disabled={R.isNil(initialRiskScore)}
                    onClick={onClick}
                  >
                    {initialRiskScore ? initialRiskScore : <span>&nbsp;</span>}
                  </TextReference>
                )}
                popperChildren={({ scheduleUpdate }) => (
                  <PurposeAndRiskDetailsTooltip
                    scheduleUpdate={scheduleUpdate}
                  />
                )}
              />
            </>
          )}
        </div>
      </div>

      <div className={classNames(classes.value, classes.mathSymbol)}>
        {initialRiskScore && !R.isNil(adjustment) && '+'}
      </div>

      <div className={classes.section}>
        <div className={classes.header}>
          {i18n('roboAdvice.riskScore.adjustment')}
        </div>
        <div className={classes.value}>
          <Poppover
            isLoading={isRiskScoreSettingsLoading}
            referenceDataId={'knowledge-and-experience-details-ref'}
            popperDataId={'knowledge-and-experience-details-popper'}
            reference={({ ref, referenceDataId, onClick }) => (
              <TextReference
                forwardedRef={() => {
                  ref(referenceNode.current);
                }}
                referenceDataId={referenceDataId}
                disabled={R.isNil(adjustment)}
                onClick={onClick}
              >
                {adjustmentToDisplay}
              </TextReference>
            )}
            popperChildren={({ scheduleUpdate }) => (
              <KnowledgeAndExperienceDetailsPopover
                scheduleUpdate={scheduleUpdate}
              />
            )}
          />
        </div>
      </div>

      <div className={classNames(classes.value, classes.mathSymbol)}>
        {initialRiskScore && !R.isNil(adjustment) && '='}
      </div>

      <div className={classes.section}>
        <div className={classes.header}>
          {i18n('roboAdvice.riskScore.finalRiskScore')}
        </div>
        <div className={classes.value}>
          <Poppover
            isLoading={isRiskScoreSettingsLoading}
            referenceDataId={'risk-details-ref'}
            popperDataId={'risk-details-popper'}
            reference={({ ref, referenceDataId, onClick }) => (
              <TextReference
                forwardedRef={() => {
                  ref(referenceNode.current);
                }}
                referenceDataId={referenceDataId}
                disabled={R.isNil(finalRiskScore)}
                onClick={onClick}
              >
                {finalRiskScore ? finalRiskScore : <span>&nbsp;</span>}
              </TextReference>
            )}
            popperChildren={({ scheduleUpdate }) => (
              <RiskDetailsPopover scheduleUpdate={scheduleUpdate} />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default RiskScore;
