import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';

import config from 'config/index.js';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

let _confirmationComponent = () => null;
export const setConfirmationComponent = component => {
  _confirmationComponent = component;
};
export const getConfirmationComponent = () => {
  return _confirmationComponent;
};

const YouHaveUnsavedChanges = () => {
  const i18n = useI18n();
  return (
    <div>
      <div>{i18n('shared.youHaveUnsavedChanges_1')}</div>
      <div>{i18n('shared.youHaveUnsavedChanges_2')}</div>
    </div>
  );
};

const history = createBrowserHistory({
  getUserConfirmation: (message, callback) => {
    const Confirmation = getConfirmationComponent();
    const container = document.getElementById(config.CONFIRMATION_ELEMENT_ID);
    const root = createRoot(container);
    root.render(
      <Confirmation
        isOpen={true}
        onConfirm={() => {
          root.unmount();
          callback(true);
        }}
        onDecline={() => {
          root.unmount();
          callback(false);
        }}
        headerTranslationKey={'shared.unsavedProgress'}
      >
        <YouHaveUnsavedChanges />
      </Confirmation>
    );
  }
});

export default history;
