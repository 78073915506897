import chroma from 'chroma-js';

import Icon from 'features/shared/components/icon/index.js';
import LoadingIndicator from 'features/shared/components/loadingIndicator/loadingIndicator';
import Popper from 'features/shared/components/popper/index.js';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

const borderRadius = {
  [BorderRadiusTypes.rounded]: '15px',
  [BorderRadiusTypes.sharp]: '10px'
};

const useStyles = createUseStyles(theme => ({
  overlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: 10,
    backgroundColor: chroma(theme.pageBackgroundColor).alpha(0.7).css(),
    borderRadius: borderRadius[theme.borderRadiusType]
  },
  loadingIndicator: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: 11,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  popper: {
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,
    fontWeight: FontWeights.normal,
    textAlign: 'left',
    zIndex: 20
  },
  popover: {
    backgroundColor: theme.boxBackgroundColor_1,
    border: `2px solid ${theme.accentColor}`,
    borderRadius: '10px',
    padding: '34px',
    position: 'relative'
  },
  closeIcon: {
    position: 'absolute',
    top: '0.6rem',
    right: '0.6rem',
    cursor: 'pointer',
    fontSize: '2.2rem',
    transform: 'rotate(0.03deg)', // fix of issue: icon small size causes pixelated icon
    color: theme.secondaryColor,
    zIndex: 12
  }
}));

export const Popover = ({
  isOpen,
  placement,
  onOpen,
  onClose,
  referenceDataId,
  popperDataId,
  popperChildren,
  reference,
  isLoading
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Popper
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      referenceDataId={referenceDataId}
      popperDataId={popperDataId}
      popperProps={{
        placement,
        modifiers: {
          flip: {
            enabled: false
          },
          offset: {
            offset: '0, 5px'
          }
        }
      }}
      reference={({ ref, referenceDataId, isPopperOpen, togglePopper }) =>
        reference({
          ref,
          referenceDataId,
          onClick: () => {
            togglePopper(!isPopperOpen);
          }
        })
      }
      popper={({
        ref,
        style,
        placement,
        isPopperOpen,
        togglePopper,
        popperDataId,
        scheduleUpdate
      }) => {
        return (
          isPopperOpen && (
            <div
              ref={ref}
              style={style}
              data-placement={placement}
              data-id={popperDataId}
              className={classes.popper}
            >
              <div className={classes.popover}>
                {isLoading && <div className={classes.overlay} />}
                {isLoading && (
                  <LoadingIndicator
                    color={theme.accentColor}
                    containerClassName={classes.loadingIndicator}
                  />
                )}
                <Icon
                  data-testid={'popover-close-button'}
                  className={classes.closeIcon}
                  type={'close'}
                  onClick={() => {
                    togglePopper(false);
                  }}
                />
                <div>{popperChildren({ scheduleUpdate })}</div>
              </div>
            </div>
          )
        );
      }}
    />
  );
};

Popover.defaultProps = {
  placement: 'bottom-end'
};

export default Popover;
