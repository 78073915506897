import classNames from 'classnames';
import * as React from 'react';

import { Tabs, Tab } from './index';
import { Typography } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const BigTabs = Tabs;

const useTabStyles = createUseStyles({
  root: {
    fontSize: Typography.heading2.size,
    lineHeight: Typography.heading2.lineHeight
  }
});

type Props = {
  rootClassName?: string;
} & React.ComponentProps<typeof Tab>;

export const BigTab = ({ rootClassName, ...restProps }: Props) => {
  const classes = useTabStyles();

  return (
    <Tab
      rootClassName={classNames(classes.root, rootClassName)}
      {...restProps}
    />
  );
};
