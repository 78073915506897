import { deepAlphaApi } from 'features/shared/utils/axios';

export const updateUserPicture = (
  currentCustomerId,
  accessToken,
  currentUserId,
  imagesData
) => {
  const formData = new FormData();

  const { full_size, thumbnail } = imagesData;

  formData.append(
    'full_size',
    JSON.stringify({
      id: full_size.id
    })
  );
  formData.append(full_size.id, full_size.data);

  formData.append(
    'thumbnail',
    JSON.stringify({
      id: thumbnail.id
    })
  );
  formData.append(thumbnail.id, thumbnail.data);

  return deepAlphaApi.put(`/users/${currentUserId}/pictures`, formData, {
    headers: {
      'X-Customer-ID': currentCustomerId,
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const deleteUserPicture = (
  currentCustomerId,
  accessToken,
  currentUserId
) => {
  return deepAlphaApi.delete(`/users/${currentUserId}/pictures`, {
    headers: {
      'X-Customer-ID': currentCustomerId,
      Authorization: `Bearer ${accessToken}`
    }
  });
};

export const updateUserProfileBasic = (
  currentCustomerId,
  accessToken,
  currentUserId,
  currentUserProfileBasicData
) => {
  return deepAlphaApi.put(
    `/users/${currentUserId}/profile-basic`,
    currentUserProfileBasicData,
    {
      headers: {
        'X-Customer-ID': currentCustomerId,
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};
