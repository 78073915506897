import { PortfolioChart } from '@quantfoliorepo/ui-components';
import { useSelector } from 'react-redux';

import { PortfolioChartData } from 'features/shared/analyticsComponents/portfolioChartCards/visual';
import { PortfolioChartDimensions } from 'features/shared/constants/chart';
import sessionSelectors from 'features/shared/services/session/selectors';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  container: {
    maxWidth: 500,
    margin: '0 auto'
  }
});

type Props = {
  portfolioChartData: PortfolioChartData;
};

const FundAllocation = ({ portfolioChartData }: Props) => {
  const classes = useStyles();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const theme = useTheme();

  return (
    <div className={classes.container}>
      <PortfolioChart
        alignTitleBottom={false}
        backgroundSpacingColor={theme.boxBackgroundColor_1}
        chartData={(portfolioChartData.fundAllocation || []).filter(
          ({ weight }) => weight > 0
        )}
        cultureCode={cultureCode}
        dataLabelsColor={theme.primaryColor}
        height={PortfolioChartDimensions.height}
        iconName={portfolioChartData.icon}
        isVertical
        noLegend={false}
        title={portfolioChartData.title}
        width={PortfolioChartDimensions.width}
      />
    </div>
  );
};

export default FundAllocation;
