import axios, { AxiosRequestConfig } from 'axios';

import { getQAuthAccessToken } from '../api';
import config from 'config/index.js';
import store from 'features/main/app/store.js';
import { creators as sessionActionCreators } from 'features/shared/services/session/actions.js';
import {
  getAuth0AccessToken,
  removeQAuthApiAccessToken
} from 'features/shared/services/session/storage.js';

const createAxios = (config: AxiosRequestConfig) => {
  const newInstance = axios.create(config);

  newInstance.interceptors.response.use(
    response => response,
    async error => {
      const status = error?.response?.status;
      if (status === 401 && error.config && !error.config.__isRetryRequest) {
        removeQAuthApiAccessToken();
        const newToken = await getQAuthAccessToken(getAuth0AccessToken());
        error.config.__isRetryRequest = true;
        error.config.headers.Authorization = newToken;
        return newInstance(error.config);
      }

      return Promise.reject(error);
    }
  );

  return newInstance;
};

export const deepAlphaApi = createAxios({
  baseURL: config.REACT_APP_API_URL
});

export const customersApi = createAxios({
  baseURL: config.CUSTOMERS_API_URL
});

export const customersApiV2 = createAxios({
  baseURL: config.CUSTOMERS_API_URL_V2
});

export const pathProjectionApi = createAxios({
  baseURL: config.PROJECTION_API_URL
});

export const stressTestApi = createAxios({
  baseURL: config.STRESS_TEST_API_URL,
  headers: { 'x-api-key': `${config.STRESS_TEST_API_KEY}` }
});

export const advisoryApi = createAxios({
  baseURL: config.ADVISORY_API_URL
});

export const advisoryApiV2 = createAxios({
  baseURL: config.ADVISORY_API_URL_V2
});

export const stateApi = createAxios(
  config.STATE_API_URL
    ? { baseURL: config.STATE_API_URL }
    : { baseURL: `${config.ADVISORY_API_URL}state` }
);

export const savingCalculatorApiV2 = createAxios({
  baseURL: config.SAVING_CALCULATOR_API_URL_V2
});

export const digitalInvestorAssessmentApi = createAxios({
  baseURL: config.DIGITAL_INVESTOR_ASSESSMENT_API_URL
});

export const qAuthApi = axios.create({
  baseURL: config.QAUTH_API_URL
});

qAuthApi.interceptors.response.use(
  response => response,
  async error => {
    const status = error?.response?.status;
    if (status === 401) {
      store.dispatch(sessionActionCreators.logout());
    }

    return Promise.reject(error);
  }
);

export const helperApi = axios.create({
  baseURL: config.HELPER_API_URL
});

export const buildPublicFileBlobUrl = fileUrl => {
  return `${config.AZURE_STORAGE_URL}/web/${fileUrl}`;
};

export const dynamicSyncApi = (url: string) => {
  // url consists of base url and endpoint, because we don't separate those in settings
  return createAxios({ baseURL: url });
};
