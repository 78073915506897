import classNames from 'classnames';
import { useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ProfileFormValues, profileForm } from '../services/profileForm';
import FinalFormTimezoneDropdown from './finalFormTimezoneDropdown';
import useSaveUserProfile from './useSaveUserProfile';
import UserProfilePicture from './userProfilePicture';
import LanguageDropdown from 'features/pageLanguage/main/components/index';
import { usePageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import Button, { ButtonType } from 'features/shared/components/button';
import { Spacing } from 'features/shared/constants/spacing';
import { FontWeights, Typography } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { CurrentUser } from 'features/shared/services/session/types';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import FinalFormTextInput from 'features/sharedModules/finalForm/components/textInput';
import FinalFormTextarea from 'features/sharedModules/finalForm/components/textarea';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  root: {
    maxWidth: '1160px'
  },
  header: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    fontWeight: FontWeights.medium,
    margin: `${Spacing.spacing04}px 0`,
    padding: 0
  },
  columnsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    height: '100%'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,

    '&:first-child': {
      borderRight: `1px solid ${theme.defaultInputStrokeColor}`,
      paddingRight: Spacing.spacing04,
      marginRight: Spacing.spacing04
    }
  },
  vertical: {
    flexDirection: 'row',
    gap: Spacing.spacing02
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: Spacing.spacing04,

    '& > *': {
      marginLeft: Spacing.spacing01
    }
  },
  input: {
    width: '100%'
  },
  shortInput: {
    width: 200
  },
  label: {
    display: 'block',
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,
    fontWeight: FontWeights.normal,
    margin: `${Spacing.spacing02}px 0 ${Spacing.spacing01}px 0`
  },
  sectionHeader: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    fontWeight: FontWeights.normal,
    margin: 0,
    marginBottom: Spacing.spacing02
  },
  bioCounter: {
    width: 'auto'
  }
}));

const ProfileAndPreferences = () => {
  const i18n = useI18n();
  const classes = useStyles();
  const history = useHistory();
  const { supportedLanguages } = useCustomerConfig();
  const { loadPageLanguage, savePageLanguage } = usePageLanguage();
  const [language, setLanguage] = useState(
    loadPageLanguage() || supportedLanguages.default
  );
  const saveUserProfile = useSaveUserProfile();
  const currentUser: CurrentUser = useSelector(sessionSelectors.getCurrentUser);

  const checkIfProfileDataChanged = (values: ProfileFormValues) =>
    values.name !== currentUser.name ||
    values.bio !== currentUser.bio ||
    values.timezone !== currentUser.timezone ||
    values.phone !== currentUser.phone ||
    language !== loadPageLanguage();

  return (
    <Form form={profileForm} onSubmit={() => {}}>
      {() => (
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <div className={classes.root}>
              <h2 className={classes.header}>
                {i18n('profile.profileAndPreferences')}
              </h2>

              <div className={classes.columnsContainer}>
                <div className={classNames(classes.column, classes.vertical)}>
                  <div>
                    <UserProfilePicture />
                  </div>
                  <div style={{ width: '100%' }}>
                    <h3 className={classes.sectionHeader}>
                      {i18n('profile.aboutYou')}
                    </h3>
                    <FinalFormTextInput
                      name="name"
                      label={i18n('shared.name')}
                      sectionClassName={classes.input}
                      className={classes.input}
                    />

                    <FinalFormTextarea
                      name="bio"
                      label={i18n('profile.biography')}
                      labelClassName={classes.label}
                      minRows={3}
                      maxLength={250}
                      showCounter
                      counterClassName={classes.bioCounter}
                    />
                  </div>
                </div>

                <div className={classes.column}>
                  <h3 className={classes.sectionHeader}>
                    {i18n('profile.contactInformation')}
                  </h3>

                  <FinalFormTextInput
                    name="email"
                    label={i18n('shared.email')}
                    sectionClassName={classes.input}
                    className={classes.input}
                    disabled
                  />

                  <FinalFormTextInput
                    name="phone"
                    label={i18n('profile.phone')}
                    sectionClassName={classes.shortInput}
                    className={classes.shortInput}
                    inputType="tel"
                    onFormat={value =>
                      value
                        .split('')
                        .filter(char => /^[ \d\-+()]*$/.test(char))
                        .join('')
                    }
                  />

                  <label
                    className={classes.label}
                    id="label-language"
                    htmlFor="select-language"
                  >
                    {i18n('profile.language')}
                  </label>
                  <LanguageDropdown
                    ariaLabelledBy="label-language"
                    className={classes.shortInput}
                    language={language}
                    setLanguage={setLanguage}
                  />

                  <label
                    className={classes.label}
                    id="label-timezone"
                    htmlFor="timezone"
                  >
                    {i18n('profile.timezone')}
                  </label>
                  <FinalFormTimezoneDropdown
                    name="timezone"
                    autoDetectName="autoDetectTimezone"
                    ariaLabelledBy="label-timezone"
                  />
                </div>
              </div>

              <div className={classes.buttonsContainer}>
                <Button
                  buttonType={ButtonType.secondary}
                  onClick={() => {
                    history.push(routeTemplates.home.build());
                  }}
                >
                  {i18n('shared.cancel')}
                </Button>
                <Button
                  onClick={async () => {
                    await saveUserProfile();
                    savePageLanguage(language);
                  }}
                  disabled={
                    !checkIfProfileDataChanged(values as ProfileFormValues)
                  }
                >
                  {i18n('shared.save')}
                </Button>
              </div>
            </div>
          )}
        </FormSpy>
      )}
    </Form>
  );
};

export default ProfileAndPreferences;
