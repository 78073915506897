export const types = {
  ARRAY_CONCAT: 'FORM/ARRAY_CONCAT',
  ARRAY_CHANGE: 'FORM/ARRAY_CHANGE',
  MERGE: 'FORM/MERGE'
};

export const creators = {
  arrayConcat: (formId, fieldId, items) => ({
    type: types.ARRAY_CONCAT,
    payload: { formId, fieldId, items }
  }),
  arrayChange: (formId, fieldId, items) => ({
    type: types.ARRAY_CHANGE,
    payload: { formId, fieldId, items }
  }),
  merge: (formId, values) => ({
    type: types.MERGE,
    payload: { formId, values }
  })
};
