import * as R from 'ramda';

export default ({ actionTypes }) =>
  (
    state = {
      location: undefined,
      prevLocation: undefined
    },
    action
  ) => {
    switch (action.type) {
      case actionTypes.LOCATION_CHANGED: {
        const { location, prevLocation } = action.payload;

        return R.merge(state, { location, prevLocation });
      }
      default:
        return state;
    }
  };
