import React from 'react';

import { form as roboAdviceForm } from '../../form/services/form';
import { useIsSessionReadOnly } from '../../main/services/selectors';
import { quizDateFields } from '../constants';
import Question from './question.js';
import Button from 'features/shared/components/button';
import Modal from 'features/shared/components/modal';
import { useModalBodyMaxDimensions } from 'features/shared/components/modal/useModalBodyMaxDimensions';
import Scrollbars from 'features/shared/components/scrollbars';
import { Spacing } from 'features/shared/constants/spacing';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  modalBodyContainer: {
    marginRight: Spacing.spacing01
  }
});

const KnowledgeAndExperienceQuestions = ({ errors }) => {
  const classes = useStyles();
  const i18n = useI18n();
  const isSessionReadOnly = useIsSessionReadOnly();
  const {
    roboAdviceForm: {
      knowledgeAndExperienceQuestions: { questions }
    }
  } = useCustomerConfig();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalText, setModalText] = React.useState(null);
  const [openModalName, setOpenModalName] = React.useState<string | null>(null);
  const sortedQuestions = [...questions];
  sortedQuestions.sort((a, b) => a.order - b.order);
  const { modalBodyMaxHeight, headerRef, footerRef } =
    useModalBodyMaxDimensions();

  const openModal = (text, name) => {
    setOpenModalName(name);
    setModalText(text);
    setIsModalOpen(true);
  };

  const finishTraining = () => {
    roboAdviceForm.change(
      quizDateFields[openModalName!],
      new Date().toISOString()
    );
    setIsModalOpen(false);
  };

  return (
    <div>
      {sortedQuestions.map(q => (
        <Question
          key={q.name}
          name={q.name}
          questionTranslationKey={q.translationKey}
          questionTranslations={q.translations}
          options={q.options}
          disabled={isSessionReadOnly}
          popupText={q.popupText}
          openModal={openModal}
          errors={errors[q.name]}
        />
      ))}
      <Modal
        footer={
          <Button onClick={finishTraining}>
            {i18n('roboAdvice.knowledgeAndExperience.quiz.popupButton')}
          </Button>
        }
        header={i18n('roboAdvice.knowledgeAndExperience.quiz.modalHeader')}
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        headerRef={headerRef}
        footerRef={footerRef}
      >
        <Scrollbars autoHeightMax={modalBodyMaxHeight}>
          <div className={classes.modalBodyContainer}>{modalText}</div>
        </Scrollbars>
      </Modal>
    </div>
  );
};

export default KnowledgeAndExperienceQuestions;
