import { useHistory, useParams } from 'react-router';

import { useHolisticViewStore, selectors } from '../services/holisticViewStore';
import ClientsTable from './clientsTable';
import CustomersPopup from './customersPopup';
import FinancialSituation from './financialSituation';
import GoalsSection from './goalsSection';
import ProposalSection from './proposalSection';
import useInitPage from './useInitPage';
import { RoboAdviceAdviceSessionPages } from 'features/roboAdvice/shared/constants';
import Button from 'features/shared/components/button';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import { HolisticViewParams } from 'features/tools/main/types';

const useStyles = createUseStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  addClientButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  goBackSection: {
    margin: `${Spacing.spacing02}px 0`,
    color: theme.accentColor,
    fontSize: Typography.heading2.size,
    cursor: 'pointer'
  }
}));

const HolisticView = () => {
  const i18n = useI18n();
  const classes = useStyles();
  const { clientType, adviceSessionId, clientId } =
    useParams<HolisticViewParams>();
  const holisticViewStore = useHolisticViewStore();
  const selectedClients = useHolisticViewStore(selectors.getSelectedClients);
  const history = useHistory();

  useInitPage();

  return (
    <div data-testid={'holistic-view-page'}>
      {!!clientType && !!adviceSessionId && !!clientId && (
        <div className={classes.goBackSection}>
          <div className={classes.goBackSection}>
            <span
              onClick={() =>
                history.push(
                  routeTemplates.roboAdviceAdviceSession.build(
                    clientType,
                    clientId,
                    adviceSessionId,
                    RoboAdviceAdviceSessionPages.proposal
                  )
                )
              }
            >
              ← {i18n('tools.holisticView.returnToAdvisorySession')}
            </span>
          </div>
        </div>
      )}
      <CustomersPopup />
      {selectedClients.length === 0 ? (
        <div className={classes.addClientButton}>
          <Button
            onClick={() => holisticViewStore.setIsAddCustomerPopupOpen(true)}
          >
            {i18n('tools.holisticView.addClient')}
          </Button>
        </div>
      ) : (
        <ClientsTable />
      )}
      <GoalsSection />
      <FinancialSituation />
      <ProposalSection />
    </div>
  );
};

export default HolisticView;
