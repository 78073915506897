import * as R from 'ramda';
import { v4 } from 'uuid';

import { useCreateAdviceSession } from './useCreateAdviceSession';
import { useUpdateAdviceSession } from './useUpdateAdviceSession';
import { clientForm } from 'features/roboAdvice/client/main/services/clientForm';
import { getHasValidationErrors } from 'features/roboAdvice/client/shared/utils';
import useClientMissingFields from 'features/roboAdvice/shared/components/useClientMissingFields';
import { formatDate } from 'features/shared/utils/dateTime';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useSaveAdviceSession() {
  const i18n = useI18n();
  const createAdviceSession = useCreateAdviceSession();
  const updateAdviceSession = useUpdateAdviceSession();
  const clientMissingFields = useClientMissingFields();

  const getInitialAdviceSession = () => {
    const now = new Date();
    const formattedDate = formatDate('no-no', now.toISOString());

    return {
      id: v4(),
      name: `${i18n('roboAdvice.client.adviceSessions')} ${formattedDate}`
    };
  };

  const saveAdviceSession = async (adviceSessionId = null, adviceType) => {
    if (clientMissingFields()) {
      return;
    }

    if (!adviceSessionId) {
      const initialValues = getInitialAdviceSession();
      const newAdviceSessionId = await createAdviceSession({
        ...initialValues,
        adviceType
      });
      return newAdviceSessionId;
    }

    const {
      values: { adviceSessions },
      errors
    } = clientForm.getState();

    if (adviceSessions == null) {
      return;
    }

    const adviceSessionIndex = adviceSessions.findIndex(
      s => s.id === adviceSessionId
    );

    const hasValidationErrors = getHasValidationErrors(
      R.path(['adviceSessions', adviceSessionIndex], errors)
    );

    if (hasValidationErrors) {
      return;
    }

    await updateAdviceSession(adviceSessionId);
  };

  return saveAdviceSession;
}
