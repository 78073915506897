import Modal, { ModalProps } from './index';
import Button, { ButtonType } from 'features/shared/components/button';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  confirmationContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  confirmationActionButton: {
    minWidth: 'auto'
  }
});

type Props = Omit<ModalProps, 'header' | 'footer'> & {
  onConfirm: () => void;
  onDecline: () => void;
  confirmButtonText?: string;
  declineButtonText?: string;
  headerTranslationKey?: string;
  header?: string;
};

const Confirmation = ({
  confirmButtonText,
  declineButtonText,
  header,
  headerTranslationKey,
  onConfirm,
  onDecline,
  children,
  ...restProps
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const headerText = headerTranslationKey ? i18n(headerTranslationKey) : header;

  return (
    <Modal
      footer={
        <>
          <Button
            className={classes.confirmationActionButton}
            onClick={onDecline}
            buttonType={ButtonType.secondary}
          >
            {declineButtonText || i18n('confirmation.no')}
          </Button>

          <Button
            className={classes.confirmationActionButton}
            onClick={onConfirm}
          >
            {confirmButtonText || i18n('confirmation.yes')}
          </Button>
        </>
      }
      header={headerText || ''}
      onRequestClose={onDecline}
      {...restProps}
    >
      <div className={classes.confirmationContent}>{children}</div>
    </Modal>
  );
};

export default Confirmation;
