import { CustomPortfolioAllocationMethods } from '../../services/constants';
import { CustomPortfolioAllocationMethod } from '../../types';
import ChangeAllocationIcon, {
  ChangeAllocationIconType
} from './changeAllocationIcon';
import { useUpdateGoal } from 'features/roboAdvice/adviceSession/purposeAndRisk/components/useUpdateGoal';
import { Goal } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import Dropdown from 'features/shared/components/dropdown';
import { Spacing } from 'features/shared/constants/spacing';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  dropdownOption: {
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.spacing00
  },
  inputContainer: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    color: theme.secondaryColor,

    '&:hover, &:focus': {
      borderColor: 'transparent',
      backgroundColor: theme.inputFillFocusColor,
      color: theme.primaryColor,

      '& svg path': {
        fill: theme.primaryColor
      }
    }
  },
  iconArrow: {
    color: `${theme.accentColor} !important`
  }
}));

type Props = {
  selectedGoal: Goal;
};

const ChangeAllocationMethodDropdown = ({ selectedGoal }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const updateGoal = useUpdateGoal();

  return (
    <Dropdown
      width={250}
      inputProps={{
        className: classes.inputContainer,
        iconClassName: classes.iconArrow
      }}
      onChange={v => {
        updateGoal(selectedGoal.goalId, {
          data: {
            customPortfolioAllocationMethod:
              v as CustomPortfolioAllocationMethod
          }
        });
      }}
      value={
        selectedGoal.data?.customPortfolioAllocationMethod as string | undefined
      }
      options={[
        {
          key: CustomPortfolioAllocationMethods.percentage,
          title: (
            <div className={classes.dropdownOption}>
              <ChangeAllocationIcon
                type={ChangeAllocationIconType.percentage}
              />
              {i18n(
                'roboAdvice.advisory.customPortfolio.changeAllocationMethod.percentage'
              )}
            </div>
          ),
          disabled: false
        },
        {
          key: CustomPortfolioAllocationMethods.monthlyDeposit,
          title: (
            <div className={classes.dropdownOption}>
              <ChangeAllocationIcon type={ChangeAllocationIconType.amount} />
              {i18n(
                'roboAdvice.advisory.customPortfolio.changeAllocationMethod.monthlyDeposit'
              )}
            </div>
          ),
          disabled: !selectedGoal.data?.monthlyDeposit
        },
        {
          key: CustomPortfolioAllocationMethods.firstDeposit,
          title: (
            <div className={classes.dropdownOption}>
              <ChangeAllocationIcon type={ChangeAllocationIconType.amount} />
              {i18n(
                'roboAdvice.advisory.customPortfolio.changeAllocationMethod.initialDeposit'
              )}
            </div>
          ),
          disabled: !selectedGoal.data?.firstDeposit
        }
      ]
        .filter(option => !option.disabled)
        .map(({ key, title }) => ({ key, title }))}
    />
  );
};

export default ChangeAllocationMethodDropdown;
