import { FormState } from 'final-form';
import { omit } from 'ramda';

import { AdviceSessionFormValues } from '../../form/services/form';
import {
  getClientInformation,
  getGeneralInformation
} from '../../proposal/services/mappingSelectors';
import { UniverseDocumentFormat } from '../../shared/api/types';
import { getProductAttributesPDF } from '../../shared/mapping';
import { CustomAttribute } from '../../shared/services/adviceSessionStore';
import { getCustomFieldsValues } from '../../shared/services/mapping';
import { GenerateOrderFormValues } from './generateOrderForm';
import { OrderPageStoreState } from './orderPageStore';
import { RoboAdvice } from 'defaults/defaultRoboAdvice';
import { RoboOrderFlow } from 'defaults/defaultRoboOrderFlow';
import { Theme } from 'defaults/defaultTheme';

const getOrderSummary = (
  orderExecutionFormValues: AdviceSessionFormValues,
  customAttributesData: CustomAttribute[] | null
) => {
  return orderExecutionFormValues.instrumentsSelected?.map(
    ({ isin, initialDeposit, monthlySaving, name, id }) => {
      const customAttributeData = customAttributesData?.find(
        ({ ticker }) => ticker === id
      );

      return {
        fund: name,
        isin,
        initialDeposit,
        monthlySaving,
        productAttributeText: customAttributeData?.productAttributeText,
        productAttributeBinary: customAttributeData?.productAttributeBinary
      };
    }
  );
};

type CreateOrderPayload = {
  advisorName: string;
  generateOrderFormValues: GenerateOrderFormValues;
  i18n: (key: string) => string;
  language: string | null;
  roboOrderFlow: RoboOrderFlow;
  customAttributesData: CustomAttribute[] | null;
  orderPageStoreState: OrderPageStoreState;
  roboAdvice: RoboAdvice;
  roboAdviceForm: FormState<
    AdviceSessionFormValues,
    Partial<AdviceSessionFormValues>
  >;
  theme: Theme;
  userName: string;
  universeDocuments: UniverseDocumentFormat[];
};

export const createOrderPayload = ({
  advisorName,
  generateOrderFormValues,
  i18n,
  language,
  roboOrderFlow,
  customAttributesData,
  orderPageStoreState,
  roboAdvice,
  roboAdviceForm,
  theme,
  userName,
  universeDocuments
}: CreateOrderPayload) => {
  const generalInformation = getGeneralInformation(
    roboAdviceForm.values.clientInformation,
    userName
  );

  const isAnyMorningstarDocumentSelected = Object.values(
    generateOrderFormValues.morningstarAttachments || {}
  ).some(field => field === true);

  const {
    instrumentsAdvisorNotes,
    orderInformationAdvisorNotes,
    proposalAdvisorNotes
  } = roboAdviceForm.values;

  return {
    advisorName,
    advisorNotes: {
      instrumentsAdvisorNotes,
      orderInformationAdvisorNotes,
      proposalAdvisorNotes
    },
    analyticsComponents: omit(
      ['all'],
      generateOrderFormValues.analyticsComponents
    ),
    chartColorsConfig: {
      expectedValue: {
        savingPlanColor: theme.chartPortfolioColors[0],
        labelsColor: theme.secondaryColor
      }
    },
    clientInformation: getClientInformation(
      i18n,
      roboAdviceForm.values.clientInformation,
      roboAdvice.clientInformation
    ),
    costData: orderPageStoreState.costData,
    expectedValueData: orderPageStoreState.expectedValueData,
    generalInformation: {
      name:
        roboAdviceForm.values.clientInformation?.name ||
        roboAdviceForm.values.clientInformation?.companyName
    },
    isins:
      isAnyMorningstarDocumentSelected &&
      roboAdviceForm.values.instrumentsSelected
        ? roboAdviceForm.values.instrumentsSelected
            .filter(({ isin }) => isin && isin.toLowerCase() !== 'missing')
            .map(({ isin }) => isin)
        : [],
    language,
    morningstarAttachments: Object.entries(
      generateOrderFormValues.morningstarAttachments || {}
    )
      .filter(([_, value]) => value)
      .map(([key]) => Number(key.split('_')[1])),
    otherDocuments: omit(['all', 'kiid'], generateOrderFormValues.attachments),
    otherDocumentsData: {
      name: generalInformation.name,
      number: generalInformation.number,
      advisorName: generalInformation.advisorName,
      email: generalInformation.advisorName,
      ssn: generalInformation.ssn,
      clientName: generalInformation.clientName,
      address: generalInformation.address
    },
    productAttachments: generateOrderFormValues.productAttachments
      ? universeDocuments
      : null,
    orderSummary: getOrderSummary(roboAdviceForm.values, customAttributesData),
    productAttributes: getProductAttributesPDF(
      roboOrderFlow.proposal.summaryTable
    ),
    orderInformation: getCustomFieldsValues({
      fields: roboOrderFlow.orderInformationFields,
      i18n,
      form: roboAdviceForm
    })
  };
};
