import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { MaxWithdrawalYear } from '../../constants';
import { TimeHorizonTypes } from '../../constants';
import { goalForm, useGoalForm } from '../../services/goalForm';
import { useIsSessionReadOnly } from 'features/roboAdvice/adviceSession/main/services/selectors';
import { Layouts } from 'features/roboAdvice/shared/constants';
import ConfigurableLabel from 'features/shared/components/configurableLabel/';
import {
  dateFormats,
  dateTypes
} from 'features/shared/components/datePicker/constants';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { getNumberInputFormat } from 'features/shared/utils/number';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import FinalFormDatepicker from 'features/sharedModules/finalForm/components/datepicker';
import FinalFormNumberInput from 'features/sharedModules/finalForm/components/numberInput';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  inputsWidth: {
    width: '100%'
  },
  withdrawalPeriod: {
    display: 'flex',
    gap: Spacing.spacing02,
    marginTop: Spacing.spacing02,

    '& input': {
      width: 184
    }
  },
  error: {
    color: theme.errorNegativeColor,
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight,
    bottom: '35px'
  },
  labelContainer: {
    marginBottom: Spacing.spacing01
  }
}));

const MonthlyWithdrawal = ({
  inputClasses,
  timeHorizonType,
  shouldUseRadioSlider,
  sortedTimeHorizonItems
}) => {
  const classes = useStyles();
  const i18n = useI18n();
  const isSessionReadOnly = useIsSessionReadOnly();
  const { errors, touched } = useGoalForm();
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const { thousandSeparator, decimalSeparator } =
    getNumberInputFormat(cultureCode);

  const currentYear = new Date().getFullYear();
  const goalStoreData = goalForm.getState().values.data;

  useEffect(() => {
    if (!goalStoreData?.withdrawalEnd) {
      goalForm.change('data.withdrawalStart', currentYear + 1);
      goalForm.change('data.timeHorizon', 1);
    }
    if (!goalStoreData?.withdrawalStart) {
      goalForm.change('data.withdrawalEnd', currentYear + 2);
      goalForm.change('data.timeHorizon', 1);
    }
  }, []);

  useEffect(() => {
    const selectedStartYear = goalStoreData.withdrawalStart;
    const selectedEndYear = goalStoreData.withdrawalEnd;
    const diff = selectedEndYear - selectedStartYear;
    if (diff <= 0) {
      goalForm.change('data.withdrawalEnd', selectedStartYear + 1);
    }
  }, [goalStoreData.withdrawalStart]);

  const onWithdrawalStartChange = date => {
    const selectedStartYear = date?.getFullYear() || currentYear;
    const horizonInYears = selectedStartYear - currentYear;

    if (timeHorizonType === TimeHorizonTypes.radio || shouldUseRadioSlider) {
      const timeHorizonValue = sortedTimeHorizonItems.find(
        ({ yearIntervalStop }) => horizonInYears <= yearIntervalStop
      )?.riskHorizonValue;

      goalForm.change('data.timeHorizon', timeHorizonValue);
      return;
    }
    goalForm.change('data.timeHorizon', horizonInYears);
  };

  return (
    <>
      <FinalFormNumberInput
        data-testid={'monthly-withdrawal'}
        name={'data.monthlyWithdrawal'}
        label={
          <ConfigurableLabel
            required
            label={i18n('roboAdvice.purposeAndRisk.monthlyWithdrawal')}
          />
        }
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        allowNegative={false}
        decimalScale={0}
        layout={Layouts.vertical}
        externalClasses={inputClasses}
        disabled={isSessionReadOnly}
        className={classes.inputsWidth}
        placeholder={i18n(
          'roboAdvice.purposeAndRisk.purposePopup.monthlyWithdrawalPlaceholder'
        )}
      />

      <div className={classes.withdrawalPeriod}>
        <FinalFormDatepicker
          name={'data.withdrawalStart'}
          label={
            <div className={classes.labelContainer}>
              <ConfigurableLabel
                required
                label={i18n('roboAdvice.purposeAndRisk.withdrawalStart')}
              />
            </div>
          }
          disabled={isSessionReadOnly}
          dateType={dateTypes.future}
          dateFormat={dateFormats.years}
          placeholder={i18n(
            'roboAdvice.purposeAndRisk.purposePopup.dateFormatPlaceholder'
          )}
          maxDate={new Date(currentYear + MaxWithdrawalYear.start, 0)}
          minDate={new Date(currentYear + 1, 0)}
          customOnChange={onWithdrawalStartChange}
        />
        <FinalFormDatepicker
          name={'data.withdrawalEnd'}
          label={
            <div className={classes.labelContainer}>
              <ConfigurableLabel
                required
                label={i18n('roboAdvice.purposeAndRisk.withdrawalEnd')}
              />
            </div>
          }
          disabled={isSessionReadOnly}
          dateType={dateTypes.future}
          dateFormat={dateFormats.years}
          placeholder={i18n(
            'roboAdvice.purposeAndRisk.purposePopup.dateFormatPlaceholder'
          )}
          minDate={new Date(goalStoreData.withdrawalStart + 1, 0)}
          maxDate={new Date(currentYear + MaxWithdrawalYear.end, 0)}
        />
      </div>
      {errors?.data?.withdrawalDate && touched?.['data.withdrawalEnd'] && (
        <div className={classes.error}>
          * {i18n(errors.data.withdrawalDate)}
        </div>
      )}
    </>
  );
};

export default MonthlyWithdrawal;
