import config from 'config/index.js';

const clearStaticUrl = (url: string): string =>
  url[0] === '/' ? url.substring(1) : url;

export const processImgURL = (url: string): string | undefined => {
  if (!url) {
    return undefined;
  }

  if (url.startsWith('http')) {
    return url;
  } else if (url.startsWith('static/') || url.startsWith('/static/')) {
    return config.CUSTOMERS_API_URL + clearStaticUrl(url);
  }

  return undefined;
};
