import { useDispatch, useSelector } from 'react-redux';

import { adviceSessionsTable } from '../../adviceSessionsTable';
import { deleteAdviceSessions as deleteAdviceSessionsApi } from '../api';
import { useAdviceSessionsStore } from '../services/adviceSessionsStore';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export function useDeleteAdviceSessions() {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const i18n = useI18n();
  const dispatch = useDispatch();

  const deleteAdviceSessions = async () => {
    const { selectedRowIds, changeAllRowsSelection } =
      adviceSessionsTable.useTableStore.getState();
    const adviceSessionsStore = useAdviceSessionsStore.getState();

    try {
      adviceSessionsStore.setIsDeleteAdviceSessionsPending(true);

      const accessToken = await getQAuthAccessToken(auth0AccessToken);

      const {
        data: { deleted, failed }
      } = await deleteAdviceSessionsApi(accessToken, selectedRowIds);

      adviceSessionsStore.deleteAdviceSessions(deleted);

      changeAllRowsSelection(false);

      adviceSessionsStore.setIsDeleteAdviceSessionsPending(false);

      const message = failed.length
        ? deleted.length
          ? 'admin.trashCan.adviceSessions.deleteAdviceSessionsHalfSuccessfulMessage'
          : 'admin.trashCan.adviceSessions.deleteAdviceSessionsFailedMessage'
        : 'admin.trashCan.adviceSessions.deleteAdviceSessionsSuccessMessage';

      dispatch(
        notificationActionCreators.showNotification({
          message:
            failed.length && deleted.length
              ? i18n(message)
                  .replace('{0}', `${deleted.length}`)
                  .replace('{1}', `${failed.length}`)
              : i18n(message),
          type: failed.length
            ? NotificationTypes.error
            : NotificationTypes.success
        })
      );
    } catch (error) {
      adviceSessionsStore.setIsDeleteAdviceSessionsPending(false);

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n(
            'admin.trashCan.adviceSessions.deleteAdviceSessionsErrorMessage'
          ),
          type: NotificationTypes.error
        })
      );

      throwSafeError(error);
    }
  };
  return deleteAdviceSessions;
}
