import * as R from 'ramda';
import { Fragment, useCallback } from 'react';
import { connect } from 'react-redux';

import { types, creators } from '../../services/actions';
import Menu from './menu.js';
import UploadImageModal from './uploadImageModal';
import { useGlobalActionsReducer } from 'features/shared/hooks/useGlobalActionsReducer.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import statusSelectors from 'features/shared/services/status/selectors.js';
import { succeedType } from 'features/shared/utils/actions.js';

const actionTypes = {
  TOGGLE_UPLOAD_IMAGE_MODAL: 'TOGGLE_UPLOAD_IMAGE_MODAL'
};

const initialState = {
  isUploadImageModalOpen: false,
  uploadImageModalData: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_UPLOAD_IMAGE_MODAL: {
      return R.pipe(
        R.assoc('isUploadImageModalOpen', action.payload.isOpen),
        R.assoc('uploadImageModalData', action.payload.data)
      )(state);
    }
    case succeedType(types.UPDATE_USER_PICTURE): {
      return R.pipe(
        R.assoc('isUploadImageModalOpen', false),
        R.assoc('uploadImageModalData', null)
      )(state);
    }
    default:
      return state;
  }
};

const UserProfilePicture = ({
  currentUserId,
  pictureUrl,
  updateUserPictureStatus,
  uploadImage: uploadImageProp,
  deleteImage: deleteImageProp
}) => {
  const [state, dispatch] = useGlobalActionsReducer(
    reducer,
    initialState,
    undefined,
    [succeedType(types.UPDATE_USER_PICTURE)]
  ) as [typeof initialState, () => void];

  const uploadImage = useCallback(
    images => {
      uploadImageProp(currentUserId, images);
    },
    [currentUserId]
  );

  const deleteImage = useCallback(() => {
    deleteImageProp(currentUserId);
  }, [currentUserId]);

  return (
    <Fragment>
      {state.isUploadImageModalOpen && (
        <UploadImageModal
          pageDispatch={dispatch}
          pageActionTypes={actionTypes}
          updateUserPictureStatus={updateUserPictureStatus}
          uploadImage={uploadImage}
        />
      )}
      <Menu
        pageDispatch={dispatch}
        pageActionTypes={actionTypes}
        pictureUrl={pictureUrl}
        deleteImage={deleteImage}
      />
    </Fragment>
  );
};

export default R.compose(
  connect(
    state => {
      const currentUser = sessionSelectors.getCurrentUser(state);
      const pictureUrl = sessionSelectors.getCurrentUserPicture(state);

      const updateUserPictureStatus = statusSelectors.getStatusOrDefault(
        'updateUserPictureStatus_state',
        state
      );

      return {
        currentUserId: R.prop('id', currentUser),
        pictureUrl,
        updateUserPictureStatus
      };
    },
    dispatch => {
      return {
        uploadImage: (currentUserId, images) => {
          dispatch(creators.updateUserPicture(currentUserId, images));
        },
        deleteImage: currentUserId => {
          dispatch(creators.deleteUserPicture(currentUserId));
        }
      };
    }
  )
)(UserProfilePicture);
