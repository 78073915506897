import classNames from 'classnames';
import * as R from 'ramda';
import { isNil } from 'ramda';
import React from 'react';
import { UseFieldConfig } from 'react-final-form';

import { getErrorText } from './shared.js';
import { useField } from './useField';
import { Layouts } from 'features/roboAdvice/shared/constants';
import ErrorMessage from 'features/shared/components/errorMessage/index.js';
import TextInput from 'features/shared/components/textInput/index';
import TextInputWithRightBlock from 'features/shared/components/textInput/withRightBlock/index.js';
import { Typography } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  section: {
    '& + &': {
      marginTop: '3rem'
    }
  },
  verticalSection: {},
  horizontalSection: {
    display: 'inline-flex',
    alignItems: 'baseline'
  },
  label: {
    color: theme.primaryColor
  },
  verticalLabel: {
    marginBottom: '1rem'
  },
  horizontalLabel: {
    marginRight: '2.4rem',
    flex: '0 0 auto',
    width: '350px',
    overflowX: 'hidden'
  },
  counter: {
    width: '32rem',
    textAlign: 'right',
    lineHeight: Typography.body1.lineHeight,
    color: theme.secondaryColor,
    marginTop: '1rem'
  }
}));

type Props = {
  name: string;
  fieldConfig?: UseFieldConfig<unknown>;
  afterChange?: (value: string) => void;
  label?: string | React.ReactNode;
  placeholder?: string;
  className?: string;
  sectionClassName?: string;
  labelClassName?: string;
  counterClassName?: string;
  externalClasses?: Record<string, string>;
  layout?: keyof typeof Layouts;
  blocksContainerClassName?: string;
  rightBlockProps?: Record<string, string>;
  rightBlockChildren?: React.ReactNode;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFormat?: (value: string) => string;
  forwardedRef?: React.Ref<HTMLInputElement>;
  maxLength?: number;
  showCounter?: boolean;
  inputType?: React.HTMLInputTypeAttribute;
  'data-testid'?: string;
  hasError?: boolean;
};

const FinalFormTextInput = ({
  name,
  fieldConfig,
  afterChange,
  label,
  placeholder,
  className,
  sectionClassName: sectionClassNameProp,
  labelClassName: labelClassNameProp,
  counterClassName: counterClassNameProp,
  externalClasses,
  layout = Layouts.vertical,
  blocksContainerClassName,
  rightBlockProps,
  rightBlockChildren,
  disabled,
  onClick,
  onBlur: onBlurProp,
  onFocus: onFocusProp,
  onFormat,
  forwardedRef,
  maxLength,
  showCounter,
  inputType,
  'data-testid': dataTestId,
  hasError
}: Props) => {
  const i18n = useI18n();
  const internalClasses = useStyles();
  const {
    input: { value, onChange, onFocus, onBlur },
    meta: { error, touched }
  } = useField(name, fieldConfig);
  const inputEl = React.useRef<HTMLInputElement>(null);

  const classes = R.isNil(externalClasses)
    ? internalClasses
    : R.mergeWith(
        (c1, c2) => classNames(c1, c2),
        internalClasses,
        externalClasses
      );

  const isErrorShown = isNil(hasError) ? touched && !R.isNil(error) : hasError;
  const errorText = getErrorText(i18n, error);

  const textInputProps = {
    'data-testid': dataTestId,
    value: value || '',
    onChange: e => {
      const formattedValue = onFormat
        ? onFormat(e.target.value)
        : e.target.value;
      onChange(formattedValue);
      !R.isNil(afterChange) && afterChange(formattedValue);
    },
    onFocus: e => {
      onFocus(e);
      !R.isNil(onFocusProp) && onFocusProp(e);
    },
    onBlur: e => {
      onBlur(e);
      !R.isNil(onBlurProp) && onBlurProp(e);
    },
    onClick: onClick,
    placeholder: disabled ? undefined : placeholder,
    className,
    id: name,
    isInvalid: isErrorShown,
    disabled,
    forwardedRef: forwardedRef || inputEl,
    maxLength,
    inputType
  };

  return (
    <div
      className={classNames(
        classes.section,
        {
          [classes.verticalSection]: layout === Layouts.vertical,
          [classes.horizontalSection]: layout === Layouts.horizontal
        },
        sectionClassNameProp
      )}
    >
      {!R.isNil(label) && !R.isEmpty(label) && (
        <div
          className={classNames(
            classes.label,
            {
              [classes.verticalLabel]: layout === Layouts.vertical,
              [classes.horizontalLabel]: layout === Layouts.horizontal
            },
            labelClassNameProp
          )}
        >
          {label}
        </div>
      )}
      <div>
        {R.isNil(rightBlockChildren) && <TextInput {...textInputProps} />}
        {!R.isNil(rightBlockChildren) && (
          <TextInputWithRightBlock
            className={blocksContainerClassName}
            textInputProps={textInputProps}
            rightBlockProps={rightBlockProps}
          >
            {rightBlockChildren}
          </TextInputWithRightBlock>
        )}

        {isErrorShown && isNil(hasError) && (
          <ErrorMessage>{`* ${errorText}`}</ErrorMessage>
        )}
        {showCounter && (
          <div className={classNames(classes.counter, counterClassNameProp)}>
            {inputEl?.current?.value?.length
              ? `${inputEl.current.value.length}/`
              : `${i18n('shared.maximum')} `}
            {maxLength} {i18n('shared.characters')}
          </div>
        )}
      </div>
    </div>
  );
};

export default FinalFormTextInput;
