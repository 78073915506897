import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { createUseStyles, useTheme } from 'modules/styles/components/styles';
import {
  TextFontSizes,
  FontFamilies,
  FontWeights
} from 'modules/shared/constants/fonts';
import { useI18n } from 'modules/settings/components/settings.js';
import { formatNumber } from 'modules/shared/utils/number';
import { goalIcons } from 'modules/shared/constants/icons';
import WarningIcon from 'assets/report_problem_24px.svg';

const useStyles = createUseStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  capitalNeed: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px'
  },
  estimatedGoalAchievement: {
    marginTop: '24px',
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 500
  },
  secondaryText: {
    color: theme.secondaryColor,
    lineHeight: '19px',
    marginTop: '7px'
  },
  dot: {
    display: 'inline-block',
    height: '16px',
    width: '16px',
    borderRadius: '50%',
    marginLeft: '10px'
  }
}));

const CashflowGoalChart = ({
  chartData,
  cultureCode,
  title,
  iconName,
  color,
  chartSize,
  chartTitleSize
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const i18n = useI18n();

  const options = {
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    chart: {
      backgroundColor: 'transparent',
      events: {
        load: function () {
          const icon = goalIcons.find(({ name }) => name === iconName)?.urlIcon;

          if (chartData.estimatedGoalAchievement < 1) {
            this.renderer.image(WarningIcon, 75, 25, 24, 24).add();
          }

          if (icon) {
            let innerSize = this.userOptions.plotOptions.pie.innerSize;
            this.renderer
              .image(
                icon,
                this.chartWidth / 2 - innerSize / 2,
                this.plotTop + this.plotSizeY / 2 - innerSize / 2,
                innerSize,
                innerSize
              )
              .add();
          }
        }
      },
      height: chartSize || 230,
      width: chartSize || 230,
      animation: false
    },
    title: {
      text: title,
      style: {
        color: theme.primaryColor,
        fontFamily: FontFamilies.roboto,
        fontSize: chartTitleSize || TextFontSizes.medium,
        fontWeight: FontWeights.medium,
        lineHeight: '19px'
      }
    },
    plotOptions: {
      pie: {
        innerSize: 35,
        enableMouseTracking: false,
        dataLabels: {
          enabled: false
        },
        colors: [color, theme.chartEmptyColor]
      }
    },
    series: [
      {
        animation: false,
        type: 'pie',
        data: [
          { y: chartData.estimatedGoalAchievement },
          { y: 1 - chartData.estimatedGoalAchievement }
        ],
        innerSize: '65%',
        borderWidth: 0,
        borderColor: theme.boxBackgroundColor_1
      }
    ]
  };

  return (
    <>
      <div className={classes.container}>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={'chart'}
          options={options}
        />

        <div>
          <div className={classes.capitalNeed}>
            {typeof chartData.capitalNeed === 'string'
              ? chartData.capitalNeed
              : formatNumber(cultureCode, chartData.capitalNeed, 0, 0)}
          </div>

          <div className={classes.secondaryText}>
            {i18n('cashflow.capitalNeed')}
          </div>

          <div className={classes.estimatedGoalAchievement}>
            {(chartData.estimatedGoalAchievement * 100).toFixed(0)} %
            <div
              className={classes.dot}
              style={{ backgroundColor: color }}
            ></div>
          </div>

          <div className={classes.secondaryText}>
            {i18n('cashflow.estimatedGoalAchievement')}
          </div>
        </div>
      </div>
    </>
  );
};

export default CashflowGoalChart;
