import { OrderSummaryData } from 'features/roboAdvice/adviceSession/orderSummary';
import { CustomAttribute } from 'features/roboAdvice/adviceSession/shared/services/adviceSessionStore';

export const mapProductAttributesToOrderSummary = (
  orderSummaryData: OrderSummaryData,
  customAttributesData: CustomAttribute[] | null
) => {
  if (!customAttributesData) return orderSummaryData;
  const {
    data: {
      monthlyOrders: { buy: monthlyOrdersBuy },
      oneTimeOrders: { buy: oneTimeOrdersBuy }
    }
  } = orderSummaryData;

  const newMonthlyOrdersBuy = monthlyOrdersBuy.map(monthlyOrder => {
    const productAttribute = customAttributesData.find(
      ({ ticker }) => ticker === monthlyOrder.id
    );

    return {
      ...monthlyOrder,
      ...productAttribute?.productAttributeText,
      ...productAttribute?.productAttributeBinary
    };
  });

  const newOneTimeOrdersBuy = oneTimeOrdersBuy.map(oneTimeOrder => {
    const productAttribute = customAttributesData.find(
      ({ ticker }) => ticker === oneTimeOrder.id
    );

    return {
      ...oneTimeOrder,
      ...productAttribute?.productAttributeText,
      ...productAttribute?.productAttributeBinary
    };
  });

  return {
    ...orderSummaryData,
    data: {
      monthlyOrders: {
        buy: newMonthlyOrdersBuy
      },
      oneTimeOrders: {
        buy: newOneTimeOrdersBuy
      }
    }
  };
};
