import { ExpectedPathPortfoliosChart } from '@quantfoliorepo/ui-components';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PortfolioIds } from '../../advisory/services/constants';
import { useReadDataListener } from '../../shared/components/useReadDataListener';
import { usePageStore } from '../services/pageStore';
import useReadExpectedPathPortfoliosChartData, {
  useExpectedPathPortfoliosChartStore
} from './useReadExpectedPathPortfoliosChartData';
import Checkbox from 'features/shared/components/checkbox';
import { hexOpacity } from 'features/shared/constants/colors';
import { Spacing } from 'features/shared/constants/spacing';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  legend: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    marginTop: Spacing.spacing01
  },
  legendOption: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: Spacing.spacing00,
    padding: Spacing.spacing00,
    borderRadius: 5,
    transition: 'background-color 0.2s ease-in-out'
  },
  legendOptionSelected: {
    backgroundColor: theme.inputFillFocusColor
  },
  legendCircle: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    marginRight: 10,
    marginLeft: 10
  },
  legendTitle: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    width: 170
  },
  legendText: {
    display: 'flex',
    alignItems: 'center'
  }
}));

type Props = {
  isSelected: boolean;
  selectedRisks: {
    risk: string;
    className: string;
    name: string;
    color: string;
  }[];
  compareWithExistingPortfolio: boolean;
};

const ExpectedPathPortfoliosChartCard = ({
  isSelected,
  selectedRisks,
  compareWithExistingPortfolio
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const { pageStatuses } = usePageStore();
  const [selectedPortfolios, setSelectedPortfolios] = useState<string[]>([]);

  const { chartData } = useExpectedPathPortfoliosChartStore();

  useEffect(() => {
    if (isSelected) {
      setSelectedPortfolios(selectedRisks.map(({ risk }) => risk));

      if (compareWithExistingPortfolio) {
        setSelectedPortfolios(state => [
          ...state,
          PortfolioIds.existingPortfolio
        ]);
      }
    }
  }, [selectedRisks, isSelected, compareWithExistingPortfolio]);

  useReadDataListener(
    pageStatuses.readExpectedPathPortfoliosChartData,
    isSelected,
    useReadExpectedPathPortfoliosChartData(
      selectedRisks,
      compareWithExistingPortfolio
    )
  );

  const handleClickLegend = (id: string) => {
    if (selectedPortfolios.includes(id)) {
      if (selectedPortfolios.length === 1) return;

      setSelectedPortfolios(
        selectedPortfolios.filter(portfolioId => portfolioId !== id)
      );
    } else {
      setSelectedPortfolios([...selectedPortfolios, id]);
    }
  };

  return (
    <>
      <ExpectedPathPortfoliosChart
        cultureCode={cultureCode}
        chartData={chartData.filter(({ id }) =>
          selectedPortfolios.includes(id)
        )}
        isInteractive
      />

      <ul className={classes.legend}>
        {[...chartData]
          .sort((a, b) => {
            if (a.id === PortfolioIds.existingPortfolio) return -1;
            if (b.id === PortfolioIds.existingPortfolio) return 1;

            return +a.id - +b.id;
          })
          .map(({ id, title, color }) => (
            <li
              key={id}
              className={classNames(classes.legendOption, {
                [classes.legendOptionSelected]: selectedPortfolios.includes(id)
              })}
              onClick={() => handleClickLegend(id)}
            >
              <Checkbox
                checked={selectedPortfolios.includes(id)}
                onChange={() => {}}
                width={20}
                height={20}
              />

              <div className={classes.legendTitle}>{title}</div>

              <div className={classes.legendText}>
                <div
                  className={classes.legendCircle}
                  style={{ backgroundColor: color }}
                ></div>
                <div>{i18n('roboAdvice.proposal.expectedValue')}</div>
              </div>

              <div className={classes.legendText}>
                <div
                  className={classes.legendCircle}
                  style={{ backgroundColor: `${color}${hexOpacity[60]}` }}
                ></div>
                <div>{i18n('roboAdvice.proposal.upperAndLowerBound')}</div>
              </div>
            </li>
          ))}
      </ul>
    </>
  );
};

export default ExpectedPathPortfoliosChartCard;
