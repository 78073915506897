import { useEffect, useRef, useState } from 'react';
import { Scrollbars as ReactCustomScrollbars } from 'react-custom-scrollbars-2';
import { useLockedBody } from 'usehooks-ts';

import PurposeAndRiskDetailsPopover from './purposeAndRiskDetailsPopover';
import TextReference from './textReference';
import Modal from 'features/shared/components/modal';
import { useModalBodyMaxDimensions } from 'features/shared/components/modal/useModalBodyMaxDimensions';
import Scrollbars from 'features/shared/components/scrollbars';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

type Props = {
  initialRiskScore: string | number | null;
};

const InitialRiskScoreModal = ({ initialRiskScore }: Props) => {
  const [isInitialRiskScoreModalOpen, setIsInitialRiskScoreModalOpen] =
    useState(false);
  const i18n = useI18n();
  const { modalBodyMaxHeight, headerRef, modalBodyMaxWidth } =
    useModalBodyMaxDimensions();
  const scrollbarsRef = useRef<ReactCustomScrollbars>(null);
  useLockedBody(isInitialRiskScoreModalOpen, 'root');

  useEffect(() => {
    if (!scrollbarsRef.current) {
      return;
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      const currentScrollTop = scrollbarsRef.current?.getScrollTop() ?? 0;

      if (e.key === 'ArrowDown') {
        scrollbarsRef.current?.scrollTop(currentScrollTop + 20);
      } else if (e.key === 'ArrowUp') {
        scrollbarsRef.current?.scrollTop(currentScrollTop - 20);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [scrollbarsRef.current]);

  return (
    <>
      <Modal
        header={i18n('roboAdvice.riskScore.initialRiskScore')}
        isOpen={isInitialRiskScoreModalOpen}
        onRequestClose={() => setIsInitialRiskScoreModalOpen(false)}
        headerRef={headerRef}
      >
        <Scrollbars
          autoHeightMax={modalBodyMaxHeight}
          forwardedRef={scrollbarsRef}
        >
          <PurposeAndRiskDetailsPopover modalBodyMaxWidth={modalBodyMaxWidth} />
        </Scrollbars>
      </Modal>

      <TextReference onClick={() => setIsInitialRiskScoreModalOpen(true)}>
        {initialRiskScore ? initialRiskScore : <span>&nbsp;</span>}
      </TextReference>
    </>
  );
};

export default InitialRiskScoreModal;
