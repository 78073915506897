import queryString from 'query-string';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import DemoEnvironmentAlert from './demoEnvironmentAlert.js';
import RootStyle from './rootStyle';
import Admin from 'features/admin/main/components/index';
import Customers from 'features/customers/main/components/index.js';
import RoboAdvice from 'features/roboAdvice/main/components/index.js';
import Login from 'features/shared/components/login/index.js';
import LoginRedirect from 'features/shared/components/loginRedirect';
import PageNotFound from 'features/shared/components/pageNotFound';
import routerSelectors from 'features/shared/services/router/selectors.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { CustomerConfigProvider } from 'features/sharedModules/customerConfig/components/index';
import { injectSheet } from 'features/sharedModules/styles/components/styles';
import Tools from 'features/tools/main/components/index.js';
import UserProfile from 'features/userProfile/components/index';

const PrivateRoute = ({
  component: Component,
  render,
  isAuthenticated,
  redirectPathname,
  redirectSearch,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          R.isNil(Component) ? (
            render(props)
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: redirectPathname,
              search: redirectSearch,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const PrivateRouteComposed = connect(state => {
  const isAuthenticated = sessionSelectors.getIsAuthenticated(state);
  let redirectPathname = null;
  if (!isAuthenticated) {
    redirectPathname = routeTemplates.login.build();
  }

  return {
    isAuthenticated,
    redirectPathname
  };
})(PrivateRoute);

const RoboAdvicePrivateRouteComposed = connect(state => {
  const isAuthenticated = sessionSelectors.getIsAuthenticated(state);
  const isAdvisorSolutionLicenseValid =
    sessionSelectors.getIsAdvisorSolutionLicenseValid(state);

  let redirectPathname = null;
  let redirectSearch = null;
  if (!isAuthenticated) {
    redirectPathname = routeTemplates.login.build();
    redirectSearch = `?${queryString.stringify({
      redirectUrl: window.location.pathname + window.location.search
    })}`;
  } else if (!isAdvisorSolutionLicenseValid) {
    redirectPathname = routeTemplates.home.build();
  }

  return {
    isAuthenticated: isAuthenticated && isAdvisorSolutionLicenseValid,
    redirectPathname,
    redirectSearch
  };
})(PrivateRoute);

const AdminPrivateRouteComposed = connect(state => {
  const isAuthenticated = sessionSelectors.getIsAuthenticated(state);
  const isCustomerAdmin = sessionSelectors.getIsCustomerAdmin(state);

  let redirectPathname = null;
  let redirectSearch = null;
  if (!isAuthenticated) {
    redirectPathname = routeTemplates.login.build();
    redirectSearch = `?${queryString.stringify({
      redirectUrl: window.location.pathname + window.location.search
    })}`;
  } else if (!isCustomerAdmin) {
    redirectPathname = routeTemplates.home.build();
  }

  return {
    isAuthenticated: isAuthenticated && isCustomerAdmin,
    redirectPathname,
    redirectSearch
  };
})(PrivateRoute);

const styles = {};

const App = ({ isPageFound, redirectRoute, message }) => {
  return (
    <CustomerConfigProvider>
      <RootStyle />
      {message?.enabled && <DemoEnvironmentAlert />}
      {isPageFound && (
        <Switch>
          <Route path={routeTemplates.login.config} component={Login} />
          <Route
            path={routeTemplates.loginRedirect.config}
            component={LoginRedirect}
          />
          {!R.isNil(redirectRoute) && (
            <Route
              exact
              path={routeTemplates.home.config}
              render={() => <Redirect to={redirectRoute} />}
            />
          )}
          <PrivateRouteComposed
            path={routeTemplates.customers.config}
            component={Customers}
          />
          <PrivateRouteComposed
            path={routeTemplates.userProfile.config}
            component={UserProfile}
          />
          <RoboAdvicePrivateRouteComposed
            path={routeTemplates.roboAdvice.config}
            component={RoboAdvice}
          />
          <RoboAdvicePrivateRouteComposed
            path={routeTemplates.tools.config}
            component={Tools}
          />
          <AdminPrivateRouteComposed
            path={routeTemplates.admin.config}
            component={Admin}
          />
        </Switch>
      )}
      {!isPageFound && <PageNotFound />}
    </CustomerConfigProvider>
  );
};

export default R.compose(
  connect(state => {
    const isPageFound = routerSelectors.isRouteFound(state);
    const redirectRoute = getRedirectRoute(state);
    const { system: { message } = { message: undefined } } =
      sessionSelectors.getCustomerConfig(state);

    return {
      isPageFound,
      redirectRoute,
      message
    };
  }),
  injectSheet(styles)
)(App);

const getRedirectRoute = state => {
  if (sessionSelectors.getIsAdvisorSolutionLicenseValid(state)) {
    return routeTemplates.roboAdvice.build() + window.location.search;
  }

  const isAuthenticated = sessionSelectors.getIsAuthenticated(state);

  if (!isAuthenticated) {
    return routeTemplates.login.build() + window.location.search;
  }

  return null;
};
