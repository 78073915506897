export const stylesValues = {
  borderWidth: 2,
  inputPaddingX: 16,
  menuPaddingY: 10,
  menuItemLineHeight: 19,
  menuItemMarginBottom: 8,
  menuItemPaddingY: 8,
  menuMarginY: 10
};

const maxNumberOfOptions = 4;

const itemHeight =
  stylesValues.menuItemLineHeight + 2 * stylesValues.menuItemPaddingY;

export const getMenuHeight = (optionsLength: number) => {
  const { borderWidth, menuItemMarginBottom, menuMarginY, menuPaddingY } =
    stylesValues;

  return optionsLength > maxNumberOfOptions
    ? maxNumberOfOptions * itemHeight +
        (maxNumberOfOptions - 1) * menuItemMarginBottom +
        2 * (menuPaddingY + borderWidth + menuMarginY)
    : optionsLength * itemHeight +
        (optionsLength - 1) * menuItemMarginBottom +
        2 * (menuPaddingY + borderWidth + menuMarginY);
};
