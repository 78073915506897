import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Client, useHolisticViewStore } from '../services/holisticViewStore';
import {
  getAdvisors,
  getInvestors
} from 'features/roboAdvice/clientsList/main/api';
import {
  GetAdvisorsResponse,
  GetInvestorsResponse
} from 'features/roboAdvice/clientsList/main/types';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useReadClientsList() {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);

  const dispatch = useDispatch();

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const readClientsList = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const holisticViewStore = useHolisticViewStore.getState();

    try {
      holisticViewStore.setIsReadClientsPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const [getAdvisorsResponse, getInvestorsResponse] = await Promise.all([
        getAdvisors(accessToken, cancelTokenSource.token),
        getInvestors(accessToken, cancelTokenSource.token)
      ]);

      const clients = mapServerInvestorsToClients(
        getAdvisorsResponse.data,
        getInvestorsResponse.data
      );

      holisticViewStore.setAvailableClients(clients);
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.clientsList.readClientsErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    } finally {
      holisticViewStore.setIsReadClientsPending(false);
    }
  };

  useEffect(() => {
    return () => {
      if (!isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
    };
  }, []);

  return readClientsList;
}

const mapServerInvestorsToClients = (
  advisorsResponse: GetAdvisorsResponse,
  investors: GetInvestorsResponse
): Client[] => {
  const { advisors } = advisorsResponse;

  return investors.map(({ name, id, investorType, email, advisorId }) => ({
    name,
    id,
    type: investorType,
    email,
    advisorId,
    advisorName:
      advisors.find(advisor => advisor.advisor_id === advisorId)?.name ?? null,
    adviceSessions: [],
    isSelected: false
  }));
};
