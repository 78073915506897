import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';

import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
    minHeight: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'inherit'
  }
});

type Props = {
  /**
   * Color of the indicator.
   * Default "inherit" matches text color.
   */
  color?: string;
  /**
   * The size of the circle. If using a number, the pixel unit is assumed. If using a string, you need to provide the CSS unit, e.g "3rem".
   */
  size?: number | string;
  /**
   * Class name to apply to the container div.
   */
  containerClassName?: string;
  /**
   * Style to apply to the MaterialUI CircularProgress element.
   *
   * https://mui.com/material-ui/api/circular-progress/
   */
  style?: React.CSSProperties;
};

export const LoadingIndicator = ({
  color = 'inherit',
  size = 50,
  style,
  containerClassName
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, containerClassName)}>
      <CircularProgress
        data-testid="loading-indicator"
        style={{ color, ...style }}
        size={size}
      />
    </div>
  );
};

export default LoadingIndicator;
