import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

import { dateFormats } from './constants';
import FullDatePicker from './fullDatePicker';
import './style.css';
import { useStyles } from './styles';
import YearDatePicker from './yearDatePicker';
import YearMonthDatePicker from './yearMonthDatePicker';
import Icon from 'features/shared/components/icon/index.js';

const CustomDatePicker = ({
  selected,
  onChange,
  inputClassName,
  placeholder,
  dateFormat,
  dateType,
  disabled,
  allowedYearsRange,
  minDate,
  maxDate
}) => {
  const [rawDate, setRawDate] = useState(
    selected ? new Date(selected) : new Date()
  );
  const styles = useStyles();

  if (disabled) {
    return (
      <div className={styles.datePickerWrap}>
        <DatePicker
          selected={selected}
          onChange={date => onChange(date)}
          disabled
          dateFormat={dateFormat}
          customInput={
            <input
              className={inputClassName}
              onChange={onChange}
              value={selected}
            />
          }
        />
        <Icon className={styles.calendarIcon} type="calendar_month" />
      </div>
    );
  }

  let finalDatePicker;

  if (dateFormat === dateFormats.years) {
    finalDatePicker = (
      <YearDatePicker
        selected={selected}
        onChange={onChange}
        placeholder={placeholder}
        dateType={dateType}
        allowedYearsRange={allowedYearsRange}
        inputClassName={inputClassName}
        minDate={minDate}
        maxDate={maxDate}
      />
    );
  } else if (dateFormat === dateFormats.monthYear) {
    finalDatePicker = (
      <YearMonthDatePicker
        selected={selected}
        onChange={onChange}
        placeholder={placeholder}
        dateType={dateType}
        allowedYearsRange={allowedYearsRange}
        inputClassName={inputClassName}
      />
    );
  } else {
    finalDatePicker = (
      <FullDatePicker
        selected={selected}
        onChange={onChange}
        placeholder={placeholder}
        dateType={dateType}
        allowedYearsRange={allowedYearsRange}
        inputClassName={inputClassName}
        rawDate={rawDate}
        setRawDate={setRawDate}
        weekDayStyles={styles.weekDay}
        dateFormat={dateFormat}
      />
    );
  }

  return (
    <div className={styles.datePickerWrap}>
      {finalDatePicker}
      <Icon className={styles.calendarIcon} type="calendar_month" />
    </div>
  );
};

export default CustomDatePicker;
