import { parseISO, isDate, parse, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { isEmpty, isNil } from 'ramda';

const apiFormatKey = 'yyyy-MM-dd';
const utcTimeZone = 'UTC';

export const utcNow = () => new Date();

export const getPlaceholder = cultureCode =>
  getDateFnsFormatKey(cultureCode).toLowerCase();

export const formatDate = (cultureCode, dateIsoString) => {
  if (isNil(dateIsoString) || isEmpty(dateIsoString)) {
    return '';
  }

  const dateFormat = getDateFnsFormatKey(cultureCode);

  return formatInTimeZone(parseISO(dateIsoString), utcTimeZone, dateFormat);
};

export const formatDateForAPI = dateString => {
  if (isNil(dateString) || isEmpty(dateString)) {
    return null;
  }

  return format(parseISO(dateString), apiFormatKey);
};

export const parseDate = (cultureCode, str) => {
  const format = getDateFnsFormatKey(cultureCode);
  const result = parse(str, format, new Date());

  return isDate(result) && !isNaN(result) ? result : undefined;
};

const getDateFnsFormatKey = cultureCode => {
  const format = cultureToFormatMap[cultureCode];

  return isNil(format) ? defaultFormat : format;
};

const defaultFormat = 'd/M/yyyy';
// culture code represents {ISO-639-1}-{ISO-3166-1}
// list of codes https://wiki.openstreetmap.org/wiki/Nominatim/Country_Codes

const cultureToFormatMap = {
  'no-no': 'dd.MM.yyyy',
  'en-us': 'M/d/yyyy',
  'sv-se': 'yyyy-MM-dd',
  'en-gb': 'dd/MM/yyyy',
  'ru-ru': 'dd.MM.yyyy',
  'fi-fi': 'd.M.yyyy',
  'da-dk': 'dd-MM-yyyy',
  'is-is': 'd.M.yyyy',
  'de-de': 'dd.MM.yyyy',
  'nl-nl': 'd-M-yyyy'
};

// * Highcharts supported format keys:
// * - `%a`: Short weekday, like 'Mon'
// * - `%A`: Long weekday, like 'Monday'
// * - `%d`: Two digit day of the month, 01 to 31
// * - `%e`: Day of the month, 1 through 31
// * - `%w`: Day of the week, 0 through 6
// * - `%b`: Short month, like 'Jan'
// * - `%B`: Long month, like 'January'
// * - `%m`: Two digit month number, 01 through 12
// * - `%o`: Month number, 1 through 12
// * - `%y`: Two digits year, like 09 for 2009
// * - `%Y`: Four digits year, like 2009
// * - `%H`: Two digits hours in 24h format, 00 through 23
// * - `%k`: Hours in 24h format, 0 through 23
// * - `%I`: Two digits hours in 12h format, 00 through 11
// * - `%l`: Hours in 12h format, 1 through 12
// * - `%M`: Two digits minutes, 00 through 59
// * - `%p`: Upper case AM or PM
// * - `%P`: Lower case AM or PM
// * - `%S`: Two digits seconds, 00 through 59
// * - `%L`: Milliseconds (naming from Ruby)
// It is possible to create custom format letters and parsers for them for highcharts.

// date format sources:
// https://github.com/unicode-cldr/cldr-json (problem: short year format not acceptable for date input)
// moment js locale folder (problem: no en-us file)
// https://gist.github.com/mlconnor/1887156
