import * as R from 'ramda';

import { UsePatchQueueStore } from './patchQueueStore';
import { throwSafeError } from 'features/shared/utils/throwSafeError';

export function createUseQueuedPatch(usePatchQueueStore: UsePatchQueueStore) {
  return (applyPatches: (patches: any[]) => void) => {
    return async patch => {
      let store = usePatchQueueStore.getState();

      store.enqueuePatch(patch);
      store.setIsScheduled(true);

      if (store.isPending) {
        return;
      }

      while (true) {
        store = usePatchQueueStore.getState();

        if (!store.isScheduled) {
          return;
        }

        store.setIsScheduled(false);
        if (!R.isEmpty(store.patches)) {
          try {
            store.setIsPending(true);
            await applyPatches(store.patches);
          } catch (error) {
            if (R.isEmpty(store.patches)) {
              throwSafeError(error);
            }
          } finally {
            store.dequeuePatches(R.length(store.patches));
            store.setIsPending(false);
          }
        }
      }
    };
  };
}
