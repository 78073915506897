import { getTotalPagesNumber } from 'features/shared/utils/table.js';

// Can we just calculate appropriate pageNumber in selector?
export const updatePageNumber = (totalItemsNumber, state) => {
  const { pageSize, pageNumber } = state;

  const totalPagesNumber = getTotalPagesNumber(totalItemsNumber, pageSize);
  const lastPageNumber = Math.max(0, totalPagesNumber - 1);

  return pageNumber > lastPageNumber
    ? { ...state, pageNumber: lastPageNumber }
    : state;
};
