import classNames from 'classnames';
import { isEmpty, isNil } from 'ramda';
import { useMemo } from 'react';
import { useField } from 'react-final-form';

import { useAutomaticClientClassificationStore } from '../../services/automaticClientClassificationStore';
import { ItemTypes, NONE_OF_THE_ABOVE_ID } from './constants';
import { ReactComponent as ArrowRightSlimIcon } from 'assets/arrowRightSlim.svg';
import { ReactComponent as InfoIcon } from 'assets/info.svg';
import Radio from 'features/shared/components/radio';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import FinalFormCheckboxes from 'features/sharedModules/finalForm/components/checkboxes';
import FinalFormRadios from 'features/sharedModules/finalForm/components/radios';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

export const useStyles = createUseStyles(theme => ({
  header: {
    fontWeight: FontWeights.medium,
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    marginBottom: Spacing.spacing00,
    display: 'flex',
    alignItems: 'center'
  },
  description: {
    color: theme.secondaryColor,
    fontSize: Typography.body1.size,
    marginBottom: Spacing.spacing02
  },
  requiredMark: {
    display: 'inline',
    fontWeight: FontWeights.medium,
    color: theme.errorNegativeColor
  },
  infoIcon: {
    fill: theme.secondaryColor,
    cursor: 'pointer'
  },
  clientClassificationContainer: {
    display: 'flex',
    gap: Spacing.spacing00,
    border: `2px solid ${theme.itemBackgroundColor_2}`,
    borderRadius: 12,
    width: 'fit-content',
    padding: Spacing.spacing00
  },
  clientClassificationList: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.spacing00
  },
  clientClassificationElement: {
    backgroundColor: theme.boxBackgroundColor_2,
    width: 330,
    height: 64,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    cursor: 'pointer'
  },
  clientClassificationDetails: {
    width: '330px',
    borderRadius: 12,
    backgroundColor: theme.boxBackgroundColor_1,
    padding: '12px 16px'
  },
  detailsHeader: {
    marginBottom: '35px'
  },
  selectedElement: {
    backgroundColor: theme.accentColor
  },
  arrowIcon: {
    marginLeft: 'auto',
    marginRight: Spacing.spacing01,

    '& path': {
      fill: theme.secondaryColor
    }
  },
  arrowIconSelected: {
    '& path': {
      fill: theme.primaryColor
    }
  },
  radio: {
    padding: `0 ${Spacing.spacing01}px`
  },
  radioItem: {
    '& + &': {
      marginTop: '18.5px'
    }
  }
}));

const ClientClassificationQuestions = () => {
  const classes = useStyles();
  const theme = useTheme();
  const i18n = useI18n();
  const {
    roboAdvice: {
      clientInformation: {
        automaticClientClassification: { items = [], educationalModal } = {}
      }
    }
  } = useCustomerConfig();
  const { setIsEducationalModalOpen } = useAutomaticClientClassificationStore();
  const {
    input: {
      onChange: onChangeClientClassificationId,
      value: clientClassificationId
    }
  } = useField('kyc.automaticClientClassification.clientClassificationId');

  const automaticClientClassificationItems = [
    ...items.map(i => ({ ...i, title: i18n(i.title) })),
    {
      title: i18n(
        'roboAdvice.kyc.automaticClientClassification.noneOfTheAbove'
      ) as string,
      id: NONE_OF_THE_ABOVE_ID
    }
  ];

  const selectedClientClassification = useMemo(() => {
    const data = items?.find(({ id }) => id === clientClassificationId);

    return {
      ...data,
      options: [
        ...(data?.options?.map(option => i18n(option)) || []),
        i18n('roboAdvice.kyc.automaticClientClassification.noneOfTheAbove')
      ]
    };
  }, [items, clientClassificationId]);

  return (
    <div>
      <div className={classes.header}>
        {i18n(
          'roboAdvice.kyc.automaticClientClassification.clientClassification.header'
        )}
        <span className={classes.requiredMark}>&nbsp;*&nbsp;</span>
        {educationalModal?.enabled &&
          educationalModal?.pages &&
          educationalModal.pages.length > 0 && (
            <InfoIcon
              className={classes.infoIcon}
              onClick={() => {
                setIsEducationalModalOpen(true);
              }}
            />
          )}
      </div>

      <div className={classes.description}>
        {i18n(
          'roboAdvice.kyc.automaticClientClassification.clientClassification.description'
        )}
      </div>

      <div className={classes.clientClassificationContainer}>
        <div className={classes.clientClassificationList}>
          {automaticClientClassificationItems.map(({ title, id }) => (
            <div
              className={classNames(classes.clientClassificationElement, {
                [classes.selectedElement]: id === clientClassificationId
              })}
              onClick={() => {
                onChangeClientClassificationId(id);
              }}
              key={id}
            >
              <Radio
                width={25}
                height={25}
                className={classes.radio}
                checked={id === clientClassificationId}
                iconColor={
                  id === clientClassificationId ? theme.primaryColor : undefined
                }
                readOnly
              />
              {title}
              {id !== NONE_OF_THE_ABOVE_ID && (
                <ArrowRightSlimIcon
                  className={classNames(classes.arrowIcon, {
                    [classes.arrowIconSelected]: id === clientClassificationId
                  })}
                />
              )}
            </div>
          ))}
        </div>

        {clientClassificationId !== NONE_OF_THE_ABOVE_ID &&
          !isNil(clientClassificationId) &&
          !isEmpty(clientClassificationId) && (
            <div className={classes.clientClassificationDetails}>
              <div className={classes.detailsHeader}>
                {i18n(selectedClientClassification?.header)}
              </div>
              {selectedClientClassification?.type === ItemTypes.radio && (
                <FinalFormRadios
                  items={selectedClientClassification?.options?.map(option => ({
                    activeValue: option,
                    label: option
                  }))}
                  name="kyc.automaticClientClassification.answer"
                  className={classes.radioItem}
                />
              )}
              {selectedClientClassification?.type === ItemTypes.checkbox && (
                <FinalFormCheckboxes
                  items={selectedClientClassification?.options?.map(option => ({
                    activeValue: option,
                    label: option
                  }))}
                  width={22.5}
                  height={22.5}
                  name="kyc.automaticClientClassification.answer"
                />
              )}
            </div>
          )}
      </div>
    </div>
  );
};

export default ClientClassificationQuestions;
