import { z } from 'zod';

export const riskScenariosSchema = z.array(
  z.object({
    id: z.number(),
    min: z.number(),
    max: z.number()
  })
);

type RiskScenarios = z.infer<typeof riskScenariosSchema>;

export const defaultRiskScenarios: RiskScenarios = [
  {
    id: 1,
    min: -11,
    max: 13
  },
  {
    id: 2,
    min: -13,
    max: 16
  },
  {
    id: 3,
    min: -17,
    max: 20
  },
  {
    id: 4,
    min: -26,
    max: 24
  }
];
