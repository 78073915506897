import { useHistory, useParams } from 'react-router-dom';

import { ToolsListPages } from '../constants';
import { ToolsParams } from '../types';
import { BigTabs, BigTab } from 'features/shared/components/tabs/big';
import { Spacing } from 'features/shared/constants/spacing';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { useQuery } from 'features/sharedModules/reactRouter/components/useQuery';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  tabs: {
    margin: `${Spacing.spacing02}px 0`
  }
});

const Navigation = () => {
  const i18n = useI18n();
  const classes = useStyles();
  const history = useHistory();
  const { pageId } = useParams<ToolsParams>();
  const query = useQuery();
  const {
    tools: {
      savingCalculator: { enabled: isSavingCalculatorEnabled },
      holisticView: { enabled: isHolisticViewEnabled }
    }
  } = useCustomerConfig();

  return (
    <>
      {Object.values(ToolsListPages).includes(pageId) && (
        <BigTabs
          rootClassName={classes.tabs}
          value={pageId}
          onChange={(event, newPageId) => {
            history.push(routeTemplates.tools.build(newPageId, query));
          }}
        >
          {isHolisticViewEnabled && (
            <BigTab
              value={ToolsListPages.holisticView}
              label={i18n('tools.holisticView')}
            />
          )}
          {isSavingCalculatorEnabled && (
            <BigTab
              value={ToolsListPages.savingCalculator}
              label={i18n('tools.savingCalculator')}
            />
          )}
        </BigTabs>
      )}
    </>
  );
};

export default Navigation;
