import { useMemo } from 'react';

import { FieldSections } from '../../constants';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { FieldComponentTypes } from 'features/sharedModules/declarativeForm/constants';
import { Layouts as FinalFormSwitchLayouts } from 'features/sharedModules/finalForm/components/switch.js';

type AnalyticsComponentsField = [
  string,
  {
    render: {
      fieldSection: string;
      componentType: string;
      labelKey: string;
      layout: string;
      display: boolean;
    };
  }
];

export const useAnalyticsComponentsFields = () => {
  const { analyticsComponents } = useCustomerConfig();

  const analyticsComponentsFields = useMemo(
    () =>
      (
        [
          [
            'analyticsComponents.all',
            {
              render: {
                fieldSection: FieldSections.analyticsComponents,
                componentType: FieldComponentTypes.switch,
                labelKey: 'roboAdvice.proposal.generateReport.all',
                layout: FinalFormSwitchLayouts.horizontal,
                display: true
              }
            }
          ],
          [
            'analyticsComponents.investmentPlan',
            {
              render: {
                fieldSection: FieldSections.analyticsComponents,
                componentType: FieldComponentTypes.switch,
                labelKey: 'roboAdvice.proposal.generateReport.investmentPlan',
                layout: FinalFormSwitchLayouts.horizontal,
                display: true
              }
            }
          ],
          [
            'analyticsComponents.portfolio.broadAssetAllocation',
            {
              render: {
                fieldSection: FieldSections.analyticsComponents,
                componentType: FieldComponentTypes.switch,
                labelKey: 'roboAdvice.proposal.broadAssetAllocation',
                layout: FinalFormSwitchLayouts.horizontal,
                display: !analyticsComponents.isPortfolioHidden
              }
            }
          ],
          [
            'analyticsComponents.portfolio.assetAllocation',
            {
              render: {
                fieldSection: FieldSections.analyticsComponents,
                componentType: FieldComponentTypes.switch,
                labelKey: 'roboAdvice.proposal.assetAllocation',
                layout: FinalFormSwitchLayouts.horizontal,
                display: !analyticsComponents.isPortfolioHidden
              }
            }
          ],
          [
            'analyticsComponents.portfolio.fundAllocation',
            {
              render: {
                fieldSection: FieldSections.analyticsComponents,
                componentType: FieldComponentTypes.switch,
                labelKey: 'roboAdvice.proposal.fundAllocation',
                layout: FinalFormSwitchLayouts.horizontal,
                display: !analyticsComponents.isPortfolioHidden
              }
            }
          ],
          [
            'analyticsComponents.expectations',
            {
              render: {
                fieldSection: FieldSections.analyticsComponents,
                componentType: FieldComponentTypes.switch,
                labelKey: 'roboAdvice.proposal.generateReport.expectations',
                layout: FinalFormSwitchLayouts.horizontal,
                display: true
              }
            }
          ],
          [
            'analyticsComponents.expectedPath',
            {
              render: {
                fieldSection: FieldSections.analyticsComponents,
                componentType: FieldComponentTypes.switch,
                labelKey: 'roboAdvice.proposal.generateReport.expectedPath',
                layout: FinalFormSwitchLayouts.horizontal,
                display: !analyticsComponents.isExpectedPathHidden
              }
            }
          ],
          [
            'analyticsComponents.expectedValue',
            {
              render: {
                fieldSection: FieldSections.analyticsComponents,
                componentType: FieldComponentTypes.switch,
                labelKey: 'roboAdvice.proposal.generateReport.expectedValue',
                layout: FinalFormSwitchLayouts.horizontal,
                display: !analyticsComponents.isExpectedValueHidden
              }
            }
          ],
          [
            'analyticsComponents.costValue',
            {
              render: {
                fieldSection: FieldSections.analyticsComponents,
                componentType: FieldComponentTypes.switch,
                labelKey: 'roboAdvice.proposal.generateReport.cost',
                layout: FinalFormSwitchLayouts.horizontal,
                display: !analyticsComponents.isCostHidden
              }
            }
          ],
          [
            'analyticsComponents.historicalReturn',
            {
              render: {
                fieldSection: FieldSections.analyticsComponents,
                componentType: FieldComponentTypes.switch,
                labelKey: 'roboAdvice.proposal.generateReport.historicalReturn',
                layout: FinalFormSwitchLayouts.horizontal,
                display: !analyticsComponents.isHistoricalReturnHidden
              }
            }
          ],
          [
            'analyticsComponents.orderSummary',
            {
              render: {
                fieldSection: FieldSections.analyticsComponents,
                componentType: FieldComponentTypes.switch,
                labelKey: 'roboAdvice.proposal.orderSummary',
                layout: FinalFormSwitchLayouts.horizontal,
                display: !analyticsComponents.isOrderSummaryHidden
              }
            }
          ],
          [
            'analyticsComponents.sustainability',
            {
              render: {
                fieldSection: FieldSections.analyticsComponents,
                componentType: FieldComponentTypes.switch,
                labelKey: 'roboAdvice.proposal.sustainability',
                layout: FinalFormSwitchLayouts.horizontal,
                display: !analyticsComponents.isSustainabilityHidden
              }
            }
          ],
          [
            'analyticsComponents.cashflow',
            {
              render: {
                fieldSection: FieldSections.analyticsComponents,
                componentType: FieldComponentTypes.switch,
                labelKey: 'roboAdvice.proposal.cashflow',
                layout: FinalFormSwitchLayouts.horizontal,
                display: !analyticsComponents.isCashflowHidden
              }
            }
          ]
        ] as AnalyticsComponentsField[]
      ).filter(([_, { render }]) => render.display),
    [
      analyticsComponents.isCashflowHidden,
      analyticsComponents.isCostHidden,
      analyticsComponents.isExpectedPathHidden,
      analyticsComponents.isExpectedValueHidden,
      analyticsComponents.isHistoricalReturnHidden,
      analyticsComponents.isOrderSummaryHidden,
      analyticsComponents.isPortfolioHidden,
      analyticsComponents.isSustainabilityHidden
    ]
  );

  return analyticsComponentsFields;
};
