import * as R from 'ramda';

const indexedReduce = R.addIndex(R.reduce);

const indexedFilter = R.addIndex(R.filter);

const getIsDetailValueEmpty = value => R.isNil(value);

const getIsDetailEmpty = detail =>
  getIsDetailValueEmpty(R.prop('value', detail));

const getIsDetailFilled = detail =>
  !getIsDetailValueEmpty(R.prop('value', detail));

export const createGetDoInitializeDetails =
  ({ name, getFormState }) =>
  () => {
    const namePath = R.split('.', name);
    const { values: formValues } = getFormState();
    const details = R.path(namePath, formValues);

    return R.isNil(details) || R.isEmpty(details);
  };

export const createGetDoAddNewDetail =
  ({ name, getFormState }) =>
  () => {
    const namePath = R.split('.', name);
    const { values } = getFormState();

    const details = R.path(namePath, values);
    const lastDetail = !R.isNil(details) ? R.last(details) : null;

    return !getIsDetailEmpty(lastDetail);
  };

export const createGetInvalidDetailsFields =
  ({ name, getFormState }) =>
  () => {
    const namePath = R.split('.', name);
    const { errors } = getFormState();

    const detailsErrors = R.pathOr([], namePath, errors);

    return indexedReduce(
      (acc, detailErrors, detailIndex) => {
        if (R.isNil(detailErrors)) {
          return acc;
        }

        return R.pipe(
          R.keys,
          R.reject(key => R.isNil(detailErrors[key])),
          R.map(key => `${name}.${detailIndex}.${key}`),
          R.concat(acc)
        )(detailErrors);
      },
      [],
      detailsErrors
    );
  };

const createUseDoShowDetailsIconError =
  ({ name, useForm }) =>
  () => {
    const formState = useForm();

    return getDoShowDetailsIconErrorResult({ name, formState });
  };

export const getDoShowDetailsIconErrorResult = ({ name, formState }) => {
  const namePath = R.split('.', name);
  const { errors, touched, values } = formState;

  const detailsErrors = R.pathOr([], namePath, errors);
  const doesAnyDetailErrorExist =
    Array.isArray(detailsErrors) &&
    detailsErrors.some(
      detailErrors => !R.isNil(detailErrors) && !R.isEmpty(detailErrors)
    );
  const doesFirstDetailIsTouched = touched?.[`${name}.0.value`] === true;
  const doesFirstDetailHasValue = !R.isNil(
    R.pathOr(null, [...namePath, 0, 'value'], values)
  );

  return (
    doesAnyDetailErrorExist &&
    (doesFirstDetailIsTouched || doesFirstDetailHasValue)
  );
};

const createUseLockValue =
  ({ name, useForm }) =>
  () => {
    const formState = useForm();

    return getLockValueResult({ name, formState });
  };

export const getLockValueResult = ({ name, formState }) => {
  const namePath = R.split('.', name);
  const { values, errors } = formState;

  const details = R.pathOr([], namePath, values);
  const detailsErrors = R.pathOr([], namePath, errors);
  const validNotEmptyDetails = getValidNotEmptyDetails(details, detailsErrors);
  const doesFirstDetailHasAccountNumber = !R.isNil(details?.[0]?.accountNumber);

  if (validNotEmptyDetails.length >= 2 || doesFirstDetailHasAccountNumber) {
    return getTotalValueResult({ name, formState });
  }

  return null;
};

const createUseDoesNumberInfoExist =
  ({ name, useForm }) =>
  () => {
    const formState = useForm();

    return getDoesNumberInfoExistResult({ name, formState });
  };

export const getDoesNumberInfoExistResult = ({ name, formState }) => {
  const namePath = R.split('.', name);
  const { values, errors } = formState;
  const details = R.pathOr([], namePath, values);
  const detailsErrors = R.pathOr([], namePath, errors);
  const validNotEmptyDetails = getValidNotEmptyDetails(details, detailsErrors);

  if (validNotEmptyDetails.length >= 2 || validNotEmptyDetails[0]?.title) {
    return true;
  }

  return false;
};

const getDetailValueValidationError = (value, isIgnored) => {
  if (isIgnored && getIsDetailValueEmpty(value)) {
    return null;
  }

  if (getIsDetailValueEmpty(value)) {
    return 'roboAdvice.detalizedNumberInput.valueEmptyValidationError';
  }

  return null;
};

const getValidNotEmptyDetails = (details, detailsErrors) =>
  indexedFilter((detail, detailIndex) => {
    const detailErrors = detailsErrors[detailIndex];

    return (
      (R.isNil(detailErrors) || R.isEmpty(detailErrors)) &&
      getIsDetailFilled(detail)
    );
  }, details);

const createUseTotalValue =
  ({ name, useForm }) =>
  () => {
    const formState = useForm();

    return getTotalValueResult({ name, formState });
  };

export const getTotalValueResult = ({ name, formState }) => {
  const { values, errors } = formState;
  const namePath = R.split('.', name);
  const details = R.pathOr([], namePath, values);
  const detailsErrors = R.pathOr([], namePath, errors);
  const validNotEmptyDetails = getValidNotEmptyDetails(details, detailsErrors);

  if (!R.isEmpty(validNotEmptyDetails)) {
    return R.pipe(R.map(R.prop('value')), R.sum)(validNotEmptyDetails);
  }

  return null;
};

export const createSelectors = settings => {
  const { getFormState } = settings;

  return {
    getFormState,
    getDoInitializeDetails: createGetDoInitializeDetails(settings),
    getDoAddNewDetail: createGetDoAddNewDetail(settings),
    getInvalidDetailsFields: createGetInvalidDetailsFields(settings),
    useDoShowDetailsIconError: createUseDoShowDetailsIconError(settings),
    useTotalValue: createUseTotalValue(settings),
    useLockValue: createUseLockValue(settings),
    useDoesNumberInfoExist: createUseDoesNumberInfoExist(settings)
  };
};

export const getDetalizedNumberInputErrors = value => {
  const details = value || [];
  const detailsErrors = details.map(
    (detail, detailIndex, detailsCollection) => {
      const valueValidationError = getDetailValueValidationError(
        detail?.value,
        detailIndex === detailsCollection.length - 1 ||
          (detailsCollection.length === 2 && detailIndex === 0)
      );
      if (R.isNil(valueValidationError)) {
        return null;
      }

      return {
        value: valueValidationError
      };
    }
  );

  return detailsErrors;
};

export const getDoShowDetailDeleteIcon = (detailsLength, detailIndex) => {
  const lastDetailIndex = detailsLength - 1;
  const isLastEmptyDetail = detailIndex === lastDetailIndex;

  return !isLastEmptyDetail;
};
