import * as R from 'ramda';
import create, { UseBoundStore } from 'zustand';

import { ClientType } from 'features/shared/constants/session';

export type Client = {
  id: string;
  type: ClientType;
  name: string;
  email: string | null;
  advisorId: string | null;
  advisorName: string | null;
};

export type ClientsListStoreData = {
  search: string;
  clients: Client[];
  isReadClientsPending: boolean;
  isDeleteClientPending: boolean;
  isTransferClientPending: boolean;
};

export type ClientsListStoreApi = {
  reset: () => void;
  setSearch: (search: string) => void;
  setClients: (clients: Client[]) => void;
  deleteClient: (clientId: string) => void;
  takeOverClient: (clientId: string, currentUser: any) => void;
  setIsReadClientsPending: (isReadClientsPending: boolean) => void;
  setIsDeleteClientPending: (isDeleteClientPending: boolean) => void;
  setIsTransferClientPending: (isTransferClientPending: boolean) => void;
};

export type ClientsListStoreState = ClientsListStoreData & ClientsListStoreApi;

export type UseClientsListStore = UseBoundStore<ClientsListStoreState>;

const defaultData = {
  search: '',
  clients: [],
  isReadClientsPending: false,
  isDeleteClientPending: false,
  isTransferClientPending: false
};

export const useClientsListStore = create<ClientsListStoreState>(set => ({
  ...defaultData,
  reset() {
    set(defaultData);
  },
  setSearch(search) {
    set({ search });
  },
  setClients(clients) {
    set({ clients });
  },
  deleteClient(clientId) {
    set(
      R.over(R.lensProp('clients'), R.reject(R.propEq('id', clientId))),
      true
    );
  },
  takeOverClient(clientId, currentUser) {
    set(state => changeClientAdvisor({ state, clientId, currentUser }), true);
  },
  setIsReadClientsPending(isReadClientsPending) {
    set({ isReadClientsPending });
  },
  setIsDeleteClientPending(isDeleteClientPending) {
    set({ isDeleteClientPending });
  },
  setIsTransferClientPending(isTransferClientPending) {
    set({ isTransferClientPending });
  }
}));

export const changeClientAdvisor = ({ state, clientId, currentUser }) => {
  const clients = state.clients;
  const clientIndex = R.findIndex(R.propEq('id', clientId))(clients);
  if (clientIndex === -1) return state;
  return R.pipe(
    R.set(
      R.lensPath(['clients', clientIndex, 'advisorId']),
      currentUser.auth0Id
    ),
    R.set(R.lensPath(['clients', clientIndex, 'advisorName']), currentUser.name)
  )(state);
};
