import { useDispatch, useSelector } from 'react-redux';

import { clientsTable } from '../../clientsTable';
import { restoreDeletedInvestors } from '../api';
import { useClientsStore } from '../services/clientsStore';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export const useRestoreClients = () => {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();

  const restoreClients = async () => {
    const clientsStore = useClientsStore.getState();
    const tableStore = clientsTable.useTableStore.getState();

    try {
      clientsStore.setIsRestoreClientsPending(true);

      const accessToken = await getQAuthAccessToken(auth0AccessToken);

      const { clients } = clientsStore;
      const { selectedRowIds } = tableStore;

      const investorIdsToRestore = clients
        .filter(c => selectedRowIds.includes(c.id))
        .map(({ id }) => id);

      const restoredInvestorsResponse = investorIdsToRestore.length
        ? await restoreDeletedInvestors(accessToken, investorIdsToRestore)
        : null;

      const restoredInvestorsIds = restoredInvestorsResponse
        ? restoredInvestorsResponse.data.data.map(({ id }) => id)
        : [];

      clientsStore.deleteClients(restoredInvestorsIds);
      tableStore.changeAllRowsSelection(false);

      clientsStore.setIsRestoreClientsPending(false);

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n('admin.trashCan.clients.restoreClientsSuccessMessage'),
          type: NotificationTypes.success
        })
      );
    } catch (error) {
      clientsStore.setIsRestoreClientsPending(false);

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n('admin.trashCan.clients.restoreClientsErrorMessage'),
          type: NotificationTypes.error
        })
      );

      throwSafeError(error);
    }
  };
  return restoreClients;
};
