export const startedType = baseActionName => `${baseActionName}_STARTED`;
export const succeedType = baseActionName => `${baseActionName}_SUCCEED`;
export const failedType = baseActionName => `${baseActionName}_FAILED`;
export const cancelledType = baseActionName => `${baseActionName}_CANCELLED`;

export const startedCreator = (baseActionName, payload, meta) => ({
  type: startedType(baseActionName),
  payload,
  meta
});

export const succeedCreator = (baseActionName, payload, meta) => ({
  type: succeedType(baseActionName),
  payload,
  meta
});

export const failedCreator = (baseActionName, payload, meta) => ({
  type: failedType(baseActionName),
  payload,
  meta
});

export const cancelledCreator = (baseActionName, payload, meta) => ({
  type: cancelledType(baseActionName),
  payload,
  meta
});
