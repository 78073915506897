import * as R from 'ramda';

import { useTranslationsStore } from '../services/translationsStore';
import { translationsTable } from 'features/admin/appAdmin/advisorSolution/translationsTable';
import {
  orderDirectionFuncs,
  getPage
} from 'features/shared/services/table/selector.js';

const TranslationsTableOrderKeys = {
  description: 'description',
  default: 'default',
  name: 'name'
};

const orderKeyFuncs = {
  [TranslationsTableOrderKeys.description]: R.pipe(
    R.propOr('', 'description'),
    R.toLower
  ),
  [TranslationsTableOrderKeys.default]: R.pipe(
    R.propOr('', 'default'),
    R.toLower
  ),
  [TranslationsTableOrderKeys.name]: R.pipe(R.propOr('', 'name'), R.toLower)
};

type Comparator = (a: unknown, b: unknown) => number;

const useTranslations = () => {
  const { useTableStore } = translationsTable;
  const tableStore = useTableStore();
  const translationsStore = useTranslationsStore();

  return getTranslationsResult({
    tableStore,
    translationsStore
  });
};

export const getTranslationsResult = ({ tableStore, translationsStore }) => {
  const translations = translationsStore.isSearchModeActive
    ? translationsStore.searchedTranslations
    : translationsStore.translations;

  let sortWithFunc: Comparator[] = [];
  if (!translationsStore.isSearchModeActive && !R.isNil(tableStore.orderKey)) {
    const orderDirectionFunc = orderDirectionFuncs[tableStore.orderDirection];
    const orderKeyFunc = orderKeyFuncs[tableStore.orderKey];
    if (!R.isNil(orderDirectionFunc) && !R.isNil(orderKeyFunc)) {
      sortWithFunc = [orderDirectionFunc(orderKeyFunc)];
    }
  }

  return R.pipe(R.sortWith(sortWithFunc), getPage(tableStore))(translations);
};

export default useTranslations;
