import { useEffect, useMemo } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useElementSize } from 'usehooks-ts';

import { generateOrderForm } from '../../services/generateOrderForm';
import { GenerateOrderFormValues } from '../../services/generateOrderForm';
import Scrollbars from 'features/shared/components/scrollbars';
import { Spacing } from 'features/shared/constants/spacing';
import { FontWeights } from 'features/shared/constants/typography';
import { getTranslation } from 'features/shared/utils/translations';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import FinalFormSwitch from 'features/sharedModules/finalForm/components/switch';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const formColumnHeaderMarginBottom = Spacing.spacing01;

const useStyles = createUseStyles(theme => ({
  formColumns: {
    display: 'flex',
    gap: Spacing.spacing04
  },
  formColumnHeader: {
    fontWeight: FontWeights.medium,
    marginBottom: formColumnHeaderMarginBottom,

    '&:not(:first-child)': {
      marginTop: Spacing.spacing02
    }
  },
  field: {
    padding: `14px ${Spacing.spacing01}px 14px 0`,

    '& + &': {
      marginTop: 0
    }
  },
  switchLabel: {
    color: theme.primaryColor,
    wordWrap: 'break-word'
  },
  switchSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  '@media (max-width: 1023px)': {
    formColumns: {
      flexDirection: 'column',
      gap: Spacing.spacing02
    }
  }
}));

type Props = {
  modalBodyMaxHeight?: number;
};

const GenerateOrderForm = ({ modalBodyMaxHeight }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const {
    reportAttachments,
    roboAdviceForm: {
      proposal: { morningstarAttachmentsAvailable }
    },
    roboOrderFlow: {
      proposal: { analyticsComponents }
    },
    roboAdvice: {
      orderExecution: { enableKiidDocuments, enableProductDocuments }
    }
  } = useCustomerConfig();
  const [attachmentsColumnRef, { height: attachmentsColumnHeight }] =
    useElementSize();

  const analyticsComponentsFields = [
    {
      name: 'analyticsComponents.all' as keyof GenerateOrderFormValues,
      label: i18n('roboAdvice.proposal.generateReport.all'),
      display: Object.values(analyticsComponents).some(value => value)
    },
    {
      name: 'analyticsComponents.expectedValue' as keyof GenerateOrderFormValues,
      label: i18n('roboAdvice.proposal.generateReport.expectedValue'),
      display: analyticsComponents.showExpectedValue
    },
    {
      name: 'analyticsComponents.cost' as keyof GenerateOrderFormValues,
      label: i18n('roboAdvice.proposal.generateReport.cost'),
      display: analyticsComponents.showCost
    }
  ].filter(({ display }) => display);

  const attachments = useMemo(() => {
    const morningstarAttachments = enableKiidDocuments
      ? morningstarAttachmentsAvailable
      : [];

    const productAttachments = enableProductDocuments
      ? [
          {
            name: 'productAttachments',
            label: i18n('roboAdvice.proposal.generateReport.productAttachments')
          }
        ]
      : [];

    return [
      {
        name: 'attachments.all',
        label: i18n('roboAdvice.proposal.generateReport.all')
      },
      ...reportAttachments.map(({ id, title }) => ({
        name: `attachments.${id}`,
        label: title
      })),
      ...morningstarAttachments.map(({ label, labelKey, type }) => ({
        name: `morningstarAttachments.type_${type}`,
        label: i18n(labelKey) || getTranslation(label) || ''
      })),
      ...productAttachments
    ];
  }, [i18n, reportAttachments]);

  useEffect(() => {
    analyticsComponentsFields.forEach(({ name }) =>
      generateOrderForm.change(name, true)
    );
    attachments.forEach(({ name }) =>
      generateOrderForm.change(name as keyof GenerateOrderFormValues, true)
    );
    generateOrderForm.change('includeReport', true);

    return () => generateOrderForm.restartToInitialValues();
  }, [attachments, analyticsComponentsFields]);

  const onChange = (fieldKey, sectionFields, value) => {
    if (fieldKey.split('.')[1] === 'all') {
      sectionFields.forEach(({ name }) =>
        generateOrderForm.change(name, value)
      );
    } else if (value === false) {
      generateOrderForm.change(sectionFields[0].name, false);
    } else {
      const { values } = generateOrderForm.getState();
      const sectionName = fieldKey.split('.')[0];
      generateOrderForm.change('includeReport', true);

      if (
        Object.keys(values[sectionName])
          .filter(key => key !== 'all')
          .every(key => values[sectionName][key])
      ) {
        generateOrderForm.change(sectionFields[0].name, true);
      }
    }
  };

  return (
    <Form form={generateOrderForm} onSubmit={() => {}}>
      {() => (
        <FormSpy subscription={{ values: true }}>
          {_ => (
            <div className={classes.formColumns}>
              <div>
                <div className={classes.formColumnHeader}>
                  {i18n('roboAdvice.proposal.generateReport.report')}
                </div>

                <div className={classes.field}>
                  <FinalFormSwitch
                    name={'includeReport'}
                    label={i18n(
                      'roboAdvice.proposal.generateReport.includeReport'
                    )}
                    labelClassName={classes.switchLabel}
                    sectionClassName={classes.switchSection}
                    afterChange={value =>
                      analyticsComponentsFields.forEach(({ name }) =>
                        generateOrderForm.change(name, value)
                      )
                    }
                  />
                </div>

                {analyticsComponentsFields.length > 0 && (
                  <>
                    <div className={classes.formColumnHeader}>
                      {i18n(
                        'roboAdvice.proposal.generateReport.analyticsComponents'
                      )}
                    </div>

                    {(analyticsComponentsFields.length === 2
                      ? analyticsComponentsFields.slice(1)
                      : analyticsComponentsFields
                    ).map(({ name, label }) => (
                      <div key={name} className={classes.field}>
                        <FinalFormSwitch
                          name={name}
                          label={label}
                          labelClassName={classes.switchLabel}
                          sectionClassName={classes.switchSection}
                          afterChange={value =>
                            onChange(name, analyticsComponentsFields, value)
                          }
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>

              <div>
                <div
                  ref={attachmentsColumnRef}
                  className={classes.formColumnHeader}
                >
                  {i18n('roboAdvice.proposal.generateReport.attachments')}
                </div>
                {modalBodyMaxHeight ? (
                  <Scrollbars
                    autoHeightMax={
                      modalBodyMaxHeight -
                      (attachmentsColumnHeight + formColumnHeaderMarginBottom)
                    }
                  >
                    {attachments.map(({ name, label }, index) => (
                      <div key={index} className={classes.field}>
                        <FinalFormSwitch
                          name={name}
                          label={label}
                          labelClassName={classes.switchLabel}
                          sectionClassName={classes.switchSection}
                          afterChange={value =>
                            onChange(name, attachments, value)
                          }
                        />
                      </div>
                    ))}
                  </Scrollbars>
                ) : (
                  attachments.map(({ name, label }, index) => (
                    <div key={index} className={classes.field}>
                      <FinalFormSwitch
                        name={name}
                        label={label}
                        labelClassName={classes.switchLabel}
                        sectionClassName={classes.switchSection}
                        afterChange={value =>
                          onChange(name, attachments, value)
                        }
                      />
                    </div>
                  ))
                )}
              </div>
            </div>
          )}
        </FormSpy>
      )}
    </Form>
  );
};

export default GenerateOrderForm;
