import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { useCustomPortfolioStore } from '../../services/customPortfolioStore';
import { FundAllocationRow, ProductAttributeExtended } from '../../types';
import { ReactComponent as CheckIcon } from 'assets/checkmark2.svg';
import { ReactComponent as NeutralIcon } from 'assets/neutral-icon.svg';
import { ReactComponent as CrossIcon } from 'assets/x-icon.svg';
import { useKnowledgeAndExperienceStore } from 'features/roboAdvice/adviceSession/knowledgeAndExperience/services/knowledgeAndExperienceStore';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { ProductAttributeType } from 'features/roboAdvice/adviceSession/shared/constants';
import { useAdviceSessionStore } from 'features/roboAdvice/adviceSession/shared/services/adviceSessionStore';
import { Goal } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import Tooltip from 'features/shared/components/tooltip';
import { Typography } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { formatNumber } from 'features/shared/utils/number';
import { getTranslation } from 'features/shared/utils/translations';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  numberCell: {
    textAlign: 'right'
  },
  checkIcon: {
    fill: theme.successColor
  },
  crossIcon: {
    fill: theme.errorNegativeColor
  },
  neutralIcon: {
    height: 16
  },
  center: {
    textAlign: 'center'
  }
}));

type Arguments = {
  productAttributes: ProductAttributeExtended[];
  namespaceId: number;
  selectedGoal: Goal;
};

const useFundAllocationOverviewData = ({
  productAttributes,
  namespaceId,
  selectedGoal
}: Arguments) => {
  const i18n = useI18n();
  const classes = useStyles();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const { fundsFees } = useCustomPortfolioStore();
  const { customAttributesData } = useAdviceSessionStore();
  const {
    advisoryComponents: {
      customPortfolio: {
        fundAllocation: {
          advancedSuitabilityAssessment: isAdvancedSuitabilityAssessmentEnabled
        }
      }
    },
    roboAdviceForm: {
      knowledgeAndExperience: {
        advancedSuitability: { enabled: isAdvancedSuitabilityEnabled }
      }
    },
    roboAdvice: { targetMarketAssessmentEnabled }
  } = useCustomerConfig();
  const {
    advancedSuitabilityStatus,
    advancedSuitabilityStatusDataStatuses,
    targetMarketAssessmentData,
    targetMarketAssessmentDataStatuses
  } = useKnowledgeAndExperienceStore();

  const overviewTableHeaders = [
    {
      title: i18n('roboAdvice.advisory.customPortfolio.fundManagementFee'),
      className: classes.numberCell,
      enabled: true
    },
    ...productAttributes.map(({ label, className }) => ({
      title: getTranslation(label),
      className,
      enabled: true
    })),
    {
      title: i18n('roboAdvice.advisory.customPortfolio.suitability'),
      className: classes.center,
      enabled: targetMarketAssessmentEnabled
    }
  ].filter(({ enabled }) => enabled);

  const getIcon = (instrumentId: string) => {
    if (
      advancedSuitabilityStatusDataStatuses[namespaceId] !==
      PageStatuses.succeed
    ) {
      return null;
    }

    const isProductSuitable = advancedSuitabilityStatus[namespaceId].find(
      ({ id }) => id === instrumentId
    )?.productIsSuitable;

    if (isProductSuitable === true) {
      return <CheckIcon className={classes.checkIcon} />;
    }

    if (isProductSuitable === false) {
      return <CrossIcon className={classes.crossIcon} />;
    }

    return null;
  };

  const getIconTargetMarket = (instrumentId: string) => {
    if (
      targetMarketAssessmentDataStatuses[selectedGoal.goalId] !==
      PageStatuses.succeed
    ) {
      return null;
    }

    const foundTargetMarketAssessmentData = targetMarketAssessmentData[
      selectedGoal.goalId
    ].find(({ id }) => id === instrumentId);

    const isProductSuitable =
      !!foundTargetMarketAssessmentData?.productIsSuitable;
    const isProductSuitableInPortfolio =
      !!foundTargetMarketAssessmentData?.productIsSuitableInPortfolio;

    if (isProductSuitable === true) {
      return (
        <Tooltip
          content={i18n('roboAdvice.advisory.customPortfolio.suitable')}
          infoTooltip
          contentStyles={{ fontSize: Typography.body2.size }}
          contentMaxWidth={350}
          trigger={<CheckIcon className={classes.checkIcon} />}
        />
      );
    }

    if (isProductSuitable === false && isProductSuitableInPortfolio === false) {
      return (
        <Tooltip
          content={i18n('roboAdvice.advisory.customPortfolio.notSuitable')}
          infoTooltip
          contentStyles={{ fontSize: Typography.body2.size }}
          contentMaxWidth={350}
          trigger={<CrossIcon className={classes.crossIcon} />}
        />
      );
    }

    if (isProductSuitable === false && isProductSuitableInPortfolio === true) {
      return (
        <Tooltip
          content={i18n(
            'roboAdvice.advisory.customPortfolio.suitableInPortfolio'
          )}
          infoTooltip
          contentStyles={{ fontSize: Typography.body2.size }}
          contentMaxWidth={350}
          trigger={<NeutralIcon className={classes.neutralIcon} />}
        />
      );
    }

    return null;
  };

  const createOverviewTableData = (
    fundAllocationRows: FundAllocationRow[]
  ): FundAllocationRow[] => {
    if (!customAttributesData) return fundAllocationRows;

    return fundAllocationRows.map(category => {
      const { instruments } = category;

      return {
        ...category,
        instruments: instruments.map(instrument => {
          const fundManagementFee =
            (fundsFees.data.find(f => f.ticker === instrument.id)
              ?.fundManagementFee ?? 0) * 100;

          const customAttributeData = customAttributesData?.[namespaceId]?.find(
            ({ ticker }) => ticker === instrument.id
          );

          return {
            ...instrument,
            createAdditionalData: ({ className }) => (
              <>
                <td className={className}>
                  {fundManagementFee > 0
                    ? `${formatNumber(cultureCode, fundManagementFee, 0, 2)}%`
                    : null}
                </td>

                {productAttributes.map(({ type, name }) => {
                  if (type === ProductAttributeType.text) {
                    return (
                      <td className={className} key={name}>
                        {customAttributeData?.productAttributeText?.[name]}
                      </td>
                    );
                  }

                  if (type === ProductAttributeType.binary) {
                    return (
                      <td
                        className={classNames(className, classes.center)}
                        key={name}
                      >
                        {customAttributeData?.productAttributeBinary?.[name] ? (
                          <CheckIcon className={classes.checkIcon} />
                        ) : (
                          <CrossIcon className={classes.crossIcon} />
                        )}
                      </td>
                    );
                  }

                  return null;
                })}
                {isAdvancedSuitabilityEnabled &&
                  isAdvancedSuitabilityAssessmentEnabled && (
                    <td className={classNames(className, classes.center)}>
                      {getIcon(instrument.id)}
                    </td>
                  )}

                {targetMarketAssessmentEnabled &&
                  targetMarketAssessmentData && (
                    <td className={classNames(className, classes.center)}>
                      {getIconTargetMarket(instrument.id)}
                    </td>
                  )}
              </>
            )
          };
        })
      };
    });
  };

  return {
    overviewTableHeaders:
      isAdvancedSuitabilityEnabled && isAdvancedSuitabilityAssessmentEnabled
        ? [
            ...overviewTableHeaders,
            {
              title: i18n('roboAdvice.advisory.customPortfolio.suitability')
            }
          ]
        : overviewTableHeaders,
    createOverviewTableData
  };
};

export default useFundAllocationOverviewData;
