import ClientClassificationQuestions from './clientClassificationQuestions';
import ClientClassificationResult from './clientClassificationResult';
import Modals from './modals';
import { useInitAutomaticClientClassification } from './useInitAutomaticClientClassification';
import { Spacing } from 'features/shared/constants/spacing';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  container: {
    margin: `${Spacing.spacing03}px 0`
  }
});

const AutomaticClientClassification = () => {
  const classes = useStyles();
  useInitAutomaticClientClassification();

  return (
    <div className={classes.container}>
      <Modals />

      <ClientClassificationQuestions />

      <ClientClassificationResult />
    </div>
  );
};

export default AutomaticClientClassification;
