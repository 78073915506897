import classNames from 'classnames';

import Tooltip from '../tooltip';
import { ReactComponent as CheckmarkIcon } from 'assets/checkmark.svg';
import { ReactComponent as InfoOutlineIcon } from 'assets/infoOutline.svg';
import { ReactComponent as CrossIcon } from 'assets/x-icon.svg';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import { processImgURL } from 'features/shared/utils/processImgURL';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  root: {
    width: 250,
    height: 140,
    padding: '0 4px',
    textAlign: 'center',
    backgroundColor: theme.itemBackgroundColor_2,
    borderRadius: 15,
    cursor: 'pointer',
    position: 'relative',
    border: `2px solid ${theme.itemBackgroundColor_1}`,

    '&:hover': {
      border: `2px solid ${theme.hoverInputStrokeColor}`
    }
  },
  selected: {
    backgroundColor: theme.itemBackgroundColor_1
  },
  disabled: {
    opacity: 0.4,
    cursor: 'auto'
  },
  checkmark: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 25,
    heigh: 25,
    visibility: 'hidden'
  },
  checkmarkSelected: {
    visibility: 'visible'
  },
  checkmarkDisabled: {
    filter: 'grayscale(100%)'
  },
  icon: {
    width: '100%',
    textAlign: 'center',
    userSelect: 'none',
    height: 50,
    marginTop: 25,
    marginBottom: 8,

    '& > img': {
      height: 50
    }
  },
  title: {
    color: theme.secondaryColor,
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,
    textAlign: 'center',
    userSelect: 'none'
  },
  centeredTitle: {
    color: theme.secondaryColor,
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,
    textAlign: 'center',
    userSelect: 'none',
    alignSelf: 'center',
    marginTop: '56px'
  },
  valueAdaptedContainer: {
    position: 'absolute',
    top: 6,
    left: 6,
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.spacing00,
    padding: '0px 4px',
    height: 35,
    borderRadius: '200px',
    backgroundColor: 'transparent',
    transition: 'background-color, box-shadow 0.2s',

    '$root:hover &': {
      backgroundColor: theme.hoverInputStrokeColor
    },

    '&:hover': {
      backgroundColor: `${theme.inputFillFocusColor} !important`,
      boxShadow: `inset 0 0 2px ${theme.accentColor}`
    }
  },
  valueAdaptedInfoIcon: {
    width: 29,
    height: 29,

    '& path': {
      fill: theme.primaryColor
    },

    '& circle': {
      stroke: theme.accentColor,
      fill: theme.accentColor
    }
  },
  valueAdaptedCloseButton: {
    cursor: 'pointer',
    fill: theme.primaryColor,
    opacity: 0,
    transition: 'all 0.2s',

    '$root:hover &': {
      opacity: 1,
      marginRight: 6,
      width: 15,
      height: 15
    }
  }
}));

type Props = {
  title: string;
  icon: string;
  selected: boolean;
  id: string;
  onChange: (id: string) => void;
  afterChange?: (id: string) => void;
  disabled?: boolean;
  isValueAdaptedIconVisible?: boolean;
  onCloseValueAdapted?: () => void;
};

const Card = ({
  title,
  icon,
  selected,
  id,
  onChange,
  afterChange,
  disabled,
  isValueAdaptedIconVisible,
  onCloseValueAdapted
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();

  const handleCloseValueAdapted: React.MouseEventHandler<
    HTMLDivElement
  > = e => {
    e.stopPropagation();

    onCloseValueAdapted?.();
  };

  return (
    <div
      className={classNames(classes.root, {
        [classes.selected]: selected,
        [classes.disabled]: disabled
      })}
      onClick={() => {
        onChange(id);
        afterChange?.(id);
      }}
    >
      <CheckmarkIcon
        className={classNames(classes.checkmark, {
          [classes.checkmarkSelected]: selected,
          [classes.checkmarkDisabled]: disabled
        })}
      />

      {isValueAdaptedIconVisible && (
        <Tooltip
          content={i18n('roboAdvice.sustainability.markedAsAdaptedTooltip')}
          triggerButtonStyles={{
            width: 70
          }}
          trigger={
            <div
              className={classes.valueAdaptedContainer}
              onClick={handleCloseValueAdapted}
            >
              <InfoOutlineIcon className={classes.valueAdaptedInfoIcon} />
              <CrossIcon className={classes.valueAdaptedCloseButton} />
            </div>
          }
        />
      )}

      {icon && (
        <div className={classes.icon}>
          <img src={processImgURL(icon)} alt={title} />
        </div>
      )}
      <div className={icon ? classes.title : classes.centeredTitle}>
        {title}
      </div>
    </div>
  );
};

export default Card;
