import { PortfolioChart } from '@quantfoliorepo/ui-components';
import { isEmpty } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

import { PortfolioType } from 'features/roboAdvice/adviceSession/portfolioChart';
import ButtonGroup from 'features/shared/components/buttonGroup';
import LoadingIndicator from 'features/shared/components/loadingIndicator/loadingIndicator';
import { PortfolioChartDimensions } from 'features/shared/constants/chart';
import { Spacing } from 'features/shared/constants/spacing';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import {
  useTheme,
  createUseStyles
} from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  noDataMessage: {
    padding: `${Spacing.spacing02}px 0`,
    color: theme.secondaryColor
  },
  body: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: Spacing.spacing02,
    paddingTop: Spacing.spacing02,
    width: '100%',
    justifyContent: 'center'
  }
}));

export const PortfolioChartsTabs = {
  broadAssetAllocation: 0,
  assetAllocation: 1,
  fundAllocation: 2
} as const;

export type Asset = {
  id: string;
  name: string;
  weight: number;
  isin?: string | null;
  color?: string | null;
  CategoryId?: string | null;
};

export type PortfolioChartData = {
  broadAssetAllocation?: Asset[];
  assetAllocation?: Asset[];
  fundAllocation?: Asset[];
  id: string;
  title?: string;
  icon?: string;
  portfolioType?: keyof typeof PortfolioType;
  allowEmpty?: boolean;
};

type Props = {
  portfolioCharts: PortfolioChartData[];
  alignTitleBottom?: boolean;
  isVertical?: boolean;
  isLoading?: boolean;
  tabs?: number[];
};

const PortfolioChartCards = ({
  portfolioCharts,
  alignTitleBottom = true,
  isVertical = false,
  isLoading = false,
  tabs = [...Object.values(PortfolioChartsTabs)]
}: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const i18n = useI18n();
  const [chartIndex, setChartIndex] = React.useState(0);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isEmpty(portfolioCharts)) {
    return (
      <div className={classes.noDataMessage}>
        {i18n('roboAdvice.proposal.NoPortfolioData')}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <ButtonGroup
        items={[
          {
            title: i18n('roboAdvice.proposal.broadAssetAllocation'),
            isActive: chartIndex === PortfolioChartsTabs.broadAssetAllocation,
            onClick: () =>
              setChartIndex(PortfolioChartsTabs.broadAssetAllocation),
            key: PortfolioChartsTabs.broadAssetAllocation
          },
          {
            title: i18n(
              'roboAdvice.advisory.customPortfolio.tabs.assetClassAllocation'
            ),
            isActive: chartIndex === PortfolioChartsTabs.assetAllocation,
            onClick: () => setChartIndex(PortfolioChartsTabs.assetAllocation),
            key: PortfolioChartsTabs.assetAllocation
          },
          {
            title: i18n('roboAdvice.proposal.fundAllocation'),
            isActive: chartIndex === PortfolioChartsTabs.fundAllocation,
            onClick: () => setChartIndex(PortfolioChartsTabs.fundAllocation),
            key: PortfolioChartsTabs.fundAllocation
          }
        ].filter(({ key }) => tabs.includes(key))}
      />

      <div className={classes.body}>
        {portfolioCharts.map(
          ({
            id,
            icon,
            title,
            broadAssetAllocation,
            assetAllocation,
            fundAllocation,
            allowEmpty
          }) => {
            const chartData: Asset[] =
              (chartIndex === PortfolioChartsTabs.broadAssetAllocation &&
                broadAssetAllocation) ||
              (chartIndex === PortfolioChartsTabs.assetAllocation &&
                assetAllocation) ||
              (chartIndex === PortfolioChartsTabs.fundAllocation &&
                fundAllocation) ||
              [];

            if (chartData.length === 0 && !allowEmpty) return null;

            return (
              <PortfolioChart
                key={id}
                alignTitleBottom={alignTitleBottom}
                backgroundSpacingColor={theme.boxBackgroundColor_1}
                chartData={chartData}
                cultureCode={cultureCode}
                dataLabelsColor={theme.primaryColor}
                height={PortfolioChartDimensions.height}
                iconName={icon}
                isVertical={isVertical}
                title={title}
                width={PortfolioChartDimensions.width}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default PortfolioChartCards;
