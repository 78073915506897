import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { profileForm } from '../services/profileForm';
import sessionSelectors from 'features/shared/services/session/selectors';
import { CurrentUser } from 'features/shared/services/session/types';

const useInitPage = () => {
  const currentUser: CurrentUser = useSelector(sessionSelectors.getCurrentUser);

  useEffect(() => {
    profileForm.initialize({
      name: currentUser.name,
      email: currentUser.email,
      bio: currentUser.bio,
      phone: currentUser.phone,
      timezone: currentUser.timezone
    });

    return () => {
      profileForm.reset();
    };
  }, [currentUser]);
};

export default useInitPage;
