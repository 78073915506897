import axios from 'axios';
import { isNil, pick } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import { updateTranslations } from '../api';
import { form } from '../services/translationsForm';
import { useTranslationsStore } from '../services/translationsStore';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

const useSaveTranslations = () => {
  const dispatch = useDispatch();
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);

  const saveTranslations = async (language = 'en') => {
    const translationsStore = useTranslationsStore.getState();
    const { values, initialValues } = form.getState();

    try {
      translationsStore.setIsSaveTranslationsPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        undefined
      );

      const changedFieldsNames = Object.keys(values).filter(
        key => values[key].value !== initialValues[key].value
      );
      const changedValues = pick(changedFieldsNames, values);
      const payload = createTranslationsPayload(changedValues, language);

      await updateTranslations(accessToken, payload);

      translationsStore.updateTranslations(payload);

      translationsStore.setIsSaveTranslationsPending(false);

      form.initialize(values);

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n(
            'admin.appAdmin.advisorSolution.text.saveTranslationsSuccessMessage'
          ),
          type: NotificationTypes.success
        })
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        translationsStore.setIsSaveTranslationsPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'admin.appAdmin.advisorSolution.text.saveTranslationsErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return saveTranslations;
};

export default useSaveTranslations;

const createTranslationsPayload = (values, language) => {
  const regex = /-/g;
  return Object.entries<{ value: string }>(values).map(([key, { value }]) => {
    const payload = {
      application: 'deepalpha',
      language,
      name: key.replace(regex, '.')
    };
    return isNil(value) || value === '' ? payload : { ...payload, value };
  });
};
