import { FormValues, Sections } from '../form/types';
import { useTemplateStore } from '../templateStore';
import {
  getCurrentDateDay,
  getCurrentDateFullYear,
  getCurrentDateMonth,
  getCurrentDateYear
} from './utils';
import { useSessionStore } from 'features/roboAdvice/adviceSession/session/services/sessionStore';

type Attachment = {
  filename: string;
  template_id: string;
  type: string;
  language?: string;
};

export type Payload = {
  language: string;
  template_id: string;
  filename: string;
  product_attachments: boolean;
  sections?: Sections;
  attachments?: Attachment[];
};

const useBuildPayload = () => {
  const { template } = useTemplateStore();
  const { clientName } = useSessionStore();

  const buildPayload = (formValues: FormValues): Payload | null => {
    if (!template) {
      return null;
    }

    const currentDate = new Date();

    const templateFilename = template.filename
      .replace('{{investorName}}', clientName as string)
      .replace('{{dd}}', getCurrentDateDay(currentDate))
      .replace('{{mm}}', getCurrentDateMonth(currentDate))
      .replace('{{yy}}', getCurrentDateYear(currentDate))
      .replace('{{yyyy}}', getCurrentDateFullYear(currentDate));

    return {
      language: template.language,
      template_id: template.id,
      filename: templateFilename,

      product_attachments: Boolean(formValues.attachments.productAttachments),

      sections: formValues.sections,
      attachments: template.attachments
        .filter(
          attachment =>
            formValues.attachments[attachment.id] &&
            attachment.id !== 'productAttachments'
        )
        .map(({ filename, id, type, language }) => ({
          ...(type === 'dynamic' && { language }),
          filename,
          template_id: id,
          type
        }))
    };
  };

  return buildPayload;
};

export default useBuildPayload;
