import * as R from 'ramda';
import React from 'react';
import { ReactReduxContext } from 'react-redux';
import { Prompt as PromptRR } from 'react-router';
import { getFormValues, getFormInitialValues } from 'redux-form';

import formSelectors from 'features/shared/services/form/selectors.js';

const Prompt = () => {
  return (
    <ReactReduxContext.Consumer>
      {({ store }) => {
        return (
          <PromptRR
            when={true}
            message={() => {
              const state = store.getState();
              const formValues = getFormValues(
                'editCustomerForm_state',
                formSelectors.getForms
              )(state);
              const formInitialValues = getFormInitialValues(
                'editCustomerForm_state',
                formSelectors.getForms
              )(state);

              if (R.isNil(formValues) && R.isNil(formInitialValues)) {
                return null;
              }

              if (R.isNil(formValues) || R.isNil(formInitialValues)) {
                return 'confirmation required';
              }

              const isAnyFieldChanged =
                formValues.title !== formInitialValues.title ||
                formValues.code !== formInitialValues.code ||
                formValues.advisoryKey !== formInitialValues.advisoryKey ||
                !R.equals(formValues.users, formInitialValues.users) ||
                !R.equals(formValues.licenses, formInitialValues.licenses);

              return isAnyFieldChanged ? 'confirmation required' : null;
            }}
          />
        );
      }}
    </ReactReduxContext.Consumer>
  );
};

export default Prompt;
