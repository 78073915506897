import React from 'react';

import { TimeHorizonTypes } from '../../constants';
import { goalForm, useGoalForm } from '../../services/goalForm';
import { useIsSessionReadOnly } from 'features/roboAdvice/adviceSession/main/services/selectors';
import {
  WithdrawalPlans,
  WithdrawalPlansClassToTitle
} from 'features/roboAdvice/adviceSession/shared/constants';
import { Layouts } from 'features/roboAdvice/shared/constants';
import ConfigurableLabel from 'features/shared/components/configurableLabel/';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import FinalFormRadios from 'features/sharedModules/finalForm/components/radios';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  label: {
    marginBottom: Spacing.spacing01,
    lineHeight: Typography.body1.lineHeight
  },
  withdrawalPlan: {
    marginBottom: Spacing.spacing01
  },
  radioButtonsLabel: {
    gap: Spacing.spacing00
  }
});

const WithdrawalPlan = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const isSessionReadOnly = useIsSessionReadOnly();
  const { timeHorizonConfig } = useCustomerConfig();
  const { values } = useGoalForm();

  const withdrawalPlans = [
    {
      activeValue: WithdrawalPlans.monthlyWithdrawal,
      label: i18n(
        WithdrawalPlansClassToTitle[WithdrawalPlans.monthlyWithdrawal]
      )
    },
    {
      activeValue: WithdrawalPlans.oneTimeWithdrawal,
      label: i18n(
        WithdrawalPlansClassToTitle[WithdrawalPlans.oneTimeWithdrawal]
      )
    },
    {
      activeValue: WithdrawalPlans.noPlannedWithdrawal,
      label: i18n(
        WithdrawalPlansClassToTitle[WithdrawalPlans.noPlannedWithdrawal]
      )
    }
  ];

  React.useEffect(() => {
    if (
      values.data?.withdrawalPlan === WithdrawalPlans.noPlannedWithdrawal &&
      !values.data?.timeHorizon
    ) {
      goalForm.change(
        'data.timeHorizon',
        timeHorizonConfig.type === TimeHorizonTypes.slider
          ? Math.max(
              ...timeHorizonConfig.items.map(
                ({ yearIntervalStop }) => yearIntervalStop
              )
            )
          : Math.max(
              ...timeHorizonConfig.items.map(
                ({ riskHorizonValue }) => riskHorizonValue
              )
            )
      );
    }
  }, [
    timeHorizonConfig.items,
    timeHorizonConfig.type,
    values.data?.withdrawalPlan
  ]);

  return (
    <div className={classes.withdrawalPlan}>
      <div className={classes.label}>
        <ConfigurableLabel
          required
          label={i18n('roboAdvice.purposeAndRisk.withdrawalPlan')}
        />
      </div>

      <FinalFormRadios
        name={'data.withdrawalPlan'}
        data-testid={'withdrawal-plan'}
        items={withdrawalPlans}
        layout={Layouts.vertical}
        disabled={isSessionReadOnly}
        labelClassName={classes.radioButtonsLabel}
      />
    </div>
  );
};

export default WithdrawalPlan;
