import { clientsTable } from '../../clientsTable';
import { RoboAdviceClientsTableOrderKeys } from '../constants';
import { useReadClientsList } from './useReadClientsList';
import { OrderDirections } from 'features/shared/constants/table';

export function useInitPage() {
  const readClientsList = useReadClientsList();

  const initPage = () => {
    const clientsTableStoreState = clientsTable.useTableStore.getState();

    clientsTableStoreState.initialize();
    clientsTableStoreState.changeOrder(
      RoboAdviceClientsTableOrderKeys.name,
      OrderDirections.asc
    );

    readClientsList();
  };

  return initPage;
}
