import { z } from 'zod';

import { pageSchema } from '../shared/types';

export const modalOptionsSchema = z.object({
  enabled: z.boolean(),
  pages: z.array(pageSchema)
});

export type ModalOptions = z.infer<typeof modalOptionsSchema>;

export const educationalModalsConfigSchema = z.object({
  enabled: z.boolean(),
  alignmentCriteria1: modalOptionsSchema.optional(),
  alignmentCriteria2: modalOptionsSchema.optional(),
  alignmentCriteria3: modalOptionsSchema.optional(),
  alignmentCriteria4: modalOptionsSchema.optional(),
  alignmentCriteria5: modalOptionsSchema.optional(),
  preferenceCriteria: modalOptionsSchema.optional(),
  genericAssessment: modalOptionsSchema.optional()
});

export type EducationalModalsConfig = z.infer<
  typeof educationalModalsConfigSchema
>;
