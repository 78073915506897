import classNames from 'classnames';
import React from 'react';

import SimpleTable from '../../components/table/simpleTable';
import { ReactComponent as CheckIcon } from 'assets/checkmark2.svg';
import { ReactComponent as CrossIcon } from 'assets/x-icon.svg';
import {
  FundNameForPresentation,
  ProductAttributeType
} from 'features/roboAdvice/adviceSession/shared/constants';
import { CustomAttribute } from 'features/roboAdvice/adviceSession/shared/services/adviceSessionStore';
import { Goal } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import {
  FundAnalysis,
  SustainabilityData
} from 'features/roboAdvice/adviceSession/sustainability';
import { Spacing } from 'features/shared/constants/spacing';
import { FontWeights } from 'features/shared/constants/typography';
import { formatNumber } from 'features/shared/utils/number';
import { getTranslation } from 'features/shared/utils/translations';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  center: {
    textAlign: 'center'
  },
  right: {
    textAlign: 'right'
  },
  headerCell: {
    padding: '20px 16px'
  },
  cell: {
    padding: '17.5px 16px'
  },
  portfolioRow: {
    backgroundColor: `${theme.inputFillFocusColor} !important`,
    fontWeight: FontWeights.medium,
    lineHeight: '19px'
  },
  preferenceRow: {
    backgroundColor: `${theme.accentFocusColor} !important`,
    fontWeight: FontWeights.medium,
    lineHeight: '19px'
  },
  table: {
    marginTop: 32
  },
  checkIcon: {
    fill: theme.successColor
  },
  crossIcon: {
    fill: theme.errorNegativeColor
  },
  noData: {
    color: theme.secondaryColor,
    marginTop: Spacing.spacing02
  }
}));

type Props = {
  alignmentValues: {
    name: string;
    title: string;
    preferenceScore: number | undefined;
  }[];
  cultureCode: string;
  fundAnalysisData: FundAnalysis[];
  goals: Goal[];
  selectedGoalsIds: string[];
  sustainabilityData: SustainabilityData[];
  totalValue: number;
  tableCustomAttributes?: {
    type: keyof typeof ProductAttributeType;
    label: Record<string, string>;
    name: string;
  }[];
  customAttributesData?: Record<number, CustomAttribute[]>;
  tableClassName?: string;
};

const StandardTable = ({
  alignmentValues,
  cultureCode,
  fundAnalysisData,
  goals,
  selectedGoalsIds,
  sustainabilityData,
  totalValue,
  tableCustomAttributes,
  customAttributesData,
  tableClassName
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const i18n = useI18n();
  const {
    tenantSettings: { fundNameForPresentation }
  } = useCustomerConfig();

  const portfolioScore = React.useMemo(() => {
    if (selectedGoalsIds.length === 1) {
      const data = sustainabilityData.find(({ goalId }) =>
        selectedGoalsIds.includes(goalId)
      );
      return alignmentValues.map(({ name }) => ({
        name: name,
        score: data?.data.portfolioAnalysis.alignmentValuesWeighted[name],
        status: data?.data.portfolioAnalysis.alignmentStatus[name]
      }));
    }

    const data = sustainabilityData.filter(({ goalId }) =>
      selectedGoalsIds.includes(goalId)
    );

    return alignmentValues
      .map(({ name }) =>
        data
          .map(d => {
            const goal = goals.find(
              ({ goalId }) => goalId === d.goalId
            ) as Goal;
            const value =
              goal.data.firstDeposit || goal.data.monthlyDeposit || 0;

            return {
              score:
                d.data.portfolioAnalysis.alignmentValuesWeighted[name]! *
                (value / totalValue),
              name
            };
          })
          .reduce((acc, curr) => ({ ...acc, score: acc.score + curr.score }), {
            score: 0,
            name
          })
      )
      .map(value => ({
        ...value,
        status:
          value.score >=
          (alignmentValues.find(({ name }) => name === value.name)
            ?.preferenceScore || 0)
      }));
  }, [
    alignmentValues,
    goals,
    selectedGoalsIds,
    sustainabilityData,
    totalValue
  ]);

  const allCustomAttributesData = React.useMemo(
    () =>
      Object.values(customAttributesData || {})
        .flat()
        .reduce((acc, curr) => {
          const index = acc.findIndex(
            ({ ticker, isin }) =>
              (isin && isin === curr.isin) || (ticker && ticker === curr.ticker)
          );
          if (index !== -1) {
            return acc;
          }
          return [...acc, curr];
        }, [] as CustomAttribute[]),
    [customAttributesData]
  );

  if (!alignmentValues.length && !tableCustomAttributes?.length) {
    return (
      <div className={classes.noData}>
        {i18n('roboAdvice.proposal.sustainability.noDataAvailable')}
      </div>
    );
  }

  return (
    <SimpleTable
      className={classNames(classes.table, tableClassName)}
      headers={({ headerCellClassName }) => (
        <>
          <th className={classNames(headerCellClassName, classes.headerCell)}>
            {i18n('roboAdvice.proposal.sustainability.weightedTable.fund')}
          </th>

          {alignmentValues.map(({ title }) => (
            <th
              key={title}
              className={classNames(
                headerCellClassName,
                classes.headerCell,
                classes.center
              )}
            >
              {title}
            </th>
          ))}

          {tableCustomAttributes?.map(({ label, name, type }) => (
            <th
              key={name}
              className={classNames(headerCellClassName, classes.headerCell, {
                [classes.center]: type === ProductAttributeType.binary,
                [classes.right]: type === ProductAttributeType.text
              })}
            >
              {getTranslation(label)}
            </th>
          ))}
        </>
      )}
      body={({ rowClassName, cellClassName }) => (
        <>
          {fundAnalysisData.map(
            ({
              fundStandardName,
              name,
              isin,
              alignmentCriteria,
              alignmentCriteriaStatus,
              ticker
            }) => (
              <tr key={isin} className={rowClassName}>
                <td className={classNames(cellClassName, classes.cell)}>
                  {fundNameForPresentation ===
                    FundNameForPresentation.fundStandardName && fundStandardName
                    ? fundStandardName
                    : name}
                </td>
                {alignmentValues.map(({ name }) => (
                  <td
                    key={name}
                    className={classNames(
                      cellClassName,
                      classes.cell,
                      classes.center
                    )}
                    style={{
                      color: alignmentCriteriaStatus[name]
                        ? theme.successColor
                        : theme.errorNegativeColor
                    }}
                  >
                    {formatNumber(cultureCode, alignmentCriteria[name], 0, 0)}
                  </td>
                ))}

                {tableCustomAttributes?.map(({ type, name }) => {
                  const customAttributeData = allCustomAttributesData?.find(
                    ({ ticker: customAttributeTicker }) =>
                      customAttributeTicker === ticker
                  );

                  if (type === ProductAttributeType.text) {
                    return (
                      <td
                        className={classNames(
                          cellClassName,
                          classes.cell,
                          classes.right
                        )}
                        key={name}
                      >
                        {customAttributeData?.productAttributeText?.[name]}
                      </td>
                    );
                  }

                  if (type === ProductAttributeType.binary) {
                    return (
                      <td
                        className={classNames(
                          cellClassName,
                          classes.cell,
                          classes.center
                        )}
                        key={name}
                      >
                        {customAttributeData?.productAttributeBinary?.[name] ? (
                          <CheckIcon className={classes.checkIcon} />
                        ) : (
                          <CrossIcon className={classes.crossIcon} />
                        )}
                      </td>
                    );
                  }

                  return null;
                })}
              </tr>
            )
          )}

          {!!alignmentValues.length && (
            <tr className={classNames(rowClassName, classes.portfolioRow)}>
              <td className={classNames(cellClassName, classes.cell)}>
                {i18n(
                  'roboAdvice.proposal.sustainability.weightedTable.portfolioScore'
                )}
              </td>

              {portfolioScore.map(({ score, status, name }) => (
                <td
                  key={name}
                  className={classNames(
                    cellClassName,
                    classes.cell,
                    classes.center
                  )}
                  style={{
                    color: status
                      ? theme.successColor
                      : theme.errorNegativeColor
                  }}
                >
                  {formatNumber(cultureCode, score, 2, 2)}
                </td>
              ))}

              {tableCustomAttributes?.map(({ name }) => (
                <td
                  key={name}
                  className={classNames(
                    cellClassName,
                    classes.cell,
                    classes.center
                  )}
                ></td>
              ))}
            </tr>
          )}

          {!!alignmentValues.length && (
            <tr className={classNames(rowClassName, classes.preferenceRow)}>
              <td className={classNames(cellClassName, classes.cell)}>
                {i18n(
                  'roboAdvice.proposal.sustainability.standardTable.myPreference'
                )}
              </td>

              {alignmentValues.map(({ name, preferenceScore }) => (
                <td
                  key={name}
                  className={classNames(
                    cellClassName,
                    classes.cell,
                    classes.center
                  )}
                >
                  {formatNumber(cultureCode, preferenceScore, 0, 0)}
                </td>
              ))}

              {tableCustomAttributes?.map(({ name }) => (
                <td
                  key={name}
                  className={classNames(
                    cellClassName,
                    classes.cell,
                    classes.center
                  )}
                ></td>
              ))}
            </tr>
          )}
        </>
      )}
    />
  );
};

export default StandardTable;
