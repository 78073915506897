import { takeEvery, put, call, all } from 'redux-saga/effects';

import { types as routerActionTypes } from 'framework/router/services/actions.js';

function* onLocationInit({ actions, selectors }, action) {
  const { payload } = action;

  const isPageOpened = yield call(selectors.getIsPageOpened, payload);

  if (isPageOpened) {
    yield put(actions.creators.opened());
  }
}

function* onLocationCleanup({ actions, selectors }, action) {
  const { payload } = action;

  const isPageClosed = yield call(selectors.getIsPageClosed, payload);

  if (isPageClosed) {
    yield put(actions.creators.closed());
  }
}

export default ({ actions, selectors }) =>
  function* () {
    yield all([
      takeEvery(routerActionTypes.LOCATION_INIT, onLocationInit, {
        actions,
        selectors
      }),
      takeEvery(routerActionTypes.LOCATION_CLEANUP, onLocationCleanup, {
        actions,
        selectors
      })
    ]);
  };
