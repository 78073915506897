import * as R from 'ramda';
import * as React from 'react';

import { getErrorText } from './shared.js';
import { useField } from './useField';
import ColorInput from 'features/shared/components/colorInput/colorInput';
import ErrorMessage from 'features/shared/components/errorMessage/index.js';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  errorMessage: {
    textAlign: 'right'
  }
});

const FinalFormColorInput = ({
  name,
  fieldConfig,
  ...restProps
}: {
  name: string;
  fieldConfig?: any;
  [key: string]: any;
}) => {
  const classes = useStyles();
  const i18n = useI18n();
  const {
    input: { value, onChange, onFocus, onBlur },
    meta: { error, touched }
  } = useField(name, fieldConfig);

  const isErrorShown = touched && !R.isNil(error);
  const errorText = getErrorText(i18n, error);

  return (
    <div>
      <ColorInput
        value={value || ''}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        isInvalid={isErrorShown}
        {...restProps}
      />
      {isErrorShown && (
        <ErrorMessage
          className={classes.errorMessage}
        >{`* ${errorText}`}</ErrorMessage>
      )}
    </div>
  );
};

export default FinalFormColorInput;
