import { useSelector } from 'react-redux';

import {
  useHolisticViewStore,
  selectors
} from '../../../services/holisticViewStore';
import { useReadEfficientFrontierChartData } from './useReadEfficientFrontierChartData';
import { useEfficientFrontierStore } from 'features/roboAdvice/adviceSession/efficientFrontierChart';
import {
  useReadDataListener,
  PageStatuses
} from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import EfficientFrontierChartVisual from 'features/shared/analyticsComponents/efficientFrontierChart/visual';
import sessionSelectors from 'features/shared/services/session/selectors';

const EfficientFrontierChart = ({ isSelected }) => {
  const { efficientFrontierChart } = useEfficientFrontierStore();
  const selectedGoalsIds = useHolisticViewStore(selectors.getSelectedGoalsIds);
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const { proposalSectionStatuses } = useHolisticViewStore();

  useReadDataListener(
    proposalSectionStatuses.readEfficientFrontierChartData,
    isSelected,
    useReadEfficientFrontierChartData()
  );

  return (
    <EfficientFrontierChartVisual
      efficientFrontierChart={efficientFrontierChart}
      selectedGoalsIds={selectedGoalsIds}
      cultureCode={cultureCode}
      isLoading={
        useHolisticViewStore.getState().proposalSectionStatuses
          .readEfficientFrontierChartData === PageStatuses.pending
      }
    />
  );
};

export default EfficientFrontierChart;
