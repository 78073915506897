import { isNil } from 'ramda';
import { useEffect } from 'react';

import { useAutomaticClientClassificationStore } from '../../services/automaticClientClassificationStore';
import {
  InvestorTypes,
  NONE_OF_THE_ABOVE_ID,
  NUMBER_OF_CHECKBOXES_TO_SELECT
} from './constants';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import {
  clientForm,
  ClientFormValues,
  useClientForm
} from 'features/roboAdvice/client/main/services/clientForm';
import { useClientPageStore } from 'features/roboAdvice/client/main/services/clientPageStore';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export const useInitAutomaticClientClassification = () => {
  const i18n = useI18n();
  const { readClientInformationStatus } = useClientPageStore();
  const { values: clientFormValues } = useClientForm();
  const { setFormStateConfirmation, setIsConfirmationModalOpen } =
    useAutomaticClientClassificationStore();

  useEffect(() => {
    if (readClientInformationStatus !== PageStatuses.succeed) {
      return;
    }

    let oldCheckboxesValue: string[] | undefined = Array.isArray(
      clientFormValues?.kyc?.automaticClientClassification?.answer
    )
      ? clientFormValues?.kyc?.automaticClientClassification?.answer
      : [];
    let oldClientClassificationId: string | null | undefined =
      clientFormValues?.kyc?.automaticClientClassification
        ?.clientClassificationId;
    let oldAnswerValue: string | string[] | undefined =
      clientFormValues?.kyc?.automaticClientClassification?.answer;

    return clientForm.subscribe(
      ({ values }) => {
        const {
          answer,
          clientClassificationId: newClientClassificationId,
          isDecisionOverwritten
        } = values?.kyc?.automaticClientClassification || {};
        const noneOfTheAboveAnswer = i18n(
          'roboAdvice.kyc.automaticClientClassification.noneOfTheAbove'
        );

        // Check for overwrite from modal
        if (
          isDecisionOverwritten &&
          oldAnswerValue === answer &&
          oldClientClassificationId === newClientClassificationId
        ) {
          return;
        } else if (
          isDecisionOverwritten &&
          (oldAnswerValue !== answer ||
            oldClientClassificationId !== newClientClassificationId)
        ) {
          // If decision is overwritten and user wants to change the answer
          // we do not change values, save new form state and display confirmation modal
          setFormStateConfirmation(values);
          clientForm.batch(() => {
            clientForm.change(
              'kyc.automaticClientClassification.answer' as any,
              oldAnswerValue
            );
            clientForm.change(
              'kyc.automaticClientClassification.clientClassificationId' as any,
              oldClientClassificationId
            );
          });
          setIsConfirmationModalOpen(true);
          return;
        } else {
          clientForm.batch(() => {
            clientForm.change(
              'kyc.automaticClientClassification.isDecisionOverwritten' as any,
              false
            );
            clientForm.change(
              'kyc.automaticClientClassification.reclassificationRequestDocumentation' as keyof ClientFormValues,
              undefined
            );
            clientForm.resetFieldState(
              'kyc.automaticClientClassification.investorType' as keyof ClientFormValues
            );
          });
        }

        // Logic for selecting proper investor type based on answer
        if (newClientClassificationId === NONE_OF_THE_ABOVE_ID) {
          clientForm.change(
            'kyc.automaticClientClassification.investorType' as any,
            InvestorTypes.nonProfessionalInvestor
          );
        } else if (isNil(answer)) {
          clientForm.change(
            'kyc.automaticClientClassification.investorType' as keyof ClientFormValues,
            null
          );
        } else {
          if (!Array.isArray(answer)) {
            if (answer === noneOfTheAboveAnswer) {
              clientForm.change(
                'kyc.automaticClientClassification.investorType' as any,
                InvestorTypes.nonProfessionalInvestor
              );
            } else {
              clientForm.change(
                'kyc.automaticClientClassification.investorType' as any,
                InvestorTypes.professionalInvestor
              );
            }
          } else {
            if (answer.includes(noneOfTheAboveAnswer)) {
              clientForm.change(
                'kyc.automaticClientClassification.investorType' as any,
                InvestorTypes.nonProfessionalInvestor
              );
            } else if (answer.length >= NUMBER_OF_CHECKBOXES_TO_SELECT) {
              clientForm.change(
                'kyc.automaticClientClassification.investorType' as any,
                InvestorTypes.professionalInvestor
              );
            } else if (answer.length > 0) {
              clientForm.change(
                'kyc.automaticClientClassification.investorType' as any,
                InvestorTypes.nonProfessionalInvestor
              );
            } else {
              clientForm.change(
                'kyc.automaticClientClassification.investorType' as keyof ClientFormValues,
                null
              );
            }
          }
        }

        // Logic for unselecting all other checkboxes when 'None of the above' is selected
        if (answer && Array.isArray(answer)) {
          const selectedCheckbox = answer.filter(
            item => !oldCheckboxesValue?.includes(item)
          );

          if (
            selectedCheckbox.length === 1 &&
            selectedCheckbox[0] === noneOfTheAboveAnswer
          ) {
            clientForm.change(
              'kyc.automaticClientClassification.answer' as keyof ClientFormValues,
              [noneOfTheAboveAnswer]
            );
          } else if (
            selectedCheckbox.length === 1 &&
            selectedCheckbox[0] !== noneOfTheAboveAnswer &&
            answer.includes(noneOfTheAboveAnswer)
          ) {
            clientForm.change(
              'kyc.automaticClientClassification.answer' as keyof ClientFormValues,
              answer.filter(item => item !== noneOfTheAboveAnswer)
            );
          }

          oldCheckboxesValue = answer;
        }

        // Logic to reset answer when clientClassificationId is changed
        if (oldClientClassificationId !== newClientClassificationId) {
          clientForm.change(
            'kyc.automaticClientClassification.answer' as keyof ClientFormValues,
            undefined
          );

          oldClientClassificationId = newClientClassificationId;
        }

        oldAnswerValue = answer;
      },
      { values: true }
    );
  }, [readClientInformationStatus]);
};
