import { useEffect } from 'react';

import {
  preMeetingSurveyForm,
  usePreMeetingSurveyForm
} from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/form';
import { useReadInvestorInformation } from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/useReadInvestorInformation';
import { usePreMeetingSurveyStore } from 'features/roboAdvice/client/adviceSessionsList/services/preMeetingSurveyStore';
import { FlowType } from 'features/roboAdvice/client/main/types';
import { getTranslation } from 'features/shared/utils/translations';

type Params = {
  selectedFlowType: FlowType | null;
  isOpen: boolean;
};

const useInitPreMeetingSurvey = ({ selectedFlowType, isOpen }: Params) => {
  const { values: preMeetingSurveyValues } = usePreMeetingSurveyForm();
  const readInvestorInformation = useReadInvestorInformation();

  useEffect(() => {
    if (isOpen) {
      readInvestorInformation();
    }
  }, [readInvestorInformation, isOpen]);

  useEffect(() => {
    let oldDoUseContactInformationFromInvestorCard: boolean | null = null;

    const unsubscribe = preMeetingSurveyForm.subscribe(
      ({ values }) => {
        const { investorInformationData } = usePreMeetingSurveyStore.getState();

        if (
          oldDoUseContactInformationFromInvestorCard !==
            values.doUseContactInformationFromInvestorCard &&
          investorInformationData
        ) {
          if (values.doUseContactInformationFromInvestorCard) {
            if (investorInformationData.phone) {
              preMeetingSurveyForm.change(
                'investorPhoneNumber',
                investorInformationData.phone
              );
            }
            if (investorInformationData.email) {
              preMeetingSurveyForm.change(
                'investorEmail',
                investorInformationData.email
              );
            }
          } else {
            preMeetingSurveyForm.change('investorPhoneNumber', '');
            preMeetingSurveyForm.change('investorEmail', '');
          }

          oldDoUseContactInformationFromInvestorCard =
            values.doUseContactInformationFromInvestorCard;
        }
      },
      { values: true }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    let oldIsSmsToCustomerEnabled: boolean | null = null;
    let oldIsEmailToCustomerEnabled: boolean | null = null;
    if (!selectedFlowType) {
      return;
    }

    const { surveyFlowData } = usePreMeetingSurveyStore.getState();

    const selectedSurveyFlow = surveyFlowData?.[selectedFlowType]?.find(
      flow => flow.id === preMeetingSurveyValues.selfServiceFlow
    );

    const unsubscribe = preMeetingSurveyForm.subscribe(
      ({ values }) => {
        if (
          oldIsSmsToCustomerEnabled !== values.isSmsToCustomerEnabled &&
          selectedSurveyFlow
        ) {
          preMeetingSurveyForm.change(
            'smsContent',
            getTranslation(selectedSurveyFlow.attributes.defaultSmsTemplate)
          );
          oldIsSmsToCustomerEnabled = values.isSmsToCustomerEnabled;
        }

        if (
          oldIsEmailToCustomerEnabled !== values.isEmailToCustomerEnabled &&
          selectedSurveyFlow
        ) {
          preMeetingSurveyForm.change(
            'emailContent',
            getTranslation(selectedSurveyFlow?.attributes.defaultEmailTemplate)
          );
          oldIsEmailToCustomerEnabled = values.isEmailToCustomerEnabled;
        }
      },
      { values: true }
    );

    return () => {
      unsubscribe();
    };
  }, [selectedFlowType, preMeetingSurveyValues.selfServiceFlow]);
};

export default useInitPreMeetingSurvey;
