import nb from 'date-fns/locale/nb';

import { WeekDayShortKeys, MonthShortKeys } from './constants';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export const useGetLocale = (dateFormat: string) => {
  const i18n = useI18n();

  const weekDays = WeekDayShortKeys.map(weekDayKey => i18n(weekDayKey));
  const months = MonthShortKeys.map(monthKey => i18n(monthKey));

  return {
    ...nb,
    localize: {
      day: n => weekDays[n],
      month: n => months[n]
    },
    formatLong: {
      date: () => dateFormat
    }
  };
};
