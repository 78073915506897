import React from 'react';
import jssInjectSheet, {
  createUseStyles as jssCreateUseStyles,
  createTheming,
  Styles
} from 'react-jss';

import { Theme } from 'defaults/defaultTheme';

const ThemeContext = React.createContext<Theme>({} as Theme);

const theming = createTheming(ThemeContext);

export const { ThemeProvider, useTheme } = theming;

export const createUseStyles = <C extends string = string, Props = unknown>(
  styles: Styles<C, Props> | ((theme: Theme) => Styles<C, Props, undefined>),
  options = {}
) =>
  jssCreateUseStyles(styles, {
    theming,
    ...options
  });

export const injectSheet = <ClassNames extends string | number | symbol, Props>(
  styles:
    | Styles<ClassNames, Props, Theme>
    | ((theme: Theme) => Styles<ClassNames, Props, undefined>),
  options
) =>
  jssInjectSheet(styles, {
    theming,
    ...options
  });
