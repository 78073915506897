import classNames from 'classnames';
import * as React from 'react';
import { useHistory, useParams } from 'react-router';

import { appAdminAdvisorSolutionConfigTypesValues } from '../constants';
import { appAdminConfigLocationsValues } from 'features/admin/appAdmin/main/constants';
import Icon from 'features/shared/components/icon/index.js';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

type Keys = keyof typeof appAdminAdvisorSolutionConfigTypesValues;
type Values = typeof appAdminAdvisorSolutionConfigTypesValues[Keys];
type ConfigType = {
  icon: string;
  text: string;
  value: Values;
};

const appAdminAdvisorSolutionConfigTypes: ConfigType[] = [
  {
    icon: 'photo',
    text: 'admin.appAdmin.advisorSolution.navigation.navItems.visuals',
    value: appAdminAdvisorSolutionConfigTypesValues.visuals
  },
  {
    icon: 'text_fields',
    text: 'admin.appAdmin.advisorSolution.navigation.navItems.text',
    value: appAdminAdvisorSolutionConfigTypesValues.text
  },
  {
    icon: 'visibility',
    text: 'admin.appAdmin.advisorSolution.navigation.navItems.preview',
    value: appAdminAdvisorSolutionConfigTypesValues.preview
  },
  {
    icon: 'difference',
    text: 'admin.appAdmin.advisorSolution.navigation.navItems.proposal',
    value: appAdminAdvisorSolutionConfigTypesValues.proposal
  }
];

const useStyles = createUseStyles(theme => ({
  nav: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '50px'
  },
  navItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    lineHeight: '19px',
    marginBottom: '30px',
    height: '61px',
    cursor: 'pointer'
  },
  icon: {
    color: theme.secondaryColor,
    fontSize: '36px',

    '$active > &': {
      color: theme.primaryColor
    }
  },
  text: {
    color: theme.secondaryColor
  },
  active: {}
}));

const Navigation = () => {
  const history = useHistory();
  const { configType } = useParams<any>();
  const classes = useStyles();
  const i18n = useI18n();

  const onConfigTypeChange = value => {
    history.push(
      routeTemplates.appAdmin.build(
        appAdminConfigLocationsValues.advisorSolution,
        value
      )
    );
  };

  return (
    <div className={classes.nav}>
      {appAdminAdvisorSolutionConfigTypes.map(({ icon, text, value }) => (
        <div
          className={classNames(classes.navItem, {
            [classes.active]: configType === value
          })}
          key={text}
          onClick={() => onConfigTypeChange(value)}
        >
          <Icon type={icon} className={classes.icon} />
          <span className={classes.text}>{i18n(text)}</span>
        </div>
      ))}
    </div>
  );
};

export default Navigation;
