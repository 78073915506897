import axios from 'axios';
import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';

import { postSettings, postLogo, deleteLogo } from '../../../shared/api';
import { Statuses } from '../../../shared/constants';
import { visualsForm } from '../services/visualsForm';
import { useVisualsStore } from '../services/visualsStore';
import store from 'features/main/app/store';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as applicationActionCreators } from 'features/shared/services/application/actions.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useUpdateSettings() {
  const i18n = useI18n();
  const auth0AccessToken: string = useSelector(
    sessionSelectors.getAuth0AccessToken
  );
  const customerCode: string = useSelector(
    sessionSelectors.getCurrentUserCustomerCode
  );
  const dispatch = useDispatch();
  const {
    styles: { logoSrc }
  } = useCustomerConfig();

  const updateSettings = async () => {
    const visualsStore = useVisualsStore.getState();

    try {
      visualsStore.setPageStatus('updateSettings', Statuses.pending);

      const { initialValues, values } = visualsForm.getState();

      const isNewLogoSelected = !R.isNil(values.logo?.file);
      const isLogoDeleted =
        R.isNil(values.logo) && !R.isNil(initialValues.logo);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        undefined
      );

      const [postSettingsResponse, postLogoResponse] = await Promise.all([
        postSettings(
          accessToken,
          undefined,
          Object.keys(values)
            .filter(key => key !== 'logo')
            .map(key => ({
              application: 'deepalpha',
              name: `styles.${key}`,
              value: values[key]
            }))
        ),
        isNewLogoSelected
          ? postLogo(
              accessToken,
              undefined,
              customerCode,
              values.logo?.file as File
            )
          : undefined,
        isLogoDeleted
          ? deleteLogo(accessToken, undefined, customerCode, logoSrc)
          : undefined
      ]);

      if (!postSettingsResponse.data.ok) {
        throw new Error(postSettingsResponse.data.error);
      }

      visualsForm.initialize({
        ...values,
        logo: isNewLogoSelected ? { url: postLogoResponse?.data } : values.logo
      });

      visualsStore.setPageStatus('updateSettings', Statuses.succeed);

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n(
            'admin.appAdmin.advisorSolution.visuals.updateSettingsSuccessMessage'
          ),
          type: NotificationTypes.success
        })
      );

      store.dispatch(applicationActionCreators.started());
    } catch (error) {
      if (!axios.isCancel(error)) {
        visualsStore.setPageStatus('updateSettings', Statuses.failed);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'admin.appAdmin.advisorSolution.visuals.updateSettingsErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return updateSettings;
}
