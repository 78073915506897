import { format, parseISO } from 'date-fns';
import { isNil, path } from 'ramda';

import { getTranslation } from 'features/shared/utils/translations';
import { FieldComponentTypes } from 'features/sharedModules/declarativeForm/constants';

export const mapServerSessionGoalToClient = ({
  description,
  name,
  icon,
  goal_id: goalId,
  session_id: sessionId,
  user_id: userId,
  data
}) => ({
  description,
  name,
  icon,
  goalId,
  sessionId,
  userId,
  data
});

export const getCustomFieldsValues = ({ fields, i18n, form }) =>
  fields.reduce(
    (acc, { name, order, label, componentType, items, dateFormat }) => {
      switch (componentType) {
        case FieldComponentTypes.dropdown:
        case FieldComponentTypes.radioButtons:
          const value = items.find(
            i => i.activeValue === path(name.split('.'), form.values)
          );

          const translatedValue = value ? getTranslation(value.label) : '';

          const foundTextFields = items
            .filter(
              i =>
                i.textField &&
                path(name.split('.'), form.values) === i.activeValue
            )
            .map(i => i.textField);

          acc.push({
            name,
            value:
              foundTextFields.length > 0
                ? `${translatedValue} - ${path(
                    foundTextFields[0].name.split('.'),
                    form.values
                  )}`
                : translatedValue,
            order,
            label: getTranslation(label)
          });
          break;
        case FieldComponentTypes.checkboxes:
          const selectedValues: any[] =
            path(name.split('.'), form.values) || [];
          const translatedItems = items
            .filter(i => selectedValues.includes(i.activeValue))
            .map(i => {
              if (i.textField) {
                const textFieldValue = path(
                  i.textField.name.split('.'),
                  form.values
                );
                if (textFieldValue) {
                  return `${getTranslation(i.label)} - ${textFieldValue}`;
                }
              }
              return getTranslation(i.label);
            });

          acc.push({
            name,
            value: translatedItems.join(', '),
            order,
            label: getTranslation(label)
          });
          break;
        case FieldComponentTypes.buttonSwitch:
          const result = { name, order, label: getTranslation(label) };
          const buttonSwitchValue = path(name.split('.'), form.values);

          if (buttonSwitchValue === true) {
            acc.push({ ...result, value: i18n('shared.yes') });
          } else if (buttonSwitchValue === false) {
            acc.push({ ...result, value: i18n('shared.no') });
          } else {
            acc.push({ ...result, value: buttonSwitchValue });
          }
          break;
        case FieldComponentTypes.datepicker:
          const dateValue = path<string | undefined>(
            name.split('.'),
            form.values
          );

          acc.push({
            name,
            value: dateValue ? format(parseISO(dateValue), dateFormat) : '',
            order,
            label: getTranslation(label)
          });
          break;
        default:
          const defaultValue = path(name.split('.'), form.values);
          acc.push({
            name,
            value: isNil(defaultValue) ? '' : defaultValue,
            order,
            label: getTranslation(label)
          });
      }

      return acc;
    },
    []
  );
