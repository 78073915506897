import * as R from 'ramda';
import React from 'react';

import Notification from './notification.js';
import Portal from 'features/shared/components/portal/index.js';
import { injectSheet } from 'features/sharedModules/styles/components/styles';

const indexedMap = R.addIndex(R.map);

const styles = {
  container: {
    position: 'fixed',
    top: '1.6rem',
    left: '1.6rem',
    zIndex: 9000,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
};

const Notifications = ({
  refToAlign,
  notifications,
  onHideNotification,
  customNotifications,
  classes
}) => {
  const containerRef = React.useRef();

  React.useLayoutEffect(() => {
    if (R.isNil(refToAlign)) {
      return;
    }

    const alignNotifications = () => {
      if (R.isNil(refToAlign.current) || R.isNil(containerRef.current)) {
        return;
      }

      const rectToAlign = refToAlign.current.getBoundingClientRect();

      containerRef.current.style.top = `${Math.max(
        16,
        rectToAlign.bottom + 18
      )}px`;
      containerRef.current.style.left = `${rectToAlign.left}px`;
    };

    alignNotifications();

    window.addEventListener('scroll', alignNotifications);
    window.addEventListener('resize', alignNotifications);

    return () => {
      window.removeEventListener('scroll', alignNotifications);
      window.addEventListener('resize', alignNotifications);
    };
  });

  return (
    !R.isEmpty(notifications) && (
      <Portal>
        <div className={classes.container} ref={containerRef}>
          {indexedMap((notification, index) => {
            if (!R.isNil(notification.customNotificationId)) {
              const CustomNotification =
                customNotifications[notification.customNotificationId];

              return (
                <CustomNotification
                  key={`${notification.id}-${index}`}
                  message={notification.message}
                  type={notification.type}
                  isHideEnabled={notification.isHideEnabled}
                  onHide={() => onHideNotification(notification.id)}
                />
              );
            } else {
              return (
                <Notification
                  key={`${notification.id}-${index}`}
                  message={notification.message}
                  type={notification.type}
                  isHideEnabled={notification.isHideEnabled}
                  onHide={() => onHideNotification(notification.id)}
                />
              );
            }
          }, notifications)}
        </div>
      </Portal>
    )
  );
};

export default injectSheet(styles)(Notifications);
