import { useEffect } from 'react';

import { useSessionStore } from '../../session/services/sessionStore';
import { PageStatuses } from '../../shared/components/useReadDataListener';
import { usePageStore as useRiskScorePageStore } from '../services/pageStore';

export const useInitRiskFinder = () => {
  const riskScorePageStore = useRiskScorePageStore();
  const productConfiguration = riskScorePageStore.productConfiguration;
  const { defaultConfigNamespaceId } = useSessionStore();

  useEffect(() => {
    if (productConfiguration === null && defaultConfigNamespaceId) {
      riskScorePageStore.setProductConfiguration(defaultConfigNamespaceId);
      riskScorePageStore.setPageStatus(
        'readPortfolioTableData',
        PageStatuses.ready
      );
    }
  }, [defaultConfigNamespaceId, productConfiguration]);

  useEffect(() => {
    return () => {
      riskScorePageStore.changePageStatuses({
        readPortfolioTableData: undefined,
        readPortfolioChartData: undefined,
        readExpectedValuePortfoliosChartData: undefined,
        readExpectedPathPortfoliosChartData: undefined,
        readHistoricalReturn3MChartData: undefined,
        readHistoricalReturnYTDChartData: undefined,
        readHistoricalReturn1YChartData: undefined,
        readHistoricalReturn3YChartData: undefined,
        readHistoricalReturn5YChartData: undefined,
        readHistoricalReturn10YChartData: undefined
      });
    };
  }, []);
};
