import axios from 'axios';
import { isEmpty } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';

import { clientsTable } from '../../clientsTable';
import { deleteDeletedInvestors } from '../api';
import { useClientsStore } from '../services/clientsStore';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useDeleteClients() {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();

  const deleteClients = async () => {
    const clientsStore = useClientsStore.getState();
    const tableStore = clientsTable.useTableStore.getState();

    try {
      clientsStore.setIsDeleteClientsPending(true);

      const accessToken = await getQAuthAccessToken(auth0AccessToken);

      const { clients } = clientsStore;
      const { selectedRowIds } = tableStore;

      const investorIdsToDelete = clients
        .filter(c => selectedRowIds.includes(c.id))
        .map(({ id }) => id);

      const deleteDeletedInvestorsResponse = await deleteDeletedInvestors(
        accessToken,
        investorIdsToDelete
      );

      const deletedInvestorIds = deleteDeletedInvestorsResponse.data.deleted;
      const failedInvestorIds = deleteDeletedInvestorsResponse.data.failed;

      const notification = getNotification(
        i18n,
        deletedInvestorIds,
        failedInvestorIds
      );

      clientsStore.deleteClients(deletedInvestorIds);
      tableStore.changeAllRowsSelection(false);

      clientsStore.setIsDeleteClientsPending(false);

      dispatch(notificationActionCreators.showNotification(notification));
    } catch (error) {
      if (!axios.isCancel(error)) {
        clientsStore.setIsDeleteClientsPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('admin.trashCan.clients.deleteClientsErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return deleteClients;
}

export const getNotification = (i18n, deletedClientIds, failedClientIds) => {
  if (isEmpty(failedClientIds)) {
    return {
      message: i18n('admin.trashCan.clients.deleteClientsSuccessMessage'),
      type: NotificationTypes.success
    };
  }

  if (!isEmpty(deletedClientIds)) {
    return {
      message: i18n('admin.trashCan.clients.deleteClientsHalfSuccessMessage')
        .replace('{0}', deletedClientIds.length)
        .replace('{1}', failedClientIds.length),
      type: NotificationTypes.error
    };
  }

  return {
    message: i18n('admin.trashCan.clients.deleteClientsFailedMessage'),
    type: NotificationTypes.error
  };
};
