import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { RoboAdviceAdviceSessionPages } from '../../../shared/constants';
import AdviceInformation from '../../adviceInformation/components';
import Advisory from '../../advisory/components/index.js';
import FinancialSituation from '../../financialSituation/components/index';
import KnowledgeAndExperience from '../../knowledgeAndExperience/components/index';
import Proposal from '../../proposal/components/index.js';
import PurposeAndRiskPage from '../../purposeAndRisk/components/index.js';
import { useSessionStore } from '../../session/services/sessionStore';
import Sustainability from '../../sustainability/components';
import { useAdvisoryTab, useProposalTab } from '../services/selectors';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  pagePlaceholder: {
    backgroundColor: theme.boxBackgroundColor_1,
    borderRadius: '15px',
    height: '300px'
  }
}));

const Pages = () => {
  const { clientType, clientId, adviceSessionId, pageId } = useParams();
  const classes = useStyles();
  const isSessionInitialized = useSessionStore(s => s.isSessionInitialized);
  const advisoryTab = useAdvisoryTab();
  const proposalTab = useProposalTab();
  const {
    roboAdviceForm: {
      adviceInformation: { fields: adviceInformationFields = [] }
    }
  } = useCustomerConfig();

  const redirectPage = React.useMemo(
    () =>
      adviceInformationFields.length > 0
        ? RoboAdviceAdviceSessionPages.adviceInformation
        : RoboAdviceAdviceSessionPages.purposeAndRisk,
    [adviceInformationFields.length]
  );

  if (!isSessionInitialized) {
    return <div className={classes.pagePlaceholder} />;
  }

  return (
    <>
      {pageId === RoboAdviceAdviceSessionPages.adviceInformation &&
        adviceInformationFields.length > 0 && <AdviceInformation />}
      {pageId === RoboAdviceAdviceSessionPages.adviceInformation &&
        !(adviceInformationFields.length > 0) && (
          <Redirect
            to={routeTemplates.roboAdviceAdviceSession.build(
              clientType,
              clientId,
              adviceSessionId,
              redirectPage
            )}
          />
        )}
      {pageId === RoboAdviceAdviceSessionPages.purposeAndRisk && (
        <PurposeAndRiskPage />
      )}
      {pageId === RoboAdviceAdviceSessionPages.knowledgeAndExperience && (
        <KnowledgeAndExperience />
      )}
      {pageId === RoboAdviceAdviceSessionPages.financialSituation && (
        <FinancialSituation />
      )}
      {pageId === RoboAdviceAdviceSessionPages.sustainability && (
        <Sustainability />
      )}
      {pageId === RoboAdviceAdviceSessionPages.advisory &&
        advisoryTab.isNavigationDisabled && (
          <Redirect
            to={routeTemplates.roboAdviceAdviceSession.build(
              clientType,
              clientId,
              adviceSessionId,
              redirectPage
            )}
          />
        )}
      {pageId === RoboAdviceAdviceSessionPages.advisory &&
        !advisoryTab.isNavigationDisabled && <Advisory />}
      {pageId === RoboAdviceAdviceSessionPages.proposal &&
        proposalTab.isNavigationDisabled && (
          <Redirect
            to={routeTemplates.roboAdviceAdviceSession.build(
              clientType,
              clientId,
              adviceSessionId,
              redirectPage
            )}
          />
        )}
      {pageId === RoboAdviceAdviceSessionPages.proposal &&
        !proposalTab.isNavigationDisabled && <Proposal />}
    </>
  );
};

export default Pages;
