import { over, lensProp, reject } from 'ramda';
import create from 'zustand';

export type AdviceSession = {
  id: string;
  name: string;
  clientName: string;
  advisorName: string;
  lastActivityDate: string;
  softDeleteDate: string;
};

export type AdviceSessionsStoreData = {
  adviceSessions: AdviceSession[];
  isReadAdviceSessionsPending: boolean;
  isDeleteAdviceSessionsPending: boolean;
  isRestoreAdviceSessionsPending: boolean;
};

export type AdviceSessionsStoreApi = {
  reset: () => void;
  setAdviceSessions: (adviceSessions: AdviceSession[]) => void;
  deleteAdviceSessions: (adviceSessionsIds: string[]) => void;
  setIsReadAdviceSessionsPending: (
    isReadAdviceSessionsPending: boolean
  ) => void;
  setIsDeleteAdviceSessionsPending: (
    isDeleteAdviceSessionsPending: boolean
  ) => void;
  setIsRestoreAdviceSessionsPending: (
    isRestoreAdviceSessionsPending: boolean
  ) => void;
};

export type AdviceSessionsStoreState = AdviceSessionsStoreData &
  AdviceSessionsStoreApi;

const defaultData = {
  adviceSessions: [],
  isReadAdviceSessionsPending: false,
  isDeleteAdviceSessionsPending: false,
  isRestoreAdviceSessionsPending: false
};

export const useAdviceSessionsStore = create<AdviceSessionsStoreState>(set => ({
  ...defaultData,
  reset() {
    set(defaultData);
  },
  setAdviceSessions(adviceSessions) {
    set({ adviceSessions });
  },
  deleteAdviceSessions(adviceSessionsIds) {
    set(
      over(
        lensProp('adviceSessions'),
        reject<AdviceSession>(({ id }) => adviceSessionsIds.includes(id))
      )
    );
  },
  setIsReadAdviceSessionsPending(isReadAdviceSessionsPending) {
    set({ isReadAdviceSessionsPending });
  },
  setIsDeleteAdviceSessionsPending(isDeleteAdviceSessionsPending) {
    set({ isDeleteAdviceSessionsPending });
  },
  setIsRestoreAdviceSessionsPending(isRestoreAdviceSessionsPending) {
    set({ isRestoreAdviceSessionsPending });
  }
}));
