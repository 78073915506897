import { ValidationErrors } from 'final-form';

import {
  createForm,
  FormApi
} from 'features/sharedModules/finalForm/services/finalForm';
import {
  createUseFinalFormAdapter,
  FormState,
  UseFinalFormStore
} from 'features/sharedModules/zustand/components/finalFormAdapter';

export type CustomPortfolioFormValues = {
  addAssetClass: string[];
};

export type CustomPortfolioFormState = FormState<CustomPortfolioFormValues>;

export type CustomPortfolioForm = FormApi<CustomPortfolioFormValues>;

export const customPortfolioForm = createForm<CustomPortfolioFormValues>({
  onSubmit: () => {},
  validate: values => {
    let errors: ValidationErrors = {};

    return errors;
  }
});

export type UseCustomPortfolioForm =
  UseFinalFormStore<CustomPortfolioFormValues>;

export const useCustomPortfolioForm = createUseFinalFormAdapter({
  form: customPortfolioForm,
  formSubscription: {
    values: true,
    errors: true,
    touched: true,
    submitFailed: true
  }
});
