import * as R from 'ramda';

import { CurrentUser } from './types';
import config from 'config/index.js';
import { defaultTheme } from 'defaults/defaultTheme';
import { defaultTranslations } from 'defaults/defaultTranslations';
import { mapServerCustomerToClient } from 'features/shared/utils/mapping.js';

export const mapServerCustomerConfigToClient = customerConfigData => {
  const settingsKey = config.USE_LEGACY_CONFIGURATION_ENDPOINT
    ? customerConfigData.customer.settings.deep_alpha
    : customerConfigData.customer.settings.deepalpha;

  const {
    roboAdvice: {
      clientInformation: { person, company }
    }
  } = settingsKey;

  const personFields = person.sort((a, b) => a.order - b.order);

  const companyFields = company.sort((a, b) => a.order - b.order);

  const customerConfig = R.pipe(
    R.over(
      R.lensProp<typeof settingsKey>('translations'),
      R.mergeRight(defaultTranslations)
    ),
    R.over(R.lensProp('styles'), R.mergeDeepRight(defaultTheme)),
    R.set(R.lensProp('language'), customerConfigData.customer.language),
    R.set(
      R.lensPath(['roboAdvice', 'clientInformation', 'person']),
      personFields
    ),
    R.set(
      R.lensPath(['roboAdvice', 'clientInformation', 'company']),
      companyFields
    )
  )(settingsKey);

  return customerConfig;
};

export const mapServerUserToClient = (
  accessTokenPayload,
  userInfoData
): CurrentUser => {
  const customer = mapServerCustomerToClient(userInfoData.customer);

  let customerCode = customer.code;
  if (R.isNil(customerCode) || R.isEmpty(R.trim(customerCode))) {
    customerCode = 'quantfolio';
  }

  let advisoryKey = customer.advisoryKey;
  if (R.isNil(advisoryKey) || R.isEmpty(R.trim(advisoryKey))) {
    advisoryKey = 'UXVhbnRmb2xpbzpGJFt9PVt6WmsxQg==';
  }

  let externalId: string | null = null;
  if (config.IDP_EXTERNAL_ID in accessTokenPayload) {
    externalId = accessTokenPayload[config.IDP_EXTERNAL_ID];

    // Check if external reference is not a string, and if so, JSON.stringify it
    if (typeof externalId !== 'string') {
      externalId = JSON.stringify(externalId);
    }
  }

  return {
    id: accessTokenPayload[config.AUTH0_ID_KEY],
    auth0Id: accessTokenPayload.sub,
    name: userInfoData.name,
    email: userInfoData.email,
    bio: userInfoData.bio,
    phone: userInfoData.phone,
    timezone: userInfoData.timezone,
    picture: userInfoData.picture,
    customPicture: R.isNil(userInfoData.custom_picture)
      ? null
      : {
          fullSize: userInfoData.custom_picture.full_size,
          thumbnail: userInfoData.custom_picture.thumbnail
        },
    permissions: accessTokenPayload[config.AUTH0_PERMISSIONS_KEY],
    roles: accessTokenPayload[config.AUTH0_ROLES_KEY],
    countryId: userInfoData.country_id,
    cultureCode: userInfoData.culture_code,
    isCustomerAdmin: userInfoData.is_customer_admin,
    isCustomerQfEmployee: accessTokenPayload['qf-employee'],
    customerId: customer.id,
    licenses: customer.licenses,
    customerCode,
    advisoryKey,
    externalId
  };
};
