import classNames from 'classnames';
import * as R from 'ramda';
import * as React from 'react';
import { v4 } from 'uuid';

import { customerPatchQueue } from '../../customerPatchQueue';
import { CustomerPatchTypes } from '../constants.js';
import { manageCustomerForm } from '../services/manageCustomerForm';
import { usePageStyles } from './shared.js';
import { useQueuedUpdateCustomer } from './useUpdateCustomer';
import Button from 'features/shared/components/button';
import Icon from 'features/shared/components/icon/index.js';
import { Colors } from 'features/shared/constants/colors.js';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import FinalFormTextInput from 'features/sharedModules/finalForm/components/textInput';
import { useFieldArray } from 'features/sharedModules/finalForm/components/useFieldArray';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const indexedMap = R.addIndex(R.map);

const useStyles = createUseStyles({
  textInput: {
    paddingRight: '44px'
  },
  blocksContainer: {
    display: 'inline-block'
  },
  rightBlock: {
    right: '12px',

    '&$hidden': {
      display: 'none'
    }
  },
  hidden: {},
  deleteIcon: {
    fontSize: '2rem',
    lineHeight: '2.4rem',
    color: Colors.gray50,
    transform: 'rotate(0.03deg)', // fix of issue: icon small size causes pixelated icon
    cursor: 'pointer',

    '&$disabled': {
      cursor: 'default'
    }
  },
  disabled: {},
  inputBlocks: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    margin: '-10px'
  },
  inputBlock: {
    flex: '0 0 auto',
    margin: '10px'
  },
  actionButtons: {
    marginTop: '30px'
  }
});

const AddUsers = () => {
  const i18n = useI18n();
  const pageClasses = usePageStyles();
  const classes = useStyles();

  const customerPatchQueueStore = customerPatchQueue.usePatchQueueStore();

  const doesAddUsersPatchExist = R.any(
    p => p.type === CustomerPatchTypes.addUsers,
    customerPatchQueueStore.patches
  );
  const queuedUpdateCustomer = useQueuedUpdateCustomer();

  const { fields } = useFieldArray('usersToAdd');

  const initUsersToAdd = () => {
    const { values } = manageCustomerForm.getState();

    if (R.isNil(values.usersToAdd) || R.isEmpty(values.usersToAdd)) {
      fields.push({ id: v4() });
    }
  };

  const addUserToAdd = index => {
    const { values } = manageCustomerForm.getState();
    const isLastUserToAdd = index === values.usersToAdd.length - 1;

    if (isLastUserToAdd) {
      fields.push({ id: v4() });
    }
  };

  const removeUserToAdd = index => {
    fields.remove(index);
  };

  const addUsers = () => {
    const { values, errors } = manageCustomerForm.getState();

    const existingUsers = R.propOr([], 'users', values);
    const usersToAdd = R.pipe(R.propOr([], 'usersToAdd'), R.init)(values);
    const usersToAddErrors = R.pipe(
      R.propOr([], 'usersToAdd'),
      R.reject(e => R.isNil(e) || R.isEmpty(e))
    )(errors);

    if (!R.isEmpty(usersToAddErrors) || R.isEmpty(usersToAdd)) {
      return;
    }

    queuedUpdateCustomer({
      id: v4(),
      type: CustomerPatchTypes.addUsers,
      payload: {
        users: indexedMap(
          (u, i) => ({
            id: v4(),
            email: R.trim(u.email),
            isCustomerAdmin: i === 0 && R.length(existingUsers) === 0
          }),
          usersToAdd
        )
      }
    });
  };

  React.useEffect(() => {
    initUsersToAdd();
  }, []);

  return (
    <div>
      <div className={classNames(pageClasses.label, pageClasses.verticalLabel)}>
        {i18n('admin.manageCustomer.addUsers')}
      </div>
      <div className={classes.inputBlocks}>
        {indexedMap((field, index) => {
          const userToAdd = fields.value[index];
          const isLastUserToAdd = index === fields.value.length - 1;

          return (
            <div key={userToAdd.id} className={classes.inputBlock}>
              <FinalFormTextInput
                disabled={doesAddUsersPatchExist}
                placeholder={i18n('admin.manageCustomer.addUser.placeholder')}
                name={`${field}.email`}
                afterChange={() => {
                  addUserToAdd(index);
                }}
                className={classes.textInput}
                blocksContainerClassName={classes.blocksContainer}
                rightBlockProps={{
                  className: classNames(classes.rightBlock, {
                    [classes.hidden]: isLastUserToAdd
                  })
                }}
                rightBlockChildren={
                  <Icon
                    className={classNames(classes.deleteIcon, {
                      [classes.disabled]: doesAddUsersPatchExist
                    })}
                    onClick={
                      doesAddUsersPatchExist
                        ? null
                        : () => {
                            removeUserToAdd(index);
                          }
                    }
                    type="cancel"
                  />
                }
              />
            </div>
          );
        }, fields)}
      </div>
      <div className={classes.actionButtons}>
        <Button
          disabled={
            R.isNil(fields.value) ||
            R.length(fields.value) <= 1 ||
            doesAddUsersPatchExist
          }
          onClick={() => {
            addUsers();
          }}
        >
          {i18n('admin.manageCustomer.invite')}
        </Button>
      </div>
    </div>
  );
};

export default AddUsers;
