import { useDispatch, useSelector } from 'react-redux';

import { adviceSessionsTable } from '../../adviceSessionsTable';
import { restoreAdviceSessions as restoreAdviceSessionsApi } from '../api';
import { useAdviceSessionsStore } from '../services/adviceSessionsStore';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export const useRestoreAdviceSessions = () => {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const i18n = useI18n();
  const dispatch = useDispatch();

  const restoreAdviceSessions = async () => {
    const { selectedRowIds, changeAllRowsSelection } =
      adviceSessionsTable.useTableStore.getState();
    const adviceSessionsStore = useAdviceSessionsStore.getState();

    try {
      adviceSessionsStore.setIsRestoreAdviceSessionsPending(true);

      const accessToken = await getQAuthAccessToken(auth0AccessToken);

      const response = await restoreAdviceSessionsApi(
        accessToken,
        selectedRowIds
      );

      const restoredAdviceSessionsIds = response.data.data.map(
        ({ session_id: id }) => id
      );

      adviceSessionsStore.deleteAdviceSessions(restoredAdviceSessionsIds);

      changeAllRowsSelection(false);

      adviceSessionsStore.setIsRestoreAdviceSessionsPending(false);

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n(
            'admin.trashCan.adviceSessions.restoreAdviceSessionsSuccessMessage'
          ),
          type: NotificationTypes.success
        })
      );
    } catch (error) {
      adviceSessionsStore.setIsRestoreAdviceSessionsPending(false);

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n(
            'admin.trashCan.adviceSessions.restoreAdviceSessionsErrorMessage'
          ),
          type: NotificationTypes.error
        })
      );

      throwSafeError(error);
    }
  };
  return restoreAdviceSessions;
};
