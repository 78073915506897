import { z } from 'zod';

import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';

export const defaultTheme = {
  //#region New config
  logoSrc: '',

  borderRadiusType: BorderRadiusTypes.rounded,

  accentColor: '#00BBF6',
  accentHoverColor: '#30729A',
  accentFocusColor: '#166595',
  inputFillFocusColor: '#214050',
  defaultButtonTextColor: '#FFFFFF',

  disabledBackgroundColor: '#2C363E',

  primaryColor: '#FFFFFF',
  secondaryColor: '#6A8595',
  disabledTextColor: '#4A5E6A',
  tertiaryTextColor: '#828282',

  pageBackgroundColor: '#1A242A',

  boxBackgroundColor_1: '#1B282E',
  boxBackgroundColor_2: '#2C373D',

  defaultInputFillColor: '#1E2930',
  itemBackgroundColor_1: '#222E35',
  itemBackgroundColor_2: '#28363D',
  hoverInputStrokeColor: '#394D57',

  defaultInputStrokeColor: '#3C4A54',

  successColor: '#41B851',

  errorNegativeColor: '#EB5757',

  warningColor: '#F2C94C',

  chartPortfolioColors: [
    '#73DDFF',
    '#78DC29',
    '#FBA96F',
    '#257CCD',
    '#FFCF24',
    '#EF6345',
    '#53FFEA',
    '#04C5BA',
    '#744CE5',
    '#00660B',
    '#C8C8C8'
  ],
  chartEmptyColor: '#3C4A54'
  //#endregion New config
};

export const themeSchema = z.object({
  logoSrc: z.string().nullable(),
  borderRadiusType: z.nativeEnum(BorderRadiusTypes),
  accentColor: z.string(),
  accentHoverColor: z.string(),
  accentFocusColor: z.string(),
  inputFillFocusColor: z.string(),
  defaultButtonTextColor: z.string(),
  disabledBackgroundColor: z.string(),
  primaryColor: z.string(),
  secondaryColor: z.string(),
  disabledTextColor: z.string(),
  tertiaryTextColor: z.string(),
  pageBackgroundColor: z.string(),
  boxBackgroundColor_1: z.string(),
  boxBackgroundColor_2: z.string(),
  defaultInputFillColor: z.string(),
  itemBackgroundColor_1: z.string(),
  itemBackgroundColor_2: z.string(),
  hoverInputStrokeColor: z.string(),
  defaultInputStrokeColor: z.string(),
  successColor: z.string(),
  errorNegativeColor: z.string(),
  warningColor: z.string(),
  chartPortfolioColors: z.array(z.string()),
  chartEmptyColor: z.string()
});

export type Theme = z.infer<typeof themeSchema>;
