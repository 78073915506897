import { useEffect } from 'react';

import { useOrderPageStore } from './../../services/orderPageStore';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';

export const useInitPage = () => {
  useEffect(() => {
    const orderPageStoreState = useOrderPageStore.getState();

    orderPageStoreState.changePageStatuses({
      readCostData: PageStatuses.ready,
      readExpectedValueData: PageStatuses.ready
    });

    return () => {
      orderPageStoreState.changePageStatuses({
        readCostData: undefined,
        readExpectedValueData: undefined
      });

      orderPageStoreState.setCostData([]);
    };
  }, []);
};
