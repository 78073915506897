import * as React from 'react';

import { TableModule } from '../types';

type TableContext = {
  module: TableModule;
};

export const TableContext = React.createContext<TableContext>(null as any);

export const useTable = () => React.useContext(TableContext);
