import { Document } from 'flexsearch';
import React, { useMemo, useRef } from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import { InstrumentSelected } from '../../../form/services/form';
import { debounce } from '../../../proposal/utils';
import { useSessionStore } from '../../../session/services/sessionStore';
import { ProductAttributeType } from '../../../shared/constants';
import { CustomAttribute } from '../../../shared/services/adviceSessionStore';
import { CustomAttributeFromConfig } from '../../../shared/services/selectors';
import { ReactComponent as CheckIcon } from 'assets/checkmark2.svg';
import { ReactComponent as CrossIcon } from 'assets/x-icon.svg';
import { ReadAnalyzeAdvancedSuitabilityStatusV2Response } from 'features/roboAdvice/adviceSession/shared/api/types';
import Icon from 'features/shared/components/icon';
import { stylesValues } from 'features/shared/components/select/utils';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import { getTranslation } from 'features/shared/utils/translations';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  error: {
    color: theme.errorNegativeColor,
    marginTop: Spacing.spacing00,
    fontSize: Typography.subtitle.size,
    lineHeight: Typography.subtitle.lineHeight
  },
  searchIcon: {
    marginLeft: Spacing.spacing01,
    fontSize: 18
  },
  optionElements: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '&>div': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: `0 ${Spacing.spacing01}px`
    }
  },
  optionLabel: {
    flex: 10
  },
  optionIsin: {
    flex: 8
  },
  optionAssetClass: {
    flex: 8
  },
  optionSuitability: {
    flex: 3,
    textAlign: 'center'
  },
  optionBinaryProductAttribute: {
    flex: 2
  },
  optionTextProductAttribute: {
    flex: 6
  },
  checkIcon: {
    fill: theme.successColor
  },
  crossIcon: {
    fill: theme.errorNegativeColor
  },
  menuListHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.itemBackgroundColor_1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: `${stylesValues.menuItemPaddingY}px 18px`,
    color: theme.secondaryColor,
    '&>div': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: `0 ${Spacing.spacing01}px`
    }
  },
  noOptionsSubheader: {
    color: theme.secondaryColor,
    paddingTop: Spacing.spacing00
  }
}));

const getCustomStyles = theme => ({
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.isFocused
      ? theme.inputFillFocusColor
      : theme.defaultInputFillColor,
    borderColor: state.isFocused
      ? theme.accentColor
      : theme.defaultInputStrokeColor,
    borderRadius: 20,
    borderWidth: stylesValues.borderWidth,
    cursor: 'pointer',

    '&:hover': {
      borderColor: theme.hoverInputStrokeColor
    }
  }),
  dropdownIndicator: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isFocused ? theme.accentColor : theme.secondaryColor,
    padding: `8px ${stylesValues.inputPaddingX}px`
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: baseStyles => ({
    ...baseStyles,
    color: theme.primaryColor,
    margin: 0,
    paddingBottom: 0,
    paddingTop: 0
  }),
  menu: baseStyles => ({
    ...baseStyles,
    backgroundColor: theme.itemBackgroundColor_1,
    border: `${stylesValues.borderWidth}px solid ${theme.accentColor}`,
    borderRadius: 15,
    marginTop: stylesValues.menuPaddingY,
    marginBottom: stylesValues.menuPaddingY,
    overflow: 'hidden'
  }),
  menuList: baseStyles => ({
    ...baseStyles,
    paddingBottom: stylesValues.menuPaddingY,
    paddingTop: 0,
    scrollbarColor: `${theme.accentColor} transparent`,
    overflow: 'overlay',
    scrollbarGutter: 'stable',

    '::-webkit-scrollbar': {
      width: 10
    },
    '::-webkit-scrollbar-thumb': {
      background: `${theme.accentColor}`,
      border: `${stylesValues.borderWidth}px solid ${theme.defaultInputStrokeColor}`,
      borderRadius: 15,
      cursor: 'pointer'
    }
  }),
  menuPortal: baseStyles => ({
    ...baseStyles,
    width: '80vw',
    top: '54px',
    left: '13px'
  }),
  noOptionsMessage: baseStyles => ({
    ...baseStyles,
    padding: `${Spacing.spacing03}px ${Spacing.spacing01}px`,
    color: theme.primaryColor
  }),
  option: (baseStyles, { isFocused, isSelected }) => ({
    ...baseStyles,
    color: 'inherit',
    backgroundColor: isSelected
      ? theme.boxBackgroundColor_2
      : isFocused
      ? theme.itemBackgroundColor_1
      : 'inherit',
    cursor: 'pointer',
    padding: `${stylesValues.menuItemPaddingY}px 18px`,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '&:first-of-type': {
      marginTop: 0
    },
    '&:last-child': {
      marginBottom: 0
    },
    '&:nth-child(odd)': {
      backgroundColor: theme.itemBackgroundColor_2
    },

    '&:hover': {
      backgroundColor: theme.hoverInputStrokeColor
    }
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isDisabled ? theme.disabledTextColor : theme.secondaryColor,
    margin: 0,
    whiteSpace: 'nowrap'
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isDisabled ? theme.disabledTextColor : theme.primaryColor,
    marginLeft: 0,
    marginRight: 0
  }),
  valueContainer: baseStyles => ({
    ...baseStyles,
    padding: `8px ${stylesValues.inputPaddingX}px`
  })
});

type Props = {
  instruments: Omit<InstrumentSelected, 'initialDeposit' | 'monthlySaving'>[];
  productAttributes: CustomAttributeFromConfig[];
  showISINColumn: boolean;
  showAssetClassColumn: boolean;
  customAttributesData?: Record<number, CustomAttribute[]>;
  selectedInstrumentsIds: string[];
  onInstrumentSelect: (
    value: Omit<InstrumentSelected, 'initialDeposit' | 'monthlySaving'>
  ) => void;
  showSuitabilityStatus: boolean;
  advancedSuitabilityData: ReadAnalyzeAdvancedSuitabilityStatusV2Response | null;
};

const InstrumentsSearch = ({
  instruments,
  productAttributes,
  showISINColumn,
  showAssetClassColumn,
  customAttributesData,
  selectedInstrumentsIds,
  onInstrumentSelect,
  showSuitabilityStatus,
  advancedSuitabilityData
}: Props) => {
  const selectContainer = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const i18n = useI18n();
  const sessionStoreState = useSessionStore.getState();
  const theme = useTheme();
  const customStyles = getCustomStyles(theme);
  const menuHeightMax = 220;

  const onChange = value => {
    onInstrumentSelect(value);
  };

  const searchDocument = useMemo(() => {
    const document = new Document({
      tokenize: 'full',
      document: {
        id: 'id',
        index: ['name', 'isin']
      }
    });

    instruments.forEach(({ id, isin, name }, index) => {
      document.add(index, { id, isin, name });
    });

    return document;
  }, [instruments]);

  const instrumentsWithoutSelected = useMemo(
    () => instruments.filter(({ id }) => !selectedInstrumentsIds.includes(id)),
    [instruments, selectedInstrumentsIds]
  );

  const debouncedFilterInstruments = debounce((value, callback) => {
    const results = searchDocument.search(value);
    callback(
      results
        .reduce(
          (acc, curr) => [...acc, ...curr.result.map(id => instruments[id])],
          []
        )
        .filter(({ id }) => !selectedInstrumentsIds.includes(id))
    );
  });

  const loadOptions = (value, callback) => {
    if (value?.length > 2) {
      debouncedFilterInstruments(value, callback);
    } else {
      callback(instrumentsWithoutSelected);
    }
  };

  const ControlComponent = props => (
    <components.Control {...props}>
      <Icon
        className={classes.searchIcon}
        style={{
          color: props.isDisabled
            ? theme.disabledTextColor
            : props.isFocused
            ? theme.accentColor
            : theme.secondaryColor
        }}
        type="search"
      />
      {props.children}
    </components.Control>
  );

  const NoOptionsMessageComponent = props => (
    <components.NoOptionsMessage {...props}>
      <div>{i18n('roboAdvice.orderExecution.searchInstrumentsNotFound')}</div>
      <div className={classes.noOptionsSubheader}>
        {i18n('roboAdvice.orderExecution.searchInstrumentsNotFoundSubheader')}
      </div>
    </components.NoOptionsMessage>
  );

  const OptionComponent = props => {
    const { data } = props;

    const instrumentSuitabilityStatus = advancedSuitabilityData?.data?.find(
      ({ id }) => id === data.id
    );

    return (
      <components.Option {...props}>
        <span title={data.name} className={classes.optionElements}>
          <div className={classes.optionLabel}>{data.name}</div>
          {showISINColumn && (
            <div className={classes.optionIsin}>{data.isin}</div>
          )}
          {showAssetClassColumn && (
            <div className={classes.optionAssetClass}>{data.subAssetClass}</div>
          )}
          {showSuitabilityStatus && (
            <div className={classes.optionSuitability}>
              {instrumentSuitabilityStatus?.productIsSuitable ===
              undefined ? null : instrumentSuitabilityStatus.productIsSuitable ? (
                <CheckIcon className={classes.checkIcon} />
              ) : (
                <CrossIcon className={classes.crossIcon} />
              )}
            </div>
          )}
          {productAttributes &&
            productAttributes.slice(0, 3).map(({ type, name }) => {
              const customAttributeData = (customAttributesData || {})[
                sessionStoreState.defaultConfigNamespaceId!
              ]?.find(({ ticker }) => ticker === data.id);
              if (type === ProductAttributeType.text) {
                return (
                  <div
                    className={classes.optionTextProductAttribute}
                    key={name}
                  >
                    {customAttributeData?.productAttributeText?.[name]}
                  </div>
                );
              }

              if (type === ProductAttributeType.binary) {
                return (
                  <div
                    className={classes.optionBinaryProductAttribute}
                    key={name}
                  >
                    {customAttributeData?.productAttributeBinary?.[name] ? (
                      <CheckIcon className={classes.checkIcon} />
                    ) : (
                      <CrossIcon className={classes.crossIcon} />
                    )}
                  </div>
                );
              }

              return null;
            })}
        </span>
      </components.Option>
    );
  };

  const MenuListComponent = props => (
    <components.MenuList {...props}>
      <div className={classes.menuListHeader}>
        <div className={classes.optionLabel}>{i18n('shared.name')}</div>
        {showISINColumn && (
          <div className={classes.optionIsin}>{i18n('shared.isin')}</div>
        )}
        {showAssetClassColumn && (
          <div className={classes.optionAssetClass}>
            {i18n('shared.assetClass')}
          </div>
        )}
        {showSuitabilityStatus && (
          <div className={classes.optionSuitability}>
            {i18n('roboAdvice.advisory.customPortfolio.suitability')}
          </div>
        )}
        {productAttributes &&
          productAttributes
            .slice(0, 3)
            .map(({ type, label }) =>
              type === ProductAttributeType.binary ? (
                <div className={classes.optionBinaryProductAttribute}>
                  {getTranslation(label)}
                </div>
              ) : (
                <div className={classes.optionTextProductAttribute}>
                  {getTranslation(label)}
                </div>
              )
            )}
      </div>
      {props.children}
    </components.MenuList>
  );

  return (
    <>
      <div ref={selectContainer} style={{ width: 'auto' }}>
        <AsyncSelect
          aria-labelledby={'instruments-search'}
          components={{
            Control: ControlComponent,
            NoOptionsMessage: NoOptionsMessageComponent,
            Option: OptionComponent,
            MenuList: MenuListComponent
          }}
          isSearchable
          maxMenuHeight={menuHeightMax}
          name={'instruments-search'}
          loadOptions={loadOptions}
          defaultOptions={instrumentsWithoutSelected}
          menuPortalTarget={document.getElementById('new-instruments-row')}
          styles={customStyles}
          onChange={onChange}
          placeholder={i18n(
            'roboAdvice.orderExecution.searchInstruments.placeholder'
          )}
        />
      </div>
    </>
  );
};

export default InstrumentsSearch;
