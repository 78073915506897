import { Transformations } from 'features/sharedModules/finalForm/components/shared.js';

// TODO: cover by tests
export const getEmptyValidationRuleConfig = (labelKey, errorMessageKey) => ({
  errorMessageConfig: {
    text: errorMessageKey,
    args: [
      {
        text: labelKey,
        transform: Transformations.toLowerCase
      }
    ]
  }
});
