import React, { useRef, useEffect, useState } from 'react';

import { clientForm } from '../../../../main/services/clientForm';
import DateRange from './dateRange';
import { useInitSignatureRightInfo } from './useInitSignatureRightInfo';
import ENINDate from 'features/shared/components/eninDate';
import Icon from 'features/shared/components/icon/index.js';
import Scrollbars from 'features/shared/components/scrollbars';
import Tooltip from 'features/shared/components/tooltip';
import { LayoutBreakpoints } from 'features/shared/constants/layoutBreakpoints';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import useOnClickOutside from 'features/shared/hooks/useClickOutside';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const popupWidth = 430;
const popupHeight = window.innerHeight / 2;

const useStyles = createUseStyles(theme => ({
  eninWrapper: {
    display: 'flex',
    justifyContent: 'right',
    minWidth: 1140,
    marginTop: Spacing.spacing00
  },
  infoIcon: {
    marginLeft: Spacing.spacing01,
    color: theme.secondaryColor,

    '&:hover': {
      color: theme.accentColor
    }
  },
  signatureRight: {
    display: 'flex',
    flexDirection: 'column',
    margin: `0 ${Spacing.spacing00}px`
  },
  header: {
    fontWeight: FontWeights.medium,
    fontSize: Typography.heading2.size,
    lineHeight: Typography.heading2.lineHeight,
    margin: `${Spacing.spacing01}px 0`
  },
  contentBlock: {
    marginBottom: Spacing.spacing01,
    color: theme.primaryColor,
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight
  },
  itemWrap: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    marginBottom: Spacing.spacing01
  },
  contentText: {
    gridColumn: '2 / 2',
    justifySelf: 'left'
  },
  [`@media (max-width: ${LayoutBreakpoints.desktopMedium}px)`]: {
    eninWrapper: {
      minWidth: 990
    }
  },
  [`@media (max-width: ${LayoutBreakpoints.tabletMin}px)`]: {
    eninWrapper: {
      minWidth: 960
    }
  }
}));

const SignatureRightInfo = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const initSignatureRightInfo = useInitSignatureRightInfo();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const { values } = clientForm.getState();
  const eninUpdateDate = values.kyc?.enin?.signatureRight?.eninUpdateDate;
  const signatureRight = values.kyc?.enin?.signatureRight?.signatureRightData;
  const signatureRightIconId = 'signatureRightInfo';

  const popupRef = useRef(null);
  useOnClickOutside([popupRef], e => {
    if (e.srcElement.id !== signatureRightIconId) {
      return setTooltipOpen(false);
    }
  });

  useEffect(() => {
    initSignatureRightInfo();
  }, []);

  return (
    <div className={classes.eninWrapper}>
      {eninUpdateDate && <ENINDate date={eninUpdateDate} />}
      <Tooltip
        ref={popupRef}
        open={tooltipOpen}
        contentMinWidth={popupWidth}
        contentMaxWidth={popupWidth}
        content={
          signatureRight ? (
            <Scrollbars autoHeightMax={popupHeight}>
              <div className={classes.signatureRight}>
                <span className={classes.header}>
                  {i18n('roboAdvice.kyc.enin.legalRights')}
                </span>
                <div className={classes.contentBlock}>
                  {signatureRight.legalRights?.map((item, index) => (
                    <div className={classes.itemWrap} key={index}>
                      <DateRange
                        fromDate={item?.fromDate}
                        toDate={item?.toDate}
                      />
                      <span className={classes.contentText}>{item?.text}</span>
                    </div>
                  ))}
                </div>
                <span className={classes.header}>
                  {i18n('roboAdvice.kyc.enin.currentRoles')}
                </span>
                <div className={classes.contentBlock}>
                  {signatureRight.currentRoles?.map((item, index) => (
                    <div className={classes.itemWrap} key={index}>
                      <DateRange
                        fromDate={item?.fromDate}
                        toDate={item?.toDate}
                      />
                      <span className={classes.contentText}>
                        {item?.personFullName}
                        {item?.roleTranslationsKey &&
                          ` (${i18n(item.roleTranslationsKey)})`}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </Scrollbars>
          ) : (
            <span>{i18n('roboAdvice.kyc.enin.noDataToPresent')}</span>
          )
        }
        trigger={
          <Icon
            id={signatureRightIconId}
            className={classes.infoIcon}
            type="info"
            onClick={() => setTooltipOpen(state => !state)}
          />
        }
      />
    </div>
  );
};

export default SignatureRightInfo;
