import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import CustomerManagement from '../../customerManagementPage/components/index.js';
import CustomersTable from '../../customersTablePage/components/index.js';
import MainLayout from 'features/shared/components/mainLayout/index.js';
import routeTemplates from 'features/shared/utils/routeTemplates';

const Customers = () => {
  return (
    <MainLayout>
      <Switch>
        <Route
          exact
          path={routeTemplates.customers.config}
          render={() => <Redirect to={routeTemplates.customersTable.build()} />}
        />
        <Route
          path={routeTemplates.customersTable.config}
          component={CustomersTable}
        />
        <Route
          path={routeTemplates.customerManagement.config}
          component={CustomerManagement}
        />
      </Switch>
    </MainLayout>
  );
};

export default Customers;
