import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getQAuthAccessToken } from '../../../../shared/api';
import sessionSelectors from '../../../../shared/services/session/selectors';
import { readAnalyzeAdvancedSuitabilityStatus } from '../../shared/api';
import { PageStatuses } from '../../shared/components/useReadDataListener';
import { useKnowledgeAndExperienceStore } from '../services/knowledgeAndExperienceStore';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export const useReadAdvancedSuitabilityStatus = () => {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<CancelTokenSource>();
  const dispatch = useDispatch();
  const i18n = useI18n();

  const readAdvancedSuitabilityStatus = async (data, namespaceId: number) => {
    const knowledgeAndExperienceStoreState =
      useKnowledgeAndExperienceStore.getState();
    const fetchStatus =
      knowledgeAndExperienceStoreState.advancedSuitabilityStatusDataStatuses[
        namespaceId
      ];

    if (
      knowledgeAndExperienceStoreState.advancedSuitabilityStatus[namespaceId] ||
      fetchStatus === PageStatuses.pending ||
      fetchStatus === PageStatuses.failed
    ) {
      return;
    }
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }

    try {
      knowledgeAndExperienceStoreState.changeAdvancedSuitabilityStatusDataStatus(
        PageStatuses.pending,
        namespaceId
      );
      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        undefined
      );

      const advancedSuitabilityStatusResponse =
        await readAnalyzeAdvancedSuitabilityStatus(
          accessToken,
          undefined,
          data,
          {
            namespace_id: namespaceId
          }
        );

      useKnowledgeAndExperienceStore
        .getState()
        .addAdvancedSuitabilityStatusData(
          advancedSuitabilityStatusResponse.data,
          namespaceId
        );
      knowledgeAndExperienceStoreState.changeAdvancedSuitabilityStatusDataStatus(
        PageStatuses.succeed,
        namespaceId
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        knowledgeAndExperienceStoreState.changeAdvancedSuitabilityStatusDataStatus(
          PageStatuses.failed,
          namespaceId
        );

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.adviceSession.knowledgeAndExperience.readAdvancedSuitabilityStatusErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readAdvancedSuitabilityStatus;
};
