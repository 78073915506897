import * as React from 'react';

import { visualsForm } from '../services/visualsForm';
import { useVisualsStore } from '../services/visualsStore';
import { useReadSettings } from './useReadSettings';

export function useInitPage() {
  const readSettings = useReadSettings();

  React.useEffect(() => {
    readSettings();

    return () => {
      useVisualsStore.getState().reset();
      visualsForm.restartToInitialValues();
    };
  }, []);
}
