import routerSelectors from 'features/shared/services/router/selectors.js';
import routerFactory from 'framework/router/components/index.js';
import { types } from 'framework/router/services/actions.js';

export default routerFactory({
  getLocation: routerSelectors.getLocation,
  onLocationChanged: dispatch => (location, prevLocation, action) => {
    dispatch({
      type: types.LOCATION_CHANGED,
      payload: {
        location,
        prevLocation,
        action
      }
    });
  }
});
