import axios from 'axios';
import { isNil } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import {
  createSessionGoal,
  getSessionData,
  getSessionGoals,
  postSessionData
} from '../../../adviceSession/shared/api';
import { SessionNamespaces } from '../../../adviceSession/shared/constants';
import { getCurrentDate } from '../../../shared/utils';
import { createAdviceSession } from '../../main/api';
import { clientForm } from '../../main/services/clientForm';
import {
  mapClientAdviceSessionToServer,
  mapNewGoalIdsForCustomPortfolioAdvisorNotes,
  mapServerAdviceSessionToClient
} from '../services/mapping';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { AdviceSessionParams } from 'features/shared/constants/session';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useCopyAdviceSession() {
  const { clientId } = useParams<AdviceSessionParams>();
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const currentUser: any = useSelector(sessionSelectors.getCurrentUser());
  const dispatch = useDispatch();

  const copyAdviceSession = async (adviceSessionId, adviceSessionCopyId) => {
    const clientFormState = clientForm.getState();

    const adviceSession = clientFormState.values.adviceSessions?.find(
      s => s.id === adviceSessionId
    );

    if (isNil(adviceSession) || isNil(adviceSession.name)) {
      return;
    }

    const inProgressNotificationId = v4();

    try {
      dispatch(
        notificationActionCreators.showNotification({
          id: inProgressNotificationId,
          isAutohideEnabled: false,
          message: i18n(
            'roboAdvice.client.copyAdviceSessionInProgressMessage'
          ).replace('{0}', adviceSession.name),
          type: NotificationTypes.info
        })
      );

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        undefined
      );

      const getSessionDataResponse = await getSessionData(
        accessToken,
        undefined,
        adviceSessionId
      );

      const sessionGoalsResponse = await getSessionGoals(
        accessToken,
        undefined,
        adviceSessionId
      );

      const createAdviceSessionResponse = await createAdviceSession(
        accessToken,
        undefined,
        {
          ...mapClientAdviceSessionToServer(
            currentUser,
            clientId,
            adviceSession
          ),
          session_id: adviceSessionCopyId,
          name: `${adviceSession.name} - ${i18n('shared.copy')}`
        }
      );

      const newGoals = await Promise.all(
        sessionGoalsResponse.data.data.map(goal =>
          createSessionGoal(accessToken, adviceSessionCopyId, {
            data: goal.data,
            name: goal.name,
            description: goal.description,
            icon: goal.icon
          })
        )
      );

      const newGoalsIdsMap = {};
      newGoals.forEach((goal, index) => {
        newGoalsIdsMap[sessionGoalsResponse.data.data[index].goal_id] =
          goal?.data.data.goal_id;
      });

      await Promise.all(
        getSessionDataResponse.data.data
          .filter(
            n =>
              n.namespace !== SessionNamespaces.finishedSessionData &&
              n.namespace !== SessionNamespaces.finishedSessionInvestorData
          )
          .map(n =>
            postSessionData(accessToken, undefined, adviceSessionCopyId, {
              namespace: n.namespace,
              payload: mapNewGoalIdsForCustomPortfolioAdvisorNotes(
                n.payload,
                newGoalsIdsMap
              )
            })
          )
      );

      clientForm.mutators.push('adviceSessions', {
        ...mapServerAdviceSessionToClient(createAdviceSessionResponse.data),
        newTimestamp: getCurrentDate().getTime()
      });

      dispatch(
        notificationActionCreators.hideNotification(inProgressNotificationId)
      );
      dispatch(
        notificationActionCreators.showNotification({
          message: i18n(
            'roboAdvice.client.copyAdviceSessionSuccessMessage'
          ).replace('{0}', adviceSession.name),
          type: NotificationTypes.success
        })
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.hideNotification(inProgressNotificationId)
        );
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.client.copyAdviceSessionErrorMessage'
            ).replace('{0}', adviceSession.name),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return copyAdviceSession;
}
