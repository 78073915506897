import classNames from 'classnames';
import { isNil } from 'ramda';
import { useElementSize } from 'usehooks-ts';

import { nameColumnWidth, valueAccountColumnWidth } from '../shared/constants';
import Details from './details';
import { useForm as useRoboAdviceForm } from 'features/roboAdvice/adviceSession/form/services/form';
import Scrollbars from 'features/shared/components/scrollbars';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { formatNumber } from 'features/shared/utils/number';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const totalContainerMarginTop = Spacing.spacing01;

const useStyles = createUseStyles(theme => ({
  columns: {
    display: 'flex',
    color: theme.secondaryColor,
    lineHeight: '150%',
    paddingBottom: Spacing.spacing01,
    marginRight: Spacing.spacing01
  },
  nameColumn: {
    width: nameColumnWidth
  },
  valueAccountColumn: {
    width: valueAccountColumnWidth,
    textAlign: 'right'
  },
  totalContainer: {
    display: 'flex',
    fontWeight: FontWeights.medium,
    height: Typography.body1.lineHeight,
    lineHeight: Typography.body1.lineHeight,
    marginTop: totalContainerMarginTop,

    '& > div:first-child': {
      paddingLeft: Spacing.spacing01,
      width: nameColumnWidth
    },

    '&> div:nth-child(2)': {
      width: valueAccountColumnWidth,
      display: 'inline-flex',
      justifyContent: 'flex-end'
    }
  }
}));

type Props = {
  allowNegative: boolean;
  cultureCode: string;
  disabled: boolean;
  lockValue: number | null;
  modalBodyMaxHeight: number;
  name: string;
};

const ModalBody = ({
  allowNegative,
  cultureCode,
  disabled,
  lockValue,
  modalBodyMaxHeight,
  name
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const [columnsRef, { height: columnsHeight }] = useElementSize();
  const [totalContainerRef, { height: totalContainerHeight }] =
    useElementSize();

  const { values } = useRoboAdviceForm();
  const [clientTypeKey, fieldName] = name.split('.');
  const fieldsValue = values?.[clientTypeKey]?.[fieldName];
  const fields = !fieldsValue || !Array.isArray(fieldsValue) ? [] : fieldsValue;
  const doesAnyFieldHasAccountNumber = fields.some(
    field => field?.accountNumber !== undefined
  );

  return (
    <>
      <div ref={columnsRef} className={classes.columns}>
        <div className={classes.nameColumn}>{i18n('shared.name')}</div>
        <div
          className={classNames({
            [classes.valueAccountColumn]: doesAnyFieldHasAccountNumber
          })}
        >
          {i18n('shared.amount')}
        </div>
        {doesAnyFieldHasAccountNumber && (
          <div className={classes.valueAccountColumn}>
            {i18n('shared.account')}
          </div>
        )}
      </div>

      <div data-testid={'detalized-number-popover'}>
        <Scrollbars
          autoHeightMax={
            modalBodyMaxHeight -
            columnsHeight -
            totalContainerHeight -
            totalContainerMarginTop
          }
        >
          <Details
            name={name}
            disabled={disabled}
            allowNegative={allowNegative}
          />
        </Scrollbars>
      </div>

      <div ref={totalContainerRef} className={classes.totalContainer}>
        {!isNil(lockValue) && (
          <>
            <div>{i18n('roboAdvice.detalizedNumberInput.total')}</div>

            <div>{formatNumber(cultureCode, lockValue, 0, 0)}</div>
          </>
        )}
      </div>
    </>
  );
};

export default ModalBody;
