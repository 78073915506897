import { z } from 'zod';

import { proposalSustainabilityProductAttributeSchema } from 'defaults/defaultRoboAdviceForm';
import { ProductAttributeType } from 'features/roboAdvice/adviceSession/shared/constants';
import { filterNil } from 'features/shared/utils/filters';

type MapCustomAttributesToProposalSustainabilityTableArguments = {
  customAttributesConfig: {
    productAttributeTxt1: z.infer<
      typeof proposalSustainabilityProductAttributeSchema
    > | null;
    productAttributeTxt2: z.infer<
      typeof proposalSustainabilityProductAttributeSchema
    > | null;
    productAttributeTxt3: z.infer<
      typeof proposalSustainabilityProductAttributeSchema
    > | null;
    productAttributeTxt4: z.infer<
      typeof proposalSustainabilityProductAttributeSchema
    > | null;
    productAttributeTxt5: z.infer<
      typeof proposalSustainabilityProductAttributeSchema
    > | null;
    productAttributeBinary1: z.infer<
      typeof proposalSustainabilityProductAttributeSchema
    > | null;
    productAttributeBinary2: z.infer<
      typeof proposalSustainabilityProductAttributeSchema
    > | null;
    productAttributeBinary3: z.infer<
      typeof proposalSustainabilityProductAttributeSchema
    > | null;
    productAttributeBinary4: z.infer<
      typeof proposalSustainabilityProductAttributeSchema
    > | null;
    productAttributeBinary5: z.infer<
      typeof proposalSustainabilityProductAttributeSchema
    > | null;
  };
};

export const mapCustomAttributesToSustainabilityTable = ({
  customAttributesConfig
}: MapCustomAttributesToProposalSustainabilityTableArguments) => {
  const {
    productAttributeBinary1,
    productAttributeBinary2,
    productAttributeBinary3,
    productAttributeBinary4,
    productAttributeBinary5,
    productAttributeTxt1,
    productAttributeTxt2,
    productAttributeTxt3,
    productAttributeTxt4,
    productAttributeTxt5
  } = customAttributesConfig;

  const productAttributeBinaries = [
    productAttributeBinary1,
    productAttributeBinary2,
    productAttributeBinary3,
    productAttributeBinary4,
    productAttributeBinary5
  ]
    .map(
      (productAttribute, index) =>
        productAttribute && {
          name: `productAttributeBinary${index + 1}`,
          ...productAttribute
        }
    )
    .filter(filterNil);
  const productAttributeTxts = [
    productAttributeTxt1,
    productAttributeTxt2,
    productAttributeTxt3,
    productAttributeTxt4,
    productAttributeTxt5
  ]
    .map(
      (productAttribute, index) =>
        productAttribute && {
          name: `productAttributeTxt${index + 1}`,
          ...productAttribute
        }
    )
    .filter(filterNil);

  const productAttributes = [
    ...productAttributeTxts.map(attr => ({
      ...attr,
      type: ProductAttributeType.text
    })),
    ...productAttributeBinaries.map(attr => ({
      ...attr,
      type: ProductAttributeType.binary
    }))
  ].sort((a, b) => a.order - b.order);

  return productAttributes;
};
