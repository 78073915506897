import classNames from 'classnames';
import debounce from 'lodash.debounce';
import { useCallback, useState, useEffect } from 'react';
import { useElementSize } from 'usehooks-ts';

import { clientsTable } from '../clientsTable';
import { useHolisticViewStore } from '../services/holisticViewStore';
import useSearchClients from '../useSearchClients';
import useClients from './useClients';
import useReadAdviceSessions from './useReadAdviceSessions';
import Button from 'features/shared/components/button';
import Checkbox from 'features/shared/components/checkbox/index';
import CompanyIcon from 'features/shared/components/icon/companyIcon';
import PersonIcon from 'features/shared/components/icon/personIcon';
import Modal from 'features/shared/components/modal/index';
import { useModalBodyMaxDimensions } from 'features/shared/components/modal/useModalBodyMaxDimensions';
import TableBody from 'features/shared/components/table/tableBody';
import TableBodyRow from 'features/shared/components/table/tableBodyRow';
import SearchInput from 'features/shared/components/textInput/search.js';
import { ClientTypes, ClientType } from 'features/shared/constants/session';
import { Spacing } from 'features/shared/constants/spacing';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import TableComponent from 'features/sharedModules/table/components/table';

// TODO: add types:
const AnyTableBodyRow = TableBodyRow as any;
const AnySearchInput = SearchInput as any;

const paginationContainerMarginTop = Spacing.spacing01;
const searchBarMarginBottom = 30;
const tableBodyRowHeight = 65;

const useStyles = createUseStyles({
  radioButton: {
    width: '30px'
  },
  clientType: {
    width: '50px',
    height: tableBodyRowHeight,
    textAlign: 'center'
  },
  searchBar: {
    marginBottom: searchBarMarginBottom
  },
  headerCell: {
    whiteSpace: 'nowrap'
  },
  paginationContainer: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: paginationContainerMarginTop
  },
  paginationItemsNumberInfo: {
    margin: '0 0 16px',
    width: '100%'
  }
});

const TypeIcon = ({ type }: { type: ClientType }) => {
  if (type === ClientTypes.person) {
    return <PersonIcon />;
  }

  if (type === ClientTypes.company) {
    return <CompanyIcon />;
  }

  return null;
};

const CustomersPopup = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const holisticViewStore = useHolisticViewStore();
  const { items, totalItemsNumber } = useClients();
  const searchClients = useSearchClients();
  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const { useTableStore } = clientsTable;
  const tableStore = useTableStore.getState();
  const readAdviceSessions = useReadAdviceSessions();
  const { modalBodyMaxHeight, headerRef, footerRef } =
    useModalBodyMaxDimensions();
  const [paginationRef, { height: paginationHeight }] = useElementSize();
  const [searchBarRef, { height: searchBarHeight }] = useElementSize();
  const [headerRowRef, { height: headerRowHeight }] = useElementSize();

  const debouncedChangeHandler = debounce(query => searchClients(query), 300);

  const softResetPopup = useCallback(() => {
    holisticViewStore.setIsAddCustomerPopupOpen(false);
    holisticViewStore.setIsSearchModeActive(false);
    setSelectedClients([]);
    tableStore.changePageNumber(0);
  }, []);

  const onConfirm = useCallback(() => {
    selectedClients.forEach(clientId => {
      holisticViewStore.setSelectedClient(clientId);
    });
    softResetPopup();
    readAdviceSessions();
  }, [selectedClients]);

  useEffect(() => {
    if (modalBodyMaxHeight > 0) {
      tableStore.changePageSize(
        Math.floor(
          (modalBodyMaxHeight -
            (searchBarHeight + searchBarMarginBottom) -
            (paginationHeight + paginationContainerMarginTop) -
            headerRowHeight) /
            tableBodyRowHeight
        )
      );
    }
  }, [modalBodyMaxHeight]);

  return (
    <Modal
      footer={
        <Button disabled={selectedClients.length === 0} onClick={onConfirm}>
          {i18n('tools.holisticView.confirm')}
        </Button>
      }
      header={i18n('tools.holisticView.addCustomerForHolisticOverview')}
      isOpen={holisticViewStore.isAddCustomerPopupOpen}
      onRequestClose={() => {
        softResetPopup();
      }}
      headerRef={headerRef}
      footerRef={footerRef}
    >
      <div>
        <div ref={searchBarRef} className={classes.searchBar}>
          <AnySearchInput
            placeholder={i18n(
              'admin.appAdmin.advisorSolution.text.searchInput.placeholder'
            )}
            onChange={event => debouncedChangeHandler(event.target.value)}
          />
        </div>

        <TableComponent
          module={clientsTable}
          header={[
            {
              title: i18n('tools.holisticView.type'),
              orderKey: 'type',
              isOrderable: true,
              className: classes.headerCell
            },
            {
              title: i18n('tools.holisticView.name'),
              orderKey: 'name',
              isOrderable: true,
              className: classes.headerCell
            },
            {
              title: ''
            }
          ]}
          totalItemsNumber={totalItemsNumber}
          isLoading={holisticViewStore.isReadClientsPending}
          paginationProps={{
            isRowsPerPageDropdownVisible: false,
            classes: {
              container: classes.paginationContainer,
              itemsNumberInfo: classes.paginationItemsNumberInfo
            },
            paginationContainerRef: paginationRef
          }}
          headerRowRef={headerRowRef}
        >
          <TableBody
            noDataTitle={i18n('tools.holisticView.noClientsFound')}
            cellsNumber={3}
          >
            {items.map(client => (
              <AnyTableBodyRow
                key={client.id}
                onClick={e => {
                  setSelectedClients(state => {
                    const radioIndex = state.indexOf(client.id);
                    if (radioIndex !== -1) {
                      const newSelectedClients = [...state];
                      newSelectedClients.splice(radioIndex, 1);
                      return newSelectedClients;
                    } else {
                      return [...state, client.id];
                    }
                  });
                }}
              >
                {({ bodyRowCellClassName }) => (
                  <>
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.clientType
                      )}
                    >
                      <TypeIcon type={client.type} />
                    </td>
                    <td className={bodyRowCellClassName}>{client.name}</td>
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.radioButton
                      )}
                    >
                      <Checkbox
                        width={25}
                        height={25}
                        checked={selectedClients.includes(client.id)}
                        onClick={e => {}}
                        onChange={() => {}}
                      />
                    </td>
                  </>
                )}
              </AnyTableBodyRow>
            ))}
          </TableBody>
        </TableComponent>
      </div>
    </Modal>
  );
};

export default CustomersPopup;
