import { z } from 'zod';

import {
  AvailablePortfoliosMappingModes,
  historicalReturnBenchmarkTypes,
  productAttributeSchema
} from './shared';
import { PortfolioModes } from 'features/roboAdvice/adviceSession/shared/constants';
import { AvailablePortfoliosModes } from 'features/roboAdvice/adviceSession/shared/constants';

export const modelPortfolioSustainabilityProductAttributeSchema =
  productAttributeSchema.omit({
    location: true
  });

export const advisoryComponentsSchema = z.object({
  availablePortfoliosMapping: z.record(
    z.string(),
    z.array(z.union([z.string(), z.number()]))
  ),
  availablePortfoliosMappingMode: z.nativeEnum(AvailablePortfoliosMappingModes),
  availablePortfoliosMappingPPA: z.record(
    z.union([z.string(), z.number()]),
    z.record(z.array(z.number()))
  ),
  availablePortfoliosMode: z.nativeEnum(AvailablePortfoliosModes),
  customPortfolio: z.object({
    assetClassAllocation: z.object({
      advisorNotes: z.object({
        enabled: z.boolean(),
        required: z.boolean()
      })
    }),
    fundAllocation: z.object({
      advancedSuitabilityAssessment: z.boolean(),
      advisorNotes: z.object({
        enabled: z.boolean(),
        required: z.boolean()
      }),
      showOverview: z.boolean(),
      showSustainability: z.boolean(),
      showPreferenceCriteria: z.boolean(),
      showSumAllocation: z.boolean(),
      productAttributeTxt1: z.nullable(productAttributeSchema),
      productAttributeTxt2: z.nullable(productAttributeSchema),
      productAttributeTxt3: z.nullable(productAttributeSchema),
      productAttributeTxt4: z.nullable(productAttributeSchema),
      productAttributeTxt5: z.nullable(productAttributeSchema),
      productAttributeBinary1: z.nullable(productAttributeSchema),
      productAttributeBinary2: z.nullable(productAttributeSchema),
      productAttributeBinary3: z.nullable(productAttributeSchema),
      productAttributeBinary4: z.nullable(productAttributeSchema),
      productAttributeBinary5: z.nullable(productAttributeSchema)
    }),
    fundsUnavailableWarning: z.boolean()
  }),
  customPortfolioEnableHistoricalReturn: z.boolean(),
  customPortfolioLabel: z.record(z.string()),
  customPortfolioModelPortfolioComparison: z.boolean(),
  customPortfolioUseCustomAssetClassTable: z.boolean(),
  customPortfolioStartWithModel: z.boolean(),
  customPortfolioFundSelectionAutoGenerated: z.boolean(),
  customPortfolioAllocatingInAmountEnabled: z.boolean(),
  existingPortfolioComparison: z.boolean(),
  customPortfolioAllowAssetClassModification: z.boolean(),
  historicalReturnEnabled: z.boolean(),
  modelPortfolioSustainabilityTab: z.object({
    enabled: z.boolean(),
    productAttributeTxt1: z.nullable(
      modelPortfolioSustainabilityProductAttributeSchema
    ),
    productAttributeTxt2: z.nullable(
      modelPortfolioSustainabilityProductAttributeSchema
    ),
    productAttributeTxt3: z.nullable(
      modelPortfolioSustainabilityProductAttributeSchema
    ),
    productAttributeTxt4: z.nullable(
      modelPortfolioSustainabilityProductAttributeSchema
    ),
    productAttributeTxt5: z.nullable(
      modelPortfolioSustainabilityProductAttributeSchema
    ),
    productAttributeBinary1: z.nullable(
      modelPortfolioSustainabilityProductAttributeSchema
    ),
    productAttributeBinary2: z.nullable(
      modelPortfolioSustainabilityProductAttributeSchema
    ),
    productAttributeBinary3: z.nullable(
      modelPortfolioSustainabilityProductAttributeSchema
    ),
    productAttributeBinary4: z.nullable(
      modelPortfolioSustainabilityProductAttributeSchema
    ),
    productAttributeBinary5: z.nullable(
      modelPortfolioSustainabilityProductAttributeSchema
    )
  }),
  defaultPortfolioMode: z.nativeEnum(PortfolioModes),
  isPortfolioPreviewHidden: z.boolean(),
  isThemesHidden: z.boolean(),
  modelPortfolioLabel: z.record(z.string()),
  portfolioModes: z.array(z.nativeEnum(PortfolioModes)),
  statisticsTableEnabled: z.boolean(),
  historicalReturnCompareWithBenchmark: z.boolean(),
  historicalReturnBenchmarkType: z.nativeEnum(historicalReturnBenchmarkTypes),
  historicalReturnShowBarCharts: z.boolean(),
  financialSituation: z.object({
    amountForAdvice: z.object({
      advisorNotes: z.object({
        enabled: z.boolean(),
        required: z.boolean()
      })
    })
  })
});

export type AdvisoryComponents = z.infer<typeof advisoryComponentsSchema>;

export const defaultAdvisoryComponents: AdvisoryComponents = {
  availablePortfoliosMapping: {},
  availablePortfoliosMappingMode: AvailablePortfoliosMappingModes.tenant,
  availablePortfoliosMappingPPA: {},
  availablePortfoliosMode: AvailablePortfoliosModes.all,
  customPortfolio: {
    assetClassAllocation: {
      advisorNotes: {
        enabled: true,
        required: false
      }
    },
    fundAllocation: {
      advancedSuitabilityAssessment: false,
      advisorNotes: {
        enabled: true,
        required: false
      },
      showOverview: true,
      showSustainability: false,
      showPreferenceCriteria: false,
      showSumAllocation: false,
      productAttributeTxt1: null,
      productAttributeTxt2: null,
      productAttributeTxt3: null,
      productAttributeTxt4: null,
      productAttributeTxt5: null,
      productAttributeBinary1: null,
      productAttributeBinary2: null,
      productAttributeBinary3: null,
      productAttributeBinary4: null,
      productAttributeBinary5: null
    },
    fundsUnavailableWarning: false
  },
  customPortfolioEnableHistoricalReturn: true,
  customPortfolioLabel: {
    en: 'Custom portfolio',
    no: 'Manuell portefølje'
  },
  customPortfolioModelPortfolioComparison: true,
  customPortfolioStartWithModel: true,
  customPortfolioUseCustomAssetClassTable: true,
  customPortfolioFundSelectionAutoGenerated: true,
  customPortfolioAllocatingInAmountEnabled: false,
  existingPortfolioComparison: true,
  customPortfolioAllowAssetClassModification: true,
  defaultPortfolioMode: 'modelPortfolio',
  historicalReturnEnabled: false,
  modelPortfolioSustainabilityTab: {
    enabled: false,
    productAttributeTxt1: null,
    productAttributeTxt2: null,
    productAttributeTxt3: null,
    productAttributeTxt4: null,
    productAttributeTxt5: null,
    productAttributeBinary1: null,
    productAttributeBinary2: null,
    productAttributeBinary3: null,
    productAttributeBinary4: null,
    productAttributeBinary5: null
  },
  isPortfolioPreviewHidden: false,
  isThemesHidden: false,
  modelPortfolioLabel: {
    en: 'Model portfolio',
    no: 'Modellportefølje'
  },
  portfolioModes: [
    PortfolioModes.modelPortfolio,
    PortfolioModes.customPortfolio
  ],
  statisticsTableEnabled: false,
  historicalReturnCompareWithBenchmark: false,
  historicalReturnBenchmarkType:
    historicalReturnBenchmarkTypes.categoryBenchmark,
  historicalReturnShowBarCharts: false,
  financialSituation: {
    amountForAdvice: {
      advisorNotes: {
        enabled: false,
        required: false
      }
    }
  }
};
