import { z } from 'zod';

import { attachmentSchema, sectionSchema } from './shared';

export const templateSchema = z.object({
  id: z.string(),
  templateName: z.string(),
  label: z.string(),
  filename: z.string(),
  allowProductAttachments: z.boolean(),
  language: z.string(),
  attachments: z.array(attachmentSchema),
  sections: z.array(sectionSchema)
});

export const reportAdviceSchema = z.array(templateSchema);

export const defaultReportAdvice: z.infer<typeof reportAdviceSchema> = [
  {
    allowProductAttachments: false,
    attachments: [],
    filename: 'Wealthplan for {{investorName}} - {{yyyy}}.pdf',
    id: 'default_template',
    label: 'Default template',
    language: 'en',
    sections: [],
    templateName: 'default_template.rml'
  }
];
