import chroma from 'chroma-js';

import { useGoalsStore } from '../../shared/services/goalsStore';
import { Spacing } from 'features/shared/constants/spacing';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  legend: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: Spacing.spacing04,
    margin: `${Spacing.spacing02}px 0`,
    padding: 0,
    width: '100%'
  },
  goal: {
    display: 'flex',
    alignItems: 'center',
    color: theme.secondaryColor,
    lineHeight: '19px'
  },
  rectangle: {
    display: 'inline-block',
    marginLeft: Spacing.spacing01,
    height: 24,
    width: 24
  }
}));

const Legend = () => {
  const classes = useStyles();
  const { goals } = useGoalsStore();
  const theme: { chartPortfolioColors: string[] } = useTheme();

  return (
    <ul className={classes.legend}>
      {goals.map((goal, index) => (
        <li key={index} className={classes.goal}>
          <span>{goal.name}</span>
          <span
            className={classes.rectangle}
            style={{
              backgroundColor: chroma(theme.chartPortfolioColors[index])
                .alpha(0.5)
                .css()
            }}
          />
        </li>
      ))}
    </ul>
  );
};

export default Legend;
