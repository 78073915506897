export const RoboAdviceClientsListPages = {
  allClients: 'all-clients',
  myClients: 'my-clients'
};

export const Layouts = {
  horizontal: 'horizontal',
  vertical: 'vertical'
} as const;

export const RoboAdviceClientPages = {
  clientInformation: 'client-information',
  adviceSessions: 'advice-sessions',
  kyc: 'kyc'
};

export const RoboAdviceAdviceSessionPages = {
  adviceInformation: 'advice-information',
  purposeAndRisk: 'purpose-and-risk',
  knowledgeAndExperience: 'knowledge-and-experience',
  financialSituation: 'financial-situation',
  sustainability: 'sustainability',
  advisory: 'advisory',
  proposal: 'proposal'
} as const;

export const RoboAdviceOrderExecutionPages = {
  orderInformation: 'order-information',
  orderInformationPage2: 'order-information-page-2',
  instruments: 'instruments',
  order: 'order',
  targetMarketAssessment: 'target-market-assessment'
} as const;

export const Countries = [
  { key: 'ad', title: 'Andorra' },
  { key: 'ae', title: 'United Arab Emirates' },
  { key: 'af', title: 'Afghanistan' },
  { key: 'ag', title: 'Antigua and Barbuda' },
  { key: 'ai', title: 'Anguilla' },
  { key: 'al', title: 'Albania' },
  { key: 'am', title: 'Armenia' },
  { key: 'ao', title: 'Angola' },
  { key: 'aq', title: 'Antarctica' },
  { key: 'ar', title: 'Argentina' },
  { key: 'as', title: 'American Samoa' },
  { key: 'at', title: 'Austria' },
  { key: 'au', title: 'Australia' },
  { key: 'aw', title: 'Aruba' },
  { key: 'ax', title: 'Åland Islands' },
  { key: 'az', title: 'Azerbaijan' },
  { key: 'ba', title: 'Bosnia and Herzegovina' },
  { key: 'bb', title: 'Barbados' },
  { key: 'bd', title: 'Bangladesh' },
  { key: 'be', title: 'Belgium' },
  { key: 'bf', title: 'Burkina Faso' },
  { key: 'bg', title: 'Bulgaria' },
  { key: 'bh', title: 'Bahrain' },
  { key: 'bi', title: 'Burundi' },
  { key: 'bj', title: 'Benin' },
  { key: 'bl', title: 'Saint Barthélemy' },
  { key: 'bm', title: 'Bermuda' },
  { key: 'bn', title: 'Brunei Darussalam' },
  { key: 'bo', title: 'Bolivia (Plurinational State of)' },
  { key: 'bq', title: 'Bonaire, Sint Eustatius and Saba' },
  { key: 'br', title: 'Brazil' },
  { key: 'bs', title: 'Bahamas' },
  { key: 'bt', title: 'Bhutan' },
  { key: 'bv', title: 'Bouvet Island' },
  { key: 'bw', title: 'Botswana' },
  { key: 'by', title: 'Belarus' },
  { key: 'bz', title: 'Belize' },
  { key: 'ca', title: 'Canada' },
  { key: 'cc', title: 'Cocos (Keeling) Islands' },
  { key: 'cd', title: 'Congo, Democratic Republic of the' },
  { key: 'cf', title: 'Central African Republic' },
  { key: 'cg', title: 'Congo' },
  { key: 'ch', title: 'Switzerland' },
  { key: 'ci', title: "Côte d'Ivoire" },
  { key: 'ck', title: 'Cook Islands' },
  { key: 'cl', title: 'Chile' },
  { key: 'cm', title: 'Cameroon' },
  { key: 'cn', title: 'China' },
  { key: 'co', title: 'Colombia' },
  { key: 'cr', title: 'Costa Rica' },
  { key: 'cu', title: 'Cuba' },
  { key: 'cv', title: 'Cabo Verde' },
  { key: 'cw', title: 'Curaçao' },
  { key: 'cx', title: 'Christmas Island' },
  { key: 'cy', title: 'Cyprus' },
  { key: 'cz', title: 'Czechia' },
  { key: 'de', title: 'Germany' },
  { key: 'dj', title: 'Djibouti' },
  { key: 'dk', title: 'Denmark' },
  { key: 'dm', title: 'Dominica' },
  { key: 'do', title: 'Dominican Republic' },
  { key: 'dz', title: 'Algeria' },
  { key: 'ec', title: 'Ecuador' },
  { key: 'ee', title: 'Estonia' },
  { key: 'eg', title: 'Egypt' },
  { key: 'eh', title: 'Western Sahara' },
  { key: 'er', title: 'Eritrea' },
  { key: 'es', title: 'Spain' },
  { key: 'et', title: 'Ethiopia' },
  { key: 'fi', title: 'Finland' },
  { key: 'fj', title: 'Fiji' },
  { key: 'fk', title: 'Falkland Islands (Malvinas)' },
  { key: 'fm', title: 'Micronesia (Federated States of)' },
  { key: 'fo', title: 'Faroe Islands' },
  { key: 'fr', title: 'France' },
  { key: 'ga', title: 'Gabon' },
  { key: 'gb', title: 'United Kingdom of Great Britain and Northern Ireland' },
  { key: 'gd', title: 'Grenada' },
  { key: 'ge', title: 'Georgia' },
  { key: 'gf', title: 'French Guiana' },
  { key: 'gg', title: 'Guernsey' },
  { key: 'gh', title: 'Ghana' },
  { key: 'gi', title: 'Gibraltar' },
  { key: 'gl', title: 'Greenland' },
  { key: 'gm', title: 'Gambia' },
  { key: 'gn', title: 'Guinea' },
  { key: 'gp', title: 'Guadeloupe' },
  { key: 'gq', title: 'Equatorial Guinea' },
  { key: 'gr', title: 'Greece' },
  { key: 'gs', title: 'South Georgia and the South Sandwich Islands' },
  { key: 'gt', title: 'Guatemala' },
  { key: 'gu', title: 'Guam' },
  { key: 'gw', title: 'Guinea-Bissau' },
  { key: 'gy', title: 'Guyana' },
  { key: 'hk', title: 'Hong Kong' },
  { key: 'hm', title: 'Heard Island and McDonald Islands' },
  { key: 'hn', title: 'Honduras' },
  { key: 'hr', title: 'Croatia' },
  { key: 'ht', title: 'Haiti' },
  { key: 'hu', title: 'Hungary' },
  { key: 'id', title: 'Indonesia' },
  { key: 'ie', title: 'Ireland' },
  { key: 'il', title: 'Israel' },
  { key: 'im', title: 'Isle of Man' },
  { key: 'in', title: 'India' },
  { key: 'io', title: 'British Indian Ocean Territory' },
  { key: 'iq', title: 'Iraq' },
  { key: 'ir', title: 'Iran (Islamic Republic of)' },
  { key: 'is', title: 'Iceland' },
  { key: 'it', title: 'Italy' },
  { key: 'je', title: 'Jersey' },
  { key: 'jm', title: 'Jamaica' },
  { key: 'jo', title: 'Jordan' },
  { key: 'jp', title: 'Japan' },
  { key: 'ke', title: 'Kenya' },
  { key: 'kg', title: 'Kyrgyzstan' },
  { key: 'kh', title: 'Cambodia' },
  { key: 'ki', title: 'Kiribati' },
  { key: 'km', title: 'Comoros' },
  { key: 'kn', title: 'Saint Kitts and Nevis' },
  { key: 'kp', title: "Korea (Democratic People's Republic of)" },
  { key: 'kr', title: 'Korea, Republic of' },
  { key: 'kw', title: 'Kuwait' },
  { key: 'ky', title: 'Cayman Islands' },
  { key: 'kz', title: 'Kazakhstan' },
  { key: 'la', title: "Lao People's Democratic Republic" },
  { key: 'lb', title: 'Lebanon' },
  { key: 'lc', title: 'Saint Lucia' },
  { key: 'li', title: 'Liechtenstein' },
  { key: 'lk', title: 'Sri Lanka' },
  { key: 'lr', title: 'Liberia' },
  { key: 'ls', title: 'Lesotho' },
  { key: 'lt', title: 'Lithuania' },
  { key: 'lu', title: 'Luxembourg' },
  { key: 'lv', title: 'Latvia' },
  { key: 'ly', title: 'Libya' },
  { key: 'ma', title: 'Morocco' },
  { key: 'mc', title: 'Monaco' },
  { key: 'md', title: 'Moldova, Republic of' },
  { key: 'me', title: 'Montenegro' },
  { key: 'mf', title: 'Saint Martin (French part)' },
  { key: 'mg', title: 'Madagascar' },
  { key: 'mh', title: 'Marshall Islands' },
  { key: 'mk', title: 'North Macedonia' },
  { key: 'ml', title: 'Mali' },
  { key: 'mm', title: 'Myanmar' },
  { key: 'mn', title: 'Mongolia' },
  { key: 'mo', title: 'Macao' },
  { key: 'mp', title: 'Northern Mariana Islands' },
  { key: 'mq', title: 'Martinique' },
  { key: 'mr', title: 'Mauritania' },
  { key: 'ms', title: 'Montserrat' },
  { key: 'mt', title: 'Malta' },
  { key: 'mu', title: 'Mauritius' },
  { key: 'mv', title: 'Maldives' },
  { key: 'mw', title: 'Malawi' },
  { key: 'mx', title: 'Mexico' },
  { key: 'my', title: 'Malaysia' },
  { key: 'mz', title: 'Mozambique' },
  { key: 'na', title: 'Namibia' },
  { key: 'nc', title: 'New Caledonia' },
  { key: 'ne', title: 'Niger' },
  { key: 'nf', title: 'Norfolk Island' },
  { key: 'ng', title: 'Nigeria' },
  { key: 'ni', title: 'Nicaragua' },
  { key: 'nl', title: 'Netherlands, Kingdom of the' },
  { key: 'no', title: 'Norway' },
  { key: 'np', title: 'Nepal' },
  { key: 'nr', title: 'Nauru' },
  { key: 'nu', title: 'Niue' },
  { key: 'nz', title: 'New Zealand' },
  { key: 'om', title: 'Oman' },
  { key: 'pa', title: 'Panama' },
  { key: 'pe', title: 'Peru' },
  { key: 'pf', title: 'French Polynesia' },
  { key: 'pg', title: 'Papua New Guinea' },
  { key: 'ph', title: 'Philippines' },
  { key: 'pk', title: 'Pakistan' },
  { key: 'pl', title: 'Poland' },
  { key: 'pm', title: 'Saint Pierre and Miquelon' },
  { key: 'pn', title: 'Pitcairn' },
  { key: 'pr', title: 'Puerto Rico' },
  { key: 'ps', title: 'Palestine, State of' },
  { key: 'pt', title: 'Portugal' },
  { key: 'pw', title: 'Palau' },
  { key: 'py', title: 'Paraguay' },
  { key: 'qa', title: 'Qatar' },
  { key: 're', title: 'Réunion' },
  { key: 'ro', title: 'Romania' },
  { key: 'rs', title: 'Serbia' },
  { key: 'ru', title: 'Russian Federation' },
  { key: 'rw', title: 'Rwanda' },
  { key: 'sa', title: 'Saudi Arabia' },
  { key: 'sb', title: 'Solomon Islands' },
  { key: 'sc', title: 'Seychelles' },
  { key: 'sd', title: 'Sudan' },
  { key: 'se', title: 'Sweden' },
  { key: 'sg', title: 'Singapore' },
  { key: 'sh', title: 'Saint Helena, Ascension and Tristan da Cunha' },
  { key: 'si', title: 'Slovenia' },
  { key: 'sj', title: 'Svalbard and Jan Mayen' },
  { key: 'sk', title: 'Slovakia' },
  { key: 'sl', title: 'Sierra Leone' },
  { key: 'sm', title: 'San Marino' },
  { key: 'sn', title: 'Senegal' },
  { key: 'so', title: 'Somalia' },
  { key: 'sr', title: 'Suriname' },
  { key: 'ss', title: 'South Sudan' },
  { key: 'st', title: 'Sao Tome and Principe' },
  { key: 'sv', title: 'El Salvador' },
  { key: 'sx', title: 'Sint Maarten (Dutch part)' },
  { key: 'sy', title: 'Syrian Arab Republic' },
  { key: 'sz', title: 'Eswatini' },
  { key: 'tc', title: 'Turks and Caicos Islands' },
  { key: 'td', title: 'Chad' },
  { key: 'tf', title: 'French Southern Territories' },
  { key: 'tg', title: 'Togo' },
  { key: 'th', title: 'Thailand' },
  { key: 'tj', title: 'Tajikistan' },
  { key: 'tk', title: 'Tokelau' },
  { key: 'tl', title: 'Timor-Leste' },
  { key: 'tm', title: 'Turkmenistan' },
  { key: 'tn', title: 'Tunisia' },
  { key: 'to', title: 'Tonga' },
  { key: 'tr', title: 'Türkiye' },
  { key: 'tt', title: 'Trinidad and Tobago' },
  { key: 'tv', title: 'Tuvalu' },
  { key: 'tw', title: 'Taiwan, Province of China' },
  { key: 'tz', title: 'Tanzania, United Republic of' },
  { key: 'ua', title: 'Ukraine' },
  { key: 'ug', title: 'Uganda' },
  { key: 'um', title: 'United States Minor Outlying Islands' },
  { key: 'us', title: 'United States of America' },
  { key: 'uy', title: 'Uruguay' },
  { key: 'uz', title: 'Uzbekistan' },
  { key: 'va', title: 'Holy See' },
  { key: 'vc', title: 'Saint Vincent and the Grenadines' },
  { key: 've', title: 'Venezuela (Bolivarian Republic of)' },
  { key: 'vg', title: 'Virgin Islands (British)' },
  { key: 'vi', title: 'Virgin Islands (U.S.)' },
  { key: 'vn', title: 'Viet Nam' },
  { key: 'vu', title: 'Vanuatu' },
  { key: 'wf', title: 'Wallis and Futuna' },
  { key: 'ws', title: 'Samoa' },
  { key: 'ye', title: 'Yemen' },
  { key: 'yt', title: 'Mayotte' },
  { key: 'za', title: 'South Africa' },
  { key: 'zm', title: 'Zambia' },
  { key: 'zw', title: 'Zimbabwe' }
];

export const CountryCode = {
  Norway: 'no',
  Sweden: 'se',
  Denmark: 'dk',
  Finland: 'fi'
} as const;

export const SupportedENINCountryCodes = [
  CountryCode.Norway,
  CountryCode.Finland,
  CountryCode.Sweden,
  CountryCode.Denmark
] as const;

export const CountriesTaxNumberFormat = {
  no: '### ### ###',
  se: '######-####',
  dk: '########',
  fi: '#######-#'
} as const;

export const ClientClassifications = {
  retailClient: 'retailClient',
  eligibleCounterparty: 'eligibleCounterparty',
  professionalClient: 'professionalClient'
} as const;

export const MoneyOrigins = {
  salary: 'salary',
  heritage: 'heritage',
  gift: 'gift',
  gamblingLottery: 'gamblingLottery',
  realEstateSale: 'realEstateSale',
  companyRevenues: 'companyRevenues',
  excessLiquidity: 'excessLiquidity',
  other: 'other'
} as const;

export const SessionStatuses = {
  open: 'open',
  complete: 'complete'
} as const;

export const OrderExecutionType = 'Order Execution';

export const NewClientId = 'new-client';

export const FundAllocationTabs = {
  overview: 'overview',
  sustainability: 'sustainability',
  preferenceCriteria: 'preferenceCriteria'
} as const;
