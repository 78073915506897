import { Typography } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const usePageStyles = createUseStyles({
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  searchInputContainer: {
    width: '60%',
    marginBottom: 10
  },
  searchInput: {
    width: '100%'
  },
  switchSection: {
    alignItems: 'center',
    marginBottom: 10
  },
  switchLabel: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    marginRight: '1.2rem',
    width: 'auto'
  },
  dropdown: {
    maxWidth: '180px',
    marginBottom: 10
  },
  table: {
    marginTop: 10
  },
  saveButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '37px'
  }
});
