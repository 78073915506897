import React from 'react';

import { useForm as useRoboAdviceForm } from '../../form/services/form';
import { useIsSessionReadOnly } from '../../main/services/selectors';
import Widget from '../../shared/components/widget';
import { FieldSections, getFinancialSituationFields } from '../constants';
import { usePersonAssetsTotal } from '../services/selectors';
import { RenderFields } from './shared.js';
import { useReadAccountsData } from './useReadAccountsData';
import { ReactComponent as RefreshIcon } from 'assets/refresh.svg';
import { ClientTypes } from 'features/shared/constants/session';
import { Spacing } from 'features/shared/constants/spacing';
import { defaultConfirmation } from 'features/sharedInstances/defaultConfirmation';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import InputErrorMessage from 'features/sharedModules/errors/components/inputErrorMessage';
import { ErrorMessage } from 'features/sharedModules/errors/types';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  refreshIcon: {
    marginLeft: Spacing.spacing02,
    cursor: 'pointer',

    '& path': {
      fill: theme.accentColor
    }
  },
  errorsContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

type Props = {
  hasError: boolean;
  errors: ErrorMessage[];
};

const PersonAssets = ({ hasError, errors }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const {
    roboAdviceForm: {
      financialSituation: {
        assets: { person: personAssets },
        oneFieldInAssetsRequired,
        accounts: {
          sync: { enabled: isAccountsSyncEnabled }
        }
      }
    }
  } = useCustomerConfig();
  const fields = getFinancialSituationFields(
    personAssets,
    FieldSections.personAssets,
    ClientTypes.person
  );
  const total = usePersonAssetsTotal({
    useRoboAdviceForm,
    personAssetsFields: fields
  });
  const isSessionReadOnly = useIsSessionReadOnly();
  const readAccountsData = useReadAccountsData();
  const defaultConfirmationStoreState =
    defaultConfirmation.useConfirmationStore.getState();

  return (
    <div className={classes.root}>
      <div className={classes.errorsContainer}>
        {errors.map(error => (
          <InputErrorMessage message={error.alertMessage} />
        ))}
      </div>
      <Widget
        header={i18n('roboAdvice.financialSituation.assets')}
        total={{
          label: i18n('roboAdvice.financialSituation.sumAssets'),
          value: total
        }}
        headerButton={
          isAccountsSyncEnabled ? (
            <RefreshIcon
              className={classes.refreshIcon}
              onClick={() => {
                defaultConfirmationStoreState.open(
                  i18n(
                    'roboAdvice.financialSituation.accountsSync.modal.content'
                  ),
                  readAccountsData,
                  () => {},
                  i18n(
                    'roboAdvice.financialSituation.accountsSync.modal.header'
                  ),
                  i18n('shared.confirm'),
                  i18n('shared.cancel')
                );
              }}
            />
          ) : undefined
        }
        required={oneFieldInAssetsRequired}
        doShowFooterSeparator={true}
        elementId={'person-assets'}
        hasError={hasError}
      >
        <RenderFields
          fields={fields}
          adjustElement={(field, element) =>
            React.cloneElement(element, {
              disabled: isSessionReadOnly
            })
          }
        />
      </Widget>
    </div>
  );
};

export default PersonAssets;
