import { ValidationErrors } from 'final-form';
import setFieldTouched from 'final-form-set-field-touched';
import { isEmpty, omit } from 'ramda';

import store from 'features/main/app/store';
import sessionSelectors from 'features/shared/services/session/selectors';
import {
  createForm as ffCreateForm,
  FormApi
} from 'features/sharedModules/finalForm/services/finalForm';
import {
  createUseFinalFormAdapter,
  FormState
} from 'features/sharedModules/zustand/components/finalFormAdapter';

export const OVERRIDE_COST_MIN_VALUE = 0;
export const OVERRIDE_COST_MAX_VALUE = 50;

export type OverrideCost = {
  platformFee?: number;
  custodyFeePercent?: number;
  custodyFeeAmount?: number;
  advisoryOnboardingFee?: number;
  advisoryPurchasingFee?: number;
};

export type OverrideFundCosts = {
  [fund: string]: {
    fundManagementFee?: number;
    fundPurchaseFee?: number;
    fundReturnCommissionPaidToClient?: number;
    fundTransactionCost?: number;
  };
};

export type CostFormValues = {
  overrideCost?: OverrideCost;
  overrideFundCosts?: OverrideFundCosts;
  overrideFundOngoingFeesAdvisorNotes?: string;
  overrideFundOneTimeFeesAdvisorNotes?: string;
  overrideSummaryAnnualOngoingFeesAdvisorNotes?: string;
  overrideSummaryCustodyFeesAdvisorNotes?: string;
  overrideSummaryOneTimeFeesAdvisorNotes?: string;
};

export type CostFormState = FormState<CostFormValues>;

export type CostForm = FormApi<CostFormValues>;

const getOverrideCostValidationError = (overrideCost?: OverrideCost) => {
  if (overrideCost && !isEmpty(overrideCost)) {
    const overrideCostPercent = omit(['custodyFeeAmount'], overrideCost);
    return Object.entries(overrideCostPercent).reduce((acc, [key, value]) => {
      return value > OVERRIDE_COST_MAX_VALUE ? { ...acc, [key]: value } : acc;
    }, {});
  }
  return {};
};

const getOverrideFundCostsValidationError = (overrideFundCosts?: {
  [fund: string]: {
    fundManagementFee?: number;
    fundPurchaseFee?: number;
    fundReturnCommissionPaidToClient?: number;
    fundTransactionCost?: number;
  };
}) => {
  if (overrideFundCosts) {
    return Object.entries(overrideFundCosts).reduce(
      (acc, [overrideFundCostsKey, overrideFundCostsValue]) => {
        const fundErrors = Object.entries(overrideFundCostsValue).reduce(
          (acc, [key, value]) => {
            return value > OVERRIDE_COST_MAX_VALUE
              ? {
                  ...acc,
                  [overrideFundCostsKey]: {
                    [key]:
                      'roboAdvice.proposal.cost.overrideCostValueErrorMessage'
                  }
                }
              : acc;
          },
          {}
        );

        return { ...acc, ...fundErrors };
      },
      {}
    );
  }

  return {};
};

const getOverrideCostAdvisorNotesValidationError = (
  value: string | undefined
) => (value ? null : 'roboAdvice.proposal.cost.emptyAdvisorNoteMessage');

export const costForm = ffCreateForm<CostFormValues>({
  onSubmit: () => {},
  validationDependencies: {
    customerConfig: [
      () => {
        const customerConfig = sessionSelectors.getCustomerConfig(
          store.getState()
        );
        return customerConfig;
      },
      store.subscribe
    ]
  },
  validate: (values, { customerConfig }) => {
    let errors: ValidationErrors = {};

    const {
      overrideCost,
      overrideFundCosts,
      overrideFundOngoingFeesAdvisorNotes,
      overrideFundOneTimeFeesAdvisorNotes,
      overrideSummaryAnnualOngoingFeesAdvisorNotes,
      overrideSummaryCustodyFeesAdvisorNotes,
      overrideSummaryOneTimeFeesAdvisorNotes
    } = values;

    const overrideCostValidationError =
      getOverrideCostValidationError(overrideCost);

    if (!isEmpty(overrideCostValidationError)) {
      errors = {
        ...errors,
        overrideCost: overrideCostValidationError
      };
    }

    const overrideFundCostsValidationError =
      getOverrideFundCostsValidationError(overrideFundCosts);

    if (!isEmpty(overrideFundCostsValidationError)) {
      errors = {
        ...errors,
        overrideFundCosts: overrideFundCostsValidationError
      };
    }

    if (
      customerConfig?.roboAdviceForm?.proposal
        ?.isOverrideSummaryAnnualOngoingFeesAdvisorNotesRequired
    ) {
      const advisorNotesValidationError =
        getOverrideCostAdvisorNotesValidationError(
          overrideSummaryAnnualOngoingFeesAdvisorNotes
        );

      if (advisorNotesValidationError) {
        errors = {
          ...errors,
          overrideSummaryAnnualOngoingFeesAdvisorNotes:
            advisorNotesValidationError
        };
      }
    }

    if (
      customerConfig?.roboAdviceForm?.proposal
        ?.isOverrideSummaryCustodyFeesAdvisorNotesRequired
    ) {
      const advisorNotesValidationError =
        getOverrideCostAdvisorNotesValidationError(
          overrideSummaryCustodyFeesAdvisorNotes
        );

      if (advisorNotesValidationError) {
        errors = {
          ...errors,
          overrideSummaryCustodyFeesAdvisorNotes: advisorNotesValidationError
        };
      }
    }

    if (
      customerConfig?.roboAdviceForm?.proposal
        ?.isOverrideSummaryOneTimeFeesAdvisorNotesRequired
    ) {
      const advisorNotesValidationError =
        getOverrideCostAdvisorNotesValidationError(
          overrideSummaryOneTimeFeesAdvisorNotes
        );

      if (advisorNotesValidationError) {
        errors = {
          ...errors,
          overrideSummaryOneTimeFeesAdvisorNotes: advisorNotesValidationError
        };
      }
    }

    if (
      customerConfig?.roboAdviceForm?.proposal
        ?.isOverrideFundOngoingFeesAdvisorNotesRequired
    ) {
      const advisorNotesValidationError =
        getOverrideCostAdvisorNotesValidationError(
          overrideFundOngoingFeesAdvisorNotes
        );

      if (advisorNotesValidationError) {
        errors = {
          ...errors,
          overrideFundOngoingFeesAdvisorNotes: advisorNotesValidationError
        };
      }
    }

    if (
      customerConfig?.roboAdviceForm?.proposal
        ?.isOverrideFundOneTimeFeesAdvisorNotesRequired
    ) {
      const advisorNotesValidationError =
        getOverrideCostAdvisorNotesValidationError(
          overrideFundOneTimeFeesAdvisorNotes
        );

      if (advisorNotesValidationError) {
        errors = {
          ...errors,
          overrideFundOneTimeFeesAdvisorNotes: advisorNotesValidationError
        };
      }
    }

    return errors;
  },
  mutators: {
    setFieldTouched
  }
});

export const useCostForm = createUseFinalFormAdapter({
  form: costForm,
  formSubscription: {
    values: true,
    errors: true,
    touched: true
  }
});
