import {
  PageStatuses,
  useReadDataListener
} from '../../shared/components/useReadDataListener';
import { getHistoricalReturnStatisticsData } from '../../shared/services/historicalReturnStatisticsSelectors';
import { usePageStore as useRiskScorePageStore } from '../services/pageStore';
import {
  useHistoricalReturnChartStore,
  useReadHistoricalReturnChartData
} from './useReadHistoricalReturnChartData';
import HistoricalReturnStatistics from 'features/roboAdvice/adviceSession/shared/components/historicalReturnStatistics';
import LoadingIndicator from 'features/shared/components/loadingIndicator/loadingIndicator';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const NUMBER_OF_ALLOWED_STATISTICS = 10;

const useStyles = createUseStyles(theme => ({
  noDataMessage: {
    color: theme.secondaryColor
  }
}));

type Props = {
  isSelected: boolean;
  selectedRisks: {
    className: string;
    color: string;
    name: string;
    risk: string;
  }[];
  compareWithExistingPortfolio: boolean;
};

const Statistics = ({
  isSelected,
  selectedRisks,
  compareWithExistingPortfolio
}: Props) => {
  const chartStoreData = useHistoricalReturnChartStore();
  const classes = useStyles();
  const i18n = useI18n();
  const { pageStatuses } = useRiskScorePageStore();
  const {
    roboAdvice: {
      riskScore: { historicalReturnStatsItems }
    }
  } = useCustomerConfig();

  useReadDataListener(
    pageStatuses.readHistoricalReturn10YChartData,
    isSelected,
    useReadHistoricalReturnChartData(
      'readHistoricalReturn10YChartData',
      '10Y',
      selectedRisks,
      compareWithExistingPortfolio
    )
  );

  const statisticsData = getHistoricalReturnStatisticsData(
    chartStoreData.chartData['10Y'],
    historicalReturnStatsItems,
    NUMBER_OF_ALLOWED_STATISTICS
  );

  return pageStatuses.readHistoricalReturn10YChartData ===
    PageStatuses.pending ? (
    <LoadingIndicator />
  ) : statisticsData ? (
    <HistoricalReturnStatistics
      data={statisticsData.data}
      headerNames={statisticsData.names}
    />
  ) : (
    <div className={classes.noDataMessage}>
      {i18n('roboAdvice.proposal.noHistoricalReturnData')}
    </div>
  );
};

export default Statistics;
