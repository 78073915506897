import create from 'zustand';

type ErrorsStoreData = {
  isErrorModeEnabled: boolean;
  isTabsMessageShown: boolean;
  isNextButtonMessageShown: boolean;
};

type ErrorsStoreApi = {
  reset: () => void;
  enableErrorMode: () => void;
  disableErrorMode: () => void;
  showTabsMessage: () => void;
  hideTabsMessage: () => void;
  showNextButtonMessage: () => void;
  hideNextButtonMessage: () => void;
};

const defaultData: ErrorsStoreData = {
  isErrorModeEnabled: false,
  isTabsMessageShown: false,
  isNextButtonMessageShown: false
};

type ErrorsStoreState = ErrorsStoreData & ErrorsStoreApi;
export const useErrorsStore = create<ErrorsStoreState>(set => ({
  ...defaultData,
  reset() {
    set(defaultData);
  },
  enableErrorMode() {
    set({ isErrorModeEnabled: true });
  },
  disableErrorMode() {
    set({ isErrorModeEnabled: false });
  },
  showTabsMessage() {
    set({ isTabsMessageShown: true, isNextButtonMessageShown: false });
  },
  hideTabsMessage() {
    set({ isTabsMessageShown: false });
  },
  showNextButtonMessage() {
    set({ isNextButtonMessageShown: true, isTabsMessageShown: false });
  },
  hideNextButtonMessage() {
    set({ isNextButtonMessageShown: false });
  }
}));
