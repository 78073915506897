import axios, { CancelTokenSource } from 'axios';
import { is, isNil } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useSessionStore } from '../../../../session/services/sessionStore';
import { useOrderPageStore } from '../../../services/orderPageStore';
import { createAdvancedExpectedValue } from 'features/roboAdvice/adviceSession/shared/api';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { useFieldArray } from 'features/sharedModules/finalForm/components/useFieldArray';

export type OrderExecutionExpectedValue = { x: number; y: number };

export function useReadOrderExecutionExpectedValueData() {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<CancelTokenSource>();
  const dispatch = useDispatch();
  const i18n = useI18n();
  const instrumentsSelected = useFieldArray(`instrumentsSelected`);
  const readOrderExecutionExpectedValueData = async () => {
    const orderPageStoreState = useOrderPageStore.getState();
    const sessionStoreState = useSessionStore.getState();

    try {
      orderPageStoreState.setPageStatus(
        'readExpectedValueData',
        PageStatuses.pending
      );

      if (!isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
      const cancelTokenSource = axios.CancelToken.source();
      cancelTokenSourceRef.current = cancelTokenSource;

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const payload = {
        horizon: 10,
        initial_deposit_portfolio: instrumentsSelected.fields.value
          .filter(
            instrument =>
              is(Number, instrument.initialDeposit) &&
              instrument.initialDeposit > 0
          )
          .map(instrument => ({
            amount: instrument.initialDeposit,
            id: instrument.id
          })),
        monthly_saving_portfolio: instrumentsSelected.fields.value
          .filter(
            instrument =>
              is(Number, instrument.monthlySaving) &&
              instrument.monthlySaving > 0
          )
          .map(instrument => ({
            amount: instrument.monthlySaving,
            id: instrument.id
          }))
      };

      const advancedExpectedValueResponse = await createAdvancedExpectedValue(
        qAuthAccessToken,
        cancelTokenSource.token,
        payload,
        {
          namespace_id: sessionStoreState.defaultConfigNamespaceId
        }
      );

      orderPageStoreState.setExpectedValueData(
        advancedExpectedValueResponse.data.map(item => ({
          x: new Date(item.date).getTime(),
          y: item.total_value
        }))
      );

      orderPageStoreState.setPageStatus(
        'readExpectedValueData',
        PageStatuses.succeed
      );
    } catch (error) {
      orderPageStoreState.setPageStatus(
        'readExpectedValueData',
        PageStatuses.failed
      );
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.orderExecution.readOrderExecutionExpectedValueDataErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  React.useEffect(() => {
    return () => {
      if (!isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
    };
  }, []);

  return readOrderExecutionExpectedValueData;
}
