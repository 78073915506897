import { useMemo, useState, Fragment } from 'react';

import { RoboAdviceAdviceSessionPages } from '../../../shared/constants';
import ActionButtons from '../../shared/components/actionButtons';
import { Page } from '../../shared/types';
import EducationalModal from './educationalModal';
import GenericAssessment from './genericAssessment';
import GranulationSection from './granulationSection';
import { OffModelQuestionSection } from './offModelQuestionSection';
import SelectionCardsSection from './selectionCardsSection';
import useInitSustainability from './useInitSustainability';
import { getAlignmentCriteriaFields } from './utils';
import { useForm as useRoboAdviceForm } from 'features/roboAdvice/adviceSession/form/services/form';
import { getTranslation } from 'features/shared/utils/translations';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import ErrorsList from 'features/sharedModules/errors/components/errorsList';
import InputErrorMessage from 'features/sharedModules/errors/components/inputErrorMessage';
import NextButtonError from 'features/sharedModules/errors/components/nextButtonError';
import { useGetAllAdviceSessionErrors } from 'features/sharedModules/errors/services/useGetAllAdviceSessionErrors';
import { SustainabilityErrors } from 'features/sharedModules/errors/types';

const MAX_STEP_NUMBER = 7;

const Sustainability = () => {
  const customerConfig = useCustomerConfig();
  const {
    sustainabilityPreference,
    alignmentCriteria,
    genericAssessment: { enabled: isGenericAssessmentEnabled } = {
      enabled: undefined
    },
    educationalModals,
    offModelQuestions
  } = customerConfig?.roboAdvice?.sustainability;
  const { touched, values } = useRoboAdviceForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<Page[]>([]);
  const [modalHeader, setModalHeader] = useState('');
  const stepsScheme = [...alignmentCriteria.stepsScheme];
  stepsScheme.sort((a, b) => a.order - b.order);
  const granulationSectionItems = stepsScheme.slice(0, MAX_STEP_NUMBER);
  const allErrors = useGetAllAdviceSessionErrors();
  const errors: SustainabilityErrors =
    allErrors[RoboAdviceAdviceSessionPages.sustainability];
  const i18n = useI18n();

  useInitSustainability();

  const alignmentCriteriaFields = useMemo(() => {
    const fields = getAlignmentCriteriaFields({ customerConfig });
    fields.sort((a, b) => a.alignmentCriteriaOrder - b.alignmentCriteriaOrder);

    return fields;
  }, [customerConfig]);

  const components = useMemo(() => {
    const enabledOffModelQuestions = !offModelQuestions.enabled
      ? []
      : offModelQuestions.questions.filter(question => question.enabled);
    enabledOffModelQuestions.sort((a, b) => a.order - b.order);

    const result = [
      {
        component: alignmentCriteriaFields.map(field => (
          <GranulationSection
            items={granulationSectionItems}
            name={field.name}
            key={getTranslation(field.config.title)}
            title={getTranslation(field.config.title)}
            description={getTranslation(field.config.content)}
            educationalModalsConfig={educationalModals}
            setIsModalOpen={setIsModalOpen}
            setModalContent={setModalContent}
            setModalHeader={setModalHeader}
            isTextFieldShown={field.config.isCommentFieldShown}
            textFieldName={field.textFieldName}
            isFieldRequired={true}
            isTextFieldRequired={field.config.required}
            isValueAdaptedRecorded={field.config.isValueAdaptedRecorded}
            elementId={`alignmentCriteria-${field.name}`}
            errors={
              errors[
                field.name.replace('sustainability.alignmentCriteria.', '')
              ]
            }
            textFieldErrors={
              errors[field.textFieldName.replace('sustainability.', '')]
            }
            valueAdaptedErrors={
              errors[
                `${field.name.replace(
                  'sustainability.alignmentCriteria.',
                  ''
                )}ValueAdapted`
              ]
            }
          />
        )),
        order: alignmentCriteria.order,
        key: 'granulationSection'
      },
      {
        component: sustainabilityPreference.enabled && (
          <SelectionCardsSection
            title={getTranslation(sustainabilityPreference.header)}
            description={getTranslation(sustainabilityPreference.description)}
            items={sustainabilityPreference.config}
            educationalModalsConfig={educationalModals}
            setIsModalOpen={setIsModalOpen}
            setModalHeader={setModalHeader}
            setModalContent={setModalContent}
            elementId={'sustainability-preference-criteria'}
            valueAdaptedErrors={errors.preferenceCriteriaValueAdapted}
          />
        ),
        order: sustainabilityPreference.order,
        key: 'selectionCardsSection'
      },
      {
        component: enabledOffModelQuestions.map(question => (
          <OffModelQuestionSection
            name={`sustainability.offModelQuestions.${question.name}`}
            header={getTranslation(question.header)}
            description={getTranslation(question.description)}
            config={question.config}
            icon={question.icon}
            required={question.required}
            educationalModalConfig={question.educationalModal}
            errors={errors?.[question.name]}
            advisorNotes={question.advisorNotes}
            advisorNotesName={`sustainability.offModelQuestionsAdvisorNotes.${question.name}`}
            advisorNotesErrors={
              errors[`offModelQuestionsAdvisorNotes-${question.name}`]
            }
            setIsModalOpen={setIsModalOpen}
            setModalContent={setModalContent}
            setModalHeader={setModalHeader}
            elementId={`offModelQuestion-${question.name}`}
            isValueAdaptedRecorded={question.isValueAdaptedRecorded}
            valueAdaptedErrors={errors[`${question.name}ValueAdapted`]}
          />
        )),
        order: offModelQuestions.order,
        key: 'offModelQuestions'
      }
    ];
    result.sort((a, b) => a.order - b.order);

    return result;
  }, [
    alignmentCriteria,
    alignmentCriteriaFields,
    educationalModals,
    sustainabilityPreference,
    offModelQuestions,
    granulationSectionItems,
    touched,
    errors
  ]);

  return (
    <div
      role="tabpanel"
      aria-labelledby={`tab-id-${RoboAdviceAdviceSessionPages.sustainability}`}
      id={`panel-id-${RoboAdviceAdviceSessionPages.sustainability}`}
    >
      <ErrorsList
        pageName={i18n('roboAdvice.tabs.sustainability')}
        errors={errors}
      />
      {isGenericAssessmentEnabled && (
        <>
          {errors?.genericAssessment?.map(error => (
            <InputErrorMessage message={error.alertMessage} />
          ))}
          <GenericAssessment
            setIsModalOpen={setIsModalOpen}
            setModalContent={setModalContent}
            setModalHeader={setModalHeader}
            errors={errors}
          />
        </>
      )}

      {(!isGenericAssessmentEnabled ||
        (isGenericAssessmentEnabled &&
          values?.sustainability?.genericAssessment?.answer)) &&
        components.map(c => <Fragment key={c.key}>{c.component}</Fragment>)}

      <EducationalModal
        closeHandler={() => setIsModalOpen(false)}
        header={modalHeader}
        isOpen={isModalOpen}
        pages={modalContent}
      />
      <NextButtonError allErrors={allErrors} />
      <ActionButtons pageId={RoboAdviceAdviceSessionPages.sustainability} />
    </div>
  );
};

export default Sustainability;
