import classNames from 'classnames';
import * as R from 'ramda';

import { getErrorText } from './shared.js';
import { useField } from './useField';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { Layouts } from 'features/roboAdvice/shared/constants';
import ButtonGroup from 'features/shared/components/buttonGroup';
import ErrorMessage from 'features/shared/components/errorMessage/index.js';
import { Colors } from 'features/shared/constants/colors.js';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  section: {
    '& + &': {
      marginTop: '3rem'
    }
  },
  verticalSection: {},
  horizontalSection: {
    display: 'inline-flex',
    alignItems: 'baseline'
  },
  label: {
    color: Colors.gray50
  },
  verticalLabel: {
    display: 'inline-block',
    marginBottom: '10px'
  },
  horizontalLabel: {
    marginRight: '2.4rem',
    flex: '0 0 auto',
    width: '300px'
  },
  container: {
    display: 'flex'
  },
  required: {
    color: theme.errorNegativeColor
  }
}));

const FinalFormButtonSwitch = ({
  name,
  fieldConfig,
  items: propsItems,
  afterChange,
  sectionClassName: sectionClassNameProp,
  labelClassName: labelClassNameProp,
  label,
  layout,
  disabled,
  required,
  hasError,
  ...restProps
}) => {
  const classes = useStyles();
  const i18n = useI18n();
  const {
    input: { value, onChange },
    meta: { error, touched }
  } = useField(name, fieldConfig);

  const isErrorShown = R.isNil(hasError)
    ? touched && !R.isNil(error)
    : hasError;
  const errorText = getErrorText(i18n, error);
  const onChangeInternal = valueInternal => {
    onChange(valueInternal);
    !R.isNil(afterChange) && afterChange(valueInternal);
  };

  const items =
    propsItems && propsItems.length
      ? propsItems.map(item => ({
          ...item,
          title: item.label
            ? item.label[getUserPageLanguage()]
            : i18n(item.translationKey),
          isActive: value === item.value,
          onClick: () => onChangeInternal(item.value)
        }))
      : [
          {
            key: 'yes',
            title: i18n('shared.yes'),
            isActive: value === true,
            onClick: () => onChangeInternal(true)
          },
          {
            key: 'no',
            title: i18n('shared.no'),
            isActive: value === false,
            onClick: () => onChangeInternal(false)
          }
        ];

  let sectionClassName = null;
  let labelClassName = null;
  if (layout === Layouts.vertical) {
    sectionClassName = classNames(
      classes.section,
      classes.verticalSection,
      sectionClassNameProp
    );
    labelClassName = classNames(
      classes.label,
      classes.verticalLabel,
      labelClassNameProp
    );
  } else if (layout === Layouts.horizontal) {
    sectionClassName = classNames(
      classes.section,
      classes.horizontalSection,
      sectionClassNameProp
    );
    labelClassName = classNames(
      classes.label,
      classes.horizontalLabel,
      labelClassNameProp
    );
  }

  return (
    <div className={sectionClassName}>
      {!R.isNil(label) && <span className={labelClassName}>{label}</span>}
      <div>
        <div className={classes.container}>
          <div>
            <ButtonGroup
              disabled={disabled}
              items={items}
              data-testid={restProps['data-testid']}
              hasError={hasError}
            />
            {isErrorShown && R.isNil(hasError) && (
              <ErrorMessage>* {errorText}</ErrorMessage>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

FinalFormButtonSwitch.defaultProps = {
  layout: Layouts.horizontal
};

export default FinalFormButtonSwitch;
