import * as R from 'ramda';

import {
  getRouteFromLocation,
  getPrevRouteFromLocation
} from 'features/shared/services/router/selectors.js';

export const createGetIsPageOpened =
  (routeId, routeReloadParamKeys) => payload => {
    const route = getRouteFromLocation(payload);
    const prevRoute = getPrevRouteFromLocation(payload);

    return (
      (route.route.id === routeId && prevRoute.route.id !== routeId) ||
      getIsPageReload(routeId, routeReloadParamKeys, route, prevRoute)
    );
  };

export const createGetIsPageClosed =
  (routeId, routeReloadParamKeys) => payload => {
    const route = getRouteFromLocation(payload);
    const prevRoute = getPrevRouteFromLocation(payload);

    return (
      (route.route.id !== routeId && prevRoute.route.id === routeId) ||
      getIsPageReload(routeId, routeReloadParamKeys, route, prevRoute)
    );
  };

const getIsPageReload = (routeId, routeReloadParamKeys, route, prevRoute) => {
  const params = route.match.params;
  const prevParams = prevRoute.match.params;
  const isAnyParamChanged = R.any(
    p => R.prop(p, params) !== R.prop(p, prevParams),
    routeReloadParamKeys
  );

  return (
    route.route.id === routeId &&
    prevRoute.route.id === routeId &&
    isAnyParamChanged
  );
};
