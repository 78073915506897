import { takeEvery, put, select, call } from 'redux-saga/effects';
import { v4 } from 'uuid';

import { types } from '../actions';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import {
  startedCreator,
  succeedCreator,
  failedCreator
} from 'features/shared/utils/actions.js';
import { getI18n } from 'features/sharedModules/customerConfig/services/selectors.js';
import {
  updateUserPicture as updateUserPictureApi,
  deleteUserPicture as deleteUserPictureApi
} from 'features/userProfile/shared/api';

function* updateUserPicture(action) {
  const { currentUserId, images } = action.payload;
  const i18n = yield select(getI18n);

  yield put(startedCreator(types.UPDATE_USER_PICTURE));

  const state = yield select();
  try {
    const currentCustomerId = sessionSelectors.getCurrentUserCustomerId(state);
    const accessToken = sessionSelectors.getAuth0AccessToken(state);
    const { translations } = sessionSelectors.getCustomerConfig(state);

    const preprocessedImages = {
      full_size: {
        id: v4(),
        data: images.fullSize
      },
      thumbnail: {
        id: v4(),
        data: images.thumbnail
      }
    };

    const response = yield call(
      updateUserPictureApi,
      currentCustomerId,
      accessToken,
      currentUserId,
      preprocessedImages
    );

    yield put(
      succeedCreator(types.UPDATE_USER_PICTURE, {
        customPicture: {
          fullSize: response.data.custom_picture.full_size,
          thumbnail: response.data.custom_picture.thumbnail
        }
      })
    );

    yield put(
      notificationActionCreators.showNotification({
        message: i18n('profile.updateUserPictureSuccessMessage', translations),
        type: NotificationTypes.success
      })
    );
  } catch (error) {
    const { translations } = sessionSelectors.getCustomerConfig(state);
    yield put(failedCreator(types.UPDATE_USER_PICTURE));

    yield put(
      notificationActionCreators.showNotification({
        message: i18n('profile.updateUserPictureErrorMessage', translations),
        type: NotificationTypes.error
      })
    );
  }
}

function* deleteUserPicture(action) {
  const { currentUserId } = action.payload;
  const i18n = yield select(getI18n);

  yield put(startedCreator(types.DELETE_USER_PICTURE));
  const state = yield select();

  try {
    const currentCustomerId = sessionSelectors.getCurrentUserCustomerId(state);
    const accessToken = sessionSelectors.getAuth0AccessToken(state);
    const { translations } = sessionSelectors.getCustomerConfig(state);

    yield call(
      deleteUserPictureApi,
      currentCustomerId,
      accessToken,
      currentUserId
    );

    yield put(succeedCreator(types.DELETE_USER_PICTURE));

    yield put(
      notificationActionCreators.showNotification({
        message: i18n('profile.deleteUserPictureSuccessMessage', translations),
        type: NotificationTypes.success
      })
    );
  } catch (error) {
    const { translations } = sessionSelectors.getCustomerConfig(state);
    yield put(failedCreator(types.DELETE_USER_PICTURE));

    yield put(
      notificationActionCreators.showNotification({
        message: i18n('profile.deleteUserPictureErrorMessage', translations),
        type: NotificationTypes.error
      })
    );
  }
}

export default function* () {
  yield takeEvery(types.UPDATE_USER_PICTURE, updateUserPicture);

  yield takeEvery(types.DELETE_USER_PICTURE, deleteUserPicture);
}
