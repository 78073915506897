import { isNil } from 'ramda';
import { useEffect, useState } from 'react';

import Button from '../../../../shared/components/button';
import Modal from '../../../../shared/components/modal/index';
import TextInput from '../../../../shared/components/textInput';
import { useI18n } from '../../../../sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from '../../../../sharedModules/styles/components/styles';
import { clientForm } from '../../main/services/clientForm';
import { useSaveAdviceSession } from './useSaveAdviceSession';
import { Spacing } from 'features/shared/constants/spacing';

const useStyles = createUseStyles(theme => ({
  label: {
    color: theme.secondaryColor,
    paddingBottom: Spacing.spacing01
  },
  nameTextInput: {
    width: '100%'
  }
}));

const SessionRenamePopup = ({ session, close }) => {
  const classes = useStyles();
  const i18n = useI18n();
  const saveAdviceSession = useSaveAdviceSession();
  const {
    values: { adviceSessions }
  } = clientForm.getState();
  const [name, setName] = useState<string | undefined>();

  const resetPopup = () => {
    setName('');
    close();
  };

  const onConfirm = () => {
    if (adviceSessions && name) {
      adviceSessions[session.index].name = name;
    }
    saveAdviceSession(session.id, session.type);
    resetPopup();
  };

  useEffect(() => {
    if (session) {
      setName(session.name);
    }
  }, [session]);

  return (
    session && (
      <Modal
        footer={
          <Button onClick={onConfirm} disabled={!name}>
            {i18n('shared.save')}
          </Button>
        }
        header={`${i18n('shared.edit')} ${session.name}`}
        isOpen={!isNil(session)}
        onRequestClose={() => {
          resetPopup();
        }}
      >
        <div className={classes.label}>{i18n('shared.sessionName')}</div>

        <TextInput
          value={name}
          onChange={e => setName(e.target.value)}
          onKeyDown={e => {
            if (e.code === 'Enter') {
              onConfirm();
            }
          }}
          placeholder={i18n('roboAdvice.client.adviceSessionsTable.name')}
          className={classes.nameTextInput}
          forwardedRef={undefined}
          isInvalid={false}
          maxLength={undefined}
          hover={undefined}
          focus={undefined}
          inputType={'text'}
        />
      </Modal>
    )
  );
};

export default SessionRenamePopup;
