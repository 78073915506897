import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { CRM_LOGIN_PARAM_NAME, REQUEST_INFO_DEFAULTS } from './constants';
import { RequestInfo } from './types';
import { getSynchronizedExternalDataResponse } from 'features/admin/appAdmin/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import { useCrmLoginStore } from 'features/shared/services/session/sagas/useCrmLoginStore';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

const useApi = () => {
  const { search, pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const i18n = useI18n();
  const {
    tenantSettings: { qipSynchEndpoint }
  } = useCustomerConfig();

  const { enableCrmLogin } = useCrmLoginStore();

  const [requestInfo, setRequestInfo] = useState<RequestInfo>(
    REQUEST_INFO_DEFAULTS
  );
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const isAuthenticated = useSelector(sessionSelectors.getIsAuthenticated);

  const queryParams = new URLSearchParams(search);

  const removeCrmLoginFromUrl = () => {
    queryParams.delete(CRM_LOGIN_PARAM_NAME);
    history.replace({
      search: queryParams.toString()
    });
  };

  const fetchSynchronizedExternalDataResponse = async () => {
    setRequestInfo({ ...REQUEST_INFO_DEFAULTS, loading: true });

    try {
      const accessToken =
        typeof auth0AccessToken === 'string' ? auth0AccessToken : '';

      if (accessToken.length <= 0) {
        throw new Error('No available access token');
      }

      if (!qipSynchEndpoint) {
        throw new Error('No available endpoint');
      }

      const response = await getSynchronizedExternalDataResponse(
        accessToken,
        qipSynchEndpoint
      );

      setRequestInfo({
        ...REQUEST_INFO_DEFAULTS,
        data: {
          investorType: response?.data?.investorType,
          investorId: response?.data?.investorId,
          adviceType: response?.data?.adviceType,
          adviceId: response?.data?.adviceId,
          viewPage: response?.data?.viewPage
        },
        status: response?.status
      });
    } catch (e) {
      setRequestInfo({
        ...REQUEST_INFO_DEFAULTS,
        error: true
      });
      throwSafeError(e);
    }
  };

  useEffect(() => {
    const isCrmlogin = queryParams?.get(CRM_LOGIN_PARAM_NAME) === 'true';

    if (isCrmlogin && !qipSynchEndpoint) {
      dispatch(
        notificationActionCreators.showNotification({
          message: i18n(
            'roboAdvice.integrationPlatform.couldNotRedirectEndpointNotProvidedErrorMessage'
          ),
          type: NotificationTypes.error
        })
      );
      return;
    }

    if (!enableCrmLogin) {
      return;
    }

    if (isCrmlogin && isAuthenticated && qipSynchEndpoint) {
      fetchSynchronizedExternalDataResponse().then(removeCrmLoginFromUrl);
    }
  }, [pathname, enableCrmLogin]);

  return requestInfo;
};

export default useApi;
