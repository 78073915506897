import React from 'react';

import { Typography } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  learnMore: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    color: theme.accentColor,
    cursor: 'pointer',
    marginBottom: '32px'
  }
}));

const LearnMore = ({ openModal }) => {
  const i18n = useI18n();
  const classes = useStyles();

  return (
    <div className={classes.learnMore} onClick={openModal}>
      {i18n('roboAdvice.knowledgeAndExperience.quiz.learnMore')}
    </div>
  );
};

export default LearnMore;
