import {
  AdviceSessionFormState,
  useForm as useRoboAdviceForm
} from '../../../roboAdvice/adviceSession/form/services/form';
import { useCustomerConfig } from '../../customerConfig/components/useCustomerConfig';
import { useI18n } from '../../customerConfig/components/useI18n';
import { ErrorMessage, InstrumentsErrors } from '../types';
import {
  TargetMarketAssessmentStoreState,
  useTargetMarketAssessmentStore
} from 'features/roboAdvice/adviceSession/orderExecution/services/targetMarketAssessmentStore';

export const useGetInstrumentsErrors = (): InstrumentsErrors => {
  const i18n = useI18n();
  const roboAdviceFormState = useRoboAdviceForm();
  const {
    roboAdviceForm: { errorMessagesEnabled },
    roboAdvice: {
      orderExecution: {
        instrumentsList: { blockOrderWithNonSuitableInstruments }
      }
    }
  } = useCustomerConfig();
  const { advancedSuitabilityData } = useTargetMarketAssessmentStore();

  return {
    instruments: getInstrumentsErrors({
      roboAdviceFormState,
      i18n,
      errorMessagesEnabled,
      blockOrderWithNonSuitableInstruments,
      advancedSuitabilityData
    }),
    advisorNotes: getAdvisorNotesErrors({
      roboAdviceFormState,
      i18n,
      errorMessagesEnabled
    })
  };
};

type GetInstrumentsErrorsParams = {
  roboAdviceFormState: AdviceSessionFormState;
  i18n: ReturnType<typeof useI18n>;
  errorMessagesEnabled: boolean;
  blockOrderWithNonSuitableInstruments: boolean;
  advancedSuitabilityData: TargetMarketAssessmentStoreState['advancedSuitabilityData'];
};

const getInstrumentsErrors = ({
  roboAdviceFormState,
  i18n,
  errorMessagesEnabled,
  blockOrderWithNonSuitableInstruments,
  advancedSuitabilityData
}: GetInstrumentsErrorsParams): ErrorMessage[] => {
  const instrumentsErrors: ErrorMessage[] = [];

  if (errorMessagesEnabled) {
    if (!roboAdviceFormState.values?.instrumentsSelected?.length) {
      instrumentsErrors.push({
        alertMessage: i18n('roboAdvice.errors.emptyInstrumentsTableAlert')
          .replace('{0}', i18n('roboAdvice.orderExecution.monthlyDeposit'))
          .replace('{1}', i18n('roboAdvice.orderExecution.initialDeposit')),
        listMessage: i18n('roboAdvice.errors.emptyInstrumentsTable'),
        elementId: 'instruments-table'
      });
    } else if (
      roboAdviceFormState.values?.instrumentsSelected?.some(
        instrument => !instrument.initialDeposit && !instrument.monthlySaving
      )
    ) {
      instrumentsErrors.push({
        alertMessage: i18n('roboAdvice.errors.noInstrumentsAlert')
          .replace('{0}', i18n('roboAdvice.orderExecution.monthlyDeposit'))
          .replace('{1}', i18n('roboAdvice.orderExecution.initialDeposit')),
        listMessage: i18n('roboAdvice.errors.noInstruments')
          .replace('{0}', i18n('roboAdvice.orderExecution.monthlyDeposit'))
          .replace('{1}', i18n('roboAdvice.orderExecution.initialDeposit')),
        elementId: 'instruments-table'
      });
    }

    if (
      blockOrderWithNonSuitableInstruments &&
      advancedSuitabilityData &&
      roboAdviceFormState.values?.instrumentsSelected?.some(instrument => {
        const suitabilityData = advancedSuitabilityData.data.find(
          ({ id }) => id === instrument.id
        );
        return suitabilityData && !suitabilityData.productIsSuitable;
      })
    ) {
      instrumentsErrors.push({
        alertMessage: i18n('roboAdvice.errors.notSuitableInstrumentsAlert'),
        listMessage: i18n('roboAdvice.errors.notSuitableInstruments'),
        elementId: 'instruments-table'
      });
    }
  }

  return instrumentsErrors;
};

const getAdvisorNotesErrors = ({
  roboAdviceFormState,
  i18n,
  errorMessagesEnabled
}): ErrorMessage[] => {
  const advisorNotesErrors: ErrorMessage[] = [];
  if (errorMessagesEnabled) {
    if (!roboAdviceFormState.values?.instrumentsAdvisorNotes) {
      advisorNotesErrors.push({
        alertMessage: i18n('roboAdvice.errors.missingAdvisorNotesAlert'),
        listMessage: i18n('roboAdvice.errors.missingAdvisorNotes').replace(
          '{0} ',
          ''
        ),
        elementId: 'instruments-advisor-notes'
      });
    } else if (roboAdviceFormState.values.instrumentsAdvisorNotes.length < 25) {
      advisorNotesErrors.push({
        listMessage: i18n('roboAdvice.errors.missingAdvisorNotes').replace(
          '{0} ',
          ''
        ),
        alertMessage: i18n(
          'roboAdvice.advisory.advisorNotes.advisorNotesMinLengthValidationError'
        ).replace('{0}', '25'),
        elementId: 'instruments-advisor-notes'
      });
    }
  }
  return advisorNotesErrors;
};
