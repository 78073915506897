import { z } from 'zod';

export const toolsSchema = z.object({
  isToolTabHidden: z.boolean(),
  holisticView: z.object({
    enabled: z.boolean()
  }),
  savingCalculator: z.object({
    enabled: z.boolean(),
    iframeUrl: z.string().nullable()
  })
});

export type Tools = z.infer<typeof toolsSchema>;

export const defaultTools: Tools = {
  isToolTabHidden: false,
  holisticView: {
    enabled: true
  },
  savingCalculator: {
    enabled: false,
    iframeUrl: null
  }
};
