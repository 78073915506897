import classNames from 'classnames';

import TableComponent from 'features/shared/components/table/table.js';
import TableBody from 'features/shared/components/table/tableBody.js';
import TableBodyRow from 'features/shared/components/table/tableBodyRow.js';
import { formatNumber } from 'features/shared/utils/number';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const AnyTableComponent: any = TableComponent;
const AnyTableBody: any = TableBody;
const AnyTableBodyRow: any = TableBodyRow;

const useStyles = createUseStyles(theme => ({
  row: {
    textAlign: 'left'
  },
  numberCell: {
    textAlign: 'right'
  },
  numberHeader: {
    '&:not(:nth-child(1))': {
      textAlign: 'right'
    }
  },
  positiveNumber: {
    color: theme.successColor
  },
  negativeNumber: {
    color: theme.primaryColor
  }
}));

const CashflowTable = ({ cashflowTableData, cultureCode }) => {
  const i18n = useI18n();
  const classes = useStyles();

  const headerTitles = [
    i18n('roboAdvice.proposal.year'),
    i18n('roboAdvice.proposal.cashflow.deposits'),
    i18n('roboAdvice.proposal.cashflow.withdrawals'),
    i18n('shared.value')
  ];

  return (
    <AnyTableComponent
      header={headerTitles.map(title => ({
        title
      }))}
      externalClasses={{ headerRowCell: classes.numberHeader }}
    >
      <AnyTableBody>
        {cashflowTableData.map(({ amount, date, deposits, withdrawals }) => (
          <AnyTableBodyRow key={date} className={classes.row}>
            {({ bodyRowCellClassName }) => (
              <>
                <td className={bodyRowCellClassName}>{date.slice(0, 4)}</td>
                <td
                  className={classNames(
                    bodyRowCellClassName,
                    classes.numberCell,
                    {
                      [classes.positiveNumber]: deposits > 0
                    }
                  )}
                >
                  {formatNumber(cultureCode, deposits || 0, 0, 0)}
                </td>
                <td
                  className={classNames(
                    bodyRowCellClassName,
                    classes.numberCell,
                    {
                      [classes.negativeNumber]: withdrawals > 0
                    }
                  )}
                >
                  {formatNumber(cultureCode, withdrawals || 0, 0, 0)}
                </td>
                <td
                  className={classNames(
                    bodyRowCellClassName,
                    classes.numberCell,
                    amount === 0
                      ? null
                      : amount < 0
                      ? classes.negativeNumber
                      : classes.positiveNumber
                  )}
                >
                  {formatNumber(cultureCode, amount, 0, 0)}
                </td>
              </>
            )}
          </AnyTableBodyRow>
        ))}
      </AnyTableBody>
    </AnyTableComponent>
  );
};

export default CashflowTable;
