import React from 'react';

import { Spacing } from 'features/shared/constants/spacing';
import { FontWeights } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  dateRange: {
    gridColumn: '1 / 1',
    justifySelf: 'right',
    color: theme.secondaryColor,
    marginRight: Spacing.spacing00,
    fontWeight: FontWeights.normal
  }
}));

type Props = {
  fromDate?: string;
  toDate?: string;
};

const DateRange = ({ fromDate, toDate }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();

  return (
    <div className={classes.dateRange}>
      {fromDate ? (
        new Date(fromDate).getFullYear()
      ) : (
        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
      )}
      &nbsp;-&nbsp;
      {toDate ? new Date(toDate).getFullYear() : i18n('shared.today')}
    </div>
  );
};

export default DateRange;
