import create from 'zustand';

import { Category } from '../../session/services/sessionStore';
import { CreateAnalyzeUniverseSustainabilityResponse } from '../../shared/api/types';
import { PageStatus } from '../../shared/components/useReadDataListener';
import { Instrument } from '../../shared/services/goalsStore';

export type AssetClass = {
  id: string;
  title: string;
  mainAssetClass: string;
  subAssetClass: string;
  instruments: Instrument[];
  categoryId: string;
  categoryIds: string[];
  availableInstruments: Instrument[];
};

export type FundFees = {
  category: string;
  fundStandardName: string;
  ISIN: string;
  name: string;
  ticker: string;
  fundManagementFee: number | null;
};

type SustainabilityData = CreateAnalyzeUniverseSustainabilityResponse;

export type CustomPortfolioStoreData = {
  availableCategories: Category[];
  assetClasses: AssetClass[];
  fundsFees: {
    data: FundFees[];
    firstDeposit?: number | null;
    monthlyDeposit?: number | null;
    productPlatformNamespace?: number | null;
  };
  sustainabilityData: SustainabilityData | null;
  isRiskTemplateLoading: boolean;
  assetClassInstrumentsStatus: PageStatus | null;
  isNewCustomPortfolioDataAvailable: boolean;
  isCustomPortfolioLoading: boolean;
};

export type CustomPortfolioStoreApi = {
  reset: () => void;
  initialize: (initialData: Partial<CustomPortfolioStoreData>) => void;
  setAvailableCategories: (categories: Category[]) => void;
  setAssetClasses: (assetClasses: AssetClass[]) => void;
  setFundsFees: (fundsFees: CustomPortfolioStoreData['fundsFees']) => void;
  setSustainabilityData: (
    sustainabilityData: SustainabilityData | null
  ) => void;
  setIsRiskTemplateLoading: (isRiskTemplateLoading: boolean) => void;
  setAssetClassInstrumentsStatus: (
    assetClassInstrumentsStatus: PageStatus
  ) => void;
  setIsNewCustomPortfolioDataAvailable: (
    isNewCustomPortfolioDataAvailable: boolean
  ) => void;
  setIsCustomPortfolioLoading: (isCustomPortfolioLoading: boolean) => void;
};

export type CustomPortfolioStoreState = CustomPortfolioStoreData &
  CustomPortfolioStoreApi;

const defaultData: CustomPortfolioStoreData = {
  availableCategories: [],
  assetClasses: [],
  fundsFees: { data: [] },
  sustainabilityData: null,
  isRiskTemplateLoading: false,
  assetClassInstrumentsStatus: null,
  isNewCustomPortfolioDataAvailable: true,
  isCustomPortfolioLoading: false
};

export const useCustomPortfolioStore = create<CustomPortfolioStoreState>(
  set => ({
    ...defaultData,
    reset: () => {
      set(defaultData);
    },
    initialize(initialData) {
      set(
        state => ({
          ...state,
          ...initialData
        }),
        true
      );
    },
    setAssetClasses(assetClasses) {
      set({ assetClasses });
    },
    setFundsFees(fundsFees) {
      set({ fundsFees });
    },
    setSustainabilityData(sustainabilityData) {
      set({ sustainabilityData });
    },
    setAvailableCategories(categories) {
      set({ availableCategories: categories });
    },
    setIsRiskTemplateLoading(isRiskTemplateLoading) {
      set({ isRiskTemplateLoading });
    },
    setAssetClassInstrumentsStatus(assetClassInstrumentsStatus) {
      set({ assetClassInstrumentsStatus });
    },
    setIsNewCustomPortfolioDataAvailable(isNewCustomPortfolioDataAvailable) {
      set({ isNewCustomPortfolioDataAvailable });
    },
    setIsCustomPortfolioLoading(isCustomPortfolioLoading) {
      set({ isCustomPortfolioLoading });
    }
  })
);
