import { creators as sessionActionCreators } from 'features/shared/services/session/actions.js';
import { deepAlphaApi } from 'features/shared/utils/axios';

const setNoCacheHeaders = axiosRequestConfig => ({
  ...axiosRequestConfig,
  headers: {
    ...axiosRequestConfig.headers,
    'Cache-Control': 'no-cache, no-store',
    Pragma: 'no-cache',
    Expires: '0'
  }
});

export const initAxios = store => {
  deepAlphaApi.interceptors.request.use(setNoCacheHeaders, error => {
    return Promise.reject(error);
  });

  deepAlphaApi.interceptors.response.use(
    response => response,
    error => {
      const statusCode = error?.response?.status;
      if (statusCode === 401) {
        const unauthorizedErrorType = error?.response?.data?.details?.type;
        store.dispatch(sessionActionCreators.logout(unauthorizedErrorType));
      }

      if (statusCode === 503) {
        window.location.reload();
      }

      return Promise.reject(error);
    }
  );
};
