import { Typography, FontWeights } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const useStyles = createUseStyles(theme => ({
  calendar: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '285px',
    height: '305px',
    borderRadius: '16px',
    margin: '5px',
    backgroundColor: theme.itemBackgroundColor_1,
    border: `2px solid ${theme.accentColor}`,
    fontSize: Typography.body2.size,
    color: theme.secondaryColor,
    overflow: 'hidden'
  },
  navIcon: {
    fontSize: '28px',
    color: theme.secondaryColor,
    cursor: 'pointer'
  },
  disabledNavIcon: {
    fontSize: '28px',
    cursor: 'default',
    color: theme.disabledTextColor
  },
  chevronRight: {
    position: 'absolute',
    top: '13px',
    right: '15px'
  },
  chevronLeft: {
    position: 'absolute',
    top: '13px',
    left: '15px'
  },
  header: {
    width: '283px',
    fontSize: Typography.heading1.size,
    margin: '12px 0 14px 0',
    display: 'flex',
    justifyContent: 'center'
  },
  headerLable: {
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.primaryColor,
    fontSize: Typography.body1.size,
    fontWeight: FontWeights.bold,
    marginTop: '3px'
  },
  weekDay: {
    width: '36px',
    marginBottom: '6px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: Typography.body3.size,
    fontWeight: FontWeights.bold,
    color: theme.secondaryColor
  },
  day: {
    fontSize: Typography.body1.size,
    color: theme.secondaryColor,
    borderRadius: '8px',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.inputFillFocusColor
    }
  },
  disabledDay: {
    fontSize: Typography.body1.size,
    color: theme.disabledTextColor,
    borderRadius: '8px',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.inputFillFocusColor
    }
  },
  excludedDay: {
    fontSize: Typography.body1.size,
    color: theme.disabledTextColor,
    cursor: 'not-allowed'
  },
  selectedDay: {
    fontSize: Typography.body1.size,
    borderRadius: '8px',
    fontWeight: FontWeights.bold,
    backgroundColor: theme.accentColor,
    color: theme.primaryColor,
    cursor: 'pointer',

    '&:hover': {
      borderRadius: '8px',
      backgroundColor: theme.accentColor
    }
  },
  centredInlineBlock: {
    width: '36px',
    height: '35px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  datePickerWrap: {
    display: 'inline-flex',
    position: 'relative'
  },
  calendarIcon: {
    position: 'absolute',
    top: 'calc(0.8rem + 2px)',
    right: '2.4rem',
    color: theme.secondaryColor,
    fontSize: '2.4rem',
    userSelect: 'none',
    pointerEvents: 'none'
  },
  month: {
    marginTop: '5px',
    width: '84px',
    height: '52px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    color: theme.secondaryColor,
    fontSize: Typography.body1.size,
    cursor: 'pointer',

    '&:hover': {
      color: theme.primaryColor,
      backgroundColor: theme.inputFillFocusColor
    }
  },
  hasError: {
    border: `2px solid ${theme.errorNegativeColor}`
  }
}));
