import { all } from 'redux-saga/effects';

import renderApplication from './render';
import customerManagementPage from 'features/customers/customerManagementPage/services/sagas/index.js';
import customersTablePage from 'features/customers/customersTablePage/services/sagas.js';
import tracking from 'features/main/services/tracking/sagas.js';
import roboAdviceAdviceSession from 'features/roboAdvice/adviceSession/main/services/sagas.js';
import applicationSagas from 'features/shared/services/application/sagas.js';
import notificationSagas from 'features/shared/services/notification/sagas.js';
import routerSagas from 'features/shared/services/router/sagas.js';
import sessionSagas from 'features/shared/services/session/sagas/index.js';
import sagaDelay from 'features/sharedModules/sagaDelay/services/sagas.js';
import userProfileBasicPageSagas from 'features/userProfile/services/sagas/index';

export function* sagas() {
  yield all([
    applicationSagas({ renderApplication }),
    routerSagas(),
    sessionSagas(),
    notificationSagas(),
    customersTablePage(),
    customerManagementPage(),
    tracking(),
    userProfileBasicPageSagas(),
    sagaDelay(),
    roboAdviceAdviceSession()
  ]);
}
