import classNames from 'classnames';
import * as R from 'ramda';
import { useReducer } from 'react';

import Icon from 'features/shared/components/icon/index.js';
import {
  FontFamilies,
  FontWeights
} from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const actionTypes = {
  TOGGLE_INPUT_FOCUS: 'TOGGLE_INPUT_FOCUS'
};

const initialState = {
  isInputFocused: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_INPUT_FOCUS: {
      return R.assoc('isInputFocused', action.payload, state);
    }
    default:
      return state;
  }
};

const useStyles = createUseStyles(theme => ({
  inputContainer: {
    position: 'relative'
  },
  input: {
    width: '100%',
    padding: '0rem 5rem 0rem 1.8rem',
    color: theme.secondaryColor,
    fontFamily: FontFamilies.roboto,
    fontWeight: FontWeights.normal,
    position: 'relative',
    zIndex: 1,
    cursor: 'pointer',
    display: 'inline-flex',
    outline: 'none',

    '&$active': {
      color: theme.accentColor
    },

    '&:focus': {
      color: theme.accentColor
    }
  },
  active: {},
  rightBlock: {
    position: 'absolute',
    top: '2px',
    right: '1.8rem',
    zIndex: 1,
    pointerEvents: 'none'
  },
  icon: {
    cursor: 'pointer',
    color: theme.secondaryColor,
    fontSize: '2.2rem',
    lineHeight: '2.4rem',

    '&$active': {
      color: theme.accentColor
    },

    '&$focused': {
      color: theme.accentColor
    }
  },
  focused: {}
}));

const NavigationDropdownInput = ({
  title,
  isActive,
  forwardedRef,
  selectedOption,
  onClick,
  isDropdownMenuOpen,
  onFocus,
  onBlur,
  className,
  iconClassName
}) => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <div className={classes.inputContainer} ref={forwardedRef}>
      <div
        className={classNames(
          classes.input,
          {
            [classes.active]: isActive
          },
          className
        )}
        tabIndex="0"
        onClick={onClick}
        onFocus={e => {
          dispatch({
            type: actionTypes.TOGGLE_INPUT_FOCUS,
            payload: true
          });

          onFocus && onFocus(e);
        }}
        onBlur={e => {
          dispatch({
            type: actionTypes.TOGGLE_INPUT_FOCUS,
            payload: false
          });

          onBlur && onBlur(e);
        }}
      >
        {title}
      </div>
      <div className={classes.rightBlock}>
        <Icon
          className={classNames(
            classes.icon,
            {
              [classes.focused]: state.isInputFocused,
              [classes.active]: isActive
            },
            iconClassName
          )}
          type={
            isDropdownMenuOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
          }
        />
      </div>
    </div>
  );
};

export default NavigationDropdownInput;
