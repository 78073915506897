import { useSelector } from 'react-redux';

import { useOrderSummaryStore } from '../../../orderSummary';
import { getCustomAttributes } from '../../../shared/services/selectors';
import { usePageStore } from '../../services/pageStore';
import useReadOrderSummary from './useReadOrderSummary';
import {
  useReadDataListener,
  PageStatuses
} from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import {
  useGoalsStore,
  selectors
} from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import OrderSummaryVisual from 'features/shared/analyticsComponents/orderSummary/visual';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';

const OrderSummary = ({ isSelected }) => {
  const {
    roboAdviceForm: {
      proposal: { orderSummary }
    }
  } = useCustomerConfig();
  const isPercentColumnEnabled = orderSummary?.isPercentColumnEnabled;
  const selectedGoalsIds = useGoalsStore<string[]>(
    selectors.getSelectedGoalsIds
  );
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const {
    pageStatuses
  } = usePageStore();
  const { orderSummaryData } = useOrderSummaryStore();
  useReadDataListener(pageStatuses.readOrderSummary, isSelected && pageStatuses.readPortfolioChartData === PageStatuses.succeed, useReadOrderSummary());
  const productAttributes = getCustomAttributes(orderSummary);

  return (
    <OrderSummaryVisual
      orderSummaryData={orderSummaryData}
      selectedGoalsIds={selectedGoalsIds}
      cultureCode={cultureCode}
      productAttributes={productAttributes}
      isLoading={pageStatuses.readOrderSummary === PageStatuses.pending}
      isError={pageStatuses.readOrderSummary === PageStatuses.failed}
      isPercentColumnEnabled={isPercentColumnEnabled}
    />
  );
};

export default OrderSummary;
