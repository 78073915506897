import { pathOr } from 'ramda';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  useHolisticViewStore,
  selectors
} from '../../../services/holisticViewStore';
import { useReadExpectedPathChartData } from './useReadExpectedPathChartData';
import { useExpectedPathChartStore } from 'features/roboAdvice/adviceSession/expectedPathChart';
import {
  PageStatuses,
  useReadDataListener
} from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import ExpectedPathChartCardVisual from 'features/shared/analyticsComponents/expectedPathChart/visual';
import sessionSelectors from 'features/shared/services/session/selectors';

const ExpectedPathChartCard = ({ isSelected }) => {
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const expectedPathChartStore = useExpectedPathChartStore();
  const selectedGoalsIds = useHolisticViewStore(selectors.getSelectedGoalsIds);
  const { proposalSectionStatuses } = useHolisticViewStore();

  useEffect(() => {
    expectedPathChartStore.setChartData(selectedGoalsIds);
  }, [expectedPathChartStore.goalsData, selectedGoalsIds]);

  useReadDataListener(
    proposalSectionStatuses.readExpectedPathChartData,
    isSelected,
    useReadExpectedPathChartData()
  );

  const expectedPathTableData = pathOr(
    [],
    ['chartData', 'expectedPathTable'],
    expectedPathChartStore
  );
  const expectedReturnChartData = pathOr(
    [],
    ['chartData', 'expectedReturn'],
    expectedPathChartStore
  );
  const boundsChartData = pathOr(
    [],
    ['chartData', 'bounds'],
    expectedPathChartStore
  );

  return (
    <ExpectedPathChartCardVisual
      cultureCode={cultureCode}
      expectedPathTableData={expectedPathTableData}
      expectedReturnChartData={expectedReturnChartData}
      boundsChartData={boundsChartData}
      isLoading={
        useHolisticViewStore.getState().proposalSectionStatuses
          .readExpectedPathChartData === PageStatuses.pending
      }
    />
  );
};

export default ExpectedPathChartCard;
