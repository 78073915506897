import page from '../../page/main.js';
import { types } from './actions.js';
import { succeedType, failedType } from 'features/shared/utils/actions.js';

const defaultRoboAdviceRiskScoreState = {};

const reducer = (state = defaultRoboAdviceRiskScoreState, action) => {
  switch (action.type) {
    case page.actions.types.CLOSED:
      return defaultRoboAdviceRiskScoreState;
    case succeedType(types.READ_RISK_SCORE_CONFIG): {
      const {
        purposeAndRiskScoreSettings,
        knowledgeAndExperienceScoreSettings,
        riskScoreSettings
      } = action.payload;

      return {
        ...state,
        purposeAndRiskScoreSettings,
        knowledgeAndExperienceScoreSettings,
        riskScoreSettings
      };
    }
    case failedType(types.READ_RISK_SCORE_CONFIG): {
      return {
        ...state,
        purposeAndRiskScoreSettings: null,
        knowledgeAndExperienceScoreSettings: null,
        riskScoreSettings: null
      };
    }
    case failedType(types.READ_RISK_SCORE): {
      return {
        ...state,
        purposeAndRiskScore: null,
        riskScore: null
      };
    }
    default:
      return state;
  }
};

export default reducer;
