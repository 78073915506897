import { useSelector } from 'react-redux';

import { useIsSessionReadOnly } from 'features/roboAdvice/adviceSession/main/services/selectors';
import { Layouts } from 'features/roboAdvice/shared/constants';
import ConfigurableLabel from 'features/shared/components/configurableLabel/';
import sessionSelectors from 'features/shared/services/session/selectors';
import { getNumberInputFormat } from 'features/shared/utils/number';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import FinalFormNumberInput from 'features/sharedModules/finalForm/components/numberInput';

const OneTimeWithdrawal = ({ inputClasses, className }) => {
  const i18n = useI18n();
  const isSessionReadOnly = useIsSessionReadOnly();
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const { thousandSeparator, decimalSeparator } =
    getNumberInputFormat(cultureCode);

  return (
    <FinalFormNumberInput
      data-testid={'time-horizon-input'}
      name={'data.capitalNeed'}
      label={
        <ConfigurableLabel
          required
          label={i18n('roboAdvice.purposeAndRisk.capitalNeed')}
        />
      }
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      allowNegative={false}
      decimalScale={0}
      layout={Layouts.vertical}
      externalClasses={inputClasses}
      disabled={isSessionReadOnly}
      className={className}
      placeholder={i18n(
        'roboAdvice.purposeAndRisk.purposePopup.capitalNeedPlaceholder'
      )}
    />
  );
};

export default OneTimeWithdrawal;
