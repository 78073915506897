import classNames from 'classnames';

import LoadingIndicator from '../loadingIndicator/loadingIndicator';
import { useStyles } from './buttonStyles';
import { ReactComponent as CrossIcon } from 'assets/x-icon.svg';

export const TertiaryButtonSize = {
  small: 'small',
  medium: 'medium'
} as const;

export const ButtonType = {
  primary: 'primary',
  secondary: 'secondary',
  additional: 'additional',
  tertiary: 'tertiary'
} as const;

const getTypeClassName = (
  buttonType: keyof typeof ButtonType,
  classes: ReturnType<typeof useStyles>
) => {
  switch (buttonType) {
    case ButtonType.primary:
      return classes.primary;
    case ButtonType.secondary:
      return classes.secondary;
    case ButtonType.tertiary:
      return classes.tertiary;
    case ButtonType.additional:
      return classes.additional;
    default:
      const _exhaustiveCheck: never = buttonType;
      return _exhaustiveCheck;
  }
};

type Props = {
  /**
   * The content of the button.
   */
  children: React.ReactNode;
  /**
   * The class name to be applied to the button.
   */
  className?: string;
  /**
   * Determines the type of the button.
   */
  buttonType?: keyof typeof ButtonType;
  /**
   * The size of the button.
   *
   * (ONLY FOR TERTIARY BUTTON)
   */
  buttonSize?: keyof typeof TertiaryButtonSize;
  /**
   * If `true`, the button will have Loading Indicator.
   */
  isLoading?: boolean;
  /**
   * If `true`, the button will have Cross Icon.
   */
  withCrossIcon?: boolean;
  /**
   * Custom icon to be displayed to the left of the button.
   */
  customIcon?: React.ReactNode;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const Button = ({
  children,
  className,
  buttonType = ButtonType.primary,
  buttonSize = TertiaryButtonSize.medium,
  isLoading = false,
  withCrossIcon = false,
  customIcon,
  disabled = false,
  ...restProps
}: Props) => {
  const classes = useStyles();

  return (
    <button
      {...restProps}
      className={classNames(
        classes.button,
        getTypeClassName(buttonType, classes),
        {
          [classes.tertiarySmall]:
            buttonSize === TertiaryButtonSize.small &&
            buttonType === ButtonType.tertiary,
          [classes.tertiaryMedium]:
            buttonSize === TertiaryButtonSize.medium &&
            buttonType === ButtonType.tertiary
        },
        className
      )}
      disabled={disabled}
    >
      {isLoading && <LoadingIndicator size={18} />}
      {withCrossIcon && !isLoading && (
        <CrossIcon
          className={classNames(classes.icon, {
            [classes.iconDisabled]: disabled
          })}
        />
      )}
      {customIcon && !isLoading && !withCrossIcon && (
        <div
          className={classNames(classes.customIconContainer, {
            [classes.iconDisabled]: disabled
          })}
        >
          {customIcon}
        </div>
      )}
      {children}
    </button>
  );
};

export default Button;
