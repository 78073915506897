export const createTypes = id => ({
  OPENED: `${id}_PAGE/OPENED`,
  CLOSED: `${id}_PAGE/CLOSED`
});

export const createCreators = (id, types) => ({
  opened: () => ({
    type: types.OPENED,
    meta: { id }
  }),
  closed: () => ({
    type: types.CLOSED,
    meta: { id }
  })
});

export default ({ id }) => {
  const types = createTypes(id);
  const creators = createCreators(id, types);

  return {
    types,
    creators
  };
};
