import HighchartsReact from 'highcharts-react-official';
import Highcharts, { SeriesColumnOptions } from 'highcharts';

import {
  FontFamilies,
  FontWeights,
  TextFontSizes
} from 'modules/shared/constants/fonts';
import { Theme } from 'defaults/defaultTheme';
import { useTheme } from 'modules/styles/components/styles';
import { useI18n } from 'modules/settings/components/settings';
import Legend from 'modules/shared/components/legend';

export type ChartData = {
  name: string;
  data: [number | null, number | null, number | null, number | null];
};

type Props = {
  chartData: ChartData[];
  height: number;
};

const HistoricalReturnBarChart = ({ chartData, height }: Props) => {
  const i18n = useI18n();
  const theme: Theme = useTheme();

  const options = {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      height: height
    },
    colors: theme.chartPortfolioColors,
    credits: { enabled: false },
    exporting: { enabled: false },
    legend: { enabled: false },
    plotOptions: {
      column: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: theme.primaryColor,
            lineHeight: TextFontSizes.xSmall,
            fontFamily: FontFamilies.roboto,
            fontSize: TextFontSizes.xxSmall,
            fontWeight: FontWeights.normal,
            textOutline: 'none'
          }
        }
      },
      series: {
        animation: false,
        enableMouseTracking: false,
        events: {
          legendItemClick: () => false
        },
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },
    series: chartData.map<SeriesColumnOptions>(data => ({
      ...data,
      data: data.data.map(value => value && +(value * 100).toFixed(2)),
      type: 'column'
    })),
    title: { text: undefined },
    tooltip: {
      enabled: false
    },
    xAxis: {
      categories: [
        i18n('historicalReturnBarChart.1year'),
        i18n('historicalReturnBarChart.3years'),
        i18n('historicalReturnBarChart.5years'),
        i18n('historicalReturnBarChart.10years')
      ],
      lineWidth: 0,
      labels: {
        style: {
          color: theme.primaryColor,
          fontSize: TextFontSizes.xxSmall,
          fontFamily: FontFamilies.roboto,
          fontWeight: FontWeights.medium,
          lineHeight: TextFontSizes.xSmall
        }
      }
    },
    yAxis: {
      labels: {
        format: '{value:.1f}%',
        style: {
          color: theme.secondaryColor,
          fontSize: TextFontSizes.xxSmall,
          fontFamily: FontFamilies.roboto,
          lineHeight: TextFontSizes.xSmall
        }
      },
      gridLineColor: theme.secondaryColor,
      gridLineDashStyle: 'Dash' as const,
      plotLines: [
        {
          color: theme.secondaryColor,
          value: 0,
          zIndex: 2
        }
      ],
      title: { text: undefined },
      tickAmount: 10
    }
  };

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'chart'}
        options={options}
      />

      <Legend items={chartData.map(({ name }) => ({ title: name }))} />
    </>
  );
};

export default HistoricalReturnBarChart;
