import { ReactComponent as ArrowLeft } from 'assets/arrowLeft.svg';
import { ReactComponent as ArrowRight } from 'assets/arrowRight.svg';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40
  },
  arrowButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    border: 'none',
    padding: 0,
    width: theme.borderRadiusType === BorderRadiusTypes.rounded ? 36 : 40,
    height: theme.borderRadiusType === BorderRadiusTypes.rounded ? 36 : 40,
    borderRadius: theme.borderRadiusType === BorderRadiusTypes.rounded ? 20 : 2,
    marginBottom: theme.borderRadiusType === BorderRadiusTypes.rounded ? 2 : 0,
    marginTop: theme.borderRadiusType === BorderRadiusTypes.rounded ? 2 : 0,

    '&:hover': {
      background: theme.hoverInputStrokeColor,
      cursor: 'pointer'
    },

    '& svg path': {
      fill: theme.accentColor
    },

    '&:disabled': {
      pointerEvents: 'none'
    },

    '&:disabled svg path': {
      fill: theme.disabledTextColor
    }
  },
  pages: {
    minWidth: 'fit-content',
    margin:
      theme.borderRadiusType === BorderRadiusTypes.rounded ? '0 28px' : '0 30px'
  },
  footerContainer: {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(3, 1fr)',
    width: '100%'
  },
  trainingPaginationContainer: {
    gridColumnStart: 2
  },
  footerButton: {
    justifySelf: 'end',
    width: 'fit-content'
  }
}));

type Props = {
  pageNumber: number;
  totalPagesNumber: number;
  button?: React.ReactNode;
  changePageNumber: React.Dispatch<React.SetStateAction<number>>;
};

const PageBuilderModalFooter = ({
  button,
  pageNumber,
  totalPagesNumber,
  changePageNumber
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.footerContainer}>
      <div className={classes.trainingPaginationContainer}>
        <div className={classes.paginationContainer}>
          <button
            className={classes.arrowButton}
            onClick={() => changePageNumber(pageNumber - 1)}
            disabled={pageNumber === 0}
          >
            <ArrowLeft />
          </button>

          <span className={classes.pages}>
            {pageNumber + 1} / {totalPagesNumber}
          </span>

          <button
            className={classes.arrowButton}
            onClick={() => changePageNumber(pageNumber + 1)}
            disabled={pageNumber === totalPagesNumber - 1}
          >
            <ArrowRight />
          </button>
        </div>
      </div>

      {button}
    </div>
  );
};

export default PageBuilderModalFooter;
