export const types = {
  INITIALIZE: 'SESSION/INITIALIZE',
  LOGIN: 'SESSION/LOGIN',
  LOGOUT: 'SESSION/LOGOUT',
  SYNC_ADVISOR: 'SESSION/SYNC_ADVISOR'
};

export const creators = {
  initialize: (auth0AccessToken, user, customerConfig) => ({
    type: types.INITIALIZE,
    payload: { auth0AccessToken, user, customerConfig }
  }),
  login: loginData => ({
    type: types.LOGIN,
    payload: {
      loginData
    }
  }),
  logout: unauthorizedErrorType => ({
    type: types.LOGOUT,
    payload: { unauthorizedErrorType }
  })
};
