import ContactInformationSection from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/contactInformationSection';
import { useStyles } from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/detailsStyles';
import MessageDetails from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/messageDetails';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export const CustomerDetails = () => {
  const i18n = useI18n();
  const classes = useStyles();

  return (
    <div>
      <div className={classes.section}>
        <div className={classes.header}>
          {i18n('roboAdvice.client.preMeetingSurvey.oneTimePassword.header')}
        </div>
        <div className={classes.description}>
          {i18n(
            'roboAdvice.client.preMeetingSurvey.oneTimePassword.description'
          )}
        </div>
      </div>

      <MessageDetails />

      <ContactInformationSection />
    </div>
  );
};
