import { StylesProvider } from '@material-ui/core/styles';
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import AdviceSession from '../../adviceSession/main/components/index.js';
import OrderExecution from '../../adviceSession/orderExecution/components';
import Client from '../../client/main/components/index';
import ClientsList from '../../clientsList/main/components/index';
import { RoboAdviceClientsListPages } from '../../shared/constants';
import Watermark from './watermark';
import MainLayout from 'features/shared/components/mainLayout/index.js';
import routeTemplates from 'features/shared/utils/routeTemplates';

const Main = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={routeTemplates.roboAdvice.config}
          render={() => (
            <Redirect
              to={{
                pathname: routeTemplates.roboAdviceClientsList.build(
                  RoboAdviceClientsListPages.allClients
                ),
                search: window.location.search
              }}
            />
          )}
        />
        <Route
          render={() => (
            <StylesProvider injectFirst>
              <MainLayout>
                <Switch>
                  <Route
                    path={routeTemplates.roboAdviceClientsList.config}
                    component={ClientsList}
                  />
                  <Route
                    path={routeTemplates.roboAdviceClient.config}
                    component={Client}
                  />
                  <Route
                    path={routeTemplates.roboAdviceOrderExecution.config}
                    render={routeProps => {
                      const { adviceSessionId } = routeProps.match.params;
                      return <OrderExecution key={adviceSessionId} />;
                    }}
                  />
                  <Route
                    path={routeTemplates.roboAdviceAdviceSession.config}
                    render={routeProps => {
                      const { adviceSessionId } = routeProps.match.params;
                      return <AdviceSession key={adviceSessionId} />;
                    }}
                  />
                </Switch>
              </MainLayout>
            </StylesProvider>
          )}
        />
      </Switch>
      <Watermark />
    </>
  );
};

export default Main;
