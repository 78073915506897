import {
  RoboAdviceAdviceSessionPages,
  RoboAdviceOrderExecutionPages
} from 'features/roboAdvice/shared/constants/index';

export const UserRoles = {
  normalUser: 'normal_user',
  systemUser: 'system_user'
} as const;

export const UserPermissions = {
  createCustomers: 'create:customers',
  readCustomers: 'read:customers',
  updateCustomers: 'update:customers',
  deleteCustomers: 'delete:customers',
  createSystemModels: 'create:system_models',
  readSystemModels: 'read:system_models',
  updateSystemModels: 'update:system_models',
  deleteSystemModels: 'delete:system_models',
  updateProcessingTasksRun: 'update:processing_tasks_run'
} as const;

export const UnauthorizedErrorTypes = {
  allModuleLicensesExpired: 'all_module_licenses_expired'
} as const;

export const ModuleTypes = {
  advisorSolution: 'advisor_solution'
} as const;

export const LicenseTypes = {
  professional: 'professional',
  basic: 'basic',
  trial: 'trial',
  beta: 'beta'
} as const;

export const Statuses = {
  pending: 'pending',
  succeed: 'succeed',
  failed: 'failed'
} as const;

export const ClientTypes = {
  person: 'person',
  company: 'company'
} as const;

export type ClientType = typeof ClientTypes[keyof typeof ClientTypes];

export type AdviceSessionListParams = {
  clientId: string;
  clientType: ClientType;
};

export type AdviceSessionParams = {
  adviceSessionId: string;
  clientId: string;
  clientType: ClientType;
  pageId: typeof RoboAdviceAdviceSessionPages[keyof typeof RoboAdviceAdviceSessionPages];
};

export type OrderExecutionParams = {
  adviceSessionId: string;
  clientId: string;
  clientType: ClientType;
  pageId: typeof RoboAdviceOrderExecutionPages[keyof typeof RoboAdviceOrderExecutionPages];
};

export const NamespaceType = {
  config: 'config',
  risk: 'risk'
} as const;
