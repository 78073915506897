import { useMergeRefs, FloatingPortal } from '@floating-ui/react';
import classNames from 'classnames';
import { omit } from 'ramda';
import React from 'react';

import { useTooltipContext } from './context';
import { Typography } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  tooltip: {
    fontSize: Typography.body3.size,
    lineHeight: Typography.body3.lineHeight,
    maxWidth: 250,
    zIndex: 21 // * > modal zIndex
  },
  clickTooltip: {
    backgroundColor: theme.itemBackgroundColor_1,
    color: theme.primaryColor,
    border: `1px solid ${theme.accentColor}`,
    borderRadius: 10,
    padding: '15px 18px'
  },
  hoverTooltip: {
    backgroundColor: theme.primaryColor,
    borderRadius: 6,
    color: theme.pageBackgroundColor,
    padding: '7px 12px'
  }
}));

type Props = React.HTMLProps<HTMLDivElement> & {
  infoTooltip?: boolean;
  maxWidth?: number;
  minWidth?: number;
  contentStyles?: React.CSSProperties;
};

const TooltipContent = React.forwardRef<HTMLDivElement, Props>(
  (props, propRef) => {
    const classes = useStyles();
    const context = useTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    return (
      <FloatingPortal>
        {context.open && (
          <div
            className={classNames(
              classes.tooltip,
              props.infoTooltip ? classes.hoverTooltip : classes.clickTooltip
            )}
            ref={ref}
            style={{
              position: context.strategy,
              top: context.y ?? 0,
              left: context.x ?? 0,
              visibility: context.x == null ? 'hidden' : 'visible',
              minWidth: props.minWidth,
              maxWidth: props.maxWidth ?? 250,
              ...props.style,
              ...props.contentStyles
            }}
            {...context.getFloatingProps(
              omit(['infoTooltip', 'contentStyles'], props)
            )}
          />
        )}
      </FloatingPortal>
    );
  }
);

export default TooltipContent;
