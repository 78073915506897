import axios, { CancelTokenSource } from 'axios';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  AdviceSession,
  useHolisticViewStore
} from '../services/holisticViewStore';
import { getClientSessions } from 'features/roboAdvice/client/main/api';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

const useReadAdviceSessions = () => {
  const i18n = useI18n();
  const dispatch = useDispatch();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const readAdviceSessions = async () => {
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const holisticViewStoreState = useHolisticViewStore.getState();
    const selectedClients = holisticViewStoreState.availableClients.filter(
      c => c.isSelected && c.adviceSessions.length === 0
    );

    try {
      holisticViewStoreState.setIsReadAdviceSessionsPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      for (const client of selectedClients) {
        const endClientSessions = await getClientSessions(
          accessToken,
          cancelTokenSource.token,
          {
            investor_id: client.id
          }
        );

        const newClientSessions = endClientSessions.data.sessions.map(session =>
          mapServerAdviceSessionsToClient(session)
        );

        if (
          newClientSessions.length > 0 &&
          !holisticViewStoreState.availableClients.find(c => c.id === client.id)
            ?.selectedAdviceSessionId
        ) {
          holisticViewStoreState.setSelectedAdviceSession(
            client.id,
            newClientSessions[0].sessionId
          );
        }

        holisticViewStoreState.setAdviceSessions(client.id, newClientSessions);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('tools.holisticView.getAdviceSessionsError'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    } finally {
      holisticViewStoreState.setIsReadAdviceSessionsPending(false);
    }
  };

  return readAdviceSessions;
};

const mapServerAdviceSessionsToClient = ({
  name,
  session_id
}): AdviceSession => ({
  name,
  sessionId: session_id
});

export default useReadAdviceSessions;
