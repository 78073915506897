import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

import { useExpectedValueChartStore } from '../../../expectedValueChart';
import { usePageStore } from '../../services/pageStore';
import { useReadExpectedValueChartData } from './useReadExpectedValueChartData';
import { useReadDataListener } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import {
  useGoalsStore,
  selectors
} from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import ExpectedValueChartCardVisual from 'features/shared/analyticsComponents/expectedValueChart/visual';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';

const ExpectedValueChartCard = ({ isSelected }) => {
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const expectedValueChartStore = useExpectedValueChartStore();
  const selectedGoalsIds = useGoalsStore(selectors.getSelectedGoalsIds);
  const { pageStatuses } = usePageStore();
  const {
    analyticsComponents: {
      expectedValue: { bankReturn, compareWithBank }
    }
  } = useCustomerConfig();

  useReadDataListener(
    pageStatuses.readExpectedValueChartData,
    isSelected,
    useReadExpectedValueChartData()
  );

  React.useEffect(() => {
    expectedValueChartStore.setChartData(selectedGoalsIds);
  }, [expectedValueChartStore.goalsData, selectedGoalsIds]);

  const expectedValueTableData = pathOr(
    [],
    ['chartData', 'expectedValueTable'],
    expectedValueChartStore
  );
  const savingPlanChartData = pathOr(
    [],
    ['chartData', 'savingPlan'],
    expectedValueChartStore
  );
  const savingInBankAccountChartData = pathOr(
    [],
    ['chartData', 'savingInBankAccount'],
    expectedValueChartStore
  );

  return (
    <ExpectedValueChartCardVisual
      expectedValueTableData={expectedValueTableData}
      savingPlanChartData={savingPlanChartData}
      savingInBankAccountChartData={savingInBankAccountChartData}
      cultureCode={cultureCode}
      bankReturn={bankReturn}
      savingPlanOnly={!compareWithBank}
    />
  );
};

export default ExpectedValueChartCard;
