import axios from 'axios';
import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';

import { customerPatchQueue } from '../../customerPatchQueue';
import { CustomerPatchTypes } from '../constants.js';
import { manageCustomerForm } from '../services/manageCustomerForm';
import { patchCustomer } from 'features/customers/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useUpdateCustomer() {
  const i18n = useI18n();

  const dispatch = useDispatch();

  const currentCustomerId = useSelector(
    sessionSelectors.getCurrentUserCustomerId
  );
  const accessToken = useSelector(sessionSelectors.getAuth0AccessToken);

  const updateCustomer = async patches => {
    try {
      const patchesData = getPatchesData({ patches });
      const response = await patchCustomer(
        currentCustomerId,
        accessToken,
        currentCustomerId,
        {
          patches: patchesData
        }
      );

      handlePatchesSucceed({
        patches,
        responseData: response.data,
        manageCustomerForm,
        dispatch,
        i18n
      });
    } catch (error) {
      if (!axios.isCancel(error)) {
        handlePatchesFailed({ patches, manageCustomerForm, dispatch, i18n });

        throw error;
      }
    }
  };

  return updateCustomer;
}

export function useQueuedUpdateCustomer() {
  const updateCustomer = useUpdateCustomer();
  const queuedUpdateCustomer =
    customerPatchQueue.useQueuedPatch(updateCustomer);

  return queuedUpdateCustomer;
}

const getPatchesData = ({ patches }) => {
  return R.map(patch => {
    if (patch.type === CustomerPatchTypes.addUsers) {
      return R.over(
        R.lensPath(['payload', 'users']),
        R.map<any, any>(u => ({
          email: u.email,
          is_customer_admin: u.isCustomerAdmin
        })),
        patch
      );
    }

    if (patch.type === CustomerPatchTypes.changeUserField) {
      const fieldsMap = {
        isCustomerAdmin: 'is_customer_admin'
      };
      return R.over(
        R.lensPath(['payload', 'field']),
        field => fieldsMap[field],
        patch
      );
    }

    return patch;
  }, patches);
};

const handlePatchesSucceed = ({
  patches,
  responseData,
  manageCustomerForm,
  dispatch,
  i18n
}) => {
  const { patches: responsePatches } = responseData;

  for (const patch of responsePatches) {
    if (!patch.is_succeed) {
      dispatch(
        notificationActionCreators.showNotification({
          message: i18n('admin.manageCustomer.updateCustomerErrorMessage'),
          type: NotificationTypes.error
        })
      );
    } else {
      if (patch.type === CustomerPatchTypes.addUsers) {
        const { users: payloadUsers } = patch.payload;
        const { users: resultUsers } = patch.result;

        manageCustomerForm.batch(() => {
          manageCustomerForm.mutators.removeBatch(
            'usersToAdd',
            R.range(0, payloadUsers.length)
          );

          for (const u of resultUsers) {
            manageCustomerForm.mutators.push('users', {
              id: u.id,
              email: u.email,
              doesAuth0UserExist: u.does_auth0_user_exist,
              isCustomerAdmin: u.is_customer_admin
            });
          }
        });
      }
    }
  }
};

const handlePatchesFailed = ({
  patches,
  manageCustomerForm,
  dispatch,
  i18n
}) => {
  dispatch(
    notificationActionCreators.showNotification({
      message: i18n('admin.manageCustomer.updateCustomerErrorMessage'),
      type: NotificationTypes.error
    })
  );
};
