import classNames from 'classnames';
import { isEmpty } from 'ramda';
import React, { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  useAmountForAdviceAssetsWithValue,
  useFinancialSituationValues
} from '../../financialSituation/services/selectors';
import { useSessionStore } from '../../session/services/sessionStore';
import { PageStatuses } from '../../shared/components/useReadDataListener';
import { useGoalsStore } from '../../shared/services/goalsStore';
import {
  getIsRiskFinderLoading,
  usePageStore as useRiskScorePageStore
} from '../services/pageStore';
import {
  useHorizonRiskScoreColumns,
  useHorizonRiskScoreItems
} from '../services/selectors.js';
import Legend from './legend';
import NumberCell from './numberCell.js';
import RiskFinder from './riskFinder';
import { useReadRiskScoreSettingsListener } from './useReadRiskScoreSettingsListener';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import Checkbox from 'features/shared/components/checkbox';
import LoadingIndicator from 'features/shared/components/loadingIndicator/loadingIndicator';
import Table from 'features/shared/components/table/index.js';
import Tooltip from 'features/shared/components/tooltip';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

const tableWidth = 700; // equal to carouselBodyContainer
const containerGap = Spacing.spacing02;
const tabsMinWidth = 240;
const riskFinderWidth = tableWidth + tabsMinWidth;
const minimalRowViewWidth = riskFinderWidth + tableWidth + containerGap;
const MAX_RISK_SCORES_SELECTED = 3;

const useStyles = createUseStyles(theme => ({
  container: {
    display: 'flex',
    gap: containerGap
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  tableDisabled: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  numberHeaderCell: {
    lineHeight: Typography.body1.lineHeight,
    padding: Spacing.spacing01,
    textAlign: 'center',

    '&:first-child': {
      textAlign: 'left'
    }
  },
  titleCell: {
    color: theme.secondaryColor,
    lineHeight: Typography.body1.lineHeight,
    padding: Spacing.spacing01,
    wordBreak: 'normal'
  },
  description: {
    maxWidth: tableWidth,
    color: theme.secondaryColor,
    marginTop: Spacing.spacing02,
    marginBottom: Spacing.spacing04
  },
  noDataInfo: {
    color: theme.secondaryColor,
    textAlign: 'center',
    width: '100%'
  },
  valueContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px solid transparent`,
    lineHeight: '19px',
    textAlign: 'center',
    height: '100%',
    width: '100%'
  },
  hoverCell: {
    border: `2px solid ${theme.accentColor}`
  },
  selectedCells1: {
    border: `8px solid ${theme.chartPortfolioColors[0]}`
  },
  selectedCells2: {
    border: `8px solid ${theme.chartPortfolioColors[1]}`
  },
  selectedCells3: {
    border: `8px solid ${theme.chartPortfolioColors[2]}`
  },
  additionalColumnTitleClassName: {
    wordBreak: 'normal'
  },
  numberCell: {
    '&:last-child': {
      padding: 0
    }
  },
  compareWithExistingPortfolioContainer: {
    display: 'flex',
    gap: Spacing.spacing00,
    alignItems: 'center',
    marginBottom: Spacing.spacing02,
    cursor: 'pointer',
    userSelect: 'none'
  },
  disabledCompareWithExistingPortfolioContainer: {
    cursor: 'auto'
  },
  compareWithExistingPortfolioLabel: {
    cursor: 'pointer'
  },
  disabledLabel: {
    opacity: 0.5,
    cursor: 'auto'
  },
  infoIcon: {
    flexShrink: 0,
    rotate: '180deg',

    '& path': {
      fill: theme.warningColor
    }
  }
}));

export const PurposeAndRiskDetailsPopover = ({ modalBodyMaxWidth }) => {
  const classes = useStyles();
  const theme = useTheme();
  const i18n = useI18n();
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const columns = useHorizonRiskScoreColumns();
  const horizonRiskScoreItems = useHorizonRiskScoreItems();
  const { goals } = useGoalsStore();
  const {
    roboAdviceForm: {
      riskScorePanel: { isRiskScoreFinderEnabled }
    },
    roboAdvice: {
      riskFinder: { existingPortfolioComparisonEnabled }
    }
  } = useCustomerConfig();
  const [selectedRisks, setSelectedRisks] = React.useState([]);
  const [availableClassNames, setAvailableClassNames] = React.useState([
    { id: 0, color: theme.chartPortfolioColors[0] },
    { id: 1, color: theme.chartPortfolioColors[1] },
    { id: 2, color: theme.chartPortfolioColors[2] }
  ]);
  const mappingClassNames = {
    0: classes.selectedCells1,
    1: classes.selectedCells2,
    2: classes.selectedCells3
  };
  const riskScorePageStore = useRiskScorePageStore();
  const sessionStore = useSessionStore();
  const [hoveredCellValue, setHoveredCellValue] = React.useState(null);
  const [compareWithExistingPortfolio, setCompareWithExistingPortfolio] =
    React.useState(false);
  const numberCellsRef = React.useRef([]);
  const amountForAdviceAssetsWithValue = useAmountForAdviceAssetsWithValue();
  const financialSituationValues = useFinancialSituationValues();

  useReadRiskScoreSettingsListener();

  const mouseEnterHandler = e => {
    setHoveredCellValue(e.target.textContent);
  };

  const mouseLeaveHandler = () => {
    setHoveredCellValue(null);
  };

  const clickHandler = e => {
    const clickedRisk = e.target.textContent;
    const selectedRisk = selectedRisks.find(({ risk }) => risk === clickedRisk);

    if (selectedRisk) {
      setAvailableClassNames(prevState =>
        [{ id: selectedRisk.id, color: selectedRisk.color }, ...prevState].sort(
          (a, b) => {
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;
            return 0;
          }
        )
      );

      setSelectedRisks(prevState =>
        prevState.filter(selectedRisk => selectedRisk.risk !== clickedRisk)
      );

      riskScorePageStore.changePageStatuses({
        readPortfolioChartData: PageStatuses.ready
      });

      return;
    }

    if (
      selectedRisks.length < MAX_RISK_SCORES_SELECTED &&
      !(
        selectedRisks.length === MAX_RISK_SCORES_SELECTED - 1 &&
        compareWithExistingPortfolio
      )
    ) {
      const { id, color } = availableClassNames[0];
      setAvailableClassNames(prevState =>
        prevState.filter(({ id: prevId }) => id !== prevId)
      );

      setSelectedRisks(prevState => [
        ...prevState,
        {
          risk: clickedRisk,
          id,
          name: `${i18n('shared.portfolio')} ${clickedRisk}`,
          color
        }
      ]);

      riskScorePageStore.changePageStatuses({
        readPortfolioChartData: PageStatuses.ready
      });
    }
  };

  const compareWithExistingPortfolioError = useMemo(() => {
    if (selectedRisks.length === MAX_RISK_SCORES_SELECTED) {
      return i18n(
        'roboAdvice.riskScore.deselectOnePortfolioToCompareWithExistingPortfolio'
      );
    }

    const existingPortfolioData = amountForAdviceAssetsWithValue
      .map(({ id }) => financialSituationValues[id] || [])
      .flat();

    const existingPortfolioDataWithoutAssetClass = [];
    const existingPortfolioDataWithToAdvisoryValue = [];
    existingPortfolioData.forEach(item => {
      if (item.toAdvisory) {
        existingPortfolioDataWithToAdvisoryValue.push(item);
        if (!item.assetClass) {
          existingPortfolioDataWithoutAssetClass.push(item);
        }
      }
    });

    if (
      existingPortfolioDataWithoutAssetClass.length > 0 ||
      amountForAdviceAssetsWithValue.length === 0 ||
      existingPortfolioDataWithToAdvisoryValue.length === 0
    ) {
      return i18n('roboAdvice.riskScore.warningTooltipNotAllAssetsTagged');
    }

    return null;
  }, [selectedRisks, amountForAdviceAssetsWithValue, financialSituationValues]);

  const isRiskFinderLoading = getIsRiskFinderLoading();

  const existingPortfolioCheckboxElement = useMemo(() => {
    if (!existingPortfolioComparisonEnabled) {
      return null;
    }

    const mainElement = (
      <div
        onClick={() => {
          if (isRiskFinderLoading || compareWithExistingPortfolioError) {
            return;
          }

          setCompareWithExistingPortfolio(state => !state);

          riskScorePageStore.changePageStatuses({
            readPortfolioChartData: PageStatuses.ready
          });
        }}
        className={classNames(classes.compareWithExistingPortfolioContainer, {
          [classes.disabledCompareWithExistingPortfolioContainer]:
            isRiskFinderLoading || compareWithExistingPortfolioError
        })}
      >
        <Checkbox
          width={20}
          height={20}
          checked={compareWithExistingPortfolio}
          onChange={() => {}}
          name="existingPortfolioComparison"
          disabled={isRiskFinderLoading || compareWithExistingPortfolioError}
        />
        <label
          htmlFor="existingPortfolioComparison"
          className={classNames(classes.compareWithExistingPortfolioLabel, {
            [classes.disabledLabel]:
              isRiskFinderLoading || compareWithExistingPortfolioError
          })}
        >
          {i18n('roboAdvice.riskScore.compareWithExistingPortfolio')}
        </label>

        {compareWithExistingPortfolioError && (
          <WarningIcon className={classes.infoIcon} />
        )}
      </div>
    );

    if (compareWithExistingPortfolioError) {
      return (
        <Tooltip
          content={compareWithExistingPortfolioError}
          trigger={mainElement}
          triggerButtonStyles={{
            justifyContent: 'flex-start',
            width: 'max-content'
          }}
        />
      );
    }

    return mainElement;
  }, [
    compareWithExistingPortfolio,
    compareWithExistingPortfolioError,
    isRiskFinderLoading,
    classes
  ]);

  if (
    sessionStore.sessionStatuses.readRiskScoreSettings === PageStatuses.pending
  ) {
    return <LoadingIndicator color={theme.accentColor} />;
  }

  return (
    <div
      className={classes.container}
      data-testid={'purpose-and-risk-details-popover'}
      style={{
        flexDirection:
          modalBodyMaxWidth >= minimalRowViewWidth ? 'row-reverse' : 'column'
      }}
    >
      {isEmpty(horizonRiskScoreItems) ? (
        <div className={classes.noDataInfo}>
          {i18n('roboAdvice.riskScore.noPurposeAndRiskData')}
        </div>
      ) : (
        <div>
          {existingPortfolioCheckboxElement}

          <Table
            style={{
              width:
                modalBodyMaxWidth >= minimalRowViewWidth
                  ? tableWidth
                  : modalBodyMaxWidth
            }}
            data-testid={'purpose-and-risk-details-table'}
            externalClasses={{
              container: classNames(classes.table, classes.tableContainer, {
                [classes.tableDisabled]: isRiskFinderLoading
              }),
              table: classes.table,
              additionalColumnTitleClassName:
                classes.additionalColumnTitleClassName
            }}
            header={[
              {
                title: i18n('roboAdvice.riskScore.horizonRisk'),
                className: classes.numberHeaderCell
              },
              ...columns.map(title => ({
                title,
                className: classes.numberHeaderCell
              }))
            ]}
            items={horizonRiskScoreItems.map((item, index, array) => ({
              id: index,
              cells: ({ bodyRowCellClassName }) => (
                <Fragment>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.titleCell
                    )}
                  >
                    {item.title}
                  </td>
                  {item.values.map((v, vIndex) => (
                    <NumberCell
                      key={vIndex}
                      backgroundStylesContainer
                      className={classNames(
                        bodyRowCellClassName,
                        classes.numberCell
                      )}
                      cultureCode={cultureCode}
                      ref={element =>
                        (numberCellsRef.current[index * array.length + vIndex] =
                          element)
                      }
                      value={v}
                      valueContainerClassName={classNames(
                        classes.valueContainer,
                        mappingClassNames[
                          selectedRisks.find(({ risk }) => +risk === v.value)
                            ?.id
                        ],
                        {
                          [classes.hoverCell]:
                            hoveredCellValue ===
                            numberCellsRef.current[
                              index * array.length + vIndex
                            ]?.textContent
                        }
                      )}
                      onClick={isRiskScoreFinderEnabled && clickHandler}
                      onMouseEnter={
                        isRiskScoreFinderEnabled && mouseEnterHandler
                      }
                      onMouseLeave={
                        isRiskScoreFinderEnabled && mouseLeaveHandler
                      }
                    />
                  ))}
                </Fragment>
              )
            }))}
          />

          {!!goals.length && <Legend />}

          <div className={classes.description}>
            {i18n('roboAdvice.riskScore.contributionToRiskScoreDescription')}
          </div>
        </div>
      )}

      {(selectedRisks.length > 0 || compareWithExistingPortfolio) && (
        <RiskFinder
          selectedRisks={selectedRisks}
          compareWithExistingPortfolio={compareWithExistingPortfolio}
        />
      )}
    </div>
  );
};

export default PurposeAndRiskDetailsPopover;
