import create from 'zustand';

export type CrmLoginStoreData = {
  enableCrmLogin: boolean;
};

export type CrmLoginStoreApi = {
  setEnableCrmLogin: (enableCrmLogin: boolean) => void;
};

export type CrmLoginStoreState = CrmLoginStoreData & CrmLoginStoreApi;

const defaultData = {
  enableCrmLogin: true
};

export const useCrmLoginStore = create<CrmLoginStoreState>(set => ({
  ...defaultData,
  setEnableCrmLogin(enableCrmLogin) {
    set({ enableCrmLogin });
  }
}));
