import classNames from 'classnames';
import * as R from 'ramda';
import { Fragment } from 'react';
import { UseFieldConfig } from 'react-final-form';

import { FieldComponentTypes } from '../../declarativeForm/constants.js';
import { getErrorText } from './shared.js';
import { useField } from './useField';
import { Layouts } from 'features/roboAdvice/shared/constants';
import Checkbox from 'features/shared/components/checkbox/index';
import ErrorMessage from 'features/shared/components/errorMessage/index.js';
import { Colors } from 'features/shared/constants/colors.js';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { ConfigurableComponent } from 'features/sharedModules/declarativeForm/components';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  section: {},
  subSection: {
    '&$horizontal': {},

    '&$vertical': {}
  },
  label: {
    color: theme.primaryColor,
    marginRight: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.spacing01,
    marginBottom: Spacing.spacing01
  },
  horizontalLabel: {
    width: '350px'
  },
  controlSectionsWrap: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 0
  },
  controlSection: {
    display: 'flex',
    alignItems: 'center'
  },
  control: {},
  controlText: {
    flex: '0 1 auto',
    cursor: 'pointer',
    marginLeft: '1.2rem',

    '&$disabled': {
      cursor: 'default'
    }
  },
  controlLabel: {
    color: theme.primaryColor,

    '&$disabled': {
      color: theme.disabledTextColor
    }
  },
  controlDescription: {
    color: Colors.gray50,
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight
  },
  disabled: {},
  vertical: {},
  horizontal: {
    display: 'inline-flex'
  },
  textField: {
    margin: '15px 30px'
  },
  checkboxTextField: {
    margin: '15px 0'
  },
  iconContainer: {
    marginLeft: 'auto'
  }
}));

type CheckboxItem = {
  activeValue: string | number | null | undefined;
  label: string;
  icon?: string;
  description?: string;
  textField?: {
    name: string;
    [x: string]: any;
  };
};

type Props = {
  name: string;
  fieldConfig?: UseFieldConfig<any, any>;
  afterChange?: (value: any) => void;
  label?: string;
  items: CheckboxItem[];
  disabled?: boolean;
  checkedColor?: string;
  uncheckedColor?: string;
  width?: string | number;
  height?: string | number;
  layout?: keyof typeof Layouts;
  className?: string;
  labelClassName?: string;
  controlLabelClassName?: string;
  sectionClassName?: string;
  subSectionClassName?: string;
  externalClasses?: string;
  'data-testid'?: string;
  [x: string]: unknown;
};

const FinalFormCheckboxes = ({
  name,
  fieldConfig,
  afterChange,
  label,
  items,
  disabled,
  checkedColor,
  uncheckedColor,
  width,
  height,
  layout,
  className,
  labelClassName,
  controlLabelClassName,
  sectionClassName,
  subSectionClassName,
  externalClasses,
  ...restProps
}: Props) => {
  const i18n = useI18n();
  const {
    input: { value, onChange },
    meta: { error, touched }
  } = useField(name, fieldConfig);

  const internalClasses = useStyles();
  const classes = R.isNil(externalClasses)
    ? internalClasses
    : R.mergeWith(
        (c1, c2) => classNames(c1, c2),
        internalClasses,
        externalClasses
      );

  const isErrorShown = touched && !R.isNil(error) && !R.isEmpty(error);
  const errorText = getErrorText(i18n, error);

  const onChangeInternal = valueInternal => {
    if (R.isNil(value)) {
      onChange([valueInternal]);
      !R.isNil(afterChange) && afterChange([valueInternal]);
    } else {
      const updatedValue = R.includes(valueInternal, value)
        ? value.filter(val => val !== valueInternal)
        : [...value, valueInternal];
      onChange(updatedValue);
      !R.isNil(afterChange) && afterChange(updatedValue);
    }
  };

  return (
    <div className={classNames(classes.section, sectionClassName)}>
      <div
        data-testid={restProps['data-testid']}
        className={classNames(classes.subSection, subSectionClassName, {
          [classes.horizontal]: layout === Layouts.horizontal,
          [classes.vertical]: layout === Layouts.vertical
        })}
      >
        {!R.isNil(label) && !R.isEmpty(label) && (
          <div
            className={classNames(classes.label, labelClassName, {
              [classes.horizontalLabel]: layout === Layouts.horizontal
            })}
          >
            {label}
          </div>
        )}
        <div
          className={classNames(classes.label, labelClassName, {
            [classes.controlSectionsWrap]: layout === Layouts.horizontal
          })}
        >
          {items.map((i, index) => {
            const isLabelShown = !R.isNil(i.label) && !R.isEmpty(i.label);
            const isDescriptionShown =
              !R.isNil(i.description) && !R.isEmpty(i.description);
            const isIconShown = !R.isNil(i.icon) && !R.isEmpty(i.icon);

            return (
              <Fragment key={index}>
                <div
                  className={classNames(
                    classes.controlSection,
                    {
                      [classes.horizontal]: layout === Layouts.horizontal,
                      [classes.vertical]: layout === Layouts.vertical
                    },
                    className
                  )}
                >
                  <div className={classes.control}>
                    <Checkbox
                      data-testid={i.activeValue}
                      checked={
                        !R.isNil(value) && R.includes(i.activeValue, value)
                      }
                      onChange={e => onChangeInternal(i.activeValue)}
                      disabled={disabled}
                      checkedColor={checkedColor}
                      uncheckedColor={uncheckedColor}
                      width={width}
                      height={height}
                    />
                  </div>
                  {(isLabelShown || isDescriptionShown) && (
                    <div
                      className={classNames(classes.controlText, {
                        [classes.disabled]: disabled
                      })}
                      onClick={() =>
                        !disabled && onChangeInternal(i.activeValue)
                      }
                    >
                      {isLabelShown && (
                        <div
                          className={classNames(
                            classes.controlLabel,
                            { [classes.disabled]: disabled },
                            controlLabelClassName
                          )}
                        >
                          {i.label}
                        </div>
                      )}
                      {isDescriptionShown && (
                        <div className={classNames(classes.controlDescription)}>
                          {i.description}
                        </div>
                      )}
                    </div>
                  )}
                  {isIconShown && (
                    <div className={classes.iconContainer}>{i.icon}</div>
                  )}
                </div>
                {i.textField && value && value.includes(i.activeValue) && (
                  <ConfigurableComponent
                    key={i.textField.name}
                    config={{
                      componentType: FieldComponentTypes.textInput
                    }}
                    sectionClassName={classes.checkboxTextField}
                    {...i.textField}
                  />
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
      {isErrorShown && <ErrorMessage>{`* ${errorText}`}</ErrorMessage>}
    </div>
  );
};

FinalFormCheckboxes.defaultProps = {
  layout: Layouts.horizontal
};

export default FinalFormCheckboxes;
