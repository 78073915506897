import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useSessionStore } from '../../../session/services/sessionStore';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { getFundsList } from 'features/roboAdvice/adviceSession/shared/api';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

const DefaultCultureCode = 'no-no';

export function useReadInstruments(setInstruments) {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cultureCode: string =
    useSelector(sessionSelectors.getCurrentUserCultureCode) ||
    DefaultCultureCode;
  const i18n = useI18n();
  const dispatch = useDispatch();
  const cancelTokenSourceRef = React.useRef<CancelTokenSource>();
  const sessionStoreState = useSessionStore.getState();

  const readInstruments = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    try {
      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const response = await getFundsList(
        accessToken,
        cancelTokenSource.token,
        {
          namespace_id: sessionStoreState.defaultConfigNamespaceId,
          language: getUserPageLanguage()
        }
      );

      const instruments =
        response.data?.funds
          ?.map(fund => ({
            id: fund.Ticker,
            name: fund.Name,
            isin: fund.ISIN,
            subAssetClass: Object.values(fund.SubAssetClasses).join(', ')
          }))
          ?.sort((a, b) => a.name.localeCompare(b.name, cultureCode)) || [];

      setInstruments(instruments);
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.orderExecution.readInstrumentsErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  React.useEffect(() => {
    return () => {
      if (!isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
    };
  }, []);

  return readInstruments;
}
