import classNames from 'classnames';
import React from 'react';

import { Typography } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  errorMessage: {
    color: theme.errorNegativeColor,
    marginTop: '0.8rem',
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight
  }
}));

const ErrorMessage = ({ children, className = '' }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.errorMessage, className)}>
      {children}
    </div>
  );
};

export default ErrorMessage;
