import * as React from 'react';

import TableBodyComponent from 'features/shared/components/table/tableBody.js';

// Temporary solution until types added to TableBodyComponent
const AnyTableBodyComponent = TableBodyComponent as any;

type TableBodyComponenetProps = {
  children: React.ReactNode;
  noDataTitle: string;
  cellsNumber: number;
};

type TableBodyProps = TableBodyComponenetProps;

const TableBody = ({ children, ...restProps }: TableBodyProps) => {
  return (
    <AnyTableBodyComponent {...restProps}>{children}</AnyTableBodyComponent>
  );
};

export default TableBody;
