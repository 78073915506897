import {
  HistoricalReturnChartData,
  HistoricalReturnTimeRangeChartData
} from '@quantfoliorepo/ui-components';
import axios from 'axios';
import { format } from 'date-fns';
import * as R from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentDate } from '../../../../shared/utils';
import { useHistoricalReturnChartStore } from '../../../historicalReturnChart';
import {
  createHistoricalReturnBenchmark,
  createHistoricalReturnV2
} from '../../../shared/api';
import {
  usePageStore as useProposalPageStore,
  ProposalPageStatuses
} from '../../services/pageStore';
import {
  getIsFormComplete,
  getLookbackStartDate
} from '../../services/selectors.js';
import { usePortfolioChartStore } from 'features/roboAdvice/adviceSession/portfolioChart';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { availableHistoricalReturnStatisticsMapping } from 'features/roboAdvice/adviceSession/shared/mapping';
import { useGoalsStore } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { useTheme } from 'features/sharedModules/styles/components/styles';

export function useReadHistoricalReturnChartData(
  statusKey: keyof ProposalPageStatuses,
  timeRangeChartDataKey: keyof HistoricalReturnChartData
) {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();
  const theme = useTheme();
  const {
    analyticsComponents: { showStatsInHistoricalReturn },
    roboAdvice: {
      historicalReturn: { benchmarkType, useBenchmarkDataForPortfolios }
    }
  } = useCustomerConfig();
  const portfolioChartStore = usePortfolioChartStore();

  const cancelTokenSourceRef = React.useRef<any>();
  const readHistoricalReturnChartData = async () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const proposalPageStore = useProposalPageStore.getState();
    const historicalReturnChartStore = useHistoricalReturnChartStore.getState();
    const { goals } = useGoalsStore.getState();

    const isFormComplete = getIsFormComplete(false);
    if (!isFormComplete) {
      historicalReturnChartStore.resetTimeRangeChartData(timeRangeChartDataKey);
      proposalPageStore.setPageStatus(statusKey, PageStatuses.failed);

      return;
    }

    try {
      proposalPageStore.setPageStatus(statusKey, PageStatuses.pending);

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const currentDate = getCurrentDate();
      let historicalReturn: HistoricalReturnTimeRangeChartData[] = [];

      for (const [index, goal] of Array.from(goals.entries())) {
        const portfolio = portfolioChartStore
          .getChartData(goal.goalId, goal.data.isPortfolioCustom)
          .categorySelectionPortfolio.map(({ id, weight }) => ({ id, weight }));

        let historicalReturnResponse;
        if (useBenchmarkDataForPortfolios) {
          historicalReturnResponse = await createHistoricalReturnBenchmark(
            qAuthAccessToken,
            cancelTokenSource.token,
            {
              portfolio,
              lookback: {
                start_date: format(
                  getLookbackStartDate(timeRangeChartDataKey, currentDate),
                  'yyyy-MM-dd'
                ),
                end_date: format(currentDate, 'yyyy-MM-dd')
              },
              showStats: showStatsInHistoricalReturn,
              benchmarkType: benchmarkType
            },
            {
              namespace_id: goal.data.productPlatformNamespace
            }
          );
        } else {
          historicalReturnResponse = await createHistoricalReturnV2(
            qAuthAccessToken,
            cancelTokenSource.token,
            {
              portfolio: portfolio,
              savingsplan: {
                starting_capital: 100
              },
              lookback: {
                start_date: format(
                  getLookbackStartDate(timeRangeChartDataKey, currentDate),
                  'yyyy-MM-dd'
                ),
                end_date: format(currentDate, 'yyyy-MM-dd')
              },
              showStats: showStatsInHistoricalReturn
            },
            {
              namespace_id: goal.data.productPlatformNamespace
            }
          );
        }

        const historicalReturnResponseData = historicalReturnResponse.data
          ?.portfolioStatistics
          ? {
              ...historicalReturnResponse.data,
              portfolioStatistics: Object.entries(
                historicalReturnResponse.data.portfolioStatistics
              ).reduce((acc, [key, value]) => {
                return {
                  ...acc,
                  [availableHistoricalReturnStatisticsMapping[key]]: value
                };
              }, {})
            }
          : historicalReturnResponse.data;

        historicalReturn = [
          ...historicalReturn,
          {
            ...historicalReturnResponseData,
            goalId: goal.goalId,
            goalName: goal.name,
            color: theme.chartPortfolioColors[index]
          }
        ];
      }

      historicalReturnChartStore.setTimeRangeChartData(
        timeRangeChartDataKey,
        historicalReturn
      );

      proposalPageStore.setPageStatus(statusKey, PageStatuses.succeed);
    } catch (error) {
      if (!axios.isCancel(error)) {
        if (
          R.path(['response', 'status'], error) === 400 &&
          R.path(['response', 'data', 'qf-api-error-code'], error) === 416
        ) {
          historicalReturnChartStore.resetTimeRangeChartData(
            timeRangeChartDataKey
          );
          proposalPageStore.setPageStatus(statusKey, PageStatuses.succeed);
        } else {
          historicalReturnChartStore.resetTimeRangeChartData(
            timeRangeChartDataKey
          );
          proposalPageStore.setPageStatus(statusKey, PageStatuses.failed);

          dispatch(
            notificationActionCreators.showNotification({
              message: i18n('roboAdvice.proposal.readDataErrorMessage'),
              type: NotificationTypes.error
            })
          );

          throwSafeError(error);
        }
      }
    }
  };

  return readHistoricalReturnChartData;
}
