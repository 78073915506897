import {
  Typography,
  FontWeights,
  FontFamilies
} from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  '@global': {
    html: {
      fontSize: '62.5%'
    },
    body: {
      backgroundColor: theme.pageBackgroundColor,
      margin: 0,
      padding: 0,
      color: theme.primaryColor,
      fontSize: Typography.body1.size,
      lineHeight: Typography.body1.lineHeight,
      fontFamily: FontFamilies.roboto,
      fontWeight: FontWeights.normal,
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale'
    },
    iframe: {
      borderWidth: '0'
    },
    '*': {
      boxSizing: 'border-box',
      scrollbarColor: `${theme.secondaryColor} transparent`,

      '&:hover, &:active': {
        scrollbarColor: `${theme.accentColor} transparent`
      },

      '&::-webkit-scrollbar-thumb': {
        background: theme.secondaryColor
      },

      '&::-webkit-scrollbar-thumb:hover, &::-webkit-scrollbar-thumb:active': {
        background: theme.accentColor
      }
    }
  }
}));

const RootStyle = () => {
  useStyles();

  return null;
};

export default RootStyle;
