import { ReactComponent as InfoOutlineIcon } from 'assets/infoOutline.svg';
import { ReactComponent as CrossIcon } from 'assets/x-icon.svg';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  adaptedValueMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '20px',
    backgroundColor: theme.accentColor,
    padding: '4px 16px 4px 10px',
    color: theme.primaryColor,
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight,
    fontWeight: 500
  },
  infoIcon: {
    marginRight: 4,

    '& path': {
      fill: theme.primaryColor
    },

    '& circle': {
      stroke: theme.primaryColor
    }
  },
  closeButton: {
    cursor: 'pointer',
    marginLeft: Spacing.spacing01,
    fill: theme.primaryColor,

    '& path': {
      opacity: 0.5
    },

    '&:hover': {
      '& path': {
        opacity: 1
      }
    }
  }
}));

type Props = {
  hasCloseButton?: boolean;
  onClose?: () => void;
};

const AdaptedValueMessage = ({ hasCloseButton = false, onClose }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();

  return (
    <div className={classes.adaptedValueMessageContainer}>
      <InfoOutlineIcon className={classes.infoIcon} />
      {i18n(
        'roboAdvice.sustainability.sustainabilityPreferencesAdaptedMessage'
      )}
      {hasCloseButton && (
        <CrossIcon className={classes.closeButton} onClick={onClose} />
      )}
    </div>
  );
};

export default AdaptedValueMessage;
