import classNames from 'classnames';

import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  link: {
    color: theme.accentColor,
    textDecoration: 'none',
    cursor: 'pointer',

    '&$disabled': {
      color: theme.disabledTextColor,
      cursor: 'default'
    }
  },
  disabled: {}
}));

type Props = {
  href: string;
  disabled?: boolean;
} & React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

const ExternalLink = ({
  children,
  href,
  className,
  disabled = false,
  ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <a
      {...rest}
      href={disabled ? undefined : href}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(
        classes.link,
        { [classes.disabled]: disabled },
        className
      )}
    >
      {children}
    </a>
  );
};

export default ExternalLink;
