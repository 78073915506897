import { Colors } from 'features/shared/constants/colors.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const usePageStyles = createUseStyles({
  header: {
    margin: '3rem 0'
  },
  section: {
    '& + &': {
      marginTop: '3rem'
    }
  },
  label: {
    color: Colors.gray50
  },
  horizontalSection: {
    display: 'flex',
    alignItems: 'baseline'
  },
  horizontalLabel: {
    marginRight: '40px'
  },
  verticalSection: {},
  verticalLabel: {
    marginBottom: '20px'
  }
});
