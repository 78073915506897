import classNames from 'classnames';
import * as R from 'ramda';
import * as React from 'react';

import Icon from 'features/shared/components/icon/index.js';
import TextInputWithBothBlocks from 'features/shared/components/textInput/withBothBlocks/index.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const actionTypes = {
  TOGGLE_INPUT_FOCUS: 'TOGGLE_INPUT_FOCUS'
};

const initialState = {
  isInputFocused: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_INPUT_FOCUS: {
      return R.assoc('isInputFocused', action.payload, state);
    }
    default:
      return state;
  }
};

const useStyles = createUseStyles(theme => ({
  textInput: {
    position: 'relative',
    zIndex: 1,
    paddingLeft: '6rem',
    paddingRight: '6rem'
  },
  leftBlock: {
    zIndex: 1,
    pointerEvents: 'none'
  },
  rightBlock: {
    zIndex: 1
  },
  icon: {
    fontSize: '2rem',
    lineHeight: '2.4rem',
    color: theme.secondaryColor,
    transform: 'rotate(0.03deg)' // fix of issue: icon small size causes pixelated icon
  },
  searchIcon: {
    '&$focused': {
      color: theme.hoverInputStrokeColor
    }
  },
  focused: {},
  cancelIcon: {
    cursor: 'pointer'
  }
}));

const SearchInput = ({
  disabled,
  forwardedRef,
  placeholder,
  onClick,
  value,
  onChange,
  onFocus,
  onBlur,
  isInvalid,
  className,
  containerClassName
}) => {
  const classes = useStyles();
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <TextInputWithBothBlocks
      className={containerClassName}
      textInputProps={{
        disabled,
        forwardedRef,
        className: classNames(classes.textInput, className),
        onFocus: e => {
          dispatch({
            type: actionTypes.TOGGLE_INPUT_FOCUS,
            payload: true
          });

          onFocus && onFocus(e);
        },
        onBlur: e => {
          dispatch({
            type: actionTypes.TOGGLE_INPUT_FOCUS,
            payload: false
          });

          onBlur && onBlur(e);
        },
        onClick,
        placeholder,
        value,
        onChange,
        isInvalid
      }}
      leftBlockProps={{ className: classes.leftBlock }}
      leftBlockChildren={
        <Icon
          className={classNames(classes.icon, classes.searchIcon, {
            [classes.focused]: state.isInputFocused
          })}
          type="search"
        />
      }
      rightBlockProps={{ className: classes.rightBlock }}
      rightBlockChildren={
        R.isNil(value) || R.isEmpty(value) ? null : (
          <Icon
            className={classNames(classes.icon, classes.cancelIcon)}
            // tabIndex="0"
            onMouseDown={e => {
              e.preventDefault();
            }}
            onClick={e => {
              onChange({
                target: { value: '' }
              });
            }}
            type="cancel"
          />
        )
      }
    />
  );
};

export default SearchInput;
