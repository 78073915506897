export const trashCanPagesValues = {
  clients: 'clients',
  adviceSessions: 'advice-sessions'
} as const;

type Keys = keyof typeof trashCanPagesValues;
type Values = typeof trashCanPagesValues[Keys];
type Page = {
  value: Values;
  label: string;
};

export const trashCanPages: Page[] = [
  {
    value: trashCanPagesValues.clients,
    label: 'admin.trashCan.navItems.clients'
  },
  {
    value: trashCanPagesValues.adviceSessions,
    label: 'admin.trashCan.navItems.adviceSessions'
  }
];
