import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import { useTemplateStore } from '../../../../shared/components/generateNewPdfReportModal/templateStore';
import { usePageStore as useProposalPageStore } from '../../../services/pageStore';
import useGenerateReport from './useGenerateReport';
import { useQueuedPatchAdviceSession } from 'features/roboAdvice/adviceSession/main/components/useQueuedPatchAdviceSession';
import GenerateNewPdfReportForm from 'features/roboAdvice/adviceSession/shared/components/generateNewPdfReportModal/form';
import { FormValues } from 'features/roboAdvice/adviceSession/shared/components/generateNewPdfReportModal/form/types';
import useBuildPayload from 'features/roboAdvice/adviceSession/shared/components/generateNewPdfReportModal/useBuildPayload';
import useBuildAdvisorInfoPayload from 'features/roboAdvice/adviceSession/shared/components/useBuildAdvisorInfoPayload';
import {
  SessionNamespaces,
  SessionPatchTypes
} from 'features/roboAdvice/adviceSession/shared/constants';
import Button from 'features/shared/components/button';
import Modal from 'features/shared/components/modal';
import { useModalBodyMaxDimensions } from 'features/shared/components/modal/useModalBodyMaxDimensions';
import Scrollbars from 'features/shared/components/scrollbars';
import { AdviceSessionParams } from 'features/shared/constants/session';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

const GenerateNewPdfReportModal = () => {
  const i18n = useI18n();
  const proposalPageStore = useProposalPageStore();
  const { headerRef, modalBodyMaxHeight, footerRef } =
    useModalBodyMaxDimensions();
  const { template } = useTemplateStore();
  const { reportAdvice } = useCustomerConfig();
  const buildPayload = useBuildPayload();
  const buildAdvisorInfoPayload = useBuildAdvisorInfoPayload();
  const generateReport = useGenerateReport();
  const queuedPatchAdviceSession = useQueuedPatchAdviceSession();
  const { adviceSessionId } = useParams<AdviceSessionParams>();

  const handleSubmit = async (formValues: FormValues) => {
    await queuedPatchAdviceSession({
      id: v4(),
      type: SessionPatchTypes.saveFinishedSessionData,
      adviceSessionId,
      payload: buildAdvisorInfoPayload(),
      namespace: SessionNamespaces.finishedSessionAdvisorInfo
    });

    proposalPageStore.setIsGenerateNewPdfReportPopupOpen(false);
    const requestPayload = buildPayload(formValues);
    await generateReport(requestPayload);
  };

  return (
    <Modal
      header={i18n('roboAdvice.proposal.generateProposal')}
      headerRef={headerRef}
      isOpen={proposalPageStore.isGenerateNewPdfReportPopupOpen}
      onRequestClose={() => {
        proposalPageStore.setIsGenerateNewPdfReportPopupOpen(false);
      }}
      disabled={proposalPageStore.isReadNewPdfReportPending}
      footer={
        <Button disabled={!Boolean(template)} form="generate-new-pdf-form">
          {i18n('roboAdvice.proposal.generateReport.generateReport')}
        </Button>
      }
      footerRef={footerRef}
    >
      <Scrollbars autoHeightMax={modalBodyMaxHeight}>
        <GenerateNewPdfReportForm
          header={i18n('roboAdvice.reportGeneration.selectTemplate')}
          sectionsHeader={i18n('roboAdvice.reportGeneration.sectionToInclude')}
          attachmentsHeader={i18n(
            'roboAdvice.reportGeneration.attachmentsToInclude'
          )}
          templates={reportAdvice}
          onSubmit={handleSubmit}
        />
      </Scrollbars>
    </Modal>
  );
};

export default GenerateNewPdfReportModal;
