import create from 'zustand';

export type ProductAttributeBinary = {
  productAttributeBinary1: boolean | null;
  productAttributeBinary2: boolean | null;
  productAttributeBinary3: boolean | null;
  productAttributeBinary4: boolean | null;
  productAttributeBinary5: boolean | null;
};

export type ProductAttributeText = {
  productAttributeTxt1: string | null;
  productAttributeTxt2: string | null;
  productAttributeTxt3: string | null;
  productAttributeTxt4: string | null;
  productAttributeTxt5: string | null;
};

export type CustomAttribute = {
  fundStandardName: string;
  isin: string;
  ticker: string;
  name: string;
  productAttributeBinary: ProductAttributeBinary | null;
  productAttributeText: ProductAttributeText | null;
};

export type AdviceSessionStoreData = {
  customAttributesData: Record<number, CustomAttribute[]>;
};

export type AdviceSessionStoreApi = {
  reset: () => void;
  initialize: (initialData: Partial<AdviceSessionStoreData>) => void;
  addCustomAttributesData: (
    namespaceId: number,
    customAttributesData: CustomAttribute[]
  ) => void;
};

export type AdviceSessionStoreState = AdviceSessionStoreData &
  AdviceSessionStoreApi;

const defaultData = {
  customAttributesData: {}
};

export const useAdviceSessionStore = create<AdviceSessionStoreState>(set => ({
  ...defaultData,
  reset: () => {
    set(defaultData);
  },
  initialize(initialData) {
    set(
      state => ({
        ...state,
        ...initialData
      }),
      true
    );
  },
  addCustomAttributesData(namespaceId, customAttributesData) {
    set(state => ({
      customAttributesData: {
        ...state.customAttributesData,
        [namespaceId]: customAttributesData
      }
    }));
  }
}));
