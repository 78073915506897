import * as R from 'ramda';
import create, { UseBoundStore } from 'zustand';

type PatchQueueStoreData = {
  isPending: boolean;
  isScheduled: boolean;
  patches: object[];
};

type PatchQueueStoreApi = {
  reset: () => void;
  setIsPending: (isPending: any) => void;
  setIsScheduled: (isScheduled: any) => void;
  enqueuePatch: (patch: any) => void;
  dequeuePatches: (patchesNumber: any) => void;
};

type PatchQueueStoreState = PatchQueueStoreData & PatchQueueStoreApi;

export type UsePatchQueueStore = UseBoundStore<PatchQueueStoreState>;

export const createUsePatchQueueStore = (
  intialData?: Partial<PatchQueueStoreData>
) => {
  const defaultData = {
    isPending: false,
    isScheduled: false,
    patches: [],
    ...intialData
  };

  return create<PatchQueueStoreState>(set => ({
    ...defaultData,
    reset: () => {
      set(defaultData);
    },
    setIsPending: isPending => {
      set({ isPending });
    },
    setIsScheduled: isScheduled => {
      set({ isScheduled });
    },
    enqueuePatch: patch => {
      set(R.over(R.lensProp('patches'), R.append(patch)), true);
    },
    dequeuePatches: patchesNumber => {
      set(R.over(R.lensProp('patches'), R.drop(patchesNumber)), true);
    }
  }));
};
