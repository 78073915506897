import classNames from 'classnames';
import { isEmpty } from 'ramda';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { useSessionStore } from '../../session/services/sessionStore';
import { useGoalsStore } from '../../shared/services/goalsStore';
import {
  useHorizonRiskScoreColumns,
  useHorizonRiskScoreItems
} from '../services/selectors.js';
import Legend from './legend';
import NumberCell from './numberCell.js';
import { useReadRiskScoreSettingsListener } from './useReadRiskScoreSettingsListener';
import Table from 'features/shared/components/table/index.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  numberHeaderCell: {
    textAlign: 'right',
    padding: '1.6rem 2.1rem'
  },
  titleCell: {
    color: theme.secondaryColor
  },
  description: {
    maxWidth: '600px',
    color: theme.secondaryColor,
    marginTop: '20px'
  },
  noDataInfo: {
    color: theme.secondaryColor,
    textAlign: 'center',
    minWidth: '280px'
  }
}));

export const PurposeAndRiskDetailsTooltip = ({ scheduleUpdate }) => {
  const classes = useStyles();
  const i18n = useI18n();
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const columns = useHorizonRiskScoreColumns();
  const horizonRiskScoreItems = useHorizonRiskScoreItems();
  const sessionStore = useSessionStore();
  const { goals } = useGoalsStore();

  React.useLayoutEffect(() => {
    scheduleUpdate();
  }, [sessionStore.sessionStatuses.readRiskScoreSettings]);

  useReadRiskScoreSettingsListener();

  return (
    <div data-testid={'purpose-and-risk-details-popover'}>
      {isEmpty(horizonRiskScoreItems) && (
        <div className={classes.noDataInfo}>
          {i18n('roboAdvice.riskScore.noPurposeAndRiskData')}
        </div>
      )}
      {!isEmpty(horizonRiskScoreItems) && (
        <Fragment>
          <div>
            <Table
              data-testid={'purpose-and-risk-details-table'}
              header={[
                { title: i18n('roboAdvice.riskScore.horizonRisk') },
                ...columns.map(h => ({
                  title: h,
                  className: classes.numberHeaderCell
                }))
              ]}
              items={horizonRiskScoreItems.map((i, index) => ({
                id: index,
                cells: ({ bodyRowCellClassName }) => (
                  <Fragment>
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.titleCell
                      )}
                    >
                      {i.title}
                    </td>
                    {i.values.map((v, vIndex) => (
                      <NumberCell
                        key={vIndex}
                        value={v}
                        cultureCode={cultureCode}
                        className={bodyRowCellClassName}
                        backgroundStylesContainer
                      />
                    ))}
                  </Fragment>
                )
              }))}
            />
          </div>

          {goals.length && <Legend />}

          <div className={classes.description}>
            {i18n('roboAdvice.riskScore.contributionToRiskScoreDescription')}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default PurposeAndRiskDetailsTooltip;
