import classNames from 'classnames';
import { isNil } from 'ramda';
import { useRef, RefObject } from 'react';

import { useField } from './useField';
import ErrorMessage from 'features/shared/components/errorMessage';
import TextareaAutosize from 'features/shared/components/textArea/textareaAutosize';
import { Typography } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { getErrorText } from 'features/sharedModules/finalForm/components/shared';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  counter: {
    width: '32rem',
    textAlign: 'right',
    lineHeight: Typography.body1.lineHeight,
    color: theme.secondaryColor,
    marginTop: '1rem'
  },
  textArea: {
    width: '100%'
  },
  required: {
    color: theme.errorNegativeColor
  }
}));

export type Props = {
  className?: string;
  counterClassName?: string;
  disabled?: boolean;
  fieldConfig?: any;
  forwardedRef?: RefObject<any>;
  id?: string;
  label?: string;
  labelClassName?: string;
  maxLength?: number;
  minRows?: number;
  name: string;
  placeholder?: string;
  required?: boolean;
  sectionClassName?: string;
  showCounter?: boolean;
  hasError?: boolean;
};

const FinalFormTextarea = ({
  className,
  counterClassName: counterClassNameProp,
  disabled,
  fieldConfig,
  forwardedRef,
  id,
  label,
  labelClassName,
  maxLength,
  minRows = 2,
  name,
  placeholder,
  sectionClassName,
  showCounter,
  required,
  hasError,
  ...restProps
}: Props) => {
  const i18n = useI18n();
  const {
    input: { value, onChange, onFocus, onBlur },
    meta: { touched, error }
  } = useField(name, fieldConfig);
  const classes = useStyles();
  const textareaEl = useRef<HTMLTextAreaElement>(null);

  let counterClassName = classNames(classes.counter, counterClassNameProp);
  let noteClassName = classNames(classes.textArea, className);

  const isErrorShown = isNil(hasError) ? touched && !isNil(error) : hasError;
  const errorText = getErrorText(i18n, error);

  return (
    <div className={sectionClassName}>
      {label && <div className={labelClassName}>{label}</div>}
      <TextareaAutosize
        className={label ? noteClassName : className}
        data-testid={restProps['data-testid']}
        disabled={disabled}
        forwardedRef={forwardedRef || textareaEl}
        id={id}
        maxLength={maxLength}
        isInvalid={isErrorShown}
        minRows={minRows}
        placeholder={disabled ? undefined : placeholder}
        value={value || ''}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        required={required}
      />
      {showCounter && (
        <div className={counterClassName}>
          {textareaEl?.current?.value?.length
            ? `${textareaEl.current.value.length}/`
            : `${i18n('shared.maximum')} `}
          {maxLength} {i18n('shared.characters')}
        </div>
      )}
      {isErrorShown && isNil(hasError) && (
        <ErrorMessage>{`* ${errorText}`}</ErrorMessage>
      )}
    </div>
  );
};

export default FinalFormTextarea;
