import * as R from 'ramda';

export class ReferenceNodeProxy {
  constructor(ref) {
    this.ref = ref;
  }

  get clientWidth() {
    const { current } = this.ref;
    return !R.isNil(current) ? current.clientWidth : 0;
  }

  get clientHeight() {
    const { current } = this.ref;
    return !R.isNil(current) ? current.clientHeight : 0;
  }

  getBoundingClientRect() {
    const { current } = this.ref;
    return !R.isNil(current)
      ? current.getBoundingClientRect()
      : {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: 0,
          height: 0
        };
  }
}
