import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import { isNil } from 'ramda';

import { useI18n } from 'modules/settings/components/settings';
import { useTheme } from 'modules/styles/components/styles';
import { TextFontSizes, FontFamilies } from 'modules/shared/constants/fonts';
import { formatNumber } from 'modules/shared/utils/number';
import { hexOpacity } from 'modules/shared/constants/colors';
import Legend from 'modules/shared/components/legend';

HighchartsMore(Highcharts);

const currency = 'NOK';

const CashflowForecastChart = ({
  chartData,
  cultureCode,
  isInteractive,
  width
}) => {
  const i18n = useI18n();
  const theme = useTheme();

  const options = {
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    title: { text: null },
    legend: {
      enabled: false
    },
    chart: {
      backgroundColor: 'transparent',
      marginTop: 35,
      marginRight: 0,
      animation: false
    },
    xAxis: {
      labels: {
        format: '{value: %Y}',
        style: {
          color: theme.secondaryColor,
          fontSize: TextFontSizes.xxSmall,
          lineHeight: TextFontSizes.xxSmallLineHeight,
          fontFamily: FontFamilies.roboto
        },
        y: 35
      },
      lineWidth: 0,
      maxPadding: 0,
      minPadding: 0,
      tickWidth: 0,
      type: 'datetime'
    },
    yAxis: {
      gridLineColor: `${theme.secondaryColor}${hexOpacity[50]}`,
      labels: {
        formatter: ({ value }) => formatNumber(cultureCode, value, 0, 0),
        style: {
          color: theme.secondaryColor,
          fontSize: TextFontSizes.xxSmall,
          lineHeight: '12px',
          fontFamily: FontFamilies.roboto
        },
        x: -32
      },
      tickAmount: 6,
      title: { enabled: false }
    },
    tooltip: {
      enabled: isInteractive,
      xDateFormat: '%Y',
      pointFormatter: function () {
        return `<b>${formatNumber(cultureCode, this.y, 0, 0)} ${currency}</b>`;
      }
    },
    plotOptions: {
      series: {
        enableMouseTracking: isInteractive,
        animation: false,
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },
    series: [
      {
        name: 'Saving plan',
        data: chartData.savingPlan,
        color: theme.chartPortfolioColors[0],
        zIndex: 1,
        lineWidth: 4,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      {
        name: 'Saving plan with no return',
        data: chartData.savingPlanWithNoReturn,
        color: theme.chartPortfolioColors[1],
        zIndex: 1,
        lineWidth: 4,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      {
        name: 'Range',
        data: chartData.ranges,
        type: 'arearange',
        fillColor: `${theme.chartPortfolioColors[0]}${hexOpacity[60]}`,
        lineWidth: 0,
        zIndex: 0,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false
            }
          }
        }
      }
    ]
  };

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'chart'}
        options={options}
        containerProps={{
          style: { height: 296, width: isNil(width) ? 'auto' : `${width}px` }
        }}
      />

      <Legend
        items={[
          { title: i18n('cashflow.savingPlan') },
          { title: i18n('cashflow.savingPlanWithNoReturn') }
        ]}
      />
    </>
  );
};

export default CashflowForecastChart;
