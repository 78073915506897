import classNames from 'classnames';
import React, { LegacyRef } from 'react';
import { Scrollbars as ReactCustomScrollbars } from 'react-custom-scrollbars-2';

import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  scrollbar: {
    cursor: 'pointer',
    borderRadius: 'inherit',
    backgroundColor: theme.secondaryColor,

    '&:hover, &:active': {
      backgroundColor: theme.accentColor
    }
  }
}));

const renderThumb =
  className =>
  ({ style, ...props }) => {
    return <div className={className} style={{ ...style }} {...props} />;
  };

const renderEmpty = () => <div style={{ display: 'none' }} />;

type Props = {
  forwardedRef?: LegacyRef<ReactCustomScrollbars> | undefined;
  doRenderThumbVertical?: boolean;
  doRenderThumbHorizontal?: boolean;
  autoHeightMax?: number | string;
  scrollbarHorizontalClassName?: string;
  children?: React.ReactNode;
  [x: string]: unknown;
};

const Scrollbars = ({
  children,
  forwardedRef = undefined,
  doRenderThumbVertical = true,
  doRenderThumbHorizontal = true,
  autoHeightMax = 400,
  scrollbarHorizontalClassName,
  ...restProps
}: Props) => {
  const classes = useStyles();

  return (
    <ReactCustomScrollbars
      renderThumbVertical={
        doRenderThumbVertical ? renderThumb(classes.scrollbar) : renderEmpty
      }
      renderThumbHorizontal={
        doRenderThumbHorizontal
          ? renderThumb(
              classNames(classes.scrollbar, scrollbarHorizontalClassName)
            )
          : renderEmpty
      }
      autoHeight
      autoHeightMax={autoHeightMax}
      ref={forwardedRef}
      {...restProps}
    >
      {children}
    </ReactCustomScrollbars>
  );
};

export default Scrollbars;
