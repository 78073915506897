import React from 'react';
import {
  createUseStyles as jssCreateUseStyles,
  createTheming,
} from 'react-jss';

const ThemeContext = React.createContext({});

const theming = createTheming(ThemeContext);

export const { ThemeProvider, useTheme } = theming;

export const createUseStyles = (styles, options = {}) =>
  jssCreateUseStyles(styles, {
    theming,
    ...options,
  });
