import classNames from 'classnames';

import { Tooltip } from '../tooltip';
import { ReactComponent as QuestionMarkIcon } from 'assets/questionMark.svg';
import { useIsSessionReadOnly } from 'features/roboAdvice/adviceSession/main/services/selectors';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import InputErrorMessage from 'features/sharedModules/errors/components/inputErrorMessage';
import { useErrorsStore } from 'features/sharedModules/errors/services/errorsStore';
import { ErrorMessage } from 'features/sharedModules/errors/types';
import FinalFormTextarea, {
  Props as FinalFormTextareaProps
} from 'features/sharedModules/finalForm/components/textarea';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  textareaContainer: {
    textAlign: 'left'
  },
  label: {
    fontWeight: FontWeights.medium,
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,

    display: 'flex',
    alignItems: 'center',
    gap: Spacing.spacing00,
    marginTop: Spacing.spacing02
  },
  textarea: {
    marginTop: Spacing.spacing01,
    width: '100%'
  },
  required: {
    color: theme.errorNegativeColor
  },
  subheader: {
    color: theme.secondaryColor,
    fontSize: Typography.body1.size,
    fontWeight: FontWeights.normal,
    lineHeight: Typography.body1.lineHeight,
    marginTop: Spacing.spacing00
  },
  questionMarkIcon: {
    verticalAlign: 'middle'
  }
}));

type Props = {
  name: string;
  header?: string | React.ReactNode;
  subheader?: string | React.ReactNode;
  customPlaceholder?: string;
  className?: string;
  id?: string;
  required?: boolean;
  errors?: ErrorMessage[];
  elementId?: string;
  infoTooltipText?: string;
} & Omit<FinalFormTextareaProps, 'id' | 'name' | 'placeholder'>;

const AdvisorNotes = ({
  header,
  subheader,
  name,
  id = name,
  required,
  customPlaceholder,
  className,
  errors,
  elementId,
  infoTooltipText,
  ...restProps
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const isSessionReadOnly = useIsSessionReadOnly();
  const isErrorModeEnabled = useErrorsStore(state => state.isErrorModeEnabled);

  return (
    <div
      className={classNames(classes.textareaContainer, className)}
      id={elementId}
    >
      {errors?.map(error => (
        <InputErrorMessage
          message={error.alertMessage}
          key={error.alertMessage}
        />
      ))}
      <label className={classes.label} htmlFor={id}>
        {header ?? i18n('roboAdvice.advisorNotes')}
        {required && <span className={classes.required}>&nbsp;*</span>}
        {infoTooltipText && (
          <Tooltip
            content={infoTooltipText}
            contentStyles={{ fontSize: Typography.body2.size }}
            contentMaxWidth={350}
            trigger={<QuestionMarkIcon className={classes.questionMarkIcon} />}
          />
        )}
      </label>
      {subheader && <div className={classes.subheader}>{subheader}</div>}

      <FinalFormTextarea
        className={classes.textarea}
        disabled={isSessionReadOnly}
        id={id}
        name={name}
        placeholder={customPlaceholder ?? i18n('roboAdvice.advisorNotes')}
        hasError={!!errors?.length && isErrorModeEnabled}
        {...restProps}
      />
    </div>
  );
};

export default AdvisorNotes;
