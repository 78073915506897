import classNames from 'classnames';
import { ChangeEventHandler, RefObject, RefAttributes } from 'react';
import TextareaAutosize, {
  TextareaAutosizeProps
} from 'react-textarea-autosize';

import { useStyles } from './shared.js';

type Props = {
  className?: string;
  disabled?: boolean;
  focus?: boolean;
  forwardedRef?: RefObject<any>;
  hover?: boolean;
  isInvalid?: boolean;
  minRows?: number;
  placeholder?: string;
  value?: string | number | string[];
  onChange?: ChangeEventHandler<any>;
};

const TextArea = ({
  className,
  disabled = false,
  focus,
  forwardedRef,
  isInvalid,
  hover,
  minRows = 3,
  placeholder = undefined,
  value = undefined,
  onChange = undefined,
  ...rest
}: Props & TextareaAutosizeProps & RefAttributes<HTMLTextAreaElement>) => {
  const classes = useStyles();

  return (
    <TextareaAutosize
      {...rest}
      className={classNames(className, classes.textArea, {
        [classes.invalid]: isInvalid,
        [classes.hover]: hover,
        [classes.focus]: focus
      })}
      disabled={disabled}
      minRows={minRows}
      onChange={onChange}
      placeholder={placeholder}
      ref={forwardedRef}
      value={value}
    />
  );
};

export default TextArea;
