import classNames from 'classnames';

import { PageBuilderSection } from '../types';
import ExternalLink from 'features/shared/components/link/externalLink';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { processImgURL } from 'features/shared/utils/processImgURL';
import { getTranslation } from 'features/shared/utils/translations';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  h1: {
    fontWeight: FontWeights.bold,
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    margin: '8px 0'
  },
  h2: {
    fontWeight: FontWeights.bold,
    fontSize: Typography.heading2.size,
    lineHeight: Typography.heading2.lineHeight,
    margin: '8px 0'
  },
  h3: {
    fontWeight: FontWeights.bold,
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,
    margin: '8px 0'
  },
  paragraph: {
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,
    margin: '8px 0'
  },
  list: {
    margin: '8px 0'
  },
  numbered_list: {
    margin: '8px 0'
  },
  rootImage: {
    fontSize: 'inherit',

    '& img': {
      width: '100%',
      maxHeight: '50vh'
    }
  },
  imageWithBody: {
    display: 'flex',
    alignItems: 'center',
    gap: 25,

    '& img': {
      width: 'auto',
      height: '100%'
    }
  }
}));

type Props = {
  sections: PageBuilderSection[];
};

const PageBuilder = ({ sections }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {sections.map(({ type, content, align }, index) => {
        if (typeof content !== 'string') {
          if (type === 'header1') {
            const text = getTranslation(content);
            return (
              <h1 className={classes.h1} key={index}>
                {text}
              </h1>
            );
          }

          if (type === 'header2') {
            const text = getTranslation(content);
            return (
              <h2 className={classes.h2} key={index}>
                {text}
              </h2>
            );
          }

          if (type === 'header3') {
            const text = getTranslation(content);
            return (
              <h3 className={classes.h3} key={index}>
                {text}
              </h3>
            );
          }

          if (type === 'paragraph') {
            const text = getTranslation(content);
            return (
              <p className={classes.paragraph} key={index}>
                {text}
              </p>
            );
          }

          if (type === 'list' && Array.isArray(content)) {
            return (
              <ul className={classes.list} key={index}>
                {content.map((c, listIndex) => (
                  <li key={listIndex}>{getTranslation(c)}</li>
                ))}
              </ul>
            );
          }

          if (type === 'numbered_list' && Array.isArray(content)) {
            return (
              <ol className={classes.numbered_list} key={index}>
                {content.map((c, listIndex) => (
                  <li key={listIndex}>{getTranslation(c)}</li>
                ))}
              </ol>
            );
          }

          if (type === 'image' && !Array.isArray(content) && content) {
            const [link_title, title, body, href, src] = [
              content.link_title,
              content.title,
              content.body,
              content.href,
              content.src
            ].map(c => getTranslation(c));

            return (
              <div
                className={classNames(classes.rootImage, {
                  [classes.imageWithBody]:
                    !!title || !!body || (!!link_title && !!href)
                })}
                key={index}
              >
                <div style={{ textAlign: (align as any) || 'left' }}>
                  <img
                    src={processImgURL(src)}
                    alt={title}
                    style={
                      content?.imgStyle?.height || content?.imgStyle?.width
                        ? content?.imgStyle
                        : null
                    }
                  />
                </div>
                <div>
                  {title && <h3 className={classes.h3}>{title}</h3>}
                  {body && <p className={classes.paragraph}>{body}</p>}
                  {link_title && href && (
                    <ExternalLink href={href}>{link_title}</ExternalLink>
                  )}
                </div>
              </div>
            );
          }
        }

        if (type === 'spacer' && typeof content === 'string') {
          return <div style={{ height: content }} key={index}></div>;
        }

        return null;
      })}
    </div>
  );
};

export default PageBuilder;
