import * as R from 'ramda';
import { call, put } from 'redux-saga/effects';

import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import {
  startedCreator,
  succeedCreator,
  failedCreator
} from 'features/shared/utils/actions.js';

export function* runRestOperation({
  actionType,
  payload,
  validateData,
  doDispatchFailedActionOnValidationError,
  onValidationError,
  sendRequest,
  successNotificationMessage,
  onSuccess,
  errorNotificationMessage,
  onError
}) {
  if (!R.isNil(validateData)) {
    const isDataValid = yield call(validateData, payload);

    if (!isDataValid) {
      if (doDispatchFailedActionOnValidationError) {
        yield put(failedCreator(actionType));
      }

      if (!R.isNil(onValidationError)) {
        yield call(onValidationError, payload);
      }

      return;
    }
  }

  yield put(startedCreator(actionType));

  try {
    const response = yield call(sendRequest, payload);

    yield put(succeedCreator(actionType, response));

    if (!R.isNil(successNotificationMessage)) {
      yield put(
        notificationActionCreators.showNotification({
          message: successNotificationMessage,
          type: NotificationTypes.success
        })
      );
    }

    if (!R.isNil(onSuccess)) {
      yield call(onSuccess, payload, response);
    }
  } catch (error) {
    yield put(failedCreator(actionType));

    if (!R.isNil(errorNotificationMessage)) {
      yield put(
        notificationActionCreators.showNotification({
          message: errorNotificationMessage,
          type: NotificationTypes.error
        })
      );
    }

    if (!R.isNil(onError)) {
      yield call(onError, payload, error);
    }
  }
}
