import { takeEvery, put, call, select } from 'redux-saga/effects';

import { types } from '../actions.js';
import sessionSelectors from '../selectors';
import {
  removeAuth0AccessToken,
  removePublicKey,
  removeQAuthApiAccessToken
} from '../storage.js';
import { initializeSession } from './initializeSession.js';
import { login } from './login.js';
import { createAuthEvent } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { UnauthorizedErrorTypes } from 'features/shared/constants/session';
import { types as applicationActionTypes } from 'features/shared/services/application/actions.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import { succeedType } from 'features/shared/utils/actions.js';
import auth from 'features/shared/utils/auth';
import { getI18n } from 'features/sharedModules/customerConfig/services/selectors.js';

// TOOD: get user data on initialize

const AuthEventTypes = {
  loginSucceed: 'login_succeed'
};

function* onLoginSuceed(action) {
  const state = yield select();
  const currentCustomerId = sessionSelectors.getCurrentUserCustomerId(state);
  const accessToken = sessionSelectors.getAuth0AccessToken(state);

  yield put(
    notificationActionCreators.hideNotification(
      SubscriptionExpiredNotificationId
    )
  );

  try {
    yield call(createAuthEvent, currentCustomerId, accessToken, {
      type: AuthEventTypes.loginSucceed
    });
  } catch (error) {}
}

export function* onLogout(action) {
  const { unauthorizedErrorType } = action.payload;
  const i18n = yield select(getI18n);

  removePublicKey();
  removeAuth0AccessToken();
  removeQAuthApiAccessToken();
  auth.logout();

  if (
    unauthorizedErrorType === UnauthorizedErrorTypes.allModuleLicensesExpired
  ) {
    yield put(
      notificationActionCreators.showNotification({
        id: SubscriptionExpiredNotificationId,
        message: i18n('session.yourSubscriptionIsExpired'),
        type: NotificationTypes.error,
        isAutohideEnabled: false
      })
    );
  }
}

export default function* () {
  yield takeEvery(applicationActionTypes.STARTED, initializeSession);

  yield takeEvery(types.LOGIN, login);

  yield takeEvery(succeedType(types.LOGIN), onLoginSuceed);

  yield takeEvery(types.LOGOUT, onLogout);
}

const SubscriptionExpiredNotificationId = 'SubscriptionExpiredNotification';
