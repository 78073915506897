import { createForm } from 'features/sharedModules/finalForm/services/finalForm';
import { createUseFinalFormAdapter } from 'features/sharedModules/zustand/components/finalFormAdapter';

export const generateReportForm = createForm({
  onSubmit: () => {}
});

export const useGenerateReportForm = createUseFinalFormAdapter({
  form: generateReportForm
});
