import { UseFieldConfig } from 'react-final-form';

import { useField } from './useField';
import Select, { Option, SelectProps } from 'features/shared/components/select';

type FinalFormSimpleDropdownProps = SelectProps<Option<string | object>> & {
  name: string;
  fieldConfig?: UseFieldConfig<unknown>;
};

const FinalFormSelect = ({
  name,
  fieldConfig,
  ...restProps
}: FinalFormSimpleDropdownProps) => {
  const {
    input: { value, onChange, onFocus, onBlur },
    meta: { error, touched }
  } = useField(name, fieldConfig);

  return (
    <Select
      error={error}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      touched={touched}
      {...restProps}
    />
  );
};

export default FinalFormSelect;
