import * as R from 'ramda';

import {
  generalColors,
  accentColorShades,
  primaryColorShades,
  pageBackgroundColorShades_1,
  pageBackgroundColorShades_2,
  infographicsColors,
  logoFileTypes,
  logoFileSizeKbLimit
} from '../constants';
import { BorderRadiusType } from 'features/sharedModules/customerConfig/types';
import { createForm } from 'features/sharedModules/finalForm/services/finalForm';

export type VisualsFormValues = {
  logo: { url?: string; file?: File } | undefined;
  borderRadiusType: BorderRadiusType | undefined;
  chartPortfolioColors: string[] | undefined;
  [key: string]: any;
};

export const validate = (values: VisualsFormValues) => {
  let errors: { [key: string]: any } = {};

  if (!R.isNil(values.logo) && !R.isNil(values.logo.file)) {
    if (!logoFileTypes.includes(values.logo.file.type)) {
      errors.logo =
        'admin.appAdmin.advisorSolution.visuals.logoFileTypeValidationErrorMessage';
    } else if (values.logo.file.size >= logoFileSizeKbLimit * 1024) {
      errors.logo =
        'admin.appAdmin.advisorSolution.visuals.logoFileSizeValidationErrorMessage';
    }
  }

  const colorPaths: (string | number)[][] = [
    ...generalColors.map(({ name }) => [name]),
    ...accentColorShades.map(({ name }) => [name]),
    ...primaryColorShades.map(({ name }) => [name]),
    ...pageBackgroundColorShades_1.map(({ name }) => [name]),
    ...pageBackgroundColorShades_2.map(({ name }) => [name]),
    ...(values.chartPortfolioColors ?? []).map((c, index) => [
      'chartPortfolioColors',
      index
    ]),
    ...infographicsColors.map(({ name }) => [name])
  ];

  errors = R.reduce(
    (acc, path) => {
      const value: string | undefined = R.path(path, values);

      if (R.isNil(value) || R.isEmpty(value)) {
        return R.set(
          R.lensPath(path),
          'admin.appAdmin.advisorSolution.visuals.emptyColorValidationErrorMessage',
          acc
        );
      }

      if (!/^#[0-9A-F]{6}$/i.test(value)) {
        return R.set(
          R.lensPath(path),
          'admin.appAdmin.advisorSolution.visuals.invalidColorFormatValidationErrorMessage',
          acc
        );
      }

      return acc;
    },
    errors,
    colorPaths
  );

  if (R.isNil(values.borderRadiusType)) {
    errors.borderRadiusType =
      'admin.appAdmin.advisorSolution.visuals.emptyBorderRadiusTypeValidationErrorMessage';
  }

  return errors;
};

export const visualsForm = createForm<VisualsFormValues>({
  onSubmit: () => {},
  validate
});
