import * as React from 'react';
import { useParams } from 'react-router-dom';

import { useErrorsStore } from '../../../../sharedModules/errors/services/errorsStore';
import { usePageStore as useAdvisoryPageStore } from '../../advisory/services/pageStore';
import { useCostChartStore } from '../../costChart';
import { useExpectedPathChartStore } from '../../expectedPathChart';
import { useExpectedValueChartStore } from '../../expectedValueChart';
import { form as roboAdviceForm } from '../../form/services/form';
import { useHistoricalReturnChartStore } from '../../historicalReturnChart';
import { useKnowledgeAndExperienceStore } from '../../knowledgeAndExperience/services/knowledgeAndExperienceStore';
import { usePortfolioChartStore } from '../../portfolioChart';
import { usePageStore as useProposalPageStore } from '../../proposal/services/pageStore';
import { useSessionStore } from '../../session/services/sessionStore';
import { useIsFirstPartCompleted } from '../services/selectors';
import { useReadAdviceSession } from './useReadAdviceSession';
import { AdviceSessionParams } from 'features/shared/constants/session';

export function useInitPage() {
  const { clientType, clientId, adviceSessionId } =
    useParams<AdviceSessionParams>();

  const sessionStore = useSessionStore();
  const readAdviceSession = useReadAdviceSession();

  React.useEffect(() => {
    readAdviceSession();

    return () => {
      const sessionStoreState = useSessionStore.getState();
      const advisoryPageStoreState = useAdvisoryPageStore.getState();
      const proposalPageStoreState = useProposalPageStore.getState();
      const portfolioChartStoreState = usePortfolioChartStore.getState();
      const expectedPathChartStoreState = useExpectedPathChartStore.getState();
      const expectedValueChartStoreState =
        useExpectedValueChartStore.getState();
      const costChartStoreState = useCostChartStore.getState();
      const historicalReturnChartStoreState =
        useHistoricalReturnChartStore.getState();
      const knowledgeAndExperienceStoreState =
        useKnowledgeAndExperienceStore.getState();
      const errorsStore = useErrorsStore.getState();

      roboAdviceForm.restartToInitialValues();
      sessionStoreState.reset();
      advisoryPageStoreState.reset();
      proposalPageStoreState.reset();
      portfolioChartStoreState.resetChartData();
      expectedPathChartStoreState.resetChartData();
      expectedValueChartStoreState.resetChartData();
      costChartStoreState.resetCostData();
      historicalReturnChartStoreState.resetChartData();
      knowledgeAndExperienceStoreState.reset();
      errorsStore.reset();
    };
  }, [clientType, clientId, adviceSessionId]);

  React.useEffect(() => {
    if (
      !sessionStore.isSessionInitialized ||
      sessionStore.isBasicDataInitialized
    ) {
      return;
    }

    sessionStore.setIsBasicDataInitialized(true);
  }, [sessionStore.isSessionInitialized, sessionStore.isBasicDataInitialized]);

  // Reset error mode when first part is completed
  const isFirstPartCompleted = useIsFirstPartCompleted();
  const [oldIsFirstPartCompleted, setOldIsFirstPartCompleted] =
    React.useState(isFirstPartCompleted);
  const errorsStore = useErrorsStore();
  React.useEffect(() => {
    if (
      isFirstPartCompleted &&
      !oldIsFirstPartCompleted &&
      errorsStore.isErrorModeEnabled
    ) {
      errorsStore.disableErrorMode();
      errorsStore.hideNextButtonMessage();
    }

    setOldIsFirstPartCompleted(isFirstPartCompleted);
  }, [isFirstPartCompleted]);
}
