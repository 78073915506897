export const appAdminConfigLocationsValues = {
  advisorSolution: 'advisor-solution'
} as const;

type Keys = keyof typeof appAdminConfigLocationsValues;
type Values = typeof appAdminConfigLocationsValues[Keys];
type ConfigLocation = {
  value: Values;
  label: string;
};

export const appAdminConfigLocations: ConfigLocation[] = [
  {
    value: appAdminConfigLocationsValues.advisorSolution,
    label: 'admin.appAdmin.navItems.advisorSolution'
  }
];
