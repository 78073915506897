import * as R from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { adviceSessionsTable, OrderKeys } from '../../adviceSessionsTable';
import { Page, ActionButtons } from '../../shared/components/page';
import { useAdviceSessionsStore } from '../services/adviceSessionsStore';
import { useAdviceSessions } from './useAdviceSessions';
import { useDeleteAdviceSessions } from './useDeleteAdviceSessions';
import { useInitPage } from './useInitPage';
import { useRestoreAdviceSessions } from './useRestoreAdviceSessions';
import sessionSelectors from 'features/shared/services/session/selectors';
import { formatDate } from 'features/shared/utils/dateTime.js';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import TableComponent from 'features/sharedModules/table/components/table';
import TableBody from 'features/sharedModules/table/components/tableBody';
import TableBodyRow from 'features/sharedModules/table/components/tableBodyRow';

const AdviceSessions = () => {
  const i18n = useI18n();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const adviceSessionsStore = useAdviceSessionsStore();
  const { items, allItemIds, totalItemsNumber } = useAdviceSessions();
  const { selectedRowIds } = adviceSessionsTable.useTableStore();
  const deleteAdviceSessions = useDeleteAdviceSessions();
  const restoreAdviceSessions = useRestoreAdviceSessions();

  useInitPage();

  return (
    <Page>
      <ActionButtons
        selectedRowsNumber={selectedRowIds.length}
        isRestorePending={adviceSessionsStore.isRestoreAdviceSessionsPending}
        isDeletePending={adviceSessionsStore.isDeleteAdviceSessionsPending}
        restoreConfirmationMessage={
          <>
            <div>
              {i18n(
                'admin.trashCan.adviceSessions.restoreAdviceSessionsConfirmationMessage_1'
              )}
            </div>
            <div>
              {i18n(
                'admin.trashCan.adviceSessions.restoreAdviceSessionsConfirmationMessage_2'
              )}
            </div>
          </>
        }
        deleteConfirmationMessage={i18n(
          'admin.trashCan.adviceSessions.deleteAdviceSessionsConfirmationMessage'
        )}
        restoreButtonOnClick={restoreAdviceSessions}
        deleteButtonOnClick={deleteAdviceSessions}
      />
      <TableComponent
        module={adviceSessionsTable}
        isPaginationShown={true}
        totalItemsNumber={totalItemsNumber}
        isSelectionShown={true}
        allRowIds={allItemIds}
        isLoading={adviceSessionsStore.isReadAdviceSessionsPending}
        header={[
          {
            title: i18n('admin.trashCan.adviceSessions.table.adviceSession'),
            isOrderable: true,
            orderKey: OrderKeys.name
          },
          {
            title: i18n('admin.trashCan.adviceSessions.table.client'),
            isOrderable: true,
            orderKey: OrderKeys.clientName
          },
          {
            title: i18n('admin.trashCan.adviceSessions.table.advisor'),
            isOrderable: true,
            orderKey: OrderKeys.advisorName
          },
          {
            title: i18n('admin.trashCan.adviceSessions.table.lastActivityDate'),
            isOrderable: true,
            orderKey: OrderKeys.lastActivityDate
          },
          {
            title: i18n('admin.trashCan.adviceSessions.table.softDeleteDate'),
            isOrderable: true,
            orderKey: OrderKeys.softDeleteDate
          }
        ]}
      >
        <TableBody
          noDataTitle={i18n(
            'admin.trashCan.adviceSessions.table.noAdviceSessions'
          )}
          cellsNumber={5}
        >
          {R.map(
            i => (
              <TableBodyRow key={i.id} id={i.id}>
                {({ bodyRowCellClassName }) => (
                  <>
                    <td className={bodyRowCellClassName}>{i.name}</td>
                    <td className={bodyRowCellClassName}>{i.clientName}</td>
                    <td className={bodyRowCellClassName}>{i.advisorName}</td>
                    <td className={bodyRowCellClassName}>
                      {formatDate(cultureCode, i.lastActivityDate)}
                    </td>
                    <td className={bodyRowCellClassName}>
                      {formatDate(cultureCode, i.softDeleteDate)}
                    </td>
                  </>
                )}
              </TableBodyRow>
            ),
            items
          )}
        </TableBody>
      </TableComponent>
    </Page>
  );
};

export default AdviceSessions;
