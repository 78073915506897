import { z } from 'zod';

export const supportedLanguagesSchema = z.object({
  default: z.string(),
  options: z.array(z.string())
});

export type SupportedLanguages = z.infer<typeof supportedLanguagesSchema>;

export const defaultSupportedLanguages: SupportedLanguages = {
  default: 'en',
  options: ['en']
};
