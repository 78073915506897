import { pipe } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

import { getTranslation } from '../../../../shared/utils/translations';
import {
  form as roboAdviceForm,
  useForm as useRoboAdviceForm
} from '../../form/services/form';
import { FieldSections } from '../constants';
import { Layouts } from 'features/roboAdvice/shared/constants';
import ConfigurableLabel from 'features/shared/components/configurableLabel/';
import { ClientTypes } from 'features/shared/constants/session';
import { Spacing } from 'features/shared/constants/spacing';
import sessionSelectors from 'features/shared/services/session/selectors';
import { getNumberInputFormat } from 'features/shared/utils/number';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { RenderFields as DfRenderFields } from 'features/sharedModules/declarativeForm/components/index.js';
import { FieldComponentTypes } from 'features/sharedModules/declarativeForm/constants';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const liquidityLabelWidth = 300;
export const textFieldWith = 256;
export const gapWidth = 4;

export const useStyles = createUseStyles(theme => ({
  verticalField: {
    '& + &': {
      marginTop: '0'
    }
  },
  horizontalField: {
    padding: `${Spacing.spacing01}px 0`,
    marginTop: 0,

    '&:first-child': {
      paddingTop: 0
    },

    '&:last-child': {
      paddingBottom: 0
    },

    '& + &': {
      marginTop: '0'
    }
  },
  numberInput: {
    width: 170,
    textAlign: 'right'
  },
  fieldLabel: {
    width: 'auto',
    maxWidth: 235,
    color: theme.primaryColor
  },
  liquidityFieldLabel: {
    width: liquidityLabelWidth,
    color: theme.primaryColor,
    marginRight: 0
  },
  liquiditySection: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: gapWidth
  },
  textareaLabel: {
    marginBottom: Spacing.spacing00
  },
  textFieldWith: {
    width: textFieldWith
  },
  datepicker: {
    width: 170
  }
}));

export const RenderFields = ({ fields, adjustElement, ...restProps }) => {
  const i18n = useI18n();
  const classes = useStyles();

  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const { thousandSeparator, decimalSeparator } =
    getNumberInputFormat(cultureCode);

  const afterExpectingFinancialButtonChange = value => {
    if (!value) {
      if (
        roboAdviceForm.getState().values.clientInformation.clientType ===
        ClientTypes.company
      ) {
        roboAdviceForm.change(
          'companyFinancialSituation.expectingFinancialChangesNote',
          null
        );
      }
      if (
        roboAdviceForm.getState().values.clientInformation.clientType ===
        ClientTypes.person
      ) {
        roboAdviceForm.change(
          'personFinancialSituation.expectingFinancialChangesNote',
          null
        );
      }
    }
  };

  return (
    <DfRenderFields
      fields={fields}
      adjustElement={(field, element) =>
        pipe(
          () => {
            if (
              field[1].render.componentType ===
              FieldComponentTypes.detalizedNumberInput
            ) {
              return React.cloneElement(element, {
                useForm: useRoboAdviceForm,
                formApi: roboAdviceForm,
                form: 'roboAdviceForm',
                title: (
                  <ConfigurableLabel
                    label={
                      field[1].render.label
                        ? getTranslation(field[1].render.label)
                        : i18n(field[1].render.labelKey)
                    }
                    required={field[1].render.required}
                  />
                ),
                fieldClassName: classes.verticalField,
                allowNegative: field[1].render.isNegativeNumberEnabled
              });
            }

            if (
              field[1].render.componentType === FieldComponentTypes.numberInput
            ) {
              return React.cloneElement(element, {
                className: classes.numberInput,
                labelClassName: classes.fieldLabel,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                allowNegative: field[1].render.isNegativeNumberEnabled,
                decimalScale: 0,
                label: (
                  <ConfigurableLabel
                    label={
                      field[1].render.label
                        ? getTranslation(field[1].render.label)
                        : i18n(field[1].render.labelKey)
                    }
                    required={field[1].render.required}
                  />
                )
              });
            }

            return React.cloneElement(element, {
              label: (
                <ConfigurableLabel
                  label={
                    field[1].render.label
                      ? getTranslation(field[1].render.label)
                      : i18n(field[1].render.labelKey)
                  }
                  required={field[1].render.required}
                />
              )
            });
          },
          element => {
            return React.cloneElement(element, {
              fieldClassName: classes.horizontalField
            });
          },
          element => {
            if (
              field[1].render.fieldSection === FieldSections.companyLiquidity ||
              field[1].render.fieldSection === FieldSections.personLiquidity
            ) {
              if (
                field[0] ===
                  'companyFinancialSituation.expectingFinancialChanges' ||
                field[0] ===
                  'personFinancialSituation.expectingFinancialChanges'
              ) {
                return React.cloneElement(element, {
                  labelClassName: classes.liquidityFieldLabel,
                  sectionClassName: classes.liquiditySection,
                  layout: Layouts.horizontal,
                  afterChange: afterExpectingFinancialButtonChange
                });
              }

              if (
                field[1].render.componentType ===
                  FieldComponentTypes.checkboxes ||
                field[1].render.componentType ===
                  FieldComponentTypes.radioButtons
              ) {
                return React.cloneElement(element, {
                  externalClasses: {
                    section: classes.liquiditySection,
                    subSection: classes.liquiditySection
                  },
                  labelClassName: classes.liquidityFieldLabel,
                  sectionClassName: classes.liquiditySection,
                  layout: Layouts.horizontal,
                  afterChange: value => {
                    field[1].render.items?.forEach(
                      ({ activeValue, textField }) => {
                        if (!!textField && !value.includes(activeValue)) {
                          roboAdviceForm.change(textField?.name, null);
                        }
                      }
                    );
                  }
                });
              }

              if (
                field[1].render.componentType === FieldComponentTypes.dropdown
              ) {
                return React.cloneElement(element, {
                  className: classes.textFieldWith,
                  labelClassName: classes.liquidityFieldLabel,
                  externalClasses: { section: classes.liquiditySection },
                  afterChange: value => {
                    field[1].render.items?.forEach(
                      ({ activeValue, textField }) => {
                        if (!!textField && !value.includes(activeValue)) {
                          roboAdviceForm.change(textField?.name, null);
                        }
                      }
                    );
                  }
                });
              }

              if (
                field[1].render.componentType === FieldComponentTypes.datepicker
              ) {
                return React.cloneElement(element, {
                  className: classes.datepicker,
                  labelClassName: classes.liquidityFieldLabel,
                  sectionClassName: classes.liquiditySection,
                  externalClasses: { section: classes.liquiditySection }
                });
              }

              if (
                field[1].render.componentType === FieldComponentTypes.textInput
              ) {
                return React.cloneElement(element, {
                  className: classes.textFieldWith,
                  labelClassName: classes.liquidityFieldLabel,
                  sectionClassName: classes.liquiditySection,
                  externalClasses: { section: classes.liquiditySection }
                });
              }

              if (
                field[0] ===
                  'personFinancialSituation.expectingFinancialChangesNote' ||
                field[0] ===
                  'companyFinancialSituation.expectingFinancialChangesNote'
              ) {
                return React.cloneElement(element, {
                  labelClassName: classes.textareaLabel,
                  externalClasses: { section: classes.liquiditySection }
                });
              }

              if (
                field[1].render.componentType === FieldComponentTypes.textarea
              ) {
                return React.cloneElement(element, {
                  className: classes.textFieldWith,
                  labelClassName: classes.liquidityFieldLabel,
                  sectionClassName: classes.liquiditySection
                });
              }

              return React.cloneElement(element, {
                externalClasses: {
                  section: classes.liquiditySection,
                  subSection: classes.liquiditySection
                },
                labelClassName: classes.liquidityFieldLabel,
                sectionClassName: classes.liquiditySection,
                layout: Layouts.horizontal
              });
            }

            return element;
          },
          element => adjustElement(field, element)
        )()
      }
      {...restProps}
    />
  );
};

RenderFields.defaultProps = {
  adjustElement: (field, element) => element
};
