import { ReactComponent as HomePopUpIcon } from 'assets/icons_multigoal/icons8-home.svg';
import { ReactComponent as HomeTableIcon } from 'assets/icons_multigoal/home_24px.svg';
import { ReactComponent as MoneyBoxPopUpIcon } from 'assets/icons_multigoal/icons8-money-box.svg';
import { ReactComponent as MoneyBoxTableIcon } from 'assets/icons_multigoal/icons8-money-box_24px.svg';
import { ReactComponent as SedanPopUpIcon } from 'assets/icons_multigoal/icons8-sedan.svg';
import { ReactComponent as SedanTableIcon } from 'assets/icons_multigoal/icon8-car_24px.svg';
import { ReactComponent as SailBoatPopUpIcon } from 'assets/icons_multigoal/icons8-sail-boat.svg';
import { ReactComponent as SailBoatTableIcon } from 'assets/icons_multigoal/icons8-sail-boat_24px.svg';
import { ReactComponent as CoinsPopUpIcon } from 'assets/icons_multigoal/icons8-coins.svg';
import { ReactComponent as CoinsTableIcon } from 'assets/icons_multigoal/icons8-coins_24px.svg';
import { ReactComponent as PieChartPopUpIcon } from 'assets/icons_multigoal/icon-piechart.svg';
import { ReactComponent as PieChartTableIcon } from 'assets/icons_multigoal/pie_24px.svg';
import { ReactComponent as EducationPopUpIcon } from 'assets/icons_multigoal/icons8-education.svg';
import { ReactComponent as EducationTableIcon } from 'assets/icons_multigoal/icon8_education_24px.svg';
import { ReactComponent as GoalPopupIcon } from 'assets/icons_multigoal/icons8-goal.svg';
import { ReactComponent as GoalTableIcon } from 'assets/icons_multigoal/icons8-goal_24px.svg';

import HomeIconUrl from 'assets/icons_multigoal/home.svg';
import MoneyBoxIconUrl from 'assets/icons_multigoal/moneyBox.svg';
import SedanIconUrl from 'assets/icons_multigoal/sedan.svg';
import SailBoatIconUrl from 'assets/icons_multigoal/sailBoat.svg';
import CoinsIconUrl from 'assets/icons_multigoal/coins.svg';
import PieChartIconUrl from 'assets/icons_multigoal/pieChart.svg';
import EducationIconUrl from 'assets/icons_multigoal/education.svg';
import GoalIconUrl from 'assets/icons_multigoal/goal.svg';

import { ReactComponent as PlusIcon } from 'assets/costIcons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/costIcons/minus.svg';
import { ReactComponent as EqualsIcon } from 'assets/costIcons/equals.svg';

export const goalIcons = [
  {
    name: 'home',
    popupIcon: <HomePopUpIcon />,
    tableIcon: <HomeTableIcon />,
    urlIcon: HomeIconUrl
  },
  {
    name: 'moneyBox',
    popupIcon: <MoneyBoxPopUpIcon />,
    tableIcon: <MoneyBoxTableIcon />,
    urlIcon: MoneyBoxIconUrl
  },
  {
    name: 'sedan',
    popupIcon: <SedanPopUpIcon />,
    tableIcon: <SedanTableIcon />,
    urlIcon: SedanIconUrl
  },
  {
    name: 'sailBoat',
    popupIcon: <SailBoatPopUpIcon />,
    tableIcon: <SailBoatTableIcon />,
    urlIcon: SailBoatIconUrl
  },
  {
    name: 'coins',
    popupIcon: <CoinsPopUpIcon />,
    tableIcon: <CoinsTableIcon />,
    urlIcon: CoinsIconUrl
  },
  {
    name: 'pieChart',
    popupIcon: <PieChartPopUpIcon />,
    tableIcon: <PieChartTableIcon />,
    urlIcon: PieChartIconUrl
  },
  {
    name: 'education',
    popupIcon: <EducationPopUpIcon />,
    tableIcon: <EducationTableIcon />,
    urlIcon: EducationIconUrl
  },
  {
    name: 'goal',
    popupIcon: <GoalPopupIcon />,
    tableIcon: <GoalTableIcon />,
    urlIcon: GoalIconUrl
  }
];

export const costIcons = {
  plus: <PlusIcon />,
  minus: <MinusIcon />,
  equals: <EqualsIcon />
};
