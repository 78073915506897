import classNames from 'classnames';
import * as R from 'ramda';
import React from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import ReactResizeDetector from 'react-resize-detector';
import { Carousel as RrCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { useCarouselStore } from './carouselStore';
import Icon from 'features/shared/components/icon/index.js';
import { Typography } from 'features/shared/constants/typography';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const indexedMap = R.addIndex(R.map);

const borderRadius = {
  [BorderRadiusTypes.rounded]: '15px',
  [BorderRadiusTypes.sharp]: '10px'
};

const useStyles = createUseStyles(theme => ({
  root: {
    padding: '20px 0 20px 0',
    backgroundColor: theme.boxBackgroundColor_1,
    borderRadius: borderRadius[theme.borderRadiusType],

    '&$pending': {
      opacity: '0.3'
    }
  },
  pending: {},
  tabs: {
    margin: '0 20px 20px 20px',
    display: 'flex',
    justifyContent: 'center'
  },
  tabsContent: {
    display: 'flex'
  },
  tab: {
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,
    color: theme.secondaryColor,
    cursor: 'pointer',

    '&$active': {
      color: theme.accentColor
    },

    '& + &': {
      marginLeft: '20px'
    }
  },
  active: {},
  item: {
    padding: '0 62px'
  },
  navButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: '2',
    top: 'calc(50% - 21px)',
    width: '42px',
    height: '42px',
    borderRadius: '50%',
    background: theme.itemBackgroundColor_2,
    cursor: 'pointer',

    '&$disabled': {
      cursor: 'default',
      background: theme.disabledBackgroundColor
    }
  },
  prevNavButton: {
    left: '10px'
  },
  nextNavButton: {
    right: '10px'
  },
  navButtonIcon: {
    color: theme.disabledTextColor,

    '$navButton$disabled &': {
      color: theme.disabledTextColor
    }
  },
  disabled: {}
}));

export const Carousel = ({ children, getIsPending, carouselId }) => {
  const classes = useStyles();
  const carouselStore = useCarouselStore();

  return (
    <div
      className={classNames(classes.root, {
        [classes.pending]: getIsPending(
          carouselStore[carouselId]?.selectedItemIndex
        )
      })}
    >
      {children}
    </div>
  );
};

export const CarouselTabs = ({ children, carouselId }) => {
  const classes = useStyles();
  const carouselStore = useCarouselStore();

  const tabs = flattenChildren(children);

  return (
    <div className={classes.tabs}>
      <div className={classes.tabsContent}>
        {indexedMap(
          (tab, index) =>
            React.cloneElement(tab, {
              onClick: e => {
                carouselStore.setSelectedItemIndex(carouselId, index);
                tab.props.onClick && tab.props.onClick(e);
              },
              isActive: index === carouselStore[carouselId]?.selectedItemIndex
            }),
          tabs
        )}
      </div>
    </div>
  );
};

export const CarouselTab = ({ children, onClick, isActive }) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.tab, { [classes.active]: isActive })}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export const CarouselBody = ({
  prevNavBattonClassName,
  nextNavBattonClassName,
  children,
  carouselId
}) => {
  const classes = useStyles();
  const carouselStore = useCarouselStore();
  const [height, setHeight] = React.useState(); // height state change forces rerender of carousel and as the result dynamicHeight recalculation
  const onResize = height => {
    setHeight(height);
  };

  return (
    <RrCarousel
      dynamicHeight={true}
      showStatus={false}
      showIndicators={false}
      showThumbs={false}
      autoplay={false}
      swipeable={true}
      selectedItem={carouselStore[carouselId]?.selectedItemIndex}
      onChange={index => carouselStore.setSelectedItemIndex(carouselId, index)}
      renderItem={(item, options) =>
        React.cloneElement(item, { onResize, isSelected: options.isSelected })
      }
      renderArrowPrev={(onClickHandler, hasPrev) => (
        <NavButton
          className={classNames(classes.prevNavButton, prevNavBattonClassName)}
          onClick={onClickHandler}
          disabled={!hasPrev}
          iconType={'chevron_left'}
        />
      )}
      renderArrowNext={(onClickHandler, hasNext) => (
        <NavButton
          className={classNames(classes.nextNavButton, nextNavBattonClassName)}
          onClick={onClickHandler}
          disabled={!hasNext}
          iconType={'chevron_right'}
        />
      )}
    >
      {children}
    </RrCarousel>
  );
};

export const CarouselItem = ({
  children,
  onResize,
  isSelected,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <div className={classes.item} data-testid={restProps['data-testid']}>
      {React.cloneElement(children, { isSelected })}
      <ReactResizeDetector
        handleHeight
        onResize={(width, height) => {
          isSelected && onResize && onResize(height);
        }}
      />
    </div>
  );
};

const NavButton = ({ className, onClick, disabled, iconType }) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(
        classes.navButton,
        { [classes.disabled]: disabled },
        className
      )}
      onClick={disabled ? null : onClick}
    >
      <Icon className={classes.navButtonIcon} type={iconType} />
    </div>
  );
};
