import { UseFieldConfig } from 'react-final-form';

import { getErrorText } from './shared.js';
import { useField } from './useField';
import NumberInput, {
  Props as NumberInputProps
} from 'features/shared/components/numberInput/numberInput';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

type FinalFormProps = {
  name: string;
  fieldConfig?: UseFieldConfig<any, any>;
};

const FinalFormNumberInput = ({
  name,
  fieldConfig,
  ...restProps
}: FinalFormProps &
  Omit<
    NumberInputProps,
    'value' | 'onChange' | 'onFocus' | 'onBlur' | 'errorText' | 'touched'
  >) => {
  let {
    input: { value, onChange, onFocus, onBlur },
    meta: { error, touched }
  } = useField(name, fieldConfig);
  const i18n = useI18n();

  const errorText = getErrorText(i18n, error);

  return (
    <NumberInput
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      errorText={errorText}
      touched={touched}
      {...restProps}
    />
  );
};

export default FinalFormNumberInput;
