import classNames from 'classnames';

const Icon = ({
  forwardedRef = null,
  className = '',
  type = '',
  ...restProps
}) => {
  return (
    <i
      ref={forwardedRef}
      className={classNames('material-icons', className)}
      {...restProps}
    >
      {type}
    </i>
  );
};

export default Icon;
