import axios from 'axios';
import { isNil } from 'ramda';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import { useForm as useRoboAdviceForm } from '../../form/services/form';
import { useQueuedPatchAdviceSession } from '../../main/components/useQueuedPatchAdviceSession';
import { useSessionStore } from '../../session/services/sessionStore';
import { SessionPatchTypes } from '../../shared/constants';
import { completePdfReport } from 'features/roboAdvice/adviceSession/proposal/api';
import { RoboAdviceClientPages } from 'features/roboAdvice/shared/constants';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export const useMarkAsComplete = () => {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = useRef<any>();
  const { clientId } = useParams<any>();
  const dispatch = useDispatch();
  const i18n = useI18n();
  const queuedPatchAdviceSession = useQueuedPatchAdviceSession();
  const history = useHistory();

  const markAsComplete = async (adviceSessionId: string) => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    try {
      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      await completePdfReport(
        accessToken,
        cancelTokenSource.token,
        clientId,
        adviceSessionId
      );

      const sessionStore = useSessionStore.getState();
      await queuedPatchAdviceSession({
        id: v4(),
        type: SessionPatchTypes.completeSession,
        adviceSessionId
      });
      sessionStore.completeSession();
      const roboAdviceForm = useRoboAdviceForm.getState();
      const clientType = roboAdviceForm.values?.clientInformation?.clientType;
      dispatch(
        notificationActionCreators.showNotification({
          message: i18n('roboAdvice.proposal.adviceSessionMarkedAsCompleted'),
          type: NotificationTypes.success
        })
      );
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      history.push(
        routeTemplates.roboAdviceClient.build(
          clientType,
          clientId,
          RoboAdviceClientPages.adviceSessions
        )
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.client.adviceSessionsTable.errorWhileDownloadingReportDocuments'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return markAsComplete;
};
