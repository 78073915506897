import classNames from 'classnames';
import { clone } from 'ramda';
import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useExistingPortfolio } from '../../proposal/services/selectors';
import { useReadDataListener } from '../../shared/components/useReadDataListener';
import { getSubAssetClassTranslation } from '../../shared/mapping';
import { usePageStore } from '../services/pageStore';
import { useReadPortfolioChartData } from './useReadPortfolioChartData';
import { useReadPortfolioTableData } from './useReadPortfolioTableData';
import Table from 'features/shared/components/table/index.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { formatNumber } from 'features/shared/utils/number';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  root: {
    marginRight: 10
  },
  headerCell: {
    lineHeight: '19px',
    padding: '15.5px 10px',
    whiteSpace: 'nowrap',

    '&:not(:first-child)': {
      minWidth: 70
    }
  },
  cell: {
    lineHeight: '19px',
    padding: '15.5px 10px',
    textAlign: 'left',
    whiteSpace: 'nowrap'
  },
  numberCell: {
    textAlign: 'right'
  }
}));

type Props = {
  selectedRisks: {
    risk: string;
    className: string;
    name: string;
    color: string;
  }[];
  compareWithExistingPortfolio?: boolean;
};

const PortfolioTable = ({
  selectedRisks,
  compareWithExistingPortfolio
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const { pageStatuses, portfolioTableData, productConfiguration } =
    usePageStore();
  const {
    roboAdvice: { subAssetClassNameMapping = {} }
  } = useCustomerConfig();
  const existingPortfolio = useExistingPortfolio();

  useReadDataListener(
    pageStatuses.readPortfolioChartData,
    true,
    useReadPortfolioChartData(selectedRisks)
  );
  useReadDataListener(
    pageStatuses.readPortfolioTableData,
    productConfiguration !== portfolioTableData?.namespaceId,
    useReadPortfolioTableData()
  );

  const sortedSelectRisks = clone(selectedRisks).sort(
    (a, b) => +a.risk - +b.risk
  );

  const assetClassesToDisplay = useMemo(() => {
    if (!portfolioTableData) {
      return [];
    }

    const assetClassesFromProductPlatform = portfolioTableData.assetClasses
      .filter(
        ({ riskClasses, id }) =>
          sortedSelectRisks.some(
            ({ risk }) =>
              (riskClasses.find(({ riskClass }) => riskClass === +risk)
                ?.weight ?? 0) > 0
          ) ||
          (compareWithExistingPortfolio &&
            !!existingPortfolio &&
            existingPortfolio[1].some(
              ({ id: existingPortfolioAssetId }) =>
                id === existingPortfolioAssetId
            ))
      )
      .map(assetClass => ({
        id: assetClass.id,
        riskClasses: assetClass.riskClasses,
        title:
          getSubAssetClassTranslation(
            assetClass.category,
            subAssetClassNameMapping
          ) || assetClass.title
      }));

    const notAddedAssetClassesFromExistingPortfolio =
      compareWithExistingPortfolio && !!existingPortfolio
        ? existingPortfolio[1]
            .filter(
              ({ id }) =>
                !assetClassesFromProductPlatform.some(
                  ({ id: assetClassId }) => assetClassId === id
                )
            )
            .map(({ id, weight }) => ({
              id,
              title: id,
              weight,
              riskClasses: sortedSelectRisks.map(({ risk }) => ({
                riskClass: +risk,
                weight: 0
              }))
            }))
        : [];

    return [
      ...assetClassesFromProductPlatform,
      ...notAddedAssetClassesFromExistingPortfolio
    ];
  }, [portfolioTableData, sortedSelectRisks]);

  return (
    <div className={classes.root}>
      <Table
        header={[
          {
            title: i18n('shared.assetClass'),
            className: classes.headerCell,
            disabled: false
          },
          ...sortedSelectRisks.map(({ risk }) => ({
            title: `${i18n('shared.portfolio')} ${risk}`,
            className: classNames(classes.headerCell, classes.numberCell),
            disabled: false
          })),
          {
            title: i18n('roboAdvice.advisory.portfolio.existingPortfolio'),
            className: classNames(classes.headerCell, classes.numberCell),
            disabled: !compareWithExistingPortfolio
          }
        ].filter(({ disabled }) => !disabled)}
        items={[
          ...assetClassesToDisplay.map(({ id, title, riskClasses }) => ({
            id: title,
            cells: ({ bodyRowCellClassName }) => {
              const showExistingPortfolio =
                compareWithExistingPortfolio && !!existingPortfolio;

              const foundExistingPortfolioAsset = showExistingPortfolio
                ? existingPortfolio[1].find(
                    ({ id: existingPortfolioAssetId }) =>
                      existingPortfolioAssetId === id
                  )
                : null;

              return (
                <Fragment>
                  <td
                    className={classNames(bodyRowCellClassName, classes.cell)}
                  >
                    {title}
                  </td>
                  {sortedSelectRisks.map(({ risk }) => (
                    <td
                      key={risk}
                      className={classNames(
                        bodyRowCellClassName,
                        classes.cell,
                        classes.numberCell
                      )}
                    >
                      {formatNumber(
                        cultureCode,
                        riskClasses.find(({ riskClass }) => riskClass === +risk)
                          ?.weight,
                        1,
                        1
                      )}
                      %
                    </td>
                  ))}
                  {showExistingPortfolio && (
                    <td
                      key={foundExistingPortfolioAsset?.id}
                      className={classNames(
                        bodyRowCellClassName,
                        classes.cell,
                        classes.numberCell
                      )}
                    >
                      {formatNumber(
                        cultureCode,
                        foundExistingPortfolioAsset?.weight ?? 0,
                        1,
                        1
                      )}
                      %
                    </td>
                  )}
                </Fragment>
              );
            }
          }))
        ]}
      />
    </div>
  );
};

export default PortfolioTable;
