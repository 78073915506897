import axios from 'axios';
import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getSessionGoals } from '../../shared/api';
import { useGoalsStore } from '../../shared/services/goalsStore';
import { mapServerSessionGoalToClient } from '../../shared/services/mapping';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';

export function useReadGoals() {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<any>();
  const { adviceSessionId } = useParams<any>();

  const readGoals = async () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    try {
      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const sessionGoalsResponse = await getSessionGoals(
        accessToken,
        cancelTokenSource.token,
        adviceSessionId
      );

      const sessionGoals = sessionGoalsResponse.data.data.map(goal => {
        return { ...mapServerSessionGoalToClient(goal), isGoalSelected: true };
      });

      useGoalsStore.getState().setGoals(sessionGoals);
    } catch (error) {
      if (!axios.isCancel(error)) {
        // cancel loading

        throwSafeError(error);
      }
    }
  };

  return readGoals;
}
