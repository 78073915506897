import chroma from 'chroma-js';
import classNames from 'classnames';
import * as R from 'ramda';
import { useState, useRef } from 'react';
import { HexColorPicker } from 'react-colorful';

import useOnClickOutside from '../../hooks/useClickOutside.js';
import TextInput from 'features/shared/components/textInput/index';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: '256px',
    alignItems: 'center'
  },
  colorPreview: {
    width: '76px',
    height: '76px',
    borderRadius: '50%',
    border: `2px solid ${theme.defaultInputStrokeColor}`,
    cursor: 'pointer'
  },
  colorInfo: {
    marginLeft: '20px'
  },
  label: {
    marginBottom: '5px'
  },
  hash: {
    marginRight: '16px'
  },
  colorHexInput: {
    width: '110px',
    padding: '9px 16px',
    height: '40px'
  },
  hexColorPicker: {
    position: 'absolute',
    bottom: 5,
    left: 30,
    transform: 'translateY(100%)',
    zIndex: 10
  }
}));

type Props = {
  label?: string;
  value: string;
  onChange: (string) => void;
  onFocus?: (event?: any) => void;
  onBlur?: (event?: any) => void;
  [key: string]: any;
};

const ColorInput = ({
  label,
  value,
  onChange,
  onFocus,
  onBlur,
  rootClassName,
  ...restProps
}: Props) => {
  const classes = useStyles();
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside([ref], () => setIsColorPickerOpen(false));

  const onChangeWithValidation = newColorValue => {
    const regex = /[0-9a-fA-F]/;

    const validatedValueColor: string = newColorValue
      .split('')
      .filter(n => regex.test(n))
      .join('')
      .substring(0, 6)
      .toUpperCase();

    onChange(R.isEmpty(validatedValueColor) ? '' : `#${validatedValueColor}`);
  };

  return (
    <div className={classNames(classes.root, rootClassName)}>
      {isColorPickerOpen && (
        <div className={classes.hexColorPicker} ref={ref}>
          <HexColorPicker color={value} onChange={onChangeWithValidation} />
        </div>
      )}
      <div
        className={classes.colorPreview}
        style={{
          backgroundColor: chroma.valid(value) ? chroma(value).css() : undefined
        }}
        onClick={() => setIsColorPickerOpen(true)}
      />
      <div className={classes.colorInfo}>
        <div className={classes.label}>{label}</div>
        <div>
          <span className={classes.hash}>#</span>
          <TextInput
            className={classes.colorHexInput}
            value={value.substring(1)}
            onChange={e => onChangeWithValidation(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur}
            {...restProps}
          />
        </div>
      </div>
    </div>
  );
};

export default ColorInput;
