import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import classNames from 'classnames';

import { useI18n } from 'modules/settings/components/settings.js';
import { createUseStyles } from 'modules/styles/components/styles.js';
import { Colors } from 'modules/shared/constants/colors.js';
import { TextFontSizes } from 'modules/shared/constants/fonts.js';
import { formatNumber } from 'modules/shared/utils/number.js';
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHader,
  TableHaderRow,
  TableHeaderCell
} from './table.js';
import { mainAssetClassesI18nMapping } from '../../default/constants';

const useStyles = createUseStyles({
  root: {
    display: 'flex'
  },
  horizontalReversedRoot: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between'
  },
  chart: {
    marginRight: '13px'
  },
  horizontalReversedChart: {
    marginRight: '50px'
  },
  headerCell: {
    backgroundColor: 'transparent !important',
    fontSize: TextFontSizes.x05,
    lineHeight: TextFontSizes.x05LineHeight,
    color: Colors.gray900
  },
  bodyCell: {
    backgroundColor: 'transparent !important'
  },
  weightCell: {
    paddingRight: '0'
  },
  titleCell: {
    paddingLeft: '6px',
    paddingTop: '14px'
  },
  isinCell: {
    paddingTop: '14px'
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  legendMarker: {
    width: '26px',
    height: '26px',
    borderRadius: '50%',
    marginRight: '10px',
    flex: '0 0 auto'
  }
});

const LegendMarker = ({ color }) => {
  const classes = useStyles();

  return (
    <div className={classes.legendMarker} style={{ backgroundColor: color }} />
  );
};
const PortfolioChart = ({
  cultureCode,
  isIsinVisible,
  isFundsAndWeightVisible = true,
  isHorizontalReversed = false,
  onLoad,
  chartData
}) => {
  const i18n = useI18n();
  const classes = useStyles();

  const plotOptions = {
    credits: {
      enabled: false
    },
    chart: {
      backgroundColor: null,
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
      width: '130',
      height: '130',
      margin: 0,
      animation: false,
      events: {
        load: onLoad
      }
    },
    plotOptions: {
      series: {
        animation: false,
        enableMouseTracking: false
      },
      pie: {
        dataLabels: {
          enabled: false
        },
        states: {
          enabled: false
        }
      }
    },
    tooltip: {
      enabled: false
    },
    series: [
      {
        type: 'pie',
        data: chartData.map(i => ({
          id: i.id,
          y: i.weight,
          color: i.color
        })),
        size: '100%',
        innerSize: '60%',
        borderWidth: 0
      }
    ],
    title: {
      text: null
    }
  };

  return (
    <div
      className={classNames(classes.root, {
        [classes.horizontalReversedRoot]: isHorizontalReversed
      })}
    >
      <div
        className={classNames(classes.chart, {
          [classes.horizontalReversedChart]: isHorizontalReversed
        })}
      >
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={'chart'}
          options={plotOptions}
        />
      </div>
      <div>
        <Table>
          <TableHader>
            <TableHaderRow>
              {isFundsAndWeightVisible && (
                <TableHeaderCell className={classes.headerCell} colSpan={2}>
                  {i18n('portfolioChart.roboFront.fundAndWeight')}
                </TableHeaderCell>
              )}
            </TableHaderRow>
          </TableHader>
          <TableBody>
            {chartData.map(i => (
              <TableBodyRow key={i.id}>
                <TableBodyCell
                  className={classNames(classes.bodyCell, classes.weightCell)}
                >
                  <div className={classes.title}>
                    <LegendMarker color={i.color} />
                    <div>{`${formatNumber(cultureCode, i.weight, 0, 2)}%`}</div>
                  </div>
                </TableBodyCell>
                <TableBodyCell
                  className={classNames(classes.bodyCell, classes.titleCell)}
                >
                  {i18n(mainAssetClassesI18nMapping[i.name]) || i.name}
                  {isIsinVisible && <div>{i.isin}</div>}
                </TableBodyCell>
              </TableBodyRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default PortfolioChart;
