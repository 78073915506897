import { isNil, isEmpty, equals } from 'ramda';
import React from 'react';

import { usePageStore as useProposalPageStore } from '../../proposal/services/pageStore';
import { useUpdateGoal } from '../../purposeAndRisk/components/useUpdateGoal';
import { useSessionStore } from '../../session/services/sessionStore';
import { PageStatuses } from '../../shared/components/useReadDataListener';
import { PortfolioModes } from '../../shared/constants';
import { getAvailablePortfolios } from '../../shared/services/availablePortfoliosSelectors';
import { useGoalsStore } from '../../shared/services/goalsStore';
import { useReadAdvisoryGoalsData } from './useReadAdvisoryGoalsData';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';

export const useInitPage = () => {
  const sessionStore = useSessionStore();
  const updateGoal = useUpdateGoal();
  const readAdvisoryGoalsData = useReadAdvisoryGoalsData();
  const { advisoryComponents, themes: customerConfigThemes } =
    useCustomerConfig();

  React.useEffect(() => {
    readAdvisoryGoalsData();
  }, []);

  React.useEffect(() => {
    const { goals } = useGoalsStore.getState();

    goals.forEach(goal => {
      const {
        data: { isPortfolioCustom, themes, riskScore }
      } = goal;

      const initData = { data: {} };

      if (isNil(isPortfolioCustom)) {
        const availablePortfolios = getAvailablePortfolios(goal.goalId);
        const matchingPortfolio = availablePortfolios.find(
          p => p.key === riskScore
        );

        const defaultPortfolioMode = advisoryComponents.portfolioModes.find(
          mode => mode === advisoryComponents.defaultPortfolioMode
        );

        initData.data.isPortfolioCustom =
          defaultPortfolioMode === PortfolioModes.customPortfolio;
        initData.data.portfolio = matchingPortfolio
          ? matchingPortfolio.key
          : availablePortfolios[0].key;
      }

      const filteredThemes = (themes || []).filter(theme =>
        customerConfigThemes.find(({ id }) => id === theme)
      );
      if (themes && filteredThemes.length !== themes.length) {
        initData.data.themes = filteredThemes;
      }

      if (!isEmpty(initData.data)) {
        updateGoal(goal.goalId, initData);
      }
    });
    sessionStore.setIsAdvisoryPageInitialized(true);
  }, []);

  React.useEffect(() => {
    if (
      !sessionStore.isSessionInitialized ||
      !sessionStore.isAdvisoryPageInitialized
    ) {
      return;
    }

    const proposalPageStore = useProposalPageStore.getState();

    const readPortfolioChartStatus =
      proposalPageStore.pageStatuses.readPortfolioChartData;

    if (!readPortfolioChartStatus) {
      proposalPageStore.changePageStatuses({
        readPortfolioChartData: PageStatuses.ready
      });
    }

    let lastGoals = useGoalsStore.getState().goals;

    const unsubscribe = useGoalsStore.subscribe(({ goals }) => {
      if (
        !equals(
          goals.map(g => ({
            portfolio: g.data.portfolio,
            riskScore: g.data.riskScore,
            themes: g.data?.themes,
            productPlatformNamespace: g.data?.productPlatformNamespace,
            isPortfolioCustom: g.data.isPortfolioCustom
          })),
          lastGoals.map(g => ({
            portfolio: g.data.portfolio,
            riskScore: g.data.riskScore,
            themes: g.data?.themes,
            productPlatformNamespace: g.data?.productPlatformNamespace,
            isPortfolioCustom: g.data.isPortfolioCustom
          }))
        )
      ) {
        proposalPageStore.changePageStatuses({
          readPortfolioChartData: PageStatuses.ready
        });
      }

      lastGoals = goals;
    });

    return unsubscribe;
  }, [
    sessionStore.isSessionInitialized,
    sessionStore.isAdvisoryPageInitialized
  ]);
};
