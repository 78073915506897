import classNames from 'classnames';
import range from 'lodash.range';
import { Fragment } from 'react';

import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  evenBand: {
    fill: theme.itemBackgroundColor_1
  },
  oddBand: {
    fill: theme.itemBackgroundColor_2
  },
  label: {
    fill: '#BDBDBD'
  },
  gainElement: {
    fill: theme.chartPortfolioColors[0]
  },
  lossElement: {
    fill: theme.chartPortfolioColors[3]
  }
}));

const GainLossChart = ({ className, scenarios, scenarioId, minY, maxY }) => {
  const classes = useStyles();

  if (maxY < minY) {
    throw Error('maxY is less then minY');
  }
  if (minY > 0) {
    minY = 0;
  }
  if (maxY < 0) {
    maxY = 0;
  }

  const padding = 20;
  const ratio = 1.5;
  const height = padding * 2 + (maxY - minY) * ratio;
  const width = 153;
  const bandHeight = 17;
  const zeroYChart = padding + maxY * ratio;
  const zeroXChart = 17.7514;
  const zeroYLabel = zeroYChart + 1.4257;
  const zeroXLabel = 18.312;
  const positiveYLabels = range(0, maxY, 10);
  const negativeYLabels = range(0, minY, -10);
  const yLabels = Array.from(new Set([...positiveYLabels, ...negativeYLabels]));

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      {range(0, height / bandHeight).map(i => (
        <rect
          key={i}
          y={bandHeight * i}
          width={width}
          height={bandHeight}
          className={classNames({
            [classes.evenBand]: i % 2 === 0,
            [classes.oddBand]: i % 2 !== 0
          })}
        />
      ))}
      {yLabels.map(l => {
        let x;
        if (l >= 10) {
          x = '6.01974';
        } else if (l >= 0) {
          x = '8.14974';
        } else if (l > -10) {
          x = '6.01974';
        } else {
          x = '4.71974';
        }

        return (
          <text
            key={l}
            x={x}
            y={zeroYLabel - ratio * l}
            style={{ fontSize: '4px' }}
            className={classes.label}
          >
            {l}
          </text>
        );
      })}
      {scenarios.map((s, index, arr) => {
        const barMargin = 10 - arr.length;
        const barWidth =
          (width - barMargin * arr.length - zeroXChart) / arr.length;
        const xOffset = barWidth + barMargin;
        const opacity = scenarioId === s.id ? '1' : '0.3';
        const minAbs = Math.abs(s.min);
        const maxAbs = Math.abs(s.max);

        return (
          <Fragment key={s.id}>
            <text
              x={zeroXLabel + index * xOffset}
              y={zeroYChart - ratio * maxAbs - 2}
              opacity={opacity}
              className={classes.gainElement}
              style={{ fontSize: '6px' }}
            >
              {s.max}
            </text>
            <text
              x={zeroXLabel + index * xOffset}
              y={zeroYChart + ratio * minAbs + 7}
              opacity={opacity}
              className={classes.lossElement}
              style={{ fontSize: '6px' }}
            >
              {s.min}
            </text>
            <rect
              opacity={opacity}
              x={zeroXChart + index * xOffset}
              y={zeroYChart - ratio * maxAbs}
              width={barWidth}
              height={ratio * maxAbs}
              className={classes.gainElement}
            />
            <rect
              opacity={opacity}
              x={zeroXChart + index * xOffset}
              y={zeroYChart}
              width={barWidth}
              height={ratio * minAbs}
              className={classes.lossElement}
            />
          </Fragment>
        );
      })}
    </svg>
  );
};

export default GainLossChart;
