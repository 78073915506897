import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCustomPortfolioStore } from '../../services/customPortfolioStore';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { useForm as useRoboAdviceForm } from 'features/roboAdvice/adviceSession/form/services/form';
import { createAnalyzeUniverseSustainability } from 'features/roboAdvice/adviceSession/shared/api';
import { Goal } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export const useReadSustainabilityData = () => {
  const i18n = useI18n();
  const dispatch = useDispatch();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const { sustainability: { alignmentCriteria, preferenceCriteria } = {} } =
    useRoboAdviceForm.getState().values;
  const customPortfolioStore = useCustomPortfolioStore();

  const readSustainabilityData = async ({
    selectedGoal
  }: {
    selectedGoal: Goal;
  }) => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    try {
      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const payload = {
        alignmentCriteria: alignmentCriteria ?? {},
        preferenceCriteria: preferenceCriteria
          ? preferenceCriteria.reduce(
              (acc, current) => ({ ...acc, [current]: true }),
              {} as Record<string, boolean>
            )
          : {}
      };

      const { data: universeSustainability } =
        await createAnalyzeUniverseSustainability(
          qAuthAccessToken,
          cancelTokenSource.token,
          payload,
          {
            namespace_id: selectedGoal.data.productPlatformNamespace,
            language: getUserPageLanguage()
          }
        );

      customPortfolioStore.setSustainabilityData(universeSustainability);
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.advisory.customPortfolio.readUniverseSustainabilityError'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readSustainabilityData;
};
