import classNames from 'classnames';
import { useState } from 'react';

import { useI18n } from '../../../../sharedModules/customerConfig/components/useI18n';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import { Spacing } from 'features/shared/constants/spacing';
import { FontWeights, Typography } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  warningContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    alignSelf: 'flex-end',
    gap: Spacing.spacing00,
    backgroundColor: theme.itemBackgroundColor_1,
    border: `1px solid ${theme.defaultInputStrokeColor}`,
    borderRadius: 10,
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight,
    padding: Spacing.spacing00,
    marginTop: Spacing.spacing04
  },
  warningIcon: {
    flexShrink: 0,
    rotate: '180deg',

    '& path': {
      fill: theme.warningColor
    }
  },
  okButtonSection: {
    padding: `${Spacing.spacing01}px ${Spacing.spacing01}px`,
    borderLeft: `1px solid ${theme.defaultInputStrokeColor}`
  },
  okButton: {
    color: theme.accentColor,
    cursor: 'pointer',
    fontSize: Typography.body2.size,
    fontWeight: FontWeights.medium
  }
}));

type Props = {
  className?: string;
  message: string;
  hasOKButton?: boolean;
  closingMessage?: string;
};

const WarningMessage = ({
  className,
  message,
  hasOKButton,
  closingMessage
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const [closedMessage, setClosedMessage] = useState(false);

  return !closedMessage ? (
    <div className={classNames(classes.warningContainer, className)}>
      <WarningIcon className={classes.warningIcon} />

      <div>
        <div>{message}</div>
        {hasOKButton && closingMessage && <div>{closingMessage}</div>}
      </div>
      {hasOKButton && (
        <div className={classes.okButtonSection}>
          <div
            className={classes.okButton}
            onClick={() => setClosedMessage(true)}
          >
            {i18n('shared.ok')}
          </div>
        </div>
      )}
    </div>
  ) : null;
};

export default WarningMessage;
