import { z } from 'zod';

import { modalOptionsSchema } from 'features/roboAdvice/adviceSession/sustainability/types';
import {
  FundAllocationTabs,
  Layouts
} from 'features/roboAdvice/shared/constants';
import { FieldComponentTypes } from 'features/sharedModules/declarativeForm/constants';

export const fieldSchema = z.object({
  name: z.string(),
  label: z.record(z.string()),
  componentType: z.nativeEnum(FieldComponentTypes),
  order: z.number(),
  required: z.boolean(),
  isEnabled: z.boolean(),
  items: z
    .array(
      z.object({
        activeValue: z.string(),
        label: z.record(z.string()),
        textField: z
          .object({
            name: z.string(),
            required: z.boolean()
          })
          .optional()
      })
    )
    .optional(),
  allowLeadingZeros: z.boolean().optional(),
  dateFormat: z.string().optional(),
  dateType: z.string().optional(),
  format: z.string().optional(),
  layout: z.nativeEnum(Layouts).optional(),
  options: z
    .array(
      z.object({
        key: z.string(),
        title: z.string(),
        isNoteActive: z.boolean().optional(),
        textField: z.string().optional()
      })
    )
    .optional(),
  placeholder: z.union([z.record(z.string()), z.string()]).optional(),
  info: z.record(z.string()).optional(),
  allowedYearsRange: z
    .object({
      yearsBack: z.number().optional(),
      yearsForward: z.number().optional()
    })
    .optional(),
  popupInfo: z.record(z.string()).optional()
});

export type Field = z.infer<typeof fieldSchema>;

export const productAttributeSchema = z.object({
  label: z.record(z.string()),
  location: z.nativeEnum(FundAllocationTabs).optional(),
  order: z.number()
});

export const orderExecutionProductAttributeSchema = z.object({
  label: z.record(z.string()),
  order: z.number()
});

export type ProductAttributeGeneric = {
  label: Record<string, string>;
  location?: string;
  order: number;
};

export const historicalReturnBenchmarkTypes = {
  categoryBenchmark: 'categoryBenchmark',
  fundBenchmark: 'fundBenchmark'
} as const;

export const orderInformationSection = z.object({
  isEnabled: z.boolean(),
  label: z.record(z.string()),
  labelKey: z.string(),
  order: z.number(),
  showSum: z.boolean(),
  sumLabel: z.record(z.string()),
  showBackground: z.boolean(),
  required: z.boolean(),
  name: z.string(),
  items: z.array(fieldSchema)
});

export const attachmentSchema = z.object({
  id: z.string(),
  filename: z.string(),
  template: z.string(),
  label: z.string(),
  type: z.string(),
  language: z.string()
});

export const translationsSchema = z.record(z.string(), z.string());

export const sectionSchema = z.object({
  id: z.string(),
  translations: translationsSchema
});

export const offModelQuestionSchema = z.object({
  enabled: z.boolean(),
  name: z.string(),
  header: z.record(z.string()),
  description: z.record(z.string()),
  order: z.number(),
  advisorNotes: z.object({
    enabled: z.boolean(),
    required: z.boolean()
  }),
  icon: z.string().nullable().optional(),
  educationalModal: modalOptionsSchema,
  required: z.boolean(),
  isValueAdaptedRecorded: z.boolean().optional(),
  config: z.object({
    componentType: z.literal('radioButtons'), // In the future: z.nativeEnum(OffModelQuestionType or FieldComponentTypes)
    items: z.array(
      z.object({
        activeValue: z.string().or(z.number()).or(z.boolean()),
        label: z.record(z.string())
      })
    )
  })
});

export type OffModelQuestionConfig = z.infer<typeof offModelQuestionSchema>;

export const alignmentCriteriaSchema = z.object({
  enabled: z.boolean(),
  alignmentCriteriaOrder: z.number(),
  config: z.object({
    title: z.record(z.string()),
    type: z.string(),
    content: z.record(z.string()),
    isCommentFieldShown: z.boolean(),
    required: z.boolean(),
    isValueAdaptedRecorded: z.boolean().optional()
  })
});

export const AvailablePortfoliosMappingModes = {
  tenant: 'tenant',
  ppa: 'ppa'
} as const;
