export const quizDateFields = {
  quiz1: 'quiz1Date',
  quiz2: 'quiz2Date',
  quiz3: 'quiz3Date'
} as const;

export const advancedSuitabilityTablesNames = {
  knowledge: 'knowledge',
  experience: 'experience',
  tradingAmount: 'tradingAmount',
  custom1: 'custom1',
  custom2: 'custom2'
} as const;

const advancedSuitabilityTablesConfigs = {
  advancedSuitabilityKnowledgeAlternatives:
    'advancedSuitabilityKnowledgeAlternatives',
  advancedSuitabilityExperienceAlternatives:
    'advancedSuitabilityExperienceAlternatives',
  advancedSuitabilityTradingAmountAlternatives:
    'advancedSuitabilityTradingAmountAlternatives',
  advancedSuitabilityCustom1Alternatives:
    'advancedSuitabilityCustom1Alternatives',
  advancedSuitabilityCustom2Alternatives:
    'advancedSuitabilityCustom2Alternatives'
} as const;

const advancedSuitabilityTablesInputs = {
  advancedSuitabilityKnowledgeInput: 'advancedSuitabilityKnowledgeInput',
  advancedSuitabilityExperienceInput: 'advancedSuitabilityExperienceInput',
  advancedSuitabilityTradingAmountInput:
    'advancedSuitabilityTradingAmountInput',
  advancedSuitabilityCustom1Input: 'advancedSuitabilityCustom1Input',
  advancedSuitabilityCustom2Input: 'advancedSuitabilityCustom2Input'
} as const;

export const advancedSuitabilityTables = [
  {
    name: advancedSuitabilityTablesNames.knowledge,
    config:
      advancedSuitabilityTablesConfigs.advancedSuitabilityKnowledgeAlternatives,
    input: advancedSuitabilityTablesInputs.advancedSuitabilityKnowledgeInput
  },
  {
    name: advancedSuitabilityTablesNames.experience,
    config:
      advancedSuitabilityTablesConfigs.advancedSuitabilityExperienceAlternatives,
    input: advancedSuitabilityTablesInputs.advancedSuitabilityExperienceInput
  },
  {
    name: advancedSuitabilityTablesNames.tradingAmount,
    config:
      advancedSuitabilityTablesConfigs.advancedSuitabilityTradingAmountAlternatives,
    input: advancedSuitabilityTablesInputs.advancedSuitabilityTradingAmountInput
  },
  {
    name: advancedSuitabilityTablesNames.custom1,
    config:
      advancedSuitabilityTablesConfigs.advancedSuitabilityCustom1Alternatives,
    input: advancedSuitabilityTablesInputs.advancedSuitabilityCustom1Input
  },
  {
    name: advancedSuitabilityTablesNames.custom2,
    config:
      advancedSuitabilityTablesConfigs.advancedSuitabilityCustom2Alternatives,
    input: advancedSuitabilityTablesInputs.advancedSuitabilityCustom2Input
  }
];
