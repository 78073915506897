import { ExpectedValueChart } from '@quantfoliorepo/ui-components';
import { useSelector } from 'react-redux';

import { useReadDataListener } from '../../../../shared/components/useReadDataListener';
import { useOrderPageStore } from '../../../services/orderPageStore';
import { useReadOrderExecutionExpectedValueData } from './useReadOrderExecutionExpectedValueData';
import sessionSelectors from 'features/shared/services/session/selectors';

const ExpectedValue = ({ isSelected }) => {
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const { expectedValueData, pageStatuses } = useOrderPageStore();

  useReadDataListener(
    pageStatuses.readExpectedValueData,
    isSelected,
    useReadOrderExecutionExpectedValueData()
  );

  return (
    <>
      <ExpectedValueChart
        yMinValue={expectedValueData[0]?.y || 0}
        cultureCode={cultureCode}
        chartData={{
          savingPlan: expectedValueData,
          savingInBankAccount: []
        }}
        isInteractive
        onLoad={() => {}}
        width={null}
        savingPlanOnly
      />
    </>
  );
};

export default ExpectedValue;
