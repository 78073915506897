import * as R from 'ramda';

export const Transformations = {
  toLowerCase: 'toLowerCase'
};

const indexedReduce = R.addIndex(R.reduce);

const isI18nObject = value =>
  R.is(Object, value) && !R.isNil(R.prop('text', value));

const transformText = R.curry((transform, text) => {
  if (R.isNil(transform) || R.isNil(text)) {
    return text;
  }

  if (transform === Transformations.toLowerCase) {
    return R.toLower(text);
  }
});

const stringifyI18nObject = (i18n, value) =>
  R.pipe(
    () =>
      indexedReduce(
        (acc, arg, index) =>
          acc.replace(
            `{${index}}`,
            isI18nObject(arg) ? stringifyI18nObject(i18n, arg) : arg
          ),
        i18n(value.text),
        value.args || []
      ),
    transformText(value.transform)
  )();

export const getErrorText = (i18n, error) => {
  if (R.isNil(error)) {
    return null;
  }

  return isI18nObject(error) ? stringifyI18nObject(i18n, error) : i18n(error);
};
