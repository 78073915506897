import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useTabs } from '../services/selectors';
import Tabs from './tabs/index';
import { Spacing } from 'features/shared/constants/spacing';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  tabs: {
    marginRight: Spacing.spacing02
  }
});

const TabsContainer = () => {
  const history = useHistory();
  const { clientType, clientId, adviceSessionId, pageId } = useParams();
  const classes = useStyles();
  const tabs = useTabs();

  return (
    <Tabs
      className={classes.tabs}
      value={pageId}
      onChange={newPageId => {
        history.push(
          routeTemplates.roboAdviceAdviceSession.build(
            clientType,
            clientId,
            adviceSessionId,
            newPageId
          )
        );
      }}
      items={tabs.map(({ tab }) => tab)}
    />
  );
};

export default TabsContainer;
