import classNames from 'classnames';
import { useMemo } from 'react';
import { useField } from 'react-final-form';

import {
  RemindOrResendValue,
  RemindOrResendValues
} from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/constants';
import Radio from 'features/shared/components/radio';
import { Spacing } from 'features/shared/constants/spacing';
import { FontWeights } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  container: {
    margin: `${Spacing.spacing02}px 0`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: Spacing.spacing01
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: Spacing.spacing01,
    cursor: 'pointer',
    backgroundColor: theme.boxBackgroundColor_2,
    borderRadius: 15,
    padding: Spacing.spacing01,
    marginRight: Spacing.spacing00,
    marginLeft: Spacing.spacing00
  },
  optionContainerSelected: {
    backgroundColor: theme.inputFillFocusColor,
    boxShadow: `0 0 0 1px ${theme.accentColor}`
  },
  optionText: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  optionTitle: {
    fontSize: 16,
    fontWeight: FontWeights.medium,
    whiteSpace: 'nowrap'
  },
  optionDescription: {
    fontSize: 14,
    color: theme.secondaryColor,
    whiteSpace: 'nowrap'
  }
}));

const RemindResendQuestion = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const {
    input: { value, onChange }
  } = useField<RemindOrResendValue | null>('remindOrResend', {
    defaultValue: null
  });

  const answers = useMemo(
    () => [
      {
        value: RemindOrResendValues.remind,
        title: i18n(
          'roboAdvice.client.preMeetingSurvey.remindResendModal.reminder.header'
        ),
        description: i18n(
          'roboAdvice.client.preMeetingSurvey.remindResendModal.reminder.description'
        )
      },
      {
        value: RemindOrResendValues.resend,
        title: i18n(
          'roboAdvice.client.preMeetingSurvey.remindResendModal.resend.header'
        ),
        description: i18n(
          'roboAdvice.client.preMeetingSurvey.remindResendModal.resend.description'
        )
      }
    ],
    [i18n]
  );

  return (
    <div className={classes.container}>
      {answers.map(({ title, description, value: answerValue }) => (
        <label
          key={title}
          className={classNames(classes.optionContainer, {
            [classes.optionContainerSelected]: value === answerValue
          })}
        >
          <Radio
            width={25}
            height={25}
            checked={value === answerValue}
            onChange={() => onChange(answerValue)}
          />

          <div className={classes.optionText}>
            <div className={classes.optionTitle}>{title}</div>
            <div className={classes.optionDescription}>{description}</div>
          </div>
        </label>
      ))}
    </div>
  );
};

export default RemindResendQuestion;
