import * as R from 'ramda';

const DefaultCultureCode = 'no-no';

export const roundNumber = (value, decimalPlaces) => {
  const precision = Math.pow(10, decimalPlaces);

  return Math.round((value + Number.EPSILON) * precision) / precision;
};

export const formatNumber = (
  cultureCode,
  number,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  multiplier = 1
) => {
  if (R.isNil(cultureCode)) {
    cultureCode = DefaultCultureCode;
  }

  if (R.isNil(number) || isNaN(number)) {
    return null;
  }

  return new Intl.NumberFormat(cultureCode, {
    minimumFractionDigits,
    maximumFractionDigits
  }).format(number * multiplier);
};

export const getNumberInputFormat = cultureCode => {
  if (R.isNil(cultureCode)) {
    cultureCode = DefaultCultureCode;
  }

  return {
    thousandSeparator: getThousandSeparator(cultureCode),
    decimalSeparator: getDecimalSeparator(cultureCode)
  };
};

function getThousandSeparator(cultureCode) {
  return Intl.NumberFormat(cultureCode).format(1111).replace(/1/g, '');
}

function getDecimalSeparator(cultureCode) {
  return Intl.NumberFormat(cultureCode).format(1.1).replace(/1/g, '');
}
