import { useEffect, useState } from 'react';

import WarningMessage from '../../../shared/components/warningMessage';
import { Goal } from '../../../shared/services/goalsStore';
import PortfolioChangeAlert from '../portfolioChangeAlert';
import RiskModel from '../riskModel';
import AddAssetClassModal from './addAssetClassModal';
import AssetClassAllocationTable from './assetClassAllocationTable';
import ChangeAllocationMethodDropdown from './changeAllocationMethodDropdown';
import RiskTemplateModal from './riskTemplateModal';
import { useHandleCustomPortfolio } from './useHandleCustomPortfolio';
import { useUpdateGoal } from 'features/roboAdvice/adviceSession/purposeAndRisk/components/useUpdateGoal';
import {
  AvailablePortfoliosModes,
  CustomPortfolioTabsNames
} from 'features/roboAdvice/adviceSession/shared/constants';
import {
  useGetCustomPortfolioChartData,
  useGetExistingPortfolioChartData,
  useGetInvalidPortfolioFunds,
  useGetSuggestedPortfolioChartData
} from 'features/roboAdvice/adviceSession/shared/portfolioChartSelectors';
import PortfolioChartCards, {
  PortfolioChartData,
  PortfolioChartsTabs
} from 'features/shared/analyticsComponents/portfolioChartCards/visual';
import AdvisorNotes from 'features/shared/components/advisorNotes';
import Button, {
  ButtonType,
  TertiaryButtonSize
} from 'features/shared/components/button';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { AdvisoryErrors } from 'features/sharedModules/errors/types';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  portfolioContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: Spacing.spacing04
  },
  rootButtonsContainer: {
    display: 'flex',
    alignContent: 'center'
  },
  leftButtonsContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  rightButtonsContainer: {
    marginLeft: 'auto'
  },
  advisorNotes: {
    textAlign: 'left'
  },
  header: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    marginTop: 0
  },
  noTableContainer: {
    textAlign: 'left'
  },
  warningMessage: {
    width: '50%',
    textAlign: 'left',
    marginTop: 0
  },
  invalidFundsMessageContainer: {
    display: 'flex',
    justifyContent: 'left'
  }
}));

type Props = {
  selectedGoal: Goal;
  errors?: AdvisoryErrors;
};

const AssetClassAllocation = ({ selectedGoal, errors }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const [isAddAssetClassModalOpen, setIsAddAssetClassModalOpen] =
    useState(false);
  const [isRiskTemplateModalOpen, setIsRiskTemplateModalOpen] = useState(false);
  const [invalidFundsErrorMessage, setInvalidFundsErrorMessage] = useState('');
  const {
    advisoryComponents: {
      customPortfolio: {
        assetClassAllocation: {
          advisorNotes: {
            enabled: isAdvisorNotesEnabled,
            required: isAdvisorNotesRequired
          }
        },
        fundsUnavailableWarning
      },
      customPortfolioUseCustomAssetClassTable,
      customPortfolioModelPortfolioComparison,
      customPortfolioAllowAssetClassModification,
      customPortfolioAllocatingInAmountEnabled,
      availablePortfoliosMode
    }
  } = useCustomerConfig();
  const { handleChangeRiskTemplate } = useHandleCustomPortfolio();
  const suggestedPortfolioChartData = useGetSuggestedPortfolioChartData({
    goal: selectedGoal
  });
  const existingPortfolioChartData = useGetExistingPortfolioChartData();
  const customPortfolioChartData = useGetCustomPortfolioChartData({
    goal: selectedGoal
  });
  const updateGoal = useUpdateGoal();
  const invalidFundsData = useGetInvalidPortfolioFunds(
    selectedGoal?.data?.customPortfolio
  );

  const portfolioCharts: PortfolioChartData[] = [
    {
      data: existingPortfolioChartData,
      enabled: true
    },
    {
      data: suggestedPortfolioChartData,
      enabled: customPortfolioModelPortfolioComparison
    },
    {
      data: { ...customPortfolioChartData, allowEmpty: true },
      enabled: true
    }
  ]
    .filter(p => p.enabled)
    .map(({ data }) => data);

  useEffect(() => {
    if (!invalidFundsData?.incorrectFunds.length || !fundsUnavailableWarning) {
      return;
    } else {
      const modifiedCustomPortfolio = selectedGoal?.data.customPortfolio?.map(
        assetClass => ({
          ...assetClass,
          instruments: assetClass.instruments.filter(
            instrument =>
              !invalidFundsData.incorrectFunds.some(
                incorrectFund =>
                  incorrectFund.id === instrument.id ||
                  incorrectFund.isin === instrument.isin
              )
          )
        })
      );

      updateGoal(selectedGoal.goalId, {
        data: {
          customPortfolio: modifiedCustomPortfolio
        }
      });
      if (invalidFundsData.incorrectFunds.length === 1) {
        setInvalidFundsErrorMessage(
          i18n('roboAdvice.errors.fundNotAvailable').replace(
            '{0}',
            invalidFundsData.incorrectFunds[0].title
          )
        );
      } else {
        setInvalidFundsErrorMessage(
          i18n('roboAdvice.errors.fundNotAvailableMultiple')
            .replace(
              '{0}',
              invalidFundsData.incorrectFunds
                .slice(0, invalidFundsData.incorrectFunds.length - 1)
                .map(fund => fund.title)
                .join(', ')
            )
            .replace(
              '{1}',
              invalidFundsData.incorrectFunds[
                invalidFundsData.incorrectFunds.length - 1
              ].title
            )
        );
      }
    }
  }, [invalidFundsData, selectedGoal]);

  return (
    <>
      <PortfolioChangeAlert
        in={!!selectedGoal.data.shouldDisplayAlertInAssetAllocation}
        message={i18n(
          'roboAdvice.advisory.customPortfolio.assetClassAllocation.riskChangeAlertMessage'
        )}
        onClick={() =>
          updateGoal(selectedGoal.goalId, {
            data: {
              shouldDisplayAlertInAssetAllocation: false
            }
          })
        }
      />

      <PortfolioChangeAlert
        in={!!selectedGoal.data.shouldDisplayFollowUpMissingInstrumentsAlert}
        message={i18n(
          'roboAdvice.advisory.customPortfolio.assetClassAllocation.missingInstrumentsInFollowUpSessionAlertMessage'
        )}
        onClick={() =>
          updateGoal(selectedGoal.goalId, {
            data: {
              shouldDisplayFollowUpMissingInstrumentsAlert: false
            }
          })
        }
      />
      {invalidFundsErrorMessage && (
        <div className={classes.invalidFundsMessageContainer}>
          <WarningMessage
            className={classes.warningMessage}
            message={invalidFundsErrorMessage}
            closingMessage={i18n('shared.closeWarningMessage')}
            hasOKButton
          />
        </div>
      )}
      {customPortfolioUseCustomAssetClassTable ? (
        <>
          <div className={classes.rootButtonsContainer}>
            {customPortfolioAllocatingInAmountEnabled && (
              <div className={classes.leftButtonsContainer}>
                <ChangeAllocationMethodDropdown selectedGoal={selectedGoal} />
              </div>
            )}
            <div className={classes.rightButtonsContainer}>
              {customPortfolioAllowAssetClassModification && (
                <Button
                  onClick={() => setIsAddAssetClassModalOpen(true)}
                  buttonType={ButtonType.tertiary}
                  buttonSize={TertiaryButtonSize.small}
                >
                  {i18n('roboAdvice.advisory.customPortfolio.addAssetClass')}
                </Button>
              )}
              {availablePortfoliosMode !==
              AvailablePortfoliosModes.linkedToFinalScore ? (
                <Button
                  onClick={() => setIsRiskTemplateModalOpen(true)}
                  buttonType={ButtonType.tertiary}
                  buttonSize={TertiaryButtonSize.small}
                >
                  {i18n('roboAdvice.advisory.customPortfolio.riskTemplate')}
                </Button>
              ) : null}
            </div>
          </div>

          <AssetClassAllocationTable selectedGoal={selectedGoal} />
        </>
      ) : (
        <div className={classes.noTableContainer}>
          <h3 className={classes.header}>
            {i18n('roboAdvice.advisory.modelPortfolio.riskModel')}
          </h3>
          <RiskModel
            goalId={selectedGoal.goalId}
            onChange={v => {
              handleChangeRiskTemplate({
                goalId: selectedGoal.goalId,
                selectedRiskTemplate: v
              });
            }}
            value={selectedGoal.data.riskTemplate}
          />
        </div>
      )}
      <div className={classes.portfolioContainer}>
        <PortfolioChartCards
          portfolioCharts={portfolioCharts}
          alignTitleBottom={false}
          isVertical
          tabs={[
            PortfolioChartsTabs.broadAssetAllocation,
            PortfolioChartsTabs.assetAllocation
          ]}
        />
      </div>
      {isAdvisorNotesEnabled && (
        <AdvisorNotes
          header={i18n(
            'advisory.customPortfolio.assetClassAllocation.advisorNotes.header'
          )}
          customPlaceholder={i18n(
            'advisory.customPortfolio.assetClassAllocation.advisorNotes.placeholder'
          )}
          infoTooltipText={i18n(
            'advisory.customPortfolio.assetClassAllocation.advisorNotes.infoTooltip'
          )}
          id="custom-portfolio-asset-class-allocation-advisor-notes"
          name={`customPortfolio.${CustomPortfolioTabsNames.assetClassAllocation}.advisorNotes.${selectedGoal.goalId}`}
          required={isAdvisorNotesRequired}
          errors={errors?.assetClassAllocationAdvisorNotes?.filter(
            error => error.data?.advisoryGoal === selectedGoal.goalId
          )}
        />
      )}
      <AddAssetClassModal
        selectedGoal={selectedGoal}
        isOpen={isAddAssetClassModalOpen}
        onClose={() => setIsAddAssetClassModalOpen(false)}
      />
      <RiskTemplateModal
        isOpen={isRiskTemplateModalOpen}
        onClose={() => setIsRiskTemplateModalOpen(false)}
        goalId={selectedGoal.goalId}
      />
    </>
  );
};

export default AssetClassAllocation;
