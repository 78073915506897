import React from 'react';

import { CustomerConfigContext } from './context';

export function useI18n() {
  const { translations } = React.useContext(CustomerConfigContext);

  return React.useMemo(
    () =>
      (key, defaultValue = '') =>
        translations[key] || defaultValue,
    [translations]
  );
}
