import { createUseStyles } from 'react-jss';

// Component supports accessibility
// Styles according documentation: https://www.w3.org/WAI/tutorials/forms/labels/#note-on-hiding-elements
const useStyles = createUseStyles({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: 1
  }
});

type Props = {
  id?: string;
  inputId: string;
  text: string;
};

const HiddenLabel = ({ id, inputId, text }: Props) => {
  const classes = useStyles();

  return (
    <label id={id} htmlFor={inputId} className={classes.visuallyHidden}>
      {text}
    </label>
  );
};

export default HiddenLabel;
