import * as React from 'react';

import { clientsTable, OrderKeys } from '../../clientsTable';
import { useClientsStore } from '../services/clientsStore';
import { useReadClients } from './useReadClients';
import { OrderDirections } from 'features/shared/constants/table';

export function useInitPage() {
  const readClients = useReadClients();

  React.useEffect(() => {
    const tableStore = clientsTable.useTableStore.getState();

    tableStore.changeOrder(OrderKeys.softDeleteDate, OrderDirections.desc);

    readClients();

    return () => {
      const tableStore = clientsTable.useTableStore.getState();
      const clientsStore = useClientsStore.getState();

      tableStore.reset();
      clientsStore.reset();
    };
  }, []);
}
