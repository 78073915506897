import classNames from 'classnames';
import * as R from 'ramda';
import * as React from 'react';
import { v4 } from 'uuid';

import Icon from 'features/shared/components/icon/index.js';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  selectFileContainer: {
    position: 'relative'
  },
  selectFileLabel: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    color: theme.accentColor,
    textDecoration: 'none',
    cursor: 'pointer'
  },
  fileContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  selectedFileName: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    marginRight: Spacing.spacing01
  },
  selectedFileImage: {
    marginRight: Spacing.spacing02,
    maxWidth: '256px'
  },
  deleteIcon: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    cursor: 'pointer',
    transform: 'rotate(0.03deg)'
  },
  file: {
    top: '0',
    left: '0',
    width: '1px',
    height: '1px',
    opacity: '0',
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1
  },
  description: {
    color: theme.secondaryColor,
    marginTop: Spacing.spacing00
  }
}));

type Props = {
  value?: { url?: string; file?: File };
  label: string;
  description?: string;
  accept?: string;
  onSelect: (file: File) => void;
  onDelete: () => void;
  onFocus?: (event?: any) => void;
  onBlur?: (event?: any) => void;
};

const FileUpload = ({
  value,
  label,
  description,
  accept,
  onSelect,
  onDelete,
  onFocus,
  onBlur
}: Props) => {
  const classes = useStyles();
  const idRef = React.useRef(v4());

  return (
    <div>
      {R.isNil(value) ? (
        <div className={classes.selectFileContainer}>
          <label
            htmlFor={idRef.current}
            className={classNames(classes.selectFileLabel)}
          >
            {label}
          </label>
          <input
            id={idRef.current}
            type="file"
            accept={accept}
            className={classes.file}
            onChange={e => {
              const files = e.target.files;

              onFocus?.();

              if (!R.isNil(files) && !R.isEmpty(files) && !R.isNil(files[0])) {
                onSelect(files[0]);
              }

              onBlur?.();
            }}
          />
        </div>
      ) : (
        <div className={classes.fileContainer}>
          {!R.isNil(value.url) ? (
            <img className={classes.selectedFileImage} src={value.url} />
          ) : (
            <span className={classes.selectedFileName}>{value.file?.name}</span>
          )}
          <Icon
            className={classes.deleteIcon}
            onClick={() => {
              onFocus?.();
              onDelete();
              onBlur?.();
            }}
            type="delete"
          />
        </div>
      )}
      {!R.isNil(description) && (
        <div className={classes.description}>{description}</div>
      )}
    </div>
  );
};

export default FileUpload;
