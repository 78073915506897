import * as React from 'react';
import { Form, FormSpy } from 'react-final-form';
import { Prompt } from 'react-router';

import Placeholder from '../../../shared/components/placeholder';
import { Statuses } from '../../../shared/constants';
import {
  generalColors,
  feedbackColors,
  accentColorShades,
  primaryColorShades,
  pageBackgroundColorShades_1,
  pageBackgroundColorShades_2,
  infographicsColors,
  logoFileTypes
} from '../constants';
import { visualsForm } from '../services/visualsForm';
import { useVisualsStore } from '../services/visualsStore';
import { useGenerateSecondaryShades } from './useGenerateSecondaryShades';
import { useInitPage } from './useInitPage';
import { useUpdateSettings } from './useUpdateSettings';
import Button from 'features/shared/components/button';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import FinalFormColorInput from 'features/sharedModules/finalForm/components/colorInput';
import FinalFormFileUpload from 'features/sharedModules/finalForm/components/fileUpload';
import FinalFormRadios from 'features/sharedModules/finalForm/components/radios';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  section: {
    minWidth: '600px',
    maxWidth: '1200px',

    '& + &': {
      marginTop: Spacing.spacing03
    }
  },
  sectionHeader: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    fontWeight: FontWeights.bold
  },
  sectionDescription: {
    fontSize: Typography.body3.size,
    paddingTop: Spacing.spacing00,
    color: theme.secondaryColor
  },
  sectionBody: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '-20px'
  },
  radiosSectionBody: {
    marginTop: Spacing.spacing01
  },
  logoSectionBody: {
    marginTop: '20px'
  },
  colorInput: {
    marginTop: Spacing.spacing04,
    marginLeft: Spacing.spacing02
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: Spacing.spacing04
  }
}));

const VisualsForm = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const visualsStore = useVisualsStore();
  const updateSettings = useUpdateSettings();

  useInitPage();
  useGenerateSecondaryShades();

  return visualsStore.pageStatuses.readSettings !== Statuses.succeed ? (
    <Placeholder />
  ) : (
    <div>
      <div className={classes.section}>
        <div className={classes.sectionHeader}>
          {i18n('admin.appAdmin.advisorSolution.visuals.logo')}
        </div>
        <div className={classes.logoSectionBody}>
          <FinalFormFileUpload
            name="logo"
            label={i18n('admin.appAdmin.advisorSolution.visuals.uploadLogo')}
            description={i18n(
              'admin.appAdmin.advisorSolution.visuals.uploadLogoDescription'
            )}
            accept={logoFileTypes.join(',')}
          />
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionHeader}>
          {i18n('admin.appAdmin.advisorSolution.visuals.generalColours')}
        </div>
        <div className={classes.sectionDescription}>
          {i18n(
            'admin.appAdmin.advisorSolution.visuals.generalColoursDescription'
          )}
        </div>
        <div className={classes.sectionBody}>
          {generalColors.map(({ name, label }) => (
            <FinalFormColorInput
              key={name}
              name={name}
              label={i18n(label)}
              rootClassName={classes.colorInput}
            />
          ))}
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionHeader}>
          {i18n('admin.appAdmin.advisorSolution.visuals.secondaryShades')}
        </div>
        <div className={classes.sectionDescription}>
          {i18n(
            'admin.appAdmin.advisorSolution.visuals.secondaryShadesDescription'
          )}
        </div>
        <div className={classes.sectionBody}>
          {accentColorShades.map(({ name, label }) => (
            <FinalFormColorInput
              key={name}
              name={name}
              label={i18n(label)}
              rootClassName={classes.colorInput}
            />
          ))}
          {primaryColorShades.map(({ name, label }) => (
            <FinalFormColorInput
              key={name}
              name={name}
              label={i18n(label)}
              rootClassName={classes.colorInput}
            />
          ))}
          {pageBackgroundColorShades_1.map(({ name, label }) => (
            <FinalFormColorInput
              key={name}
              name={name}
              label={i18n(label)}
              rootClassName={classes.colorInput}
            />
          ))}
          {pageBackgroundColorShades_2.map(({ name, label }) => (
            <FinalFormColorInput
              key={name}
              name={name}
              label={i18n(label)}
              rootClassName={classes.colorInput}
            />
          ))}
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionHeader}>
          {i18n('admin.appAdmin.advisorSolution.visuals.feedbackColors')}
        </div>
        <div className={classes.sectionDescription}>
          {i18n(
            'admin.appAdmin.advisorSolution.visuals.generalColoursDescription'
          )}
        </div>
        <div className={classes.sectionBody}>
          {feedbackColors.map(({ name, label }) => (
            <FinalFormColorInput
              key={name}
              name={name}
              label={i18n(label)}
              rootClassName={classes.colorInput}
            />
          ))}
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionHeader}>
          {i18n('admin.appAdmin.advisorSolution.visuals.infographicsColours')}
        </div>
        <div className={classes.sectionDescription}>
          {i18n(
            'admin.appAdmin.advisorSolution.visuals.infographicsColoursDescription'
          )}
        </div>
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <div className={classes.sectionBody}>
              {values.chartPortfolioColors?.map((c, index) => (
                <FinalFormColorInput
                  key={index}
                  name={`chartPortfolioColors[${index}]`}
                  label={`${i18n(
                    'admin.appAdmin.advisorSolution.visuals.chartColor'
                  )} ${index + 1}`}
                  rootClassName={classes.colorInput}
                />
              ))}
              {infographicsColors.map(({ name, label }) => (
                <FinalFormColorInput
                  key={name}
                  name={name}
                  label={i18n(label)}
                  rootClassName={classes.colorInput}
                />
              ))}
            </div>
          )}
        </FormSpy>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionHeader}>
          {i18n('admin.appAdmin.advisorSolution.visuals.corners')}
        </div>
        <div className={classes.sectionDescription}>
          {i18n('admin.appAdmin.advisorSolution.visuals.cornersDescription')}
        </div>
        <div className={classes.radiosSectionBody}>
          <FinalFormRadios
            name="borderRadiusType"
            items={[
              {
                activeValue: BorderRadiusTypes.rounded,
                label: i18n('admin.appAdmin.advisorSolution.visuals.round')
              },
              {
                activeValue: BorderRadiusTypes.sharp,
                label: i18n('admin.appAdmin.advisorSolution.visuals.sharp')
              }
            ]}
          />
        </div>
      </div>
      <FormSpy subscription={{ pristine: true, hasValidationErrors: true }}>
        {({ pristine, hasValidationErrors }) => (
          <div className={classes.actionButtons}>
            <Button
              disabled={
                pristine ||
                visualsStore.pageStatuses.updateSettings === Statuses.pending
              }
              onClick={() => {
                visualsForm.submit();

                if (!hasValidationErrors) {
                  updateSettings();
                }
              }}
            >
              {i18n('admin.save')}
            </Button>
          </div>
        )}
      </FormSpy>
    </div>
  );
};

const Visuals = () => {
  return (
    <>
      <Prompt
        when={true}
        message={() => {
          const { pristine } = visualsForm.getState();

          return pristine ? true : 'confirmation required';
        }}
      />
      <Form form={visualsForm} onSubmit={() => {}}>
        {() => <VisualsForm />}
      </Form>
    </>
  );
};

export default Visuals;
