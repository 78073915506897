import { useEffect } from 'react';

import { useCostChartStore } from 'features/roboAdvice/adviceSession/costChart';
import { useGoalsStore } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { selectors } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { prepareCostChartData } from 'features/shared/analyticsComponents/costChart/mapping';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export const useInitPage = () => {
  const i18n = useI18n();
  const selectedGoalsIds = useGoalsStore<string[]>(
    selectors.getSelectedGoalsIds
  );
  const costChartStore = useCostChartStore();
  const {
    analyticsComponents: { multiGoalCostEnabled }
  } = useCustomerConfig();

  useEffect(() => {
    const selectedGoalsData = costChartStore.goalsData.filter(({ goalId }) =>
      selectedGoalsIds.includes(goalId)
    );

    const selectedOriginalGoalsData = costChartStore.originalGoalsData.filter(
      ({ goalId }) => selectedGoalsIds.includes(goalId)
    );

    const { summary, chartData, firstYearMerged, lastYearMerged, fundDetails } =
      prepareCostChartData({
        selectedGoalsData,
        i18n,
        isUsedMultiGoalCostEndpoint: multiGoalCostEnabled
      });

    costChartStore.setCostData({
      chartData,
      tableData: {
        firstYear: firstYearMerged,
        fundDetails,
        lastYear: lastYearMerged,
        summary
      }
    });

    const {
      summary: originalSummary,
      firstYearMerged: originalFirstYearMerged,
      lastYearMerged: originalLastYearMerged,
      fundDetails: originalFundDetails
    } = prepareCostChartData({
      selectedGoalsData: selectedOriginalGoalsData,
      i18n,
      isUsedMultiGoalCostEndpoint: multiGoalCostEnabled
    });

    costChartStore.setOriginalTableData({
      originalTableData: {
        firstYear: originalFirstYearMerged,
        fundDetails: originalFundDetails,
        lastYear: originalLastYearMerged,
        summary: originalSummary
      }
    });
  }, [
    costChartStore.goalsData,
    costChartStore.originalGoalsData,
    selectedGoalsIds
  ]);
};

export default useInitPage;
