export const types = {
  CHANGE_ORDER: 'TABLE/CHANGE_ORDER',
  CHANGE_PAGE_NUMBER: 'TABLE/CHANGE_PAGE_NUMBER',
  CHANGE_PAGE_SIZE: 'TABLE/CHANGE_PAGE_SIZE',
  CHANGE_ROW_SELECTION: 'TABLE/CHANGE_ROW_SELECTION',
  CHANGE_ALL_ROWS_SELECTION: 'TABLE/CHANGE_ALL_ROWS_SELECTION'
};

export const creators = {
  changeOrder: (tableId, orderKey, orderDirection) => ({
    type: types.CHANGE_ORDER,
    payload: { tableId, orderKey, orderDirection }
  }),
  changePageNumber: (tableId, pageNumber) => ({
    type: types.CHANGE_PAGE_NUMBER,
    payload: { tableId, pageNumber }
  }),
  changePageSize: (tableId, pageSize) => ({
    type: types.CHANGE_PAGE_SIZE,
    payload: { tableId, pageSize }
  }),
  changeRowSelection: (tableId, rowId, isSelected) => ({
    type: types.CHANGE_ROW_SELECTION,
    payload: { tableId, rowId, isSelected }
  }),
  changeAllRowsSelection: (tableId, allRowIds, isSelected) => ({
    type: types.CHANGE_ALL_ROWS_SELECTION,
    payload: { tableId, allRowIds, isSelected }
  })
};
