import { FlowTypes } from 'features/roboAdvice/client/main/constants';

export const PreMeetingSurveyPages = {
  initialQuestion: 'initialQuestion',
  selfServiceFlow: 'selfServiceFlow',
  customerDetails: 'customerDetails'
} as const;

export type PreMeetingSurveyPage =
  typeof PreMeetingSurveyPages[keyof typeof PreMeetingSurveyPages];

export const RemindOrResendValues = {
  remind: 'remind',
  resend: 'resend'
} as const;

export type RemindOrResendValue =
  typeof RemindOrResendValues[keyof typeof RemindOrResendValues];

export const INITIAL_QUESTION_TRANSLATIONS_KEYS = {
  [FlowTypes.adviceflow]: {
    modalHeader:
      'roboAdvice.client.preMeetingSurvey.newInvestmentAdvice.header',
    createAdviceButton: 'roboAdvice.client.preMeetingSurvey.createAdvice',
    true: {
      title: 'roboAdvice.client.preMeetingSurvey.selfAssessmentOption.header',
      description:
        'roboAdvice.client.preMeetingSurvey.selfAssessmentOption.description'
    },
    false: {
      title: 'roboAdvice.client.preMeetingSurvey.noSelfAssessmentOption.header',
      description:
        'roboAdvice.client.preMeetingSurvey.noSelfAssessmentOption.description'
    }
  },
  [FlowTypes.orderexecution]: {
    modalHeader:
      'roboAdvice.client.preMeetingSurvey.orderExecution.newInvestmentAdvice.header',
    createAdviceButton:
      'roboAdvice.client.preMeetingSurvey.orderExecution.createAdvice',
    true: {
      title:
        'roboAdvice.client.preMeetingSurvey.orderExecution.selfAssessmentOption.header',
      description:
        'roboAdvice.client.preMeetingSurvey.orderExecution.selfAssessmentOption.description'
    },
    false: {
      title:
        'roboAdvice.client.preMeetingSurvey.orderExecution.noSelfAssessmentOption.header',
      description:
        'roboAdvice.client.preMeetingSurvey.orderExecution.noSelfAssessmentOption.description'
    }
  }
} as const;
