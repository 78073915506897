import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RoboAdviceClientPages } from '../../../shared/constants';
import { getClientName } from '../../../shared/services/mapping';
import { createInvestor } from '../api';
import { clientForm } from '../services/clientForm';
import { useClientPageStore } from '../services/clientPageStore';
import { createInvestorPayload } from '../services/mapping';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { ClientTypes } from 'features/shared/constants/session';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useCreateClient() {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const currentUser: any = useSelector(sessionSelectors.getCurrentUser());
  const i18n = useI18n();
  const history = useHistory();
  const {
    roboAdvice: {
      kyc: {
        company: { isKYCEnabled: companyIsKYCEnabled },
        person: { isKYCEnabled: personIsKYCEnabled }
      }
    }
  } = useCustomerConfig();

  const dispatch = useDispatch();

  const createClient = async () => {
    const clientPageStore = useClientPageStore.getState();
    const { values } = clientForm.getState();

    try {
      clientPageStore.setIsCreateClientPending(true);
      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        undefined
      );

      const clientType = values.clientInformation?.clientType;
      const isKYCEnabled =
        clientType === ClientTypes.company
          ? companyIsKYCEnabled
          : personIsKYCEnabled;
      const newInvestorData = createInvestorPayload(
        values,
        currentUser.auth0Id
      );
      const { data: createInvestorResponse } = await createInvestor(
        accessToken,
        newInvestorData
      );
      const investorId = createInvestorResponse.id;

      history.push(
        routeTemplates.roboAdviceClient.build(
          clientType,
          investorId,
          isKYCEnabled
            ? RoboAdviceClientPages.kyc
            : RoboAdviceClientPages.adviceSessions
        )
      );

      clientForm.initialize(values);

      const clientName = getClientName(values.clientInformation);

      clientPageStore.setClientData(clientName);
      clientPageStore.setIsCreateClientPending(false);

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n('roboAdvice.client.createClientSuccessMessage'),
          type: NotificationTypes.success
        })
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        clientPageStore.setIsCreateClientPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.client.createClientErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return createClient;
}
