import create from 'zustand';

import { PageStatus } from '../../shared/components/useReadDataListener';
import { NamespaceType } from 'features/shared/constants/session';

export type ProductPlatformNamespace = {
  description: string | null;
  isDefault: boolean;
  name: string;
  namespaceId: number;
  namespaceType: keyof typeof NamespaceType;
  riskModel: any | null;
  userId: number;
};

export type Theme = {
  id: string;
  title: string;
};
type Portfolio = { id: string; riskScore: number; title: string };
type CategorySelection = { [key: string]: string };
type SuggestedPortfolio = {
  AssetClass: {
    CategoryId: string;
    CategoryName: string;
    EquityShare: number;
    MainAssetClass: string;
    SubAssetClass: string;
  };
  Weight: number;
};

export type PageStatuses = {
  readHistoricalReturn3MChartData?: PageStatus;
  readHistoricalReturnYTDChartData?: PageStatus;
  readHistoricalReturn1YChartData?: PageStatus;
  readHistoricalReturn3YChartData?: PageStatus;
  readHistoricalReturn5YChartData?: PageStatus;
  readHistoricalReturn10YChartData?: PageStatus;
  readRefreshCustomPortfolio?: PageStatus;
  readSustainabilityModelPortfolioData?: PageStatus;
};

export type AdvisoryPageStoreData = {
  themes: Theme[];
  portfolios: Portfolio[];
  categoriesSelection: CategorySelection;
  goalThemes?: Record<string, Theme[]>;
  goalPortfolios?: Record<string, Portfolio[]>;
  goalCategoriesSelection?: Record<string, CategorySelection>;
  pageStatuses: PageStatuses;
  suggestedPortfolios: Record<string, SuggestedPortfolio[]>;
};

export type AdvisoryPageStoreApi = {
  reset: () => void;
  initialize: (initialData: Partial<AdvisoryPageStoreData>) => void;
  setGoalsAdvisoryData: (
    goalThemes: Record<string, Theme[]> | undefined,
    goalPortfolios: Record<string, Portfolio[]> | undefined,
    goalCategoriesSelection: Record<string, CategorySelection> | undefined
  ) => void;
  setPageStatus: <K extends keyof PageStatuses>(
    key: K,
    value: PageStatuses[K]
  ) => void;
  changePageStatuses: (pageStatuses: Partial<PageStatuses>) => void;
  setSuggestedPortfolio: (
    goalId: string,
    suggestedPortfolio: SuggestedPortfolio[]
  ) => void;
};

export type AdvisoryPageStoreState = AdvisoryPageStoreData &
  AdvisoryPageStoreApi;

export const defaultData = {
  themes: [],
  portfolios: [],
  categoriesSelection: {},
  goalThemes: {},
  goalPortfolios: {},
  goalCategoriesSelection: {},
  pageStatuses: {},
  suggestedPortfolios: {}
};

export const usePageStore = create<AdvisoryPageStoreState>(set => ({
  ...defaultData,
  reset: () => {
    set(defaultData);
  },
  initialize(initialData) {
    set(
      state => ({
        ...state,
        ...initialData
      }),
      true
    );
  },
  setGoalsAdvisoryData: (
    goalThemes,
    goalPortfolios,
    goalCategoriesSelection
  ) => {
    set({ goalThemes, goalPortfolios, goalCategoriesSelection });
  },
  setPageStatus(key, value) {
    set(state => ({
      pageStatuses: { ...state.pageStatuses, [key]: value }
    }));
  },
  changePageStatuses(pageStatuses) {
    set(state => ({
      pageStatuses: { ...state.pageStatuses, ...pageStatuses }
    }));
  },
  setSuggestedPortfolio(goalId, newSuggestedPortfolio) {
    set(state => ({
      suggestedPortfolios: {
        ...state.suggestedPortfolios,
        [goalId]: newSuggestedPortfolio
      }
    }));
  }
}));
