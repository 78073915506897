import { FlowTypes } from './constants';
import { Contact } from './services/clientForm';
import { SessionStatuses } from 'features/roboAdvice/shared/constants';
import { SessionStatus } from 'features/roboAdvice/shared/types';
import { ClientType } from 'features/shared/constants/session';
import { StatusResponse } from 'features/shared/types/axios';
import { InvestorData } from 'features/shared/types/investor';

export type CreateInvestorRequestData = {
  name: string;
  additionalData?: Record<string, any>;
  address?: string;
  advisorId?: string;
  city?: string;
  contacts?: Contact[];
  country?: string;
  email?: string;
  externalId?: string;
  investorType?: ClientType;
  kyc?: Record<string, any>;
  organizationNumber?: string;
  phone?: string;
  ssn?: string;
  zipCode?: string;
};

export type CreateInvestorResponse = InvestorData;

export type GetInvestorInformationResponse = InvestorData;

export type UpdateInvestorInformationRequestData = Partial<InvestorData>;

export type UpdateInvestorResponse = Partial<
  Omit<InvestorData, 'investorType' | 'id' | 'advisorId'>
>;

export type GetClientSessionsParams = {
  investor_id: string;
};

export type ClientSession = {
  advice_type: string;
  channel: string;
  company_id: string;
  created_at: string;
  end_user_id: string;
  last_activity_date: string;
  name: string;
  session_id: string;
  status: SessionStatus;
};

export type GetClientSessionsResponse = {
  sessions: ClientSession[];
};

export type CreateAdviceSessionRequestData = {
  advice_type: string;
  advisor_id: string;
  investor_id: string;
  name: string;
  session_id: string;
};

export type CreateAdviceSessionResponse = {
  advice_type: string;
  channel: string;
  created_date: string;
  last_activity_date: string;
  name: string;
  session_id: string;
  status: typeof SessionStatuses[keyof typeof SessionStatuses];
};

export type DeleteAdviceSessionResponse = StatusResponse;

export type UpdateAdviceSessionRequestData = { name?: string };

export type UpdateAdviceSessionResponse = StatusResponse;

export type FlowType = typeof FlowTypes[keyof typeof FlowTypes];

export type SurveyFlowsResponse = {
  links: {
    self: string;
  };
  data: {
    type: string;
    id: string;
    attributes: {
      tenantId: string;
      flowInvestorType: ClientType;
      disabled: boolean;
      flowType: string;
      name: Record<string, string>;
      defaultEmailTemplate: Record<string, string>;
      defaultSmsTemplate: Record<string, string>;
      pages: [];
      createdAt: string;
      updatedAt: string;
    };
    links: {
      self: string;
    };
  }[];
};

export const NotificationChannelTypes = {
  sms: 'sms',
  email: 'email'
};

export type NotificationChannelType =
  typeof NotificationChannelTypes[keyof typeof NotificationChannelTypes];

export type CreatePreMeetingSessionPayload = {
  id: string;
  dapSessionType: FlowType;
  surveyFlowId: string;
  investorType: ClientType;
  contact: {
    name: string;
    phone: string;
    email: string | null;
    ssn: string | null;
  };
  language: string;
  notification: {
    channel: NotificationChannelType;
    subject: string | null;
    message: string;
  }[];
};

export type GetActivePreMeetingOtpSessionResponse = {
  data: {
    type: 'SessionAuthentication';
    attributes: {
      externalReferenceId: string;
      recipientId: string;
      type: 'otp';
    };
  };
};
