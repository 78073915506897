import { DataSources } from 'modules/shared/constants/dataSources.js';

export const dataSources = [
  DataSources.roboPortfolio,
  DataSources.roboSelection,
  DataSources.fundList,
  DataSources.fundMeta,
  DataSources.analyzeRiskReturn
];

export const mainAssetClassesI18nMapping = {
  'Money Market': 'portfolioChart.moneyMarket',
  'Fixed Income': 'portfolioChart.fixedIncome',
  Equity: 'portfolioChart.equity'
};
