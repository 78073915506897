import create from 'zustand';

export const carouselIds = {
  customPortfolio: 'custom-portfolio',
  modelPortfolio: 'model-portfolio',
  proposalPreview: 'proposal-preview',
  proposal: 'proposal',
  orderPage: 'order-page',
  riskFinder: 'risk-finder',
  holisticViewProposal: 'holistic-view-proposal'
} as const;

export type carouselIdsValues = typeof carouselIds[keyof typeof carouselIds];

export type CarouselStoreData = {
  [key in carouselIdsValues]?: {
    selectedItemIndex: number;
  };
};

export type CarouselStoreApi = {
  reset: () => void;
  setSelectedItemIndex: (
    carouselId: carouselIdsValues,
    selectedItemIndex: number
  ) => void;
};

const defaultData: CarouselStoreData = {};

export type CarouselStoreState = CarouselStoreData & CarouselStoreApi;

export const useCarouselStore = create<CarouselStoreState>(set => ({
  ...defaultData,
  reset() {
    set(defaultData);
  },
  setSelectedItemIndex: (carouselId, selectedItemIndex) => {
    set(state => ({
      ...state,
      [carouselId]: {
        selectedItemIndex
      }
    }));
  }
}));
