import { Document, Index } from 'flexsearch';
import { uniq } from 'ramda';
import * as React from 'react';

import { useTranslationsStore } from '../services/translationsStore';
import { translationsTable } from 'features/admin/appAdmin/advisorSolution/translationsTable';

const useSearchTranslations = () => {
  const translationsStore = useTranslationsStore();
  const { useTableStore } = translationsTable;

  const index = React.useMemo(() => {
    if (translationsStore.isAdminModeActive) {
      const index = new Index({
        tokenize: 'forward'
      });

      translationsStore.translations
        .map(({ default: defaultValue }) => defaultValue)
        .forEach((value, i) => {
          index.add(i, value);
        });

      return index;
    }

    const index = new Document({
      tokenize: 'forward',
      document: {
        index: ['value'],
        store: 'index'
      }
    });

    const translationsValuesAndDefaults = translationsStore.translations.reduce<
      { index: number; value: string }[]
    >((acc, curr, index) => {
      return [
        ...acc,
        { index: index, value: curr.value },
        { index: index, value: curr.default }
      ];
    }, []);

    translationsValuesAndDefaults.forEach(({ index: id, value }, i) => {
      index.add({ id: i, value, index: id });
    });

    return index;
  }, [translationsStore.translations, translationsStore.isAdminModeActive]);

  const searchTranslations = query => {
    const tableStore = useTableStore.getState();
    const isQueryLongEnough = query.length >= 3;
    let matchingIndexes = [];
    if (isQueryLongEnough) {
      if (translationsStore.isAdminModeActive) {
        matchingIndexes = index.search(query, { suggest: true });
      } else {
        const results = index.search(query, { suggest: true, enrich: true });
        if (results.length) {
          matchingIndexes = uniq(
            results[0].result.map(({ doc: { index } }) => index)
          );
        }
      }
    }
    tableStore.changePageNumber(0);
    translationsStore.setIsSearchModeActive(isQueryLongEnough);
    translationsStore.setSearchedTranslations(matchingIndexes);
  };

  return searchTranslations;
};

export default useSearchTranslations;
