import { CancelToken } from 'axios';

import { Translation } from './services/translationsStore';
import { customersApiV2 } from 'features/shared/utils/axios';

export type TranslationPayload =
  | Pick<Translation, 'application' | 'language' | 'name'>
  | Pick<Translation, 'application' | 'language' | 'name' | 'value'>;

export type AdminTranslationPayload = Omit<
  Translation,
  'value' | 'is_overruled'
>;

export const getTranslations = (
  accessToken: string,
  cancelToken: CancelToken,
  language: string
) => {
  return customersApiV2.get<{ settings: Translation[] }>(
    `/translations/deepalpha/${language}`,
    {
      cancelToken,
      headers: { Authorization: accessToken }
    }
  );
};

export const updateTranslations = (
  accessToken: string,
  data: TranslationPayload[]
) => {
  return customersApiV2.post(`/translations/`, data, {
    headers: { Authorization: accessToken }
  });
};

export const updateAdminTranslations = (
  accessToken: string,
  data: AdminTranslationPayload[]
) => {
  return customersApiV2.put(
    `admin/translations`,
    { translations: data },
    {
      headers: { Authorization: accessToken }
    }
  );
};
