import { useEffect, useState } from 'react';

import PortfolioChangeAlert from '../portfolioChangeAlert';
import FundAllocationBase from './fundAllocationBase';
import { useUpdateGoal } from 'features/roboAdvice/adviceSession/purposeAndRisk/components/useUpdateGoal';
import { Goal } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { FundAllocationTabs } from 'features/roboAdvice/shared/constants';
import ButtonGroup from 'features/shared/components/buttonGroup';
import { Spacing } from 'features/shared/constants/spacing';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { AdvisoryErrors } from 'features/sharedModules/errors/types';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  fundAllocationButtons: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonGroup: {
    height: '32px'
  },
  buttonGroupItem: {
    height: '32px',
    padding: `0 ${Spacing.spacing02}px`,
    borderWidth: 1
  }
}));

type Props = {
  selectedGoal: Goal;
  isSelected?: boolean;
  errors?: AdvisoryErrors;
};

const FundAllocation = ({ selectedGoal, isSelected, errors }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const updateGoal = useUpdateGoal();
  const {
    roboAdvice: {
      sustainability: { enabled: isSustainabilityEnabled }
    },
    advisoryComponents: {
      customPortfolio: {
        fundAllocation: {
          showOverview,
          showSustainability,
          showPreferenceCriteria
        }
      }
    }
  } = useCustomerConfig();
  const [fundAllocationTab, setFundAllocationTab] = useState<
    keyof typeof FundAllocationTabs
  >(FundAllocationTabs.overview);

  const fundAllocationButtons = [
    {
      type: FundAllocationTabs.overview,
      title: i18n('roboAdvice.advisory.customPortfolio.overview'),
      isActive: fundAllocationTab === FundAllocationTabs.overview,
      onClick: () => setFundAllocationTab(FundAllocationTabs.overview),
      display: showOverview
    },
    {
      type: FundAllocationTabs.sustainability,
      title: i18n('roboAdvice.advisory.customPortfolio.sustainability'),
      isActive: fundAllocationTab === FundAllocationTabs.sustainability,
      onClick: () => setFundAllocationTab(FundAllocationTabs.sustainability),
      display: showSustainability && isSustainabilityEnabled
    },
    {
      type: FundAllocationTabs.preferenceCriteria,
      title: i18n('roboAdvice.advisory.customPortfolio.preferenceCriteria'),
      isActive: fundAllocationTab === FundAllocationTabs.preferenceCriteria,
      onClick: () =>
        setFundAllocationTab(FundAllocationTabs.preferenceCriteria),
      display: showPreferenceCriteria && isSustainabilityEnabled
    }
  ].filter(({ display }) => display);

  useEffect(() => {
    if (fundAllocationButtons.length > 0) {
      setFundAllocationTab(fundAllocationButtons[0].type);
    }
  }, []);

  return (
    <>
      <PortfolioChangeAlert
        in={!!selectedGoal.data.shouldDisplayAlertInFundAllocation}
        message={i18n(
          'roboAdvice.advisory.customPortfolio.fundAllocation.riskChangeAlertMessage'
        )}
        onClick={() =>
          updateGoal(selectedGoal.goalId, {
            data: {
              shouldDisplayAlertInFundAllocation: false
            }
          })
        }
      />

      <PortfolioChangeAlert
        in={!!selectedGoal.data.shouldDisplayFollowUpMissingInstrumentsAlert}
        message={i18n(
          'roboAdvice.advisory.customPortfolio.assetClassAllocation.missingInstrumentsInFollowUpSessionAlertMessage'
        )}
        onClick={() =>
          updateGoal(selectedGoal.goalId, {
            data: {
              shouldDisplayFollowUpMissingInstrumentsAlert: false
            }
          })
        }
      />

      <div className={classes.fundAllocationButtons}>
        {fundAllocationButtons.length > 1 && (
          <ButtonGroup
            items={fundAllocationButtons}
            className={classes.buttonGroup}
            itemClassName={classes.buttonGroupItem}
          />
        )}
      </div>

      {fundAllocationButtons.length > 0 && (
        <FundAllocationBase
          selectedGoal={selectedGoal}
          isSelected={isSelected}
          fundAllocationTab={fundAllocationTab}
          errors={errors}
        />
      )}
    </>
  );
};

export default FundAllocation;
