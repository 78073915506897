import { Typography, FontWeights } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const usePageStyles = createUseStyles({
  headerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  header: {
    margin: '36px 20px 31px 0px',
    fontWeight: FontWeights.medium,
    fontSize: Typography.heading2.size,
    lineHeight: Typography.heading2.lineHeight
  },
  settings: {
    cursor: 'pointer'
  },
  tabs: {
    display: 'inline-flex'
  },
  tab: {
    margin: '0 10px',
    padding: '8px 10px',
    fontSize: Typography.heading2.size,
    lineHeight: Typography.heading2.lineHeight
  }
});
