import { getNumberOfErrors } from '../utils';
import { useTabs as useAdviceSessionTabs } from 'features/roboAdvice/adviceSession/main/services/selectors';
import {
  isPageOrderExecution,
  useTabs as useOrderExecutionTabs
} from 'features/roboAdvice/adviceSession/orderExecution/services/selectors';

export const useGetFirstTabWithError = (
  allErrors,
  pageId
): string | undefined => {
  const adviceSessionTabs = useAdviceSessionTabs();
  const orderExecutionTabs = useOrderExecutionTabs();
  const tabs = isPageOrderExecution(pageId)
    ? orderExecutionTabs
    : adviceSessionTabs;

  const firstTabWithError = tabs?.find(tab =>
    getNumberOfErrors(allErrors[tab.pageId])
  );

  return firstTabWithError?.pageId;
};
