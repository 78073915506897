import { useEffect } from 'react';
import { Prompt } from 'react-router';
import { useParams } from 'react-router-dom';

import { useClientForm } from '../../main/services/clientForm';
import { ClientParams } from '../../shared/types';
import AutomaticClientClassification from './automaticClientClassification';
import CompanyFlags from './eninElements/companyFlags/companyFlags';
import { useCheckIsOrgNumberValid } from './eninElements/useCheckIsOrgNumberValid';
import SaveButton from './saveButton';
import SignatureRightTable from './signatureRightTable';
import UboTable from './uboTable';
import { useInitPage } from './useInitPage';
import { useIsSessionReadOnly } from 'features/roboAdvice/adviceSession/main/services/selectors';
import AdvisorNotes from 'features/shared/components/advisorNotes';
import ErrorMessage from 'features/shared/components/errorMessage';
import { LayoutBreakpoints } from 'features/shared/constants/layoutBreakpoints';
import { ClientTypes } from 'features/shared/constants/session';
import { Spacing } from 'features/shared/constants/spacing';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { ConfigurableComponent } from 'features/sharedModules/declarativeForm/components';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  rightContainer: {
    marginLeft: '225px'
  },
  advisorNotesContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: Spacing.spacing04
  },
  buttonContainer: {
    marginTop: Spacing.spacing04
  },
  customField: {
    '& + &': {
      marginTop: Spacing.spacing03
    }
  },
  tableInput: {},
  [`@media (max-width: ${LayoutBreakpoints.desktopLarge}px)`]: {
    rightContainer: {
      marginLeft: '100px'
    }
  },
  [`@media (max-width: ${LayoutBreakpoints.desktopMedium}px)`]: {
    fieldsContainer: {
      flexDirection: 'column-reverse'
    },
    rightContainer: {
      margin: `0`
    },
    tableInput: {
      width: '180px'
    }
  }
});

const KYC = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const isSessionReadOnly = useIsSessionReadOnly();
  const { errors, touched, pristine } = useClientForm();
  const showError = errors?.kyc?.advisorNote && touched?.['kyc.advisorNotes'];
  const { clientType } = useParams<ClientParams>();
  const isClientCompany = clientType === ClientTypes.company;

  const fields = useInitPage();
  const checkIsOrgNumberValid = useCheckIsOrgNumberValid();

  const {
    roboAdvice: {
      kyc: {
        company: {
          enin: { companyFlags, ubo, signatureRight }
        }
      }
    }
  } = useCustomerConfig();
  const isENINElementsEnabled =
    signatureRight?.isEnabled || ubo?.isEnabled || companyFlags?.isEnabled;

  useEffect(() => {
    if (isClientCompany && isENINElementsEnabled) {
      checkIsOrgNumberValid();
    }
  }, []);

  return (
    <>
      <div className={classes.fieldsContainer}>
        <div>
          {fields.map(({ name, config, ...restProps }) => {
            if (name === 'kyc.automaticClientClassification') {
              return <AutomaticClientClassification key={name} />;
            }

            return (
              <ConfigurableComponent
                fieldClassName={classes.customField}
                key={name}
                name={name}
                config={config}
                {...restProps}
              />
            );
          })}
        </div>
        {isClientCompany && companyFlags.isEnabled && (
          <div className={classes.rightContainer}>
            <CompanyFlags />
          </div>
        )}
      </div>

      {fields.map(({ name }) => {
        if (name === 'kyc.signatureRight' && isClientCompany) {
          return (
            <SignatureRightTable
              key={name}
              inputClassName={classes.tableInput}
            />
          );
        }

        if (name === 'kyc.ubo' && isClientCompany) {
          return <UboTable key={name} inputClassName={classes.tableInput} />;
        }
      })}

      <AdvisorNotes
        name={'kyc.advisorNote'}
        header={i18n('kyc.advisorNotes.header')}
        customPlaceholder={i18n('kyc.advisorNotes.placeholder')}
        infoTooltipText={i18n('kyc.advisorNotes.infoTooltip')}
        disabled={isSessionReadOnly}
        className={classes.advisorNotesContainer}
      />
      {showError && (
        <ErrorMessage>* {i18n(errors.kyc.advisorNote.text)}</ErrorMessage>
      )}

      <div className={classes.buttonContainer}>
        <SaveButton />
      </div>

      <Prompt when={!pristine} message={'confirm required'} />
    </>
  );
};

export default KYC;
