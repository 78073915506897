import create from 'zustand';

export type CustomDocument = {
  createdAt: string;
  documentId: string;
  documentStatus: string;
  documentType: string;
  name: string;
  version?: string | null;
};

export type CustomDownloadStoreData = {
  availableDocuments: Record<string, CustomDocument[]>;
  areDocumentsLoading: boolean;
};

export type CustomDownloadStoreApi = {
  reset: () => void;
  initialize: (initialData: Partial<CustomDownloadStoreData>) => void;
  addAvailableDocuments: (
    adviceSessionId: string,
    docs: CustomDocument[]
  ) => void;
  setAreDocumentsLoading: (status: boolean) => void;
};

export type CustomDownloadStoreState = CustomDownloadStoreData &
  CustomDownloadStoreApi;

export const defaultData = {
  availableDocuments: {},
  areDocumentsLoading: false
};

export const useCustomDownloadStore = create<CustomDownloadStoreState>(set => ({
  ...defaultData,
  reset: () => {
    set(defaultData);
  },
  initialize(initialData) {
    set(
      state => ({
        ...state,
        ...initialData
      }),
      true
    );
  },
  addAvailableDocuments(adviceSessionId, docs) {
    set(state => ({
      availableDocuments: {
        ...state.availableDocuments,
        [adviceSessionId]: docs
      }
    }));
  },
  setAreDocumentsLoading(status) {
    set({ areDocumentsLoading: status });
  }
}));
