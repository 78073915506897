import YAML from 'json-to-pretty-yaml';
import { ZodIssueCode } from 'zod';

import { DefaultCustomerConfig } from '../selectors';
import { customerConfigSchema } from '../types';
import config from 'config/index.js';

const getPropertyByDotNotation = (path: string, obj: unknown) => {
  return path.split('.').reduce((o: any, i) => o?.[i], obj);
};

type ConfigError = {
  key: string;
  value: unknown;
  exampleData?: unknown;
  receivedType?: string;
  expectedType?: string;
};

export const validateCustomerConfig = async (customerConfig: unknown) => {
  if (!config.APPLICATION_DEBUG) {
    return;
  }

  const result = await customerConfigSchema.safeParseAsync(customerConfig);

  if (!result.success) {
    const errors = result.error.errors;
    const configErrors = errors.map(error => {
      const key = error.path.join('.');

      const configError: ConfigError = {
        key,
        value: getPropertyByDotNotation(key, customerConfig),
        exampleData: getPropertyByDotNotation(key, DefaultCustomerConfig)
      };

      if (error.code === ZodIssueCode.invalid_type) {
        configError.receivedType = error.received;
        configError.expectedType = error.expected;
      }

      return configError;
    });

    console.group(
      '%cCustomer Config Errors',
      'color: #B22222; font-weight: bold;'
    );
    console.log(customerConfig);
    console.warn(`Customer Config is not configured properly. Below you can check which keys have invalid values.
For more information check out: https://docs.quantfol.io/internal/settings`);
    configErrors.forEach(error => {
      console.group(error.key);
      console.log(
        `Received Value: %c${error.value}`,
        'color: red; font-weight: bold;'
      );
      if (error.receivedType) {
        console.log(
          `Received Type: %c${error.receivedType}`,
          'color: red; font-weight: bold;'
        );
      }
      if (error.expectedType) {
        console.log(
          `Expected Type: %c${error.expectedType}`,
          'color: green; font-weight: bold;'
        );
      }
      const exampleData = error.exampleData;
      if (exampleData) {
        console.log(
          `Example Data: \n%c${YAML.stringify(exampleData, null, 2)}`,
          'color: lightgreen; font-weight: bold;'
        );
      }
      console.groupEnd();
    });
    console.groupEnd();
  } else {
    console.log(
      '%cCustomer Config is valid',
      'color: #32CD32; font-weight: bold;'
    );
  }
};
