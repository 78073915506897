import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const usePageStyles = createUseStyles({
  descriptionHeaderRowCell: {
    width: '30%'
  },
  yourConfigurationHeaderRowCell: {
    width: '30%'
  }
});
