import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { usePageStore as useAdvisoryPageStore } from '../../services/pageStore';
import { useReadSustainability } from './useReadSustainability';
import { useForm as useRoboAdviceForm } from 'features/roboAdvice/adviceSession/form/services/form';
import { useSessionStore } from 'features/roboAdvice/adviceSession/session/services/sessionStore';
import useReadCustomAttributesData from 'features/roboAdvice/adviceSession/shared/components/useReadCustomAttributesData';
import {
  PageStatuses,
  useReadDataListener
} from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { useAdviceSessionStore } from 'features/roboAdvice/adviceSession/shared/services/adviceSessionStore';
import {
  Goal,
  useGoalsStore
} from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { areProductAttributesEnabled } from 'features/roboAdvice/adviceSession/shared/services/selectors';
import {
  FundAnalysis,
  useSustainabilityStore
} from 'features/roboAdvice/adviceSession/sustainability';
import { getAlignmentCriteriaFields } from 'features/roboAdvice/adviceSession/sustainability/components/utils';
import { mapCustomAttributesToSustainabilityTable } from 'features/shared/analyticsComponents/sustainability/mapping';
import PreferenceCriteriaTable from 'features/shared/analyticsComponents/sustainability/preferenceCriteriaTable';
import StandardTable from 'features/shared/analyticsComponents/sustainability/standardTable';
import LoadingIndicator from 'features/shared/components/loadingIndicator/loadingIndicator';
import { FontWeights } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { getTranslation } from 'features/shared/utils/translations';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  table: {
    marginTop: 0
  },
  header: {
    fontWeight: FontWeights.medium,
    lineHeight: '19px',
    textAlign: 'left',
    marginBottom: 30,

    '&:not(:first-child)': {
      marginTop: 128
    }
  }
});

const SustainabilityTab = ({
  goal,
  isSelected
}: {
  goal: Goal;
  isSelected?: boolean;
}) => {
  const classes = useStyles();
  const i18n = useI18n();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const customerConfig = useCustomerConfig();
  const { sustainabilityData } = useSustainabilityStore();
  const { values } = useRoboAdviceForm();
  const { goals } = useGoalsStore();
  const {
    pageStatuses: { readSustainabilityModelPortfolioData: pageStatus }
  } = useAdvisoryPageStore();
  const readCustomAttributesData = useReadCustomAttributesData();

  const alignmentCriteriaFields = getAlignmentCriteriaFields({
    customerConfig
  });
  const selectedGoalsIds = useMemo(() => [goal.goalId], [goal.goalId]);
  const { customAttributesData } = useAdviceSessionStore();
  const sessionStoreState = useSessionStore.getState();
  const sustainabilityPreference =
    customerConfig.roboAdvice.sustainability.sustainabilityPreference.config;

  const areAnyProductAttributesEnabled = areProductAttributesEnabled(
    customerConfig.advisoryComponents.modelPortfolioSustainabilityTab
  );

  useEffect(() => {
    const namespaceId =
      goal.data.productPlatformNamespace ||
      sessionStoreState.defaultConfigNamespaceId;

    if (namespaceId && areAnyProductAttributesEnabled) {
      readCustomAttributesData(namespaceId);
    }
  }, [
    goal.data.productPlatformNamespace,
    goal.data.portfolio,
    readCustomAttributesData,
    sessionStoreState.defaultConfigNamespaceId,
    areAnyProductAttributesEnabled
  ]);

  const alignmentValues = useMemo(() => {
    const genericAssessmentAnswer =
      values.sustainability?.genericAssessment?.answer;
    if (genericAssessmentAnswer === false) {
      return [];
    }

    const alignmentCriteriaNames = alignmentCriteriaFields.map(({ name }) => {
      return name.replace('sustainability.alignmentCriteria.', '');
    });

    return alignmentCriteriaNames
      .filter(
        alignmentCriteria =>
          values.sustainability?.alignmentCriteria?.[alignmentCriteria]
      )
      .map(alignmentCriteria => ({
        name: alignmentCriteria,
        title: getTranslation(
          alignmentCriteriaFields.find(
            a =>
              a.name === `sustainability.alignmentCriteria.${alignmentCriteria}`
          )?.config.title
        ),
        preferenceScore:
          values.sustainability?.alignmentCriteria?.[alignmentCriteria]
      }));
  }, [
    alignmentCriteriaFields,
    values.sustainability?.alignmentCriteria,
    values.sustainability?.genericAssessment?.answer
  ]);

  const areAnyAlignmentCriteriaValuesPositive = alignmentValues.some(
    ({ preferenceScore }) => preferenceScore && preferenceScore > 0
  );

  const fundAnalysisData = useMemo(
    () =>
      sustainabilityData
        .filter(({ goalId }) => selectedGoalsIds.includes(goalId))
        .map(({ data }) => data.fundAnalysis)
        .flat()
        .reduce<FundAnalysis[]>((acc, curr) => {
          const index = acc.findIndex(
            ({ ticker, isin }) =>
              (isin && isin === curr.isin) || (ticker && ticker === curr.ticker)
          );
          if (index !== -1) {
            return acc;
          }
          return [...acc, curr];
        }, []),
    [selectedGoalsIds, sustainabilityData]
  );

  const totalValue = useMemo(
    () =>
      goals
        .filter(({ goalId }) => selectedGoalsIds.includes(goalId))
        .reduce(
          (acc, curr) =>
            acc + (curr.data.firstDeposit || curr.data.monthlyDeposit || 0),
          0
        ),
    [goals, selectedGoalsIds]
  );

  useReadDataListener(
    pageStatus,
    !!isSelected,
    useReadSustainability({ goal })
  );

  const tableCustomAttributes = mapCustomAttributesToSustainabilityTable({
    customAttributesConfig:
      customerConfig.advisoryComponents.modelPortfolioSustainabilityTab
  });

  if (pageStatus === PageStatuses.pending) {
    return <LoadingIndicator />;
  }

  if (pageStatus === PageStatuses.failed) {
    return <div>{i18n('roboAdvice.proposal.sustainability.noData')}</div>;
  }

  const isAlignmentCriteriaTableVisible =
    areAnyProductAttributesEnabled || areAnyAlignmentCriteriaValuesPositive;
  const isPreferenceCriteriaTableVisible =
    !!values.sustainability?.preferenceCriteria?.length;

  return (
    <>
      {!isAlignmentCriteriaTableVisible &&
        !isPreferenceCriteriaTableVisible &&
        i18n(
          'roboAdvice.sustainability.investorHadNoSustainabilityPreferences'
        )}

      {(areAnyProductAttributesEnabled ||
        areAnyAlignmentCriteriaValuesPositive) && (
        <>
          <div className={classes.header}>
            {i18n('roboAdvice.proposal.sustainabilityAssessmentAlignment')}
          </div>

          <StandardTable
            goals={[goal]}
            selectedGoalsIds={selectedGoalsIds}
            cultureCode={cultureCode}
            alignmentValues={alignmentValues}
            fundAnalysisData={fundAnalysisData}
            sustainabilityData={sustainabilityData}
            totalValue={totalValue}
            tableClassName={classes.table}
            tableCustomAttributes={tableCustomAttributes}
            customAttributesData={customAttributesData}
          />
        </>
      )}

      {!!values.sustainability?.preferenceCriteria?.length && (
        <>
          <div className={classes.header}>
            {i18n(
              'roboAdvice.proposal.sustainability.preferenceCriteriaAlignment'
            )}
          </div>

          <PreferenceCriteriaTable
            fundAnalysisData={fundAnalysisData}
            preferenceCriteria={values.sustainability?.preferenceCriteria}
            sustainabilityPreference={sustainabilityPreference}
          />
        </>
      )}
    </>
  );
};

export default SustainabilityTab;
