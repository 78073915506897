import axios from 'axios';
import { isEmpty } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { updateInvestorInformation } from '../../main/api';
import { clientForm, ClientFormValues } from '../../main/services/clientForm';
import { useClientPageStore } from '../../main/services/clientPageStore';
import { ClientParams } from '../../shared/types';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { InvestorData } from 'features/shared/types/investor';
import { omitNilProps } from 'features/shared/utils/omitNilProps';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export function useSaveKYC() {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const i18n = useI18n();
  const dispatch = useDispatch();
  const { clientId } = useParams<ClientParams>();

  const saveKYC = async () => {
    const { errors, values } = clientForm.getState();
    const kycErrors = errors?.kyc;
    const clientPageStore = useClientPageStore.getState();

    if (kycErrors && !isEmpty(kycErrors)) {
      clientForm.batch(() => {
        Object.keys(kycErrors).forEach(key => {
          clientForm.mutators.setFieldTouched(`kyc.${key}`, true);
        });
        clientForm.mutators.setFieldTouched(
          `kyc.signatureRight[0].email`,
          true
        );
        clientForm.mutators.setFieldTouched(`kyc.ubo[0].ownership`, true);
        clientForm.mutators.setFieldTouched(
          `kyc.automaticClientClassification.reclassificationRequestDocumentation`,
          true
        );
        clientForm.mutators.setFieldTouched(
          `kyc.automaticClientClassification.investorType`,
          true
        );
      });
      return;
    }

    try {
      clientPageStore.setIsUpdateClientPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        undefined
      );

      const payloadInvestorKYC = {
        kyc: omitNilProps<InvestorData['kyc'], ClientFormValues['kyc']>(
          values.kyc
        ),
        contacts: values.clientInformation?.contacts || []
      };

      await updateInvestorInformation(
        accessToken,
        payloadInvestorKYC,
        clientId
      );

      clientForm.initialize(values);

      clientPageStore.setIsUpdateClientPending(false);

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n('roboAdvice.client.updateClientSuccessMessage'),
          type: NotificationTypes.success
        })
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        clientPageStore.setIsUpdateClientPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.client.updateClientErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return saveKYC;
}
