import classNames from 'classnames';
import React from 'react';
import flattenChildren from 'react-keyed-flatten-children';

import { useTable } from './context.js';

const TableBody = ({ children, cellsNumber, noDataTitle }) => {
  const { classes, noDataBodyRowCellClassName, isSelectionShown } = useTable();
  const isEmpty =
    React.Children.toArray(children).filter(child =>
      React.isValidElement(child)
    ).length === 0;
  cellsNumber = isSelectionShown ? cellsNumber + 1 : cellsNumber;

  return (
    <tbody>
      {isEmpty && (
        <tr
          className={classNames(classes.bodyRow, classes.noData, classes.even)}
        >
          <td
            className={classNames(
              classes.bodyRowCell,
              noDataBodyRowCellClassName
            )}
            colSpan={cellsNumber}
          >
            {noDataTitle}
          </td>
        </tr>
      )}
      {!isEmpty &&
        flattenChildren(children).map((c, index) =>
          React.cloneElement(c, { index: c.props.index ?? index })
        )}
    </tbody>
  );
};

export default TableBody;
