import { getTimeZones } from '@vvo/tzdb';
import { useMemo } from 'react';
import { UseFieldConfig, useField } from 'react-final-form';

import Checkbox from 'features/shared/components/checkbox';
import Select from 'features/shared/components/select';
import { Spacing } from 'features/shared/constants/spacing';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  checkboxContainer: {
    display: 'flex',
    gap: Spacing.spacing01,
    alignItems: 'center',
    marginBottom: Spacing.spacing01,
    cursor: 'pointer'
  },
  label: {
    cursor: 'pointer'
  }
}));

type Props = {
  name: string;
  autoDetectName: string;
  className?: string;
  ariaLabelledBy?: string;
  fieldConfig?: UseFieldConfig<unknown>;
};

const FinalFormTimezoneDropdown = ({
  className,
  ariaLabelledBy,
  name,
  fieldConfig,
  autoDetectName
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const {
    input: { value, onChange }
  } = useField(name, fieldConfig);
  const {
    input: { value: autoDetectValue, onChange: onAutoDetectChange }
  } = useField(autoDetectName, fieldConfig);

  const timeZones = useMemo(() => {
    const newestTimeZones = getTimeZones();
    newestTimeZones.sort((a, b) => a.rawOffsetInMinutes - b.rawOffsetInMinutes);
    return newestTimeZones;
  }, []);
  const options = timeZones.map(timeZone => ({
    label: `UTC ${timeZone.rawFormat} - ${timeZone.name}`,
    value: timeZone.name
  }));

  const detectedUserTimezoneName =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  const detectedUserTimezoneValue = options.find(
    timeZone => timeZone.value === detectedUserTimezoneName
  )?.value;

  return (
    <div className={className}>
      <div
        onClick={() => {
          if (!autoDetectValue) {
            onChange(detectedUserTimezoneValue);
          }
          onAutoDetectChange(!autoDetectValue);
        }}
        className={classes.checkboxContainer}
      >
        <Checkbox
          width={20}
          height={20}
          checked={autoDetectValue}
          readOnly
          name={autoDetectName}
        />
        <label htmlFor={autoDetectName} className={classes.label}>
          {i18n('profile.setTimeZoneAutomatically')}
        </label>
      </div>
      <Select
        ariaLabelledBy={ariaLabelledBy}
        id="timezone"
        options={options}
        isSearchable={true}
        value={autoDetectValue ? detectedUserTimezoneValue : value}
        onChange={autoDetectValue ? undefined : onChange}
        isDisabled={autoDetectValue}
      />
    </div>
  );
};

export default FinalFormTimezoneDropdown;
