export const REQUEST_INFO_DEFAULTS = {
  data: {},
  status: null,
  loading: false,
  error: false
};

export const CRM_LOGIN_PARAM_NAME = 'crmlogin';

export const ViewPageOptions = {
  clientInformation: 'client-information',
  adviceSessionOverview: 'advice-session-overview',
  adviceSession: 'advice-session',
  kyc: 'kyc'
} as const;
