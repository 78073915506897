import { useSelector } from 'react-redux';

import { PortfolioIds } from '../../advisory/services/constants';
import { useReadDataListener } from '../../shared/components/useReadDataListener';
import { usePageStore } from '../services/pageStore';
import {
  useReadExpectedValuePortfoliosChartData,
  useExpectedValuePortfoliosChartStore
} from './useReadExpectedValuePortfoliosChartData';
import ExpectedValuePortfoliosChartVisual from 'features/shared/analyticsComponents/expectedValuePortfoliosChart/visual';
import sessionSelectors from 'features/shared/services/session/selectors';

const ExpectedValuePortfoliosChartCard = ({
  isSelected,
  selectedRisks,
  compareWithExistingPortfolio
}) => {
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const expectedValuePortfoliosChartStore =
    useExpectedValuePortfoliosChartStore();
  const { pageStatuses } = usePageStore();

  useReadDataListener(
    pageStatuses.readExpectedValuePortfoliosChartData,
    isSelected,
    useReadExpectedValuePortfoliosChartData(
      selectedRisks,
      compareWithExistingPortfolio
    )
  );

  const selectedGoalIds = [
    ...selectedRisks.map(r => r.risk),
    compareWithExistingPortfolio ? PortfolioIds.existingPortfolio : null
  ].filter(val => !!val);

  return (
    <ExpectedValuePortfoliosChartVisual
      expectedValuePortfoliosChartData={
        expectedValuePortfoliosChartStore.chartData
      }
      selectedGoalsIds={selectedGoalIds}
      cultureCode={cultureCode}
    />
  );
};

export default ExpectedValuePortfoliosChartCard;
