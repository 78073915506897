import { createUseExpectedPathPortfoliosChartStore } from '@quantfoliorepo/ui-components';
import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UseBoundStore } from 'zustand';

import { PortfolioIds } from '../../advisory/services/constants';
import { DEFAULT_CONFIDENCE } from '../../proposal/constants';
import { useExistingPortfolio } from '../../proposal/services/selectors';
import { useSessionStore } from '../../session/services/sessionStore';
import { createAnalyzeForecast } from '../../shared/api';
import { PageStatuses } from '../../shared/components/useReadDataListener';
import { usePageStore as useRiskScorePageStore } from '../services/pageStore';
import { usePortfolioChartStore } from './useReadPortfolioChartData';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { useTheme } from 'features/sharedModules/styles/components/styles';

export const useExpectedPathPortfoliosChartStore: UseBoundStore<any> =
  createUseExpectedPathPortfoliosChartStore();

const useReadExpectedPathPortfoliosChartData = (
  selectedRisks,
  compareWithExistingPortfolio: boolean
) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const i18n = useI18n();
  const portfolioChartStore = usePortfolioChartStore();
  const existingPortfolioData = useExistingPortfolio();
  const { categories } = useSessionStore();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  const readExpectedPathPortfoliosChartData = async () => {
    const riskScorePageStore = useRiskScorePageStore.getState();
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    try {
      riskScorePageStore.setPageStatus(
        'readExpectedPathPortfoliosChartData',
        PageStatuses.pending
      );

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const timeHorizon = 10;
      const initialDeposit = 100_000;
      const monthlyDeposit = 0;

      const portfoliosData: {
        forecast: {
          date: string;
          lower_bound: number;
          month: number;
          upper_bound: number;
          value: number;
          year: number;
        }[];
        id: string;
        title: string;
        color: string;
        timeHorizon: number;
      }[] = [];

      for (const selectedRisk of selectedRisks) {
        const portfolio = portfolioChartStore
          .getChartData(selectedRisk.risk, false)
          .categorySelectionPortfolio.map(({ id, weight }) => ({ id, weight }));

        const createAnalyzeForecastResponse = await createAnalyzeForecast(
          qAuthAccessToken,
          cancelTokenSource.token,
          {
            confidence_interval: DEFAULT_CONFIDENCE,
            initial_deposit: initialDeposit,
            monthly_saving: monthlyDeposit,
            portfolio,
            time_horizon: timeHorizon
          }
        );

        portfoliosData.push({
          forecast: createAnalyzeForecastResponse.data.forecast,
          id: selectedRisk.risk,
          title: selectedRisk.name,
          color: selectedRisk.color,
          timeHorizon
        });
      }

      if (compareWithExistingPortfolio && existingPortfolioData) {
        const existingPortfolio = existingPortfolioData?.[1].map(
          ({ name, weight }) => ({
            category_id: categories.find(
              ({ SubAssetClass }) => SubAssetClass === name
            )?.CategoryId,
            weight
          })
        );

        const createAnalyzeForecastResponse = await createAnalyzeForecast(
          qAuthAccessToken,
          cancelTokenSource.token,
          {
            confidence_interval: DEFAULT_CONFIDENCE,
            initial_deposit: initialDeposit,
            monthly_saving: monthlyDeposit,
            portfolio: existingPortfolio,
            time_horizon: timeHorizon
          }
        );

        portfoliosData.push({
          forecast: createAnalyzeForecastResponse.data.forecast,
          id: PortfolioIds.existingPortfolio,
          title: i18n('roboAdvice.advisory.portfolio.existingPortfolio'),
          color: theme.chartPortfolioColors[3],
          timeHorizon
        });
      }

      useExpectedPathPortfoliosChartStore
        .getState()
        .setChartData(portfoliosData);

      riskScorePageStore.setPageStatus(
        'readExpectedPathPortfoliosChartData',
        PageStatuses.succeed
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        useExpectedPathPortfoliosChartStore.getState().reset();
        riskScorePageStore.setPageStatus(
          'readExpectedPathPortfoliosChartData',
          PageStatuses.failed
        );

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.proposal.readDataErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readExpectedPathPortfoliosChartData;
};

export default useReadExpectedPathPortfoliosChartData;
