import classNames from 'classnames';
import * as R from 'ramda';

import Icon from 'features/shared/components/icon/index.js';
import { OrderDirections } from 'features/shared/constants/table';

const HeaderCell = ({
  cell,
  orderKey,
  orderDirection,
  changeOrder,
  classes
}) => {
  return (
    <th
      className={classNames(
        { [classes.headerRowCell]: R.isNil(cell.component) },
        {
          [classes.headerRowComponentCell]: !R.isNil(cell.component)
        },
        cell.className
      )}
      colSpan={cell.colSpan}
    >
      {!R.isNil(cell.component) && cell.component}
      {R.isNil(cell.component) && (
        <a
          className={classNames(classes.headerRowCellContent, {
            [classes.sortable]: cell.isOrderable
          })}
          onClick={e => {
            e.preventDefault();

            if (cell.isOrderable) {
              let newOrderDirection = OrderDirections.asc;

              if (
                orderKey === cell.orderKey &&
                orderDirection === OrderDirections.asc
              ) {
                newOrderDirection = OrderDirections.desc;
              }

              if (
                orderKey === cell.orderKey &&
                orderDirection === OrderDirections.desc
              ) {
                newOrderDirection = OrderDirections.asc;
              }

              changeOrder(cell.orderKey, newOrderDirection);
            }
          }}
        >
          <span
            className={classNames(
              classes.columnTitle,
              classes.additionalColumnTitleClassName
            )}
          >
            {cell.title}
          </span>
          {cell.isOrderable &&
            orderKey === cell.orderKey &&
            orderDirection === OrderDirections.asc && (
              <Icon className={classes.sortingIcon} type="arrow_drop_up" />
            )}
          {cell.isOrderable &&
            orderKey === cell.orderKey &&
            orderDirection === OrderDirections.desc && (
              <Icon className={classes.sortingIcon} type="arrow_drop_down" />
            )}
        </a>
      )}
    </th>
  );
};

export default HeaderCell;
