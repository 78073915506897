import classNames from 'classnames';
import { useState } from 'react';
import { useElementSize } from 'usehooks-ts';

import { useHandleCustomPortfolio } from './useHandleCustomPortfolio';
import { useAvailablePortfolios } from 'features/roboAdvice/adviceSession/shared/services/availablePortfoliosSelectors';
import Button, { ButtonType } from 'features/shared/components/button';
import Confirmation from 'features/shared/components/modal/confirmation';
import Modal from 'features/shared/components/modal/index';
import { useModalBodyMaxDimensions } from 'features/shared/components/modal/useModalBodyMaxDimensions';
import Scrollbars from 'features/shared/components/scrollbars';
import { Spacing } from 'features/shared/constants/spacing';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const legendMarginBottom = Spacing.spacing01;

const useStyles = createUseStyles(theme => ({
  editIcon: {
    cursor: 'pointer'
  },
  alignRight: {
    textAlign: 'right'
  },
  templateList: {
    padding: 0,
    margin: 0,
    border: 'none',
    display: 'flex',
    flexDirection: 'column',

    '& legend': {
      marginBottom: legendMarginBottom
    }
  },
  template: {
    paddingLeft: Spacing.spacing01,
    height: Spacing.spacing04,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    border: `2px solid transparent`,

    '&:nth-child(odd)': {
      backgroundColor: theme.itemBackgroundColor_1
    },

    '&:nth-child(even)': {
      backgroundColor: theme.itemBackgroundColor_2
    },

    '&:hover, &:focus-within': {
      backgroundColor: theme.hoverInputStrokeColor
    },

    '& input': {
      position: 'fixed',
      opacity: 0,
      pointerEvents: 'none'
    }
  },
  templateSelected: {
    borderColor: theme.accentColor
  }
}));

type Props = {
  isOpen: boolean;
  onClose: () => void;
  goalId: string;
};

const RiskTemplateModal = ({ isOpen, onClose, goalId }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const riskTemplates: { key: string; title: string }[] =
    useAvailablePortfolios(goalId);
  const { handleChangeRiskTemplate } = useHandleCustomPortfolio();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedRiskTemplate, setSelectedRiskTemplate] = useState<
    string | null
  >(null);
  const { modalBodyMaxHeight, headerRef, footerRef } =
    useModalBodyMaxDimensions();
  const [legendRef, { height: legendHeight }] = useElementSize();

  return (
    <>
      <Modal
        footer={
          <>
            <Button onClick={() => onClose()} buttonType={ButtonType.secondary}>
              {i18n('roboAdvice.navigation.close')}
            </Button>

            <Button
              disabled={!selectedRiskTemplate}
              onClick={() => setIsConfirmationModalOpen(true)}
            >
              {i18n('shared.add')}
            </Button>
          </>
        }
        header={i18n(
          'roboAdvice.advisory.customPortfolio.riskTemplatesModalHeader'
        )}
        isOpen={isOpen}
        onRequestClose={() => {
          onClose();
          setSelectedRiskTemplate(null);
        }}
        headerRef={headerRef}
        footerRef={footerRef}
      >
        <fieldset className={classes.templateList}>
          <legend ref={legendRef}>
            {i18n('roboAdvice.advisory.customPortfolio.selectATemplate')}
          </legend>

          <Scrollbars
            autoHeightMax={
              modalBodyMaxHeight - (legendHeight + legendMarginBottom)
            }
          >
            {riskTemplates.map(({ key, title }) => (
              <label
                className={classNames(classes.template, {
                  [classes.templateSelected]: key === selectedRiskTemplate
                })}
                key={key}
              >
                {title}
                <input
                  type="radio"
                  name="riskTemplate"
                  onChange={() => setSelectedRiskTemplate(key)}
                  value={key}
                />
              </label>
            ))}
          </Scrollbars>
        </fieldset>
      </Modal>

      <Confirmation
        header={i18n(
          'roboAdvice.advisory.customPortfolio.overrideAllocationConfirmationHeader'
        )}
        isOpen={isConfirmationModalOpen}
        onConfirm={() => {
          handleChangeRiskTemplate({ goalId, selectedRiskTemplate });
          setIsConfirmationModalOpen(false);
          onClose();
          setSelectedRiskTemplate(null);
        }}
        onDecline={() => setIsConfirmationModalOpen(false)}
      >
        <div>
          {i18n(
            'roboAdvice.advisory.customPortfolio.overrideAllocationConfirmationBody'
          )}
        </div>
      </Confirmation>
    </>
  );
};

export default RiskTemplateModal;
