import axios from 'axios';
import * as R from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHolisticViewStore } from '../../../services/holisticViewStore';
import { HorizonConfig } from 'defaults/defaultTimeHorizonConfig';
import { useExpectedPathChartStore } from 'features/roboAdvice/adviceSession/expectedPathChart';
import { usePortfolioChartStore } from 'features/roboAdvice/adviceSession/portfolioChart';
import { DEFAULT_CONFIDENCE } from 'features/roboAdvice/adviceSession/proposal/constants';
import { getHorizonAnalysisProjectionYearsValue } from 'features/roboAdvice/adviceSession/proposal/services/selectors';
import { createAnalyzeForecast } from 'features/roboAdvice/adviceSession/shared/api';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { getCurrentDate } from 'features/roboAdvice/shared/utils';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useReadExpectedPathChartData() {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();
  const { timeHorizonConfig }: { timeHorizonConfig: HorizonConfig } =
    useCustomerConfig();
  const portfolioChartStore = usePortfolioChartStore();

  const cancelTokenSourceRef = React.useRef<any>();
  const readExpectedPathChartData = async () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;
    const expectedPathChartStore = useExpectedPathChartStore.getState();
    const { chartData } = usePortfolioChartStore.getState();

    try {
      useHolisticViewStore
        .getState()
        .setProposalSectionStatus(
          'readExpectedPathChartData',
          PageStatuses.pending
        );

      const goals = useHolisticViewStore
        .getState()
        .availableClients.filter(client => client.isSelected)
        .map(client => client.goals || [])
        .flat();

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const currentDate = getCurrentDate();

      for (const goal of goals) {
        const portfolio = portfolioChartStore
          .getChartData(goal.goalId, goal.data.isPortfolioCustom)
          .categorySelectionPortfolio.map(({ id, weight }) => ({ id, weight }));

        const timeHorizon = getHorizonAnalysisProjectionYearsValue({
          timeHorizonConfig,
          timeHorizon: goal.data.timeHorizon
        });

        const createAnalyzeForecastResponse = await createAnalyzeForecast(
          qAuthAccessToken,
          cancelTokenSource.token,
          {
            confidence_interval: DEFAULT_CONFIDENCE,
            initial_deposit: goal.data.firstDeposit,
            monthly_saving: goal.data.monthlyDeposit,
            portfolio,
            time_horizon: timeHorizon
          }
        );

        const data = {
          forecast: createAnalyzeForecastResponse.data.forecast,
          timeHorizon,
          currentDate,
          goalId: goal.goalId
        };

        expectedPathChartStore.addGoalData(data);
      }

      useHolisticViewStore
        .getState()
        .setProposalSectionStatus(
          'readExpectedPathChartData',
          PageStatuses.succeed
        );
    } catch (error) {
      if (!axios.isCancel(error)) {
        expectedPathChartStore.resetChartData();
        useHolisticViewStore
          .getState()
          .setProposalSectionStatus(
            'readExpectedPathChartData',
            PageStatuses.failed
          );

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.proposal.readDataErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readExpectedPathChartData;
}
