import { useEffect, useMemo, useState } from 'react';

import { useAutomaticClientClassificationStore } from '../../services/automaticClientClassificationStore';
import { InvestorTypes } from './constants';
import {
  clientForm,
  ClientFormValues,
  useClientForm
} from 'features/roboAdvice/client/main/services/clientForm';
import Button, { ButtonType } from 'features/shared/components/button';
import Checkbox from 'features/shared/components/checkbox';
import Modal from 'features/shared/components/modal';
import { useModalBodyMaxDimensions } from 'features/shared/components/modal/useModalBodyMaxDimensions';
import Radios from 'features/shared/components/radios';
import Scrollbars from 'features/shared/components/scrollbars';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const useStyles = createUseStyles(theme => ({
  checkboxItem: {
    display: 'flex',
    gap: 12,
    cursor: 'pointer',

    '& + &': {
      marginTop: Spacing.spacing01
    }
  },
  modalButtons: {
    display: 'flex',
    gap: Spacing.spacing01
  },
  modalDescription: {
    marginBottom: Spacing.spacing02,
    color: theme.secondaryColor
  },
  checkboxText: {
    position: 'relative',
    lineHeight: Typography.heading1.lineHeight
  },
  checklist: {
    marginBottom: Spacing.spacing01
  },
  radioItem: {
    '& + &': {
      marginTop: '13px'
    }
  }
}));

const ChecklistModal = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const { modalBodyMaxHeight, headerRef, footerRef } =
    useModalBodyMaxDimensions();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [simpleModalValue, setSimpleModalValue] = useState<
    string | undefined
  >();
  const { selectedInvestorTypeItems } = useAutomaticClientClassificationStore();
  const {
    roboAdvice: {
      clientInformation: {
        automaticClientClassification: {
          checklistModal: { isSimpleModalEnabled = false } = {}
        } = {}
      }
    }
  } = useCustomerConfig();
  const { isChecklistModalOpen, setIsChecklistModalOpen } =
    useAutomaticClientClassificationStore();
  const { values } = useClientForm();
  const investorType = values?.kyc?.automaticClientClassification?.investorType;

  useEffect(() => {
    if (!isSimpleModalEnabled) {
      setSelectedItems([]);
    } else {
      setSimpleModalValue(investorType);
    }
  }, [isChecklistModalOpen]);

  const handleCloseChecklistModal = () => {
    clientForm.change(
      'kyc.automaticClientClassification.checklistModal' as keyof ClientFormValues,
      undefined
    );
    setIsChecklistModalOpen(false);
  };

  const handleClickChecklistItem = (item: string) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(oldItems => oldItems.filter(i => i !== item));
    } else {
      setSelectedItems(oldItems => [...oldItems, item]);
    }
  };

  const handleSubmit = () => {
    let newInvestorType = simpleModalValue;
    if (!isSimpleModalEnabled) {
      newInvestorType =
        investorType === InvestorTypes.professionalInvestor
          ? InvestorTypes.nonProfessionalInvestor
          : InvestorTypes.professionalInvestor;
    }

    clientForm.batch(() => {
      clientForm.change(
        'kyc.automaticClientClassification.isDecisionOverwritten' as any,
        true
      );
      clientForm.change(
        'kyc.automaticClientClassification.investorType' as any,
        newInvestorType
      );
    });
    handleCloseChecklistModal();
  };

  const headerText = useMemo(() => {
    if (!isSimpleModalEnabled) {
      if (investorType === InvestorTypes.professionalInvestor) {
        return i18n(
          'roboAdvice.kyc.automaticClientClassification.nonProfessionalChecklist.header'
        );
      } else {
        return i18n(
          'roboAdvice.kyc.automaticClientClassification.professionalChecklist.header'
        );
      }
    }

    return i18n(
      'roboAdvice.kyc.automaticClientClassification.simpleModal.header'
    );
  }, [isSimpleModalEnabled, i18n, investorType]);

  return (
    <Modal
      footer={
        <div className={classes.modalButtons}>
          <Button
            disabled={false}
            onClick={handleCloseChecklistModal}
            buttonType={ButtonType.secondary}
          >
            {i18n('shared.cancel')}
          </Button>

          <Button
            disabled={
              (!isSimpleModalEnabled &&
                selectedItems.length !== selectedInvestorTypeItems.length) ||
              (isSimpleModalEnabled && simpleModalValue === investorType)
            }
            onClick={handleSubmit}
          >
            {i18n('shared.change')}
          </Button>
        </div>
      }
      header={headerText}
      isOpen={isChecklistModalOpen}
      onRequestClose={handleCloseChecklistModal}
      headerRef={headerRef}
      footerRef={footerRef}
      style={{
        content: {
          maxWidth: '700px'
        }
      }}
    >
      <Scrollbars autoHeightMax={modalBodyMaxHeight}>
        {!isSimpleModalEnabled ? (
          <>
            <div className={classes.modalDescription}>
              {investorType === InvestorTypes.professionalInvestor
                ? i18n(
                    'roboAdvice.kyc.automaticClientClassification.nonProfessionalChecklist.description'
                  )
                : i18n(
                    'roboAdvice.kyc.automaticClientClassification.professionalChecklist.description'
                  )}
            </div>
            <div className={classes.checklist}>
              {selectedInvestorTypeItems?.map(item => (
                <label
                  className={classes.checkboxItem}
                  htmlFor={item}
                  onChange={() => handleClickChecklistItem(item)}
                  key={item}
                >
                  <Checkbox
                    width={22.5}
                    height={22.5}
                    id={item}
                    checked={selectedItems.includes(item)}
                    onChange={() => {}}
                  />
                  <div className={classes.checkboxText}>{item}</div>
                </label>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className={classes.modalDescription}>
              {i18n(
                'roboAdvice.kyc.automaticClientClassification.simpleModal.description'
              )}
            </div>
            <div className={classes.checklist}>
              <Radios
                items={Object.keys(InvestorTypes).map(type => ({
                  activeValue: type,
                  label: i18n(
                    `roboAdvice.kyc.automaticClientClassification.${type}`
                  )
                }))}
                onChange={value => setSimpleModalValue(value as string)}
                value={simpleModalValue || null}
                className={classes.radioItem}
              />
            </div>
          </>
        )}
      </Scrollbars>
    </Modal>
  );
};

export default ChecklistModal;
