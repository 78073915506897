import queryString from 'query-string';
import * as R from 'ramda';

import config from 'config/index.js';

const stringifyQuery = query =>
  R.isNil(query) ? '' : `?${queryString.stringify(query)}`;

// TODO: Make routeTemplates composable from modules.
// In each module try to use only own routes.
// Try to use full composed config only for global logic in author root.
const routeTemplatesInternal = [
  {
    id: 'home',
    config: '/',
    build: () => '/',
    children: R.reject(R.isNil, [
      {
        id: 'login',
        config: '/login',
        build: () => '/login',
        title: 'title.deepAlphaLogin'
      },
      {
        id: 'loginRedirect',
        config: '/redirect',
        build: () => '/redirect',
        title: 'title.deepAlphaLogin'
      },
      {
        id: 'loginSilentRenew',
        config: '/silent-renew',
        build: () => '/silent-renew',
        title: 'title.deepAlphaLogin'
      },
      {
        id: 'customers',
        config: '/customers',
        build: () => '/customers',
        title: 'title.deepAlphaCustomers',
        children: [
          {
            id: 'customersTable',
            config: '/customers/customers-table',
            build: () => '/customers/customers-table'
          },
          {
            id: 'customerManagement',
            config: '/customers/:id/management',
            build: id => `/customers/${id}/management`
          }
        ]
      },
      {
        id: 'userProfile',
        config: '/user-profile/:userId',
        build: userId => `/user-profile/${userId}`,
        title: 'title.deepAlphaUserProfile',
        children: [
          {
            id: 'userProfileBasic',
            config: '/user-profile/:userId/basic',
            build: userId => `/user-profile/${userId}/basic`
          },
          {
            id: 'userProfileSubscription',
            config: '/user-profile/:userId/subscription',
            build: userId => `/user-profile/${userId}/subscription`
          }
        ]
      },
      {
        id: 'roboAdvice',
        config: '/robo-advice',
        build: () => '/robo-advice',
        title: 'title.deepAlphaRoboAdvice',
        children: [
          {
            id: 'roboAdviceClientsList',
            config: '/robo-advice/clients/list/:pageId',
            build: (pageId, query) =>
              `/robo-advice/clients/list/${pageId}${stringifyQuery(query)}`
          },
          {
            id: 'roboAdviceClient',
            config: '/robo-advice/clients/view/:clientType?/:clientId/:pageId',
            build: (clientType, clientId, pageId, query) =>
              `/robo-advice/clients/view/${
                clientType ? clientType + '/' : ''
              }${clientId}/${pageId}${stringifyQuery(query)}`
          },
          {
            id: 'roboAdviceOrderExecution',
            config:
              '/robo-advice/:clientType/:clientId/order-execution/:adviceSessionId/:pageId',
            build: (clientType, clientId, adviceSessionId, pageId) =>
              `/robo-advice/${clientType}/${clientId}/order-execution/${adviceSessionId}/${pageId}`
          },
          {
            id: 'roboAdviceAdviceSession',
            config:
              '/robo-advice/:clientType/:clientId/:adviceSessionId/:pageId',
            build: (clientType, clientId, adviceSessionId, pageId) =>
              `/robo-advice/${clientType}/${clientId}/${adviceSessionId}/${pageId}`
          }
        ]
      },
      {
        id: 'tools',
        config: '/tools/:pageId',
        build: (pageId = '', query) =>
          `/tools/${pageId}${stringifyQuery(query)}`,
        title: 'title.deepAlphaTools',
        children: [
          {
            id: 'toolsHolisticView',
            config:
              '/tools/holistic-view/:clientType?/:clientId?/:adviceSessionId?',
            build: (
              clientType?: string,
              clientId?: string,
              adviceSessionId?: string
            ) => {
              if (clientType && clientId && adviceSessionId) {
                return `/tools/holistic-view/${clientType}/${clientId}/${adviceSessionId}`;
              }

              return '/tools/holistic-view/';
            }
          },
          {
            id: 'toolsSavingCalculator',
            config: '/tools/saving-calculator/',
            build: () => `/tools/saving-calculator/`
          }
        ]
      },
      {
        id: 'admin',
        config: '/admin',
        build: () => '/admin',
        title: 'title.admin',
        children: [
          {
            id: 'adminManageCustomer',
            config: '/admin/manage-customer',
            build: () => '/admin/manage-customer'
          },
          {
            id: 'adminTrashCan',
            config: '/admin/trash-can/:pageId',
            build: pageId => `/admin/trash-can/${pageId}`
          },
          {
            id: 'appAdmin',
            config: '/admin/app-admin/:configLocation/:configType?',
            build: (configLocation, configType) =>
              `/admin/app-admin/${configLocation}/${
                configType ? configType : ''
              }`
          }
        ]
      }
    ])
  }
];

// TODO: add validate function that verify all child routes has parent route as substring at the start of string

const initRouteTemplateTitles = R.curry((defaultTitle, routeTemplates) => {
  return R.map(routeTemplate => {
    const title = routeTemplate.title || defaultTitle;
    return {
      ...routeTemplate,
      title,
      children: R.isNil(routeTemplate.children)
        ? routeTemplate.children
        : initRouteTemplateTitles(title, routeTemplate.children)
    };
  }, routeTemplates);
});

const flattenRouteTemplates = routeTemplates => {
  let flattenedRouteTemplates: any[] = [];

  routeTemplates.forEach(rt => {
    flattenedRouteTemplates.push(rt);
    if (rt.children != null) {
      flattenedRouteTemplates = flattenedRouteTemplates.concat(
        flattenRouteTemplates(rt.children)
      );
    }
  });

  return flattenedRouteTemplates;
};

const reduceRouteTemplates = routeTemplates => {
  return R.reduce<any, any>(
    (reducedRouteTemplates, rt) => {
      return {
        ...reducedRouteTemplates,
        [rt.id]: {
          ...rt,
          children:
            rt.children != null ? flattenRouteTemplates(rt.children) : []
        }
      };
    },
    {},
    routeTemplates
  );
};

export const routeTemplates = initRouteTemplateTitles(
  'title.deepAlpha',
  routeTemplatesInternal
);

const routeTemplatesDefault: any = R.pipe(
  flattenRouteTemplates,
  reduceRouteTemplates
)(routeTemplates);

export default routeTemplatesDefault;
