import classNames from 'classnames';
import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

import ConfigurableLabel from 'features/shared/components/configurableLabel/';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { formatNumber } from 'features/shared/utils/number';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: theme.boxBackgroundColor_2
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${Spacing.spacing02}px ${Spacing.spacing02}px 22px ${Spacing.spacing02}px`
  },
  header: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    fontWeight: FontWeights.medium
  },
  headerButton: {
    display: 'flex',
    alignContent: 'center'
  },
  body: {
    padding: `0 ${Spacing.spacing02}px`
  },
  footer: {
    padding: Spacing.spacing02,
    display: 'flex',
    justifyContent: 'space-between'
  },
  emptyFooter: {
    padding: `0 ${Spacing.spacing02}px ${Spacing.spacing02}px`
  },
  totalLabel: {
    marginRight: Spacing.spacing02
  },
  totalNumber: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    fontWeight: FontWeights.medium,
    marginRight: Spacing.spacing03
  },
  footerSeparator: {
    height: '1px',
    backgroundColor: theme.disabledTextColor,
    margin: `${Spacing.spacing02}px  ${Spacing.spacing02}px 0 ${Spacing.spacing02}px`
  },
  hasError: {
    border: `2px solid ${theme.errorNegativeColor}`
  }
}));

type Props = {
  header: string;
  headerButton?: React.ReactNode;
  total?: {
    label: string;
    value: number | null;
  };
  doShowFooterSeparator?: boolean;
  doShowEmptyFooter?: boolean;
  className?: string;
  bodyClassName?: string;
  children: React.ReactNode;
  required?: boolean;
  elementId?: string;
  hasError?: boolean;
  maxFractionDigits?: number;
  minFractionDigits?: number;
};

const Widget = ({
  header = '',
  headerButton,
  total,
  doShowFooterSeparator = false,
  doShowEmptyFooter,
  className,
  bodyClassName,
  children,
  required = false,
  elementId,
  hasError,
  maxFractionDigits,
  minFractionDigits
}: Props) => {
  const classes = useStyles();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );

  return (
    <div
      className={classNames(classes.root, className, {
        [classes.hasError]: hasError
      })}
      id={elementId}
    >
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          <ConfigurableLabel required={required} label={header} />
        </div>
        {headerButton && (
          <div className={classes.headerButton}>{headerButton}</div>
        )}
      </div>
      <div className={classNames(classes.body, bodyClassName)}>{children}</div>
      {!R.isNil(total) && (
        <>
          {doShowFooterSeparator && <div className={classes.footerSeparator} />}
          <div className={classes.footer}>
            <div className={classes.totalLabel}>{total.label}</div>
            <div className={classes.totalNumber}>
              {!R.isNil(total.value)
                ? formatNumber(
                    cultureCode,
                    total.value,
                    minFractionDigits || 0,
                    maxFractionDigits || 0
                  )
                : null}
            </div>
          </div>
        </>
      )}
      {R.isNil(total) && doShowEmptyFooter && (
        <div className={classes.emptyFooter} />
      )}
    </div>
  );
};

export default Widget;
