import * as R from 'ramda';

import { CountryCode } from 'features/roboAdvice/shared/constants';

export const getHasValidationErrors = value => {
  if (R.type(value) === 'Object') {
    return R.pipe<object, any[], boolean>(
      R.values,
      R.any(getHasValidationErrors)
    )(value);
  }

  return !R.isNil(value) && !R.isEmpty(value);
};

// MOD11 VALIDATION
export const createCheckDigit = (input: string) => {
  let sum = 0;
  input
    .split('')
    .reverse()
    .forEach((value, index) => {
      sum += parseInt(value, 10) * ((index % 6) + 2);
    });
  const sumMod11 = sum % 11;
  if (sumMod11 === 0) {
    return '0';
  } else if (sumMod11 === 1) {
    return '-';
  }
  return `${11 - sumMod11}`;
};

export const isValidMOD11 = (input: string) => {
  const checkDigitIndex = input.length - 1;

  return (
    input.substr(checkDigitIndex) ===
    createCheckDigit(input.substr(0, checkDigitIndex))
  );
};

export const getOrgNumberErrors = ({ errors, values }) => {
  const country = values.clientInformation?.country?.toLowerCase();
  let orgNumber: string = values.clientInformation?.orgNumber;

  if (country && orgNumber) {
    orgNumber = orgNumber.toString();
    const isVatValid = getVatValidationForCountry(country);

    if (!isVatValid(orgNumber)) {
      return R.set(
        R.lensPath(['clientInformation', 'orgNumber']),
        {
          text: 'roboAdvice.clientInformation.generalInformation.orgNumber.invalidValue'
        },
        errors
      );
    }
  }

  return errors;
};

const FinlandBusinessFormat = /^\d{7}[-]?\d{1}$/;
const FinlandVatFormat = /^(FI)?\d{8}$/;
const SwedenBusinessFormat = /^\d{6}[-]?\d{4}$/;
const SwedenVatFormat = /^(SE)\d{6}[-]?\d{4}(01)$/;

export const getVatValidationForCountry = (
  country: typeof CountryCode[keyof typeof CountryCode]
) => {
  switch (country) {
    case CountryCode.Norway:
      return (orgNumber: string) => {
        if (orgNumber.length !== 9) return false;

        let total = 0;
        const multipliers = [3, 2, 7, 6, 5, 4, 3, 2];

        for (let i = 0; i < 8; i++) {
          total += Number(orgNumber.charAt(i)) * multipliers[i];
        }

        total = 11 - (total % 11);

        if (total === 11) {
          total = 0;
        }

        if (total < 10) {
          const expect = Number(orgNumber.slice(8, 9));
          return total === expect;
        }

        return false;
      };
    case CountryCode.Sweden:
      return (orgNumber: string) => {
        const uppecasedOrgNumber = orgNumber.toUpperCase();

        if (
          !SwedenBusinessFormat.test(uppecasedOrgNumber) &&
          !SwedenVatFormat.test(uppecasedOrgNumber)
        ) {
          return false;
        }

        const cleaned_orgNumber = uppecasedOrgNumber.replace(/[-|A-Z]/g, '');

        let R = 0;
        for (let i = 0; i < 9; i = i + 2) {
          const digit = Number(cleaned_orgNumber.charAt(i));
          R += Math.floor(digit / 5) + ((digit * 2) % 10);
        }

        let S = 0;
        for (let j = 1; j < 9; j = j + 2) {
          S += Number(cleaned_orgNumber.charAt(j));
        }

        const checkDigit = (10 - ((R + S) % 10)) % 10;

        let expect = Number(cleaned_orgNumber.slice(9, 10));

        return checkDigit === expect;
      };
    case CountryCode.Denmark:
      return (orgNumber: string) => {
        if (orgNumber.length !== 8) return false;
        const multipliers = [2, 7, 6, 5, 4, 3, 2, 1];
        let total = 0;

        for (let i = 0; i < 8; i++) {
          total += Number(orgNumber.charAt(i)) * multipliers[i];
        }

        return total % 11 === 0;
      };
    case CountryCode.Finland:
      return (orgNumber: string) => {
        const uppecasedOrgNumber = orgNumber.toUpperCase();

        if (
          !FinlandBusinessFormat.test(uppecasedOrgNumber) &&
          !FinlandVatFormat.test(uppecasedOrgNumber)
        ) {
          return false;
        }

        // Remove non digits
        const cleanedOrgNumber = uppecasedOrgNumber.replace(/[-|A-Z]/g, '');
        const multipliers = [7, 9, 10, 5, 8, 4, 2];
        let total = 0;

        for (let i = 0; i < 7; i++)
          total += Number(cleanedOrgNumber.charAt(i)) * multipliers[i];

        total = 11 - (total % 11);
        if (total > 9) {
          total = 0;
        }

        const expect = Number(cleanedOrgNumber.slice(7, 8));
        return total === expect;
      };
    default:
      return () => true;
  }
};
