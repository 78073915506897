export const flagNames = {
  possible_change_ownership: {
    flagLabel: 'roboAdvice.kyc.enin.possibleChangeOwnership',
    icon: 'network'
  },
  beneficial_ownership_change_or_new_beneficial_owners: {
    flagLabel:
      'roboAdvice.kyc.enin.beneficialOwnershipChangeOrNewBeneficialOwners',
    icon: 'treeStructure'
  },
  same_person_owns_more_than_half_and_is_ceo_and_board_chairman: {
    flagLabel:
      'roboAdvice.kyc.enin.samePersonOwnsMoreThanHalfAndIsCeoAndBoardChairman',
    icon: 'person'
  },
  any_direct_ownership_change_norwegian_share_registry: {
    flagLabel:
      'roboAdvice.kyc.enin.anyDirectOwnershipChangeNorwegianShareRegistry',
    icon: 'buildings'
  },
  auditor_change_after_accounts_with_auditor_note: {
    flagLabel: 'roboAdvice.kyc.enin.auditorChangeAfterAccountsWithAuditorNote',
    icon: 'person'
  },
  auditor_resigned_after_accounts_with_auditor_note: {
    flagLabel:
      'roboAdvice.kyc.enin.auditorResignedAfterAccountsWithAuditorNote',
    icon: 'person'
  },
  complete_ownership_change_direct_ownership_previous_year: {
    flagLabel:
      'roboAdvice.kyc.enin.completeOwnershipChangeDirectOwnershipPreviousYear',
    icon: 'building'
  },
  complete_ownership_change_ultimate_ownership_previous_year: {
    flagLabel:
      'roboAdvice.kyc.enin.completeOwnershipChangeUltimateOwnershipPreviousYear',
    icon: 'building'
  },
  beneficial_ownership_change: {
    flagLabel: 'roboAdvice.kyc.enin.beneficialOwnershipChange',
    icon: 'building'
  }
};
