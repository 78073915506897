import Icon from 'features/shared/components/icon/index.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

// Temporary solution until types added to Icon
const AnyIcon = Icon as any;

const useStyles = createUseStyles(theme => ({
  favoriteIcon: {
    color: theme.accentColor,
    fontSize: '26px',
    lineHeight: '26px',
    cursor: 'pointer',
    transform: 'rotate(0.03deg)' // fix of issue: icon small size causes pixelated icon
  }
}));

type FavoriteIconProps = {
  isFavorite: boolean;
  onClick?: (event: any, isFavorite: boolean) => void;
};

const FavoriteIcon = ({ isFavorite, onClick }: FavoriteIconProps) => {
  const classes = useStyles();

  return (
    <AnyIcon
      className={classes.favoriteIcon}
      onClick={event => {
        onClick && onClick(event, !isFavorite);
      }}
      type={isFavorite ? 'star' : 'star_border'}
    />
  );
};

export default FavoriteIcon;
