import { ValidationErrors } from 'final-form';
import { isNil, lensPath, view } from 'ramda';

import {
  AdviceSessionFormValues,
  useForm as useRoboAdviceForm
} from '../../../roboAdvice/adviceSession/form/services/form';
import { getAlignmentCriteriaFields } from '../../../roboAdvice/adviceSession/sustainability/components/utils';
import { getTranslation } from '../../../shared/utils/translations';
import { useCustomerConfig } from '../../customerConfig/components/useCustomerConfig';
import { useI18n } from '../../customerConfig/components/useI18n';
import { ErrorMessage, SustainabilityErrors } from '../types';

export const useGetSustainabilityErrors = (): SustainabilityErrors => {
  const i18n = useI18n();
  const roboAdviceFormState = useRoboAdviceForm();
  const customerConfig = useCustomerConfig();

  return {
    ...getGenericAssessmentErrors({
      customerConfig,
      roboAdviceFormState,
      i18n
    }),
    ...getAlignmentCriteriaErrors({
      customerConfig,
      roboAdviceFormState,
      i18n
    }),
    ...getOffModelQuestionErrors({
      customerConfig,
      roboAdviceFormState,
      i18n
    }),
    ...getValueAdaptedErrors({
      customerConfig,
      roboAdviceFormState,
      i18n
    })
  };
};

const getGenericAssessmentErrors = ({
  customerConfig,
  roboAdviceFormState,
  i18n
}): Record<string, ErrorMessage[]> => {
  const genericAssessmentErrors: ErrorMessage[] = [];
  const genericAssessmentCommentsErrors: ErrorMessage[] = [];
  const {
    genericAssessment: {
      enabled: isGenericAssessmentEnabled,
      isCommentFieldRequired,
      isCommentFieldShown
    }
  } = customerConfig?.roboAdvice?.sustainability;
  const genericAssessmentAnswer =
    roboAdviceFormState.values?.sustainability?.genericAssessment?.answer;
  const genericAssessmentComment =
    roboAdviceFormState.values?.sustainability?.genericAssessment?.comment;

  if (
    !isGenericAssessmentEnabled ||
    !customerConfig?.roboAdviceForm?.errorMessagesEnabled
  ) {
    return {
      genericAssessment: genericAssessmentErrors,
      genericAssessmentComment: genericAssessmentCommentsErrors
    };
  }

  if (isNil(genericAssessmentAnswer)) {
    genericAssessmentErrors.push({
      alertMessage: i18n('roboAdvice.errors.missingAnswerAlert'),
      listMessage: i18n('roboAdvice.errors.missingAnswer').replace(
        '{0}',
        i18n('roboAdvice.sustainability.genericAssessment.header')
      ),
      elementId: 'generic-assessment'
    });
  }
  if (
    isCommentFieldRequired &&
    isCommentFieldShown &&
    (isNil(genericAssessmentComment) || !genericAssessmentComment.length)
  ) {
    genericAssessmentCommentsErrors.push({
      alertMessage: i18n('roboAdvice.errors.missingAdvisorNotesAlert'),
      listMessage: i18n('roboAdvice.errors.missingAdvisorNotes').replace(
        '{0}',
        i18n('roboAdvice.sustainability.genericAssessment.header')
      ),
      elementId: 'generic-assessment-comment'
    });
  }

  return {
    genericAssessment: genericAssessmentErrors,
    genericAssessmentComment: genericAssessmentCommentsErrors
  };
};

const getAlignmentCriteriaErrors = ({
  customerConfig,
  roboAdviceFormState,
  i18n
}): Record<string, ErrorMessage[]> => {
  const alignmentCriteriaErrors: Record<string, ErrorMessage[]> = {};
  const {
    genericAssessment: { enabled: isGenericAssessmentEnabled }
  } = customerConfig?.roboAdvice?.sustainability;
  const genericAssessmentAnswer =
    roboAdviceFormState.values?.sustainability?.genericAssessment?.answer;

  if (
    (isGenericAssessmentEnabled && !genericAssessmentAnswer) ||
    !customerConfig?.roboAdviceForm?.errorMessagesEnabled
  ) {
    return {};
  }

  getAlignmentCriteriaFields({
    customerConfig
  }).forEach(field => {
    if (
      view(lensPath(field.name.split('.')), roboAdviceFormState.errors) ||
      isNil(view(lensPath(field.name.split('.')), roboAdviceFormState.values))
    ) {
      const fieldName = field.name.replace(
        'sustainability.alignmentCriteria.',
        ''
      );
      alignmentCriteriaErrors[fieldName] = [
        {
          listMessage: i18n('roboAdvice.errors.missingAnswer').replace(
            '{0}',
            getTranslation(field.config.title)
          ),
          alertMessage: i18n('roboAdvice.errors.missingAnswerAlert'),
          elementId: `alignmentCriteria-${field.name}`
        }
      ];
    }
    if (
      field.config.required &&
      (isNil(
        view(
          lensPath(field.textFieldName.split('.')),
          roboAdviceFormState.values
        )
      ) ||
        !view(
          lensPath(field.textFieldName.split('.')),
          roboAdviceFormState.values
        )?.length)
    ) {
      const fieldName = field.textFieldName.replace('sustainability.', '');
      alignmentCriteriaErrors[fieldName] = [
        {
          listMessage: i18n('roboAdvice.errors.missingAdvisorNotes').replace(
            '{0}',
            getTranslation(field.config.title)
          ),
          alertMessage: i18n('roboAdvice.errors.missingAdvisorNotesAlert'),
          elementId: `alignmentCriteria-${field.name}`
        }
      ];
    }
  });

  return alignmentCriteriaErrors;
};

const getOffModelQuestionErrors = ({
  customerConfig,
  roboAdviceFormState,
  i18n
}: {
  customerConfig: ReturnType<typeof useCustomerConfig>;
  roboAdviceFormState: {
    values: AdviceSessionFormValues;
    errors: ValidationErrors;
  };
  i18n: ReturnType<typeof useI18n>;
}): Record<string, ErrorMessage[]> => {
  const offModelQuestionErrors: Record<string, ErrorMessage[]> = {};
  const {
    offModelQuestions: { enabled: isOffModelQuestionsEnabled, questions },
    genericAssessment: { enabled: isGenericAssessmentEnabled } = {}
  } = customerConfig?.roboAdvice?.sustainability;
  const offModelQuestionsAnswers =
    roboAdviceFormState.values?.sustainability?.offModelQuestions;
  const offModelQuestionsAdvisorNotes =
    roboAdviceFormState.values?.sustainability?.offModelQuestionsAdvisorNotes;
  const genericAssessmentAnswer =
    roboAdviceFormState.values?.sustainability?.genericAssessment?.answer;

  if (
    (isGenericAssessmentEnabled && !genericAssessmentAnswer) ||
    !isOffModelQuestionsEnabled ||
    !customerConfig?.roboAdviceForm?.errorMessagesEnabled
  ) {
    return {};
  }
  questions.forEach(question => {
    if (
      question.enabled &&
      question.required &&
      isNil(offModelQuestionsAnswers?.[question.name])
    ) {
      offModelQuestionErrors[question.name] = [
        ...(offModelQuestionErrors[question.name] ?? []),
        {
          listMessage: i18n('roboAdvice.errors.missingAnswer').replace(
            '{0}',
            getTranslation(question.header)
          ),
          alertMessage: i18n('roboAdvice.errors.missingAnswerAlert'),
          elementId: `offModelQuestion-${question.name}`
        }
      ];
    }

    if (
      question.advisorNotes.enabled &&
      question.advisorNotes.required &&
      !offModelQuestionsAdvisorNotes?.[question.name]
    ) {
      offModelQuestionErrors[`offModelQuestionsAdvisorNotes-${question.name}`] =
        [
          ...(offModelQuestionErrors[
            `offModelQuestionsAdvisorNotes-${question.name}`
          ] ?? []),
          {
            listMessage: i18n('roboAdvice.errors.missingAdvisorNotes').replace(
              '{0}',
              getTranslation(question.header)
            ),
            alertMessage: i18n('roboAdvice.errors.missingAdvisorNotesAlert'),
            elementId: `offModelQuestion-${question.name}`
          }
        ];
    }
  });

  return offModelQuestionErrors;
};

const getValueAdaptedErrors = ({
  customerConfig,
  roboAdviceFormState,
  i18n
}: {
  customerConfig: ReturnType<typeof useCustomerConfig>;
  roboAdviceFormState: { values: AdviceSessionFormValues };
  i18n: ReturnType<typeof useI18n>;
}): Record<string, ErrorMessage[]> => {
  const valueAdaptedErrors: Record<string, ErrorMessage[]> = {};
  const { values = {} } = roboAdviceFormState;

  // Generic assessment
  const {
    genericAssessment: {
      isValueAdaptedRecorded: isGenericAssessmentValueAdaptedRecorded
    } = {}
  } = customerConfig?.roboAdvice?.sustainability;

  if (
    isGenericAssessmentValueAdaptedRecorded &&
    values?.sustainability?.genericAssessment?.isValueAdaptedQuestionVisible
  ) {
    valueAdaptedErrors.genericAssessmentValueAdapted = [
      {
        alertMessage: i18n(
          'roboAdvice.sustainability.valueAdaptedQuestionErrorAlertMessage'
        ),
        listMessage: i18n(
          'roboAdvice.sustainability.valueAdaptedQuestionErrorListMessage'
        ),
        elementId: 'generic-assessment-question-alert'
      }
    ];
  }

  // Alignment criteria
  const alignmentCriteriaFields = getAlignmentCriteriaFields({
    customerConfig
  });
  alignmentCriteriaFields.forEach(field => {
    const fieldId = field.name.split('.').pop();
    if (!fieldId) return;

    if (
      field.config.isValueAdaptedRecorded &&
      values?.sustainability?.alignmentCriteriaValueAdapted?.[fieldId]
        ?.isValueAdaptedQuestionVisible
    ) {
      valueAdaptedErrors[`${fieldId}ValueAdapted`] = [
        {
          alertMessage: i18n(
            'roboAdvice.sustainability.valueAdaptedQuestionErrorAlertMessage'
          ),
          listMessage: i18n(
            'roboAdvice.sustainability.valueAdaptedQuestionErrorListMessage'
          ),
          elementId: `adaptedValueQuestionAlert-${fieldId}`
        }
      ];
    }
  });

  // Preference criteria
  const { sustainabilityPreference } =
    customerConfig?.roboAdvice?.sustainability;
  const preferenceCriteriaFields = sustainabilityPreference?.config;

  const areAnyPreferenceCriteriaValueAdaptedQuestionsVisible =
    sustainabilityPreference.isValueAdaptedRecorded &&
    preferenceCriteriaFields.some(field => {
      const fieldId = field.id;
      if (!fieldId) return false;

      return values?.sustainability?.preferenceCriteriaValueAdapted?.[fieldId]
        ?.isValueAdaptedQuestionVisible;
    });

  if (areAnyPreferenceCriteriaValueAdaptedQuestionsVisible) {
    valueAdaptedErrors.preferenceCriteriaValueAdapted = [
      {
        alertMessage: i18n(
          'roboAdvice.sustainability.valueAdaptedQuestionErrorAlertMessage'
        ),
        listMessage: i18n(
          'roboAdvice.sustainability.valueAdaptedQuestionErrorListMessage'
        ),
        elementId: 'preference-criteria-question-alert'
      }
    ];
  }

  // Off model questions
  const { offModelQuestions } = customerConfig?.roboAdvice?.sustainability;

  offModelQuestions?.questions.forEach(question => {
    if (!question.name || !question.isValueAdaptedRecorded) return;

    if (
      values?.sustainability?.offModelQuestionsValueAdapted?.[question.name]
        ?.isValueAdaptedQuestionVisible
    ) {
      valueAdaptedErrors[`${question.name}ValueAdapted`] = [
        {
          alertMessage: i18n(
            'roboAdvice.sustainability.valueAdaptedQuestionErrorAlertMessage'
          ),
          listMessage: i18n(
            'roboAdvice.sustainability.valueAdaptedQuestionErrorListMessage'
          ),
          elementId: `adaptedValueQuestionAlert-${question.name}`
        }
      ];
    }
  });

  return valueAdaptedErrors;
};
