import create from 'zustand';

type ManageCustomerStoreData = {
  isReadCustomerPending: boolean;
};

type ManageCustomerStoreApi = {
  reset: () => void;
  setIsReadCustomerPending: (isReadCustomerPending: any) => void;
};

type ManageCustomerStoreState = ManageCustomerStoreData &
  ManageCustomerStoreApi;

const defaultData = {
  isReadCustomerPending: false
};

export const useManageCustomerStore = create<ManageCustomerStoreState>(set => ({
  ...defaultData,
  reset: () => {
    set(defaultData);
  },
  setIsReadCustomerPending: isReadCustomerPending => {
    set({ isReadCustomerPending });
  }
}));
