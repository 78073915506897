export const types = {
  OPENED: 'CUSTOMER_MANAGEMENT_PAGE/OPENED',
  CLOSED: 'CUSTOMER_MANAGEMENT_PAGE/CLOSED',

  READ_CUSTOMER: 'CUSTOMER_MANAGEMENT_PAGE/READ_CUSTOMER',

  CREATE_CUSTOMER: 'CUSTOMER_MANAGEMENT_PAGE/CREATE_CUSTOMER',

  UPDATE_CUSTOMER: 'CUSTOMER_MANAGEMENT_PAGE/UPDATE_CUSTOMER',

  SAVE_CUSTOMER_MANAGEMENT_FORM:
    'CUSTOMER_MANAGEMENT_PAGE/SAVE_CUSTOMER_MANAGEMENT_FORM',

  CANCEL_CUSTOMER_MANAGEMENT_FORM_CHANGES:
    'CUSTOMER_MANAGEMENT_PAGE/CANCEL_CUSTOMER_MANAGEMENT_FORM_CHANGES',

  DELETE_USER: 'CUSTOMER_MANAGEMENT_PAGE/DELETE_USER'
};

export const creators = {
  opened: () => ({
    type: types.OPENED
  }),
  closed: () => ({
    type: types.CLOSED
  }),

  readCustomer: customerId => ({
    type: types.READ_CUSTOMER,
    payload: { customerId }
  }),

  createCustomer: customer => ({
    type: types.CREATE_CUSTOMER,
    payload: { customer }
  }),

  updateCustomer: (customerId, customer) => ({
    type: types.UPDATE_CUSTOMER,
    payload: { customerId, customer }
  }),

  saveCustomerManagementForm: () => ({
    type: types.SAVE_CUSTOMER_MANAGEMENT_FORM
  }),

  cancelCustomerManagementFormChanges: () => ({
    type: types.CANCEL_CUSTOMER_MANAGEMENT_FORM_CHANGES
  }),

  deleteUser: () => ({
    type: types.DELETE_USER
  })
};
