import classNames from 'classnames';
import React, { useEffect } from 'react';

import { RoboAdviceAdviceSessionPages } from '../../../shared/constants';
import PortfolioChangeAlert from '../../advisory/components/portfolioChangeAlert';
import { useIsSessionReadOnly } from '../../main/services/selectors';
import { useSessionStore } from '../../session/services/sessionStore';
import ActionButtons from '../../shared/components/actionButtons';
import { WithdrawalPlans } from '../../shared/constants';
import { useGoalsStore } from '../../shared/services/goalsStore';
import { GoalModalModeTypes } from '../constants';
import { goalForm } from '../services/goalForm';
import GoalModal from './goalModal/goalModal';
import GoalTable from './goalTable';
import Risk from './risk.js';
import AdvisorNotes from 'features/shared/components/advisorNotes';
import Button, { ButtonType } from 'features/shared/components/button';
import ConfigurableLabel from 'features/shared/components/configurableLabel/';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import ErrorsList from 'features/sharedModules/errors/components/errorsList';
import InputErrorMessage from 'features/sharedModules/errors/components/inputErrorMessage';
import NextButtonError from 'features/sharedModules/errors/components/nextButtonError';
import { useErrorsStore } from 'features/sharedModules/errors/services/errorsStore';
import { useGetAllAdviceSessionErrors } from 'features/sharedModules/errors/services/useGetAllAdviceSessionErrors';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const borderRadius = {
  [BorderRadiusTypes.rounded]: '25px',
  [BorderRadiusTypes.sharp]: '15px'
};

const useStyles = createUseStyles(theme => ({
  sectionHeader: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    fontWeight: FontWeights.medium,
    marginBottom: Spacing.spacing01
  },
  sectionSubHeader: {
    lineHeight: Typography.heading2.lineHeight,
    marginBottom: Spacing.spacing02
  },
  goalTableWrap: {
    marginBottom: Spacing.spacing04
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: Spacing.spacing02
  },
  actionButton: {
    '& + &': {
      marginLeft: '2.4rem'
    }
  },
  advisorNotes: {
    marginTop: Spacing.spacing04
  },
  hasError: {
    border: `2px solid ${theme.errorNegativeColor}`,
    padding: `${Spacing.spacing01}px ${Spacing.spacing01}px ${Spacing.spacing00}px `,
    borderRadius: borderRadius[theme.borderRadiusType],
    marginBottom: `${Spacing.spacing00}px`
  }
}));

const Page = () => {
  const i18n = useI18n();
  const classes = useStyles();
  const isSessionReadOnly = useIsSessionReadOnly();
  const [goalModalMode, setGoalModalMode] = React.useState(null);
  const [editGoalId, setEditGoalId] = React.useState(null);
  const { goals } = useGoalsStore();
  const customerConfig = useCustomerConfig();
  const maxGoalsNumber =
    customerConfig.roboAdviceForm.purposeAndRisk.maxGoalsNumber;
  const allErrors = useGetAllAdviceSessionErrors();
  const errors = allErrors[RoboAdviceAdviceSessionPages.purposeAndRisk];
  const isErrorModeEnabled = useErrorsStore(state => state.isErrorModeEnabled);
  const sessionStore = useSessionStore();

  useEffect(() => {
    return () => {
      useSessionStore.getState().resetFieldsWhichChangedRiskScore();
    };
  }, []);

  const addNewGoal = () => {
    goalForm.change('data.withdrawalPlan', WithdrawalPlans.noPlannedWithdrawal);
    setGoalModalMode(GoalModalModeTypes.new);
  };

  return (
    <div
      role="tabpanel"
      aria-labelledby={`tab-id-${RoboAdviceAdviceSessionPages.purposeAndRisk}`}
      id={`panel-id-${RoboAdviceAdviceSessionPages.purposeAndRisk}`}
    >
      <ErrorsList
        pageName={i18n('roboAdvice.tabs.purposeAndRisk')}
        errors={errors}
      />

      <div
        className={classNames(classes.section, {
          [classes.hasError]: errors?.goals?.length && isErrorModeEnabled
        })}
        id={'goals-section'}
      >
        <div className={classes.sectionHeader}>
          <ConfigurableLabel
            required
            label={i18n('roboAdvice.purposeAndRisk.purpose')}
          />
        </div>
        {errors.goals?.map(error => (
          <InputErrorMessage message={error.alertMessage} />
        ))}

        <div className={classes.sectionSubHeader}>
          {i18n('roboAdvice.purposeAndRisk.whatAreYouSavingFor')}
        </div>

        <GoalModal
          goalModalMode={goalModalMode}
          editGoalId={editGoalId}
          setGoalModalMode={setGoalModalMode}
        />

        <PortfolioChangeAlert
          in={sessionStore.fieldsWhichChangedRiskScore
            .filter(field => field.startsWith('timeHorizon'))
            .map(field => field.split('_'))
            .some(([_, id]) =>
              goals.some(
                ({ goalId, data }) =>
                  goalId === id &&
                  data.isPortfolioCustom &&
                  data.isCustomPortfolioDirty
              )
            )}
          message={i18n('roboAdvice.advisory.riskScoreChangeAlertMessage')}
          onClick={() =>
            sessionStore.removeFieldWhichChangedRiskScore('timeHorizon')
          }
        />

        {!isSessionReadOnly && goals.length < maxGoalsNumber && (
          <Button
            buttonType={ButtonType.tertiary}
            onClick={addNewGoal}
            style={{
              marginBottom: goals.length ? Spacing.spacing01 : Spacing.spacing04
            }}
          >
            + {i18n('roboAdvice.purposeAndRisk.addNewGoal')}
          </Button>
        )}

        {goals.length > 0 && (
          <div className={classes.goalTableWrap}>
            <GoalTable
              setGoalModalMode={setGoalModalMode}
              setEditGoalId={setEditGoalId}
            />
          </div>
        )}
      </div>

      <div className={classes.sectionHeader}>
        {i18n('roboAdvice.purposeAndRisk.risk')}
      </div>

      <Risk errors={errors} />

      <AdvisorNotes
        name={'purposeAndRiskAdvisorNotes'}
        header={i18n('purposeAndRisk.advisorNotes.header')}
        customPlaceholder={i18n('purposeAndRisk.advisorNotes.placeholder')}
        infoTooltipText={i18n('purposeAndRisk.advisorNotes.infoTooltip')}
        disabled={isSessionReadOnly}
        className={classes.advisorNotes}
      />
      <NextButtonError allErrors={allErrors} />
      <ActionButtons pageId={RoboAdviceAdviceSessionPages.purposeAndRisk} />
    </div>
  );
};

export default Page;
