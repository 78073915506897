import * as R from 'ramda';
import {
  takeEvery,
  take,
  all,
  put,
  delay as delaySaga
} from 'redux-saga/effects';
import { v4 } from 'uuid';

import { types, creators } from './actions.js';

export function* delay({ delayId, msTimeout }) {
  if (R.isNil(delayId)) {
    delayId = v4();
  }

  yield put(creators.run(delayId, msTimeout));
  yield take(a => a.type === types.RESOLVED && a.payload.id === delayId);
}

function* run(action) {
  const { id, msTimeout } = action.payload;

  yield delaySaga(msTimeout);
  yield put(creators.resolved(id));
}

export default function* () {
  yield all([takeEvery(types.RUN, run)]);
}
