import * as R from 'ramda';

import { globalizeSelectors } from 'framework/globalize/utils/index.js';

const defaultNotification = {};

export const getNotifications = state =>
  R.map(id => state.entities[id], state.ids);

export default globalizeSelectors(['notification_state'], {
  getEntities: state => state.entities,
  getEntity: (id, state) => state.entities[id],
  getEntityOrDefault: (id, state) => state.entities[id] || defaultNotification,
  getNotifications
});
