import { useInitPage } from './useInitPage';
import SuitabilitySection from 'features/roboAdvice/adviceSession/knowledgeAndExperience/components/suitabilitySection';
import { advancedSuitabilityTables } from 'features/roboAdvice/adviceSession/knowledgeAndExperience/constants';
import { useKnowledgeAndExperienceStore } from 'features/roboAdvice/adviceSession/knowledgeAndExperience/services/knowledgeAndExperienceStore';
import {
  PageId,
  useIsSessionReadOnly
} from 'features/roboAdvice/adviceSession/main/services/selectors';
import OrderExecutionActionButtons from 'features/roboAdvice/adviceSession/orderExecution/components/orderExecutionActionButtons';
import { RoboAdviceOrderExecutionPages } from 'features/roboAdvice/shared/constants';
import AdvisorNotes from 'features/shared/components/advisorNotes';
import { Spacing } from 'features/shared/constants/spacing';
import { getTranslation } from 'features/shared/utils/translations';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import ErrorsList from 'features/sharedModules/errors/components/errorsList';
import NextButtonError from 'features/sharedModules/errors/components/nextButtonError';
import { useGetAllOrderExecutionErrors } from 'features/sharedModules/errors/services/useGetAllOrderExecutionErrors';
import { ErrorMessage } from 'features/sharedModules/errors/types';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  errors: {
    marginTop: Spacing.spacing04
  },
  advisorNotes: {
    marginTop: Spacing.spacing04
  },
  firstSection: {
    paddingTop: 0
  }
});
type Props = {
  onNavButtonClick: (pageId: PageId) => void;
};

export const TargetMarketAssessment = ({ onNavButtonClick }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const allErrors = useGetAllOrderExecutionErrors();
  const { advancedSuitabilityConfig } = useKnowledgeAndExperienceStore();
  const {
    roboAdviceForm: {
      knowledgeAndExperience: { advancedSuitability }
    },
    roboOrderFlow: {
      targetMarketAssessmentPage: { pageLabel },
      targetMarketAssessment: { advisorNote }
    }
  } = useCustomerConfig();
  const errors =
    allErrors[RoboAdviceOrderExecutionPages.targetMarketAssessment];
  const isSessionReadOnly = useIsSessionReadOnly();

  useInitPage();

  return (
    <>
      <ErrorsList pageName={getTranslation(pageLabel)} errors={errors} />

      {advancedSuitabilityTables.map(
        ({ name, config }, index) =>
          advancedSuitabilityConfig &&
          advancedSuitabilityConfig[config] > 0 &&
          advancedSuitability[name].enabled && (
            <SuitabilitySection
              name={name}
              header={getTranslation(advancedSuitability[name]?.header)}
              options={advancedSuitability[name]?.tableHeaders}
              checks={advancedSuitabilityConfig?.checks}
              key={index}
              errors={
                errors[`targetMarketAssessment-${name}`] as ErrorMessage[]
              }
              containerClassName={
                index === 0 ? classes.firstSection : undefined
              }
              isTrainingVisible={false}
            />
          )
      )}

      {advisorNote.enabled && (
        <AdvisorNotes
          name={'targetMarketAssessmentAdvisorNotes'}
          header={i18n(
            'orderExecution.targetMarketAssessment.advisorNotes.header'
          )}
          customPlaceholder={i18n(
            'orderExecution.targetMarketAssessment.advisorNotes.placeholder'
          )}
          infoTooltipText={i18n(
            'orderExecution.targetMarketAssessment.advisorNotes.infoTooltip'
          )}
          disabled={isSessionReadOnly}
          className={classes.advisorNotes}
          required={advisorNote.required}
          errors={
            errors['targetMarketAssessment-advisorNote'] as ErrorMessage[]
          }
        />
      )}

      <div className={classes.errors}>
        <NextButtonError allErrors={allErrors} />
      </div>
      <OrderExecutionActionButtons
        pageId={RoboAdviceOrderExecutionPages.targetMarketAssessment}
        onNavButtonClick={onNavButtonClick}
      />
    </>
  );
};
