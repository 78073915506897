import { z } from 'zod';

export const reportAttachmentsSchema = z.array(
  z.object({
    id: z.string(),
    path: z.array(z.string()),
    title: z.string(),
    type: z.string()
  })
);

export type ReportAttachments = z.infer<typeof reportAttachmentsSchema>;
