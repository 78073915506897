import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { parsePhoneNumber } from 'react-phone-number-input';
import { useParams } from 'react-router';

import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import {
  INITIAL_QUESTION_TRANSLATIONS_KEYS,
  PreMeetingSurveyPage,
  PreMeetingSurveyPages
} from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/constants';
import { CustomerDetails } from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/customerDetails';
import {
  preMeetingSurveyForm,
  usePreMeetingSurveyForm
} from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/form';
import { InitialQuestion } from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/initialQuestion';
import { SelfServiceFlow } from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/selfServiceFlow';
import { useCreatePreMeetingSession } from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/useCreatePreMeetingSession';
import useInitPreMeetingSurvey from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/useInitPreMeetingSurvey';
import { useReadSurveyFlows } from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/useReadSurveyFlows';
import { useSaveAdviceSession } from 'features/roboAdvice/client/adviceSessionsList/components/useSaveAdviceSession';
import { usePreMeetingSurveyStore } from 'features/roboAdvice/client/adviceSessionsList/services/preMeetingSurveyStore';
import { FlowTypes } from 'features/roboAdvice/client/main/constants';
import {
  CreatePreMeetingSessionPayload,
  NotificationChannelTypes
} from 'features/roboAdvice/client/main/types';
import Button, { ButtonType } from 'features/shared/components/button';
import Modal from 'features/shared/components/modal';
import { useModalBodyMaxDimensions } from 'features/shared/components/modal/useModalBodyMaxDimensions';
import Scrollbars from 'features/shared/components/scrollbars';
import { AdviceSessionListParams } from 'features/shared/constants/session';
import { filterNil } from 'features/shared/utils/filters';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedAdviceType: string;
};

const PreMeetingSurveyModal = ({
  isOpen,
  setIsOpen,
  selectedAdviceType
}: Props) => {
  const i18n = useI18n();
  const { modalBodyMaxHeight, headerRef, footerRef } =
    useModalBodyMaxDimensions();
  const { values } = usePreMeetingSurveyForm();
  const saveAdviceSession = useSaveAdviceSession();
  const [page, setPage] = useState<PreMeetingSurveyPage>(
    PreMeetingSurveyPages.initialQuestion
  );
  const { adviceFlows } = useCustomerConfig();
  const readSurveyFlows = useReadSurveyFlows();
  const {
    reset: resetPreMeetingSurveyStore,
    investorInformationData,
    isCreatePreMeetingSessionPending,
    setIsCreatePreMeetingSessionPending
  } = usePreMeetingSurveyStore();
  const createPreMeetingSession = useCreatePreMeetingSession();
  const { clientType } = useParams<AdviceSessionListParams>();

  const selectedFlowType =
    selectedAdviceType === adviceFlows?.[0]?.name
      ? FlowTypes.adviceflow
      : selectedAdviceType === adviceFlows?.[1]?.name
      ? FlowTypes.orderexecution
      : null;

  useInitPreMeetingSurvey({ selectedFlowType, isOpen });

  useEffect(() => {
    if (selectedFlowType) {
      readSurveyFlows(selectedFlowType);
    }
  }, [selectedFlowType, readSurveyFlows]);

  const handleCreateAdviceWithoutSurvey = () => {
    saveAdviceSession(null, selectedAdviceType);
    handleCloseModal();
  };

  const handleCreateAdviceWithSurvey = async () => {
    preMeetingSurveyForm.submit();
    if (
      preMeetingSurveyForm.getState().hasValidationErrors ||
      selectedFlowType === null
    ) {
      return;
    }
    setIsCreatePreMeetingSessionPending(true);

    const createdAdviceSessionId = await saveAdviceSession(
      null,
      selectedAdviceType
    );

    const payload: CreatePreMeetingSessionPayload = {
      id: createdAdviceSessionId!,
      dapSessionType: selectedFlowType,
      investorType: clientType,
      surveyFlowId: values.selfServiceFlow,
      language: getUserPageLanguage()!,
      contact: {
        email: values.investorEmail ?? null,
        phone:
          parsePhoneNumber(values.investorPhoneNumber)?.number ??
          values.investorPhoneNumber,
        name: investorInformationData?.name ?? '',
        ssn: investorInformationData?.ssn ?? ''
      },
      notification: [
        values.isEmailToCustomerEnabled
          ? {
              channel: NotificationChannelTypes.email,
              message: values.emailContent,
              subject: i18n('roboAdvice.client.preMeetingSurvey.emailSubject')
            }
          : null,
        values.isSmsToCustomerEnabled
          ? {
              channel: NotificationChannelTypes.sms,
              message: values.smsContent,
              subject: null
            }
          : null
      ].filter(filterNil)
    };

    await createPreMeetingSession(payload);
    handleCloseModal();
  };

  const buttonNextComponent = useMemo(() => {
    switch (page) {
      case PreMeetingSurveyPages.initialQuestion:
        if (values.isSelfAssessment) {
          return (
            <Button
              onClick={() => {
                setPage(PreMeetingSurveyPages.selfServiceFlow);
              }}
            >
              {i18n('roboAdvice.client.preMeetingSurvey.prepare')}
            </Button>
          );
        } else {
          return (
            <Button onClick={() => handleCreateAdviceWithoutSurvey()}>
              {i18n(
                INITIAL_QUESTION_TRANSLATIONS_KEYS[
                  selectedFlowType ?? FlowTypes.adviceflow
                ].createAdviceButton
              )}
            </Button>
          );
        }
      case PreMeetingSurveyPages.selfServiceFlow:
        return (
          <Button
            onClick={() => {
              setPage(PreMeetingSurveyPages.customerDetails);
            }}
            disabled={!values.selfServiceFlow}
          >
            {i18n('shared.next')}
          </Button>
        );
      case PreMeetingSurveyPages.customerDetails:
        return (
          <Button
            onClick={() => {
              handleCreateAdviceWithSurvey();
            }}
            disabled={isCreatePreMeetingSessionPending}
          >
            {i18n('roboAdvice.client.preMeetingSurvey.createAndSend')}
          </Button>
        );
      default:
        return null;
    }
  }, [
    page,
    values,
    handleCreateAdviceWithoutSurvey,
    handleCreateAdviceWithSurvey
  ]);

  const handleCloseModal = () => {
    preMeetingSurveyForm.reset();
    setPage(PreMeetingSurveyPages.initialQuestion);
    setIsOpen(false);
    resetPreMeetingSurveyStore();
  };

  return (
    <Form form={preMeetingSurveyForm} onSubmit={() => {}}>
      {() => (
        <Modal
          footer={
            <>
              <Button
                onClick={handleCloseModal}
                buttonType={ButtonType.secondary}
              >
                {i18n('shared.cancel')}
              </Button>

              {buttonNextComponent}
            </>
          }
          header={
            page === PreMeetingSurveyPages.initialQuestion
              ? i18n(
                  INITIAL_QUESTION_TRANSLATIONS_KEYS[
                    selectedFlowType ?? FlowTypes.adviceflow
                  ].modalHeader
                )
              : i18n(
                  'roboAdvice.client.preMeetingSurvey.prepareSelfAssessment.header'
                )
          }
          isOpen={isOpen}
          onRequestClose={() => {
            setIsOpen(false);
          }}
          headerRef={headerRef}
          footerRef={footerRef}
          onAfterClose={handleCloseModal}
        >
          <Scrollbars autoHeightMax={modalBodyMaxHeight}>
            {page === PreMeetingSurveyPages.initialQuestion && (
              <InitialQuestion selectedFlowType={selectedFlowType} />
            )}
            {page === PreMeetingSurveyPages.selfServiceFlow && (
              <SelfServiceFlow selectedFlowType={selectedFlowType!} />
            )}
            {page === PreMeetingSurveyPages.customerDetails && (
              <CustomerDetails />
            )}
          </Scrollbars>
        </Modal>
      )}
    </Form>
  );
};

export default PreMeetingSurveyModal;
