import classNames from 'classnames';
import { isNil } from 'ramda';
import { useMemo, useRef } from 'react';

import { useIsSessionReadOnly } from 'features/roboAdvice/adviceSession/main/services/selectors';
import {
  Category,
  useSessionStore
} from 'features/roboAdvice/adviceSession/session/services/sessionStore';
import { useIsExistingPortfolioVisible } from 'features/roboAdvice/shared/components/useIsExistingPortfolioVisible';
import HiddenLabel from 'features/shared/components/label/hiddenLabel';
import Modal from 'features/shared/components/modal/index';
import { useModalBodyMaxDimensions } from 'features/shared/components/modal/useModalBodyMaxDimensions';
import Scrollbars from 'features/shared/components/scrollbars';
import SimpleTable from 'features/shared/components/table/simpleTable';
import { ClientTypes } from 'features/shared/constants/session';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { formatNumber } from 'features/shared/utils/number';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import FinalFormNumberInput from 'features/sharedModules/finalForm/components/numberInput';
import FinalFormSelect from 'features/sharedModules/finalForm/components/select';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const AnyFinalFormNumberInput = FinalFormNumberInput as any;

const useStyles = createUseStyles(theme => ({
  headerCell: {
    backgroundColor: theme.itemBackgroundColor_1,
    color: theme.secondaryColor,
    fontWeight: FontWeights.normal,
    height: 59,
    lineHeight: Typography.body1.lineHeight,
    padding: `0 ${Spacing.spacing01}px`,
    whiteSpace: 'nowrap',
    minWidth: 150
  },
  invisible: {
    visibility: 'hidden'
  },
  right: {
    textAlign: 'right'
  },
  row: {
    backgroundColor: theme.itemBackgroundColor_1,
    height: 59,

    '&:nth-child(odd)': {
      backgroundColor: theme.itemBackgroundColor_2
    }
  },
  cell: {
    lineHeight: Typography.body1.lineHeight,
    padding: `0px ${Spacing.spacing01}px`
  },
  originalAmount: {
    color: theme.disabledTextColor,
    textAlign: 'right',
    textDecoration: 'line-through'
  },
  toAdvisoryInput: {
    width: 143,
    textAlign: 'right'
  }
}));

export const HoldingsSource = {
  internal: 'internal',
  external: 'external'
} as const;

export type Item = {
  id: string;
  label: Record<string, string>;
  amount: number;
  source: keyof typeof HoldingsSource;
  priority: number;
};

type Props = {
  clientType: keyof typeof ClientTypes;
  cultureCode: string;
  decimalSeparator: string;
  financialSituation: Record<string, any>;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  selectedItem: Item;
  thousandSeparator: string;
};

const GranularInformationModal = ({
  clientType,
  cultureCode,
  decimalSeparator,
  financialSituation,
  isModalOpen,
  setIsModalOpen,
  selectedItem,
  thousandSeparator
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const isSessionReadOnly = useIsSessionReadOnly();
  const modalBodyRef = useRef<HTMLDivElement | null>(null);
  const { isExistingPortfolioVisible } = useIsExistingPortfolioVisible();
  const { categories } = useSessionStore();
  const { modalBodyMaxHeight, headerRef } = useModalBodyMaxDimensions();

  const instruments = useMemo(() => {
    const filteredInstrumentsBySource = financialSituation?.[
      selectedItem.id
    ]?.filter(({ accountNumber }) =>
      selectedItem.source === HoldingsSource.internal
        ? !isNil(accountNumber)
        : isNil(accountNumber)
    );

    filteredInstrumentsBySource.sort((a, b) => {
      if (a.isTaggedAutomatically && !b.isTaggedAutomatically) {
        return -1;
      }

      if (!a.isTaggedAutomatically && b.isTaggedAutomatically) {
        return 1;
      }

      return 0;
    });

    return filteredInstrumentsBySource;
  }, [financialSituation, selectedItem]);

  const assetClassOptions = categories
    .reduce((acc, curr) => {
      const index = acc.findIndex(
        ({ SubAssetClass }) => SubAssetClass === curr.SubAssetClass
      );
      return index === -1 ? [...acc, curr] : acc;
    }, [] as Category[])
    .map(category => ({ label: category.SubAssetClass, value: category }));

  const modalOffset =
    modalBodyRef.current &&
    modalBodyRef.current?.getBoundingClientRect().bottom + 32;

  const displayOriginalAmountHeader = instruments?.some(item => {
    return financialSituation.amountForAdviceSwitch
      ? item.toAdvisory !== 100
      : item.toAdvisory !== item.value;
  });

  return (
    <Modal
      isOpen={isModalOpen}
      header={
        isExistingPortfolioVisible
          ? i18n(
              'roboAdvice.financialSituation.amountForAdvice.granularModalTitle'
            )
          : i18n(
              'roboAdvice.financialSituation.amountForAdvice.assetsForAdvisory'
            )
      }
      onRequestClose={() => {
        setIsModalOpen(false);
      }}
      bodyRef={modalBodyRef}
      headerRef={headerRef}
    >
      <Scrollbars autoHeightMax={modalBodyMaxHeight}>
        <SimpleTable
          headers={() => (
            <>
              <th className={classes.headerCell}>
                {i18n(
                  'roboAdvice.financialSituation.amountForAdviceTable.instrument'
                )}
              </th>

              <th className={classNames(classes.headerCell, classes.right)}>
                <span
                  className={
                    displayOriginalAmountHeader ? undefined : classes.invisible
                  }
                >
                  {i18n(
                    'roboAdvice.financialSituation.amountForAdviceTable.originalValue'
                  )}
                </span>
              </th>

              {financialSituation.amountForAdviceSwitch && (
                <th className={classNames(classes.headerCell, classes.right)}>
                  {i18n('roboAdvice.financialSituation.amountForAdvice.amount')}
                </th>
              )}

              <th className={classNames(classes.headerCell, classes.right)}>
                {i18n(
                  'roboAdvice.financialSituation.amountForAdvice.toAdvisory'
                )}
              </th>

              {instruments.some(item => item?.accountNumber !== undefined) && (
                <th className={classNames(classes.headerCell, classes.right)}>
                  {i18n('shared.account')}
                </th>
              )}

              {isExistingPortfolioVisible && (
                <th className={classNames(classes.headerCell, classes.right)}>
                  {i18n('shared.assetClass')}
                </th>
              )}
            </>
          )}
          body={({ rowClassName }) => (
            <>
              {instruments &&
                instruments.map(instrument => {
                  const index = financialSituation[selectedItem.id]?.findIndex(
                    ({ id }) => id === instrument.id
                  );

                  return !isNil(instrument.value) ? (
                    <tr
                      key={instrument.id}
                      className={classNames(rowClassName, classes.row)}
                    >
                      <td className={classes.cell}>{instrument.title}</td>

                      <td
                        className={classNames(
                          classes.cell,
                          classes.originalAmount
                        )}
                      >
                        {(financialSituation.amountForAdviceSwitch &&
                          instrument.toAdvisory === 100) ||
                        (!financialSituation.amountForAdviceSwitch &&
                          instrument.toAdvisory === instrument.value)
                          ? null
                          : formatNumber(cultureCode, instrument.value, 0, 0)}
                      </td>

                      {financialSituation.amountForAdviceSwitch && (
                        <td className={classNames(classes.cell, classes.right)}>
                          {formatNumber(
                            cultureCode,
                            financialSituation.amountForAdviceSwitch
                              ? (instrument.toAdvisory / 100) * instrument.value
                              : instrument.toAdvisory,
                            0,
                            0
                          )}
                        </td>
                      )}

                      <td className={classNames(classes.cell, classes.right)}>
                        <HiddenLabel
                          inputId={`to-advisory-input-${instrument.id}`}
                          text={`${instrument.title} ${i18n(
                            'shared.assetClass'
                          )}`}
                        />

                        <AnyFinalFormNumberInput
                          allowNegative={false}
                          className={classes.toAdvisoryInput}
                          decimalScale={0}
                          decimalSeparator={decimalSeparator}
                          disabled={isSessionReadOnly}
                          id={`to-advisory-input-${instrument.id}`}
                          isAllowed={({ floatValue }) => {
                            return financialSituation.amountForAdviceSwitch
                              ? floatValue <= 100
                              : floatValue <= instrument.value;
                          }}
                          name={
                            clientType === ClientTypes.person
                              ? `personFinancialSituation.${selectedItem.id}.${index}.toAdvisory`
                              : `companyFinancialSituation.${selectedItem.id}.${index}.toAdvisory`
                          }
                          suffix={
                            financialSituation.amountForAdviceSwitch
                              ? '%'
                              : undefined
                          }
                          thousandSeparator={thousandSeparator}
                        />
                      </td>

                      {instrument.accountNumber !== undefined && (
                        <td className={classes.cell}>
                          {instrument.accountNumber}
                        </td>
                      )}
                      {instrument.accountNumber === undefined &&
                        instruments.some(i => i?.accountNumber) && (
                          <td className={classes.cell} />
                        )}

                      {isExistingPortfolioVisible && (
                        <>
                          {instrument.isTaggedAutomatically ? (
                            <td
                              className={classNames(
                                classes.cell,
                                classes.right
                              )}
                            >
                              {instrument.assetClass.SubAssetClass}
                            </td>
                          ) : (
                            <td className={classes.cell}>
                              <HiddenLabel
                                id={`select-asset-class-label-${instrument.id}`}
                                inputId={`select-asset-class-input-${instrument.id}`}
                                text={i18n(
                                  'roboAdvice.financialSituation.amountForAdvice.selectAssetClass'
                                )}
                              />

                              <FinalFormSelect
                                ariaLabelledBy={`select-asset-class-label-${instrument.id}`}
                                id={`select-asset-class-input-${instrument.id}`}
                                name={
                                  clientType === ClientTypes.person
                                    ? `personFinancialSituation.${selectedItem.id}.${index}.assetClass`
                                    : `companyFinancialSituation.${selectedItem.id}.${index}.assetClass`
                                }
                                options={assetClassOptions}
                                placeholder={i18n(
                                  'roboAdvice.financialSituation.amountForAdvice.selectAssetClass'
                                )}
                                determinativeMenuPlacementElementTopOffset={
                                  modalOffset
                                }
                                width={290}
                              />
                            </td>
                          )}
                        </>
                      )}
                    </tr>
                  ) : null;
                })}
            </>
          )}
        />
      </Scrollbars>
    </Modal>
  );
};

export default GranularInformationModal;
