import create, { UseBoundStore } from 'zustand';

import { AdminTranslationPayload, TranslationPayload } from '../api';

export type Translation = {
  application: string;
  language: string;
  name: string;
  description: string;
  default: string;
  value: string;
  is_overruled: boolean;
};

export type TranslationsStoreData = {
  translations: Translation[];
  searchedTranslations: Translation[];
  isReadTranslationsPending: boolean;
  isSaveTranslationsPending: boolean;
  isSearchModeActive: boolean;
  isAdminModeActive: boolean;
};

export type TranslationsStoreApi = {
  reset: () => void;
  setTranslations: (translations: Translation[]) => void;
  setSearchedTranslations: (indexes: number[]) => void;
  updateTranslations: (translations: TranslationPayload[]) => void;
  updateAdminTranslations: (translations: AdminTranslationPayload[]) => void;
  setIsReadTranslationsPending: (isReadTranslationsPending: boolean) => void;
  setIsSaveTranslationsPending: (isSaveTranslationsPending: boolean) => void;
  setIsSearchModeActive: (isSearchModeActive: boolean) => void;
  setIsAdminModeActive: (isSearchModeActive: boolean) => void;
};

export type TranslationsStoreState = TranslationsStoreData &
  TranslationsStoreApi;

export type UseTranslationsStore = UseBoundStore<TranslationsStoreState>;

export const defaultData = {
  translations: [],
  searchedTranslations: [],
  isReadTranslationsPending: false,
  isSaveTranslationsPending: false,
  isSearchModeActive: false,
  isAdminModeActive: false
};

export const useTranslationsStore = create<TranslationsStoreState>(set => ({
  ...defaultData,
  reset() {
    set(defaultData);
  },
  setTranslations(translations) {
    set({ translations });
  },
  updateTranslations: translations =>
    set(state => {
      const currentTranslations = [...state.translations];
      translations.forEach(translation => {
        const index = currentTranslations.findIndex(
          ({ name }) => name === translation.name
        );
        if (index !== -1) {
          const updatedItem = {
            ...currentTranslations[index],
            value: translation['value'] || currentTranslations[index].default,
            is_overruled:
              !!translation['value'] &&
              translation['value'] !== currentTranslations[index].default
          };
          currentTranslations[index] = updatedItem;
        }
      });
      return { translations: currentTranslations };
    }),
  updateAdminTranslations: translations =>
    set(state => {
      const currentTranslations = [...state.translations];
      translations.forEach(translation => {
        const index = currentTranslations.findIndex(
          ({ name }) => name === translation.name
        );
        if (index !== -1) {
          const updatedItem = {
            ...currentTranslations[index],
            default: translation.default,
            description: translation.description
          };
          currentTranslations[index] = updatedItem;
        }
      });
      return { translations: currentTranslations };
    }),
  setSearchedTranslations: indexes =>
    set(state => {
      const searchedTranslations = indexes.map(
        index => state.translations[index]
      );
      return { searchedTranslations };
    }),
  setIsReadTranslationsPending(isReadTranslationsPending) {
    set({ isReadTranslationsPending });
  },
  setIsSaveTranslationsPending(isSaveTranslationsPending) {
    set({ isSaveTranslationsPending });
  },
  setIsSearchModeActive(isSearchModeActive) {
    set({ isSearchModeActive });
  },
  setIsAdminModeActive(isAdminModeActive) {
    set({ isAdminModeActive });
  }
}));
