import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import { postSettings } from '../../../shared/api';
import { Statuses } from '../../../shared/constants';
import { useProposalStore } from '../services/proposalStore';
import { ProposalsForm } from '../services/proposalsForm';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useUpdateSettings() {
  const i18n = useI18n();
  const auth0AccessToken: string = useSelector(
    sessionSelectors.getAuth0AccessToken
  );

  const dispatch = useDispatch();

  const updateSettings = async () => {
    const proposalStore = useProposalStore.getState();

    try {
      proposalStore.setPageStatus('updateSettings', Statuses.pending);

      const { initialValues, values } = ProposalsForm.getState();

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        undefined
      );

      const [postSettingsResponse] = await Promise.all([
        postSettings(
          accessToken,
          undefined,
          Object.keys(values).map(key => ({
            application: 'deepalpha',
            name: key,
            value: values[key]
          }))
        )
      ]);

      if (!postSettingsResponse.data.ok) {
        throw new Error(postSettingsResponse.data.error);
      }

      ProposalsForm.initialize({
        ...values
      });

      proposalStore.setPageStatus('updateSettings', Statuses.succeed);

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n(
            'admin.appAdmin.advisorSolution.visuals.updateSettingsSuccessMessage'
          ),
          type: NotificationTypes.success
        })
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        proposalStore.setPageStatus('updateSettings', Statuses.failed);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'admin.appAdmin.advisorSolution.visuals.updateSettingsErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return updateSettings;
}
