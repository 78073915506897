export const Typography = {
  heading1: {
    size: 20,
    lineHeight: '24px'
  },
  heading2: {
    size: 18,
    lineHeight: '21px'
  },
  body1: {
    size: 16,
    lineHeight: '19px'
  },
  body2: {
    size: 14,
    lineHeight: '16px'
  },
  body3: {
    size: 12,
    lineHeight: '14px'
  },
  subtitle: {
    size: 10,
    lineHeight: '12px'
  }
} as const;

export const FontWeights = {
  light: '300',
  normal: '400',
  medium: '500',
  bold: '700'
} as const;

export const FontFamilies = {
  roboto: "'Roboto', sans-serif"
};
