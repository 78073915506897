import { goalIcons } from '@quantfoliorepo/ui-components';

import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  iconContainer: {
    height: 30,
    width: 30,

    '& > img': {
      maxWidth: 30,
      maxHeight: 30
    }
  }
});

const GoalTableIcon = ({ name = 'icon', icon }) => {
  const classes = useStyles();

  const tableIcon = goalIcons.find(({ name }) => name === icon)?.tableIcon;
  const urlIcon = icon && <img src={icon} alt={name} />;
  const iconToDisplay = tableIcon || urlIcon;

  return iconToDisplay ? (
    <div className={classes.iconContainer}>{iconToDisplay}</div>
  ) : null;
};

export default GoalTableIcon;
