import * as R from 'ramda';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useIsSessionReadOnly } from '../../main/services/selectors';
import {
  useReadDataListener,
  PageStatuses
} from '../../shared/components/useReadDataListener';
import { usePageStore as useProposalPageStore } from '../services/pageStore';
import ActionButtons from './actionButtons.js';
import CashflowChart from './cashflowChart/index.js';
import CashflowTable from './cashflowTable.js';
import CostChartView from './costChart/index.js';
import EfficientFrontierChart from './efficientFrontierChart/index.js';
import ExpectedPathChart from './expectedPathChart/index.js';
import ExpectedValueChart from './expectedValueChart/index.js';
import GenerateNewPdfReportModal from './generateNewPdfReportModal/components/generateNewPdfReportModal';
import GenerateReportModal from './generateReportModal/generateReportModal';
import GoalsTable from './goalsTable.js';
import HistoricalReturnChart from './historicalReturnChart/index.js';
import OrderSummary from './orderSummary';
import { useReadPortfolioChartData } from './portfolioChart/useReadPortfolioChartData';
import PortfolioChartGrid from './portfolioChartGrid/index.js';
import SelectPortfolioButtons from './selectPortfolioButtons';
import Sustainability from './sustainability';
import TransactionList from './transactionList';
import { useInitPage } from './useInitPage';
import { useReadProposalPageData } from './useReadProposalPageData';
import { RoboAdviceAdviceSessionPages } from 'features/roboAdvice/shared/constants';
import AdvisorNotes from 'features/shared/components/advisorNotes';
import ButtonLink from 'features/shared/components/button/buttonLink';
import {
  carouselIds,
  useCarouselStore
} from 'features/shared/components/carousel/carouselStore';
import {
  Carousel,
  CarouselBody,
  CarouselItem,
  CarouselTab,
  CarouselTabs
} from 'features/shared/components/carousel/verticalNavigation';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  section: {
    position: 'relative',
    marginBottom: Spacing.spacing04
  },
  sectionHeader: {
    fontWeight: FontWeights.medium,
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    marginBottom: Spacing.spacing01
  },
  chartsColumn: {
    marginTop: Spacing.spacing00
  },
  holisticViewButton: {
    position: 'absolute',
    top: '0',
    right: '0'
  },
  advisorNotes: {
    marginTop: Spacing.spacing04
  },
  carouselRoot: {
    padding: Spacing.spacing02
  }
}));

const Proposal = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const {
    analyticsComponents,
    tools: {
      holisticView: { enabled: isHolisticViewEnabled }
    }
  } = useCustomerConfig();
  const proposalPageStore = useProposalPageStore();
  const carouselStore = useCarouselStore();
  const { clientType, clientId, adviceSessionId } = useParams();

  useInitPage();

  useReadDataListener(
    proposalPageStore.pageStatuses.readPortfolioChartData,
    true,
    useReadPortfolioChartData()
  );

  useReadDataListener(
    proposalPageStore.pageStatuses.readProposalPageData,
    proposalPageStore.pageStatuses.readPortfolioChartData ===
      PageStatuses.succeed,
    useReadProposalPageData()
  );

  const isSessionReadOnly = useIsSessionReadOnly();

  const carouselElements = [
    {
      tab: 'roboAdvice.proposal.portfolio',
      item: <PortfolioChartGrid />,
      dataTestId: 'portfolio-chart-card',
      dataTestIdTab: 'portfolio-tab',
      display: !analyticsComponents.isPortfolioHidden,
      statusKeys: ['readPortfolioChartData'],
      order: analyticsComponents.order.portfolio
    },
    {
      tab: 'roboAdvice.proposal.historicalReturn',
      item: <HistoricalReturnChart />,
      key: 'historical-return-chart-card',
      dataTestId: 'historical-return-chart-card',
      display: !analyticsComponents.isHistoricalReturnHidden,
      statusKeys: [
        'readHistoricalReturn3MChartData',
        'readHistoricalReturnYTDChartData',
        'readHistoricalReturn1YChartData',
        'readHistoricalReturn3YChartData',
        'readHistoricalReturn5YChartData',
        'readHistoricalReturn10YChartData'
      ],
      order: analyticsComponents.order.historicalReturn
    },
    {
      tab: 'roboAdvice.proposal.expectedPath',
      item: <ExpectedPathChart />,
      dataTestId: 'expected-path-chart-card',
      display: !analyticsComponents.isExpectedPathHidden,
      statusKeys: ['readExpectedPathChartData'],
      order: analyticsComponents.order.expectedPath
    },
    {
      tab: 'roboAdvice.proposal.expectedValue',
      item: <ExpectedValueChart />,
      key: 'expected-value-chart-card',
      dataTestId: 'expected-value-chart-card',
      display: !analyticsComponents.isExpectedValueHidden,
      statusKeys: ['readExpectedValueChartData'],
      order: analyticsComponents.order.expectedValue
    },
    {
      tab: 'roboAdvice.proposal.efficientFrontier',
      item: <EfficientFrontierChart />,
      key: 'efficient-frontier-chart-card',
      dataTestId: 'efficient-frontier-chart-card',
      display: !analyticsComponents.isEfficientFrontierHidden,
      statusKeys: ['readEfficientFrontierChartData'],
      order: analyticsComponents.order.efficientFrontier
    },
    {
      tab: 'roboAdvice.proposal.cost',
      item: <CostChartView />,
      key: 'cost-chart-card',
      dataTestId: 'cost-chart-card',
      display: !analyticsComponents.isCostHidden,
      statusKeys: ['readCostChartData'],
      order: analyticsComponents.order.cost
    },
    {
      tab: 'roboAdvice.proposal.cashflow',
      item: <CashflowChart />,
      key: 'cashflow-chart-card',
      dataTestId: 'cashflow-chart-card',
      display: !analyticsComponents.isCashflowHidden,
      statusKeys: ['readCashflowData'],
      order: analyticsComponents.order.cashflow
    },
    {
      tab: 'roboAdvice.proposal.sustainability',
      item: <Sustainability />,
      key: 'sustainability-card',
      dataTestId: 'sustainability-card',
      display: !analyticsComponents.isSustainabilityHidden,
      order: analyticsComponents.order.sustainability
    },
    {
      tab: 'roboAdvice.proposal.orderSummary',
      item: <OrderSummary />,
      key: 'order-summary-card',
      dataTestId: 'order-summary-card',
      display: !analyticsComponents.isOrderSummaryHidden,
      order: analyticsComponents.order.orderSummary,
      statusKeys: ['readOrderSummary']
    },
    {
      tab: 'roboAdvice.proposal.transactionList.transactionList',
      item: <TransactionList />,
      key: 'transaction-list-card',
      dataTestId: 'transaction-list-card',
      display: !analyticsComponents.isTransactionListHidden,
      order: analyticsComponents.order.transactionList,
      statusKeys: ['readTransactionList']
    }
  ]
    .filter(({ display }) => display)
    .sort((a, b) => a.order - b.order);

  useEffect(() => {
    carouselStore.setSelectedItemIndex(carouselIds.proposal, 0);

    return () => {
      carouselStore.setSelectedItemIndex(carouselIds.proposal, 0);
    };
  }, [clientId, adviceSessionId]);

  const getIsCarouselPending = React.useCallback(
    selectedItemIndex => {
      const statusKeys = carouselElements[selectedItemIndex]?.statusKeys;

      return (
        (R.isNil(statusKeys)
          ? false
          : R.any(
              statusKey =>
                proposalPageStore.pageStatuses[statusKey] ===
                PageStatuses.pending,
              statusKeys
            )) ||
        proposalPageStore.pageStatuses.readProposalPageData ===
          PageStatuses.pending
      );
    },
    [proposalPageStore.pageStatuses]
  );

  return (
    <div
      role="tabpanel"
      aria-labelledby={`tab-id-${RoboAdviceAdviceSessionPages.proposal}`}
      id={`panel-id-${RoboAdviceAdviceSessionPages.proposal}`}
    >
      <GenerateReportModal />
      <GenerateNewPdfReportModal />

      <div className={classes.section}>
        <div className={classes.sectionHeader}>
          {i18n('roboAdvice.proposal.goals')}
        </div>
        {isHolisticViewEnabled && (
          <ButtonLink
            to={routeTemplates.toolsHolisticView.build(
              clientType,
              clientId,
              adviceSessionId
            )}
            className={classes.holisticViewButton}
          >
            {i18n('tools.holisticView')}
          </ButtonLink>
        )}
        <GoalsTable />
      </div>

      {!analyticsComponents.isCashflowHidden && (
        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            {i18n('roboAdvice.proposal.cashflow')}
          </div>

          <CashflowTable />
        </div>
      )}

      <div className={classes.section}>
        <div className={classes.sectionHeader}>
          {i18n('roboAdvice.proposal.portfolio')}
        </div>
        <div>
          <SelectPortfolioButtons />
        </div>

        {!!carouselElements.length && (
          <div className={classes.chartsColumn}>
            <Carousel
              getIsPending={getIsCarouselPending}
              className={classes.carouselRoot}
              carouselId={carouselIds.proposal}
            >
              <CarouselTabs carouselId={carouselIds.proposal}>
                {carouselElements.map(({ tab, dataTestId, dataTestIdTab }) => (
                  <CarouselTab data-testid={dataTestIdTab} key={dataTestId}>
                    {i18n(tab)}
                  </CarouselTab>
                ))}
              </CarouselTabs>
              <CarouselBody carouselId={carouselIds.proposal}>
                {carouselElements.map(({ dataTestId, item }) => (
                  <CarouselItem key={dataTestId} data-testid={dataTestId}>
                    {item}
                  </CarouselItem>
                ))}
              </CarouselBody>
            </Carousel>
          </div>
        )}
      </div>

      <AdvisorNotes
        name={'proposalAdvisorNotes'}
        disabled={isSessionReadOnly}
        header={i18n('roboAdvice.proposal.notes')}
        customPlaceholder={i18n('roboAdvice.proposal.notes.placeholder')}
        infoTooltipText={i18n('roboAdvice.proposal.notes.infoTooltip')}
        className={classes.advisorNotes}
      />
      <ActionButtons />
    </div>
  );
};

export default Proposal;
