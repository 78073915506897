import { useWindowSize } from 'usehooks-ts';

import { useSessionStore } from '../../../session/services/sessionStore';
import { usePageStore as useProposalPageStore } from '../../services/pageStore';
import GenerateReportForm from './generateReportForm';
import { useDownloadPdfReport } from './useDownloadPdfReport';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import Button from 'features/shared/components/button';
import Modal from 'features/shared/components/modal';
import { useModalBodyMaxDimensions } from 'features/shared/components/modal/useModalBodyMaxDimensions';
import Scrollbars from 'features/shared/components/scrollbars';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

const GenerateReportModal = () => {
  const i18n = useI18n();
  const proposalPageStore = useProposalPageStore();
  const sessionStore = useSessionStore();
  const downloadPdfReport = useDownloadPdfReport();
  const { width } = useWindowSize();
  const { modalBodyMaxHeight, headerRef, footerRef } =
    useModalBodyMaxDimensions();

  const isButtonDisabled =
    !sessionStore.isSessionInitialized ||
    proposalPageStore.pageStatuses.readProposalPageData !==
      PageStatuses.succeed ||
    proposalPageStore.isReadPdfReportPending;

  return (
    <Modal
      footer={
        <>
          <Button
            disabled={isButtonDisabled}
            onClick={() => {
              downloadPdfReport();
            }}
          >
            {i18n('roboAdvice.proposal.generateReport.generateReport')}
          </Button>
        </>
      }
      header={i18n('roboAdvice.proposal.generateReport.selectComponents')}
      isOpen={proposalPageStore.isGenerateReportPopupOpen}
      onRequestClose={() => {
        proposalPageStore.setIsGenerateReportPopupOpen(false);
      }}
      disabled={proposalPageStore.isReadPdfReportPending}
      headerRef={headerRef}
      footerRef={footerRef}
    >
      {width < 1024 ? (
        <Scrollbars autoHeightMax={modalBodyMaxHeight}>
          <GenerateReportForm />
        </Scrollbars>
      ) : (
        <GenerateReportForm modalBodyMaxHeight={modalBodyMaxHeight} />
      )}
    </Modal>
  );
};

export default GenerateReportModal;
