import { useClientForm } from '../../main/services/clientForm';
import { useClientPageStore } from '../../main/services/clientPageStore';
import { useSaveKYC } from './useSaveKYC';
import Button from 'features/shared/components/button';
import { Spacing } from 'features/shared/constants/spacing';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: Spacing.spacing04
  },
  actionButton: {
    '& + &': {
      marginLeft: '2.4rem'
    }
  }
});

const SaveButton = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const saveKYC = useSaveKYC();
  const clientPageStore = useClientPageStore();
  const { pristine } = useClientForm();

  return (
    <div className={classes.actionButtons}>
      <Button
        disabled={clientPageStore.isUpdateClientPending || pristine}
        className={classes.actionButton}
        onClick={() => saveKYC()}
      >
        {i18n('roboAdvice.client.save')}
      </Button>
    </div>
  );
};

export default SaveButton;
