import * as R from 'ramda';
import React, { useReducer, useEffect } from 'react';
import { Manager, Reference, Popper as ReactPopperPopper } from 'react-popper';

import { useLazyEffect } from 'features/shared/hooks/useLazyEffect.js';

const actionTypes = {
  TOGGLE_POPPER: 'TOGGLE_POPPER'
};

const initialState = { isPopperOpen: false };

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_POPPER: {
      return R.assoc('isPopperOpen', action.payload, state);
    }
    default:
      return state;
  }
};

const Popper = ({
  isOpen,
  referenceProps = {},
  popperProps = {},
  referenceDataId,
  popperDataId,
  reference,
  popper,
  onOpen,
  onClose
} = {}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useLazyEffect(() => {
    if (state.isPopperOpen) {
      !R.isNil(onOpen) && onOpen();
    } else {
      !R.isNil(onClose) && onClose();
    }
  }, [state.isPopperOpen]);

  useEffect(() => {
    const listener = e => {
      if (
        e.target.closest(`[data-id="${popperDataId}"]`) ||
        e.target.closest(`[data-id="${referenceDataId}"]`) ||
        !document.body.contains(e.target)
      ) {
        return;
      }

      dispatch({
        type: actionTypes.TOGGLE_POPPER,
        payload: false
      });
    };
    window.addEventListener('click', listener);

    return () => {
      window.removeEventListener('click', listener);
    };
  }, []);

  const isPopperOpen = state.isPopperOpen || isOpen;

  return (
    <Manager>
      <Reference {...referenceProps}>
        {({ ref }) =>
          reference({
            ref,
            isPopperOpen,
            togglePopper: isPopperOpen => {
              dispatch({
                type: actionTypes.TOGGLE_POPPER,
                payload: isPopperOpen
              });
            },
            referenceDataId: referenceDataId
          })
        }
      </Reference>
      {isPopperOpen && (
        <ReactPopperPopper {...popperProps}>
          {({ ref, style, placement, scheduleUpdate }) =>
            popper({
              ref,
              style,
              placement,
              isPopperOpen,
              togglePopper: isPopperOpen => {
                dispatch({
                  type: actionTypes.TOGGLE_POPPER,
                  payload: isPopperOpen
                });
              },
              popperDataId: popperDataId,
              scheduleUpdate
            })
          }
        </ReactPopperPopper>
      )}
    </Manager>
  );
};

export default Popper;
