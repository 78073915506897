import classNames from 'classnames';
import * as R from 'ramda';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { useSessionStore } from '../../session/services/sessionStore';
import { useGoalsStore } from '../../shared/services/goalsStore';
import {
  getReactionIfMarketFailsScoreColumns,
  useReactionIfMarketFailsScoreItems,
  getAbilityToBearLossesScoreColumns,
  useAbilityToBearLossesScoreItems,
  getExperienceScoreColumns,
  useExperienceScoreItems
} from '../services/selectors.js';
import Legend from './legend';
import NumberCell from './numberCell.js';
import { useReadRiskScoreSettingsListener } from './useReadRiskScoreSettingsListener';
import Table from 'features/shared/components/table/index.js';
import { Colors } from 'features/shared/constants/colors.js';
import { Typography } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  table: {
    '& + &': {
      marginTop: '30px'
    }
  },
  numberHeaderCell: {
    textAlign: 'right',
    padding: '1.6rem 2.1rem'
  },
  extraHeaderCell: {
    textAlign: 'center',
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight,
    paddingBottom: '0'
  },
  numberExtraHeaderCell: {
    padding: '1.6rem 2.1rem 0 2.1rem'
  },
  noDataInfo: {
    color: Colors.gray50,
    textAlign: 'center',
    minWidth: '280px'
  }
});

export const KnowledgeAndExperienceDetailsPopover = ({ scheduleUpdate }) => {
  const classes = useStyles();
  const i18n = useI18n();
  const { goals } = useGoalsStore();
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const reactionIfMarketFailsScoreColumns = useSelector(
    getReactionIfMarketFailsScoreColumns
  );
  const reactionIfMarketFailsScoreItems = useReactionIfMarketFailsScoreItems();
  const abilityToBearLossesScoreColumns = useSelector(
    getAbilityToBearLossesScoreColumns
  );
  const abilityToBearLossesScoreItems = useAbilityToBearLossesScoreItems();
  const experienceScoreColumns = useSelector(state =>
    getExperienceScoreColumns(state, { i18n })
  );
  const experienceScoreItems = useExperienceScoreItems();
  const {
    roboAdviceForm: {
      riskQuestions: { isRisk2Hidden }
    }
  } = useCustomerConfig();
  const sessionStore = useSessionStore();

  React.useLayoutEffect(() => {
    scheduleUpdate();
  }, [sessionStore.sessionStatuses.readRiskScoreSettings]);

  useReadRiskScoreSettingsListener();

  const questions = React.useMemo(() => {
    const questions = R.reject(
      q => R.isEmpty(q.options),
      [
        {
          id: 1,
          title: i18n('roboAdvice.riskScore.reactionIfMarketFails'),
          columns: reactionIfMarketFailsScoreColumns,
          columnsGroup: i18n('roboAdvice.riskScore.riskClasses'),
          options: reactionIfMarketFailsScoreItems
        },
        {
          id: 2,
          title: i18n('roboAdvice.riskScore.abilityToBearLosses'),
          columns: abilityToBearLossesScoreColumns,
          columnsGroup: i18n('roboAdvice.riskScore.riskClasses'),
          options: abilityToBearLossesScoreItems
        },
        !isRisk2Hidden
          ? {
              id: 3,
              title: i18n('roboAdvice.riskScore.experience'),
              columns: experienceScoreColumns,
              options: experienceScoreItems
            }
          : { options: [] }
      ]
    );

    return questions;
  }, [
    reactionIfMarketFailsScoreItems,
    abilityToBearLossesScoreItems,
    experienceScoreItems,
    isRisk2Hidden
  ]);

  return (
    <div data-testid={'knowledge-and-experience-details-popover'}>
      {R.isEmpty(questions) && (
        <div className={classes.noDataInfo}>
          {i18n('roboAdvice.riskScore.noKnowledgeAndExperienceData')}
        </div>
      )}
      {!R.isEmpty(questions) &&
        questions.map(question => (
          <div key={question.id} className={classes.table}>
            <Table
              data-testid={`knowledge-and-experience-details-${question.id}-table`}
              header={[
                { title: question.title },
                ...question.columns.map(column => ({
                  title: column,
                  className: classes.numberHeaderCell
                }))
              ]}
              extraHeader={
                !R.isNil(question.columnsGroup)
                  ? [
                      { title: '', className: classes.extraHeaderCell },
                      {
                        title: question.columnsGroup,
                        className: classNames(
                          classes.extraHeaderCell,
                          classes.numberExtraHeaderCell
                        ),
                        colSpan: question.columns.length
                      }
                    ]
                  : null
              }
              items={question.options.map((option, optionIndex) => ({
                id: optionIndex,
                cells: ({ bodyRowCellClassName }) => (
                  <Fragment>
                    <td className={bodyRowCellClassName}>{option.title}</td>
                    {option.values.map((value, valueIndex) => (
                      <NumberCell
                        key={valueIndex}
                        value={value}
                        cultureCode={cultureCode}
                        className={bodyRowCellClassName}
                        backgroundStylesContainer
                      />
                    ))}
                  </Fragment>
                )
              }))}
            />
          </div>
        ))}
      {goals.length && <Legend />}
    </div>
  );
};

export default KnowledgeAndExperienceDetailsPopover;
