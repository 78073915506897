import * as R from 'ramda';

import { adviceSessionPatchQueue } from '../../adviceSessionPatchQueue';
import { useSessionStore } from '../../session/services/sessionStore';
import { SessionPatchTypes } from '../../shared/constants';
import { usePatchAdviceSession } from './usePatchAdviceSession';

export function useQueuedPatchAdviceSession() {
  const patchAdviceSession = usePatchAdviceSession();
  const queuedPatchAdviceSession =
    adviceSessionPatchQueue.useQueuedPatch(patchAdviceSession);

  return async patch => {
    const sessionStore = useSessionStore.getState();

    if (
      patch.type === SessionPatchTypes.saveSessionData ||
      patch.type === SessionPatchTypes.saveFinishedSessionData
    ) {
      if (R.includes(patch.namespace, sessionStore.namespaces)) {
        await queuedPatchAdviceSession({
          ...patch,
          isNew: false
        });
      } else {
        await queuedPatchAdviceSession({
          ...patch,
          isNew: true
        });
        sessionStore.addNamespace(patch.namespace);
      }
    } else {
      await queuedPatchAdviceSession(patch);
    }
  };
}
