import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { reduxForm } from 'redux-form';

import { NewCustomerId } from '../../shared/constants/index.js';
import { creators } from '../services/actions.js';
import { getTitleValidationError } from '../services/selectors/editCustomerForm.js';
import Licenses from './licenses.js';
import Prompt from './prompt.js';
import Users from './users.js';
import Button, { ButtonType } from 'features/shared/components/button';
import ErrorMessage from 'features/shared/components/errorMessage/index.js';
import { Field, FieldArray } from 'features/shared/components/form/index.js';
import TextInput from 'features/shared/components/textInput/index';
import { Colors } from 'features/shared/constants/colors.js';
import formSelectors from 'features/shared/services/form/selectors.js';
import statusSelectors from 'features/shared/services/status/selectors.js';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { injectSheet } from 'features/sharedModules/styles/components/styles';

const styles = {
  content: {
    maxWidth: '100rem',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  header: {
    margin: '3rem 0'
  },
  section: {
    display: 'flex',
    alignItems: 'baseline',

    '& + &': {
      marginTop: '3rem'
    }
  },
  label: {
    color: Colors.gray50,
    marginRight: '2.4rem',
    flex: '0 0 auto',
    width: '14.4rem'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '3rem'
  },
  actionButton: {
    '& + &': {
      marginLeft: '2.4rem'
    }
  },
  customerNameInput: {
    width: '40rem'
  },
  customerCodeInput: {
    width: '40rem'
  },
  advisoryKeyInput: {
    width: '40rem'
  }
};

const ReduxFormTextInput = ({
  input: { value, onChange, onFocus, onBlur },
  meta: { touched },
  placeholder,
  className,
  getValidationError
}) => {
  const i18n = useI18n();
  const error = getValidationError && getValidationError(value, i18n);
  const isErrorShown = touched && !R.isNil(error) && !R.isEmpty(error);

  return (
    <div>
      <TextInput
        value={value || ''}
        onChange={e => onChange(e.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        className={className}
        isInvalid={isErrorShown}
      />
      {isErrorShown && <ErrorMessage>* {error}</ErrorMessage>}
    </div>
  );
};

const CustomerManagement = ({
  classes,
  history,
  saveCustomerManagementForm,
  cancelCustomerManagementFormChanges,
  customerId,
  saveCustomerStatus
}) => {
  const i18n = useI18n();

  return (
    <div className={classes.content}>
      <Prompt />
      <div className={classes.header}>
        {customerId === NewCustomerId
          ? i18n('customers.addCustomer')
          : i18n('customers.editCustomer')}
      </div>
      <div className={classes.section}>
        <span className={classes.label}>
          {i18n('customers.customerManagement.customerName')}
        </span>
        <div>
          <Field
            name="title"
            component={ReduxFormTextInput}
            placeholder={i18n('customers.customerManagement.customerName')}
            className={classes.customerNameInput}
            getValidationError={getTitleValidationError}
          />
        </div>
      </div>
      <div className={classes.section}>
        <span className={classes.label}>
          {i18n('customers.customerManagement.customerCode')}
        </span>
        <div>
          <Field
            name="code"
            component={ReduxFormTextInput}
            placeholder={i18n('customers.customerManagement.customerCode')}
            className={classes.customerCodeInput}
          />
        </div>
      </div>
      <div className={classes.section}>
        <span className={classes.label}>
          {i18n('customers.customerManagement.advisoryApiKey')}
        </span>
        <div>
          <Field
            name="advisoryKey"
            component={ReduxFormTextInput}
            placeholder={i18n('customers.customerManagement.advisoryApiKey')}
            className={classes.advisoryKeyInput}
          />
        </div>
      </div>
      <FieldArray name="licenses" component={Licenses} pageClasses={classes} />
      <FieldArray name="users" component={Users} pageClasses={classes} />
      <div className={classes.actions}>
        <Button
          className={classes.actionButton}
          buttonType={ButtonType.secondary}
          onClick={() => {
            cancelCustomerManagementFormChanges();
            history.push(routeTemplates.customers.build());
          }}
        >
          {i18n('shared.cancel')}
        </Button>
        <Button
          className={classes.actionButton}
          disabled={saveCustomerStatus.isPending}
          onClick={() => {
            saveCustomerManagementForm();
          }}
        >
          {i18n('shared.save')}
        </Button>
      </div>
    </div>
  );
};

export default R.compose(
  reduxForm({
    form: 'editCustomerForm_state',
    getFormState: formSelectors.getForms
  }),
  withRouter,
  connect(
    (state, props) => {
      const customerId = props.match.params.id;
      const saveCustomerStatus = statusSelectors.getStatusOrDefault(
        customerId === NewCustomerId
          ? 'createCustomerStatus_state'
          : 'updateCustomerStatus_state',
        state
      );

      return {
        customerId,
        saveCustomerStatus
      };
    },
    dispatch => {
      return {
        saveCustomerManagementForm: () => {
          dispatch(creators.saveCustomerManagementForm());
        },
        cancelCustomerManagementFormChanges: () => {
          dispatch(creators.cancelCustomerManagementFormChanges());
        }
      };
    }
  ),
  injectSheet(styles)
)(CustomerManagement);
