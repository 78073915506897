import { useEffect } from 'react';

import { useProposalStore } from '../services/proposalStore';
import { ProposalsForm } from '../services/proposalsForm';
import { useReadSettings } from './useReadSettings';

export function useInitPage() {
  const readSettings = useReadSettings();

  useEffect(() => {
    readSettings();

    return () => {
      useProposalStore.getState().reset();
      ProposalsForm.restartToInitialValues();
    };
  }, []);
}
