import { useField } from './useField';
import SelectionCards, {
  Props
} from 'features/shared/components/selectionCards';

type FinalFormProps = {
  name: string;
  fieldConfig?: any;
};

const FinalFormSelectionCards = ({
  name,
  fieldConfig,
  ...restProps
}: FinalFormProps & Omit<Props, 'values' | 'onChange'>) => {
  const {
    input: { value, onChange }
  } = useField(name, fieldConfig);

  return <SelectionCards values={value} onChange={onChange} {...restProps} />;
};

export default FinalFormSelectionCards;
