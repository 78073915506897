import { FloatingArrow, Placement } from '@floating-ui/react';
import * as React from 'react';

import { ARROW_HEIGHT } from './constants';
import TooltipContent from './content';
import { TooltipContext } from './context';
import TooltipTrigger from './trigger';
import { useTooltip } from './useTooltip';
import { useTheme } from 'features/sharedModules/styles/components/styles';

type Props = {
  /**
   * Tooltip content.
   */
  content: React.ReactNode;
  /**
   * Should tooltip be initially opened?
   */
  initialOpen?: boolean;
  /**
   * Should tooltip use info tooltip styles?
   */
  infoTooltip?: boolean;
  /**
   * How tooltip should be aligned?
   */
  placement?: Placement;
  /**
   * Is tooltip open?
   */
  open?: boolean;
  /**
   * Should center of the tooltip be placed in top right corner of trigger?
   */
  topCorner?: boolean;
  /**
   * Node, which trigger tooltip.
   */
  trigger: React.ReactNode;
  /**
   * Additional styles for trigger button.
   */
  triggerButtonStyles?: React.CSSProperties;
  /**
   *  State setter called when the open state changes.
   */
  onOpenChange?: (open: boolean) => void;
  /**
   * Function called when the trigger is clicked.
   */
  triggerOnClick?: () => void;
  /**
   * Tooltip content maximum width.
   */
  contentMaxWidth?: number;
  /**
   * Tooltip content minimum width.
   */
  contentMinWidth?: number;
  /**
   * Tooltip content styles.
   */
  contentStyles?: React.CSSProperties;
};

export const Tooltip = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      content,
      infoTooltip,
      initialOpen,
      open,
      placement = 'top',
      trigger,
      triggerButtonStyles,
      topCorner,
      contentMinWidth,
      contentMaxWidth,
      contentStyles,
      triggerOnClick,
      onOpenChange
    },
    propRef
  ) => {
    const arrowRef = React.useRef<SVGSVGElement | null>(null);
    const theme = useTheme();
    const tooltip = useTooltip({
      arrowRef,
      initialOpen,
      open,
      placement,
      topCorner,
      onOpenChange
    });

    return (
      <TooltipContext.Provider value={tooltip}>
        <TooltipTrigger style={triggerButtonStyles} onClick={triggerOnClick}>
          {trigger}
        </TooltipTrigger>

        <TooltipContent
          ref={propRef}
          infoTooltip={infoTooltip}
          maxWidth={contentMaxWidth}
          minWidth={contentMinWidth}
          contentStyles={contentStyles}
        >
          {content}

          <FloatingArrow
            ref={arrowRef}
            context={tooltip.context}
            height={ARROW_HEIGHT}
            width={16}
            fill={
              infoTooltip ? theme.primaryColor : theme.itemBackgroundColor_1
            }
            staticOffset={
              tooltip.middlewareData.arrow?.centerOffset !== 0
                ? (tooltip.middlewareData.offset?.x || 0) +
                  (tooltip.middlewareData.arrow?.centerOffset || 0)
                : (tooltip.middlewareData.arrow.x || 0) +
                  (tooltip.middlewareData.offset?.x || 0)
            }
            stroke={theme.accentColor}
            strokeWidth={infoTooltip ? 0 : 1}
          />
        </TooltipContent>
      </TooltipContext.Provider>
    );
  }
);

export default Tooltip;
