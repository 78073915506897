import * as R from 'ramda';
import create from 'zustand';

import { Status } from '../../../shared/types';

export type PageStatuses = {
  readSettings?: Status;
  updateSettings?: Status;
};

export type VisualsStoreData = {
  pageStatuses: PageStatuses;
};

export type VisualsStoreApi = {
  reset: () => void;
  setPageStatus: <K extends keyof PageStatuses>(
    key: K,
    value: PageStatuses[K]
  ) => void;
};

export type VisualsStoreState = VisualsStoreData & VisualsStoreApi;

export const defaultData = {
  pageStatuses: {}
};

export const useVisualsStore = create<VisualsStoreState>(set => ({
  ...defaultData,
  reset() {
    set(defaultData);
  },
  setPageStatus(key, value) {
    set(R.set(R.lensPath(['pageStatuses', key]), value), true);
  }
}));
