import axios from 'axios';
import { isNil } from 'ramda';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useHolisticViewStore } from '../services/holisticViewStore';
import {
  mapKnowledgeAndExperienceScoreClientDataToServer,
  mapPurposeAndRiskScoreClientDataToServer,
  mapRiskScoreServerDataToClient
} from 'features/roboAdvice/adviceSession/riskScore/services/sagas/readRiskScore';
import { getRisk } from 'features/roboAdvice/adviceSession/shared/api';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export const useReadRiskScores = () => {
  const i18n = useI18n();
  const dispatch = useDispatch();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<any>();
  const {
    riskEndpointType,
    timeHorizonConfig: { type: timeHorizonType, items }
  } = useCustomerConfig();

  const readRiskScores = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const holisticViewStoreState = useHolisticViewStore.getState();
    const selectedClients = holisticViewStoreState.availableClients.filter(
      c =>
        c.isSelected &&
        c.financialSituationData &&
        c.goals &&
        !c.goals.every(g => g.data.riskScore)
    );

    if (
      holisticViewStoreState.isReadRiskScoresPending ||
      selectedClients.length === 0
    )
      return;

    try {
      holisticViewStoreState.setIsReadRiskScoresPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      for (const client of selectedClients) {
        if (client.goals) {
          for (const goal of client.goals) {
            const purposeAndRiskServerData =
              mapPurposeAndRiskScoreClientDataToServer(
                {
                  expectationOfRisk:
                    client.financialSituationData?.expectationOfRisk,
                  riskStrategy: client.financialSituationData?.riskStrategy
                },
                goal.data.timeHorizon,
                timeHorizonType,
                items
              );
            const knowledgeAndExperienceServerData =
              mapKnowledgeAndExperienceScoreClientDataToServer(
                client.financialSituationData,
                goal.data.timeHorizon,
                timeHorizonType,
                [],
                items
              );
            const [purposeAndRiskResponse, knowledgeAndExperienceResponse] =
              await Promise.all([
                getRisk(
                  accessToken,
                  purposeAndRiskServerData,
                  riskEndpointType
                ),
                getRisk(
                  accessToken,
                  knowledgeAndExperienceServerData,
                  riskEndpointType
                )
              ]);
            const clientData = mapRiskScoreServerDataToClient(
              purposeAndRiskResponse.data,
              knowledgeAndExperienceResponse.data
            );

            holisticViewStoreState.setGoalRiskScore(
              client.id,
              goal.goalId,
              clientData.data
            );
          }
        }
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('tools.holisticView.getRiskScoresError'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    } finally {
      holisticViewStoreState.setIsReadRiskScoresPending(false);
    }
  };

  return readRiskScores;
};
