import { z } from 'zod';

import {
  alignmentCriteriaSchema,
  fieldSchema,
  historicalReturnBenchmarkTypes,
  offModelQuestionSchema,
  orderExecutionProductAttributeSchema
} from './shared';
import {
  namespaceConfigSchema,
  questionSchema
} from 'features/roboAdvice/adviceSession/advisory/types';
import {
  educationalModalsConfigSchema,
  modalOptionsSchema
} from 'features/roboAdvice/adviceSession/sustainability/types';
import { ItemTypes } from 'features/roboAdvice/client/kyc/components/automaticClientClassification/constants';
import { CountriesTypeOptions } from 'features/roboAdvice/shared/components/useCountryDropdownOptions';
import { CountryCode } from 'features/roboAdvice/shared/constants';

const KYCFieldItemSchema = z.object({
  activeValue: z.string(),
  label: z.record(z.string()),
  textField: z
    .object({
      name: z.string(),
      required: z.boolean()
    })
    .optional()
});

const KYCSchema = z.object({
  defaultFields: z.object({
    clientRelationshipPurpose: z
      .object({
        isEnabled: z.boolean(),
        order: z.number(),
        required: z.boolean()
      })
      .optional(),
    moneyOrigin: z
      .object({
        isEnabled: z.boolean(),
        order: z.number(),
        required: z.boolean(),
        items: z.array(KYCFieldItemSchema)
      })
      .optional(),
    clientClassification: z
      .object({
        isEnabled: z.boolean(),
        order: z.number(),
        required: z.boolean(),
        items: z.array(KYCFieldItemSchema)
      })
      .optional(),
    signatureRight: z
      .object({
        isEnabled: z.boolean(),
        order: z.number(),
        required: z.boolean()
      })
      .optional(),
    ubo: z
      .object({
        isEnabled: z.boolean(),
        order: z.number(),
        required: z.boolean()
      })
      .optional(),
    pep: z
      .object({
        isEnabled: z.boolean(),
        order: z.number(),
        required: z.boolean()
      })
      .optional(),
    automaticClientClassification: z
      .object({
        isEnabled: z.boolean(),
        order: z.number()
      })
      .optional()
  }),
  fields: z.array(fieldSchema),
  isKYCEnabled: z.boolean(),
  shouldKYCBlockAdviceSession: z.boolean()
});

const ENINSchema = z.object({
  enin: z.object({
    companyFlags: z.object({
      isEnabled: z.boolean()
    }),
    signatureRight: z.object({
      isEnabled: z.boolean()
    }),
    ubo: z
      .object({
        isEnabled: z.boolean()
      })
      .optional()
  })
});

export type KYC = z.infer<typeof KYCSchema>;

export const roboAdviceSchema = z.object({
  clientInformation: z.object({
    company: z.array(fieldSchema),
    person: z.array(fieldSchema),
    contacts: z.object({
      isContactListEnabled: z.boolean(),
      isUBOEnabled: z.boolean(),
      isSignatureRightsEnabled: z.boolean(),
      required: z.boolean()
    }),
    automaticClientClassification: z
      .object({
        items: z.array(
          z.object({
            title: z.string(),
            id: z.string(),
            header: z.string(),
            type: z.nativeEnum(ItemTypes),
            options: z.array(z.string())
          })
        ),
        educationalModal: modalOptionsSchema,
        checklistModal: z
          .object({
            isCommentEnabled: z.boolean().optional(),
            isCommentRequired: z.boolean().optional(),
            isSimpleModalEnabled: z.boolean().optional(),
            professionalItems: z.array(z.string()).optional(),
            nonProfessionalItems: z.array(z.string()).optional()
          })
          .optional()
      })
      .optional()
  }),
  kyc: z.object({
    company: KYCSchema.merge(ENINSchema),
    person: KYCSchema
  }),
  sustainability: z.object({
    enabled: z.boolean(),
    alignmentCriteria: z.object({
      order: z.number(),
      stepsScheme: z.array(
        z.object({
          order: z.number(),
          value: z.number(),
          name: z.string(),
          label: z.record(z.string())
        })
      )
    }),
    alignmentCriteria1: alignmentCriteriaSchema.optional(),
    alignmentCriteria2: alignmentCriteriaSchema.optional(),
    alignmentCriteria3: alignmentCriteriaSchema.optional(),
    alignmentCriteria4: alignmentCriteriaSchema.optional(),
    alignmentCriteria5: alignmentCriteriaSchema.optional(),
    sustainabilityPreference: z.object({
      enabled: z.boolean(),
      header: z.record(z.string()),
      description: z.record(z.string()),
      order: z.number(),
      config: z.array(
        z.object({
          icon: z.string(),
          id: z.string(),
          title: z.record(z.string())
        })
      ),
      advisorNotes: z.object({
        enabled: z.boolean(),
        required: z.boolean()
      }),
      isValueAdaptedRecorded: z.boolean().optional()
    }),
    educationalModals: educationalModalsConfigSchema,
    genericAssessment: z
      .object({
        enabled: z.boolean(),
        isCommentFieldShown: z.boolean().optional(),
        isCommentFieldRequired: z.boolean().optional(),
        isValueAdaptedRecorded: z.boolean().optional()
      })
      .optional(),
    offModelQuestions: z.object({
      enabled: z.boolean(),
      order: z.number(),
      questions: z.array(offModelQuestionSchema)
    })
  }),
  productChooserRecommandation: z.object({
    enabled: z.boolean(),
    requireComment: z.boolean(),
    namespaceConfig: z.record(namespaceConfigSchema).nullable(),
    question1: questionSchema.omit({ name: true }).optional(),
    question2: questionSchema.omit({ name: true }).optional(),
    question3: questionSchema.omit({ name: true }).optional(),
    question4: questionSchema.omit({ name: true }).optional(),
    question5: questionSchema.omit({ name: true }).optional()
  }),
  riskScore: z.object({
    isRiskFinderEnabled: z.boolean(),
    historicalReturnStatsItems: z.array(
      z.object({
        enabled: z.boolean(),
        id: z.string(),
        label: z.record(z.string()),
        order: z.number()
      })
    ),
    showStats: z.boolean(),
    showExpectedPath: z.boolean()
  }),
  subAssetClassNameMapping: z.record(z.record(z.string())).optional(),
  investmentAdvice: z.object({
    enableKiidDocuments: z.boolean(),
    enableProductDocuments: z.boolean()
  }),
  orderExecution: z.object({
    enableKiidDocuments: z.boolean(),
    enableProductDocuments: z.boolean(),
    instrumentsList: z.object({
      productAttributeTxt1: z.nullable(orderExecutionProductAttributeSchema),
      productAttributeTxt2: z.nullable(orderExecutionProductAttributeSchema),
      productAttributeTxt3: z.nullable(orderExecutionProductAttributeSchema),
      productAttributeTxt4: z.nullable(orderExecutionProductAttributeSchema),
      productAttributeTxt5: z.nullable(orderExecutionProductAttributeSchema),
      productAttributeBinary1: z.nullable(orderExecutionProductAttributeSchema),
      productAttributeBinary2: z.nullable(orderExecutionProductAttributeSchema),
      productAttributeBinary3: z.nullable(orderExecutionProductAttributeSchema),
      productAttributeBinary4: z.nullable(orderExecutionProductAttributeSchema),
      productAttributeBinary5: z.nullable(orderExecutionProductAttributeSchema),
      showISINColumn: z.boolean(),
      showAssetClassColumn: z.boolean(),
      useAdvancedSearch: z.boolean(),
      showSuitabilityStatus: z.boolean(),
      blockOrderWithNonSuitableInstruments: z.boolean()
    }),
    pdfGeneration: z.object({
      usePDFv1: z.boolean(),
      usePDFv2: z.boolean()
    })
  }),
  countries: z.object({
    type: z.nativeEnum(CountriesTypeOptions),
    items: z.array(z.string())
  }),
  pdfGeneration: z.object({
    usePDFv1: z.boolean(),
    usePDFv2: z.boolean()
  }),
  riskFinder: z.object({
    isHistoricalReturnBenchmarkEnabled: z.boolean(),
    historicalReturnBenchmarkType: z.nativeEnum(historicalReturnBenchmarkTypes),
    existingPortfolioComparisonEnabled: z.boolean()
  }),
  adviceSessionList: z.object({ showAdviceOwner: z.boolean() }),
  historicalReturn: z.object({
    useBenchmarkDataForPortfolios: z.boolean(),
    benchmarkType: z.nativeEnum(historicalReturnBenchmarkTypes)
  }),
  followUpFlow: z.object({
    enabled: z.boolean()
  }),
  targetMarketAssessmentEnabled: z.boolean(),
  targetMarketAssessmentBlockNotSuitable: z.boolean()
});

export type RoboAdvice = z.infer<typeof roboAdviceSchema>;

export const defaultRoboAdvice: RoboAdvice = {
  clientInformation: {
    company: [
      {
        name: 'clientInformation.clientType',
        componentType: 'dropdown',
        label: { en: 'Type', no: 'Kundetype' },
        placeholder: { en: 'Select type', no: 'Velg kundetype' },
        items: [
          {
            activeValue: 'person',
            label: { en: 'Person', no: 'Privatkunde' }
          },
          {
            activeValue: 'company',
            label: { en: 'Company', no: 'Bedrift' }
          }
        ],
        order: 1,
        required: true,
        isEnabled: true
      },
      {
        name: 'clientInformation.country',
        componentType: 'dropdown',
        label: { en: 'Country', no: 'Land' },
        placeholder: { en: 'Country', no: 'Land' },
        items: [
          {
            activeValue: CountryCode.Norway,
            label: { en: 'Norway', no: 'Norge' }
          },
          {
            activeValue: CountryCode.Denmark,
            label: { en: 'Denmark', no: 'Danmark' }
          },
          {
            activeValue: CountryCode.Sweden,
            label: { en: 'Sweden', no: 'Sverige' }
          },
          {
            activeValue: CountryCode.Finland,
            label: { en: 'Finland', no: 'Finland' }
          }
        ],
        order: 2,
        required: false,
        isEnabled: true
      },
      {
        name: 'clientInformation.orgNumber',
        componentType: 'numberInput',
        label: { en: 'Org. number', no: 'Org. nr.' },
        placeholder: { en: 'Org. number', no: 'Org. nr.' },
        allowLeadingZeros: true,
        order: 3,
        required: false,
        isEnabled: true
      },
      {
        name: 'clientInformation.companyName',
        componentType: 'textInput',
        label: { en: 'Company name', no: 'Selskapsnavn' },
        placeholder: { en: 'Company name', no: 'Selskapsnavn' },
        order: 4,
        required: true,
        isEnabled: true
      },
      {
        name: 'clientInformation.address',
        componentType: 'textInput',
        label: { en: 'Address', no: 'Adresse' },
        placeholder: { en: 'Address', no: 'Adresse' },
        order: 5,
        required: false,
        isEnabled: true
      },
      {
        name: 'clientInformation.zipCode',
        componentType: 'textInput',
        label: { en: 'Zip Code', no: 'Postnr' },
        placeholder: { en: 'Zip Code', no: 'Postnr' },
        order: 6,
        required: false,
        isEnabled: true
      },
      {
        name: 'clientInformation.city',
        componentType: 'textInput',
        label: { en: 'City', no: 'Poststed' },
        placeholder: { en: 'City', no: 'Poststed' },
        order: 7,
        required: false,
        isEnabled: true
      },
      {
        name: 'clientInformation.additionalData.accountNumber',
        componentType: 'numberInput',
        label: { en: 'Account Number', no: 'Bankkontonr' },
        placeholder: { en: 'Account Number', no: 'Bankkontonr' },
        format: '#### ## #####',
        allowLeadingZeros: true,
        order: 8,
        required: false,
        isEnabled: true
      }
    ],
    person: [
      {
        name: 'clientInformation.clientType',
        componentType: 'dropdown',
        label: { en: 'Type', no: 'Kundetype' },
        placeholder: { en: 'Select type', no: 'Velg kundetype' },
        items: [
          {
            activeValue: 'person',
            label: { en: 'Person', no: 'Privatkunde' }
          },
          {
            activeValue: 'company',
            label: { en: 'Company', no: 'Bedrift' }
          }
        ],
        order: 1,
        required: true,
        isEnabled: true
      },
      {
        name: 'clientInformation.country',
        componentType: 'dropdown',
        label: { en: 'Country', no: 'Land' },
        placeholder: { en: 'Country', no: 'Land' },
        items: [
          {
            activeValue: CountryCode.Norway,
            label: { en: 'Norway', no: 'Norge' }
          },
          {
            activeValue: CountryCode.Denmark,
            label: { en: 'Denmark', no: 'Danmark' }
          },
          {
            activeValue: CountryCode.Sweden,
            label: { en: 'Sweden', no: 'Sverige' }
          },
          {
            activeValue: CountryCode.Finland,
            label: { en: 'Finland', no: 'Finland' }
          }
        ],
        order: 2,
        required: false,
        isEnabled: true
      },
      {
        name: 'clientInformation.name',
        componentType: 'textInput',
        label: { en: 'Name', no: 'Navn' },
        placeholder: { en: 'Name', no: 'Navn' },
        order: 3,
        required: true,
        isEnabled: true
      },
      {
        name: 'clientInformation.ssn',
        componentType: 'textInput',
        label: { en: 'SSN', no: 'Personnummer' },
        placeholder: { en: 'SSN', no: 'Personnummer' },
        order: 4,
        required: false,
        isEnabled: true
      },
      {
        name: 'clientInformation.additionalData.accountNumber',
        componentType: 'numberInput',
        label: { en: 'Account Number', no: 'Bankkontonr' },
        placeholder: { en: 'Account Number', no: 'Bankkontonr' },
        format: '#### ## #####',
        allowLeadingZeros: true,
        order: 5,
        required: false,
        isEnabled: true
      },
      {
        name: 'clientInformation.address',
        componentType: 'textInput',
        label: { en: 'Address', no: 'Adresse' },
        placeholder: { en: 'Address', no: 'Adresse' },
        order: 6,
        required: false,
        isEnabled: true
      },
      {
        name: 'clientInformation.phoneNumber',
        componentType: 'textInput',
        label: { en: 'Phone number', no: 'Telefon' },
        placeholder: { en: 'Phone number', no: 'Telefon' },
        order: 7,
        required: false,
        isEnabled: true
      },
      {
        name: 'clientInformation.zipCode',
        componentType: 'textInput',
        label: { en: 'Zip Code', no: 'Postnr' },
        placeholder: { en: 'Zip Code', no: 'Postnr' },
        order: 8,
        required: false,
        isEnabled: true
      },
      {
        name: 'clientInformation.city',
        componentType: 'textInput',
        label: { en: 'City', no: 'Poststed' },
        placeholder: { en: 'City', no: 'Poststed' },
        order: 9,
        required: false,
        isEnabled: true
      },
      {
        name: 'clientInformation.email',
        componentType: 'textInput',
        label: { en: 'Email', no: 'Epost' },
        placeholder: { en: 'Email', no: 'Epost' },
        order: 10,
        required: false,
        isEnabled: true
      },
      {
        name: 'clientInformation.additionalData.education',
        componentType: 'textInput',
        label: { en: 'Education', no: 'Utdanning' },
        placeholder: { en: 'Education', no: 'Utdanning' },
        order: 11,
        required: false,
        isEnabled: true
      },
      {
        name: 'clientInformation.additionalData.currentEmployer',
        componentType: 'textInput',
        label: { en: 'Current employer', no: 'Arbeidsgiver' },
        placeholder: { en: 'Current employer', no: 'Arbeidsgiver' },
        order: 12,
        required: false,
        isEnabled: true
      },
      {
        name: 'clientInformation.additionalData.title',
        componentType: 'textInput',
        label: { en: 'Title', no: 'Rolle' },
        placeholder: { en: 'Title', no: 'Rolle' },
        order: 13,
        required: false,
        isEnabled: true
      }
    ],
    contacts: {
      isContactListEnabled: true,
      isUBOEnabled: true,
      isSignatureRightsEnabled: true,
      required: false
    }
  },
  kyc: {
    company: {
      defaultFields: {
        clientRelationshipPurpose: {
          isEnabled: true,
          order: 1,
          required: false
        },
        moneyOrigin: {
          isEnabled: true,
          order: 2,
          required: false,
          items: [
            {
              activeValue: 'salary',
              label: { en: 'Salary', no: 'Lønn' }
            },
            {
              activeValue: 'heritage',
              label: { en: 'Heritage', no: 'Arv' }
            },
            {
              activeValue: 'gift',
              label: { en: 'Gift', no: 'Gave' }
            },
            {
              activeValue: 'gamblingLottery',
              label: { en: 'Gambling/Lottery', no: 'Gambling eller lotteri' }
            },
            {
              activeValue: 'realEstateSale',
              label: { en: 'Realestate', no: 'Eiendom' }
            },
            {
              activeValue: 'companyRevenues',
              label: { en: 'Company Revenue', no: 'Firmainntekter' }
            },
            {
              activeValue: 'excessLiquidity',
              label: { en: 'Excess Liquidity', no: 'Overskuddslikvidited' }
            },
            {
              activeValue: 'other',
              label: { en: 'Other', no: 'Annet' },
              textField: {
                name: 'kyc.otherMoneyOrigin',
                required: true
              }
            }
          ]
        },
        clientClassification: {
          isEnabled: true,
          order: 3,
          required: false,
          items: [
            {
              activeValue: 'retailClient',
              label: { en: 'Retail Client', no: 'Privatkunde' }
            },
            {
              activeValue: 'eligibleCounterparty',
              label: { en: 'Eligible Counterparty', no: 'Kvalifisert Motpart' }
            },
            {
              activeValue: 'professionalClient',
              label: { en: 'Professional Client', no: 'Profesjonell klient' }
            }
          ]
        },
        signatureRight: {
          isEnabled: true,
          order: 7,
          required: false
        },
        ubo: {
          isEnabled: true,
          order: 8,
          required: false
        },
        pep: {
          isEnabled: true,
          order: 9,
          required: false
        }
      },
      fields: [
        // order 1,2,3 reserved for clientRelationshipPurpose, moneyOrigin, clientClassification fields
        {
          name: 'kyc.investmentAdvice',
          componentType: 'buttonSwitch',
          label: { en: 'Investment advice', no: 'Investeringsrådgivning' },
          layout: 'vertical',
          order: 4,
          required: false,
          isEnabled: true
        },
        {
          name: 'kyc.orderExecution',
          componentType: 'buttonSwitch',
          label: { en: 'Order execution', no: 'Ordreformidling' },
          layout: 'vertical',
          order: 5,
          required: false,
          isEnabled: true
        },
        {
          name: 'kyc.activeManagement',
          componentType: 'buttonSwitch',
          label: { en: 'Active management', no: 'Aktiv forvaltning' },
          layout: 'vertical',
          order: 6,
          required: false,
          isEnabled: true
        },
        // order 7,8,9 reserved for signatureRight, ubo, pep fields
        {
          name: 'kyc.sanctions',
          order: 10,
          required: false,
          componentType: 'buttonSwitch',
          label: {
            en: 'Is the client sanctioned?',
            no: 'Er kunden sanksjonert?'
          },
          layout: 'vertical',
          isEnabled: true
        }
      ],
      isKYCEnabled: false,
      shouldKYCBlockAdviceSession: false,
      enin: {
        companyFlags: {
          isEnabled: false
        },
        signatureRight: {
          isEnabled: false
        },
        ubo: {
          isEnabled: false
        }
      }
    },
    person: {
      defaultFields: {
        clientRelationshipPurpose: {
          isEnabled: true,
          order: 1,
          required: false
        },
        moneyOrigin: {
          isEnabled: true,
          order: 2,
          required: false,
          items: [
            {
              activeValue: 'salary',
              label: { en: 'Salary', no: 'Lønn' }
            },
            {
              activeValue: 'heritage',
              label: { en: 'Heritage', no: 'Arv' }
            },
            {
              activeValue: 'gift',
              label: { en: 'Gift', no: 'Gave' }
            },
            {
              activeValue: 'gamblingLottery',
              label: { en: 'Gambling/Lottery', no: 'Gambling eller lotteri' }
            },
            {
              activeValue: 'realEstateSale',
              label: { en: 'Realestate', no: 'Eiendom' }
            },
            {
              activeValue: 'companyRevenues',
              label: { en: 'Company Revenue', no: 'Firmainntekter' }
            },
            {
              activeValue: 'excessLiquidity',
              label: { en: 'Excess Liquidity', no: 'Overskuddslikvidited' }
            },
            {
              activeValue: 'other',
              label: { en: 'Other', no: 'Annet' },
              textField: {
                name: 'kyc.otherMoneyOrigin',
                required: true
              }
            }
          ]
        },
        clientClassification: {
          isEnabled: true,
          order: 3,
          required: false,
          items: [
            {
              activeValue: 'retailClient',
              label: { en: 'Retail Client', no: 'Privatkunde' }
            },
            {
              activeValue: 'eligibleCounterparty',
              label: { en: 'Eligible Counterparty', no: 'Kvalifisert Motpart' }
            },
            {
              activeValue: 'professionalClient',
              label: { en: 'Professional Client', no: 'Profesjonell klient' }
            }
          ]
        },
        pep: {
          isEnabled: true,
          order: 7,
          required: false
        }
      },
      fields: [
        // order 1,2,3 reserved for clientRelationshipPurpose, moneyOrigin, clientClassification fields
        {
          name: 'kyc.investmentAdvice',
          componentType: 'buttonSwitch',
          label: { en: 'Investment advice', no: 'Investeringsrådgivning' },
          layout: 'vertical',
          order: 4,
          required: false,
          isEnabled: true
        },
        {
          name: 'kyc.orderExecution',
          componentType: 'buttonSwitch',
          label: { en: 'Order execution', no: 'Ordreformidling' },
          layout: 'vertical',
          order: 5,
          required: false,
          isEnabled: true
        },
        {
          name: 'kyc.activeManagement',
          componentType: 'buttonSwitch',
          label: { en: 'Active management', no: 'Aktiv forvaltning' },
          layout: 'vertical',
          order: 6,
          required: false,
          isEnabled: true
        },
        // order 7 reserved for pep fieldorder 7 reserved for pep field
        {
          name: 'kyc.sanctions',
          order: 8,
          required: false,
          componentType: 'buttonSwitch',
          label: {
            en: 'Is the client sanctioned?',
            no: 'Er kunden sanksjonert?'
          },
          layout: 'vertical',
          isEnabled: true
        }
      ],
      isKYCEnabled: false,
      shouldKYCBlockAdviceSession: false
    }
  },
  sustainability: {
    educationalModals: {
      enabled: false
    },
    enabled: true,
    alignmentCriteria: {
      order: 1,
      stepsScheme: [
        {
          order: 1,
          value: 5,
          name: 'roboAdvice.sustainability.granulationBar.step1',
          label: { en: 'Very important', no: 'Veldig viktig' }
        },
        {
          order: 2,
          value: 4,
          name: 'roboAdvice.sustainability.granulationBar.step2',
          label: { en: 'Important', no: 'Viktig' }
        },
        {
          order: 3,
          value: 3,
          name: 'roboAdvice.sustainability.granulationBar.step3',
          label: { en: 'Moderately Important', no: 'Ganske viktig' }
        },
        {
          order: 4,
          value: 2,
          name: 'roboAdvice.sustainability.granulationBar.step4',
          label: { en: 'Slightly Important', no: 'Litt viktig' }
        },
        {
          order: 5,
          value: 1,
          name: 'roboAdvice.sustainability.granulationBar.step5',
          label: { en: 'Not Important', no: 'Ikke viktig' }
        },
        {
          order: 6,
          value: 0,
          name: 'roboAdvice.sustainability.granulationBar.stepNeutral',
          label: { en: 'Undecided', no: 'Ubestemt' }
        }
      ]
    },
    alignmentCriteria1: {
      enabled: true,
      alignmentCriteriaOrder: 1,
      config: {
        title: {
          en: 'EU Taxonomy Alignment',
          no: 'EU Taksonomi'
        },
        type: 'granulationComponent',
        content: {
          en: 'To what extent is it important to you that your money is invested in one or several activitities that qualify as sustainable accordring to the EU-Taxonomy?',
          no: 'I hvilken grad er det viktig for deg at dine midler er investert i en eller flere aktiviteter som klassifiseres som bærekraftig i henhold til EU-taksonomien?'
        },
        isCommentFieldShown: true,
        required: true
      }
    },
    sustainabilityPreference: {
      enabled: true,
      header: {
        en: 'Preference Criteria',
        no: 'Preferansekriterier'
      },
      description: {
        en: 'Select preference criteria by clicking on the indicator card',
        no: 'Velg preferansekriterier ved å klikke på ikonene'
      },
      order: 2,
      config: [
        {
          icon: '',
          id: 'preferenceCriteria2',
          title: {
            en: 'Fossil Free',
            no: 'Fossil fri'
          }
        },
        {
          icon: '',
          id: 'preferenceCriteria5',
          title: {
            en: 'Diversity',
            no: 'Likestilking'
          }
        }
      ],
      advisorNotes: {
        enabled: false,
        required: false
      }
    },
    genericAssessment: {
      enabled: false
    },
    offModelQuestions: {
      enabled: false,
      order: 3,
      questions: []
    }
  },
  productChooserRecommandation: {
    enabled: false,
    requireComment: false,
    namespaceConfig: {}
  },
  riskScore: {
    isRiskFinderEnabled: false,
    historicalReturnStatsItems: [
      {
        enabled: true,
        id: 'totalReturn',
        label: {
          en: 'Total return',
          no: 'Total avkastning'
        },
        order: 1
      },
      {
        enabled: true,
        id: 'cagr',
        label: {
          en: 'Cagr',
          no: 'Annualisert avkastning'
        },
        order: 2
      },
      {
        enabled: true,
        id: 'maxDrawdown',
        label: {
          en: 'Max drawdown',
          no: 'Maksimal nedside'
        },
        order: 3
      },
      {
        enabled: true,
        id: 'bestYear',
        label: {
          en: 'Best year',
          no: 'Beste år'
        },
        order: 4
      },
      {
        enabled: true,
        id: 'worstYear',
        label: {
          en: 'Worst year',
          no: 'Verste år '
        },
        order: 5
      },
      {
        enabled: true,
        id: 'bestMonth',
        label: {
          en: 'Best month',
          no: 'Beste måned'
        },
        order: 6
      },
      {
        enabled: true,
        id: 'worstMonth',
        label: {
          en: 'Worst month',
          no: 'Verste måned'
        },
        order: 7
      },
      {
        enabled: true,
        id: 'averageDrawdown',
        label: {
          en: 'Average drawdown',
          no: 'Gjennomsnittlig nedside'
        },
        order: 8
      },
      {
        enabled: true,
        id: 'twelveMonthWinPercent',
        label: {
          en: '12 month win percent',
          no: '%-andel positiv årlig avkastning'
        },
        order: 9
      },
      {
        enabled: true,
        id: 'winYearPercent',
        label: {
          en: 'Win year percent',
          no: '%-andel positive år'
        },
        order: 10
      }
    ],
    showStats: false,
    showExpectedPath: false
  },
  investmentAdvice: {
    enableKiidDocuments: true,
    enableProductDocuments: false
  },
  orderExecution: {
    enableKiidDocuments: true,
    enableProductDocuments: false,
    instrumentsList: {
      productAttributeTxt1: null,
      productAttributeTxt2: null,
      productAttributeTxt3: null,
      productAttributeTxt4: null,
      productAttributeTxt5: null,
      productAttributeBinary1: null,
      productAttributeBinary2: null,
      productAttributeBinary3: null,
      productAttributeBinary4: null,
      productAttributeBinary5: null,
      showISINColumn: true,
      showAssetClassColumn: true,
      useAdvancedSearch: false,
      showSuitabilityStatus: false,
      blockOrderWithNonSuitableInstruments: false
    },
    pdfGeneration: {
      usePDFv1: true,
      usePDFv2: false
    }
  },
  subAssetClassNameMapping: {
    C01: { en: 'Norwegian Money Market' },
    C02: { en: 'Norwegian Corporate Bonds' },
    C03: { en: 'Norway' },
    C04: { en: 'Euro' }
  },
  countries: {
    type: CountriesTypeOptions.exclusive,
    items: [
      CountryCode.Norway,
      CountryCode.Denmark,
      CountryCode.Sweden,
      CountryCode.Finland
    ]
  },
  pdfGeneration: {
    usePDFv1: true,
    usePDFv2: false
  },
  riskFinder: {
    isHistoricalReturnBenchmarkEnabled: false,
    historicalReturnBenchmarkType:
      historicalReturnBenchmarkTypes.categoryBenchmark,
    existingPortfolioComparisonEnabled: false
  },
  adviceSessionList: {
    showAdviceOwner: false
  },
  historicalReturn: {
    useBenchmarkDataForPortfolios: false,
    benchmarkType: historicalReturnBenchmarkTypes.categoryBenchmark
  },
  followUpFlow: {
    enabled: false
  },
  targetMarketAssessmentEnabled: false,
  targetMarketAssessmentBlockNotSuitable: false
};
