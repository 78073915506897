import { TableModule, TableStoreData } from '../types';
import { createUseTableStore } from './tableStore';

const create = (
  id: string,
  intialData?: Partial<TableStoreData>
): TableModule => {
  return {
    useTableStore: createUseTableStore(id, intialData)
  };
};

export default create;
