import classNames from 'classnames';
import { path } from 'ramda';
import React, { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { LayoutBreakpoints } from '../../../../../shared/constants/layoutBreakpoints';
import { Spacing } from '../../../../../shared/constants/spacing';
import sessionSelectors from '../../../../../shared/services/session/selectors';
import { getNumberInputFormat } from '../../../../../shared/utils/number';
import { FieldComponentTypes } from '../../../../../sharedModules/declarativeForm/constants';
import { createUseStyles } from '../../../../../sharedModules/styles/components/styles';
import Widget from '../../../shared/components/widget';
import { useForm as useRoboAdviceForm } from 'features/roboAdvice/adviceSession/form/services/form';
import { getTranslation } from 'features/shared/utils/translations';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { ConfigurableComponent } from 'features/sharedModules/declarativeForm/components';
import InputErrorMessage from 'features/sharedModules/errors/components/inputErrorMessage';
import { useErrorsStore } from 'features/sharedModules/errors/services/errorsStore';

const useStyles = createUseStyles(theme => ({
  section: {
    width: '50%',
    [`@media (max-width: ${LayoutBreakpoints.desktopMin}px)`]: {
      width: '100%'
    }
  },
  widget: {
    backgroundColor: theme.boxBackgroundColor_2,
    border: `1px solid ${theme.defaultInputStrokeColor}`,
    marginBottom: Spacing.spacing02
  },
  inputSection: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  noBackgroundSection: {
    paddingLeft: Spacing.spacing02,
    paddingRight: Spacing.spacing02
  },
  field: {
    '& + &': {
      marginTop: Spacing.spacing04
    }
  },
  fieldWithBackground: {
    '& + &': {
      marginTop: Spacing.spacing02
    }
  }
}));

const OrderInformationSection = ({
  label,
  labelKey,
  showSum,
  sumLabel,
  showBackground,
  items,
  required,
  name,
  errors
}) => {
  const i18n = useI18n();
  const classes = useStyles();
  const isErrorModeEnabled = useErrorsStore(state => state.isErrorModeEnabled);
  const values = useRoboAdviceForm.getState()?.values;
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const { decimalSeparator } = getNumberInputFormat(cultureCode);

  const sumValue = useMemo(() => {
    return showSum && values
      ? items.reduce((acc, { name, config }) => {
          if (
            config.componentType !== FieldComponentTypes.numberInput ||
            config.allowLeadingZeros
          ) {
            return acc;
          }
          const value = path(name.split('.'), values);
          if (typeof value === 'string') {
            return acc + Number(value?.replace(decimalSeparator, '.') ?? 0);
          }
          return acc + (value ?? 0);
        }, 0)
      : 0;
  }, [items, values, showSum]);

  const renderedItems = items.map(({ name, config, ...restProps }) => (
    <Fragment key={name}>
      {errors[name]?.map(error => (
        <InputErrorMessage message={error.alertMessage} key={error.elementId} />
      ))}
      <ConfigurableComponent
        key={name}
        name={name}
        config={config}
        elementId={name}
        hasError={isErrorModeEnabled && errors[name]?.length}
        sectionClassName={classes.inputSection}
        fieldClassName={classNames({
          [classes.field]: !showBackground,
          [classes.fieldWithBackground]: showBackground
        })}
        {...restProps}
      />
    </Fragment>
  ));

  return (
    <div className={classes.section} id={name}>
      {errors?.[name]?.map(error => (
        <InputErrorMessage message={error.alertMessage} />
      ))}
      {showBackground ? (
        <Widget
          header={labelKey ? i18n(labelKey) : getTranslation(label)}
          className={classes.widget}
          doShowFooterSeparator={!!showSum}
          total={
            showSum
              ? { label: getTranslation(sumLabel), value: sumValue }
              : undefined
          }
          doShowEmptyFooter={!showSum}
          required={required}
          minFractionDigits={0}
          maxFractionDigits={2}
        >
          {renderedItems}
        </Widget>
      ) : (
        <div className={classes.noBackgroundSection}>{renderedItems}</div>
      )}
    </div>
  );
};

export default OrderInformationSection;
