import { useSelector } from 'react-redux';

import { useReadMultiGoalCostChartData } from '../../../../../../roboAdvice/adviceSession/proposal/components/costChart/useReadMultiGoalCostChartData';
import { useHolisticViewStore } from '../../../services/holisticViewStore';
import { useInitPage } from './useInitPage';
import { useReadCostChartData } from './useReadCostChartData';
import { useCostChartStore } from 'features/roboAdvice/adviceSession/costChart';
import {
  useReadDataListener,
  PageStatuses
} from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import CostChartViewVisual from 'features/shared/analyticsComponents/costChart/visual';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';

const CostChartView = ({ isSelected = false }) => {
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const chartStoreData = useCostChartStore();
  const { proposalSectionStatuses } = useHolisticViewStore();
  const {
    analyticsComponents: { multiGoalCostEnabled }
  } = useCustomerConfig();

  useReadDataListener(
    proposalSectionStatuses.readCostChartData,
    isSelected && !multiGoalCostEnabled,
    useReadCostChartData()
  );
  useReadDataListener(
    proposalSectionStatuses.readCostChartData,
    isSelected && multiGoalCostEnabled,
    useReadMultiGoalCostChartData()
  );
  useInitPage();

  return (
    <CostChartViewVisual
      chartStoreChartData={chartStoreData.chartData}
      chartStoreTableData={chartStoreData.tableData}
      cultureCode={cultureCode}
      isLoading={
        useHolisticViewStore.getState().proposalSectionStatuses
          .readCostChartData === PageStatuses.pending
      }
    />
  );
};

export default CostChartView;
