import { isNil } from 'ramda';
import { useSelector } from 'react-redux';

import {
  AdvisoryPageStoreState,
  usePageStore as useAdvisoryPageStore
} from '../../advisory/services/pageStore';
import { riskScoreToNumber } from '../../riskScore/services/shared';
import { AvailablePortfoliosModes } from '../constants';
import { useGoalsStore } from './goalsStore';
import { AvailablePortfoliosMappingModes } from 'defaults/shared';
import store from 'features/main/app/store.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { CustomerConfigType } from 'features/shared/services/session/types';

export function useAvailablePortfolios(goalId) {
  const advisoryPageStore = useAdvisoryPageStore();
  const customerConfig: CustomerConfigType = useSelector(
    sessionSelectors.getCustomerConfig
  );
  const riskScore = useGoalsStore().getGoalRiskScore(goalId);
  const { goals } = useGoalsStore.getState();
  const goal = goals.find(g => g.goalId === goalId);

  return getAvailablePortfoliosResult({
    advisoryPageStore,
    customerConfig,
    riskScore,
    namespaceId: goal?.data.productPlatformNamespace
  });
}
export function getAvailablePortfolios(goalId: string, namespaceId?: number) {
  const advisoryPageStore = useAdvisoryPageStore.getState();
  const reduxStoreState = store.getState();
  const customerConfig = sessionSelectors.getCustomerConfig(reduxStoreState);
  const riskScore = useGoalsStore.getState().getGoalRiskScore(goalId);
  const { goals } = useGoalsStore.getState();
  const goal = goals.find(g => g.goalId === goalId);

  return getAvailablePortfoliosResult({
    advisoryPageStore,
    customerConfig,
    riskScore,
    namespaceId: namespaceId ?? goal?.data.productPlatformNamespace
  });
}

export function usePortfolioRisk(goalId) {
  const advisoryPageStore = useAdvisoryPageStore();
  const riskScore = useGoalsStore().getGoalPortfolioRiskScore(goalId);

  return advisoryPageStore.portfolios
    .map(p => ({ key: p.id, title: p.title }))
    .find(
      portfolio =>
        riskScoreToNumber(portfolio.key) === riskScoreToNumber(riskScore)
    );
}

export function getAvailablePortfoliosResult({
  advisoryPageStore,
  customerConfig,
  riskScore,
  namespaceId
}: {
  advisoryPageStore: AdvisoryPageStoreState;
  customerConfig: CustomerConfigType;
  riskScore: string | null;
  namespaceId: number | null | undefined;
}) {
  const portfolioOptions = advisoryPageStore.portfolios.map(p => ({
    key: p.id,
    title: p.title
  }));
  const { advisoryComponents, customRiskTitles } = customerConfig;
  const {
    availablePortfoliosMode,
    availablePortfoliosMapping,
    availablePortfoliosMappingMode,
    availablePortfoliosMappingPPA
  } = advisoryComponents || {};

  switch (availablePortfoliosMode) {
    case AvailablePortfoliosModes.all:
      return portfolioOptions;
    case AvailablePortfoliosModes.linkedToFinalScore:
      return portfolioOptions.filter(
        portfolio =>
          riskScoreToNumber(portfolio.key) === riskScoreToNumber(riskScore)
      );
    case AvailablePortfoliosModes.equalOrLower:
      return portfolioOptions.filter(
        portfolio =>
          (riskScoreToNumber(portfolio.key) ?? 0) <=
          (riskScoreToNumber(riskScore) ?? 0)
      );
    case AvailablePortfoliosModes.custom:
      const riskScoreNumber = riskScoreToNumber(riskScore);

      if (
        availablePortfoliosMappingMode ===
        AvailablePortfoliosMappingModes.tenant
      ) {
        const portfoliosForRiskScore = riskScoreNumber
          ? availablePortfoliosMapping[riskScoreNumber]
          : null;

        if (isNil(portfoliosForRiskScore)) {
          return portfolioOptions;
        }

        return portfolioOptions.filter(portfolio => {
          const portfolioRiskScoreNumber = riskScoreToNumber(portfolio.key);

          if (!portfolioRiskScoreNumber) {
            return false;
          }

          return portfoliosForRiskScore.includes(portfolioRiskScoreNumber);
        });
      } else if (
        availablePortfoliosMappingMode ===
          AvailablePortfoliosMappingModes.ppa &&
        !isNil(namespaceId) &&
        !isNil(riskScoreNumber)
      ) {
        const portfoliosForRiskScore =
          availablePortfoliosMappingPPA?.[namespaceId]?.[riskScoreNumber];

        if (isNil(portfoliosForRiskScore)) {
          return portfolioOptions;
        }

        let allCustomRiskPortfolios: { key: string; title: string }[] = [];

        const allCustomRiskTitles: Record<string, string> =
          customRiskTitles ??
          portfolioOptions
            .map(p => ({ key: p.key, title: p.title }))
            .reduce((acc, p) => {
              acc[p.key] = p.title;
              return acc;
            }, {});

        for (const portfolioForRiskScore of portfoliosForRiskScore) {
          let added = false;

          for (const [riskLevel, customRiskTitle] of Object.entries(
            allCustomRiskTitles
          )) {
            const numericRiskScoreNumber = riskScoreToNumber(riskLevel);

            if (isNil(numericRiskScoreNumber)) {
              continue;
            }

            if (portfolioForRiskScore === numericRiskScoreNumber) {
              added = true;

              allCustomRiskPortfolios.push({
                key: riskLevel,
                title: customRiskTitle
              });

              break;
            }
          }

          if (!added) {
            allCustomRiskPortfolios.push({
              key: 'R' + portfolioForRiskScore,
              title: 'Risk ' + portfolioForRiskScore
            });
          }
        }

        return allCustomRiskPortfolios;
      }

      return portfolioOptions;
    default:
      return portfolioOptions;
  }
}
