import { createUsePatchQueueStore } from './patchQueueStore';
import { createUseQueuedPatch } from './useQueuedPatch';

const create = () => {
  const usePatchQueueStore = createUsePatchQueueStore();
  const useQueuedPatch = createUseQueuedPatch(usePatchQueueStore);

  return {
    usePatchQueueStore,
    useQueuedPatch
  };
};

export default create;
