import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { createAnalyzeUniverseCustomAttributes } from 'features/roboAdvice/adviceSession/shared/api';
import { useAdviceSessionStore } from 'features/roboAdvice/adviceSession/shared/services/adviceSessionStore';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

const useReadCustomAttributesData = () => {
  const i18n = useI18n();
  const dispatch = useDispatch();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const adviceSessionStore = useAdviceSessionStore();

  const readCustomAttributesData = async (namespaceId: number) => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;
    if (adviceSessionStore?.customAttributesData?.[namespaceId]) {
      return;
    }

    try {
      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const { data: universeCustomAttributesData } =
        await createAnalyzeUniverseCustomAttributes(
          qAuthAccessToken,
          cancelTokenSource.token,
          {
            namespace_id: namespaceId,
            language: getUserPageLanguage()
          }
        );

      adviceSessionStore.addCustomAttributesData(
        namespaceId,
        universeCustomAttributesData
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.advisory.customPortfolio.readUniverseCustomAttributesError'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readCustomAttributesData;
};

export default useReadCustomAttributesData;
