import { isNil } from 'ramda';

import { advancedSuitabilityTables } from '../../../roboAdvice/adviceSession/knowledgeAndExperience/constants';
import { useForm as useRoboAdviceForm } from 'features/roboAdvice/adviceSession/form/services/form';
import { AdviceSessionFormState } from 'features/roboAdvice/adviceSession/form/services/form';
import {
  KnowledgeAndExperienceStoreState,
  useKnowledgeAndExperienceStore
} from 'features/roboAdvice/adviceSession/knowledgeAndExperience/services/knowledgeAndExperienceStore';
import { getTranslation } from 'features/shared/utils/translations';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import {
  ErrorMessage,
  TargetMarketAssessmentErrors
} from 'features/sharedModules/errors/types';

export const useGetTargetMarketAssessmentErrors =
  (): TargetMarketAssessmentErrors => {
    const i18n = useI18n();
    const roboAdviceFormState = useRoboAdviceForm();
    const {
      roboAdviceForm: {
        knowledgeAndExperience: {
          advancedSuitability: advancedSuitabilityCustomerConfig
        },
        errorMessagesEnabled
      },
      roboOrderFlow: { targetMarketAssessment: targetMarketAssessmentConfig }
    } = useCustomerConfig();
    const { advancedSuitabilityConfig } = useKnowledgeAndExperienceStore();

    return {
      ...getTargetMarketAssessmentErrors({
        roboAdviceFormState: roboAdviceFormState as AdviceSessionFormState,
        advancedSuitabilityCustomerConfig,
        advancedSuitabilityConfig,
        i18n,
        errorMessagesEnabled,
        targetMarketAssessmentConfig
      })
    };
  };

type GetTargetMarketAssessmentErrorsParams = {
  roboAdviceFormState: AdviceSessionFormState;
  advancedSuitabilityCustomerConfig: ReturnType<
    typeof useCustomerConfig
  >['roboAdviceForm']['knowledgeAndExperience']['advancedSuitability'];
  advancedSuitabilityConfig: KnowledgeAndExperienceStoreState['advancedSuitabilityConfig'];
  i18n: ReturnType<typeof useI18n>;
  errorMessagesEnabled: ReturnType<
    typeof useCustomerConfig
  >['roboAdviceForm']['errorMessagesEnabled'];
  targetMarketAssessmentConfig: ReturnType<
    typeof useCustomerConfig
  >['roboOrderFlow']['targetMarketAssessment'];
};

const getTargetMarketAssessmentErrors = ({
  roboAdviceFormState,
  advancedSuitabilityCustomerConfig,
  advancedSuitabilityConfig,
  i18n,
  errorMessagesEnabled,
  targetMarketAssessmentConfig
}: GetTargetMarketAssessmentErrorsParams): {
  [key: string]: ErrorMessage[];
} => {
  const targetMarketAssessmentErrors: { [key: string]: ErrorMessage[] } = {};
  if (
    isNil(roboAdviceFormState.values) ||
    !errorMessagesEnabled ||
    !targetMarketAssessmentConfig.enabled
  ) {
    return targetMarketAssessmentErrors;
  }

  advancedSuitabilityTables
    .filter(({ name }) => advancedSuitabilityCustomerConfig[name]?.enabled)
    .forEach(({ name }) => {
      if (
        isNil(roboAdviceFormState.values?.suitability) ||
        isNil(roboAdviceFormState.values?.suitability[name]) ||
        advancedSuitabilityConfig?.checks.some(check =>
          isNil(roboAdviceFormState.values?.suitability?.[name][check])
        )
      ) {
        targetMarketAssessmentErrors[`targetMarketAssessment-${name}`] = [
          {
            listMessage: i18n('roboAdvice.errors.missingAnswer').replace(
              '{0}',
              getTranslation(advancedSuitabilityCustomerConfig[name].header)
            ),
            alertMessage: i18n(
              'roboAdvice.errors.missingAdvancedSuitabilityAlert'
            ),
            elementId: `advancedSuitability-${name}`
          }
        ];
      }
    });

  if (
    targetMarketAssessmentConfig.advisorNote.enabled &&
    targetMarketAssessmentConfig.advisorNote.required
  ) {
    if (!roboAdviceFormState.values?.targetMarketAssessmentAdvisorNotes) {
      targetMarketAssessmentErrors['targetMarketAssessment-advisorNote'] = [
        {
          listMessage: i18n('roboAdvice.errors.missingAdvisorNotes').replace(
            '{0}',
            i18n('orderExecution.targetMarketAssessment.advisorNotes.header')
          ),
          alertMessage: i18n('roboAdvice.errors.missingAdvisorNotesAlert'),
          elementId: 'targetMarketAssessmentAdvisorNotes'
        }
      ];
    }
  }

  return targetMarketAssessmentErrors;
};
