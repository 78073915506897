import { useRef } from 'react';

import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { injectSheet } from 'features/sharedModules/styles/components/styles';

const styles = {
  container: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    paddingTop: '72%'
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    border: 'none'
  }
};

const SavingCalculator = ({ classes }) => {
  const savingCalculatorIframeRef = useRef();
  const customerConfig = useCustomerConfig();
  const iframeUrl = customerConfig.tools.savingCalculator.iframeUrl;

  return (
    <div className={classes.container}>
      <iframe
        title={'savingCalculatorIframe'}
        ref={savingCalculatorIframeRef}
        className={classes.iframe}
        src={iframeUrl ? iframeUrl : ''}
      />
    </div>
  );
};

export default injectSheet(styles)(SavingCalculator);
