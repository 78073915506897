export const generalColors = [
  {
    name: 'accentColor',
    label: 'admin.appAdmin.advisorSolution.visuals.accent'
  },
  {
    name: 'primaryColor',
    label: 'admin.appAdmin.advisorSolution.visuals.primary'
  },
  {
    name: 'pageBackgroundColor',
    label: 'admin.appAdmin.advisorSolution.visuals.pageBackground'
  },
  {
    name: 'defaultInputStrokeColor',
    label: 'admin.appAdmin.advisorSolution.visuals.defaultInputStroke'
  }
];

export const feedbackColors = [
  {
    name: 'successColor',
    label: 'admin.appAdmin.advisorSolution.visuals.success'
  },
  {
    name: 'errorNegativeColor',
    label: 'admin.appAdmin.advisorSolution.visuals.error'
  },
  {
    name: 'warningColor',
    label: 'admin.appAdmin.advisorSolution.visuals.warning'
  }
];

export const accentColorShades = [
  {
    name: 'accentHoverColor',
    label: 'admin.appAdmin.advisorSolution.visuals.hoverAccent'
  },
  {
    name: 'accentFocusColor',
    label: 'admin.appAdmin.advisorSolution.visuals.focusAccent'
  },
  {
    name: 'defaultButtonTextColor',
    label: 'admin.appAdmin.advisorSolution.visuals.defaultButtonText'
  }
];

export const primaryColorShades = [
  {
    name: 'secondaryColor',
    label: 'admin.appAdmin.advisorSolution.visuals.secondary'
  },
  {
    name: 'disabledTextColor',
    label: 'admin.appAdmin.advisorSolution.visuals.disabledText'
  },
  {
    name: 'tertiaryTextColor',
    label: 'admin.appAdmin.advisorSolution.visuals.tertiaryText'
  }
];

export const pageBackgroundColorShades_1 = [
  {
    name: 'boxBackgroundColor_1',
    label: 'admin.appAdmin.advisorSolution.visuals.boxBackground_1'
  },
  {
    name: 'boxBackgroundColor_2',
    label: 'admin.appAdmin.advisorSolution.visuals.boxBackground_2'
  },
  {
    name: 'defaultInputFillColor',
    label: 'admin.appAdmin.advisorSolution.visuals.defaultInputFill'
  },
  {
    name: 'inputFillFocusColor',
    label: 'admin.appAdmin.advisorSolution.visuals.inputFillFocus'
  }
];

export const pageBackgroundColorShades_2 = [
  {
    name: 'itemBackgroundColor_1',
    label: 'admin.appAdmin.advisorSolution.visuals.itemBackground_1'
  },
  {
    name: 'itemBackgroundColor_2',
    label: 'admin.appAdmin.advisorSolution.visuals.itemBackground_2'
  },
  {
    name: 'disabledBackgroundColor',
    label: 'admin.appAdmin.advisorSolution.visuals.disabledBackground'
  },
  {
    name: 'hoverInputStrokeColor',
    label: 'admin.appAdmin.advisorSolution.visuals.hoverInputStroke'
  }
];

export const infographicsColors = [
  {
    name: 'chartEmptyColor',
    label: 'admin.appAdmin.advisorSolution.visuals.emptyChart'
  }
];

export const logoFileTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];
export const logoFileSizeKbLimit = 500;
