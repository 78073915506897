import * as R from 'ramda';
import create from 'zustand';

export type HistoricalReturnTimeRangeChartData = {
  portfolio: {
    date: string;
    value: number;
  }[];
  statistics: {
    cagr: number;
    days: number;
    first_data_point: string;
    five_year: number | null;
    growth: number;
    last_data_point: string;
    one_year: number | null;
    ten_year: number | null;
    three_year: number | null;
    years: number;
  };
  goalId: string;
  goalName: string;
  portfolioStatistics?: {
    averageDownMonth: null | number;
    averageDrawdown: null | number;
    averageDrawdownDays: null | number;
    averageUpMonth: null | number;
    bestMonth: null | number;
    bestYear: null | number;
    cagr: null | number;
    maxDrawdown: null | number;
    monthlyMean: null | number;
    monthlySharpe: null | number;
    monthlySortino: null | number;
    totalReturn: null | number;
    twelveMonthWinPercent: null | number;
    winYearPercent: null | number;
    worstMonth: null | number;
    worstYear: null | number;
    yearlyMean: null | number;
    yearlySharpe: null | number;
    yearlySortino: null | number;
  };
};

export type HistoricalReturnChartData = {
  '3M'?: HistoricalReturnTimeRangeChartData[];
  YTD?: HistoricalReturnTimeRangeChartData[];
  '1Y'?: HistoricalReturnTimeRangeChartData[];
  '3Y'?: HistoricalReturnTimeRangeChartData[];
  '5Y'?: HistoricalReturnTimeRangeChartData[];
  '10Y'?: HistoricalReturnTimeRangeChartData[];
};

type UseHistoricalReturnChartStoreData = {
  chartData: HistoricalReturnChartData;
};

type UseHistoricalReturnChartStoreApi = {
  reset: () => void;
  initialize: (initialData: Partial<UseHistoricalReturnChartStoreData>) => void;
  setTimeRangeChartData: <K extends keyof HistoricalReturnChartData>(
    key: K,
    value: HistoricalReturnChartData[K]
  ) => void;
  resetTimeRangeChartData: <K extends keyof HistoricalReturnChartData>(
    key: K
  ) => void;
  setChartData: (chartData: HistoricalReturnChartData) => void;
  resetChartData: () => void;
};

type UseHistoricalReturnChartStoreState = UseHistoricalReturnChartStoreData &
  UseHistoricalReturnChartStoreApi;

export const createUseHistoricalReturnChartStore = () => {
  const defaultData = {
    chartData: {}
  };

  return create<UseHistoricalReturnChartStoreState>(set => ({
    ...defaultData,
    reset: () => {
      set(defaultData);
    },
    initialize(initialData) {
      set(
        state => ({
          ...state,
          ...initialData
        }),
        true
      );
    },
    setTimeRangeChartData(key, value) {
      set(R.set(R.lensPath(['chartData', key]), value), true);
    },
    resetTimeRangeChartData(key) {
      set(R.set(R.lensPath(['chartData', key]), undefined), true);
    },
    setChartData(chartData) {
      set({ chartData });
    },
    resetChartData() {
      set({ chartData: {} });
    }
  }));
};
