import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { PortfolioIds } from '../../advisory/services/constants';
import { useReadDataListener } from '../../shared/components/useReadDataListener';
import { usePageStore as useRiskScorePageStore } from '../services/pageStore';
import {
  useHistoricalReturnChartStore,
  useReadHistoricalReturnChartData
} from './useReadHistoricalReturnChartData';
import HistoricalReturnChartCardVisual from 'features/shared/analyticsComponents/historicalReturnChart/visual';
import sessionSelectors from 'features/shared/services/session/selectors';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  buttonGroup: {
    justifyContent: 'center'
  }
});

const HistoricalReturnChartCard = ({
  isSelected,
  selectedRisks,
  compareWithExistingPortfolio
}) => {
  const classes = useStyles();
  const [activeItem, setActiveItem] = useState({
    statusKey: 'readHistoricalReturn3MChartData',
    timeRangeChartDataKey: '3M'
  });
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const chartStoreData = useHistoricalReturnChartStore();
  const { pageStatuses } = useRiskScorePageStore();

  const getItemStatus = useCallback(
    item => pageStatuses[item.statusKey],
    [pageStatuses]
  );

  useReadDataListener(
    pageStatuses.readHistoricalReturn3MChartData,
    isSelected && activeItem.statusKey === 'readHistoricalReturn3MChartData',
    useReadHistoricalReturnChartData(
      'readHistoricalReturn3MChartData',
      '3M',
      selectedRisks,
      compareWithExistingPortfolio
    )
  );
  useReadDataListener(
    pageStatuses.readHistoricalReturnYTDChartData,
    isSelected && activeItem.statusKey === 'readHistoricalReturnYTDChartData',
    useReadHistoricalReturnChartData(
      'readHistoricalReturnYTDChartData',
      'YTD',
      selectedRisks,
      compareWithExistingPortfolio
    )
  );
  useReadDataListener(
    pageStatuses.readHistoricalReturn1YChartData,
    isSelected && activeItem.statusKey === 'readHistoricalReturn1YChartData',
    useReadHistoricalReturnChartData(
      'readHistoricalReturn1YChartData',
      '1Y',
      selectedRisks,
      compareWithExistingPortfolio
    )
  );
  useReadDataListener(
    pageStatuses.readHistoricalReturn3YChartData,
    isSelected && activeItem.statusKey === 'readHistoricalReturn3YChartData',
    useReadHistoricalReturnChartData(
      'readHistoricalReturn3YChartData',
      '3Y',
      selectedRisks,
      compareWithExistingPortfolio
    )
  );
  useReadDataListener(
    pageStatuses.readHistoricalReturn5YChartData,
    isSelected && activeItem.statusKey === 'readHistoricalReturn5YChartData',
    useReadHistoricalReturnChartData(
      'readHistoricalReturn5YChartData',
      '5Y',
      selectedRisks,
      compareWithExistingPortfolio
    )
  );
  useReadDataListener(
    pageStatuses.readHistoricalReturn10YChartData,
    isSelected && activeItem.statusKey === 'readHistoricalReturn10YChartData',
    useReadHistoricalReturnChartData(
      'readHistoricalReturn10YChartData',
      '10Y',
      selectedRisks,
      compareWithExistingPortfolio
    )
  );

  const selectedGoalIds = [
    ...selectedRisks.map(r => r.risk),
    compareWithExistingPortfolio ? PortfolioIds.existingPortfolio : null
  ].filter(val => !!val);

  return (
    <HistoricalReturnChartCardVisual
      activeItem={activeItem}
      setActiveItem={setActiveItem}
      cultureCode={cultureCode}
      chartStoreChartData={chartStoreData.chartData}
      buttonGroupClassName={classes.buttonGroup}
      selectedGoalsIds={selectedGoalIds}
      getItemStatus={getItemStatus}
    />
  );
};

export default HistoricalReturnChartCard;
