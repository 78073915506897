import jsrsasign from 'jsrsasign';
import * as R from 'ramda';
import { put, call } from 'redux-saga/effects';

import { types } from '../actions.js';
import { useCrmLoginStore } from './useCrmLoginStore';
import config from 'config/index.js';
import {
  getAdvisor,
  postAdvisor,
  patchAdvisor
} from 'features/roboAdvice/shared/api';
import { getAuth0PublicKey } from 'features/shared/api/auth0.js';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import {
  startedCreator,
  succeedCreator,
  failedCreator,
  cancelledCreator
} from 'features/shared/utils/actions.js';
import { throwSafeError } from 'features/shared/utils/throwSafeError';

function certToPEM(cert) {
  return `-----BEGIN CERTIFICATE-----\n${cert}\n-----END CERTIFICATE-----\n`;
}

export function verifyAccessToken(cert, accessToken) {
  const rsaKey = R.isNil(cert) ? null : jsrsasign.KEYUTIL.getKey(cert);
  const isValid =
    !R.isNil(rsaKey) &&
    jsrsasign.KJUR.jws.JWS.verifyJWT(accessToken, rsaKey, {
      alg: config.REACT_APP_AUTH_PROVIDER_ALGORITHMS
    });

  return isValid;
}

export function parseAccessToken(accessToken) {
  return jsrsasign.KJUR.jws.JWS.readSafeJSONString(
    jsrsasign.b64utoutf8(accessToken.split('.')[1])
  );
}

export const getAuth0PublicKeyFunc = () =>
  getAuth0PublicKey(config.REACT_APP_AUTH_PROVIDER_DOMAIN).then(
    response => response.data
  );

export const getCertFromPublickKey = (publicKey, accessToken) => {
  const headerObj = jsrsasign.KJUR.jws.JWS.readSafeJSONString(
    jsrsasign.b64utoutf8(accessToken.split('.')[0])
  );
  const cert = R.pipe(
    R.prop('keys'),
    R.find(key => key.kid === headerObj.kid),
    key => (R.isNil(key) ? null : certToPEM(key.x5c[0]))
  )(publicKey);

  return cert;
};

export function* syncAdvisor({ auth0AccessToken, user }) {
  yield put(startedCreator(types.SYNC_ADVISOR));

  try {
    const accessToken = yield call(
      getQAuthAccessToken,
      auth0AccessToken,
      undefined
    );

    let isAdvisorFound;
    let advisor = null;

    try {
      const getAdvisorResponse = yield call(
        getAdvisor,
        accessToken,
        undefined,
        user.auth0Id
      );

      useCrmLoginStore.getState().setEnableCrmLogin(true);

      isAdvisorFound = true;
      advisor = getAdvisorResponse.data.advisor;
    } catch (error) {
      if (R.path(['response', 'status'], error) === 404) {
        isAdvisorFound = false;
      } else {
        throw error;
      }
    }

    if (!isAdvisorFound) {
      yield call(postAdvisor, accessToken, undefined, {
        advisor_id: user.auth0Id,
        name: user.name,
        email: user.email,
        external_id: user.externalId
      });
      useCrmLoginStore.getState().setEnableCrmLogin(true);
      yield put(succeedCreator(types.SYNC_ADVISOR));
    } else if (
      advisor.name !== user.name ||
      advisor.email !== user.email ||
      advisor.external_id !== user.externalId
    ) {
      yield call(patchAdvisor, accessToken, undefined, user.auth0Id, {
        name: user.name,
        email: user.email,
        external_id: user.externalId
      });
      yield put(succeedCreator(types.SYNC_ADVISOR));
    } else {
      yield put(cancelledCreator(types.SYNC_ADVISOR));
    }
  } catch (error) {
    yield put(failedCreator(types.SYNC_ADVISOR));
    throwSafeError(error);
  }
}
