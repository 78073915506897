import {
  CashflowForecastChart,
  CashflowGoalChart
} from '@quantfoliorepo/ui-components';

import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  portfolioGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(450px, 1fr));',
    columnGap: '20px',
    justifyItems: 'center',
    overflow: 'hidden'
  }
});

const CashflowCharts = ({
  cashflowGoalChartsData,
  cashflowForecastChartData,
  cultureCode
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <div className={classes.portfolioGrid}>
        {cashflowGoalChartsData.length &&
          cashflowGoalChartsData.map((data, index) => (
            <CashflowGoalChart
              key={index}
              chartData={data.chartData}
              cultureCode={cultureCode}
              title={data.goalName}
              iconName={data.goalIcon}
              color={(theme as any).chartPortfolioColors[index]}
            />
          ))}
      </div>

      {cashflowForecastChartData && (
        <CashflowForecastChart
          chartData={cashflowForecastChartData}
          cultureCode={cultureCode}
          isInteractive
        />
      )}
    </>
  );
};

export default CashflowCharts;
