import { useTemplateStore } from '../templateStore';

const useGetInitialValues = () => {
  const { template } = useTemplateStore();

  const getInitialValues = () => ({
    sections:
      template?.sections.reduce(
        (acc, curr) => ({ ...acc, [curr.id]: true }),
        {} as Record<string, boolean>
      ) || {},
    attachments:
      template?.attachments.reduce(
        (acc, curr) => ({ ...acc, [curr.id]: true }),
        (template?.allowProductAttachments
          ? { productAttachments: true }
          : {}) as Record<string, boolean>
      ) || {}
  });

  return getInitialValues;
};

export default useGetInitialValues;
