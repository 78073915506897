import * as React from 'react';

import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  icon: {
    fill: theme.accentColor
  }
}));

const PersonIcon = () => {
  const classes = useStyles();

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 10C12.7625 10 15 7.75625 15 5C15 2.2375 12.7625 0 10 0C7.2375 0 5 2.2375 5 5C5 7.75625 7.2375 10 10 10ZM10 12.5C6.66875 12.5 0 14.1687 0 17.5V20H20V17.5C20 14.1687 13.3313 12.5 10 12.5Z"
        className={classes.icon}
      />
    </svg>
  );
};

export default PersonIcon;
