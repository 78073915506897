import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { createPreMeetingSession as createPreMeetingSessionApi } from '../../../main/api';
import { useReadAdviceSessions } from 'features/roboAdvice/client/adviceSessionsList/components/useReadAdviceSessions';
import { usePreMeetingSurveyStore } from 'features/roboAdvice/client/adviceSessionsList/services/preMeetingSurveyStore';
import { CreatePreMeetingSessionPayload } from 'features/roboAdvice/client/main/types';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useCreatePreMeetingSession() {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const i18n = useI18n();
  const dispatch = useDispatch();
  const {
    tenantSettings: { investorAuthentication }
  } = useCustomerConfig();
  const readAdviceSessions = useReadAdviceSessions();

  const createPreMeetingSession = async (
    payload: CreatePreMeetingSessionPayload
  ) => {
    const { setIsCreatePreMeetingSessionPending } =
      usePreMeetingSurveyStore.getState();

    try {
      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        undefined
      );

      await createPreMeetingSessionApi(
        accessToken,
        undefined,
        payload,
        investorAuthentication
      );

      setIsCreatePreMeetingSessionPending(false);

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n(
            'roboAdvice.client.preMeetingSurvey.createPreMeetingSessionSuccessMessage'
          ),
          type: NotificationTypes.success
        })
      );
      await readAdviceSessions();
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsCreatePreMeetingSessionPending(false);
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.client.preMeetingSurvey.createPreMeetingSessionErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );
      }
    }
  };

  return createPreMeetingSession;
}
