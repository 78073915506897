import classNames from 'classnames';

import { ReactComponent as YourScoreIcon } from 'assets/triangleDown.svg';
import { ReactComponent as PortfolioScoreIcon } from 'assets/triangleUp.svg';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { formatNumber } from 'features/shared/utils/number';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const minValue = 0;
const maxValue = 5;

const useStyles = createUseStyles(theme => ({
  relative: {
    position: 'relative'
  },
  scoreContainer: {
    color: theme.secondaryColor,
    fontSize: Typography.heading1.size,
    lineHeight: '23px',
    width: 250
  },
  yourScoreContainer: {
    position: 'absolute',
    top: 'calc(50% - 72.5px)'
  },
  portfolioScoreContainer: {
    position: 'absolute',
    top: 'calc(50% + 9.5px)'
  },
  score: {
    color: theme.primaryColor
  },
  yourScoreIcon: {
    marginTop: 16,
    fill: theme.accentColor
  },
  portfolioScoreIcon: {
    marginBottom: 16,
    fill: theme.inputFillFocusColor
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 32,
    height: 300
  },
  value: {
    color: theme.secondaryColor,
    fontWeight: FontWeights.medium,
    fontSize: Typography.heading1.size,
    lineHeight: '23px'
  },
  bar: {
    borderRadius: 20,
    height: 4,
    backgroundColor: theme.defaultInputStrokeColor,
    width: 540
  }
}));

type Props = {
  portfolioScore: number;
  yourScore: number;
  cultureCode: string;
};

const HorizontalBar = ({ portfolioScore, yourScore, cultureCode }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();

  return (
    <div className={classes.relative}>
      <div
        className={classNames(
          classes.scoreContainer,
          classes.yourScoreContainer
        )}
        style={{
          left: `calc(50% - 125px - 270px + ${yourScore}/${maxValue} * 540px)`
        }}
      >
        <div>
          {i18n('roboAdvice.proposal.sustainability.horizontalBar.yourScore')}{' '}
          <span className={classes.score}>
            {formatNumber(cultureCode, yourScore, 0, 2)}
          </span>
        </div>
        <YourScoreIcon className={classes.yourScoreIcon} />
      </div>

      <div
        className={classNames(
          classes.scoreContainer,
          classes.portfolioScoreContainer
        )}
        style={{
          left: `calc(50% - 125px - 270px + ${portfolioScore}/${maxValue} * 540px)`
        }}
      >
        <PortfolioScoreIcon className={classes.portfolioScoreIcon} />
        <div>
          {i18n('roboAdvice.proposal.sustainability.horizontalBar.portfolio')}{' '}
          <span className={classes.score}>
            {formatNumber(cultureCode, portfolioScore, 0, 2)}
          </span>
        </div>
      </div>

      <div className={classes.container}>
        <div className={classes.value}>{minValue}</div>

        <div className={classes.bar}></div>

        <div className={classes.value}>{maxValue}</div>
      </div>
    </div>
  );
};

export default HorizontalBar;
