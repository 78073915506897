import * as R from 'ramda';
import React from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { useElementSize } from 'usehooks-ts';

import { FieldSections } from '../../constants';
import { generateReportForm } from '../../services/generateReportForm';
import { useAnalyticsComponentsFields } from './useAnalyticsComponentsFields';
import { useIsSessionReadOnly } from 'features/roboAdvice/adviceSession/main/services/selectors';
import Scrollbars from 'features/shared/components/scrollbars';
import { ClientTypes } from 'features/shared/constants/session';
import { Spacing } from 'features/shared/constants/spacing';
import { FontWeights } from 'features/shared/constants/typography';
import { useUnmountEffect } from 'features/shared/hooks/useUnmountEffect.js';
import { getTranslation } from 'features/shared/utils/translations';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { RenderFields } from 'features/sharedModules/declarativeForm/components/index.js';
import { FieldComponentTypes } from 'features/sharedModules/declarativeForm/constants';
import { Layouts as FinalFormSwitchLayouts } from 'features/sharedModules/finalForm/components/switch.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const formColumnHeaderMarginBottom = Spacing.spacing02;
const notFirstColumnHeaderMarginTop = Spacing.spacing02;

const useStyles = createUseStyles(theme => ({
  formColumns: {
    display: 'flex',
    gap: Spacing.spacing04
  },
  formColumnHeader: {
    fontWeight: FontWeights.medium,
    marginBottom: Spacing.spacing01,

    '&:not(:first-child)': {
      marginTop: notFirstColumnHeaderMarginTop
    }
  },
  field: {
    padding: `14px ${Spacing.spacing01}px 14px 0`,

    '& + &': {
      margin: 0
    }
  },
  switchLabel: {
    width: '293px',
    color: theme.primaryColor,
    wordWrap: 'break-word'
  },
  '@media (max-width: 1023px)': {
    formColumns: {
      flexDirection: 'column',
      gap: Spacing.spacing02
    }
  }
}));

const reportFields = [
  [
    'report.includeReport',
    {
      render: {
        componentType: FieldComponentTypes.switch,
        labelKey: 'roboAdvice.proposal.generateReport.includeReport',
        layout: FinalFormSwitchLayouts.horizontal
      }
    }
  ]
];

export const afterSwitchChange = (
  generateReportForm,
  allFieldKey,
  sectionFields,
  field,
  value
) => {
  if (field[0] === allFieldKey) {
    generateReportForm.batch(() => {
      for (const f of sectionFields) {
        generateReportForm.change(f[0], value);
      }
    });
  } else if (value === false) {
    generateReportForm.change(allFieldKey, false);
  } else if (value === true) {
    const { values } = generateReportForm.getState();

    if (
      R.pipe(
        R.map(f => f[0]),
        R.filter(k => k !== allFieldKey),
        R.map(k => R.path(R.split('.', k), values)),
        R.all(v => v === true)
      )(sectionFields)
    ) {
      generateReportForm.change(allFieldKey, true);
    }
  }
};

const GenerateReportForm = ({ modalBodyMaxHeight = 0 }) => {
  const classes = useStyles();
  const i18n = useI18n();
  const {
    reportAttachments,
    roboAdviceForm: {
      proposal: { morningstarAttachmentsAvailable }
    },
    roboAdvice: {
      investmentAdvice: { enableKiidDocuments, enableProductDocuments },
      kyc: {
        company: { isKYCEnabled: isCompanyKYCEnabled },
        person: { isKYCEnabled: isPersonKYCEnabled }
      }
    }
  } = useCustomerConfig();
  const { clientType } = useParams();
  const isSessionReadOnly = useIsSessionReadOnly();
  const analyticsComponentsFields = useAnalyticsComponentsFields();
  const [
    analyticsComponentsHeaderRef,
    { height: analyticsComponentsHeaderHeight }
  ] = useElementSize();
  const [reportComponentRef, { height: reportComponentHeight }] =
    useElementSize();

  const otherDocumentsFields = React.useMemo(() => {
    const otherDocumentsReportAttachments = reportAttachments
      .filter(
        attachment =>
          !R.prop('clientType', attachment) ||
          attachment.clientType === clientType
      )
      .map(attachment => [
        `otherDocuments.${attachment.id}`,
        {
          render: {
            fieldSection: FieldSections.otherDocuments,
            componentType: FieldComponentTypes.switch,
            labelValue: attachment.title,
            layout: FinalFormSwitchLayouts.horizontal
          }
        }
      ]);

    const otherDocumentsMorningstarAttachments = enableKiidDocuments
      ? morningstarAttachmentsAvailable.map(attachment => [
          `morningstarAttachments.type_${attachment.type}`,
          {
            render: {
              fieldSection: FieldSections.otherDocuments,
              componentType: FieldComponentTypes.switch,
              labelValue:
                i18n(attachment.labelKey) ||
                getTranslation(attachment.label) ||
                '',
              layout: FinalFormSwitchLayouts.horizontal
            }
          }
        ])
      : [];

    const otherDocumentsProductAttachments = enableProductDocuments
      ? [
          [
            `productAttachments`,
            {
              render: {
                fieldSection: FieldSections.otherDocuments,
                componentType: FieldComponentTypes.switch,
                labelKey:
                  'roboAdvice.proposal.generateReport.productAttachments',
                layout: FinalFormSwitchLayouts.horizontal
              }
            }
          ]
        ]
      : [];

    const otherDocuments = [
      [
        'otherDocuments.all',
        {
          render: {
            fieldSection: FieldSections.otherDocuments,
            componentType: FieldComponentTypes.switch,
            labelKey: 'roboAdvice.proposal.generateReport.all',
            layout: FinalFormSwitchLayouts.horizontal
          }
        }
      ],
      clientType === ClientTypes.company &&
        isCompanyKYCEnabled && [
          'otherDocuments.kyc',
          {
            render: {
              fieldSection: FieldSections.otherDocuments,
              componentType: FieldComponentTypes.switch,
              labelKey: 'roboAdvice.proposal.generateReport.kycCompany',
              layout: FinalFormSwitchLayouts.horizontal
            }
          }
        ],
      clientType === ClientTypes.person &&
        isPersonKYCEnabled && [
          'otherDocuments.kyc',
          {
            render: {
              fieldSection: FieldSections.otherDocuments,
              componentType: FieldComponentTypes.switch,
              labelKey: 'roboAdvice.proposal.generateReport.kycPerson',
              layout: FinalFormSwitchLayouts.horizontal
            }
          }
        ],
      ...otherDocumentsReportAttachments,
      ...otherDocumentsMorningstarAttachments,
      ...otherDocumentsProductAttachments
    ];

    return otherDocuments.filter(doc => !!doc);
  }, [reportAttachments, clientType, isCompanyKYCEnabled, isPersonKYCEnabled]);

  React.useEffect(() => {
    afterSwitchChange(
      generateReportForm,
      'report.includeReport',
      reportFields.concat(analyticsComponentsFields),
      ['report.includeReport'],
      true
    );
    afterSwitchChange(
      generateReportForm,
      'analyticsComponents.all',
      analyticsComponentsFields,
      ['analyticsComponents.all'],
      true
    );
    afterSwitchChange(
      generateReportForm,
      'otherDocuments.all',
      otherDocumentsFields,
      ['otherDocuments.all'],
      true
    );
  }, []);

  useUnmountEffect(() => {
    generateReportForm.restartToInitialValues();
  });

  const adjustElement = (allFieldKey, sectionFields) => (field, element) => {
    return React.cloneElement(element, {
      fieldClassName: classes.field,
      labelClassName: classes.switchLabel,
      afterChange: value =>
        afterSwitchChange(
          generateReportForm,
          allFieldKey,
          sectionFields,
          field,
          value
        )
    });
  };

  return (
    <Form form={generateReportForm} onSubmit={() => {}}>
      {() => (
        <FormSpy subscription={{ values: true }}>
          {props => (
            <div className={classes.formColumns}>
              <div>
                <div ref={reportComponentRef}>
                  <div className={classes.formColumnHeader}>
                    {i18n('roboAdvice.proposal.generateReport.report')}
                  </div>

                  <RenderFields
                    fields={reportFields}
                    adjustElement={adjustElement(
                      'report.includeReport',
                      reportFields.concat(analyticsComponentsFields)
                    )}
                    disabled={!isSessionReadOnly}
                  />
                </div>

                <div className={classes.formColumnHeader}>
                  {i18n('roboAdvice.proposal.generateReport.attachments')}
                </div>

                {modalBodyMaxHeight ? (
                  <Scrollbars
                    autoHeightMax={
                      modalBodyMaxHeight -
                      (analyticsComponentsHeaderHeight +
                        formColumnHeaderMarginBottom) -
                      reportComponentHeight -
                      notFirstColumnHeaderMarginTop
                    }
                  >
                    <RenderFields
                      fields={otherDocumentsFields}
                      adjustElement={adjustElement(
                        'otherDocuments.all',
                        otherDocumentsFields
                      )}
                    />
                  </Scrollbars>
                ) : (
                  <RenderFields
                    fields={otherDocumentsFields}
                    adjustElement={adjustElement(
                      'otherDocuments.all',
                      otherDocumentsFields
                    )}
                  />
                )}
              </div>

              <div>
                <div
                  ref={analyticsComponentsHeaderRef}
                  className={classes.formColumnHeader}
                >
                  {i18n(
                    'roboAdvice.proposal.generateReport.analyticsComponents'
                  )}
                </div>

                {modalBodyMaxHeight ? (
                  <Scrollbars
                    autoHeightMax={
                      modalBodyMaxHeight -
                      (analyticsComponentsHeaderHeight +
                        formColumnHeaderMarginBottom)
                    }
                  >
                    <RenderFields
                      fields={analyticsComponentsFields}
                      adjustElement={adjustElement(
                        'analyticsComponents.all',
                        analyticsComponentsFields
                      )}
                      disabled={!props.values.report?.includeReport}
                    />
                  </Scrollbars>
                ) : (
                  <RenderFields
                    fields={analyticsComponentsFields}
                    adjustElement={adjustElement(
                      'analyticsComponents.all',
                      analyticsComponentsFields
                    )}
                    disabled={!props.values.report?.includeReport}
                  />
                )}
              </div>
            </div>
          )}
        </FormSpy>
      )}
    </Form>
  );
};

export default GenerateReportForm;
