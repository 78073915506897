import { isNil, all } from 'ramda';
import React from 'react';

import CostTable from './costTable';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  tableHeader: {
    paddingTop: '30px',
    paddingBottom: '20px',
    textAlign: 'left'
  }
});

const YearsTablesSection = ({
  tableData,
  headerTitles,
  dataKeys,
  asterisk,
  sectionHeader,
  highlightLastRow
}) => {
  const classes = useStyles();

  return (
    !!tableData?.length &&
    all(item => !isNil(item.percent) || !isNil(item.value), tableData) && (
      <>
        <div className={classes.tableHeader}>
          <span>{sectionHeader}</span>
        </div>
        <CostTable
          headerTitles={headerTitles}
          data={tableData}
          dataKeys={dataKeys}
          asterisk={asterisk}
          highlightLastRow={highlightLastRow}
        />
      </>
    )
  );
};

export default YearsTablesSection;
