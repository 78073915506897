import * as R from 'ramda';
import create from 'zustand';

import { Status } from '../../../shared/types';

export type PageStatuses = {
  readCustomerConfig?: Status;
};

export type PreviewStoreData = {
  pageStatuses: PageStatuses;
  customerConfig?: any;
};

export type PreviewStoreApi = {
  reset: () => void;
  setPageStatus: <K extends keyof PageStatuses>(
    key: K,
    value: PageStatuses[K]
  ) => void;
  setCustomerConfig: (customerConfig: any) => void;
};

export type PreviewStoreState = PreviewStoreData & PreviewStoreApi;

export const defaultData = {
  pageStatuses: {},
  customerConfig: undefined
};

export const usePreviewStore = create<PreviewStoreState>(set => ({
  ...defaultData,
  reset() {
    set(defaultData);
  },
  setPageStatus(key, value) {
    set(R.set(R.lensPath(['pageStatuses', key]), value), true);
  },
  setCustomerConfig(customerConfig) {
    set({ customerConfig });
  }
}));
