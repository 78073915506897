import classNames from 'classnames';
import { format } from 'date-fns';
import { isNil, isEmpty } from 'ramda';

import { getErrorText } from './shared.js';
import { useField } from './useField';
import { Layouts } from 'features/roboAdvice/shared/constants';
import { dateFormats } from 'features/shared/components/datePicker/constants';
import CustomDatePicker from 'features/shared/components/datePicker/index.js';
import ErrorMessage from 'features/shared/components/errorMessage/index.js';
import {
  Typography,
  FontWeights,
  FontFamilies
} from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const borderRadius = {
  [BorderRadiusTypes.rounded]: '80px',
  [BorderRadiusTypes.sharp]: '5px'
};

const useStyles = createUseStyles(theme => ({
  section: {
    '& + &': {
      marginTop: '3rem'
    }
  },
  horizontalSection: {
    display: 'inline-flex',
    alignItems: 'baseline'
  },
  label: {
    color: theme.secondaryColor
  },
  horizontalLabel: {
    marginRight: '2.4rem',
    flex: '0 0 auto',
    width: '350px'
  },
  dateInput: {
    width: '230px',
    height: 'calc(4rem + 4px)',
    border: `2px solid ${theme.defaultInputStrokeColor}`,
    borderRadius: borderRadius[theme.borderRadiusType],
    padding: '0.8rem 2.4rem 0.8rem 2.4rem',
    color: theme.primaryColor,
    backgroundColor: theme.defaultInputFillColor,
    fontFamily: FontFamilies.roboto,
    fontWeight: FontWeights.normal,
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,
    outline: 'none',

    '&::-ms-clear': {
      display: 'none'
    },

    '&::placeholder': {
      color: theme.secondaryColor
    },

    '&$invalid': {
      border: `2px solid ${theme.errorNegativeColor}`
    },

    '&:hover, &$hover': {
      border: `2px solid ${theme.hoverInputStrokeColor}`
    },

    '&:focus, &$focus': {
      border: `2px solid ${theme.hoverInputStrokeColor}`,
      backgroundColor: theme.inputFillFocusColor
    },

    '&:disabled': {
      backgroundColor: theme.defaultInputFillColor,
      color: theme.disabledTextColor,
      borderColor: theme.disabledBackgroundColor
    },

    '&:read-only': {
      backgroundColor: theme.defaultInputFillColor,
      color: theme.disabledTextColor,
      borderColor: theme.disabledBackgroundColor
    }
  },
  hasError: {
    border: `2px solid ${theme.errorNegativeColor}`
  }
}));

type Props = {
  name: string;
  label?: string;
  fieldConfig?: any;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  labelClassName?: string;
  sectionClassName?: string;
  dateFormat?: string;
  layout?: string;
  dateType?: string;
  allowedYearsRange?: {
    yearsBack?: number;
    yearsForward?: number;
  };
  minDate?: Date;
  maxDate?: Date;
  hasError?: boolean;
  customOnChange?: (value: Date) => void;
};

const FinalFormDatepicker = ({
  name,
  label,
  layout,
  disabled,
  dateType,
  className,
  dateFormat,
  fieldConfig,
  placeholder,
  labelClassName,
  sectionClassName,
  allowedYearsRange,
  minDate,
  maxDate,
  hasError,
  customOnChange
}: Props) => {
  const styles = useStyles();

  const i18n = useI18n();
  const {
    input: { value, onChange },
    meta: { error, touched }
  } = useField(name, fieldConfig);

  const isYearPicker = dateFormat === dateFormats.years;
  const isErrorShown = isNil(hasError) ? touched && !isNil(error) : hasError;
  const errorText = getErrorText(i18n, error);
  const selectedDate = value
    ? isYearPicker
      ? new Date(value, 0)
      : new Date(value)
    : '';
  let fullSectionClassName: string | undefined;
  let fullLabelClassName: string | undefined;

  if (layout === Layouts.vertical) {
    fullSectionClassName = classNames(styles.section, sectionClassName);
    fullLabelClassName = classNames(styles.label, labelClassName);
  } else if (layout === Layouts.horizontal) {
    fullSectionClassName = classNames(
      styles.section,
      styles.horizontalSection,
      sectionClassName
    );
    fullLabelClassName = classNames(
      styles.label,
      styles.horizontalLabel,
      labelClassName
    );
  }

  return (
    <div className={fullSectionClassName}>
      {!isNil(label) && !isEmpty(label) && (
        <div className={fullLabelClassName}>{label}</div>
      )}
      <CustomDatePicker
        selected={selectedDate}
        onChange={value => {
          isYearPicker
            ? onChange(value.getFullYear())
            : onChange(format(value, 'yyyy-MM-dd'));
          customOnChange?.(value);
        }}
        dateFormat={dateFormat}
        dateType={dateType}
        inputClassName={classNames(styles.dateInput, className, {
          [styles.hasError]: hasError
        })}
        placeholder={placeholder}
        disabled={disabled}
        allowedYearsRange={allowedYearsRange}
        minDate={minDate}
        maxDate={maxDate}
      />
      {isErrorShown && isNil(hasError) && (
        <ErrorMessage>{`* ${errorText}`}</ErrorMessage>
      )}
    </div>
  );
};

export default FinalFormDatepicker;
