import Detail from './detail';
import { useForm as useRoboAdviceForm } from 'features/roboAdvice/adviceSession/form/services/form';

type Props = {
  name: string;
  disabled?: boolean;
  allowNegative?: boolean;
};

const Details = ({ name, disabled, allowNegative }: Props) => {
  const { values } = useRoboAdviceForm();
  const [clientTypeKey, fieldName] = name.split('.');
  const fieldsValue = values?.[clientTypeKey]?.[fieldName];
  const fields = !fieldsValue || !Array.isArray(fieldsValue) ? [] : fieldsValue;

  return (
    <div>
      {fields.map((field, index) => (
        <Detail
          key={field.id}
          name={name}
          index={index}
          fields={fields}
          disabled={disabled}
          allowNegative={allowNegative}
        />
      ))}
    </div>
  );
};

export default Details;
