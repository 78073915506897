import { RoboAdviceAdviceSessionPages } from '../../../roboAdvice/shared/constants';
import { getNumberOfErrors } from '../utils';
import { useGetAdviceInformationErrors } from './useGetAdviceInformationErrors';
import { useGetAdvisoryErrors } from './useGetAdvisoryErrors';
import { useGetFinancialSituationErrors } from './useGetFinancialSituationErrors';
import { useGetKnowledgeAndExperienceErrors } from './useGetKnowledgeAndExperienceErrors';
import { useGetPurposeAndRiskErrors } from './useGetPurposeAndRiskErrors';
import { useGetSustainabilityErrors } from './useGetSustainabilityErrors';

export type GetAllAdviceSessionErrors = {
  numberOfAllErrors: number;
  numberOfFirstPartErrors: number;
  [RoboAdviceAdviceSessionPages.adviceInformation]: ReturnType<
    typeof useGetAdviceInformationErrors
  >;
  [RoboAdviceAdviceSessionPages.purposeAndRisk]: ReturnType<
    typeof useGetPurposeAndRiskErrors
  >;
  [RoboAdviceAdviceSessionPages.knowledgeAndExperience]: ReturnType<
    typeof useGetKnowledgeAndExperienceErrors
  >;
  [RoboAdviceAdviceSessionPages.financialSituation]: ReturnType<
    typeof useGetFinancialSituationErrors
  >;
  [RoboAdviceAdviceSessionPages.sustainability]: ReturnType<
    typeof useGetSustainabilityErrors
  >;
  [RoboAdviceAdviceSessionPages.advisory]: ReturnType<
    typeof useGetAdvisoryErrors
  >;
};

export const useGetAllAdviceSessionErrors = (): GetAllAdviceSessionErrors => {
  const pagesErrors = {
    [RoboAdviceAdviceSessionPages.adviceInformation]:
      useGetAdviceInformationErrors(),
    [RoboAdviceAdviceSessionPages.purposeAndRisk]: useGetPurposeAndRiskErrors(),
    [RoboAdviceAdviceSessionPages.knowledgeAndExperience]:
      useGetKnowledgeAndExperienceErrors(),
    [RoboAdviceAdviceSessionPages.financialSituation]:
      useGetFinancialSituationErrors(),
    [RoboAdviceAdviceSessionPages.sustainability]: useGetSustainabilityErrors(),
    [RoboAdviceAdviceSessionPages.advisory]: useGetAdvisoryErrors()
  };

  let numberOfAllErrors = 0,
    numberOfFirstPartErrors = 0;

  Object.entries(pagesErrors).forEach(([pageId, pageErrors]) => {
    numberOfAllErrors += getNumberOfErrors(pageErrors);
    numberOfFirstPartErrors +=
      pageId !== RoboAdviceAdviceSessionPages.advisory
        ? getNumberOfErrors(pageErrors)
        : 0;
  });

  return {
    ...pagesErrors,
    numberOfAllErrors,
    numberOfFirstPartErrors
  };
};
