import { useEffect } from 'react';
import Helmet from 'react-helmet';
import { components } from 'react-select';

import { usePageLanguage } from './usePageLanguage';
import Select, { Option } from 'features/shared/components/select';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

type Props = {
  ariaLabelledBy: string;
  className?: string;
  language: string;
  setLanguage: (language: string) => void;
};

const PageLanguage = ({
  ariaLabelledBy,
  className,
  language,
  setLanguage
}: Props) => {
  const i18n = useI18n();
  const { supportedLanguages, languageDescriptions } = useCustomerConfig();
  const { language: customerPageLanguage } = useCustomerConfig();
  const { loadPageLanguage } = usePageLanguage();

  const languageOptions: Option<string>[] = supportedLanguages.options.map(
    el => ({
      value: el,
      label: i18n(languageDescriptions[el])
    })
  );

  useEffect(() => {
    const userPageLanguage = loadPageLanguage();

    if (!userPageLanguage && customerPageLanguage) {
      setLanguage(customerPageLanguage);
    }
  }, [customerPageLanguage]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: language }} />
      <div className={className}>
        <Select
          ariaLabelledBy={ariaLabelledBy}
          id="select-language"
          components={{
            SingleValue: ({ children, ...props }) => {
              if (props.data.label) {
                const optionLabel = props.options.find(
                  ({ label }) => label === props.data.label
                );

                if (!optionLabel) {
                  const option = (props.options as Option<string>[]).find(
                    option => option.value === props.data.value
                  );
                  option && props.selectOption(option);
                }
              }

              return (
                <components.SingleValue {...props}>
                  {children}
                </components.SingleValue>
              );
            }
          }}
          options={languageOptions}
          value={language}
          onChange={value => setLanguage(value)}
        />
      </div>
    </>
  );
};

export default PageLanguage;
