import {
  AssetsDrilldownChart,
  FinancialSituationAggregatedChart
} from '@quantfoliorepo/ui-components';
import React from 'react';
import { useSelector } from 'react-redux';

import { useHolisticViewStore } from '../services/holisticViewStore';
import HolisticSection from './holisticSection';
import { useIsLoading } from './useIsLoading';
import LoadingIndicator from 'features/shared/components/loadingIndicator/loadingIndicator';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  header: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '23px',
    margin: '64px 0 32px'
  },
  assetsDrilldownChartsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '20px calc(100% - 1020px)',
    width: '100%'
  }
});

const FinancialSituation = () => {
  const classes = useStyles();
  const theme = useTheme();
  const holisticViewStore = useHolisticViewStore();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const i18n = useI18n();
  const isLoading = useIsLoading();

  const financialSituationAggregatedChartData: {
    name: string;
    data: [number, number];
  }[] = React.useMemo(() => {
    return holisticViewStore.availableClients
      .filter(
        ({ isSelected, financialSituationData }) =>
          isSelected && financialSituationData
      )
      .map(({ financialSituationData }) => ({
        name: financialSituationData?.name || '',
        data: [
          financialSituationData?.totalAssets || 0,
          financialSituationData?.totalDebt || 0
        ]
      }));
  }, [holisticViewStore.availableClients]);

  const assetsDrilldownChartsData = React.useMemo(() => {
    return holisticViewStore.availableClients
      .filter(
        ({ isSelected, financialSituationData }) =>
          isSelected && financialSituationData
      )
      .map(({ financialSituationData }) => ({
        name: financialSituationData?.name || '',
        data: financialSituationData?.assets!
      }));
  }, [holisticViewStore.availableClients]);

  // No selected clients
  if (
    !isLoading &&
    holisticViewStore.availableClients.filter(c => c.isSelected).length === 0
  ) {
    return (
      <HolisticSection
        sectionHeader={i18n('roboAdvice.tabs.financialSituation')}
      />
    );
  }

  // Not enough data
  if (
    !isLoading &&
    holisticViewStore.availableClients
      .filter(c => c.isSelected)
      .every(c => !c.selectedAdviceSessionId || !c.financialSituationData)
  ) {
    return (
      <HolisticSection
        sectionHeader={i18n('roboAdvice.tabs.financialSituation')}
        notEnoughData
      />
    );
  }

  // Loading data
  if (isLoading) {
    return (
      <HolisticSection
        sectionHeader={i18n('roboAdvice.tabs.financialSituation')}
      >
        <LoadingIndicator color={theme.accentColor} />
      </HolisticSection>
    );
  }

  return (
    <HolisticSection sectionHeader={i18n('roboAdvice.tabs.financialSituation')}>
      {!!financialSituationAggregatedChartData.length && (
        <FinancialSituationAggregatedChart
          chartData={financialSituationAggregatedChartData}
          cultureCode={cultureCode}
          height={100 + financialSituationAggregatedChartData.length * 30}
        />
      )}

      {!!assetsDrilldownChartsData.length && (
        <>
          <div className={classes.header}>
            {i18n('tools.holisticView.assetsDrilldown')}
          </div>

          <div className={classes.assetsDrilldownChartsContainer}>
            {assetsDrilldownChartsData.map(data => (
              <AssetsDrilldownChart
                key={data.name}
                chartData={data}
                cultureCode={cultureCode}
              />
            ))}
          </div>
        </>
      )}
    </HolisticSection>
  );
};

export default FinancialSituation;
