import { ValidationErrors } from 'final-form';

import { createForm } from 'features/sharedModules/finalForm/services/finalForm';
import {
  UseFinalFormStore,
  createUseFinalFormAdapter
} from 'features/sharedModules/zustand/components/finalFormAdapter';

export type ProfileFormValues = {
  name: string;
  email: string;
  bio: string | null;
  phone: string | null;
  timezone: string | null;
};

export const profileForm = createForm<ProfileFormValues>({
  onSubmit: () => {},
  validate: value => {
    let errors: ValidationErrors = {};

    if (!value?.name || value.name.trim().length === 0) {
      errors.name = 'profile.nameEmptyValidationError';
    }

    return errors;
  }
});

export type UseProfileForm = UseFinalFormStore<ProfileFormValues>;

export const useProfileForm = createUseFinalFormAdapter({
  form: profileForm,
  formSubscription: {
    values: true,
    errors: true,
    touched: true,
    pristine: true
  }
});
