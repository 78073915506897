const config = {
  PORTAL_ELEMENT_ID: 'portal-root',
  CONFIRMATION_ELEMENT_ID: 'confirmation-root',
  APP_ELEMENT_ID: 'root',
  AUTH_AUDIENCE: 'https://deep-alpha/api',
  AUTH0_ID_KEY: 'http://deep-alpha/id',
  AUTH0_ROLES_KEY: 'http://deep-alpha/roles',
  AUTH0_PERMISSIONS_KEY: 'permissions',
  IDP_EXTERNAL_ID: 'http://deep-alpha/external_id',
  APPLICATION_DEBUG: false,
  ...window.env,
  REACT_APP_AUTH_PROVIDER_ALGORITHMS:
    window.env.REACT_APP_AUTH_PROVIDER_ALGORITHMS.split(',')
};

export default config;
