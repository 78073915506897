import { ReactNode } from 'react';
import { z } from 'zod';

import { ProductAttributeType } from '../shared/constants';
import { CustomPortfolioAllocationMethods } from './services/constants';
import { AssetClass } from './services/customPortfolioStore';
import { ProductAttributeGeneric } from 'defaults/shared';

type TableInstrument = {
  id: string;
  name: string;
  instrumentWeight: number | undefined;
};

export type FundAllocationRow = Omit<AssetClass, 'instruments'> & {
  weight: number | undefined;
  instruments: (TableInstrument & {
    createAdditionalData?: ({ className }: { className: string }) => ReactNode;
  })[];
  isEditing: boolean;
  allAvailableInstruments: TableInstrument[];
  index: number;
};

export type ProductAttributeExtended = ProductAttributeGeneric & {
  name: string;
  className: string;
  type: keyof typeof ProductAttributeType;
};

export const namespaceConfigSchema = z.object({
  labelKey: z.string(),
  namespace_id: z.number(),
  enforce: z
    .object({
      is_pension_product: z.boolean().optional().nullable(),
      minimum_saving_horizon: z.boolean().optional().nullable(),
      minimum_monthly_saving_amount: z.boolean().optional().nullable(),
      maximum_monthly_saving_amount: z.boolean().optional().nullable(),
      minimum_initial_deposit: z.boolean().optional().nullable(),

      product_question_preference_1: z.boolean().optional().nullable(),
      product_question_preference_2: z.boolean().optional().nullable(),
      product_question_preference_3: z.boolean().optional().nullable(),
      product_question_preference_4: z.boolean().optional().nullable(),
      product_question_preference_5: z.boolean().optional().nullable()
    })
    .optional(),
  is_pension_product: z.boolean().optional().nullable(),

  minimum_saving_horizon: z.number().optional().nullable(),

  minimum_monthly_saving_amount: z.number().optional().nullable(),
  maximum_monthly_saving_amount: z.number().optional().nullable(),
  minimum_initial_deposit: z.number().optional().nullable(),

  product_question_preference_1: z.boolean().optional().nullable(),
  product_question_preference_2: z.boolean().optional().nullable(),
  product_question_preference_3: z.boolean().optional().nullable(),
  product_question_preference_4: z.boolean().optional().nullable(),
  product_question_preference_5: z.boolean().optional().nullable()
});

export type NamespaceConfig = z.infer<typeof namespaceConfigSchema>;

export const questionSchema = z.object({
  enabled: z.boolean(),
  label: z.record(z.string()),
  name: z.string()
});

export type Question = z.infer<typeof questionSchema>;

export const NamespaceStatus = {
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  NEUTRAL: 'NEUTRAL',
  NOT_RECOMMENDED: 'NOT_RECOMMENDED',
  RECOMMENDED: 'RECOMMENDED'
} as const;

export type NamespaceStatusType = keyof typeof NamespaceStatus;

export type CustomPortfolioAllocationMethod =
  typeof CustomPortfolioAllocationMethods[keyof typeof CustomPortfolioAllocationMethods];
