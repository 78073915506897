import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getCurrentDate } from '../../../shared/utils';
import { createAnalyzeForecast } from '../../shared/api';
import { AnalyzeForecastResponse } from '../../shared/api/types';
import useReadCustomAttributesData from '../../shared/components/useReadCustomAttributesData';
import { PageStatuses } from '../../shared/components/useReadDataListener';
import { useGoalsStore } from '../../shared/services/goalsStore';
import { areProductAttributesEnabled } from '../../shared/services/selectors';
import { DEFAULT_CONFIDENCE } from '../constants';
import { usePageStore as useProposalPageStore } from '../services/pageStore';
import {
  getIsFormComplete,
  getHorizonAnalysisProjectionYearsValue
} from '../services/selectors.js';
import { usePortfolioChartStore } from 'features/roboAdvice/adviceSession/portfolioChart';
import { useSessionStore } from 'features/roboAdvice/adviceSession/session/services/sessionStore';
import { useReadReportDocuments } from 'features/roboAdvice/shared/components/useReadReportDocuments';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useReadProposalPageData() {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();
  const { chartData } = usePortfolioChartStore.getState();
  const portfolioChartStore = usePortfolioChartStore();
  const { adviceSessionId }: any = useParams();
  const readReportDocuments = useReadReportDocuments();
  const readCustomAttributesData = useReadCustomAttributesData();
  const sessionStoreState = useSessionStore.getState();

  const {
    timeHorizonConfig,
    roboAdviceForm: {
      proposal: { orderSummary, sustainability }
    }
  } = useCustomerConfig();

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const readProposalPageData = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const proposalPageStore = useProposalPageStore.getState();
    const { goals: storeGoals } = useGoalsStore.getState();

    const isFormComplete = getIsFormComplete();
    if (
      !isFormComplete ||
      chartData.length === 0 ||
      proposalPageStore.pageStatuses.readPortfolioChartData ===
        PageStatuses.pending
    ) {
      proposalPageStore.setPageStatus(
        'readProposalPageData',
        PageStatuses.failed
      );

      return;
    }

    try {
      proposalPageStore.setPageStatus(
        'readProposalPageData',
        PageStatuses.pending
      );

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const currentDate = getCurrentDate();

      const isUpdated =
        !!proposalPageStore.goalsToUpdate.readProposalPageData.length;

      const goals = isUpdated
        ? proposalPageStore.goalsToUpdate.readProposalPageData
        : storeGoals;

      for (const goal of goals) {
        const portfolio = portfolioChartStore
          .getChartData(goal.goalId, goal.data.isPortfolioCustom)
          .categorySelectionPortfolio.map(({ id, weight }) => ({ id, weight }));

        const timeHorizon = getHorizonAnalysisProjectionYearsValue({
          timeHorizonConfig,
          timeHorizon: goal.data.timeHorizon
        });

        const createAnalyzeForecastResponse = await createAnalyzeForecast(
          qAuthAccessToken,
          cancelTokenSource.token,
          {
            confidence_interval: DEFAULT_CONFIDENCE,
            initial_deposit:
              (goal.data.firstDeposit ?? 0) +
              (goal.data.internalHolding ?? 0) +
              ((goal.data.followUpAddOn ?? 0) -
                (goal.data.followUpWithdrawal ?? 0)),
            monthly_saving: goal.data.monthlyDeposit,
            portfolio,
            time_horizon: timeHorizon
          },
          {
            namespace_id: goal.data.productPlatformNamespace
          }
        );

        const expectedValue = getExpectedValue(
          timeHorizon as number,
          currentDate,
          createAnalyzeForecastResponse.data.forecast
        );

        useGoalsStore
          .getState()
          .setGoalExpectedValue(expectedValue, goal.goalId);

        const namespaceId =
          goal.data.productPlatformNamespace ||
          sessionStoreState.defaultConfigNamespaceId;

        if (
          (areProductAttributesEnabled(sustainability) ||
            areProductAttributesEnabled(orderSummary)) &&
          namespaceId
        ) {
          await readCustomAttributesData(namespaceId);
        }
      }

      await readReportDocuments(adviceSessionId);

      if (isUpdated) {
        proposalPageStore.setGoalsToUpdate('readProposalPageData', []);
      }

      proposalPageStore.setPageStatus(
        'readProposalPageData',
        PageStatuses.succeed
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        proposalPageStore.setPageStatus(
          'readProposalPageData',
          PageStatuses.failed
        );

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.proposal.readDataErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readProposalPageData;
}

export const getExpectedValue = (
  timeHorizon: number,
  currentDate: Date,
  forecast: AnalyzeForecastResponse['forecast']
) => {
  const monthsOffset = timeHorizon * 12;
  const months = currentDate.getFullYear() * 12 + currentDate.getMonth();

  return (
    forecast
      .map(i => {
        const date = new Date(i.date);

        return {
          value: i.value,
          months: date.getFullYear() * 12 + date.getMonth()
        };
      })
      .find(i => i.months - months === monthsOffset)?.value ?? 0
  );
};
