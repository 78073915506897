export const TextFontSizes = {
  xxSmall: '10px',
  xxSmallLineHeight: '15px',
  xSmall: '12px',
  xSmallLineHeight: '18px',
  small: '1.3rem',
  smallLineHeight: '1.95rem',
  normal: '14px',
  normalLineHeight: '21px',
  medium: '1.6rem',
  mediumLineHeight: '2.4rem',
  mediumPx: '16px',
  mediumLineHeightPx: '24px',
  xMedium_1: '1.8rem',
  xMediumLineHeight_1: '2.7rem',
  xMedium_2: '1.92rem',
  xMediumLineHeight_2: '2.88rem',
  large: '20px',
  largeLineHeight: '30px',
  xLarge: '2.4rem',
  xLargeLineHeight: '3.6rem',
  xxLarge: '3.2rem',
  xxLargeLineHeight: '4.8rem',
  x05: '13px',
  x05LineHeight: '16px',
  x1: '16px',
  x1LineHeight: '20px',
};

export const ButtonFontSizes = {
  medium: '1.6rem',
  large: '2rem',
};

// https://github.com/jonathantneal/css-font-weight-names
export const FontWeights = {
  light: '300',
  normal: '400',
  medium: '500',
  bold: '700',
};

export const FontFamilies = {
  roboto: "'Roboto', sans-serif",
};
