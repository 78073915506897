import { prop, groupBy, clone, isEmpty } from 'ramda';
import { useMemo } from 'react';

import Chart from './chart.js';
import LoadingIndicator from 'features/shared/components/loadingIndicator/loadingIndicator';
import { Colors } from 'features/shared/constants/colors';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { useTheme } from 'features/sharedModules/styles/components/styles';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  noDataMessage: {
    paddingTop: '40px',
    paddingBottom: '40px',
    display: 'flex',
    justifyContent: 'center',
    color: Colors.gray50
  },
  body: {
    paddingTop: '40px'
  }
});

const EfficientFrontierChartVisual = ({
  efficientFrontierChart,
  selectedGoalsIds,
  cultureCode,
  isLoading = false
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const i18n = useI18n();

  const chartData = useMemo(() => {
    const groupedEfficientFrontierChart = Object.values(
      groupBy(prop('portfolioRiskReturnChartData'))(efficientFrontierChart)
    )
      .map((efficientFrontierCharts, index) =>
        efficientFrontierCharts
          .map(ef => ({
            ...clone(ef),
            color: theme.chartPortfolioColors[index]
          }))
          .filter(({ goalId }) => selectedGoalsIds.includes(goalId))
      )
      .filter(efficientFrontierCharts => efficientFrontierCharts.length);

    const efficientFrontierChartData = groupedEfficientFrontierChart.length
      ? groupedEfficientFrontierChart.flat()[0].efficientFrontierChartData
      : [];

    const portfolioRiskReturnChartData = groupedEfficientFrontierChart.map(
      efficientFrontierCharts => ({
        data: efficientFrontierCharts[0].portfolioRiskReturnChartData,
        color: efficientFrontierCharts[0].color
      })
    );

    const goalNames = groupedEfficientFrontierChart.map(
      efficientFrontierCharts => ({
        name: efficientFrontierCharts
          .map(({ goalName }) => goalName)
          .join(' / '),
        color: efficientFrontierCharts[0].color
      })
    );

    return {
      efficientFrontierChartData,
      portfolioRiskReturnChartData,
      goalNames
    };
  }, [efficientFrontierChart, selectedGoalsIds, theme.chartPortfolioColors]);

  const isChartDataEmpty =
    isEmpty(chartData.efficientFrontierChartData) ||
    isEmpty(chartData.portfolioRiskReturnChartData);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isChartDataEmpty) {
    return (
      <div className={classes.noDataMessage}>
        {i18n('roboAdvice.proposal.NoEfficientFrontierData')}
      </div>
    );
  }

  return (
    <div className={classes.body}>
      <Chart chartData={chartData} cultureCode={cultureCode} />
    </div>
  );
};

export default EfficientFrontierChartVisual;
