import classNames from 'classnames';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import Tab from './tab.js';
import { isPageOrderExecution } from 'features/roboAdvice/adviceSession/orderExecution/services/selectors';
import {
  RoboAdviceAdviceSessionPages,
  RoboAdviceOrderExecutionPages
} from 'features/roboAdvice/shared/constants';
import {
  AdviceSessionParams,
  OrderExecutionParams
} from 'features/shared/constants/session';
import TabErrorMessage from 'features/sharedModules/errors/components/tabErrorMessage';
import { useErrorsStore } from 'features/sharedModules/errors/services/errorsStore';
import { useGetAllAdviceSessionErrors } from 'features/sharedModules/errors/services/useGetAllAdviceSessionErrors';
import { useGetAllOrderExecutionErrors } from 'features/sharedModules/errors/services/useGetAllOrderExecutionErrors';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  container: {
    display: 'flex'
  },
  tabContainer: {
    '& + &': {
      marginLeft: '30px'
    }
  }
});

const operatingKeys = {
  arrowRight: 'ArrowRight',
  arrowLeft: 'ArrowLeft',
  home: 'Home',
  end: 'End'
} as const;

type Props = {
  value: string;
  onChange: (value: string) => void;
  items: {
    value: string;
    label: string;
    progress: number;
    hasError?: boolean;
    disabled?: boolean;
  }[];
  className?: string;
};

const Tabs = ({ value, onChange, items, className }: Props) => {
  const classes = useStyles();
  const tabRefs = useRef({});
  const [currentFocusId, setCurrentFocusId] = useState<string | undefined>();
  const errorsStore = useErrorsStore();
  const { pageId } = useParams<AdviceSessionParams | OrderExecutionParams>();
  const adviceSessionErrors = useGetAllAdviceSessionErrors();
  const orderExecutionErrors = useGetAllOrderExecutionErrors();
  const allErrors = isPageOrderExecution(pageId)
    ? orderExecutionErrors
    : adviceSessionErrors;

  const setIndex = (val: string) => {
    const tab = tabRefs.current[val];
    if (tab) {
      tab.focus();
      setCurrentFocusId(val);
    }
  };

  const onKeyDown = event => {
    if (Object.values(operatingKeys).includes(event.key)) {
      event.preventDefault();
      const count = items.length;
      const currentItemIndex = items.findIndex(i => i.value === currentFocusId);

      switch (event.key) {
        case operatingKeys.arrowRight:
          setIndex(items[(currentItemIndex + 1) % count].value);
          break;
        case operatingKeys.arrowLeft:
          setIndex(items[(currentItemIndex - 1 + count) % count].value);
          break;
        case operatingKeys.home:
          setIndex(items[0].value);
          break;
        case operatingKeys.end:
          setIndex(items[count - 1].value);
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      <div
        className={classNames(classes.container, className)}
        role="tablist"
        aria-orientation="horizontal"
      >
        {items.map(i => (
          <div key={i.value} className={classes.tabContainer}>
            <Tab
              value={i.value}
              label={i.label}
              progress={i.progress}
              hasError={i.hasError}
              onClick={() => {
                errorsStore.hideTabsMessage();
                onChange(i.value);
              }}
              active={i.value === value}
              disabled={i.disabled}
              forwardedRef={element => (tabRefs.current[i.value] = element)}
              onKeyDown={onKeyDown}
              onFocus={() => setCurrentFocusId(i.value)}
              tabIndex={i.value === value ? 0 : -1}
            />
            {(i.value === RoboAdviceAdviceSessionPages.advisory ||
              i.value === RoboAdviceOrderExecutionPages.order) &&
              errorsStore.isTabsMessageShown && (
                <TabErrorMessage
                  allErrors={allErrors}
                  closeErrorMessage={() => errorsStore.hideTabsMessage()}
                />
              )}
          </div>
        ))}
      </div>
    </>
  );
};

export default Tabs;
