import * as R from 'ramda';

import { adviceSessionsTable } from '../../adviceSessionsTable';
import {
  AdviceSession,
  AdviceSessionsStoreData,
  useAdviceSessionsStore
} from '../services/adviceSessionsStore';
import { getPage } from 'features/shared/services/table/selector';
import { TableStoreData } from 'features/sharedModules/table/types';
import { getSortFunc } from 'features/sharedModules/table/utils';

export function useAdviceSessions() {
  const tableStore = adviceSessionsTable.useTableStore();
  const adviceSessionsStore = useAdviceSessionsStore();

  return getAdviceSessionsResult({
    tableStoreData: tableStore,
    adviceSessionsStoreData: adviceSessionsStore
  });
}

export type AdviceSessionTableView = {
  id: string;
  name: string;
  clientName: string;
  advisorName: string;
  lastActivityDate: string;
  softDeleteDate: string;
};

export function getAdviceSessionsResult({
  tableStoreData,
  adviceSessionsStoreData
}: {
  tableStoreData: TableStoreData;
  adviceSessionsStoreData: AdviceSessionsStoreData;
}): {
  items: AdviceSessionTableView[];
  allItemIds: string[];
  totalItemsNumber: number;
} {
  const items = R.pipe<
    AdviceSession[],
    AdviceSession[],
    { items: AdviceSession[]; totalItemsNumber: number }
  >(
    getSortFunc({ tableStoreData }),
    getPage(tableStoreData)
  )(adviceSessionsStoreData.adviceSessions);

  const allItemIds = R.map(c => c.id, adviceSessionsStoreData.adviceSessions);

  return {
    ...items,
    allItemIds
  };
}
