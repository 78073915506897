import { clientsTable } from '../../clientsTable';
import { useClientsListStore } from '../services/clientsListStore';

export function useCleanupPage() {
  const cleanupPage = () => {
    const clientsListStoreState = useClientsListStore.getState();
    const clientsTableStoreState = clientsTable.useTableStore.getState();

    clientsListStoreState.reset();
    clientsTableStoreState.reset();
  };

  return cleanupPage;
}
