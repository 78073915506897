import { SigninRedirectArgs, UserManager } from 'oidc-client-ts';
import queryString from 'query-string';

import config from 'config/index.js';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import routeTemplates from 'features/shared/utils/routeTemplates';

let metadata: Record<string, string> | undefined = undefined;

let extraQueryParams: Record<string, string> = {};
if (config.REACT_APP_AUTH_AUTH0_MODE) {
  const endSessionConfig = {
    returnTo: window.location.origin,
    client_id: config.REACT_APP_AUTH_PROVIDER_DEEP_ALPHA_CLIENT_ID
  };

  metadata = {
    authorization_endpoint: `${config.REACT_APP_AUTH_PROVIDER_DOMAIN}/authorize`,
    token_endpoint: `${config.REACT_APP_AUTH_PROVIDER_DOMAIN}/oauth/token`,
    issuer: `${config.REACT_APP_AUTH_PROVIDER_DOMAIN}/`,
    jwks_uri: `${config.REACT_APP_AUTH_PROVIDER_DOMAIN}/.well-known/jwks.json`,
    userinfo_endpoint: `${config.REACT_APP_AUTH_PROVIDER_DOMAIN}/userinfo`,
    end_session_endpoint: `${
      config.REACT_APP_AUTH_PROVIDER_DOMAIN
    }/v2/logout/?${queryString.stringify(endSessionConfig)}`
  };

  extraQueryParams.audience = config.AUTH_AUDIENCE;
}

if (config.REACT_APP_AUTH_PROVIDER_ADDITIONAL_QUERY_PARAMS !== '') {
  const additionalQueryParams = queryString.parse(
    config.REACT_APP_AUTH_PROVIDER_ADDITIONAL_QUERY_PARAMS
  );

  const keys = Object.keys(additionalQueryParams);

  keys.forEach(key => {
    extraQueryParams[key] = additionalQueryParams[key] as string;
  });
}

const settings = {
  authority: config.REACT_APP_AUTH_PROVIDER_DOMAIN,
  client_id: config.REACT_APP_AUTH_PROVIDER_DEEP_ALPHA_CLIENT_ID,
  redirect_uri: `${
    window.location.origin
  }${routeTemplates.loginRedirect.build()}`,
  response_type: config.REACT_APP_AUTH_PROVIDER_RESPONSE_TYPE,
  scope: config.REACT_APP_AUTH_PROVIDER_SCOPES,
  silent_redirect_uri: `${
    window.location.origin
  }${routeTemplates.loginSilentRenew.build()}`,
  post_logout_redirect_uri: window.location.origin,
  extraQueryParams: extraQueryParams,
  metadata: metadata,
  includeIdTokenInSilentRenew: true
};

const userManager = new UserManager(settings);

const addUserLoginListener = callback => {
  userManager.events.addUserLoaded(callback);
};

const login = (redirectUrl?: string) => {
  const args: SigninRedirectArgs = {};
  if (redirectUrl) {
    args.redirect_uri =
      window.location.origin +
      routeTemplates.loginRedirect.build() +
      `?${queryString.stringify({
        redirectUrl
      })}`;
  }
  userManager.signinRedirect(args);
};

const loginCallback = () => userManager.signinRedirectCallback();

const getUser = async () => {
  return await userManager.getUser();
};

const logout = async () => {
  const user = await getUser();

  if (user) {
    await userManager.signoutRedirect({
      id_token_hint: user?.id_token,
      extraQueryParams: { ui_locales: getUserPageLanguage() || '' }
    });
  }
};

const loginSilent = () => userManager.signinSilent();

const loginSilentCallback = () => userManager.signinSilentCallback();

const auth = {
  login,
  loginCallback,
  logout,
  loginSilent,
  loginSilentCallback,
  getUser,
  addUserLoginListener
};

export default auth;
