import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useOrderSummaryStore, OrderSummaryData } from '../../../orderSummary';
import { usePageStore } from '../../services/pageStore';
import { getIsFormComplete } from '../../services/selectors';
import { mapProductAttributesToOrderSummary } from './mapping';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { usePortfolioChartStore } from 'features/roboAdvice/adviceSession/portfolioChart';
import { useSessionStore } from 'features/roboAdvice/adviceSession/session/services/sessionStore';
import { createOrderSummary } from 'features/roboAdvice/adviceSession/shared/api';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { useAdviceSessionStore } from 'features/roboAdvice/adviceSession/shared/services/adviceSessionStore';
import { useGoalsStore } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export const useReadOrderSummary = () => {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<CancelTokenSource>();
  const dispatch = useDispatch();
  const i18n = useI18n();
  const { customAttributesData } = useAdviceSessionStore();
  const sessionStoreState = useSessionStore.getState();

  const readOrderSummary = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;
    const { goals: storeGoals } = useGoalsStore.getState();
    const proposalPageStore = usePageStore.getState();
    const orderSummaryStore = useOrderSummaryStore.getState();
    const portfolioChartStore = usePortfolioChartStore.getState();
    const isFormComplete = getIsFormComplete();

    if (
      proposalPageStore.pageStatuses.readPortfolioChartData !==
      PageStatuses.succeed
    ) {
      return;
    }

    if (!isFormComplete) {
      orderSummaryStore.reset();
      proposalPageStore.setPageStatus('readOrderSummary', PageStatuses.failed);
      return;
    }

    try {
      proposalPageStore.setPageStatus('readOrderSummary', PageStatuses.pending);

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const isUpdated =
        !!proposalPageStore.goalsToUpdate.readOrderSummary.length;

      const goals = isUpdated
        ? proposalPageStore.goalsToUpdate.readOrderSummary
        : storeGoals;

      let orderSummaryData: OrderSummaryData[] = [];

      for (const goal of goals) {
        if (!goal.data.firstDeposit && !goal.data.monthlyDeposit) {
          continue;
        }

        const instruments = portfolioChartStore
          .getChartData(goal.goalId, goal.data.isPortfolioCustom)
          ?.categorySelectionPortfolio.map(({ id, weight }) => ({
            id,
            weight
          }));

        if (!isNil(instruments)) {
          const payload = {
            currency: 'NOK',
            purchase_portfolios: [
              {
                initial_deposit: goal.data.firstDeposit ?? 0,
                monthly_deposit: goal.data.monthlyDeposit ?? 0,
                instruments
              }
            ]
          };

          const { data: orderSummaryResponse } = await createOrderSummary(
            qAuthAccessToken,
            cancelTokenSource.token,
            payload,
            {
              language: getUserPageLanguage(),
              namespace_id: goal.data.productPlatformNamespace
            }
          );

          const data = {
            data: {
              monthlyOrders: orderSummaryResponse['monthly-orders'],
              oneTimeOrders: orderSummaryResponse['one-time-orders']
            },
            goalId: goal.goalId
          };

          const namespaceId =
            goal.data.productPlatformNamespace ||
            sessionStoreState.defaultConfigNamespaceId;
          const dataWithProductAttributesData =
            mapProductAttributesToOrderSummary(
              data,
              customAttributesData[namespaceId!]
            );

          orderSummaryData = [
            ...orderSummaryData,
            dataWithProductAttributesData
          ];
        }
      }

      isUpdated
        ? orderSummaryStore.editOrderSummaryData(orderSummaryData)
        : orderSummaryStore.addOrderSummaryData(orderSummaryData);

      if (isUpdated) {
        proposalPageStore.setGoalsToUpdate('readOrderSummary', []);
      }

      proposalPageStore.setPageStatus('readOrderSummary', PageStatuses.succeed);
    } catch (error) {
      if (!axios.isCancel(error)) {
        orderSummaryStore.reset();
        proposalPageStore.setPageStatus(
          'readOrderSummary',
          PageStatuses.failed
        );

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.proposal.readDataErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readOrderSummary;
};

export default useReadOrderSummary;
