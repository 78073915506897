import { Typography, FontWeights } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const usePageStyles = createUseStyles({
  header: {
    margin: '3rem 0',
    fontWeight: FontWeights.medium,
    fontSize: Typography.heading2.size,
    lineHeight: Typography.heading2.lineHeight
  },
  tabs: {
    marginBottom: '40px'
  },
  tab: {
    margin: '0 10px',
    padding: '8px 10px',
    fontSize: Typography.heading2.size,
    lineHeight: Typography.heading2.lineHeight
  }
});
