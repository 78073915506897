import classNames from 'classnames';
import { lensPath, view } from 'ramda';
import { useEffect, useState } from 'react';
import { FormSpy } from 'react-final-form';

import { form as roboAdviceForm } from '../../form/services/form';
import { useIsSessionReadOnly } from '../../main/services/selectors';
import { Page } from '../../shared/types';
import { ModalOptions } from '../types';
import AdaptedValueMessage from './adaptedValueMessage';
import AdaptedValueQuestionAlert from './adaptedValueQuestionAlert';
import { ReactComponent as InfoIcon } from 'assets/info.svg';
import { ReactComponent as LeafIcon } from 'assets/leaf.svg';
import { OffModelQuestionConfig } from 'defaults/shared';
import { Layouts } from 'features/roboAdvice/shared/constants';
import AdvisorNotes from 'features/shared/components/advisorNotes';
import ConfigurableLabel from 'features/shared/components/configurableLabel';
import { Spacing } from 'features/shared/constants/spacing';
import { FontWeights, Typography } from 'features/shared/constants/typography';
import { processImgURL } from 'features/shared/utils/processImgURL';
import { getTranslation } from 'features/shared/utils/translations';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import InputErrorMessage from 'features/sharedModules/errors/components/inputErrorMessage';
import { useErrorsStore } from 'features/sharedModules/errors/services/errorsStore';
import { ErrorMessage } from 'features/sharedModules/errors/types';
import FinalFormRadios from 'features/sharedModules/finalForm/components/radios';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const borderRadius = {
  [BorderRadiusTypes.rounded]: '25px',
  [BorderRadiusTypes.sharp]: '15px'
};

const useStyles = createUseStyles(theme => ({
  container: {
    '&:not(:first-child)': {
      marginTop: Spacing.spacing04
    },
    maxWidth: 1200
  },
  titleBar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: Spacing.spacing01
  },
  title: {
    margin: `0 ${Spacing.spacing01}px`,
    fontSize: Typography.heading2.size,
    fontWeight: FontWeights.medium
  },
  description: {
    color: theme.secondaryColor,
    marginBottom: Spacing.spacing02
  },
  error: {
    color: theme.errorNegativeColor
  },
  component: {
    marginBottom: 44,
    marginRight: 60
  },
  leafIcon: {
    fill: theme.accentColor
  },
  infoIcon: {
    fill: theme.secondaryColor,
    cursor: 'pointer',
    marginRight: Spacing.spacing01
  },
  customIcon: {
    width: 20,
    height: 20,

    '& img': {
      width: '100%',
      height: '100%'
    }
  },
  hasError: {
    border: `2px solid ${theme.errorNegativeColor}`,
    padding: `${Spacing.spacing01}px ${Spacing.spacing01}px ${Spacing.spacing00}px `,
    borderRadius: borderRadius[theme.borderRadiusType]
  },
  advisorNotes: {
    marginTop: Spacing.spacing01
  },
  valueAdaptedErrors: {
    marginBottom: Spacing.spacing01
  },
  content: {
    marginLeft: Spacing.spacing02
  },
  radiosItem: {
    '& + &': {
      marginTop: Spacing.spacing01
    }
  },
  radiosContainer: {
    margin: `${Spacing.spacing02}px 0`,
    display: 'block'
  }
}));

type Props = {
  name: string;
  header: string;
  description: string;
  config: OffModelQuestionConfig['config'];
  elementId: string;
  icon?: string | null;
  errors?: ErrorMessage[];
  advisorNotesErrors?: ErrorMessage[];
  valueAdaptedErrors?: ErrorMessage[];
  required: boolean;
  educationalModalConfig?: ModalOptions;
  setIsModalOpen?: (val: boolean) => void;
  setModalContent?: (pages: Page[]) => void;
  setModalHeader?: (header: string) => void;
  advisorNotes: {
    enabled: boolean;
    required: boolean;
  };
  advisorNotesName: string;
  isValueAdaptedRecorded?: boolean;
};

export const OffModelQuestionSection = ({
  name,
  header,
  description,
  config,
  elementId,
  icon,
  errors,
  advisorNotesErrors,
  valueAdaptedErrors,
  required,
  educationalModalConfig,
  setIsModalOpen,
  setModalContent,
  setModalHeader,
  advisorNotes,
  advisorNotesName,
  isValueAdaptedRecorded
}: Props) => {
  const classes = useStyles();
  const isErrorModeEnabled = useErrorsStore(state => state.isErrorModeEnabled);
  const isSessionReadOnly = useIsSessionReadOnly();
  const [oldValue, setOldValue] = useState<number | string | undefined>();

  useEffect(() => {
    const { values } = roboAdviceForm.getState();

    setOldValue(view(lensPath(name.split('.')), values));
  }, []);

  const fieldId = name.split('.').pop();

  const handleAfterChange = (value: string | number) => {
    if (oldValue !== undefined && value !== oldValue) {
      roboAdviceForm.change(
        `sustainability.offModelQuestionsValueAdapted.${fieldId}.isValueAdaptedQuestionVisible` as any,
        true
      );
    }

    setOldValue(value);
  };

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => (
        <div className={classes.container} id={elementId}>
          {errors?.map(error => (
            <InputErrorMessage message={error.alertMessage} />
          ))}
          <div className={classes.titleBar}>
            {icon ? (
              <div className={classes.customIcon}>
                <img src={processImgURL(icon)} alt={header} />
              </div>
            ) : (
              <LeafIcon className={classes.leafIcon} />
            )}
            <div className={classes.title}>
              <ConfigurableLabel required={required} label={header} />
            </div>
            {educationalModalConfig?.enabled &&
              educationalModalConfig.pages.length > 0 && (
                <InfoIcon
                  className={classes.infoIcon}
                  onClick={
                    setIsModalOpen && setModalContent && setModalHeader
                      ? () => {
                          setModalContent(educationalModalConfig.pages);
                          setIsModalOpen(true);
                          setModalHeader(header);
                        }
                      : () => {}
                  }
                />
              )}
            {isValueAdaptedRecorded &&
              values?.sustainability?.offModelQuestionsValueAdapted?.[fieldId!]
                ?.isValueAdaptedAnswer === true && (
                <AdaptedValueMessage
                  hasCloseButton
                  onClose={() => {
                    roboAdviceForm.batch(() => {
                      roboAdviceForm.change(
                        `sustainability.offModelQuestionsValueAdapted.${fieldId}.isValueAdaptedAnswer` as any,
                        false
                      );
                      roboAdviceForm.change(
                        `sustainability.offModelQuestionsValueAdapted.${fieldId}.isValueAdaptedQuestionVisible` as any,
                        false
                      );
                    });
                  }}
                />
              )}
          </div>

          <div className={classes.content}>
            <div className={classes.description}>{description}</div>

            <div
              className={classNames(classes.component, {
                [classes.hasError]: errors?.length && isErrorModeEnabled
              })}
            >
              {config.componentType === 'radioButtons' && (
                <FinalFormRadios
                  name={name}
                  items={config.items.map(item => ({
                    activeValue: item.activeValue,
                    label: getTranslation(item.label)
                  }))}
                  disabled={isSessionReadOnly}
                  layout={Layouts.vertical}
                  className={classes.radiosItem}
                  sectionClassName={classes.radiosContainer}
                  afterChange={val => handleAfterChange(val as string | number)}
                />
              )}
            </div>

            {valueAdaptedErrors && valueAdaptedErrors?.length > 0 && (
              <div className={classes.valueAdaptedErrors}>
                {valueAdaptedErrors?.map(error => (
                  <InputErrorMessage
                    message={error.alertMessage}
                    key={error.alertMessage}
                  />
                ))}
              </div>
            )}

            {isValueAdaptedRecorded &&
              values?.sustainability?.offModelQuestionsValueAdapted?.[fieldId!]
                ?.isValueAdaptedQuestionVisible && (
                <AdaptedValueQuestionAlert
                  id={`adaptedValueQuestionAlert-${fieldId}`}
                  hasError={
                    valueAdaptedErrors &&
                    valueAdaptedErrors?.length > 0 &&
                    isErrorModeEnabled
                  }
                  onClickNo={() => {
                    roboAdviceForm.batch(() => {
                      roboAdviceForm.change(
                        `sustainability.offModelQuestionsValueAdapted.${fieldId}.isValueAdaptedAnswer` as any,
                        false
                      );
                      roboAdviceForm.change(
                        `sustainability.offModelQuestionsValueAdapted.${fieldId}.isValueAdaptedQuestionVisible` as any,
                        false
                      );
                    });
                  }}
                  onClickYes={() => {
                    roboAdviceForm.batch(() => {
                      roboAdviceForm.change(
                        `sustainability.offModelQuestionsValueAdapted.${fieldId}.isValueAdaptedAnswer` as any,
                        true
                      );
                      roboAdviceForm.change(
                        `sustainability.offModelQuestionsValueAdapted.${fieldId}.isValueAdaptedQuestionVisible` as any,
                        false
                      );
                    });
                  }}
                />
              )}

            {advisorNotes.enabled && (
              <AdvisorNotes
                name={advisorNotesName}
                minRows={2}
                disabled={isSessionReadOnly}
                data-testid={`${advisorNotesName}-test`}
                required={advisorNotes.required}
                errors={advisorNotesErrors}
                className={classes.advisorNotes}
              />
            )}
          </div>
        </div>
      )}
    </FormSpy>
  );
};
