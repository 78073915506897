import create from 'zustand';

type SustainabilityResponseData = {
  investorSustainabilityScore: number;
  fundAnalysis: FundAnalysis[];
  portfolioAnalysis: PortfolioAnalysis;
};

export type preferenceCriteriaKey = `preferenceCriteria${number}`;
export type alignmentCriteriaKey = `alignmentCriteria${1 | 2 | 3 | 4 | 5}`;

export type FundAnalysis = {
  name: string;
  fundStandardName: string;
  isin: string;
  ticker: string;
  alignmentCriteria: Record<alignmentCriteriaKey, number | null>;
  alignmentCriteriaStatus: Record<alignmentCriteriaKey, boolean | null>;
  preferenceValues: Record<preferenceCriteriaKey, boolean | null>;
  preferenceStatus: Record<preferenceCriteriaKey, boolean | null>;
  fundWeightedSustainabilityScore: number;
  fundAlignmentStatus: boolean;
};

type PortfolioAnalysis = {
  alignmentValuesWeighted: Record<alignmentCriteriaKey, number | null>;
  alignmentStatus: Record<alignmentCriteriaKey, boolean | null>;
  portfolioWeightedSustainabilityScore: number;
  portfolioWeightedStatus: boolean;
};

export type SustainabilityData = {
  goalId: string;
  data: SustainabilityResponseData;
};

type SustainabilityStoreData = {
  sustainabilityData: SustainabilityData[];
};

type SustainabilityStoreApi = {
  reset: () => void;
  initialize: (initialData: Partial<SustainabilityStoreData>) => void;
  addSustainabilityData: (data: SustainabilityData[]) => void;
};

type SustainabilityStoreState = SustainabilityStoreData &
  SustainabilityStoreApi;

const defaultData = {
  sustainabilityData: []
};

export const useSustainabilityStore = create<SustainabilityStoreState>(set => ({
  ...defaultData,
  reset: () => {
    set(defaultData);
  },
  initialize(initialData) {
    set(
      state => ({
        ...state,
        ...initialData
      }),
      true
    );
  },
  addSustainabilityData: data => {
    set(state => ({
      ...state,
      sustainabilityData: [...state.sustainabilityData, ...data]
    }));
  }
}));
