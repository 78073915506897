import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import { Colors } from 'modules/shared/constants/colors.js';

const useStyles = createUseStyles({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  header: {},
  headerRow: {},
  headerCell: {
    padding: '11px 13px',
    color: Colors.gray600,
    backgroundColor: Colors.gray700,
  },
  body: {},
  bodyRow: {
    '&:nth-of-type(even) $bodyCell': {
      backgroundColor: Colors.gray700,
    },
    '&:nth-of-type(odd) $bodyCell': {
      backgroundColor: Colors.gray800,
    },
  },
  bodyCell: {
    padding: '11px 13px',
    verticalAlign: 'top',
  },
});

export const Table = ({ className, children }) => {
  const classes = useStyles();

  return (
    <table className={classNames(classes.table, className)}>{children}</table>
  );
};

export const TableHader = ({ className, children }) => {
  const classes = useStyles();

  return (
    <thead className={classNames(classes.header, className)}>{children}</thead>
  );
};

export const TableHaderRow = ({ className, children }) => {
  const classes = useStyles();

  return (
    <tr className={classNames(classes.headerRow, className)}>{children}</tr>
  );
};

export const TableHeaderCell = ({ className, children, ...restProps }) => {
  const classes = useStyles();

  return (
    <td className={classNames(classes.headerCell, className)} {...restProps}>
      {children}
    </td>
  );
};

export const TableBody = ({ className, children }) => {
  const classes = useStyles();

  return (
    <tbody className={classNames(classes.body, className)}>{children}</tbody>
  );
};

export const TableBodyRow = ({ className, children }) => {
  const classes = useStyles();

  return <tr className={classNames(classes.bodyRow, className)}>{children}</tr>;
};

export const TableBodyCell = ({ className, children }) => {
  const classes = useStyles();

  return (
    <td className={classNames(classes.bodyCell, className)}>{children}</td>
  );
};
