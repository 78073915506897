import { connect } from 'react-redux';

import NotificationComponent from './index.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import notificationSelectors from 'features/shared/services/notification/selectors.js';

export default connect(
  state => {
    const notifications = notificationSelectors.getNotifications(state);

    return {
      notifications
    };
  },
  dispatch => {
    return {
      onHideNotification: id =>
        dispatch(notificationActionCreators.hideNotification(id))
    };
  }
)(NotificationComponent);
