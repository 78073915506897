import { ReactNode, useState } from 'react';

import Dropdown from '../../../../shared/components/dropdown';
import { useCustomerConfig } from '../../../../sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from '../../../../sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from '../../../../sharedModules/styles/components/styles';
import { useSaveAdviceSession } from './useSaveAdviceSession';
import PreMeetingSurveyModal from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/preMeetingSurveyModal';
import Button, { ButtonType } from 'features/shared/components/button';

const useStyles = createUseStyles(theme => ({
  menuOffset: {
    marginTop: '0',
    paddingTop: '0',
    paddingBottom: '0',
    borderRadius: '0'
  }
}));

const AdviceSessionTypeSelectDropdown = ({ options }) => {
  const i18n = useI18n();
  const classes = useStyles();
  const saveAdviceSession = useSaveAdviceSession();
  const {
    roboOrderFlow,
    adviceFlows,
    tenantSettings: { advisoryFlow, preMeetingSurveyEnabled }
  } = useCustomerConfig();
  const [isPreSurveyModalOpen, setIsPreSurveyModalOpen] = useState(false);
  const [selectedAdviceType, setSelectedAdviceType] = useState<string>('');

  const handleCreateNewAdviceSession = (adviceType: string) => {
    if (preMeetingSurveyEnabled) {
      setSelectedAdviceType(adviceType);
      setIsPreSurveyModalOpen(true);
    } else {
      saveAdviceSession(null, adviceType);
    }
  };

  return (
    <>
      {preMeetingSurveyEnabled && (
        <PreMeetingSurveyModal
          isOpen={isPreSurveyModalOpen}
          setIsOpen={setIsPreSurveyModalOpen}
          selectedAdviceType={selectedAdviceType}
        />
      )}

      {roboOrderFlow.enabled && advisoryFlow.enabled ? (
        <Dropdown
          input={props =>
            (
              <div ref={props.forwardedRef}>
                <Button {...props} buttonType={ButtonType.tertiary}>
                  + {i18n('roboAdvice.client.addNewAdvice')}
                </Button>
              </div>
            ) as ReactNode
          }
          options={options}
          menuProps={{
            offsetBottomClassName: classes.menuOffset
          }}
          onChange={item => handleCreateNewAdviceSession(item)}
          value={null}
        />
      ) : (
        <>
          {advisoryFlow.enabled && (
            <Button
              onClick={() => handleCreateNewAdviceSession(adviceFlows[0].name)}
              buttonType={ButtonType.tertiary}
            >
              + {i18n('roboAdvice.client.addNewAdvice')}
            </Button>
          )}
          {roboOrderFlow.enabled && (
            <Button
              onClick={() => handleCreateNewAdviceSession(adviceFlows[1].name)}
              buttonType={ButtonType.tertiary}
            >
              + {i18n('roboAdvice.client.addNewAdvice')}
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default AdviceSessionTypeSelectDropdown;
