import bike from '../shared/components/icons/bike.svg';
import paragliding from '../shared/components/icons/paragliding.svg';
import sun_lounger from '../shared/components/icons/sun_lounger.svg';
import tightrope_walker from '../shared/components/icons/tightrope_walker.svg';
import zipline from '../shared/components/icons/zipline.svg';

export const TimeHorizonTypes = {
  radio: 'radio',
  slider: 'slider'
} as const;

export const GoalModalModeTypes = {
  new: 'new',
  edit: 'edit'
} as const;

export const Icons = {
  bike,
  zipline,
  paragliding,
  sun_lounger,
  tightrope_walker
} as const;

export const SliderConfig = {
  max: 35,
  min: 1,
  step: 1
} as const;

export const MaxWithdrawalYear = {
  start: SliderConfig.max,
  end: 80
} as const;
