import create from 'zustand';

export type BuyOrder = {
  FundStandardName: string;
  currency: string;
  id: string;
  isin: string;
  moneyAmount: number;
  name: string;
  type: string;
  weight: number;
};

export type OrderSummaryData = {
  goalId: string;
  data: {
    monthlyOrders: {
      buy: BuyOrder[];
    };
    oneTimeOrders: {
      buy: BuyOrder[];
    };
  };
};

type OrderSummaryStoreData = {
  orderSummaryData: OrderSummaryData[];
};

type OrderSummaryStoreApi = {
  reset: () => void;
  initialize: (initialData: Partial<OrderSummaryStoreData>) => void;
  addOrderSummaryData: (data: OrderSummaryData[]) => void;
  editOrderSummaryData: (data: OrderSummaryData[]) => void;
};

type OrderSummaryStoreState = OrderSummaryStoreData & OrderSummaryStoreApi;

const defaultData = {
  orderSummaryData: []
};

export const useOrderSummaryStore = create<OrderSummaryStoreState>(set => ({
  ...defaultData,
  reset: () => {
    set(defaultData);
  },
  initialize(initialData) {
    set(
      state => ({
        ...state,
        ...initialData
      }),
      true
    );
  },
  addOrderSummaryData: data => {
    set(state => ({
      ...state,
      orderSummaryData: [...state.orderSummaryData, ...data]
    }));
  },
  editOrderSummaryData: data => {
    set(state => {
      return {
        ...state,
        orderSummaryData: state.orderSummaryData.map(singleOrderSummaryData => {
          const updatedGoal = data.find(
            ({ goalId }) => goalId === singleOrderSummaryData.goalId
          );
          return updatedGoal ? updatedGoal : singleOrderSummaryData;
        })
      };
    });
  }
}));
