import { getVatValidationForCountry } from 'features/roboAdvice/client/shared/utils';
import {
  CountryCode,
  SupportedENINCountryCodes
} from 'features/roboAdvice/shared/constants';
import { SupportedCountryCode as SupportedCountryCodeType } from 'features/roboAdvice/shared/types';

export const isDateToday = (date: Date) => {
  const today = new Date();

  return (
    today.getFullYear() === date.getFullYear() &&
    today.getMonth() === date.getMonth() &&
    today.getDate() === date.getDate()
  );
};

export const isDateNotGreaterThanThreeYearsAgo = (date: Date) => {
  const now = new Date();
  const threeYearsAgo = new Date(now.setFullYear(now.getFullYear() - 3));

  return date > threeYearsAgo;
};

export const isVatNumberValid = (
  vatNumber: string | null,
  currentCountryCode: SupportedCountryCodeType
) => {
  const isClientCountrySupported = (
    SupportedENINCountryCodes as readonly string[]
  ).includes(currentCountryCode);

  if (isClientCountrySupported) {
    const isVatValid = getVatValidationForCountry(currentCountryCode);

    return vatNumber ? isVatValid(vatNumber) : false;
  }

  return false;
};

export const getFullCompanyOrgNumber = (
  vatNumber: string | null,
  currentCountryCode: SupportedCountryCodeType
) => {
  switch (currentCountryCode) {
    case CountryCode.Norway:
      return CountryCode.Norway.toUpperCase() + vatNumber || '';

    case CountryCode.Denmark:
      return CountryCode.Denmark.toUpperCase() + vatNumber || '';

    case CountryCode.Sweden:
      const formattedSwedenVat =
        vatNumber && vatNumber.length === 10
          ? vatNumber.replace(/^(\d{6})(\d{4})$/, '$1-$2')
          : '';
      return CountryCode.Sweden.toUpperCase() + formattedSwedenVat;

    case CountryCode.Finland:
      const formattedFinlandVat =
        vatNumber && vatNumber.length === 8
          ? vatNumber.replace(/^(\d{7})(\d{1})$/, '$1-$2')
          : '';
      return CountryCode.Finland.toUpperCase() + formattedFinlandVat;

    default:
      return '';
  }
};
