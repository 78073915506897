import { set, lensPath } from 'ramda';
import create from 'zustand';

import { Status } from '../../../shared/types';

export type PageStatuses = {
  readSettings?: Status;
  updateSettings?: Status;
};

export type ProposalStoreData = {
  pageStatuses: PageStatuses;
};

export type ProposalStoreApi = {
  reset: () => void;
  setPageStatus: <K extends keyof PageStatuses>(
    key: K,
    value: PageStatuses[K]
  ) => void;
};

export type ProposalStoreState = ProposalStoreData & ProposalStoreApi;

export const defaultData = {
  pageStatuses: {}
};

export const useProposalStore = create<ProposalStoreState>(setState => ({
  ...defaultData,
  reset() {
    setState(defaultData);
  },
  setPageStatus(key, value) {
    setState(set(lensPath(['pageStatuses', key]), value), true);
  }
}));
