import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { TimeHorizonTypes } from '../../purposeAndRisk/constants';
import {
  WithdrawalPlans,
  WithdrawalPlansClassToTitle
} from '../../shared/constants';
import { useGoalsStore } from '../../shared/services/goalsStore';
import TableComponent from 'features/shared/components/table/table.js';
import TableBody from 'features/shared/components/table/tableBody.js';
import TableBodyRow from 'features/shared/components/table/tableBodyRow.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { formatNumber } from 'features/shared/utils/number';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  withdrawal: {
    color: theme.errorNegativeColor,
    textAlign: 'right'
  },
  table: {
    width: 'auto'
  }
}));

const CashflowTable = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const { goals } = useGoalsStore();
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const {
    timeHorizonConfig: { type: timeHorizonType }
  } = useCustomerConfig();

  const headers = [
    { title: i18n('roboAdvice.purposeAndRisk.purposeTable.goal') },
    { title: i18n('roboAdvice.proposal.cashflow.withdrawal') },
    { title: i18n('roboAdvice.proposal.cashflow.frequency') },
    { title: i18n('roboAdvice.proposal.year') }
  ];

  const cashflowGoals = React.useMemo(
    () =>
      goals.map(({ goalId, name, data }) => ({
        goalId,
        name,
        frequency: i18n(WithdrawalPlansClassToTitle[data.withdrawalPlan]),
        withdrawal: getCashflowWithdrawal(data, cultureCode),
        year: getCashflowYear(data, timeHorizonType)
      })),
    [cultureCode, goals, i18n, timeHorizonType]
  );

  return (
    <TableComponent
      header={headers}
      externalClasses={{ headerRowCell: classes.cell, table: classes.table }}
    >
      <TableBody>
        {cashflowGoals.map(({ goalId, name, withdrawal, frequency, year }) => (
          <TableBodyRow key={goalId}>
            {({ bodyRowCellClassName }) => (
              <>
                <td className={bodyRowCellClassName}>{name}</td>
                <td
                  className={classNames(
                    bodyRowCellClassName,
                    classes.withdrawal
                  )}
                >
                  {withdrawal}
                </td>
                <td className={bodyRowCellClassName}>{frequency}</td>
                <td className={bodyRowCellClassName}>{year}</td>
              </>
            )}
          </TableBodyRow>
        ))}
      </TableBody>
    </TableComponent>
  );
};

export const getCashflowWithdrawal = (data, cultureCode) => {
  if (data.withdrawalPlan === WithdrawalPlans.monthlyWithdrawal) {
    return formatNumber(cultureCode, data.monthlyWithdrawal, 0, 0);
  } else if (data.withdrawalPlan === WithdrawalPlans.oneTimeWithdrawal) {
    return formatNumber(cultureCode, data.capitalNeed, 0, 0);
  }

  return '-';
};

export const getCashflowYear = (data, timeHorizonType) => {
  const currentYear = new Date().getFullYear();

  if (data.withdrawalPlan === WithdrawalPlans.monthlyWithdrawal) {
    return `${data.withdrawalStart}-${data.withdrawalEnd}`;
  } else if (data.withdrawalPlan === WithdrawalPlans.oneTimeWithdrawal) {
    return timeHorizonType === TimeHorizonTypes.slider
      ? currentYear + data.timeHorizon
      : currentYear + data.timeHorizonToDisplay;
  }

  return '-';
};

export default CashflowTable;
