import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FundNameForPresentation } from '../../../shared/constants';
import {
  getSubAssetClassTranslation,
  groupAllAssetClasses
} from '../../../shared/mapping';
import { useCustomPortfolioStore } from '../../services/customPortfolioStore';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import {
  getCategoriesAll,
  getFundsList
} from 'features/roboAdvice/adviceSession/shared/api';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { Goal } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export const useReadAssetClassInstruments = () => {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const dispatch = useDispatch();
  const {
    tenantSettings: { fundNameForPresentation },
    roboAdvice: { subAssetClassNameMapping = {} }
  } = useCustomerConfig();

  const readAssetClassInstruments = async (selectedGoal: Goal) => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;
    try {
      useCustomPortfolioStore
        .getState()
        .setAssetClassInstrumentsStatus(PageStatuses.pending);
      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      type Instrument = {
        FundStandardName: string;
        ISIN: string;
        Name: string;
        SubAssetClasses: Record<string, string>;
        Ticker: string;
      };
      const fundsListResponse: AxiosResponse<{ funds: Instrument[] }> =
        await getFundsList(qAuthAccessToken, cancelTokenSource.token, {
          namespace_id: selectedGoal?.data.productPlatformNamespace,
          language: getUserPageLanguage()
        });
      const categoriesResponse = await getCategoriesAll(
        qAuthAccessToken,
        cancelTokenSource.token,
        {
          namespace_id: selectedGoal?.data.productPlatformNamespace
        }
      );
      const allCategories = categoriesResponse.data.Categories;

      const groupedAllAssetClasses = groupAllAssetClasses(allCategories);

      const assetClasses = groupedAllAssetClasses.map(c => {
        const availableInstruments = fundsListResponse.data.funds
          .filter(fund =>
            c.CategoryIds.some(categoryId =>
              Object.keys(fund.SubAssetClasses).includes(categoryId.id)
            )
          )
          .map(f => ({
            id: f.Ticker,
            isin: f.ISIN,
            title:
              fundNameForPresentation ===
                FundNameForPresentation.fundStandardName && f.FundStandardName
                ? f.FundStandardName
                : f.Name
          }));

        return {
          id: c.AssetClass.SubAssetClass,
          title: c.AssetClass.SubAssetClass,
          mainAssetClass: c.AssetClass.MainAssetClass,
          subAssetClass:
            getSubAssetClassTranslation(
              c.CategoryIds[0]?.id,
              subAssetClassNameMapping
            ) || c.AssetClass.SubAssetClass,
          instruments: [],
          categoryId: c.CategoryIds[0]?.id,
          categoryIds: c.CategoryIds.map(({ id }) => id),
          availableInstruments: availableInstruments
        };
      });

      useCustomPortfolioStore.getState().setAssetClasses(assetClasses);
      useCustomPortfolioStore
        .getState()
        .setAssetClassInstrumentsStatus(PageStatuses.succeed);

      return assetClasses;
    } catch (error) {
      if (!axios.isCancel(error)) {
        useCustomPortfolioStore
          .getState()
          .setAssetClassInstrumentsStatus(PageStatuses.failed);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.advisory.customPortfolio.readAssetClassInstrumentsError'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readAssetClassInstruments;
};
