import classNames from 'classnames';
import * as R from 'ramda';
import React from 'react';

import { getErrorText } from './shared.js';
import { useField } from './useField';
import ErrorMessage from 'features/shared/components/errorMessage/index.js';
import BaseSwitch from 'features/shared/components/switch/index.js';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const Layouts = {
  horizontal: 'horizontal',
  vertical: 'vertical',
  horizontalReversed: 'horizontalReversed'
};

const useStyles = createUseStyles(theme => ({
  section: {
    '& + &': {
      marginTop: '3rem'
    }
  },
  sectionNoMargin: {
    whiteSpace: 'nowrap'
  },
  verticalSection: {},
  horizontalSection: {
    display: 'inline-flex',
    alignItems: 'baseline'
  },
  label: {
    color: theme.secondaryColor
  },
  verticalLabel: {
    display: 'inline-block',
    marginBottom: '10px'
  },
  horizontalLabel: {
    marginRight: '2.4rem',
    flex: '0 0 auto',
    width: '300px'
  },
  horizontalReversedLabel: {
    order: 2,
    marginLeft: '20px',
    flex: '0 0 auto',
    width: '15rem',
    color: theme.primaryColor
  },
  container: {
    display: 'flex'
  }
}));

export const Switch = ({
  value,
  onChange,
  error,
  touched,
  afterChange,
  sectionClassName: sectionClassNameProp,
  labelClassName: labelClassNameProp,
  label,
  layout,
  flippedValues,
  hasError,
  ...restProps
}) => {
  const classes = useStyles();
  const i18n = useI18n();

  const isErrorShown = R.isNil(hasError)
    ? touched && !R.isNil(error)
    : hasError;
  const errorText = getErrorText(i18n, error);
  const onChangeInternal = valueInternal => {
    onChange(valueInternal);
    !R.isNil(afterChange) && afterChange(valueInternal);
  };

  let sectionClassName = null;
  let labelClassName = null;
  if (layout === Layouts.vertical) {
    sectionClassName = classNames(
      classes.section,
      classes.verticalSection,
      sectionClassNameProp
    );
    labelClassName = classNames(
      classes.label,
      classes.verticalLabel,
      labelClassNameProp
    );
  } else if (layout === Layouts.horizontal) {
    sectionClassName = classNames(
      classes.section,
      classes.horizontalSection,
      sectionClassNameProp
    );
    labelClassName = classNames(
      classes.label,
      classes.horizontalLabel,
      labelClassNameProp
    );
  } else if (layout === Layouts.horizontalReversed) {
    sectionClassName = classNames(
      classes.sectionNoMargin,
      classes.horizontalSection,
      sectionClassNameProp
    );
    labelClassName = classNames(
      classes.label,
      classes.horizontalReversedLabel,
      labelClassNameProp
    );
  }

  return (
    <div className={sectionClassName}>
      {!R.isNil(label) && <span className={labelClassName}>{label}</span>}
      <div>
        <div className={classes.container}>
          <div>
            <BaseSwitch
              checked={
                !R.isNil(value) ? (flippedValues ? !value : value) : false
              }
              onChange={event => {
                onChangeInternal(
                  flippedValues ? !event.target.checked : event.target.checked
                );
              }}
              data-testid={restProps['data-testid']}
              {...restProps}
            />
            {isErrorShown && R.isNil(hasError) && (
              <ErrorMessage>* {errorText}</ErrorMessage>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Switch.defaultProps = {
  layout: Layouts.horizontal,
  flippedValues: false
};

const FinalFormSwitch = ({ name, fieldConfig = {}, ...restProps }) => {
  const {
    input: { value, onChange },
    meta: { error, touched }
  } = useField(name, fieldConfig);

  return (
    <Switch
      value={value}
      onChange={onChange}
      error={error}
      touched={touched}
      {...restProps}
    />
  );
};

export default FinalFormSwitch;
