import classNames from 'classnames';
import { isNil } from 'ramda';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import GranularInformationModal, {
  HoldingsSource,
  Item
} from '../granularInformationModal';
import AmountForAdviceTableLastRow from './lastRow';
import { ReactComponent as AddListIcon } from 'assets/addList.svg';
import { ReactComponent as EditListIcon } from 'assets/editList.svg';
import {
  useAmountForAdviceValues,
  useFinancialSituationValues
} from 'features/roboAdvice/adviceSession/financialSituation/services/selectors';
import { useIsSessionReadOnly } from 'features/roboAdvice/adviceSession/main/services/selectors';
import SimpleTable from 'features/shared/components/table/simpleTable';
import {
  AdviceSessionParams,
  ClientTypes
} from 'features/shared/constants/session';
import { FontWeights } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { filterNil } from 'features/shared/utils/filters';
import {
  formatNumber,
  getNumberInputFormat
} from 'features/shared/utils/number';
import { getTranslation } from 'features/shared/utils/translations';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import InputErrorMessage from 'features/sharedModules/errors/components/inputErrorMessage';
import FinalFormNumberInput from 'features/sharedModules/finalForm/components/numberInput';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  headerCell: {
    color: theme.secondaryColor,
    padding: '17.5px 16px',
    fontWeight: FontWeights.normal,
    lineHeight: '150%'
  },
  right: {
    textAlign: 'right'
  },
  cell: {
    padding: '17.5px 16px',
    lineHeight: '150%'
  },
  originalAmount: {
    color: theme.disabledTextColor,
    textAlign: 'right',
    textDecoration: 'line-through'
  },
  toAdvisoryRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 18
  },
  toAdvisoryInput: {
    width: 143,
    textAlign: 'right'
  },
  toAdvisoryCell: {
    paddingRight: 'calc(38px + 2.4rem)'
  },
  listIcon: {
    fill: theme.accentColor,
    cursor: 'pointer'
  },
  errorsContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const AmountForAdviceTable = ({ errors }) => {
  const { clientType } = useParams<AdviceSessionParams>();
  const classes = useStyles();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const financialSituationValues = useFinancialSituationValues();
  const isSessionReadOnly = useIsSessionReadOnly();
  const i18n = useI18n();
  const { decimalSeparator, thousandSeparator } =
    getNumberInputFormat(cultureCode);
  const { data, currentSum, originalSum } = useAmountForAdviceValues();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);

  const getSumOfDetails = (item: Item) => {
    const filteredValues = financialSituationValues?.[item.id]?.filter(
      ({ accountNumber }) =>
        item.source === HoldingsSource.internal
          ? !isNil(accountNumber)
          : isNil(accountNumber)
    );
    return financialSituationValues.amountForAdviceSwitch
      ? (filteredValues?.reduce(
          (acc, curr) => acc + ((curr.toAdvisory / 100) * curr.value || 0),
          0
        ) /
          data.find(
            ({ id: dataId, source: dataSource }) =>
              dataId === item.id && dataSource === item.source
          )?.amount) *
          100
      : filteredValues?.reduce((acc, curr) => acc + (curr.toAdvisory || 0), 0);
  };

  const getRowAmount = (item: Item) => {
    return financialSituationValues?.[item.id]?.length > 1
      ? (getSumOfDetails(item) / 100) * item.amount
      : ((financialSituationValues[item.id].toAdvisory || 0) * item.amount) /
          100;
  };

  const getOriginalAmount = (item: Item) => {
    if (financialSituationValues?.[item.id]?.length > 1) {
      if (financialSituationValues.amountForAdviceSwitch) {
        return getSumOfDetails(item) === 100
          ? null
          : formatNumber(cultureCode, item.amount, 0, 0);
      } else {
        return getSumOfDetails(item) === item.amount
          ? null
          : formatNumber(cultureCode, item.amount, 0, 0);
      }
    } else {
      if (financialSituationValues.amountForAdviceSwitch) {
        return financialSituationValues[item.id].toAdvisory === 100
          ? null
          : formatNumber(cultureCode, item.amount, 0, 0);
      } else {
        return financialSituationValues[item.id].toAdvisory === item.amount
          ? null
          : formatNumber(cultureCode, item.amount, 0, 0);
      }
    }
  };

  return (
    <>
      <div className={classes.errorsContainer}>
        {errors.map(error => (
          <InputErrorMessage
            message={error.alertMessage}
            key={error.alertMessage}
          />
        ))}
      </div>
      <SimpleTable
        headers={() => (
          <>
            <th className={classes.headerCell}>
              {i18n('roboAdvice.financialSituation.amountForAdvice.type')}
            </th>
            <th className={classes.headerCell}>
              {i18n('roboAdvice.financialSituation.amountForAdvice.source')}
            </th>
            <th
              className={classNames(classes.headerCell, classes.originalAmount)}
            ></th>
            {financialSituationValues.amountForAdviceSwitch && (
              <th className={classNames(classes.headerCell, classes.right)}>
                {i18n('roboAdvice.financialSituation.amountForAdvice.amount')}
              </th>
            )}
            <th
              className={classNames(
                classes.headerCell,
                classes.right,
                classes.toAdvisoryCell
              )}
            >
              {i18n('roboAdvice.financialSituation.amountForAdvice.toAdvisory')}
            </th>
          </>
        )}
        body={({ rowClassName }) => (
          <>
            {data.map(item => (
              <tr key={`${item.id}-${item.source}`} className={rowClassName}>
                <td className={classes.cell}>{getTranslation(item.label)}</td>

                <td className={classes.cell}>
                  {item.source === HoldingsSource.internal
                    ? i18n(
                        'roboAdvice.financialSituation.amountForAdviceTable.internalHoldings'
                      )
                    : i18n(
                        'roboAdvice.financialSituation.amountForAdviceTable.externalHoldings'
                      )}
                </td>

                <td
                  className={classNames(classes.cell, classes.originalAmount)}
                >
                  {getOriginalAmount(item)}
                </td>

                {financialSituationValues.amountForAdviceSwitch && (
                  <td className={classNames(classes.cell, classes.right)}>
                    {formatNumber(cultureCode, getRowAmount(item), 0, 0)}
                  </td>
                )}

                <td className={classNames(classes.cell, classes.right)}>
                  <div className={classes.toAdvisoryRow}>
                    <FinalFormNumberInput
                      allowNegative={false}
                      className={classes.toAdvisoryInput}
                      decimalScale={0}
                      decimalSeparator={decimalSeparator}
                      disabled={isSessionReadOnly}
                      fixedValue={
                        financialSituationValues?.[item.id]?.filter(field =>
                          filterNil(field?.value)
                        ).length > 1 ||
                        !isNil(
                          financialSituationValues?.[item.id]?.[0]
                            ?.accountNumber
                        )
                          ? getSumOfDetails(item)
                          : null
                      }
                      isAllowed={({ floatValue }) => {
                        return financialSituationValues.amountForAdviceSwitch
                          ? (floatValue || 0) <= 100
                          : (floatValue || 0) <= item.amount;
                      }}
                      name={
                        clientType === ClientTypes.person
                          ? `personFinancialSituation.${item.id}.0.toAdvisory`
                          : `companyFinancialSituation.${item.id}.0.toAdvisory`
                      }
                      suffix={
                        financialSituationValues.amountForAdviceSwitch
                          ? '%'
                          : undefined
                      }
                      thousandSeparator={thousandSeparator}
                    />

                    {financialSituationValues?.[item.id]?.some(
                      ({ assetClass }) => assetClass
                    ) ? (
                      <EditListIcon
                        className={classes.listIcon}
                        onClick={() => {
                          setSelectedItem(item);
                          setIsModalOpen(true);
                        }}
                      />
                    ) : (
                      <AddListIcon
                        className={classes.listIcon}
                        onClick={() => {
                          setSelectedItem(item);
                          setIsModalOpen(true);
                        }}
                      />
                    )}
                  </div>
                </td>
              </tr>
            ))}

            <AmountForAdviceTableLastRow
              cultureCode={cultureCode}
              currentSum={currentSum}
              originalSum={originalSum}
              financialSituationValues={financialSituationValues}
            />
          </>
        )}
        hasError={!!errors?.length}
      />

      {selectedItem && (
        <GranularInformationModal
          clientType={clientType}
          cultureCode={cultureCode}
          decimalSeparator={decimalSeparator}
          financialSituation={financialSituationValues}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          selectedItem={selectedItem}
          thousandSeparator={thousandSeparator}
        />
      )}
    </>
  );
};

export default AmountForAdviceTable;
