import { useEffect } from 'react';

export const PageStatuses = {
  ready: 'ready',
  pending: 'pending',
  succeed: 'succeed',
  failed: 'failed'
} as const;

export type PageStatus = typeof PageStatuses[keyof typeof PageStatuses];

export function useReadDataListener(
  statusValue: PageStatus | undefined,
  isActive: boolean,
  readData: () => Promise<void>
) {
  useEffect(() => {
    if (isActive && statusValue === PageStatuses.ready) {
      readData();
    }
  }, [isActive, statusValue]);
}
