import create from 'zustand';
import * as R from 'ramda';

export const createUseExpectedValuePortfoliosChartStore = () => {
  const defaultData = {
    chartData: []
  };

  return create(set => ({
    ...defaultData,
    reset: () => {
      set(defaultData);
    },
    initialize(initialData) {
      set(
        state => ({
          ...state,
          ...initialData
        }),
        true
      );
    },
    setChartData(portfolios) {
      const chartData = portfolios.map(
        ({
          forecast,
          currentDate,
          timeHorizon,
          firstDeposit,
          goalId,
          goalName,
          color
        }) => ({
          portfolio: mapServerDataToChartData(
            timeHorizon,
            currentDate,
            firstDeposit,
            forecast
          ),
          goalId,
          goalName,
          color
        })
      );

      set({ chartData });
    }
  }));
};

const mapServerDataToChartData = (
  timeHorizon,
  currentDate,
  firstDeposit,
  forecast
) => {
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const filteredData = filterExpectedValueServerData(
    timeHorizon,
    {
      currentYear,
      currentMonth
    },
    firstDeposit,
    forecast
  );

  return filteredData.map(i => ({
    x: Date.UTC(i.year, i.month),
    y: i.value ?? 0,
    custom: { accReturn: i.acc_return }
  }));
};

const filterExpectedValueServerData = (
  yearOffset,
  currentDate,
  firstDeposit,
  forecast
) => {
  const { currentMonth, currentYear } = currentDate;
  const finalYear = currentYear + yearOffset;

  return R.pipe(
    R.map(i => {
      const { date: dateStr, ...restProps } = i;
      const date = new Date(dateStr);

      return {
        ...restProps,
        year: date.getFullYear(),
        month: date.getMonth()
      };
    }),
    R.filter(i => i.year > currentYear),
    R.append({
      year: currentYear,
      month: currentMonth,
      acc_deposit: firstDeposit,
      acc_return: 0,
      acc_return_bank: 0,
      value: firstDeposit,
      value_bank: firstDeposit
    }),
    R.groupBy(R.prop('year')),
    R.toPairs,
    R.filter(g => g[0] <= finalYear),
    R.sort(R.ascend(R.prop(0))),
    R.map(g => {
      const items = g[1];
      const currentMonthItem = R.find(i => i.month === currentMonth, items);

      return currentMonthItem;
    }),
    R.reject(R.isNil)
  )(forecast);
};
