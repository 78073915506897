import create from 'zustand';

import {
  ConfirmationStoreData,
  ConfirmationStoreState,
  UseConfirmationStore
} from '../types';

export const createUseConfirmationStore = (
  propsInitialData?: Partial<ConfirmationStoreData>
): UseConfirmationStore => {
  const defaultData = {
    isOpen: false,
    message: '',
    onDecline: undefined,
    onConfirm: undefined,
    header: undefined,
    confirmButtonText: undefined,
    declineButtonText: undefined,
    ...propsInitialData
  };

  return create<ConfirmationStoreState>((set, get) => ({
    ...defaultData,
    reset() {
      set(defaultData);
    },
    open(
      message,
      onConfirm,
      onDecline,
      header,
      confirmButtonText,
      declineButtonText
    ) {
      set({
        isOpen: true,
        message,
        onConfirm,
        onDecline,
        header,
        confirmButtonText,
        declineButtonText
      });
    },
    decline() {
      const { onDecline } = get();

      if (onDecline != null) {
        onDecline();
      }

      set(defaultData);
    },
    confirm() {
      const { onConfirm } = get();

      if (onConfirm != null) {
        onConfirm();
      }

      set(defaultData);
    }
  }));
};
