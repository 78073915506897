import { useEffect } from 'react';

import { ErrorMessage } from '../../../../sharedModules/errors/types';
import { RoboAdviceAdviceSessionPages } from '../../../shared/constants';
import { useIsSessionReadOnly } from '../../main/services/selectors';
import { useSessionStore } from '../../session/services/sessionStore';
import ActionButtons from '../../shared/components/actionButtons';
import { advancedSuitabilityTables } from '../constants';
import { useKnowledgeAndExperienceStore } from '../services/knowledgeAndExperienceStore';
import KnowledgeAndExperienceQuestions from './knowledgeAndExperienceQuestions';
import SuitabilitySection from './suitabilitySection';
import AdvisorNotes from 'features/shared/components/advisorNotes';
import { Spacing } from 'features/shared/constants/spacing';
import { getTranslation } from 'features/shared/utils/translations';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import ErrorsList from 'features/sharedModules/errors/components/errorsList';
import NextButtonError from 'features/sharedModules/errors/components/nextButtonError';
import { useGetAllAdviceSessionErrors } from 'features/sharedModules/errors/services/useGetAllAdviceSessionErrors';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: Spacing.spacing04
  },
  actionButton: {
    '& + &': {
      marginLeft: '2.4rem'
    }
  },
  advisorNotes: {
    marginTop: Spacing.spacing04
  }
});

const Page = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const isSessionReadOnly = useIsSessionReadOnly();
  const { advancedSuitabilityConfig } = useKnowledgeAndExperienceStore();
  const {
    roboAdviceForm: {
      knowledgeAndExperience: { advancedSuitability }
    }
  } = useCustomerConfig();
  const allErrors = useGetAllAdviceSessionErrors();
  const errors = allErrors[RoboAdviceAdviceSessionPages.knowledgeAndExperience];

  useEffect(() => {
    return () => {
      useSessionStore.getState().resetFieldsWhichChangedRiskScore();
    };
  }, []);

  return (
    <div
      role="tabpanel"
      aria-labelledby={`tab-id-${RoboAdviceAdviceSessionPages.knowledgeAndExperience}`}
      id={`panel-id-${RoboAdviceAdviceSessionPages.knowledgeAndExperience}`}
    >
      <ErrorsList
        pageName={i18n('roboAdvice.tabs.knowledgeAndExperience')}
        errors={errors}
      />
      <KnowledgeAndExperienceQuestions errors={errors} />

      {advancedSuitability.enabled && (
        <>
          {advancedSuitabilityTables.map(
            ({ name, config }, index) =>
              advancedSuitabilityConfig &&
              advancedSuitabilityConfig[config] > 0 &&
              advancedSuitability[name].enabled && (
                <SuitabilitySection
                  name={name}
                  header={getTranslation(advancedSuitability[name]?.header)}
                  options={advancedSuitability[name]?.tableHeaders}
                  checks={advancedSuitabilityConfig?.checks}
                  key={index}
                  errors={
                    errors[`advancedSuitability-${name}`] as ErrorMessage[]
                  }
                />
              )
          )}
        </>
      )}

      <AdvisorNotes
        className={classes.advisorNotes}
        header={i18n('knowledgeAndExperience.advisorNotes.header')}
        customPlaceholder={i18n(
          'knowledgeAndExperience.advisorNotes.placeholder'
        )}
        infoTooltipText={i18n(
          'knowledgeAndExperience.advisorNotes.infoTooltip'
        )}
        disabled={isSessionReadOnly}
        name={'knowledgeAndExperienceAdvisorNotes'}
      />
      <NextButtonError allErrors={allErrors} />
      <ActionButtons
        pageId={RoboAdviceAdviceSessionPages.knowledgeAndExperience}
      />
    </div>
  );
};

export default Page;
