import { dataSourcesMapping } from '@quantfoliorepo/ui-components';
import axios from 'axios';
import { isNil } from 'ramda';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useHolisticViewStore } from '../services/holisticViewStore';
import { useReadCashflowData } from './proposalSection/cashflowChart/useReadCashflowData';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import {
  PortfolioType,
  usePortfolioChartStore
} from 'features/roboAdvice/adviceSession/portfolioChart';
import { readStandardPortfolio } from 'features/roboAdvice/adviceSession/proposal/components/portfolioChart/useReadPortfolioChartData';
import { getExpectedValue } from 'features/roboAdvice/adviceSession/proposal/components/useReadProposalPageData';
import { DEFAULT_CONFIDENCE } from 'features/roboAdvice/adviceSession/proposal/constants';
import { getHorizonAnalysisProjectionYearsValue } from 'features/roboAdvice/adviceSession/proposal/services/selectors';
import {
  createAnalyzeAssetClassAllocation,
  createAnalyzeForecast,
  createRiskReturn,
  getSessionGoals
} from 'features/roboAdvice/adviceSession/shared/api';
import { getSubAssetClassTranslation } from 'features/roboAdvice/adviceSession/shared/mapping';
import { mapServerSessionGoalToClient } from 'features/roboAdvice/adviceSession/shared/services/mapping';
import { getCurrentDate } from 'features/roboAdvice/shared/utils';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { useTheme } from 'features/sharedModules/styles/components/styles';

export const useReadGoalsData = () => {
  const i18n = useI18n();
  const dispatch = useDispatch();
  const theme = useTheme();
  const portfolioChartColors = theme.chartPortfolioColors;
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<any>();
  const {
    roboPortfolioPrecision,
    tenantSettings: { fundNameForPresentation },
    timeHorizonConfig,
    roboAdvice: { subAssetClassNameMapping = {} }
  } = useCustomerConfig();

  const portfolioChartStore = usePortfolioChartStore();
  const readCashflowData = useReadCashflowData();

  const readGoalsData = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;
    const currentDate = getCurrentDate();

    const holisticViewStoreState = useHolisticViewStore.getState();
    const selectedClients = holisticViewStoreState.availableClients.filter(
      c =>
        c.isSelected &&
        c.selectedAdviceSessionId &&
        (!c.goals || c.goals.length === 0)
    );

    try {
      holisticViewStoreState.setIsReadGoalsDataPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      for (const client of selectedClients) {
        const sessionGoalsResponse = await getSessionGoals(
          accessToken,
          cancelTokenSource.token,
          client.selectedAdviceSessionId
        );

        const newGoals = sessionGoalsResponse.data.data.map(d =>
          mapServerSessionGoalToClient(d)
        );

        for (const goal of newGoals) {
          if (goal.data.isPortfolioCustom && goal.data.customPortfolio) {
            const portfolio = dataSourcesMapping
              .getPortfolioFromAssetClasses(goal.data.customPortfolio)
              .filter(({ weight }) => weight)
              .reduce((acc, asset) => {
                const foundAssetIndex = acc.findIndex(a => a.id === asset.id);
                if (foundAssetIndex !== -1) {
                  return acc.map(({ id, weight }) => ({
                    id,
                    weight: id === asset.id ? weight + asset.weight! : weight
                  }));
                }

                return [...acc, { id: asset.id, weight: asset.weight ?? 0 }];
              }, [] as { id: string; weight: number }[]);

            const assetClassAllocationResponse =
              await createAnalyzeAssetClassAllocation(
                accessToken,
                cancelTokenSource.token,
                {
                  portfolio
                },
                {
                  namespace_id: goal.data.productPlatformNamespace,
                  language: getUserPageLanguage()
                }
              );

            const createRiskReturnResponse = await createRiskReturn(
              accessToken,
              cancelTokenSource.token,
              {
                portfolio
              },
              {
                namespace_id: goal.data.productPlatformNamespace
              }
            );

            const assetClassAllocation = {
              ...assetClassAllocationResponse?.data,
              portfolioAllocation:
                assetClassAllocationResponse?.data.portfolioAllocation.map(
                  allocation => ({
                    ...allocation,
                    name:
                      getSubAssetClassTranslation(
                        allocation.category,
                        subAssetClassNameMapping
                      ) || allocation.name
                  })
                )
            };

            portfolioChartStore.addAssetClassAllocationPortfolioChartData({
              dataSources: {
                analyzeRiskReturn: createRiskReturnResponse?.data,
                assetClassAllocation
              },
              portfolioChartColors,
              fundNameForPresentation,
              goalId: goal.goalId,
              goalName: goal.name,
              goalIcon: goal.icon,
              portfolioType: PortfolioType.custom,
              i18n
            });
          } else if (!goal.data.isPortfolioCustom && goal.data?.portfolio) {
            const portfolio = await readStandardPortfolio({
              qAuthAccessToken: accessToken,
              cancelTokenSource,
              risk_tolerance: goal.data?.portfolio,
              optionals: goal.data?.themes,
              precision: roboPortfolioPrecision,
              namespace_id: goal.data.productPlatformNamespace,
              portfolioChartColors,
              fundNameForPresentation,
              goalId: goal.goalId,
              goalName: goal.name,
              goalIcon: goal.icon,
              subAssetClassNameMapping,
              portfolioType: PortfolioType.model
            });
            portfolioChartStore.addAssetClassAllocationPortfolioChartData({
              ...portfolio,
              i18n
            });
          }

          const { chartData } = usePortfolioChartStore.getState();
          if (chartData.length > 0) {
            const portfolio = portfolioChartStore
              .getChartData(goal.goalId, goal.data.isPortfolioCustom)
              .categorySelectionPortfolio.map(({ id, weight }) => ({
                id,
                weight
              }));

            const createAnalyzeForecastResponse = await createAnalyzeForecast(
              accessToken,
              cancelTokenSource.token,
              {
                confidence_interval: DEFAULT_CONFIDENCE,
                initial_deposit: goal.data.firstDeposit,
                monthly_saving: goal.data.monthlyDeposit,
                portfolio,
                time_horizon: getHorizonAnalysisProjectionYearsValue({
                  timeHorizonConfig,
                  timeHorizon: goal.data.timeHorizon
                })
              }
            );

            goal.expectedValue = getExpectedValue(
              goal.data.timeHorizon as number,
              currentDate,
              createAnalyzeForecastResponse.data.forecast
            );
            goal.isGoalSelected = true;
          }
        }
        holisticViewStoreState.setGoals(client.id, newGoals);
      }

      // Check for unselected clients to remove their portfolios
      // from portfolioChartStore
      const currSelectedClientsGoals = useHolisticViewStore
        .getState()
        .availableClients.filter(c => c.isSelected)
        .map(c => c.goals || [])
        .flat()
        .map(g => g.goalId);
      const currPortfolioChartData =
        usePortfolioChartStore.getState().chartData;
      const filteredPortfolioChartData = currPortfolioChartData.filter(c =>
        currSelectedClientsGoals.includes(c.goalId)
      );

      usePortfolioChartStore
        .getState()
        .initialize({ chartData: filteredPortfolioChartData });

      await readCashflowData();
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('tools.holisticView.getGoalsError'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    } finally {
      holisticViewStoreState.setIsReadGoalsDataPending(false);
    }
  };

  return readGoalsData;
};
