import { useSelector } from 'react-redux';

import sessionSelectors from 'features/shared/services/session/selectors';
import { CurrentUser } from 'features/shared/services/session/types';

const useBuildAdvisorInfoPayload = () => {
  const currentUser: CurrentUser = useSelector(sessionSelectors.getCurrentUser);

  const buildAdvisorInfoPayload = () => {
    return {
      email: currentUser.email,
      full_name: currentUser.name,
      avatar: currentUser.customPicture
        ? currentUser.customPicture.thumbnail
        : currentUser.picture,
      phone: currentUser.phone ?? null,
      bio: currentUser.bio ?? null,
      timezone: currentUser.timezone ?? null
    };
  };

  return buildAdvisorInfoPayload;
};

export default useBuildAdvisorInfoPayload;
