import { omit, prop } from 'ramda';

import { FormState } from '../../../../sharedModules/zustand/components/finalFormAdapter';
import { AdviceSessionFormValues } from '../../form/services/form';
import { Goal } from '../../shared/services/goalsStore';
import {
  getClientInformation,
  getGeneralInformation
} from './mappingSelectors';
import { PortfolioChartStoreState } from './mappingTypes';
import { Contact } from 'features/roboAdvice/client/main/services/clientForm';

type PdfReportPayload = {
  i18n: (key: string) => string;
  userName: string;
  roboAdviceForm: FormState<AdviceSessionFormValues>;
  generateReportFromValues: {
    otherDocuments: {
      [key: string]: boolean;
    };
    morningstarAttachments: {
      [key: string]: boolean;
    };
  };
  maxRiskScore: string;
  portfolioChartStoreState: PortfolioChartStoreState;
  goals: Goal[];
  roboAdvice: Record<string, any>;
  kycData?: Record<string, any>;
  contacts?: Contact[] | null;
  isContactListEnabled: boolean;
  isSignatureRightsEnabled: boolean;
  isUBOEnabled: boolean;
  language: string;
};

export const mapAttachmentsData = ({
  i18n,
  userName,
  roboAdviceForm,
  generateReportFromValues,
  maxRiskScore,
  portfolioChartStoreState,
  goals,
  roboAdvice,
  kycData,
  contacts,
  isContactListEnabled,
  isSignatureRightsEnabled,
  isUBOEnabled,
  language
}: PdfReportPayload) => {
  const generalInformation = getGeneralInformation(
    prop('clientInformation', roboAdviceForm.values),
    userName
  );

  const isAnyMorningstarDocumentSelected = Object.values(
    generateReportFromValues.morningstarAttachments
  ).some(field => field === true);

  return {
    clientInformation: getClientInformation(
      i18n,
      prop('clientInformation', roboAdviceForm.values),
      roboAdvice.clientInformation,
      isContactListEnabled,
      isSignatureRightsEnabled,
      isUBOEnabled
    ),
    generalInformation: {
      name: generalInformation.name
    },
    purposeAndRisk: {
      maxRiskScore: maxRiskScore,
      expectationOfRisk: prop('expectationOfRisk', roboAdviceForm.values),
      riskStrategy: prop('riskStrategy', roboAdviceForm.values)
    },
    purposeAndRiskAdvisorNotes: prop(
      'purposeAndRiskAdvisorNotes',
      roboAdviceForm.values
    ),
    morningstarAttachments: Object.entries(
      generateReportFromValues.morningstarAttachments
    )
      .filter(([key, value]) => value)
      .map(([key]) => Number(key.split('_')[1])),
    otherDocuments: omit(
      ['all', 'kiid'],
      generateReportFromValues.otherDocuments
    ),
    otherDocumentsData: {
      name: generalInformation.name,
      number: generalInformation.number,
      advisorName: generalInformation.advisorName,
      email: generalInformation.advisorName,
      ssn: generalInformation.ssn,
      clientName: generalInformation.clientName,
      adress: generalInformation.address,
      riskModel: goals[0]?.data?.riskScore || null
    },
    isins: isAnyMorningstarDocumentSelected
      ? Array.from(
          new Set(
            portfolioChartStoreState.chartData
              .map(chartData =>
                chartData.categorySelectionPortfolio.map(p => p.isin)
              )
              .flat()
          )
        )
      : [],
    emailData: {
      name: generalInformation.name,
      email: generalInformation.email,
      phoneNumber: generalInformation.phoneNumber
    },
    kyc: kycData,
    contacts,
    language
  };
};
