import * as R from 'ramda';

import { types as customersTablePageActionTypes } from 'features/customers/customersTablePage/services/actions.js';
import { succeedType } from 'features/shared/utils/actions.js';

const reducer = (state = { entities: {}, ids: [] }, action) => {
  switch (action.type) {
    case succeedType(customersTablePageActionTypes.READ_CUSTOMERS): {
      const { customers } = action.payload;

      return {
        entities: R.indexBy(R.prop('id'), customers),
        ids: R.map(R.prop('id'), customers)
      };
    }
    case customersTablePageActionTypes.DELETE_CUSTOMER: {
      const { customerId } = action.payload;

      return R.pipe(
        R.over(R.lensProp('entities'), R.dissoc(customerId)),
        R.over(R.lensProp('ids'), R.reject(R.identical(customerId)))
      )(state);
    }
    default:
      return state;
  }
};

export default reducer;
