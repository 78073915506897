import { ExpectedPathChart } from '@quantfoliorepo/ui-components';
import { isEmpty } from 'ramda';
import { useState } from 'react';

import Table from './table.js';
import ButtonGroup from 'features/shared/components/buttonGroup';
import LoadingIndicator from 'features/shared/components/loadingIndicator/loadingIndicator';
import { Colors } from 'features/shared/constants/colors';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  root: {},
  noDataMessage: {
    paddingTop: '40px',
    paddingBottom: '40px',
    display: 'flex',
    justifyContent: 'center',
    color: Colors.gray50
  },
  body: {
    paddingTop: '40px'
  }
});

const ExpectedPathChartCardVisual = ({
  expectedPathTableData,
  expectedReturnChartData,
  boundsChartData,
  cultureCode,
  isLoading = false
}) => {
  const classes = useStyles();
  const i18n = useI18n();
  const [chartIndex, setChartIndex] = useState(0);

  const isChartDataEmpty =
    isEmpty(expectedPathTableData) ||
    isEmpty(expectedReturnChartData) ||
    isEmpty(boundsChartData);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isChartDataEmpty) {
    return (
      <div className={classes.noDataMessage}>
        {i18n('roboAdvice.proposal.NoExpectedPathData')}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <ButtonGroup
        items={[
          {
            title: i18n('roboAdvice.proposal.graph'),
            isActive: chartIndex === 0,
            onClick: () => setChartIndex(0)
          },
          {
            title: i18n('roboAdvice.proposal.table'),
            isActive: chartIndex === 1,
            onClick: () => setChartIndex(1)
          }
        ]}
      />
      {chartIndex === 0 && (
        <div className={classes.body}>
          <ExpectedPathChart
            cultureCode={cultureCode}
            chartData={{
              expectedReturn: expectedReturnChartData,
              bounds: boundsChartData
            }}
          />
        </div>
      )}
      {chartIndex === 1 && (
        <div className={classes.body}>
          <Table
            expectedPathTableData={expectedPathTableData}
            cultureCode={cultureCode}
          />
        </div>
      )}
    </div>
  );
};

export default ExpectedPathChartCardVisual;
