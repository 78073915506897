import { CancelToken } from 'axios';

import {
  DeleteDeletedInvestorsResponse,
  GetDeletedInvestorsResponse,
  RestoreDeletedInvestorsResponse
} from './types';
import { stateApi } from 'features/shared/utils/axios';

export const getDeletedInvestors = (
  accessToken: string,
  cancelToken?: CancelToken
) => {
  return stateApi.get<GetDeletedInvestorsResponse>(`/deleted/investor`, {
    cancelToken,
    headers: { Authorization: accessToken }
  });
};

export const deleteDeletedInvestors = (
  accessToken: string,
  investorIds: string[]
) =>
  stateApi.delete<DeleteDeletedInvestorsResponse>(
    `/deleted/investor/:bulk_delete`,
    {
      data: {
        investorIds
      },
      headers: { Authorization: accessToken }
    }
  );

export const restoreDeletedInvestors = (
  accessToken: string,
  investorIds: string[]
) =>
  stateApi.put<RestoreDeletedInvestorsResponse>(
    `/deleted/investor/:bulk_restore`,
    {
      investorIds
    },
    {
      headers: { Authorization: accessToken }
    }
  );
