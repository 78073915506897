import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getQAuthAccessToken } from '../../../../shared/api';
import sessionSelectors from '../../../../shared/services/session/selectors';
import { readAnalyzeAdvancedSuitabilityStatusV2 } from '../../shared/api';
import { PageStatuses } from '../../shared/components/useReadDataListener';
import { useKnowledgeAndExperienceStore } from '../services/knowledgeAndExperienceStore';
import { useForm as useRoboAdviceForm } from 'features/roboAdvice/adviceSession/form/services/form';
import { riskScoreToNumber } from 'features/roboAdvice/adviceSession/riskScore/services/shared';
import { useGoalsStore } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { clientForm } from 'features/roboAdvice/client/main/services/clientForm';
import { getInvestorTypeNumber } from 'features/roboAdvice/shared/utils';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export const useReadTargetMarketAssessmentData = () => {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<CancelTokenSource>();
  const dispatch = useDispatch();
  const i18n = useI18n();
  const {
    roboAdvice: { targetMarketAssessmentEnabled }
  } = useCustomerConfig();
  const {
    values: {
      dropInPercent,
      dropInStd,
      educationWork,
      expectationOfRisk,
      experience
    }
  } = useRoboAdviceForm();
  const { values: clientFormValues } = clientForm.getState();

  const readTargetMarketAssessmentData = async (data, goalId: string) => {
    if (!targetMarketAssessmentEnabled) {
      return;
    }

    const goal = useGoalsStore
      .getState()
      .goals.find(goal => goal.goalId === goalId);
    if (!goal) {
      return;
    }

    const knowledgeAndExperienceStoreState =
      useKnowledgeAndExperienceStore.getState();
    const fetchStatus =
      knowledgeAndExperienceStoreState.targetMarketAssessmentDataStatuses[
        goalId
      ];

    if (
      knowledgeAndExperienceStoreState.advancedSuitabilityConfig === null ||
      knowledgeAndExperienceStoreState.targetMarketAssessmentData[
        goal.data.productPlatformNamespace ?? ''
      ] ||
      fetchStatus === PageStatuses.pending ||
      fetchStatus === PageStatuses.failed
    ) {
      return;
    }

    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }

    try {
      knowledgeAndExperienceStoreState.changeTargetMarketAssessmentDataStatus(
        PageStatuses.pending,
        goalId
      );

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        undefined
      );

      const investorInformationFieldsConfig =
        knowledgeAndExperienceStoreState.advancedSuitabilityConfig
          .investorInformationFields;

      const targetMarketAssessmentResponse =
        await readAnalyzeAdvancedSuitabilityStatusV2(
          accessToken,
          undefined,
          {
            checks: data,
            investorInformationFields: Object.entries({
              dropInPercent,
              dropInStd,
              educationWork,
              expectationOfRisk,
              experience,
              horizon: goal.data.timeHorizon,
              investorType: getInvestorTypeNumber({
                clientClassification:
                  clientFormValues?.kyc?.clientClassification,
                automaticClientClassification:
                  clientFormValues?.kyc?.automaticClientClassification
                    ?.investorType
              }),
              riskScore: riskScoreToNumber(goal.data.riskScore)
            }).reduce((acc, [key, value]) => {
              if (!investorInformationFieldsConfig?.[key] || isNil(value)) {
                return acc;
              }

              acc[key] = value;
              return acc;
            }, {})
          },
          {
            namespace_id: goal.data.productPlatformNamespace
          }
        );

      useKnowledgeAndExperienceStore
        .getState()
        .addTargetMarketAssessmentData(
          targetMarketAssessmentResponse.data.data,
          goalId
        );

      knowledgeAndExperienceStoreState.changeTargetMarketAssessmentDataStatus(
        PageStatuses.succeed,
        goalId
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        if (targetMarketAssessmentEnabled) {
          knowledgeAndExperienceStoreState.changeTargetMarketAssessmentDataStatus(
            PageStatuses.failed,
            goalId
          );
        }

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.adviceSession.knowledgeAndExperience.readAdvancedSuitabilityStatusErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readTargetMarketAssessmentData;
};
