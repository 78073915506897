import classNames from 'classnames';

import TableComponent from 'features/shared/components/table/table.js';
import TableBody from 'features/shared/components/table/tableBody.js';
import TableBodyRow from 'features/shared/components/table/tableBodyRow.js';
import { Typography } from 'features/shared/constants/typography';
import { formatNumber } from 'features/shared/utils/number';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  headerCell: {
    padding: '7px 20px',
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight
  },
  bodyCell: {
    padding: '7px 20px'
  },
  yearCell: {
    textAlign: 'left'
  },
  expectedReturnCell: {
    textAlign: 'right'
  },
  lowerBoundCell: {
    textAlign: 'right'
  },
  upperBoundCell: {
    textAlign: 'right'
  }
}));

const Table = ({ expectedPathTableData, cultureCode }) => {
  const classes = useStyles();
  const i18n = useI18n();

  return (
    <div className={classes.root}>
      <TableComponent
        header={[
          {
            title: i18n('roboAdvice.proposal.year'),
            className: classNames(classes.headerCell, classes.yearCell)
          },
          {
            title: i18n('roboAdvice.proposal.expectedValue'),
            className: classNames(
              classes.headerCell,
              classes.expectedReturnCell
            )
          },
          {
            title: i18n('roboAdvice.proposal.lowerBound'),
            className: classNames(classes.headerCell, classes.lowerBoundCell)
          },
          {
            title: i18n('roboAdvice.proposal.upperBound'),
            className: classNames(classes.headerCell, classes.upperBoundCell)
          }
        ]}
      >
        <TableBody>
          {expectedPathTableData.map(i => (
            <TableBodyRow
              key={i.year}
              className={classes.bodyRow}
              isCssParity={true}
            >
              {({ bodyRowCellClassName }) => (
                <>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.bodyCell,
                      classes.yearCell
                    )}
                  >
                    {i.year}
                  </td>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.bodyCell,
                      classes.expectedReturnCell
                    )}
                  >
                    {formatNumber(cultureCode, i.expectedReturn, 0, 0)}
                  </td>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.bodyCell,
                      classes.lowerBoundCell
                    )}
                  >
                    {formatNumber(cultureCode, i.lowerBound, 0, 0)}
                  </td>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.bodyCell,
                      classes.upperBoundCell
                    )}
                  >
                    {formatNumber(cultureCode, i.upperBound, 0, 0)}
                  </td>
                </>
              )}
            </TableBodyRow>
          ))}
        </TableBody>
      </TableComponent>
    </div>
  );
};

export default Table;
