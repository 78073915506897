import * as React from 'react';

import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  circle: {
    fill: theme.successColor
  }
}));

const Checkmark = ({ className, ...restProps }) => {
  const classes = useStyles();

  return (
    <svg
      className={className}
      data-testid={restProps['data-testid']}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" className={classes.circle} />
      <path d="M15 20L19 24L27 16" stroke="white" strokeWidth="2" />
    </svg>
  );
};

export default Checkmark;
