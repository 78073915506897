import { TimeHorizonTypes } from '../../constants';
import { goalForm, useGoalForm } from '../../services/goalForm';
import GoalIcon from '../goalIcon';
import RadioTimeHorizon from '../timeHorizon/radioTimeHorizon';
import SliderTimeHorizon from '../timeHorizon/sliderTimeHorizon';
import MonthlyWithdrawal from './monthlyWithdrawal';
import OneTimeWithdrawal from './oneTimeWithdrawal';
import WithdrawalPlan from './withdrawalPlan';
import { useIsSessionReadOnly } from 'features/roboAdvice/adviceSession/main/services/selectors';
import { WithdrawalPlans } from 'features/roboAdvice/adviceSession/shared/constants';
import ConfigurableLabel from 'features/shared/components/configurableLabel/';
import { Spacing } from 'features/shared/constants/spacing';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import FinalFormTextInput from 'features/sharedModules/finalForm/components/textInput';
import FinalFormTextarea from 'features/sharedModules/finalForm/components/textarea';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.spacing02
  },
  column: {
    width: '420px'
  },
  label: {
    marginBottom: Spacing.spacing01
  },
  iconsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: Spacing.spacing01,
    gap: `${Spacing.spacing01}px 24px`
  },
  section: {
    marginTop: Spacing.spacing02
  },
  inputSection: {
    '& + &': {
      marginTop: 0
    }
  },
  inputsWidth: {
    width: `calc(100% - ${Spacing.spacing02}px)`
  },
  inputsSmallWidth: {
    width: 184
  },
  '@media (min-width: 1300px)': {
    root: {
      flexDirection: 'row',
      gap: Spacing.spacing04
    }
  }
});

const GoalForm = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const isSessionReadOnly = useIsSessionReadOnly();
  const {
    timeHorizonConfig,
    analyticsComponents: { isCashflowHidden },
    roboAdviceForm: {
      purposeAndRisk: { goals, showGoalIcons }
    }
  } = useCustomerConfig();
  const { values } = useGoalForm();

  const inputClasses = {
    section: classes.inputSection,
    label: classes.label
  };

  const shouldUseRadioSlider =
    timeHorizonConfig.type === TimeHorizonTypes.radio && !isCashflowHidden;
  const isMonthlyWithdrawalEnabled =
    values.data?.withdrawalPlan === WithdrawalPlans.monthlyWithdrawal &&
    !isCashflowHidden;

  const currentYear = new Date().getFullYear();
  const sortedTimeHorizonItems = timeHorizonConfig.items
    .slice()
    .sort((a, b) => a.yearIntervalStop - b.yearIntervalStop);

  const onSliderChange = value => {
    if (shouldUseRadioSlider) {
      const timeHorizonValue = sortedTimeHorizonItems.find(
        ({ yearIntervalStop }) => value <= yearIntervalStop
      ).riskHorizonValue;

      goalForm.change('data.timeHorizon', timeHorizonValue);
    }
    if (isMonthlyWithdrawalEnabled) {
      goalForm.change('data.withdrawalStart', currentYear + value);
    }
  };

  const onRadioButtonsChange = value => {
    if (isMonthlyWithdrawalEnabled) {
      const yearIntervalStart = sortedTimeHorizonItems.find(
        ({ riskHorizonValue }) => value === riskHorizonValue
      ).yearIntervalStart;

      goalForm.change('data.withdrawalStart', currentYear + yearIntervalStart);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.column}>
        {showGoalIcons && (
          <>
            <div className={classes.label}>
              {i18n(
                'roboAdvice.purposeAndRisk.purposePopup.selectOptionalIcon'
              )}
            </div>

            <div className={classes.iconsContainer}>
              {Object.values(goals)
                .sort((a, b) => a.order - b.order)
                .map(({ iconName, iconUrl, translations, type }) => (
                  <GoalIcon
                    key={iconName}
                    iconName={iconName}
                    iconUrl={iconUrl}
                    translations={translations}
                    goalType={type}
                  />
                ))}
            </div>
          </>
        )}

        <FinalFormTextInput
          data-testid={'saving-purpose-input'}
          name={'name'}
          label={
            <ConfigurableLabel
              required
              label={i18n('roboAdvice.purposeAndRisk.whatAreYouSavingFor')}
            />
          }
          externalClasses={inputClasses}
          disabled={isSessionReadOnly || values.data?.type === 'pension'}
          className={classes.inputsWidth}
          maxLength={25}
          showCounter={true}
          counterClassName={classes.inputsWidth}
          placeholder={i18n(
            'roboAdvice.purposeAndRisk.purposePopup.namePlaceholder'
          )}
        />

        <div className={classes.section}>
          <div className={classes.label}>
            {i18n('roboAdvice.purposeAndRisk.purposePopup.optionalDescription')}
          </div>

          <FinalFormTextarea
            name={'description'}
            minRows={2}
            disabled={isSessionReadOnly}
            className={classes.inputsWidth}
            maxLength={150}
            showCounter={true}
            counterClassName={classes.inputsWidth}
            placeholder={i18n(
              'roboAdvice.purposeAndRisk.purposePopup.descriptionPlaceholder'
            )}
          />
        </div>
      </div>

      <div className={classes.column}>
        {!isCashflowHidden && (
          <>
            <WithdrawalPlan />

            {isMonthlyWithdrawalEnabled && (
              <div style={{ marginBottom: Spacing.spacing02 }}>
                <MonthlyWithdrawal
                  sortedTimeHorizonItems={sortedTimeHorizonItems}
                  shouldUseRadioSlider={shouldUseRadioSlider}
                  timeHorizonType={timeHorizonConfig.type}
                  inputClasses={inputClasses}
                />
              </div>
            )}

            {values.data?.withdrawalPlan ===
              WithdrawalPlans.oneTimeWithdrawal && (
              <div style={{ marginBottom: Spacing.spacing02 }}>
                <OneTimeWithdrawal
                  inputClasses={inputClasses}
                  className={classes.inputsWidth}
                />
              </div>
            )}
          </>
        )}

        {(timeHorizonConfig.type === TimeHorizonTypes.slider ||
          shouldUseRadioSlider) && (
          <SliderTimeHorizon
            inputClasses={inputClasses}
            disabled={isSessionReadOnly}
            inputClassName={classes.inputsSmallWidth}
            sliderClassName={classes.inputsWidth}
            name={
              shouldUseRadioSlider
                ? 'data.timeHorizonToDisplay'
                : 'data.timeHorizon'
            }
            afterChange={onSliderChange}
          />
        )}

        {timeHorizonConfig.type === TimeHorizonTypes.radio &&
          isCashflowHidden && (
            <RadioTimeHorizon
              disabled={isSessionReadOnly}
              afterChange={onRadioButtonsChange}
            />
          )}
      </div>
    </div>
  );
};

export default GoalForm;
