import * as R from 'ramda';

import { clientsTable } from '../../clientsTable';
import {
  Client,
  ClientsStoreData,
  useClientsStore
} from '../services/clientsStore';
import { ClientType } from 'features/shared/constants/session';
import { getPage } from 'features/shared/services/table/selector';
import { TableStoreData } from 'features/sharedModules/table/types';
import { getSortFunc } from 'features/sharedModules/table/utils';

type ClientTableView = {
  id: string;
  type: ClientType;
  name: string;
  advisorName: string;
  lastActivityDate: string;
  softDeleteDate: string;
};

export function useClients() {
  const tableStore = clientsTable.useTableStore();
  const clientsStore = useClientsStore();

  return getClientsResult({
    tableStoreData: tableStore,
    clientsStoreData: clientsStore
  });
}

export function getClientsResult({
  tableStoreData,
  clientsStoreData
}: {
  tableStoreData: TableStoreData;
  clientsStoreData: ClientsStoreData;
}): {
  items: ClientTableView[];
  allItemIds: string[];
  totalItemsNumber: number;
} {
  const items = R.pipe<
    Client[],
    Client[],
    { items: Client[]; totalItemsNumber: number }
  >(
    getSortFunc({ tableStoreData }),
    getPage(tableStoreData)
  )(clientsStoreData.clients);

  const allItemIds = R.map(c => c.id, clientsStoreData.clients);

  return {
    ...items,
    allItemIds
  };
}
