import classNames from 'classnames';
import { isNil } from 'ramda';

import { useCustomPortfolioStore } from '../../services/customPortfolioStore';
import { FundAllocationRow, ProductAttributeExtended } from '../../types';
import { ReactComponent as CheckIcon } from 'assets/checkmark2.svg';
import { ReactComponent as CrossIcon } from 'assets/x-icon.svg';
import { useForm as useRoboAdviceForm } from 'features/roboAdvice/adviceSession/form/services/form';
import { ProductAttributeType } from 'features/roboAdvice/adviceSession/shared/constants';
import { useAdviceSessionStore } from 'features/roboAdvice/adviceSession/shared/services/adviceSessionStore';
import { getAlignmentCriteriaFields } from 'features/roboAdvice/adviceSession/sustainability/components/utils';
import TableBodyRow from 'features/shared/components/table/tableBodyRow';
import { getTranslation } from 'features/shared/utils/translations';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const AnyTableBodyRow: any = TableBodyRow;

const useStyles = createUseStyles(theme => ({
  numberCell: {
    textAlign: 'right'
  },
  positive: {
    color: theme.successColor
  },
  negative: {
    color: theme.errorNegativeColor
  },
  investorsPreference: {
    // Hack to replace !important
    '&$investorsPreference': {
      '&$investorsPreference': {
        backgroundColor: theme.inputFillFocusColor,
        color: theme.primaryColor
      }
    }
  },
  checkIcon: {
    fill: theme.successColor
  },
  crossIcon: {
    fill: theme.errorNegativeColor
  },
  center: {
    textAlign: 'center'
  }
}));

type Arguments = {
  productAttributes: ProductAttributeExtended[];
  namespaceId: number;
};

const useFundAllocationSustainabilityData = ({
  productAttributes,
  namespaceId
}: Arguments) => {
  const i18n = useI18n();
  const classes = useStyles();
  const { sustainabilityData } = useCustomPortfolioStore();
  const { customAttributesData } = useAdviceSessionStore();
  const customerConfig = useCustomerConfig();
  const { sustainability: { alignmentCriteria, genericAssessment } = {} } =
    useRoboAdviceForm.getState().values;

  const alignmentCriteriaFields = getAlignmentCriteriaFields({
    customerConfig
  })
    .filter(({ name }) => {
      const fieldName = name.split('.').pop() ?? '';
      return !!alignmentCriteria?.[fieldName];
    })
    .sort((a, b) => a.alignmentCriteriaOrder - b.alignmentCriteriaOrder);

  const sustainabilityTableHeaders = [
    ...alignmentCriteriaFields.map(({ config: { title } }) => ({
      title: getTranslation(title),
      className: classes.numberCell
    })),
    ...productAttributes.map(({ label, className }) => ({
      title: getTranslation(label),
      className
    }))
  ];

  const investorsPreferenceValues = alignmentCriteria
    ? Object.entries(alignmentCriteria)
        .map(([key, value]) => ({
          name: key,
          value,
          order:
            alignmentCriteriaFields.find(
              field => field.name === `sustainability.alignmentCriteria.${key}`
            )?.alignmentCriteriaOrder || 0
        }))
        .filter(({ name }) =>
          alignmentCriteriaFields.find(
            field => field.name === `sustainability.alignmentCriteria.${name}`
          )
        )
        .sort((a, b) => a.order - b.order)
    : [];

  const sustainabilityFirstRows =
    !customerConfig.roboAdvice.sustainability.genericAssessment?.enabled ||
    genericAssessment?.answer === true ? (
      <AnyTableBodyRow>
        {({ bodyRowCellClassName }) => (
          <>
            <td
              className={classNames(
                bodyRowCellClassName,
                classes.investorsPreference
              )}
            >
              {i18n('roboAdvice.advisory.customPortfolio.investorsPreference')}
            </td>

            {/* empty allocation column */}
            <td
              className={classNames(
                bodyRowCellClassName,
                classes.investorsPreference
              )}
            ></td>

            {investorsPreferenceValues.map(({ name, value }) => (
              <td
                key={name}
                className={classNames(
                  bodyRowCellClassName,
                  classes.investorsPreference,
                  classes.numberCell
                )}
              >
                {value}
              </td>
            ))}

            {productAttributes.map(_ => (
              <td
                className={classNames(
                  bodyRowCellClassName,
                  classes.investorsPreference
                )}
              ></td>
            ))}

            {/* empty edit column */}
            <td
              className={classNames(
                bodyRowCellClassName,
                classes.investorsPreference
              )}
            ></td>
          </>
        )}
      </AnyTableBodyRow>
    ) : null;

  const createSustainabilityTableData = (
    fundAllocationRows: FundAllocationRow[]
  ): FundAllocationRow[] => {
    if (!sustainabilityData) return fundAllocationRows;

    return fundAllocationRows.map(category => {
      const { instruments } = category;

      return {
        ...category,
        instruments: instruments.map(instrument => {
          const foundInstrumentFundAnalysis =
            sustainabilityData?.fundAnalysis.find(
              f => f.ticker === instrument.id
            );

          if (!foundInstrumentFundAnalysis || !customAttributesData) {
            return {
              ...instrument,
              createAdditionalData: ({ className }) => (
                <>
                  {instrumentAlignmentCriteria.map(
                    ({ name, colorClassName }) => (
                      <td
                        key={name}
                        className={classNames(className, colorClassName)}
                      />
                    )
                  )}

                  {productAttributes.map(({ name }) => (
                    <td className={className} key={name} />
                  ))}
                </>
              )
            };
          }

          const instrumentAlignmentCriteria = Object.entries(
            foundInstrumentFundAnalysis.alignmentCriteria
          )
            .map(([name, value]) => {
              const status = Object.entries(
                foundInstrumentFundAnalysis.alignmentCriteriaStatus
              ).find(
                ([alignmentCriteriaName]) => alignmentCriteriaName === name
              )?.[1];
              const investorAnswer = investorsPreferenceValues.find(
                ({ name: investorName }) => investorName === name
              )?.value;

              let colorClassName: string | undefined;
              if (investorAnswer === 0 || isNil(status)) {
                colorClassName = undefined;
              } else if (status === true) {
                colorClassName = classes.positive;
              } else if (status === false) {
                colorClassName = classes.negative;
              }

              return {
                value,
                name,
                colorClassName,
                order:
                  alignmentCriteriaFields.find(
                    field =>
                      field.name === `sustainability.alignmentCriteria.${name}`
                  )?.alignmentCriteriaOrder || 0
              };
            })
            .filter(
              ({ name }) =>
                alignmentCriteriaFields.find(
                  field =>
                    field.name === `sustainability.alignmentCriteria.${name}`
                )?.enabled
            )
            .sort((a, b) => a.order - b.order);

          const customAttributeData = customAttributesData?.[namespaceId]?.find(
            ({ ticker }) => ticker === instrument.id
          );

          return {
            ...instrument,
            createAdditionalData: ({ className }) => (
              <>
                {instrumentAlignmentCriteria.map(
                  ({ value, name, colorClassName }) => (
                    <td
                      key={name}
                      className={classNames(className, colorClassName)}
                    >
                      {value}
                    </td>
                  )
                )}

                {productAttributes.map(({ type, name }) => {
                  if (type === ProductAttributeType.text) {
                    return (
                      <td className={className} key={name}>
                        {customAttributeData?.productAttributeText?.[name]}
                      </td>
                    );
                  }

                  if (type === ProductAttributeType.binary) {
                    return (
                      <td
                        className={classNames(className, classes.center)}
                        key={name}
                      >
                        {customAttributeData?.productAttributeBinary?.[name] ? (
                          <CheckIcon className={classes.checkIcon} />
                        ) : (
                          <CrossIcon className={classes.crossIcon} />
                        )}
                      </td>
                    );
                  }

                  return null;
                })}
              </>
            )
          };
        })
      };
    });
  };

  return {
    sustainabilityTableHeaders,
    sustainabilityFirstRows,
    createSustainabilityTableData
  };
};

export default useFundAllocationSustainabilityData;
