import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  getClientName,
  mapServerInvestorInformationToClient
} from '../../../shared/services/mapping';
import { getInvestorInformation } from '../../main/api';
import { clientForm } from '../../main/services/clientForm';
import { useClientPageStore } from '../../main/services/clientPageStore';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { AdviceSessionParams } from 'features/shared/constants/session';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useReadClientInformation() {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const i18n = useI18n();
  const { clientId } = useParams<AdviceSessionParams>();
  const { state }: { state: { isCrmRedirect?: boolean } } = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const readClientInformation = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const clientPageStore = useClientPageStore.getState();

    try {
      clientPageStore.setReadClientInformationStatus(PageStatuses.pending);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const getInvestorInformationResponse = await getInvestorInformation(
        accessToken,
        cancelTokenSource.token,
        clientId
      );
      const clientInformation = mapServerInvestorInformationToClient(
        getInvestorInformationResponse.data
      );
      const kyc = getInvestorInformationResponse.data.kyc || {
        signatureRight: [{}],
        ubo: [{}]
      };
      const clientName = getClientName(clientInformation);

      const { values } = clientForm.getState();
      clientForm.initialize({
        ...values,
        clientInformation,
        kyc
      });
      clientPageStore.setClientData(clientName);
      clientPageStore.setReadClientInformationStatus(PageStatuses.succeed);
    } catch (error) {
      if (state?.isCrmRedirect) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.integrationPlatform.CRMRedirectFailedDefaultingToHomeWarning'
            ),
            type: NotificationTypes.warning
          })
        );

        history.push(routeTemplates.home.build());
        return;
      }

      if (!axios.isCancel(error)) {
        clientPageStore.setReadClientInformationStatus(PageStatuses.failed);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.client.readClientInformationErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (!isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
    };
  }, []);

  return readClientInformation;
}
