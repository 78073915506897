import classNames from 'classnames';
import React, { useState, useRef, useEffect } from 'react';

import { checkIsMonthActive, MonthShortKeys } from './constants';
import Icon from 'features/shared/components/icon/index.js';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import useOnClickOutside from 'features/shared/hooks/useClickOutside.js';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  headerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerLable: {
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.primaryColor,
    fontSize: Typography.body1.size,
    fontWeight: FontWeights.bold,
    margin: '3px 0 0 6px',
    cursor: 'pointer'
  },
  dropDownIcon: {
    display: 'inline-flex',
    marginLeft: '4px',
    color: theme.secondaryColor,
    fontSize: Typography.body1.size,
    height: Typography.body1.lineHeight
  },
  dropDownIconActive: {
    transform: 'rotate(180deg)'
  },
  valuesWrapper: {
    position: 'absolute',
    top: '50px',
    left: '14px',
    width: '300px',
    height: '250px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    paddingTop: '10px',
    backgroundColor: theme.itemBackgroundColor_1,
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  valueCell: {
    width: '84px',
    height: '52px',
    display: 'flex',
    borderRadius: '8px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.secondaryColor,
    fontSize: Typography.body1.size,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.inputFillFocusColor
    }
  },
  disabledValueCell: {
    color: theme.disabledTextColor,

    '&:hover': {
      backgroundColor: theme.itemBackgroundColor_1,
      cursor: 'not-allowed'
    }
  },
  selectedValueCell: {
    borderRadius: '8px',
    backgroundColor: theme.accentColor,
    color: theme.primaryColor,

    '&:hover': {
      backgroundColor: theme.accentColor
    }
  }
}));

const CustomDropdown = ({
  selectedDate,
  selectedValue,
  valueArray,
  changeFunction,
  minAllowedDate,
  maxAllowedDate
}) => {
  const styles = useStyles();
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const scrollRef = useRef(null);
  const i18n = useI18n();
  const months = MonthShortKeys.map(monthKey => i18n(monthKey));

  const ref = useRef(null);
  useOnClickOutside([ref], () => setIsDropdownActive(false));

  const onValueClick = value => {
    changeFunction(value);
    setIsDropdownActive(false);
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ block: 'nearest' });
  }, [isDropdownActive]);

  return (
    <div className={styles.headerWrapper} ref={ref}>
      <span
        className={styles.headerLable}
        onClick={() => setIsDropdownActive(state => !state)}
      >
        {selectedValue}
        <Icon
          className={classNames(styles.dropDownIcon, {
            [styles.dropDownIconActive]: isDropdownActive
          })}
          type="expand_more"
        />
      </span>
      {isDropdownActive && (
        <div className={styles.valuesWrapper}>
          {valueArray.map(value =>
            checkIsMonthActive(
              selectedDate,
              value,
              months,
              minAllowedDate,
              maxAllowedDate
            ) ? (
              <span
                className={classNames(styles.valueCell, {
                  [styles.selectedValueCell]: selectedValue === value
                })}
                key={value}
                onClick={() => onValueClick(value)}
                ref={selectedValue === value ? scrollRef : null}
              >
                {value}
              </span>
            ) : (
              <span
                className={classNames(
                  styles.valueCell,
                  styles.disabledValueCell
                )}
                key={value}
                ref={selectedValue === value ? scrollRef : null}
              >
                {value}
              </span>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
