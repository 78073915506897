import axios, { AxiosResponse } from 'axios';
import { add, format, addMonths, addYears, setDate } from 'date-fns';
import * as R from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePortfolioChartStore } from '../../../portfolioChart';
import {
  readCalculatorCapitalNeed,
  readCalculatorForecast
} from '../../../shared/api';
import { usePageStore as useProposalPageStore } from '../../services/pageStore';
import { getHorizonInYears } from '../../services/selectors';
import { WithdrawalPlans } from './../../../shared/constants';
import { useCashflowChartStore } from 'features/roboAdvice/adviceSession/cashflowChart';
import { TimeHorizonTypes } from 'features/roboAdvice/adviceSession/purposeAndRisk/constants';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { useGoalsStore } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export const useReadCashflowData = () => {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<any>();
  const portfolioChartStore = usePortfolioChartStore();
  const dispatch = useDispatch();
  const customerConfig = useCustomerConfig();

  const readCashflowData = async () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const proposalPageStore = useProposalPageStore.getState();
    const cashflowChartStore = useCashflowChartStore.getState();
    const { goals: storeGoals } = useGoalsStore.getState();

    try {
      proposalPageStore.setPageStatus('readCashflowData', PageStatuses.pending);

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const isUpdated =
        !!proposalPageStore.goalsToUpdate.readCashflowData.length;

      const goals = isUpdated
        ? proposalPageStore.goalsToUpdate.readCashflowData
        : storeGoals;

      for (const goal of goals) {
        const expectedAnnualReturn = R.prop(
          'expectedAnnualReturn',
          portfolioChartStore.getChartData(
            goal.goalId,
            goal.data.isPortfolioCustom
          )
        );
        if (!expectedAnnualReturn) {
          return;
        }

        const capitalNeedPayload: CalculatorForecastPayload = {
          interest_rate: expectedAnnualReturn / 100,
          start_date: `${goal.data.withdrawalStart}-01-01`,
          end_date: `${goal.data.withdrawalEnd}-01-01`,
          aggregation_period: 'year',
          transactions: {
            single: [],
            regular: []
          }
        };

        let forecastPayload: Partial<CalculatorForecastPayload> = {
          interest_rate: expectedAnnualReturn / 100,
          aggregation_period: 'year'
        };
        if (goal.data.withdrawalPlan === WithdrawalPlans.oneTimeWithdrawal) {
          const startDate = format(add(new Date(), { days: 1 }), 'yyyy-MM-dd');
          const withdrawalStartDate = format(
            addMonths(setDate(new Date(), 1), 1),
            'yyyy-MM-dd'
          );

          const withdrawalEndDate = format(
            addYears(
              addMonths(setDate(new Date(), 1), 1),
              customerConfig.timeHorizonConfig.type === TimeHorizonTypes.radio
                ? goal.data.timeHorizonToDisplay!
                : goal.data.timeHorizon!
            ),
            'yyyy-MM-dd'
          );

          forecastPayload = {
            ...forecastPayload,
            start_date: startDate,
            end_date: withdrawalEndDate,
            transactions: {
              single: [
                {
                  amount:
                    (goal.data.firstDeposit ?? 0) +
                    (goal.data.internalHolding ?? 0) +
                    ((goal.data.followUpAddOn ?? 0) -
                      (goal.data.followUpWithdrawal ?? 0)),
                  date: withdrawalStartDate
                },
                {
                  amount: -goal.data.capitalNeed!,
                  date: withdrawalEndDate
                }
              ],
              regular: [
                {
                  amount: goal.data.monthlyDeposit || 0,
                  start_date: withdrawalStartDate,
                  end_date: format(
                    addYears(
                      new Date(),
                      customerConfig.timeHorizonConfig.type ===
                        TimeHorizonTypes.radio
                        ? goal.data.timeHorizonToDisplay!
                        : goal.data.timeHorizon!
                    ),
                    'yyyy-MM-dd'
                  )
                }
              ]
            }
          };
        }

        const startDate = format(add(new Date(), { days: 1 }), 'yyyy-MM-dd');
        const endDate = getEndDate(goal, customerConfig);
        if (goal.data.withdrawalPlan === WithdrawalPlans.monthlyWithdrawal) {
          forecastPayload = {
            ...forecastPayload,
            start_date: startDate,
            end_date: `${goal.data.withdrawalEnd}-01-01`,
            transactions: {
              single: [
                {
                  amount:
                    (goal.data.firstDeposit ?? 0) +
                    (goal.data.internalHolding ?? 0) +
                    ((goal.data.followUpAddOn ?? 0) -
                      (goal.data.followUpWithdrawal ?? 0)),
                  date: startDate
                }
              ],
              regular: [
                {
                  amount: goal.data.monthlyDeposit || 0,
                  start_date: startDate,
                  end_date: endDate
                },
                {
                  amount: -goal.data.monthlyWithdrawal!,
                  start_date: `${goal.data.withdrawalStart}-02-01`,
                  end_date: `${goal.data.withdrawalEnd}-01-01`
                }
              ]
            }
          };

          capitalNeedPayload.transactions.regular = [
            {
              amount: -goal.data.monthlyWithdrawal!,
              start_date: `${goal.data.withdrawalStart}-02-01`,
              end_date: `${goal.data.withdrawalEnd}-01-01`
            }
          ];
        }

        if (goal.data.withdrawalPlan === WithdrawalPlans.noPlannedWithdrawal) {
          forecastPayload = {
            ...forecastPayload,
            start_date: startDate,
            end_date: endDate,
            transactions: {
              single: [
                {
                  amount:
                    (goal.data.firstDeposit ?? 0) +
                    (goal.data.internalHolding ?? 0) +
                    ((goal.data.followUpAddOn ?? 0) -
                      (goal.data.followUpWithdrawal ?? 0)),
                  date: startDate
                }
              ],
              regular: [
                {
                  amount: goal.data.monthlyDeposit || 0,
                  start_date: startDate,
                  end_date: endDate
                }
              ]
            }
          };
        }

        const forecastResponse: AxiosResponse<CashflowData> =
          await readCalculatorForecast(qAuthAccessToken, forecastPayload);

        const { capitalNeed, estimatedGoalAchievement } =
          await getCapitalNeedData(
            goal,
            capitalNeedPayload,
            forecastResponse,
            qAuthAccessToken
          );

        const data = {
          goalId: goal.goalId,
          forecast: forecastResponse.data.forecast,
          goalName: goal.name,
          goalIcon: goal.icon,
          capitalNeed,
          estimatedGoalAchievement
        };

        isUpdated
          ? cashflowChartStore.editGoalData(data)
          : cashflowChartStore.addGoalData(data);
      }

      if (isUpdated) {
        proposalPageStore.setGoalsToUpdate('readCashflowData', []);
      }
      proposalPageStore.setPageStatus('readCashflowData', PageStatuses.succeed);
    } catch (error) {
      if (!axios.isCancel(error)) {
        proposalPageStore.setPageStatus(
          'readCashflowData',
          PageStatuses.failed
        );

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.proposal.readDataErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readCashflowData;
};

const getCapitalNeedData = async (
  goal,
  payload,
  forecastResponse,
  qAuthAccessToken
) => {
  let capitalNeed;

  switch (goal.data.withdrawalPlan) {
    case WithdrawalPlans.monthlyWithdrawal:
      const capitalNeedResponse: AxiosResponse<CapitalNeed> =
        await readCalculatorCapitalNeed(qAuthAccessToken, payload);

      const forecastWithdrawalStart = forecastResponse.data.forecast.find(
        ({ date }) => +date.slice(0, 4) === goal.data.withdrawalStart
      );

      capitalNeed = capitalNeedResponse.data.capital_need;

      return {
        capitalNeed,
        estimatedGoalAchievement: forecastWithdrawalStart!.amount / capitalNeed
      };
    case WithdrawalPlans.oneTimeWithdrawal:
      capitalNeed = goal.data.capitalNeed;

      return {
        capitalNeed,
        estimatedGoalAchievement:
          forecastResponse.data.forecast[
            forecastResponse.data.forecast.length - 2
          ].amount / capitalNeed
      };
    case WithdrawalPlans.noPlannedWithdrawal:
      return {
        capitalNeed: 'N/A',
        estimatedGoalAchievement: 1
      };
    default:
      return {};
  }
};

const getEndDate = (goal, customerConfig) => {
  if (goal.data.withdrawalPlan === WithdrawalPlans.monthlyWithdrawal) {
    return `${goal.data.withdrawalStart}-01-01`;
  } else if (
    goal.data.withdrawalPlan === WithdrawalPlans.oneTimeWithdrawal &&
    customerConfig.timeHorizonConfig.type === TimeHorizonTypes.radio
  ) {
    return `${
      new Date().getFullYear() + goal.data.timeHorizonToDisplay!
    }-01-01`;
  }

  return format(
    add(new Date(), {
      years: getHorizonInYears({
        customerConfig,
        timeHorizon: goal.data.timeHorizon
      })
    }),
    'yyyy-MM-dd'
  );
};

type CalculatorForecastPayload = {
  interest_rate: number;
  start_date: string;
  end_date: string;
  aggregation_period: 'month' | 'year';
  transactions: {
    single: {
      amount: number;
      date: string;
    }[];
    regular: {
      amount: number;
      start_date: string;
      end_date: string;
    }[];
  };
};

type CashflowData = {
  forecast: {
    amount: number;
    date: string;
    deposits: number;
    withdrawals: number;
  }[];
};

type CapitalNeed = {
  capital_need: number;
  forecast: {
    amount: number;
    date: string;
    deposits: number;
    withdrawals: number;
  }[];
};
