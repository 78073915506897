import * as R from 'ramda';
import { initialize, actionTypes } from 'redux-form';
import { takeEvery, takeLatest, put, call, select } from 'redux-saga/effects';
import { v4 } from 'uuid';

import { getCustomer } from '../../../shared/api/index.js';
import { NewCustomerId } from '../../../shared/constants/index.js';
import { types, creators } from '../actions.js';
import { mapServerCustomerToClient } from '../mapping.js';
import { getIsPageOpened, getIsPageClosed } from '../selectors/index.js';
import { getUsers } from '../selectors/index.js';
import editCustomerFormSagas from './editCustomerForm.js';
import routerSelectors from 'features/shared/services/router/selectors.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { creators as tableActionCreators } from 'features/shared/services/table/actions.js';
import tableSelectors from 'features/shared/services/table/selector.js';
import { updatePageNumber } from 'features/shared/services/table/utils.js';
import {
  startedCreator,
  succeedCreator,
  failedCreator
} from 'features/shared/utils/actions.js';
import { formatDate } from 'features/shared/utils/dateTime.js';
import { types as routerActionTypes } from 'framework/router/services/actions.js';

function* onLocationInit(action) {
  const state = yield select();
  const isPageOpened = getIsPageOpened(state);

  if (!isPageOpened) {
    return;
  }

  yield put(creators.opened());
}

function* onLocationCleanup(action) {
  const state = yield select();
  const isPageClosed = getIsPageClosed(state);

  if (!isPageClosed) {
    return;
  }

  yield put(creators.closed());
}

function* onPageOpened(action) {
  const state = yield select();
  const route = routerSelectors.getRoute(state);
  const params = route.match.params;

  if (params.id !== NewCustomerId) {
    yield put(creators.readCustomer(params.id));
  } else {
    yield put(
      initialize('editCustomerForm_state', {
        title: '',
        code: null,
        advisoryKey: null,
        users: [],
        licenses: []
      })
    );
  }
}

function* readCustomer(action) {
  const { customerId } = action.payload;

  yield put(startedCreator(types.READ_CUSTOMER));

  try {
    const state = yield select();
    const currentCustomerId = sessionSelectors.getCurrentUserCustomerId(state);
    const accessToken = sessionSelectors.getAuth0AccessToken(state);
    const cultureCode = sessionSelectors.getCurrentUserCultureCode(state);

    const result = yield call(
      getCustomer,
      currentCustomerId,
      accessToken,
      customerId
    );
    const data = mapServerCustomerToClient(result.data);

    yield put(succeedCreator(types.READ_CUSTOMER, data));

    const { customer, users } = data;

    yield put(
      initialize('editCustomerForm_state', {
        title: customer.title,
        code: customer.code,
        advisoryKey: customer.advisoryKey,
        users,
        licenses: R.map(
          license => ({
            ...license,
            renderId: v4(),
            licenseRenewalDate: formatDate(
              cultureCode,
              license.licenseRenewalDate
            )
          }),
          customer.licenses
        )
      })
    );
  } catch (error) {
    yield put(failedCreator(types.READ_CUSTOMER));
  }
}

function* fitTablePageNumber(action) {
  const state = yield select();

  const rows = getUsers(state);

  const tableState = tableSelectors.getTable(
    'customerManagementUsersTable_state',
    state
  );
  const updatedTableState = updatePageNumber(rows.totalItemsNumber, tableState);

  if (updatedTableState.pageNumber !== tableState.pageNumber) {
    yield put(
      tableActionCreators.changePageNumber(
        'customerManagementUsersTable_state',
        updatedTableState.pageNumber
      )
    );
  }
}

export default function* () {
  yield takeEvery(routerActionTypes.LOCATION_INIT, onLocationInit);

  yield takeEvery(routerActionTypes.LOCATION_CLEANUP, onLocationCleanup);

  yield takeEvery(types.OPENED, onPageOpened);

  yield takeLatest(types.READ_CUSTOMER, readCustomer);

  yield takeEvery(
    action =>
      action.type === actionTypes.ARRAY_REMOVE &&
      action.meta.form === 'editCustomerForm_state' &&
      action.meta.field === 'users',
    fitTablePageNumber
  );

  yield* editCustomerFormSagas();
}
