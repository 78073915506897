import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { useHistoricalReturnChartStore } from '../../../historicalReturnChart';
import { usePageStore as useProposalPageStore } from '../../services/pageStore';
import { useReadHistoricalReturnChartData } from './useReadHistoricalReturnChartData';
import HistoricalReturnStatistics from 'features/roboAdvice/adviceSession/shared/components/historicalReturnStatistics';
import { useReadDataListener } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { NUMBER_OF_ALLOWED_STATISTICS } from 'features/roboAdvice/adviceSession/shared/constants';
import { useGoalsStore } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { selectors } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { getHistoricalReturnStatisticsData } from 'features/roboAdvice/adviceSession/shared/services/historicalReturnStatisticsSelectors';
import HistoricalReturnChartCardVisual from 'features/shared/analyticsComponents/historicalReturnChart/visual';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  statistics: {
    fontSize: Typography.heading2.size,
    lineHeight: Typography.heading2.lineHeight,
    margin: `${Spacing.spacing04}px 0 ${Spacing.spacing01}px`,
    textAlign: 'left'
  }
});

const HistoricalReturnChartCard = ({ isSelected }) => {
  const [activeItem, setActiveItem] = useState({
    statusKey: 'readHistoricalReturn3MChartData',
    timeRangeChartDataKey: '3M'
  });
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const chartStoreData = useHistoricalReturnChartStore();
  const { pageStatuses } = useProposalPageStore();
  const selectedGoalsIds = useGoalsStore(selectors.getSelectedGoalsIds);
  const classes = useStyles();
  const i18n = useI18n();
  const {
    analyticsComponents: {
      showStatsInHistoricalReturn,
      historicalReturnStatsItems
    }
  } = useCustomerConfig();

  const getItemStatus = useCallback(
    item => pageStatuses[item.statusKey],
    [pageStatuses]
  );

  useReadDataListener(
    pageStatuses.readHistoricalReturn3MChartData,
    isSelected && activeItem.statusKey === 'readHistoricalReturn3MChartData',
    useReadHistoricalReturnChartData('readHistoricalReturn3MChartData', '3M')
  );
  useReadDataListener(
    pageStatuses.readHistoricalReturnYTDChartData,
    isSelected && activeItem.statusKey === 'readHistoricalReturnYTDChartData',
    useReadHistoricalReturnChartData('readHistoricalReturnYTDChartData', 'YTD')
  );
  useReadDataListener(
    pageStatuses.readHistoricalReturn1YChartData,
    isSelected && activeItem.statusKey === 'readHistoricalReturn1YChartData',
    useReadHistoricalReturnChartData('readHistoricalReturn1YChartData', '1Y')
  );
  useReadDataListener(
    pageStatuses.readHistoricalReturn3YChartData,
    isSelected && activeItem.statusKey === 'readHistoricalReturn3YChartData',
    useReadHistoricalReturnChartData('readHistoricalReturn3YChartData', '3Y')
  );
  useReadDataListener(
    pageStatuses.readHistoricalReturn5YChartData,
    isSelected && activeItem.statusKey === 'readHistoricalReturn5YChartData',
    useReadHistoricalReturnChartData('readHistoricalReturn5YChartData', '5Y')
  );
  useReadDataListener(
    pageStatuses.readHistoricalReturn10YChartData,
    isSelected && activeItem.statusKey === 'readHistoricalReturn10YChartData',
    useReadHistoricalReturnChartData('readHistoricalReturn10YChartData', '10Y')
  );

  const statisticsData =
    showStatsInHistoricalReturn &&
    getHistoricalReturnStatisticsData(
      chartStoreData.chartData[activeItem.timeRangeChartDataKey]?.filter(
        ({ goalId }) => selectedGoalsIds.includes(goalId)
      ),
      historicalReturnStatsItems,
      NUMBER_OF_ALLOWED_STATISTICS
    );

  return (
    <>
      <HistoricalReturnChartCardVisual
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        cultureCode={cultureCode}
        chartStoreChartData={chartStoreData.chartData}
        selectedGoalsIds={selectedGoalsIds}
        getItemStatus={getItemStatus}
      />

      {showStatsInHistoricalReturn && statisticsData && (
        <>
          <div className={classes.statistics}>{i18n('shared.statistics')}</div>

          <HistoricalReturnStatistics
            data={statisticsData.data}
            headerNames={statisticsData.names}
          />
        </>
      )}
    </>
  );
};

export default HistoricalReturnChartCard;
