import * as R from 'ramda';

import {
  startedType,
  succeedType,
  failedType,
  cancelledType
} from 'features/shared/utils/actions.js';

const setStateKey = getStateKey => (acc, key) => {
  const stateKey = getStateKey(key);

  if (R.isNil(stateKey)) {
    return acc;
  }

  return R.isNil(acc[stateKey])
    ? R.set(R.lensProp(stateKey), [key], acc)
    : R.over(R.lensProp(stateKey), R.append(key), acc);
};

// TODO: allow to pass custom reducer function instead of object for specific status key
const createReducer = config => {
  const startedActionTypesIndex = R.reduce(
    setStateKey(key => config[key].started),
    {},
    R.keys(config)
  );
  const succeedActionTypesIndex = R.reduce(
    setStateKey(key => config[key].succeed),
    {},
    R.keys(config)
  );
  const failedActionTypesIndex = R.reduce(
    setStateKey(key => config[key].failed),
    {},
    R.keys(config)
  );
  const cancelledActionTypesIndex = R.reduce(
    setStateKey(key => config[key].cancelled),
    {},
    R.keys(config)
  );

  return (state = {}, action) => {
    const startedKeys = startedActionTypesIndex[action.type];
    if (!R.isNil(startedKeys)) {
      return R.reduce(
        (acc, key) =>
          R.assoc(
            key,
            {
              isPending: true,
              isFailed: false,
              isDefault: false
            },
            acc
          ),
        state,
        startedKeys
      );
    }

    const succeedKeys = succeedActionTypesIndex[action.type];
    if (!R.isNil(succeedKeys)) {
      return R.reduce(
        (acc, key) =>
          R.assoc(
            key,
            {
              isPending: false,
              isFailed: false,
              isDefault: false,
              payload: action.payload
            },
            acc
          ),
        state,
        succeedKeys
      );
    }

    const failedKeys = failedActionTypesIndex[action.type];
    if (!R.isNil(failedKeys)) {
      return R.reduce(
        (acc, key) =>
          R.assoc(
            key,
            {
              isPending: false,
              isFailed: true,
              isDefault: false,
              payload: action.payload
            },
            acc
          ),
        state,
        failedKeys
      );
    }

    const cancelledKeys = cancelledActionTypesIndex[action.type];
    if (!R.isNil(cancelledKeys)) {
      return R.reduce(
        (acc, key) =>
          R.assoc(
            key,
            {
              isPending: false,
              isFailed: false,
              isDefault: false,
              payload: action.payload
            },
            acc
          ),
        state,
        cancelledKeys
      );
    }

    return state;
  };
};

export default createReducer;

export const getStatus = baseActionName => ({
  started: startedType(baseActionName),
  succeed: succeedType(baseActionName),
  failed: failedType(baseActionName),
  cancelled: cancelledType(baseActionName)
});
