import { useReducer } from 'react';
import { takeEvery } from 'redux-saga/effects';

import { useSaga } from './useSaga.js';

export function useGlobalActionsReducer(
  reducer,
  initialArg,
  init,
  globalActions
) {
  const [state, dispatch] = useReducer(reducer, initialArg, init);

  // TODO: Saga approach for this task can cause performance issue because takeEvery calls fork on each action.
  // So test it and if performance issue exist, then replace usesaga with own midleware
  // that just collects action to dispaches map and calls matched dispatches.
  useSaga(function* () {
    yield takeEvery(globalActions, function* (action) {
      dispatch(action);
    });
  });

  return [state, dispatch];
}
