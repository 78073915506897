import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { profileForm } from '../services/profileForm';
import { updateUserProfileBasic } from '../shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { CurrentUser } from 'features/shared/services/session/types';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

const useSaveUserProfile = () => {
  const i18n = useI18n();
  const dispatch = useDispatch();

  const currentUser: CurrentUser = useSelector(sessionSelectors.getCurrentUser);
  const currentCustomerId: string = useSelector(
    sessionSelectors.getCurrentUserCustomerId
  );
  const accessToken: string = useSelector(sessionSelectors.getAuth0AccessToken);

  const saveUserProfile = async () => {
    const profileFormValues = profileForm.getState().values;
    const userProfileBasic = {
      user_profile_basic: {
        name: profileFormValues.name,
        timezone: profileFormValues.timezone,
        bio: profileFormValues.bio,
        phone: profileFormValues.phone,

        country_id: currentUser.countryId,
        culture_code: currentUser.cultureCode
      }
    };

    try {
      await updateUserProfileBasic(
        currentCustomerId,
        accessToken,
        currentUser.id,
        userProfileBasic
      );

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n('profile.updateUserProfileBasicSuccessMessage'),
          type: NotificationTypes.success
        })
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('profile.updateUserProfileBasicErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return saveUserProfile;
};

export default useSaveUserProfile;
