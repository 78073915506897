import classNames from 'classnames';
import { isNil } from 'ramda';
import { useSelector } from 'react-redux';

import { CostChartSummary } from './types';
import {
  costForm,
  useCostForm
} from 'features/roboAdvice/adviceSession/proposal/services/costForm';
import Icon from 'features/shared/components/icon';
import SimpleTable from 'features/shared/components/table/simpleTable';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import {
  getNumberFromLocaleStringNumber,
  getNumberInputFormat
} from 'features/shared/utils/number';
import FinalFormNumberInput from 'features/sharedModules/finalForm/components/numberInput';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 'calc(3rem + 3px)',
    paddingTop: 30,
    paddingBottom: Spacing.spacing01,
    fontWeight: FontWeights.medium,
    fontSize: Typography.heading1,
    lineHeight: '23px'
  },
  editIcon: {
    cursor: 'pointer'
  },
  tableContainer: {
    flexGrow: 1,
    marginRight: '3rem',
    border: `1px solid transparent`
  },
  tableContainerEditMode: {
    border: `1px solid ${theme.accentColor}`
  },
  headerCell: {
    backgroundColor: theme.itemBackgroundColor_1,
    color: theme.secondaryColor,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '14px'
  },
  cell: {
    height: 59,
    lineHeight: '150%',
    padding: '2rem 2.5rem !important',

    '&:not(:first-child)': {
      textAlign: 'right'
    }
  },
  withIcon: {
    '&:first-child': {
      width: 60
    },

    '&:nth-child(2)': {
      textAlign: 'left'
    }
  },
  highlightedRow: {
    backgroundColor: theme.inputFillFocusColor
  },
  input: {
    width: 90,
    textAlign: 'right',
    padding: `${Spacing.spacing00}px ${Spacing.spacing01}px`
  },
  originalValue: {
    color: theme.disabledTextColor,
    marginRight: 10,
    textDecoration: 'line-through'
  }
}));

type Props = {
  tableData?: CostChartSummary[keyof CostChartSummary];
  originalTableData?: CostChartSummary[keyof CostChartSummary];
  headerTitles: string[];
  dataKeys: string[];
  sectionHeader: string;
  highlightLastRow?: boolean;
  withIcon?: boolean;
  isEditModeActive?: boolean;
  isAnyEditModeActive?: boolean;
  setIsEditModeActive?: (value: boolean) => void;
  overrideCosts?: string[];
  decimalScale?: number;
};

const CostSummarySection = ({
  tableData,
  headerTitles,
  dataKeys,
  sectionHeader,
  highlightLastRow,
  withIcon,
  isEditModeActive,
  isAnyEditModeActive,
  setIsEditModeActive,
  overrideCosts,
  originalTableData,
  decimalScale = 2
}: Props) => {
  const classes = useStyles();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const { values } = useCostForm();
  const { thousandSeparator, decimalSeparator } =
    getNumberInputFormat(cultureCode);

  const handleStartEditCost = () => {
    costForm.batch(() => {
      tableData?.forEach(({ name, value, percent }) => {
        if (name && (percent || value)) {
          costForm.change(
            `overrideCost.${name}` as any,
            getNumberFromLocaleStringNumber(percent ?? value, cultureCode)
          );
        }
      });
    });
    setIsEditModeActive?.(true);
  };

  return (
    <>
      {!!tableData?.length &&
        tableData.every(item => !isNil(item.percent) || !isNil(item.value)) && (
          <>
            <div className={classes.tableHeader}>
              <span>{sectionHeader}</span>

              {overrideCosts &&
                overrideCosts?.length > 0 &&
                !isAnyEditModeActive && (
                  <Icon
                    className={classes.editIcon}
                    type="edit"
                    onClick={handleStartEditCost}
                  />
                )}
            </div>

            <div
              className={classNames(classes.tableContainer, {
                [classes.tableContainerEditMode]:
                  isEditModeActive && overrideCosts && overrideCosts?.length > 0
              })}
            >
              <SimpleTable
                headers={() => (
                  <>
                    {headerTitles.map((title, index) => (
                      <th
                        key={index}
                        className={classNames(classes.cell, classes.headerCell)}
                      >
                        {title}
                      </th>
                    ))}
                  </>
                )}
                body={({ rowClassName }) => (
                  <>
                    {tableData.map((data, index) => (
                      <tr key={index} className={classNames(rowClassName)}>
                        {dataKeys.map((dataKey, dataKeyIndex) => (
                          <td
                            key={dataKey}
                            className={classNames(classes.cell, {
                              [classes.withIcon]: withIcon,
                              [classes.highlightedRow]:
                                highlightLastRow &&
                                index === tableData.length - 1
                            })}
                          >
                            {isEditModeActive &&
                            overrideCosts?.includes(data.field) &&
                            dataKeyIndex === dataKeys.length - 1 ? (
                              <FinalFormNumberInput
                                additionalInputComponent={
                                  values.overrideCost?.[data.name ?? ''] !==
                                  getNumberFromLocaleStringNumber(
                                    originalTableData?.[index]?.[dataKey] ??
                                      data[dataKey],
                                    cultureCode
                                  ) ? (
                                    <span className={classes.originalValue}>
                                      {originalTableData?.[index]?.[dataKey] ||
                                        data[dataKey]}
                                      {dataKey === 'percent' && '%'}
                                    </span>
                                  ) : null
                                }
                                allowNegative={false}
                                className={classes.input}
                                decimalScale={decimalScale}
                                decimalSeparator={decimalSeparator}
                                isErrorMessageHidden
                                name={`overrideCost.${data.name}`}
                                suffix={dataKey === 'percent' ? '%' : undefined}
                                thousandSeparator={thousandSeparator}
                              />
                            ) : (
                              <>
                                {!isNil(
                                  originalTableData?.[index]?.[dataKey]
                                ) &&
                                  originalTableData?.[index]?.[dataKey] !==
                                    data[dataKey] && (
                                    <span className={classes.originalValue}>
                                      {originalTableData?.[index]?.[dataKey]}
                                      {dataKey === 'percent' && '%'}
                                    </span>
                                  )}
                                {data[dataKey]}
                                {dataKey === 'value' &&
                                  isNil(data[dataKey]) &&
                                  !isNil(data.percent) &&
                                  `${data.percent}%`}
                                {dataKey === 'percent' && '%'}
                              </>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                )}
              />
            </div>
          </>
        )}
    </>
  );
};

export default CostSummarySection;
