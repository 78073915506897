import classNames from 'classnames';
import React from 'react';

import Icon from 'features/shared/components/icon/index.js';
import { Colors } from 'features/shared/constants/colors.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { Typography } from 'features/shared/constants/typography';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const borderRadius = {
  [BorderRadiusTypes.rounded]: '50px',
  [BorderRadiusTypes.sharp]: '5px'
};

const useStyles = createUseStyles(theme => ({
  notification: {
    minWidth: '32rem',
    borderRadius: borderRadius[theme.borderRadiusType],
    padding: '1.2rem 2.4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: `0px 0px 8px ${Colors.blue50_07}`,

    '& + &': {
      marginTop: '1.6rem'
    }
  },
  message: {
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight
  },
  successNotification: {
    border: `2px solid ${theme.successColor}`,
    backgroundColor: theme.boxBackgroundColor_1
  },
  errorNotification: {
    border: `2px solid ${theme.errorNegativeColor}`,
    backgroundColor: theme.boxBackgroundColor_1
  },
  warningNotification: {
    border: `2px solid ${theme.warningColor}`,
    backgroundColor: theme.boxBackgroundColor_1
  },
  infoNotification: {
    border: `2px solid ${theme.disabledBackgroundColor}`,
    backgroundColor: theme.boxBackgroundColor_1
  },
  hideable: {
    cursor: 'pointer'
  },
  icon: {
    marginLeft: '2.4rem',
    fontSize: '1.7rem',
    transform: 'rotate(0.03deg)' // fix of issue: icon small size causes pixelated icon
  },
  successIcon: {
    color: theme.successColor
  },
  errorIcon: {
    color: theme.errorNegativeColor
  },
  warningIcon: {
    color: theme.warningColor
  },
  infoIcon: {
    color: theme.disabledBackgroundColor
  }
}));

const getIconByType = type => {
  switch (type) {
    case NotificationTypes.success:
      return 'check_circle';
    case NotificationTypes.error:
      return 'error';
    case NotificationTypes.warning:
      return 'warning';
    case NotificationTypes.info:
      return 'info';
    default:
      return '';
  }
};

const getIconClassByType = (type, classes) => {
  switch (type) {
    case NotificationTypes.success:
      return classes.successIcon;
    case NotificationTypes.error:
      return classes.errorIcon;
    case NotificationTypes.warning:
      return classes.warningIcon;
    case NotificationTypes.info:
      return classes.infoIcon;
    default:
      return '';
  }
};

const getNotificationClassByType = (type, classes) => {
  switch (type) {
    case NotificationTypes.success:
      return classes.successNotification;
    case NotificationTypes.error:
      return classes.errorNotification;
    case NotificationTypes.warning:
      return classes.warningNotification;
    case NotificationTypes.info:
      return classes.infoNotification;
    default:
      return '';
  }
};

const Notification = ({ message, type, isHideEnabled, onHide }) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(
        classes.notification,
        getNotificationClassByType(type, classes),
        {
          [classes.hideable]: isHideEnabled
        }
      )}
      onClick={isHideEnabled ? onHide : null}
    >
      <span className={classes.message}>{message}</span>
      <Icon
        className={classNames(classes.icon, getIconClassByType(type, classes))}
        type={getIconByType(type)}
      />
    </div>
  );
};

export default Notification;
