import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { updateAdviceSession as updateAdviceSessionApi } from '../../main/api';
import { clientForm } from '../../main/services/clientForm';
import { useClientPageStore } from '../../main/services/clientPageStore';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useUpdateAdviceSession() {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);

  const dispatch = useDispatch();

  const updateAdviceSession = async adviceSessionId => {
    const clientPageStoreState = useClientPageStore.getState();
    const {
      values: { adviceSessions }
    } = clientForm.getState();

    if (adviceSessions == null) {
      return;
    }

    const adviceSession = adviceSessions.find(
      ({ id }) => id === adviceSessionId
    );

    if (!adviceSession) {
      return;
    }

    try {
      clientPageStoreState.setIsUpdateAdviceSessionPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        undefined
      );

      await updateAdviceSessionApi(accessToken, undefined, adviceSessionId, {
        name: adviceSession.name
      });

      clientPageStoreState.setIsUpdateAdviceSessionPending(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        clientPageStoreState.setIsUpdateAdviceSessionPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.client.updateAdviceSessionErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return updateAdviceSession;
}
