import classNames from 'classnames';
import React from 'react';

import ButtonGroup, { Props as ButtonGroupProps } from './index';
import { Typography } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  item: {
    fontSize: Typography.body3.size,
    lineHeight: Typography.body3.lineHeight,
    height: 'auto',
    padding: '5px 10px'
  },
  active: {},
  disabled: {},
  hover: {}
});

const SmallButtonGroup = ({
  className,
  itemClassName,
  activeItemClassName,
  disabledItemClassName,
  hoverItemClassName,
  ...restProps
}: ButtonGroupProps) => {
  const classes = useStyles();

  return (
    <ButtonGroup
      className={className}
      itemClassName={classNames(classes.item, itemClassName)}
      activeItemClassName={classNames(classes.active, activeItemClassName)}
      disabledItemClassName={classNames(
        classes.disabled,
        disabledItemClassName
      )}
      hoverItemClassName={classNames(classes.hover, hoverItemClassName)}
      {...restProps}
    />
  );
};

export default SmallButtonGroup;
