import React from 'react';

const SettingsContext = React.createContext({});

export const SettingsProvider = SettingsContext.Provider;

export function useI18n() {
  const { translations } = React.useContext(SettingsContext);

  return React.useMemo(
    () => (key, defaultValue = '') => translations[key] || defaultValue,
    [translations]
  );
}
