import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { SliderConfig } from '../../constants';
import { Layouts } from 'features/roboAdvice/shared/constants';
import ConfigurableLabel from 'features/shared/components/configurableLabel/';
import { Spacing } from 'features/shared/constants/spacing';
import sessionSelectors from 'features/shared/services/session/selectors';
import { getNumberInputFormat } from 'features/shared/utils/number';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import FinalFormNumberInput from 'features/sharedModules/finalForm/components/numberInput';
import FinalFormSlider from 'features/sharedModules/finalForm/components/slider';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  sliderSection: {
    marginTop: `calc(${Spacing.spacing01}px - 5px)`
  },
  rail: {
    borderRadius: '100px',
    marginTop: 0,
    height: 9
  },
  track: {
    borderRadius: '100px',
    marginTop: 0,
    height: 9
  },
  thumb: {
    backgroundColor: theme.primaryColor,
    height: 20,
    width: 20,

    '&:focus': {
      backgroundColor: theme.primaryColor
    },
    '&.Mui-disabled': {
      backgroundColor: theme.primaryColor
    }
  }
}));

const SliderTimeHorizon = ({
  inputClasses,
  disabled,
  inputClassName,
  sliderClassName,
  name,
  afterChange
}) => {
  const i18n = useI18n();
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const { thousandSeparator, decimalSeparator } =
    getNumberInputFormat(cultureCode);
  const classes = useStyles();

  const sliderClasses = React.useMemo(
    () => ({
      section: classNames(classes.sliderSection, sliderClassName),
      rail: classes.rail,
      track: classes.track,
      thumb: classes.thumb
    }),
    [classes, sliderClassName]
  );

  return (
    <>
      <FinalFormNumberInput
        data-testid={'time-horizon-input'}
        name={name}
        label={
          <ConfigurableLabel
            required
            label={i18n('roboAdvice.purposeAndRisk.timeHorizon')}
          />
        }
        suffix={` ${i18n('roboAdvice.purposeAndRisk.timeHorizonSuffix')}`}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        allowNegative={false}
        decimalScale={0}
        layout={Layouts.vertical}
        externalClasses={inputClasses}
        disabled={disabled}
        className={inputClassName}
        isAllowed={({ floatValue }) => floatValue <= SliderConfig.max}
        afterChange={afterChange}
      />

      <FinalFormSlider
        data-testid={'time-horizon-slider'}
        name={name}
        sliderConfig={SliderConfig}
        externalClasses={sliderClasses}
        disabled={disabled}
        afterChange={afterChange}
      />
    </>
  );
};

export default SliderTimeHorizon;
