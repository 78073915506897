import {
  createUseHistoricalReturnChartStore,
  HistoricalReturnChartData,
  HistoricalReturnTimeRangeChartData
} from '@quantfoliorepo/ui-components';
import axios, { CancelTokenSource } from 'axios';
import { format } from 'date-fns';
import { isNil, path } from 'ramda';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePortfolioChartStore } from '../../portfolioChart';
import {
  createHistoricalReturnBenchmark,
  createHistoricalReturnV2
} from '../../shared/api';
import { PageStatuses } from '../../shared/components/useReadDataListener';
import {
  availableHistoricalReturnStatisticsMapping,
  getExistingPortfolioForHistoricalReturnBenchmark
} from '../../shared/mapping';
import { PortfolioIds } from '../services/constants';
import {
  usePageStore as useAdvisoryPageStore,
  PageStatuses as AdvisoryPageStatuses
} from '../services/pageStore';
import { Goal } from './../../shared/services/goalsStore';
import { Theme } from 'defaults/defaultTheme';
import {
  getLookbackStartDate,
  useExistingPortfolio
} from 'features/roboAdvice/adviceSession/proposal/services/selectors';
import { useSessionStore } from 'features/roboAdvice/adviceSession/session/services/sessionStore';
import { useIsExistingPortfolioVisible } from 'features/roboAdvice/shared/components/useIsExistingPortfolioVisible';
import { getCurrentDate } from 'features/roboAdvice/shared/utils';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions';
import sessionSelectors from 'features/shared/services/session/selectors';
import { roundNumber } from 'features/shared/utils/number';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { useTheme } from 'features/sharedModules/styles/components/styles';

const getModelPortfolioColor = (
  theme: Theme,
  isPortfolioCustom: boolean | undefined,
  historicalReturnCompareWithBenchmark: boolean,
  portfoliosDataLength: number
) => {
  if (!isPortfolioCustom) {
    return theme.chartPortfolioColors[0];
  }

  if (historicalReturnCompareWithBenchmark) {
    return portfoliosDataLength > 1
      ? theme.chartPortfolioColors[3]
      : theme.chartPortfolioColors[2];
  }
  return theme.chartPortfolioColors[1];
};

export const useHistoricalReturnChartStore =
  createUseHistoricalReturnChartStore();

export const useReadHistoricalReturnData = (
  statusKey: keyof AdvisoryPageStatuses,
  timeRangeChartDataKey: keyof HistoricalReturnChartData,
  selectedGoal: Goal
) => {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const dispatch = useDispatch();
  const existingPortfolioData = useExistingPortfolio();
  const i18n = useI18n();
  const theme = useTheme();
  const {
    advisoryComponents: {
      customPortfolioModelPortfolioComparison,
      historicalReturnCompareWithBenchmark,
      historicalReturnBenchmarkType,
      statisticsTableEnabled
    },
    roboAdvice: {
      historicalReturn: { benchmarkType, useBenchmarkDataForPortfolios }
    }
  } = useCustomerConfig();
  const { isExistingPortfolioVisible } = useIsExistingPortfolioVisible();
  const { categories } = useSessionStore();
  const portfolioChartStore = usePortfolioChartStore();

  const readHistoricalReturnData = async () => {
    const { chartData } = usePortfolioChartStore.getState();

    if (isNil(chartData)) {
      return;
    }

    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }

    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;
    const advisoryPageStore = useAdvisoryPageStore.getState();
    const historicalReturnChartStore = useHistoricalReturnChartStore.getState();
    const sessionStoreState = useSessionStore.getState();

    try {
      advisoryPageStore.setPageStatus(statusKey, PageStatuses.pending);

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const currentDate = getCurrentDate();

      let portfoliosData: {
        color: string;
        id: string;
        name: string;
        portfolio: { id: string; weight: number }[];
      }[] = [];

      // existing portfolio
      if (existingPortfolioData && isExistingPortfolioVisible) {
        const existingPortfolio = existingPortfolioData?.[1].map(
          ({ name, weight }) => ({
            category_id: categories.find(
              ({ SubAssetClass }) => SubAssetClass === name
            )?.CategoryId,
            weight
          })
        );

        portfoliosData = [
          ...portfoliosData,
          {
            color: historicalReturnCompareWithBenchmark
              ? theme.chartPortfolioColors[2]
              : theme.chartPortfolioColors[1],
            id: PortfolioIds.existingPortfolio,
            name: i18n('roboAdvice.advisory.portfolio.existingPortfolio'),
            portfolio: existingPortfolio
          }
        ];
      }

      // custom portfolio
      if (
        selectedGoal.data.isPortfolioCustom &&
        selectedGoal.data.customPortfolio?.length
      ) {
        const customPortfolio = selectedGoal.data.customPortfolio
          .map(({ instruments }) =>
            instruments.map(({ id, weight }) => ({ id, weight }))
          )
          .flat()
          .filter(({ weight }) => weight && weight <= 100);
        portfoliosData = [
          ...portfoliosData,
          {
            color: theme.chartPortfolioColors[0],
            id: PortfolioIds.customPortfolio,
            name: i18n('roboAdvice.advisory.portfolio.customPortfolio'),
            portfolio: customPortfolio as { id: string; weight: number }[]
          }
        ];
      }

      // model portfolio
      if (
        !selectedGoal.data.isPortfolioCustom ||
        customPortfolioModelPortfolioComparison
      ) {
        const modelPortfolio = (
          selectedGoal.data.isPortfolioCustom
            ? portfolioChartStore.getSuggestedChartData(selectedGoal.goalId)
            : portfolioChartStore.getChartData(
                selectedGoal.goalId,
                selectedGoal.data.isPortfolioCustom
              )
        ).categorySelectionPortfolio.map(({ id, weight }) => ({
          id,
          weight
        }));

        const modelColor = getModelPortfolioColor(
          theme,
          selectedGoal.data.isPortfolioCustom,
          historicalReturnCompareWithBenchmark,
          portfoliosData.length
        );

        portfoliosData = [
          ...portfoliosData,
          {
            color: modelColor,
            id: selectedGoal.data.isPortfolioCustom
              ? PortfolioIds.suggestedPortfolio
              : PortfolioIds.modelPortfolio,
            portfolio: modelPortfolio,
            name: i18n(
              selectedGoal.data.isPortfolioCustom
                ? 'roboAdvice.advisory.portfolio.riskClassPortfolio'
                : 'roboAdvice.advisory.portfolio.modelPortfolio'
            )
          }
        ];
      }

      let historicalReturn: HistoricalReturnTimeRangeChartData[] = [];

      for (const { color, id, name, portfolio } of portfoliosData) {
        const sumOfPortfolioWeights = roundNumber(
          portfolio.reduce((acc, { weight }) => acc + weight, 0) || 0,
          2
        );

        if (portfolio.length && sumOfPortfolioWeights === 100) {
          let historicalReturnResponse;
          try {
            if (useBenchmarkDataForPortfolios) {
              const historicalReturnBenchmarkPortfolio =
                id !== PortfolioIds.existingPortfolio
                  ? portfolio
                  : await getExistingPortfolioForHistoricalReturnBenchmark({
                      portfolio,
                      namespaceId:
                        id === PortfolioIds.existingPortfolio
                          ? sessionStoreState.defaultConfigNamespaceId
                          : selectedGoal.data.productPlatformNamespace,
                      qAuthAccessToken,
                      cancelToken: cancelTokenSource.token
                    });

              historicalReturnResponse = await createHistoricalReturnBenchmark(
                qAuthAccessToken,
                cancelTokenSource.token,
                {
                  portfolio: historicalReturnBenchmarkPortfolio,
                  lookback: {
                    start_date: format(
                      getLookbackStartDate(timeRangeChartDataKey, currentDate),
                      'yyyy-MM-dd'
                    ),
                    end_date: format(currentDate, 'yyyy-MM-dd')
                  },
                  showStats: statisticsTableEnabled,
                  benchmarkType: benchmarkType
                },
                {
                  namespace_id:
                    id === PortfolioIds.existingPortfolio
                      ? sessionStoreState.defaultConfigNamespaceId
                      : selectedGoal.data.productPlatformNamespace
                }
              );
            } else {
              historicalReturnResponse = await createHistoricalReturnV2(
                qAuthAccessToken,
                cancelTokenSource.token,
                {
                  portfolio,
                  savingsplan: {
                    starting_capital: 100
                  },
                  lookback: {
                    start_date: format(
                      getLookbackStartDate(timeRangeChartDataKey, currentDate),
                      'yyyy-MM-dd'
                    ),
                    end_date: format(currentDate, 'yyyy-MM-dd')
                  },
                  showStats: statisticsTableEnabled
                },
                {
                  namespace_id:
                    id === PortfolioIds.existingPortfolio
                      ? sessionStoreState.defaultConfigNamespaceId
                      : selectedGoal.data.productPlatformNamespace
                }
              );
            }
          } catch (error) {
            continue;
          }

          const historicalReturnResponseData = historicalReturnResponse.data
            ?.portfolioStatistics
            ? {
                ...historicalReturnResponse.data,
                portfolioStatistics: Object.entries(
                  historicalReturnResponse.data.portfolioStatistics
                ).reduce((acc, [key, value]) => {
                  return {
                    ...acc,
                    [availableHistoricalReturnStatisticsMapping[key]]: value
                  };
                }, {})
              }
            : historicalReturnResponse.data;

          historicalReturn = [
            ...historicalReturn,
            {
              ...historicalReturnResponseData,
              goalId: id,
              goalName: name,
              color
            }
          ];
        }
      }

      // Historical return benchmark for a comparison
      if (historicalReturnCompareWithBenchmark) {
        const portfolio =
          portfoliosData.find(({ id }) => id === PortfolioIds.customPortfolio)
            ?.portfolio ||
          portfoliosData.find(({ id }) => id === PortfolioIds.modelPortfolio)
            ?.portfolio;

        const sumOfPortfolioWeights = roundNumber(
          portfolio?.reduce((acc, { weight }) => acc + weight, 0) || 0,
          2
        );

        if (portfolio?.length && sumOfPortfolioWeights === 100) {
          const historicalReturnBenchmarkResponse =
            await createHistoricalReturnBenchmark(
              qAuthAccessToken,
              cancelTokenSource.token,
              {
                portfolio,
                savingsplan: {
                  monthly_savings: 0,
                  starting_capital: 100
                },
                lookback: {
                  start_date: format(
                    getLookbackStartDate(timeRangeChartDataKey, currentDate),
                    'yyyy-MM-dd'
                  ),
                  end_date: format(currentDate, 'yyyy-MM-dd')
                },
                showStats: statisticsTableEnabled,
                benchmarkType: historicalReturnBenchmarkType
              },
              {
                namespace_id: selectedGoal.data.productPlatformNamespace
              }
            );

          const historicalReturnBenchmarkResponseData =
            historicalReturnBenchmarkResponse.data?.portfolioStatistics
              ? {
                  ...historicalReturnBenchmarkResponse.data,
                  portfolioStatistics: Object.entries(
                    historicalReturnBenchmarkResponse.data.portfolioStatistics
                  ).reduce((acc, [key, value]) => {
                    return {
                      ...acc,
                      [availableHistoricalReturnStatisticsMapping[key]]: value
                    };
                  }, {})
                }
              : historicalReturnBenchmarkResponse.data;

          historicalReturn = [
            ...historicalReturn,
            {
              ...historicalReturnBenchmarkResponseData,
              goalId: PortfolioIds.benchmarkPortfolio,
              goalName: i18n('roboAdvice.advisory.portfolio.benchmark'),
              color: theme.chartPortfolioColors[1]
            }
          ];
        }
      }

      historicalReturnChartStore.setTimeRangeChartData(
        timeRangeChartDataKey,
        historicalReturn
      );

      advisoryPageStore.setPageStatus(statusKey, PageStatuses.succeed);
    } catch (error) {
      if (!axios.isCancel(error)) {
        if (
          path(['response', 'status'], error) === 400 &&
          path(['response', 'data', 'qf-api-error-code'], error) === 416
        ) {
          historicalReturnChartStore.resetTimeRangeChartData(
            timeRangeChartDataKey
          );
          advisoryPageStore.setPageStatus(statusKey, PageStatuses.succeed);
        } else {
          historicalReturnChartStore.resetTimeRangeChartData(
            timeRangeChartDataKey
          );
          advisoryPageStore.setPageStatus(statusKey, PageStatuses.failed);

          dispatch(
            notificationActionCreators.showNotification({
              message: i18n('roboAdvice.proposal.readDataErrorMessage'),
              type: NotificationTypes.error
            })
          );

          throwSafeError(error);
        }
      }
    }
  };

  return readHistoricalReturnData;
};
