import classNames from 'classnames';
import { isNil } from 'ramda';
import { useSelector } from 'react-redux';

import SimpleTable from 'features/shared/components/table/simpleTable';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { formatNumber } from 'features/shared/utils/number';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  right: {
    textAlign: 'right'
  },
  cell: {
    height: 40,
    lineHeight: Typography.body1.lineHeight,
    padding: `0 ${Spacing.spacing01}px`
  }
});

const percentValueItemsIds = [
  'averageDownMonth',
  'averageDrawdown',
  'averageUpMonth',
  'bestMonth',
  'bestYear',
  'cagr',
  'maxDrawdown',
  'monthlyVol',
  'totalReturn',
  'twelveMonthWinPercent',
  'winYearPercent',
  'worstMonth',
  'worstYear',
  'yearlyVol',
  'ytd'
];

type HistoricalReturnStatisticsData = {
  id: string;
  label: string;
  values: (number | null)[];
};

type Props = {
  data: HistoricalReturnStatisticsData[];
  headerNames: string[];
};

const HistoricalReturnStatistics = ({ data, headerNames }: Props) => {
  const classes = useStyles();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const i18n = useI18n();

  return (
    <SimpleTable
      headers={({ headerCellClassName }) => (
        <>
          <th className={headerCellClassName}>
            {i18n('roboAdvice.adviceSession.statisticsTable.item')}
          </th>
          {headerNames.map(name => (
            <th
              className={classNames(headerCellClassName, classes.right)}
              key={name}
            >
              {name}
            </th>
          ))}
        </>
      )}
      body={({ rowClassName }) => (
        <>
          {data
            .filter(({ values }) => values.some(value => value !== null))
            .map(({ id, label, values }) => (
              <tr key={label} className={rowClassName}>
                <td className={classes.cell}>{label}</td>
                {values.map((value, index) => (
                  <td
                    key={index}
                    className={classNames(classes.cell, classes.right)}
                  >
                    {isNil(value)
                      ? '-'
                      : percentValueItemsIds.includes(id)
                      ? formatNumber(cultureCode, value * 100, 1, 1) + ' %'
                      : formatNumber(cultureCode, value, 1, 1)}
                  </td>
                ))}
              </tr>
            ))}
        </>
      )}
    />
  );
};

export default HistoricalReturnStatistics;
