import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import { form } from '../../../form/services/form';
import { MissingDocuments } from '../../../proposal/components/generateReportModal/types';
import { useOrderPageStore } from '../../services/orderPageStore';
import { useReadOrderExecutionCostData } from '../order/cost/useReadOrderExecutionCostData';
import { useReadOrderExecutionExpectedValueData } from '../order/expectedValue/useReadOrderExecutionExpectedValueData';
import { generateOrderForm } from './../../services/generateOrderForm';
import { createOrderPayload } from './../../services/mapping';
import { usePageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { useQueuedPatchAdviceSession } from 'features/roboAdvice/adviceSession/main/components/useQueuedPatchAdviceSession';
import { createPdfReport } from 'features/roboAdvice/adviceSession/proposal/api.js';
import { saveFile } from 'features/roboAdvice/adviceSession/proposal/utils';
import { useSessionStore } from 'features/roboAdvice/adviceSession/session/services/sessionStore';
import { readAnalyzeUniverseDocuments } from 'features/roboAdvice/adviceSession/shared/api';
import { UniverseDocumentFormat } from 'features/roboAdvice/adviceSession/shared/api/types';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { SessionPatchTypes } from 'features/roboAdvice/adviceSession/shared/constants';
import { useAdviceSessionStore } from 'features/roboAdvice/adviceSession/shared/services/adviceSessionStore';
import {
  RoboAdviceClientPages,
  SessionStatuses
} from 'features/roboAdvice/shared/constants';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import { AdviceSessionParams } from 'features/shared/constants/session';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { useTheme } from 'features/sharedModules/styles/components/styles';

const useDownloadPdfOrder = () => {
  const advisorName: string = useSelector(sessionSelectors.getCurrentUserName);
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const customerId = useSelector(sessionSelectors.getCurrentUserCustomerCode);
  const dispatch = useDispatch();
  const history = useHistory();
  const i18n = useI18n();
  const queuedPatchAdviceSession = useQueuedPatchAdviceSession();
  const readOrderExecutionCostData = useReadOrderExecutionCostData();
  const readOrderExecutionExpectedValueData =
    useReadOrderExecutionExpectedValueData();
  const theme = useTheme();
  const userName: string = useSelector(sessionSelectors.getCurrentUserName);
  const { loadPageLanguage } = usePageLanguage();
  const { customAttributesData } = useAdviceSessionStore();

  const { adviceSessionId, clientId } = useParams<AdviceSessionParams>();
  const { roboAdvice, roboOrderFlow, supportedLanguages } = useCustomerConfig();

  const language = loadPageLanguage()
    ? loadPageLanguage()
    : supportedLanguages.default;

  const downloadPdfOrder = async () => {
    const sessionStoreState = useSessionStore.getState();
    const { setIsReadPdfReportPending } = useOrderPageStore.getState();
    try {
      setIsReadPdfReportPending(true);

      const { values: generateOrderFormValues } = generateOrderForm.getState();
      const roboAdviceForm = form.getState();

      if (!isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
      const cancelTokenSource = axios.CancelToken.source();
      cancelTokenSourceRef.current = cancelTokenSource;

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );
      const clientType = roboAdviceForm.values.clientInformation?.clientType;

      if (generateOrderFormValues.includeReport) {
        const orderPageStoreState = useOrderPageStore.getState();

        if (
          orderPageStoreState.pageStatuses.readCostData !==
            PageStatuses.succeed &&
          generateOrderFormValues.analyticsComponents?.cost
        ) {
          await readOrderExecutionCostData();
        }

        if (
          orderPageStoreState.pageStatuses.readExpectedValueData !==
            PageStatuses.succeed &&
          generateOrderFormValues.analyticsComponents?.expectedValue
        ) {
          await readOrderExecutionExpectedValueData();
        }

        let universeDocuments: UniverseDocumentFormat[] = [];
        if (
          roboAdvice.orderExecution.enableProductDocuments &&
          generateOrderFormValues.productAttachments
        ) {
          const { data } = await readAnalyzeUniverseDocuments(
            accessToken,
            cancelTokenSource.token
          );

          universeDocuments = data
            .filter(document =>
              roboAdviceForm.values.instrumentsSelected?.some(
                ({ id, isin }) =>
                  (isin && document.isin === isin) ||
                  (id && document.ticker === id)
              )
            )
            .map(({ documents }) => documents[language ?? ''])
            .filter(Boolean);
        }

        const payload = createOrderPayload({
          advisorName,
          generateOrderFormValues,
          i18n,
          language,
          roboOrderFlow,
          customAttributesData: sessionStoreState.defaultConfigNamespaceId
            ? customAttributesData[sessionStoreState.defaultConfigNamespaceId]
            : [],
          orderPageStoreState: useOrderPageStore.getState(),
          roboAdvice,
          roboAdviceForm,
          theme,
          userName,
          universeDocuments
        });

        const response = await createPdfReport(
          accessToken,
          cancelTokenSource.token,
          customerId,
          payload,
          adviceSessionId,
          clientId,
          'orderExecution'
        );

        saveFile(
          new Blob([response.data], { type: 'application/pdf; charset=utf-8' }),
          'Report.pdf'
        );

        const missingDocuments: MissingDocuments =
          response.headers['missing-docs'] &&
          response.headers['missing-docs'] !== 'None'
            ? JSON.parse(response.headers['missing-docs'])
            : [];

        if (missingDocuments.length) {
          missingDocuments.forEach(({ isin, document_name }) => {
            dispatch(
              notificationActionCreators.showNotification({
                message: i18n(
                  'roboAdvice.proposal.generateReport.missingDocumentsErrorMessage'
                )
                  .replace('{0}', document_name)
                  .replace('{1}', isin),
                type: NotificationTypes.error
              })
            );
          });
        }

        if (sessionStoreState.status !== SessionStatuses.complete) {
          await queuedPatchAdviceSession({
            id: v4(),
            type: SessionPatchTypes.completeSession,
            adviceSessionId
          });

          sessionStoreState.completeSession();

          history.push(
            routeTemplates.roboAdviceClient.build(
              clientType,
              clientId,
              RoboAdviceClientPages.adviceSessions
            )
          );
        }
      } else {
        const response = await createPdfReport(
          accessToken,
          cancelTokenSource.token,
          customerId,
          {
            shouldGenerateReport: false
          },
          adviceSessionId,
          clientId,
          'orderExecution'
        );

        saveFile(
          new Blob([response.data], { type: 'application/pdf; charset=utf-8' }),
          'Report.pdf'
        );

        const missingDocuments: MissingDocuments =
          response.headers['missing-docs'] &&
          response.headers['missing-docs'] !== 'None'
            ? JSON.parse(response.headers['missing-docs'])
            : [];

        if (missingDocuments.length) {
          missingDocuments.forEach(({ isin, document_name }) =>
            dispatch(
              notificationActionCreators.showNotification({
                message: i18n(
                  'roboAdvice.proposal.generateReport.missingDocumentsErrorMessage'
                )
                  .replace('{0}', document_name)
                  .replace('{1}', isin),
                type: NotificationTypes.error
              })
            )
          );
        }
      }

      setIsReadPdfReportPending(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsReadPdfReportPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.proposal.generateReport.getPdfReportErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return downloadPdfOrder;
};

export default useDownloadPdfOrder;
