import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AdviceSessionGetResponse, getDeletedAdviceSessions } from '../api';
import {
  AdviceSession,
  useAdviceSessionsStore
} from '../services/adviceSessionsStore';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export function useReadAdviceSessions() {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const dispatch = useDispatch();
  const i18n = useI18n();

  const readAdviceSession = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const adviceSessionsStore = useAdviceSessionsStore.getState();

    try {
      adviceSessionsStore.setIsReadAdviceSessionsPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const response = await getDeletedAdviceSessions(
        accessToken,
        cancelTokenSource.token
      );
      const adviceSessions = mapServerAdviceSessionsToClient(
        response.data.data
      );

      adviceSessionsStore.setAdviceSessions(adviceSessions);

      adviceSessionsStore.setIsReadAdviceSessionsPending(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        adviceSessionsStore.setIsReadAdviceSessionsPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'admin.trashCan.adviceSessions.readAdviceSessionsErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (!isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
    };
  }, []);

  return readAdviceSession;
}

const mapServerAdviceSessionsToClient = (
  adviceSessions: AdviceSessionGetResponse[]
): AdviceSession[] => {
  return adviceSessions.map(
    ({
      session_id: id,
      name,
      client_name: clientName,
      advisor_name: advisorName,
      last_activity_date: lastActivityDate,
      deleted_at: softDeleteDate
    }) => {
      return {
        id,
        name,
        clientName,
        advisorName,
        lastActivityDate,
        softDeleteDate
      };
    }
  );
};
