import { z } from 'zod';

import { attachmentSchema, sectionSchema } from './shared';

export const orderExecutionTemplateSchema = z.object({
  allowProductAttachments: z.boolean(),
  attachments: z.array(attachmentSchema),
  filename: z.string(),
  id: z.string(),
  label: z.string(),
  language: z.string(),
  sections: z.array(sectionSchema),
  dataCollectorItems: z.array(
    z.object({
      id: z.string()
    })
  ),
  templateName: z.string()
});

export const reportOrderExecutionSchema = z.array(orderExecutionTemplateSchema);

export const defaultReportOrderExecution: z.infer<
  typeof reportOrderExecutionSchema
> = [
  {
    allowProductAttachments: false,
    attachments: [],
    filename: 'Wealthplan for {{investorName}} - {{yyyy}}.pdf',
    id: 'default_template',
    label: 'Default template',
    language: 'en',
    sections: [],
    dataCollectorItems: [],
    templateName: 'default_template.rml'
  }
];
