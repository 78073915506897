import { ValidationErrors } from 'final-form';
import setFieldTouched from 'final-form-set-field-touched';
import * as R from 'ramda';

import { WithdrawalPlans } from './../../shared/constants';
import store from 'features/main/app/store.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import {
  createForm as ffCreateForm,
  FormApi
} from 'features/sharedModules/finalForm/services/finalForm';
import {
  createUseFinalFormAdapter,
  FormState,
  UseFinalFormStore
} from 'features/sharedModules/zustand/components/finalFormAdapter';

export const getTimeHorizonValidationError = value => {
  if (R.isNil(value) || R.isEmpty(value)) {
    return 'roboAdvice.purposeAndRisk.timeHorizonEmptyValidationError';
  }

  if (value === 0) {
    return 'roboAdvice.purposeAndRisk.timeHorizonNegativeValidationError';
  }

  return null;
};

export type GoalFormValues = {
  name?: string;
  description?: string;
  icon?: string;
  data?: {
    timeHorizon?: number;
    withdrawalPlan?: number;
    monthlyWithdrawal?: number;
    withdrawalStart?: number;
    withdrawalEnd?: number;
    capitalNeed?: number;
    type?: string | null;
  };
};

export type GoalFormState = FormState<GoalFormValues>;

export type GoalForm = FormApi<GoalFormValues>;

export const goalForm = ffCreateForm<GoalFormValues>({
  onSubmit: () => {},
  validationDependencies: {
    customerConfig: [
      () => {
        const customerConfig = sessionSelectors.getCustomerConfig(
          store.getState()
        );
        return customerConfig;
      },
      store.subscribe
    ]
  },
  validate: (values, { customerConfig }) => {
    let errors: ValidationErrors = {};
    const {
      analyticsComponents: { isCashflowHidden }
    } = customerConfig;
    const { name, data } = values;

    if (R.isNil(name) || R.isEmpty(name)) {
      errors.name =
        'roboAdvice.purposeAndRisk.savingPurposeEmptyValidationError';
    }

    if (!isCashflowHidden) {
      if (R.isNil(data?.withdrawalPlan)) {
        errors = {
          ...errors,
          data: {
            withdrawalPlan:
              'roboAdvice.purposeAndRisk.withdrawalPlanEmptyValidationError'
          }
        };
      }

      if (data?.withdrawalPlan === WithdrawalPlans.monthlyWithdrawal) {
        if (!data?.monthlyWithdrawal) {
          errors = {
            ...errors,
            data: {
              monthlyWithdrawal:
                'roboAdvice.purposeAndRisk.monthlyWithdrawalValidationError'
            }
          };
        }

        if (
          (data?.withdrawalStart || 0) < 1000 ||
          (data?.withdrawalStart || 0) > 9999 ||
          (data?.withdrawalEnd || 0) < 1000 ||
          (data?.withdrawalEnd || 0) > 9999
        ) {
          errors = {
            ...errors,
            data: {
              ...errors.data,
              withdrawalDate:
                'roboAdvice.purposeAndRisk.withdrawalDateFormatValidationError'
            }
          };
        }

        if ((data?.withdrawalEnd || 0) <= (data?.withdrawalStart || 0)) {
          errors = {
            ...errors,
            data: {
              ...errors.data,
              withdrawalDate:
                'roboAdvice.purposeAndRisk.withdrawalDateValidationError'
            }
          };
        }
      }

      if (data?.withdrawalPlan === WithdrawalPlans.oneTimeWithdrawal) {
        if (!data?.capitalNeed) {
          errors = {
            ...errors,
            data: {
              capitalNeed:
                'roboAdvice.purposeAndRisk.capitalNeedValidationError'
            }
          };
        }
      }
    }

    const timeHorizonValidationError = getTimeHorizonValidationError(
      data?.timeHorizon
    );

    if (!R.isNil(timeHorizonValidationError)) {
      errors = {
        ...errors,
        data: { timeHorizon: timeHorizonValidationError }
      };
    }

    return errors;
  },
  mutators: {
    setFieldTouched
  }
});

export type UseGoalForm = UseFinalFormStore<GoalFormValues>;

export const useGoalForm = createUseFinalFormAdapter({
  form: goalForm
});
