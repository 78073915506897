import { useEffect } from 'react';

import { form as roboAdviceForm } from '../../form/services/form';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';

const useInitSustainability = () => {
  const {
    roboAdvice: {
      sustainability: {
        genericAssessment: {
          isValueAdaptedRecorded: isGenericAssessmentValueAdaptedRecorded
        } = {},
        sustainabilityPreference: {
          isValueAdaptedRecorded: isSustainabilityPreferenceValueAdaptedRecorded
        } = {},
        alignmentCriteria1,
        alignmentCriteria2,
        alignmentCriteria3,
        alignmentCriteria4,
        alignmentCriteria5
      }
    }
  } = useCustomerConfig();

  useEffect(() => {
    roboAdviceForm.batch(() => {
      if (!isGenericAssessmentValueAdaptedRecorded) {
        roboAdviceForm.change(
          'sustainability.genericAssessment.isValueAdaptedQuestionVisible' as any,
          undefined
        );
        roboAdviceForm.change(
          'sustainability.genericAssessment.isValueAdaptedAnswer' as any,
          undefined
        );
      }

      if (!isSustainabilityPreferenceValueAdaptedRecorded) {
        roboAdviceForm.change(
          'sustainability.preferenceCriteriaValueAdapted' as any,
          undefined
        );
      }

      const isAlignmentCriteria1ValueAdaptedRecorded =
        alignmentCriteria1?.config?.isValueAdaptedRecorded;
      const isAlignmentCriteria2ValueAdaptedRecorded =
        alignmentCriteria2?.config?.isValueAdaptedRecorded;
      const isAlignmentCriteria3ValueAdaptedRecorded =
        alignmentCriteria3?.config?.isValueAdaptedRecorded;
      const isAlignmentCriteria4ValueAdaptedRecorded =
        alignmentCriteria4?.config?.isValueAdaptedRecorded;
      const isAlignmentCriteria5ValueAdaptedRecorded =
        alignmentCriteria5?.config?.isValueAdaptedRecorded;
      if (!isAlignmentCriteria1ValueAdaptedRecorded) {
        roboAdviceForm.change(
          'sustainability.alignmentCriteriaValueAdapted.alignmentCriteria1' as any,
          undefined
        );
      }
      if (!isAlignmentCriteria2ValueAdaptedRecorded) {
        roboAdviceForm.change(
          'sustainability.alignmentCriteriaValueAdapted.alignmentCriteria2' as any,
          undefined
        );
      }
      if (!isAlignmentCriteria3ValueAdaptedRecorded) {
        roboAdviceForm.change(
          'sustainability.alignmentCriteriaValueAdapted.alignmentCriteria3' as any,
          undefined
        );
      }
      if (!isAlignmentCriteria4ValueAdaptedRecorded) {
        roboAdviceForm.change(
          'sustainability.alignmentCriteriaValueAdapted.alignmentCriteria4' as any,
          undefined
        );
      }
      if (!isAlignmentCriteria5ValueAdaptedRecorded) {
        roboAdviceForm.change(
          'sustainability.alignmentCriteriaValueAdapted.alignmentCriteria5' as any,
          undefined
        );
      }
    });
  }, []);
};

export default useInitSustainability;
