import classNames from 'classnames';
import * as R from 'ramda';
import * as React from 'react';

import Icon from 'features/shared/components/icon/index.js';
import ContextMenu from 'features/shared/components/popper/contextMenu.js';
import Popper from 'features/shared/components/popper/index.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  menuIcon: {
    color: theme.primaryColor,
    fontSize: '2rem',
    lineHeight: '2.4rem',
    cursor: 'pointer',

    '&$hidden': {
      display: 'none'
    }
  },
  hidden: {},
  tableMenuContainer: {
    zIndex: 3
  },
  tableMenu: {
    border: `2px solid ${theme.hoverInputStrokeColor}`
  }
}));

const TableRowMenu = ({
  tableRef,
  tableContainerRef,
  createMenuItems,
  isMenuIconHidden,
  setIdOpenModal,
  currentId
}) => {
  const classes = useStyles();

  return (
    <Popper
      popperProps={{
        placement: 'bottom-start',
        modifiers: {
          offset: {
            order: 825,
            fn: (data, options) => {
              const popper = R.path(['instance', 'popper'], data);
              const table = R.prop('current', tableRef);
              const tableContainer = R.prop('current', tableContainerRef);

              if (
                R.isNil(popper) ||
                R.isNil(table) ||
                R.isNil(tableContainer)
              ) {
                return data;
              }

              const popperBounds = popper.getBoundingClientRect();
              const tableBounds = table.getBoundingClientRect();
              const tableContainerBounds =
                tableContainer.getBoundingClientRect();

              const popperOffsetLeft =
                tableBounds.right -
                tableContainerBounds.left -
                popperBounds.width;

              return R.set(
                R.lensPath(['offsets', 'popper', 'left']),
                popperOffsetLeft,
                data
              );
            }
          }
        }
      }}
      reference={({ ref, isPopperOpen, togglePopper }) => (
        <Icon
          forwardedRef={ref}
          className={classNames(classes.menuIcon, {
            [classes.hidden]: isMenuIconHidden
          })}
          onClick={e => {
            e.stopPropagation();

            if (!isPopperOpen && setIdOpenModal && currentId) {
              setIdOpenModal(currentId);
            }
            togglePopper(!isPopperOpen);
          }}
          type="more_vert"
        />
      )}
      popper={({ ref, style, placement, isPopperOpen, togglePopper }) => {
        return (
          isPopperOpen && (
            <div
              ref={ref}
              style={style}
              data-placement={placement}
              className={classes.tableMenuContainer}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <ContextMenu
                classNames={{
                  menu: classes.tableMenu
                }}
                items={createMenuItems({ toggleMenu: togglePopper })}
              />
            </div>
          )
        );
      }}
    />
  );
};

export default TableRowMenu;
