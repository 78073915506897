import { pathOr } from 'ramda';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useExpectedPathChartStore } from '../../../expectedPathChart';
import { usePageStore } from '../../services/pageStore';
import { useReadExpectedPathChartData } from './useReadExpectedPathChartData';
import { useReadDataListener } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import {
  useGoalsStore,
  selectors
} from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import ExpectedPathChartCardVisual from 'features/shared/analyticsComponents/expectedPathChart/visual';
import sessionSelectors from 'features/shared/services/session/selectors';

const ExpectedPathChartCard = ({ isSelected }) => {
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const expectedPathChartStore = useExpectedPathChartStore();
  const selectedGoalsIds = useGoalsStore(selectors.getSelectedGoalsIds);
  const { pageStatuses } = usePageStore();

  useEffect(() => {
    expectedPathChartStore.setChartData(selectedGoalsIds);
  }, [expectedPathChartStore.goalsData, selectedGoalsIds]);

  useReadDataListener(
    pageStatuses.readExpectedPathChartData,
    isSelected,
    useReadExpectedPathChartData()
  );

  const expectedPathTableData = pathOr(
    [],
    ['chartData', 'expectedPathTable'],
    expectedPathChartStore
  );
  const expectedReturnChartData = pathOr(
    [],
    ['chartData', 'expectedReturn'],
    expectedPathChartStore
  );
  const boundsChartData = pathOr(
    [],
    ['chartData', 'bounds'],
    expectedPathChartStore
  );

  return (
    <ExpectedPathChartCardVisual
      cultureCode={cultureCode}
      expectedPathTableData={expectedPathTableData}
      expectedReturnChartData={expectedReturnChartData}
      boundsChartData={boundsChartData}
    />
  );
};

export default ExpectedPathChartCard;
