import { createRoot } from 'react-dom/client';
import ReactModal from 'react-modal';
import { Provider as ReactReduxProvider } from 'react-redux';
import { Route } from 'react-router-dom';

import { sagaMiddleware } from './sagaMiddleware.js';
import store from './store.js';
import config from 'config/index.js';
import App from 'features/main/components/index.js';
import Confirmation from 'features/shared/components/modal/confirmation';
import RandomErrorPage from 'features/shared/components/randomErrorPage';
import Router from 'features/shared/components/router/index.js';
import SagaMiddlewareContext from 'features/shared/contexts/sagaMiddlewareContext.js';
import history, {
  setConfirmationComponent
} from 'features/shared/utils/history.js';
import { CustomerConfigProvider } from 'features/sharedModules/customerConfig/components/index';
import { ErrorBoundary } from 'react-error-boundary';

ReactModal.setAppElement(`#${config.APP_ELEMENT_ID}`);

const ConfirmationWithContext = props => {
  return (
    <ErrorBoundary fallback={<RandomErrorPage />}>
      <ReactReduxProvider store={store}>
        <CustomerConfigProvider shouldRenderHelmet={false}>
          <Confirmation {...props} />
        </CustomerConfigProvider>
      </ReactReduxProvider>
    </ErrorBoundary>
  );
};

setConfirmationComponent(ConfirmationWithContext);

const container = document.getElementById(config.APP_ELEMENT_ID);
const root = createRoot(container!);
const render = () => {
  root.render(
    <ErrorBoundary fallback={<RandomErrorPage />}>
      <ReactReduxProvider store={store}>
        <SagaMiddlewareContext.Provider value={sagaMiddleware}>
          <Router history={history}>
            <Route component={App} />
          </Router>
        </SagaMiddlewareContext.Provider>
      </ReactReduxProvider>
    </ErrorBoundary>
  );
};

export default render;
