import React from 'react';
import { useHistory, useParams } from 'react-router';
import { Route, Redirect } from 'react-router-dom';

import {
  appAdminConfigLocations,
  appAdminConfigLocationsValues
} from '../constants';
import { usePageStyles } from './style';
import AdvisorSolution from 'features/admin/appAdmin/advisorSolution/main/components/index';
import { appAdminAdvisorSolutionConfigTypesValues } from 'features/admin/appAdmin/advisorSolution/main/constants';
import { Tabs, Tab } from 'features/shared/components/tabs';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

const AppAdmin = () => {
  const history = useHistory();
  const { configLocation } = useParams();
  const classes = usePageStyles();
  const i18n = useI18n();

  const onChangeTab = (e, newValue) => {
    history.push(routeTemplates.appAdmin.build(newValue));
  };

  return (
    <>
      <Route
        exact
        path={routeTemplates.appAdmin.build(
          appAdminConfigLocationsValues.advisorSolution
        )}
        render={() => (
          <Redirect
            to={routeTemplates.appAdmin.build(
              appAdminConfigLocationsValues.advisorSolution,
              appAdminAdvisorSolutionConfigTypesValues.visuals
            )}
          />
        )}
      />
      <div className={classes.header}>{i18n('admin.appAdmin')}</div>

      <Tabs
        rootClassName={classes.tabs}
        value={configLocation}
        onChange={onChangeTab}
      >
        {appAdminConfigLocations.map(({ value, label }) => (
          <Tab
            rootClassName={classes.tab}
            key={value}
            value={value}
            label={i18n(label)}
          />
        ))}
      </Tabs>

      {configLocation === appAdminConfigLocationsValues.advisorSolution && (
        <AdvisorSolution />
      )}
    </>
  );
};

export default AppAdmin;
