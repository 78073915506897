import React from 'react';

import { useTranslationsStore } from '../services/translationsStore';
import { usePageStyles } from './tableStyle';
import useTranslations from './useTranslations';
import { translationsTable } from 'features/admin/appAdmin/advisorSolution/translationsTable';
import TableBody from 'features/shared/components/table/tableBody';
import TableBodyRow from 'features/shared/components/table/tableBodyRow';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import FinalFormTextarea from 'features/sharedModules/finalForm/components/textarea';
import TableComponent from 'features/sharedModules/table/components/table';

const Table = ({ isAdminModeActive }) => {
  const translationsStore = useTranslationsStore();
  const { items, totalItemsNumber } = useTranslations();
  const classes = usePageStyles();
  const isSearchModeActive = translationsStore.isSearchModeActive;
  const i18n = useI18n();

  const header = [
    {
      title: i18n(
        'admin.appAdmin.advisorSolution.text.table.header.description'
      ),
      orderKey: 'description',
      isOrderable: !isSearchModeActive,
      className: isAdminModeActive ? '' : classes.descriptionHeaderRowCell
    },
    {
      title: i18n('admin.appAdmin.advisorSolution.text.table.header.default'),
      orderKey: 'default',
      isOrderable: !isSearchModeActive
    }
  ];

  return (
    <TableComponent
      module={translationsTable}
      header={
        isAdminModeActive
          ? [
              {
                title: 'Text_id',
                orderKey: 'name',
                isOrderable: !isSearchModeActive
              },
              ...header
            ]
          : [
              ...header,
              {
                title: i18n(
                  'admin.appAdmin.advisorSolution.text.table.header.yourConfig'
                ),
                className: classes.yourConfigurationHeaderRowCell
              }
            ]
      }
      totalItemsNumber={totalItemsNumber}
      isPaginationShown={true}
      isLoading={translationsStore.isReadTranslationsPending}
    >
      <TableBody
        noDataTitle={i18n(
          isSearchModeActive
            ? 'admin.appAdmin.advisorSolution.text.table.noMatchingTranslations'
            : 'admin.appAdmin.advisorSolution.text.table.noTranslations'
        )}
        cellsNumber={3}
      >
        {items.map(translation => (
          <TableBodyRow key={translation.name}>
            {({ bodyRowCellClassName }) => (
              <>
                {isAdminModeActive && (
                  <td className={bodyRowCellClassName}>{translation.name}</td>
                )}
                <td className={bodyRowCellClassName}>
                  {isAdminModeActive ? (
                    <FinalFormTextarea
                      name={`${translation.name.replaceAll(
                        '.',
                        '-'
                      )}.description`}
                      minRows={1}
                    />
                  ) : (
                    translation.description
                  )}
                </td>

                <td className={bodyRowCellClassName}>
                  {isAdminModeActive ? (
                    <FinalFormTextarea
                      name={`${translation.name.replaceAll('.', '-')}.default`}
                      minRows={1}
                    />
                  ) : (
                    translation.default
                  )}
                </td>

                {!isAdminModeActive && (
                  <td className={bodyRowCellClassName}>
                    <FinalFormTextarea
                      placeholder={i18n(
                        'admin.appAdmin.advisorSolution.text.table.yourConfigPlaceholder'
                      )}
                      name={`${translation.name.replaceAll('.', '-')}.value`}
                      minRows={1}
                    />
                  </td>
                )}
              </>
            )}
          </TableBodyRow>
        ))}
      </TableBody>
    </TableComponent>
  );
};

export default Table;
