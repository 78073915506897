import { dissoc, pathOr, pipe } from 'ramda';

import { NamespaceConfig } from '../../advisory/types';
import { AdviceSessionFormValues } from '../../form/services/form';
import { OrderSummaryData } from '../../orderSummary';
import { PortfolioType } from '../../portfolioChart';
import { TimeHorizonTypes } from '../../purposeAndRisk/constants';
import { UniverseDocumentFormat } from '../../shared/api/types';
import { ProductAttributePDF } from '../../shared/mapping';
import { Goal } from '../../shared/services/goalsStore';
import { getCustomFieldsValues } from '../../shared/services/mapping';
import { SustainabilityData } from '../../sustainability';
import { getAutomaticClientClassification } from './kycMapping';
import {
  getClientInformation,
  getFinancialSituation,
  getGeneralInformation,
  getKnowledgeAndExperience,
  getProductPlatformData,
  getAdvancedSuitability,
  getSustainabilityData,
  AdvancedSuitabilityTableRow
} from './mappingSelectors';
import {
  AdviceInformationField,
  ClientInformation,
  FinancialSituationFields,
  KnowledgeAndExperienceQuestions,
  PersonFinancialSituation,
  PortfolioChartStoreState,
  Liquidity,
  CompanyFinancialSituation,
  CashflowGoal,
  CashflowGoalChartData,
  HistoricalReturn,
  Cost,
  CashflowTableData,
  ExpectedValueChartData,
  ExpectedPathChartData,
  FinancialSituation,
  CashflowForecastChartData,
  AnalyticsComponents,
  SustainabilityDataReport,
  ProductPlatformData,
  ProductPlatformConfig
} from './mappingTypes';
import { AnalyticsComponents as AnalyticsComponentsConfig } from 'defaults/defaultAnalyticsComponents';
import { RoboAdviceForm } from 'defaults/defaultRoboAdviceForm';
import { HorizonConfig } from 'defaults/defaultTimeHorizonConfig';
import { ReadAnalyzeAdvancedSuitabilityConfigResponse } from 'features/roboAdvice/adviceSession/shared/api/types';
import { Contact } from 'features/roboAdvice/client/main/services/clientForm';
import { tableType } from 'features/shared/analyticsComponents/sustainability/constants';
import { ClientTypes } from 'features/shared/constants/session';
import { FormState } from 'features/sharedModules/zustand/components/finalFormAdapter';

export type PdfReportInput = {
  i18n: (key: string) => string;
  adviceInformationFields: Record<string, any>[];
  cultureCode: string;
  userName: string;
  roboAdviceForm: FormState<AdviceSessionFormValues>;
  roboAdvice: Record<string, any>;
  generateReportFromValues: {
    analyticsComponents: {
      all: boolean;
      investmentPlan: boolean;
      portfolio: {
        broadAssetAllocation: boolean;
        assetAllocation: boolean;
        fundAllocation: boolean;
      };
      expectations: boolean;
      expectedPath: boolean;
      expectedValue: boolean;
      costValue: boolean;
      historicalReturn: boolean;
    };
    otherDocuments: {
      [key: string]: boolean;
    };
    morningstarAttachments: {
      [key: string]: boolean;
    };
    productAttachments: boolean;
  };
  maxRiskScore: number;
  goals: Goal[];
  timeHorizonConfig: HorizonConfig;
  cashflowGoals: CashflowGoal[];
  cashflowGoalChartsData: CashflowGoalChartData[];
  cashflowForecastChartData: CashflowForecastChartData[];
  cashflowTableData: CashflowTableData[];
  cost: Cost;
  expectedPathChartData: ExpectedPathChartData;
  expectedValueChartData: ExpectedValueChartData;
  portfolioChartStoreState: PortfolioChartStoreState;
  knowledgeAndExperienceQuestions: KnowledgeAndExperienceQuestions;
  financialSituation: FinancialSituation;
  historicalReturn: HistoricalReturn;
  orderSummaryData?: OrderSummaryData[];
  orderSummaryProductAttributes?: ProductAttributePDF[];
  sustainabilityData?: SustainabilityData[];
  sustainabilityTableType?: typeof tableType[keyof typeof tableType];
  kycData?: Record<string, any>;
  universeDocuments: UniverseDocumentFormat[];
  automaticClientClassification?: ReturnType<
    typeof getAutomaticClientClassification
  >;
  contacts?: Contact[] | null;
  personAssetsFields?: FinancialSituationFields;
  personDebtFields?: FinancialSituationFields;
  companyAssetsFields?: FinancialSituationFields;
  companyDebtFields?: FinancialSituationFields;
  companyLiquidityFields?: FinancialSituationFields;
  personLiquidityFields?: Liquidity[];
  companyAccountingFiguresFields?: FinancialSituationFields;
  chartPortfolioColors?: string[];
  chartEmptyColor?: string;
  secondaryColor?: string;
  language: string | null;
  productPlatformNamespaces: NamespaceConfig[];
  theme: Record<string, any>;
  isContactListEnabled: boolean;
  isSignatureRightsEnabled: boolean;
  isUBOEnabled: boolean;
  isCustomPortfolioAssetClassAllocationAdvisorNotesEnabled: boolean;
  isCustomPortfolioFundAllocationAdvisorNotesEnabled: boolean;
  advancedSuitabilityConfig: RoboAdviceForm['knowledgeAndExperience']['advancedSuitability'];
  productPlatformConfig?: ProductPlatformConfig[];
  advancedSuitabilityStoreConfig: ReadAnalyzeAdvancedSuitabilityConfigResponse | null;
  expectedValueConfig: AnalyticsComponentsConfig['expectedValue'];
};

type PdfReportPayload = {
  chartColorsConfig: {
    expectedPath: {
      expectedReturnColor: string;
      boundsColor: string;
      labelsColor: string;
    };
    expectedValue: {
      savingPlanColor: string;
      savingInBankAccountColor: string;
      labelsColor: string;
    };
    cost: {
      costColor: string;
      valueAfterCostColor: string;
      labelsColor: string;
    };
    historicalReturn: {
      labelsColor: string;
      errorNegativeColor: string;
      successColor: string;
    };
  };
  clientInformation: ClientInformation;
  adviceInformation: AdviceInformationField[];
  adviceInformationNotes: string | undefined;
  generalInformation: {
    name: string;
  };
  purposeAndRisk: {
    maxRiskScore: number;
    expectationOfRisk?: number;
    riskStrategy?: number;
  };
  purposeAndRiskAdvisorNotes: string | undefined;
  knowledgeAndExperience: {
    question: string;
    answers: string[];
    trainingDate: string | null;
  }[];
  knowledgeAndExperienceAdvisorNotes: string | undefined;
  advancedSuitability:
    | { [key: string]: AdvancedSuitabilityTableRow[] }
    | undefined
    | null;
  personLiquidity?: Liquidity[];
  personFinancialSituation?: PersonFinancialSituation;
  personFinancialSituationAssessment?: {
    id: string;
    title: string;
    status: string | null;
    value: string | null;
  }[];
  companyFinancialSituation?: CompanyFinancialSituation;
  companyFinancialSituationAssessment?: {
    id: string;
    title: string;
    status: string | null;
    value: string | null;
  }[];
  expectingFinancialChanges: boolean | null | undefined;
  expectingFinancialChangesNote: string | null;
  financialSituationAdvisorNotes: string | undefined;
  advisoryAdvisorNotes: string;
  expectedValueConfig: AnalyticsComponentsConfig['expectedValue'];
  chartData: {
    expectedAnnualReturn: number;
    expectedVolatility: number;
    equityPortion: number;
    goalId: string;
    goalName: string;
    goalIcon: string;
    categoryMainAssetClassPortfolio: {
      id: string;
      isin: string | null;
      name: string;
      weight: number;
    }[];
    categorySubAssetClassPortfolio: {
      id: string;
      isin: string | null;
      name: string;
      weight: number;
    }[];
    categorySelectionPortfolio: {
      id: string;
      isin: string | null;
      name: string;
      weight: number;
    }[];
  }[];
  goals: Goal[];
  cashflowGoals: CashflowGoal[];
  cashflowGoalChartsData: CashflowGoalChartData[];
  cashflowForecastChartData: CashflowForecastChartData[];
  cashflowTableData: CashflowTableData[];
  cost: Cost;
  expectedPathChartData: ExpectedPathChartData;
  expectedValueChartData: ExpectedValueChartData;
  historicalReturn: HistoricalReturn;
  orderSummaryData?: OrderSummaryData[];
  orderSummaryProductAttributes?: ProductAttributePDF[];
  sustainabilityData?: SustainabilityDataReport;
  kyc?: Record<string, any>;
  automaticClientClassification?: ReturnType<
    typeof getAutomaticClientClassification
  >;
  contacts?: Contact[];
  analyticsComponents: AnalyticsComponents;
  otherDocuments: { [key: string]: boolean };
  morningstarAttachments: number[];
  otherDocumentsData: {
    name: string;
    email: string;
    ssn: string;
    number: string | undefined;
    advisorName: string;
    clientName: string;
    address: string | undefined;
    totalCostPortfolio: number;
    riskModel: string | null;
    portfolios: { portfolio: string; goalName: string }[];
  };
  isins: (string | null)[];
  productAttachments: UniverseDocumentFormat[] | null;
  emailData: {
    name: string;
    email: string | undefined;
    phoneNumber: string | null;
  };
  productPlatformData: ProductPlatformData[];
  language: string | null;
};

export const mapClientUserDataToReport = ({
  i18n,
  adviceInformationFields,
  cultureCode,
  userName,
  roboAdviceForm,
  roboAdvice,
  generateReportFromValues,
  maxRiskScore,
  goals,
  timeHorizonConfig,
  knowledgeAndExperienceQuestions,
  financialSituation: customerConfigFinancialSituation,
  theme,
  cashflowGoals,
  cashflowGoalChartsData,
  cashflowForecastChartData,
  cashflowTableData,
  cost,
  expectedPathChartData,
  expectedValueChartData,
  portfolioChartStoreState,
  historicalReturn,
  orderSummaryData,
  orderSummaryProductAttributes,
  sustainabilityData,
  sustainabilityTableType,
  kycData,
  universeDocuments,
  automaticClientClassification,
  contacts,
  language,
  productPlatformNamespaces,
  isContactListEnabled,
  isSignatureRightsEnabled,
  isUBOEnabled,
  isCustomPortfolioAssetClassAllocationAdvisorNotesEnabled,
  isCustomPortfolioFundAllocationAdvisorNotesEnabled,
  advancedSuitabilityConfig,
  productPlatformConfig,
  advancedSuitabilityStoreConfig,
  expectedValueConfig
}: PdfReportInput): PdfReportPayload => {
  const generalInformation = getGeneralInformation(
    roboAdviceForm?.values?.clientInformation,
    userName
  );

  const financialSituation = getFinancialSituation({
    i18n,
    cultureCode,
    clientType: roboAdviceForm.values.clientInformation.clientType,
    roboAdviceForm,
    financialSituation: customerConfigFinancialSituation
  });

  const preparedGoals = goals.map(goal => {
    const namespace = productPlatformNamespaces.find(
      p => p.namespace_id === goal.data.productPlatformNamespace
    );
    return {
      ...goal,
      data: {
        ...goal.data,
        productPlatformNamespaceName: namespace ? i18n(namespace.labelKey) : '',
        assetClassAllocationAdvisorNotes:
          isCustomPortfolioAssetClassAllocationAdvisorNotesEnabled &&
          roboAdviceForm.values.customPortfolio?.assetClassAllocation
            ?.advisorNotes?.[goal.goalId],
        fundAllocationAdvisorNotes:
          isCustomPortfolioFundAllocationAdvisorNotesEnabled &&
          roboAdviceForm.values.customPortfolio?.fundAllocation?.advisorNotes?.[
            goal.goalId
          ],
        analysisProjectionYearsValue:
          timeHorizonConfig.type === TimeHorizonTypes.radio
            ? timeHorizonConfig.items.find(
                i => i.riskHorizonValue === goal.data.timeHorizon
              )?.analysisProjectionYearsValue
            : goal.data.timeHorizon
      }
    };
  });

  const isAnyMorningstarDocumentSelected = Object.values(
    generateReportFromValues.morningstarAttachments || {}
  ).some(field => field === true);

  const totalCostPortfolio = cost.tableData?.summary?.annualOngoingFees
    ?.totalPortfolioFee
    ? cost.tableData.summary.annualOngoingFees.totalPortfolioFee * 100
    : 0;

  return {
    ...financialSituation,
    adviceInformation: getCustomFieldsValues({
      fields: adviceInformationFields,
      i18n,
      form: roboAdviceForm
    }),
    adviceInformationNotes:
      roboAdviceForm?.values?.adviceInformationAdvisorNotes,
    chartColorsConfig: {
      expectedPath: {
        expectedReturnColor: theme.chartPortfolioColors[0],
        boundsColor: theme.chartPortfolioColors[0],
        labelsColor: theme.secondaryColor
      },
      expectedValue: {
        savingPlanColor: theme.chartPortfolioColors[0],
        savingInBankAccountColor: theme.chartPortfolioColors[1],
        labelsColor: theme.secondaryColor
      },
      cost: {
        costColor: theme.chartPortfolioColors[1],
        valueAfterCostColor: theme.chartPortfolioColors[0],
        labelsColor: theme.secondaryColor
      },
      historicalReturn: {
        labelsColor: theme.secondaryColor,
        errorNegativeColor: theme.errorNegativeColor,
        successColor: theme.successColor
      }
    },
    clientInformation: getClientInformation(
      i18n,
      roboAdviceForm?.values?.clientInformation,
      roboAdvice.clientInformation,
      isContactListEnabled,
      isSignatureRightsEnabled,
      isUBOEnabled
    ),
    generalInformation: {
      name: generalInformation.name
    },
    purposeAndRisk: {
      maxRiskScore: maxRiskScore,
      expectationOfRisk: roboAdviceForm?.values?.expectationOfRisk,
      riskStrategy: roboAdviceForm?.values?.riskStrategy
    },
    purposeAndRiskAdvisorNotes:
      roboAdviceForm?.values?.purposeAndRiskAdvisorNotes,
    knowledgeAndExperience: getKnowledgeAndExperience(
      roboAdviceForm.values,
      knowledgeAndExperienceQuestions,
      cultureCode,
      i18n,
      language || 'en'
    ),
    advancedSuitability: getAdvancedSuitability(
      roboAdviceForm.values,
      advancedSuitabilityConfig,
      advancedSuitabilityStoreConfig
    ),
    knowledgeAndExperienceAdvisorNotes:
      roboAdviceForm.values?.knowledgeAndExperienceAdvisorNotes,
    expectingFinancialChanges:
      roboAdviceForm.values?.clientInformation?.clientType ===
      ClientTypes.person
        ? roboAdviceForm.values?.personFinancialSituation
            ?.expectingFinancialChanges
        : roboAdviceForm.values?.companyFinancialSituation
            ?.expectingFinancialChanges,
    expectingFinancialChangesNote:
      roboAdviceForm.values?.clientInformation?.clientType ===
      ClientTypes.person
        ? roboAdviceForm.values?.personFinancialSituation
            ?.expectingFinancialChangesNote
        : roboAdviceForm.values?.companyFinancialSituation
            ?.expectingFinancialChangesNote,
    financialSituationAdvisorNotes:
      roboAdviceForm.values?.financialSituationAdvisorNotes,
    advisoryAdvisorNotes: roboAdviceForm?.values?.advisoryAdvisorNotes || '',
    expectedValueConfig,
    chartData: portfolioChartStoreState.chartData.filter(
      chartData => chartData.portfolioType !== PortfolioType.suggested
    ),
    goals: preparedGoals,
    cashflowGoals,
    cashflowGoalChartsData,
    cashflowForecastChartData,
    cashflowTableData,
    cost,
    expectedPathChartData,
    expectedValueChartData,
    historicalReturn,
    orderSummaryData,
    orderSummaryProductAttributes,
    sustainabilityData: getSustainabilityData({
      i18n,
      language: language || 'en',
      sustainability: roboAdvice.sustainability,
      sustainabilityData,
      sustainabilityFormValues: roboAdviceForm.values?.sustainability,
      sustainabilityTableType
    }),
    kyc: kycData,
    automaticClientClassification,
    contacts: contacts || [],
    analyticsComponents: pipe(
      () => generateReportFromValues.analyticsComponents,
      dissoc('all')
    )(),
    morningstarAttachments: Object.entries(
      generateReportFromValues.morningstarAttachments || {}
    )
      .filter(([key, value]) => value)
      .map(([key]) => Number(key.split('_')[1])),
    otherDocuments: pipe(
      () => generateReportFromValues.otherDocuments,
      dissoc('all'),
      dissoc('kiid')
    )(),
    otherDocumentsData: {
      name: generalInformation.name,
      number: generalInformation.number,
      advisorName: generalInformation.advisorName,
      email: generalInformation.advisorName,
      ssn: generalInformation.ssn,
      clientName: generalInformation.clientName,
      address: generalInformation.address,
      totalCostPortfolio,
      riskModel: goals[0]?.data?.riskScore || null,
      portfolios: goals
        .map(goal => ({
          portfolio: goal.data.portfolio || '',
          goalName: goal.name
        }))
        .reduce((acc, curr) => {
          const index = acc.findIndex(
            ({ portfolio }) => portfolio === curr.portfolio
          );

          if (index === -1) {
            return [...acc, curr];
          }

          return acc.map((goal, i) =>
            i === index
              ? {
                  portfolio: goal.portfolio,
                  goalName: `${goal.goalName}, ${curr.goalName}`
                }
              : goal
          );
        }, [] as { portfolio: string; goalName: string }[])
    },
    isins: isAnyMorningstarDocumentSelected
      ? Array.from(
          new Set(
            portfolioChartStoreState.chartData
              .filter(
                chartData => chartData.portfolioType !== PortfolioType.suggested
              )
              .map(chartData =>
                chartData.categorySelectionPortfolio.map(p => p.isin)
              )
              .flat()
              .filter(p => p && p.toLowerCase() !== 'missing')
          )
        )
      : [],
    productAttachments: generateReportFromValues.productAttachments
      ? universeDocuments
      : null,
    emailData: {
      name: generalInformation.name,
      email: generalInformation.email,
      phoneNumber: generalInformation.phoneNumber
    },
    productPlatformData: getProductPlatformData({
      goals,
      productPlatformConfig,
      language,
      i18n
    }),
    language
  };
};
