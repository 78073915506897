import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getDeletedInvestors } from '../api';
import { useClientsStore } from '../services/clientsStore';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useReadClients() {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const readClients = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const clientsStore = useClientsStore.getState();

    try {
      clientsStore.setIsReadClientsPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const { data: deletedInvestorsResponse } = await getDeletedInvestors(
        accessToken,
        cancelTokenSource.token
      );

      const deletedInvestors = deletedInvestorsResponse.map(investor => ({
        id: investor.id,
        type: investor.investorType,
        name: investor.name,
        advisorName: investor.advisorName ?? '',
        lastActivityDate: investor.lastActivityDate,
        softDeleteDate: investor.deletedAt
      }));

      clientsStore.setClients(deletedInvestors);
      clientsStore.setIsReadClientsPending(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        clientsStore.setIsReadClientsPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('admin.trashCan.clients.readClientsErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (!isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
    };
  }, []);

  return readClients;
}
