import { ValidationErrors } from 'final-form';

import {
  createForm,
  FormApi
} from 'features/sharedModules/finalForm/services/finalForm';
import {
  createUseFinalFormAdapter,
  FormState,
  UseFinalFormStore
} from 'features/sharedModules/zustand/components/finalFormAdapter';

export type FundAllocationFormValues = {
  fundAllocation: {
    [CategoryId: string]: {
      [instrumentId: string]: number;
    };
  };
};

export type FundAllocationFormState = FormState<FundAllocationFormValues>;

export type FundAllocationForm = FormApi<FundAllocationFormValues>;

export const fundAllocationForm = createForm<FundAllocationFormValues>({
  onSubmit: () => {},
  validate: values => {
    let errors: ValidationErrors = {};

    return errors;
  }
});

export type UseFundAllocationForm = UseFinalFormStore<FundAllocationFormValues>;

export const useFundAllocationForm = createUseFinalFormAdapter({
  form: fundAllocationForm,
  formSubscription: {
    values: true,
    errors: true,
    touched: true,
    submitFailed: true
  }
});
