import axios from 'axios';
import { isNil } from 'ramda';
import * as React from 'react';

import { getCompanyData } from 'features/roboAdvice/client/main/api';
import { clientForm } from 'features/roboAdvice/client/main/services/clientForm';
import { isValidMOD11 } from 'features/roboAdvice/client/shared/utils';
import { CountryCode } from 'features/roboAdvice/shared/constants';
import { throwSafeError } from 'features/shared/utils/throwSafeError';

const useReadCompanyData = () => {
  const cancelTokenSourceRef = React.useRef<any>();

  const readCompanyData = async (value: number) => {
    const { values } = clientForm.getState();
    const valueString = value ? value.toString() : '';

    if (
      valueString.length === 9 &&
      values.clientInformation?.country?.toLowerCase() === CountryCode.Norway &&
      isValidMOD11(valueString)
    ) {
      if (!isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
      const cancelTokenSource = axios.CancelToken.source();
      cancelTokenSourceRef.current = cancelTokenSource;

      try {
        const { data } = await getCompanyData(
          valueString,
          cancelTokenSource.token
        );

        const {
          orgNumber = '',
          companyName = '',
          postCity = '',
          zipCode = '',
          address = ''
        } = mapServerCompanyDataToClient(data);

        clientForm.change<any>('clientInformation', {
          ...values.clientInformation,
          orgNumber: orgNumber,
          companyName: companyName,
          city: postCity,
          zipCode: zipCode,
          address: address.join(', ')
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          throwSafeError(error);
        }
      }
    }
  };

  return readCompanyData;
};

export default useReadCompanyData;

const mapServerCompanyDataToClient = ({
  organisasjonsnummer,
  navn,
  forretningsadresse: { postnummer, poststed, adresse }
}) => ({
  orgNumber: organisasjonsnummer,
  companyName: navn,
  zipCode: postnummer,
  postCity: poststed,
  address: adresse
});
