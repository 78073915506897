import * as React from 'react';

import { TableModule } from '../types';
import { TableContext } from './context';
import TableComponent from 'features/shared/components/table/table.js';

// Temporary solution until types added to TableComponent
const AnyTableComponent = TableComponent as any;

type TableComponenetProps = {
  children: React.ReactNode;
  header: object[];
  isLoading?: boolean;
  forwardedTableRef?: React.Ref<any>;
  forwardedTableContainerRef?: React.Ref<any>;
  isPaginationShown?: boolean;
  isSelectionShown?: boolean;
  allRowIds?: string[];
  totalItemsNumber?: number;
  paginationProps?: Record<string, any>;
  headerRowRef?: (node: HTMLDivElement | null) => void;
};

type TableProps = {
  module: TableModule;
} & TableComponenetProps;

const Table = ({ module, children, ...restProps }: TableProps) => {
  const tableContextValue = React.useMemo(() => ({ module }), [module]);
  const { useTableStore } = module;
  const tableStore = useTableStore();

  return (
    <TableContext.Provider value={tableContextValue}>
      <AnyTableComponent
        orderDirection={tableStore.orderDirection}
        orderKey={tableStore.orderKey}
        changeOrder={tableStore.changeOrder}
        pageNumber={tableStore.pageNumber}
        changePageNumber={tableStore.changePageNumber}
        pageSize={tableStore.pageSize}
        changePageSize={tableStore.changePageSize}
        selectedRowIds={tableStore.selectedRowIds}
        changeAllRowsSelection={(allRowIds, isSelected) => {
          tableStore.changeAllRowsSelection(isSelected, allRowIds);
        }}
        {...restProps}
      >
        {children}
      </AnyTableComponent>
    </TableContext.Provider>
  );
};

export default Table;
