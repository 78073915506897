import { ReactComponent as AllocationMethodAmountIcon } from 'assets/allocationMethodAmount.svg';
import { ReactComponent as AllocationMethodPercentIcon } from 'assets/allocationMethodPercent.svg';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  changeAllocationIcon: {
    aspectRatio: '1/1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,

    '& svg path': {
      fill: theme.secondaryColor,
      transition: 'fill 0.2s'
    }
  }
}));

export const ChangeAllocationIconType = {
  percentage: 'percentage',
  amount: 'amount'
} as const;

type Props = {
  type: keyof typeof ChangeAllocationIconType;
};

const ChangeAllocationIcon = ({
  type = ChangeAllocationIconType.percentage
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.changeAllocationIcon}>
      {type === ChangeAllocationIconType.percentage && (
        <AllocationMethodPercentIcon />
      )}
      {type === ChangeAllocationIconType.amount && (
        <AllocationMethodAmountIcon />
      )}
    </div>
  );
};

export default ChangeAllocationIcon;
