import React from 'react';
import { useParams } from 'react-router';

import Preview from '../../preview/components/index';
import Proposal from '../../proposal/components/index';
import Text from '../../text/components/index.js';
import Visuals from '../../visuals/components/index';
import { appAdminAdvisorSolutionConfigTypesValues } from '../constants';
import Navigation from './navigation';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  container: {
    display: 'flex'
  },
  content: {
    flex: '1'
  }
});

const AdvisorSolution = () => {
  const classes = useStyles();
  const { configType } = useParams<any>();

  return (
    <div className={classes.container}>
      <Navigation />

      <div className={classes.content}>
        {configType === appAdminAdvisorSolutionConfigTypesValues.visuals && (
          <Visuals />
        )}
        {configType === appAdminAdvisorSolutionConfigTypesValues.text && (
          <Text />
        )}
        {configType === appAdminAdvisorSolutionConfigTypesValues.preview && (
          <Preview />
        )}
        {configType === appAdminAdvisorSolutionConfigTypesValues.proposal && (
          <Proposal />
        )}
      </div>
    </div>
  );
};

export default AdvisorSolution;
