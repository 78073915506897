import create from 'zustand';

import { PageStatus } from '../../shared/components/useReadDataListener';
import { ReadAnalyzeAdvancedSuitabilityStatusV2Response } from 'features/roboAdvice/adviceSession/shared/api/types';

export type TargetMarketAssessmentStoreData = {
  advancedSuitabilityData: ReadAnalyzeAdvancedSuitabilityStatusV2Response | null;
  advancedSuitabilityDataStatus: PageStatus | undefined;
};

export type TargetMarketAssessmentStoreApi = {
  reset: () => void;
  initialize: (initialData: Partial<TargetMarketAssessmentStoreData>) => void;
  setAdvancedSuitabilityData: (
    data: ReadAnalyzeAdvancedSuitabilityStatusV2Response | null
  ) => void;
  setAdvancedSuitabilityDataStatus: (status: PageStatus) => void;
};

export type TargetMarketAssessmentStoreState = TargetMarketAssessmentStoreData &
  TargetMarketAssessmentStoreApi;

export const defaultData = {
  advancedSuitabilityData: null,
  advancedSuitabilityDataStatus: undefined
};

export const useTargetMarketAssessmentStore =
  create<TargetMarketAssessmentStoreState>(set => ({
    ...defaultData,
    reset: () => {
      set(defaultData);
    },
    initialize(initialData) {
      set(
        state => ({
          ...state,
          ...initialData
        }),
        true
      );
    },
    setAdvancedSuitabilityData(data) {
      set(state => ({
        ...state,
        advancedSuitabilityData: data
      }));
    },
    setAdvancedSuitabilityDataStatus(status) {
      set(state => ({
        ...state,
        advancedSuitabilityDataStatus: status
      }));
    }
  }));
