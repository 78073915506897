import classNames from 'classnames';
import * as R from 'ramda';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';

import { ReactComponent as DefaultLogo } from '../../../../assets/logo.svg';
import { getUserPageLanguage } from '../../../pageLanguage/main/components/usePageLanguage';
import { useSessionStore } from '../../../sharedModules/session/services/sessionStore';
import CrmLoginGuard from '../crmLoginGuard';
import NavigationDropdown from './navigationDropdown.js';
import ProfileDropdown from './profileDropdown';
import { PaddingBottom } from './shared.js';
import config from 'config/index.js';
import { appAdminAdvisorSolutionConfigTypesValues } from 'features/admin/appAdmin/advisorSolution/main/constants';
import { appAdminConfigLocationsValues } from 'features/admin/appAdmin/main/constants';
import { trashCanPagesValues } from 'features/admin/trashCan/shared/constants';
import { RoboAdviceClientsListPages } from 'features/roboAdvice/shared/constants';
import Link from 'features/shared/components/link/index.js';
import Notification from 'features/shared/components/notification/connectedNotification.js';
import { LayoutBreakpoints } from 'features/shared/constants/layoutBreakpoints';
import { UserPermissions } from 'features/shared/constants/session';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { processImgURL } from 'features/shared/utils/processImgURL.ts';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { defaultConfirmation } from 'features/sharedInstances/defaultConfirmation';
import Confirmation from 'features/sharedModules/confirmation/components/confirmation';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  // ie flexbox bug fix
  pageContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  page: {
    backgroundColor: theme.pageBackgroundColor,
    minHeight: '100vh'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.defaultInputStrokeColor}`
  },
  logo: {
    flex: '0 0 auto',
    lineHeight: '0',
    cursor: 'pointer',
    maxWidth: '250px'
  },
  logoImage: {
    width: '100%',
    height: 'auto'
  },
  navigation: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'flex-start',
    lineHeight: Typography.heading1.lineHeight,
    margin: `${Spacing.spacing02}px 0 ${Spacing.spacing02}px ${Spacing.spacing04}px`,
    fontWeight: FontWeights.medium
  },
  navigationWeight: {
    fontWeight: FontWeights.medium,
    padding: `0 ${Spacing.spacing03}px 0 ${Spacing.spacing02}px`
  },
  navigationLink: {
    color: theme.secondaryColor,

    '&$active': {
      color: theme.accentColor
    },

    '& + &': {
      marginLeft: Spacing.spacing02
    },

    '$navigationMenu + &': {
      marginLeft: Spacing.spacing00
    }
  },
  navigationMenu: {
    '& + &': {
      marginLeft: 0
    }
  },
  active: {},
  body: {
    paddingBottom: PaddingBottom
  },
  [`@media (max-width: ${LayoutBreakpoints.desktopMin}px)`]: {
    page: {
      padding: `0 ${Spacing.spacing02}px`
    },
    logo: {
      maxWidth: '150px'
    }
  },
  [`@media (min-width: ${LayoutBreakpoints.desktopMin + 0.1}px)`]: {
    page: {
      padding: '0 9.6rem'
    }
  }
}));

export const MainLayout = ({ children, location }) => {
  const i18n = useI18n();
  const classes = useStyles();
  const theme = useTheme();
  const currentUser = useSelector(sessionSelectors.getCurrentUser);
  const isAdvisorSolutionLicenseValid = useSelector(
    sessionSelectors.getIsAdvisorSolutionLicenseValid
  );
  const isCustomerAdmin = useSelector(sessionSelectors.getIsCustomerAdmin);
  const {
    settingsApplication: { enabled: isSettingsApplicationEnabled },
    tools: { isToolTabHidden }
  } = useCustomerConfig();

  const headerRef = React.useRef();

  const getIsActive = pathname => R.startsWith(pathname, location.pathname);
  const getNavigationLinkClassName = pathname =>
    classNames(classes.navigationLink, {
      [classes.active]: getIsActive(pathname)
    });

  const currentUserPermissions = currentUser.permissions || [];
  const currentUserHasReadCustomersPermission = R.includes(
    UserPermissions.readCustomers,
    currentUserPermissions
  );
  const currentUserHasUpdateDashboardPermissions = R.includes(
    UserPermissions.updateDashboard,
    currentUserPermissions
  );
  const currentUserHasReadUserFeedbackPermission = R.includes(
    UserPermissions.readUserFeedback,
    currentUserPermissions
  );
  const currentUserHasRunProcessingPermissions = R.includes(
    UserPermissions.updateProcessingTasksRun,
    currentUserPermissions
  );

  const { permissions: userPermissions } = useSessionStore.getState();
  const language = getUserPageLanguage();

  const adminNavOptions = useMemo(() => {
    const options = [
      {
        key: 'app_admin',
        permissions: [
          'qsa:translations:update',
          'qsa:translations:update-scoped'
        ],
        to: routeTemplates.appAdmin.build(
          appAdminConfigLocationsValues.advisorSolution,
          appAdminAdvisorSolutionConfigTypesValues.visuals
        ),
        title: i18n('admin.appAdmin')
      },
      {
        key: 'trash_can',
        permissions: ['qra:state_session:admin'],
        to: routeTemplates.adminTrashCan.build(trashCanPagesValues.clients),
        title: i18n('admin.trashCan')
      },
      {
        key: 'manage_customer',
        to: routeTemplates.adminManageCustomer.build(),
        title: i18n('admin.userManagement')
      }
    ];

    const filteredOptions = options.filter(
      el =>
        el.permissions?.some(p => userPermissions?.includes(p)) ||
        !el.permissions
    );

    return isSettingsApplicationEnabled
      ? [
          ...filteredOptions,
          {
            key: 'redirect_to_settings',
            redirect: config.QS_FRONT_URL,
            title: i18n('admin.settings')
          }
        ]
      : filteredOptions;
  }, [isSettingsApplicationEnabled, userPermissions, language]);

  return (
    <div className={classes.pageContainer}>
      <div className={classes.page}>
        <CrmLoginGuard>
          <Notification refToAlign={headerRef} />
          <Confirmation module={defaultConfirmation} />
          <div className={classes.header} ref={headerRef}>
            <ReactRouterLink
              to={routeTemplates.home.build()}
              className={classes.logo}
            >
              {theme.logoSrc ? (
                <img
                  className={classes.logoImage}
                  src={processImgURL(theme.logoSrc)}
                  alt="logo"
                />
              ) : (
                <DefaultLogo className={classes.logoImage} />
              )}
            </ReactRouterLink>
            <div className={classes.navigation}>
              {isAdvisorSolutionLicenseValid && (
                <Link
                  to={routeTemplates.roboAdviceClientsList.build(
                    RoboAdviceClientsListPages.allClients
                  )}
                  className={getNavigationLinkClassName(
                    routeTemplates.roboAdvice.build()
                  )}
                >
                  {i18n('navigation.advisory')}
                </Link>
              )}
              {!isToolTabHidden && (
                <Link
                  to={routeTemplates.tools.build('start')}
                  className={getNavigationLinkClassName(
                    routeTemplates.tools.build('')
                  )}
                >
                  {i18n('navigation.tools')}
                </Link>
              )}
              {isCustomerAdmin && (
                <NavigationDropdown
                  className={classes.navigationMenu}
                  inputProps={{
                    title: i18n('shared.admin'),
                    isActive: getIsActive(routeTemplates.admin.build()),
                    className: classes.navigationWeight
                  }}
                  options={adminNavOptions}
                />
              )}
              {currentUserHasReadCustomersPermission && (
                <Link
                  to={routeTemplates.customersTable.build()}
                  className={getNavigationLinkClassName(
                    routeTemplates.customers.build()
                  )}
                >
                  {i18n('navigation.customers')}
                </Link>
              )}
              {currentUserHasReadUserFeedbackPermission && (
                <Link
                  to={routeTemplates.userFeedbacksTable.build()}
                  className={getNavigationLinkClassName(
                    routeTemplates.userFeedbacks.build()
                  )}
                >
                  {i18n('navigation.usersFeedback')}
                </Link>
              )}
              {currentUserHasUpdateDashboardPermissions && (
                <Link
                  to={routeTemplates.editDashboard.build()}
                  className={getNavigationLinkClassName(
                    routeTemplates.editDashboard.build()
                  )}
                >
                  {i18n('navigation.editDashboard')}
                </Link>
              )}
              {currentUserHasRunProcessingPermissions && (
                <Link
                  to={routeTemplates.runProcessing.build()}
                  className={getNavigationLinkClassName(
                    routeTemplates.runProcessing.build()
                  )}
                >
                  {i18n('navigation.runProcessing')}
                </Link>
              )}
            </div>
            <ProfileDropdown />
          </div>
          <div className={classes.body}>{children}</div>
        </CrmLoginGuard>
      </div>
    </div>
  );
};

export const MainLayoutContainer = ({ children }) => {
  const location = useLocation();

  return <MainLayout location={location}>{children}</MainLayout>;
};

export default MainLayoutContainer;
