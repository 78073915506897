import * as React from 'react';
import { useHistory, useParams } from 'react-router';

import AdviceSessions from '../../adviceSessions/components/index';
import Clients from '../../clients/components/index';
import { trashCanPagesValues, trashCanPages } from '../../shared/constants';
import { usePageStyles } from './style';
import { Tabs, Tab } from 'features/shared/components/tabs';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

const TrashCan = () => {
  const history = useHistory();
  const { pageId } = useParams<any>();
  const classes = usePageStyles();
  const i18n = useI18n();

  const onChangeTab = (e, newValue) => {
    history.push(routeTemplates.adminTrashCan.build(newValue));
  };

  return (
    <>
      <div className={classes.headerContainer}>
        <div className={classes.header}>{i18n('admin.trashCan')}</div>
      </div>
      <Tabs rootClassName={classes.tabs} value={pageId} onChange={onChangeTab}>
        {trashCanPages.map(({ value, label }) => (
          <Tab
            rootClassName={classes.tab}
            key={value}
            value={value}
            label={i18n(label)}
          />
        ))}
      </Tabs>
      {pageId === trashCanPagesValues.clients && <Clients />}
      {pageId === trashCanPagesValues.adviceSessions && <AdviceSessions />}
    </>
  );
};

export default TrashCan;
