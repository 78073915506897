import { PortfolioChart } from '@quantfoliorepo/ui-components';

import useMocks from './useMocks';
import SmallButtonGroup from 'features/shared/components/buttonGroup/smallButtonGroup';
import { getColor } from 'features/shared/utils/colors.js';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import {
  useTheme,
  createUseStyles
} from 'features/sharedModules/styles/components/styles';

const AnySmallButtonGroup = SmallButtonGroup as any;
const AnyPortfolioChart = PortfolioChart as any;

const useStyles = createUseStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'center'
  },
  body: {
    paddingTop: '40px'
  }
});

const MockPortfolioChart = ({ isSelected }: { isSelected?: boolean }) => {
  const classes = useStyles();
  const theme: any = useTheme();
  const i18n = useI18n();
  const { mockChartData } = useMocks();

  const mockChartDataWithColors = mockChartData.map((m, index) => ({
    ...m,
    color: getColor(theme.chartPortfolioColors, index, mockChartData.length)
  }));

  return (
    <>
      <div className={classes.toolbar}>
        <AnySmallButtonGroup
          items={[
            {
              title: i18n('roboAdvice.proposal.broadAssetAllocation'),
              isActive: false,
              onClick: () => {}
            },
            {
              title: i18n('roboAdvice.proposal.assetAllocation'),
              isActive: true,
              onClick: () => {}
            },
            {
              title: i18n('roboAdvice.proposal.fundAllocation'),
              isActive: false,
              onClick: () => {}
            }
          ]}
        />
      </div>
      <div className={classes.body}>
        <AnyPortfolioChart
          cultureCode={'en'}
          chartData={mockChartDataWithColors}
          width={280}
          height={200}
          dataLabelsColor={theme.primaryColor}
          backgroundSpacingColor={theme.boxBackgroundColor_1}
        />
      </div>
    </>
  );
};

export default MockPortfolioChart;
