import { reducer as customerManagementPageReducer } from 'features/customers/customerManagementPage/services/reducers/status.js';
import { reducer as customersTablePageReducer } from 'features/customers/customersTablePage/services/reducers/status.js';
import { reducer as sessionReducer } from 'features/shared/services/session/reducers/status.js';
import createStatusReducer from 'features/shared/services/status/reducer.js';
import { reducer as userProfileBasicPageReducer } from 'features/userProfile/services/reducers/status';

const reducer = createStatusReducer({
  ...sessionReducer,
  ...customersTablePageReducer,
  ...customerManagementPageReducer,
  ...userProfileBasicPageReducer
});

export default reducer;
