const lastAddedSessionHighlightKey = 'lastAddedSessionHighlight';

export const setLastAddedSessionHighlight = (sessionId: string) => {
  localStorage.setItem(lastAddedSessionHighlightKey, sessionId);
};

export const getLastAddedSessionHighlight = (): string | null => {
  return localStorage.getItem(lastAddedSessionHighlightKey);
};

export const removeLastAddedSessionHighlight = () => {
  localStorage.removeItem(lastAddedSessionHighlightKey);
};
