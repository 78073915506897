import queryString from 'query-string';
import { forEach, isNil, isEmpty } from 'ramda';
import * as R from 'ramda';
import { matchPath } from 'react-router';
import { createSelector } from 'reselect';

import { routeTemplates } from 'features/shared/utils/routeTemplates';
import { globalizeSelectors } from 'framework/globalize/utils/index.js';
import { getLocation } from 'framework/router/services/selectors.js';

const getRouteData = (pathname, routeData, routeTemplates) => {
  if (routeData.isCompleted) {
    return;
  }

  forEach(rt => {
    if (routeData.isCompleted) {
      return;
    }

    let match = matchPath(pathname, { path: rt.config, exact: false });

    if (match != null) {
      if (match.isExact) {
        routeData.route = rt;
        routeData.match = match;
        routeData.isCompleted = true;

        return;
      } else if (rt.children != null) {
        getRouteData(pathname, routeData, rt.children);
      }
    }
  }, routeTemplates);
};

export const getRouteBase = (pathname, search) => {
  let routeData = {
    route: {},
    match: {
      params: {}
    }
  };

  if (pathname == null) {
    return routeData;
  }

  getRouteData(pathname, routeData, routeTemplates);

  routeData = {
    ...routeData,
    query: isNil(search) || isEmpty(search) ? {} : queryString.parse(search)
  };

  return routeData;
};

export const getRoute = createSelector(
  state => (state.location != null ? state.location.pathname : null),
  state => (state.location != null ? state.location.search : null),
  getRouteBase
);

export const getPrevRoute = createSelector(
  state => (state.prevLocation != null ? state.prevLocation.pathname : null),
  state => (state.location != null ? state.location.search : null),
  getRouteBase
);

export const getRouteFromLocation = createSelector(
  state => (state.location != null ? state.location.pathname : null),
  state => (state.location != null ? state.location.search : null),
  getRouteBase
);

export const getPrevRouteFromLocation = createSelector(
  state => (state.prevLocation != null ? state.prevLocation.pathname : null),
  state => (state.location != null ? state.location.search : null),
  getRouteBase
);

export const isRouteFound = state => !R.isNil(getRoute(state).route.id);

export default globalizeSelectors(['router_state'], {
  getLocation,
  getRoute,
  getPrevRoute,
  isRouteFound
});
