import classNames from 'classnames';

import Radio from 'features/shared/components/radio';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { getTranslation } from 'features/shared/utils/translations';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  row: {
    width: '100%',
    position: 'relative'
  },
  options: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  optionBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 2,

    '&:nth-child(1)': {
      background: `linear-gradient(to left, transparent 50%, ${theme.pageBackgroundColor} 50%)`
    },
    '&:last-child': {
      background: `linear-gradient(to left, ${theme.pageBackgroundColor} 50%, transparent 50%)`
    },

    '& svg': {
      border: 'none'
    }
  },
  label: {
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,
    fontWeight: FontWeights.normal,
    marginBottom: 30,
    whiteSpace: 'nowrap'
  },
  bar: {
    height: 6,
    width: '100%',
    backgroundColor: theme.accentColor,
    position: 'absolute',
    bottom: 10,
    zIndex: 0,
    overflow: 'hidden'
  },
  radio: {
    background: `linear-gradient(to right, transparent 0%, transparent 5%, ${theme.pageBackgroundColor} 5%, ${theme.pageBackgroundColor} 95%, transparent 95%)`
  },
  disabled: {
    opacity: 0.5
  }
}));

export type GranulationItem = {
  order: number;
  value: number;
  name: string;
  label: Record<string, string>;
};

export type Props = {
  items: GranulationItem[];
  onChange: (value: number) => void;
  afterChange?: (value: number) => void;
  value: number;
  disabled?: boolean;
  className?: string;
};

const GranulationBar = ({
  items,
  value,
  onChange,
  afterChange,
  disabled,
  className
}: Props) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(
        classes.row,
        {
          [classes.disabled]: disabled
        },
        className
      )}
    >
      <div className={classes.options}>
        {items.map(i => (
          <div className={classes.optionBox} key={i.name}>
            <span className={classes.label}>{getTranslation(i.label)}</span>
            <Radio
              disabled={disabled}
              className={classes.radio}
              width={25}
              height={25}
              checked={i.value === value}
              onChange={() => {
                onChange(i.value);
                afterChange?.(i.value);
              }}
            />
          </div>
        ))}
      </div>
      <div
        className={classes.bar}
        style={{ opacity: disabled ? '0.5' : '1' }}
      ></div>
    </div>
  );
};

export default GranulationBar;
