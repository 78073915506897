import {
  Typography,
  FontWeights,
  FontFamilies
} from 'features/shared/constants/typography';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const borderRadius = {
  [BorderRadiusTypes.rounded]: '16px',
  [BorderRadiusTypes.sharp]: '5px'
};

export const useStyles = createUseStyles(theme => ({
  textArea: {
    width: '32rem',
    border: `2px solid ${theme.defaultInputStrokeColor}`,
    borderRadius: borderRadius[theme.borderRadiusType],
    padding: '1.4rem 2.4rem',
    color: theme.primaryColor,
    backgroundColor: theme.defaultInputFillColor,
    fontFamily: FontFamilies.roboto,
    fontSize: Typography.body1.size,
    lineHeight: Typography.heading1.lineHeight,
    fontWeight: FontWeights.normal,
    resize: 'none',
    overflow: 'auto',
    outline: 'none',

    '&::placeholder': {
      color: theme.disabledTextColor
    },

    '&$invalid': {
      border: `2px solid ${theme.errorNegativeColor}`
    },

    '&:hover, &$hover': {
      border: `2px solid ${theme.hoverInputStrokeColor}`
    },

    '&:focus, &$focus': {
      border: `2px solid ${theme.hoverInputStrokeColor}`,
      backgroundColor: theme.inputFillFocusColor
    },

    '&:disabled': {
      backgroundColor: theme.defaultInputFillColor,
      color: theme.disabledTextColor,
      borderColor: theme.disabledBackgroundColor
    }
  },
  invalid: {},
  hover: {},
  focus: {}
}));
