export const InvestorTypes = {
  nonProfessionalInvestor: 'nonProfessionalInvestor',
  professionalInvestor: 'professionalInvestor'
} as const;

export const ItemTypes = {
  radio: 'radio',
  checkbox: 'checkbox'
} as const;

export const NONE_OF_THE_ABOVE_ID = 'none-of-the-above';
export const NUMBER_OF_CHECKBOXES_TO_SELECT = 2;
