import { equals } from 'ramda';
import React from 'react';
import { useParams } from 'react-router-dom';

import { HolisticViewParams } from '../../../main/types';
import { Client, useHolisticViewStore } from '../services/holisticViewStore';
import useReadAdviceSessions from './useReadAdviceSessions';
import { useReadClientsList } from './useReadClientsList';
import { useReadGoalsData } from './useReadGoalsData';
import { useReadRiskScores } from './useReadRiskScores';
import useReadSelectedAdviceSessions from './useReadSelectedAdviceSessions';
import { usePortfolioChartStore } from 'features/roboAdvice/adviceSession/portfolioChart';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';

const useInitPage = () => {
  const readClientsList = useReadClientsList();
  const readGoalsData = useReadGoalsData();
  const readRiskScores = useReadRiskScores();
  const readSelectedAdviceSession = useReadSelectedAdviceSessions();
  const readAdviceSessions = useReadAdviceSessions();
  const portfolioChartStore = usePortfolioChartStore();
  const { clientId, adviceSessionId } = useParams<HolisticViewParams>();

  React.useEffect(() => {
    const setClient = async () => {
      await readClientsList();
      if (clientId) {
        useHolisticViewStore.getState().setSelectedClient(clientId);
        useHolisticViewStore
          .getState()
          .setSelectedAdviceSession(clientId, adviceSessionId);
        readAdviceSessions();
      }
    };
    setClient();

    return () => {
      useHolisticViewStore.getState().reset();
      portfolioChartStore.reset();
    };
  }, []);

  React.useEffect(() => {
    let oldSelectedClients: Client[] = [];

    const unsubscribe = useHolisticViewStore.subscribe(state => {
      const currSelectedClients = state.availableClients.filter(
        c => c.isSelected
      );

      if (
        !state.isReadGoalsDataPending &&
        !state.isReadClientsPending &&
        !state.isReadAdviceSessionsPending &&
        state.availableClients.map(c => c.adviceSessions).flat().length > 0 &&
        (currSelectedClients.length !== oldSelectedClients.length ||
          !equals(
            currSelectedClients.map(c => c.selectedAdviceSessionId),
            oldSelectedClients.map(c => c.selectedAdviceSessionId)
          ))
      ) {
        readGoalsData();
        oldSelectedClients = currSelectedClients;
      }
    });

    return unsubscribe;
  }, []);

  React.useEffect(() => {
    let oldSelectedClients: Client[] = [];

    const unsubscribe = useHolisticViewStore.subscribe(state => {
      const currSelectedClients = state.availableClients.filter(
        c => c.isSelected
      );

      if (
        !state.isReadGoalsDataPending &&
        !state.isReadClientsPending &&
        !state.isReadAdviceSessionsPending &&
        !state.isReadSelectedAdviceSessionsPending &&
        !equals(
          currSelectedClients.map(c => c.financialSituationData),
          oldSelectedClients.map(c => c.financialSituationData)
        )
      ) {
        readRiskScores();
        oldSelectedClients = currSelectedClients;
      }
    });

    return unsubscribe;
  }, []);

  React.useEffect(() => {
    let oldSelectedClients: Client[] = [];

    const unsubscribe = useHolisticViewStore.subscribe(state => {
      const currSelectedClients = state.availableClients.filter(
        c => c.isSelected
      );

      if (
        !state.isReadGoalsDataPending &&
        !state.isReadClientsPending &&
        !state.isReadAdviceSessionsPending &&
        !state.isReadSelectedAdviceSessionsPending &&
        (currSelectedClients.length !== oldSelectedClients.length ||
          !equals(
            currSelectedClients.map(c => c.selectedAdviceSessionId),
            oldSelectedClients.map(c => c.selectedAdviceSessionId)
          ))
      ) {
        readSelectedAdviceSession();
        oldSelectedClients = currSelectedClients;
      }
    });
    return unsubscribe;
  }, []);

  React.useEffect(() => {
    useHolisticViewStore.getState().changeProposalSectionStatuses({
      readExpectedPathChartData: PageStatuses.ready,
      readExpectedValueChartData: PageStatuses.ready,
      readEfficientFrontierChartData: PageStatuses.ready,
      readCashflowData: PageStatuses.ready,
      readHistoricalReturn3MChartData: PageStatuses.ready,
      readHistoricalReturnYTDChartData: PageStatuses.ready,
      readHistoricalReturn1YChartData: PageStatuses.ready,
      readHistoricalReturn3YChartData: PageStatuses.ready,
      readHistoricalReturn5YChartData: PageStatuses.ready,
      readHistoricalReturn10YChartData: PageStatuses.ready,
      readCostChartData: PageStatuses.ready
    });

    return () => {
      useHolisticViewStore.getState().changeProposalSectionStatuses({
        readExpectedPathChartData: undefined,
        readExpectedValueChartData: undefined,
        readEfficientFrontierChartData: undefined,
        readCashflowData: undefined,
        readHistoricalReturn3MChartData: undefined,
        readHistoricalReturnYTDChartData: undefined,
        readHistoricalReturn1YChartData: undefined,
        readHistoricalReturn3YChartData: undefined,
        readHistoricalReturn5YChartData: undefined,
        readHistoricalReturn10YChartData: undefined,
        readCostChartData: undefined
      });
    };
  }, []);
};

export default useInitPage;
