import axios, { CancelTokenSource } from 'axios';
import { isNil, pathOr } from 'ramda';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getRoboPortfolioConfig, getRoboPortfolioMeta } from '../../shared/api';
import { PageStatuses } from '../../shared/components/useReadDataListener';
import { usePageStore as useRiskScorePageStore } from '../services/pageStore';
import { mapToRiskScoreSettings } from '../services/sagas/mapRiskScoreToResult';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export const useReadPortfolioTableData = () => {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();
  const { roboPortfolioPrecision } = useCustomerConfig();
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  const readPortfolioTableData = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const riskScorePageStore = useRiskScorePageStore.getState();

    if (
      riskScorePageStore.pageStatuses.readPortfolioTableData !==
        PageStatuses.ready ||
      riskScorePageStore.productConfiguration ===
        riskScorePageStore.portfolioTableData?.namespaceId
    )
      return;

    try {
      riskScorePageStore.setPageStatus(
        'readPortfolioTableData',
        PageStatuses.pending
      );

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const getRoboPortfolioMetaResponse = await getRoboPortfolioMeta(
        qAuthAccessToken,
        cancelTokenSource.token
      );

      const getRoboPortfolioConfigResponses = await Promise.all(
        pathOr(
          [],
          ['allowed options', 'risk'],
          getRoboPortfolioMetaResponse.data
        ).map(riskScore =>
          getRoboPortfolioConfig(
            qAuthAccessToken,
            riskScore,
            roboPortfolioPrecision,
            {
              namespace_id: riskScorePageStore.productConfiguration
            }
          )
        )
      );
      const portfolioConfigs = getRoboPortfolioConfigResponses.map(
        ({ data }) => data
      );
      const riskScoreSettings = mapToRiskScoreSettings(portfolioConfigs);

      riskScorePageStore.setPortfolioTableData({
        ...riskScoreSettings,
        namespaceId: riskScorePageStore.productConfiguration
      });

      riskScorePageStore.setPageStatus(
        'readPortfolioTableData',
        PageStatuses.succeed
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        riskScorePageStore.setPageStatus(
          'readPortfolioTableData',
          PageStatuses.failed
        );

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.proposal.readDataErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readPortfolioTableData;
};
