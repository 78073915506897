import { useMergeRefs } from '@floating-ui/react';
import React from 'react';

import { useTooltipContext } from './context';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'none',
    border: 'none',
    color: 'inherit',
    cursor: 'pointer',
    font: 'inherit',
    padding: 0
  }
});

type Props = React.HTMLProps<HTMLElement> & { asChild?: boolean };

const TooltipTrigger = React.forwardRef<HTMLElement, Props>(
  ({ children, asChild = false, ...props }, propRef) => {
    const classes = useStyles();
    const context = useTooltipContext();
    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && React.isValidElement(children)) {
      return React.cloneElement(
        children,
        context.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          'data-state': context.open ? 'open' : 'closed'
        })
      );
    }

    return (
      <div
        ref={ref}
        className={classes.button}
        data-state={context.open ? 'open' : 'closed'}
        {...context.getReferenceProps(props)}
      >
        {children}
      </div>
    );
  }
);

export default TooltipTrigger;
