import classNames from 'classnames';
import * as R from 'ramda';

import { useField } from './useField';
import Slider from 'features/shared/components/slider/index';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  section: {}
});

const FinalFormSlider = ({
  name,
  fieldConfig,
  sliderConfig,
  externalClasses,
  disabled,
  afterChange,
  ...restProps
}) => {
  const internalClasses = useStyles();
  const classes = R.isNil(externalClasses)
    ? internalClasses
    : R.mergeWith(
        (c1, c2) => classNames(c1, c2),
        internalClasses,
        externalClasses
      );

  const {
    input: { value, onChange }
  } = useField(name, fieldConfig);

  return (
    <div className={classes.section}>
      <Slider
        data-testid={restProps['data-testid']}
        value={value || 0}
        config={sliderConfig}
        callbackFn={newValue => {
          onChange(newValue);
          !R.isNil(afterChange) && afterChange(newValue);
        }}
        disabled={disabled}
        externalClasses={classes}
      />
    </div>
  );
};

export default FinalFormSlider;
