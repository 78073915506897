import { ComponentProps } from 'react';

import { useField } from './useField';
import Checkbox from 'features/shared/components/checkbox/index';

type Props = {
  name: string;
  fieldConfig?: Record<string, any>;
} & ComponentProps<typeof Checkbox>;

const FinalFormCheckbox = ({ name, fieldConfig = {}, ...restProps }: Props) => {
  const {
    input: { checked, onChange, onFocus, onBlur },
    meta: { error }
  } = useField(name, { ...fieldConfig, type: 'checkbox' });

  return (
    <Checkbox
      checked={checked}
      onChange={e => onChange(!checked)}
      onFocus={onFocus}
      onBlur={onBlur}
      error={error}
      {...restProps}
    />
  );
};

export default FinalFormCheckbox;
