import { isNil, pipe, sortWith, propOr, toLower } from 'ramda';

import { clientsTable } from '../clientsTable';
import {
  Client,
  HolisticViewState,
  useHolisticViewStore
} from '../services/holisticViewStore';
import {
  orderDirectionFuncs,
  getPage
} from 'features/shared/services/table/selector.js';
import { TableStoreState } from 'features/sharedModules/table/types';

type Comparator = (a: unknown, b: unknown) => number;

const clientsTableOrderKeys = {
  type: 'type',
  name: 'name'
};

const orderKeyFuncs = {
  [clientsTableOrderKeys.type]: pipe(propOr('', 'type'), toLower),
  [clientsTableOrderKeys.name]: pipe(propOr('', 'name'), toLower)
};

const useClients = () => {
  const { useTableStore } = clientsTable;
  const tableStore = useTableStore();
  const holisticViewStore = useHolisticViewStore();

  return getClientsResult({
    tableStore,
    holisticViewStore
  });
};

const getClientsResult = ({
  tableStore,
  holisticViewStore
}: {
  tableStore: TableStoreState;
  holisticViewStore: HolisticViewState;
}): { items: Client[]; totalItemsNumber: number } => {
  const clients = holisticViewStore.isSearchModeActive
    ? holisticViewStore.searchedClients.filter(c => !c.isSelected)
    : holisticViewStore.availableClients.filter(c => !c.isSelected);

  let sortWithFunc: Comparator[] = [];
  if (!isNil(tableStore.orderKey)) {
    const orderDirectionFunc = orderDirectionFuncs[tableStore.orderDirection];
    const orderKeyFunc = orderKeyFuncs[tableStore.orderKey];
    if (!isNil(orderDirectionFunc) && !isNil(orderKeyFunc)) {
      sortWithFunc = [orderDirectionFunc(orderKeyFunc)];
    }
  }

  return pipe(sortWith(sortWithFunc), getPage(tableStore))(clients) as any;
};

export default useClients;
