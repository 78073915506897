import chroma from 'chroma-js';
import classNames from 'classnames';

import { useErrorsStore } from '../../../../../sharedModules/errors/services/errorsStore';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import { Typography, FontFamilies } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const ProgressWidth = 130;

const useStyles = createUseStyles(theme => ({
  container: {
    cursor: 'pointer',
    backgroundColor: 'unset',
    border: 'none',
    textAlign: 'left',
    fontFamily: FontFamilies.roboto,

    '&$disabled': {
      cursor: 'default',

      '& > $label': {
        opacity: '0.5',
        color: theme.disabledTextColor
      },

      '& > $progressContainer': {
        opacity: '0.5'
      }
    },

    '&$active': {
      '& > $label': {
        color: theme.primaryColor
      },

      '& > $progressContainer': {
        opacity: '1'
      }
    }
  },
  label: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight,
    color: theme.secondaryColor
  },
  progressContainer: {
    width: `${ProgressWidth}px`,
    height: '4px',
    borderRadius: '10px',
    opacity: '0.5',
    backgroundColor: chroma(theme.accentColor).alpha(0.3).css(),
    marginTop: '18px'
  },
  progress: {
    height: '4px',
    borderRadius: '10px',
    backgroundColor: theme.accentColor
  },
  errorIcon: {
    display: 'inline',
    paddingRight: '4px',
    verticalAlign: 'middle',
    '& svg': {
      fill: theme.errorNegativeColor,
      width: '16px',
      height: '16px'
    }
  },
  disabled: {},
  active: {}
}));

const Tab = ({
  label,
  value,
  progress,
  hasError,
  onClick,
  active,
  disabled,
  forwardedRef,
  onKeyDown,
  onFocus,
  tabIndex
}) => {
  const classes = useStyles();
  const errorsStore = useErrorsStore();

  const showErrorMessage = () => {
    errorsStore.showTabsMessage();
  };

  return (
    <button
      type="button"
      className={classNames(classes.container, {
        [classes.disabled]: disabled,
        [classes.active]: active
      })}
      onClick={disabled ? showErrorMessage : onClick}
      role="tab"
      aria-selected={active}
      aria-controls={`panel-id-${value}`}
      id={`tab-id-${value}`}
      ref={forwardedRef}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      tabIndex={tabIndex}
    >
      <div className={classes.label}>
        {errorsStore.isErrorModeEnabled && hasError && !disabled ? (
          <div className={classes.errorIcon}>
            <WarningIcon />
          </div>
        ) : null}
        {label}
      </div>
      <div className={classes.progressContainer}>
        <div
          className={classes.progress}
          style={{ width: `${ProgressWidth * progress}px` }}
        />
      </div>
    </button>
  );
};

export default Tab;
