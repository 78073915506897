import { createForm } from 'features/sharedModules/finalForm/services/finalForm';
import { createUseFinalFormAdapter } from 'features/sharedModules/zustand/components/finalFormAdapter';

type CustomDownloadFormValues = {
  allAttachments: string[];
  report: (string | undefined)[];
  attachments: string[];
};

export const customDownloadForm = createForm<CustomDownloadFormValues>({
  onSubmit: () => {}
});

export const useCustomDownloadForm = createUseFinalFormAdapter({
  form: customDownloadForm
});
