import { CancelToken } from 'axios';
import { over, lensIndex, append } from 'ramda';

import { CUSTOM_PORTFOLIO_PRECISION } from '../advisory/components/customPortfolio/constants';
import { Category } from '../session/services/sessionStore';
import { getFundsList } from './api';
import { ProductAttributeType } from './constants';
import { ProductAttributesConfig } from './services/selectors';
import { RoboAdvice } from 'defaults/defaultRoboAdvice';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { filterNil } from 'features/shared/utils/filters';
import { roundNumber } from 'features/shared/utils/number';
import { getTranslation } from 'features/shared/utils/translations';

export const availableHistoricalReturnStatisticsMapping = {
  avg_down_month: 'averageDownMonth',
  avg_drawdown: 'averageDrawdown',
  avg_drawdown_days: 'averageDrawdownDays',
  avg_up_month: 'averageUpMonth',
  best_month: 'bestMonth',
  best_year: 'bestYear',
  cagr: 'cagr',
  total_return: 'totalReturn',
  incep: 'incep',
  max_drawdown: 'maxDrawdown',
  monthly_mean: 'monthlyMean',
  monthly_sharpe: 'monthlySharpe',
  monthly_sortino: 'monthlySortino',
  monthly_vol: 'monthlyVol',
  twelve_month_win_perc: 'twelveMonthWinPercent',
  win_year_perc: 'winYearPercent',
  worst_month: 'worstMonth',
  worst_year: 'worstYear',
  yearly_mean: 'yearlyMean',
  yearly_sharpe: 'yearlySharpe',
  yearly_sortino: 'yearlySortino',
  yearly_vol: 'yearlyVol',
  ytd: 'ytd'
} as const;

export type AssetClass = {
  AssetClass: {
    CategoryName: string;
    MainAssetClass: string;
    PortfolioRole: string;
    SubAssetClass: string;
    CategoryId: string;
  };
  Weight?: number;
};

export type AssetClassWithCategoryIds = AssetClass & {
  CategoryIds: { id: string; weight: number }[];
};

export type FlatAssetClass = {
  CategoryId: string;
  CategoryIds: {
    id: string;
    weight: number;
  }[];
  mainAssetClass: string;
  subAssetClass: string;
  id: string;
  title: string;
  instruments: {
    weight: number;
    id: string;
    isin: string;
    title: string;
  }[];
  weight: number;
};

export const groupAssetClasses = <T extends AssetClass>(
  categories: T[]
): AssetClassWithCategoryIds[] =>
  categories
    ? categories.reduce((acc, assetClass) => {
        const index = acc.findIndex(
          v =>
            v.AssetClass.SubAssetClass === assetClass.AssetClass.SubAssetClass
        );

        const weight = Number(assetClass.Weight);
        const categoryId = assetClass.AssetClass.CategoryId;

        return index !== -1
          ? over(
              lensIndex(index),
              c => ({
                ...c,
                Weight: roundNumber(
                  (c.Weight ?? 0) + weight,
                  CUSTOM_PORTFOLIO_PRECISION
                ),
                CategoryIds: append(
                  { id: categoryId, weight: weight },
                  c.CategoryIds
                )
              }),
              acc
            )
          : append(
              {
                ...assetClass,
                Weight: roundNumber(weight, CUSTOM_PORTFOLIO_PRECISION),
                CategoryIds: [{ id: categoryId, weight: weight }]
              },
              acc
            );
      }, [] as AssetClassWithCategoryIds[])
    : [];

export const groupAllAssetClasses = (
  categories: Category[]
): AssetClassWithCategoryIds[] =>
  categories.reduce((acc, assetClass) => {
    const index = acc.findIndex(
      v => v.AssetClass?.SubAssetClass === assetClass.SubAssetClass
    );

    const categoryId = assetClass.CategoryId;

    return index !== -1
      ? over(
          lensIndex(index),
          c => ({
            ...c,
            Weight: 0,
            CategoryIds: append({ id: categoryId, weight: 0 }, c.CategoryIds)
          }),
          acc
        )
      : append(
          {
            AssetClass: {
              CategoryName: assetClass.CategoryName,
              MainAssetClass: assetClass.MainAssetClass,
              PortfolioRole: assetClass.PortfolioRole,
              SubAssetClass: assetClass.SubAssetClass,
              CategoryId: categoryId
            },
            Weight: 0,
            CategoryIds: [{ id: categoryId, weight: 0 }]
          },
          acc
        );
  }, [] as AssetClassWithCategoryIds[]);

export type ProductAttributePDF = {
  type: keyof typeof ProductAttributeType;
  name: string;
  label: string;
};

export const getProductAttributesPDF = (
  productAttributesConfig: ProductAttributesConfig
): ProductAttributePDF[] => {
  const {
    productAttributeTxt1,
    productAttributeTxt2,
    productAttributeTxt3,
    productAttributeTxt5,
    productAttributeTxt4,
    productAttributeBinary1,
    productAttributeBinary2,
    productAttributeBinary3,
    productAttributeBinary4,
    productAttributeBinary5
  } = productAttributesConfig;

  const productAttributeBinaries = [
    productAttributeBinary1,
    productAttributeBinary2,
    productAttributeBinary3,
    productAttributeBinary4,
    productAttributeBinary5
  ]
    .map(
      (productAttribute, index) =>
        productAttribute && {
          ...productAttribute,
          name: `productAttributeBinary${index + 1}`,
          label: getTranslation(productAttribute.label)
        }
    )
    .filter(filterNil);

  const productAttributeTxts = [
    productAttributeTxt1,
    productAttributeTxt2,
    productAttributeTxt3,
    productAttributeTxt4,
    productAttributeTxt5
  ]
    .map(
      (productAttribute, index) =>
        productAttribute && {
          ...productAttribute,
          name: `productAttributeTxt${index + 1}`,
          label: getTranslation(productAttribute.label)
        }
    )
    .filter(filterNil);

  return [
    ...productAttributeTxts.map(attr => ({
      ...attr,
      type: ProductAttributeType.text
    })),
    ...productAttributeBinaries.map(attr => ({
      ...attr,
      type: ProductAttributeType.binary
    }))
  ];
};

export const getSubAssetClassTranslation = (
  categoryId: string,
  subAssetClassNameMapping: RoboAdvice['subAssetClassNameMapping']
) => getTranslation(subAssetClassNameMapping?.[categoryId]);

export const getExistingPortfolioForHistoricalReturnBenchmark = async ({
  portfolio,
  namespaceId,
  qAuthAccessToken,
  cancelToken
}: {
  portfolio: { category_id?: string; weight: number }[];
  namespaceId: number | null | undefined;
  qAuthAccessToken: string;
  cancelToken: CancelToken;
}) => {
  const fundsListResponse = await getFundsList(qAuthAccessToken, cancelToken, {
    namespace_id: namespaceId,
    language: getUserPageLanguage()
  });
  const { funds } = fundsListResponse.data;

  const benchmarkExistingPortfolio = portfolio
    .map(({ category_id, weight }) => {
      const fund = funds.find(fund =>
        Object.keys(fund.SubAssetClasses).includes(category_id || '')
      );
      if (!fund) {
        return null;
      }

      return {
        id: fund.Ticker as string,
        weight
      };
    })
    .filter(filterNil);

  return benchmarkExistingPortfolio;
};
