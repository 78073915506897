import { StylesProvider } from '@material-ui/core/styles';
import { Route, Switch, Redirect } from 'react-router-dom';

import HolisticView from '../../holisticView/main/components/index';
import SavingCalculator from '../../savingCalculatorPage/components/index';
import Navigation from './navigation';
import MainLayout from 'features/shared/components/mainLayout/index.js';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';

const Main = () => {
  const {
    tools: {
      savingCalculator: { enabled: isSavingCalculatorEnabled },
      holisticView: { enabled: isHolisticViewEnabled }
    }
  } = useCustomerConfig();

  return (
    <>
      <StylesProvider injectFirst>
        <MainLayout>
          <Navigation />
          <Switch>
            {isHolisticViewEnabled && (
              <Route
                path={routeTemplates.toolsHolisticView.config}
                component={HolisticView}
              />
            )}
            {isSavingCalculatorEnabled && (
              <Route
                path={routeTemplates.toolsSavingCalculator.config}
                component={SavingCalculator}
              />
            )}
            <Route
              exact
              path={routeTemplates.tools.config}
              render={() =>
                isHolisticViewEnabled ? (
                  <Redirect to={routeTemplates.toolsHolisticView.build()} />
                ) : isSavingCalculatorEnabled ? (
                  <Redirect to={routeTemplates.toolsSavingCalculator.build()} />
                ) : null
              }
            />
          </Switch>
        </MainLayout>
      </StylesProvider>
    </>
  );
};

export default Main;
