import classNames from 'classnames';
import * as R from 'ramda';
import React from 'react';

import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const borderRadius = {
  [BorderRadiusTypes.rounded]: '40px',
  [BorderRadiusTypes.sharp]: '5px'
};

const useStyles = createUseStyles(theme => {
  const borderRadiusValue = borderRadius[theme.borderRadiusType];

  return {
    root: {
      display: 'flex'
    },
    item: {
      height: '4rem',
      display: 'flex',
      alignItems: 'center',
      padding: '0 3.2rem',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: theme.secondaryColor,
      borderRightWidth: '0',
      backgroundColor: theme.boxBackgroundColor_1,
      color: theme.secondaryColor,
      cursor: 'pointer',

      '&:first-child': {
        borderRadius: `${borderRadiusValue} 0 0 ${borderRadiusValue}`
      },

      '&:last-child': {
        borderRightWidth: '2px',
        borderRadius: `0 ${borderRadiusValue} ${borderRadiusValue} 0`
      },

      '&$active': {
        borderColor: theme.secondaryColor,
        borderRightWidth: '2px',
        backgroundColor: theme.secondaryColor,
        color: theme.defaultButtonTextColor
      },

      '&$active + &': {
        borderLeftWidth: '0'
      },

      '&:hover, &$hover': {
        borderColor: theme.secondaryColor,
        backgroundColor: theme.itemBackgroundColor_2,
        color: theme.secondaryColor
      },

      '&$active:hover, &$active$hover': {
        borderColor: theme.secondaryColor,
        backgroundColor: theme.secondaryColor,
        color: theme.defaultButtonTextColor
      },

      '&$disabled, &:hover$disabled, &$hover$disabled': {
        borderColor: theme.disabledBackgroundColor,
        backgroundColor: theme.boxBackgroundColor_1,
        color: theme.disabledTextColor,
        cursor: 'default'
      },

      '&$active$disabled, &$active:hover$disabled, &$active$hover$disabled': {
        borderColor: theme.disabledBackgroundColor,
        backgroundColor: theme.disabledBackgroundColor,
        color: theme.disabledTextColor,
        cursor: 'default'
      },

      '&$invalid': {
        borderColor: theme.errorNegativeColor
      }
    },
    active: {},
    disabled: {},
    hover: {},
    invalid: {}
  };
});

type Item = {
  isDisabled?: boolean;
  key?: string | number;
  isActive?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  title: string;
};

export type Props = {
  items: Item[];
  disabled?: boolean;
  className?: string;
  itemClassName?: string;
  activeItemClassName?: string;
  disabledItemClassName?: string;
  hoverItemClassName?: string;
  hover?: boolean;
  hasError?: boolean;
  'data-testid'?: string;
};

const ButtonGroup = ({
  className,
  itemClassName,
  activeItemClassName,
  disabledItemClassName,
  hoverItemClassName,
  items,
  disabled,
  hover = false,
  hasError,
  ...restProps
}: Props) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.root, className)}
      data-testid={restProps['data-testid']}
    >
      {items.map((item, index) => {
        const isDisabled = item.isDisabled || disabled;
        const key = R.isNil(item.key) ? index : item.key;

        return (
          <div
            key={key}
            data-testid={key}
            data-active={item.isActive}
            className={classNames(
              classes.item,
              itemClassName,
              {
                [classNames(classes.active, activeItemClassName)]:
                  item.isActive,
                [classNames(classes.disabled, disabledItemClassName)]:
                  isDisabled,
                [classNames(classes.hover, hoverItemClassName)]: hover,
                [classes.invalid]: hasError
              },
              item.className
            )}
            onClick={isDisabled ? undefined : item.onClick}
          >
            {item.title}
          </div>
        );
      })}
    </div>
  );
};

export default ButtonGroup;
