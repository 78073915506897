import { isNil } from 'ramda';

import { RoboAdviceAdviceSessionPages } from '../../../roboAdvice/shared/constants';
import { useCustomerConfig } from '../../customerConfig/components/useCustomerConfig';

export const useIsThisPageLastBeforeAdvisory = (pageId: string): boolean => {
  const {
    tenantSettings: { flowOrderAdviceInformation }
  } = useCustomerConfig();

  const tabs = [
    {
      order: flowOrderAdviceInformation.adviceInformation,
      pageId: RoboAdviceAdviceSessionPages.adviceInformation
    },
    {
      order: flowOrderAdviceInformation.purposeAndRisk,
      pageId: RoboAdviceAdviceSessionPages.purposeAndRisk
    },
    {
      order: flowOrderAdviceInformation.knowledgeAndExperience,
      pageId: RoboAdviceAdviceSessionPages.knowledgeAndExperience
    },
    {
      order: flowOrderAdviceInformation.financialSituation,
      pageId: RoboAdviceAdviceSessionPages.financialSituation
    },
    {
      order: flowOrderAdviceInformation.sustainability,
      pageId: RoboAdviceAdviceSessionPages.sustainability
    }
  ].sort((a, b) =>
    !isNil(a.order) && !isNil(b.order) ? b.order - a.order : 0
  );

  return tabs[0].pageId === pageId;
};
