import React from 'react';

import HorizontalBar from './horizontalBar';
import WeightedTable from './weightedTable';
import { Goal } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import {
  FundAnalysis,
  SustainabilityData
} from 'features/roboAdvice/adviceSession/sustainability';
import { Spacing } from 'features/shared/constants/spacing';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  noData: {
    color: theme.secondaryColor,
    marginTop: Spacing.spacing02
  }
}));

type Props = {
  alignmentValues: {
    name: string;
    title: string;
    preferenceScore: number | undefined;
  }[];
  cultureCode: string;
  fundAnalysisData: FundAnalysis[];
  goals: Goal[];
  selectedGoalsIds: string[];
  sustainabilityData: SustainabilityData[];
  totalValue: number;
};

const WeightedTableContainer = ({
  alignmentValues,
  cultureCode,
  fundAnalysisData,
  goals,
  selectedGoalsIds,
  sustainabilityData,
  totalValue
}: Props) => {
  const i18n = useI18n();
  const classes = useStyles();

  const portfolioScore = React.useMemo(() => {
    if (selectedGoalsIds.length === 1) {
      return (
        sustainabilityData.find(({ goalId }) =>
          selectedGoalsIds.includes(goalId)
        )?.data.portfolioAnalysis.portfolioWeightedSustainabilityScore || 0
      );
    }

    return sustainabilityData
      .filter(({ goalId }) => selectedGoalsIds.includes(goalId))
      .map(({ data, goalId: id }) => {
        const goal = goals.find(({ goalId }) => goalId === id) as Goal;
        const value = goal.data.firstDeposit || goal.data.monthlyDeposit || 0;
        return (
          data.portfolioAnalysis.portfolioWeightedSustainabilityScore *
          (value / totalValue)
        );
      })
      .reduce((acc, curr) => acc + curr, 0);
  }, [goals, selectedGoalsIds, sustainabilityData, totalValue]);

  const yourScore = React.useMemo(
    () =>
      sustainabilityData.length > 0
        ? (sustainabilityData[0].data.investorSustainabilityScore as number)
        : 0,
    [sustainabilityData]
  );

  if (!alignmentValues.length) {
    return (
      <div className={classes.noData}>
        {i18n('roboAdvice.proposal.sustainability.noData')}
      </div>
    );
  }

  return (
    <>
      <HorizontalBar
        cultureCode={cultureCode}
        portfolioScore={portfolioScore}
        yourScore={yourScore}
      />
      <WeightedTable
        alignmentValues={alignmentValues}
        cultureCode={cultureCode}
        fundAnalysisData={fundAnalysisData}
        portfolioScore={portfolioScore}
        yourScore={yourScore}
      />
    </>
  );
};

export default WeightedTableContainer;
