import { UseFieldConfig } from 'react-final-form';

import { useField } from './useField';
import Radio, { Props } from 'features/shared/components/radio/radio';

type FinalFormProps = {
  name: string;
  fieldConfig?: UseFieldConfig<unknown>;
} & Omit<Props, 'value' | 'onChange'>;

const FinalFormRadio = ({
  name,
  fieldConfig = {},
  ...restProps
}: FinalFormProps) => {
  const {
    input: { value, onChange }
  } = useField(name, fieldConfig);

  return <Radio value={value} onChange={onChange} {...restProps} />;
};

export default FinalFormRadio;
