import classNames from 'classnames';
import { isEmpty, isNil, mergeWith } from 'ramda';

import BaseRadio from 'features/shared/components/radio';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  section: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  control: {},
  label: {
    color: theme.primaryColor,
    flex: '0 0 auto',
    cursor: 'pointer',
    marginLeft: '1.2rem',

    '&$disabled': {
      color: theme.disabledTextColor,
      cursor: 'default'
    }
  },
  disabled: {}
}));

export type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
  afterChange?: (value: boolean) => void;
  activeValue?: boolean;
  label?: string;
  disabled?: boolean;
  className?: string;
  externalClasses?: ReturnType<typeof useStyles>;
};

export const Radio = ({
  value,
  onChange,
  afterChange,
  activeValue,
  label,
  disabled,
  className,
  externalClasses,
  ...restProps
}: Props) => {
  const inputOnChange = selectedValue => {
    if (selectedValue !== value) {
      onChange(selectedValue);
      afterChange && afterChange(selectedValue);
    }
  };

  const internalClasses = useStyles();
  const classes = isNil(externalClasses)
    ? internalClasses
    : (mergeWith(
        (c1, c2) => classNames(c1, c2),
        internalClasses,
        externalClasses
      ) as ReturnType<typeof useStyles>);

  return (
    <div className={classNames(classes.section, className)}>
      <div className={classes.control}>
        <BaseRadio
          data-testid={restProps['data-testid']}
          checked={value === activeValue}
          onChange={() => {
            inputOnChange(activeValue);
          }}
          disabled={disabled}
        />
      </div>
      {!isNil(label) && !isEmpty(label) && (
        <span
          className={classNames(classes.label, {
            [classes.disabled]: disabled
          })}
          onClick={() => {
            if (!disabled) {
              inputOnChange(activeValue);
            }
          }}
        >
          {label}
        </span>
      )}
    </div>
  );
};

export default Radio;
