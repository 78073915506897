import React from 'react';

import { useIsSessionReadOnly } from '../../main/services/selectors';
import Widget from '../../shared/components/widget';
import { getCompanyLiquidity } from '../constants.js';
import { RenderFields } from './shared.js';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import InputErrorMessage from 'features/sharedModules/errors/components/inputErrorMessage';
import { ErrorMessage } from 'features/sharedModules/errors/types';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column'
  }
});

type Props = {
  hasError: boolean;
  errors: ErrorMessage[];
};

const CompanyLiquidity = ({ hasError, errors }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const isSessionReadOnly = useIsSessionReadOnly();
  const {
    roboAdviceForm: {
      financialSituation: {
        liquidity: { company: companyLiquidity }
      }
    }
  } = useCustomerConfig();
  const fields = getCompanyLiquidity(companyLiquidity);

  return (
    <div className={classes.root}>
      {errors.map(error => (
        <InputErrorMessage message={error.alertMessage} />
      ))}
      <Widget
        header={i18n('roboAdvice.financialSituation.liquidity')}
        doShowEmptyFooter
        elementId={'company-liquidity'}
        hasError={hasError}
      >
        <RenderFields
          fields={fields}
          adjustElement={(field, element) =>
            React.cloneElement(element, {
              disabled: isSessionReadOnly
            })
          }
        />
      </Widget>
    </div>
  );
};

export default CompanyLiquidity;
