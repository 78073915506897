import classNames from 'classnames';

import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  reference: {
    cursor: 'pointer',
    width: '100%',
    display: 'block',

    '&$disabled': {
      cursor: 'default'
    }
  },
  disabled: {}
});

type Props = {
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLSpanElement>;
  disabled?: boolean;
  forwardedRef?: React.LegacyRef<HTMLSpanElement>;
  referenceDataId?: string;
};

const TextReference = ({
  forwardedRef,
  referenceDataId,
  children,
  disabled,
  onClick
}: Props) => {
  const classes = useStyles();

  return (
    <span
      data-id={referenceDataId}
      onClick={disabled ? undefined : onClick}
      ref={forwardedRef}
      className={classNames(classes.reference, {
        [classes.disabled]: disabled
      })}
    >
      {children}
    </span>
  );
};

export default TextReference;
