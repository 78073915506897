import * as R from 'ramda';
import create from 'zustand';

import { SessionStatuses } from '../../../shared/constants';
import { SessionStatus } from '../../../shared/types';
import { AssetClassAllocation } from '../../shared/portfolioChartSelectors';
import { SessionNamespace } from '../../shared/types';

export const Statuses = {
  pending: 'pending',
  succeed: 'succeed',
  failed: 'failed'
} as const;

export type Status = typeof Statuses[keyof typeof Statuses];

export type SessionStoreStatuses = {
  readRiskScoreSettings?: Status;
};

export type AdviceSession = {
  id: string;
  name: string;
  status: SessionStatus;
  adviceType: string;
};

export const PortfolioRoles = {
  base: 'Base',
  optional: 'Optional',
  theme: 'Theme'
} as const;

export type Category = {
  CategoryId: string;
  CategoryName: string;
  EquityShare: number;
  MainAssetClass: string;
  PortfolioRole: typeof PortfolioRoles[keyof typeof PortfolioRoles];
  SubAssetClass: string;
  VisibleCustomPortfolio: boolean | null;
};

export type SessionStoreData = {
  sessionStatuses: SessionStoreStatuses;
  isReadAdviceSessionPending: boolean;
  isAdvisoryPageInitialized: boolean;
  isBasicDataInitialized: boolean;
  clientName?: string | null;
  clientAdviceSessions?: AdviceSession[];
  namespaces: SessionNamespace[];
  isSessionInitialized: boolean;
  initializedSessionId?: string;
  status?: SessionStatus;
  defaultConfigNamespaceId?: number;
  categories: Category[];
  fieldsWhichChangedRiskScore: string[];
  isAdviceSessionPatched: boolean;
  followUpId?: string;
  followUpData?: {
    goals: {
      newGoalId: string;
      originalGoalId: string;
      data: {
        isPortfolioCustom: boolean;
        portfolio: string;
        customPortfolio: {
          CategoryId: string;
          CategoryIds: {
            id: string;
            weight: number;
          }[];
          id: string;
          instruments: {
            id: string;
            isin: string;
            title: string;
            weight: number;
          }[];
          mainAssetClass: string;
          subAssetClass: string;
          title: string;
          weight: number;
        } | null;
        modelPortfolioInstrumentAllocation:
          | AssetClassAllocation['instrumentAllocation']
          | null;
      };
    }[];
  };
};

export type SessionStoreApi = {
  reset: () => void;
  initialize: (initialData: Partial<SessionStoreData>) => void;
  setSessionStatus: <K extends keyof SessionStoreStatuses>(
    key: K,
    value: SessionStoreStatuses[K]
  ) => void;
  setIsReadAdviceSessionPending: (isReadAdviceSessionPending: boolean) => void;
  setIsAdvisoryPageInitialized: (isAdvisoryPageInitialized: boolean) => void;
  setIsBasicDataInitialized: (isBasicDataInitialized: boolean) => void;
  addNamespace: (namespace: SessionNamespace) => void;
  setIsSessionInitialized: (
    isSessionInitialized: boolean,
    initializedSessionId?: string
  ) => void;
  completeSession: () => void;
  addFieldWhichChangedRiskScore: (fieldsWhichChangedRiskScore: string) => void;
  removeFieldWhichChangedRiskScore: (
    fieldsWhichChangedRiskScore: string
  ) => void;
  resetFieldsWhichChangedRiskScore: () => void;
  setIsAdviceSessionPatched: (isPatched: boolean) => void;
};

export type SessionStoreState = SessionStoreData & SessionStoreApi;

export const defaultData = {
  sessionStatuses: {},
  isReadAdviceSessionPending: false,
  isAdvisoryPageInitialized: false,
  isBasicDataInitialized: false,
  clientName: undefined,
  clientAdviceSessions: undefined,
  namespaces: [],
  isSessionInitialized: false,
  initializedSessionId: undefined,
  status: undefined,
  defaultConfigNamespaceId: undefined,
  categories: [],
  fieldsWhichChangedRiskScore: [],
  isAdviceSessionPatched: false
};

export const useSessionStore = create<SessionStoreState>(set => ({
  ...defaultData,
  reset() {
    set(defaultData);
  },
  initialize(initialData) {
    set(
      state => ({
        ...state,
        ...initialData
      }),
      true
    );
  },
  setSessionStatus(key, value) {
    set(R.set(R.lensPath(['sessionStatuses', key]), value), true);
  },
  setIsReadAdviceSessionPending(isReadAdviceSessionPending) {
    set({ isReadAdviceSessionPending });
  },
  setIsAdvisoryPageInitialized(isAdvisoryPageInitialized) {
    set({
      isAdvisoryPageInitialized
    });
  },
  setIsBasicDataInitialized(isBasicDataInitialized) {
    set({ isBasicDataInitialized });
  },
  addNamespace(namespace) {
    set(R.over(R.lensProp('namespaces'), R.append(namespace)), true);
  },
  setIsSessionInitialized(isSessionInitialized, initializedSessionId) {
    set({
      isSessionInitialized,
      initializedSessionId
    });
  },
  completeSession() {
    set({ status: SessionStatuses.complete });
  },
  addFieldWhichChangedRiskScore(fieldWhichChangedRiskScore) {
    set(state => ({
      fieldsWhichChangedRiskScore: Array.from(
        new Set([
          ...state.fieldsWhichChangedRiskScore,
          fieldWhichChangedRiskScore
        ])
      )
    }));
  },
  removeFieldWhichChangedRiskScore(fieldsWhichChangedRiskScore) {
    set(state => ({
      fieldsWhichChangedRiskScore: state.fieldsWhichChangedRiskScore.filter(
        field => !field.startsWith(fieldsWhichChangedRiskScore)
      )
    }));
  },
  resetFieldsWhichChangedRiskScore() {
    set({ fieldsWhichChangedRiskScore: [] });
  },
  setIsAdviceSessionPatched(isPatched) {
    set({ isAdviceSessionPatched: isPatched });
  }
}));
