import * as R from 'ramda';

import { parseDate } from 'features/shared/utils/dateTime.js';
import { mapServerCustomerToClient as mapServerCustomerToClientUtil } from 'features/shared/utils/mapping.js';

export const mapServerCustomerToClient = data => {
  const { customer, users } = data;

  return {
    customer: mapServerCustomerToClientUtil(customer),
    users: R.map(
      user => ({
        id: user.id,
        email: user.email,
        doesAuth0UserExist: user.does_auth0_user_exist,
        isCustomerAdmin: user.is_customer_admin
      }),
      users
    )
  };
};

export const mapClientCustomerToServer = (cultureCode, customer) => {
  return {
    customer: {
      id: customer.id,
      code:
        R.isNil(customer.code) || R.isEmpty(R.trim(customer.code))
          ? null
          : R.trim(customer.code),
      advisory_key:
        R.isNil(customer.advisoryKey) || R.isEmpty(R.trim(customer.advisoryKey))
          ? null
          : R.trim(customer.advisoryKey),
      title: customer.title,
      users: R.map(
        user => ({
          id: user.id,
          email: user.email,
          is_customer_admin: user.isCustomerAdmin
        }),
        customer.users
      ),
      licenses: R.map(
        license => ({
          module_type: license.moduleType,
          license_type: license.licenseType,
          license_renewal_date: parseDate(
            cultureCode,
            license.licenseRenewalDate
          )
        }),
        customer.licenses
      )
    }
  };
};
