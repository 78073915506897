import { Fragment } from 'react';

import { useIsSessionReadOnly } from '../../../main/services/selectors';
import OrderExecutionActionButtons from '../orderExecutionActionButtons';
import { useInitPage } from './useInitPage';
import { RoboAdviceOrderExecutionPages } from 'features/roboAdvice/shared/constants';
import AdvisorNotes from 'features/shared/components/advisorNotes';
import { Spacing } from 'features/shared/constants/spacing';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { ConfigurableComponent } from 'features/sharedModules/declarativeForm/components';
import ErrorsList from 'features/sharedModules/errors/components/errorsList';
import InputErrorMessage from 'features/sharedModules/errors/components/inputErrorMessage';
import NextButtonError from 'features/sharedModules/errors/components/nextButtonError';
import { useErrorsStore } from 'features/sharedModules/errors/services/errorsStore';
import { useGetAllOrderExecutionErrors } from 'features/sharedModules/errors/services/useGetAllOrderExecutionErrors';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: Spacing.spacing04
  },
  actionButton: {
    '& + &': {
      marginLeft: '2.4rem'
    }
  },
  advisorNotes: {
    marginTop: Spacing.spacing04
  },
  customField: {
    margin: `0 0 ${Spacing.spacing04}px 0`,

    '& + &': {
      marginTop: 0
    }
  },
  customLabel: {
    fontSize: '3.6rem',
    backgroundColor: 'red'
  },
  errors: {
    marginTop: Spacing.spacing04
  }
});

const OrderInformation = ({ onNavButtonClick }) => {
  const classes = useStyles();
  const isSessionReadOnly = useIsSessionReadOnly();
  const i18n = useI18n();
  const allErrors = useGetAllOrderExecutionErrors();
  const errors = allErrors[RoboAdviceOrderExecutionPages.orderInformation];
  const fields = useInitPage();
  const isErrorModeEnabled = useErrorsStore(state => state.isErrorModeEnabled);

  return (
    fields && (
      <>
        <ErrorsList
          pageName={i18n('roboAdvice.tabs.orderInformation')}
          errors={errors}
        />
        {fields.map(({ name, config, ...restProps }) => (
          <Fragment key={name}>
            {errors[name]?.map(error => (
              <InputErrorMessage
                message={error.alertMessage}
                key={error.elementId}
              />
            ))}
            <ConfigurableComponent
              key={name}
              fieldClassName={classes.customField}
              name={name}
              config={config}
              elementId={name}
              hasError={isErrorModeEnabled && errors[name]?.length}
              {...restProps}
            />
          </Fragment>
        ))}

        <AdvisorNotes
          name={'orderInformationAdvisorNotes'}
          header={i18n('orderExecution.orderInformation.advisorNotes.header')}
          customPlaceholder={i18n(
            'orderExecution.orderInformation.advisorNotes.placeholder'
          )}
          infoTooltipText={i18n(
            'orderExecution.orderInformation.advisorNotes.infoTooltip'
          )}
          disabled={isSessionReadOnly}
          className={classes.advisorNotes}
        />
        <div className={classes.errors}>
          <NextButtonError allErrors={allErrors} />
        </div>
        <OrderExecutionActionButtons
          pageId={RoboAdviceOrderExecutionPages.orderInformation}
          onNavButtonClick={onNavButtonClick}
        />
      </>
    )
  );
};

export default OrderInformation;
