import classNames from 'classnames';
import { ReactElement } from 'react';

import { useErrorsStore } from '../../../sharedModules/errors/services/errorsStore';
import { FontWeights } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  table: {
    borderCollapse: 'collapse',
    width: '100%'
  },
  hasError: {
    border: `2px solid ${theme.errorNegativeColor}`
  },
  headerRow: {
    backgroundColor: theme.itemBackgroundColor_2,
    textAlign: 'left'
  },
  headerCell: {
    color: theme.secondaryColor,
    padding: '12px 16px',
    fontWeight: FontWeights.normal,
    lineHeight: '19px',
    wordBreak: 'break-word',
    minWidth: '100px'
  },
  row: {
    backgroundColor: theme.itemBackgroundColor_2,
    textAlign: 'left',

    '&:nth-child(odd)': {
      backgroundColor: theme.itemBackgroundColor_1
    }
  },
  cell: {
    padding: '10.5px 16px',
    wordBreak: 'break-word'
  }
}));

type Props = {
  headers: (props: { headerCellClassName: string }) => ReactElement;
  body: (props: {
    rowClassName: string;
    cellClassName: string;
  }) => ReactElement;
  className?: string;
  hasError?: boolean;
};

const SimpleTable = ({ body, headers, className, hasError }: Props) => {
  const classes = useStyles();
  const isErrorModeEnabled = useErrorsStore(state => state.isErrorModeEnabled);

  return (
    <table
      className={classNames(
        classes.table,
        {
          [classes.hasError]: hasError && isErrorModeEnabled
        },
        className
      )}
    >
      <thead>
        <tr className={classes.headerRow}>
          {headers({ headerCellClassName: classes.headerCell })}
        </tr>
      </thead>
      <tbody>
        {body({ rowClassName: classes.row, cellClassName: classes.cell })}
      </tbody>
    </table>
  );
};

export default SimpleTable;
