import { useClientPageStore } from '../services/clientPageStore';

export function useCleanupPage() {
  const cleanupPage = () => {
    const clientPageStoreState = useClientPageStore.getState();

    clientPageStoreState.reset();
  };

  return cleanupPage;
}
