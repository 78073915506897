import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import LoadingIndicator from '../loadingIndicator/loadingIndicator';
import { ViewPageOptions } from './constants';
import { CrmLoginGuardProps, CrmLoginGuardReturn } from './types';
import useApi from './useApi';
import {
  OrderExecutionType,
  RoboAdviceAdviceSessionPages,
  RoboAdviceClientPages,
  RoboAdviceOrderExecutionPages
} from 'features/roboAdvice/shared/constants';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  loadingContainer: {
    height: '100vh',
    backgroundColor: theme.pageBackgroundColor
  }
}));

const CrmLoginGuard = ({
  children
}: CrmLoginGuardProps): CrmLoginGuardReturn => {
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const i18n = useI18n();
  const classes = useStyles();

  const {
    tenantSettings: { flowOrderAdviceInformation }
  } = useCustomerConfig();

  const tabs = [
    {
      order: flowOrderAdviceInformation.adviceInformation || 0,
      pageId: RoboAdviceAdviceSessionPages.adviceInformation
    },
    {
      order: flowOrderAdviceInformation.purposeAndRisk || 0,
      pageId: RoboAdviceAdviceSessionPages.purposeAndRisk
    },
    {
      order: flowOrderAdviceInformation.knowledgeAndExperience || 0,
      pageId: RoboAdviceAdviceSessionPages.knowledgeAndExperience
    },
    {
      order: flowOrderAdviceInformation.financialSituation || 0,
      pageId: RoboAdviceAdviceSessionPages.financialSituation
    },
    {
      order: flowOrderAdviceInformation.sustainability || 0,
      pageId: RoboAdviceAdviceSessionPages.sustainability
    }
  ].sort((a, b) => a.order - b.order);

  const { data, status, loading, error } = useApi();

  useLayoutEffect(() => {
    if (status === 200) {
      const { investorType, investorId, viewPage, adviceId, adviceType } = data;

      try {
        if (!investorType || !investorId) {
          throw new Error(
            'investorType and investorId is required for CRM redirect'
          );
        }

        switch (viewPage) {
          case ViewPageOptions.clientInformation:
            history.push(
              routeTemplates.roboAdviceClient.build(
                investorType,
                investorId,
                RoboAdviceClientPages.clientInformation
              ),
              { isCrmRedirect: true }
            );
            break;
          case ViewPageOptions.adviceSessionOverview:
            history.push(
              routeTemplates.roboAdviceClient.build(
                investorType,
                investorId,
                RoboAdviceClientPages.adviceSessions
              ),
              { isCrmRedirect: true }
            );
            break;
          case ViewPageOptions.adviceSession:
            if (!adviceId || !adviceType) {
              throw new Error(
                'adviceId and adviceType is required for CRM redirect to specific advice session'
              );
            }

            if (adviceType === OrderExecutionType) {
              history.push(
                routeTemplates.roboAdviceOrderExecution.build(
                  investorType,
                  investorId,
                  adviceId,
                  RoboAdviceOrderExecutionPages.orderInformation
                ),
                { isCrmRedirect: true }
              );
            } else {
              history.push(
                routeTemplates.roboAdviceAdviceSession.build(
                  investorType,
                  investorId,
                  adviceId,
                  tabs[0].pageId
                ),
                { isCrmRedirect: true }
              );
            }

            break;
          case ViewPageOptions.kyc:
            history.push(
              routeTemplates.roboAdviceClient.build(
                investorType,
                investorId,
                RoboAdviceClientPages.kyc
              ),
              { isCrmRedirect: true }
            );
            break;
          default:
            history.push(
              routeTemplates.roboAdviceClient.build(
                investorType,
                investorId,
                RoboAdviceClientPages.adviceSessions
              ),
              { isCrmRedirect: true }
            );
            break;
        }
      } catch (error) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.integrationPlatform.couldNotRedirectInvestorDataNotProvidedErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    } else if (status === 204) {
      dispatch(
        notificationActionCreators.showNotification({
          message: i18n('roboAdvice.integrationPlatform.nothingToSyncMessage'),
          type: NotificationTypes.success
        })
      );
    } else if (error === true) {
      dispatch(
        notificationActionCreators.showNotification({
          message: i18n('roboAdvice.integrationPlatform.syncErrorMessage'),
          type: NotificationTypes.error
        })
      );
    }
  }, [loading]);

  if (loading) {
    return (
      <LoadingIndicator
        color={theme.accentColor}
        containerClassName={classes.loadingContainer}
      />
    );
  }

  return children;
};

export default CrmLoginGuard;
