import { map } from 'ramda';
import { v4 } from 'uuid';

import { NotificationTypes } from 'features/shared/constants/notification.js';
import { utcNow } from 'features/shared/utils/dateTime.js';

export const types = {
  SHOW_NOTIFICATION: 'NOTIFICATION/SHOW_NOTIFICATION',
  SHOW_NOTIFICATIONS: 'NOTIFICATION/SHOW_NOTIFICATIONS',
  HIDE_NOTIFICATION: 'NOTIFICATION/HIDE_NOTIFICATION',
  HIDE_NOTIFICATIONS: 'NOTIFICATION/HIDE_NOTIFICATIONS'
};

const getDefaultNotification = () => ({
  id: v4(),
  type: NotificationTypes.info,
  isHideEnabled: true,
  isAutohideEnabled: true,
  autohideMilliseconds: 3500,
  shownTime: utcNow().getTime()
});

export const creators = {
  showNotification: notification => ({
    type: types.SHOW_NOTIFICATION,
    payload: {
      notification: {
        ...getDefaultNotification(),
        ...notification
      }
    }
  }),
  showNotifications: notifications => ({
    type: types.SHOW_NOTIFICATIONS,
    payload: {
      notifications: map(
        notification => ({
          ...getDefaultNotification(),
          ...notification
        }),
        notifications
      )
    }
  }),
  hideNotification: id => ({
    type: types.HIDE_NOTIFICATION,
    payload: {
      id
    }
  }),
  hideNotifications: ids => ({
    type: types.HIDE_NOTIFICATIONS,
    payload: {
      ids
    }
  })
};
