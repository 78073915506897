import queryString from 'query-string';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import LoadingIndicator from '../loadingIndicator/loadingIndicator';
import sessionSelectors from 'features/shared/services/session/selectors';
import auth from 'features/shared/utils/auth';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  page: {
    minHeight: '100vh'
  }
});

const LoginRedirect = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const redirectUrl = queryString.parse(search)?.redirectUrl;
  const isAuthenticated = useSelector(sessionSelectors.getIsAuthenticated);

  React.useEffect(() => {
    if (!isAuthenticated) {
      auth.loginCallback();
    }
  }, [isAuthenticated]);

  if (isAuthenticated) {
    // Url is split to handle query params in the redirect url
    const urlSplit = redirectUrl ? redirectUrl.split('?') : [];
    return (
      <Redirect
        to={{
          pathname: redirectUrl ? urlSplit[0] : '/',
          search: urlSplit.length > 1 ? urlSplit[1] : null
        }}
      />
    );
  }

  return (
    <div className={classes.page}>
      <LoadingIndicator size={100} />
    </div>
  );
};

export default LoginRedirect;
