import classNames from 'classnames';

import { ReactComponent as QuestionMarkWarningIcon } from 'assets/questionMarkWarning.svg';
import { Spacing } from 'features/shared/constants/spacing';
import { FontWeights, Typography } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  alertContainer: {
    display: 'flex',
    marginBottom: Spacing.spacing01
  },
  alertMessage: {
    padding: `${Spacing.spacing00}px`,
    borderRadius: '10px',
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,
    fontWeight: FontWeights.normal,
    background: theme.itemBackgroundColor_1,
    border: `1px solid ${theme.defaultInputStrokeColor}`,
    display: 'flex',
    justifyContent: 'space-between'
  },
  infoIcon: {
    width: '24px',
    height: '24px',
    marginRight: '12px',
    verticalAlign: 'middle'
  },
  messageText: {
    display: 'flex',
    alignItems: 'center'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: Spacing.spacing02
  },
  button: {
    cursor: 'pointer',
    padding: `0 ${Spacing.spacing01}px`,
    color: theme.accentColor,
    borderRadius: '4px',
    outline: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,

    '&:hover': {
      backgroundColor: theme.inputFillFocusColor
    },

    '&:active': {
      color: theme.accentHoverColor,
      backgroundColor: theme.defaultInputFillColor
    },

    '&:focus-visible': {
      outline: `2px solid ${theme.primaryColor}`
    }
  },
  spacer: {
    width: 1,
    height: '100%',
    flexGrow: 0,
    backgroundColor: theme.defaultInputStrokeColor,
    margin: `0 ${Spacing.spacing01}px`
  },
  hasError: {
    border: `2px solid ${theme.errorNegativeColor}`
  }
}));

type Props = {
  onClickYes: () => void;
  onClickNo: () => void;
  id: string;
  hasError?: boolean;
  customMessage?: string;
};

const AdaptedValueQuestionAlert = ({
  onClickNo,
  onClickYes,
  id,
  hasError,
  customMessage
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();

  return true ? (
    <div className={classes.alertContainer}>
      <div
        className={classNames(classes.alertMessage, {
          [classes.hasError]: hasError
        })}
        id={id}
      >
        <div className={classes.messageText}>
          <QuestionMarkWarningIcon className={classes.infoIcon} />
          {customMessage
            ? customMessage
            : i18n('roboAdvice.sustainability.doYouWantToMarkAsAdapted')}
        </div>
        <div className={classes.buttons}>
          <button className={classes.button} onClick={onClickNo}>
            {i18n('shared.no')}
          </button>
          <div className={classes.spacer} />
          <button className={classes.button} onClick={onClickYes}>
            {i18n('shared.yes')}
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default AdaptedValueQuestionAlert;
