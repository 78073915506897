import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { clientForm } from 'features/roboAdvice/client/main/services/clientForm';
import { ClientParams } from 'features/roboAdvice/client/shared/types';
import { isVatNumberValid } from 'features/roboAdvice/shared/helpers/enin';
import { SupportedCountryCode as SupportedCountryCodeType } from 'features/roboAdvice/shared/types';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { ClientTypes } from 'features/shared/constants/session';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useCheckIsOrgNumberValid() {
  const dispatch = useDispatch();
  const i18n = useI18n();

  const { values } = clientForm.getState();
  const { clientType } = useParams<ClientParams>();
  const orgNumber = values?.clientInformation?.orgNumber || null;
  const currentCountryCode =
    values.clientInformation?.country?.toLowerCase() || '';

  const isClientCompany = clientType === ClientTypes.company;
  const isVatValid = isVatNumberValid(
    orgNumber,
    currentCountryCode as SupportedCountryCodeType
  );

  const checkIsOrgNumberValid = () => {
    if (isClientCompany && isVatValid) {
      return true;
    } else {
      dispatch(
        notificationActionCreators.showNotification({
          message: i18n(
            'roboAdvice.clientInformation.generalInformation.orgNumber.missingValue'
          ),
          type: NotificationTypes.error
        })
      );
      return false;
    }
  };

  return checkIsOrgNumberValid;
}
