import * as R from 'ramda';

import { PORTFOLIO_MODES } from '../constants.js';

export const getPortfolio = (portfolioMode, data) => {
  if (portfolioMode === PORTFOLIO_MODES.standard) {
    const {
      dataSources: { roboPortfolio, roboSelection }
    } = data;

    const portfolioItems = R.prop('Portfolio', roboPortfolio);
    const selection = R.prop('Selection', roboSelection);

    const categoriesSelection = R.reduce(
      (acc, c) => {
        if (R.isNil(c.Instruments) || R.isEmpty(c.Instruments)) {
          return acc;
        }

        const instrument = c.Instruments[0];

        return R.assoc(
          c.CategoryId,
          {
            id: instrument.Ticker
          },
          acc
        );
      },
      {},
      selection
    );

    return R.pipe(
      R.map(i => {
        const instrument = categoriesSelection[i.AssetClass.CategoryId];

        if (R.isNil(instrument)) {
          return null;
        }

        return {
          id: instrument.id,
          weight: Number(i.Weight)
        };
      }),
      R.reject(R.isNil)
    )(portfolioItems);
  }

  if (portfolioMode === PORTFOLIO_MODES.customAssetClasses) {
    const { customPortfolio } = data;

    const assetClasses = getAssetClasses(customPortfolio);

    return R.chain(
      c =>
        R.map(
          i => ({
            id: i.id,
            weight: i.weight
          }),
          c.instruments
        ),
      assetClasses
    );
  }

  throw new Error('Portfolio mode is not supported.');
};

export const getPortfolioFromAssetClasses = assetClasses => {
  return R.chain(
    c =>
      R.map(
        i => ({
          id: i.id,
          weight: i.weight
        }),
        c.instruments
      ),
    assetClasses
  );
};

const getAssetClasses = customPortfolio =>
  R.path(['existingPortfolio', 'assetClasses'], customPortfolio);
