import { clone } from 'ramda';
import create from 'zustand';

type EfficientFrontierChart = {
  efficientFrontierChartData: any;
  portfolioRiskReturnChartData: any;
  goalId: string;
  goalName: string;
};

type EfficientFrontierStoreData = {
  efficientFrontierChart: EfficientFrontierChart[];
};

type EfficientFrontierStoreApi = {
  reset: () => void;
  initialize: (initialData: Partial<EfficientFrontierStoreData>) => void;
  setEfficientFrontierChart: (
    efficientFrontierChart?: EfficientFrontierChart
  ) => void;
};

type EfficientFrontierStoreState = EfficientFrontierStoreData &
  EfficientFrontierStoreApi;

const defaultData = {
  efficientFrontierChart: []
};

export const useEfficientFrontierStore = create<EfficientFrontierStoreState>(
  set => ({
    ...defaultData,
    reset: () => {
      set(defaultData);
    },
    setEfficientFrontierChart(efficientFrontierChart) {
      set(state => ({
        efficientFrontierChart: efficientFrontierChart
          ? [...clone(state.efficientFrontierChart), efficientFrontierChart]
          : []
      }));
    },
    initialize(initialData) {
      set(
        state => ({
          ...state,
          ...initialData
        }),
        true
      );
    }
  })
);
