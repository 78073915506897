import React from 'react';

export function useLazyEffect(effect, deps) {
  const initRef = React.useRef(false);

  React.useEffect(() => {
    if (initRef.current) {
      return effect();
    } else {
      initRef.current = true;
    }
  }, deps);
}
