import classNames from 'classnames';
import { format } from 'date-fns';

import { ReactComponent as ENINIcon } from 'assets/enin.svg';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  ENINWrapper: {
    display: 'flex',
    justifyContent: 'right',
    padding: `${Spacing.spacing01}px 0`
  },
  ENINIcon: {
    marginRight: Spacing.spacing01,

    '& path, & circle,': {
      fill: theme.secondaryColor
    }
  },
  ENINDate: {
    color: theme.secondaryColor,
    fontSize: Typography.body1.size,
    lineHeight: Typography.heading1.lineHeight
  }
}));

type Props = {
  className?: string;
  date?: Date | string;
};

const ENINDate = ({ className, date }: Props) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.ENINWrapper, className)}>
      <ENINIcon className={classes.ENINIcon} />
      <span className={classes.ENINDate}>
        {date && format(new Date(date), 'dd.MM.yyyy kk:mm')}
      </span>
    </div>
  );
};

export default ENINDate;
