import { Component } from 'react';
import ReactDOM from 'react-dom';

import config from 'config/index.js';

const portalElement = document.getElementById(config.PORTAL_ELEMENT_ID);

class Portal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    portalElement.appendChild(this.el);
  }

  componentWillUnmount() {
    portalElement.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default Portal;
