import { z } from 'zod';

import { fieldSchema, productAttributeSchema } from './shared';
import { questionSchema } from 'features/roboAdvice/adviceSession/proposal/services/mappingTypes';
import { pageBuilderSectionSchema } from 'features/roboAdvice/adviceSession/shared/types';
import { Layouts } from 'features/roboAdvice/shared/constants';
import { tableType } from 'features/shared/analyticsComponents/sustainability/constants';
import { FieldComponentTypes } from 'features/sharedModules/declarativeForm/constants';

const assetSchema = z.object({
  enabled: z.boolean(),
  id: z.string(),
  name: z.string(),
  priority: z.number(),
  placeholder: z.record(z.string()).optional(),
  label: z.record(z.string()),
  showInAmountForAdvice: z.boolean().optional(),
  type: z.nativeEnum(FieldComponentTypes).optional(),
  layout: z.nativeEnum(Layouts).optional()
});

const advancedSuitabilityTableHeaderSchema = z.object({
  id: z.number(),
  label: z.record(z.string()),
  description: z.record(z.string()).nullable(),
  descriptionSubsections: z.array(z.record(z.string())).nullable()
});

export type AdvancedSuitabilityTableHeader = z.infer<
  typeof advancedSuitabilityTableHeaderSchema
>;

const advancedSuitabilityTableSchema = z.object({
  enabled: z.boolean(),
  header: z.record(z.string()).optional(),
  tableHeaders: z.array(advancedSuitabilityTableHeaderSchema).optional()
});

const advancedSuitabilityTrainingContentSchema = z.object({
  pages: z.array(z.object({ sections: z.array(pageBuilderSectionSchema) })),
  check: z.string()
});

export type AdvancedSuitabilityTrainingContent = z.infer<
  typeof advancedSuitabilityTrainingContentSchema
>;

export const AccountValueTarget = {
  positions: 'positions',
  cashAmount: 'cashAmount'
} as const;

const accountsSyncMappingSchema = z.array(
  z.object({
    accountType: z.string(),
    accountValueTarget: z.nativeEnum(AccountValueTarget),
    financialSituationFieldId: z.string()
  })
);

export type AccountsSyncMapping = z.infer<typeof accountsSyncMappingSchema>;

export const proposalSustainabilityProductAttributeSchema =
  productAttributeSchema.omit({
    location: true
  });

export const roboAdviceFormSchema = z.object({
  adviceInformation: z.object({
    fields: z.array(fieldSchema)
  }),
  riskScorePanel: z.object({
    isRiskScorePanelHidden: z.boolean()
  }),
  riskQuestions: z.object({
    isRisk2Hidden: z.boolean()
  }),
  purposeAndRisk: z.object({
    maxGoalsNumber: z.number(),
    showGoalIcons: z.boolean(),
    expectationOfRisk: z.array(
      z.object({
        id: z.number(),
        imageName: z.string(),
        title: z.string(),
        description: z.string()
      })
    ),
    riskStrategy: z.array(
      z.object({
        id: z.number(),
        title: z.string(),
        description: z.string()
      })
    )
  }),
  proposal: z.object({
    goalsTableColumns: z.object({
      goalName: z.boolean(),
      years: z.boolean(),
      riskScore: z.boolean(),
      monthlyDeposit: z.boolean(),
      firstDeposit: z.boolean(),
      addOnWithdrawal: z.boolean(),
      existingPortfolioValue: z.boolean(),
      newPortfolioValue: z.boolean(),
      expectedAnnualReturn: z.boolean(),
      expectedVolatility: z.boolean(),
      equityPortion: z.boolean(),
      expectedValueInYears: z.boolean()
    }),
    sustainability: z.object({
      tableType: z.nativeEnum(tableType),
      showPreferenceCriteriaTable: z.boolean(),
      productAttributeTxt1:
        proposalSustainabilityProductAttributeSchema.nullable(),
      productAttributeTxt2:
        proposalSustainabilityProductAttributeSchema.nullable(),
      productAttributeTxt3:
        proposalSustainabilityProductAttributeSchema.nullable(),
      productAttributeTxt4:
        proposalSustainabilityProductAttributeSchema.nullable(),
      productAttributeTxt5:
        proposalSustainabilityProductAttributeSchema.nullable(),
      productAttributeBinary1:
        proposalSustainabilityProductAttributeSchema.nullable(),
      productAttributeBinary2:
        proposalSustainabilityProductAttributeSchema.nullable(),
      productAttributeBinary3:
        proposalSustainabilityProductAttributeSchema.nullable(),
      productAttributeBinary4:
        proposalSustainabilityProductAttributeSchema.nullable(),
      productAttributeBinary5:
        proposalSustainabilityProductAttributeSchema.nullable()
    }),
    orderSummary: z.object({
      isPercentColumnEnabled: z.boolean(),
      productAttributeTxt1: productAttributeSchema.nullable(),
      productAttributeTxt2: productAttributeSchema.nullable(),
      productAttributeTxt3: productAttributeSchema.nullable(),
      productAttributeTxt4: productAttributeSchema.nullable(),
      productAttributeTxt5: productAttributeSchema.nullable(),
      productAttributeBinary1: productAttributeSchema.nullable(),
      productAttributeBinary2: productAttributeSchema.nullable(),
      productAttributeBinary3: productAttributeSchema.nullable(),
      productAttributeBinary4: productAttributeSchema.nullable(),
      productAttributeBinary5: productAttributeSchema.nullable()
    }),
    morningstarAttachmentsAvailable: z.array(
      z.object({
        type: z.number(),
        label: z.record(z.string()).optional(),
        labelKey: z.string().optional()
      })
    ),
    isOverrideFundManagementFeeEnabled: z.boolean(),
    isOverrideFundTransactionCostEnabled: z.boolean(),
    isOverrideFundPurchasingFeeEnabled: z.boolean(),
    isOverrideFundReturnCommissionPaidToClientEnabled: z.boolean(),
    isOverrideFundOngoingFeesAdvisorNotesRequired: z.boolean(),
    isOverrideFundOneTimeFeesAdvisorNotesRequired: z.boolean(),
    isOverrideSummaryPlatformFeeEnabled: z.boolean(),
    isOverrideSummaryCustodyFeeEnabled: z.boolean(),
    isOverrideSummaryCustodyFeeAmountEnabled: z.boolean(),
    isOverrideSummaryPortfolioAdvisoryOnboardingFeeEnabled: z.boolean(),
    isOverrideSummaryPortfolioAdvisoryPurchasingFeeEnabled: z.boolean(),
    isOverrideSummaryAnnualOngoingFeesAdvisorNotesRequired: z.boolean(),
    isOverrideSummaryCustodyFeesAdvisorNotesRequired: z.boolean(),
    isOverrideSummaryOneTimeFeesAdvisorNotesRequired: z.boolean()
  }),
  knowledgeAndExperienceQuestions: z.object({
    questions: z.array(questionSchema)
  }),
  financialSituation: z.object({
    oneFieldInAssetsRequired: z.boolean(),
    oneFieldInDebtRequired: z.boolean(),
    isAmountForAdviceEnabled: z.boolean(),
    assets: z.object({
      person: z.array(assetSchema),
      company: z.array(assetSchema)
    }),
    debt: z.object({
      person: z.array(assetSchema),
      company: z.array(assetSchema)
    }),
    liquidity: z.object({
      person: z.array(assetSchema),
      company: z.array(assetSchema)
    }),
    accountingFigures: z.array(assetSchema),
    isEninAccountingFiguresEnabled: z.boolean().optional(),
    isRiskAssessmentButtonVisible: z.boolean().optional(),
    isRiskAssessmentVisible: z.boolean().optional(),
    accounts: z.object({
      sync: z.object({
        enabled: z.boolean(),
        mapping: z.object({
          person: accountsSyncMappingSchema,
          company: accountsSyncMappingSchema
        })
      }),
      automaticTaggingOfAssetClassEnabled: z.boolean()
    }),
    taggingOfAssetClassesIsMandatory: z.boolean()
  }),
  advisory: z.object({
    validateIfSavingPlanSuitable: z.boolean()
  }),
  knowledgeAndExperience: z.object({
    advancedSuitability: z.object({
      enabled: z.boolean(),
      showTraining: z.boolean(),
      custom1: advancedSuitabilityTableSchema,
      custom2: advancedSuitabilityTableSchema,
      experience: advancedSuitabilityTableSchema,
      knowledge: advancedSuitabilityTableSchema,
      tradingAmount: advancedSuitabilityTableSchema,
      trainingContent: z
        .array(advancedSuitabilityTrainingContentSchema)
        .optional(),
      checksTranslations: z.array(
        z.object({
          id: z.string(),
          label: z.record(z.string())
        })
      )
    })
  }),
  errorMessagesEnabled: z.boolean()
});

export type RoboAdviceForm = z.infer<typeof roboAdviceFormSchema>;

export const defaultRoboAdviceForm: RoboAdviceForm = {
  adviceInformation: {
    fields: [
      {
        name: 'adviceInformation.meetingPlace',
        label: {
          en: 'Where did the meeting take place?',
          no: 'Hvor ble møtet avholdt?'
        },
        componentType: 'radioButtons',
        order: 1,
        required: true,
        isEnabled: true,
        items: [
          {
            activeValue: 'quantfolioOffices',
            label: { en: 'Quantfolio offices', no: 'Quantfolio kontoret' }
          },
          {
            activeValue: 'phone',
            label: { en: 'Phone', no: 'Telefon' }
          },
          {
            activeValue: 'online',
            label: { en: 'Online', no: 'Digitalt' }
          },
          {
            activeValue: 'other',
            label: { en: 'Other', no: 'Annet' },
            textField: {
              name: 'adviceInformation.meetingPlaceOther',
              required: true
            }
          }
        ]
      },
      {
        name: 'adviceInformation.whoInitiatedMeeting',
        label: {
          en: 'Who initiated the meeting?',
          no: 'Hvem tok initiativ til møtet?'
        },
        componentType: 'radioButtons',
        order: 2,
        required: true,
        isEnabled: true,
        items: [
          {
            activeValue: 'advisor',
            label: { en: 'The advisor', no: 'Rådgiver' }
          },
          {
            activeValue: 'customer',
            label: { en: 'The customer', no: 'Kunde' }
          }
        ]
      }
    ]
  },
  riskScorePanel: {
    isRiskScorePanelHidden: false
  },
  riskQuestions: {
    isRisk2Hidden: false
  },
  purposeAndRisk: {
    maxGoalsNumber: 10,
    showGoalIcons: true,

    expectationOfRisk: [
      {
        id: 1,
        imageName: 'sun_lounger',
        title: 'roboAdvice.purposeAndRisk.expectationOfRisk.lowRisk',
        description:
          'roboAdvice.purposeAndRisk.expectationOfRisk.lowRiskDescription'
      },
      {
        id: 2,
        imageName: 'bike',
        title: 'roboAdvice.purposeAndRisk.expectationOfRisk.mediumRisk',
        description:
          'roboAdvice.purposeAndRisk.expectationOfRisk.mediumRiskDescription'
      },
      {
        id: 3,
        imageName: 'zipline',
        title: 'roboAdvice.purposeAndRisk.expectationOfRisk.highRisk',
        description:
          'roboAdvice.purposeAndRisk.expectationOfRisk.highRiskDescription'
      },
      {
        id: 4,
        imageName: 'paragliding',
        title: 'roboAdvice.purposeAndRisk.expectationOfRisk.maximumRisk',
        description:
          'roboAdvice.purposeAndRisk.expectationOfRisk.maximumRiskDescription'
      }
    ],
    riskStrategy: [
      {
        id: 1,
        title: 'roboAdvice.purposeAndRisk.riskStrategy.scenarioA',
        description:
          'roboAdvice.purposeAndRisk.riskStrategy.scenarioADescription'
      },
      {
        id: 2,
        title: 'roboAdvice.purposeAndRisk.riskStrategy.scenarioB',
        description:
          'roboAdvice.purposeAndRisk.riskStrategy.scenarioBDescription'
      },
      {
        id: 3,
        title: 'roboAdvice.purposeAndRisk.riskStrategy.scenarioC',
        description:
          'roboAdvice.purposeAndRisk.riskStrategy.scenarioCDescription'
      },
      {
        id: 4,
        title: 'roboAdvice.purposeAndRisk.riskStrategy.scenarioD',
        description:
          'roboAdvice.purposeAndRisk.riskStrategy.scenarioDDescription'
      }
    ]
  },
  proposal: {
    goalsTableColumns: {
      goalName: true,
      years: true,
      riskScore: true,
      monthlyDeposit: true,
      firstDeposit: true,
      addOnWithdrawal: true,
      existingPortfolioValue: true,
      newPortfolioValue: true,
      expectedAnnualReturn: true,
      expectedVolatility: true,
      equityPortion: true,
      expectedValueInYears: true
    },
    isOverrideFundManagementFeeEnabled: false,
    isOverrideFundTransactionCostEnabled: false,
    isOverrideFundPurchasingFeeEnabled: false,
    isOverrideFundReturnCommissionPaidToClientEnabled: false,
    isOverrideFundOngoingFeesAdvisorNotesRequired: false,
    isOverrideFundOneTimeFeesAdvisorNotesRequired: false,
    isOverrideSummaryPlatformFeeEnabled: false,
    isOverrideSummaryCustodyFeeEnabled: false,
    isOverrideSummaryCustodyFeeAmountEnabled: false,
    isOverrideSummaryPortfolioAdvisoryOnboardingFeeEnabled: false,
    isOverrideSummaryPortfolioAdvisoryPurchasingFeeEnabled: false,
    isOverrideSummaryAnnualOngoingFeesAdvisorNotesRequired: false,
    isOverrideSummaryCustodyFeesAdvisorNotesRequired: false,
    isOverrideSummaryOneTimeFeesAdvisorNotesRequired: false,
    sustainability: {
      tableType: 'tableStandard',
      showPreferenceCriteriaTable: false,
      productAttributeTxt1: null,
      productAttributeTxt2: null,
      productAttributeTxt3: null,
      productAttributeTxt4: null,
      productAttributeTxt5: null,
      productAttributeBinary1: null,
      productAttributeBinary2: null,
      productAttributeBinary3: null,
      productAttributeBinary4: null,
      productAttributeBinary5: null
    },
    orderSummary: {
      isPercentColumnEnabled: true,
      productAttributeTxt1: null,
      productAttributeTxt2: null,
      productAttributeTxt3: null,
      productAttributeTxt4: null,
      productAttributeTxt5: null,
      productAttributeBinary1: null,
      productAttributeBinary2: null,
      productAttributeBinary3: null,
      productAttributeBinary4: null,
      productAttributeBinary5: null
    },
    morningstarAttachmentsAvailable: []
  },
  knowledgeAndExperienceQuestions: {
    questions: [
      {
        order: 1,
        name: 'dropInStd',
        riskApiParam: 'drop_in_std',
        options: [
          {
            id: 1,
            order: 1,
            translationKey: null,
            translations: {
              no: '',
              en: 'I would use this as an opportunity to invest more'
            }
          },

          {
            id: 2,
            order: 2,
            translationKey: null,
            translations: {
              no: '',
              en: 'I would not make changes'
            }
          },
          {
            id: 3,
            order: 3,
            translationKey: null,
            translations: {
              no: '',
              en: 'I will get uneasy but not sell my investment immediately'
            }
          },
          {
            id: 4,
            order: 4,
            translationKey: null,
            translations: {
              no: '',
              en: 'I would sell my investments'
            }
          }
        ],
        popupText: null,
        translationKey: null,
        translations: {
          no: '',
          en: 'If your investments fall X% in value, how would you react?'
        }
      },
      {
        order: 2,
        name: 'dropInPercent',
        riskApiParam: 'drop_in_percent',
        options: [
          {
            id: 1,
            order: 1,
            translationKey: null,
            translations: {
              no: '',
              en: 'This investment is not important for my financial security'
            }
          },
          {
            id: 2,
            order: 2,
            translationKey: null,
            translations: {
              no: '',
              en: '40%'
            }
          },
          {
            id: 3,
            order: 3,
            translationKey: null,
            translations: {
              no: '',
              en: '20%'
            }
          },
          {
            id: 4,
            order: 4,
            translationKey: null,
            translations: {
              no: '',
              en: '10%'
            }
          }
        ],
        popupText: null,
        translationKey: null,
        translations: {
          no: '',
          en: 'How much can your investment fall in value before it affects your financial security?'
        }
      },
      {
        order: 3,
        name: 'educationWork',
        riskApiParam: 'education_work',
        options: [
          {
            id: 3,
            order: 3,
            translationKey: null,
            translations: {
              no: '',
              en: 'I have work experience that makes me understand financial products and services'
            }
          },
          {
            id: 2,
            order: 2,
            translationKey: null,
            translations: {
              no: '',
              en: 'I have education in economics and finance'
            }
          },
          {
            id: 1,
            order: 1,
            translationKey: null,
            translations: {
              no: '',
              en: 'I have no experience'
            }
          }
        ],
        popupText: null,
        translationKey: null,
        translations: {
          no: '',
          en: 'What knowledge and experience do you have with investing in funds?'
        }
      },
      {
        order: 4,
        name: 'experience',
        riskApiParam: 'experience',
        options: [
          {
            id: 3,
            order: 3,
            translationKey: null,
            translations: {
              no: '',
              en: 'More than 10'
            }
          },
          {
            id: 2,
            order: 2,
            translationKey: null,
            translations: {
              no: '',
              en: 'Between 5 and 10'
            }
          },
          {
            id: 1,
            order: 1,
            translationKey: null,
            translations: {
              no: '',
              en: 'Less than 5'
            }
          }
        ],
        popupText: null,
        translationKey: null,
        translations: {
          no: '',
          en: 'How many times have you traded stocks or funds over the last five years?'
        }
      }
    ]
  },
  financialSituation: {
    oneFieldInAssetsRequired: true,
    oneFieldInDebtRequired: true,
    isAmountForAdviceEnabled: false,
    assets: {
      person: [
        {
          enabled: true,
          id: 'realEstate',
          name: 'Real Estate',
          priority: 100,
          label: { en: 'Real Estate', no: 'Eiendom' },
          showInAmountForAdvice: false
        },
        {
          enabled: true,
          id: 'valueOfCar',
          name: 'Value of a car/ boat',
          priority: 200,
          label: { en: 'Car', no: 'Bil' },
          showInAmountForAdvice: false
        },
        {
          enabled: true,
          id: 'investmentPortfolio',
          name: 'Investment Portfolio',
          priority: 300,
          label: { en: 'Investment portfolio', no: 'Investeringsportefølje' },
          showInAmountForAdvice: true
        },
        {
          enabled: true,
          id: 'cash',
          name: 'Cash',
          priority: 400,
          label: { en: 'Cash', no: 'Kontanter' },
          showInAmountForAdvice: true
        },
        {
          enabled: true,
          id: 'otherAssets',
          name: 'Other',
          priority: 500,
          label: { en: 'Other', no: 'Annet' },
          showInAmountForAdvice: false
        }
      ],
      company: [
        {
          enabled: true,
          id: 'realEstate',
          name: 'Real Estate',
          priority: 100,
          label: { en: 'Real Estate', no: 'Eiendom' },
          showInAmountForAdvice: false
        },
        {
          enabled: true,
          id: 'investmentPortfolio',
          name: 'Investment portfolio',
          priority: 200,
          label: { en: 'Investment portfolio', no: 'Investeringsportefølje' },
          showInAmountForAdvice: true
        },
        {
          enabled: true,
          id: 'cash',
          name: 'Cash',
          priority: 300,
          label: { en: 'Cash', no: 'Kontanter' },
          showInAmountForAdvice: true
        },
        {
          enabled: true,
          id: 'otherInvestableAssets',
          name: 'Other',
          priority: 400,
          label: { en: 'Other', no: 'Annet' },
          showInAmountForAdvice: false
        }
      ]
    },
    debt: {
      person: [
        {
          id: 'mortgage',
          enabled: true,
          name: 'Mortgage',
          label: { en: 'Mortgage', no: 'Huslån' },
          priority: 100
        },
        {
          id: 'carLoan',
          enabled: true,
          name: 'Car or boat loan',
          label: { en: 'Car loan', no: 'Billån' },
          priority: 200
        },
        {
          id: 'studentLoan',
          enabled: true,
          name: 'Money in stock/fund',
          label: { en: 'Student loan', no: 'Studielån' },
          priority: 300
        },
        {
          id: 'debt',
          enabled: true,
          name: 'Debt',
          label: { en: 'Debt', no: 'Gjeld' },
          priority: 400
        },
        {
          id: 'otherDebt',
          enabled: true,
          name: 'Other',
          label: { en: 'Other', no: 'Annet' },
          priority: 500
        }
      ],
      company: [
        {
          id: 'shortTermDebt',
          enabled: true,
          name: 'Short-term debt',
          label: { en: 'Short-term debt', no: 'Kortsiktig gjeld' },
          priority: 100
        },
        {
          id: 'longTermDebt',
          enabled: true,
          name: 'Long-term debt',
          label: { en: 'Long-term debt', no: 'Langsiktig gjeld' },
          priority: 200
        },
        {
          id: 'otherDebt',
          enabled: true,
          name: 'Other debt',
          label: { en: 'Other debt', no: 'Annen gjeld' },
          priority: 300
        }
      ]
    },
    liquidity: {
      person: [
        {
          id: 'sufficientBuffer',
          enabled: true,
          name: 'Sufficient buffer',
          label: { en: 'Sufficient buffer', no: 'Tilstrekkelig bufferkapital' },
          type: 'buttonSwitch',
          layout: 'horizontal',
          priority: 100
        },
        {
          id: 'monthlySurplus',
          enabled: true,
          name: 'Monthly Surplus',
          label: { en: 'Monthly Surplus', no: 'Månedlig overskudd' },
          type: 'numberInput',
          priority: 200
        }
      ],
      company: [
        {
          id: 'requiredWorkingCapital',
          enabled: true,
          name: 'Required working capital',
          label: {
            en: 'Required working capital',
            no: 'Nødvendig arbeidskapital'
          },
          priority: 100
        },
        {
          id: 'annualSurplus',
          enabled: true,
          name: 'Annual surplus',
          label: { en: 'Annual surplus', no: 'Estimert årlig overskudd' },
          priority: 200
        }
      ]
    },
    accountingFigures: [
      {
        id: 'revenues',
        enabled: true,
        name: 'Revenues',
        label: { en: 'Revenues', no: 'Inntekter' },
        priority: 100
      },
      {
        id: 'costs',
        enabled: true,
        name: 'Costs',
        label: { en: 'Costs', no: 'Kostnader' },
        priority: 200
      },
      {
        id: 'operationalResult',
        enabled: true,
        name: 'Operational result',
        label: { en: 'Operational result', no: 'Operasjonelt resultat' },
        priority: 300
      },
      {
        id: 'preTaxResult',
        enabled: true,
        name: 'Pre-tax result',
        label: { en: 'Pre-tax result', no: 'Resultat før skatt' },
        priority: 400
      },
      {
        id: 'yearlyResult',
        enabled: true,
        name: 'Yearly result',
        label: { en: 'Yearly result', no: 'Årlig resultat' },
        priority: 500
      },
      {
        id: 'sumEquity',
        enabled: true,
        name: 'Sum Equity',
        label: { en: 'Sum equity', no: 'Sum eiendeler' },
        priority: 600
      },
      {
        id: 'sumDebt',
        enabled: true,
        name: 'Sum Debt',
        label: { en: 'Sum debt', no: 'Sum Gjeld' },
        priority: 700
      }
    ],
    isEninAccountingFiguresEnabled: false,
    accounts: {
      sync: {
        enabled: false,
        mapping: {
          person: [
            {
              accountType: 'ASK',
              accountValueTarget: 'cashAmount',
              financialSituationFieldId: 'cash'
            },
            {
              accountType: 'ASK',
              accountValueTarget: 'positions',
              financialSituationFieldId: 'investmentPortfolio'
            },
            {
              accountType: 'InvestmentAccount',
              accountValueTarget: 'cashAmount',
              financialSituationFieldId: 'cash'
            },
            {
              accountType: 'InvestmentAccount',
              accountValueTarget: 'positions',
              financialSituationFieldId: 'investmentPortfolio'
            }
          ],
          company: [
            {
              accountType: 'ASK',
              accountValueTarget: 'cashAmount',
              financialSituationFieldId: 'cash'
            },
            {
              accountType: 'ASK',
              accountValueTarget: 'positions',
              financialSituationFieldId: 'investmentPortfolio'
            },
            {
              accountType: 'InvestmentAccount',
              accountValueTarget: 'cashAmount',
              financialSituationFieldId: 'cash'
            },
            {
              accountType: 'InvestmentAccount',
              accountValueTarget: 'positions',
              financialSituationFieldId: 'investmentPortfolio'
            }
          ]
        }
      },
      automaticTaggingOfAssetClassEnabled: false
    },
    isRiskAssessmentButtonVisible: false,
    isRiskAssessmentVisible: false,
    taggingOfAssetClassesIsMandatory: false
  },
  advisory: {
    validateIfSavingPlanSuitable: true
  },
  knowledgeAndExperience: {
    advancedSuitability: {
      enabled: false,
      showTraining: false,
      knowledge: { enabled: false },
      experience: { enabled: false },
      tradingAmount: { enabled: false },
      custom1: { enabled: false },
      custom2: { enabled: false },
      checksTranslations: []
    }
  },
  errorMessagesEnabled: true
};
