import { CostChart } from '@quantfoliorepo/ui-components';
import { isEmpty, isNil } from 'ramda';
import { useState, useMemo } from 'react';

import CostSummary from './costSummary';
import FundsTables from './fundsTables';
import { mapChartStoreTableData } from './mapping';
import YearsTables from './yearsTables';
import { costForm } from 'features/roboAdvice/adviceSession/proposal/services/costForm';
import SmallButtonGroup from 'features/shared/components/buttonGroup/smallButtonGroup';
import LoadingIndicator from 'features/shared/components/loadingIndicator/loadingIndicator';
import { Colors } from 'features/shared/constants/colors';
import { Typography } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  buttonGroup: {
    padding: '2rem 0',
    width: '62.5%'
  },
  warning: {
    color: theme.errorNegativeColor,
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight,
    marginTop: 8,
    textAlign: 'left',
    width: '62.5%'
  },
  item: {
    flexGrow: 1,
    display: 'block'
  },
  noDataMessage: {
    paddingTop: '40px',
    paddingBottom: '40px',
    display: 'flex',
    justifyContent: 'center',
    color: Colors.gray50
  }
}));

const CostChartViewVisual = ({
  chartStoreTableData,
  chartStoreChartData,
  cultureCode,
  isLoading = false,
  isOverrideFundEnabled = false,
  chartStoreOriginalTableData = null
}) => {
  const classes = useStyles();
  const i18n = useI18n();
  const [activeTableIndex, setActiveTableIndex] = useState('costSummary');

  const startYear = useMemo(() => new Date().getUTCFullYear() + 1, []);
  const tableData = useMemo(
    () =>
      mapChartStoreTableData({
        chartStoreTableData,
        cultureCode,
        i18n
      }),
    [chartStoreTableData]
  );

  const originalTableData =
    !isNil(chartStoreOriginalTableData) && !isEmpty(chartStoreOriginalTableData)
      ? mapChartStoreTableData({
          chartStoreTableData: chartStoreOriginalTableData,
          cultureCode,
          i18n
        })
      : null;

  const buttonTitles = useMemo(
    () =>
      [
        {
          title: i18n('roboAdvice.proposal.cost.summary'),
          enabled: true,
          id: 'costSummary'
        },
        {
          title: i18n('roboAdvice.proposal.cost.year1'),
          enabled: true,
          id: 'costYear1'
        },
        {
          title: `${i18n('roboAdvice.proposal.cost.yearX')} ${
            chartStoreChartData?.length >= 11 ? 10 : chartStoreChartData?.length
          }`,
          enabled: chartStoreChartData?.length > 1,
          id: 'costYearX'
        },
        {
          title: i18n('roboAdvice.proposal.cost.fundDetailsAmount'),
          enabled: true,
          id: 'costFundDetailsAmount'
        },
        {
          title: i18n('roboAdvice.proposal.cost.fundDetails%'),
          enabled: true,
          id: 'costFundDetailsPercent'
        }
      ].filter(item => item.enabled),
    [chartStoreChartData]
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isEmpty(chartStoreChartData) || isEmpty(chartStoreTableData)) {
    return (
      <div className={classes.noDataMessage}>
        {i18n('roboAdvice.proposal.noCostData')}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <CostChart
        items={chartStoreChartData}
        startYear={startYear}
        width={600}
      />
      <SmallButtonGroup
        className={classes.buttonGroup}
        itemClassName={classes.item}
        items={buttonTitles.map(item => ({
          title: item.title,
          isActive: activeTableIndex === item.id,
          onClick: () => {
            setActiveTableIndex(item.id);
            if (!costForm.getState().pristine) {
              costForm.restart();
            }
          }
        }))}
      />
      {activeTableIndex === 'costSummary' && tableData.summary && (
        <CostSummary
          summaryData={tableData.summary}
          originalTableData={originalTableData?.summary}
        />
      )}
      {activeTableIndex === 'costYear1' && tableData.firstYear && (
        <YearsTables elementsData={tableData.firstYear} numberOfYears={1} />
      )}
      {activeTableIndex === 'costYearX' && tableData.lastYear && (
        <YearsTables
          elementsData={tableData.lastYear}
          numberOfYears={
            chartStoreChartData?.length > 10 ? 10 : chartStoreChartData?.length
          }
        />
      )}
      {activeTableIndex === 'costFundDetailsAmount' &&
        tableData.fundsCurrency && (
          <FundsTables fundsDetailsData={tableData.fundsCurrency} />
        )}
      {activeTableIndex === 'costFundDetailsPercent' &&
        tableData.fundsPercent && (
          <FundsTables
            isOverrideFundEnabled={isOverrideFundEnabled}
            fundsDetailsData={tableData.fundsPercent}
            originalTableData={originalTableData?.fundsPercent}
          />
        )}
    </div>
  );
};

export default CostChartViewVisual;
