import { format } from 'date-fns';
import { ValidationErrors } from 'final-form';
import {
  always,
  append,
  isEmpty,
  isNil,
  map,
  path,
  pipe,
  range,
  reject,
  split,
  zip
} from 'ramda';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import { NamespaceStatus } from '../../advisory/types';
import { getTotalValueResult } from '../../detalizedNumberInput/services/selectors.js';
import {
  FieldSections,
  getCompanyLiquidity,
  getFinancialSituationFields,
  getPersonLiquidity
} from '../../financialSituation/constants';
import {
  getCompanyDebtTotalResult,
  getCompanyInvestableAssetsTotalResult,
  getPersonAssetsTotalResult,
  getPersonDebtTotalResult
} from '../../financialSituation/services/selectors';
import { AdviceSessionFormValues } from '../../form/services/form';
import { useSessionStore } from '../../session/services/sessionStore';
import {
  AdvancedSuitabilityStatus,
  ReadAnalyzeAdvancedSuitabilityConfigResponse,
  ReadAnalyzeAdvancedSuitabilityStatusResponse
} from '../../shared/api/types';
import { FlatAssetClass } from '../../shared/mapping';
import { Goal } from '../../shared/services/goalsStore';
import { Instrument } from '../../shared/services/goalsStore';
import { getCustomFieldsValues } from '../../shared/services/mapping';
import {
  ClientInformation,
  CompanyFinancialSituation,
  FinancialSituationField,
  KnowledgeAndExperienceQuestions,
  PersonFinancialSituation
} from './mappingTypes';
import { FinancialSituationFields } from './mappingTypes';
import { RoboAdviceForm } from 'defaults/defaultRoboAdviceForm';
import { quizDateFields } from 'features/roboAdvice/adviceSession/knowledgeAndExperience/constants';
import { dateFormats } from 'features/shared/components/datePicker/constants';
import { ClientTypes } from 'features/shared/constants/session';
import { formatDate } from 'features/shared/utils/dateTime';
import { formatNumber } from 'features/shared/utils/number';
import { getTranslation } from 'features/shared/utils/translations';
import { FieldComponentTypes } from 'features/sharedModules/declarativeForm/constants';

const formatPhoneNumber = phoneNumber => {
  if (isNil(phoneNumber) || isEmpty(phoneNumber)) {
    return null;
  }

  const formattedPhoneNumber = formatPhoneNumberIntl(phoneNumber);

  if (isNil(formattedPhoneNumber) || isEmpty(formattedPhoneNumber)) {
    return phoneNumber;
  }

  return formattedPhoneNumber;
};

const getAddress = (address: string, zipCode: string, city: string) =>
  `${address ? address + ', ' : ''}${zipCode ?? ''} ${city ?? ''}`;

export const getClientInformation = (
  i18n: (key: string) => string,
  clientInformation: any,
  roboAdviceConfig: Record<string, any>,
  isContactListEnabled = false,
  isSignatureRightsEnabled = false,
  isUBOEnabled = false
): ClientInformation => {
  let fieldsConfig: any[] = [];

  if (clientInformation.clientType === ClientTypes.person) {
    fieldsConfig = roboAdviceConfig.person;
  } else if (clientInformation.clientType === ClientTypes.company) {
    fieldsConfig = roboAdviceConfig.company;
  }

  const values = { clientInformation };
  const clientInformationMapped: any[] = fieldsConfig.map(f => {
    if (f.name === 'clientInformation.clientType') {
      return null;
    }

    const value = path(f.name.split('.'), values);

    if (f.componentType === FieldComponentTypes.dropdown) {
      const optionValue =
        (f.options && f.options.find(o => o.key === value)) ||
        (f.items && f.items.find(o => o.activeValue === value));

      return {
        value: optionValue?.label
          ? getTranslation(optionValue.label)
          : i18n(optionValue?.titleKey) || optionValue?.titleKey || value || '',
        title: f.label ? getTranslation(f.label) : i18n(f.labelKey) || '',
        order: f.order || 0,
        name: f.name
      };
    }

    if (f.componentType === FieldComponentTypes.datepicker) {
      const dateFormat = f.dateFormat || dateFormats.dayMonthYear;
      const formatedDate = value
        ? format(new Date(`${value}`), dateFormat)
        : '';

      return {
        value: formatedDate,
        title: f.label ? getTranslation(f.label) : i18n(f.labelKey),
        order: f.order,
        name: f.name
      };
    }

    return {
      value,
      title: f.label ? getTranslation(f.label) : i18n(f.labelKey),
      order: f.order,
      name: f.name
    };
  });

  return {
    clientType: clientInformation.clientType,
    accountNumber:
      path(['additionalData', 'accountNumber'], clientInformation) || '',
    fields: reject(isNil, clientInformationMapped),
    contacts: clientInformation?.contacts || [],
    isContactListEnabled,
    isSignatureRightsEnabled,
    isUBOEnabled
  };
};

const getCompanyFinancialSituation = (
  i18n: (key: string) => string,
  cultureCode: string,
  roboAdviceFormValues: AdviceSessionFormValues,
  roboAdviceFormErrors: ValidationErrors,
  companyInvestableAssetsTotal: number | null,
  companyDebtTotal: number | null,
  companyAssetsFields: FinancialSituationFields,
  companyDebtFields: FinancialSituationFields,
  companyAccountingFiguresFields: FinancialSituationFields
): CompanyFinancialSituation => {
  const mapDetalizedNumberInputFields = (fields: FinancialSituationFields) =>
    fields.map(f => {
      return {
        title: getTranslation(f[1].render?.label),
        value:
          f[0] === 'companyFinancialSituation.accountingYear'
            ? path<any>(split('.', f[0]), roboAdviceFormValues)
            : formatNumber(
                cultureCode,
                getTotalValueResult({
                  name: f[0],
                  formState: {
                    values: roboAdviceFormValues,
                    errors: roboAdviceFormErrors
                  }
                }),
                0,
                2
              )
      };
    });
  return {
    investableAssets: append(
      {
        title: i18n('roboAdvice.financialSituation.sumAssets'),
        value: formatNumber(cultureCode, companyInvestableAssetsTotal, 0, 2)
      },
      mapDetalizedNumberInputFields(companyAssetsFields)
    ),
    accountingFigures: mapDetalizedNumberInputFields(
      companyAccountingFiguresFields
    ),
    debt: append(
      {
        title: i18n('roboAdvice.financialSituation.sumDebt'),
        value: formatNumber(cultureCode, companyDebtTotal, 0, 2)
      },
      mapDetalizedNumberInputFields(companyDebtFields)
    )
  };
};

export const getFinancialSituation = ({
  i18n,
  cultureCode,
  clientType,
  roboAdviceForm,
  financialSituation
}) => {
  if (clientType === ClientTypes.person) {
    const personAssetsFields = getFinancialSituationFields(
      financialSituation.assets.person,
      FieldSections.personAssets,
      ClientTypes.person
    );

    const personDebtFields = getFinancialSituationFields(
      financialSituation.debt.person,
      FieldSections.personDebt,
      ClientTypes.person
    );

    const personLiquidityFields = getPersonLiquidity(
      financialSituation.liquidity.person
    );

    const personAssetsTotal = getPersonAssetsTotalResult({
      roboAdviceForm,
      personAssetsFields
    });

    const personDebtTotal = getPersonDebtTotalResult({
      roboAdviceForm,
      personDebtFields
    });

    const liquidity = getCustomFieldsValues({
      fields: personLiquidityFields.map(f => ({
        name: f[0],
        label: f[1].render.label,
        componentType: f[1].render.componentType,
        items: f[1].render.items,
        dateFormat: f[1].render.dateFormat
      })),
      i18n,
      form: roboAdviceForm
    });

    return {
      liquidity,
      personFinancialSituation: getPersonFinancialSituation(
        i18n,
        cultureCode,
        roboAdviceForm.values,
        roboAdviceForm.errors,
        personAssetsTotal,
        personDebtTotal,
        personAssetsFields,
        personDebtFields
      )
    };
  } else {
    const companyAssetsFields = getFinancialSituationFields(
      financialSituation.assets.company,
      FieldSections.companyInvestableAssets,
      ClientTypes.company
    );
    const companyDebtFields = getFinancialSituationFields(
      financialSituation.debt.company,
      FieldSections.companyDebt,
      ClientTypes.company
    );

    const companyLiquidityFields = getCompanyLiquidity(
      financialSituation.liquidity.company
    );

    const companyAccountingFiguresFields = getFinancialSituationFields(
      financialSituation.accountingFigures,
      FieldSections.companyAccountingFigures,
      ClientTypes.company
    );

    const companyInvestableAssetsTotal = getCompanyInvestableAssetsTotalResult({
      roboAdviceForm,
      companyAssetsFields
    });

    const companyDebtTotal = getCompanyDebtTotalResult({
      roboAdviceForm,
      companyDebtFields
    });

    const liquidity = getCustomFieldsValues({
      fields: companyLiquidityFields.map(f => ({
        name: f[0],
        label: f[1].render.label,
        componentType: f[1].render.componentType,
        items: f[1].render.items,
        dateFormat: f[1].render.dateFormat
      })),
      i18n,
      form: roboAdviceForm
    });

    return {
      liquidity,
      companyFinancialSituation: getCompanyFinancialSituation(
        i18n,
        cultureCode,
        roboAdviceForm.values,
        roboAdviceForm.errors,
        companyInvestableAssetsTotal,
        companyDebtTotal,
        companyAssetsFields,
        companyDebtFields,
        companyAccountingFiguresFields
      )
    };
  }
};

export const getGeneralInformation = (
  clientInformation: Record<string, any>,
  userName: string
) => {
  return {
    name: clientInformation?.name || clientInformation?.companyName,
    email: clientInformation?.email,
    ssn: clientInformation?.ssn,
    phoneNumber: formatPhoneNumber(clientInformation?.phoneNumber),
    number: clientInformation?.ssn || clientInformation?.orgNumber,
    address: getAddress(
      clientInformation?.address,
      clientInformation?.zipCode,
      clientInformation?.city
    ),
    advisorName: userName,
    clientName: clientInformation?.name || clientInformation?.companyName
  };
};

export const getKnowledgeAndExperience = (
  roboAdviceFormValues: AdviceSessionFormValues,
  knowledgeAndExperienceQuestions: KnowledgeAndExperienceQuestions,
  cultureCode: string,
  i18n: (key: string) => string,
  language: string
) => {
  return knowledgeAndExperienceQuestions.questions
    .map(({ name, translations, translationKey, options }) => {
      const answer = options.find(
        ({ id }) => id === path(name.split('.'), roboAdviceFormValues)
      );
      const answerText: string = answer
        ? getTranslationText(
            answer.translationKey,
            answer.translations,
            i18n,
            language
          )
        : '';
      return isNil(answer)
        ? null
        : {
            question: getTranslationText(
              translationKey,
              translations,
              i18n,
              language
            ) as string,
            answers: [answerText],
            trainingDate: roboAdviceFormValues[quizDateFields[name]]
              ? formatDate(
                  cultureCode,
                  roboAdviceFormValues[quizDateFields[name]]
                )
              : null
          };
    })
    .filter(
      (
        question
      ): question is {
        question: string;
        answers: string[];
        trainingDate: string | null;
      } => {
        return !isNil(question);
      }
    );
};
export type AdvancedSuitabilityTableRow = {
  order: number;
  label: string;
  trainingContentValue: string;
};

export const getAdvancedSuitability = (
  roboAdviceFormValues: AdviceSessionFormValues,
  advancedSuitabilityConfig: RoboAdviceForm['knowledgeAndExperience']['advancedSuitability'],
  advancedSuitabilityStoreConfig: ReadAnalyzeAdvancedSuitabilityConfigResponse | null
) => {
  if (!advancedSuitabilityConfig.enabled || !roboAdviceFormValues.suitability) {
    return null;
  }

  return Object.entries(roboAdviceFormValues.suitability).reduce(
    (acc, [tableKey, values]) => {
      if (!advancedSuitabilityConfig[tableKey].enabled) {
        return acc;
      }
      const translatedKey = getTranslation(
        advancedSuitabilityConfig[tableKey]?.header
      );

      const translatedValue = Object.entries(values).reduce(
        (acc, [valueKey, valueText]) => {
          const trainingContentIndex =
            advancedSuitabilityStoreConfig?.checks?.findIndex(item => {
              return item === valueKey;
            });

          if (isNil(trainingContentIndex) || trainingContentIndex === -1) {
            return acc;
          }

          const label =
            getTranslation(
              advancedSuitabilityConfig.checksTranslations.find(
                ({ id }) => id === valueKey
              )?.label
            ) || valueKey;

          const trainingContentValue = getTranslation(
            advancedSuitabilityConfig[tableKey]?.tableHeaders.find(
              ({ id }) => id === valueText
            )?.label
          );

          return [
            ...acc,
            {
              order: trainingContentIndex,
              label,
              trainingContentValue
            }
          ];
        },
        [] as AdvancedSuitabilityTableRow[]
      );

      translatedValue.sort((a, b) => a.order - b.order);

      return {
        ...acc,
        [translatedKey]: translatedValue
      };
    },
    {} as { [key: string]: AdvancedSuitabilityTableRow[] }
  );
};

const getPersonFinancialSituation = (
  i18n: (key: string) => string,
  cultureCode: string,
  roboAdviceFormValues: AdviceSessionFormValues,
  roboAdviceFormErrors: ValidationErrors,
  personAssetsTotal: number | null,
  personDebtTotal: number | null,
  personAssetsFields: FinancialSituationFields,
  personDebtFields: FinancialSituationFields
): PersonFinancialSituation => {
  let assetsConfig: (FinancialSituationField | null)[] = personAssetsFields;
  let debtConfig: (FinancialSituationField | null)[] = personDebtFields;

  const lengthsDiff = assetsConfig.length - debtConfig.length;
  const lengthsDiffArray = pipe(
    Math.abs,
    range(0),
    map(always(null))
  )(lengthsDiff);
  if (lengthsDiff > 0) {
    debtConfig = debtConfig.concat(lengthsDiffArray);
  } else if (lengthsDiff < 0) {
    assetsConfig = assetsConfig.concat(lengthsDiffArray);
  }

  return pipe(
    () => zip(assetsConfig, debtConfig),
    map(i => {
      let assetItem: {
        asset: string | undefined;
        assetSum: string | null | undefined;
      } = {
        asset: undefined,
        assetSum: undefined
      };
      if (!isNil(i[0])) {
        const config = i[0];
        assetItem = {
          asset: getTranslation(config[1].render.label),
          assetSum: formatNumber(
            cultureCode,
            getTotalValueResult({
              name: config[0],
              formState: {
                values: roboAdviceFormValues,
                errors: roboAdviceFormErrors
              }
            }),
            0,
            2
          )
        };
      }

      let debtItem: {
        debt: string | undefined;
        debtSum: string | null | undefined;
      } = {
        debt: undefined,
        debtSum: undefined
      };
      if (!isNil(i[1])) {
        const config = i[1];
        debtItem = {
          debt: getTranslation(config[1].render.label),
          debtSum: formatNumber(
            cultureCode,
            getTotalValueResult({
              name: config[0],
              formState: {
                values: roboAdviceFormValues,
                errors: roboAdviceFormErrors
              }
            }),
            0,
            2
          )
        };
      }

      return { ...assetItem, ...debtItem };
    }),
    append({
      asset: i18n('roboAdvice.financialSituation.sumAssets'),
      assetSum: formatNumber(cultureCode, personAssetsTotal, 0, 2),
      debt: i18n('roboAdvice.financialSituation.sumDebt'),
      debtSum: formatNumber(cultureCode, personDebtTotal, 0, 2)
    })
  )();
};

export const getSustainabilityData = ({
  i18n,
  language,
  sustainability,
  sustainabilityData,
  sustainabilityFormValues,
  sustainabilityTableType
}) => ({
  sustainabilityAssessment: Object.keys(
    sustainabilityFormValues?.alignmentCriteria || {}
  ).map(key => ({
    header: sustainability[key].config.title[language],
    body: sustainability[key].config.content[language],
    answer: sustainabilityFormValues?.alignmentCriteria?.[key],
    advisorNote: sustainabilityFormValues?.alignmentCriteriaTextFields?.[key]
  })),
  preferenceCriteria: sustainabilityFormValues?.preferenceCriteria,
  preferenceCriteriaAdvisorNotes: sustainability.sustainabilityPreference
    .advisorNotes.enabled
    ? sustainabilityFormValues?.preferenceCriteriaAdvisorNotes
    : null,
  sustainabilityPreference: sustainability.sustainabilityPreference.config.map(
    ({ icon, title, id }) => ({
      icon,
      id,
      title: title[language]
    })
  ),
  genericAssessment: {
    enabled: sustainability?.genericAssessment?.enabled,
    header: i18n('roboAdvice.sustainability.genericAssessment.header'),
    description: i18n(
      'roboAdvice.sustainability.genericAssessment.description'
    ),
    answerText: sustainabilityFormValues?.genericAssessment?.answer
      ? i18n('roboAdvice.sustainability.genericAssessment.answerPositive')
      : i18n('roboAdvice.sustainability.genericAssessment.answerNegative'),
    answer: sustainabilityFormValues?.genericAssessment?.answer,
    comment: sustainabilityFormValues?.genericAssessment?.comment || null
  },
  data: sustainabilityData,
  tableType: sustainabilityTableType
});

export const getProductPlatformData = ({
  goals,
  productPlatformConfig,
  language,
  i18n
}) =>
  goals.map(({ goalId, name, data }) => {
    const config = productPlatformConfig.find(
      config => config.goalId === goalId
    );
    const namespace = config.items.find(
      namespace => namespace.activeValue === data.productPlatformNamespace
    );
    return {
      name,
      productPlatformNamespace: namespace?.labelString,
      productPlatformReasoning:
        isNil(namespace) || namespace.status !== NamespaceStatus.RECOMMENDED
          ? data.productPlatformReasoning
          : null,
      productPlatformQuestions: config?.questions.map(({ label, name }) => ({
        label: label[language],
        answer: data.productPlatformQuestions[name]
          ? i18n('shared.yes')
          : i18n('shared.no')
      }))
    };
  });

const getTranslationText = (translationKey, translations, i18n, language) => {
  if (translationKey) {
    return i18n(translationKey);
  } else if (translations) {
    return translations[language];
  } else {
    return '';
  }
};

export type AdvisedPortfolioFund = {
  id: string;
  name: string;
  isin: string;
  title: string;
  weight?: number | undefined;
  instruments: AdvisedInstrument[];
};

type AdvisedInstrument = Instrument & {
  suitabilityAssessment: AdvancedSuitabilityStatus | null;
};

export const populateGoalsWithAdvancedSuitabilityStatusData = (
  goals: Goal[],
  advancedSuitabilityStatus: Record<
    number,
    ReadAnalyzeAdvancedSuitabilityStatusResponse
  >,
  modelPortfolios: Record<string, FlatAssetClass[]>
): Goal[] => {
  const { defaultConfigNamespaceId } = useSessionStore.getState();

  return goals.map(goal => {
    if (goal.data.isPortfolioCustom && goal.data.customPortfolio) {
      const { customPortfolio, ...restGoalData } = goal.data;

      return {
        ...goal,
        data: {
          ...restGoalData,
          advisedPortfolio: goal.data.customPortfolio.map(fund => ({
            ...fund,
            instruments: fund.instruments.map(instrument => {
              const suitabilityAssessmentData = advancedSuitabilityStatus?.[
                goal.data.productPlatformNamespace || defaultConfigNamespaceId!
              ]?.find(({ id }) => id === instrument.id);

              const { id, ...suitabilityAssessmentDataWithoutId } =
                suitabilityAssessmentData || {};

              return {
                ...instrument,
                suitabilityAssessment: suitabilityAssessmentData
                  ? suitabilityAssessmentDataWithoutId
                  : null
              };
            })
          }))
        }
      };
    } else if (!goal.data.isPortfolioCustom) {
      const modelPortfolio = modelPortfolios[goal.goalId];
      const { customPortfolio, ...restGoalData } = goal.data;

      return {
        ...goal,
        data: {
          ...restGoalData,
          advisedPortfolio: modelPortfolio.map(fund => ({
            ...fund,
            instruments: fund.instruments.map(instrument => {
              const suitabilityAssessmentData = advancedSuitabilityStatus?.[
                goal.data.productPlatformNamespace || defaultConfigNamespaceId!
              ]?.find(({ id }) => id === instrument.id);

              const { id, ...suitabilityAssessmentDataWithoutId } =
                suitabilityAssessmentData || {};

              return {
                ...instrument,
                suitabilityAssessment: suitabilityAssessmentData
                  ? suitabilityAssessmentDataWithoutId
                  : null
              };
            })
          }))
        }
      };
    }

    return goal;
  });
};
