import { RoboAdviceOrderExecutionPages } from '../../../roboAdvice/shared/constants';
import { getNumberOfErrors } from '../utils';
import { useGetInstrumentsErrors } from './useGetInstrumentsErrors';
import { useGetOrderInformationErrors } from './useGetOrderInformationErrors';
import { useGetOrderInformationPage2Errors } from './useGetOrderInformationPage2Errors';
import { useGetTargetMarketAssessmentErrors } from 'features/sharedModules/errors/services/useGetTargetMarketAssessmentErrors';

export type GetAllOrderExecutionErrors = {
  numberOfAllErrors: number;
  [RoboAdviceOrderExecutionPages.orderInformation]: ReturnType<
    typeof useGetOrderInformationErrors
  >;
  [RoboAdviceOrderExecutionPages.orderInformationPage2]: ReturnType<
    typeof useGetOrderInformationPage2Errors
  >;
  [RoboAdviceOrderExecutionPages.instruments]: ReturnType<
    typeof useGetInstrumentsErrors
  >;
  [RoboAdviceOrderExecutionPages.targetMarketAssessment]: ReturnType<
    typeof useGetTargetMarketAssessmentErrors
  >;
};

export const useGetAllOrderExecutionErrors = (): GetAllOrderExecutionErrors => {
  const pagesErrors = {
    [RoboAdviceOrderExecutionPages.orderInformation]:
      useGetOrderInformationErrors(),
    [RoboAdviceOrderExecutionPages.orderInformationPage2]:
      useGetOrderInformationPage2Errors(),
    [RoboAdviceOrderExecutionPages.instruments]: useGetInstrumentsErrors(),
    [RoboAdviceOrderExecutionPages.targetMarketAssessment]:
      useGetTargetMarketAssessmentErrors()
  };
  let numberOfAllErrors = Object.values(pagesErrors).reduce(
    (prev, pageErrors) => prev + getNumberOfErrors(pageErrors),
    0
  );

  return {
    ...pagesErrors,
    numberOfAllErrors
  };
};
