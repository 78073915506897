import { CancelToken } from 'axios';

import { ExternalSyncResponseData } from './types';
import { customersApiV2, dynamicSyncApi } from 'features/shared/utils/axios';

type SettingsResponseItem = {
  application: string;
  default: string;
  description?: string;
  group?: string;
  is_overruled: boolean;
  name: string;
  schema?: any;
  type?: string;
  value: string;
};

type SettingsResponse = SettingsResponseItem[];

export const getSettings = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  application: string,
  group: string
) => {
  return customersApiV2.get<{ settings: SettingsResponse }>(
    `/settings/${application}`,
    {
      cancelToken,
      headers: { Authorization: accessToken },
      params: {
        group
      }
    }
  );
};

export const getSynchronizedExternalDataResponse = (
  accessToken: string,
  synchronizeUrl: string
) => {
  const api = dynamicSyncApi(synchronizeUrl);

  return api.get<ExternalSyncResponseData>('', {
    withCredentials: true,
    headers: {
      Authorization: accessToken
    }
  });
};

type SettingsPayloadItem = {
  application: string;
  name: string;
  value: string | boolean;
};

type SettingsPayload = SettingsPayloadItem[];

export const postSettings = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  data: SettingsPayload
) => {
  return customersApiV2.post<{ ok: boolean; error?: any }>(`/settings/`, data, {
    cancelToken,
    headers: { Authorization: accessToken }
  });
};

export const postLogo = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  customerCode: string,
  file: File
) => {
  const formData = new FormData();
  formData.append('logo', file);
  formData.append('application', 'deepalpha');

  return customersApiV2
    .post<{ key: string; url: string }[]>(
      `/customers/${customerCode}/upload_file`,
      formData,
      {
        cancelToken,
        headers: {
          Authorization: accessToken,
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    .then(r => ({ ...r, data: r.data.find(i => i.key === 'logo')?.url }));
};

export const deleteLogo = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  customerCode: string,
  logoUrl?: string | null
) => {
  return customersApiV2({
    url: `/customers/${customerCode}/delete_file`,
    method: 'put',
    data: {
      application: 'deepalpha',
      files: [logoUrl || 'logo']
    },
    cancelToken,
    headers: { Authorization: accessToken }
  });
};
