import { useEffect, useContext } from 'react';

import SagaMiddlewareContext from 'features/shared/contexts/sagaMiddlewareContext.js';

export function useSaga(saga, dependencies = []) {
  const sagaMiddleware = useContext(SagaMiddlewareContext);

  useEffect(() => {
    const task = sagaMiddleware.run(saga);

    return () => {
      task.cancel();
    };
  }, dependencies);
}
