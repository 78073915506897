import { MutableState, Tools } from 'final-form';

import { EmptyFormValues, FormValues } from './types';

export const areAllFieldsSelected = (
  group: string,
  values?: FormValues
): boolean => {
  // Check if every field of given group is selected to determine
  // value of 'All' switch.

  return values && Object.values(values).length
    ? Object.values(values[group]).every(value => value === true)
    : true;
};

export const handleSwitchChange = (
  _,
  state: MutableState<FormValues, EmptyFormValues>,
  tools: Tools<FormValues, EmptyFormValues>,
  group: string,
  value: boolean
) => {
  // Change every switch to 'false' or 'true' based on 'All' switch action.

  Object.keys(state.lastFormState?.values[group] || {}).forEach(fieldName =>
    tools.changeValue(state, `${group}.${fieldName}`, () => value)
  );
};
