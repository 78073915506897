import classNames from 'classnames';
import React from 'react';
import DatePicker from 'react-datepicker';

import {
  isDateFormatCorrect,
  setMinAllowedDate,
  setMaxAllowedDate,
  filterDateByType,
  getYearsRange,
  dateFormats,
  MonthShortKeys
} from './constants';
import CustomDropdown from './customDropdown';
import { useStyles } from './styles';
import { useGetLocale } from './useGetLocale';
import Icon from 'features/shared/components/icon/index.js';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

const FullDatePicker = ({
  selected,
  onChange,
  placeholder,
  dateType,
  inputClassName,
  rawDate,
  setRawDate,
  dateFormat,
  allowedYearsRange
}) => {
  const styles = useStyles();
  const i18n = useI18n();
  const locale = useGetLocale(dateFormat);
  const months = MonthShortKeys.map(monthKey => i18n(monthKey));

  const checkedDateFormat = isDateFormatCorrect(dateFormat)
    ? dateFormat
    : dateFormats.dayMonthYear;

  const minAllowedDate = setMinAllowedDate(
    dateType,
    allowedYearsRange?.yearsBack
  );
  const maxAllowedDate = setMaxAllowedDate(
    dateType,
    allowedYearsRange?.yearsForward
  );

  const processDayClassName = date => {
    const currentItemDate = new Date(date);
    const selectedDate = new Date(selected);

    if (
      !filterDateByType(
        date,
        dateType,
        allowedYearsRange?.yearsBack,
        allowedYearsRange?.yearsForward
      )
    ) {
      return classNames(styles.centredInlineBlock, styles.excludedDay);
    }
    if (!selected) {
      return classNames(styles.day, styles.centredInlineBlock);
    }
    if (currentItemDate.toDateString() === selectedDate.toDateString()) {
      return classNames(styles.selectedDay, styles.centredInlineBlock);
    }
    if (currentItemDate.getMonth() !== rawDate.getMonth()) {
      return classNames(styles.centredInlineBlock, styles.disabledDay);
    }
    return classNames(styles.day, styles.centredInlineBlock);
  };

  return (
    <DatePicker
      locale={locale}
      selected={selected}
      calendarClassName={styles.calendar}
      onChange={date => onChange(date)}
      dateFormat={checkedDateFormat}
      onSelect={date => setRawDate(new Date(date))}
      onYearChange={date => setRawDate(new Date(date))}
      onMonthChange={date => setRawDate(new Date(date))}
      formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
      customInput={
        <input
          className={inputClassName}
          onChange={onChange}
          value={selected}
        />
      }
      placeholderText={placeholder}
      weekDayClassName={() => styles.weekDay}
      dayClassName={date => processDayClassName(date)}
      filterDate={date =>
        filterDateByType(
          date,
          dateType,
          allowedYearsRange?.yearsBack,
          allowedYearsRange?.yearsForward
        )
      }
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth
      }) => (
        <div className={styles.header}>
          {date > minAllowedDate ? (
            <span className={styles.chevronLeft} onClick={decreaseMonth}>
              <Icon className={styles.navIcon} type="chevron_left" />
            </span>
          ) : (
            <span className={styles.chevronLeft}>
              <Icon className={styles.disabledNavIcon} type="chevron_left" />
            </span>
          )}

          <CustomDropdown
            minAllowedDate={minAllowedDate}
            maxAllowedDate={maxAllowedDate}
            selectedDate={date}
            selectedValue={months[new Date(date).getMonth()]}
            valueArray={months}
            changeFunction={month => changeMonth(months.indexOf(month))}
          />

          <CustomDropdown
            selectedValue={new Date(date).getFullYear()}
            valueArray={getYearsRange(
              dateType,
              allowedYearsRange?.yearsBack,
              allowedYearsRange?.yearsForward
            )}
            changeFunction={year => changeYear(year)}
          />
          {date < maxAllowedDate ? (
            <span className={styles.chevronRight} onClick={increaseMonth}>
              <Icon className={styles.navIcon} type="chevron_right" />
            </span>
          ) : (
            <span className={styles.chevronRight}>
              <Icon className={styles.disabledNavIcon} type="chevron_right" />
            </span>
          )}
        </div>
      )}
    />
  );
};

export default FullDatePicker;
