import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useFinancialSituationValues } from '../../financialSituation/services/selectors';
import { form as roboAdviceForm } from '../../form/services/form';
import { creators } from '../services/actions.js';
import { getDoShowDetailDeleteIcon } from '../services/selectors.js';
import { nameColumnWidth, valueAccountColumnWidth } from '../shared/constants';
import { Context } from './context.js';
import Icon from 'features/shared/components/icon/index.js';
import { Spacing } from 'features/shared/constants/spacing';
import sessionSelectors from 'features/shared/services/session/selectors';
import {
  formatNumber,
  getNumberInputFormat
} from 'features/shared/utils/number';
import FinalFormNumberInput from 'features/sharedModules/finalForm/components/numberInput';
import FinalFormTextInput from 'features/sharedModules/finalForm/components/textInput';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  detail: {
    display: 'flex',
    alignItems: 'flex-start',

    '& + &': {
      marginTop: '16px'
    }
  },
  staticCell: {
    width: nameColumnWidth,
    height: 40,
    display: 'inline-flex',
    alignItems: 'center',
    paddingLeft: Spacing.spacing01
  },
  valueAmountCell: {
    width: valueAccountColumnWidth,
    display: 'inline-flex',
    justifyContent: 'flex-end'
  },
  titleInputSection: {
    marginRight: '24px'
  },
  titleInput: {
    width: nameColumnWidth
  },
  valueInput: {
    width: '155px',
    textAlign: 'right'
  },
  deleteIconContainer: {
    margin: '0 15px'
  },
  deleteIconPlaceholder: {
    width: '20px'
  },
  deleteIcon: {
    fontSize: '20px',
    lineHeight: '44px',
    cursor: 'pointer',
    transform: 'rotate(0.03deg)' // fix of issue: icon small size causes pixelated icon
  }
});

export const Detail = ({ name, index, fields, disabled, allowNegative }) => {
  const classes = useStyles();
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const { thousandSeparator, decimalSeparator } =
    getNumberInputFormat(cultureCode);

  const { form, name: detalizedNumberInputName } = React.useContext(Context);
  const doShowDetailDeleteIcon = getDoShowDetailDeleteIcon(
    fields.length,
    index
  );
  const financialSituationValues = useFinancialSituationValues();

  useEffect(() => {
    roboAdviceForm.mutators.setFieldTouched(`${name}.${index}.value`, true);
  }, []);

  const dispatch = useDispatch();
  const detailChanged = () => {
    dispatch(creators.detailChanged(form, detalizedNumberInputName));
  };
  const removeDetail = () => {
    roboAdviceForm.mutators.remove(name, index);
  };

  const [clientTypeKey, fieldName] = name.split('.');
  const detailData =
    roboAdviceForm.getState().values?.[clientTypeKey]?.[fieldName]?.[index];

  if (
    detailData?.accountNumber !== undefined &&
    detailData?.accountNumber !== null
  ) {
    return (
      <div className={classes.detail}>
        <div className={classes.staticCell}>{detailData?.title}</div>
        <div
          className={classNames(classes.staticCell, classes.valueAmountCell)}
        >
          {formatNumber(cultureCode, detailData?.value, 0, 2)}
        </div>
        <div
          className={classNames(classes.staticCell, classes.valueAmountCell)}
        >
          {detailData?.accountNumber}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.detail}>
      <FinalFormTextInput
        data-testid={`detail-${index}-title-input`}
        name={`${name}.${index}.title`}
        afterChange={() => {
          detailChanged();
        }}
        className={classes.titleInput}
        sectionClassName={classes.titleInputSection}
        disabled={disabled}
      />
      <FinalFormNumberInput
        data-testid={`detail-${index}-value-input`}
        name={`${name}.${index}.value`}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        allowNegative={allowNegative}
        decimalScale={0}
        afterChange={value => {
          detailChanged();
          roboAdviceForm.change(
            `${name}.${index}.toAdvisory`,
            financialSituationValues?.amountForAdviceSwitch ? 100 : value
          );
        }}
        className={classes.valueInput}
        disabled={disabled}
      />

      <div className={classes.deleteIconContainer}>
        {!disabled && doShowDetailDeleteIcon && (
          <Icon
            data-testid={`delete-detail-${index}-icon`}
            type="delete"
            className={classes.deleteIcon}
            onClick={() => {
              removeDetail();
            }}
          />
        )}
        {!doShowDetailDeleteIcon && (
          <div className={classes.deleteIconPlaceholder} />
        )}
      </div>
    </div>
  );
};

export default Detail;
