import {
  equals,
  omit,
  pipe,
  map,
  when,
  is,
  reject,
  ifElse,
  either,
  isEmpty,
  isNil
} from 'ramda';

import { usePageStore as useProposalPageStore } from '../../proposal/services/pageStore';
import { AdvisedPortfolioFund } from '../services/mappingSelectors';
import { useForm as useRoboAdviceForm } from 'features/roboAdvice/adviceSession/form/services/form';
import { useCostForm } from 'features/roboAdvice/adviceSession/proposal/services/costForm';
import {
  CustomPortfolioItem,
  Goal,
  useGoalsStore
} from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { useCustomDownloadStore } from 'features/roboAdvice/shared/services/customDownloadStore';

const deepRemoveUndefined = (val: unknown) => {
  if (val === undefined) return null;

  if (typeof val === 'object' && !Array.isArray(val) && val !== null) {
    return Object.entries(val).reduce((res, [key, value]) => {
      return {
        ...res,
        [key]: deepRemoveUndefined(value)
      };
    }, {});
  }

  if (Array.isArray(val)) {
    return val.map(v => deepRemoveUndefined(v));
  }

  return val;
};

const normalizeValue = (val: unknown) =>
  JSON.parse(JSON.stringify(deepRemoveUndefined(val)));

const prepareFinishedPortfolioGoals = (goals: Goal[]): Goal[] => {
  return goals.map(goal => {
    const { advisedPortfolio, ...restGoalData } = (goal.data as Goal['data'] & {
      advisedPortfolio: (AdvisedPortfolioFund & CustomPortfolioItem)[];
    }) || {
      advisedPortfolio: []
    };

    return {
      ...goal,
      data: {
        ...restGoalData,
        customPortfolio: goal.data.isPortfolioCustom
          ? advisedPortfolio?.map(({ instruments, ...restPortfolioData }) => ({
              ...restPortfolioData,
              instruments: instruments.map(
                ({ suitabilityAssessment, ...restInstrumentData }) => ({
                  ...restInstrumentData
                })
              )
            }))
          : null
      }
    };
  });
};

export const useIsReportDataOld = (adviceSessionId: string) => {
  const { availableDocuments } = useCustomDownloadStore.getState();
  const { values: roboAdviceFormValues } = useRoboAdviceForm();
  const { goals } = useGoalsStore.getState();
  const { finishedPortfolioData } = useProposalPageStore();
  const { values: costFormValues } = useCostForm();

  // Check if we even have documents data for session
  if (
    !availableDocuments[adviceSessionId] ||
    availableDocuments[adviceSessionId].length === 0
  ) {
    return false;
  }

  // Check if we have finishedPortfolioData
  if (
    !finishedPortfolioData?.goals ||
    !finishedPortfolioData?.roboAdviceForm ||
    !finishedPortfolioData?.costForm
  ) {
    return false;
  }

  // Remove customPortfolio from goals if it is not custom portfolio when validating.
  // TODO: Remove when this is not a problem anymore - will most likely require a DB migration for old advice sessions
  goals.forEach(goal => {
    if (!goal.data.isPortfolioCustom && goal.data.customPortfolio) {
      goal.data.customPortfolio = null;
    }
  });

  // we use this function to remove nulls and empty strings from objects, so we only compare actual data in the objects
  const removeNulls = pipe(
    map(when(is(Object), v => removeNulls(v))),
    reject(ifElse(is(String), isEmpty, either(isEmpty, isNil)))
  );

  // normalize goals
  const goalsValueToOmit = ['expectedValue', 'isGoalSelected'];
  const normalizedGoal = normalizeValue(removeNulls(goals) || []).map(goal =>
    omit(goalsValueToOmit, goal)
  );
  const normalizedFinishedGoal = normalizeValue(
    removeNulls(
      prepareFinishedPortfolioGoals(finishedPortfolioData?.goals || [])
    )
  ).map(goal => omit(goalsValueToOmit, goal));

  // normalize user input
  const normalizedRoboForm = normalizeValue(removeNulls(roboAdviceFormValues));
  const normalizedFinishedRoboForm = normalizeValue(
    removeNulls(finishedPortfolioData?.roboAdviceForm)
  );

  // normalize cost override
  const normalizeCostOverride = normalizeValue(
    removeNulls({
      overrideCost: costFormValues?.overrideCost,
      overrideFundCosts: costFormValues?.overrideFundCosts
    })
  );
  const normalizeFinishedCostOverride = normalizeValue(
    removeNulls(finishedPortfolioData?.costForm)
  );

  // check if there are differences between data in finishedSessionData (from db) and in the frontend browser state
  const dataHasChanged =
    !equals(normalizedGoal, normalizedFinishedGoal) ||
    !equals(normalizedRoboForm, normalizedFinishedRoboForm) ||
    !equals(normalizeCostOverride, normalizeFinishedCostOverride);

  return dataHasChanged;
};
