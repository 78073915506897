import create from 'zustand';
import * as R from 'ramda';

import { FundNameForPresentation } from 'modules/shared/constants/settings';

export const PortfolioType = {
  model: 'model',
  suggested: 'suggested',
  custom: 'custom'
};

export const createUsePortfolioChartStore = (
  intialState = {
    chartData: []
  }
) =>
  create((set, get) => ({
    ...intialState,
    reset: () => {
      set(intialState);
    },
    initialize(initialData) {
      set(
        state => ({
          ...state,
          ...initialData
        }),
        true
      );
    },
    // This function is used only in `robo-front`.
    // When it's replaced (as it is in DeepAlpha),
    // it can be removed along with all helper functions below
    // and we will only use `addAssetClassAllocationPortfolioChartData`.
    addStandardPortfolioChartData: ({
      dataSources: {
        roboPortfolio,
        roboSelection,
        fundList,
        analyzeRiskReturn
      },
      portfolioChartColors,
      fundNameForPresentation = FundNameForPresentation.fundStandardName,
      goalId = null,
      goalName = null,
      goalIcon = null,
      portfolioType = PortfolioType.model,
      i18n
    }) => {
      const newChartData = {
        expectedAnnualReturn: R.prop('return', analyzeRiskReturn),
        expectedVolatility: R.prop('risk', analyzeRiskReturn),
        equityPortion: getStandardEquityPortion(
          R.prop('Portfolio', roboPortfolio)
        ),
        categoryMainAssetClassPortfolio: getStandardMainAssetCategoryPortfolio(
          R.prop('Portfolio', roboPortfolio),
          ['AssetClass', 'MainAssetClass'],
          portfolioChartColors,
          i18n
        ),
        categorySubAssetClassPortfolio: getStandardSubAssetCategoryPortfolio(
          R.prop('Portfolio', roboPortfolio),
          portfolioChartColors
        ),
        categorySelectionPortfolio: getStandardCategorySelectionPortfolio(
          R.prop('Portfolio', roboPortfolio),
          R.prop('Selection', roboSelection),
          R.prop('funds', fundList),
          portfolioChartColors,
          fundNameForPresentation
        ),
        goalId,
        goalName,
        goalIcon,
        portfolioType
      };

      set(oldState => ({ chartData: [...oldState.chartData, newChartData] }));
    },
    addAssetClassAllocationPortfolioChartData: ({
      dataSources: { analyzeRiskReturn, assetClassAllocation },
      portfolioChartColors,
      fundNameForPresentation = FundNameForPresentation.fundStandardName,
      goalId = null,
      goalName = null,
      goalIcon = null,
      portfolioType = PortfolioType.model,
      i18n
    }) => {
      const newChartData = {
        expectedAnnualReturn: R.prop('return', analyzeRiskReturn),
        expectedVolatility: R.prop('risk', analyzeRiskReturn),
        equityPortion: assetClassAllocation.equityShare * 100,
        categoryMainAssetClassPortfolio: [
          {
            id: 'fixedIncome',
            name: i18n('portfolioChart.fixedIncome'),
            color: getColor(portfolioChartColors, 0, 2),
            weight: assetClassAllocation.fixedIncomeShare * 100
          },
          {
            id: 'equity',
            name: i18n('portfolioChart.equity'),
            color: getColor(portfolioChartColors, 1, 2),
            weight: assetClassAllocation.equityShare * 100
          }
        ],
        categorySubAssetClassPortfolio: getSubAssetClassFromAssetClassAllocation(
          { assetClassAllocation, portfolioChartColors }
        ),
        categorySelectionPortfolio: assetClassAllocation.instrumentAllocation.map(
          ({ Ticker, FundStandardName, Name, ISIN, weight }, index) => ({
            id: Ticker,
            name:
              fundNameForPresentation.toLocaleLowerCase() ===
              FundNameForPresentation.fundStandardName.toLocaleLowerCase()
                ? FundStandardName
                : Name,
            isin: ISIN,
            color: getColor(
              portfolioChartColors,
              index,
              assetClassAllocation.instrumentAllocation.length
            ),
            weight: weight * 100
          })
        ),
        goalId,
        goalName,
        goalIcon,
        portfolioType
      };

      set(oldState => ({ chartData: [...oldState.chartData, newChartData] }));
    },
    resetChartData: () => {
      set({ chartData: R.propOr([], 'chartData', intialState) });
    },
    getChartData: (goalId, isPortfolioCustom = false) => {
      return get().chartData.find(
        c =>
          c.goalId === goalId &&
          c.portfolioType ===
            (isPortfolioCustom ? PortfolioType.custom : PortfolioType.model)
      );
    },
    getSuggestedChartData: goalId => {
      return get().chartData.find(
        c => c.goalId === goalId && c.portfolioType === PortfolioType.suggested
      );
    }
  }));

const indexedMap = R.addIndex(R.map);

const getSubAssetClassFromAssetClassAllocation = ({
  assetClassAllocation,
  portfolioChartColors
}) => {
  const groupedPortfolioAllocation = assetClassAllocation.portfolioAllocation.reduce(
    (prev, curr) => {
      const foundAssetIndex = prev.findIndex(p => p.name === curr.name);

      if (foundAssetIndex !== -1) {
        return prev.map((portfolioAllocation, index) => {
          if (foundAssetIndex === index) {
            return {
              ...portfolioAllocation,
              weight: portfolioAllocation.weight + curr.weight
            };
          }

          return portfolioAllocation;
        });
      }

      return [...prev, curr];
    },
    []
  );

  return groupedPortfolioAllocation.map(
    ({ category, name, weight }, index) => ({
      id: category,
      name: name,
      color: getColor(
        portfolioChartColors,
        index,
        assetClassAllocation.portfolioAllocation.length
      ),
      weight: weight * 100
    })
  );
};

export const getColor = (chartColors, index, itemsLength) => {
  const normalizedIndex = index % chartColors.length;

  if (normalizedIndex === 0 && index === itemsLength - 1 && itemsLength > 1) {
    return chartColors[Math.min(chartColors.length - 1, 1)];
  }

  return chartColors[normalizedIndex];
};

const getStandardEquityPortion = portfolioItems =>
  R.pipe(
    R.filter(i => i.AssetClass.EquityShare > 0),
    R.map(i => Number(i.AssetClass.EquityShare) * Number(i.Weight / 100)),
    R.sum
  )(portfolioItems);

const getStandardMainAssetCategoryPortfolio = (
  portfolioItems,
  keyPath,
  chartColors,
  i18n
) => {
  const equityItems = portfolioItems
    .map(i => ({
      weight: Number(i.Weight),
      equityShare: Number(i.AssetClass.EquityShare),
      mainAssetClass: R.path(keyPath, i)
    }))
    .filter(i => i.equityShare > 0);
  let equity = null;
  if (equityItems.length > 0) {
    equity = {
      id: 'equity',
      isin: null,
      name: i18n('portfolioChart.equity'),
      color: null,
      weight: equityItems.reduce(
        (acc, i) => acc + (i.weight / 100) * i.equityShare,
        0
      )
    };
  }

  const fixedIncome = {
    id: 'fixedIncome',
    isin: null,
    name: i18n('portfolioChart.fixedIncome'),
    color: null,
    weight: 100 - (equity?.weight || 0)
  };

  const assets = [fixedIncome, equity].filter(a => !!a);
  return assets.map((a, index) => ({
    ...a,
    color: getColor(chartColors, index, assets.length)
  }));
};

const getStandardSubAssetCategoryPortfolio = (portfolioItems, chartColors) =>
  R.pipe(
    R.map(i => ({
      weight: Number(i.Weight),
      groupByKey: R.path(['AssetClass', 'SubAssetClass'], i),
      CategoryId: R.path(['AssetClass', 'CategoryId'], i)
    })),
    R.groupBy(R.prop('groupByKey')),
    R.toPairs,
    indexedMap(([groupByKey, groupedItems], groupIndex, groupsList) => ({
      id: groupByKey,
      isin: null,
      name: groupByKey,
      color: getColor(chartColors, groupIndex, R.length(groupsList)),
      weight: R.pipe(R.map(R.prop('weight')), R.sum)(groupedItems),
      CategoryId: groupedItems.length > 0 ? groupedItems[0]?.CategoryId : null
    }))
  )(portfolioItems);

const getStandardCategorySelectionPortfolio = (
  portfolioItems,
  selection,
  funds,
  chartColors,
  fundNameForPresentation
) => {
  const fundsByTicker = R.indexBy(R.prop('Ticker'), funds);
  const categoriesSelection = R.reduce(
    (acc, c) => {
      if (R.isNil(c.Instruments) || R.isEmpty(c.Instruments)) {
        return acc;
      }

      const instrument = c.Instruments[0];
      const instrumentDetails = fundsByTicker[instrument.Ticker];
      const FundStandardName = R.prop('FundStandardName', instrumentDetails);
      const Name = R.prop('Name', instrumentDetails);

      return R.assoc(
        c.CategoryId,
        {
          id: instrument.Ticker,
          isin: instrument.ISIN,
          title:
            fundNameForPresentation ===
              FundNameForPresentation.fundStandardName && FundStandardName
              ? FundStandardName
              : Name
        },
        acc
      );
    },
    {},
    selection
  );

  return R.pipe(
    indexedMap((i, index, list) => {
      const instrument = categoriesSelection[i.AssetClass.CategoryId];

      if (R.isNil(instrument)) {
        return null;
      }

      return {
        id: instrument.id,
        isin: instrument.isin,
        name: instrument.title,
        color: getColor(chartColors, index, R.length(list)),
        weight: Number(i.Weight)
      };
    }),
    R.reject(R.isNil)
  )(portfolioItems);
};
