import create from 'zustand';

export type SessionStoreData = {
  permissions: string[];
};

export type SessionStoreApi = {
  reset: () => void;
  setPermissions: (permissions: string[]) => void;
};

export type SessionStoreState = SessionStoreData & SessionStoreApi;

const defaultData = {
  permissions: []
};

export const useSessionStore = create<SessionStoreState>(set => ({
  ...defaultData,
  reset() {
    set(defaultData);
  },
  setPermissions(permissions) {
    set({ permissions });
  }
}));
