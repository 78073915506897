import axios from 'axios';
import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';

import { clientsTable } from '../../clientsTable';
import { deleteInvestor } from '../api';
import { useClientsListStore } from '../services/clientsListStore';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useDeleteClient() {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);

  const dispatch = useDispatch();

  const deleteClient = async (clientId: string) => {
    const clientsListStore = useClientsListStore.getState();
    const clientsTableStore = clientsTable.useTableStore.getState();

    const client = R.find(c => c.id === clientId, clientsListStore.clients);

    if (client == null) {
      return;
    }

    clientsListStore.deleteClient(clientId);
    clientsTableStore.deleteItem(clientId);

    try {
      clientsListStore.setIsDeleteClientPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        undefined
      );

      await deleteInvestor(accessToken, clientId);

      clientsListStore.setIsDeleteClientPending(false);

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n('roboAdvice.clientsList.deleteClientSuccessMessage'),
          type: NotificationTypes.success
        })
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        clientsListStore.setIsDeleteClientPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.clientsList.deleteClientErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return deleteClient;
}
