import { isNil } from 'ramda';

import { ClientInformation } from 'features/roboAdvice/client/main/services/clientForm';
import { ClientTypes } from 'features/shared/constants/session';
import { InvestorData } from 'features/shared/types/investor';

export const getClientName = (
  clientInformation:
    | { name?: string | null; companyName?: string | null }
    | undefined
): string | undefined | null => {
  return clientInformation?.companyName || clientInformation?.name;
};

export const mapServerInvestorInformationToClient = (
  values: InvestorData
): ClientInformation => {
  const {
    additionalData,
    address,
    city,
    country,
    email,
    name,
    ssn,
    phone,
    zipCode,
    investorType,
    organizationNumber,
    contacts
  } = values;

  return {
    additionalData,
    address,
    city,
    clientType: investorType,
    country,
    contacts,
    email,
    name,
    companyName: investorType === ClientTypes.company ? name : null,
    ssn,
    phoneNumber: phone,
    zipCode,
    orgNumber: isNil(organizationNumber) ? null : organizationNumber
  };
};
