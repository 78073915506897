import { goalIcons } from '@quantfoliorepo/ui-components';
import classNames from 'classnames';
import { useMemo } from 'react';

import { goalForm } from '../services/goalForm';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import Checkmark from 'features/roboAdvice/adviceSession/shared/components/cradsList/checkmark';
import Tooltip from 'features/shared/components/tooltip';
import { useField } from 'features/sharedModules/finalForm/components/useField';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.itemBackgroundColor_2,
    borderRadius: '16px',
    cursor: 'pointer',
    position: 'relative',
    height: '80px',
    width: '80px',

    '&.selected': {
      border: `2px solid ${theme.accentColor}`
    },

    '&:hover': {
      backgroundColor: theme.accentHoverColor
    }
  },
  checkMark: {
    position: 'absolute',
    top: '-15px',
    right: '-15px'
  }
}));

const GoalIcon = ({ iconName, iconUrl, translations, goalType }) => {
  const classes = useStyles();
  const {
    input: { value, onChange }
  } = useField('icon');
  const pageLanguage = getUserPageLanguage();

  const iconChangeHandler = (iconUrl, iconName, goalName) => {
    const newValue =
      value === iconUrl || value === iconName ? null : iconUrl || iconName;
    onChange(newValue);
    goalForm.change('name', goalName);
    goalForm.change('data.type', goalType);
  };

  const icon = useMemo(() => {
    return iconUrl ? (
      <img src={iconUrl} alt={translations[pageLanguage]} />
    ) : (
      goalIcons.find(({ name }) => name === iconName).popupIcon
    );
  }, [iconName, iconUrl, pageLanguage, translations]);

  return (
    <Tooltip
      content={translations[pageLanguage]}
      infoTooltip
      trigger={
        <div
          className={classNames(classes.icon, {
            selected: value === iconUrl || value === iconName
          })}
          onClick={() =>
            iconChangeHandler(iconUrl, iconName, translations[pageLanguage])
          }
        >
          {(value === iconUrl || value === iconName) && (
            <Checkmark className={classes.checkMark} />
          )}

          {icon}
        </div>
      }
    />
  );
};

export default GoalIcon;
