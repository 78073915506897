import { CancelTokenSource } from 'axios';
import { v4 } from 'uuid';

import { Category } from '../../session/services/sessionStore';
import { readFundLookup } from '../../shared/api';
import {
  AccountData,
  GetInvestorAccountsDataResponse,
  ReadFundLookupResponse
} from '../../shared/api/types';
import { getSubAssetClassTranslation } from '../../shared/mapping';
import {
  AccountValueTarget,
  AccountsSyncMapping
} from 'defaults/defaultRoboAdviceForm';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';

export type Detail = {
  id: string;
  title: string;
  accountNumber?: string;
  toAdvisory: number;
  value: number;
  assetClass?: Category;
  isTaggedAutomatically?: boolean;
};

export const mapDetailsToISINs = (
  accountsData: AccountData[],
  accountsSyncMapping: AccountsSyncMapping
): string[] => {
  const result: string[] = [];
  accountsData.forEach(accountData => {
    const { type, positions } = accountData;

    const mappings = accountsSyncMapping.filter(
      ({ accountType }) => accountType === type
    );

    mappings.forEach(({ accountValueTarget }) => {
      if (accountValueTarget === AccountValueTarget.positions) {
        positions.forEach(({ isin }) => {
          if (isin) {
            result.push(isin);
          }
        });
      }
    });
  });

  return result;
};

export const mapAccountsDataToFinancialSituationAssets = (
  accountsData: AccountData[],
  financialSituationValues: Record<string, any>,
  accountsSyncMapping: AccountsSyncMapping,
  fundLookupData?: ReadFundLookupResponse,
  assetClassOptions?: {
    label: string;
    mainCategory: Category;
    categories: string[];
  }[],
  subAssetClassNameMapping?: Record<string, Record<string, string>>
): Record<string, Detail[]> => {
  const amountForAdviceSwitch = financialSituationValues?.amountForAdviceSwitch;

  const result: Record<string, Detail[]> = {};
  accountsData.forEach(accountData => {
    const { accountNumber, cashAmount, type, positions, accountName } =
      accountData;

    const mappings = accountsSyncMapping.filter(
      ({ accountType }) => accountType === type
    );

    mappings.forEach(({ accountValueTarget, financialSituationFieldId }) => {
      let newData: Detail[] | null = null;

      if (accountValueTarget === AccountValueTarget.cashAmount) {
        newData = [
          {
            id: v4(),
            title: accountName ?? accountNumber ?? '',
            accountNumber: accountNumber || '',
            toAdvisory: amountForAdviceSwitch
              ? 100
              : Math.floor(+cashAmount) || 0,
            value: Math.floor(+cashAmount) || 0
          }
        ];
      } else if (accountValueTarget === AccountValueTarget.positions) {
        newData = positions.map(({ name, amount, isin }) => {
          const additionalData: {
            assetClass?: Category;
            isTaggedAutomatically?: boolean;
          } = {};

          if (fundLookupData && assetClassOptions) {
            const lookUpNamespaces =
              fundLookupData.funds.find(
                ({ isin: fundIsin }) => fundIsin === isin
              )?.namespaces ?? [];
            const lookUpCategory = lookUpNamespaces.find(
              ({ isDefault }) => isDefault
            )?.categories?.[0];

            const assetClassOption = assetClassOptions.find(({ categories }) =>
              categories.some(
                category => category === lookUpCategory?.categoryCode
              )
            );

            if (assetClassOption && lookUpCategory) {
              const mappedSubAssetClass = getSubAssetClassTranslation(
                lookUpCategory.categoryCode,
                subAssetClassNameMapping
              );

              additionalData.assetClass = {
                ...assetClassOption.mainCategory,
                SubAssetClass:
                  mappedSubAssetClass || lookUpCategory.subCategoryName
              };
              additionalData.isTaggedAutomatically = true;
            }
          }

          return {
            id: v4(),
            title: name,
            accountNumber: accountNumber || '',
            toAdvisory: amountForAdviceSwitch ? 100 : Math.floor(+amount) || 0,
            value: Math.floor(+amount) || 0,
            ...additionalData
          };
        });
      }

      if (newData) {
        if (
          result[financialSituationFieldId] &&
          Array.isArray(result[financialSituationFieldId])
        ) {
          result[financialSituationFieldId].push(...newData);
        } else {
          result[financialSituationFieldId] = newData;
        }
      }
    });
  });

  return result;
};

export const mapAccountsData = async ({
  automaticTaggingOfAssetClassEnabled,
  investorAccountData,
  accountsSyncMapping,
  qAuthAccessToken,
  cancelTokenSource,
  categories,
  financialSituationValues,
  subAssetClassNameMapping
}: {
  automaticTaggingOfAssetClassEnabled: boolean;
  investorAccountData: GetInvestorAccountsDataResponse;
  accountsSyncMapping: AccountsSyncMapping;
  qAuthAccessToken: string;
  cancelTokenSource: CancelTokenSource | undefined;
  categories: Category[];
  financialSituationValues: any;
  subAssetClassNameMapping: Record<string, Record<string, string>> | undefined;
}) => {
  const fundLookupData: ReadFundLookupResponse | undefined =
    await getFundLookupData({
      automaticTaggingOfAssetClassEnabled,
      investorAccountData,
      accountsSyncMapping,
      qAuthAccessToken,
      cancelTokenSource
    });
  const assetClassOptions = automaticTaggingOfAssetClassEnabled
    ? getAssetClassOptions(categories)
    : undefined;

  return mapAccountsDataToFinancialSituationAssets(
    investorAccountData,
    financialSituationValues,
    accountsSyncMapping,
    fundLookupData,
    assetClassOptions,
    subAssetClassNameMapping
  );
};

// Helper function to fetch fund lookup data
const getFundLookupData = async ({
  automaticTaggingOfAssetClassEnabled,
  investorAccountData,
  accountsSyncMapping,
  qAuthAccessToken,
  cancelTokenSource
}: {
  automaticTaggingOfAssetClassEnabled: boolean;
  investorAccountData: GetInvestorAccountsDataResponse;
  accountsSyncMapping: AccountsSyncMapping;
  qAuthAccessToken: string;
  cancelTokenSource: CancelTokenSource | undefined;
}): Promise<ReadFundLookupResponse | undefined> => {
  if (!automaticTaggingOfAssetClassEnabled) return undefined;

  const isins = mapDetailsToISINs(investorAccountData, accountsSyncMapping);
  if (isins.length === 0) return undefined;

  const fundLookupResponse = await readFundLookup(
    qAuthAccessToken,
    cancelTokenSource?.token,
    { isin: isins, language: getUserPageLanguage() ?? '' }
  );
  return fundLookupResponse.data;
};

// Helper function to create asset class options
const getAssetClassOptions = (categories: Category[]) => {
  const subAssetClassMapping = createSubAssetClassMapping(categories);

  return Object.entries(subAssetClassMapping)
    .filter(([_subAssetClass, items]) => items.length > 0)
    .map(([subAssetClass, items]) => ({
      label: subAssetClass,
      mainCategory: items[0],
      categories: Array.from(new Set(items.map(item => item.CategoryId)))
    }));
};

// Helper function to create sub-asset class mapping
const createSubAssetClassMapping = (
  categories: Category[]
): { [key: string]: Category[] } =>
  categories.reduce<{ [key: string]: Category[] }>((acc, curr) => {
    if (!acc[curr.SubAssetClass]) {
      acc[curr.SubAssetClass] = [];
    }
    acc[curr.SubAssetClass].push(curr);
    return acc;
  }, {});
