import create from 'zustand';

import { ClientType } from 'features/shared/constants/session';

export type Client = {
  id: string;
  type: ClientType;
  name: string;
  advisorName: string;
  lastActivityDate: string;
  softDeleteDate: string;
};

export type ClientsStoreData = {
  clients: Client[];
  isReadClientsPending: boolean;
  isDeleteClientsPending: boolean;
  isRestoreClientsPending: boolean;
};

export type ClientsStoreApi = {
  reset: () => void;
  setIsReadClientsPending: (isReadClientsPending: boolean) => void;
  setIsDeleteClientsPending: (isDeleteClientsPending: boolean) => void;
  setIsRestoreClientsPending: (isRestoreClientsPending: boolean) => void;
  setClients: (clients: Client[]) => void;
  deleteClients: (clientIds: string[]) => void;
};

export type ClientsStoreState = ClientsStoreData & ClientsStoreApi;

const defaultData = {
  clients: [],
  isReadClientsPending: false,
  isDeleteClientsPending: false,
  isRestoreClientsPending: false
};

export const useClientsStore = create<ClientsStoreState>(set => ({
  ...defaultData,
  reset() {
    set(defaultData);
  },
  setIsReadClientsPending(isReadClientsPending) {
    set({ isReadClientsPending });
  },
  setIsDeleteClientsPending(isDeleteClientsPending) {
    set({ isDeleteClientsPending });
  },
  setClients(clients) {
    set({ clients });
  },
  deleteClients(clientIds) {
    set(({ clients }) => ({
      clients: clients.filter(c => !clientIds.includes(c.id))
    }));
  },
  setIsRestoreClientsPending(isRestoreClientsPending) {
    set({ isRestoreClientsPending });
  }
}));
