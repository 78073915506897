import { CancelToken } from 'axios';
import queryString from 'query-string';
import * as R from 'ramda';

import {
  CreateAnalyzeUniverseCustomAttributesResponse,
  CreateAdvancedExpectedValueRequestData,
  CreateAdvancedExpectedValueResponse,
  CreateAnalyzeUniverseSustainabilityRequestData,
  CreateAnalyzeUniverseSustainabilityResponse,
  ReadAnalyzeAdvancedSuitabilityConfigResponse,
  ReadAnalyzeAdvancedSuitabilityStatusResponse,
  ReadAnalyzeAdvancedSuitabilityStatusPayload,
  ReadAnalyzeUniverseDocumentsResponse,
  GetInvestorAccountsDataResponse,
  AnalyzeForecastPayload,
  AnalyzeForecastResponse,
  CalculatorSimpleResponse,
  CalculatorSimplePayload,
  CreateAnalyzeCostResponse,
  FundLookupParams,
  ReadFundLookupResponse,
  CreateAnalyzeMultiGoalCostResponse,
  ReadAnalyzeAdvancedSuitabilityStatusV2Payload,
  ReadAnalyzeAdvancedSuitabilityStatusV2Response
} from './types';
import {
  advisoryApi,
  advisoryApiV2,
  stateApi,
  savingCalculatorApiV2
} from 'features/shared/utils/axios';

type Params = Record<string, string | number | undefined | null | string[]>;

// #region advisory robo
export const getRoboPortfolioConfig = async (
  accessToken: string,
  riskClass,
  precision: number,
  params?: Params
) => {
  const response = await advisoryApi.get(`/robo/portfolio`, {
    params: { risk_tolerance: riskClass, precision, ...params },
    headers: { Authorization: accessToken }
  });

  return R.set(R.lensPath(['data', 'riskClass']), riskClass, response);
};

export const getRoboPortfolio = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  params?: Params
) =>
  advisoryApi.get(`/robo/portfolio`, {
    cancelToken,
    headers: { Authorization: accessToken },
    params,
    paramsSerializer: params => queryString.stringify(params)
  });

export const getRoboPortfolioMeta = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  params?: Params
) =>
  advisoryApi.get('/robo/portfolio/meta', {
    cancelToken,
    headers: { Authorization: accessToken },
    params
  });

export const getRoboSelection = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  params?: Params
) =>
  advisoryApi.get('/robo/selection', {
    cancelToken,
    headers: { Authorization: accessToken },
    params: { no_instruments: 1, ...params }
  });

export const readForecast = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  params?: Params
) => {
  return advisoryApi.get(`/robo/forecast`, {
    cancelToken,
    headers: { Authorization: accessToken },
    params,
    paramsSerializer: params => queryString.stringify(params)
  });
};
// #endregion

// #region advisory risk
export const getRiskConfig = (accessToken: string) => {
  return advisoryApi.get(`/risk/generic/config/`, {
    headers: { Authorization: accessToken }
  });
};

export const getRisk = (accessToken: string, data, endpointType) => {
  const riskModel = endpointType ? endpointType : 'generic';

  return advisoryApi.get(`/risk/${riskModel}/`, {
    params: data,
    headers: { Authorization: accessToken }
  });
};
// #endregion

// #region advisory analyze
export const createRiskReturn = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  data,
  params?: Params
) =>
  advisoryApi.post('/analyze/risk-return', data, {
    cancelToken,
    headers: { Authorization: accessToken },
    params
  });

export const createAnalyzeForecast = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  data: AnalyzeForecastPayload,
  params?: { namespace_id?: number | null }
) => {
  return advisoryApiV2.post<AnalyzeForecastResponse>(
    `/analyze/forecast`,
    data,
    {
      cancelToken,
      headers: { Authorization: accessToken },
      params
    }
  );
};

export const createAnalyzeCost = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  data,
  params?: Params
) => {
  return advisoryApi.post<CreateAnalyzeCostResponse>(`/analyze/cost`, data, {
    cancelToken,
    headers: { Authorization: accessToken },
    params
  });
};

export const createAnalyzeMultiGoalCost = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  data,
  params?: Params
) => {
  return advisoryApi.post<CreateAnalyzeMultiGoalCostResponse>(
    '/analyze/multi-goal-cost',
    data,
    {
      cancelToken,
      headers: { Authorization: accessToken },
      params
    }
  );
};

export const readAnalyzeFrontier = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  params?: Params
) => {
  return advisoryApi.get(`/analyze/frontier`, {
    cancelToken,
    headers: { Authorization: accessToken },
    params
  });
};

export const createHistoricalReturn = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  data,
  params?: Params
) => {
  return advisoryApi.post(`/analyze/historical-return`, data, {
    cancelToken,
    headers: { Authorization: accessToken },
    params
  });
};

export const createHistoricalReturnBenchmark = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  data,
  params?: Params
) => {
  return advisoryApi.post(`/analyze/historical-return-benchmark`, data, {
    cancelToken,
    headers: { Authorization: accessToken },
    params
  });
};

export const createHistoricalReturnV2 = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  data,
  params?: Params
) => {
  return advisoryApiV2.post(`/analyze/historical-return`, data, {
    cancelToken,
    headers: { Authorization: accessToken },
    params
  });
};

export const createOrderSummary = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  data,
  params?: Params
) => {
  return advisoryApi.post(`/analyze/order-summary`, data, {
    cancelToken,
    headers: { Authorization: accessToken },
    params
  });
};

export const createSustainability = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  data,
  params?: Params
) => {
  return advisoryApi.post(`/analyze/sustainability`, data, {
    cancelToken,
    headers: { Authorization: accessToken },
    params
  });
};
export const createTransactionList = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  data,
  params?: Params
) => {
  return advisoryApi.post(`/analyze/transaction-list`, data, {
    cancelToken,
    headers: { Authorization: accessToken },
    params
  });
};

export const createAnalyzeUniverseCost = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  data,
  params?: Params
) => {
  return advisoryApi.post(`/analyze/universe-cost`, data, {
    cancelToken,
    headers: { Authorization: accessToken },
    params
  });
};
export const createAdvancedExpectedValue = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  data: CreateAdvancedExpectedValueRequestData,
  params?: Params
) => {
  return advisoryApi.post<CreateAdvancedExpectedValueResponse>(
    `/analyze/advanced-expected-value`,
    data,
    {
      cancelToken,
      headers: { Authorization: accessToken },
      params
    }
  );
};

export const createAnalyzeAssetClassAllocation = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  data,
  params?: Params
) => {
  return advisoryApi.post(`/analyze/asset-class-allocation`, data, {
    cancelToken,
    headers: { Authorization: accessToken },
    params
  });
};

export const createAnalyzeUniverseSustainability = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  data: CreateAnalyzeUniverseSustainabilityRequestData,
  params?: Params
) =>
  advisoryApi.post<CreateAnalyzeUniverseSustainabilityResponse>(
    `/analyze/universe-sustainability`,
    data,
    {
      cancelToken,
      headers: { Authorization: accessToken },
      params
    }
  );

export const createAnalyzeUniverseCustomAttributes = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  params?: Params
) =>
  advisoryApi.get<CreateAnalyzeUniverseCustomAttributesResponse>(
    `/analyze/universe-custom-attributes`,
    {
      cancelToken,
      headers: { Authorization: accessToken },
      params
    }
  );

export const readAnalyzeAdvancedSuitabilityConfig = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  params?: Params
) => {
  return advisoryApi.get<ReadAnalyzeAdvancedSuitabilityConfigResponse>(
    `analyze/advanced-suitability-config`,
    {
      cancelToken,
      headers: { Authorization: accessToken },
      params
    }
  );
};

export const readAnalyzeAdvancedSuitabilityStatus = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  data: ReadAnalyzeAdvancedSuitabilityStatusPayload,
  params?: Params
) => {
  return advisoryApi.post<ReadAnalyzeAdvancedSuitabilityStatusResponse>(
    `analyze/advanced-suitability-status`,
    data,
    {
      cancelToken,
      headers: { Authorization: accessToken },
      params
    }
  );
};

export const readAnalyzeAdvancedSuitabilityStatusV2 = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  data: ReadAnalyzeAdvancedSuitabilityStatusV2Payload,
  params?: Params
) => {
  return advisoryApiV2.post<ReadAnalyzeAdvancedSuitabilityStatusV2Response>(
    `analyze/advanced-suitability-status`,
    data,
    {
      cancelToken,
      headers: { Authorization: accessToken },
      params
    }
  );
};

export const readAnalyzeUniverseDocuments = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  params?: Params
) => {
  return advisoryApi.get<ReadAnalyzeUniverseDocumentsResponse>(
    `analyze/universe-documents`,
    {
      cancelToken,
      headers: { Authorization: accessToken },
      params
    }
  );
};

// #endregion

// #region advisory categories
export const getCategoriesAll = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  params?: Params
) =>
  advisoryApi.get('/categories/all', {
    cancelToken,
    headers: { Authorization: accessToken },
    params
  });

export const getCategoriesOptionals = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  params?: Params
) =>
  advisoryApi.get('/categories/optionals', {
    cancelToken,
    headers: { Authorization: accessToken },
    params
  });
// #endregion

// #region advisory funds
export const getFundsList = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  params?: Params
) =>
  advisoryApi.get('/fund/list/', {
    cancelToken,
    headers: { Authorization: accessToken },
    params: {
      filter: 0,
      ...params
    }
  });

export const readFundMeta = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  ticker
) => {
  return advisoryApi.get(`/fund/meta/${ticker}`, {
    cancelToken,
    headers: { Authorization: accessToken }
  });
};

export const readFundLookup = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  params: FundLookupParams
) => {
  return advisoryApi.get<ReadFundLookupResponse>(`/fund/lookup`, {
    cancelToken,
    headers: { Authorization: accessToken },
    params,
    paramsSerializer: params =>
      queryString.stringify(params, { arrayFormat: 'none' })
  });
};
// #endregion

// #region advisory namespace
export const getNamespaceList = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  namespaceType
) =>
  advisoryApi.get('/namespace/generic/namespace/', {
    cancelToken,
    headers: { Authorization: accessToken },
    params: {
      namespace_type: namespaceType
    }
  });
// #endregion

// #region advisory state
export const getSessionData = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  sessionId
) => {
  return stateApi.get(`/session/${sessionId}/data`, {
    cancelToken,
    headers: { Authorization: accessToken }
  });
};

export const postSessionData = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  sessionId,
  data
) => {
  return stateApi.post(`/session/${sessionId}/data`, data, {
    cancelToken,
    headers: { Authorization: accessToken }
  });
};

export const putSessionData = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  sessionId,
  sessionNamespace,
  data
) => {
  return stateApi.put(`/session/${sessionId}/data/${sessionNamespace}`, data, {
    cancelToken,
    headers: { Authorization: accessToken }
  });
};

export const putCompleteSession = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  sessionId
) => {
  return stateApi.put(`/session/${sessionId}/:complete`, undefined, {
    cancelToken,
    headers: { Authorization: accessToken }
  });
};
// #endregion

// #region advisory goals
export const createSessionGoal = (accessToken: string, sessionId, payload) => {
  return stateApi.post(`/session/${sessionId}/goal`, payload, {
    headers: { Authorization: accessToken }
  });
};

export const getSessionGoals = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  sessionId
) => {
  return stateApi.get(`/session/${sessionId}/goal`, {
    cancelToken,
    headers: { Authorization: accessToken }
  });
};

export const deleteSessionGoal = (accessToken: string, sessionId, goalId) => {
  return stateApi.delete(`/session/${sessionId}/goal/${goalId}`, {
    headers: { Authorization: accessToken }
  });
};

export const patchSessionGoal = (
  accessToken: string,
  sessionId,
  goalId,
  data
) => {
  return stateApi.patch(`/session/${sessionId}/goal/${goalId}`, data, {
    headers: { Authorization: accessToken }
  });
};

export const postSessionGoal = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  sessionId,
  data
) => {
  return stateApi.post(`/session/${sessionId}/goal`, data, {
    cancelToken,
    headers: { Authorization: accessToken }
  });
};
// #endregion

// #region saving calculator calculator
export const readCalculatorSimple = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  data: CalculatorSimplePayload
) => {
  return savingCalculatorApiV2.post<CalculatorSimpleResponse>(
    `/calculator/simple/`,
    { input: data },
    {
      headers: { Authorization: accessToken },
      cancelToken
    }
  );
};
// #endregion

// #region saving calculator v2
export const readCalculatorCapitalNeed = (accessToken: string, data) => {
  return savingCalculatorApiV2.post(`/calculator/capital_need/`, data, {
    headers: { Authorization: accessToken }
  });
};

export const readCalculatorForecast = (accessToken: string, data) => {
  return savingCalculatorApiV2.post(`/calculator/forecast/`, data, {
    headers: { Authorization: accessToken }
  });
};

export const readCalculatorGoal = (accessToken: string, data) => {
  return savingCalculatorApiV2.post(`/calculator/goal/`, data, {
    headers: { Authorization: accessToken }
  });
};
// #endregion

// #region accounts sync
export const getInvestorAccountsData = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  investorId: string
) =>
  stateApi.get<GetInvestorAccountsDataResponse>(
    `/investor/${investorId}/accounts`,
    {
      cancelToken,
      headers: { Authorization: accessToken }
    }
  );
// #endregion
