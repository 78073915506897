import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';

import { RemindOrResendValues } from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/constants';
import ContactInformationSection from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/contactInformationSection';
import { useStyles } from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/detailsStyles';
import {
  preMeetingSurveyForm,
  usePreMeetingSurveyForm
} from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/form';
import MessageDetails from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/messageDetails';
import RemindResendQuestion from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/remindResendQuestion';
import useInitPreMeetingSurvey from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/useInitPreMeetingSurvey';
import { useReadExistingOtpSession } from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/useReadExistingOtpSession';
import { useResendOneTimePassword } from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/useResendOneTimePassword';
import useSendReminder from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/useSendReminder';
import { usePreMeetingSurveyStore } from 'features/roboAdvice/client/adviceSessionsList/services/preMeetingSurveyStore';
import Button, { ButtonType } from 'features/shared/components/button';
import Modal from 'features/shared/components/modal';
import { useModalBodyMaxDimensions } from 'features/shared/components/modal/useModalBodyMaxDimensions';
import Scrollbars from 'features/shared/components/scrollbars';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedSessionId: string;
};

const RemindResendModal = ({ isOpen, setIsOpen, selectedSessionId }: Props) => {
  const i18n = useI18n();
  const { modalBodyMaxHeight, headerRef, footerRef } =
    useModalBodyMaxDimensions();
  const [page, setPage] = useState(0);
  const { values } = usePreMeetingSurveyForm();
  const { reset: resetPreMeetingSurveyStore } = usePreMeetingSurveyStore();
  const classes = useStyles();
  useInitPreMeetingSurvey({ selectedFlowType: null, isOpen });
  const resendOneTimePassword = useResendOneTimePassword();
  const sendReminder = useSendReminder();
  const readExistingOtpSession = useReadExistingOtpSession();

  useEffect(() => {
    if (isOpen) {
      readExistingOtpSession(selectedSessionId);
    }
  }, [selectedSessionId, isOpen, readExistingOtpSession]);

  const buttonNextComponent = useMemo(() => {
    switch (page) {
      case 0:
        return (
          <Button
            onClick={() => {
              setPage(1);
            }}
            disabled={!values?.remindOrResend}
          >
            {i18n('shared.next')}
          </Button>
        );
      case 1:
        if (values.remindOrResend === RemindOrResendValues.remind) {
          return (
            <Button
              onClick={async () => {
                preMeetingSurveyForm.submit();
                if (preMeetingSurveyForm.getState().hasValidationErrors) {
                  return;
                }

                await sendReminder(selectedSessionId);
                handleCloseModal();
              }}
            >
              {i18n(
                'roboAdvice.client.preMeetingSurvey.remindResendModal.sendReminder'
              )}
            </Button>
          );
        } else if (values.remindOrResend === RemindOrResendValues.resend) {
          return (
            <Button
              onClick={async () => {
                preMeetingSurveyForm.submit();
                if (preMeetingSurveyForm.getState().hasValidationErrors) {
                  return;
                }

                await resendOneTimePassword(selectedSessionId);
                handleCloseModal();
              }}
            >
              {i18n(
                'roboAdvice.client.preMeetingSurvey.remindResendModal.resend'
              )}
            </Button>
          );
        }

        return null;
      default:
        return null;
    }
  }, [
    page,
    values,
    selectedSessionId,
    resendOneTimePassword,
    sendReminder,
    i18n
  ]);

  const handleCloseModal = () => {
    preMeetingSurveyForm.reset();
    setPage(0);
    setIsOpen(false);
    resetPreMeetingSurveyStore();
  };

  return (
    <Form form={preMeetingSurveyForm} onSubmit={() => {}}>
      {() => (
        <Modal
          footer={
            <>
              <Button
                onClick={handleCloseModal}
                buttonType={ButtonType.secondary}
              >
                {i18n('shared.cancel')}
              </Button>

              {buttonNextComponent}
            </>
          }
          header={
            page === 0
              ? i18n('roboAdvice.client.adviceSessionsTable.selfAssessment')
              : values.remindOrResend === RemindOrResendValues.remind
              ? i18n(
                  'roboAdvice.client.preMeetingSurvey.remindResendModal.reminder.header'
                )
              : i18n(
                  'roboAdvice.client.preMeetingSurvey.remindResendModal.resend.header'
                )
          }
          isOpen={isOpen}
          onRequestClose={() => {
            setIsOpen(false);
          }}
          headerRef={headerRef}
          footerRef={footerRef}
          onAfterClose={handleCloseModal}
        >
          <Scrollbars autoHeightMax={modalBodyMaxHeight}>
            {page === 0 && <RemindResendQuestion />}

            {page === 1 && (
              <div>
                {values.remindOrResend === RemindOrResendValues.remind && (
                  <>
                    <div className={classes.section}>
                      <div className={classes.description}>
                        {i18n(
                          'roboAdvice.client.preMeetingSurvey.reminder.description'
                        )}
                      </div>
                    </div>

                    <MessageDetails />
                  </>
                )}

                <ContactInformationSection />
              </div>
            )}
          </Scrollbars>
        </Modal>
      )}
    </Form>
  );
};

export default RemindResendModal;
