import { ConfirmationModule } from '../types';
import { createUseConfirmationStore } from './confirmationStore';

const create = (): ConfirmationModule => {
  return {
    useConfirmationStore: createUseConfirmationStore()
  };
};

export default create;
