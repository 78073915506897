import * as R from 'ramda';
import { createSelector } from 'reselect';

import { CustomersTableOrderKeys } from './reducers/customersTable.js';
import customersSelectors from 'features/shared/services/customers/selectors.js';
import routerSelectors from 'features/shared/services/router/selectors.js';
import tableSelectors from 'features/shared/services/table/selector.js';
import {
  orderDirectionFuncs,
  getPage
} from 'features/shared/services/table/selector.js';
import routeTemplates from 'features/shared/utils/routeTemplates';

export const getIsChildPage = state => {
  const route = routerSelectors.getRoute(state);

  return R.includes(route.route.id, [
    routeTemplates.customers.id,
    routeTemplates.customersTable.id,
    routeTemplates.customerManagement.id
  ]);
};

export const getIsPageOpened = state => {
  const route = routerSelectors.getRoute(state);
  const prevRoute = routerSelectors.getPrevRoute(state);

  return (
    route.route.id === routeTemplates.customersTable.id &&
    prevRoute.route.id !== routeTemplates.customersTable.id
  );
};

export const getIsPageClosed = state => {
  const route = routerSelectors.getRoute(state);
  const prevRoute = routerSelectors.getPrevRoute(state);

  return (
    route.route.id !== routeTemplates.customersTable.id &&
    prevRoute.route.id === routeTemplates.customersTable.id
  );
};

const orderKeyFuncs = {
  [CustomersTableOrderKeys.title]: R.pipe(R.propOr('', 'title'), R.toLower)
};

export const getCustomersTableRows = createSelector(
  state => customersSelectors.getIds(state),
  state => customersSelectors.getEntities(state),
  state => tableSelectors.getTable('customersTable_state', state),
  (customerIds, customers, table) => {
    const orderDirectionFunc = orderDirectionFuncs[table.orderDirection];
    const orderKeyFunc = orderKeyFuncs[table.orderKey];

    return R.pipe(
      R.map(id => {
        const customer = customers[id];

        return customer;
      }),
      R.sortWith([orderDirectionFunc(orderKeyFunc)]),
      getPage(table)
    )(customerIds);
  }
);
