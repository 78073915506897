import React from 'react';

import { useCashflowChartStore } from '../../cashflowChart';
import { useCostChartStore } from '../../costChart';
import { useEfficientFrontierStore } from '../../efficientFrontierChart';
import { useExpectedPathChartStore } from '../../expectedPathChart';
import { useExpectedValueChartStore } from '../../expectedValueChart';
import { useOrderSummaryStore } from '../../orderSummary';
import { usePortfolioChartStore } from '../../portfolioChart';
import { useSessionStore } from '../../session/services/sessionStore';
import { PageStatuses } from '../../shared/components/useReadDataListener';
import { useAdviceSessionStore } from '../../shared/services/adviceSessionStore';
import { useSustainabilityStore } from '../../sustainability';
import { usePageStore as useProposalPageStore } from '../services/pageStore';
import useCachePDF from './useCachePDF';
import { useUnmountEffect } from 'features/shared/hooks/useUnmountEffect.js';

export function useInitPage() {
  const sessionStore = useSessionStore();
  useCachePDF();

  React.useEffect(() => {
    if (!sessionStore.isSessionInitialized) {
      return;
    }

    const proposalPageStore = useProposalPageStore.getState();

    proposalPageStore.changePageStatuses({
      readProposalPageData: PageStatuses.ready,
      readPortfolioChartData: PageStatuses.ready,
      readCashflowData: PageStatuses.ready,
      readExpectedPathChartData: PageStatuses.ready,
      readExpectedValueChartData: PageStatuses.ready,
      readCostChartData: PageStatuses.ready,
      readEfficientFrontierChartData: PageStatuses.ready,
      readHistoricalReturn3MChartData: PageStatuses.ready,
      readHistoricalReturnYTDChartData: PageStatuses.ready,
      readHistoricalReturn1YChartData: PageStatuses.ready,
      readHistoricalReturn3YChartData: PageStatuses.ready,
      readHistoricalReturn5YChartData: PageStatuses.ready,
      readHistoricalReturn10YChartData: PageStatuses.ready,
      readOrderSummary: PageStatuses.ready,
      readSustainability: PageStatuses.ready,
      readTransactionList: PageStatuses.ready
    });
    proposalPageStore.setCachePDFStatus(PageStatuses.ready);
  }, [sessionStore.isSessionInitialized]);

  useUnmountEffect(() => {
    const proposalPageStore = useProposalPageStore.getState();

    proposalPageStore.changePageStatuses({
      readProposalPageData: undefined,
      readPortfolioChartData: undefined,
      readCashflowData: undefined,
      readExpectedPathChartData: undefined,
      readExpectedValueChartData: undefined,
      readCostChartData: undefined,
      readEfficientFrontierChartData: undefined,
      readHistoricalReturn3MChartData: undefined,
      readHistoricalReturnYTDChartData: undefined,
      readHistoricalReturn1YChartData: undefined,
      readHistoricalReturn3YChartData: undefined,
      readHistoricalReturn5YChartData: undefined,
      readHistoricalReturn10YChartData: undefined,
      readOrderSummary: undefined,
      readSustainability: undefined,
      readTransactionList: undefined
    });
    proposalPageStore.setIsGenerateReportPopupOpen(false);
    proposalPageStore.setCachePDFStatus(PageStatuses.ready);

    useExpectedPathChartStore.getState().resetChartData();
    useExpectedValueChartStore.getState().resetChartData();
    useCostChartStore.getState().resetCostData();
    useCashflowChartStore.getState().reset();
    useEfficientFrontierStore.getState().reset();
    useOrderSummaryStore.getState().reset();
    useSustainabilityStore.getState().reset();
    useAdviceSessionStore.getState().reset();
    usePortfolioChartStore.getState().resetChartData();
  });
}
