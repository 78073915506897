import { HistoricalReturnChart } from '@quantfoliorepo/ui-components';
import { isNil, isEmpty, equals, clone } from 'ramda';
import { useMemo } from 'react';

import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import ButtonGroup from 'features/shared/components/buttonGroup';
import LoadingIndicator from 'features/shared/components/loadingIndicator/loadingIndicator';
import { Colors } from 'features/shared/constants/colors';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import { useTheme } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'center'
  },
  noDataMessage: {
    paddingTop: '40px',
    paddingBottom: '40px',
    display: 'flex',
    justifyContent: 'center',
    color: Colors.gray50
  },
  body: {
    paddingTop: '40px'
  }
});

const HistoricalReturnChartCardVisual = ({
  activeItem,
  buttonGroupClassName,
  setActiveItem,
  cultureCode,
  chartStoreChartData,
  selectedGoalsIds,
  getItemStatus,
  width = undefined,
  benchmarkIcon
}) => {
  const i18n = useI18n();
  const classes = useStyles();
  const theme = useTheme();

  const chartData = useMemo(() => {
    if (isNil(chartStoreChartData[activeItem.timeRangeChartDataKey])) {
      return null;
    }

    const chartData = chartStoreChartData[activeItem.timeRangeChartDataKey];

    return chartData
      .reduce((acc, curr) => {
        return [
          ...acc,
          {
            ...curr,
            portfolio: curr.portfolio.map(data => ({
              ...data,
              value: Math.floor(data.value * 1000) / 1000
            }))
          }
        ];
      }, [])
      .reduce((acc, curr, index) => {
        const searchIndex = acc.findIndex(({ portfolio }) =>
          equals(portfolio, curr.portfolio)
        );
        return searchIndex === -1
          ? [
              ...clone(acc),
              {
                ...clone(curr),
                color: curr.color
                  ? curr.color
                  : theme.chartPortfolioColors[index]
              }
            ]
          : [
              ...clone(acc),
              {
                ...clone(curr),
                color: curr.color
                  ? curr.color
                  : theme.chartPortfolioColors[searchIndex]
              }
            ];
      }, [])
      .filter(({ goalId }) => selectedGoalsIds.includes(goalId))
      .reduce((acc, curr) => {
        const searchIndex = acc.findIndex(({ portfolio }) =>
          equals(portfolio, curr.portfolio)
        );
        return searchIndex === -1
          ? [...clone(acc), curr]
          : acc.map((chartData, index) => {
              if (index === searchIndex) {
                return {
                  ...clone(chartData),
                  goalName: `${chartData.goalName} / ${curr.goalName}`
                };
              }
              return chartData;
            });
      }, [])
      .map(data => ({
        ...data,
        portfolio: data.portfolio.map(data => ({
          x: new Date(data.date).getTime(),
          y: data.value
        }))
      }));
  }, [
    activeItem.timeRangeChartDataKey,
    chartStoreChartData,
    selectedGoalsIds,
    theme.chartPortfolioColors
  ]);

  const chartButtons = useMemo(
    () => [
      {
        title: i18n('roboAdvice.proposal.historicalReturn.3m'),
        isActive: activeItem.timeRangeChartDataKey === '3M',
        onClick: () =>
          setActiveItem({
            statusKey: 'readHistoricalReturn3MChartData',
            timeRangeChartDataKey: '3M'
          })
      },
      {
        title: i18n('roboAdvice.proposal.historicalReturn.ytd'),
        isActive: activeItem.timeRangeChartDataKey === 'YTD',
        onClick: () =>
          setActiveItem({
            statusKey: 'readHistoricalReturnYTDChartData',
            timeRangeChartDataKey: 'YTD'
          })
      },
      {
        title: i18n('roboAdvice.proposal.historicalReturn.1y'),
        isActive: activeItem.timeRangeChartDataKey === '1Y',
        onClick: () =>
          setActiveItem({
            statusKey: 'readHistoricalReturn1YChartData',
            timeRangeChartDataKey: '1Y'
          })
      },
      {
        title: i18n('roboAdvice.proposal.historicalReturn.3y'),
        isActive: activeItem.timeRangeChartDataKey === '3Y',
        onClick: () =>
          setActiveItem({
            statusKey: 'readHistoricalReturn3YChartData',
            timeRangeChartDataKey: '3Y'
          })
      },
      {
        title: i18n('roboAdvice.proposal.historicalReturn.5y'),
        isActive: activeItem.timeRangeChartDataKey === '5Y',
        onClick: () =>
          setActiveItem({
            statusKey: 'readHistoricalReturn5YChartData',
            timeRangeChartDataKey: '5Y'
          })
      },
      {
        title: i18n('roboAdvice.proposal.historicalReturn.10y'),
        isActive: activeItem.timeRangeChartDataKey === '10Y',
        onClick: () =>
          setActiveItem({
            statusKey: 'readHistoricalReturn10YChartData',
            timeRangeChartDataKey: '10Y'
          })
      }
    ],
    [activeItem]
  );

  let body = (
    <div className={classes.body}>
      <HistoricalReturnChart
        chartData={chartData}
        cultureCode={cultureCode}
        width={width}
        benchmarkIcon={benchmarkIcon}
      />
    </div>
  );

  if (getItemStatus(activeItem) === PageStatuses.pending) {
    body = <LoadingIndicator />;
  } else if (
    isNil(chartData) &&
    getItemStatus(activeItem) === PageStatuses.ready
  ) {
    body = (
      <div className={classes.noDataMessage}>
        {i18n('historicalReturn.historyIsNotLongEnough')}
      </div>
    );
  } else if (isNil(chartData) || isEmpty(chartStoreChartData)) {
    body = (
      <div className={classes.noDataMessage}>
        {i18n('roboAdvice.proposal.noHistoricalReturnData')}
      </div>
    );
  }

  return (
    <div>
      <ButtonGroup className={buttonGroupClassName} items={chartButtons} />
      {body}
    </div>
  );
};

export default HistoricalReturnChartCardVisual;
