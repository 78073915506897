import * as R from 'ramda';

export const setPageSettings = pageSettings => {
  sessionStorage.setItem('customersPageSettings', JSON.stringify(pageSettings));
};

export const getPageSettings = () => {
  const pageSettings = sessionStorage.getItem('customersPageSettings');
  return R.isNil(pageSettings) ? null : JSON.parse(pageSettings);
};

export const removePageSettings = () => {
  sessionStorage.removeItem('customersPageSettings');
};
