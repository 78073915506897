import { Route, Switch, Redirect } from 'react-router-dom';

import ProfileAndPreferences from './page';
import useInitPage from './useInitPage';
import MainLayout from 'features/shared/components/mainLayout/index.js';
import routeTemplates from 'features/shared/utils/routeTemplates';

const UserProfile = () => {
  useInitPage();

  return (
    <Switch>
      <Route
        exact
        path={routeTemplates.userProfile.config}
        render={({ match }) => {
          const { userId } = match.params;

          return (
            <Redirect to={routeTemplates.userProfileBasic.build(userId)} />
          );
        }}
      />
      <Route
        render={() => (
          <MainLayout>
            <Switch>
              <Route
                path={routeTemplates.userProfileBasic.config}
                component={ProfileAndPreferences}
              />
            </Switch>
          </MainLayout>
        )}
      />
    </Switch>
  );
};

export default UserProfile;
