import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { CRM_LOGIN_PARAM_NAME } from '../crmLoginGuard/constants';
import logo from 'assets/logo.svg';
import Button from 'features/shared/components/button';
import Notification from 'features/shared/components/notification/connectedNotification.js';
import { Colors } from 'features/shared/constants/colors.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import auth from 'features/shared/utils/auth';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  loginButton: {
    marginTop: '2rem',
    marginBottom: '6rem',
    minWidth: '24rem',
    height: '5rem',
    fontSize: '2rem'
  },
  // ie flexbox bug fix
  pageContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  page: {
    backgroundColor: Colors.blue50,
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loginTemplateDialog: {
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: Colors.blue100,
    borderRadius: '0.4rem',
    width: '67rem',
    padding: '0 6rem'
  },
  logoImage: {
    marginTop: '6rem',
    marginBottom: '2rem',
    height: '5rem',
    width: 'auto'
  }
});

const Login = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const { search } = useLocation();
  const redirectUrl = queryString.parse(search)?.redirectUrl
    ? queryString.parse(search)?.redirectUrl
    : search;
  const isAuthenticated = useSelector(sessionSelectors.getIsAuthenticated);
  const crmLogin = queryString.parse(search)[CRM_LOGIN_PARAM_NAME];

  if (isAuthenticated) {
    return <Redirect to={{ pathname: '/', search }} />;
  } else if (crmLogin === 'true') {
    auth.login(redirectUrl);
  }

  return (
    <div className={classes.pageContainer}>
      <div className={classes.page}>
        <Notification />
        <div className={classes.loginTemplateDialog}>
          <img className={classes.logoImage} src={logo} alt="logo" />
          <Button
            className={classes.loginButton}
            onClick={() => auth.login(redirectUrl)}
          >
            {i18n('login.login')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
