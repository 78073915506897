import axios from 'axios';
import * as R from 'ramda';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { manageCustomerForm } from '../services/manageCustomerForm';
import { useManageCustomerStore } from '../services/manageCustomerStore';
import { getCustomer } from 'features/customers/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useReadCustomer() {
  const i18n = useI18n();

  const dispatch = useDispatch();

  const currentCustomerId = useSelector(
    sessionSelectors.getCurrentUserCustomerId
  );
  const accessToken = useSelector(sessionSelectors.getAuth0AccessToken);

  const cancelTokenSourceRef = React.useRef<any>();
  const readCustomer = async () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const manageCustomerStoreState = useManageCustomerStore.getState();

    try {
      manageCustomerStoreState.setIsReadCustomerPending(true);

      const response = await getCustomer(
        currentCustomerId,
        accessToken,
        currentCustomerId
      );

      let values = manageCustomerForm.getState().values;
      const { customer, users } = response.data;

      manageCustomerForm.initialize({
        customerId: customer.id,
        customerCode: customer.code,
        customerAdvisoryKey: customer.advisory_key,
        customerName: customer.title,
        licenses: R.map(
          l => ({
            moduleType: l.module_type,
            licenseType: l.license_type,
            licenseRenewalDate: l.license_renewal_date
          }),
          customer.licenses
        ),
        users: R.map(
          u => ({
            id: u.id,
            email: u.email,
            doesAuth0UserExist: u.does_auth0_user_exist,
            isCustomerAdmin: u.is_customer_admin
          }),
          users
        ),
        usersToAdd: values.usersToAdd
      });
      manageCustomerStoreState.setIsReadCustomerPending(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        manageCustomerStoreState.setIsReadCustomerPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('admin.manageCustomer.readCustomerErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  React.useEffect(() => {
    return () => {
      if (!R.isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
    };
  }, []);

  return readCustomer;
}
