import axios from 'axios';
import { isNil } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  AdvisoryPageStoreData,
  usePageStore as useAdvisoryPageStore
} from '../../advisory/services/pageStore';
import {
  mapServerCategoriesSelectionToClient,
  mapServerPortfoliosToClient,
  mapServerThemesToClient
} from '../../main/components/useReadAdviceSession';
import {
  getCategoriesOptionals,
  getRoboPortfolioMeta,
  getRoboSelection
} from '../../shared/api';
import { useGoalsStore } from '../../shared/services/goalsStore';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export const useReadAdvisoryGoalsData = () => {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<any>();
  const { customRiskTitles } = useCustomerConfig();

  const readAdvisoryGoalsData = async (
    selectedGoalId?: string,
    newProductPlatformNamespace?: number
  ) => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;
    const accessToken = await getQAuthAccessToken(
      auth0AccessToken,
      cancelTokenSource.token
    );
    const { goals } = useGoalsStore.getState();

    const getGoalData = async ({ oldGoalsAdvisoryPageStore, goal }) => {
      const [
        getCategoriesOptionalsResponse,
        getRoboPortfolioMetaResponse,
        getRoboSelectionResponse
      ] = await Promise.all([
        getCategoriesOptionals(accessToken, cancelTokenSource.token, {
          namespace_id:
            newProductPlatformNamespace ?? goal.data.productPlatformNamespace
        }),
        getRoboPortfolioMeta(accessToken, cancelTokenSource.token, {
          namespace_id:
            newProductPlatformNamespace ?? goal.data.productPlatformNamespace
        }),
        getRoboSelection(accessToken, cancelTokenSource.token, {
          namespace_id:
            newProductPlatformNamespace ?? goal.data.productPlatformNamespace
        })
      ]);

      const themes = mapServerThemesToClient(
        getCategoriesOptionalsResponse.data,
        getRoboPortfolioMetaResponse.data
      );
      const portfolios = mapServerPortfoliosToClient(
        i18n,
        getRoboPortfolioMetaResponse.data,
        customRiskTitles
      );
      const categoriesSelection = mapServerCategoriesSelectionToClient(
        getRoboSelectionResponse.data
      );

      return {
        goalThemes: {
          ...oldGoalsAdvisoryPageStore?.goalThemes,
          [goal.goalId]: themes
        },
        goalPortfolios: {
          ...oldGoalsAdvisoryPageStore?.goalPortfolios,
          [goal.goalId]: portfolios
        },
        goalCategoriesSelection: {
          ...oldGoalsAdvisoryPageStore?.goalCategoriesSelection,
          [goal.goalId]: categoriesSelection
        }
      };
    };

    let goalsAdvisoryPageStore: Partial<AdvisoryPageStoreData> = {
      goalThemes: useAdvisoryPageStore.getState().goalThemes,
      goalPortfolios: useAdvisoryPageStore.getState().goalPortfolios,
      goalCategoriesSelection:
        useAdvisoryPageStore.getState().goalCategoriesSelection
    };
    const selectedGoal = goals.find(g => g.goalId === selectedGoalId);
    if (!isNil(selectedGoal)) {
      goalsAdvisoryPageStore = await getGoalData({
        oldGoalsAdvisoryPageStore: goalsAdvisoryPageStore,
        goal: selectedGoal
      });
    } else {
      for (const goal of goals) {
        goalsAdvisoryPageStore = await getGoalData({
          oldGoalsAdvisoryPageStore: goalsAdvisoryPageStore,
          goal
        });
      }
    }

    useAdvisoryPageStore
      .getState()
      .setGoalsAdvisoryData(
        goalsAdvisoryPageStore.goalThemes,
        goalsAdvisoryPageStore.goalPortfolios,
        goalsAdvisoryPageStore.goalCategoriesSelection
      );
  };

  return readAdvisoryGoalsData;
};
