import MockPortfolioChart from './mockPortfolioChart';

const mockChartData = [
  {
    id: 'Globale Government Bonds',
    isin: null,
    name: 'Globale Government Bonds',
    weight: 35
  },
  {
    id: 'Globale Corporate Bonds',
    isin: null,
    name: 'Globale Corporate Bonds',
    weight: 20
  },
  {
    id: 'Norway',
    isin: null,
    name: 'Norway',
    weight: 8
  },
  {
    id: 'Europe',
    isin: null,
    name: 'Europe',
    weight: 5
  },
  {
    id: 'USA',
    isin: null,
    name: 'USA',
    weight: 5
  },
  {
    id: 'Japan',
    isin: null,
    name: 'Japan',
    weight: 2
  },
  {
    id: 'Asia Pacific Ex Japan',
    isin: null,
    name: 'Asia Pacific Ex Japan',
    weight: 15
  },
  {
    id: 'Emerging markets',
    isin: null,
    name: 'Emerging markets',
    weight: 5
  },
  {
    id: 'Alternativ Energy',
    isin: null,
    name: 'Alternativ Energy',
    weight: 5
  }
];

const mockTabsProposal = [
  {
    value: 'client-information',
    progress: 1,
    label: 'Client information',
    disabled: false
  },
  {
    value: 'purpose-and-risk',
    progress: 1,
    label: 'Purpose & risk',
    disabled: false
  },
  {
    value: 'knowledge-and-experience',
    progress: 1,
    label: 'Knowledge & experience',
    disabled: false
  },
  {
    value: 'financial-situation',
    progress: 1,
    label: 'Financial situation',
    disabled: false
  },
  {
    value: 'advisory',
    progress: 1,
    disabled: false,
    isNavigationDisabled: false,
    label: 'Advisory'
  },
  {
    value: 'proposal',
    progress: 0,
    disabled: false,
    isNavigationDisabled: false,
    label: 'Proposal'
  }
];

const mockTabsFinancialSituation = [
  {
    value: 'client-information',
    progress: 1,
    label: 'Client information',
    disabled: false
  },
  {
    value: 'purpose-and-risk',
    progress: 1,
    label: 'Purpose & risk',
    disabled: false
  },
  {
    value: 'knowledge-and-experience',
    progress: 1,
    label: 'Knowledge & experience',
    disabled: false
  },
  {
    value: 'financial-situation',
    progress: 0.8,
    label: 'Financial situation',
    disabled: false
  },
  {
    value: 'advisory',
    progress: 0,
    disabled: false,
    isNavigationDisabled: false,
    label: 'Advisory'
  },
  {
    value: 'proposal',
    progress: 0,
    disabled: true,
    isNavigationDisabled: false,
    label: 'Proposal'
  }
];

const mockAdviceSessionOptions = [
  {
    key: 'advice-session-3',
    title: 'Advice sessions 3'
  },
  {
    key: 'advice-session-2',
    title: 'Advice sessions 2'
  }
];

const MockChart = ({ isSelected }: { isSelected?: boolean }) => {
  return <div />;
};

const mockCarouselElements = [
  {
    tab: 'roboAdvice.proposal.portfolio',
    item: <MockPortfolioChart />,
    dataTestId: 'portfolio-chart-card',
    statusKeys: ['readPortfolioChartData']
  },
  {
    tab: 'roboAdvice.proposal.expectedPath',
    item: <MockChart />,
    dataTestId: 'expected-path-chart-card',
    statusKeys: ['readExpectedPathChartData']
  },
  {
    tab: 'roboAdvice.proposal.expectedValue',
    item: <MockChart />,
    key: 'expected-value-chart-card',
    dataTestId: 'expected-value-chart-card',
    statusKeys: ['readExpectedValueChartData']
  },
  {
    tab: 'roboAdvice.proposal.cost',
    item: <MockChart />,
    key: 'cost-chart-card',
    dataTestId: 'cost-chart-card',
    statusKeys: ['readCostChartData']
  },
  {
    tab: 'roboAdvice.proposal.efficientFrontier',
    item: <MockChart />,
    key: 'efficient-frontier-chart-card',
    dataTestId: 'efficient-frontier-chart-card',
    statusKeys: ['readEfficientFrontierChartData']
  },
  {
    tab: 'roboAdvice.proposal.historicalReturn',
    item: <MockChart />,
    key: 'historical-return-chart-card',
    dataTestId: 'historical-return-chart-card',
    statusKeys: [
      'readHistoricalReturn3MChartData',
      'readHistoricalReturnYTDChartData',
      'readHistoricalReturn1YChartData',
      'readHistoricalReturn3YChartData',
      'readHistoricalReturn5YChartData',
      'readHistoricalReturn10YChartData'
    ]
  }
];

const mockPersonAssets = [
  [
    'personFinancialSituation.realEstate',
    {
      render: {
        fieldSection: 'personAssets',
        componentType: 'detalizedNumberInput',
        labelKey: 'roboAdvice.financialSituation.realEstate'
      },
      validate: {},
      calcProgress: {
        when: {
          type: 'allFieldsEqual',
          fields: [['clientInformation.clientType', 'person']]
        }
      }
    }
  ],
  [
    'personFinancialSituation.valueOfCar',
    {
      render: {
        fieldSection: 'personAssets',
        componentType: 'detalizedNumberInput',
        labelKey: 'roboAdvice.financialSituation.valueOfCarBoat'
      },
      validate: {},
      calcProgress: {
        when: {
          type: 'allFieldsEqual',
          fields: [['clientInformation.clientType', 'person']]
        }
      }
    }
  ],
  [
    'personFinancialSituation.moneyInStock',
    {
      render: {
        fieldSection: 'personAssets',
        componentType: 'detalizedNumberInput',
        labelKey: 'roboAdvice.financialSituation.moneyInStockFunds'
      },
      validate: {},
      calcProgress: {
        when: {
          type: 'allFieldsEqual',
          fields: [['clientInformation.clientType', 'person']]
        }
      }
    }
  ],
  [
    'personFinancialSituation.cash',
    {
      render: {
        fieldSection: 'personAssets',
        componentType: 'detalizedNumberInput',
        labelKey: 'roboAdvice.financialSituation.cash'
      },
      validate: {},
      calcProgress: {
        when: {
          type: 'allFieldsEqual',
          fields: [['clientInformation.clientType', 'person']]
        }
      }
    }
  ],
  [
    'personFinancialSituation.otherAssets',
    {
      render: {
        fieldSection: 'personAssets',
        componentType: 'detalizedNumberInput',
        labelKey: 'roboAdvice.financialSituation.other'
      },
      validate: {},
      calcProgress: {
        when: {
          type: 'allFieldsEqual',
          fields: [['clientInformation.clientType', 'person']]
        }
      }
    }
  ]
];

const mockPersonDebt = [
  [
    'personFinancialSituation.mortgage',
    {
      render: {
        fieldSection: 'personDebt',
        componentType: 'detalizedNumberInput',
        labelKey: 'roboAdvice.financialSituation.mortgage'
      },
      validate: {},
      calcProgress: {
        when: {
          type: 'allFieldsEqual',
          fields: [['clientInformation.clientType', 'person']]
        }
      }
    }
  ],
  [
    'personFinancialSituation.carLoan',
    {
      render: {
        fieldSection: 'personDebt',
        componentType: 'detalizedNumberInput',
        labelKey: 'roboAdvice.financialSituation.carOrBoatLoan'
      },
      validate: {},
      calcProgress: {
        when: {
          type: 'allFieldsEqual',
          fields: [['clientInformation.clientType', 'person']]
        }
      }
    }
  ],
  [
    'personFinancialSituation.studentLoan',
    {
      render: {
        fieldSection: 'personDebt',
        componentType: 'detalizedNumberInput',
        labelKey: 'roboAdvice.financialSituation.studentLoan'
      },
      validate: {},
      calcProgress: {
        when: {
          type: 'allFieldsEqual',
          fields: [['clientInformation.clientType', 'person']]
        }
      }
    }
  ],
  [
    'personFinancialSituation.debt',
    {
      render: {
        fieldSection: 'personDebt',
        componentType: 'detalizedNumberInput',
        labelKey: 'roboAdvice.financialSituation.debt'
      },
      validate: {},
      calcProgress: {
        when: {
          type: 'allFieldsEqual',
          fields: [['clientInformation.clientType', 'person']]
        }
      }
    }
  ],
  [
    'personFinancialSituation.otherDebt',
    {
      render: {
        fieldSection: 'personDebt',
        componentType: 'detalizedNumberInput',
        labelKey: 'roboAdvice.financialSituation.other'
      },
      validate: {},
      calcProgress: {
        when: {
          type: 'allFieldsEqual',
          fields: [['clientInformation.clientType', 'person']]
        }
      }
    }
  ]
];

const mockPersonLiquidity = [
  [
    'personFinancialSituation.sufficientBuffer',
    {
      render: {
        fieldSection: 'personLiquidity',
        componentType: 'buttonSwitch',
        labelKey: 'roboAdvice.financialSituation.sufficientBuffer',
        layout: 'horizontal'
      },
      validate: {},
      calcProgress: {
        when: {
          type: 'allFieldsEqual',
          fields: [['clientInformation.clientType', 'person']]
        }
      }
    }
  ],
  [
    'personFinancialSituation.monthlySurplus',
    {
      render: {
        fieldSection: 'personLiquidity',
        componentType: 'numberInput',
        labelKey: 'roboAdvice.financialSituation.monthlySurplus'
      },
      validate: {},
      calcProgress: {
        when: {
          type: 'allFieldsEqual',
          fields: [['clientInformation.clientType', 'person']]
        }
      }
    }
  ]
];

const useMocks = () => ({
  mockTabsFinancialSituation,
  mockTabsProposal,
  mockAdviceSessionOptions,
  mockCarouselElements,
  mockChartData,
  mockPersonAssets,
  mockPersonDebt,
  mockPersonLiquidity
});

export default useMocks;
