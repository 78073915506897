import axios, { CancelTokenSource } from 'axios';
import * as R from 'ramda';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { clientForm } from '../../../../main/services/clientForm';
import { getENINUBO } from 'features/roboAdvice/shared/api';
import {
  isDateToday,
  isVatNumberValid,
  getFullCompanyOrgNumber
} from 'features/roboAdvice/shared/helpers/enin';
import { SupportedCountryCode as SupportedCountryCodeType } from 'features/roboAdvice/shared/types';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { roundNumber } from 'features/shared/utils/number';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useInitTableUBOInfo() {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<CancelTokenSource>();
  const dispatch = useDispatch();
  const i18n = useI18n();

  const { values } = clientForm.getState();
  const orgNumber = values?.clientInformation?.orgNumber || null;
  const currentCountryCode =
    values.clientInformation?.country?.toLowerCase() || '';
  const eninUpdateDate = values.kyc?.enin?.ubo?.eninUpdateDate;
  const isLastCallToday =
    eninUpdateDate && isDateToday(new Date(eninUpdateDate));

  const isOrgNumberValid = isVatNumberValid(
    orgNumber,
    currentCountryCode as SupportedCountryCodeType
  );

  const fullOrgNumber = getFullCompanyOrgNumber(
    orgNumber,
    currentCountryCode as SupportedCountryCodeType
  );

  const initTableUBOInfo = async (isForcedCall = false) => {
    if (!isForcedCall && isLastCallToday) {
      return;
    }

    if (!isOrgNumberValid) {
      return;
    }

    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    try {
      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const { data } = await getENINUBO(
        accessToken,
        cancelTokenSource.token,
        fullOrgNumber
      );
      const uboList = mapServerUBOList(data) || [];

      clientForm.batch(() => {
        clientForm.change<any>('kyc.ubo', uboList);
        clientForm.change<any>('kyc.enin.ubo.eninUpdateDate', new Date());
      });
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.financialSituation.readENINDataErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  React.useEffect(() => {
    return () => {
      if (!R.isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
    };
  }, []);

  return initTableUBOInfo;
}

const mapServerUBOList = data =>
  data.company_share_ownership_ultimate?.map(item => ({
    ownership: item?.ownership ? roundNumber(item?.ownership * 100, 2) : '',
    identity: '',
    name: item?.shareholder_name || '',
    country: ''
  }));
