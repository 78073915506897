import { useEffect, useState } from 'react';
import { FormSpy } from 'react-final-form';

import {
  Sustainability,
  form as roboAdviceForm
} from '../../form/services/form';
import { useIsSessionReadOnly } from '../../main/services/selectors';
import { Page } from '../../shared/types';
import { EducationalModalsConfig } from '../types';
import AdaptedValueMessage from './adaptedValueMessage';
import AdaptedValueQuestionAlert from './adaptedValueQuestionAlert';
import { ReactComponent as InfoIcon } from 'assets/info.svg';
import { ReactComponent as LeafIcon } from 'assets/leaf.svg';
import AdvisorNotes from 'features/shared/components/advisorNotes';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { getTranslation } from 'features/shared/utils/translations';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import InputErrorMessage from 'features/sharedModules/errors/components/inputErrorMessage';
import { useErrorsStore } from 'features/sharedModules/errors/services/errorsStore';
import { ErrorMessage } from 'features/sharedModules/errors/types';
import FFSelectionCards from 'features/sharedModules/finalForm/components/selectionCards';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  selectionCardsWrap: {
    '&:not(:first-child)': {
      marginTop: Spacing.spacing04
    },
    maxWidth: 1200
  },
  titleBar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: Spacing.spacing01
  },
  title: {
    margin: `0 ${Spacing.spacing01}px`,
    fontSize: Typography.heading2.size,
    fontWeight: FontWeights.medium
  },
  description: {
    color: theme.secondaryColor,
    marginBottom: Spacing.spacing02
  },
  leafIcon: {
    fill: theme.accentColor
  },
  cards: {
    margin: `${Spacing.spacing02}px 0 ${Spacing.spacing02}px 0`
  },
  advisorNotes: {
    margin: `0 ${Spacing.spacing04}px ${Spacing.spacing02}px 0`
  },
  infoIcon: {
    fill: theme.secondaryColor,
    cursor: 'pointer'
  },
  valueAdaptedErrors: {
    marginBottom: Spacing.spacing01
  },
  content: {
    marginLeft: Spacing.spacing02
  }
}));

type Props = {
  title: string;
  description: string;
  items: {
    icon: string;
    id: string;
    title: Record<string, string>;
    isValueAdaptedIconVisible?: boolean;
  }[];
  educationalModalsConfig?: EducationalModalsConfig;
  setIsModalOpen?: (val: boolean) => void;
  setModalContent?: (pages: Page[]) => void;
  setModalHeader?: (header: string) => void;
  elementId?: string;
  valueAdaptedErrors?: ErrorMessage[];
};

const SelectionCardsSection = ({
  title,
  description,
  items,
  educationalModalsConfig,
  setIsModalOpen,
  setModalContent,
  setModalHeader,
  elementId,
  valueAdaptedErrors
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const isSessionReadOnly = useIsSessionReadOnly();
  const customerConfig = useCustomerConfig();
  const isErrorModeEnabled = useErrorsStore(state => state.isErrorModeEnabled);
  const {
    advisorNotes: {
      enabled: isPreferenceNoteEnabled,
      required: isPreferenceNoteRequired
    } = {},
    isValueAdaptedRecorded
  } = customerConfig?.roboAdvice?.sustainability?.sustainabilityPreference;
  const [oldValues, setOldValues] = useState<string[] | undefined>();

  useEffect(() => {
    const { values } = roboAdviceForm.getState();
    setOldValues(values?.sustainability?.preferenceCriteria);

    return () => {
      roboAdviceForm.change(
        `sustainability.arePreferenceCriteriaValueAdaptedQuestionsVisible` as any,
        true
      );
    };
  }, []);

  const handleAfterChange = (ids: string[]) => {
    if (!isValueAdaptedRecorded) {
      return;
    }
    const { values } = roboAdviceForm.getState();

    const unselectedValues = oldValues?.filter(id => !ids.includes(id)) || [];

    // If the user has visited the preference criteria question before or if the user has unselected any value, then show the value adapted question
    if (
      values.sustainability
        ?.arePreferenceCriteriaValueAdaptedQuestionsVisible ||
      unselectedValues.length > 0
    ) {
      const selectedValues = ids.filter(id => !oldValues?.includes(id));
      const changedValue = [...unselectedValues, ...selectedValues]?.[0];
      roboAdviceForm.change(
        `sustainability.preferenceCriteriaValueAdapted.${changedValue}.isValueAdaptedQuestionVisible` as any,
        true
      );
      roboAdviceForm.change(
        `sustainability.arePreferenceCriteriaValueAdaptedQuestionsVisible` as any,
        true
      );
    }

    setOldValues(ids);
  };

  const translatedItems = items.map(i => {
    const { values } = roboAdviceForm.getState();

    let valueRecordedData = {};
    if (isValueAdaptedRecorded) {
      valueRecordedData = {
        isValueAdaptedIconVisible:
          values.sustainability?.preferenceCriteriaValueAdapted?.[i.id]
            ?.isValueAdaptedAnswer,
        onCloseValueAdapted: () => {
          roboAdviceForm.change(
            `sustainability.preferenceCriteriaValueAdapted.${i.id}.isValueAdaptedAnswer` as any,
            false
          );
          roboAdviceForm.change(
            `sustainability.preferenceCriteriaValueAdapted.${i.id}.isValueAdaptedQuestionVisible` as any,
            false
          );
        }
      };
    }

    return {
      ...i,
      ...valueRecordedData,
      title: getTranslation(i.title)
    };
  });

  return (
    <FormSpy subscription={{ values: true }}>
      {({
        values: { sustainability }
      }: {
        values: { sustainability: Sustainability };
      }) => (
        <div className={classes.selectionCardsWrap} id={elementId}>
          <div className={classes.titleBar}>
            <LeafIcon className={classes.leafIcon} />
            <div className={classes.title}>{title}</div>
            {educationalModalsConfig?.enabled &&
              educationalModalsConfig.preferenceCriteria?.enabled &&
              educationalModalsConfig.preferenceCriteria?.pages &&
              educationalModalsConfig.preferenceCriteria.pages.length > 0 && (
                <InfoIcon
                  className={classes.infoIcon}
                  onClick={
                    setIsModalOpen && setModalContent && setModalHeader
                      ? () => {
                          setModalContent(
                            educationalModalsConfig.preferenceCriteria!.pages
                          );
                          setModalHeader(title);
                          setIsModalOpen(true);
                        }
                      : () => {}
                  }
                />
              )}
            {isValueAdaptedRecorded &&
              Object.values(
                sustainability?.preferenceCriteriaValueAdapted ?? {}
              ).some(val => val.isValueAdaptedAnswer) && (
                <AdaptedValueMessage />
              )}
          </div>

          <div className={classes.content}>
            <div className={classes.description}>{description}</div>

            {valueAdaptedErrors && valueAdaptedErrors?.length > 0 && (
              <div className={classes.valueAdaptedErrors}>
                {valueAdaptedErrors?.map(error => (
                  <InputErrorMessage
                    message={error.alertMessage}
                    key={error.alertMessage}
                  />
                ))}
              </div>
            )}

            {Object.entries(
              sustainability?.preferenceCriteriaValueAdapted ?? {}
            )
              .filter(([_, data]) => data.isValueAdaptedQuestionVisible)
              .map(([fieldId]) => {
                const translatedTitle = getTranslation(
                  items.find(i => i.id === fieldId)?.title
                );

                return (
                  <AdaptedValueQuestionAlert
                    id="preference-criteria-question-alert"
                    hasError={
                      valueAdaptedErrors &&
                      valueAdaptedErrors?.length > 0 &&
                      isErrorModeEnabled
                    }
                    customMessage={`${translatedTitle} - ${i18n(
                      'roboAdvice.sustainability.doYouWantToMarkAsAdapted'
                    )}`}
                    onClickNo={() => {
                      roboAdviceForm.batch(() => {
                        roboAdviceForm.change(
                          `sustainability.preferenceCriteriaValueAdapted.${fieldId}.isValueAdaptedAnswer` as any,
                          false
                        );
                        roboAdviceForm.change(
                          `sustainability.preferenceCriteriaValueAdapted.${fieldId}.isValueAdaptedQuestionVisible` as any,
                          false
                        );
                      });
                    }}
                    onClickYes={() => {
                      roboAdviceForm.batch(() => {
                        roboAdviceForm.change(
                          `sustainability.preferenceCriteriaValueAdapted.${fieldId}.isValueAdaptedAnswer` as any,
                          true
                        );
                        roboAdviceForm.change(
                          `sustainability.preferenceCriteriaValueAdapted.${fieldId}.isValueAdaptedQuestionVisible` as any,
                          false
                        );
                      });
                    }}
                  />
                );
              })}
            <div className={classes.cards}>
              <FFSelectionCards
                name="sustainability.preferenceCriteria"
                items={translatedItems}
                disabled={isSessionReadOnly}
                afterChange={handleAfterChange}
              />
            </div>
            <div className={classes.advisorNotes}>
              {isPreferenceNoteEnabled && (
                <AdvisorNotes
                  required={isPreferenceNoteRequired}
                  name={'sustainability.preferenceCriteriaAdvisorNotes'}
                  disabled={isSessionReadOnly}
                  minRows={2}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </FormSpy>
  );
};

export default SelectionCardsSection;
