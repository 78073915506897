import { z } from 'zod';

export const themesSchema = z.array(
  z.object({
    id: z.string(),
    title: z.string(),
    icon: z.string()
  })
);

export type Themes = z.infer<typeof themesSchema>;

export const defaultThemes: Themes = [
  {
    id: 'C12',
    title: 'themes.healthcare',
    icon: 'healthcare'
  },
  {
    id: 'C11',
    title: 'themes.technology',
    icon: 'technology'
  },
  {
    id: 'C13',
    title: 'themes.china',
    icon: 'china'
  },
  {
    id: 'C18',
    title: 'themes.india',
    icon: 'india'
  },
  {
    id: 'C14',
    title: 'themes.ecology',
    icon: 'ecology'
  },
  {
    id: 'C16',
    title: 'themes.alternativeEnergy',
    icon: 'alternativeEnergy'
  },
  {
    id: 'C15',
    title: 'themes.preciousMetals',
    icon: 'preciousMetals'
  },
  {
    id: 'C17',
    title: 'themes.globalRealEstate',
    icon: 'globalRealEstate'
  }
];
