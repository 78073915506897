import { isNil } from 'ramda';

import { ClientTypes } from '../../../../shared/constants/session';
import { roundNumber } from '../../../../shared/utils/number';

type MonthlySurplusDetail = {
  id: string;
  title?: string;
  toAdvisory?: number;
  value?: number;
};

type MonthlySurplus = null | undefined | number | MonthlySurplusDetail[];

export const calculateMonthlySurplus = (
  monthlySurplus: MonthlySurplus
): number | null => {
  const isDetalizedNumberInputEmpty =
    Array.isArray(monthlySurplus) &&
    !monthlySurplus.some(({ value }) => !isNil(value));

  if (isNil(monthlySurplus) || isDetalizedNumberInputEmpty) {
    return null;
  }

  if (typeof monthlySurplus === 'number') {
    return monthlySurplus;
  }

  return Array.isArray(monthlySurplus)
    ? monthlySurplus.reduce((acc, { value }) => (value ? acc + value : acc), 0)
    : 0;
};

export const calculateInternalPortfolioTotalValue = ({
  financialSituationValues,
  clientType,
  accountsSyncPersonMapping,
  accountsSyncCompanyMapping
}) => {
  const accountsSyncMapping =
    clientType === ClientTypes.company
      ? accountsSyncCompanyMapping
      : accountsSyncPersonMapping;
  const positionsFieldId = accountsSyncMapping.find(
    map => map.accountValueTarget === 'positions'
  )?.financialSituationFieldId;
  if (positionsFieldId && financialSituationValues?.[positionsFieldId]) {
    return roundNumber(
      financialSituationValues[positionsFieldId]
        ?.filter(field => !isNil(field.accountNumber))
        .reduce(
          (acc, curr) =>
            acc +
            (curr.toAdvisory || 0) *
              (financialSituationValues.amountForAdviceSwitch
                ? (curr.value || 0) / 100
                : 1),
          0
        ),
      0
    );
  }
};
