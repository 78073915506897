import { useParams } from 'react-router';

import { AdviceSessionParams } from '../../../../../shared/constants/session';
import { Spacing } from '../../../../../shared/constants/spacing';
import {
  FontWeights,
  Typography
} from '../../../../../shared/constants/typography';
import { createUseStyles } from '../../../../../sharedModules/styles/components/styles';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { form } from 'features/roboAdvice/adviceSession/form/services/form';
import { Layouts } from 'features/roboAdvice/shared/constants/index';
import ConfigurableLabel from 'features/shared/components/configurableLabel/';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { FieldComponentTypes } from 'features/sharedModules/declarativeForm/constants';

const useStyles = createUseStyles(theme => ({
  standardLabel: {
    width: 'auto',
    maxWidth: 'calc(90% - 250px)'
  },
  standardInputWidth: {
    width: '230px'
  },
  label: {
    color: theme.primaryColor,
    fontWeight: FontWeights.normal,
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,
    marginTop: 5,
    marginBottom: Spacing.spacing01
  },
  inputSubSection: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > div': {
      minWidth: '230px',
      marginRight: 0
    }
  },
  dropdownSection: {
    justifyContent: 'space-between'
  }
}));

export const useInitPage = () => {
  const classes = useStyles();
  const { clientType } = useParams<AdviceSessionParams>();
  const {
    roboOrderFlow: { orderInformationPage2 }
  } = useCustomerConfig();

  const pageConfig = orderInformationPage2[clientType];

  const sections = pageConfig?.sections
    ?.filter(({ isEnabled }) => isEnabled)
    .sort((a, b) => a.order - b.order)
    .map(
      ({
        label,
        labelKey,
        showSum,
        sumLabel,
        showBackground,
        required,
        name,
        items
      }) => {
        const languageKey = getUserPageLanguage();

        const fields = items.map(config => {
          const labelContent =
            languageKey && config.label ? config.label[languageKey] : '';
          const customLabel = (
            <div className={classes.label}>
              <ConfigurableLabel
                required={config.required}
                label={labelContent}
                info={config.info && languageKey && config.info[languageKey]}
              />
            </div>
          );

          if (
            config.componentType === FieldComponentTypes.textInput ||
            config.componentType === FieldComponentTypes.numberInput
          ) {
            return {
              name: config.name,
              config: {
                ...config,
                labelValue: customLabel
              },
              labelClassName: classes.standardLabel
            };
          }

          if (config.componentType === FieldComponentTypes.textarea) {
            return {
              name: config.name,
              config: {
                ...config,
                labelValue: customLabel
              },
              className: classes.standardInputWidth,
              sectionClassName: classes.inputSubSection,
              labelClassName: classes.standardLabel
            };
          }

          if (config.componentType === FieldComponentTypes.dropdown) {
            return {
              name: config.name,
              config: {
                ...config,
                labelValue: customLabel
              },
              labelClassName: classes.standardLabel,
              externalClasses: {
                section: classes.dropdownSection
              },
              afterChange: value => {
                config.items?.forEach(({ activeValue, textField }) => {
                  if (!!textField && !value.includes(activeValue)) {
                    form.change(textField?.name as any, null);
                  }
                });
              }
            };
          }

          if (
            config.componentType === FieldComponentTypes.radioButtons ||
            config.componentType === FieldComponentTypes.checkboxes
          ) {
            return {
              name: config.name,
              config: {
                ...config,
                layout: Layouts.horizontal,
                labelValue: customLabel
              },
              labelClassName: classes.standardLabel,
              subSectionClassName: classes.inputSubSection,
              afterChange: value => {
                config.items?.forEach(({ activeValue, textField }) => {
                  if (!!textField && !value.includes(activeValue)) {
                    form.change(textField?.name as any, null);
                  }
                });
              }
            };
          }

          if (config.componentType === FieldComponentTypes.buttonSwitch) {
            return {
              name: config.name,
              config: {
                ...config,
                layout: Layouts.horizontal,
                labelValue: customLabel
              },
              labelClassName: classes.standardLabel
            };
          }

          if (config.componentType === FieldComponentTypes.datepicker) {
            return {
              name: config.name,
              config: {
                ...config,
                layout: Layouts.horizontal,
                labelValue: customLabel
              },
              labelClassName: classes.standardLabel
            };
          }
          return { name: config.name, config };
        });
        return {
          label,
          labelKey,
          showSum,
          sumLabel,
          showBackground,
          required,
          name,
          items: fields
        };
      }
    );

  return { enabled: pageConfig.enabled, sections };
};
