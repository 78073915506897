import { FormControlLabel } from '@material-ui/core';

import { useStyles } from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/detailsStyles';
import { usePreMeetingSurveyForm } from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/form';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import FinalFormCheckbox from 'features/sharedModules/finalForm/components/checkbox';
import FinalFormTextarea from 'features/sharedModules/finalForm/components/textarea';

const MessageDetails = () => {
  const i18n = useI18n();
  const classes = useStyles();
  const {
    values: preMeetingSurveyValues,
    errors,
    touched
  } = usePreMeetingSurveyForm();

  const isErrorShown =
    !!errors?.shareAssessmentLink &&
    (touched?.isEmailToCustomerEnabled ||
      preMeetingSurveyValues.isEmailToCustomerEnabled);

  return (
    <div className={classes.section}>
      <div className={classes.header}>
        {i18n('roboAdvice.client.preMeetingSurvey.shareAssessmentLink.header')}
      </div>
      <div className={classes.description}>
        {i18n(
          'roboAdvice.client.preMeetingSurvey.shareAssessmentLink.description'
        )}
      </div>

      <div className={classes.checkboxBox}>
        <FormControlLabel
          control={
            <FinalFormCheckbox
              name="isEmailToCustomerEnabled"
              width={22.5}
              height={22.5}
              hasError={isErrorShown}
            />
          }
          label={
            <div className={classes.checkboxLabel}>
              {i18n(
                'roboAdvice.client.preMeetingSurvey.contactInformation.emailToCustomer'
              )}
            </div>
          }
          className={classes.checkboxContainer}
        />

        {preMeetingSurveyValues.isEmailToCustomerEnabled && (
          <FinalFormTextarea
            name="emailContent"
            minRows={2}
            className={classes.textarea}
          />
        )}
      </div>

      <div className={classes.checkboxBox}>
        <FormControlLabel
          control={
            <FinalFormCheckbox
              name="isSmsToCustomerEnabled"
              width={22.5}
              height={22.5}
              hasError={isErrorShown}
            />
          }
          label={
            <div className={classes.checkboxLabel}>
              {i18n(
                'roboAdvice.client.preMeetingSurvey.contactInformation.smsToCustomer'
              )}
            </div>
          }
          className={classes.checkboxContainer}
        />

        {preMeetingSurveyValues.isSmsToCustomerEnabled && (
          <FinalFormTextarea
            name="smsContent"
            minRows={2}
            className={classes.textarea}
            showCounter
            maxLength={160}
            counterClassName={classes.phoneTextareaCounter}
          />
        )}
      </div>

      {isErrorShown && (
        <div className={classes.errorMessage}>
          * {i18n(errors.shareAssessmentLink)}
        </div>
      )}
    </div>
  );
};

export default MessageDetails;
