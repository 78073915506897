import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import * as R from 'ramda';

import { FontFamilies, TextFontSizes } from 'modules/shared/constants/fonts.js';
import { createUseStyles, useTheme } from 'modules/styles/components/styles.js';
import { formatNumber } from 'modules/shared/utils/number.js';
import { hexOpacity } from 'modules/shared/constants/colors';
import Legend from 'modules/shared/components/legend';

export const PortfolioIds = {
  customPortfolio: 'customPortfolio',
  existingPortfolio: 'existingPortfolio',
  modelPortfolio: 'modelPortfolio',
  suggestedPortfolio: 'suggestedPortfolio',
  benchmarkPortfolio: 'benchmarkPortfolio'
};

const useStyles = createUseStyles({
  tooltipHeader: {
    fontFamily: FontFamilies.roboto,
    fontSize: TextFontSizes.xxSmall,
    lineHeight: TextFontSizes.xxSmallLineHeight
  },
  tooltipFirstLine: {
    fontFamily: FontFamilies.roboto,
    fontSize: TextFontSizes.xSmall,
    lineHeight: TextFontSizes.xSmallLineHeight,
    marginTop: '4px'
  }
});

const HistoricalReturnChart = ({
  chartData,
  cultureCode,
  isInteractive,
  onLoad,
  width,
  customColors,
  benchmarkIcon
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const chartComponent = React.useRef();

  const colors = {
    labelsColor: customColors?.labelsColor || theme.secondaryColor,
    errorNegativeColor:
      customColors?.errorNegativeColor || theme.errorNegativeColor,
    successColor: customColors?.successColor || theme.successColor
  };

  React.useEffect(() => {
    const reflow = () => {
      chartComponent.current.chart.reflow();
    };

    window.addEventListener('resize', reflow);

    reflow();

    return () => {
      window.removeEventListener('resize', reflow);
    };
  }, []);

  const series = React.useMemo(
    () =>
      chartData.map(({ goalId: id, color, portfolio: data }) => ({
        id,
        type: 'line',
        color,
        lineWidth: 3,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false
            }
          }
        },
        data
      })),
    [chartData]
  );

  const chartOptions = {
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    chart: {
      backgroundColor: 'transparent',
      marginTop: 35,
      marginRight: 35,
      marginLeft: 35,
      animation: false,
      events: {
        load: onLoad
      }
    },
    title: {
      text: null
    },
    xAxis: {
      crosshair: {
        color: colors.labelsColor,
        zIndex: 5
      },
      labels: {
        format: '{value: %d.%m.%y}',
        style: {
          color: colors.labelsColor,
          fontSize: TextFontSizes.xxSmall,
          lineHeight: TextFontSizes.xxSmallLineHeight,
          fontFamily: FontFamilies.roboto
        },
        overflow: 'allow',
        y: 35
      },
      lineWidth: 0,
      minPadding: 0,
      maxPadding: 0,
      tickWidth: 0,
      type: 'datetime'
    },
    yAxis: {
      crosshair: {
        color: colors.labelsColor,
        zIndex: 5
      },
      gridLineColor: `${colors.labelsColor}${hexOpacity[50]}`,
      labels: {
        formatter: ({ value }) => formatNumber(cultureCode, value, 0, 0),
        style: {
          color: colors.labelsColor,
          fontSize: TextFontSizes.xxSmall,
          lineHeight: TextFontSizes.xxSmallLineHeight,
          fontFamily: FontFamilies.roboto
        },
        x: -10
      },
      tickAmount: 6,
      title: {
        enabled: false
      }
    },
    tooltip: {
      enabled: isInteractive,
      crosshairs: true,
      xDateFormat: '%d.%m.%y',
      useHTML: true,
      headerFormat: `<span class='${classes.tooltipHeader}'>{point.key}</span><br>`,
      pointFormatter: function () {
        const value = formatNumber(cultureCode, this.y, 0, 2);

        return `<div class='${classes.tooltipFirstLine}'>${value}</div>`;
      },
      borderColor: colors.boundsColor
    },
    plotOptions: {
      series: {
        enableMouseTracking: isInteractive,
        animation: false,
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },
    series
  };

  return (
    <div>
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        containerProps={{
          style: {
            width: R.isNil(width) ? 'auto' : `${width}px`
          }
        }}
        options={chartOptions}
      />
      <Legend
        items={chartData.map(data => ({
          title: (
            <>
              <span>{data.goalName}</span>
              <span
                style={{
                  color:
                    data.statistics.growth < 0
                      ? colors.errorNegativeColor
                      : colors.successColor
                }}
              >
                {formatNumber(cultureCode, data.statistics.growth * 100, 0, 1)}%
              </span>
              {data.goalId === PortfolioIds.benchmarkPortfolio && benchmarkIcon}
            </>
          ),
          style: { backgroundColor: data.color }
        }))}
      />
    </div>
  );
};

HistoricalReturnChart.defaultProps = {
  isInteractive: true
};

export default HistoricalReturnChart;
