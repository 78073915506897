import * as R from 'ramda';
import create from 'zustand';

import { TableStoreData, TableStoreState, UseTableStore } from '../types';

export const createUseTableStore = (
  id: string,
  intialData?: Partial<TableStoreData>
): UseTableStore => {
  const favoriteItemsLocalStorageKey = `tables_${id}_favoriteItems`;
  const defaultData = {
    orderKey: undefined,
    orderDirection: undefined,
    pageNumber: 0,
    pageSize: 10,
    favoriteItems: {},
    selectedRowIds: [],
    ...intialData
  };

  return create<TableStoreState>((set, get) => {
    const getFavoriteItems = () => {
      const favoriteItemsString = localStorage.getItem(
        favoriteItemsLocalStorageKey
      );

      return favoriteItemsString == null ? {} : JSON.parse(favoriteItemsString);
    };
    const saveFavoriteItems = () => {
      localStorage.setItem(
        favoriteItemsLocalStorageKey,
        JSON.stringify(get().favoriteItems)
      );
    };

    return {
      ...defaultData,
      reset() {
        set(defaultData);
      },
      initialize(initialData) {
        set({
          ...defaultData,
          favoriteItems: getFavoriteItems(),
          ...initialData
        });
      },
      deleteItem(itemId) {
        set(R.over(R.lensProp('favoriteItems'), R.dissoc(itemId)), true);

        saveFavoriteItems();
      },
      setFavoriteItem(itemId, isItemFavorite) {
        set(R.set(R.lensPath(['favoriteItems', itemId]), isItemFavorite), true);

        saveFavoriteItems();
      },
      changeOrder(orderKey, orderDirection) {
        set({
          orderKey,
          orderDirection,
          pageNumber: 0
        });
      },
      changePageNumber(pageNumber) {
        set({
          pageNumber
        });
      },
      changePageSize(pageSize) {
        set({
          pageSize,
          pageNumber: 0
        });
      },
      changeRowSelection(isSelected, rowId) {
        const { selectedRowIds } = get();
        const rowIndex = R.indexOf(rowId, selectedRowIds);
        const isIncluded = rowIndex !== -1;

        if (!isIncluded && isSelected) {
          set(R.over(R.lensProp('selectedRowIds'), R.append(rowId)));
        } else if (isIncluded && !isSelected) {
          set(R.over(R.lensProp('selectedRowIds'), R.remove(rowIndex, 1)));
        }
      },
      changeAllRowsSelection(isSelected, allRowIds) {
        if (isSelected) {
          set({ selectedRowIds: allRowIds ?? [] });
        } else {
          set({ selectedRowIds: [] });
        }
      }
    };
  });
};
