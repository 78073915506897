import classNames from 'classnames';
import React from 'react';

import TextInput from '../index';
import { injectSheet } from 'features/sharedModules/styles/components/styles';

const styles = {
  container: {
    position: 'relative'
  },
  textInput: {},
  leftBlock: {
    position: 'absolute',
    top: 'calc(0.8rem + 2px)',
    left: '2.4rem'
  },
  rightBlock: {
    position: 'absolute',
    top: 'calc(0.8rem + 2px)',
    right: '2.4rem'
  }
};

const TextInputWithBothBlocks = ({
  className,
  textInputProps,
  leftBlockProps,
  leftBlockChildren,
  rightBlockProps,
  rightBlockChildren,
  classes
}) => {
  return (
    <div className={classNames(classes.container, className)}>
      <TextInput
        {...textInputProps}
        className={classNames(classes.textInput, textInputProps.className)}
      />
      <div className={classNames(classes.leftBlock, leftBlockProps.className)}>
        {leftBlockChildren}
      </div>
      <div
        className={classNames(classes.rightBlock, rightBlockProps.className)}
      >
        {rightBlockChildren}
      </div>
    </div>
  );
};

export default injectSheet(styles)(TextInputWithBothBlocks);
