import { map } from 'ramda';
import { useMemo } from 'react';

import { getUserPageLanguage } from '../../../../pageLanguage/main/components/usePageLanguage';
import { getTranslation } from '../../../../shared/utils/translations';
import { useCustomerConfig } from '../../../../sharedModules/customerConfig/components/useCustomerConfig';
import AdviceSessionTypeSelectDropdown from './adviceSessionTypeSelectDropdown';
import Table from './table';
import { ReactComponent as RefreshIcon } from 'assets/refresh.svg';
import { useReadAdviceSessions } from 'features/roboAdvice/client/adviceSessionsList/components/useReadAdviceSessions';
import { useClientPageStore } from 'features/roboAdvice/client/main/services/clientPageStore';
import Button, { ButtonType } from 'features/shared/components/button';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  toolbar: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginBottom: Spacing.spacing01
  },
  refreshIcon: {
    '& path': {
      fill: 'currentColor'
    }
  },
  refreshButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: Spacing.spacing00,

    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    color: theme.accentColor
  }
}));

const AdviceSessionsList = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const {
    adviceFlows,
    tenantSettings: { preMeetingSurveyEnabled }
  } = useCustomerConfig();
  const options = useMemo(
    () =>
      map(
        option => ({ key: option.name, title: getTranslation(option.label) }),
        adviceFlows
      ),
    [adviceFlows, getUserPageLanguage()]
  );
  const readAdviceSessions = useReadAdviceSessions();
  const { isReadAdviceSessionsPending } = useClientPageStore();

  return (
    <div>
      <div className={classes.toolbar}>
        <div>
          <AdviceSessionTypeSelectDropdown options={options} />
        </div>

        {preMeetingSurveyEnabled && (
          <Button
            className={classes.refreshButton}
            onClick={() => {
              readAdviceSessions();
            }}
            buttonType={ButtonType.tertiary}
            disabled={isReadAdviceSessionsPending}
          >
            <RefreshIcon className={classes.refreshIcon} />
            <span>{i18n('roboAdvice.adviceSessionsList.refresh')}</span>
          </Button>
        )}
      </div>
      <Table />
    </div>
  );
};

export default AdviceSessionsList;
