import { z } from 'zod';

import { SessionNamespaces } from './constants';

export type SessionNamespace =
  typeof SessionNamespaces[keyof typeof SessionNamespaces];

const pageBuilderContentSchema = z.union([
  z.string(),
  z.record(z.any()),
  z.array(z.record(z.any()))
]);

export type PageBuilderContent = z.infer<typeof pageBuilderContentSchema>;

export const pageBuilderSectionSchema = z.object({
  type: z.string(),
  content: pageBuilderContentSchema,
  align: z.string().optional(),
  imgStyle: z
    .object({
      width: z.string().optional().nullable(),
      height: z.string().optional().nullable()
    })
    .optional()
});

export type PageBuilderSection = z.infer<typeof pageBuilderSectionSchema>;

export const pageSchema = z.object({
  sections: z.array(pageBuilderSectionSchema)
});

export type Page = z.infer<typeof pageSchema>;
