import axios from 'axios';
import * as R from 'ramda';
import { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getSettings } from '../../../shared/api';
import { Statuses } from '../../../shared/constants';
import { ANALYTICS_LINKS, GOAL_TABLE_LINKS } from '../constants';
import { useProposalStore } from '../services/proposalStore';
import { ProposalsForm } from '../services/proposalsForm';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useReadSettings() {
  const i18n = useI18n();
  const auth0AccessToken: string = useSelector(
    sessionSelectors.getAuth0AccessToken
  );
  const dispatch = useDispatch();

  const cancelTokenSourceRef = useRef<any>();
  const readSettings = async () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const proposalStore = useProposalStore.getState();

    try {
      proposalStore.setPageStatus('readSettings', Statuses.pending);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const response = await getSettings(
        accessToken,
        cancelTokenSource.token,
        'deepalpha',
        ''
      );

      ProposalsForm.initialize(
        response.data.settings.reduce((acc, i) => {
          if (GOAL_TABLE_LINKS.some(e => e.config === i.name)) {
            return {
              ...acc,
              [i.name]: i.value
            };
          } else if (ANALYTICS_LINKS.some(e => e.config === i.name)) {
            return {
              ...acc,
              [i.name]: i.value
            };
          }
          return { ...acc };
        }, {})
      );

      proposalStore.setPageStatus('readSettings', Statuses.succeed);
    } catch (error) {
      if (!axios.isCancel(error)) {
        proposalStore.setPageStatus('readSettings', Statuses.failed);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'admin.appAdmin.advisorSolution.visuals.readSettingsErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (!R.isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
    };
  }, []);

  return readSettings;
}
