import classNames from 'classnames';
import * as R from 'ramda';
import NumberFormat, {
  FormatInputValueFunction,
  NumberFormatValues
} from 'react-number-format';

import { Layouts } from 'features/roboAdvice/shared/constants';
import ErrorMessage from 'features/shared/components/errorMessage/index.js';
import TextInput from 'features/shared/components/textInput/index';
import { roundNumber } from 'features/shared/utils/number';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  section: {
    '& + &': {
      marginTop: '3rem'
    }
  },
  verticalSection: {},
  horizontalSection: {
    display: 'inline-flex',
    alignItems: 'baseline'
  },
  label: {
    color: theme.primaryColor
  },
  verticalLabel: {},
  horizontalLabel: {
    marginRight: '2.4rem',
    flex: '0 0 auto',
    width: '350px',
    overflowX: 'hidden'
  },
  required: {
    color: theme.errorNegativeColor
  }
}));

export type Props = {
  value?: number;
  onChange: (value: any) => void;
  onFocus?: (value: any) => void;
  onBlur?: (value: any) => void;
  touched?: boolean;
  errorText?: string;
  afterChange?: (value: string | number | undefined) => void;
  fixedValue?: any;
  label?: any;
  placeholder?: string;
  className?: string;
  sectionClassName?: string;
  labelClassName?: string;
  thousandSeparator?: string;
  decimalSeparator?: string;
  decimalScale?: number;
  allowNegative?: boolean;
  allowLeadingZeros?: boolean;
  suffix?: string;
  normalizeValue?: (floatValue: number | string | undefined) => number | string;
  externalClasses?: Record<string, any>;
  disabled?: boolean;
  layout?: keyof typeof Layouts;
  format?: string | FormatInputValueFunction;
  additionalInputComponent?: React.ReactNode;
  isAllowed?: (values: NumberFormatValues) => boolean;
  required?: boolean;
  id?: string;
  'data-testid'?: string;
  isErrorMessageHidden?: boolean;
  hasError?: boolean;
};

const NumberInput = ({
  value,
  onChange,
  onFocus,
  onBlur,
  touched,
  errorText,
  afterChange,
  fixedValue,
  label,
  placeholder,
  className,
  sectionClassName: sectionClassNameProp,
  labelClassName: labelClassNameProp,
  thousandSeparator,
  decimalSeparator,
  decimalScale,
  allowNegative,
  allowLeadingZeros,
  suffix,
  normalizeValue,
  externalClasses,
  disabled,
  layout = Layouts.horizontal,
  format,
  additionalInputComponent,
  isAllowed,
  required,
  id,
  isErrorMessageHidden = false,
  hasError,
  ...restProps
}: Props) => {
  const internalClasses = useStyles();
  const classes = R.isNil(externalClasses)
    ? internalClasses
    : R.mergeWith(
        (c1, c2) => classNames(c1, c2),
        internalClasses,
        externalClasses
      );

  const originalValue = value;
  if (!R.isNil(fixedValue)) {
    value = fixedValue;
    disabled = true;
  }

  const isErrorShown = R.isNil(hasError)
    ? touched && !R.isNil(errorText)
    : hasError;
  const roundedValue =
    R.isNil(value) || R.isNil(decimalScale)
      ? value
      : roundNumber(value, decimalScale);

  let sectionClassName;
  let labelClassName;
  if (layout === Layouts.vertical) {
    sectionClassName = classNames(
      classes.section,
      classes.verticalSection,
      sectionClassNameProp
    );
    labelClassName = classNames(
      classes.label,
      classes.verticalLabel,
      labelClassNameProp
    );
  } else if (layout === Layouts.horizontal) {
    sectionClassName = classNames(
      classes.section,
      classes.horizontalSection,
      sectionClassNameProp
    );
    labelClassName = classNames(
      classes.label,
      classes.horizontalLabel,
      labelClassNameProp
    );
  }

  return (
    <div className={sectionClassName}>
      {!R.isNil(label) && !R.isEmpty(label) && (
        <div className={labelClassName}>{label}</div>
      )}
      <div className={classes.control}>
        {additionalInputComponent}
        <NumberFormat
          id={id}
          data-testid={restProps['data-testid']}
          customInput={TextInput}
          value={roundedValue ?? ''}
          onValueChange={values => {
            if (
              (R.isNil(values.floatValue) && R.isNil(roundedValue)) ||
              (values.floatValue === roundedValue && !allowLeadingZeros)
            ) {
              return;
            }

            let floatValue: number | string | undefined;
            if (allowLeadingZeros) {
              floatValue = decimalSeparator
                ? values.value.replace('.', decimalSeparator)
                : values.value;
            } else if (!R.isNil(values.floatValue)) {
              floatValue = values.floatValue;

              if (!R.isNil(normalizeValue)) {
                floatValue = normalizeValue(floatValue);
              }
            }

            onChange(floatValue);
            !R.isNil(afterChange) && afterChange(floatValue);
          }}
          onFocus={onFocus}
          onBlur={
            onBlur
              ? e => {
                  onBlur(originalValue);
                }
              : () => null
          }
          placeholder={disabled ? undefined : placeholder}
          className={className}
          thousandSeparator={thousandSeparator}
          decimalSeparator={decimalSeparator}
          allowedDecimalSeparators={[',', '.']}
          decimalScale={decimalScale}
          allowNegative={allowNegative}
          allowLeadingZeros={allowLeadingZeros}
          suffix={suffix}
          isInvalid={isErrorShown}
          disabled={disabled}
          format={format}
          isAllowed={isAllowed}
        />
        {!isErrorMessageHidden && isErrorShown && R.isNil(hasError) && (
          <ErrorMessage
            className={classes.errorMessage}
          >{`* ${errorText}`}</ErrorMessage>
        )}
      </div>
    </div>
  );
};

export default NumberInput;
