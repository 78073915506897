import classNames from 'classnames';
import * as R from 'ramda';
import * as React from 'react';
import { v4 } from 'uuid';

import { CustomerPatchTypes } from '../constants.js';
import { usePageStyles } from './shared.js';
import { useQueuedUpdateCustomer } from './useUpdateCustomer';
import Icon from 'features/shared/components/icon/index.js';
import Table from 'features/shared/components/table/table.js';
import TableBody from 'features/shared/components/table/tableBody.js';
import TableBodyRow from 'features/shared/components/table/tableBodyRow.js';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import FinalFormSwitch from 'features/sharedModules/finalForm/components/switch.js';
import { useFieldArray } from 'features/sharedModules/finalForm/components/useFieldArray';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  adminCell: {
    textAlign: 'center'
  },
  auth0Cell: {
    textAlign: 'center'
  },
  deleteCell: {
    textAlign: 'center',
    width: '8rem'
  },
  deleteIcon: {
    fontSize: '2rem',
    lineHeight: '2.4rem',
    cursor: 'pointer',
    transform: 'rotate(0.03deg)' // fix of issue: icon small size causes pixelated icon
  }
});

const indexedMap = R.addIndex(R.map);

const ManageUsers = () => {
  const i18n = useI18n();
  const classes = useStyles();
  const pageClasses = usePageStyles();
  const queuedUpdateCustomer = useQueuedUpdateCustomer();

  const { fields } = useFieldArray('users');

  const sortedUsers = React.useMemo(
    () =>
      R.pipe(
        indexedMap((u, i) => [u, i]),
        R.sort(R.ascend(R.path([0, 'email'])))
      )(fields.value || []),
    [fields.value]
  );

  const changeUserField = (id, field, value) => {
    queuedUpdateCustomer({
      id: v4(),
      type: CustomerPatchTypes.changeUserField,
      payload: {
        id,
        field,
        value
      }
    });
  };

  const removeUser = (id, index) => {
    fields.remove(index);

    queuedUpdateCustomer({
      id: v4(),
      type: CustomerPatchTypes.removeUser,
      payload: {
        id
      }
    });
  };

  return (
    <div>
      <div className={classNames(pageClasses.label, pageClasses.verticalLabel)}>
        {i18n('admin.manageCustomer.manageUsers')}
      </div>
      <div>
        <Table
          header={[
            {
              title: i18n('admin.manageCustomer.eMail')
            },
            {
              title: i18n('admin.manageCustomer.admin'),
              className: classes.adminCell
            },
            {
              title: i18n('admin.manageCustomer.auth0'),
              className: classes.auth0Cell
            },
            {
              title: '',
              className: classes.deleteCell
            }
          ]}
        >
          <TableBody
            noDataTitle={i18n('admin.manageCustomer.noUsers')}
            cellsNumber={4}
          >
            {R.map(sortedUser => {
              const [user, index] = sortedUser;

              return (
                <TableBodyRow key={user.id}>
                  {({ bodyRowCellClassName }) => (
                    <>
                      <td className={bodyRowCellClassName}>{user.email}</td>
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.adminCell
                        )}
                      >
                        <FinalFormSwitch
                          name={`users.[${index}].isCustomerAdmin`}
                          afterChange={value => {
                            changeUserField(user.id, 'isCustomerAdmin', value);
                          }}
                        />
                      </td>
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.auth0Cell
                        )}
                      >
                        {user.doesAuth0UserExist && (
                          <Icon
                            className={classes.checkMarkIcon}
                            type="check"
                          />
                        )}
                      </td>
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.deleteCell
                        )}
                      >
                        <Icon
                          className={classes.deleteIcon}
                          onClick={() => {
                            removeUser(user.id, index);
                          }}
                          type="delete"
                        />
                      </td>
                    </>
                  )}
                </TableBodyRow>
              );
            }, sortedUsers)}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default ManageUsers;
