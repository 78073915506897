export const types = {
  DETAIL_CHANGED: 'DETALIZED_NUMBER_INPUT/DETAIL_CHANGED',
  DETAILS_CLOSED: 'DETALIZED_NUMBER_INPUT/DETAILS_CLOSED',
  DETAILS_OPENED: 'DETALIZED_NUMBER_INPUT/DETAILS_OPENED'
};

export const creators = {
  detailChanged: (form, name) => ({
    type: types.DETAIL_CHANGED,
    meta: {
      form,
      name
    }
  }),
  detailsClosed: (form, name) => ({
    type: types.DETAILS_CLOSED,
    meta: {
      form,
      name
    }
  }),
  detailsOpened: (form, name) => ({
    type: types.DETAILS_OPENED,
    meta: {
      form,
      name
    }
  })
};
