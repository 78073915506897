import { StylesProvider } from '@material-ui/core/styles';
import * as R from 'ramda';
import { useReducer, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';

import { getCroppedImg, getResizedImg } from './transformImage';
import Button, { ButtonType } from 'features/shared/components/button';
import Modal from 'features/shared/components/modal/index';
import Slider from 'features/shared/components/slider/index';
import createStatusReducer, {
  getStatus
} from 'features/shared/services/status/reducer.js';
import { getStatusOrDefault } from 'features/shared/services/status/selectors.js';
import {
  startedCreator,
  succeedCreator,
  failedCreator
} from 'features/shared/utils/actions.js';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const CropLength = 200;
const CropSize = { width: CropLength, height: CropLength };

const useStyles = createUseStyles(theme => ({
  actionButton: {
    minWidth: 'auto'
  },
  content: {
    width: '40rem'
  },
  dropzoneContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '20rem'
  },
  dropzone: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2.4rem',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: theme.defaultInputStrokeColor,
    borderStyle: 'dashed',
    backgroundColor: theme.defaultInputFillColor,
    color: theme.primaryColor,
    outline: 'none'
  },
  cropperContainer: {
    position: 'relative',
    height: '30rem'
  },
  slider: {
    marginTop: '2.4rem'
  }
}));

const actionTypes = {
  IMAGE_UPLOADED: 'IMAGE_UPLOADED',
  MEDIA_LOADED: 'MEDIA_LOADED',
  CROP_CHANGED: 'CROP_CHANGED',
  CROP_COMPLETED: 'CROP_COMPLETED',
  ZOOM_CHANGED: 'ZOOM_CHANGED',
  TRANSFORM_IMAGE: 'TRANSFORM_IMAGE'
};

const initialStatusState = {};

const statusReducer = createStatusReducer({
  transformImageStatus: getStatus(actionTypes.TRANSFORM_IMAGE)
});

const initialDataState = {
  imageUrl: null,
  crop: { x: 0, y: 0 },
  minZoom: null,
  maxZoom: null,
  zoom: 1,
  aspect: 1,
  croppedAreaPixels: null
};

const dataReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.IMAGE_UPLOADED: {
      const { imageUrl } = action.payload;

      return R.assoc('imageUrl', imageUrl, initialDataState);
    }
    case actionTypes.MEDIA_LOADED: {
      const { mediaSize } = action.payload;

      return R.pipe(
        R.assoc(
          'minZoom',
          CropLength / Math.min(mediaSize.width, mediaSize.height)
        ),
        R.assoc('maxZoom', 3)
      )(state);
    }
    case actionTypes.CROP_CHANGED: {
      const crop = action.payload;

      return R.assoc('crop', crop, state);
    }
    case actionTypes.CROP_COMPLETED: {
      const croppedAreaPixels = action.payload;

      return R.assoc('croppedAreaPixels', croppedAreaPixels, state);
    }
    case actionTypes.ZOOM_CHANGED: {
      const zoom = action.payload;

      return R.assoc('zoom', zoom, state);
    }
    default:
      return state;
  }
};

const initialState = {
  data: initialDataState,
  status: initialStatusState
};

const reducer = (state, action) => {
  return {
    data: dataReducer(state.data, action),
    status: statusReducer(state.status, action)
  };
};

const UploadImageModal = ({
  pageDispatch,
  pageActionTypes,
  updateUserPictureStatus,
  uploadImage
}) => {
  const i18n = useI18n();
  const classes = useStyles();
  const [{ data, status }, dispatch] = useReducer(reducer, initialState);

  const transformImageStatus = getStatusOrDefault(
    'transformImageStatus',
    status
  );

  const onDrop = useCallback(acceptedFiles => {
    if (R.isNil(acceptedFiles) || R.isEmpty(acceptedFiles)) {
      return;
    }

    const imageUrl = URL.createObjectURL(acceptedFiles[0]);

    dispatch({
      type: actionTypes.IMAGE_UPLOADED,
      payload: {
        imageUrl
      }
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop
  });

  const onMediaLoaded = useCallback(mediaSize => {
    dispatch({
      type: actionTypes.MEDIA_LOADED,
      payload: { mediaSize }
    });
  }, []);

  const onCropChange = useCallback(value => {
    dispatch({
      type: actionTypes.CROP_CHANGED,
      payload: value
    });
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    dispatch({
      type: actionTypes.CROP_COMPLETED,
      payload: croppedAreaPixels
    });
  }, []);

  const onZoomChange = useCallback(value => {
    dispatch({
      type: actionTypes.ZOOM_CHANGED,
      payload: value
    });
  }, []);

  const onCancel = () => {
    pageDispatch({
      type: pageActionTypes.TOGGLE_UPLOAD_IMAGE_MODAL,
      payload: { isOpen: false }
    });
  };

  const onSave = async () => {
    dispatch(startedCreator(actionTypes.TRANSFORM_IMAGE));

    try {
      const croppedImage = await getCroppedImg(
        data.imageUrl,
        data.croppedAreaPixels,
        0
      );

      if (R.isNil(croppedImage)) {
        throw new Error('Failed to crop image');
      }

      const resizedImage = await getResizedImg(
        URL.createObjectURL(croppedImage),
        100,
        100
      );

      dispatch(succeedCreator(actionTypes.TRANSFORM_IMAGE));

      uploadImage({
        fullSize: croppedImage,
        thumbnail: resizedImage
      });
    } catch (e) {
      dispatch(failedCreator(actionTypes.TRANSFORM_IMAGE));

      console.error(e);
    }
  };

  return (
    <Modal
      footer={
        <>
          <Button
            className={classes.actionButton}
            buttonType={ButtonType.secondary}
            onClick={onCancel}
          >
            {i18n('shared.cancel')}
          </Button>

          <Button
            disabled={
              R.isNil(data.imageUrl) ||
              transformImageStatus.isPending ||
              updateUserPictureStatus.isPending
            }
            className={classes.actionButton}
            onClick={onSave}
          >
            {i18n('shared.save')}
          </Button>
        </>
      }
      header={i18n('profile.uploadImage')}
      isOpen={true}
      onRequestClose={() => {
        pageDispatch({
          type: pageActionTypes.TOGGLE_UPLOAD_IMAGE_MODAL,
          payload: { isOpen: false }
        });
      }}
    >
      <div className={classes.content}>
        {R.isNil(data.imageUrl) ? (
          <div className={classes.dropzoneContainer}>
            <div {...getRootProps({ className: classes.dropzone })}>
              <input {...getInputProps()} />
              <div>{i18n('profile.dragAndDropFileHere')}</div>
            </div>
          </div>
        ) : (
          <div>
            <div className={classes.cropperContainer}>
              <Cropper
                image={data.imageUrl}
                crop={data.crop}
                zoom={data.zoom}
                minZoom={data.minZoom}
                maxZoom={data.maxZoom}
                zoomSpeed={0.5}
                aspect={data.aspect}
                cropShape="round"
                cropSize={CropSize}
                showGrid={false}
                restrictPosition={true}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
                onMediaLoaded={onMediaLoaded}
              />
            </div>
            <div className={classes.slider}>
              <StylesProvider injectFirst>
                <Slider
                  disabled={R.isNil(data.minZoom) || R.isNil(data.maxZoom)}
                  value={data.zoom}
                  config={{
                    min: data.minZoom,
                    max: data.maxZoom,
                    step: 0.0001
                  }}
                  callbackFn={value => {
                    onZoomChange(value);
                  }}
                />
              </StylesProvider>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UploadImageModal;
