import axios from 'axios';
import { pick } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import { updateAdminTranslations } from '../api';
import { form } from '../services/translationsForm';
import { useTranslationsStore } from '../services/translationsStore';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export const useSaveAdminTranslations = () => {
  const dispatch = useDispatch();
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);

  const saveAdminTranslations = async language => {
    const translationsStore = useTranslationsStore.getState();
    const { values, initialValues } = form.getState();

    try {
      translationsStore.setIsSaveTranslationsPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        undefined
      );

      const changedFieldsNames = Object.keys(values).filter(
        key =>
          values[key].default !== initialValues[key].default ||
          values[key].description !== initialValues[key].description
      );
      const changedValues = pick(changedFieldsNames, values);
      const payload = createAdminTranslationsPayload(changedValues, language);

      await updateAdminTranslations(accessToken, payload);

      translationsStore.updateAdminTranslations(payload);

      translationsStore.setIsSaveTranslationsPending(false);

      form.initialize(values);

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n(
            'admin.appAdmin.advisorSolution.text.saveTranslationsSuccessMessage'
          ),
          type: NotificationTypes.success
        })
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        translationsStore.setIsSaveTranslationsPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'admin.appAdmin.advisorSolution.text.saveTranslationsErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return saveAdminTranslations;
};

const createAdminTranslationsPayload = (values, language) => {
  const regex = /-/g;
  return Object.entries<{ default: string; description: string }>(values).map(
    ([key, { default: defaultValue, description }]) => ({
      application: 'deepalpha',
      language,
      description,
      default: defaultValue,
      name: key.replace(regex, '.')
    })
  );
};
