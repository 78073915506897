import { PageId } from '../../main/services/selectors';
import { usePrevNextButtonsOrderExecutionTabs } from '../services/selectors';
import Button, { ButtonType } from 'features/shared/components/button';
import { Spacing } from 'features/shared/constants/spacing';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { useErrorsStore } from 'features/sharedModules/errors/services/errorsStore';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: `${Spacing.spacing01}px`
  },
  actionButton: {
    '& + &': {
      marginLeft: Spacing.spacing01
    }
  }
});

type Props = {
  pageId: PageId;
  onNavButtonClick: (pageId: PageId) => void;
};

const OrderExecutionActionButtons = ({ pageId, onNavButtonClick }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const {
    roboAdviceForm: { errorMessagesEnabled }
  } = useCustomerConfig();

  const { prevPage, nextPage } = usePrevNextButtonsOrderExecutionTabs(pageId);
  const errorsStore = useErrorsStore();

  const nexButtonClickHandler = () => {
    if (nextPage) {
      if (nextPage.tab?.disabled) {
        errorsStore.showNextButtonMessage();
      } else {
        onNavButtonClick(nextPage.pageId);
      }
    }
  };

  return (
    <div className={classes.actionButtons}>
      {prevPage && (
        <Button
          className={classes.actionButton}
          buttonType={ButtonType.secondary}
          onClick={() => {
            onNavButtonClick(prevPage.pageId);
          }}
        >
          {i18n('roboAdvice.navigation.back')}
        </Button>
      )}

      {nextPage && (
        <Button
          className={classes.actionButton}
          disabled={nextPage.tab?.disabled && !errorMessagesEnabled}
          onClick={nexButtonClickHandler}
        >
          {i18n('roboAdvice.navigation.next')}
        </Button>
      )}
    </div>
  );
};

export default OrderExecutionActionButtons;
