import axios from 'axios';
import * as R from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { readAnalyzeFrontier } from '../../../shared/api';
import { usePageStore as useProposalPageStore } from '../../services/pageStore';
import { getIsFormComplete } from '../../services/selectors.js';
import { useEfficientFrontierStore } from 'features/roboAdvice/adviceSession/efficientFrontierChart';
import { usePortfolioChartStore } from 'features/roboAdvice/adviceSession/portfolioChart';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { useGoalsStore } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useReadEfficientFrontierChartData() {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();
  const portfolioChartStore = usePortfolioChartStore();

  const cancelTokenSourceRef = React.useRef<any>();
  const readEfficientFrontierChartData = async () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const proposalPageStore = useProposalPageStore.getState();
    const efficientFrontierChartStore = useEfficientFrontierStore.getState();
    const { goals } = useGoalsStore.getState();

    const isFormComplete = getIsFormComplete(false);
    if (!isFormComplete) {
      efficientFrontierChartStore.reset();
      proposalPageStore.setPageStatus(
        'readEfficientFrontierChartData',
        PageStatuses.failed
      );

      return;
    }

    try {
      proposalPageStore.setPageStatus(
        'readEfficientFrontierChartData',
        PageStatuses.pending
      );

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      for (const goal of goals) {
        const goalChartData = portfolioChartStore.getChartData(
          goal.goalId,
          goal.data.isPortfolioCustom
        );

        const analyzeRiskReturn = {
          risk: goalChartData.expectedVolatility,
          return: goalChartData.expectedAnnualReturn,
          namespace_id: goal.data.productPlatformNamespace
        };

        const readAnalyzeFrontierResponse = await readAnalyzeFrontier(
          qAuthAccessToken,
          cancelTokenSource.token,
          analyzeRiskReturn
        );

        const efficientFrontierChartData =
          mapServerDataToEfficientFrontierChartData(
            R.path(['data', 'frontier'], readAnalyzeFrontierResponse)
          );

        const portfolioRiskReturnChartData =
          mapServerDataToPortfolioRiskReturnChartData(
            R.path(['data', 'customer'], readAnalyzeFrontierResponse)
          );

        efficientFrontierChartStore.setEfficientFrontierChart({
          efficientFrontierChartData,
          portfolioRiskReturnChartData,
          goalName: goal.name,
          goalId: goal.goalId
        });
      }

      proposalPageStore.setPageStatus(
        'readEfficientFrontierChartData',
        PageStatuses.succeed
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        efficientFrontierChartStore.reset();
        proposalPageStore.setPageStatus(
          'readEfficientFrontierChartData',
          PageStatuses.failed
        );

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.proposal.readDataErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readEfficientFrontierChartData;
}

const mapServerDataToEfficientFrontierChartData = frontier =>
  R.map(i => [i.risk, i.return], frontier);

const mapServerDataToPortfolioRiskReturnChartData = customer => {
  const modelPortfolioNearestCustomer = R.prop(
    'model_portfolio_nearest_customer',
    customer
  );

  if (R.isNil(modelPortfolioNearestCustomer)) {
    return null;
  }

  return [
    [modelPortfolioNearestCustomer.risk, modelPortfolioNearestCustomer.return]
  ];
};
