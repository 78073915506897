import { HistoricalReturnBarChart } from '@quantfoliorepo/ui-components';

import { PortfolioIds } from '../services/constants';
import { useHistoricalReturnChartStore } from './useReadHistoricalReturnData';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  historicalReturnBarChartContainer: {
    marginTop: 120
  }
});

const BarChart = () => {
  const classes = useStyles();
  const chartStoreData = useHistoricalReturnChartStore();

  const chartData =
    chartStoreData.chartData['10Y']
      ?.filter(
        ({ goalId }) =>
          goalId === PortfolioIds.modelPortfolio ||
          goalId === PortfolioIds.customPortfolio ||
          goalId === PortfolioIds.benchmarkPortfolio
      )
      .map(({ goalName, statistics }) => {
        return {
          name: goalName,
          data: [
            statistics?.one_year,
            statistics?.three_year,
            statistics?.five_year,
            statistics?.ten_year
          ] as [number | null, number | null, number | null, number | null]
        };
      }) || [];

  const hasChartDataAnyValues = chartData?.some(item =>
    item.data.some(dataItem => !!dataItem)
  );

  return hasChartDataAnyValues ? (
    <div className={classes.historicalReturnBarChartContainer}>
      <HistoricalReturnBarChart chartData={chartData} height={390} />
    </div>
  ) : null;
};

export default BarChart;
