import { isNil } from 'ramda';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Spacing } from '../../../shared/constants/spacing';
import { useI18n } from '../../customerConfig/components/useI18n';
import { useErrorsStore } from '../services/errorsStore';
import { useGetFirstTabWithError } from '../services/useGetFirstTabWithError';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import { isPageOrderExecution } from 'features/roboAdvice/adviceSession/orderExecution/services/selectors';
import {
  AdviceSessionParams,
  OrderExecutionParams
} from 'features/shared/constants/session';
import { FontWeights, Typography } from 'features/shared/constants/typography';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  errorContainer: {
    position: 'absolute',
    display: 'flex',
    padding: `${Spacing.spacing01}px 0 0`
  },
  errorMessage: {
    width: 'max-content',
    padding: `${Spacing.spacing00}px ${Spacing.spacing01}px ${Spacing.spacing00}px ${Spacing.spacing00}px`,
    borderRadius: '10px',
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight,
    fontWeight: FontWeights.normal,
    color: theme.primaryColor,
    background: theme.itemBackgroundColor_1,
    border: `1px solid ${theme.defaultInputStrokeColor}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  },
  infoIcon: {
    fill: theme.errorNegativeColor,
    width: '28px',
    height: '28px',
    marginRight: '12px',
    verticalAlign: 'middle'
  },
  showMeAction: {
    color: theme.accentColor,
    cursor: 'pointer',
    marginLeft: `${Spacing.spacing02}px`
  }
}));

const TabErrorMessage = ({ allErrors, closeErrorMessage }) => {
  const classes = useStyles();
  const i18n = useI18n();
  const { clientType, clientId, adviceSessionId, pageId } = useParams<
    AdviceSessionParams | OrderExecutionParams
  >();
  const firstTabWithError = useGetFirstTabWithError(allErrors, pageId);
  const history = useHistory();
  const errorsStore = useErrorsStore();

  const onShowMeClicked = () => {
    if (closeErrorMessage) {
      closeErrorMessage();
    }
    errorsStore.enableErrorMode();
    if (firstTabWithError) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      if (isPageOrderExecution(pageId)) {
        history.push(
          routeTemplates.roboAdviceOrderExecution.build(
            clientType,
            clientId,
            adviceSessionId,
            firstTabWithError
          )
        );
      } else {
        history.push(
          routeTemplates.roboAdviceAdviceSession.build(
            clientType,
            clientId,
            adviceSessionId,
            firstTabWithError
          )
        );
      }
    }
  };

  const numberOfErrors =
    isNil(allErrors?.numberOfFirstPartErrors) ||
    allErrors?.numberOfFirstPartErrors === 0
      ? allErrors?.numberOfAllErrors
      : allErrors?.numberOfFirstPartErrors;

  if (!numberOfErrors) {
    return null;
  }

  return (
    <div className={classes.errorContainer}>
      <div className={classes.errorMessage}>
        <div>
          <WarningIcon className={classes.infoIcon} />
          {i18n('roboAdvice.errors.nextButtonError').replace(
            '{0}',
            numberOfErrors
          )}
        </div>
        {!errorsStore.isErrorModeEnabled && (
          <div className={classes.showMeAction} onClick={onShowMeClicked}>
            {i18n('roboAdvice.errors.showMe')}
          </div>
        )}
      </div>
    </div>
  );
};

export default TabErrorMessage;
