export const types = {
  FIELD_CHANGED: 'RISK_SCORE/FIELD_CHANGED',
  READ_RISK_SCORE_CONFIG: 'RISK_SCORE/READ_RISK_SCORE_CONFIG',
  READ_RISK_SCORE: 'RISK_SCORE/READ_RISK_SCORE'
};

export const creators = {
  fieldChanged: (field, updateGoal) => ({
    type: types.FIELD_CHANGED,
    meta: {
      field,
      updateGoal
    }
  }),
  readRiskScoreConfig: () => ({
    type: types.READ_RISK_SCORE_CONFIG
  }),
  readRiskScore: () => ({
    type: types.READ_RISK_SCORE
  })
};
