import { z } from 'zod';

export const systemConfigSchema = z.object({
  message: z.object({
    enabled: z.boolean(),
    backgroundColor: z.string().optional(),
    textColor: z.string().optional()
  })
});

export type SystemConfig = z.infer<typeof systemConfigSchema>;

export const defaultSystemConfig: SystemConfig = {
  message: {
    enabled: false,
    backgroundColor: '#B22222',
    textColor: '#FFFFFF'
  }
};
