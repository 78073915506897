import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Icon from 'features/shared/components/icon/index.js';
import Popper from 'features/shared/components/popper/index.js';
import Menu from 'features/shared/components/popper/menu.js';
import { LayoutBreakpoints } from 'features/shared/constants/layoutBreakpoints';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import { creators as sessionActionCreators } from 'features/shared/services/session/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  profile: {
    flex: '0 0 auto',
    marginLeft: Spacing.spacing02,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  profileName: {
    color: theme.secondaryColor,
    fontSize: Typography.body2.size,
    lineHeight: '2rem'
  },
  profileImage: {
    marginLeft: '1.6rem',
    width: '4.8rem',
    height: '4.8rem',
    borderRadius: '50%'
  },
  profileMenuIcon: {
    marginLeft: '0.8rem',
    color: theme.secondaryColor,
    fontSize: '2rem',
    lineHeight: '2rem'
  },
  menuContainer: {
    zIndex: 2
  },
  [`@media (max-width: ${LayoutBreakpoints.desktopMin}px)`]: {
    profileName: {
      display: 'none'
    }
  }
}));

const ProfileDropdown = () => {
  const i18n = useI18n();
  const classes = useStyles();
  const currentUser = useSelector(sessionSelectors.getCurrentUser);
  const pictureUrl = useSelector(sessionSelectors.getCurrentUserPicture);
  const dispatch = useDispatch();
  const logout = () => dispatch(sessionActionCreators.logout());

  return (
    <Popper
      popperProps={{
        placement: 'bottom-end',
        modifiers: {
          offset: {
            offset: '0, 10px'
          }
        }
      }}
      referenceDataId="profile-menu-toggle"
      popperDataId="profile-menu"
      reference={({ ref, isPopperOpen, togglePopper, referenceDataId }) => (
        <a
          className={classes.profile}
          onClick={e => {
            e.preventDefault();
            togglePopper(!isPopperOpen);
          }}
          ref={ref}
          data-id={referenceDataId}
        >
          <span className={classes.profileName}>{currentUser.name}</span>
          <img
            className={classes.profileImage}
            src={pictureUrl}
            alt="profile"
          />
          <Icon className={classes.profileMenuIcon} type="arrow_drop_down" />
        </a>
      )}
      popper={({
        ref,
        style,
        placement,
        isPopperOpen,
        togglePopper,
        popperDataId
      }) =>
        isPopperOpen && (
          <div
            ref={ref}
            style={style}
            data-placement={placement}
            data-id={popperDataId}
            className={classes.menuContainer}
          >
            <Menu
              showSeparator={true}
              items={[
                {
                  title: i18n('profile.profileAndPreferences'),
                  to: routeTemplates.userProfileBasic.build(currentUser.id),
                  onClick: e => {
                    togglePopper(false);
                  }
                },
                {
                  title: i18n('profile.logout'),
                  onClick: e => {
                    e.preventDefault();
                    logout();
                    togglePopper(false);
                  }
                }
              ]}
            />
          </div>
        )
      }
    />
  );
};

export default ProfileDropdown;
