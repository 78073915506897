import * as R from 'ramda';
import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { CustomerManagementUsersTableOrderKeys } from '../reducers/customerManagementUsersTable.js';
import formSelectors from 'features/shared/services/form/selectors.js';
import routerSelectors from 'features/shared/services/router/selectors.js';
import tableSelectors from 'features/shared/services/table/selector.js';
import {
  orderDirectionFuncs,
  getPage
} from 'features/shared/services/table/selector.js';
import routeTemplates from 'features/shared/utils/routeTemplates';

const indexedMap = R.addIndex(R.map);

export const getIsPageReload = state => {
  const route = routerSelectors.getRoute(state);
  const prevRoute = routerSelectors.getPrevRoute(state);

  const params = route.match.params;
  const prevParams = prevRoute.match.params;

  return (
    route.route.id === routeTemplates.customerManagement.id &&
    prevRoute.route.id === routeTemplates.customerManagement.id &&
    params.id !== prevParams.id
  );
};

export const getIsPageOpened = state => {
  const route = routerSelectors.getRoute(state);
  const prevRoute = routerSelectors.getPrevRoute(state);

  return (
    (route.route.id === routeTemplates.customerManagement.id &&
      prevRoute.route.id !== routeTemplates.customerManagement.id) ||
    getIsPageReload(state)
  );
};

export const getIsPageClosed = state => {
  const route = routerSelectors.getRoute(state);
  const prevRoute = routerSelectors.getPrevRoute(state);

  return (
    (route.route.id !== routeTemplates.customerManagement.id &&
      prevRoute.route.id === routeTemplates.customerManagement.id) ||
    getIsPageReload(state)
  );
};

const orderKeyFuncs = {
  [CustomerManagementUsersTableOrderKeys.email]: R.prop('email')
};

export const getUsers = createSelector(
  state => {
    const formValues = getFormValues(
      'editCustomerForm_state',
      formSelectors.getForms
    )(state);

    return R.prop('users', formValues);
  },
  state => tableSelectors.getTable('customerManagementUsersTable_state', state),
  (formUsers, table) => {
    const orderDirectionFunc = orderDirectionFuncs[table.orderDirection];
    const orderKeyFunc = orderKeyFuncs[table.orderKey];

    return R.pipe(
      indexedMap((formUser, index) => {
        return {
          ...formUser,
          index
        };
      }),
      R.sortWith([orderDirectionFunc(orderKeyFunc)]),
      getPage(table)
    )(formUsers || []);
  }
);
