import * as R from 'ramda';
import React from 'react';

import Card from './card.js';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const indexedMap = R.addIndex(R.map);

const useStyles = createUseStyles(theme => ({
  cardsContainer: {
    margin: `-${Spacing.spacing01}px`
  },
  cardsLine: {
    display: 'flex'
  },
  card: {
    margin: Spacing.spacing01
  },
  legendContainer: {
    margin: `${Spacing.spacing01}px 0 0 265px`,
    display: 'flex'
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '28px'
  },
  legendColor: {
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    marginRight: '6px'
  },
  legendText: {
    color: theme.secondaryColor,
    fontSize: Typography.body3.size,
    lineHeight: Typography.body3.lineHeight
  }
}));

export const CardsList = ({
  cards,
  selectedCardIds,
  onCardSelected,
  cardLayout,
  maxLineLength: maxLineLengthProp,
  legend,
  imageContainerClassName,
  disabled,
  ...restProps
}) => {
  const classes = useStyles();

  if (R.isNil(selectedCardIds)) {
    selectedCardIds = [];
  }

  if (R.isNil(onCardSelected)) {
    onCardSelected = () => {};
  }

  const cardsLength = R.length(cards || []);
  const maxLineLength = R.isNil(maxLineLengthProp)
    ? cardsLength
    : maxLineLengthProp;
  const linesNumber = Math.ceil(cardsLength / maxLineLength);

  return (
    <div data-testid={restProps['data-testid']}>
      {!R.isNil(cards) && (
        <div
          className={classes.cardsContainer}
          data-testid={'cards-list-cards'}
        >
          {R.map(
            i => (
              <div key={i} className={classes.cardsLine}>
                {R.map(
                  c => (
                    <Card
                      key={c.id}
                      className={classes.card}
                      id={c.id}
                      isSelected={R.includes(c.id, selectedCardIds)}
                      onSelect={() => {
                        onCardSelected(c.id);
                      }}
                      imageSrc={c.imageSrc}
                      imageComponent={c.imageComponent}
                      imageContainerClassName={imageContainerClassName}
                      title={c.title}
                      layout={cardLayout}
                      description={c.description}
                      disabled={disabled || c.disabled}
                    />
                  ),
                  R.pipe(
                    R.drop(i * maxLineLength),
                    R.take(maxLineLength)
                  )(cards)
                )}
              </div>
            ),
            R.range(0, linesNumber)
          )}
        </div>
      )}
      {!R.isNil(legend) && (
        <div
          className={classes.legendContainer}
          data-testid={'cards-list-legend'}
        >
          {indexedMap(
            (i, index) => (
              <div
                className={classes.legendItem}
                key={index}
                data-testid={`cards-list-legend-item-${index}`}
              >
                <div
                  data-testid={'legend-item-color'}
                  className={classes.legendColor}
                  style={{ backgroundColor: i.color }}
                />
                <div className={classes.legendText}>{i.text}</div>
              </div>
            ),
            legend
          )}
        </div>
      )}
    </div>
  );
};

export default CardsList;
