import React, { Fragment, useRef } from 'react';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { form } from '../../form/services/form';
import AdviceSessionBreadcrumbs from '../../main/components/adviceSessionBreadcrumbs';
import { useInitPage } from '../../main/components/useInitPage';
import { usePatchAdviceSessionListener } from '../../main/components/usePatchAdviceSessionListener';
import OrderExecutionPages from './pages';
import Tabs from './tabs.js';
import useInitOrderExecution from './useInitOrderExecution';
import { AdviceSessionParams } from 'features/shared/constants/session';
import { Spacing } from 'features/shared/constants/spacing';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  header: {
    padding: `${Spacing.spacing02}px 0`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  tabs: {
    paddingBottom: Spacing.spacing04
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column'
  }
});

const Main = () => {
  const classes = useStyles();
  const { clientType, clientId, adviceSessionId } =
    useParams<AdviceSessionParams>();
  const history = useHistory();
  const ref = useRef<HTMLDivElement>(null);

  useInitPage();

  useInitOrderExecution();

  usePatchAdviceSessionListener();

  const onNavButtonClick = nextPage => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
    history.push(
      routeTemplates.roboAdviceOrderExecution.build(
        clientType,
        clientId,
        adviceSessionId,
        nextPage
      )
    );
  };

  return (
    <div data-testid={'order-execution-page'} ref={ref}>
      <Form form={form} onSubmit={() => {}}>
        {() => (
          <Fragment>
            <div className={classes.header}>
              <AdviceSessionBreadcrumbs />
            </div>
            <div className={classes.tabs}>
              <Tabs />
            </div>
            <div className={classes.pageContainer}>
              <OrderExecutionPages onNavButtonClick={onNavButtonClick} />
            </div>
          </Fragment>
        )}
      </Form>
    </div>
  );
};

export default Main;
