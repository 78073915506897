import { useParams } from 'react-router';
import { useWindowSize } from 'usehooks-ts';
import { v4 } from 'uuid';

import { useOrderPageStore } from '../../services/orderPageStore';
import GenerateOrderForm from './generateOrderForm';
import useDownloadPdfOrder from './useDownloadPdfOrder';
import { useQueuedPatchAdviceSession } from 'features/roboAdvice/adviceSession/main/components/useQueuedPatchAdviceSession';
import useBuildAdvisorInfoPayload from 'features/roboAdvice/adviceSession/shared/components/useBuildAdvisorInfoPayload';
import {
  SessionNamespaces,
  SessionPatchTypes
} from 'features/roboAdvice/adviceSession/shared/constants';
import Button from 'features/shared/components/button';
import Modal from 'features/shared/components/modal/index';
import { useModalBodyMaxDimensions } from 'features/shared/components/modal/useModalBodyMaxDimensions';
import Scrollbars from 'features/shared/components/scrollbars';
import { LayoutBreakpoints } from 'features/shared/constants/layoutBreakpoints';
import { AdviceSessionParams } from 'features/shared/constants/session';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

const GenerateOrderModal = () => {
  const i18n = useI18n();
  const downloadPdfOrder = useDownloadPdfOrder();
  const {
    isGenerateReportPopupOpen,
    isReadPdfReportPending,
    setIsGenerateReportPopupOpen
  } = useOrderPageStore();
  const { width } = useWindowSize();
  const { modalBodyMaxHeight, headerRef, footerRef } =
    useModalBodyMaxDimensions();
  const queuedPatchAdviceSession = useQueuedPatchAdviceSession();
  const { adviceSessionId } = useParams<AdviceSessionParams>();
  const buildAdvisorInfoPayload = useBuildAdvisorInfoPayload();

  const onSubmit = async () => {
    await queuedPatchAdviceSession({
      id: v4(),
      type: SessionPatchTypes.saveFinishedSessionData,
      adviceSessionId,
      payload: buildAdvisorInfoPayload(),
      namespace: SessionNamespaces.finishedSessionAdvisorInfo
    });

    downloadPdfOrder();
    setIsGenerateReportPopupOpen(false);
  };

  return (
    <Modal
      footer={
        <Button disabled={isReadPdfReportPending} onClick={onSubmit}>
          {i18n('roboAdvice.orderExecution.pdfGeneration.modalGenerateReport')}
        </Button>
      }
      header={i18n('roboAdvice.orderExecution.pdfGeneration.modalHeader')}
      isOpen={isGenerateReportPopupOpen}
      onRequestClose={() => setIsGenerateReportPopupOpen(false)}
      disabled={isReadPdfReportPending}
      headerRef={headerRef}
      footerRef={footerRef}
    >
      {width < LayoutBreakpoints.tabletMin ? (
        <Scrollbars autoHeightMax={modalBodyMaxHeight}>
          <GenerateOrderForm />
        </Scrollbars>
      ) : (
        <GenerateOrderForm modalBodyMaxHeight={modalBodyMaxHeight} />
      )}
    </Modal>
  );
};

export default GenerateOrderModal;
