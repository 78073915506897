import {
  HistoricalReturnChartData,
  HistoricalReturnTimeRangeChartData
} from '@quantfoliorepo/ui-components';
import axios from 'axios';
import { format } from 'date-fns';
import * as R from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ProposalSectionStatuses,
  useHolisticViewStore
} from '../../../services/holisticViewStore';
import { useHistoricalReturnChartStore } from 'features/roboAdvice/adviceSession/historicalReturnChart';
import { usePortfolioChartStore } from 'features/roboAdvice/adviceSession/portfolioChart';
import { getLookbackStartDate } from 'features/roboAdvice/adviceSession/proposal/services/selectors.js';
import { createHistoricalReturnV2 } from 'features/roboAdvice/adviceSession/shared/api';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { getCurrentDate } from 'features/roboAdvice/shared/utils';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useReadHistoricalReturnChartData(
  statusKey: keyof ProposalSectionStatuses,
  timeRangeChartDataKey: keyof HistoricalReturnChartData
) {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();
  const portfolioChartStore = usePortfolioChartStore();

  const cancelTokenSourceRef = React.useRef<any>();
  const readHistoricalReturnChartData = async () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const historicalReturnChartStore = useHistoricalReturnChartStore.getState();
    const goals = useHolisticViewStore
      .getState()
      .availableClients.filter(client => client.isSelected)
      .map(client => client.goals || [])
      .flat();

    try {
      useHolisticViewStore
        .getState()
        .setProposalSectionStatus(statusKey, PageStatuses.pending);

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const currentDate = getCurrentDate();
      let historicalReturn: HistoricalReturnTimeRangeChartData[] = [];

      for (const goal of goals) {
        const portfolio = portfolioChartStore
          .getChartData(goal.goalId, goal.data.isPortfolioCustom)
          .categorySelectionPortfolio.map(({ id, weight }) => ({ id, weight }));

        const historicalReturnResponse = await createHistoricalReturnV2(
          qAuthAccessToken,
          cancelTokenSource.token,
          {
            portfolio: portfolio,
            savingsplan: {
              starting_capital: 100
            },
            lookback: {
              start_date: format(
                getLookbackStartDate(timeRangeChartDataKey, currentDate),
                'yyyy-MM-dd'
              ),
              end_date: format(currentDate, 'yyyy-MM-dd')
            }
          }
        );
        historicalReturn = [
          ...historicalReturn,
          {
            ...historicalReturnResponse.data,
            goalId: goal.goalId,
            goalName: goal.name
          }
        ];
      }

      historicalReturnChartStore.setTimeRangeChartData(
        timeRangeChartDataKey,
        historicalReturn
      );
      useHolisticViewStore
        .getState()
        .setProposalSectionStatus(statusKey, PageStatuses.succeed);
    } catch (error) {
      if (!axios.isCancel(error)) {
        useHolisticViewStore
          .getState()
          .setProposalSectionStatus(statusKey, PageStatuses.failed);
        historicalReturnChartStore.resetTimeRangeChartData(
          timeRangeChartDataKey
        );

        if (
          !(
            R.path(['response', 'status'], error) === 400 &&
            R.path(['response', 'data', 'qf-api-error-code'], error) === 416
          )
        ) {
          dispatch(
            notificationActionCreators.showNotification({
              message: i18n('roboAdvice.proposal.readDataErrorMessage'),
              type: NotificationTypes.error
            })
          );

          throwSafeError(error);
        }
      }
    }
  };

  return readHistoricalReturnChartData;
}
