import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';

import { Typography, FontFamilies } from 'features/shared/constants/typography';
import { formatNumber } from 'features/shared/utils/number';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  root: {},
  chart: {
    height: '300px'
  },
  tooltipRoot: {
    fontFamily: FontFamilies.roboto
  },
  tooltipItem: {
    fontSize: Typography.body3.size,
    lineHeight: Typography.body3.lineHeight,
    marginTop: '4px'
  },
  legend: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    padding: 0
  },
  goal: {
    display: 'inline',
    color: theme.secondaryTextColor,
    fontSize: Typography.body3.size,
    lineHeight: '1.4rem',
    margin: '0 2rem'
  },
  dot: {
    display: 'inline-block',
    height: '16px',
    width: '16px',
    borderRadius: '50%',
    marginRight: '8px',
    verticalAlign: 'text-top'
  }
}));

const Chart = ({ chartData, cultureCode }) => {
  const i18n = useI18n();
  const classes = useStyles();
  const chartComponent = React.useRef();
  const theme = useTheme();

  React.useEffect(() => {
    const reflow = () => {
      chartComponent.current.chart.reflow();
    };

    window.addEventListener('resize', reflow);

    reflow();

    return () => {
      window.removeEventListener('resize', reflow);
    };
  }, []);

  const chartOptions = {
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    chart: {
      backgroundColor: 'transparent',
      marginTop: 20,
      marginRight: 5,
      animation: false
    },
    title: {
      text: null
    },
    xAxis: {
      title: {
        text: i18n('roboAdvice.proposal.efficientFrontier.risk'),
        style: {
          color: theme.primaryColor,
          fontSize: Typography.body3.size,
          lineHeight: Typography.body3.lineHeight,
          fontFamily: FontFamilies.roboto
        }
      },
      lineWidth: 1,
      lineColor: theme.primaryColor,
      tickWidth: 0,
      labels: {
        style: {
          color: theme.secondaryColor,
          fontSize: Typography.body3.size,
          lineHeight: Typography.body3.lineHeight,
          fontFamily: FontFamilies.roboto
        },
        formatter: function () {
          if (this.isFirst) {
            return i18n('roboAdvice.proposal.efficientFrontier.low');
          }

          if (this.isLast) {
            return i18n('roboAdvice.proposal.efficientFrontier.high');
          }

          return null;
        }
      }
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: i18n('roboAdvice.proposal.efficientFrontier.expectedReturn'),
        style: {
          color: theme.primaryColor,
          fontSize: Typography.body3.size,
          lineHeight: Typography.body3.lineHeight,
          fontFamily: FontFamilies.roboto
        }
      },
      lineWidth: 1,
      lineColor: theme.primaryColor,
      labels: {
        style: {
          color: theme.secondaryColor,
          fontSize: Typography.body3.size,
          lineHeight: Typography.body3.lineHeight,
          fontFamily: FontFamilies.roboto
        },
        rotation: -90,
        formatter: function () {
          if (this.isFirst) {
            return i18n('roboAdvice.proposal.efficientFrontier.low');
          }

          if (this.isLast) {
            return i18n('roboAdvice.proposal.efficientFrontier.high');
          }

          return null;
        }
      }
    },
    plotOptions: {
      series: {
        animation: false,
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        if (this.series.options.id.includes('portfolioRiskReturn')) {
          return `
          <div class="${classes.tooltipRoot}">
          <div class="${classes.tooltipItem}">
          <span>${i18n('roboAdvice.proposal.efficientFrontier.risk')}</span>
          <span>${formatNumber(cultureCode, this.x, 0, 2)}%</span>
          </div>
          <div class="${classes.tooltipItem}">
          <span>${i18n(
            'roboAdvice.proposal.efficientFrontier.expectedReturn'
          )}</span>
          <span>${formatNumber(cultureCode, this.y, 0, 2)}%</span>
          </div>
          </div>
        `;
        }
      }
    },
    series: [
      {
        id: 'efficientFrontier',
        type: 'spline',
        data: chartData.efficientFrontierChartData,
        color: theme.primaryColor,
        states: {
          hover: {
            lineWidthPlus: 0
          },
          select: {
            lineWidthPlus: 0
          }
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
              radiusPlus: 0
            }
          }
        },
        enableMouseTracking: false,
        tooltip: {
          enabled: false
        }
      },
      ...chartData.portfolioRiskReturnChartData.map(
        ({ data, color }, index) => ({
          id: `portfolioRiskReturn-${index}`,
          type: 'scatter',
          data,
          color,
          marker: {
            radius: 10,
            symbol: 'circle',
            states: {
              hover: {
                radiusPlus: 0
              },
              select: {
                radiusPlus: 0
              }
            }
          }
        })
      )
    ]
  };

  return (
    <div className={classes.root}>
      <div className={classes.chart}>
        <HighchartsReact
          ref={chartComponent}
          highcharts={Highcharts}
          containerProps={{ style: { width: 'auto', height: '300px' } }}
          options={chartOptions}
        />
      </div>

      <ul className={classes.legend}>
        {chartData.goalNames.map(({ name, color }) => (
          <li key={name} className={classes.goal}>
            <span className={classes.dot} style={{ background: color }} />
            <span>{name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Chart;
