import chroma from 'chroma-js';

import { Spacing } from 'features/shared/constants/spacing';
import {
  Typography,
  FontWeights,
  FontFamilies
} from 'features/shared/constants/typography';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const borderRadius = {
  [BorderRadiusTypes.rounded]: '80px',
  [BorderRadiusTypes.sharp]: '5px'
};

export const useStyles = createUseStyles(theme => ({
  button: {
    border: 'none',
    borderRadius: borderRadius[theme.borderRadiusType],

    fontFamily: FontFamilies.roboto,
    fontWeight: FontWeights.normal,
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    cursor: 'pointer',
    whiteSpace: 'nowrap',

    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'baseline',
    gap: 16,

    height: 40,
    padding: `0 ${Spacing.spacing02}px`,
    transition: 'background .3s, color .3s, border-color .3s'
  },
  primary: {
    backgroundColor: theme.accentColor,
    color: theme.defaultButtonTextColor,

    '&:hover': {
      backgroundColor: theme.accentHoverColor
    },
    '&:active': {
      backgroundColor: theme.inputFillFocusColor
    },
    '&:focus-visible': {
      border: `2px solid ${theme.primaryColor}`,
      outline: 'none'
    },
    '&:disabled': {
      backgroundColor: theme.disabledBackgroundColor,
      color: chroma(theme.primaryColor).alpha(0.4).css(),
      cursor: 'default'
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    color: theme.accentColor,
    border: `2px solid ${theme.accentColor}`,

    '&:hover': {
      color: theme.accentHoverColor,
      border: `2px solid ${theme.accentHoverColor}`
    },
    '&:active:not(:disabled)': {
      color: theme.accentHoverColor,
      backgroundColor: theme.inputFillFocusColor,
      border: `2px solid transparent`
    },
    '&:focus-visible': {
      boxShadow: `0 0 0 2px ${theme.primaryColor}`,
      outline: 'none'
    },
    '&:disabled': {
      color: theme.disabledTextColor,
      border: `2px solid ${theme.disabledTextColor}`,
      cursor: 'default'
    }
  },
  tertiary: {
    backgroundColor: 'transparent',
    color: theme.accentColor,
    borderRadius: 0,
    padding: `0px ${Spacing.spacing00}px`,
    textDecoration: 'none',

    '&:hover': {
      color: theme.accentHoverColor
    },
    '&:focus-visible': {
      color: theme.accentColor,
      border: `2px solid ${theme.primaryColor}`,
      textDecoration: 'underline',
      outline: 'none'
    },
    '&:disabled': {
      color: theme.disabledTextColor,
      cursor: 'default'
    }
  },
  tertiarySmall: {
    fontSize: Typography.body1.size,
    lineHeight: Typography.heading1.lineHeight
  },
  tertiaryMedium: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight
  },
  additional: {
    backgroundColor: theme.accentFocusColor,
    color: theme.defaultButtonTextColor,

    height: '35px',
    padding: '8px 24px',
    fontSize: Typography.body1.size,

    '&:hover': {
      backgroundColor: theme.accentHoverColor
    },
    '&:active': {
      backgroundColor: theme.inputFillFocusColor
    },
    '&:focus-visible': {
      border: `2px solid ${theme.primaryColor}`,
      outline: 'none'
    },
    '&:disabled': {
      backgroundColor: theme.disabledBackgroundColor,
      color: chroma(theme.primaryColor).alpha(0.4).css(),
      cursor: 'default'
    }
  },
  icon: {
    width: 18,
    height: 18,
    fill: theme.primaryColor
  },
  iconDisabled: {
    fill: chroma(theme.primaryColor).alpha(0.4).css()
  },
  customIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 18,
    height: 18
  }
}));
