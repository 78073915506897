import classNames from 'classnames';
import * as R from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePageLanguage } from '../../../../pageLanguage/main/components/usePageLanguage';
import { useCustomerConfig } from '../../../../sharedModules/customerConfig/components/useCustomerConfig';
import InputErrorMessage from '../../../../sharedModules/errors/components/inputErrorMessage';
import PortfolioChangeAlert from '../../advisory/components/portfolioChangeAlert';
import { useForm as useRoboAdviceForm } from '../../form/services/form';
import { useUpdateGoal } from '../../purposeAndRisk/components/useUpdateGoal';
import { creators } from '../../riskScore/services/actions';
import { useSessionStore } from '../../session/services/sessionStore';
import { useGoalsStore } from '../../shared/services/goalsStore';
import { quizDateFields } from '../constants';
import LearnMore from './learnMore';
import { Layouts } from 'features/roboAdvice/shared/constants';
import ConfigurableLabel from 'features/shared/components/configurableLabel/';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { formatDate } from 'features/shared/utils/dateTime';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import { useErrorsStore } from 'features/sharedModules/errors/services/errorsStore';
import FinalFormRadios from 'features/sharedModules/finalForm/components/radios';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const borderRadius = {
  [BorderRadiusTypes.rounded]: '25px',
  [BorderRadiusTypes.sharp]: '15px'
};

const useStyles = createUseStyles(theme => ({
  root: {
    '&+&': {
      marginTop: Spacing.spacing04
    }
  },
  question: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    fontWeight: FontWeights.medium,
    marginBottom: 0
  },
  optionSection: {
    fontWeight: FontWeights.normal,
    fontSize: Typography.body1.size,
    '&:first-child': {
      marginTop: Spacing.spacing01
    }
  },
  trainingDate: {
    color: '#90A0A6',
    lineHeight: Typography.body1.lineHeight
  },
  hasError: {
    border: `2px solid ${theme.errorNegativeColor}`,
    padding: `${Spacing.spacing01}px`,
    paddingBottom: `${Spacing.spacing01 + Spacing.spacing00}px`,
    borderRadius: borderRadius[theme.borderRadiusType]
  },
  riskChangeAlert: {
    marginTop: Spacing.spacing01,
    marginBottom: Spacing.spacing00,
    maxWidth: '60%'
  }
}));

export const Question = ({
  name,
  questionTranslationKey,
  questionTranslations,
  options,
  disabled,
  popupText,
  openModal,
  errors
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const i18n = useI18n();
  const { values } = useRoboAdviceForm();
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const { supportedLanguages } = useCustomerConfig();
  const { loadPageLanguage } = usePageLanguage();
  const updateGoal = useUpdateGoal();
  const isErrorVisible =
    R.includes(name, ['quiz1', 'quiz2', 'quiz3']) && values[name] === 1;
  const isErrorModeEnabled = useErrorsStore(state => state.isErrorModeEnabled);
  const sessionStore = useSessionStore();
  const { goals } = useGoalsStore();

  const questionLabel = React.useMemo(() => {
    if (questionTranslationKey) {
      return i18n(questionTranslationKey);
    } else if (questionTranslations && loadPageLanguage()) {
      return questionTranslations[loadPageLanguage()];
    } else if (questionTranslations) {
      return questionTranslations[supportedLanguages.default];
    }
  }, [loadPageLanguage()]);

  const getOptionLabel = option => {
    if (option.translationKey) {
      return i18n(option.translationKey);
    } else if (option.translations && loadPageLanguage()) {
      return option.translations[loadPageLanguage()];
    } else if (option.translations) {
      return option.translations[supportedLanguages.default];
    }
  };

  return (
    <div
      className={classNames(classes.root, {
        [classes.hasError]: errors?.length && isErrorModeEnabled
      })}
    >
      {errors?.map(error => (
        <InputErrorMessage message={error.alertMessage} />
      ))}
      <FinalFormRadios
        name={name}
        label={<ConfigurableLabel required label={questionLabel} />}
        items={R.pipe(
          R.sort(R.ascend(R.prop('order'))),
          R.map(o => ({
            activeValue: o.id,
            label: getOptionLabel(o)
          }))
        )(options)}
        layout={Layouts.vertical}
        data-testid={`question-${name}`}
        elementId={`question-${name}`}
        labelClassName={classes.question}
        className={classes.optionSection}
        afterChange={() => {
          dispatch(creators.fieldChanged(name, updateGoal));
        }}
        disabled={disabled}
        additionalLabelComponent={
          <>
            {!disabled && popupText && (
              <LearnMore openModal={() => openModal(popupText, name)} />
            )}
            {
              <PortfolioChangeAlert
                in={
                  goals.some(
                    ({ data }) =>
                      data.isPortfolioCustom && data.isCustomPortfolioDirty
                  ) &&
                  sessionStore.fieldsWhichChangedRiskScore.some(
                    field => field === name
                  )
                }
                message={i18n(
                  'roboAdvice.advisory.riskScoreChangeAlertMessage'
                )}
                onClick={() =>
                  sessionStore.removeFieldWhichChangedRiskScore(name)
                }
                className={classes.riskChangeAlert}
              />
            }
          </>
        }
        additionalErrorCondition={isErrorVisible}
      />

      {values[quizDateFields[name]] && (
        <div
          className={classes.trainingDate}
          style={{ marginTop: isErrorVisible ? 0 : '2.7rem' }}
        >
          {i18n('roboAdvice.knowledgeAndExperience.quiz.trainingPerformed')}{' '}
          {formatDate(cultureCode, values[quizDateFields[name]])}
        </div>
      )}
    </div>
  );
};

export default Question;
