import classNames from 'classnames';
import { isNil } from 'ramda';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 } from 'uuid';

import { useFinancialSituationValues } from '../../financialSituation/services/selectors';
import { form as roboAdviceForm } from '../../form/services/form';
import { creators } from '../services/actions.js';
import { Context } from './context.js';
import ModalBody from './modalBody';
import { ReactComponent as AddListIcon } from 'assets/addList.svg';
import { ReactComponent as EditListIcon } from 'assets/editList.svg';
import Modal from 'features/shared/components/modal/index';
import { useModalBodyMaxDimensions } from 'features/shared/components/modal/useModalBodyMaxDimensions';
import { Spacing } from 'features/shared/constants/spacing';
import sessionSelectors from 'features/shared/services/session/selectors';
import { getNumberInputFormat } from 'features/shared/utils/number';
import FinalFormNumberInput from 'features/sharedModules/finalForm/components/numberInput';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  defaultValueInput: {
    width: 170,
    textAlign: 'right'
  },
  defaultValueInputLabel: {
    width: '250px'
  },
  listIcon: {
    fill: theme.secondaryColor,
    cursor: 'pointer',
    marginLeft: Spacing.spacing01,
    height: '44px',

    '&$filled': {
      fill: theme.accentColor
    },

    '&$invalid': {
      fill: theme.errorNegativeColor
    }
  },
  filled: {},
  invalid: {}
}));

export const DetalizedNumberInput = ({
  className,
  labelClassName,
  sectionClassName,
  disabled,
  allowNegative = false,
  ...restProps
}) => {
  const { modalBodyMaxHeight, headerRef } = useModalBodyMaxDimensions();
  const classes = useStyles();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const { thousandSeparator, decimalSeparator } =
    getNumberInputFormat(cultureCode);

  const {
    form,
    name,
    title,
    useDoShowDetailsIconError,
    useLockValue,
    useDoesNumberInfoExist
  } = React.useContext(Context);

  const doShowDetailsIconError = useDoShowDetailsIconError();
  const lockValue = useLockValue();
  const doesNumberInfoExist = useDoesNumberInfoExist();
  const financialSituationValues = useFinancialSituationValues();

  useEffect(() => {
    // If the config is changed and the input type changes from a number to a detalizedNumberInput
    const [firstKey, secondKey] = name.split('.');
    const oldValue = roboAdviceForm.getState().values?.[firstKey]?.[secondKey];

    if (!isNil(oldValue) && !Array.isArray(oldValue)) {
      if (typeof oldValue === 'number') {
        roboAdviceForm.change(`${name}`, [
          { id: v4(), value: oldValue },
          { id: v4() }
        ]);
      } else {
        roboAdviceForm.change(`${name}`, [{ id: v4() }]);
      }
    }
  }, []);

  const dispatch = useDispatch();
  const detailsClosed = () => {
    dispatch(creators.detailsClosed(form, name));
  };
  const detailsOpened = () => {
    dispatch(creators.detailsOpened(form, name));
  };

  const handleChange = value => {
    const [firstKey, secondKey] = name.split('.');
    const oldValue =
      roboAdviceForm.getState().values?.[firstKey]?.[secondKey]?.[0];

    const initialId = oldValue?.id ? {} : { id: v4() };

    const newValue = {
      ...(oldValue || {}),
      ...initialId,
      value,
      toAdvisory: financialSituationValues?.amountForAdviceSwitch ? 100 : value
    };

    roboAdviceForm.mutators.update(`${name}`, 0, newValue);
    dispatch(creators.detailChanged(form, name));
  };

  return (
    <div className={classes.root}>
      <FinalFormNumberInput
        data-testid={'default-value-input'}
        name={`${name}.0.value`}
        afterChange={handleChange}
        label={title}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        allowNegative={allowNegative}
        decimalScale={0}
        fixedValue={lockValue}
        className={classNames(classes.defaultValueInput, className)}
        labelClassName={classNames(
          labelClassName,
          classes.defaultValueInputLabel
        )}
        sectionClassName={sectionClassName}
        disabled={disabled}
        {...restProps}
      />

      {doesNumberInfoExist ? (
        <EditListIcon
          className={classNames(classes.listIcon, classes.filled, {
            [classes.invalid]: !isPopoverOpen && doShowDetailsIconError
          })}
          onClick={() => {
            setIsPopoverOpen(true);
            detailsOpened();
          }}
        />
      ) : (
        <AddListIcon
          data-testid={'add-list-icon'}
          className={classNames(classes.listIcon, {
            [classes.invalid]: !isPopoverOpen && doShowDetailsIconError
          })}
          onClick={() => {
            setIsPopoverOpen(true);
            detailsOpened();
          }}
        />
      )}

      <Modal
        header={title}
        isOpen={isPopoverOpen}
        onRequestClose={() => {
          setIsPopoverOpen(false);
          detailsClosed();
        }}
        headerRef={headerRef}
      >
        <ModalBody
          allowNegative={allowNegative}
          cultureCode={cultureCode}
          disabled={disabled}
          lockValue={lockValue}
          modalBodyMaxHeight={modalBodyMaxHeight}
          name={name}
        />
      </Modal>
    </div>
  );
};

export default DetalizedNumberInput;
