import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { PageStatuses } from '../../shared/components/useReadDataListener';
import { cachePDFReportData } from '../api';
import { usePageStore as useProposalPageStore } from '../services/pageStore';
import { getQAuthAccessToken } from 'features/shared/api';
import { AdviceSessionParams } from 'features/shared/constants/session';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';

const useCachePDF = () => {
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const { adviceSessionId } = useParams<AdviceSessionParams>();
  const proposalPageStore = useProposalPageStore();

  const cachePDF = async () => {
    if (
      !adviceSessionId ||
      proposalPageStore.cachePDFStatus !== PageStatuses.ready
    ) {
      return;
    }

    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    proposalPageStore.setCachePDFStatus(PageStatuses.pending);

    try {
      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      await cachePDFReportData(
        accessToken,
        cancelTokenSource.token,
        adviceSessionId
      );

      proposalPageStore.setCachePDFStatus(PageStatuses.succeed);
    } catch (error) {
      if (!axios.isCancel(error)) {
        proposalPageStore.setCachePDFStatus(PageStatuses.failed);

        throwSafeError(error);
      }
    }
  };

  cachePDF();
};

export default useCachePDF;
