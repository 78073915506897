import { useIsSessionReadOnly } from '../../main/services/selectors';
import ActionButtons from '../../shared/components/actionButtons';
import { useInitPage } from './useInitPage';
import { RoboAdviceAdviceSessionPages } from 'features/roboAdvice/shared/constants';
import AdvisorNotes from 'features/shared/components/advisorNotes';
import { Spacing } from 'features/shared/constants/spacing';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { ConfigurableComponent } from 'features/sharedModules/declarativeForm/components';
import ErrorsList from 'features/sharedModules/errors/components/errorsList';
import InputErrorMessage from 'features/sharedModules/errors/components/inputErrorMessage';
import NextButtonError from 'features/sharedModules/errors/components/nextButtonError';
import { useErrorsStore } from 'features/sharedModules/errors/services/errorsStore';
import { useGetAllAdviceSessionErrors } from 'features/sharedModules/errors/services/useGetAllAdviceSessionErrors';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  customField: {
    margin: `0 0 ${Spacing.spacing04}px 0`,

    '& + &': {
      marginTop: 0
    }
  }
});

const AdviceInformation = () => {
  const classes = useStyles();
  const isSessionReadOnly = useIsSessionReadOnly();
  const i18n = useI18n();

  const fields = useInitPage();
  const allErrors = useGetAllAdviceSessionErrors();
  const errors = allErrors[RoboAdviceAdviceSessionPages.adviceInformation];
  const isErrorModeEnabled = useErrorsStore(state => state.isErrorModeEnabled);

  return (
    fields && (
      <div
        role="tabpanel"
        aria-labelledby={`tab-id-${RoboAdviceAdviceSessionPages.adviceInformation}`}
        id={`panel-id-${RoboAdviceAdviceSessionPages.adviceInformation}`}
      >
        <ErrorsList
          pageName={i18n('roboAdvice.tabs.adviceInformation')}
          errors={errors}
        />
        {fields.map(({ name, config, ...restProps }) => (
          <>
            {errors[name]?.map(error => (
              <InputErrorMessage
                message={error.alertMessage}
                key={error.elementId}
              />
            ))}
            {config?.items &&
              config.items
                .filter(
                  item =>
                    item.textField &&
                    item.textField.name &&
                    errors[item.textField.name]
                )
                .map(item =>
                  errors[item.textField.name].map(error => (
                    <InputErrorMessage
                      message={error.alertMessage}
                      key={error.elementId}
                    />
                  ))
                )
                .flat()}
            <ConfigurableComponent
              key={name}
              fieldClassName={classes.customField}
              name={name}
              config={config}
              elementId={name}
              hasError={
                isErrorModeEnabled &&
                (!!errors[name]?.length ||
                  (config?.items &&
                    config.items
                      .filter(
                        item =>
                          item.textField &&
                          item.textField.name &&
                          errors[item.textField.name]
                      )
                      .some(item => !!errors[item.textField.name]?.length)))
              }
              {...restProps}
            />
          </>
        ))}

        <AdvisorNotes
          name={'adviceInformationAdvisorNotes'}
          header={i18n('adviceInformation.advisorNotes.header')}
          customPlaceholder={i18n('adviceInformation.advisorNotes.placeholder')}
          infoTooltipText={i18n('adviceInformation.advisorNotes.infoTooltip')}
          disabled={isSessionReadOnly}
        />
        <NextButtonError allErrors={allErrors} />
        <ActionButtons
          pageId={RoboAdviceAdviceSessionPages.adviceInformation}
        />
      </div>
    )
  );
};

export default AdviceInformation;
