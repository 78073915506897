import { Form } from 'react-final-form';

import { customPortfolioForm } from '../../services/customPortfolioForm';
import { useCustomPortfolioStore } from '../../services/customPortfolioStore';
import { useHandleCustomPortfolio } from './useHandleCustomPortfolio';
import { Goal } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { Layouts } from 'features/roboAdvice/shared/constants';
import Button from 'features/shared/components/button';
import Modal from 'features/shared/components/modal/index';
import { useModalBodyMaxDimensions } from 'features/shared/components/modal/useModalBodyMaxDimensions';
import Scrollbars from 'features/shared/components/scrollbars';
import { Spacing } from 'features/shared/constants/spacing';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import FinalFormCheckboxes from 'features/sharedModules/finalForm/components/checkboxes';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  modalBodyContainer: {
    marginRight: Spacing.spacing01
  },
  listItem: {
    '& + &': {
      marginTop: Spacing.spacing01
    }
  }
});

type Props = {
  selectedGoal: Goal;
  isOpen: boolean;
  onClose: () => void;
};

const AddAssetClassModal = ({ selectedGoal, isOpen, onClose }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const customPortfolioStore = useCustomPortfolioStore();
  const { saveCustomPortfolio } = useHandleCustomPortfolio();
  const { modalBodyMaxHeight, headerRef, footerRef } =
    useModalBodyMaxDimensions();

  const categories = customPortfolioStore.availableCategories.filter(
    allAssetClass =>
      !(selectedGoal?.data?.customPortfolio || []).find(
        goalAssetClass => goalAssetClass.CategoryId === allAssetClass.CategoryId
      )
  );

  return (
    <Modal
      footer={
        <Button
          onClick={() => {
            saveCustomPortfolio({ goalId: selectedGoal.goalId });
            onClose();
          }}
        >
          {categories.length > 0
            ? i18n('shared.add')
            : i18n('roboAdvice.navigation.close')}
        </Button>
      }
      header={i18n(
        'roboAdvice.advisory.customPortfolio.addAssetClassModalHeader'
      )}
      isOpen={isOpen}
      onRequestClose={onClose}
      headerRef={headerRef}
      footerRef={footerRef}
    >
      {categories.length > 0 ? (
        <Scrollbars autoHeightMax={modalBodyMaxHeight}>
          <div className={classes.modalBodyContainer}>
            <Form onSubmit={() => {}} form={customPortfolioForm}>
              {() => (
                <FinalFormCheckboxes
                  name="addAssetClass"
                  items={categories.map(({ CategoryId, SubAssetClass }) => ({
                    activeValue: CategoryId,
                    label: SubAssetClass
                  }))}
                  width={25}
                  height={25}
                  layout={Layouts.vertical}
                  className={classes.listItem}
                />
              )}
            </Form>
          </div>
        </Scrollbars>
      ) : (
        i18n('roboAdvice.advisory.customPortfolio.allAssetClassesAreSelected')
      )}
    </Modal>
  );
};

export default AddAssetClassModal;
