import { StylesProvider } from '@material-ui/core/styles';
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import ManageCustomer from '../../manageCustomer/main/components/index.js';
import AppAdmin from 'features/admin/appAdmin/main/components/index.js';
import TrashCan from 'features/admin/trashCan/main/components/index';
import MainLayout from 'features/shared/components/mainLayout/index.js';
import routeTemplates from 'features/shared/utils/routeTemplates';

const Admin = () => {
  return (
    <Switch>
      <Route
        exact
        path={routeTemplates.admin.config}
        render={() => (
          <Redirect to={routeTemplates.adminManageCustomer.build()} />
        )}
      />
      <Route
        render={() => (
          <StylesProvider injectFirst>
            <MainLayout>
              <Switch>
                <Route
                  path={routeTemplates.appAdmin.config}
                  component={AppAdmin}
                />
                <Route
                  path={routeTemplates.adminManageCustomer.config}
                  component={ManageCustomer}
                />
                <Route
                  path={routeTemplates.adminTrashCan.config}
                  component={TrashCan}
                />
              </Switch>
            </MainLayout>
          </StylesProvider>
        )}
      />
    </Switch>
  );
};

export default Admin;
