import { ExpectedValuePortfoliosChart } from '@quantfoliorepo/ui-components';
import * as R from 'ramda';
import { useMemo } from 'react';

import LoadingIndicator from 'features/shared/components/loadingIndicator/loadingIndicator';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

const ExpectedValuePortfoliosVisual = ({
  expectedValuePortfoliosChartData,
  selectedGoalsIds,
  cultureCode,
  isLoading = false
}) => {
  const i18n = useI18n();

  const chartData = useMemo(() => {
    const selectedGoalsData = expectedValuePortfoliosChartData.filter(
      ({ goalId }) => selectedGoalsIds.includes(goalId)
    );

    return selectedGoalsData;
  }, [expectedValuePortfoliosChartData, selectedGoalsIds]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (R.isEmpty(chartData)) {
    return <div>{i18n('roboAdvice.proposal.NoPortfolioData')}</div>;
  }

  return (
    <div>
      <ExpectedValuePortfoliosChart
        cultureCode={cultureCode}
        chartData={chartData}
        isInteractive
        yMinValue={100_000}
      />
    </div>
  );
};

export default ExpectedValuePortfoliosVisual;
