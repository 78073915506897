import create from 'zustand';
import * as R from 'ramda';

export const createUseExpectedPathChartStore = (
  initialState = {
    chartData: {},
    goalsData: []
  }
) =>
  create(set => ({
    ...initialState,
    reset: () => {
      set(initialState);
    },
    initialize(initialData) {
      set(
        state => ({
          ...state,
          ...initialData
        }),
        true
      );
    },
    setChartData(selectedGoalsIds) {
      set(state => {
        const currentDate = new Date();

        const selectedGoalsData = selectedGoalsIds
          ? state.goalsData.filter(({ goalId }) =>
              selectedGoalsIds.includes(goalId)
            )
          : state.goalsData;

        const timeHorizon = Math.max(
          ...selectedGoalsData.map(({ timeHorizon }) => timeHorizon)
        );

        // First, process each goal's forecast separately to track end values
        const goalForecasts = selectedGoalsData.map(({ forecast, timeHorizon }) => {
          const sortedForecast = [...forecast].sort((a, b) => new Date(a.date) - new Date(b.date));
          return {
            forecast: sortedForecast,
            endValue: sortedForecast[sortedForecast.length - 1].value,
            endBounds: {
              upper: sortedForecast[sortedForecast.length - 1].upper_bound,
              lower: sortedForecast[sortedForecast.length - 1].lower_bound
            },
            endDate: new Date(sortedForecast[sortedForecast.length - 1].date),
            timeHorizon
          };
        });

        // Create extended forecasts for completed goals
        const extendedForecasts = goalForecasts.flatMap(({ forecast, endValue, endBounds, endDate, timeHorizon }) => {
          const lastDate = new Date(endDate);
          const result = [...forecast];

          // If this isn't the longest-term goal, extend its forecast
          if (timeHorizon < Math.max(...selectedGoalsData.map(g => g.timeHorizon))) {
            // Find all unique dates from longer-term goals that are after this goal's end
            const futureDates = selectedGoalsData
              .filter(g => g.timeHorizon > timeHorizon)
              .flatMap(g => g.forecast)
              .map(f => f.date)
              .filter(date => new Date(date) > lastDate)
              .filter((date, index, self) => self.indexOf(date) === index)
              .sort();

            // Create extended forecast entries with flat values
            futureDates.forEach(date => {
              result.push({
                date,
                value: endValue,
                upper_bound: endBounds.upper,
                lower_bound: endBounds.lower
              });
            });
          }

          return result;
        });

        // Combine all forecasts including extended ones
        const forecast = extendedForecasts.reduce((acc, curr) => {
          const index = acc.findIndex(({ date }) => date === curr.date);
          if (index !== -1) {
            return acc.map((item, i) =>
              i === index
                ? R.mergeWithKey(
                    (k, l, r) => {
                      if (k === 'date') return r;
                      return R.add(l, r);
                    },
                    acc[index],
                    curr
                  )
                : item
            );
          }
          return [...acc, curr];
        }, []);

        const chartData = {
          expectedPathTable: mapServerDataToExpectedPathTable(
            timeHorizon,
            currentDate,
            forecast
          ),
          expectedReturn: mapServerDataToExpectedReturnChart(
            timeHorizon,
            currentDate,
            forecast
          ),
          bounds: mapServerDataToBoundsChart(timeHorizon, currentDate, forecast)
        };
        return { chartData };
      });
    },
    addGoalData: goalData => {
      set(state => ({ ...state, goalsData: [...state.goalsData, goalData] }));
    },
    editGoalData: goalData => {
      set(state => ({
        ...state,
        goalsData: state.goalsData.map(data => {
          if (goalData.goalId === data.goalId) {
            return goalData;
          }
          return data;
        })
      }));
    },
    resetChartData: () => {
      set({
        chartData: R.propOr({}, 'chartData', initialState),
        goalsData: R.propOr([], 'goalsData', initialState)
      });
    }
  }));

const filterExpectedPathServerData = (yearOffset, currentDate, forecast) => {
  const { currentMonth, currentYear } = currentDate;
  const finalYear = currentYear + yearOffset;

  const expectedPath = R.pipe(
    R.map(i => {
      const { date: dateStr, ...restProps } = i;
      const date = new Date(dateStr);

      return {
        ...restProps,
        year: date.getFullYear(),
        month: date.getMonth()
      };
    }),
    R.groupBy(R.prop('year')),
    R.toPairs,
    R.filter(g => g[0] <= finalYear),
    R.sort(R.ascend(R.prop(0))),
    R.map(g => {
      const items = g[1];
      const currentMonthItem = R.find(i => i.month === currentMonth + 1, items);

      return currentMonthItem;
    }),
    R.reject(R.isNil)
  )(forecast);

  if (forecast.length > 0) {
    // Last entry in the forecast is the last month of the last year
    const { date: dateStr, ...restProps } = forecast[forecast.length - 1];
    const date = new Date(dateStr);

    expectedPath.push({
      ...restProps,
      year: date.getFullYear(),
      month: date.getMonth()
    });
  }

  return expectedPath;
};

const mapServerDataToExpectedPathTable = (
  timeHorizon,
  currentDate,
  forecast
) => {
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const filteredData = R.filter(
    i => i.year > currentYear,
    filterExpectedPathServerData(
      timeHorizon,
      {
        currentYear,
        currentMonth
      },
      forecast
    )
  );

  return R.map(
    i => ({
      year: i.year,
      expectedReturn: i.value,
      lowerBound: i.lower_bound,
      upperBound: i.upper_bound
    }),
    filteredData
  );
};

export const mapServerDataToExpectedReturnChart = (
  timeHorizon,
  currentDate,
  forecast
) => {
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const filteredData = filterExpectedPathServerData(
    timeHorizon,
    {
      currentYear,
      currentMonth
    },
    forecast
  );

  return R.map(i => [Date.UTC(i.year, i.month), i.value], filteredData);
};

export const mapServerDataToBoundsChart = (
  timeHorizon,
  currentDate,
  forecast
) => {
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const filteredData = filterExpectedPathServerData(
    timeHorizon,
    { currentYear, currentMonth },
    forecast
  );

  return R.map(
    i => [Date.UTC(i.year, i.month), i.lower_bound, i.upper_bound],
    filteredData
  );
};
