import classNames from 'classnames';
import { useCallback } from 'react';

import {
  AdviceSession,
  selectors,
  useHolisticViewStore
} from '../services/holisticViewStore';
import { useIsLoading } from './useIsLoading';
import Button, {
  ButtonType,
  TertiaryButtonSize
} from 'features/shared/components/button';
import Dropdown from 'features/shared/components/dropdown';
import Icon from 'features/shared/components/icon/index.js';
import Table from 'features/shared/components/table/table.js';
import TableBody from 'features/shared/components/table/tableBody.js';
import TableBodyRow from 'features/shared/components/table/tableBodyRow.js';
import { defaultConfirmation } from 'features/sharedInstances/defaultConfirmation';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const AnyTableComponent: any = Table;
const AnyTableBody: any = TableBody;
const AnyTableBodyRow: any = TableBodyRow;
const AnyDropdown: any = Dropdown;

const useStyles = createUseStyles(theme => ({
  tableActionButton: {
    cursor: 'pointer'
  },
  lastColumn: {
    width: '100px'
  },
  icons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  dropdown: {
    width: '400px'
  }
}));

const formatAdviceSessions = (adviceSessions: AdviceSession[]) =>
  adviceSessions.map(({ name, sessionId }) => ({
    key: sessionId,
    title: name
  }));

const ClientsTable = () => {
  const i18n = useI18n();
  const classes = useStyles();
  const holisticViewStore = useHolisticViewStore();
  const selectedClients = useHolisticViewStore(selectors.getSelectedClients);
  const isLoading = useIsLoading();

  const handleUnselectClient = useCallback(clientId => {
    holisticViewStore.setSelectedClient(clientId, false);
    holisticViewStore.setAdviceSessions(clientId, []);
    holisticViewStore.setGoals(clientId, undefined);
    holisticViewStore.setSelectedAdviceSession(clientId, undefined);
    holisticViewStore.setFinancialSituationData(clientId, null);
  }, []);

  return (
    <>
      <AnyTableComponent
        header={[
          {
            title: i18n('tools.holisticView.client')
          },
          {
            title: i18n('tools.holisticView.adviceSession')
          },
          {
            component: (
              <Button
                buttonType={ButtonType.tertiary}
                buttonSize={TertiaryButtonSize.small}
                onClick={() =>
                  holisticViewStore.setIsAddCustomerPopupOpen(true)
                }
              >
                {i18n('tools.holisticView.addClient')}
              </Button>
            )
          }
        ]}
      >
        <AnyTableBody noDataTitle={'no data'} cellsNumber={3}>
          {selectedClients.map(client => (
            <AnyTableBodyRow key={client.id}>
              {({ bodyRowCellClassName }) => (
                <>
                  <td className={bodyRowCellClassName}>{client.name}</td>
                  <td className={bodyRowCellClassName}>
                    <AnyDropdown
                      options={formatAdviceSessions(client.adviceSessions)}
                      value={client.selectedAdviceSessionId}
                      onChange={val =>
                        holisticViewStore.setSelectedAdviceSession(
                          client.id,
                          val
                        )
                      }
                      noDataTitle={i18n('tools.holisticView.noAdviceSessions')}
                      disabled={isLoading}
                      className={classes.dropdown}
                    />
                  </td>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.lastColumn
                    )}
                  >
                    <div className={classes.icons}>
                      <Icon
                        className={classes.tableActionButton}
                        type="delete"
                        onClick={() =>
                          defaultConfirmation.useConfirmationStore
                            .getState()
                            .open(
                              i18n(
                                'tools.holisticView.removeCustomerConfirmation'
                              ),
                              () => handleUnselectClient(client.id)
                            )
                        }
                      />
                    </div>
                  </td>
                </>
              )}
            </AnyTableBodyRow>
          ))}
        </AnyTableBody>
      </AnyTableComponent>
    </>
  );
};

export default ClientsTable;
