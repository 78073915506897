import { isNil, isEmpty } from 'ramda';

import Link from 'features/shared/components/link/index';
import { Spacing } from 'features/shared/constants/spacing';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  breadcrumbsPlaceholder: {
    height: 64,
    width: 40
  },
  breadcrumbs: {
    color: theme.secondaryColor,
    display: 'flex',
    lineHeight: '150%',
    padding: `${Spacing.spacing02}px 0 ${Spacing.spacing04}px 0`
  },
  breadcrumb: {
    display: 'flex',
    alignItems: 'center'
  },
  breadcrumbLink: {
    color: theme.secondaryColor
  },
  icon: {
    width: 0,
    height: 0,
    borderTop: '7px solid transparent',
    borderLeft: `8px solid ${theme.secondaryColor}`,
    borderBottom: '7px solid transparent',
    margin: `0 ${Spacing.spacing01}px`
  }
}));

const Breadcrumbs = ({ items = [], ...restProps }) => {
  const classes = useStyles();

  return isNil(items) || isEmpty(items) ? (
    <div className={classes.breadcrumbsPlaceholder} />
  ) : (
    <div className={classes.breadcrumbs} {...restProps}>
      {items.map((item, index, collection) => {
        return index === collection.length - 1 ? (
          <div key={index}>{item.title}</div>
        ) : (
          <div key={index} className={classes.breadcrumb}>
            <Link className={classes.breadcrumbLink} to={item.to}>
              {item.title}
            </Link>
            <div className={classes.icon} />
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
