import { ReactElement } from 'react';

import { ReactComponent as DefaultLogo } from '../../../../../src/assets/logo.svg';
import './style.css';
import { defaultTheme } from 'defaults/defaultTheme';
import Watermark from 'features/roboAdvice/main/components/watermark';
import { LayoutBreakpoints } from 'features/shared/constants/layoutBreakpoints';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  page: {
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,
    backgroundColor: defaultTheme.pageBackgroundColor,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    width: '375px',
    paddingBottom: '100px',
    fontSize: Typography.body1.size,
    lineHeight: Typography.heading1.lineHeight,
    fontWeight: FontWeights.normal,
    color: defaultTheme.secondaryColor
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: Spacing.spacing06,
    [`@media (max-width: ${LayoutBreakpoints.desktopMin}px)`]: {
      marginBottom: Spacing.spacing05
    }
  },
  logo: {
    flex: '0 0 auto',
    lineHeight: '0',
    cursor: 'pointer'
  },
  logoImage: {
    width: '188px'
  },
  header: {
    margin: `0 0 ${Spacing.spacing01}px 0`,
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    fontWeight: FontWeights.medium,
    color: defaultTheme.primaryColor
  },
  footer: {
    color: defaultTheme.secondaryColor,
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%'
  }
});

type Props = {
  header: string;
  children: ReactElement;
  isLinkActive?: boolean;
};

const ErrorPage = ({ header, children, isLinkActive }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.page}>
      <main className={classes.content}>
        <div className={classes.logoWrapper}>
          <DefaultLogo className={classes.logoImage} />
        </div>
        <h1 className={classes.header}>{header}</h1>
        {children}
      </main>
      <footer className={classes.footer}>
        <Watermark isLinkActive={isLinkActive} />
      </footer>
    </div>
  );
};

export default ErrorPage;
