import FileSaver from 'file-saver';
import ldDebounce from 'lodash.debounce';
import * as R from 'ramda';

export const saveFile = (data, filename, options) => {
  FileSaver.saveAs(data, filename, options);
};

export const debounce = fn => ldDebounce((...args) => fn(...args), 300);

export const excludeNilFields = R.reject(R.isNil);
