import { types as customerManagementPageActionTypes } from 'features/customers/customerManagementPage/services/actions.js';
import { OrderDirections } from 'features/shared/constants/table';

export const CustomerManagementUsersTableOrderKeys = {
  email: 'email'
};

export const customerManagementUsersTableInitialState = {
  orderKey: CustomerManagementUsersTableOrderKeys.email,
  orderDirection: OrderDirections.asc,
  pageSize: 50,
  pageNumber: 0,
  selectedRowIds: []
};

export const reducer = (state, action) => {
  switch (action.type) {
    case customerManagementPageActionTypes.CLOSED: {
      return customerManagementUsersTableInitialState;
    }
    default:
      return state;
  }
};
