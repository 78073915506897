import deepFreeze from 'deep-freeze';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { reducers } from './reducers.js';
import { sagaMiddleware } from './sagaMiddleware.js';

const isDevelopment = process.env.NODE_ENV === 'development';

const composeEnhancers =
  isDevelopment && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: 'DEEP_ALPHA'
      })
    : compose;

const rootReducer = (state, action) => {
  const newState =
    action.type === '__ROOT__/RESET'
      ? reducers(undefined, action)
      : reducers(state, action);

  if (isDevelopment) {
    deepFreeze(newState);
  }

  return newState;
};

const middleware = [thunk, sagaMiddleware];

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
