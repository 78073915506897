import { useParams } from 'react-router';

import { useCreateClient } from '../../main/components/useCreateClient';
import { useUpdateClient } from '../../main/components/useUpdateClient';
import { clientForm } from '../../main/services/clientForm';
import { ClientParams } from '../../shared/types';
import { getHasValidationErrors } from '../../shared/utils';
import { NewClientId } from 'features/roboAdvice/shared/constants';
import { getFieldsWithTextFields } from 'features/roboAdvice/shared/utils';
import { ClientTypes } from 'features/shared/constants/session';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';

export function useSaveClientInformation() {
  const { clientId } = useParams<ClientParams>();
  const createClient = useCreateClient();
  const updateClient = useUpdateClient();
  const {
    roboAdvice: {
      clientInformation: {
        company,
        person,
        contacts: { isContactListEnabled }
      }
    }
  } = useCustomerConfig();

  const saveClientInformation = async () => {
    const { errors, values } = clientForm.getState();
    const hasValidationErrors = getHasValidationErrors(
      errors?.clientInformation
    );

    if (hasValidationErrors) {
      const fields =
        values.clientInformation?.clientType === ClientTypes.company
          ? [...company, ...getFieldsWithTextFields(company, values)]
          : [...person, ...getFieldsWithTextFields(person, values)];

      clientForm.batch(() => {
        for (const f of fields) {
          clientForm.mutators.setFieldTouched(f.name, true);
        }
        if (
          values.clientInformation?.clientType === ClientTypes.company &&
          isContactListEnabled
        ) {
          clientForm.mutators.setFieldTouched(
            'clientInformation.contacts[0].role',
            true
          );
        }
      });
      return;
    }

    if (clientId === NewClientId) {
      await createClient();
    } else {
      await updateClient();
    }
  };
  return saveClientInformation;
}
