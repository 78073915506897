import { ReactNode } from 'react';

import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const borderRadius = {
  [BorderRadiusTypes.rounded]: '15px',
  [BorderRadiusTypes.sharp]: '10px'
};

const useStyles = createUseStyles(theme => ({
  root: {
    marginTop: Spacing.spacing02,
    backgroundColor: theme.boxBackgroundColor_1,
    borderRadius: borderRadius[theme.borderRadiusType],
    border: `1px solid ${theme.defaultInputStrokeColor}`
  },
  sectionHeader: {
    padding: `${Spacing.spacing02}px 0 ${Spacing.spacing02}px ${Spacing.spacing04}px`,
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    fontWeight: FontWeights.medium
  },
  noDataBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '80px',
    color: theme.secondaryColor
  },
  noDataBlockHeader: {
    paddingBottom: Spacing.spacing00,
    fontSize: Typography.heading1.size,
    fontWeight: FontWeights.medium
  },
  sectionChildren: {
    padding: `0 ${Spacing.spacing04}px ${Spacing.spacing02}px ${Spacing.spacing04}px`
  }
}));

type Props = {
  sectionHeader: string;
  notEnoughData?: boolean;
  children?: ReactNode;
};

const HolisticSection = ({
  sectionHeader,
  notEnoughData = false,
  children
}: Props) => {
  const i18n = useI18n();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.sectionHeader}>{sectionHeader}</div>
      {children ? (
        <div className={classes.sectionChildren}>{children}</div>
      ) : notEnoughData ? (
        <div className={classes.noDataBlock}>
          <div className={classes.noDataBlockHeader}>
            {i18n('tools.holisticView.notEnoughData')}
          </div>
        </div>
      ) : (
        <div className={classes.noDataBlock}>
          <div className={classes.noDataBlockHeader}>
            {i18n('tools.holisticView.noDataDisplayed')}
          </div>
          <div>{i18n('tools.holisticView.pleaseAddClient')}</div>
        </div>
      )}
    </div>
  );
};

export default HolisticSection;
