import chroma from 'chroma-js';
import classNames from 'classnames';
import * as R from 'ramda';
import React from 'react';

import { formatNumber } from 'features/shared/utils/number';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  cell: {
    padding: '0',
    textAlign: 'right',
    height: 1, // hack to have a possibility to set td child to 100% height

    '&:first-child': {
      paddingLeft: '0.9rem'
    },

    '&:last-child': {
      paddingRight: '0.9rem'
    }
  },
  valueContainer: {
    display: 'inline-block'
  },
  value: {
    padding: '1.6rem 2.1rem',
    display: 'inline-block'
  }
});

const displayNumber = (suffix, number) =>
  !R.isNil(suffix) ? `${number}${suffix}` : number;

const NumberCell = React.forwardRef(
  (
    {
      value,
      cultureCode,
      suffix,
      className,
      backgroundStylesCell,
      backgroundStylesContainer,
      valueContainerClassName,
      onMouseEnter,
      onMouseLeave,
      onClick,
      ...restProps
    },
    ref
  ) => {
    const classes = useStyles();
    const theme = useTheme();

    const backgroundStyles = React.useMemo(() => {
      if (value.selectedGoalsIndexes.length === 1) {
        return {
          backgroundColor: chroma(
            theme.chartPortfolioColors[value.selectedGoalsIndexes[0]]
          )
            .alpha(0.5)
            .css()
        };
      }
      if (value.selectedGoalsIndexes.length > 1) {
        let colors = '';
        value.selectedGoalsIndexes.forEach((i, index) => {
          const color = chroma(theme.chartPortfolioColors[i]).alpha(0.5).css();
          colors += `${color} ${
            (index / value.selectedGoalsIndexes.length) * 100
          }%, ${color} ${
            ((index + 1) / value.selectedGoalsIndexes.length) * 100
          }%`;
          if (index !== value.selectedGoalsIndexes.length - 1) {
            colors += ', ';
          }
        });
        return { background: `linear-gradient(to right, ${colors})` };
      }
      return null;
    }, [theme.chartPortfolioColors, value.selectedGoalsIndexes]);

    return (
      <td
        data-testid={restProps['data-testid']}
        className={classNames(className, classes.cell)}
        ref={ref}
        style={backgroundStylesCell && backgroundStyles}
      >
        <div
          data-id={'number-cell-value-container'}
          data-selected={!!value.selectedGoalsIndexes.length}
          className={classNames(
            classes.valueContainer,
            valueContainerClassName
          )}
          style={backgroundStylesContainer && backgroundStyles}
          onMouseEnter={e => onMouseEnter && onMouseEnter(e)}
          onMouseLeave={onMouseLeave && onMouseLeave}
          onClick={e => onClick && onClick(e)}
        >
          <div
            data-id={'number-cell-value'}
            data-selected={!!value.selectedGoalsIndexes.length}
            className={classes.value}
          >
            {!R.isNil(value.value) ? (
              displayNumber(
                suffix,
                formatNumber(cultureCode, value.value, 0, 2)
              )
            ) : (
              <span dangerouslySetInnerHTML={{ __html: '&mdash;' }} />
            )}
          </div>
        </div>
      </td>
    );
  }
);

export default NumberCell;
