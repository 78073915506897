import * as React from 'react';

import { adviceSessionsTable, OrderKeys } from '../../adviceSessionsTable';
import { useAdviceSessionsStore } from '../services/adviceSessionsStore';
import { useReadAdviceSessions } from './useReadAdviceSessions';
import { OrderDirections } from 'features/shared/constants/table';

export function useInitPage() {
  const readAdviceSessions = useReadAdviceSessions();

  React.useEffect(() => {
    const tableStore = adviceSessionsTable.useTableStore.getState();

    tableStore.changeOrder(OrderKeys.softDeleteDate, OrderDirections.desc);

    readAdviceSessions();

    return () => {
      const tableStore = adviceSessionsTable.useTableStore.getState();
      const adviceSessionsStore = useAdviceSessionsStore.getState();

      tableStore.reset();
      adviceSessionsStore.reset();
    };
  }, []);
}
