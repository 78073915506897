import classNames from 'classnames';
import * as R from 'ramda';
import React from 'react';

import Checkmark from './checkmark';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const Layouts = {
  default: 'default',
  fullWidth: 'fullWidth'
};

const useStyles = createUseStyles(theme => ({
  container: {
    position: 'relative',
    width: '164px',
    backgroundColor: theme.itemBackgroundColor_1,
    borderRadius: '15px',
    textAlign: 'center',
    padding: '40px 0 20px 0',
    cursor: 'pointer',
    border: `2px solid ${theme.itemBackgroundColor_1}`,

    '&:hover': {
      border: `2px solid ${theme.hoverInputStrokeColor}`
    }
  },
  selected: {
    backgroundColor: theme.itemBackgroundColor_2,
    border: `2px solid ${theme.itemBackgroundColor_2}`
  },
  checkMark: {
    position: 'absolute',
    top: '-5px',
    right: '-5px',
    '& > path': {
      fill: 'none'
    }
  },
  image: {
    maxWidth: '100%',
    padding: '0 10px'
  },
  imageFullWidth: {
    padding: '0'
  },
  title: {
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight,
    // fontWeight: FontWeights.light,
    padding: '0 10px',
    marginTop: '8px',
    color: theme.primaryColor
  },
  description: {
    fontSize: Typography.subtitle.size,
    lineHeight: Typography.subtitle.lineHeight,
    fontWeight: FontWeights.medium,
    color: theme.secondaryColor,
    padding: '0 10px',
    marginTop: '10px'
  },
  disabled: {
    cursor: 'not-allowed',

    '& $title': {
      color: theme.disabledTextColor
    },

    '&:hover': {
      border: `2px solid ${theme.itemBackgroundColor_1}`
    },

    '& svg > path': {
      fill: theme.disabledTextColor
    }
  }
}));

export const Card = ({
  id,
  isSelected,
  onSelect,
  imageSrc,
  imageComponent,
  title,
  description,
  layout,
  className,
  imageContainerClassName,
  disabled
}) => {
  const classes = useStyles();
  const imageClassName = classNames(classes.image, {
    [classes.imageFullWidth]: layout === Layouts.fullWidth
  });

  return (
    <div
      className={classNames(
        classes.container,
        { [classes.disabled]: disabled },
        { [classes.selected]: isSelected },
        className
      )}
      onClick={disabled ? undefined : onSelect}
      data-testid={`card-${id}`}
    >
      {isSelected && (
        <Checkmark
          className={classes.checkMark}
          data-testid={'card-selected-icon'}
        />
      )}
      <div className={imageContainerClassName}>
        {imageSrc && (
          <img className={imageClassName} src={imageSrc} alt={title} />
        )}
        {imageComponent && imageComponent}
      </div>
      <div className={classes.title}>{title}</div>
      {!R.isNil(description) && (
        <div className={classes.description}>{description}</div>
      )}
    </div>
  );
};

Card.defaultProps = {
  layout: Layouts.default
};

export default Card;
