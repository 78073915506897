import { useSelector } from 'react-redux';

import { usePortfolioChartStore } from '../../../portfolioChart';
import { usePageStore as useProposalPageStore } from '../../services/pageStore';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { useGoalsStore } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { selectors } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import PortfolioChartGridVisual from 'features/shared/analyticsComponents/portfolioChartGrid/visual';
import sessionSelectors from 'features/shared/services/session/selectors';

const PortfolioChartGrid = () => {
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const portfolioChartStore = usePortfolioChartStore();
  const proposalPageStore = useProposalPageStore();
  const selectedGoalsIds = useGoalsStore(selectors.getSelectedGoalsIds);
  const selectedGoalsNames = useGoalsStore(selectors.getSelectedGoalsNames);

  return (
    <PortfolioChartGridVisual
      portfolioChartData={portfolioChartStore.chartData}
      selectedGoalsIds={selectedGoalsIds}
      selectedGoalsNames={selectedGoalsNames}
      cultureCode={cultureCode}
      isLoading={
        proposalPageStore.pageStatuses.readPortfolioChartData ===
        PageStatuses.pending
      }
    />
  );
};

export default PortfolioChartGrid;
