import { HistoricalReturnTimeRangeChartData } from '@quantfoliorepo/ui-components';
import { ReactElement } from 'react';
import { z } from 'zod';

import { NamespaceStatusType } from '../../advisory/types';
import { PortfolioType } from '../../portfolioChart';
import { ProductPlatformQuestions } from '../../shared/services/goalsStore';
import {
  preferenceCriteriaKey,
  SustainabilityData
} from '../../sustainability';
import { tableType } from 'features/shared/analyticsComponents/sustainability/constants';
import {
  CostChartData,
  CostTableData
} from 'features/shared/types/costChartTypes';

export type AdviceInformationField = {
  label: string;
  name: string;
  order: number;
  value: string;
};

export type AnalyticsComponents = {
  investmentPlan: boolean;
  portfolio: {
    broadAssetAllocation: boolean;
    assetAllocation: boolean;
    fundAllocation: boolean;
  };
  expectations: boolean;
  expectedPath: boolean;
  expectedValue: boolean;
  costValue: boolean;
  historicalReturn: boolean;
};

const answerSchema = z.object({
  translationKey: z.string().nullable(),
  translations: z.record(z.string()).optional(),
  id: z.number(),
  order: z.number()
});

type Answer = z.infer<typeof answerSchema>;

export type CashflowGoal = {
  goalId: string;
  icon?: string;
  name: string;
  withdrawal?: string | null;
  capitalNeed: number | string;
  frequency: string;
  year: number | string;
};

export type CashflowForecastChartData = {
  ranges: [number, number, number][];
  savingPlan: [number, number][];
  savingPlanWithNoReturn: [number, number][];
};

export type CashflowGoalChartData = {
  chartData: { estimatedGoalAchievement: number; capitalNeed: number };
  goalIcon?: string;
  goalId: string;
  goalName: string;
};

export type CashflowTableData = {
  amount: number;
  date: string;
  deposits: number;
  withdrawals: number;
};

export type ClientInformation = {
  clientType: string;
  fields: { value: string; title: string; order: number }[];
  accountNumber: string;
  isContactListEnabled?: boolean;
  isSignatureRightsEnabled?: boolean;
  isUBOEnabled?: boolean;
  contacts?: {
    name: string;
    role: string;
    email: string;
    phone: string;
  }[];
};

export type CompanyFinancialSituation = {
  investableAssets: { title: string; value: string | null }[];
  accountingFigures: { title: string; value: string | null }[];
  debt: { title: string; value: string | null }[];
};

export type Cost = {
  chartData: CostChartData[];
  tableData: CostTableData;
  overrideFundOngoingFeesAdvisorNotes?: string;
  overrideFundOneTimeFeesAdvisorNotes?: string;
  overrideSummaryAnnualOngoingFeesAdvisorNotes?: string;
  overrideSummaryCustodyFeesAdvisorNotes?: string;
  overrideSummaryOneTimeFeesAdvisorNotes?: string;
};

export type ExpectedPathChartData = {
  expectedPathTable: {
    year: number;
    expectedReturn: number;
    lowerBound: number;
    upperBound: number;
  }[];
  expectedReturn: [number, number][];
  bounds: [number, number, number][];
};

export type ExpectedValueChartData = {
  expectedValueTable: {
    year: number;
    accDeposit: number;
    accReturn: number;
    value: number;
  }[];
  savingPlan: {
    x: number;
    y: number;
    custom: {
      accReturn: number;
    };
  }[];
  savingInBankAccount: {
    x: number;
    y: number;
    custom: {
      accReturn: number;
    };
  }[];
};

export type FinancialSituation = {
  isAmountForAdviceEnabled: boolean;
  assets: FinancialSituationGroup;
  debt: FinancialSituationGroup;
  liquidity: FinancialSituationGroup;
  accountingFigures: FinancialSituationComponent[];
};

type FinancialSituationComponent = {
  id: string;
  enabled: boolean;
  name: string;
  label: Record<string, string>;
  placeholder?: Record<string, string>;
  priority: number;
  type?: string;
  layout?: string;
  showInAmountForAdvice?: boolean;
};

export type FinancialSituationField = [
  string,
  {
    render: {
      label: any;
      fieldSection: string;
      componentType: string;
    };
    validate: {};
    calcProgress: {
      when: any;
    };
  }
];

export type FinancialSituationFields = FinancialSituationField[];

type FinancialSituationGroup = {
  person: FinancialSituationComponent[];
  company: FinancialSituationComponent[];
};

type HistoricalReturnKeyStatistics = Record<
  string,
  {
    goalId: string;
    growth: number;
  }[]
>;

export type HistoricalReturn = {
  chartData: HistoricalReturnTimeRangeChartData;
  chartDescription: string;
  keyStatistics: HistoricalReturnKeyStatistics;
};

export type KnowledgeAndExperience = {
  translations?: { [key: string]: string };
  translationkey?: string;
  answers: Answer[];
  trainingDate: string | null;
}[];

export type KnowledgeAndExperienceQuestions = {
  questions: Question[];
};

export type Liquidity = {
  title: string | null;
  value: boolean | string | number | null;
};

export type PersonFinancialSituation = {
  asset: string | undefined;
  assetSum: string | null | undefined;
  debt: string | undefined;
  debtSum: string | null | undefined;
}[];

export type PortfolioChartStoreState = {
  chartData: {
    expectedAnnualReturn: number;
    expectedVolatility: number;
    equityPortion: number;
    goalId: string;
    goalName: string;
    goalIcon: string;
    portfolioType: keyof typeof PortfolioType;
    categoryMainAssetClassPortfolio: {
      id: string;
      isin: string | null;
      name: string;
      weight: number;
    }[];
    categorySubAssetClassPortfolio: {
      id: string;
      isin: string | null;
      name: string;
      weight: number;
    }[];
    categorySelectionPortfolio: {
      id: string;
      isin: string | null;
      name: string;
      weight: number;
    }[];
  }[];
};

export type SustainabilityDataReport = {
  sustainabilityAssessment: {
    header: string;
    body: string;
    answer: number;
    advisorNote: string;
  }[];
  preferenceCriteria: preferenceCriteriaKey[];
  sustainabilityPreference: { icon: string; id: string; title: string }[];
  data: SustainabilityData;
  tableType: typeof tableType[keyof typeof tableType];
};

export const questionSchema = z.object({
  name: z.string(),
  options: z.array(answerSchema),
  order: z.number(),
  riskApiParam: z.string(),
  popupText: z.string().nullable(),
  translationKey: z.string().nullable(),
  translations: z.record(z.string()).optional()
});

export type Question = z.infer<typeof questionSchema>;

export type ProductPlatformData = {
  name: string;
  productPlatformNamespace?: string;
  productPlatformQuestions?: ProductPlatformQuestions;
  productPlatformReasoning?: string;
};

export type ProductPlatformConfig = {
  items: {
    activeValue: number;
    disabled: boolean;
    label: ReactElement;
    labelString: string;
    status: NamespaceStatusType;
  }[];
  questions: {
    enabled?: boolean;
    label?: { [key: string]: string };
    name?: string;
  }[];
  goalId: string;
};
