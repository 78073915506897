import chroma from 'chroma-js';
import classNames from 'classnames';
import ReactModal from 'react-modal';

import {
  modalBorderWidth,
  modalFooterMarginTop,
  modalHeaderMarginBottom,
  modalMaxHeightPercent,
  modalPadding
} from './useModalBodyMaxDimensions';
import Icon from 'features/shared/components/icon/index.js';
import { LayoutBreakpoints } from 'features/shared/constants/layoutBreakpoints';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const borderRadius = {
  [BorderRadiusTypes.rounded]: '15px',
  [BorderRadiusTypes.sharp]: '10px'
};

const useStyles = createUseStyles(theme => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: chroma(theme.pageBackgroundColor).alpha(0.7).css(),
    zIndex: 2
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: borderRadius[theme.borderRadiusType],
    border: `${modalBorderWidth}px solid ${theme.accentColor}`,
    backgroundColor: theme.pageBackgroundColor,
    outline: 'none',
    padding: modalPadding,
    minWidth: '25%',
    maxWidth: '84%', // horizontal margin 8%
    maxHeight: `${modalMaxHeightPercent}%`,
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight
  },
  bodyOpen: {
    overflow: 'hidden'
  },
  htmlOpen: {
    overflow: 'hidden'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: modalHeaderMarginBottom
  },
  headerText: {
    fontWeight: FontWeights.medium,
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight
  },
  closeIcon: {
    cursor: 'pointer',
    transform: 'rotate(0.03deg)', // fix of issue: icon small size causes pixelated icon
    color: theme.secondaryColor,

    '&$disabled': {
      cursor: 'default'
    }
  },
  body: {
    display: 'flex',
    flexDirection: 'column'
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: Spacing.spacing01,
    marginTop: modalFooterMarginTop
  },
  disabled: {},
  wideContent: {
    [`@media (max-width: ${LayoutBreakpoints.desktopMin}px)`]: {
      minWidth: '84%'
    }
  }
}));

export type ModalProps = ReactModal.Props & {
  header: string;
  footer?: React.ReactNode;
  disabled?: boolean;
  bodyRef?: React.MutableRefObject<HTMLDivElement | null>;
  headerRef?: (node: HTMLDivElement | null) => void;
  footerRef?: (node: HTMLDivElement | null) => void;
  wideContent?: boolean;
};

const Modal = ({
  isOpen,
  onAfterOpen,
  onRequestClose,
  children,
  header,
  footer,
  disabled,
  bodyRef,
  headerRef,
  footerRef,
  wideContent,
  ...restProps
}: ModalProps) => {
  const classes = useStyles();

  return (
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={disabled ? undefined : onRequestClose}
      overlayClassName={classes.overlay}
      className={classNames(classes.content, {
        [classes.wideContent]: wideContent
      })}
      bodyOpenClassName={classes.bodyOpen}
      htmlOpenClassName={classes.htmlOpen}
      {...restProps}
    >
      <div ref={headerRef} className={classes.header}>
        <div className={classes.headerText}>{header}</div>

        <Icon
          className={classNames(classes.closeIcon, {
            [classes.disabled]: disabled
          })}
          type={'close'}
          onClick={onRequestClose}
        />
      </div>

      <div ref={bodyRef} className={classes.body}>
        {children}
      </div>

      {footer && (
        <div ref={footerRef} className={classes.footer}>
          {footer}
        </div>
      )}
    </ReactModal>
  );
};

export default Modal;
