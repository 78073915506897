import classNames from 'classnames';
import * as React from 'react';
import { Form, FormSpy } from 'react-final-form';

import { manageCustomerForm } from '../services/manageCustomerForm';
import { useManageCustomerStore } from '../services/manageCustomerStore';
import AddUsers from './addUsers.js';
import ManageUsers from './manageUsers.js';
import { usePageStyles } from './shared.js';
import Subscription from './subscription.js';
import { useReadCustomer } from './useReadCustomer';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

const ManageCustomer = () => {
  const i18n = useI18n();
  const pageClasses = usePageStyles();

  const readCustomer = useReadCustomer();

  const cleanupState = () => {
    const manageCustomerStoreState = useManageCustomerStore.getState();

    manageCustomerForm.restartToInitialValues();
    manageCustomerStoreState.reset();
  };

  React.useEffect(() => {
    readCustomer();

    return cleanupState;
  }, []);

  return (
    <Form form={manageCustomerForm} onSubmit={() => {}}>
      {() => (
        <div>
          <div className={pageClasses.header}>
            {i18n('admin.userManagement')}
          </div>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <div
                className={classNames(
                  pageClasses.section,
                  pageClasses.horizontalSection
                )}
              >
                <div
                  className={classNames(
                    pageClasses.label,
                    pageClasses.horizontalLabel
                  )}
                >
                  {i18n('admin.manageCustomer.customerName')}
                </div>
                <div>{values.customerName}</div>
              </div>
            )}
          </FormSpy>
          <div
            className={classNames(
              pageClasses.section,
              pageClasses.verticalSection
            )}
          >
            <Subscription />
          </div>
          <div
            className={classNames(
              pageClasses.section,
              pageClasses.verticalSection
            )}
          >
            <AddUsers />
          </div>
          <div
            className={classNames(
              pageClasses.section,
              pageClasses.verticalSection
            )}
          >
            <ManageUsers />
          </div>
        </div>
      )}
    </Form>
  );
};

export default ManageCustomer;
