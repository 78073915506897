import { useEffect } from 'react';

import Button from '../../../../../shared/components/button';
import { useIsSessionReadOnly } from '../../../main/services/selectors';
import { useOrderPageStore } from '../../services/orderPageStore';
import { useIsGenerateOrderEnabled } from '../../services/selectors';
import GenerateNewPdfReportModal from '../generateNewPdfReportModal';
import GenerateOrderModal from '../generateOrderModal/generateOrderModal';
import CostTable from './cost/costTable';
import ExpectedValue from './expectedValue/expectedValue';
import ProposalInstrumentsTable from './orderInstrumentsTable';
import { useInitPage } from './useInitPage';
import { useSessionStore } from 'features/roboAdvice/adviceSession/session/services/sessionStore';
import WarningMessage from 'features/roboAdvice/adviceSession/shared/components/warningMessage';
import AdvisorNotes from 'features/shared/components/advisorNotes';
import {
  carouselIds,
  useCarouselStore
} from 'features/shared/components/carousel/carouselStore';
import {
  Carousel,
  CarouselBody,
  CarouselItem,
  CarouselTab,
  CarouselTabs
} from 'features/shared/components/carousel/verticalNavigation';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  section: {
    position: 'relative',
    marginBottom: Spacing.spacing04
  },
  sectionHeader: {
    fontWeight: FontWeights.medium,
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    marginBottom: Spacing.spacing01
  },
  holisticLink: {
    position: 'absolute',
    top: '0',
    right: '0'
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  actionButton: {
    '& + &': {
      marginLeft: '2.4rem'
    }
  },
  customRoot: {
    padding: Spacing.spacing02
  },
  carouselBodyContainer: {
    minWidth: '700px'
  },
  carouselItem: {
    minHeight: 'initial'
  }
});

const Order = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const isGenerateOrderEnabled = useIsGenerateOrderEnabled();
  const isSessionReadOnly = useIsSessionReadOnly();
  const {
    isReadPdfReportPending,
    setIsGenerateReportPopupOpen,
    isReadNewPdfReportPending,
    setIsGenerateNewPdfReportPopupOpen
  } = useOrderPageStore();
  const carouselStore = useCarouselStore();
  const isAdviceSessionPatched = useSessionStore(
    state => state.isAdviceSessionPatched
  );

  const {
    roboOrderFlow: {
      proposal: { analyticsComponents }
    },
    roboAdvice: {
      orderExecution: {
        pdfGeneration: { usePDFv1, usePDFv2 }
      }
    }
  } = useCustomerConfig();

  useInitPage();

  useEffect(() => {
    carouselStore.setSelectedItemIndex(carouselIds.orderPage, 0);
  }, []);

  const carouselElements = [
    {
      tab: 'roboAdvice.proposal.cost',
      item: <CostTable />,
      dataTestId: 'cost-chart-card',
      dataTestIdTab: 'cost-tab',
      display: analyticsComponents.showCost,
      statusKeys: []
    },
    {
      tab: 'roboAdvice.proposal.expectedValue',
      item: <ExpectedValue />,
      dataTestId: 'expected-value-card',
      dataTestIdTab: 'expected-value-tab',
      display: analyticsComponents.showExpectedValue,
      statusKeys: []
    }
  ].filter(({ display }) => display);

  return (
    <>
      <div className={classes.section}>
        <div className={classes.sectionHeader}>
          {i18n('roboAdvice.orderExecution.selectedInstruments')}
        </div>
        <ProposalInstrumentsTable />
      </div>

      <div className={classes.section}>
        {!!carouselElements.length && (
          <div>
            <Carousel
              className={classes.customRoot}
              carouselId={carouselIds.orderPage}
            >
              <CarouselTabs carouselId={carouselIds.orderPage}>
                {carouselElements.map(({ tab, dataTestId, dataTestIdTab }) => (
                  <CarouselTab data-testid={dataTestIdTab} key={dataTestId}>
                    {i18n(tab)}
                  </CarouselTab>
                ))}
              </CarouselTabs>
              <CarouselBody
                containerClassName={classes.carouselBodyContainer}
                carouselId={carouselIds.orderPage}
              >
                {carouselElements.map(({ dataTestId, item }) => (
                  <CarouselItem
                    key={dataTestId}
                    data-testid={dataTestId}
                    className={classes.carouselItem}
                  >
                    {item}
                  </CarouselItem>
                ))}
              </CarouselBody>
            </Carousel>
          </div>
        )}
      </div>

      <AdvisorNotes
        name={'proposalAdvisorNotes'}
        header={i18n('orderExecution.order.advisorNotes.header')}
        customPlaceholder={i18n(
          'orderExecution.order.advisorNotes.placeholder'
        )}
        infoTooltipText={i18n('orderExecution.order.advisorNotes.infoTooltip')}
        disabled={isSessionReadOnly}
        className={classes.advisorNotes}
      />

      <div
        className={classes.actionButtons}
        style={{
          marginTop: !isAdviceSessionPatched
            ? Spacing.spacing01
            : Spacing.spacing04
        }}
      >
        {!isAdviceSessionPatched && (
          <WarningMessage
            className={classes.warningMessage}
            message={i18n(
              'roboAdvice.proposal.generateAndCompleteAdviceDisabled'
            )}
          />
        )}

        {usePDFv1 && (
          <Button
            className={classes.actionButton}
            disabled={
              !isGenerateOrderEnabled ||
              isReadPdfReportPending ||
              !isAdviceSessionPatched
            }
            onClick={() => setIsGenerateReportPopupOpen(true)}
            isLoading={isReadPdfReportPending}
          >
            {i18n('roboAdvice.orderExecution.generateOrder')}
          </Button>
        )}

        {usePDFv2 && (
          <Button
            className={classes.actionButton}
            disabled={!isGenerateOrderEnabled || isReadNewPdfReportPending}
            onClick={() => setIsGenerateNewPdfReportPopupOpen(true)}
            isLoading={isReadNewPdfReportPending}
          >
            {i18n('roboAdvice.orderExecution.pdfGeneration.button')}
          </Button>
        )}
      </div>

      <GenerateOrderModal />
      <GenerateNewPdfReportModal />
    </>
  );
};

export default Order;
