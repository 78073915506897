import { useLocation, useParams } from 'react-router';

import { useSessionStore } from '../../session/services/sessionStore';
import {
  RoboAdviceClientPages,
  RoboAdviceClientsListPages
} from 'features/roboAdvice/shared/constants';
import Breadcrumbs from 'features/shared/components/breadcrumbs';
import { AdviceSessionParams } from 'features/shared/constants/session';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

type LocationState = {
  clientName: string;
  sessionName: string;
};

const AdviceSessionBreadcrumbs = () => {
  const { clientType, clientId, adviceSessionId } =
    useParams<AdviceSessionParams>();
  const i18n = useI18n();
  const sessionStore = useSessionStore();
  const { state } = useLocation<LocationState>();

  const breadcrumbsItems = [
    {
      title: i18n('roboAdvice.breadcrumbs.advisorSolution'),
      to: routeTemplates.roboAdviceClientsList.build(
        RoboAdviceClientsListPages.allClients
      )
    },
    {
      title: i18n('roboAdvice.breadcrumbs.clients'),
      to: routeTemplates.roboAdviceClientsList.build(
        RoboAdviceClientsListPages.myClients
      )
    },
    {
      title: state?.clientName || sessionStore.clientName,
      to: routeTemplates.roboAdviceClient.build(
        clientType,
        clientId,
        RoboAdviceClientPages.adviceSessions
      )
    },
    {
      title:
        state?.sessionName ||
        sessionStore.clientAdviceSessions?.find(
          ({ id }) => id === adviceSessionId
        )?.name
    }
  ];

  return <Breadcrumbs items={breadcrumbsItems} style={{ paddingTop: 0 }} />;
};

export default AdviceSessionBreadcrumbs;
