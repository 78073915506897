import classNames from 'classnames';
import { useEffect } from 'react';

import { useProposalPreviewPageStyles } from './style';
import useMocks from './useMocks';
import Tabs from 'features/roboAdvice/adviceSession/main/components/tabs/index';
import {
  InformationCard,
  InformationSection
} from 'features/roboAdvice/adviceSession/proposal/components/informationSection';
import Button from 'features/shared/components/button';
import {
  carouselIds,
  useCarouselStore
} from 'features/shared/components/carousel/carouselStore';
import {
  Carousel,
  CarouselBody,
  CarouselItem,
  CarouselTab,
  CarouselTabs
} from 'features/shared/components/carousel/horisontalNavigation';
import Link from 'features/shared/components/link/index.js';
import { MainLayout } from 'features/shared/components/mainLayout/index.js';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { Dropdown } from 'features/sharedModules/finalForm/components/dropdown.js';
import FinalFormNumberInput from 'features/sharedModules/finalForm/components/numberInput';

const AnyDropdown = Dropdown as any;
const AnyFinalFormNumberInput = FinalFormNumberInput as any;
const AnyCarouselItem = CarouselItem as any;
const AnyCarouselTab = CarouselTab as any;

const ProposalPreview = () => {
  const classes = useProposalPreviewPageStyles();
  const i18n = useI18n();
  const { mockTabsProposal, mockAdviceSessionOptions, mockCarouselElements } =
    useMocks();
  const carouselStore = useCarouselStore();

  useEffect(() => {
    carouselStore.setSelectedItemIndex(carouselIds.proposalPreview, 0);
  }, []);

  return (
    <MainLayout location={{ pathname: routeTemplates.roboAdvice.build() }}>
      <div className={classes.header}>
        <div>
          <div className={classes.selectClientSection}>
            <div className={classes.currentClientInfo}>Client: Test Client</div>
            <Link className={classes.clientsListLink} to="/">
              Change client
            </Link>
          </div>
          <div className={classes.selectAdviceSessionSection}>
            <AnyDropdown
              value="advice-session-3"
              onChange={() => {}}
              disabled={false}
              options={mockAdviceSessionOptions}
              className={classes.adviceSessionDropdown}
              label="Advice session"
              labelClassName={classes.adviceSessionDropdownLabel}
            />
          </div>
        </div>
        <div>
          <InformationSection label={''}>
            <div className={classNames(classes.containerRiskScore)}>
              <div
                className={classNames(
                  classes.sectionRiskScore,
                  classes.scoreSectionRiskScore
                )}
              >
                <div className={classes.headerRiskScore}>
                  {i18n('roboAdvice.riskScore.initialRiskScore')}
                </div>
                <div className={classes.valueRiskScore}>8</div>
              </div>
              <div
                className={classNames(
                  classes.sectionRiskScore,
                  classes.scoreSectionRiskScore
                )}
              >
                <div className={classes.headerRiskScore}>
                  {i18n('roboAdvice.riskScore.adjustment')}
                </div>
                <div className={classes.valueRiskScore}>1</div>
              </div>
              <div
                className={classNames(
                  classes.sectionRiskScore,
                  classes.scoreSectionRiskScore
                )}
              >
                <div className={classes.headerRiskScore}>
                  {i18n('roboAdvice.riskScore.finalRiskScore')}
                </div>
                <div className={classes.valueRiskScore}>9</div>
              </div>
            </div>
          </InformationSection>
        </div>
      </div>
      <Tabs
        className={classes.tabs}
        value="proposal"
        onChange={() => {}}
        items={mockTabsProposal}
      />
      <div className={classes.row}>
        <div className={classes.informationColumn}>
          <div className={classes.section}>
            <AnyFinalFormNumberInput
              name={'monthlyDeposit'}
              label={i18n('roboAdvice.savingsPlan.monthlyDeposit')}
              thousandSeparator={' '}
              decimalSeparator={','}
              allowNegative={false}
              decimalScale={0}
              labelClassName={classes.label}
              data-testid={'monthly-deposit-input'}
            />
          </div>
          <div className={classes.section}>
            <AnyFinalFormNumberInput
              name={'firstDeposit'}
              label={i18n('roboAdvice.savingsPlan.firstDeposit')}
              thousandSeparator={' '}
              decimalSeparator={','}
              allowNegative={false}
              decimalScale={0}
              labelClassName={classes.label}
              data-testid={'first-deposit-input'}
            />
          </div>
          <div className={classes.section}>
            <InformationSection label={i18n('roboAdvice.proposal.riskScore')}>
              <div className={classNames(classes.containerRiskScore)}>
                <div
                  className={classNames(
                    classes.sectionRiskScore,
                    classes.scoreSectionRiskScore
                  )}
                >
                  <div className={classes.headerRiskScore}>
                    {i18n('roboAdvice.riskScore.initialRiskScore')}
                  </div>
                  <div className={classes.valueRiskScore}>8</div>
                </div>
                <div
                  className={classNames(
                    classes.sectionRiskScore,
                    classes.scoreSectionRiskScore
                  )}
                >
                  <div className={classes.headerRiskScore}>
                    {i18n('roboAdvice.riskScore.adjustment')}
                  </div>
                  <div className={classes.valueRiskScore}>1</div>
                </div>
                <div
                  className={classNames(
                    classes.sectionRiskScore,
                    classes.scoreSectionRiskScore
                  )}
                >
                  <div className={classes.headerRiskScore}>
                    {i18n('roboAdvice.riskScore.finalRiskScore')}
                  </div>
                  <div className={classes.valueRiskScore}>9</div>
                </div>
              </div>
            </InformationSection>
          </div>
          <div className={classes.section}>
            <InformationSection
              label={i18n('roboAdvice.proposal.keyStatistics')}
            >
              <InformationCard
                isPending={false}
                informationItems={[
                  {
                    label: i18n('roboAdvice.proposal.expectedAnnualReturn'),
                    value: `5.2 %`
                  },
                  {
                    label: i18n('roboAdvice.proposal.expectedVolatility'),
                    value: `13.7 %`
                  },
                  {
                    label: i18n('roboAdvice.proposal.equityPortion'),
                    value: `55 %`
                  }
                ]}
              />
            </InformationSection>
          </div>
          <div className={classes.section}>
            <InformationSection label="">
              <InformationCard
                isPending={false}
                informationItems={[
                  {
                    label: i18n(
                      'roboAdvice.proposal.expectedValueInXYears'
                    ).replace('{0}', '10'),
                    value: `778 071`
                  }
                ]}
              />
            </InformationSection>
          </div>
        </div>
        <div className={classes.chartsColumn}>
          <Carousel
            getIsPending={() => false}
            carouselId={carouselIds.proposalPreview}
          >
            <CarouselTabs carouselId={carouselIds.proposalPreview}>
              {mockCarouselElements.map(({ tab, dataTestId }) => (
                <AnyCarouselTab key={dataTestId}>{i18n(tab)}</AnyCarouselTab>
              ))}
            </CarouselTabs>
            <CarouselBody
              prevNavBattonClassName={
                mockCarouselElements.length === 1 ? classes.hideArrow : null
              }
              nextNavBattonClassName={
                mockCarouselElements.length === 1 ? classes.hideArrow : null
              }
              carouselId={carouselIds.proposalPreview}
            >
              {mockCarouselElements.map(({ dataTestId, item }) => (
                <AnyCarouselItem key={dataTestId} data-testid={dataTestId}>
                  {item}
                </AnyCarouselItem>
              ))}
            </CarouselBody>
          </Carousel>
        </div>
      </div>
      <div className={classes.actionButtons}>
        <Button className={classes.actionButton} onClick={() => {}}>
          {i18n('roboAdvice.proposal.generateProposal')}
        </Button>
      </div>
    </MainLayout>
  );
};

export default ProposalPreview;
