import { call, put } from 'redux-saga/effects';

import { types } from '../actions.js';
import {
  mapServerCustomerConfigToClient,
  mapServerUserToClient
} from '../mapping';
import { setAuth0AccessToken, setPublicKey } from '../storage.js';
import {
  getAuth0PublicKeyFunc,
  getCertFromPublickKey,
  parseAccessToken,
  syncAdvisor,
  verifyAccessToken
} from './shared.js';
import { useCrmLoginStore } from './useCrmLoginStore';
import { validateCustomerConfig } from './validateCustomerConfig';
import config from 'config/index.js';
import {
  getUserPageLanguage,
  setUserPageLanguage
} from 'features/pageLanguage/main/components/usePageLanguage';
import {
  getCustomerConfig,
  getQAuthAccessToken,
  getUserInfo
} from 'features/shared/api/index.js';
import {
  failedCreator,
  startedCreator,
  succeedCreator
} from 'features/shared/utils/actions.js';

export function* login(action) {
  const { loginData } = action.payload;
  yield put(startedCreator(types.LOGIN));

  useCrmLoginStore.getState().setEnableCrmLogin(false);

  try {
    const publicKey = yield call(getAuth0PublicKeyFunc);

    setPublicKey(publicKey);

    const auth0AccessToken = loginData.access_token;
    const expiresAt = loginData.expires_at;

    const cert = getCertFromPublickKey(publicKey, auth0AccessToken);

    const isValid = verifyAccessToken(cert, auth0AccessToken);
    const accessTokenPayload = parseAccessToken(auth0AccessToken);

    // TODO: validation does not work periodically, fix it
    // if (isValid) {
    setAuth0AccessToken(auth0AccessToken, expiresAt * 1000);

    // This call is used to get user info but also to verify license expiration
    const userInfoData = yield call(
      getUserInfo,
      auth0AccessToken,
      accessTokenPayload[config.AUTH0_ID_KEY]
    );

    const user = mapServerUserToClient(accessTokenPayload, userInfoData.data);
    const pageLanguage = getUserPageLanguage();

    const accessToken = yield call(getQAuthAccessToken, auth0AccessToken);
    const customerConfig = yield call(
      getCustomerConfig,
      accessToken,
      user.customerCode,
      pageLanguage
    );

    const customerLanguage = customerConfig?.data?.customer?.language;
    const language =
      pageLanguage !== customerLanguage ? customerLanguage : pageLanguage;
    setUserPageLanguage(language);

    yield syncAdvisor({
      auth0AccessToken,
      user
    });

    const mappedCustomerConfig = mapServerCustomerConfigToClient(
      customerConfig.data
    );

    validateCustomerConfig(mappedCustomerConfig);

    yield put(
      succeedCreator(types.LOGIN, {
        auth0AccessToken,
        user,
        customerConfig: mappedCustomerConfig
      })
    );
  } catch (e) {
    yield put(failedCreator(types.LOGIN, { errorCode: e.code }));
  }
}
