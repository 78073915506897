import React from 'react';
import { call } from 'redux-saga/effects';

import sagas from '../services/sagas.js';
import { createSelectors } from '../services/selectors.js';
import { Context } from './context.js';
import Input from './input.js';
import { useSaga } from 'features/shared/hooks/useSaga.js';

const DetalizedNumberInputRoot = ({
  useForm,
  formApi,
  form,
  name,
  title,
  ...restProps
}) => {
  const settings = React.useMemo(() => {
    const selectors = createSelectors({
      name,
      useForm,
      getFormState: () => formApi.getState()
    });

    return {
      formApi,
      form,
      name,
      title,
      ...selectors
    };
  }, [formApi, form, name, title]);

  useSaga(
    function* () {
      yield call(sagas, settings);
    },
    [settings]
  );

  return (
    <Context.Provider value={settings}>
      <Input {...restProps} />
    </Context.Provider>
  );
};

export default DetalizedNumberInputRoot;
