import classNames from 'classnames';
import React from 'react';

import {
  Typography,
  FontWeights,
  FontFamilies
} from 'features/shared/constants/typography';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const borderRadius = {
  [BorderRadiusTypes.rounded]: '80px',
  [BorderRadiusTypes.sharp]: '5px'
};

const useStyles = createUseStyles(theme => ({
  textInput: {
    width: '230px',
    height: 'calc(4rem + 4px)',
    border: `2px solid ${theme.defaultInputStrokeColor}`,
    borderRadius: borderRadius[theme.borderRadiusType],
    padding: '0.8rem 2.4rem 0.8rem 2.4rem',
    color: theme.primaryColor,
    backgroundColor: theme.defaultInputFillColor,
    fontFamily: FontFamilies.roboto,
    fontWeight: FontWeights.normal,
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,
    outline: 'none',

    '&::-ms-clear': {
      display: 'none'
    },

    '&::placeholder': {
      color: theme.secondaryColor
    },

    '&$invalid': {
      border: `2px solid ${theme.errorNegativeColor}`
    },

    '&:hover, &$hover': {
      border: `2px solid ${theme.hoverInputStrokeColor}`
    },

    '&:focus, &$focus': {
      border: `2px solid ${theme.hoverInputStrokeColor}`,
      backgroundColor: theme.inputFillFocusColor
    },

    [`&:-webkit-autofill`]: {
      border: `2px solid ${theme.defaultInputStrokeColor}`,
      '-webkit-text-fill-color': theme.primaryColor,
      '-webkit-box-shadow': `0 0 0px 1000px ${theme.defaultInputFillColor} inset`,
      transition: `background-color 5000s ease-in-out 0s`
    },

    [`&:-webkit-autofill:focus`]: {
      border: `2px solid ${theme.hoverInputStrokeColor}`,
      '-webkit-text-fill-color': theme.primaryColor,
      '-webkit-box-shadow': `0 0 0px 1000px ${theme.inputFillFocusColor} inset`,
      transition: `background-color 5000s ease-in-out 0s`
    },

    '&:disabled': {
      backgroundColor: theme.defaultInputFillColor,
      color: theme.disabledTextColor,
      '-webkit-text-fill-color': theme.disabledTextColor,
      borderColor: theme.disabledBackgroundColor
    },

    '&:read-only': {
      backgroundColor: theme.defaultInputFillColor,
      color: theme.disabledTextColor,
      '-webkit-text-fill-color': theme.disabledTextColor,
      borderColor: theme.disabledBackgroundColor
    }
  },
  invalid: {},
  hover: {},
  focus: {}
}));

type Props = {
  inputType?: React.HTMLInputTypeAttribute;
  isInvalid?: boolean;
  hover?: boolean;
  focus?: boolean;
  forwardedRef?: React.LegacyRef<HTMLInputElement>;
};

const TextInput = ({
  className,
  forwardedRef,
  isInvalid,
  hover,
  focus,
  inputType,
  ...restProps
}: Props &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >) => {
  const classes = useStyles();

  return (
    <input
      type={inputType || 'text'}
      className={classNames(
        classes.textInput,
        {
          [classes.invalid]: isInvalid,
          [classes.hover]: hover,
          [classes.focus]: focus
        },
        className
      )}
      ref={forwardedRef}
      {...restProps}
    />
  );
};

export default TextInput;
