import { useDispatch } from 'react-redux';

import { creators as applicationActionCreators } from 'features/shared/services/application/actions.js';

const pageLanguageKey = 'page_language';

export const usePageLanguage = () => {
  const dispatch = useDispatch();

  const savePageLanguage = language => {
    setUserPageLanguage(language);
    dispatch(applicationActionCreators.started());
  };

  const loadPageLanguage = () => {
    return getUserPageLanguage();
  };

  return { savePageLanguage, loadPageLanguage };
};

export const setUserPageLanguage = (lang: string) => {
  localStorage.setItem(pageLanguageKey, lang);
};

export const getUserPageLanguage = (): string | null => {
  return localStorage.getItem(pageLanguageKey);
};
