import arrayMutators from 'final-form-arrays';
import setFieldTouched from 'final-form-set-field-touched';
import * as R from 'ramda';

import { createForm } from 'features/sharedModules/finalForm/services/finalForm';
import { createUseFinalFormAdapter } from 'features/sharedModules/zustand/components/finalFormAdapter';

type UserToAdd = { id: string; email?: string };

type UsersToAdd = UserToAdd[];

type ManageCustomerFormValues = {
  customerId?: string;
  customerCode?: string;
  customerAdvisoryKey?: string;
  customerName?: string;
  licenses?: {
    moduleType: string;
    licenseType: string;
    licenseRenewalDate: string;
  }[];
  users?: {
    id: string;
    email: string;
    doesAuth0UserExist: boolean;
    isCustomerAdmin: boolean;
  }[];
  usersToAdd?: UsersToAdd;
};

type Errors = { [key: string]: any };

export const getUsersToAddValidationErrors = (
  usersToAdd: UsersToAdd | undefined
): Errors[] => {
  if (R.isNil(usersToAdd) || R.isEmpty(usersToAdd)) {
    return [];
  }

  return R.addIndex<UserToAdd, Errors>(R.map)(
    (userToAdd, index, collection) => {
      const isLastUserToAdd = index === (collection?.length ?? 0) - 1;

      if (isLastUserToAdd) {
        return {};
      }

      const emailValidationError = getUserToAddEmailValidationError(
        userToAdd.email
      );

      if (R.isNil(emailValidationError)) {
        return {};
      }

      return {
        email: emailValidationError
      };
    },
    usersToAdd
  );
};

const getUserToAddEmailValidationError = (value: string | undefined) => {
  if (R.isNil(value) || R.isEmpty(R.trim(value))) {
    return 'admin.manageCustomer.addUser.emptyValidationError';
  }

  return null;
};

export const manageCustomerForm = createForm<ManageCustomerFormValues>({
  onSubmit: () => {},
  validate: values => {
    const { usersToAdd } = values;

    return {
      usersToAdd: getUsersToAddValidationErrors(usersToAdd)
    };
  },
  mutators: {
    ...arrayMutators,
    setFieldTouched
  }
});

export const useManageCustomerForm = createUseFinalFormAdapter({
  form: manageCustomerForm
});
