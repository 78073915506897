import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { v4 } from 'uuid';

import { useOrderPageStore } from '../../services/orderPageStore';
import { Payload } from './useBuildPayload';
import { useQueuedPatchAdviceSession } from 'features/roboAdvice/adviceSession/main/components/useQueuedPatchAdviceSession';
import { createNewPdfOrderExecutionReport } from 'features/roboAdvice/adviceSession/proposal/api';
import { MissingDocuments } from 'features/roboAdvice/adviceSession/proposal/components/generateReportModal/types';
import { saveFile } from 'features/roboAdvice/adviceSession/proposal/utils';
import { useSessionStore } from 'features/roboAdvice/adviceSession/session/services/sessionStore';
import { SessionPatchTypes } from 'features/roboAdvice/adviceSession/shared/constants';
import { useReadReportDocuments } from 'features/roboAdvice/shared/components/useReadReportDocuments';
import {
  RoboAdviceClientPages,
  SessionStatuses
} from 'features/roboAdvice/shared/constants';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import { AdviceSessionParams } from 'features/shared/constants/session';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

const useGenerateReport = () => {
  const { adviceSessionId, clientId, clientType } =
    useParams<AdviceSessionParams>();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();
  const i18n = useI18n();
  const history = useHistory();
  const orderPageStore = useOrderPageStore();
  const queuedPatchAdviceSession = useQueuedPatchAdviceSession();
  const readReportDocuments = useReadReportDocuments();

  const generateReport = async (payload: Payload | null) => {
    const sessionStoreState = useSessionStore.getState();
    if (!payload) {
      return;
    }

    const cancelTokenSource = axios.CancelToken.source();

    try {
      orderPageStore.setIsReadNewPdfReportPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const response = await createNewPdfOrderExecutionReport(
        accessToken,
        cancelTokenSource.token,
        adviceSessionId,
        payload
      );

      saveFile(
        new Blob([response.data], { type: 'application/pdf; charset=utf-8' }),
        payload?.filename
      );

      const missingDocuments: MissingDocuments =
        response.headers['missing-docs'] &&
        response.headers['missing-docs'] !== 'None'
          ? JSON.parse(response.headers['missing-docs'])
          : [];

      if (missingDocuments.length) {
        missingDocuments.forEach(({ isin, document_name }) =>
          dispatch(
            notificationActionCreators.showNotification({
              message: i18n(
                'roboAdvice.proposal.generateReport.missingDocumentsErrorMessage'
              )
                .replace('{0}', document_name)
                .replace('{1}', isin),
              type: NotificationTypes.error
            })
          )
        );
      }

      await readReportDocuments(adviceSessionId, true);

      if (sessionStoreState.status !== SessionStatuses.complete) {
        await queuedPatchAdviceSession({
          id: v4(),
          type: SessionPatchTypes.completeSession,
          adviceSessionId
        });

        sessionStoreState.completeSession();

        history.push(
          routeTemplates.roboAdviceClient.build(
            clientType,
            clientId,
            RoboAdviceClientPages.adviceSessions
          )
        );
      }

      orderPageStore.setIsReadNewPdfReportPending(false);
    } catch (error) {
      orderPageStore.setIsReadNewPdfReportPending(false);

      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.proposal.generateReport.getPdfReportErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return generateReport;
};

export default useGenerateReport;
