import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useFinancialSituationValues } from '../../../financialSituation/services/selectors';
import { usePortfolioChartStore } from '../../../portfolioChart';
import {
  createTransactionList,
  getInvestorAccountsData
} from '../../../shared/api';
import { PageStatuses } from '../../../shared/components/useReadDataListener';
import { useGoalsStore } from '../../../shared/services/goalsStore';
import {
  Transaction,
  useTransactionListStore
} from '../../../transactionListStore';
import { usePageStore as useProposalPageStore } from '../../services/pageStore';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import {
  AdviceSessionParams,
  ClientTypes
} from 'features/shared/constants/session';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

const useReadTransactionList = () => {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<CancelTokenSource>();
  const dispatch = useDispatch();
  const i18n = useI18n();
  const { clientId } = useParams<AdviceSessionParams>();
  const transactionListStore = useTransactionListStore();
  const {
    roboAdviceForm: {
      financialSituation: {
        accounts: {
          sync: {
            mapping: {
              company: accountsSyncCompanyMapping,
              person: accountsSyncPersonMapping
            }
          }
        }
      }
    }
  } = useCustomerConfig();
  const { clientType } = useParams<AdviceSessionParams>();
  const financialSituationValues = useFinancialSituationValues();

  const readTransactionList = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const { goals } = useGoalsStore.getState();
    const proposalPageStore = useProposalPageStore.getState();
    const portfolioChartStore = usePortfolioChartStore.getState();

    try {
      proposalPageStore.setPageStatus(
        'readTransactionList',
        PageStatuses.pending
      );
      transactionListStore.setIsWarningForUsingMonthlyDepositVisible(false);
      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const { data: investorAccountData } = await getInvestorAccountsData(
        qAuthAccessToken,
        cancelTokenSource.token,
        clientId
      );
      const accountsSyncMapping =
        clientType === ClientTypes.company
          ? accountsSyncCompanyMapping
          : accountsSyncPersonMapping;

      const positionsFieldId = accountsSyncMapping.find(
        map => map.accountValueTarget === 'positions'
      )?.financialSituationFieldId;

      const groupedFinancialSituationPositions = financialSituationValues[
        positionsFieldId ?? ''
      ]?.reduce((acc, field) => {
        if (!field.title || !field.accountNumber) {
          return acc;
        }

        if (
          acc.some(existingPosition => existingPosition.title === field.title)
        ) {
          return acc.map(existingPosition => {
            if (existingPosition.title === field.title) {
              return {
                ...existingPosition,
                toAdvisoryAmount:
                  existingPosition.toAdvisoryAmount +
                  (financialSituationValues.amountForAdviceSwitch
                    ? (field.toAdvisory / 100) * field.value
                    : field.toAdvisory || 0),
                value: existingPosition.value + (field.value || 0)
              };
            }

            return existingPosition;
          });
        }

        return [
          ...acc,
          {
            title: field.title,
            toAdvisoryAmount: financialSituationValues.amountForAdviceSwitch
              ? (field.toAdvisory / 100) * field.value
              : field.toAdvisory || 0,
            value: field.value || 0
          }
        ];
      }, [] as { title: string; toAdvisoryAmount: number; value: number }[]);

      const positionToIsinMap: Map<string, string> = new Map();
      investorAccountData.forEach(account => {
        account.positions.forEach(position => {
          positionToIsinMap.set(position.name, position.isin);
        });
      });
      const existingPortfolio = {
        instruments: groupedFinancialSituationPositions.map(position => ({
          isin: positionToIsinMap.get(position.title),
          name: position.title,
          value: Number(position.value) || 0,
          toAdvisoryPercentage:
            ((position.toAdvisoryAmount || 0) / (position.value || 0)) * 100 ||
            0
        }))
      };

      let goalsData: {
        goalPortfolio: any;
        goalValueOfExistingPortfolio: number;
        oneTimeDeposit: number;
        withdrawal: number;
      }[] = [];

      // Handle edge case when there are only goals without first deposit, follow up add on or internal holding
      // Then we send the monthly deposit as the one time deposit and display warning about that
      if (
        goals.every(
          goal =>
            !goal.data.firstDeposit &&
            !goal.data.followUpAddOn &&
            !goal.data.internalHolding
        )
      ) {
        goalsData = goals.map(goal => {
          const goalPortfolio = portfolioChartStore
            .getChartData(goal.goalId, goal.data.isPortfolioCustom)
            ?.categorySelectionPortfolio.map(({ isin, weight }) => ({
              isin,
              weight
            }));

          return {
            goalPortfolio,
            goalValueOfExistingPortfolio: 0,
            oneTimeDeposit: goal.data.monthlyDeposit || 0,
            withdrawal: 0
          };
        });
        transactionListStore.setIsWarningForUsingMonthlyDepositVisible(true);
      } else {
        goalsData = goals
          .filter(
            goal =>
              goal.data.firstDeposit ||
              goal.data.followUpAddOn ||
              goal.data.internalHolding
          )
          .map(goal => {
            const goalPortfolio = portfolioChartStore
              .getChartData(goal.goalId, goal.data.isPortfolioCustom)
              ?.categorySelectionPortfolio.map(({ isin, weight }) => ({
                isin,
                weight
              }));

            return {
              goalPortfolio,
              goalValueOfExistingPortfolio: goal.data.internalHolding || 0,
              oneTimeDeposit:
                goal.data.firstDeposit || goal.data.followUpAddOn || 0,
              withdrawal: goal.data.followUpWithdrawal || 0
            };
          });
      }

      const allPortfolioExists = goalsData.every(
        ({ goalPortfolio }) => goalPortfolio && goalPortfolio.length > 0
      );
      const payload = {
        goals: goalsData,
        existingPortfolio
      };
      if (allPortfolioExists) {
        const { data: transactionList } = (await createTransactionList(
          qAuthAccessToken,
          cancelTokenSource.token,
          payload
        )) as { data: { data: { transactions: Transaction[] } } };

        transactionListStore.setTransactions(
          transactionList.data.transactions.map(transaction => {
            const portfolioItem = existingPortfolio.instruments.find(
              ({ isin }) => isin === transaction.isin
            );
            return {
              ...transaction,
              existingPortfolioValue: portfolioItem?.value || 0,
              toAdvisory:
                ((portfolioItem?.toAdvisoryPercentage || 0) *
                  (portfolioItem?.value || 0)) /
                100
            };
          })
        );

        proposalPageStore.setPageStatus(
          'readTransactionList',
          PageStatuses.succeed
        );
      } else {
        throwSafeError('Portfolio data is missing');
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        proposalPageStore.setPageStatus(
          'readTransactionList',
          PageStatuses.failed
        );

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.proposal.readDataErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readTransactionList;
};

export default useReadTransactionList;
