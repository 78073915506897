import * as R from 'ramda';
import create from 'zustand';

import {
  PageStatus,
  PageStatuses as PageStatusTypes
} from '../../shared/components/useReadDataListener';

export type PageStatuses = {
  readPortfolioTableData?: PageStatus;
  readPortfolioChartData?: PageStatus;
  readExpectedValuePortfoliosChartData?: PageStatus;
  readExpectedPathPortfoliosChartData?: PageStatus;
  readHistoricalReturn3MChartData?: PageStatus;
  readHistoricalReturnYTDChartData?: PageStatus;
  readHistoricalReturn1YChartData?: PageStatus;
  readHistoricalReturn3YChartData?: PageStatus;
  readHistoricalReturn5YChartData?: PageStatus;
  readHistoricalReturn10YChartData?: PageStatus;
};

export type ProposalPageStoreData = {
  pageStatuses: PageStatuses;
  productConfiguration: number | null;
  portfolioTableData: {
    namespaceId: number | null;
    assetClassGroups: {
      id: string;
      title: string;
    }[];
    assetClasses: {
      id: string;
      category: string;
      title: string;
      assetClassGroupId: string;
      riskClasses: {
        riskClass: number;
        weight: number;
      }[];
      order: number;
    }[];
  } | null;
};

export type ProposalPageStoreApi = {
  reset: () => void;
  initialize: (initialData: Partial<ProposalPageStoreData>) => void;
  setPageStatus: <K extends keyof PageStatuses>(
    key: K,
    value: PageStatuses[K]
  ) => void;
  changePageStatuses: (pageStatuses: Partial<PageStatuses>) => void;
  setProductConfiguration: (productConfiguration: number) => void;
  setPortfolioTableData: (
    riskScoreConfig: ProposalPageStoreData['portfolioTableData']
  ) => void;
};

export type ProposalPageStoreState = ProposalPageStoreData &
  ProposalPageStoreApi;

export const defaultData = {
  pageStatuses: {},
  productConfiguration: null,
  portfolioTableData: null
};

export const usePageStore = create<ProposalPageStoreState>(set => ({
  ...defaultData,
  reset: () => {
    set(defaultData);
  },
  initialize(initialData) {
    set(
      state => ({
        ...state,
        ...initialData
      }),
      true
    );
  },
  setPageStatus(key, value) {
    set(R.set(R.lensPath(['pageStatuses', key]), value), true);
  },
  changePageStatuses(pageStatuses) {
    set(R.over(R.lensProp('pageStatuses'), R.mergeLeft(pageStatuses)), true);
  },
  setProductConfiguration(productConfiguration) {
    set({ productConfiguration });
  },
  setPortfolioTableData(portfolioTableData) {
    set({ portfolioTableData });
  }
}));

export const getIsRiskFinderLoading = () =>
  Object.values(usePageStore.getState().pageStatuses).some(
    status => status === PageStatusTypes.pending
  );
