import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import { useOrderPageStore } from '../../services/orderPageStore';
import useBuildPayload from './useBuildPayload';
import useGenerateReport from './useGenerateReport';
import { useQueuedPatchAdviceSession } from 'features/roboAdvice/adviceSession/main/components/useQueuedPatchAdviceSession';
import GenerateNewPdfReportForm from 'features/roboAdvice/adviceSession/shared/components/generateNewPdfReportModal/form';
import { FormValues } from 'features/roboAdvice/adviceSession/shared/components/generateNewPdfReportModal/form/types';
import { useTemplateStore } from 'features/roboAdvice/adviceSession/shared/components/generateNewPdfReportModal/templateStore';
import useBuildAdvisorInfoPayload from 'features/roboAdvice/adviceSession/shared/components/useBuildAdvisorInfoPayload';
import {
  SessionNamespaces,
  SessionPatchTypes
} from 'features/roboAdvice/adviceSession/shared/constants';
import Button from 'features/shared/components/button';
import Modal from 'features/shared/components/modal';
import { useModalBodyMaxDimensions } from 'features/shared/components/modal/useModalBodyMaxDimensions';
import Scrollbars from 'features/shared/components/scrollbars';
import { AdviceSessionParams } from 'features/shared/constants/session';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

const GenerateNewPdfReportModal = () => {
  const i18n = useI18n();
  const {
    isReadNewPdfReportPending,
    setIsGenerateNewPdfReportPopupOpen,
    isGenerateNewPdfReportPopupOpen
  } = useOrderPageStore();
  const { headerRef, modalBodyMaxHeight, footerRef } =
    useModalBodyMaxDimensions();
  const { template } = useTemplateStore();
  const { reportOrderExecution } = useCustomerConfig();

  const buildPayload = useBuildPayload();
  const buildAdvisorInfoPayload = useBuildAdvisorInfoPayload();
  const generateReport = useGenerateReport();
  const queuedPatchAdviceSession = useQueuedPatchAdviceSession();
  const { adviceSessionId } = useParams<AdviceSessionParams>();

  const handleSubmit = async (formValues: FormValues) => {
    await queuedPatchAdviceSession({
      id: v4(),
      type: SessionPatchTypes.saveFinishedSessionData,
      adviceSessionId,
      payload: buildAdvisorInfoPayload(),
      namespace: SessionNamespaces.finishedSessionAdvisorInfo
    });

    setIsGenerateNewPdfReportPopupOpen(false);
    const requestPayload = buildPayload(formValues);
    await generateReport(requestPayload);
  };

  return (
    <Modal
      header={i18n('roboAdvice.proposal.generateProposal')}
      headerRef={headerRef}
      isOpen={isGenerateNewPdfReportPopupOpen}
      onRequestClose={() => {
        setIsGenerateNewPdfReportPopupOpen(false);
      }}
      disabled={isReadNewPdfReportPending}
      footer={
        <Button disabled={!Boolean(template)} form="generate-new-pdf-form">
          {i18n('roboAdvice.proposal.generateReport.generateReport')}
        </Button>
      }
      footerRef={footerRef}
    >
      <Scrollbars autoHeightMax={modalBodyMaxHeight}>
        <GenerateNewPdfReportForm
          header={i18n(
            'roboAdvice.orderExecution.pdfGeneration.modalTemplateSelection'
          )}
          sectionsHeader={i18n(
            'roboAdvice.orderExecution.pdfGeneration.modalSectionSelection'
          )}
          attachmentsHeader={i18n(
            'roboAdvice.orderExecution.pdfGeneration.modalAttachmentSelection'
          )}
          templates={reportOrderExecution}
          onSubmit={handleSubmit}
        />
      </Scrollbars>
    </Modal>
  );
};

export default GenerateNewPdfReportModal;
