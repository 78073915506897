import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { usePageStore as useProposalPageStore } from '../../services/pageStore';
import { useReadCashflowData } from '../cashflowChart/useReadCashflowData';
import { useCashflowChartStore } from 'features/roboAdvice/adviceSession/cashflowChart';
import {
  useReadDataListener,
  PageStatuses
} from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import {
  useGoalsStore,
  selectors
} from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import CashflowChartVisual from 'features/shared/analyticsComponents/cashflowChart/visual';
import sessionSelectors from 'features/shared/services/session/selectors';

const CashflowChart = ({ isSelected }) => {
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const selectedGoalsIds = useGoalsStore(selectors.getSelectedGoalsIds);
  const {
    goalsData,
    cashflowGoalChartsData,
    cashflowForecastChartData,
    setCashflowGoalChartsData,
    setCashflowForecastChartData,
    setTableData,
    tableData
  } = useCashflowChartStore();
  const {
    pageStatuses: { readCashflowData }
  } = useProposalPageStore.getState();

  useReadDataListener(readCashflowData, isSelected, useReadCashflowData());

  useEffect(() => {
    setCashflowGoalChartsData(selectedGoalsIds);
    setCashflowForecastChartData(selectedGoalsIds);
    setTableData(selectedGoalsIds);
  }, [goalsData, selectedGoalsIds]);

  return (
    <CashflowChartVisual
      cashflowGoalChartsData={cashflowGoalChartsData}
      cashflowForecastChartData={cashflowForecastChartData}
      cashflowTableData={tableData}
      cultureCode={cultureCode}
      isLoading={readCashflowData === PageStatuses.pending}
      isError={readCashflowData === PageStatuses.failed}
    />
  );
};

export default CashflowChart;
