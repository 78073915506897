import * as R from 'ramda';

import { types } from './actions.js';

const createReducer = ({ initialState, plugins = {} } = {}) => {
  const pluginKeys = R.keys(plugins);

  return (state = initialState, action) => {
    state = R.reduce(
      (acc, pluginKey) =>
        R.over(
          R.lensProp(pluginKey),
          tableState => plugins[pluginKey](tableState, action),
          acc
        ),
      state,
      pluginKeys
    );

    switch (action.type) {
      case types.CHANGE_ROW_SELECTION: {
        const { tableId, rowId, isSelected } = action.payload;

        return R.over(
          R.lensProp(tableId),
          table => {
            let { selectedRowIds } = table;

            if (isSelected) {
              const rowIndex = R.indexOf(rowId, selectedRowIds);
              selectedRowIds =
                rowIndex !== -1
                  ? selectedRowIds
                  : R.append(rowId, selectedRowIds);
            } else {
              selectedRowIds = R.reject(
                selectedRowId => selectedRowId === rowId,
                selectedRowIds
              );
            }

            return { ...table, selectedRowIds };
          },
          state
        );
      }
      case types.CHANGE_ALL_ROWS_SELECTION: {
        const { tableId, allRowIds, isSelected } = action.payload;

        return R.over(
          R.lensProp(tableId),
          table => {
            return { ...table, selectedRowIds: isSelected ? allRowIds : [] };
          },
          state
        );
      }
      case types.CHANGE_ORDER: {
        const { tableId, orderKey, orderDirection } = action.payload;

        return R.over(
          R.lensProp(tableId),
          table => ({ ...table, orderKey, orderDirection, pageNumber: 0 }),
          state
        );
      }
      case types.CHANGE_PAGE_SIZE: {
        const { tableId, pageSize } = action.payload;

        return R.over(
          R.lensProp(tableId),
          table => ({ ...table, pageSize, pageNumber: 0 }),
          state
        );
      }
      case types.CHANGE_PAGE_NUMBER: {
        const { tableId, pageNumber } = action.payload;

        return R.over(
          R.lensProp(tableId),
          table => ({ ...table, pageNumber }),
          state
        );
      }
      default:
        return state;
    }
  };
};

export default createReducer;
