import { createForm } from 'features/sharedModules/finalForm/services/finalForm';
import { createUseFinalFormAdapter } from 'features/sharedModules/zustand/components/finalFormAdapter';

export type GenerateOrderFormValues = {
  analyticsComponents: Record<string, boolean>;
  attachments: Record<string, boolean>;
  includeReport: boolean;
  morningstarAttachments: boolean[];
  productAttachments: boolean;
};

export const generateOrderForm = createForm<GenerateOrderFormValues>({
  onSubmit: () => {}
});

export const useGenerateOrderForm = createUseFinalFormAdapter({
  form: generateOrderForm
});
