import { isNil, path, split } from 'ramda';
import { useParams } from 'react-router';

import { useForm as useRoboAdviceForm } from '../../../roboAdvice/adviceSession/form/services/form';
import { AdviceSessionParams } from '../../../shared/constants/session';
import { getTranslation } from '../../../shared/utils/translations';
import { useCustomerConfig } from '../../customerConfig/components/useCustomerConfig';
import { useI18n } from '../../customerConfig/components/useI18n';
import { ErrorMessage, OrderInformationPage2Errors } from '../types';

export const useGetOrderInformationPage2Errors =
  (): OrderInformationPage2Errors => {
    const i18n = useI18n();
    const roboAdviceFormState = useRoboAdviceForm();
    const {
      roboAdviceForm: { errorMessagesEnabled },
      roboOrderFlow: { orderInformationPage2 }
    } = useCustomerConfig();
    const { clientType } = useParams<AdviceSessionParams>();

    if (!orderInformationPage2[clientType]?.enabled) {
      return {};
    }

    return {
      ...getFieldsErrors({
        roboAdviceFormState,
        sections: orderInformationPage2[clientType]?.sections,
        i18n,
        errorMessagesEnabled
      }),
      ...getSectionsErrors({
        roboAdviceFormState,
        sections: orderInformationPage2[clientType]?.sections,
        i18n,
        errorMessagesEnabled
      }),
      advisorNotes: getAdvisorNotesErrors({
        roboAdviceFormState,
        advisorNotesConfig: orderInformationPage2[clientType]?.advisorNotes,
        i18n,
        errorMessagesEnabled
      })
    };
  };

const getFieldsErrors = ({
  roboAdviceFormState,
  sections,
  i18n,
  errorMessagesEnabled
}): { [key: string]: ErrorMessage[] } => {
  const fieldsErrors: { [key: string]: ErrorMessage[] } = {};

  const requiredFields = sections
    .filter(({ isEnabled }) => isEnabled)
    .map(({ items }) => items)
    .flat()
    .filter(({ required }) => required);

  const textFields = sections
    .map(({ items }) => items)
    .flat()
    .map(field =>
      field.items
        ? field.items.filter(a => {
            const value = path(
              field.name.split('.'),
              roboAdviceFormState.values
            );
            return (
              (Array.isArray(value)
                ? value.includes(a.activeValue)
                : a.activeValue === value) &&
              a.textField &&
              a.textField?.required
            );
          })
        : []
    )
    .filter(f => f.length > 0);

  if (errorMessagesEnabled) {
    [...requiredFields, ...textFields].forEach(field => {
      if (Array.isArray(field)) {
        field.forEach(item => {
          if (
            !isNil(
              path(split('.', item.textField?.name), roboAdviceFormState.errors)
            )
          ) {
            fieldsErrors[item.textField?.name] = [
              {
                alertMessage: i18n('roboAdvice.errors.missingAnswerAlert'),
                listMessage: i18n('roboAdvice.errors.missingAnswer').replace(
                  '{0}',
                  getTranslation(item.label)
                ),
                elementId: item.textField?.name
              }
            ];
          }
        });
      } else if (
        !isNil(path(split('.', field?.name), roboAdviceFormState.errors))
      ) {
        fieldsErrors[field.name] = [
          {
            alertMessage: i18n('roboAdvice.errors.missingAnswerAlert'),
            listMessage: i18n('roboAdvice.errors.missingAnswer').replace(
              '{0}',
              getTranslation(field.label)
            ),
            elementId: field.name
          }
        ];
      }
    });
  }
  return fieldsErrors;
};

const getSectionsErrors = ({
  roboAdviceFormState,
  sections,
  i18n,
  errorMessagesEnabled
}) => {
  const sectionsErrors: { [key: string]: ErrorMessage[] } = {};

  const requiredSections = sections.filter(
    ({ isEnabled, required }) => isEnabled && required
  );

  if (errorMessagesEnabled) {
    requiredSections.forEach(section => {
      const sectionName = section.name;
      const sectionHasNoValue = !section.items.some(item => {
        const value = path(split('.', item?.name), roboAdviceFormState.values);
        return !isNil(value) && value !== '';
      });

      if (sectionHasNoValue) {
        sectionsErrors[sectionName] = [
          {
            alertMessage: i18n('roboAdvice.errors.missingFieldAlert').replace(
              '{0}',
              getTranslation(section.label)
            ),
            listMessage: i18n('roboAdvice.errors.missingField').replace(
              '{0}',
              getTranslation(section.label)
            ),
            elementId: sectionName
          }
        ];
      }
    });
  }

  return sectionsErrors;
};

const getAdvisorNotesErrors = ({
  roboAdviceFormState,
  advisorNotesConfig,
  i18n,
  errorMessagesEnabled
}) => {
  if (
    !errorMessagesEnabled ||
    !advisorNotesConfig?.enabled ||
    !advisorNotesConfig?.required
  ) {
    return [];
  } else if (
    !roboAdviceFormState.values.orderInformationPage2AdvisorNotes ||
    roboAdviceFormState.values.orderInformationPage2AdvisorNotes === ''
  ) {
    return [
      {
        alertMessage: i18n('roboAdvice.errors.missingAdvisorNotesAlert'),
        listMessage: i18n('roboAdvice.errors.missingAdvisorNotes').replace(
          '{0}',
          i18n('orderExecution.orderInformationPage2.advisorNotes.header')
        ),
        elementId: 'orderInformationPage2AdvisorNotes'
      }
    ];
  }
  return [];
};
