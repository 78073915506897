import * as R from 'ramda';
import { useParams } from 'react-router';

import { getTotalValueResult } from '../../detalizedNumberInput/services/selectors';
import { useForm as useRoboAdviceForm } from '../../form/services/form';
import {
  HoldingsSource,
  Item
} from '../components/amountForAdvice/granularInformationModal';
import { Detail } from './mapping.js';
import {
  AdviceSessionParams,
  ClientTypes
} from 'features/shared/constants/session';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';

export const useFinancialSituationValues = () => {
  const { clientType } = useParams<AdviceSessionParams>();
  const { values } = useRoboAdviceForm();

  return clientType === ClientTypes.person
    ? values.personFinancialSituation
    : values.companyFinancialSituation;
};

export const useAmountForAdviceAssetsWithValue = () => {
  const { clientType } = useParams<AdviceSessionParams>();
  const { values } = useRoboAdviceForm();
  const {
    roboAdviceForm: {
      financialSituation: {
        assets: { company: companyAssets, person: personAssets }
      }
    }
  } = useCustomerConfig();

  return (
    clientType === ClientTypes.person ? personAssets : companyAssets
  ).filter(({ enabled, id, showInAmountForAdvice }) =>
    clientType === ClientTypes.person
      ? showInAmountForAdvice &&
        enabled &&
        values.personFinancialSituation?.[id]?.some(
          field => !R.isNil(field?.value)
        )
      : showInAmountForAdvice &&
        enabled &&
        values.companyFinancialSituation?.[id]?.some(
          field => !R.isNil(field?.value)
        )
  );
};

export const useAmountForAdviceValues = () => {
  const amountForAdviceAssetsWithValue = useAmountForAdviceAssetsWithValue();
  const financialSituationValues = useFinancialSituationValues();

  const internalHoldings = amountForAdviceAssetsWithValue.map(
    ({ id, label, priority }) => ({
      id,
      label,
      amount: financialSituationValues?.[id]
        ?.filter(({ accountNumber }) => !R.isNil(accountNumber))
        ?.reduce((acc, curr) => acc + (curr.value || 0), 0),
      source: HoldingsSource.internal,
      priority
    })
  );
  const externalHoldings = amountForAdviceAssetsWithValue.map(
    ({ id, label, priority }) => ({
      id: id,
      label,
      amount: financialSituationValues?.[id]
        ?.filter(({ accountNumber }) => R.isNil(accountNumber))
        ?.reduce((acc, curr) => acc + (curr.value || 0), 0),
      source: HoldingsSource.external,
      priority
    })
  );
  const data = [...internalHoldings, ...externalHoldings].filter(
    ({ amount }) => amount > 0
  );
  data.sort((a, b) => a.priority - b.priority);

  const getTypeCurrentAmount = (item: Item) => {
    const filteredValues = financialSituationValues?.[item.id]?.filter(
      ({ accountNumber }) =>
        item.source === HoldingsSource.internal
          ? !R.isNil(accountNumber)
          : R.isNil(accountNumber)
    );
    return filteredValues.reduce(
      financialSituationValues.amountForAdviceSwitch
        ? (acc, curr) =>
            acc + ((curr.toAdvisory || 0) / 100) * (curr.value || 0)
        : (acc, curr) => acc + (curr.toAdvisory || 0),
      0
    );
  };

  const originalSum = data.reduce((acc, curr) => acc + curr.amount, 0);

  const currentSum = data.reduce(
    (acc, curr) => acc + getTypeCurrentAmount(curr),
    0
  );

  return {
    data,
    originalSum,
    currentSum
  };
};

export const usePersonAssetsTotal = ({
  useRoboAdviceForm,
  personAssetsFields = []
}) => {
  const roboAdviceForm = useRoboAdviceForm();

  return getPersonAssetsTotalResult({ roboAdviceForm, personAssetsFields });
};

export const getPersonAssetsTotalResult = ({
  roboAdviceForm,
  personAssetsFields = []
}) => {
  return getDetalizedNumberInputsTotalResult({
    fields: R.map(f => f[0], personAssetsFields),
    formState: roboAdviceForm
  });
};

export const usePersonDebtTotal = ({
  useRoboAdviceForm,
  personDebtFields = []
}) => {
  const roboAdviceForm = useRoboAdviceForm();

  return getPersonDebtTotalResult({ roboAdviceForm, personDebtFields });
};

export const getPersonDebtTotalResult = ({
  roboAdviceForm,
  personDebtFields = []
}) =>
  getDetalizedNumberInputsTotalResult({
    fields: R.map(f => f[0], personDebtFields),
    formState: roboAdviceForm
  });

export const useCompanyInvestableAssetsTotal = ({
  useRoboAdviceForm,
  companyAssetsFields = []
}) => {
  const roboAdviceForm = useRoboAdviceForm();

  return getCompanyInvestableAssetsTotalResult({
    roboAdviceForm,
    companyAssetsFields
  });
};

export const getCompanyInvestableAssetsTotalResult = ({
  roboAdviceForm,
  companyAssetsFields = []
}) =>
  getDetalizedNumberInputsTotalResult({
    fields: R.map(f => f[0], companyAssetsFields),
    formState: roboAdviceForm
  });

export const useCompanyDebtTotal = ({
  useRoboAdviceForm,
  companyDebtFields = []
}) => {
  const roboAdviceForm = useRoboAdviceForm();

  return getCompanyDebtTotalResult({ roboAdviceForm, companyDebtFields });
};

export const getCompanyDebtTotalResult = ({
  roboAdviceForm,
  companyDebtFields = []
}) => {
  return getDetalizedNumberInputsTotalResult({
    fields: R.map(f => f[0], companyDebtFields),
    formState: roboAdviceForm
  });
};

export const getDetalizedNumberInputsTotalResult = ({ fields, formState }) => {
  const { values } = formState;
  const validFields = fields.filter(f => {
    const details = R.pathOr([] as Detail[], R.split('.', f), values);
    return details.some(d => !R.isNil(d?.value));
  });

  if (R.isEmpty(validFields)) {
    return null;
  }

  return validFields
    .map(f => getTotalValueResult({ name: f, formState }))
    .reduce((prev, val) => prev + val, 0);
};

export const getSavingsPlan = ({ useRoboAdviceForm }) => {
  const roboAdviceForm = useRoboAdviceForm.getState();

  const clientType = R.path(
    ['clientInformation', 'clientType'],
    roboAdviceForm.values
  );

  if (clientType === ClientTypes.person) {
    const monthlySurplus: number | undefined = R.path(
      ['personFinancialSituation', 'monthlySurplus'],
      roboAdviceForm.values
    );
    const liquidAssets = getLiquidAssets({ roboAdviceForm });

    return {
      monthlyDeposit: R.isNil(monthlySurplus)
        ? null
        : Math.max(0, monthlySurplus),
      firstDeposit: R.isNil(liquidAssets) ? null : Math.max(0, liquidAssets)
    };
  }

  if (clientType === ClientTypes.company) {
    const requiredWorkingCapital: number | undefined = R.path(
      ['companyFinancialSituation', 'requiredWorkingCapital'],
      roboAdviceForm.values
    );
    const cash = getTotalValueResult({
      name: 'companyFinancialSituation.cash',
      formState: roboAdviceForm
    });

    return {
      monthlyDeposit: 0,
      firstDeposit:
        R.isNil(requiredWorkingCapital) || R.isNil(cash)
          ? null
          : Math.max(0, cash - requiredWorkingCapital)
    };
  }

  return {};
};

const getLiquidAssets = ({ roboAdviceForm }) => {
  const otherDebt = getTotalValueResult({
    name: 'personFinancialSituation.otherDebt',
    formState: roboAdviceForm
  });
  const cash = getTotalValueResult({
    name: 'personFinancialSituation.cash',
    formState: roboAdviceForm
  });

  return R.isNil(cash) || R.isNil(otherDebt) ? null : cash - otherDebt;
};
