import * as R from 'ramda';
import React from 'react';

import { useSessionStore } from '../../session/services/sessionStore';
import { useReadRiskScoreSettings } from './useReadRiskScoreSettings';

export function useReadRiskScoreSettingsListener() {
  const readRiskScoreSettings = useReadRiskScoreSettings();

  React.useEffect(() => {
    const sessionStore = useSessionStore.getState();
    if (R.isNil(sessionStore.sessionStatuses.readRiskScoreSettings)) {
      readRiskScoreSettings();
    }
  }, []);
}
