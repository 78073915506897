import React from 'react';
import DatePicker from 'react-datepicker';

import { getYearsRange, dateFormats } from './constants';
import { useStyles } from './styles';

const YearDatePicker = ({
  selected,
  onChange,
  placeholder,
  dateType,
  inputClassName,
  allowedYearsRange,
  minDate,
  maxDate
}) => {
  const styles = useStyles();

  return (
    <DatePicker
      selected={selected}
      calendarClassName={styles.calendar}
      onChange={date => onChange(date)}
      dateFormat={dateFormats.years}
      placeholderText={placeholder}
      showYearPicker
      minDate={
        minDate
          ? minDate
          : new Date(
              getYearsRange(
                dateType,
                allowedYearsRange?.yearsBack,
                allowedYearsRange?.yearsForward
              )[0],
              0,
              1
            )
      }
      maxDate={
        maxDate
          ? maxDate
          : new Date(
              getYearsRange(
                dateType,
                allowedYearsRange?.yearsBack,
                allowedYearsRange?.yearsForward
              ).slice(-1)[0],
              0,
              1
            )
      }
      customInput={
        <input
          className={inputClassName}
          onChange={onChange}
          value={selected}
        />
      }
      showDisabledMonthNavigation
    />
  );
};

export default YearDatePicker;
