import * as React from 'react';

import Button from 'features/shared/components/button';
import Icon from 'features/shared/components/icon/index.js';
import { Spacing } from 'features/shared/constants/spacing';
import { defaultConfirmation } from 'features/sharedInstances/defaultConfirmation';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-20px',
    marginBottom: '20px'
  },
  button: {
    '& + &': {
      marginLeft: Spacing.spacing01
    }
  }
});

export const Page = ({ children }) => children;

export const ActionButtons = ({
  selectedRowsNumber,
  isRestorePending,
  isDeletePending,
  restoreConfirmationMessage,
  deleteConfirmationMessage,
  restoreButtonOnClick,
  deleteButtonOnClick
}: {
  selectedRowsNumber: number;
  isRestorePending: boolean;
  isDeletePending: boolean;
  restoreConfirmationMessage: React.ReactNode;
  deleteConfirmationMessage: React.ReactNode;
  restoreButtonOnClick: () => void;
  deleteButtonOnClick: () => void;
}) => {
  const classes = useStyles();
  const i18n = useI18n();

  return (
    <div className={classes.buttonsContainer}>
      <Button
        disabled={isRestorePending || isDeletePending}
        className={classes.button}
        style={{ visibility: selectedRowsNumber > 0 ? 'visible' : 'hidden' }}
        onClick={() => {
          const defaultConfirmationStore =
            defaultConfirmation.useConfirmationStore.getState();

          defaultConfirmationStore.open(restoreConfirmationMessage, () => {
            restoreButtonOnClick();
          });
        }}
        customIcon={<Icon type="cached" style={{ fontSize: 'inherit' }} />}
      >
        {i18n('admin.trashCan.actionButtons.restore')}{' '}
        {`(${selectedRowsNumber})`}
      </Button>
      <Button
        disabled={isRestorePending || isDeletePending}
        className={classes.button}
        style={{ visibility: selectedRowsNumber > 0 ? 'visible' : 'hidden' }}
        onClick={() => {
          const defaultConfirmationStore =
            defaultConfirmation.useConfirmationStore.getState();
          defaultConfirmationStore.open(deleteConfirmationMessage, () => {
            deleteButtonOnClick();
          });
        }}
        customIcon={<Icon type="delete" style={{ fontSize: 'inherit' }} />}
      >
        {i18n('admin.trashCan.actionButtons.delete')}{' '}
        {`(${selectedRowsNumber})`}
      </Button>
    </div>
  );
};
