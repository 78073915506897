import * as R from 'ramda';
import {
  reducer as reduxFormReducer,
  arrayPush,
  arrayRemoveAll,
  change
} from 'redux-form';

import { types } from './actions.js';

const extendReducer = reducer => {
  return (state, action) => {
    switch (action.type) {
      case types.ARRAY_CONCAT: {
        const { formId, fieldId, items } = action.payload;

        return R.reduce(
          (acc, item) => reducer(acc, arrayPush(formId, fieldId, item)),
          state,
          items
        );
      }
      case types.ARRAY_CHANGE: {
        const { formId, fieldId, items } = action.payload;

        return R.pipe(
          state => reducer(state, arrayRemoveAll(formId, fieldId)),
          state =>
            R.reduce(
              (acc, item) => reducer(acc, arrayPush(formId, fieldId, item)),
              state,
              items
            )
        )(state);
      }
      case types.MERGE: {
        const { formId, values } = action.payload;

        return R.reduce(
          (acc, key) => reducer(acc, change(formId, key, values[key])),
          state,
          R.keys(values)
        );
      }
      default:
        return reducer(state, action);
    }
  };
};

export default (plugins, syncPlugins) =>
  R.pipe(
    extendReducer
    // syncReducer(syncPlugins)
  )(reduxFormReducer.plugin(plugins));
