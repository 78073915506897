import classNames from 'classnames';

import TableComponent from 'features/shared/components/table/table.js';
import TableBody from 'features/shared/components/table/tableBody.js';
import TableBodyRow from 'features/shared/components/table/tableBodyRow.js';
import { Typography } from 'features/shared/constants/typography';
import { formatNumber } from 'features/shared/utils/number';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  headerCell: {
    padding: '7px 20px',
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight
  },
  bodyCell: {
    padding: '7px 20px'
  },
  yearCell: {
    textAlign: 'left'
  },
  accumulatedDepositsCell: {
    textAlign: 'right'
  },
  accumulatedReturnsCell: {
    textAlign: 'right'
  },
  totalValueCell: {
    textAlign: 'right'
  }
});

const Table = ({ expectedValueTableData, cultureCode }) => {
  const classes = useStyles();
  const i18n = useI18n();

  return (
    <div className={classes.root}>
      <TableComponent
        header={[
          {
            title: i18n('roboAdvice.proposal.year'),
            className: classNames(classes.headerCell, classes.yearCell)
          },
          {
            title: i18n('roboAdvice.proposal.accumulatedDeposits'),
            className: classNames(
              classes.headerCell,
              classes.accumulatedDepositsCell
            )
          },
          {
            title: i18n('roboAdvice.proposal.accumulatedReturns'),
            className: classNames(
              classes.headerCell,
              classes.accumulatedReturnsCell
            )
          },
          {
            title: i18n('roboAdvice.proposal.totalValue'),
            className: classNames(classes.headerCell, classes.totalValueCell)
          }
        ]}
      >
        <TableBody>
          {expectedValueTableData.map(i => (
            <TableBodyRow
              key={i.year}
              className={classes.bodyRow}
              isCssParity={true}
            >
              {({ bodyRowCellClassName }) => (
                <>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.bodyCell,
                      classes.yearCell
                    )}
                  >
                    {i.year}
                  </td>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.bodyCell,
                      classes.accumulatedDepositsCell
                    )}
                  >
                    {formatNumber(cultureCode, i.accDeposit, 0, 0)}
                  </td>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.bodyCell,
                      classes.accumulatedReturnsCell
                    )}
                  >
                    {formatNumber(cultureCode, i.accReturn, 0, 0)}
                  </td>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.bodyCell,
                      classes.totalValueCell
                    )}
                  >
                    {formatNumber(cultureCode, i.value, 0, 0)}
                  </td>
                </>
              )}
            </TableBodyRow>
          ))}
        </TableBody>
      </TableComponent>
    </div>
  );
};

export default Table;
