import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { usePreMeetingSurveyStore } from 'features/roboAdvice/client/adviceSessionsList/services/preMeetingSurveyStore';
import { getSurveyFlows } from 'features/roboAdvice/client/main/api';
import { FlowType } from 'features/roboAdvice/client/main/types';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export const useReadSurveyFlows = () => {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();
  const i18n = useI18n();
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  const readSurveyFlows = async (flowType: FlowType) => {
    const { surveyFlowsStatus, setSurveyFlowData, setSurveyFlowsStatus } =
      usePreMeetingSurveyStore.getState();

    if (surveyFlowsStatus[flowType] !== PageStatuses.ready) {
      return;
    }

    setSurveyFlowsStatus(flowType, PageStatuses.pending);

    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    try {
      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const { data } = await getSurveyFlows(
        accessToken,
        cancelTokenSource.token,
        flowType
      );

      setSurveyFlowData(flowType, data.data);
      setSurveyFlowsStatus(flowType, PageStatuses.succeed);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setSurveyFlowsStatus(flowType, PageStatuses.failed);
        dispatch(
          notificationActionCreators.showNotification({
            type: NotificationTypes.error,
            message: i18n('roboAdvice.client.readSurveyFlowsErrorMessage')
          })
        );
      }
    }
  };

  return readSurveyFlows;
};
