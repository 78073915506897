import classNames from 'classnames';
import * as R from 'ramda';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { useSessionStore } from '../../session/services/sessionStore';
import { useGoalsStore } from '../../shared/services/goalsStore';
import { getRiskClasses, useRiskScoreItems } from '../services/selectors.js';
import Legend from './legend';
import NumberCell from './numberCell.js';
import { useReadRiskScoreSettingsListener } from './useReadRiskScoreSettingsListener';
import { useTableSelectionWidthAligner } from './useTableSelectionWidthAligner.js';
import { getSubAssetClassTranslation } from 'features/roboAdvice/adviceSession/shared/mapping';
import Table from 'features/shared/components/table/index.js';
import { Colors } from 'features/shared/constants/colors.js';
import { Typography } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const indexedChain = R.addIndex(R.chain);
const indexedMap = R.addIndex(R.map);

const useStyles = createUseStyles({
  rowsGroupCell: {
    backgroundColor: 'transparent !important',
    paddingLeft: '0 !important'
  },
  firstVisibleColumnCell: {
    paddingLeft: '3rem',
    '&:first-child': {
      paddingLeft: '3rem'
    }
  },
  rowsGroupTitle: {
    transform: 'rotate(-90deg)',
    display: 'flex',
    justifyContent: 'center',
    width: '2.4rem',
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight,
    color: Colors.gray50
  },
  numberHeaderCell: {
    textAlign: 'right',
    padding: '1.6rem 2.1rem'
  },
  extraHeaderCell: {
    textAlign: 'center',
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight,
    paddingBottom: '0'
  },
  numberExtraHeaderCell: {
    padding: '1.6rem 2.1rem 0 2.1rem'
  },
  firstEquityCell: {
    borderTop: `2px solid ${Colors.gray50}`
  },
  noDataInfo: {
    color: Colors.gray50,
    textAlign: 'center',
    minWidth: '280px'
  }
});

export const RiskDetailsPopover = ({ scheduleUpdate }) => {
  const classes = useStyles();
  const i18n = useI18n();

  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const riskClasses = useSelector(getRiskClasses);
  const groups = useRiskScoreItems();
  const isDataEmpty = R.isEmpty(groups);
  const sessionStore = useSessionStore();
  const { goals } = useGoalsStore();
  const {
    roboAdvice: { subAssetClassNameMapping = {} }
  } = useCustomerConfig();

  React.useLayoutEffect(() => {
    scheduleUpdate();
  }, [sessionStore.sessionStatuses.readRiskScoreSettings]);

  useReadRiskScoreSettingsListener();

  const riskDetailsTableRef = useTableSelectionWidthAligner();

  return (
    <div data-testid={'risk-details-popover'}>
      {isDataEmpty && (
        <div className={classes.noDataInfo}>
          {i18n('roboAdvice.riskScore.noRiskScoreData')}
        </div>
      )}
      {!isDataEmpty && (
        <Table
          forwardedTableRef={riskDetailsTableRef}
          data-testid={`risk-details-table`}
          header={[
            { title: '', className: classes.rowsGroupCell },
            {
              title: i18n('roboAdvice.riskScore.assetClasses'),
              className: classes.firstVisibleColumnCell
            },
            ...R.map(
              h => ({ title: h, className: classes.numberHeaderCell }),
              riskClasses
            )
          ]}
          extraHeader={[
            {
              title: '',
              className: classNames(
                classes.rowsGroupCell,
                classes.extraHeaderCell
              )
            },
            {
              title: '',
              className: classNames(
                classes.firstVisibleColumnCell,
                classes.extraHeaderCell
              )
            },
            {
              title: i18n('roboAdvice.riskScore.riskClasses'),
              className: classNames(
                classes.extraHeaderCell,
                classes.numberExtraHeaderCell
              ),
              colSpan: R.length(riskClasses)
            }
          ]}
          items={indexedChain((g, gIndex) => {
            const isFirstGroup = gIndex === 0;

            return indexedMap(
              (c, cIndex) => ({
                id: `${c.id}_${cIndex}`,
                cells: ({ bodyRowCellClassName }) => {
                  const isFirstRow = cIndex === 0;
                  const isSeparatorRequired = !isFirstGroup && isFirstRow;

                  return (
                    <Fragment>
                      {isFirstRow && (
                        <td
                          rowSpan={R.length(g.assetClasses)}
                          className={classNames(
                            bodyRowCellClassName,
                            classes.rowsGroupCell
                          )}
                        >
                          <div className={classes.rowsGroupTitle}>
                            {i18n(`shared.${g.title.toLowerCase()}`) || g.title}
                          </div>
                        </td>
                      )}
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.firstVisibleColumnCell,
                          { [classes.firstEquityCell]: isSeparatorRequired }
                        )}
                      >
                        {getSubAssetClassTranslation(
                          c.category,
                          subAssetClassNameMapping
                        ) || c.title}
                      </td>
                      {indexedMap(
                        (v, vIndex) => (
                          <NumberCell
                            key={vIndex}
                            value={v}
                            suffix={'%'}
                            cultureCode={cultureCode}
                            backgroundStylesCell
                            className={classNames(bodyRowCellClassName, {
                              [classes.firstEquityCell]: isSeparatorRequired
                            })}
                          />
                        ),
                        c.values
                      )}
                    </Fragment>
                  );
                }
              }),
              g.assetClasses
            );
          }, groups)}
        />
      )}
      {goals.length && <Legend />}
    </div>
  );
};

export default RiskDetailsPopover;
