import create from 'zustand';

import {
  PageStatus,
  PageStatuses
} from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';

export type ClientPageStoreData = {
  readClientInformationStatus: PageStatus;
  isReadAdviceSessionsPending: boolean;
  isCreateClientPending: boolean;
  isUpdateClientPending: boolean;
  isCreateAdviceSessionPending: boolean;
  isUpdateAdviceSessionPending: boolean;
  isDeleteAdviceSessionPending: boolean;
  clientName?: string | null;
};

export type ClientPageStoreApi = {
  reset: () => void;
  setReadClientInformationStatus: (
    readClientInformationPending: PageStatus
  ) => void;
  setIsReadAdviceSessionsPending: (
    isReadAdviceSessionsPending: boolean
  ) => void;
  setIsCreateClientPending: (isCreateClientPending: boolean) => void;
  setIsUpdateClientPending: (isUpdateClientPending: boolean) => void;
  setIsCreateAdviceSessionPending: (
    isCreateAdviceSessionPending: boolean
  ) => void;
  setIsUpdateAdviceSessionPending: (
    isUpdateAdviceSessionPending: boolean
  ) => void;
  setIsDeleteAdviceSessionPending: (
    isDeleteAdviceSessionPending: boolean
  ) => void;
  setClientData: (clientName: string | undefined | null) => void;
};

export type ClientPageStoreState = ClientPageStoreData & ClientPageStoreApi;

const defaultData = {
  readClientInformationStatus: PageStatuses.ready,
  isReadAdviceSessionsPending: false,
  isCreateClientPending: false,
  isUpdateClientPending: false,
  isCreateAdviceSessionPending: false,
  isUpdateAdviceSessionPending: false,
  isDeleteAdviceSessionPending: false
};

export const useClientPageStore = create<ClientPageStoreState>(set => ({
  ...defaultData,
  reset() {
    set(defaultData);
  },
  setReadClientInformationStatus(readClientInformationStatus) {
    set({ readClientInformationStatus });
  },
  setIsReadAdviceSessionsPending(isReadAdviceSessionsPending) {
    set({ isReadAdviceSessionsPending });
  },
  setIsCreateClientPending(isCreateClientPending) {
    set({ isCreateClientPending });
  },
  setIsUpdateClientPending(isUpdateClientPending) {
    set({ isUpdateClientPending });
  },
  setIsCreateAdviceSessionPending(isCreateAdviceSessionPending) {
    set({ isCreateAdviceSessionPending });
  },
  setIsUpdateAdviceSessionPending(isUpdateAdviceSessionPending) {
    set({ isUpdateAdviceSessionPending });
  },
  setIsDeleteAdviceSessionPending(isDeleteAdviceSessionPending) {
    set({ isDeleteAdviceSessionPending });
  },
  setClientData(clientName) {
    set({ clientName });
  }
}));
