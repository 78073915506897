import { PortfolioChart } from '@quantfoliorepo/ui-components';
import { isEmpty } from 'ramda';
import { useMemo, useState } from 'react';

import { Asset, PortfolioChartsTabs } from '../portfolioChartCards/visual';
import { PortfolioType } from 'features/roboAdvice/adviceSession/portfolioChart';
import ButtonGroup from 'features/shared/components/buttonGroup';
import LoadingIndicator from 'features/shared/components/loadingIndicator/loadingIndicator';
import { PortfolioChartDimensions } from 'features/shared/constants/chart';
import { Spacing } from 'features/shared/constants/spacing';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import {
  useTheme,
  createUseStyles
} from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  portfolioGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(450px, 1fr));',
    columnGap: '20px',
    rowGap: Spacing.spacing02,
    justifyItems: 'center',
    overflow: 'hidden',
    marginTop: Spacing.spacing02
  }
});

type PortfolioChartGridData = {
  expectedAnnualReturn: number;
  expectedVolatility: number;
  equityPortion: number;
  categoryMainAssetClassPortfolio: Asset[];
  categorySubAssetClassPortfolio: Asset[];
  categorySelectionPortfolio: Asset[];
  goalId?: string;
  goalName?: string;
  goalIcon?: string;
  portfolioType: keyof typeof PortfolioType;
};

type Props = {
  isLoading?: boolean;
  portfolioChartData: PortfolioChartGridData[];
  selectedGoalsIds: string[];
  cultureCode: string;
  selectedGoalsNames?: string[];
};

const PortfolioChartGridVisual = ({
  isLoading,
  portfolioChartData,
  selectedGoalsIds,
  cultureCode,
  selectedGoalsNames = []
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const i18n = useI18n();
  const [chartIndex, setChartIndex] = useState<
    typeof PortfolioChartsTabs[keyof typeof PortfolioChartsTabs]
  >(PortfolioChartsTabs.broadAssetAllocation);

  const chartData = useMemo(() => {
    const selectedGoalsData = portfolioChartData.filter(
      ({ goalId, portfolioType }) =>
        goalId &&
        selectedGoalsIds.includes(goalId) &&
        (portfolioType === PortfolioType.model ||
          portfolioType === PortfolioType.custom)
    );
    return selectedGoalsData.map(goalData => {
      let data = {
        goalId: goalData?.goalId,
        goalName: goalData?.goalName,
        goalIcon: goalData?.goalIcon,
        chartData: [] as Asset[]
      };
      if (chartIndex === PortfolioChartsTabs.broadAssetAllocation) {
        data = {
          ...data,
          chartData: goalData?.categoryMainAssetClassPortfolio || []
        };
      } else if (chartIndex === PortfolioChartsTabs.assetAllocation) {
        data = {
          ...data,
          chartData: goalData?.categorySubAssetClassPortfolio || []
        };
      } else if (chartIndex === PortfolioChartsTabs.fundAllocation) {
        data = {
          ...data,
          chartData: goalData?.categorySelectionPortfolio || []
        };
      }
      data = {
        ...data,
        chartData: data.chartData.filter(d => d.weight > 0)
      };

      return data;
    });
  }, [chartIndex, portfolioChartData, selectedGoalsIds, selectedGoalsNames]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isEmpty(chartData)) {
    return <div>{i18n('roboAdvice.proposal.NoPortfolioData')}</div>;
  }

  return (
    <>
      <ButtonGroup
        items={[
          {
            title: i18n('roboAdvice.proposal.broadAssetAllocation'),
            isActive: chartIndex === PortfolioChartsTabs.broadAssetAllocation,
            onClick: () =>
              setChartIndex(PortfolioChartsTabs.broadAssetAllocation)
          },
          {
            title: i18n('roboAdvice.proposal.assetAllocation'),
            isActive: chartIndex === PortfolioChartsTabs.assetAllocation,
            onClick: () => setChartIndex(PortfolioChartsTabs.assetAllocation)
          },
          {
            title: i18n('roboAdvice.proposal.fundAllocation'),
            isActive: chartIndex === PortfolioChartsTabs.fundAllocation,
            onClick: () => setChartIndex(PortfolioChartsTabs.fundAllocation)
          }
        ]}
      />
      <div className={classes.portfolioGrid}>
        {chartData.map(data => (
          <div key={data.goalId}>
            <PortfolioChart
              cultureCode={cultureCode}
              chartData={data.chartData}
              width={PortfolioChartDimensions.width}
              height={PortfolioChartDimensions.height}
              dataLabelsColor={theme.primaryColor}
              backgroundSpacingColor={theme.boxBackgroundColor_1}
              iconName={data.goalIcon}
              isVertical
              noLegend={false}
              alignTitleBottom={false}
              title={data.goalName}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default PortfolioChartGridVisual;
