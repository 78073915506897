import { Field, Form } from 'react-final-form';

import TemplateSelect from '../select';
import { Template, useTemplateStore } from '../templateStore';
import useGetInitialValues from '../useGetInitialValues';
import { FormValues } from './types';
import { areAllFieldsSelected, handleSwitchChange } from './utils';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import Switch from 'features/shared/components/switch';
import { Spacing } from 'features/shared/constants/spacing';
import { FontWeights } from 'features/shared/constants/typography';
import { useUnmountEffect } from 'features/shared/hooks/useUnmountEffect';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const useStyles = createUseStyles(theme => ({
  container: {
    marginLeft: Spacing.spacing01,
    marginRight: Spacing.spacing01
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: Spacing.spacing02,
    gap: Spacing.spacing02
  },
  templateSelect: {
    marginTop: Spacing.spacing01
  },
  fieldsGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: 28,

    '&:first-child': {
      gap: Spacing.spacing01
    }
  },
  fieldRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  submitButton: {
    marginLeft: 'auto',
    width: 200
  },
  switch: {
    marginLeft: 'auto'
  },
  header: {
    fontWeight: FontWeights.medium
  }
}));

type Props = {
  header: string;
  sectionsHeader: string;
  attachmentsHeader: string;
  templates: Template[];
  onSubmit: (values: FormValues) => void;
};

const GenerateNewPdfReportForm = ({
  header,
  sectionsHeader,
  attachmentsHeader,
  templates,
  onSubmit
}: Props) => {
  const i18n = useI18n();
  const classes = useStyles();
  const getInitialValues = useGetInitialValues();

  const { template, setTemplate } = useTemplateStore();

  const sectionsFields =
    template?.sections.map(section => ({
      label: section.translations[getUserPageLanguage() ?? 'en'],
      value: `sections.${section.id}`
    })) ?? [];

  const attachmentsFields =
    template?.attachments.map(attachment => ({
      label: attachment.label,
      value: `attachments.${attachment.id}`
    })) ?? [];

  const areSectionsAvailable = sectionsFields.length > 0;
  const areAttachmentsAvailable = Boolean(
    attachmentsFields.length > 0 || template?.allowProductAttachments
  );

  useUnmountEffect(() => {
    setTemplate(null);
  });

  return (
    <Form
      initialValues={getInitialValues()}
      onSubmit={onSubmit}
      mutators={{
        selectAllSections: (...args) =>
          handleSwitchChange(...args, 'sections', true),
        unselectAllSections: (...args) =>
          handleSwitchChange(...args, 'sections', false),
        selectAllAttachments: (...args) =>
          handleSwitchChange(...args, 'attachments', true),
        unselectAllAttachments: (...args) =>
          handleSwitchChange(...args, 'attachments', false)
      }}
      render={({ handleSubmit, form, values }) => (
        <div className={classes.container}>
          <div className={classes.fieldsGroup}>
            <span className={classes.header}>{header}</span>
            <TemplateSelect templates={templates} resetForm={form.reset} />
          </div>

          <form
            id="generate-new-pdf-form"
            onSubmit={handleSubmit}
            className={classes.formContainer}
          >
            {areSectionsAvailable && (
              <div className={classes.fieldsGroup}>
                <span className={classes.header}>{sectionsHeader}</span>

                <div className={classes.fieldRow}>
                  <span>{i18n('roboAdvice.proposal.generateReport.all')}</span>
                  <Switch
                    className={classes.switch}
                    checked={areAllFieldsSelected('sections', values)}
                    onChange={() =>
                      areAllFieldsSelected('sections', values)
                        ? form.mutators.unselectAllSections()
                        : form.mutators.selectAllSections()
                    }
                  />
                </div>

                {sectionsFields.map(field => (
                  <Field name={field.value} type="checkbox" key={field.value}>
                    {({ input }) => {
                      return (
                        <div className={classes.fieldRow}>
                          <span>{field.label}</span>
                          <Switch
                            className={classes.switch}
                            checked={input.checked}
                            onChange={input.onChange}
                          />
                        </div>
                      );
                    }}
                  </Field>
                ))}
              </div>
            )}

            {areAttachmentsAvailable && (
              <div className={classes.fieldsGroup}>
                <span className={classes.header}>{attachmentsHeader}</span>

                <div className={classes.fieldRow}>
                  <span>{i18n('roboAdvice.proposal.generateReport.all')}</span>
                  <Switch
                    className={classes.switch}
                    checked={areAllFieldsSelected('attachments', values)}
                    onChange={() =>
                      areAllFieldsSelected('attachments', values)
                        ? form.mutators.unselectAllAttachments()
                        : form.mutators.selectAllAttachments()
                    }
                  />
                </div>

                {attachmentsFields.map(field => (
                  <Field name={field.value} type="checkbox" key={field.value}>
                    {({ input }) => (
                      <div className={classes.fieldRow}>
                        <span>{field.label}</span>
                        <Switch
                          className={classes.switch}
                          checked={input.checked}
                          onChange={input.onChange}
                        />
                      </div>
                    )}
                  </Field>
                ))}

                {template?.allowProductAttachments && (
                  <Field
                    name={'attachments.productAttachments'}
                    type="checkbox"
                  >
                    {({ input }) => {
                      return (
                        <div className={classes.fieldRow}>
                          <span>
                            {i18n(
                              'roboAdvice.proposal.generateReport.productAttachments'
                            )}
                          </span>
                          <Switch
                            className={classes.switch}
                            checked={input.checked}
                            onChange={input.onChange}
                          />
                        </div>
                      );
                    }}
                  </Field>
                )}
              </div>
            )}
          </form>
        </div>
      )}
    />
  );
};

export default GenerateNewPdfReportForm;
