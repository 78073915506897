import axios from 'axios';
import { isNil } from 'ramda';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  CustomDocument,
  useCustomDownloadStore
} from '../services/customDownloadStore';
import { getAvailableDocuments } from 'features/roboAdvice/adviceSession/proposal/api';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export const useReadReportDocuments = () => {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = useRef<any>();
  const { clientId } = useParams<any>();
  const dispatch = useDispatch();
  const i18n = useI18n();
  const {
    roboAdvice: {
      pdfGeneration: { usePDFv1, usePDFv2 },
      orderExecution: {
        pdfGeneration: { usePDFv2: orderUsePDFv2 }
      }
    }
  } = useCustomerConfig();

  const readReportDocuments = async (
    adviceSessionId: string,
    forceReload: boolean = false
  ) => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const customDownloadStoreState = useCustomDownloadStore.getState();
    const availableDocuments =
      customDownloadStoreState.availableDocuments[adviceSessionId];

    if (availableDocuments && !forceReload) {
      return availableDocuments;
    }

    try {
      const allDocuments: CustomDocument[] = [];

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );
      if (usePDFv2 || orderUsePDFv2) {
        const documentsv2Response = await getAvailableDocuments(
          accessToken,
          cancelTokenSource.token,
          clientId,
          adviceSessionId,
          2
        );
        if (documentsv2Response?.data?.data) {
          allDocuments.push(...documentsv2Response.data.data);
        }
      }
      if (usePDFv1) {
        const documentsv1Response = await getAvailableDocuments(
          accessToken,
          cancelTokenSource.token,
          clientId,
          adviceSessionId,
          null
        );
        if (documentsv1Response?.data?.data) {
          allDocuments.push(...documentsv1Response.data.data);
        }
      }

      const uniqueDocuments = allDocuments.filter(
        (doc, index) =>
          allDocuments.findIndex(
            ({ documentId }) => doc.documentId === documentId
          ) === index
      );

      useCustomDownloadStore
        .getState()
        .addAvailableDocuments(adviceSessionId, uniqueDocuments);

      return uniqueDocuments;
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.client.adviceSessionsTable.errorWhileFetchingDocuments'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readReportDocuments;
};
