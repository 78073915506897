import classNames from 'classnames';
import { path, prop, isNil, set, lensPath, isEmpty } from 'ramda';
import { useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useSessionStore } from '../../session/services/sessionStore';
import { PageStatuses } from '../../shared/components/useReadDataListener';
import WarningMessage from '../../shared/components/warningMessage';
import { usePageStore as useProposalPageStore } from '../services/pageStore';
import { useIsFormComplete } from '../services/selectors.js';
import { useIsReportDataOld } from './useIsReportDataOld';
import { ReactComponent as CloudDownloadIcon } from 'assets/cloudDownload.svg';
import { useMarkAsComplete } from 'features/roboAdvice/adviceSession/proposal/components/useMarkAsComplete';
import CustomDownloadPopup from 'features/roboAdvice/shared/components/CustomDownloadPopup';
import useClientMissingFields from 'features/roboAdvice/shared/components/useClientMissingFields';
import { useDownloadReportDocuments } from 'features/roboAdvice/shared/components/useDownloadReportDocuments';
import { useCustomDownloadStore } from 'features/roboAdvice/shared/services/customDownloadStore';
import Button from 'features/shared/components/button';
import ContextMenu from 'features/shared/components/popper/contextMenu.js';
import Popper from 'features/shared/components/popper/index.js';
import { Spacing } from 'features/shared/constants/spacing';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: Spacing.spacing01
  },
  warningMessage: {
    marginRight: 40 // icon width + margin between action buttons
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& + &': {
      marginLeft: Spacing.spacing01
    }
  },
  iconButton: {
    '& path': {
      fill: theme.primaryColor
    }
  },
  iconButtonDisabled: {
    cursor: 'unset',
    opacity: 0.4
  }
}));

const ActionButtons = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const { adviceSessionId } = useParams();
  const proposalPageStoreState = useProposalPageStore.getState();
  const containerRef = useRef();
  const [isCustomDownloadModalOpen, setIsCustomDownloadModalOpen] =
    useState(false);
  const { availableDocuments } = useCustomDownloadStore.getState();
  const downloadReportDocuments = useDownloadReportDocuments();
  const markAsComplete = useMarkAsComplete();
  const isFormComplete = useIsFormComplete();
  const isReportDataOld = useIsReportDataOld(adviceSessionId);
  const noDocumentsAvailable =
    isEmpty(availableDocuments[adviceSessionId]) ||
    isNil(availableDocuments[adviceSessionId]);
  const clientMissingFields = useClientMissingFields();
  const {
    roboAdvice: {
      pdfGeneration: { usePDFv1, usePDFv2 }
    }
  } = useCustomerConfig();
  const isAdviceSessionPatched = useSessionStore(
    state => state.isAdviceSessionPatched
  );

  const openModal = () => {
    if (clientMissingFields()) {
      return;
    }

    proposalPageStoreState.setIsGenerateReportPopupOpen(true);
  };

  const openNewPdfModal = () => {
    if (clientMissingFields()) {
      return;
    }

    proposalPageStoreState.setIsGenerateNewPdfReportPopupOpen(true);
  };

  const warningMessage = useMemo(() => {
    if (isReportDataOld) {
      return i18n(
        !isAdviceSessionPatched
          ? 'roboAdvice.proposal.warningMessage.newDataNotOwner'
          : 'roboAdvice.proposal.warningMessage.newDataOwner'
      );
    }

    return !isAdviceSessionPatched
      ? i18n('roboAdvice.proposal.generateAndCompleteAdviceDisabled')
      : null;
  }, [i18n, isAdviceSessionPatched, isReportDataOld]);

  return (
    <div className={classes.root}>
      {warningMessage && (
        <WarningMessage
          className={classes.warningMessage}
          message={warningMessage}
        />
      )}

      <div
        className={classes.actionButtons}
        style={{
          marginTop: warningMessage ? 0 : Spacing.spacing04
        }}
      >
        {usePDFv1 && (
          <Button
            disabled={!isFormComplete || !isAdviceSessionPatched}
            className={classes.actionButton}
            onClick={openModal}
            isLoading={proposalPageStoreState.isReadPdfReportPending}
          >
            {i18n('roboAdvice.proposal.generateProposal')}
          </Button>
        )}

        {usePDFv2 && (
          <Button
            disabled={
              !isFormComplete ||
              !isAdviceSessionPatched ||
              proposalPageStoreState.cachePDFStatus === PageStatuses.pending
            }
            className={classes.actionButton}
            onClick={openNewPdfModal}
            isLoading={proposalPageStoreState.isReadNewPdfReportPending}
          >
            {i18n('roboAdvice.reportGeneration.generateNewPdfProposal')}
          </Button>
        )}

        <Button
          disabled={!isFormComplete || noDocumentsAvailable || warningMessage}
          className={classes.actionButton}
          onClick={() => markAsComplete(adviceSessionId)}
        >
          {i18n('roboAdvice.proposal.markAsComplete')}
        </Button>

        <CustomDownloadPopup
          setIsModalOpen={setIsCustomDownloadModalOpen}
          isModalOpen={isCustomDownloadModalOpen}
          selectedSessionId={adviceSessionId}
          documents={availableDocuments[adviceSessionId] || []}
        />

        <div className={classes.actionButton} ref={containerRef}>
          <Popper
            popperProps={{
              placement: 'top',
              modifiers: {
                offset: {
                  order: 825,
                  fn: (data, options) => {
                    const popper = path(['instance', 'popper'], data);
                    const container = prop('current', containerRef);

                    if (isNil(popper) || isNil(container)) {
                      return data;
                    }

                    const popperBounds = popper.getBoundingClientRect();
                    const containerBounds = container.getBoundingClientRect();

                    const popperOffsetLeft =
                      containerBounds.left - popperBounds.width;

                    return set(
                      lensPath(['offsets', 'popper', 'left']),
                      popperOffsetLeft,
                      data
                    );
                  }
                }
              }
            }}
            reference={({ ref, isPopperOpen, togglePopper }) => (
              <CloudDownloadIcon
                ref={ref}
                className={classNames(classes.iconButton, {
                  [classes.iconButtonDisabled]:
                    noDocumentsAvailable || isReportDataOld
                })}
                onClick={
                  noDocumentsAvailable || isReportDataOld
                    ? null
                    : e => {
                        e.stopPropagation();

                        togglePopper(!isPopperOpen);
                      }
                }
              />
            )}
            popper={({ ref, style, placement, isPopperOpen, togglePopper }) => {
              return (
                isPopperOpen && (
                  <div
                    ref={ref}
                    style={style}
                    data-placement={placement}
                    className={classes.tableMenuContainer}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  >
                    <ContextMenu
                      classNames={{
                        menu: classes.tableMenu
                      }}
                      items={[
                        {
                          title: i18n(
                            'roboAdvice.client.adviceSessionsTable.downloadReport'
                          ),
                          iconType: 'cloud_download',
                          onClick: e => {
                            e.preventDefault();
                            downloadReportDocuments(
                              adviceSessionId,
                              availableDocuments[adviceSessionId]?.filter(
                                a => a.documentType === 'report'
                              )
                            );
                            togglePopper(false);
                          }
                        },
                        {
                          title: i18n(
                            'roboAdvice.client.adviceSessionsTable.downloadReportWithAttachments'
                          ),
                          iconType: 'cloud_download',
                          onClick: e => {
                            e.preventDefault();

                            downloadReportDocuments(
                              adviceSessionId,
                              availableDocuments[adviceSessionId]
                            );
                            togglePopper(false);
                          }
                        },
                        {
                          title: i18n(
                            'roboAdvice.client.adviceSessionsTable.customDownload'
                          ),
                          iconType: 'cloud_download',
                          onClick: e => {
                            e.preventDefault();
                            setIsCustomDownloadModalOpen(true);
                            togglePopper(false);
                          }
                        }
                      ]}
                    />
                  </div>
                )
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ActionButtons;
