import classNames from 'classnames';
import { useState } from 'react';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { useWindowSize } from 'usehooks-ts';

import { CustomAttribute } from '../../../shared/services/adviceSessionStore';
import { CustomAttributeFromConfig } from '../../../shared/services/selectors';
import InstrumentsSearch from './instrumentsSearch';
import { InstrumentSelected } from 'features/roboAdvice/adviceSession/form/services/form';
import { ReadAnalyzeAdvancedSuitabilityStatusV2Response } from 'features/roboAdvice/adviceSession/shared/api/types';
import NumberInput from 'features/shared/components/numberInput/numberInput';
import Select from 'features/shared/components/select';
import TableBodyRow from 'features/shared/components/table/tableBodyRow';
import { LayoutBreakpoints } from 'features/shared/constants/layoutBreakpoints';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const AnyTableBodyRow: any = TableBodyRow;

const useStyles = createUseStyles({
  newRow: {
    position: 'relative'
  },
  newRowCell: {
    padding: '1.2rem 1.3rem'
  },
  numberCell: {
    textAlign: 'right',
    maxWidth: '16rem'
  },
  numberInput: {
    width: '100%',
    textAlign: 'right'
  }
});

type Props = {
  instruments: Omit<InstrumentSelected, 'initialDeposit' | 'monthlySaving'>[];
  instrumentsSelected: FieldArrayRenderProps<InstrumentSelected, HTMLElement>;
  decimalSeparator?: string;
  thousandSeparator?: string;
  productAttributes: CustomAttributeFromConfig[];
  showISINColumn: boolean;
  showAssetClassColumn: boolean;
  customAttributesData?: Record<number, CustomAttribute[]>;
  showSuitabilityStatus: boolean;
  advancedSuitabilityData: ReadAnalyzeAdvancedSuitabilityStatusV2Response | null;
};

const NewRow = ({
  instruments,
  instrumentsSelected,
  decimalSeparator,
  thousandSeparator,
  showISINColumn,
  showAssetClassColumn,
  productAttributes,
  customAttributesData,
  showSuitabilityStatus,
  advancedSuitabilityData
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const [selectedId, setSelectedId] = useState('');
  const [newInstrument, setNewInstrument] = useState({
    initialDeposit: undefined,
    monthlySaving: undefined
  });
  const {
    roboAdvice: {
      orderExecution: {
        instrumentsList: { useAdvancedSearch }
      }
    }
  } = useCustomerConfig();

  const selectWidth =
    useWindowSize().width < LayoutBreakpoints.desktopMin ? 230 : 290;

  const selectedInstrumentsIds = instrumentsSelected.fields.value
    ? instrumentsSelected.fields.value.map(({ id }) => id)
    : [];

  const nameOptions = instruments
    .filter(({ id }) => !selectedInstrumentsIds.includes(id))
    .map(i => ({
      label: i.name,
      value: i
    }));

  const isinOptions = instruments
    .filter(({ id }) => !selectedInstrumentsIds.includes(id))
    .map(i => ({
      label: i.isin ? i.isin : i.name,
      value: i
    }));

  const onInstrumentSelected = option => {
    const selectedInstrument = instruments.find(i => i.id === option.id)!;

    instrumentsSelected.fields.push({
      ...selectedInstrument,
      initialDeposit: newInstrument.initialDeposit,
      monthlySaving: newInstrument.monthlySaving
    });

    setNewInstrument({
      initialDeposit: undefined,
      monthlySaving: undefined
    });
  };

  const onSelectChange = value => {
    value && onInstrumentSelected(value);
    setSelectedId(value.id);
  };

  return (
    <AnyTableBodyRow
      data-testid={'new-instruments-row'}
      elementId={'new-instruments-row'}
      className={classes.newRow}
    >
      {({ bodyRowCellClassName }) => (
        <>
          {!useAdvancedSearch && (
            <td
              className={classNames(classes.newRowCell, bodyRowCellClassName)}
            >
              <Select
                key={'name' + selectedId}
                isSearchable
                onChange={onSelectChange}
                options={nameOptions}
                placeholder={i18n(
                  'roboAdvice.orderExecution.searchInstrumentName'
                )}
                width={selectWidth}
              />
            </td>
          )}
          {showISINColumn && !useAdvancedSearch && (
            <td
              className={classNames(classes.newRowCell, bodyRowCellClassName)}
            >
              <Select
                key={'isin' + selectedId}
                isSearchable
                onChange={onSelectChange}
                options={isinOptions}
                placeholder={i18n('roboAdvice.orderExecution.searchISIN')}
                width={selectWidth}
              />
            </td>
          )}
          {useAdvancedSearch && (
            <td
              className={classNames(classes.newRowCell, bodyRowCellClassName)}
              colSpan={showISINColumn ? 2 : 1}
            >
              <InstrumentsSearch
                instruments={instruments}
                onInstrumentSelect={onSelectChange}
                productAttributes={productAttributes}
                showISINColumn={showISINColumn}
                showAssetClassColumn={showAssetClassColumn}
                customAttributesData={customAttributesData}
                selectedInstrumentsIds={selectedInstrumentsIds}
                showSuitabilityStatus={showSuitabilityStatus}
                advancedSuitabilityData={advancedSuitabilityData}
              />
            </td>
          )}
          {showAssetClassColumn && (
            <td
              className={classNames(classes.newRowCell, bodyRowCellClassName)}
            />
          )}

          {productAttributes.map((_, index) => (
            <td
              key={index}
              className={classNames(classes.newRowCell, bodyRowCellClassName)}
            />
          ))}

          {showSuitabilityStatus && (
            <td
              className={classNames(classes.newRowCell, bodyRowCellClassName)}
            />
          )}

          <td
            className={classNames(
              classes.newRowCell,
              classes.numberCell,
              bodyRowCellClassName
            )}
          >
            <NumberInput
              value={newInstrument.initialDeposit}
              thousandSeparator={thousandSeparator}
              decimalSeparator={decimalSeparator}
              allowNegative={false}
              decimalScale={2}
              className={classes.numberInput}
              placeholder="0"
              onChange={val => {
                setNewInstrument({
                  monthlySaving: newInstrument.monthlySaving,
                  initialDeposit: val
                });
              }}
            />
          </td>

          <td
            className={classNames(
              classes.newRowCell,
              classes.numberCell,
              bodyRowCellClassName
            )}
          >
            <NumberInput
              value={newInstrument.monthlySaving}
              thousandSeparator={thousandSeparator}
              decimalSeparator={decimalSeparator}
              allowNegative={false}
              decimalScale={2}
              className={classes.numberInput}
              placeholder="0"
              onChange={val => {
                setNewInstrument({
                  initialDeposit: newInstrument.initialDeposit,
                  monthlySaving: val
                });
              }}
            />
          </td>

          <td
            className={classNames(classes.newRowCell, bodyRowCellClassName)}
          />
        </>
      )}
    </AnyTableBodyRow>
  );
};

export default NewRow;
