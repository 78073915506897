import axios from 'axios';
import { omit } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { RoboAdviceClientPages } from '../../../shared/constants';
import { getClientName } from '../../../shared/services/mapping';
import { ClientParams } from '../../shared/types';
import { updateInvestorInformation } from '../api';
import { clientForm } from '../services/clientForm';
import { useClientPageStore } from '../services/clientPageStore';
import { createInvestorPayload } from '../services/mapping';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { ClientTypes } from 'features/shared/constants/session';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useUpdateClient() {
  const history = useHistory();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const i18n = useI18n();
  const { clientId } = useParams<ClientParams>();
  const currentUser: any = useSelector(sessionSelectors.getCurrentUser());
  const {
    roboAdvice: {
      kyc: {
        company: { isKYCEnabled: companyIsKYCEnabled },
        person: { isKYCEnabled: personIsKYCEnabled }
      }
    }
  } = useCustomerConfig();

  const dispatch = useDispatch();

  const updateClient = async () => {
    const clientPageStore = useClientPageStore.getState();
    const { values } = clientForm.getState();

    try {
      clientPageStore.setIsUpdateClientPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        undefined
      );
      const clientType = values.clientInformation?.clientType;
      const isKYCEnabled =
        clientType === ClientTypes.company
          ? companyIsKYCEnabled
          : personIsKYCEnabled;
      const updatedInvestorData = omit(
        ['advisorId', 'investorType'],
        createInvestorPayload(values, currentUser.auth0Id)
      );
      await updateInvestorInformation(
        accessToken,
        updatedInvestorData,
        clientId
      );

      history.push(
        routeTemplates.roboAdviceClient.build(
          clientType,
          clientId,
          isKYCEnabled
            ? RoboAdviceClientPages.kyc
            : RoboAdviceClientPages.adviceSessions
        )
      );

      clientForm.initialize(values);

      const clientName = getClientName(values.clientInformation);

      clientPageStore.setClientData(clientName);
      clientPageStore.setIsUpdateClientPending(false);

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n('roboAdvice.client.updateClientSuccessMessage'),
          type: NotificationTypes.success
        })
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        clientPageStore.setIsUpdateClientPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.client.updateClientErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return updateClient;
}
