import classNames from 'classnames';
import { omit } from 'ramda';
import React from 'react';

import { useClientForm, clientForm } from '../../main/services/clientForm';
import ConfigurableLabel from 'features/shared/components/configurableLabel/';
import ErrorMessage from 'features/shared/components/errorMessage';
import Icon from 'features/shared/components/icon/index';
import Table from 'features/shared/components/table/table.js';
import TableBody from 'features/shared/components/table/tableBody.js';
import TableBodyRow from 'features/shared/components/table/tableBodyRow.js';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { defaultConfirmation } from 'features/sharedInstances/defaultConfirmation';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import FinalFormCheckbox from 'features/sharedModules/finalForm/components/checkbox';
import FinalFormTextInput from 'features/sharedModules/finalForm/components/textInput';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const useStyles = createUseStyles(theme => ({
  header: {
    fontSize: Typography.heading1.size,
    fontWeight: FontWeights.medium,
    lineHeight: '23px',
    margin: `${Spacing.spacing03}px 0 ${Spacing.spacing01}px 0`
  },
  container: {
    '& $headerCell': {
      wordBreak: 'initial'
    },
    '& $bodyRowCell': {
      paddingLeft: '1rem',
      paddingRight: '1rem'
    }
  },
  headerCell: {
    color: theme.secondaryColor,
    textAlign: 'center',
    minWidth: '70px'
  },
  bodyRowCell: {},
  input: {
    width: '18rem'
  },
  deleteIcon: {
    padding: 0,
    fontSize: '2rem',
    lineHeight: '2.4rem',
    cursor: 'pointer',
    transform: 'rotate(0.03deg)' // fix of issue: icon small size causes pixelated icon
  },
  checkboxContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const ContactsTable = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const { values, errors, touched } = useClientForm();
  const {
    roboAdvice: {
      clientInformation: {
        contacts: { isSignatureRightsEnabled, isUBOEnabled, required }
      }
    }
  } = useCustomerConfig();
  const { open: openConfirmation } =
    defaultConfirmation.useConfirmationStore.getState();

  const showError =
    errors.clientInformation?.contactList &&
    touched['clientInformation.contacts[0].role'];

  const deleteRow = index => {
    clientForm.change(
      'clientInformation.contacts',
      values.clientInformation.contacts.filter((_, i) => i !== index)
    );
  };

  const rows = React.useMemo(() => {
    const contacts = values.clientInformation.contacts;

    let newRow = {
      name: '',
      email: '',
      phone: '',
      role: ''
    };

    if (isSignatureRightsEnabled) newRow = { ...newRow, signRight: false };
    if (isUBOEnabled) newRow = { ...newRow, ubo: false };

    if (
      contacts &&
      contacts.length < 10 &&
      Object.values(
        omit(['signRight', 'ubo'], contacts[contacts.length - 1])
      ).some(value => !!value)
    ) {
      return [...values.clientInformation.contacts, newRow];
    }

    return contacts?.length > 0 ? contacts : [newRow];
  }, [
    isSignatureRightsEnabled,
    isUBOEnabled,
    values.clientInformation.contacts
  ]);

  const maxIndexToDelete = React.useMemo(() => {
    const contacts = values.clientInformation.contacts;

    return contacts && contacts.length > 1 ? contacts.length - 1 : -1;
  }, [values.clientInformation.contacts]);

  const setConfigurableLabel = label => (
    <ConfigurableLabel
      required={required}
      label={<span className={classes.headerCell}>{label}</span>}
    />
  );

  const headers = React.useMemo(() => {
    return [
      {
        component: setConfigurableLabel(
          i18n('roboAdvice.clientInformation.contacts.name')
        )
      },
      {
        component: setConfigurableLabel(
          i18n('roboAdvice.clientInformation.contacts.email')
        )
      },
      {
        component: setConfigurableLabel(
          i18n('roboAdvice.clientInformation.contacts.phone')
        )
      },
      {
        component: setConfigurableLabel(
          i18n('roboAdvice.clientInformation.contacts.role')
        )
      },
      isSignatureRightsEnabled
        ? {
            title: i18n('roboAdvice.clientInformation.contacts.signRight'),
            className: classes.headerCell
          }
        : null,
      isUBOEnabled
        ? {
            title: i18n('roboAdvice.clientInformation.contacts.ubo'),
            className: classes.headerCell
          }
        : null,
      { title: '' }
    ].filter(i => !!i);
  }, [classes.headerCell, i18n, isSignatureRightsEnabled, isUBOEnabled]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {i18n('roboAdvice.clientInformation.contacts')}
      </div>

      <Table header={headers}>
        <TableBody>
          {rows.map((_, index) => (
            <TableBodyRow key={index}>
              {({ bodyRowCellClassName }) => (
                <>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.bodyRowCell
                    )}
                  >
                    <FinalFormTextInput
                      className={classes.input}
                      name={`clientInformation.contacts[${index}].name`}
                    />
                  </td>

                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.bodyRowCell
                    )}
                  >
                    <FinalFormTextInput
                      className={classes.input}
                      name={`clientInformation.contacts[${index}].email`}
                    />
                  </td>

                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.bodyRowCell
                    )}
                  >
                    <FinalFormTextInput
                      className={classes.input}
                      name={`clientInformation.contacts[${index}].phone`}
                    />
                  </td>

                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.bodyRowCell
                    )}
                  >
                    <FinalFormTextInput
                      className={classes.input}
                      name={`clientInformation.contacts[${index}].role`}
                    />
                  </td>

                  {isSignatureRightsEnabled && (
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.bodyRowCell
                      )}
                    >
                      <div className={classes.checkboxContainer}>
                        <FinalFormCheckbox
                          name={`clientInformation.contacts[${index}].signRight`}
                          fieldConfig={{ type: 'checkbox' }}
                        />
                      </div>
                    </td>
                  )}

                  {isUBOEnabled && (
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.bodyRowCell
                      )}
                    >
                      <div className={classes.checkboxContainer}>
                        <FinalFormCheckbox
                          name={`clientInformation.contacts[${index}].ubo`}
                          fieldConfig={{ type: 'checkbox' }}
                        />
                      </div>
                    </td>
                  )}

                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.bodyRowCell
                    )}
                  >
                    {index <= maxIndexToDelete && (
                      <Icon
                        className={classes.deleteIcon}
                        type="delete"
                        onClick={() =>
                          openConfirmation(
                            i18n(
                              'roboAdvice.clientInformation.contacts.deleteConfirmationMessage'
                            ),
                            () => deleteRow(index)
                          )
                        }
                      />
                    )}
                  </td>
                </>
              )}
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>

      {showError && (
        <ErrorMessage>
          * {i18n(errors.clientInformation.contactList)}
        </ErrorMessage>
      )}
    </div>
  );
};

export default ContactsTable;
