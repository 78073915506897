import * as R from 'ramda';

import { TableStoreData } from './types';
import { OrderDirections } from 'features/shared/constants/table';

const sortDirectionFuncs = {
  [OrderDirections.asc]: R.ascend,
  [OrderDirections.desc]: R.descend
};

export const getSortFunc = <T extends { id: string }>({
  tableStoreData,
  sortKeyFuncs
}: {
  tableStoreData: TableStoreData;
  sortKeyFuncs?: any;
}) => {
  if (sortKeyFuncs == null) {
    sortKeyFuncs = {};
  }

  const { orderDirection, orderKey } = tableStoreData;

  const sortDirectionFunc =
    orderDirection == null ? null : sortDirectionFuncs[orderDirection];

  let sortKeyFunc: any = null;
  if (orderKey != null) {
    sortKeyFunc = sortKeyFuncs[orderKey];

    if (sortKeyFunc == null) {
      sortKeyFunc = R.propOr('', orderKey);
    }
  }

  if (sortDirectionFunc == null || sortKeyFunc == null) {
    return (list: T[]) => list;
  }

  return R.sortWith<T>([
    R.descend(i => R.propOr(false, i.id, tableStoreData.favoriteItems)),
    sortDirectionFunc(sortKeyFunc)
  ]);
};
