import axios from 'axios';

import config from 'config/index.js';

export const getAuth0PublicKey = async () => {
  const jwsk_uri = await axios
    .get(
      `${config.REACT_APP_AUTH_PROVIDER_DOMAIN}/.well-known/openid-configuration`
    )
    .then(response => response.data.jwks_uri);
  return axios.get(jwsk_uri);
};
