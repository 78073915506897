import { useAutomaticClientClassificationStore } from '../../services/automaticClientClassificationStore';
import ChecklistModal from './checklistModal';
import EducationalModal from 'features/roboAdvice/adviceSession/sustainability/components/educationalModal';
import { clientForm } from 'features/roboAdvice/client/main/services/clientForm';
import Confirmation from 'features/shared/components/modal/confirmation';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

const Modals = () => {
  const i18n = useI18n();
  const {
    roboAdvice: {
      clientInformation: {
        automaticClientClassification: { educationalModal } = {}
      }
    }
  } = useCustomerConfig();
  const {
    isEducationalModalOpen,
    setIsEducationalModalOpen,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
    formStateConfirmation,
    setFormStateConfirmation
  } = useAutomaticClientClassificationStore();

  return (
    <>
      <EducationalModal
        pages={educationalModal?.pages || []}
        header={i18n(
          'roboAdvice.kyc.automaticClientClassification.clientClassification.header'
        )}
        isOpen={isEducationalModalOpen}
        closeHandler={() => setIsEducationalModalOpen(false)}
      />

      <ChecklistModal />

      <Confirmation
        header={i18n(
          'roboAdvice.kyc.automaticClientClassification.resetClientClassification.header'
        )}
        isOpen={isConfirmationModalOpen}
        onConfirm={() => {
          if (formStateConfirmation) {
            clientForm.batch(() => {
              clientForm.change(
                'kyc.automaticClientClassification.isDecisionOverwritten' as any,
                false
              );
              clientForm.change(
                'kyc.automaticClientClassification.answer' as any,
                formStateConfirmation.kyc?.automaticClientClassification?.answer
              );
              clientForm.change(
                'kyc.automaticClientClassification.clientClassificationId' as any,
                formStateConfirmation.kyc?.automaticClientClassification
                  ?.clientClassificationId
              );
            });
          }
          setIsConfirmationModalOpen(false);
        }}
        onDecline={() => {
          setFormStateConfirmation(null);
          setIsConfirmationModalOpen(false);
        }}
        style={{
          content: {
            maxWidth: '500px'
          }
        }}
      >
        <div>
          {i18n(
            'roboAdvice.kyc.automaticClientClassification.resetClientClassification.description'
          )}
        </div>
      </Confirmation>
    </>
  );
};

export default Modals;
