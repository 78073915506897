import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getQAuthAccessToken } from '../../../../../shared/api';
import { NotificationTypes } from '../../../../../shared/constants/notification';
import { creators as notificationActionCreators } from '../../../../../shared/services/notification/actions';
import sessionSelectors from '../../../../../shared/services/session/selectors';
import { throwSafeError } from '../../../../../shared/utils/throwSafeError';
import { DEFAULT_CONFIDENCE } from '../../../proposal/constants';
import { useSessionStore } from '../../../session/services/sessionStore';
import { createAnalyzeForecast, createRiskReturn } from '../../../shared/api';
import { AnalyzeForecastResponse } from 'features/roboAdvice/adviceSession/shared/api/types';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export function useReadOrderData() {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<CancelTokenSource>();
  const dispatch = useDispatch();

  const readOrderData = async ({ instruments }) => {
    const orderProposalData = {};
    const sessionStoreState = useSessionStore.getState();

    try {
      if (!isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
      const cancelTokenSource = axios.CancelToken.source();
      cancelTokenSourceRef.current = cancelTokenSource;

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const instrumentsWithDeposit = instruments.filter(
        instrument =>
          instrument.initialDeposit > 0 || instrument.monthlySaving > 0
      );

      const riskReturnPromises: Promise<any>[] = instrumentsWithDeposit.map(
        instrument => {
          orderProposalData[instrument.id] = {
            expectedValue: 0,
            expectedAnnualReturn: 0
          };
          return createRiskReturn(
            qAuthAccessToken,
            cancelTokenSource.token,
            {
              portfolio: [{ id: instrument.id, weight: 100 }]
            },
            {
              namespace_id: sessionStoreState.defaultConfigNamespaceId
            }
          );
        }
      );

      const forecastPromises: Promise<
        AxiosResponse<AnalyzeForecastResponse>
      >[] = instrumentsWithDeposit.map(instrument =>
        createAnalyzeForecast(
          qAuthAccessToken,
          cancelTokenSource.token,
          {
            confidence_interval: DEFAULT_CONFIDENCE,
            initial_deposit: instrument.initialDeposit,
            monthly_saving: instrument.monthlySaving,
            portfolio: [{ id: instrument.id, weight: 100 }],
            time_horizon: 10
          },
          {
            namespace_id: sessionStoreState.defaultConfigNamespaceId
          }
        )
      );

      const forecastResponse = await Promise.all(forecastPromises);
      forecastResponse.forEach(({ data }, index) => {
        orderProposalData[instrumentsWithDeposit[index].id].expectedValue =
          data.forecast[data.forecast.length - 1].value;
      });

      const riskReturnResponse = await Promise.all(riskReturnPromises);
      riskReturnResponse.forEach(({ data }, index) => {
        orderProposalData[
          instrumentsWithDeposit[index].id
        ].expectedAnnualReturn = data.return;
      });
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.orderExecution.readInstrumentsDataErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );
        throwSafeError(error);
      }
    }

    return orderProposalData;
  };

  return readOrderData;
}
