import { Form, FormSpy } from 'react-final-form';
import { Prompt } from 'react-router';

import { Statuses } from '../../../shared/constants';
import { ANALYTICS_LINKS, GOAL_TABLE_LINKS } from '../constants';
import { useProposalStore } from '../services/proposalStore';
import { ProposalsForm } from '../services/proposalsForm';
import { useInitPage } from './useInitPage';
import { useUpdateSettings } from './useUpdateSettings';
import Placeholder from 'features/admin/appAdmin/shared/components/placeholder';
import Button from 'features/shared/components/button';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import FinalFormSwitch from 'features/sharedModules/finalForm/components/switch';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  switch: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  root: {
    width: '40px',
    height: '20px',
    padding: 0
  },
  switchBase: {
    padding: '2px',
    color: theme.primaryColor,
    '&$checked': {
      color: theme.primaryColor,
      '& + $track': {
        backgroundColor: theme.accentColor,
        opacity: 1,
        border: 'none'
      }
    },
    '&$disabled$checked + $track': {
      backgroundColor: theme.inputFillFocusColor
    }
  },
  thumb: {
    width: Spacing.spacing01,
    height: Spacing.spacing01
  },
  track: {
    borderRadius: '10px',
    backgroundColor: theme.secondaryColor,
    opacity: 1
  },
  checked: {},
  disabled: {},
  focusVisible: {},

  proposalDiv: {},
  analyticDiv: {
    marginTop: Spacing.spacing04
  },
  switchDiv: {
    marginBottom: Spacing.spacing01,
    fontSize: Typography.body1.size,
    fontWeight: FontWeights.normal,
    whiteSpace: 'noWrap'
  },
  header: {
    fontSize: Typography.heading1.size,
    fontWeight: FontWeights.medium,
    margin: 0,
    marginBottom: Spacing.spacing02
  },
  header2: {
    marginBottom: '26px',
    fontSize: Typography.heading2.size,
    fontWeight: FontWeights.medium
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    margin: 0,
    marginLeft: '117px',
    color: theme.primaryColor
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: Spacing.spacing04
  }
}));

const ProposalForm = () => {
  const i18n = useI18n();
  const classes = useStyles();
  const proposalStore = useProposalStore();
  const updateSettings = useUpdateSettings();

  useInitPage();

  const styles = {
    root: classes.root,
    switchBase: classes.switchBase,
    thumb: classes.thumb,
    track: classes.track,
    checked: classes.checked,
    disabled: classes.disabled
  };

  return proposalStore.pageStatuses.readSettings !== Statuses.succeed ? (
    <Placeholder />
  ) : (
    <div className={classes.content}>
      <div className={classes.proposalDiv}>
        <h1 className={classes.header}>
          {i18n('admin.appAdmin.advisorSolution.navigation.navItems.proposal')}
        </h1>
      </div>
      <div className={classes.proposalDiv}>
        <h2 className={classes.header2}>
          {i18n(
            'admin.appAdmin.advisorSolution.proposal.sectionHeader.goalTable'
          )}
        </h2>
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <div>
              {GOAL_TABLE_LINKS.map(({ config, translation }, index) => (
                <div key={'goalTable' + index} className={classes.switchDiv}>
                  <FinalFormSwitch
                    key={'goalTable' + index}
                    name={`[${config}]`}
                    label={i18n(translation)}
                    fieldConfig={{}}
                    classes={styles}
                    layout={'horizontalReversed'}
                  />
                </div>
              ))}
            </div>
          )}
        </FormSpy>
      </div>
      <div className={classes.analyticDiv}>
        <h2 className={classes.header2}>
          {i18n(
            'admin.appAdmin.advisorSolution.proposal.sectionHeader.analyticsComponents'
          )}
        </h2>
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <div>
              {ANALYTICS_LINKS.map(({ config, translation }, index) => (
                <div key={'analytics' + index} className={classes.switchDiv}>
                  <FinalFormSwitch
                    key={'analytics' + index}
                    flippedValues={true}
                    name={`[${config}]`}
                    label={i18n(translation)}
                    classes={styles}
                    fieldConfig={{}}
                    layout={'horizontalReversed'}
                  />
                </div>
              ))}
            </div>
          )}
        </FormSpy>

        <FormSpy subscription={{ pristine: true, hasValidationErrors: true }}>
          {({ pristine, hasValidationErrors }) => (
            <div className={classes.actionButtons}>
              <Button
                disabled={
                  pristine ||
                  proposalStore.pageStatuses.updateSettings === Statuses.pending
                }
                onClick={() => {
                  ProposalsForm.submit();

                  if (!hasValidationErrors) {
                    updateSettings();
                  }
                }}
              >
                {i18n('admin.save')}
              </Button>
            </div>
          )}
        </FormSpy>
      </div>
    </div>
  );
};

const Proposals = () => {
  return (
    <>
      <Prompt
        when={true}
        message={() => {
          const { pristine } = ProposalsForm.getState();

          return pristine ? true : 'confirmation required';
        }}
      />
      <Form form={ProposalsForm} onSubmit={() => {}}>
        {() => <ProposalForm />}
      </Form>
    </>
  );
};

export default Proposals;
