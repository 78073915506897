import * as R from 'ramda';

import { parseDate, getPlaceholder } from 'features/shared/utils/dateTime.js';

export const getTitleValidationError = (value, i18n) => {
  if (R.isNil(value) || R.isEmpty(value)) {
    return i18n(
      'customers.customerManagement.customerNameEmptyValidationError'
    );
  }

  return null;
};

export const getModuleTypeValidationError = R.curry((licenses, value, i18n) => {
  if (R.isNil(value) || R.isEmpty(value)) {
    return i18n('customers.customerManagement.moduleTypeEmptyValidationError');
  }

  const valuesNumber = R.pipe(
    R.filter(l => l.moduleType === value),
    R.length
  )(licenses || []);
  if (valuesNumber > 1) {
    return i18n(
      'customers.customerManagement.moduleTypeDuplicateValidationError'
    );
  }

  return null;
});

export const getLicenseTypeValidationError = R.curry(
  (moduleType, value, i18n) => {
    if (R.isNil(moduleType)) {
      return null;
    }

    if (R.isNil(value) || R.isEmpty(value)) {
      return i18n(
        'customers.customerManagement.licenseTypeEmptyValidationError'
      );
    }

    return null;
  }
);

export const getLicenseRenewalDateValidationError = R.curry(
  (cultureCode, moduleType, value, i18n) => {
    if (R.isNil(moduleType)) {
      return null;
    }

    if (R.isNil(value) || R.isEmpty(value)) {
      return i18n(
        'customers.customerManagement.licenseRenewalDateEmptyValidationError'
      );
    }

    const date = parseDate(cultureCode, value);
    if (R.isNil(date)) {
      const placeholder = getPlaceholder(cultureCode);
      return i18n(
        'customers.customerManagement.licenseRenewalDateInvalidFormatValidationError'
      ).replace('{0}', placeholder);
    }

    return null;
  }
);

export const getCustomerValidateableFields = (customer = {}) => {
  return R.concat(
    ['title'],
    R.chain(
      index => [
        `licenses[${index}].moduleType`,
        `licenses[${index}].licenseType`,
        `licenses[${index}].licenseRenewalDate`
      ],
      R.range(0, customer.licenses.length)
    )
  );
};

export const getIsCustomerValid = (cultureCode, customer = {}, i18n) => {
  return (
    R.isNil(getTitleValidationError(customer.title, i18n)) &&
    R.all(
      license =>
        R.isNil(
          getModuleTypeValidationError(
            customer.licenses,
            license.moduleType,
            i18n
          )
        ) &&
        R.isNil(
          getLicenseTypeValidationError(
            license.moduleType,
            license.licenseType,
            i18n
          )
        ) &&
        R.isNil(
          getLicenseRenewalDateValidationError(
            cultureCode,
            license.moduleType,
            license.licenseRenewalDate,
            i18n
          )
        ),
      customer.licenses
    )
  );
};
