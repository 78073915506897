import classNames from 'classnames';
import * as R from 'ramda';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { v4 } from 'uuid';

import {
  getModuleTypeValidationError,
  getLicenseTypeValidationError,
  getLicenseRenewalDateValidationError
} from '../services/selectors/editCustomerForm.js';
import Button from 'features/shared/components/button';
import Dropdown from 'features/shared/components/dropdown/index';
import ErrorMessage from 'features/shared/components/errorMessage/index.js';
import { Field } from 'features/shared/components/form/index.js';
import Icon from 'features/shared/components/icon/index.js';
import Table from 'features/shared/components/table/table.js';
import TableBody from 'features/shared/components/table/tableBody.js';
import TableBodyRow from 'features/shared/components/table/tableBodyRow.js';
import TextInput from 'features/shared/components/textInput/index';
import { ModuleTypes, LicenseTypes } from 'features/shared/constants/session';
import formSelectors from 'features/shared/services/form/selectors.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { getPlaceholder } from 'features/shared/utils/dateTime.js';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const indexedMap = R.addIndex(R.map);

const getEditCustomerFormValues = getFormValues(
  'editCustomerForm_state',
  formSelectors.getForms
);

const useStyles = createUseStyles({
  table: {
    marginTop: '3rem',
    marginBottom: '3rem'
  },
  moduleTypeCell: {
    verticalAlign: 'top'
  },
  licenseTypeCell: {
    verticalAlign: 'top',
    width: '26.6rem'
  },
  licenseRenewalDateCell: {
    verticalAlign: 'top',
    width: '28.3rem'
  },
  // moduleTypeContainer: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   height: '4.4rem'
  // },
  moduleTypeMenuContainer: {
    width: '100%'
  },
  licenseRenewalDateMenuContainer: {
    width: '100%'
  },
  licenseTypeMenuContainer: {
    width: '100%'
  },
  deleteIcon: {
    fontSize: '20px',
    lineHeight: '44px',
    cursor: 'pointer',
    transform: 'rotate(0.03deg)' // fix of issue: icon small size causes pixelated icon
  },
  deleteCell: {
    verticalAlign: 'top',
    width: '8rem'
  },
  addSubscriptionActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1.6rem'
  }
});

const Licenses = ({ fields, pageClasses }) => {
  const i18n = useI18n();
  const classes = useStyles();
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  // eslint-disable-next-line no-unused-vars
  const formValues = useSelector(getEditCustomerFormValues); // formValues selected to rerender on form value change

  const licenses = fields.getAll();

  const ModuleTypeOptions = [
    {
      key: ModuleTypes.advisorSolution,
      title: i18n('shared.advisorSolution')
    }
  ];
  const LicenseTypeOptionsByModule = {
    [ModuleTypes.advisorSolution]: [
      {
        key: LicenseTypes.basic,
        title: i18n('shared.basic')
      }
    ]
  };

  const licenseTypeOptionsByModule = LicenseTypeOptionsByModule;
  const moduleTypeOptions = ModuleTypeOptions;

  return (
    <Fragment>
      <div className={pageClasses.section}>
        <span className={pageClasses.label}>{i18n('shared.subscription')}</span>
      </div>
      <div className={classes.table}>
        <Table
          header={[
            {
              title: i18n('shared.module')
            },
            {
              title: i18n('shared.license')
            },
            {
              title: i18n('customers.customerManagement.licenseRenewalDate')
            },
            {
              title: ''
            }
          ]}
        >
          <TableBody
            noDataTitle={i18n('customers.customerManagement.noModules')}
            cellsNumber={4}
          >
            {indexedMap((field, index) => {
              const license = fields.get(index);

              return (
                <TableBodyRow key={license.renderId}>
                  {({ bodyRowCellClassName }) => (
                    <Fragment>
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.moduleTypeCell
                        )}
                      >
                        <Field
                          name={`${field}.moduleType`}
                          component={ReduxFormDropdown}
                          options={moduleTypeOptions}
                          inputProps={{
                            placeholder: i18n(
                              'customers.customerManagement.selectModule'
                            )
                          }}
                          className={classes.moduleTypeMenuContainer}
                          getValidationError={getModuleTypeValidationError(
                            licenses
                          )}
                        />
                      </td>
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.licenseTypeCell
                        )}
                      >
                        <Field
                          name={`${field}.licenseType`}
                          disabled={R.isNil(license.moduleType)}
                          component={ReduxFormDropdown}
                          options={R.propOr(
                            [],
                            license.moduleType,
                            licenseTypeOptionsByModule
                          )}
                          inputProps={{
                            placeholder: i18n(
                              'customers.customerManagement.selectLicense'
                            )
                          }}
                          className={classes.licenseTypeMenuContainer}
                          getValidationError={getLicenseTypeValidationError(
                            license.moduleType
                          )}
                        />
                      </td>
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.licenseRenewalDateCell
                        )}
                      >
                        <Field
                          name={`${field}.licenseRenewalDate`}
                          disabled={R.isNil(license.moduleType)}
                          component={ReduxFormTextInput}
                          placeholder={getPlaceholder(cultureCode)}
                          className={classes.licenseRenewalDateMenuContainer}
                          getValidationError={getLicenseRenewalDateValidationError(
                            cultureCode,
                            license.moduleType
                          )}
                        />
                      </td>
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.deleteCell
                        )}
                      >
                        <Icon
                          className={classes.deleteIcon}
                          onClick={() => fields.remove(index)}
                          type="delete"
                        />
                      </td>
                    </Fragment>
                  )}
                </TableBodyRow>
              );
            }, fields)}
          </TableBody>
        </Table>
        <div className={classes.addSubscriptionActions}>
          <Button
            onClick={() => {
              fields.push({
                renderId: v4(),
                moduleType: null,
                licenseType: null,
                licenseRenewalDate: null
              });
            }}
          >
            {i18n('shared.add')}
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default Licenses;

const ReduxFormTextInput = ({
  input: { value, onChange, onFocus, onBlur },
  meta: { touched },
  placeholder,
  className,
  disabled,
  getValidationError
}) => {
  const i18n = useI18n();
  const error = getValidationError && getValidationError(value, i18n);
  const isErrorShown = touched && !R.isNil(error) && !R.isEmpty(error);

  return (
    <div>
      <TextInput
        value={value || ''}
        disabled={disabled}
        onChange={e => onChange(e.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        className={className}
        isInvalid={isErrorShown}
      />
      {isErrorShown && <ErrorMessage>* {error}</ErrorMessage>}
    </div>
  );
};

const ReduxFormDropdown = ({
  input: { value, onChange, onFocus, onBlur },
  meta: { touched },
  options,
  inputProps,
  menuProps,
  getValidationError,
  noDataTitle,
  className,
  disabled
}) => {
  const i18n = useI18n();
  const error = getValidationError && getValidationError(value, i18n);
  const isErrorShown = touched && !R.isNil(error) && !R.isEmpty(error);

  return (
    <div>
      <Dropdown
        className={className}
        value={R.isNil(value) ? null : value}
        disabled={disabled}
        onChange={onChange}
        options={options}
        inputProps={{
          ...inputProps,
          onFocus,
          onBlur,
          isInvalid: isErrorShown
        }}
        menuProps={menuProps}
        noDataTitle={noDataTitle}
      />
      {isErrorShown && <ErrorMessage>* {error}</ErrorMessage>}
    </div>
  );
};
