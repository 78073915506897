import create from 'zustand';

import { PageStatus } from '../../shared/components/useReadDataListener';
import { OrderExecutionCost } from '../components/order/cost/useReadOrderExecutionCostData';
import { OrderExecutionExpectedValue } from '../components/order/expectedValue/useReadOrderExecutionExpectedValueData';

export type PageStatuses = {
  readCostData?: PageStatus;
  readExpectedValueData?: PageStatus;
};

export type OrderPageStoreData = {
  costData: OrderExecutionCost[];
  expectedValueData: OrderExecutionExpectedValue[];
  isGenerateReportPopupOpen: boolean;
  isReadPdfReportPending: boolean;
  isGenerateNewPdfReportPopupOpen: boolean;
  isReadNewPdfReportPending: boolean;
  pageStatuses: PageStatuses;
};

export type OrderPageStoreApi = {
  reset: () => void;
  initialize: (initialData: Partial<OrderPageStoreData>) => void;
  setPageStatus: (key: keyof PageStatuses, value: PageStatus) => void;
  changePageStatuses: (pageStatuses: Partial<PageStatuses>) => void;
  setIsGenerateReportPopupOpen: (isGenerateReportPopupOpen: boolean) => void;
  setIsReadPdfReportPending: (isReadPdfReportPending: boolean) => void;
  setIsGenerateNewPdfReportPopupOpen: (
    isGenerateNewPdfReportPopupOpen: boolean
  ) => void;
  setIsReadNewPdfReportPending: (isReadNewPdfReportPending: boolean) => void;
  setCostData: (costData: OrderExecutionCost[]) => void;
  setExpectedValueData: (
    expectedValueData: OrderExecutionExpectedValue[]
  ) => void;
};

export type OrderPageStoreState = OrderPageStoreData & OrderPageStoreApi;

export const defaultData = {
  costData: [],
  expectedValueData: [],
  pageStatuses: {},
  isGenerateReportPopupOpen: false,
  isReadPdfReportPending: false,
  isGenerateNewPdfReportPopupOpen: false,
  isReadNewPdfReportPending: false
};

export const useOrderPageStore = create<OrderPageStoreState>(set => ({
  ...defaultData,
  reset: () => {
    set(defaultData);
  },
  initialize(initialData) {
    set(
      state => ({
        ...state,
        ...initialData
      }),
      true
    );
  },
  setCostData(costData) {
    set({ costData });
  },
  setExpectedValueData(expectedValueData) {
    set({ expectedValueData });
  },
  setPageStatus(key, value) {
    set(state => ({
      pageStatuses: { ...state.pageStatuses, [key]: value }
    }));
  },
  changePageStatuses(pageStatuses) {
    set(state => ({
      pageStatuses: { ...state.pageStatuses, ...pageStatuses }
    }));
  },
  setIsGenerateReportPopupOpen(isGenerateReportPopupOpen) {
    set({ isGenerateReportPopupOpen });
  },
  setIsReadPdfReportPending(isReadPdfReportPending) {
    set({ isReadPdfReportPending });
  },
  setIsGenerateNewPdfReportPopupOpen(isGenerateNewPdfReportPopupOpen) {
    set({ isGenerateNewPdfReportPopupOpen });
  },
  setIsReadNewPdfReportPending(isReadNewPdfReportPending) {
    set({ isReadNewPdfReportPending });
  }
}));
