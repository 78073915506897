import React from 'react';

import { useForm as useRoboAdviceForm } from '../../form/services/form';
import { useIsSessionReadOnly } from '../../main/services/selectors';
import Widget from '../../shared/components/widget';
import { FieldSections, getFinancialSituationFields } from '../constants';
import { usePersonDebtTotal } from '../services/selectors';
import { RenderFields } from './shared.js';
import { ClientTypes } from 'features/shared/constants/session';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import InputErrorMessage from 'features/sharedModules/errors/components/inputErrorMessage';
import { ErrorMessage } from 'features/sharedModules/errors/types';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  errorsContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

type Props = {
  hasError: boolean;
  errors: ErrorMessage[];
};

const PersonDebt = ({ hasError, errors }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const {
    roboAdviceForm: {
      financialSituation: {
        debt: { person: personDebt },
        oneFieldInDebtRequired
      }
    }
  } = useCustomerConfig();
  const fields = getFinancialSituationFields(
    personDebt,
    FieldSections.personDebt,
    ClientTypes.person
  );
  const total = usePersonDebtTotal({
    useRoboAdviceForm,
    personDebtFields: fields
  });
  const isSessionReadOnly = useIsSessionReadOnly();

  return (
    <div className={classes.root}>
      <div className={classes.errorsContainer}>
        {errors.map(error => (
          <InputErrorMessage message={error.alertMessage} />
        ))}
      </div>
      <Widget
        header={i18n('roboAdvice.financialSituation.debt')}
        total={{
          label: i18n('roboAdvice.financialSituation.sumDebt'),
          value: total
        }}
        required={oneFieldInDebtRequired}
        doShowFooterSeparator={true}
        elementId={'person-debt'}
        hasError={hasError}
      >
        <RenderFields
          fields={fields}
          adjustElement={(field, element) =>
            React.cloneElement(element, {
              disabled: isSessionReadOnly
            })
          }
        />
      </Widget>
    </div>
  );
};

export default PersonDebt;
