import chroma from 'chroma-js';
import * as R from 'ramda';
import * as React from 'react';
import { useFormState } from 'react-final-form';

import { visualsForm } from '../services/visualsForm';

export function useGenerateSecondaryShades() {
  const { values, errors, dirty } = useFormState({
    subscription: { values: true, errors: true, dirty: true }
  });

  const isModifiedRef = React.useRef(false);
  React.useEffect(() => {
    isModifiedRef.current = isModifiedRef.current || dirty;
  }, [dirty]);

  useGenerateColorShades(
    isModifiedRef,
    errors?.accentColor,
    values.accentColor,
    getAccentColorShades
  );

  useGenerateColorShades(
    isModifiedRef,
    errors?.primaryColor,
    values.primaryColor,
    getPrimaryColorShades
  );

  useGenerateColorShades(
    isModifiedRef,
    errors?.pageBackgroundColor,
    values.pageBackgroundColor,
    getPageBackgroundColorShades
  );
}

function useGenerateColorShades(
  isModifiedRef,
  colorError,
  colorValue,
  getColorShades
) {
  React.useEffect(() => {
    if (!isModifiedRef.current || !R.isNil(colorError)) {
      return;
    }

    const timeoutId = setTimeout(() => {
      const colorShades = getColorShades(colorValue);

      visualsForm.batch(() => {
        colorShades.map(s => {
          visualsForm.change(s.name, s.value);
        });
      });
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [colorError, colorValue]);
}

function getAccentColorShades(colorValue) {
  return [
    {
      name: 'accentHoverColor',
      value: getAccentColorShade(
        {
          getH: h => h,
          getS: s => (s > 50 ? s * 0.5 : s * 2),
          getL: l => l
        },
        colorValue
      )
    },
    {
      name: 'accentFocusColor',
      value: getAccentColorShade(
        {
          getH: h => h,
          getS: s => (s > 50 ? s * 0.7 : s * 1.3),
          getL: l => l
        },
        colorValue
      )
    },
    {
      name: 'defaultButtonTextColor',
      value: getAccentColorShade(
        {
          getH: h => 0,
          getS: s => 0,
          getL: l => (l > 50 ? 0 : l * 2)
        },
        colorValue
      )
    }
  ];
}

function getAccentColorShade({ getH, getS, getL }, hex) {
  const [hue, saturation, lightness] = chroma(hex).hsl();

  return chroma
    .hsl(
      getH(isNaN(hue) ? 0 : hue),
      getS(saturation * 100) / 100,
      getL(lightness * 100) / 100
    )
    .hex()
    .toUpperCase();
}

function getPrimaryColorShades(colorValue) {
  return [
    {
      name: 'secondaryColor',
      value: getPrimaryColorShade(
        {
          getL: l => (l > 50 ? l * 0.5 : l + 30)
        },
        colorValue
      )
    },
    {
      name: 'disabledTextColor',
      value: getPrimaryColorShade(
        {
          getL: l => (l > 50 ? l * 0.5 - 10 : l + 40)
        },
        colorValue
      )
    },
    {
      name: 'tertiaryTextColor',
      value: getPrimaryColorShade(
        {
          getL: l => (l > 50 ? l * 0.5 - 20 : l + 50)
        },
        colorValue
      )
    }
  ];
}

function getPrimaryColorShade({ getL }, hex) {
  const [hue, saturation, lightness] = chroma(hex).hsl();

  return chroma
    .hsl(isNaN(hue) ? 0 : hue, saturation, getL(lightness * 100) / 100)
    .hex()
    .toUpperCase();
}

function getPageBackgroundColorShades(colorValue) {
  return [
    {
      name: 'boxBackgroundColor_1',
      value: getPageBackgroundColorShade(
        {
          h: -3,
          s: 1,
          l: 1
        },
        colorValue
      )
    },
    {
      name: 'boxBackgroundColor_2',
      value: getPageBackgroundColorShade(
        {
          h: -2,
          s: -8,
          l: 8
        },
        colorValue
      )
    },
    {
      name: 'defaultInputFillColor',
      value: getPageBackgroundColorShade(
        {
          h: 0,
          s: -1,
          l: 2
        },
        colorValue
      )
    },
    {
      name: 'inputFillFocusColor',
      value: getPageBackgroundColorShade(
        {
          h: -3,
          s: 18,
          l: 9
        },
        colorValue
      )
    },
    {
      name: 'itemBackgroundColor_1',
      value: getPageBackgroundColorShade(
        {
          h: -1,
          s: -2,
          l: 4
        },
        colorValue
      )
    },
    {
      name: 'itemBackgroundColor_2',
      value: getPageBackgroundColorShade(
        {
          h: -3,
          s: -3,
          l: 7
        },
        colorValue
      )
    },
    {
      name: 'disabledBackgroundColor',
      value: getPageBackgroundColorShade(
        {
          h: -2,
          s: -8,
          l: 8
        },
        colorValue
      )
    },
    {
      name: 'hoverInputStrokeColor',
      value: getPageBackgroundColorShade(
        {
          h: -3,
          s: +21,
          l: -9
        },
        colorValue
      )
    }
  ];
}

function getPageBackgroundColorShade({ h, s, l }, hex) {
  const [hue, saturation, lightness] = chroma(hex).hsl();

  try {
    return chroma
      .hsl(
        getLoopValue(0, 360, isNaN(hue) ? 0 : hue, h),
        getRangeValue(0, 100, saturation * 100, s) / 100,
        getRangeValue(0, 100, lightness * 100, l) / 100
      )
      .hex()
      .toUpperCase();
  } catch (error) {
    return '#FFFFFF';
  }
}

function getLoopValue(min, max, originalValue, offset) {
  const sum = originalValue + offset;
  const rangeDiff = max - min;

  if (sum < min) {
    return sum + rangeDiff;
  } else if (sum > max) {
    return sum - rangeDiff;
  } else {
    return sum;
  }
}

function getRangeValue(min, max, originalValue, offset) {
  const isInRange = v => v >= min && v <= max;
  const sum = originalValue + offset;
  const diff = originalValue - offset;

  if (isInRange(sum)) {
    return sum;
  } else if (isInRange(diff)) {
    return diff;
  } else {
    throw Error('Value is out of range');
  }
}
