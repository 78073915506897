import { equals } from 'ramda';
import { useEffect } from 'react';

import { form as roboAdviceForm } from '../../../../../roboAdvice/adviceSession/form/services/form';
import { useTargetMarketAssessmentStore } from 'features/roboAdvice/adviceSession/orderExecution/services/targetMarketAssessmentStore';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';

export const useInitPage = () => {
  const { setAdvancedSuitabilityDataStatus } = useTargetMarketAssessmentStore();

  useEffect(() => {
    let suitabilityOldData = roboAdviceForm.getState().values.suitability;

    const unsubscribe = roboAdviceForm.subscribe(
      ({ values }) => {
        if (values.suitability) {
          const hasSuitabilityChanged = !equals(
            suitabilityOldData,
            values.suitability
          );

          if (hasSuitabilityChanged) {
            setAdvancedSuitabilityDataStatus(PageStatuses.ready);
          }

          suitabilityOldData = values.suitability;
        }
      },
      { values: true }
    );

    return () => {
      unsubscribe();
    };
  }, []);
};
