import * as React from 'react';

import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  icon: {
    fill: theme.accentColor
  }
}));

const CompanyIcon = () => {
  const classes = useStyles();

  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 5.75V0.75H0.5V23.25H25.5V5.75H13ZM5.5 20.75H3V18.25H5.5V20.75ZM5.5 15.75H3V13.25H5.5V15.75ZM5.5 10.75H3V8.25H5.5V10.75ZM5.5 5.75H3V3.25H5.5V5.75ZM10.5 20.75H8V18.25H10.5V20.75ZM10.5 15.75H8V13.25H10.5V15.75ZM10.5 10.75H8V8.25H10.5V10.75ZM10.5 5.75H8V3.25H10.5V5.75ZM23 20.75H13V18.25H15.5V15.75H13V13.25H15.5V10.75H13V8.25H23V20.75ZM20.5 10.75H18V13.25H20.5V10.75ZM20.5 15.75H18V18.25H20.5V15.75Z"
        className={classes.icon}
      />
    </svg>
  );
};

export default CompanyIcon;
