import createTableModule from 'features/sharedModules/table/services/main';

export const adviceSessionsTable = createTableModule(
  'trashCanAdviceSessionsTable'
);

export const OrderKeys = {
  name: 'name',
  clientName: 'clientName',
  advisorName: 'advisorName',
  lastActivityDate: 'lastActivityDate',
  softDeleteDate: 'softDeleteDate'
} as const;
