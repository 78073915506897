import classNames from 'classnames';
import React from 'react';

import { ReactComponent as CheckIcon } from 'assets/checkmark2.svg';
import { ReactComponent as CrossIcon } from 'assets/x-icon.svg';
import { FundNameForPresentation } from 'features/roboAdvice/adviceSession/shared/constants';
import { FundAnalysis } from 'features/roboAdvice/adviceSession/sustainability';
import SimpleTable from 'features/shared/components/table/simpleTable';
import Tooltip from 'features/shared/components/tooltip';
import { processImgURL } from 'features/shared/utils/processImgURL';
import { getTranslation } from 'features/shared/utils/translations';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  table: {
    marginTop: 30,
    tableLayout: 'fixed'
  },
  center: {
    textAlign: 'center'
  },
  checkIcon: {
    fill: theme.successColor
  },
  crossIcon: {
    fill: theme.errorNegativeColor
  },
  iconHeader: {
    height: '40px',
    width: '40px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    margin: 'auto'
  },
  fundName: {
    width: 300,
    height: 130
  }
}));

type Props = {
  fundAnalysisData: FundAnalysis[];
  preferenceCriteria: string[] | undefined;
  sustainabilityPreference: {
    icon: string;
    id: string;
    title: Record<string, string>;
  }[];
};

const PreferenceCriteriaTable = ({
  fundAnalysisData,
  preferenceCriteria,
  sustainabilityPreference
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const {
    tenantSettings: { fundNameForPresentation }
  } = useCustomerConfig();

  const selectedSustainabilityPreference = React.useMemo(
    () =>
      sustainabilityPreference.filter(({ id }) =>
        preferenceCriteria?.includes(id)
      ),
    [preferenceCriteria, sustainabilityPreference]
  );

  return (
    <SimpleTable
      className={classes.table}
      headers={({ headerCellClassName }) => (
        <>
          <th className={classNames(headerCellClassName, classes.fundName)}>
            {i18n('roboAdvice.proposal.sustainability.weightedTable.fund')}
          </th>

          {selectedSustainabilityPreference.map(({ icon, id, title }) => (
            <th
              key={id}
              className={classNames(headerCellClassName, classes.center)}
            >
              {icon ? (
                <Tooltip
                  content={getTranslation(title)}
                  infoTooltip
                  trigger={
                    <div
                      style={{
                        backgroundImage: `url(${processImgURL(icon)})`
                      }}
                      className={classes.iconHeader}
                    />
                  }
                  triggerButtonStyles={{ margin: 'auto' }}
                />
              ) : (
                getTranslation(title)
              )}
            </th>
          ))}
        </>
      )}
      body={({ rowClassName, cellClassName }) => (
        <>
          {fundAnalysisData.map(
            ({ name, fundStandardName, preferenceValues }) => (
              <tr key={name} className={classNames(rowClassName)}>
                <td className={cellClassName}>
                  {fundNameForPresentation ===
                    FundNameForPresentation.fundStandardName && fundStandardName
                    ? fundStandardName
                    : name}
                </td>
                {selectedSustainabilityPreference.map(({ id }) => (
                  <td
                    key={id}
                    className={classNames(cellClassName, classes.center)}
                  >
                    {preferenceValues[id] !== null ? (
                      preferenceValues[id] ? (
                        <CheckIcon className={classes.checkIcon} />
                      ) : (
                        <CrossIcon className={classes.crossIcon} />
                      )
                    ) : null}
                  </td>
                ))}
              </tr>
            )
          )}
        </>
      )}
    />
  );
};

export default PreferenceCriteriaTable;
