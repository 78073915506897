import { isNil, path, split } from 'ramda';

import { useForm as useRoboAdviceForm } from '../../../roboAdvice/adviceSession/form/services/form';
import { getTranslation } from '../../../shared/utils/translations';
import { useCustomerConfig } from '../../customerConfig/components/useCustomerConfig';
import { useI18n } from '../../customerConfig/components/useI18n';
import { ErrorMessage, OrderInformationErrors } from '../types';

export const useGetOrderInformationErrors = (): OrderInformationErrors => {
  const i18n = useI18n();
  const roboAdviceFormState = useRoboAdviceForm();
  const {
    roboAdviceForm: { errorMessagesEnabled },
    roboOrderFlow: { orderInformationFields }
  } = useCustomerConfig();

  return {
    ...getFieldsErrors({
      roboAdviceFormState,
      orderInformationFields,
      i18n,
      errorMessagesEnabled
    })
  };
};

const getFieldsErrors = ({
  roboAdviceFormState,
  orderInformationFields,
  i18n,
  errorMessagesEnabled
}): { [key: string]: ErrorMessage[] } => {
  const fieldsErrors: { [key: string]: ErrorMessage[] } = {};

  const requiredFields = orderInformationFields.filter(
    ({ required }) => required
  );

  const textFields = orderInformationFields
    .map(field =>
      field.items
        ? field.items.filter(a => {
            const value = path(
              field.name.split('.'),
              roboAdviceFormState.values
            );
            return (
              (Array.isArray(value)
                ? value.includes(a.activeValue)
                : a.activeValue === value) &&
              a.textField &&
              a.textField?.required
            );
          })
        : []
    )
    .filter(f => f.length > 0);

  if (errorMessagesEnabled) {
    [...requiredFields, ...textFields].forEach(field => {
      if (Array.isArray(field)) {
        field.forEach(item => {
          if (
            !isNil(
              path(split('.', item.textField?.name), roboAdviceFormState.errors)
            )
          ) {
            fieldsErrors[item.textField?.name] = [
              {
                alertMessage: i18n('roboAdvice.errors.missingAnswerAlert'),
                listMessage: i18n('roboAdvice.errors.missingAnswer').replace(
                  '{0}',
                  getTranslation(item.label)
                ),
                elementId: item.textField?.name
              }
            ];
          }
        });
      } else if (
        !isNil(path(split('.', field?.name), roboAdviceFormState.errors))
      ) {
        fieldsErrors[field.name] = [
          {
            alertMessage: i18n('roboAdvice.errors.missingAnswerAlert'),
            listMessage: i18n('roboAdvice.errors.missingAnswer').replace(
              '{0}',
              getTranslation(field.label)
            ),
            elementId: field.name
          }
        ];
      }
    });
  }

  return fieldsErrors;
};
