import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  secondLabel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: Spacing.spacing01,
    padding: '0 10px',
    borderRadius: 20,
    fontSize: Typography.subtitle.size,
    fontWeight: FontWeights.medium
  }
});

type Props = {
  className?: string;
  text?: string;
  icon?: ReactNode;
};

const RoundEdgeLabel = ({ className, text, icon }: Props) => {
  const classes = useStyles();

  return (
    <span className={classNames(classes.secondLabel, className)}>
      {icon && icon}
      {text && text}
    </span>
  );
};

export default RoundEdgeLabel;
