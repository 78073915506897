import { map, slice, view, curryN, pipe } from 'ramda';
import * as R from 'ramda';

export const globalizeSelector = path => {
  const lens = R.lensPath(path);

  return selector => {
    return (...args) => {
      let state = args[args.length - 1];
      let extraArgs = slice(0, args.length - 1, args);

      let localState = view(lens, state);
      return selector(...extraArgs, localState);
    };
  };
};

export const currySelector = selectorLength => selector => {
  return curryN(selectorLength, selector);
};

export const globalizeSelectors = (path, selectors) => {
  return map(
    selector =>
      pipe(globalizeSelector(path), currySelector(selector.length))(selector),
    selectors
  );
};
