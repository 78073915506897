import { useEffect } from 'react';

import { useAdviceSessionStore } from '../../shared/services/adviceSessionStore';
import { useReadAdvancedSuitabilityStatus } from 'features/roboAdvice/adviceSession/orderExecution/components/useReadAdvancedSuitabilityStatus';
import { useTargetMarketAssessmentStore } from 'features/roboAdvice/adviceSession/orderExecution/services/targetMarketAssessmentStore';
import { useSessionStore } from 'features/roboAdvice/adviceSession/session/services/sessionStore';
import useReadCustomAttributesData from 'features/roboAdvice/adviceSession/shared/components/useReadCustomAttributesData';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { areProductAttributesEnabled } from 'features/roboAdvice/adviceSession/shared/services/selectors';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';

const useInitOrderExecution = () => {
  const {
    roboOrderFlow: {
      proposal: { summaryTable }
    },
    roboAdvice: {
      orderExecution: { instrumentsList }
    }
  } = useCustomerConfig();
  const sessionStoreState = useSessionStore.getState();
  const readCustomAttributesData = useReadCustomAttributesData();
  const readAdvancedSuitabilityStatus = useReadAdvancedSuitabilityStatus();
  readAdvancedSuitabilityStatus();

  useEffect(() => {
    if (
      (areProductAttributesEnabled(summaryTable) ||
        areProductAttributesEnabled(instrumentsList)) &&
      sessionStoreState.defaultConfigNamespaceId
    ) {
      readCustomAttributesData(sessionStoreState.defaultConfigNamespaceId);
    }
  }, [
    summaryTable,
    instrumentsList,
    sessionStoreState.defaultConfigNamespaceId,
    readCustomAttributesData
  ]);

  useEffect(() => {
    useTargetMarketAssessmentStore
      .getState()
      .setAdvancedSuitabilityDataStatus(PageStatuses.ready);

    return () => {
      useAdviceSessionStore.getState().reset();
      useTargetMarketAssessmentStore.getState().reset();
    };
  }, []);
};

export default useInitOrderExecution;
