import classNames from 'classnames';
import * as R from 'ramda';
import React from 'react';

import { useTable } from './context.js';
import Checkbox from 'features/shared/components/checkbox/index';

const TableBodyRow = ({
  children,
  index,
  onClick,
  id,
  selectedRowIds,
  changeRowSelection,
  isCssParity,
  className,
  elementId,
  hoverEffect,
  ...restProps
}) => {
  const { classes, isSelectionShown } = useTable();
  selectedRowIds = selectedRowIds || [];
  const isEven = index % 2 === 0;

  return (
    <tr
      className={classNames(
        classes.bodyRow,
        {
          [classes.cssParity]: isCssParity,
          [classes.even]: !isCssParity && isEven,
          [classes.odd]: !isCssParity && !isEven,
          [classes.clickable]: !R.isNil(onClick),
          [classes.hoverEffect]: hoverEffect
        },
        className
      )}
      onClick={onClick}
      data-id={'table-body-row'}
      data-index={index}
      data-testid={restProps['data-testid']}
      id={elementId}
    >
      {isSelectionShown && (
        <td className={classes.bodyRowCell}>
          <Checkbox
            checked={R.includes(id, selectedRowIds)}
            onClick={e => {
              e.stopPropagation();
            }}
            onChange={e => {
              changeRowSelection(id, e.target.checked);
            }}
          />
        </td>
      )}
      {children({ bodyRowCellClassName: classes.bodyRowCell })}
    </tr>
  );
};

export default TableBodyRow;
