import classNames from 'classnames';
import * as R from 'ramda';
import React from 'react';

import CardsListBase from '../../shared/components/cradsList/cardsList.js';
import ErrorMessage from 'features/shared/components/errorMessage/index.js';
import { Spacing } from 'features/shared/constants/spacing';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { getErrorText } from 'features/sharedModules/finalForm/components/shared.js';
import { useField } from 'features/sharedModules/finalForm/components/useField';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const updateMultiselectCardsValue = (id, value) => {
  if (R.isNil(value)) {
    return [id];
  }

  const idIndex = R.indexOf(id, value);

  return idIndex === -1 ? R.append(id, value) : R.remove(idIndex, 1, value);
};

const useStyles = createUseStyles({
  container: {},
  label: {
    marginBottom: Spacing.spacing02
  },
  errorMessage: {
    marginTop: Spacing.spacing01
  }
});

export const CardsList = ({
  value,
  onChange,
  error,
  touched,
  label,
  isMultiselect,
  cards,
  cardLayout,
  legend,
  containerClassName,
  imageContainerClassName,
  afterChange,
  disabled,
  additionalComponent,
  ...restProps
}) => {
  const i18n = useI18n();
  const classes = useStyles();

  const isErrorShown = touched && !R.isNil(error);
  const errorText = getErrorText(i18n, error);

  return (
    <div
      className={classNames(classes.container, containerClassName)}
      id={restProps.elementId}
    >
      {label && <div className={classes.label}>{label}</div>}
      {additionalComponent}
      <CardsListBase
        maxLineLength={5}
        selectedCardIds={isMultiselect ? value : [value]}
        onCardSelected={id => {
          if (isMultiselect) {
            onChange(updateMultiselectCardsValue(id, value));
          } else {
            onChange(id);
          }
          !R.isNil(afterChange) && afterChange();
        }}
        imageContainerClassName={imageContainerClassName}
        cards={cards}
        cardLayout={cardLayout}
        legend={legend}
        data-testid={restProps['data-testid']}
        disabled={disabled}
      />
      {isErrorShown && (
        <ErrorMessage
          className={classes.errorMessage}
        >{`* ${errorText}`}</ErrorMessage>
      )}
    </div>
  );
};

const FinalFormCardsList = ({ name, fieldConfig, ...restProps }) => {
  const {
    input: { value, onChange },
    meta: { error, touched }
  } = useField(name, fieldConfig);

  return (
    <CardsList
      value={value}
      onChange={onChange}
      error={error}
      touched={touched}
      {...restProps}
    />
  );
};

export default FinalFormCardsList;
