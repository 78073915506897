import * as R from 'ramda';

import { types as customersTablePageActionTypes } from 'features/customers/customersTablePage/services/actions.js';
import { OrderDirections } from 'features/shared/constants/table';

export const CustomersTableOrderKeys = {
  title: 'title'
};

export const customersTableInitialState = {
  orderKey: CustomersTableOrderKeys.title,
  orderDirection: OrderDirections.asc,
  pageSize: 50,
  pageNumber: 0,
  selectedRowIds: []
};

export const reducer = (state, action) => {
  switch (action.type) {
    case customersTablePageActionTypes.SETTINGS_INITIALIZED: {
      const { settings } = action.payload;

      return R.propOr(state, 'table', settings);
    }
    case customersTablePageActionTypes.CLOSED: {
      return customersTableInitialState;
    }
    default:
      return state;
  }
};
