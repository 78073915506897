import * as R from 'ramda';

import { utcNow } from 'features/shared/utils/dateTime.js';

const createSetAccessToken = key => (accessToken, expiresTime) => {
  localStorage.setItem(
    key,
    JSON.stringify({
      accessToken,
      expiresTime
    })
  );
};

const createRemoveAccessToken = key => () => {
  localStorage.removeItem(key);
};

const createGetAccessToken = key => () => {
  return R.pipe(
    key => localStorage.getItem(key),
    item => {
      if (R.isNil(item)) {
        return null;
      }

      const tokenData = JSON.parse(item);
      const currentTime = utcNow().getTime();

      if (currentTime > tokenData.expiresTime) {
        return null;
      } else {
        return tokenData.accessToken;
      }
    }
  )(key);
};

const auth0AccessTokenKey = 'auth0_accessToken';

export const setAuth0AccessToken = createSetAccessToken(auth0AccessTokenKey);

export const removeAuth0AccessToken =
  createRemoveAccessToken(auth0AccessTokenKey);

export const getAuth0AccessToken = createGetAccessToken(auth0AccessTokenKey);

const qAuthApiAccessTokenKey = 'qAuthApi_accessToken';

export const setQAuthApiAccessToken = createSetAccessToken(
  qAuthApiAccessTokenKey
);

export const removeQAuthApiAccessToken = createRemoveAccessToken(
  qAuthApiAccessTokenKey
);

export const getQAuthApiAccessToken = createGetAccessToken(
  qAuthApiAccessTokenKey
);

export const setPublicKey = publicKey => {
  localStorage.setItem('auth0_publicKey', JSON.stringify(publicKey));
};

export const removePublicKey = () => {
  localStorage.removeItem('auth0_publicKey');
};

export const getPublicKey = () => {
  return JSON.parse(localStorage.getItem('auth0_publicKey'));
};
