// react-app-polyfill must be the first import in the file
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { initAxios } from './axios';
import './polyfill.js';
import { sagaMiddleware } from './sagaMiddleware.js';
import { sagas } from './sagas.js';
import store from './store.js';
import { creators as applicationActionCreators } from 'features/shared/services/application/actions.js';
import { creators as sessionActionCreators } from 'features/shared/services/session/actions.js';
import auth from 'features/shared/utils/auth';
import routeTemplates from 'features/shared/utils/routeTemplates';

//log analytics tool

// Check if log analytics tools should be used
if (
  window.env.USE_LOG_ANALYTICS &&
  window.env.LOG_ANALYTICS_CONNECTION_STRING
) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: window.env.LOG_ANALYTICS_CONNECTION_STRING
    }
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
}

if (window.location.pathname === routeTemplates.loginSilentRenew.build()) {
  auth.loginSilentCallback();
} else {
  sagaMiddleware.run(sagas);

  initAxios(store);

  store.dispatch(applicationActionCreators.started());

  auth.getUser().then(user => {
    if (!user) {
      auth.loginSilent();
    }
  });

  auth.addUserLoginListener(user => {
    store.dispatch(sessionActionCreators.login(user));
  });
}
