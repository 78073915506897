import { CancelToken } from 'axios';

import { stateApi, helperApi } from 'features/shared/utils/axios';

export const getAdvisor = (accessToken, cancelToken, advisorId) =>
  stateApi.get(`/advisor/${advisorId}`, {
    cancelToken,
    headers: { Authorization: accessToken }
  });

export const postAdvisor = (accessToken, cancelToken, advisor) =>
  stateApi.post('/advisor', advisor, {
    cancelToken,
    headers: { Authorization: accessToken }
  });

export const patchAdvisor = (accessToken, cancelToken, advisorId, advisor) =>
  stateApi.patch(`/advisor/${advisorId}`, advisor, {
    cancelToken,
    headers: { Authorization: accessToken }
  });

//region ENIN
export const getENINCompanyDetails = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  companyId: string
) =>
  helperApi.get('enin/companydetails', {
    cancelToken,
    headers: { Authorization: accessToken },
    params: {
      company_id: companyId
    }
  });
export const getENINFlags = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  companyId: string
) =>
  helperApi.get('enin/flags', {
    cancelToken,
    headers: { Authorization: accessToken },
    params: {
      company_id: companyId
    }
  });
export const getENINUBO = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  companyId: string
) =>
  helperApi.get('enin/ubo', {
    cancelToken,
    headers: { Authorization: accessToken },
    params: {
      company_id: companyId
    }
  });
// endregion
