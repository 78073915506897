export const EducationWorkSettingsClassToTitle = {
  1: 'roboAdvice.riskScore.educationWork.iHaveNoExperience',
  2: 'roboAdvice.riskScore.educationWork.iHaveEducation',
  3: 'roboAdvice.riskScore.educationWork.iHaveWorkExperience'
};

export const ExperienceSettingsClassToTitle = {
  1: 'roboAdvice.riskScore.experience.lessThan5',
  2: 'roboAdvice.riskScore.experience.between5And10',
  3: 'roboAdvice.riskScore.experience.moreThan10'
};

export const SessionPatchTypes = {
  saveSessionData: 'saveSessionData',
  completeSession: 'completeSession',
  saveFinishedSessionData: 'saveFinishedSessionData'
};

export const SessionNamespaces = {
  advisorInput: 'advisorInput',
  sessionData: 'sessionData',
  finishedSessionData: 'finishedSessionData',
  finishedSessionInvestorData: 'finishedSessionInvestorData',
  finishedSessionAdvisorInfo: 'finishedSessionAdvisorInfo'
} as const;

export const PortfolioModes = {
  modelPortfolio: 'modelPortfolio',
  customPortfolio: 'customPortfolio'
} as const;

export const AvailablePortfoliosModes = {
  all: 'all',
  linkedToFinalScore: 'linkedToFinalScore',
  equalOrLower: 'equalOrLower',
  custom: 'custom'
} as const;

export const WithdrawalPlans = {
  monthlyWithdrawal: 1,
  oneTimeWithdrawal: 2,
  noPlannedWithdrawal: 3
} as const;

export const WithdrawalPlansClassToTitle = {
  1: 'roboAdvice.purposeAndRisk.monthlyWithdrawal',
  2: 'roboAdvice.purposeAndRisk.oneTimeWithdrawal',
  3: 'roboAdvice.purposeAndRisk.noPlannedWithdrawal'
} as const;

export const FundNameForPresentation = {
  fundStandardName: 'fundStandardName',
  name: 'name'
} as const;

export const NUMBER_OF_ALLOWED_STATISTICS = 20;

export const ProductAttributeType = {
  text: 'text',
  binary: 'binary'
} as const;

export const CustomPortfolioTabsNames = {
  fundAllocation: 'fundAllocation',
  assetClassAllocation: 'assetClassAllocation'
} as const;
