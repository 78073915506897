import classNames from 'classnames';
import React from 'react';

import Menu from './menu';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  menu: {
    padding: '0.8rem 0',
    minWidth: '16rem'
  },
  menuLink: {
    padding: '1.2rem 2.4rem',

    '&:hover': {
      backgroundColor: theme.hoverInputStrokeColor
    }
  }
}));

const ContextMenu = ({ classNames: classNamesProp = {}, ...rest } = {}) => {
  const classes = useStyles();

  return (
    <Menu
      classNames={{
        ...classNamesProp,
        menu: classNames(classNamesProp.menu, classes.menu),
        menuLink: classNames(classNamesProp.menuLink, classes.menuLink)
      }}
      {...rest}
    />
  );
};

export default ContextMenu;
