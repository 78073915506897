import { useClientForm } from '../../main/services/clientForm';
import ActionButtons from './actionButtons';
import ContactsTable from './contactsTable';
import { useInitPage } from './useInitPage';
import { ClientTypes } from 'features/shared/constants/session';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { ConfigurableComponent } from 'features/sharedModules/declarativeForm/components';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const useStyles = createUseStyles(theme => ({
  bodyColumn: {
    width: '700px',
    display: 'inline-flex',
    padding: '35px 40px',
    backgroundColor: theme.boxBackgroundColor_2,

    '& + &': {
      marginLeft: '25px'
    }
  },
  section: {
    '& + &': {
      marginTop: '30px'
    }
  },
  header: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    fontWeight: FontWeights.medium,
    marginBottom: '20px'
  }
}));

const ClientInformation = () => {
  const classes = useStyles();
  const { values } = useClientForm();
  const i18n = useI18n();
  const {
    roboAdvice: {
      clientInformation: {
        contacts: { isContactListEnabled }
      }
    }
  } = useCustomerConfig();

  const fields = useInitPage();
  const showContactList =
    values.clientInformation?.clientType === ClientTypes.company &&
    isContactListEnabled;

  return (
    <>
      <div className={classes.bodyColumn}>
        <div className={classes.section}>
          <div className={classes.header}>
            {i18n('roboAdvice.clientInformation.generalInformation.header')}
          </div>

          {fields.map(({ name, config, ...restProps }) => (
            <ConfigurableComponent
              key={name}
              name={name}
              config={config}
              {...restProps}
            />
          ))}
        </div>
      </div>

      {showContactList && <ContactsTable />}

      <ActionButtons isContactListEnabled={showContactList} />
    </>
  );
};

export default ClientInformation;
