import { getTranslation } from '../../../../shared/utils/translations';
import { RoboAdviceOrderExecutionPages } from '../../../shared/constants';
import { useForm as useRoboAdviceForm } from '../../form/services/form';
import { PageId, Tab, getFieldsProgress } from '../../main/services/selectors';
import { usePageStore as useProposalPageStore } from '../../proposal/services/pageStore';
import { useSessionStore } from '../../session/services/sessionStore';
import {
  calculateCustomFieldsTabProgress,
  calculateInstrumentsTabProgress,
  calculateOrderInformationPage2Progress
} from '../../shared/services/progressSelectors';
import { advancedSuitabilityTables } from 'features/roboAdvice/adviceSession/knowledgeAndExperience/constants';
import { useKnowledgeAndExperienceStore } from 'features/roboAdvice/adviceSession/knowledgeAndExperience/services/knowledgeAndExperienceStore';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { useGetInstrumentsErrors } from 'features/sharedModules/errors/services/useGetInstrumentsErrors';
import { useGetOrderInformationErrors } from 'features/sharedModules/errors/services/useGetOrderInformationErrors';
import { useGetOrderInformationPage2Errors } from 'features/sharedModules/errors/services/useGetOrderInformationPage2Errors';
import { useGetTargetMarketAssessmentErrors } from 'features/sharedModules/errors/services/useGetTargetMarketAssessmentErrors';
import { getNumberOfErrors } from 'features/sharedModules/errors/utils';

export const useOrderInformationTab = () => {
  const i18n = useI18n();
  const { values, errors: formErrors } = useRoboAdviceForm();
  const isSessionInitialized = useSessionStore(s => s.isSessionInitialized);
  const isReadPdfReportPending = useProposalPageStore(
    s => s.isReadPdfReportPending
  );
  const {
    roboOrderFlow: { orderInformationFields }
  } = useCustomerConfig();
  const errors = useGetOrderInformationErrors();

  return getOrderInformationTabResult({
    i18n,
    values,
    formErrors,
    orderInformationFields,
    isSessionInitialized,
    isReadPdfReportPending,
    errors
  });
};

export const getOrderInformationTabResult = ({
  i18n,
  values,
  formErrors,
  orderInformationFields,
  isSessionInitialized,
  isReadPdfReportPending,
  errors
}) => {
  return {
    value: RoboAdviceOrderExecutionPages.orderInformation,
    progress: calculateCustomFieldsTabProgress({
      fields: orderInformationFields,
      values,
      errors: formErrors
    }),
    label: i18n('roboAdvice.tabs.orderInformation'),
    disabled: isSessionInitialized && isReadPdfReportPending,
    hasError: isSessionInitialized && getNumberOfErrors(errors) > 0
  };
};

export const useOrderInformationPage2Tab = () => {
  const roboAdviceFormState = useRoboAdviceForm();
  const isSessionInitialized = useSessionStore(s => s.isSessionInitialized);
  const isReadPdfReportPending = useProposalPageStore(
    s => s.isReadPdfReportPending
  );
  const {
    roboOrderFlow: { orderInformationPage2 }
  } = useCustomerConfig();
  const clientType = roboAdviceFormState.values?.clientInformation?.clientType;
  const errors = useGetOrderInformationPage2Errors();

  return getOrderInformationPage2TabResult({
    orderInformationPage2,
    roboAdviceFormState,
    isSessionInitialized,
    isReadPdfReportPending,
    clientType,
    errors
  });
};

export const getOrderInformationPage2TabResult = ({
  orderInformationPage2,
  roboAdviceFormState,
  isSessionInitialized,
  isReadPdfReportPending,
  clientType,
  errors
}) => {
  return {
    value: RoboAdviceOrderExecutionPages.orderInformationPage2,
    progress: calculateOrderInformationPage2Progress({
      sections: orderInformationPage2[clientType]?.sections,
      advisorNotesConfig: orderInformationPage2[clientType]?.advisorNotes,
      roboAdviceFormState
    }),
    label: getTranslation(orderInformationPage2[clientType]?.pageLabel),
    disabled: isSessionInitialized && isReadPdfReportPending,
    hasError: isSessionInitialized && getNumberOfErrors(errors) > 0
  };
};

export const useInstrumentsTab = () => {
  const i18n = useI18n();
  const { values } = useRoboAdviceForm();
  const isSessionInitialized = useSessionStore(s => s.isSessionInitialized);
  const errors = useGetInstrumentsErrors();

  return getInstrumentsTabResult({
    i18n,
    values,
    isSessionInitialized,
    errors
  });
};

export const getInstrumentsTabResult = ({
  i18n,
  values,
  isSessionInitialized,
  errors
}) => {
  return {
    value: RoboAdviceOrderExecutionPages.instruments,
    progress: calculateInstrumentsTabProgress({ values, errors }),
    label: i18n('roboAdvice.tabs.instruments'),
    disabled: !isSessionInitialized,
    hasError: isSessionInitialized && getNumberOfErrors(errors) > 0
  };
};

export const getOrderTabResult = ({
  i18n,
  isSessionInitialized,
  isPreOrderTabPartCompleted
}) => {
  return {
    value: RoboAdviceOrderExecutionPages.order,
    progress: 0,
    disabled: !isSessionInitialized || !isPreOrderTabPartCompleted,
    isNavigationDisabled: !isSessionInitialized,
    label: i18n('roboAdvice.tabs.order'),
    hasError: false
  };
};

export const useOrderTab = () => {
  const i18n = useI18n();
  const isSessionInitialized = useSessionStore(s => s.isSessionInitialized);
  const isPreOrderTabPartCompleted = useIsPreOrderTabPartCompleted();

  return getOrderTabResult({
    i18n,
    isSessionInitialized,
    isPreOrderTabPartCompleted
  });
};

export const useTargetMarketAssessmentTab = () => {
  const isSessionInitialized = useSessionStore(s => s.isSessionInitialized);
  const errors = useGetTargetMarketAssessmentErrors();
  const {
    roboOrderFlow: {
      targetMarketAssessmentPage: { pageLabel },
      targetMarketAssessment: targetMarketAssessmentConfig
    },
    roboAdviceForm: {
      knowledgeAndExperience: {
        advancedSuitability: advancedSuitabilityCustomerConfig
      }
    }
  } = useCustomerConfig();
  const { advancedSuitabilityConfig } = useKnowledgeAndExperienceStore();
  const roboAdviceFormState = useRoboAdviceForm();

  return getTargetMarketAssessmentTabResult({
    roboAdviceFormState,
    targetMarketAssessmentConfig,
    advancedSuitabilityConfig,
    advancedSuitabilityCustomerConfig,
    isSessionInitialized,
    errors,
    pageLabel
  });
};

export const getTargetMarketAssessmentTabResult = ({
  roboAdviceFormState,
  advancedSuitabilityCustomerConfig,
  advancedSuitabilityConfig,
  isSessionInitialized,
  errors,
  pageLabel,
  targetMarketAssessmentConfig
}) => {
  const { values } = roboAdviceFormState;
  let fields: boolean[] = [];

  if (targetMarketAssessmentConfig.enabled) {
    const suitabilityFieldsNumber =
      advancedSuitabilityTables.filter(
        ({ name }) => advancedSuitabilityCustomerConfig[name].enabled
      ).length * advancedSuitabilityConfig?.checks?.length || 1;
    const filledSuitabilityFields = values.suitability
      ? Object.values(
          values.suitability as Record<string, Record<string, any>>
        ).reduce((acc, curr) => acc + Object.values(curr).length, 0)
      : 0;

    fields = Array(suitabilityFieldsNumber)
      .fill(true, 0, filledSuitabilityFields)
      .fill(false, filledSuitabilityFields);
  }

  if (
    targetMarketAssessmentConfig.advisorNote.enabled &&
    targetMarketAssessmentConfig.advisorNote.required
  ) {
    fields.push(!!values.targetMarketAssessmentAdvisorNotes);
  }

  return {
    value: RoboAdviceOrderExecutionPages.targetMarketAssessment,
    progress: getFieldsProgress(fields),
    label: getTranslation(pageLabel),
    disabled: !isSessionInitialized,
    hasError: isSessionInitialized && getNumberOfErrors(errors) > 0
  };
};

export const useTabs = (): Tab[] => {
  const orderInformationTabResult = useOrderInformationTab();
  const orderInformationPage2TabResult = useOrderInformationPage2Tab();
  const instrumentsTab = useInstrumentsTab();
  const orderTab = useOrderTab();
  const targetMarketAssessmentTabResult = useTargetMarketAssessmentTab();
  const { values } = useRoboAdviceForm();
  const {
    roboOrderFlow: {
      orderInformationFields,
      orderInformationPage2,
      targetMarketAssessment
    },
    tenantSettings: { flowOrderOrderExecution }
  } = useCustomerConfig();
  const clientType = values?.clientInformation?.clientType;

  const tabs: Tab[] = [
    {
      tab: orderInformationTabResult,
      pageId: RoboAdviceOrderExecutionPages.orderInformation,
      enabled: orderInformationFields.length > 0,
      order: flowOrderOrderExecution.orderInformation || 0
    },
    {
      tab: orderInformationPage2TabResult,
      pageId: RoboAdviceOrderExecutionPages.orderInformationPage2,
      enabled: orderInformationPage2[clientType]?.enabled,
      order: flowOrderOrderExecution.orderInformationPage2 || 0
    },
    {
      tab: instrumentsTab,
      pageId: RoboAdviceOrderExecutionPages.instruments,
      enabled: true,
      order: flowOrderOrderExecution.instruments || 0
    },
    {
      tab: targetMarketAssessmentTabResult,
      pageId: RoboAdviceOrderExecutionPages.targetMarketAssessment,
      enabled: targetMarketAssessment.enabled,
      order: flowOrderOrderExecution.targetMarketAssessment || 0
    }
  ].sort((a, b) => a.order - b.order);

  tabs.push({
    tab: orderTab,
    pageId: RoboAdviceOrderExecutionPages.order,
    enabled: true
  });

  return tabs.filter(({ enabled }) => enabled);
};

const useIsPreOrderTabPartCompleted = () => {
  const {
    roboOrderFlow: { orderInformationPage2, targetMarketAssessment }
  } = useCustomerConfig();
  const { values } = useRoboAdviceForm();
  const clientType = values?.clientInformation?.clientType;

  const orderInformationTabResult = useOrderInformationTab();
  const instrumentsTab = useInstrumentsTab();
  const orderInformationPage2TabResult = useOrderInformationPage2Tab();
  const isOrderInformationPage2Enabled =
    orderInformationPage2[clientType]?.enabled;
  const targetMarketAssessmentTabResult = useTargetMarketAssessmentTab();
  const isTargetMarketAssessmentEnabled = targetMarketAssessment.enabled;

  return (
    orderInformationTabResult.progress === 1 &&
    instrumentsTab.progress === 1 &&
    (!isOrderInformationPage2Enabled ||
      orderInformationPage2TabResult.progress === 1) &&
    (!isTargetMarketAssessmentEnabled ||
      targetMarketAssessmentTabResult.progress === 1)
  );
};

export const useIsGenerateOrderEnabled = () => {
  const { progress } = useOrderInformationTab();
  const { values } = useRoboAdviceForm();

  return (
    progress === 1 &&
    values.instrumentsSelected?.some(
      instrument => instrument.initialDeposit || instrument.monthlySaving
    )
  );
};

export const usePrevNextButtonsOrderExecutionTabs: (currentPageId: PageId) => {
  prevPage: Tab | null;
  nextPage: Tab | null;
} = currentPageId => {
  const tabs = useTabs();

  const currentPageIndex = tabs.findIndex(t => t.pageId === currentPageId);

  if (currentPageIndex === -1) {
    return {
      prevPage: null,
      nextPage: null
    };
  }

  return {
    prevPage: currentPageIndex !== 0 ? tabs[currentPageIndex - 1] : null,
    nextPage:
      currentPageIndex !== tabs.length - 1 ? tabs[currentPageIndex + 1] : null
  };
};

export const useTotalValues = (): {
  initialDeposit?: number;
  monthlySaving?: number;
} => {
  const { values } = useRoboAdviceForm();

  if (values.instrumentsSelected) {
    return values.instrumentsSelected.reduce(
      (acc, current) => ({
        initialDeposit: acc.initialDeposit + (current.initialDeposit ?? 0),
        monthlySaving: acc.monthlySaving + (current.monthlySaving ?? 0)
      }),
      {
        initialDeposit: 0,
        monthlySaving: 0
      }
    );
  }

  return {};
};

export const isPageOrderExecution = (pageId: PageId): boolean =>
  Object.values(RoboAdviceOrderExecutionPages).some(page => page === pageId);
