import { useParams } from 'react-router-dom';

import { RoboAdviceOrderExecutionPages } from '../../../shared/constants';
import { useSessionStore } from '../../session/services/sessionStore';
import Instruments from './instruments/instruments';
import Order from './order/order';
import OrderInformation from './orderInformation/orderInformation';
import OrderInformationPage2 from './orderInformationPage2/orderInformationPage2';
import { TargetMarketAssessment } from 'features/roboAdvice/adviceSession/orderExecution/components/targetMarketAssessment/targetMarketAssessment';
import { OrderExecutionParams } from 'features/shared/constants/session';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  pagePlaceholder: {
    backgroundColor: theme.boxBackgroundColor_1,
    borderRadius: '15px',
    height: '300px'
  }
}));

const OrderExecutionPages = ({ onNavButtonClick }) => {
  const { pageId } = useParams<OrderExecutionParams>();
  const classes = useStyles();
  const isSessionInitialized = useSessionStore(s => s.isSessionInitialized);
  const {
    roboAdviceForm: {
      adviceInformation: { fields: adviceInformationFields = [] }
    }
  } = useCustomerConfig();

  if (!isSessionInitialized) {
    return <div className={classes.pagePlaceholder} />;
  }

  return (
    <>
      {pageId === RoboAdviceOrderExecutionPages.orderInformation &&
        adviceInformationFields.length > 0 && (
          <OrderInformation onNavButtonClick={onNavButtonClick} />
        )}
      {pageId === RoboAdviceOrderExecutionPages.orderInformationPage2 &&
        adviceInformationFields.length > 0 && (
          <OrderInformationPage2 onNavButtonClick={onNavButtonClick} />
        )}
      {pageId === RoboAdviceOrderExecutionPages.instruments &&
        adviceInformationFields.length > 0 && (
          <Instruments onNavButtonClick={onNavButtonClick} />
        )}
      {pageId === RoboAdviceOrderExecutionPages.targetMarketAssessment &&
        adviceInformationFields.length > 0 && (
          <TargetMarketAssessment onNavButtonClick={onNavButtonClick} />
        )}
      {pageId === RoboAdviceOrderExecutionPages.order && <Order />}
    </>
  );
};

export default OrderExecutionPages;
