import {
  useFloating,
  autoUpdate,
  offset,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  arrow,
  Placement
} from '@floating-ui/react';
import React from 'react';

import { ARROW_HEIGHT } from './constants';

type TooltipOptions = {
  arrowRef: React.MutableRefObject<SVGSVGElement | null>;
  initialOpen?: boolean;
  open?: boolean;
  placement: Placement;
  topCorner?: boolean;
  onOpenChange?: (open: boolean) => void;
};

export function useTooltip({
  arrowRef,
  initialOpen = false,
  onOpenChange: setControlledOpen,
  open: controlledOpen,
  placement,
  topCorner
}: TooltipOptions) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(({ rects }) => ({
        mainAxis: ARROW_HEIGHT + 8,
        crossAxis: topCorner ? rects.reference.width / 2 : 0
      })),
      shift({ padding: 5 }),
      arrow({
        element: arrowRef as React.MutableRefObject<SVGSVGElement | null>
      })
    ]
  });

  const context = data.context;

  const hover = useHover(context, {
    enabled: controlledOpen == null,
    delay: { open: 500, close: 0 },
    move: false
  });
  const focus = useFocus(context, {
    enabled: controlledOpen == null
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data
    }),
    [open, setOpen, interactions, data]
  );
}
