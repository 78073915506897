import { UseFieldConfig } from 'react-final-form';

import { useField } from './useField';
import Radios, { Props } from 'features/shared/components/radios';

type FinalFormProps = {
  name: string;
  fieldConfig?: UseFieldConfig<unknown>;
} & Omit<Props, 'value' | 'onChange'>;

const FinalFormRadios = ({
  name,
  fieldConfig = {},
  ...restProps
}: FinalFormProps) => {
  const {
    input: { value, onChange },
    meta: { error, touched }
  } = useField(name, fieldConfig);

  return (
    <Radios
      value={value}
      onChange={onChange}
      error={error}
      touched={touched}
      {...restProps}
    />
  );
};

export default FinalFormRadios;
