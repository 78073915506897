import { useParams } from 'react-router';

import { usePreMeetingSurveyStore } from 'features/roboAdvice/client/adviceSessionsList/services/preMeetingSurveyStore';
import { FlowType } from 'features/roboAdvice/client/main/types';
import { AdviceSessionParams } from 'features/shared/constants/session';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import { getTranslation } from 'features/shared/utils/translations';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import FinalFormRadios from 'features/sharedModules/finalForm/components/radios';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  description: {
    color: theme.secondaryColor,
    fontSize: Typography.body1.size,
    marginBottom: Spacing.spacing01
  }
}));

type Props = {
  selectedFlowType: FlowType;
};

export const SelfServiceFlow = ({ selectedFlowType }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const { surveyFlowData } = usePreMeetingSurveyStore();
  const { clientType } = useParams<AdviceSessionParams>();

  const options =
    surveyFlowData[selectedFlowType]
      ?.filter(flow => flow.attributes.flowInvestorType === clientType)
      .map(flow => ({
        label: getTranslation(flow.attributes.name),
        activeValue: flow.id
      })) ?? [];

  return (
    <div>
      <div className={classes.description}>
        {i18n('roboAdvice.client.preMeetingSurvey.selfServiceFlow.description')}
      </div>

      <FinalFormRadios name="selfServiceFlow" items={options} />
    </div>
  );
};
