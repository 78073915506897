import create from 'zustand';

import { ClientFormValues } from '../../main/services/clientForm';

export type AutomaticClientClassificationStoreData = {
  isEducationalModalOpen: boolean;
  isChecklistModalOpen: boolean;
  isConfirmationModalOpen: boolean;
  formStateConfirmation: ClientFormValues | null;
  selectedInvestorTypeItems: string[];
};

export type AutomaticClientClassificationStoreApi = {
  reset: () => void;
  initialize: (
    initialData: Partial<AutomaticClientClassificationStoreData>
  ) => void;
  setIsEducationalModalOpen: (isEducationalModalOpen: boolean) => void;
  setIsChecklistModalOpen: (isChecklistModalOpen: boolean) => void;
  setIsConfirmationModalOpen: (isConfirmationModalOpen: boolean) => void;
  setFormStateConfirmation: (
    formStateConfirmation: ClientFormValues | null
  ) => void;
  setSelectedInvestorTypeItems: (selectedInvestorTypeItems: string[]) => void;
};

export type AutomaticClientClassificationStoreState =
  AutomaticClientClassificationStoreData &
    AutomaticClientClassificationStoreApi;

const defaultData: AutomaticClientClassificationStoreData = {
  isEducationalModalOpen: false,
  isChecklistModalOpen: false,
  isConfirmationModalOpen: false,
  formStateConfirmation: null,
  selectedInvestorTypeItems: []
};

export const useAutomaticClientClassificationStore =
  create<AutomaticClientClassificationStoreState>(set => ({
    ...defaultData,
    reset: () => {
      set(defaultData);
    },
    initialize(initialData) {
      set(
        state => ({
          ...state,
          ...initialData
        }),
        true
      );
    },
    setIsEducationalModalOpen(isEducationalModalOpen) {
      set({ isEducationalModalOpen });
    },
    setIsChecklistModalOpen(isChecklistModalOpen) {
      set({ isChecklistModalOpen });
    },
    setIsConfirmationModalOpen(isConfirmationModalOpen) {
      set({ isConfirmationModalOpen });
    },
    setFormStateConfirmation(formStateConfirmation) {
      set({ formStateConfirmation });
    },
    setSelectedInvestorTypeItems(selectedInvestorTypeItems) {
      set({ selectedInvestorTypeItems });
    }
  }));
