import { FormControlLabel } from '@material-ui/core';
import classNames from 'classnames';
import { useEffect } from 'react';
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber
} from 'react-phone-number-input';

import { RemindOrResendValues } from './constants';
import { useStyles } from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/detailsStyles';
import {
  preMeetingSurveyForm,
  usePreMeetingSurveyForm
} from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/form';
import { usePreMeetingSurveyStore } from 'features/roboAdvice/client/adviceSessionsList/services/preMeetingSurveyStore';
import ConfigurableLabel from 'features/shared/components/configurableLabel';
import { isValidEmail } from 'features/shared/utils/email';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import FinalFormSwitch from 'features/sharedModules/finalForm/components/switch';
import FinalFormTextInput from 'features/sharedModules/finalForm/components/textInput';

const ContactInformationSection = () => {
  const i18n = useI18n();
  const classes = useStyles();
  const { values: preMeetingSurveyValues } = usePreMeetingSurveyForm();
  const { investorInformationData, originalInvestorPhoneNumber } =
    usePreMeetingSurveyStore();

  useEffect(() => {
    if (originalInvestorPhoneNumber) {
      preMeetingSurveyForm.change(
        'investorPhoneNumber',
        originalInvestorPhoneNumber
      );
    }
  }, [originalInvestorPhoneNumber]);

  return (
    <>
      <div className={classes.contactInformationRow}>
        <div className={classes.header}>
          {i18n('roboAdvice.client.preMeetingSurvey.contactInformation.header')}
        </div>

        <FormControlLabel
          control={
            <FinalFormSwitch
              name="doUseContactInformationFromInvestorCard"
              disabled={
                !investorInformationData?.phone &&
                (!investorInformationData?.email ||
                  !preMeetingSurveyValues.isEmailToCustomerEnabled)
              }
            />
          }
          label={
            <div
              className={classNames(classes.switchLabel, {
                [classes.switchLabelDisabled]:
                  !investorInformationData?.phone &&
                  !investorInformationData?.email
              })}
            >
              {i18n(
                'roboAdvice.client.preMeetingSurvey.contactInformation.fromInvestorCardLabel'
              )}
            </div>
          }
        />
      </div>

      <div className={classes.textInputsContainer}>
        <FinalFormTextInput
          name="investorPhoneNumber"
          label={
            <ConfigurableLabel
              label={i18n(
                'roboAdvice.client.preMeetingSurvey.contactInformation.phoneNumber'
              )}
              required={
                !preMeetingSurveyValues.remindOrResend ||
                preMeetingSurveyValues.isSmsToCustomerEnabled
              }
              info={
                preMeetingSurveyValues.remindOrResend &&
                originalInvestorPhoneNumber
                  ? i18n(
                      'roboAdvice.client.preMeetingSurvey.resend.phoneNumberDescription'
                    ).replace(
                      '{0}',
                      formatPhoneNumberIntl(originalInvestorPhoneNumber)
                    )
                  : undefined
              }
            />
          }
          disabled={
            preMeetingSurveyValues.doUseContactInformationFromInvestorCard &&
            !!investorInformationData?.phone &&
            isValidPhoneNumber(investorInformationData.phone)
          }
          sectionClassName={classes.textInput}
        />

        {preMeetingSurveyValues.isEmailToCustomerEnabled && (
          <FinalFormTextInput
            name="investorEmail"
            inputType="email"
            label={
              <ConfigurableLabel
                label={i18n(
                  'roboAdvice.client.preMeetingSurvey.contactInformation.email'
                )}
                required
              />
            }
            disabled={
              preMeetingSurveyValues.doUseContactInformationFromInvestorCard &&
              !!investorInformationData?.email &&
              isValidEmail(investorInformationData.email)
            }
            sectionClassName={classes.textInput}
          />
        )}
      </div>
    </>
  );
};

export default ContactInformationSection;
