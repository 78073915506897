import create from 'zustand';

import {
  PageStatus,
  PageStatuses
} from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { FlowTypes } from 'features/roboAdvice/client/main/constants';
import {
  FlowType,
  GetInvestorInformationResponse,
  SurveyFlowsResponse
} from 'features/roboAdvice/client/main/types';

export type PreMeetingSurveyStoreData = {
  surveyFlowsStatus: {
    [FlowTypes.adviceflow]: PageStatus | null;
    [FlowTypes.orderexecution]: PageStatus | null;
  };
  surveyFlowData: {
    [FlowTypes.adviceflow]: SurveyFlowsResponse['data'] | null;
    [FlowTypes.orderexecution]: SurveyFlowsResponse['data'] | null;
  };
  investorInformationStatus: PageStatus | null;
  investorInformationData: GetInvestorInformationResponse | null;
  isCreatePreMeetingSessionPending: boolean;
  originalInvestorPhoneNumber: string | null;
  originalInvestorPhoneNumberStatus: PageStatus | null;
};

export type PreMeetingSurveyStoreApi = {
  reset: () => void;
  initialize: (initialData: Partial<PreMeetingSurveyStoreData>) => void;
  setSurveyFlowsStatus: (flowType: FlowType, status: PageStatus | null) => void;
  setSurveyFlowData: (
    flowType: FlowType,
    data: SurveyFlowsResponse['data']
  ) => void;
  setInvestorInformationStatus: (status: PageStatus | null) => void;
  setInvestorInformationData: (data: GetInvestorInformationResponse) => void;
  setIsCreatePreMeetingSessionPending: (isPending: boolean) => void;
  setOriginalInvestorPhoneNumber: (phoneNumber: string | null) => void;
  setOriginalInvestorPhoneNumberStatus: (status: PageStatus | null) => void;
};

export type PreMeetingSurveyStoreState = PreMeetingSurveyStoreData &
  PreMeetingSurveyStoreApi;

const defaultData: PreMeetingSurveyStoreData = {
  surveyFlowsStatus: {
    [FlowTypes.adviceflow]: PageStatuses.ready,
    [FlowTypes.orderexecution]: PageStatuses.ready
  },
  surveyFlowData: {
    [FlowTypes.adviceflow]: null,
    [FlowTypes.orderexecution]: null
  },
  investorInformationStatus: PageStatuses.ready,
  investorInformationData: null,
  isCreatePreMeetingSessionPending: false,
  originalInvestorPhoneNumber: null,
  originalInvestorPhoneNumberStatus: PageStatuses.ready
};

export const usePreMeetingSurveyStore = create<PreMeetingSurveyStoreState>(
  set => ({
    ...defaultData,
    reset: () => {
      set(defaultData);
    },
    initialize(initialData) {
      set(
        state => ({
          ...state,
          ...initialData
        }),
        true
      );
    },
    setSurveyFlowsStatus: (flowType, status) => {
      set(state => ({
        surveyFlowsStatus: {
          ...state.surveyFlowsStatus,
          [flowType]: status
        }
      }));
    },
    setSurveyFlowData: (flowType, data) => {
      set(state => ({
        surveyFlowData: {
          ...state.surveyFlowData,
          [flowType]: data
        }
      }));
    },
    setInvestorInformationStatus: investorInformationStatus => {
      set({ investorInformationStatus });
    },
    setInvestorInformationData: investorInformationData => {
      set({ investorInformationData });
    },
    setIsCreatePreMeetingSessionPending: isCreatePreMeetingSessionPending => {
      set({ isCreatePreMeetingSessionPending });
    },
    setOriginalInvestorPhoneNumber: originalInvestorPhoneNumber => {
      set({ originalInvestorPhoneNumber });
    },
    setOriginalInvestorPhoneNumberStatus: originalInvestorPhoneNumberStatus => {
      set({ originalInvestorPhoneNumberStatus });
    }
  })
);
