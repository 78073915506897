import { useEffect, useState } from 'react';

import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import Icon from 'features/shared/components/icon/index.js';
import ContextMenu from 'features/shared/components/popper/contextMenu.js';
import Popper from 'features/shared/components/popper/index.js';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const ImageHeight = 10.6;
const ImageWidth = 10.6;

const useStyles = createUseStyles(theme => ({
  root: {
    position: 'relative',
    cursor: 'pointer'
  },
  profileImage: {
    width: `${ImageWidth}rem`,
    height: `${ImageHeight}rem`,
    borderRadius: '50%'
  },
  editIconBackground: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: '0',
    right: '0',
    width: '3.6rem',
    height: '3.6rem',
    borderRadius: '50%',
    backgroundColor: theme.secondaryColor
  },
  editIcon: {
    fontSize: '1.9rem'
  },
  menu: {
    border: `2px solid ${theme.hoverInputStrokeColor}`
  }
}));

const Menu = ({ pageDispatch, pageActionTypes, pictureUrl, deleteImage }) => {
  const classes = useStyles();
  const i18n = useI18n();
  const [language, setLanguage] = useState('');

  // Effect used to re-render context menu items after the page language is changed
  useEffect(() => {
    setLanguage(getUserPageLanguage());
  }, [getUserPageLanguage()]);

  return (
    <Popper
      referenceDataId="profile-picture-menu-toggle"
      popperDataId="profile-picture-menu"
      reference={({ ref, isPopperOpen, togglePopper, referenceDataId }) => (
        <div
          ref={ref}
          data-id={referenceDataId}
          className={classes.root}
          onClick={e => {
            togglePopper(!isPopperOpen);
          }}
        >
          <div className={classes.editIconBackground}>
            <Icon className={classes.editIcon} type="edit" />
          </div>
          <img
            className={classes.profileImage}
            src={pictureUrl}
            alt="profile"
          />
        </div>
      )}
      popper={({
        ref,
        style,
        placement,
        popperDataId,
        isPopperOpen,
        togglePopper
      }) =>
        isPopperOpen && (
          <div
            ref={ref}
            style={style}
            data-placement={placement}
            data-id={popperDataId}
          >
            <ContextMenu
              classNames={{
                menu: classes.menu
              }}
              items={[
                {
                  title: i18n('profile.uploadImage'),
                  onClick: e => {
                    e.preventDefault();
                    pageDispatch({
                      type: pageActionTypes.TOGGLE_UPLOAD_IMAGE_MODAL,
                      payload: { isOpen: true }
                    });
                    togglePopper(false);
                  }
                },
                {
                  title: i18n('profile.deleteImage'),
                  onClick: e => {
                    e.preventDefault();
                    deleteImage();
                    togglePopper(false);
                  }
                }
              ]}
            />
          </div>
        )
      }
    />
  );
};

export default Menu;
