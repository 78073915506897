import { createForm } from 'features/sharedModules/finalForm/services/finalForm';

export type PreviewFormValues = {
  [key: string]: any;
};

export const previewForm = createForm<PreviewFormValues>({
  onSubmit: () => {},
  initialValues: {
    monthlyDeposit: 2000,
    firstDeposit: 280000,
    personFinancialSituation: {
      cash: {
        defaultValue: 5000
      },
      debt: {
        defaultValue: 400
      },
      carLoan: {
        defaultValue: 200
      },
      mortgage: {
        defaultValue: 0
      },
      otherDebt: {
        defaultValue: 900
      },
      realEstate: {
        defaultValue: 5000
      },
      valueOfCar: {
        defaultValue: 5000
      },
      otherAssets: {
        defaultValue: 10000
      },
      studentLoan: {
        defaultValue: 0
      },
      moneyInStock: {
        defaultValue: 5000
      },
      monthlySurplus: 100,
      sufficientBuffer: false
    }
  }
});
