import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getQAuthAccessToken } from '../../../../shared/api';
import sessionSelectors from '../../../../shared/services/session/selectors';
import { useSessionStore } from '../../session/services/sessionStore';
import { readAnalyzeAdvancedSuitabilityConfig } from '../../shared/api';
import { useKnowledgeAndExperienceStore } from '../services/knowledgeAndExperienceStore';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export const useReadAdvancedSuitabilityConfig = () => {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<CancelTokenSource>();
  const dispatch = useDispatch();
  const i18n = useI18n();
  const sessionStoreState = useSessionStore.getState();

  const readAdvancedSuitabilityConfig = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    try {
      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const advancedSuitabilityConfigResponse =
        await readAnalyzeAdvancedSuitabilityConfig(
          accessToken,
          cancelTokenSource.token
        );

      useKnowledgeAndExperienceStore
        .getState()
        .addAdvancedSuitabilityConfigData(
          advancedSuitabilityConfigResponse.data
        );
    } catch (error) {
      if (!axios.isCancel(error)) {
        sessionStoreState.setIsReadAdviceSessionPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.adviceSession.knowledgeAndExperience.readAdvancedSuitabilityConfigErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readAdvancedSuitabilityConfig;
};
