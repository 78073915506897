import React from 'react';

import Button from '../../../../shared/components/button';
import { useI18n } from '../../../../sharedModules/customerConfig/components/useI18n';
import PageBuilder from '../../shared/components/pageBuilder';
import PageBuilderModalFooter from '../../shared/components/pageBuilderModalFooter';
import { AdvancedSuitabilityTrainingContent } from 'defaults/defaultRoboAdviceForm';
import Modal from 'features/shared/components/modal/index';
import { useModalBodyMaxDimensions } from 'features/shared/components/modal/useModalBodyMaxDimensions';
import Scrollbars from 'features/shared/components/scrollbars';
import { Spacing } from 'features/shared/constants/spacing';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  footerButton: {
    justifySelf: 'end',
    width: 'fit-content'
  },
  modalBodyContainer: {
    marginRight: Spacing.spacing01
  },
  modalContent: {
    '& > img': {
      margin: '25px auto',
      display: 'block'
    }
  }
});

type Props = {
  trainingContent: AdvancedSuitabilityTrainingContent | null | undefined;
  isOpen: boolean;
  header: string;
  closeHandler: () => void;
  completeTraining: (check: string) => void;
};

const TrainingModal = ({
  trainingContent,
  isOpen,
  closeHandler,
  completeTraining,
  header
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const { modalBodyMaxHeight, headerRef, footerRef } =
    useModalBodyMaxDimensions();

  const [selectedPage, setSelectedPage] = React.useState(0);

  return (
    <Modal
      footer={
        trainingContent && (
          <PageBuilderModalFooter
            pageNumber={selectedPage}
            totalPagesNumber={trainingContent.pages.length}
            changePageNumber={setSelectedPage}
            button={
              selectedPage + 1 === trainingContent.pages.length ? (
                <Button
                  className={classes.footerButton}
                  onClick={() => {
                    completeTraining(trainingContent.check);
                    setSelectedPage(0);
                  }}
                >
                  {i18n(
                    'roboAdvice.knowledgeAndExperience.advancedThemeSuitabilityAssessment.confirmTraining'
                  )}
                </Button>
              ) : (
                <Button
                  className={classes.footerButton}
                  onClick={() => setSelectedPage(selectedPage + 1)}
                >
                  {i18n('shared.next')}
                </Button>
              )
            }
          />
        )
      }
      header={header}
      isOpen={isOpen}
      onRequestClose={() => {
        closeHandler();
        setSelectedPage(0);
      }}
      headerRef={headerRef}
      footerRef={footerRef}
    >
      {trainingContent && (
        <Scrollbars autoHeightMax={modalBodyMaxHeight}>
          <div className={classes.modalBodyContainer}>
            <div className={classes.modalContent}>
              {trainingContent && (
                <PageBuilder
                  sections={trainingContent.pages[selectedPage].sections}
                />
              )}
            </div>
          </div>
        </Scrollbars>
      )}
    </Modal>
  );
};

export default TrainingModal;
