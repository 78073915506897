import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { useEffect, useMemo, useRef } from 'react';
import { isNil } from 'ramda';

import { useSetHighchartsLocaleMonths } from 'modules/shared/utils/months';
import { formatNumber } from 'modules/shared/utils/number';
import { hexOpacity } from 'modules/shared/constants/colors';
import { useI18n } from 'modules/settings/components/settings';
import { FontFamilies, TextFontSizes } from 'modules/shared/constants/fonts';
import { createUseStyles, useTheme } from 'modules/styles/components/styles';
import CalendarIcon from 'assets/calendar.svg';

HighchartsMore(Highcharts);

const useStyles = createUseStyles(theme => ({
  tooltipHeader: {
    fontFamily: FontFamilies.roboto,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx,
    color: theme.primaryColor,
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    padding: '5px 10px',
    backgroundColor: theme.inputFillFocusColor,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    marginRight: '-1px',
    width: '350px'
  },
  tooltipHeaderIcon: {
    paddingRight: 10,
    height: 22
  },
  tooltipFirstLine: {
    fontFamily: FontFamilies.roboto,
    fontSize: TextFontSizes.normal,
    lineHeight: TextFontSizes.normalLineHeight,
    color: theme.primaryColor,
    display: 'flex',
    alignItems: 'center',
    marginRight: -1,
    padding: '24px 24px 0 24px',
    backgroundColor: theme.boxBackgroundColor_1,

    '&:nth-child(2)': {
      paddingTop: 16
    }
  },
  tooltipCircle: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    marginRight: 8
  },
  tooltipNextLine: {
    fontFamily: FontFamilies.roboto,
    fontSize: TextFontSizes.normal,
    lineHeight: TextFontSizes.normalLineHeight,
    padding: '4px 24px 0 24px',
    marginRight: -1,
    backgroundColor: theme.boxBackgroundColor_1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  tooltipLastElement: {
    marginBottom: -1,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    paddingBottom: 24
  },
  tooltipNextLineType: {
    color: theme.secondaryColor,
    minWidth: '50%'
  },
  tooltipNextLineValue: {
    color: theme.primaryColor,
    width: '40%',
    marginLeft: 10
  },
  chartTooltip: {
    '& span': {
      display: 'flex',
      flexDirection: 'column'
    }
  }
}));

type Props = {
  cultureCode: string;
  chartData: {
    title: string;
    id: string;
    color: string;
    data: {
      expectedReturnChartData: [number, number][];
      boundsChartData: [number, number, number][];
    };
  }[];
  isInteractive?: boolean;
  onLoad?: () => void;
  width?: number;
};

const ExpectedPathPortfoliosChart = ({
  cultureCode,
  chartData,
  isInteractive,
  onLoad,
  width
}: Props) => {
  const i18n = useI18n();
  const classes = useStyles();
  const chartComponent = useRef<HighchartsReact>(null);
  const theme: Record<string, string> = useTheme();
  useSetHighchartsLocaleMonths();

  useEffect(() => {
    const reflow = () => {
      chartComponent.current?.chart.reflow();
    };

    window.addEventListener('resize', reflow);

    reflow();

    return () => {
      window.removeEventListener('resize', reflow);
    };
  }, []);

  const seriesData = useMemo(() => {
    const chartDataCopy = [...chartData];
    chartDataCopy.sort(
      (a, b) =>
        a.data.boundsChartData[a.data.boundsChartData.length - 1][1] -
        b.data.boundsChartData[b.data.boundsChartData.length - 1][1]
    );

    return chartDataCopy;
  }, [chartData]);

  const series = seriesData
    .map(({ id, color, data, title }, index, arr) => {
      const portfolioId = id === 'existingPortfolio' ? 0 : +id;

      return [
        {
          id: `${id}-expectedReturn`,
          type: 'line' as const,
          zIndex: 10 + index,
          color,
          custom: {
            type: i18n('expectedPathChart.default.expectedValue'),
            isMean: true,
            color,
            title,
            portfolioId,
            renderOrder: 2 * index
          },
          states: {
            hover: {
              lineWidthPlus: 0
            },
            select: {
              lineWidthPlus: 0
            }
          },
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
                radiusPlus: 0
              }
            }
          },
          data: data.expectedReturnChartData
        },
        {
          id: `${id}-bounds`,
          type: 'arearange' as const,
          zIndex: index,
          color: `${color}${hexOpacity[60]}`,
          lineWidth: 0,
          data: data.boundsChartData,
          custom: {
            type: i18n('expectedPathChart.default.upperAndLowerBound'),
            isMean: false,
            portfolioId,
            renderOrder: 2 * index + 1
          },
          states: {
            hover: {
              lineWidthPlus: 0
            },
            select: {
              lineWidthPlus: 0
            }
          },
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
                radiusPlus: 0
              }
            }
          }
        }
      ];
    })
    .flat();

  const seriesWithCorrectTooltipOrder = useMemo(() => {
    const seriesSortedByPortfolioId = [...series].sort(
      (a, b) => a.custom.portfolioId - b.custom.portfolioId
    );

    const correctTooltipOrderSeries = seriesSortedByPortfolioId.map(
      (s, index) => {
        const dataIndex = Math.floor(index / 2);

        return {
          ...s,
          custom: {
            ...s.custom,
            tooltipOrder: dataIndex * 3 + (index % 2) * 2
          }
        };
      }
    );

    correctTooltipOrderSeries.sort(
      (a, b) => a.custom.renderOrder - b.custom.renderOrder
    );

    return correctTooltipOrderSeries;
  }, [series]);

  const chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    chart: {
      backgroundColor: 'transparent',
      marginTop: 20,
      marginRight: 0,
      animation: false,
      events: {
        load: onLoad
      }
    },
    title: {
      text: ''
    },
    xAxis: {
      crosshair: {
        color: theme.secondaryColor,
        zIndex: 5
      },
      labels: {
        format: '{value: %Y}',
        style: {
          color: theme.secondaryColor,
          fontSize: TextFontSizes.xxSmall,
          lineHeight: TextFontSizes.xxSmallLineHeight,
          fontFamily: FontFamilies.roboto
        },
        y: 35
      },
      lineWidth: 0,
      maxPadding: 0,
      minPadding: 0,
      tickWidth: 0,
      type: 'datetime' as const
    },
    yAxis: {
      gridLineColor: `${theme.secondaryColor}${hexOpacity[50]}`,
      labels: {
        formatter: ({ value }) => formatNumber(cultureCode, value, 0, 0) ?? '',
        style: {
          color: theme.secondaryColor,
          fontSize: TextFontSizes.xxSmall,
          lineHeight: TextFontSizes.xxSmallLineHeight,
          fontFamily: FontFamilies.roboto
        },
        x: -32
      },
      tickAmount: 6,
      title: undefined
    },
    tooltip: {
      enabled: isInteractive,
      shared: true,
      xDateFormat: '%m/%Y',
      useHTML: true,
      padding: 1,
      borderRadius: 15,
      borderColor: `${theme.accentColor}`,
      headerFormat: `<div class='${classes.tooltipHeader}'>
<img src='${CalendarIcon}' class='${classes.tooltipHeaderIcon}' />
  <div>{point.key}</div>
</div>`,
      className: classes.chartTooltip,
      outside: true,
      pointFormatter: function () {
        const customOptions = this.series.options.custom;

        if (!customOptions) {
          return '';
        }

        const value = customOptions.isMean
          ? formatNumber(cultureCode, this.y, 0, 0)
          : `${formatNumber(cultureCode, this.low, 0, 0)} - ${formatNumber(
              cultureCode,
              this.high,
              0,
              0
            )}`;

        if (
          customOptions.type === i18n('expectedPathChart.default.expectedValue')
        ) {
          return `
          <div class='${classes.tooltipFirstLine}' style='order: ${
            customOptions.tooltipOrder
          }'>
            <div class='${classes.tooltipCircle}' style='background-color: ${
            customOptions.color
          }'></div>
            <div>${customOptions.title}</div>
          </div>
          
          <div class='${classes.tooltipNextLine}' style='order: ${
            customOptions.tooltipOrder + 1
          }'>
            <div class='${classes.tooltipNextLineType}'>${
            customOptions.type
          }</div>
            <div class='${classes.tooltipNextLineValue}'>${value}</div>
          </div>
          `;
        }

        return `
        <div class='${classes.tooltipNextLine}${
          customOptions.tooltipOrder === 3 * (series.length / 2) - 1
            ? ' ' + classes.tooltipLastElement
            : ''
        }' style='order: ${customOptions.tooltipOrder}'>
          <div class='${classes.tooltipNextLineType}'>${
          customOptions.type
        }</div>
          <div class='${classes.tooltipNextLineValue}'>${value}</div>
        </div>`;
      }
    },
    plotOptions: {
      series: {
        enableMouseTracking: isInteractive,
        animation: false,
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },
    series: seriesWithCorrectTooltipOrder
  };

  return (
    <HighchartsReact
      ref={chartComponent}
      highcharts={Highcharts}
      containerProps={{
        style: {
          height: 281,
          width: isNil(width) ? 'auto' : `${width}px`
        }
      }}
      options={chartOptions}
    />
  );
};

export default ExpectedPathPortfoliosChart;
