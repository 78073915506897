import classNames from 'classnames';
import { useRef } from 'react';
import { Transition } from 'react-transition-group';

import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import { Button, ButtonType } from 'features/shared/components/button';
import { Spacing } from 'features/shared/constants/spacing';
import { FontWeights, Typography } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  alertContainer: {
    display: 'flex',
    border: `1px solid ${theme.defaultInputStrokeColor}`,
    borderRadius: 10,
    marginBottom: Spacing.spacing02,
    maxWidth: '40%',
    padding: '12px 8px 16px',
    textAlign: 'left',
    maxHeight: 300,
    transition: 'all 300ms ease-out 300ms, opacity 300ms ease-out 0ms'
  },
  alertDescription: {
    borderRight: `1px solid ${theme.defaultInputStrokeColor}`,
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight,
    marginLeft: Spacing.spacing00,
    marginRight: Spacing.spacing01,
    paddingRight: 24
  },
  okButton: {
    alignSelf: 'center',
    fontSize: Typography.body2.size,
    fontWeight: FontWeights.medium,
    lineHeight: Typography.body2.lineHeight
  },
  infoIcon: {
    flexShrink: 0,
    rotate: '180deg',

    '& path': {
      fill: theme.warningColor
    }
  }
}));

const transitionStyles = {
  exiting: {
    borderWidth: 0,
    maxHeight: 0,
    margin: 0,
    opacity: 0,
    padding: 0
  }
};

type Props = {
  in: boolean;
  message: string;
  onClick: () => void;
  className?: string;
};

const PortfolioChangeAlert = ({
  in: inProp,
  message,
  onClick,
  className
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const nodeRef = useRef(null);

  return (
    <Transition nodeRef={nodeRef} in={inProp} timeout={600} unmountOnExit>
      {state => (
        <div
          className={classNames(classes.alertContainer, className)}
          ref={nodeRef}
          style={{ ...transitionStyles[state] }}
        >
          <WarningIcon className={classes.infoIcon} />

          <div className={classes.alertDescription}>{message}</div>

          <Button
            buttonType={ButtonType.tertiary}
            className={classes.okButton}
            onClick={onClick}
          >
            {i18n('shared.ok')}
          </Button>
        </div>
      )}
    </Transition>
  );
};

export default PortfolioChangeAlert;
