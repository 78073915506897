import Confirmation from 'features/shared/components/modal/confirmation';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

type Props = {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  onConfirm: () => void;
};

const ReselectConfirmation = ({ isOpen, setOpen, onConfirm }: Props) => {
  const i18n = useI18n();

  return (
    <Confirmation
      header={i18n(
        'roboAdvice.advisory.customPortfolio.reselectConfirmationHeader'
      )}
      isOpen={isOpen}
      onConfirm={onConfirm}
      onDecline={() => setOpen(false)}
    >
      <div>
        {i18n('roboAdvice.advisory.customPortfolio.reselectConfirmationBody')}
      </div>
    </Confirmation>
  );
};

export default ReselectConfirmation;
