import * as R from 'ramda';
import { connect } from 'react-redux';

import Table from './index.js';
import { creators } from 'features/shared/services/table/actions.js';
import tableSelectors from 'features/shared/services/table/selector.js';

export { Table };

export default R.compose(
  connect(
    (state, props) => {
      const { orderDirection, orderKey, pageSize, pageNumber, selectedRowIds } =
        tableSelectors.getTable(props.id, state);

      return {
        orderDirection,
        orderKey,
        pageSize,
        pageNumber,
        selectedRowIds
      };
    },
    (dispatch, props) => {
      return {
        changeOrder: (orderKey, orderDirection) =>
          dispatch(creators.changeOrder(props.id, orderKey, orderDirection)),
        changePageNumber: pageNumber =>
          dispatch(creators.changePageNumber(props.id, pageNumber)),
        changePageSize: pageSize =>
          dispatch(creators.changePageSize(props.id, pageSize)),
        changeRowSelection: (rowId, isSelected) =>
          dispatch(creators.changeRowSelection(props.id, rowId, isSelected)),
        changeAllRowsSelection: (allRowIds, isSelected) =>
          dispatch(
            creators.changeAllRowsSelection(props.id, allRowIds, isSelected)
          )
      };
    }
  )
)(Table);
