export const RuleTypes = {
  never: 'never',
  allFieldsEqual: 'allFieldsEqual',
  fieldChecked: 'fieldChecked',
  buttonSwitchChecked: 'buttonSwitchChecked',
  buttonSwitchUnchecked: 'buttonSwitchUnchecked'
};

export const FieldComponentTypes = {
  dropdown: 'dropdown',
  textInput: 'textInput',
  numberInput: 'numberInput',
  textarea: 'textarea',
  switch: 'switch',
  buttonSwitch: 'buttonSwitch',
  radioButtons: 'radioButtons',
  detalizedNumberInput: 'detalizedNumberInput',
  checkboxes: 'checkboxes',
  datepicker: 'datepicker',
  select: 'select',
  countryDropdown: 'countryDropdown'
};
