import { useSelector } from 'react-redux';

import {
  useHolisticViewStore,
  selectors
} from '../../services/holisticViewStore';
import { useIsLoading } from './../useIsLoading';
import { usePortfolioChartStore } from 'features/roboAdvice/adviceSession/portfolioChart';
import PortfolioChartGridVisual from 'features/shared/analyticsComponents/portfolioChartGrid/visual';
import sessionSelectors from 'features/shared/services/session/selectors';

const PortfolioChartGrid = () => {
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const portfolioChartStore = usePortfolioChartStore();
  const selectedGoalsIds = useHolisticViewStore(selectors.getSelectedGoalsIds);
  const isLoading = useIsLoading();

  return (
    <PortfolioChartGridVisual
      portfolioChartData={portfolioChartStore.chartData}
      selectedGoalsIds={selectedGoalsIds}
      cultureCode={cultureCode as string}
      isLoading={isLoading}
    />
  );
};

export default PortfolioChartGrid;
