import { costTableDataMapping, costIcons } from '@quantfoliorepo/ui-components';
import {
  add,
  mergeWithKey,
  isNil,
  mergeDeepWithKey,
  max,
  findIndex,
  propEq,
  isEmpty,
  omit,
  repeat,
  last,
  concat,
  includes,
  multiply
} from 'ramda';

import { CostChartFunds, CostChartSummary, CostChartTableData } from './types';
import {
  CostFormValues,
  OverrideCost,
  OverrideFundCosts
} from 'features/roboAdvice/adviceSession/proposal/services/costForm';
import {
  CostChartData,
  CostChartGoalData,
  CostChartStoreData,
  TableDataFundDetails,
  TableDataSummary,
  TableDataYear
} from 'features/shared/types/costChartTypes';
import { getNumberFromLocaleStringNumber } from 'features/shared/utils/number';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export const mapChartStoreTableData = ({
  chartStoreTableData,
  i18n,
  cultureCode
}: {
  chartStoreTableData: CostChartStoreData['tableData'];
  i18n: ReturnType<typeof useI18n>;
  cultureCode: string;
}): CostChartTableData => {
  const { summary, firstYear, lastYear, fundDetails } = chartStoreTableData;

  if (!summary || isEmpty(firstYear)) {
    return {};
  }

  return {
    summary: {
      annualOngoingFees: costTableDataMapping
        .getSummaryAnnualOngoingFees(summary, cultureCode)
        .filter(el => !isNil(el.percent))
        .map(el => ({
          ...el,
          icon: costIcons[el.icon],
          field: i18n(el.field)
        })),
      oneTimeFees: costTableDataMapping
        .getSummaryOneTimeFees(summary, cultureCode)
        .filter(el => !isNil(el.percent))
        .map(el => ({ ...el, field: i18n(el.field) })),
      annualReturnCommissionPaidToAdvisor: costTableDataMapping
        .getSummaryAnnualReturnCommissionPaidToAdvisor(summary, cultureCode)
        .filter(el => !isNil(el.percent))
        .map(el => ({ ...el, field: i18n(el.field) })),
      custodyFees: costTableDataMapping
        .getSummaryCustodyFees(summary, cultureCode)
        .filter(el => !isNil(el.value))
        .map(el => ({ ...el, field: i18n(el.field) }))
    },
    firstYear: {
      aggregatedCostEffect: costTableDataMapping
        .getForYearAggregatedCostEffect(firstYear, cultureCode)
        .filter(el => !isNil(el.value) || !isNil(el.percent))
        .map(el => ({ ...el, field: i18n(el.field) })),
      aggregatedOngoingFees: costTableDataMapping
        .getForYearAggregatedOngoingFees(firstYear, cultureCode)
        .filter(el => !isNil(el.value) && !isNil(el.percent))
        .map(el => ({ ...el, field: i18n(el.field) })),
      oneTimeFees: costTableDataMapping
        .getForYearOneTimeFees(firstYear, cultureCode)
        .map(el => ({ ...el, field: i18n(el.field) })),
      aggregatedReturnCommissionPaidToAdvisor: costTableDataMapping
        .getForYearAggregatedReturnCommissionPaidToAdvisor(
          firstYear,
          cultureCode
        )
        .map(el => ({ ...el, field: i18n(el.field) }))
    },
    lastYear: {
      aggregatedCostEffect: costTableDataMapping
        .getForYearAggregatedCostEffect(lastYear, cultureCode)
        .filter(el => !isNil(el.value) || !isNil(el.percent))
        .map(el => ({ ...el, field: i18n(el.field) })),
      aggregatedOngoingFees: costTableDataMapping
        .getForYearAggregatedOngoingFees(lastYear, cultureCode)
        .filter(el => !isNil(el.value) && !isNil(el.percent))
        .map(el => ({ ...el, field: i18n(el.field) })),
      oneTimeFees: costTableDataMapping
        .getForYearOneTimeFees(lastYear, cultureCode)
        .filter(el => !isNil(el.value) && !isNil(el.percent))
        .map(el => ({ ...el, field: i18n(el.field) })),
      aggregatedReturnCommissionPaidToAdvisor: costTableDataMapping
        .getForYearAggregatedReturnCommissionPaidToAdvisor(
          lastYear,
          cultureCode
        )
        .filter(el => !isNil(el.value) && !isNil(el.percent))
        .map(el => ({ ...el, field: i18n(el.field) }))
    },
    fundsCurrency: {
      ongoingFees: fundDetails.ongoingFees
        .map(el => el.currency)
        .map(el => costTableDataMapping.getFundOngoingFees(el, cultureCode, 0)),
      oneTimeFees: fundDetails.oneTimeFees
        .map(el => el.currency)
        .map(el => costTableDataMapping.getFundOneTimeFees(el, cultureCode, 0)),
      custodyFees: fundDetails.custodyFees
        .map(el => el.currency)
        .map(el => costTableDataMapping.getFundCustodyFees(el, cultureCode, 0)),
      returnCommissionPaidToAdvisor: fundDetails.returnCommissionPaidToAdvisor
        .map(el => el.currency)
        .map(el =>
          costTableDataMapping.getFundReturnCommissionPaidToAdvisor(
            el,
            cultureCode,
            0
          )
        )
    },
    fundsPercent: {
      ongoingFees: fundDetails.ongoingFees
        .map(el => el.percent)
        .map(el => costTableDataMapping.getFundOngoingFees(el, cultureCode, 2)),
      oneTimeFees: fundDetails.oneTimeFees
        .map(el => el.percent)
        .map(el => costTableDataMapping.getFundOneTimeFees(el, cultureCode, 2)),
      custodyFees: fundDetails.custodyFees
        .map(el => el.percent)
        .map(el => costTableDataMapping.getFundCustodyFees(el, cultureCode, 2)),
      returnCommissionPaidToAdvisor: fundDetails.returnCommissionPaidToAdvisor
        .map(el => el.percent)
        .map(el =>
          costTableDataMapping.getFundReturnCommissionPaidToAdvisor(
            el,
            cultureCode,
            2
          )
        )
    }
  };
};

export const prepareCostChartData = ({
  selectedGoalsData,
  i18n,
  isUsedMultiGoalCostEndpoint
}: {
  selectedGoalsData: CostChartGoalData[];
  i18n: ReturnType<typeof useI18n>;
  isUsedMultiGoalCostEndpoint?: boolean;
}) => {
  const selectedGoalsDepositSum = selectedGoalsData.reduce((acc, val) => {
    return acc + val.startingCapital;
  }, 0);

  const goalsDataMaxLength = selectedGoalsData.reduce(
    (acc, current) => max(acc, current.chartData.length),
    0
  );

  const chartData = selectedGoalsData
    .map(({ chartData }) => {
      const data = concat(
        chartData,
        repeat(last(chartData), goalsDataMaxLength - chartData.length)
      );
      return data.map((item, index) => ({ ...item, index }));
    })
    .flat()
    .reduce((acc, current) => {
      const index = acc.findIndex(({ index }) => index === current.index);
      if (index !== -1) {
        return acc.map((item, i) =>
          i === index
            ? mergeWithKey(
                (k, l, r) => (k === 'index' ? r : add(l, r)),
                acc[index],
                current
              )
            : item
        );
      }
      return [...acc, current];
    }, [] as (CostChartData & { index: number })[]);

  const summary = selectedGoalsData
    .map(({ tableData: { summary }, startingCapital }) => ({
      summary,
      startingCapital
    }))
    .reduce((acc, current) => {
      const portfolioWeight = current.startingCapital / selectedGoalsDepositSum;
      return mergeDeepWithKey(
        (key, a, b) => {
          if (key === 'custodyFeeAmount') {
            if (isUsedMultiGoalCostEndpoint) {
              return add(a, b);
            } else {
              return max(a, b);
            }
          }

          return isNil(a) && isNil(b) ? null : add(a, b);
        },
        acc,
        applyWeights(current.summary, portfolioWeight, ['custodyFeeAmount'])
      );
    }, {} as TableDataSummary);

  const firstYear = selectedGoalsData.map(
    ({ tableData: { firstYear } }) => firstYear
  );

  const firstYearDepositsSum = firstYear.reduce((acc, val) => {
    return acc + (val.aggregatedCostEffect.deposits ?? 0);
  }, 0);
  const firstYearValuesSum = firstYear.reduce((acc, val) => {
    return acc + (val.aggregatedCostEffect.valueBeforeCost ?? 0);
  }, 0);

  const firstYearMerged = firstYear.reduce((acc, current) => {
    const portfolioWeight =
      (current.aggregatedCostEffect.deposits ?? 0) / firstYearDepositsSum;
    const portfolioValueWeight =
      (current.aggregatedCostEffect.valueBeforeCost ?? 0) / firstYearValuesSum;
    return mergeDeepWithKey(
      (key, a, b) => {
        if (key === 'custodyFee') {
          if (isUsedMultiGoalCostEndpoint) {
            return add(a, b);
          } else {
            return max(a, b);
          }
        }
        return isNil(a) && isNil(b) ? null : (a ?? 0) + (b ?? 0);
      },
      acc,
      {
        ...current,
        aggregatedCostEffect: {
          ...current.aggregatedCostEffect,
          reductionInReturnPercent:
            (current.aggregatedCostEffect.reductionInReturnPercent ?? 0) *
            portfolioWeight,
          returnAfterCost:
            (current.aggregatedCostEffect.returnAfterCost ?? 0) *
            portfolioWeight
        },
        aggregatedOngoingFeesPercent: applyWeights(
          current.aggregatedOngoingFeesPercent,
          portfolioValueWeight,
          []
        ),
        aggregatedReturnCommissionPaidToAdvisorPercent: applyWeights(
          current.aggregatedReturnCommissionPaidToAdvisorPercent,
          portfolioValueWeight,
          []
        ),
        oneTimeFeesPercent: applyWeights(
          current.oneTimeFeesPercent,
          portfolioValueWeight,
          []
        )
      }
    );
  }, {} as TableDataYear);
  if (firstYearMerged.aggregatedOngoingFees) {
    firstYearMerged.aggregatedOngoingFees.totalPortfolioFee = getOngoingFeesSum(
      firstYearMerged.aggregatedOngoingFees
    );
  }
  if (firstYearMerged.aggregatedOngoingFeesPercent) {
    firstYearMerged.aggregatedOngoingFeesPercent.totalPortfolioFee =
      getOngoingFeesSum(firstYearMerged.aggregatedOngoingFeesPercent);
  }

  const lastYear = selectedGoalsData.map(
    ({ tableData: { lastYear } }) => lastYear
  );

  const lastYearDepositsSum = lastYear.reduce((acc, val) => {
    return acc + (val.aggregatedCostEffect.deposits ?? 0);
  }, 0);

  const lastYearValuesSum = lastYear.reduce((acc, val) => {
    return acc + (val.aggregatedCostEffect.valueBeforeCost ?? 0);
  }, 0);

  const lastYearMerged = lastYear.reduce((acc, current) => {
    const portfolioWeight =
      (current.aggregatedCostEffect.deposits ?? 0) / lastYearDepositsSum;
    const portfolioValueWeight =
      (current.aggregatedCostEffect.valueBeforeCost ?? 0) / lastYearValuesSum;
    return mergeDeepWithKey(
      (key, a, b) => {
        if (key === 'custodyFee') {
          if (isUsedMultiGoalCostEndpoint) {
            return add(a, b);
          } else {
            return max(a, b);
          }
        }
        return isNil(a) && isNil(b) ? null : (a ?? 0) + (b ?? 0);
      },
      acc,
      {
        ...current,
        aggregatedCostEffect: {
          ...current.aggregatedCostEffect,
          reductionInReturnPercent:
            (current.aggregatedCostEffect.reductionInReturnPercent ?? 0) *
            portfolioWeight,
          returnAfterCost:
            (current.aggregatedCostEffect.returnAfterCost ?? 0) *
            portfolioWeight
        },
        aggregatedOngoingFeesPercent: applyWeights(
          current.aggregatedOngoingFeesPercent,
          portfolioValueWeight,
          []
        ),
        aggregatedReturnCommissionPaidToAdvisorPercent: applyWeights(
          current.aggregatedReturnCommissionPaidToAdvisorPercent,
          portfolioValueWeight,
          []
        ),
        oneTimeFeesPercent: applyWeights(
          current.oneTimeFeesPercent,
          portfolioValueWeight,
          []
        )
      }
    );
  }, {} as TableDataYear);

  if (lastYearMerged.aggregatedOngoingFees) {
    lastYearMerged.aggregatedOngoingFees.totalPortfolioFee = getOngoingFeesSum(
      lastYearMerged.aggregatedOngoingFees
    );
  }
  if (lastYearMerged.aggregatedOngoingFeesPercent) {
    lastYearMerged.aggregatedOngoingFeesPercent.totalPortfolioFee =
      getOngoingFeesSum(lastYearMerged.aggregatedOngoingFeesPercent);
  }

  const fundDetails: TableDataFundDetails = selectedGoalsData
    .map(({ tableData: { fundDetails }, startingCapital }) => ({
      ongoingFees: fundDetails.ongoingFees.map(fd =>
        getFundDetailsData(fd, startingCapital, selectedGoalsDepositSum, i18n)
      ),
      oneTimeFees: fundDetails.oneTimeFees.map(fd =>
        getFundDetailsData(fd, startingCapital, selectedGoalsDepositSum, i18n)
      ),
      custodyFees: fundDetails.custodyFees.map(fd => ({
        percent:
          fd.percent.fund === i18n('portfolioChart.default.portfolio')
            ? {
                fund: fd.percent.fund,
                custodyFee: fd.percent.custodyFee
              }
            : applyWeights(
                fd.percent,
                Math.pow(startingCapital / selectedGoalsDepositSum, 2),
                ['fund']
              ),
        currency:
          fd.currency.fund === i18n('portfolioChart.default.portfolio') ||
          isUsedMultiGoalCostEndpoint
            ? {
                custodyFee: fd.currency.custodyFee,
                fund: fd.currency.fund
              }
            : applyWeights(
                fd.currency,
                startingCapital / selectedGoalsDepositSum,
                ['fund']
              ),
        fund: fd.currency.fund
      })),
      returnCommissionPaidToAdvisor:
        fundDetails.returnCommissionPaidToAdvisor.map(fd =>
          getFundDetailsData(fd, startingCapital, selectedGoalsDepositSum, i18n)
        )
    }))
    .reduce(
      (acc, current) => ({
        ongoingFees: [...acc.ongoingFees, ...current.ongoingFees],
        oneTimeFees: [...acc.oneTimeFees, ...current.oneTimeFees],
        custodyFees: [...acc.custodyFees, ...current.custodyFees],
        returnCommissionPaidToAdvisor: [
          ...acc.returnCommissionPaidToAdvisor,
          ...current.returnCommissionPaidToAdvisor
        ]
      }),
      {
        ongoingFees: [],
        oneTimeFees: [],
        custodyFees: [],
        returnCommissionPaidToAdvisor: []
      } as TableDataFundDetails
    );

  Object.keys(fundDetails).forEach(key => {
    fundDetails[key] = fundDetails[key].reduce(
      (
        acc: TableDataFundDetails[keyof TableDataFundDetails][number][],
        current: TableDataFundDetails[keyof TableDataFundDetails][number]
      ) => {
        const index = acc.findIndex(({ fund }) => fund === current.fund);
        if (index !== -1) {
          return acc.map((item, i) =>
            i === index
              ? {
                  currency: mergeWithKey(
                    (k, l, r) => {
                      if (isNil(l) && isNil(r)) {
                        return null;
                      }

                      if (isUsedMultiGoalCostEndpoint) {
                        return add(l, r);
                      }

                      return (item.fund ===
                        i18n('portfolioChart.default.portfolio') &&
                        key !== 'custodyFees') ||
                        (item.fund !==
                          i18n('portfolioChart.default.portfolio') &&
                          key === 'custodyFees')
                        ? add(l, r)
                        : max(l, r);
                    },
                    omit(['fund'], acc[index].currency),
                    current.currency
                  ),
                  percent: mergeWithKey(
                    (k, l, r) => {
                      if (isNil(l) && isNil(r)) {
                        return null;
                      }
                      return (item.fund ===
                        i18n('portfolioChart.default.portfolio') &&
                        key !== 'custodyFees') ||
                        key === 'custodyFees'
                        ? add(l, r)
                        : max(l, r);
                    },
                    omit(['fund'], acc[index].percent),
                    current.percent
                  ),
                  fund: current.fund
                }
              : item
          );
        }
        return [...acc, current];
      },
      [] as TableDataFundDetails[keyof TableDataFundDetails][number][]
    );

    const portfolioIndex = findIndex(
      propEq('fund', i18n('portfolioChart.default.portfolio'))
    )(fundDetails[key]);
    if (portfolioIndex) {
      fundDetails[key].push(fundDetails[key].splice(portfolioIndex, 1)[0]);
    }
  });

  if (
    fundDetails.custodyFees?.length &&
    !isNil(fundDetails.custodyFees[fundDetails.custodyFees.length - 1])
  ) {
    fundDetails.custodyFees[
      fundDetails.custodyFees.length - 1
    ].percent.custodyFee = getCustodyFeePortfolioPercent(
      fundDetails.custodyFees.at(-1)?.currency.custodyFee ?? 0,
      selectedGoalsDepositSum
    );
  }

  return { summary, chartData, firstYearMerged, lastYearMerged, fundDetails };
};

const applyWeights = <T extends Record<string, unknown>>(
  data: T,
  weight: number,
  omitKeys: string[]
): T => {
  return mergeDeepWithKey(
    (key, l) => (includes(key, omitKeys) || isNil(l) ? l : multiply(l, weight)),
    data,
    data
  );
};

const getOngoingFeesSum = (fees: TableDataYear[keyof TableDataYear]) => {
  if (fees) {
    return Object.entries(fees).reduce((acc, [key, val]) => {
      if (key === 'totalPortfolioFee') {
        return acc;
      }
      return key !== 'fundReturnCommissionPaidToClient'
        ? acc + (val ?? 0)
        : acc - (val ?? 0);
    }, 0);
  }

  return 0;
};

const getFundDetailsData = <
  T extends TableDataFundDetails[keyof TableDataFundDetails][number]
>(
  fund: T,
  startingCapital: number,
  selectedGoalsDepositSum: number,
  i18n: ReturnType<typeof useI18n>
): T | { currency: T['currency']; percent: T['percent']; fund: string } => {
  if (fund.currency.fund === i18n('portfolioChart.default.portfolio')) {
    return {
      currency: fund.currency,
      percent: applyWeights(
        fund.percent,
        startingCapital / selectedGoalsDepositSum,
        ['fund']
      ),
      fund: fund.currency.fund
    };
  }

  return {
    ...fund,
    fund: fund.currency.fund
  };
};

const getCustodyFeePortfolioPercent = (
  custodyFee: number,
  selectedGoalsDepositSum: number
) => {
  return (100 * custodyFee) / selectedGoalsDepositSum;
};

export const getOnlyModifiedOverrideCostTableData = ({
  values,
  cultureCode,
  originalTableData,
  i18n
}: {
  values: CostFormValues;
  cultureCode: string;
  originalTableData: CostChartStoreData['tableData'];
  i18n: ReturnType<typeof useI18n>;
}) => {
  const mappedOriginalTableData =
    !isNil(originalTableData) && !isEmpty(originalTableData)
      ? mapChartStoreTableData({
          chartStoreTableData: originalTableData,
          cultureCode,
          i18n
        })
      : null;

  const originalFundsPercent = Object.values(
    mappedOriginalTableData?.fundsPercent || ({} as CostChartFunds)
  ).reduce((acc, value) => {
    for (const { fund, ...restFees } of value) {
      if (fund === i18n('portfolioChart.default.portfolio')) {
        continue;
      }

      acc[fund] = { ...acc[fund], ...restFees };
    }

    return acc;
  }, {});

  const originalSummary = Object.values(
    mappedOriginalTableData?.summary || ({} as CostChartSummary)
  ).reduce((acc, value) => {
    for (const fee of value) {
      if (fee.name) {
        acc[fee.name] = getNumberFromLocaleStringNumber(
          fee.value ?? fee.percent,
          cultureCode
        );
      }
    }

    return acc;
  }, {});

  return {
    ...values,
    overrideCost: Object.entries(
      values.overrideCost || ({} as OverrideCost)
    ).reduce((acc, [feeName, feeValue]) => {
      if (originalSummary[feeName] !== feeValue) {
        acc[feeName] = feeValue;
      }

      return acc;
    }, {}),
    overrideFundCosts: Object.entries(
      values.overrideFundCosts || ({} as OverrideFundCosts)
    ).reduce((acc, [fundName, fees]) => {
      Object.entries(fees).forEach(([feeName, feeValue]) => {
        if (
          getNumberFromLocaleStringNumber(
            originalFundsPercent[fundName]?.[feeName],
            cultureCode
          ) !== feeValue
        ) {
          acc[fundName] = {
            ...acc[fundName],
            [feeName]: feeValue
          };
        }
      });

      return acc;
    }, {})
  };
};
