import * as R from 'ramda';
import React from 'react';

import Table from './table.js';
import TableBody from './tableBody.js';
import TableBodyRow from './tableBodyRow.js';

const TableAdapter = props => {
  const { header, noDataTitle, selectedRowIds, changeRowSelection, items } =
    props;

  return (
    <Table {...props}>
      <TableBody cellsNumber={header.length} noDataTitle={noDataTitle}>
        {R.map(
          item => (
            <TableBodyRow
              key={item.id}
              onClick={item.onClick}
              data-testid={item.testId}
              id={item.id}
              selectedRowIds={selectedRowIds}
              changeRowSelection={changeRowSelection}
            >
              {item.cells}
            </TableBodyRow>
          ),
          items
        )}
      </TableBody>
    </Table>
  );
};

export default TableAdapter;
