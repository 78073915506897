import { FormValues } from '../form/types';
import { Template, useTemplateStore } from '../templateStore';
import useGetInitialValues from '../useGetInitialValues';
import Select from 'features/shared/components/select';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

type Props = {
  templates: Template[];
  resetForm: (initialValues?: Partial<FormValues> | undefined) => void;
};

const TemplateSelect = ({ resetForm, templates }: Props) => {
  const { setTemplate } = useTemplateStore();
  const getInitialValues = useGetInitialValues();
  const i18n = useI18n();

  const selectOptions = templates.map(template => ({
    label: template.label,
    value: template.id
  }));

  const onChange = (selectedTemplateId: string) => {
    const template = templates.find(
      template => template.id === selectedTemplateId
    );

    setTemplate(template as Template);

    const newInitialValues = getInitialValues();
    resetForm(newInitialValues);
  };

  return (
    <Select
      options={selectOptions}
      onChange={onChange}
      placeholder={i18n('shared.select.placeholder')}
    />
  );
};

export default TemplateSelect;
