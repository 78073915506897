import * as R from 'ramda';

import { CustomerConfigType } from './types';
import { defaultAdviceFlows } from 'defaults/defaultAdviceFlows';
import { defaultAdvisoryComponents } from 'defaults/defaultAdvisoryComponents';
import { defaultAnalyticsComponents } from 'defaults/defaultAnalyticsComponents';
import { defaultReportAdvice } from 'defaults/defaultReportAdvice';
import { defaultReportOrderExecution } from 'defaults/defaultReportOrderExecution';
import { defaultRiskScenarios } from 'defaults/defaultRiskScenarios';
import { defaultRoboAdvice } from 'defaults/defaultRoboAdvice';
import { defaultRoboAdviceForm } from 'defaults/defaultRoboAdviceForm';
import { defaultRoboOrderFlow } from 'defaults/defaultRoboOrderFlow';
import { defaultSettingsApplication } from 'defaults/defaultSettingsApplication';
import { defaultSupportedLanguages } from 'defaults/defaultSupportedLanguages';
import { defaultSystemConfig } from 'defaults/defaultSystemConfig';
import { defaultTenantSettings } from 'defaults/defaultTenantSettings';
import { defaultTheme } from 'defaults/defaultTheme';
import { defaultThemes } from 'defaults/defaultThemes';
import { defaultTimeHorizonConfig } from 'defaults/defaultTimeHorizonConfig';
import { defaultTools } from 'defaults/defaultTools';
import { defaultTranslations } from 'defaults/defaultTranslations';
import { ModuleTypes } from 'features/shared/constants/session';
import { globalizeSelectors } from 'framework/globalize/utils/index.js';

export const DefaultCustomerConfig: CustomerConfigType = {
  styles: defaultTheme,
  translations: defaultTranslations,
  roboAdviceForm: defaultRoboAdviceForm,
  roboAdvice: defaultRoboAdvice,
  analyticsComponents: defaultAnalyticsComponents,
  timeHorizonConfig: defaultTimeHorizonConfig,
  themes: defaultThemes,
  tools: defaultTools,
  advisoryComponents: defaultAdvisoryComponents,
  supportedLanguages: defaultSupportedLanguages,
  languageDescriptions: {},
  roboOrderFlow: defaultRoboOrderFlow,
  tenantSettings: defaultTenantSettings,
  adviceFlows: defaultAdviceFlows,
  styleSheets: [],
  riskEndpointType: 'generic',
  roboPortfolioPrecision: 1,
  customRiskTitles: null,
  reportAttachments: [],
  system: defaultSystemConfig,
  riskScenarios: defaultRiskScenarios,
  reportAdvice: defaultReportAdvice,
  reportOrderExecution: defaultReportOrderExecution,
  language: 'en',
  settingsApplication: defaultSettingsApplication
};

const getCurrentUserPicture = state => {
  const defaultPicture = R.path(['currentUser', 'picture'], state);
  const customPicture = R.path(
    ['currentUser', 'customPicture', 'thumbnail'],
    state
  );
  return R.isNil(customPicture) ? defaultPicture : customPicture;
};

const getAdvisorSolutionLicense = R.pipe<unknown, any, any>(
  R.pathOr([], ['currentUser', 'licenses']),
  R.find(license => license.moduleType === ModuleTypes.advisorSolution)
);

const getIsAdvisorSolutionLicenseValid = state => {
  const license = getAdvisorSolutionLicense(state);

  if (R.isNil(license)) {
    return false;
  }

  return true;
};

export default globalizeSelectors(['session_state'], {
  getIsAuthenticated: state =>
    !R.isNil(state.auth0AccessToken) &&
    !R.isNil(R.path(['currentUser', 'customerId'], state)),
  getAuth0AccessToken: state => state.auth0AccessToken,
  getCurrentUser: state => state.currentUser || {},
  getCurrentUserPermissions: state =>
    R.isNil(state.currentUser) ? [] : state.currentUser.permissions,
  getCurrentUserRoles: state =>
    R.isNil(state.currentUser) ? [] : state.currentUser.roles,
  getCurrentUserPicture,
  getCurrentUserCultureCode: R.path(['currentUser', 'cultureCode']),
  getCurrentUserCustomerCode: R.path(['currentUser', 'customerCode']),
  getCurrentUserAdvisoryKey: R.path(['currentUser', 'advisoryKey']),
  getCurrentUserName: R.path(['currentUser', 'name']),
  getCurrentUserTimezone: R.path(['currentUser', 'timezone']),
  getCustomerConfig: state =>
    state.customerConfig ? state.customerConfig : DefaultCustomerConfig,
  getIsAdvisorSolutionLicenseValid,
  getCurrentUserCustomerId: R.path(['currentUser', 'customerId']),
  getIsCustomerAdmin: R.path(['currentUser', 'isCustomerAdmin']),
  getIsCustomerQfEmployee: R.path(['currentUser', 'isCustomerQfEmployee'])
});
