import { propOr } from 'ramda';
import { useEffect } from 'react';

import { usePortfolioChartStore } from '../../portfolioChart';
import { useUpdateGoal } from '../../purposeAndRisk/components/useUpdateGoal';
import { useSessionStore } from '../../session/services/sessionStore';
import { Goal } from '../../shared/services/goalsStore';
import { useIsFollowUpEnabled } from 'features/roboAdvice/shared/components/useIsFollowUpEnabled';
import { Asset } from 'features/shared/analyticsComponents/portfolioChartCards/visual';
import { roundNumber } from 'features/shared/utils/number';

type Arguments = {
  selectedGoal: Goal;
};

export const useInitFollowUpModelPortfolio = ({ selectedGoal }: Arguments) => {
  const sessionStore = useSessionStore();
  const updateGoal = useUpdateGoal();
  const portfolioChartStore = usePortfolioChartStore();
  const categorySelectionPortfolio = propOr(
    [],
    'categorySelectionPortfolio',
    portfolioChartStore.getChartData(
      selectedGoal.goalId,
      selectedGoal.data.isPortfolioCustom
    )
  ) as Asset[];
  const { isFollowUpEnabled } = useIsFollowUpEnabled();

  useEffect(() => {
    const checkIfModelPortfolioIsDifferentFromOriginalAdvice = async () => {
      if (
        sessionStore.followUpId &&
        isFollowUpEnabled &&
        !selectedGoal.data.isFollowUpModelPortfolioChangedChecked &&
        selectedGoal.data.portfolio &&
        categorySelectionPortfolio.length > 0
      ) {
        const originalGoalData = sessionStore?.followUpData?.goals?.find(
          goal => goal.newGoalId === selectedGoal.goalId
        )?.data;

        // Check if risk score has changed
        const originalModelPortfolio = originalGoalData?.portfolio;

        // Check if instruments are different
        const originalModelPortfolioInstrumentAllocation =
          originalGoalData?.modelPortfolioInstrumentAllocation ?? [];

        const areInstrumentsDifferent =
          originalModelPortfolioInstrumentAllocation.length !==
            categorySelectionPortfolio.length ||
          originalModelPortfolioInstrumentAllocation.some(instrument => {
            const foundInstrument = categorySelectionPortfolio.find(
              category => category.isin === instrument.ISIN
            );
            const originalInstrumentWeight = roundNumber(
              instrument.weight * 100,
              3
            );
            const newInstrumentWeight = roundNumber(
              foundInstrument?.weight ?? 0,
              3
            );

            return newInstrumentWeight !== originalInstrumentWeight;
          });

        // Display alerts in portfolio interface
        await updateGoal(selectedGoal.goalId, {
          data: {
            shouldDisplayFollowUpModelPortfolioRiskChangedAlert:
              originalModelPortfolio !== selectedGoal.data.portfolio,
            shouldDisplayFollowUpModelPortfolioInstrumentsChangedAlert:
              areInstrumentsDifferent,
            isFollowUpModelPortfolioChangedChecked: true
          }
        });
      }
    };

    checkIfModelPortfolioIsDifferentFromOriginalAdvice();
  }, [
    selectedGoal.goalId,
    selectedGoal.data.portfolio,
    sessionStore.followUpId,
    isFollowUpEnabled
  ]);
};
