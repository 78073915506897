import { addYears, subYears, setDate, add, sub } from 'date-fns';

const dateFormats = {
  years: 'yyyy',
  monthYear: 'MM.yyyy',
  monthDayYear: 'MM.dd.yyyy',
  dayMonthYear: 'dd.MM.yyyy'
};

const defaultAllowedYearsRange = {
  yearsBack: 100,
  yearsForward: 150
};

const dateTypes = {
  history: 'history',
  future: 'future'
} as const;

const MonthShortKeys = [
  'shared.shortMonth.january',
  'shared.shortMonth.february',
  'shared.shortMonth.march',
  'shared.shortMonth.april',
  'shared.shortMonth.may',
  'shared.shortMonth.june',
  'shared.shortMonth.july',
  'shared.shortMonth.august',
  'shared.shortMonth.september',
  'shared.shortMonth.october',
  'shared.shortMonth.november',
  'shared.shortMonth.december'
];

const WeekDayShortKeys = [
  'shared.shortDayOfTheWeek.sunday',
  'shared.shortDayOfTheWeek.monday',
  'shared.shortDayOfTheWeek.tuesday',
  'shared.shortDayOfTheWeek.wednesday',
  'shared.shortDayOfTheWeek.thursday',
  'shared.shortDayOfTheWeek.friday',
  'shared.shortDayOfTheWeek.saturday'
];

const range = (start: number, stop: number, step: number): number[] =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const getYearsRange = (
  dateType: keyof typeof dateTypes,
  yearsBack?: number,
  yearsForward?: number
): number[] => {
  const currentYear = new Date().getFullYear();

  const startYear =
    dateType === dateTypes.future
      ? currentYear
      : currentYear - (yearsBack || defaultAllowedYearsRange.yearsBack);

  const endYear =
    dateType === dateTypes.history
      ? currentYear
      : currentYear + (yearsForward || defaultAllowedYearsRange.yearsForward);

  return range(startYear, endYear, 1);
};

const isDateAllowed = (
  date: Date,
  yearsBack?: number,
  yearsForward?: number
): boolean => {
  const today = new Date();
  const selectedDate = new Date(date);

  const minAllowedDate = new Date(
    today.setFullYear(
      today.getFullYear() - (yearsBack || defaultAllowedYearsRange.yearsBack)
    )
  );
  const maxAllowedDate = new Date(
    today.setFullYear(
      today.getFullYear() +
        (yearsForward || defaultAllowedYearsRange.yearsForward)
    )
  );

  return selectedDate > minAllowedDate && selectedDate < maxAllowedDate;
};

const filterMonthDateByType = (
  date: Date,
  dateType: keyof typeof dateTypes,
  yearsBack?: number,
  yearsForward?: number
): boolean => {
  const currentDate = setDate(new Date(), 2);
  const selectedDate = setDate(new Date(date), 1);
  const maxAllowedMY = add(new Date(), {
    years: yearsForward || defaultAllowedYearsRange.yearsForward
  });
  const minAllowedMY = sub(new Date(), {
    years: yearsBack || defaultAllowedYearsRange.yearsBack
  });

  if (dateType === dateTypes.history) {
    return selectedDate > minAllowedMY && selectedDate < currentDate;
  }
  if (dateType === dateTypes.future) {
    return (
      setDate(selectedDate, 2) > setDate(currentDate, 1) &&
      selectedDate < maxAllowedMY
    );
  }

  return selectedDate > minAllowedMY && selectedDate < maxAllowedMY;
};

const filterDateByType = (
  date: Date,
  dateType: keyof typeof dateTypes,
  yearsBack?: number,
  yearsForward?: number
): boolean => {
  const currentDate = new Date();
  const selectedDate = new Date(date);

  if (dateType === dateTypes.history) {
    return (
      currentDate > selectedDate && isDateAllowed(date, yearsBack, yearsForward)
    );
  }
  if (dateType === dateTypes.future) {
    return (
      currentDate < selectedDate && isDateAllowed(date, yearsBack, yearsForward)
    );
  }

  return isDateAllowed(date, yearsBack, yearsForward);
};

const isDateFormatCorrect = (dateFormat: string): boolean => {
  return Object.values(dateFormats).includes(dateFormat);
};

const setMinAllowedDate = (
  dateType: keyof typeof dateTypes,
  yearsBack?: number
): Date => {
  if (dateType === dateTypes.future) {
    return new Date();
  }
  return subYears(new Date(), yearsBack || defaultAllowedYearsRange.yearsBack);
};

const setMaxAllowedDate = (dateType: string, yearsForward?: number): Date => {
  if (dateType === dateTypes.history) {
    return new Date();
  }
  return addYears(
    new Date(),
    yearsForward || defaultAllowedYearsRange.yearsForward
  );
};

const checkIsMonthActive = (
  selectedDate: Date | null,
  month: string,
  months: string[],
  minAllowedDate: Date,
  maxAllowedDate: Date
): boolean => {
  if (selectedDate) {
    const selectedYear = new Date(selectedDate).getFullYear();
    const currentMonth = new Date(selectedYear, months.indexOf(month), 2);
    if (
      setDate(minAllowedDate, 1) > currentMonth ||
      setDate(maxAllowedDate, 3) < currentMonth
    ) {
      return false;
    }
  }

  return true;
};

export {
  filterMonthDateByType,
  isDateFormatCorrect,
  checkIsMonthActive,
  setMinAllowedDate,
  setMaxAllowedDate,
  filterDateByType,
  getYearsRange,
  dateFormats,
  dateTypes,
  MonthShortKeys,
  WeekDayShortKeys
};
