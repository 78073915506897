import { z } from 'zod';

import { adviceFlowsSchema } from 'defaults/defaultAdviceFlows';
import { advisoryComponentsSchema } from 'defaults/defaultAdvisoryComponents';
import { analyticsComponentsSchema } from 'defaults/defaultAnalyticsComponents';
import { reportAdviceSchema } from 'defaults/defaultReportAdvice';
import { reportAttachmentsSchema } from 'defaults/defaultReportAttachments';
import { reportOrderExecutionSchema } from 'defaults/defaultReportOrderExecution';
import { riskScenariosSchema } from 'defaults/defaultRiskScenarios';
import { roboAdviceSchema } from 'defaults/defaultRoboAdvice';
import { roboAdviceFormSchema } from 'defaults/defaultRoboAdviceForm';
import { roboOrderFlowSchema } from 'defaults/defaultRoboOrderFlow';
import { settingsApplicationSchema } from 'defaults/defaultSettingsApplication';
import { supportedLanguagesSchema } from 'defaults/defaultSupportedLanguages';
import { systemConfigSchema } from 'defaults/defaultSystemConfig';
import { tenantSettingsSchema } from 'defaults/defaultTenantSettings';
import { themeSchema } from 'defaults/defaultTheme';
import { themesSchema } from 'defaults/defaultThemes';
import { horizonConfigSchema } from 'defaults/defaultTimeHorizonConfig';
import { toolsSchema } from 'defaults/defaultTools';

export const customerConfigSchema = z.object({
  styles: themeSchema,
  styleSheets: z.array(z.string()),
  translations: z.record(z.string()),
  roboAdviceForm: roboAdviceFormSchema,
  analyticsComponents: analyticsComponentsSchema,
  timeHorizonConfig: horizonConfigSchema,
  riskEndpointType: z.string(),
  advisoryComponents: advisoryComponentsSchema,
  roboPortfolioPrecision: z.number(),
  themes: themesSchema,
  supportedLanguages: supportedLanguagesSchema,
  languageDescriptions: z.record(z.string()),
  roboAdvice: roboAdviceSchema,
  roboOrderFlow: roboOrderFlowSchema,
  tenantSettings: tenantSettingsSchema,
  tools: toolsSchema,
  customRiskTitles: z.record(z.string()).nullable(),
  reportAttachments: reportAttachmentsSchema,
  adviceFlows: adviceFlowsSchema,
  system: systemConfigSchema,
  riskScenarios: riskScenariosSchema,
  reportAdvice: reportAdviceSchema,
  reportOrderExecution: reportOrderExecutionSchema,
  language: z.string(),
  settingsApplication: settingsApplicationSchema
});

export type CustomerConfigType = z.infer<typeof customerConfigSchema>;

export type CurrentUser = {
  id: string;
  auth0Id: string;
  name: string;
  email: string;
  bio: string | null;
  phone: string | null;
  timezone: string | null;
  picture: string;
  customPicture: {
    fullSize: string;
    thumbnail: string;
  } | null;
  permissions: string[];
  roles: string[];
  countryId: string | null;
  cultureCode: string | null;
  isCustomerAdmin: boolean;
  isCustomerQfEmployee: boolean;
  customerId: string;
  licenses: {
    moduleType: string;
    licenseType: string;
    licenseRenewalDate: string;
  }[];
  customerCode: string;
  advisoryKey: string;
  externalId: string | null;
};
