import React from 'react';

import NavigationDropdownInput from './navigationDropdownInput.js';
import NavigationDropdownMenu from './navigationDropdownMenu.js';
import Dropdown from 'features/shared/components/dropdown/index';

const NavigationDropdown = ({ className, ...restProps }) => {
  return (
    <Dropdown
      width={'auto'}
      input={props => <NavigationDropdownInput {...props} />}
      menu={props => <NavigationDropdownMenu {...props} />}
      className={className}
      {...restProps}
    />
  );
};

export default NavigationDropdown;
