import classNames from 'classnames';
import { ChangeEventHandler, DetailedHTMLProps } from 'react';

import { ReactComponent as RadioCheckedIcon } from 'assets/radioChecked.svg';
import { ReactComponent as RadioUncheckedIcon } from 'assets/radioUnchecked.svg';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  container: {
    position: 'relative',
    lineHeight: '0'
  },
  input: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 0,
    opacity: 0,
    cursor: 'pointer',
    width: 25,
    height: 25,

    '&:disabled': {
      cursor: 'default'
    },

    '& + svg': {
      border: `2px solid transparent`,
      borderRadius: '50%',
      boxSizing: 'content-box'
    },

    '&:focus-visible + svg': {
      border: `2px solid ${theme.primaryColor}`
    }
  },
  icon: {
    width: 25,
    height: 25,
    fill: theme.accentColor,
    userSelect: 'none'
  },
  disabled: {
    fill: theme.disabledTextColor
  }
}));

type Props = {
  checked: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onClick?: () => void;
  iconColor?: string;
  className?: string;
  disabled?: boolean;
} & DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const Radio = ({
  checked,
  onChange,
  onClick,
  iconColor,
  className,
  disabled,
  ...restProps
}: Props) => {
  const classes = useStyles();

  const iconStyles = iconColor ? { fill: iconColor } : {};

  return (
    <div className={classNames(classes.container, className)}>
      <input
        disabled={disabled}
        type="radio"
        checked={checked}
        onChange={onChange}
        onClick={onClick}
        className={classes.input}
        {...restProps}
      />
      {checked && (
        <RadioCheckedIcon
          className={classNames(classes.icon, {
            [classes.disabled]: disabled
          })}
          style={iconStyles}
        />
      )}
      {!checked && (
        <RadioUncheckedIcon
          className={classNames(classes.icon, {
            [classes.disabled]: disabled
          })}
          style={iconStyles}
        />
      )}
    </div>
  );
};

export default Radio;
