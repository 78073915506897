import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { usePreMeetingSurveyStore } from 'features/roboAdvice/client/adviceSessionsList/services/preMeetingSurveyStore';
import { getActivePreMeetingOtpSession } from 'features/roboAdvice/client/main/api';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export const useReadExistingOtpSession = () => {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();
  const i18n = useI18n();
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  const readExistingOtpSession = async (sessionId: string) => {
    const {
      setOriginalInvestorPhoneNumber,
      originalInvestorPhoneNumberStatus,
      setOriginalInvestorPhoneNumberStatus
    } = usePreMeetingSurveyStore.getState();

    if (originalInvestorPhoneNumberStatus !== PageStatuses.ready) {
      return;
    }

    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    try {
      setOriginalInvestorPhoneNumberStatus(PageStatuses.pending);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const { data } = await getActivePreMeetingOtpSession(
        accessToken,
        cancelTokenSource.token,
        sessionId
      );

      setOriginalInvestorPhoneNumber(data.data.attributes.recipientId);
      setOriginalInvestorPhoneNumberStatus(PageStatuses.succeed);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setOriginalInvestorPhoneNumberStatus(PageStatuses.failed);
        dispatch(
          notificationActionCreators.showNotification({
            type: NotificationTypes.error,
            message: i18n(
              'roboAdvice.client.readInvestorInformationErrorMessage'
            )
          })
        );
      }
    }
  };

  return readExistingOtpSession;
};
