import { createUsePortfolioChartStore } from '@quantfoliorepo/ui-components';
import { UseBoundStore } from 'zustand';

export const usePortfolioChartStore: UseBoundStore<any> =
  createUsePortfolioChartStore();

export const PortfolioType = {
  model: 'model',
  suggested: 'suggested',
  custom: 'custom'
} as const;
