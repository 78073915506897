import { Spacing } from '../../../shared/constants/spacing';
import { useErrorsStore } from '../services/errorsStore';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import { FontWeights, Typography } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  errorContainer: {
    display: 'flex',
    margin: `${Spacing.spacing00}px 0`
  },
  errorMessage: {
    padding: `${Spacing.spacing00}px`,
    borderRadius: '10px',
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,
    fontWeight: FontWeights.normal,
    background: theme.itemBackgroundColor_1,
    border: `1px solid ${theme.defaultInputStrokeColor}`,
    display: 'flex',
    justifyContent: 'space-between'
  },
  infoIcon: {
    fill: theme.errorNegativeColor,
    width: '24px',
    height: '24px',
    marginRight: '12px',
    verticalAlign: 'middle'
  }
}));

const InputErrorMessage = ({ message }) => {
  const classes = useStyles();
  const isErrorModeEnabled = useErrorsStore(state => state.isErrorModeEnabled);

  return isErrorModeEnabled ? (
    <div className={classes.errorContainer}>
      <div className={classes.errorMessage}>
        <div>
          <WarningIcon className={classes.infoIcon} />
          {message}
        </div>
      </div>
    </div>
  ) : null;
};

export default InputErrorMessage;
