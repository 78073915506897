import * as R from 'ramda';
import {
  takeEvery,
  fork,
  call,
  put,
  race,
  take,
  all,
  delay
} from 'redux-saga/effects';

import {
  types as notificationActionTypes,
  creators as notificationActionCreators
} from './actions.js';

function* autohideNotification(id, autohideMilliseconds) {
  const { hideNotification } = yield race({
    delayTimeout: delay(autohideMilliseconds),
    hideNotification: take(
      action =>
        (action.type === notificationActionTypes.HIDE_NOTIFICATION &&
          action.payload.id === id) ||
        (action.type === notificationActionTypes.HIDE_NOTIFICATIONS &&
          R.indexOf(id, action.payload.ids) !== -1)
    )
  });

  if (!R.isNil(hideNotification)) {
    return;
  }

  yield put(notificationActionCreators.hideNotification(id));
}

function* onNotificationShown(action) {
  const { id, isAutohideEnabled, autohideMilliseconds } =
    action.payload.notification;

  if (!isAutohideEnabled) {
    return;
  }

  yield fork(autohideNotification, id, autohideMilliseconds);
}

function* onNotificationsShown(action) {
  yield all(
    R.pipe(
      R.map(notification => {
        const { id, isAutohideEnabled, autohideMilliseconds } = notification;

        return isAutohideEnabled
          ? call(autohideNotification, id, autohideMilliseconds)
          : null;
      }),
      R.filter(effect => !R.isNil(effect))
    )(action.payload.notifications)
  );
}

export default function* () {
  yield takeEvery(
    notificationActionTypes.SHOW_NOTIFICATION,
    onNotificationShown
  );

  yield takeEvery(
    notificationActionTypes.SHOW_NOTIFICATIONS,
    onNotificationsShown
  );
}
