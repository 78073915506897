import { ConfirmationModule } from '../types';
import ConfirmationComponent from 'features/shared/components/modal/confirmation';

type ConfirmationProps = {
  module: ConfirmationModule;
};

const Confirmation = ({ module }: ConfirmationProps) => {
  const { useConfirmationStore } = module;
  const confirmationStore = useConfirmationStore();

  return (
    <ConfirmationComponent
      header={confirmationStore.header}
      isOpen={confirmationStore.isOpen}
      onConfirm={confirmationStore.confirm}
      onDecline={confirmationStore.decline}
      confirmButtonText={confirmationStore.confirmButtonText}
      declineButtonText={confirmationStore.declineButtonText}
    >
      {confirmationStore.message}
    </ConfirmationComponent>
  );
};

export default Confirmation;
