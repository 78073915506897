import { ProductAttributeType } from '../constants';
import { ProductAttributeGeneric } from 'defaults/shared';
import { filterNil } from 'features/shared/utils/filters';

export type ProductAttributesConfig = {
  productAttributeTxt1: ProductAttributeGeneric | null;
  productAttributeTxt2: ProductAttributeGeneric | null;
  productAttributeTxt3: ProductAttributeGeneric | null;
  productAttributeTxt4: ProductAttributeGeneric | null;
  productAttributeTxt5: ProductAttributeGeneric | null;
  productAttributeBinary1: ProductAttributeGeneric | null;
  productAttributeBinary2: ProductAttributeGeneric | null;
  productAttributeBinary3: ProductAttributeGeneric | null;
  productAttributeBinary4: ProductAttributeGeneric | null;
  productAttributeBinary5: ProductAttributeGeneric | null;
};

export type CustomAttributeFromConfig = ProductAttributeGeneric & {
  type: keyof typeof ProductAttributeType;
  name: string;
};

export const getCustomAttributes = (
  config: ProductAttributesConfig
): CustomAttributeFromConfig[] => {
  const {
    productAttributeTxt1,
    productAttributeTxt2,
    productAttributeTxt3,
    productAttributeTxt4,
    productAttributeTxt5,
    productAttributeBinary1,
    productAttributeBinary2,
    productAttributeBinary3,
    productAttributeBinary4,
    productAttributeBinary5
  } = config;

  const productAttributeBinaries = [
    productAttributeBinary1,
    productAttributeBinary2,
    productAttributeBinary3,
    productAttributeBinary4,
    productAttributeBinary5
  ]
    .map(
      (productAttribute, index) =>
        productAttribute && {
          name: `productAttributeBinary${index + 1}`,
          ...productAttribute
        }
    )
    .filter(filterNil);

  const productAttributeTxts = [
    productAttributeTxt1,
    productAttributeTxt2,
    productAttributeTxt3,
    productAttributeTxt4,
    productAttributeTxt5
  ]
    .map(
      (productAttribute, index) =>
        productAttribute && {
          name: `productAttributeTxt${index + 1}`,
          ...productAttribute
        }
    )
    .filter(filterNil);

  return [
    ...productAttributeTxts.map(attr => ({
      ...attr,
      type: ProductAttributeType.text
    })),
    ...productAttributeBinaries.map(attr => ({
      ...attr,
      type: ProductAttributeType.binary
    }))
  ];
};

export const areProductAttributesEnabled = (
  config: ProductAttributesConfig
) => {
  const {
    productAttributeTxt1,
    productAttributeTxt2,
    productAttributeTxt3,
    productAttributeTxt4,
    productAttributeTxt5,
    productAttributeBinary1,
    productAttributeBinary2,
    productAttributeBinary3,
    productAttributeBinary4,
    productAttributeBinary5
  } = config;

  return (
    !!productAttributeTxt1 ||
    !!productAttributeTxt2 ||
    !!productAttributeTxt3 ||
    !!productAttributeTxt4 ||
    !!productAttributeTxt5 ||
    !!productAttributeBinary1 ||
    !!productAttributeBinary2 ||
    !!productAttributeBinary3 ||
    !!productAttributeBinary4 ||
    !!productAttributeBinary5
  );
};
