import { isEmpty } from 'ramda';
import * as React from 'react';

import { form } from '../services/translationsForm';
import { useTranslationsStore } from '../services/translationsStore';
import useReadTranslations from './useReadTranslations';
import { translationsTable } from 'features/admin/appAdmin/advisorSolution/translationsTable';
import { useUnmountEffect } from 'features/shared/hooks/useUnmountEffect';

const useInitPage = async (language: string, isAdminModeActive: boolean) => {
  const readTranslations = useReadTranslations();
  const { useTableStore } = translationsTable;

  const initializeForm = () => {
    const { translations } = useTranslationsStore.getState();
    const regex = /\./g;
    const initialValues = translations.reduce((acc, current) => {
      return isAdminModeActive
        ? {
            ...acc,
            [`${current.name.replace(regex, '-')}`]: {
              description: current.description,
              default: current.default
            }
          }
        : {
            ...acc,
            [`${current.name.replace(regex, '-')}`]: {
              value: current.is_overruled ? current.value : ''
            }
          };
    }, {});

    if (!isEmpty(initialValues)) {
      form.initialize(initialValues);
    }
  };

  React.useEffect(() => {
    async function getTranslations() {
      await readTranslations(language);
      initializeForm();
      useTableStore.getState().changePageNumber(0);
    }
    getTranslations();
  }, [language]);

  React.useEffect(() => {
    initializeForm();
  }, [isAdminModeActive]);

  useUnmountEffect(() => {
    const translationsStoreState = useTranslationsStore.getState();
    const tableStoreState = useTableStore.getState();

    form.restartToInitialValues();
    translationsStoreState.reset();
    tableStoreState.reset();
  });
};

export default useInitPage;
