import axios, { AxiosResponse } from 'axios';
import { add, format } from 'date-fns';
import * as R from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHolisticViewStore } from '../../../services/holisticViewStore';
import { useCashflowChartStore } from 'features/roboAdvice/adviceSession/cashflowChart';
import { usePortfolioChartStore } from 'features/roboAdvice/adviceSession/portfolioChart';
import { getHorizonInYears } from 'features/roboAdvice/adviceSession/proposal/services/selectors';
import { TimeHorizonTypes } from 'features/roboAdvice/adviceSession/purposeAndRisk/constants';
import {
  readCalculatorCapitalNeed,
  readCalculatorForecast
} from 'features/roboAdvice/adviceSession/shared/api';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { WithdrawalPlans } from 'features/roboAdvice/adviceSession/shared/constants';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export const useReadCashflowData = () => {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<any>();
  const portfolioChartStore = usePortfolioChartStore();
  const dispatch = useDispatch();
  const customerConfig = useCustomerConfig();

  const readCashflowData = async () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;
    const cashflowChartStore = useCashflowChartStore.getState();
    cashflowChartStore.reset();

    try {
      useHolisticViewStore
        .getState()
        .setProposalSectionStatus('readCashflowData', PageStatuses.pending);

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const goals = useHolisticViewStore
        .getState()
        .availableClients.filter(client => client.isSelected)
        .map(client => client.goals || [])
        .flat();

      for (const goal of goals) {
        const expectedAnnualReturn = R.prop(
          'expectedAnnualReturn',
          portfolioChartStore.getChartData(
            goal.goalId,
            goal.data.isPortfolioCustom
          )
        );
        if (!expectedAnnualReturn) {
          return;
        }

        const startDate = format(add(new Date(), { days: 1 }), 'yyyy-MM-dd');
        const endDate = getEndDate(goal, customerConfig);

        let singleTransactions = [
          { amount: goal.data.firstDeposit || 0, date: startDate }
        ];
        let regularTransactions = [
          {
            amount: goal.data.monthlyDeposit || 0,
            start_date: startDate,
            end_date: endDate
          }
        ];

        if (goal.data.withdrawalPlan === WithdrawalPlans.oneTimeWithdrawal) {
          singleTransactions = [
            ...singleTransactions,
            {
              amount: -goal.data.capitalNeed!,
              date: `${
                new Date().getFullYear() +
                (customerConfig.timeHorizonConfig.type ===
                TimeHorizonTypes.radio
                  ? goal.data.timeHorizonToDisplay!
                  : goal.data.timeHorizon!)
              }-01-01`
            }
          ];
        }

        if (goal.data.withdrawalPlan === WithdrawalPlans.monthlyWithdrawal) {
          regularTransactions = [
            ...regularTransactions,
            {
              amount: -goal.data.monthlyWithdrawal!,
              start_date: `${goal.data.withdrawalStart}-01-01`,
              end_date: `${goal.data.withdrawalEnd}-01-01`
            }
          ];
        }

        const payload: CalculatorForecastPayload = {
          interest_rate: expectedAnnualReturn / 100,
          start_date: startDate,
          end_date: endDate,
          aggregation_period: 'year',
          transactions: {
            single: singleTransactions,
            regular: regularTransactions
          }
        };

        const forecastResponse: AxiosResponse<CashflowData> =
          await readCalculatorForecast(qAuthAccessToken, payload);

        const { capitalNeed, estimatedGoalAchievement } =
          await getCapitalNeedData(
            goal,
            payload,
            forecastResponse,
            qAuthAccessToken
          );

        const data = {
          goalId: goal.goalId,
          forecast: forecastResponse.data.forecast,
          goalName: goal.name,
          goalIcon: goal.icon,
          capitalNeed,
          estimatedGoalAchievement
        };

        cashflowChartStore.addGoalData(data);
      }

      useHolisticViewStore
        .getState()
        .setProposalSectionStatus('readCashflowData', PageStatuses.succeed);
    } catch (error) {
      if (!axios.isCancel(error)) {
        useHolisticViewStore
          .getState()
          .setProposalSectionStatus('readCashflowData', PageStatuses.failed);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.proposal.readDataErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readCashflowData;
};

const getCapitalNeedData = async (
  goal,
  payload,
  forecastResponse,
  qAuthAccessToken
) => {
  let capitalNeed;

  switch (goal.data.withdrawalPlan) {
    case WithdrawalPlans.monthlyWithdrawal:
      const capitalNeedResponse: AxiosResponse<CapitalNeed> =
        await readCalculatorCapitalNeed(qAuthAccessToken, payload);

      const forecastWithdrawalStart = forecastResponse.data.forecast.find(
        ({ date }) => +date.slice(0, 4) === goal.data.withdrawalStart! - 1
      );

      capitalNeed = capitalNeedResponse.data.capital_need;

      return {
        capitalNeed,
        estimatedGoalAchievement: forecastWithdrawalStart!.amount / capitalNeed
      };
    case WithdrawalPlans.oneTimeWithdrawal:
      capitalNeed = goal.data.capitalNeed;

      return {
        capitalNeed,
        estimatedGoalAchievement:
          forecastResponse.data.forecast[
            forecastResponse.data.forecast.length - 2
          ].amount / capitalNeed
      };
    case WithdrawalPlans.noPlannedWithdrawal:
      return {
        capitalNeed: 'N/A',
        estimatedGoalAchievement: 1
      };
    default:
      return {};
  }
};

const getEndDate = (goal, customerConfig) => {
  if (goal.data.withdrawalPlan === WithdrawalPlans.monthlyWithdrawal) {
    return `${goal.data.withdrawalEnd}-01-01`;
  } else if (
    goal.data.withdrawalPlan === WithdrawalPlans.oneTimeWithdrawal &&
    customerConfig.timeHorizonConfig.type === TimeHorizonTypes.radio
  ) {
    return `${
      new Date().getFullYear() + goal.data.timeHorizonToDisplay!
    }-01-01`;
  }

  return format(
    add(new Date(), {
      years: getHorizonInYears({
        customerConfig,
        timeHorizon: goal.data.timeHorizon
      })
    }),
    'yyyy-MM-dd'
  );
};

type CalculatorForecastPayload = {
  interest_rate: number;
  start_date: string;
  end_date: string;
  aggregation_period: 'month' | 'year';
  transactions: {
    single: {
      amount: number;
      date: string;
    }[];
    regular: {
      amount: number;
      start_date: string;
      end_date: string;
    }[];
  };
};

type CashflowData = {
  forecast: {
    amount: number;
    date: string;
    deposits: number;
    withdrawals: number;
  }[];
};

type CapitalNeed = {
  capital_need: number;
  forecast: {
    amount: number;
    date: string;
    deposits: number;
    withdrawals: number;
  }[];
};
