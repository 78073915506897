import classNames from 'classnames';

import { formatNumber } from 'features/shared/utils/number';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  amountForAdviceRow: {
    backgroundColor: theme.inputFillFocusColor
  },
  cell: {
    padding: '17.5px 16px',
    lineHeight: '150%'
  },
  right: {
    textAlign: 'right'
  },
  originalAmount: {
    color: theme.disabledTextColor,
    textAlign: 'right',
    textDecoration: 'line-through'
  },
  toAdvisoryRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  currentSum: {
    marginRight: 'calc(38px + 2.4rem)',
    paddingLeft: '2.4rem',
    width: 143
  }
}));

const AmountForAdviceTableLastRow = ({
  cultureCode,
  originalSum,
  currentSum,
  financialSituationValues
}) => {
  const classes = useStyles();
  const i18n = useI18n();

  return (
    <tr className={classes.amountForAdviceRow}>
      <td className={classes.cell}>
        {i18n('roboAdvice.financialSituation.amountForAdvice')}
      </td>

      <td className={classes.cell} />

      <td className={classNames(classes.cell, classes.originalAmount)}>
        {formatNumber(
          cultureCode,
          currentSum === originalSum ? null : originalSum,
          0,
          0
        )}
      </td>

      {financialSituationValues.amountForAdviceSwitch ? (
        <>
          <td className={classNames(classes.cell, classes.right)}>
            {formatNumber(cultureCode, currentSum, 0, 0)}
          </td>
          <td className={classes.cell} />
        </>
      ) : (
        <td className={classNames(classes.cell, classes.right)}>
          <div className={classes.toAdvisoryRow}>
            <div className={classes.currentSum}>
              {formatNumber(cultureCode, currentSum, 0, 0)}
            </div>
          </div>
        </td>
      )}
    </tr>
  );
};

export default AmountForAdviceTableLastRow;
