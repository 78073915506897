import { Fragment } from 'react';
import { Form } from 'react-final-form';

import { form } from '../../form/services/form';
import RiskScore from '../../riskScore/components/index.js';
import AdviceSessionBreadcrumbs from './adviceSessionBreadcrumbs';
import Pages from './pages.js';
import Tabs from './tabs.js';
import { useInitPage } from './useInitPage';
import { usePatchAdviceSessionListener } from './usePatchAdviceSessionListener';
import { Spacing } from 'features/shared/constants/spacing';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  header: {
    paddingTop: Spacing.spacing02,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  tabs: {
    paddingBottom: Spacing.spacing04
  }
});

const Main = () => {
  const classes = useStyles();
  const customerConfig = useCustomerConfig();
  const isRiskScorePanelHidden =
    customerConfig.roboAdviceForm.riskScorePanel.isRiskScorePanelHidden;

  useInitPage();

  usePatchAdviceSessionListener();

  return (
    <div data-testid={'advice-session-page'}>
      <Form form={form}>
        {() => (
          <Fragment>
            <div className={classes.header}>
              <AdviceSessionBreadcrumbs />
              {!isRiskScorePanelHidden && <RiskScore />}
            </div>
            <div className={classes.tabs}>
              <Tabs />
            </div>
            <div>
              <Pages />
            </div>
          </Fragment>
        )}
      </Form>
    </div>
  );
};

export default Main;
