import createTableModule from 'features/sharedModules/table/services/main';

export const clientsTable = createTableModule('trashCanClientsTable');

export const OrderKeys = {
  type: 'type',
  name: 'name',
  advisorName: 'advisorName',
  lastActivityDate: 'lastActivityDate',
  softDeleteDate: 'softDeleteDate'
} as const;
