import classNames from 'classnames';
import { isEmpty, isNil } from 'ramda';
import { useField } from 'react-final-form';

import { useAutomaticClientClassificationStore } from '../../services/automaticClientClassificationStore';
import { InvestorTypes } from './constants';
import { ReactComponent as EditPencilIcon } from 'assets/editPencil.svg';
import { useClientForm } from 'features/roboAdvice/client/main/services/clientForm';
import AdvisorNotes from 'features/shared/components/advisorNotes';
import ErrorMessage from 'features/shared/components/errorMessage';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const useStyles = createUseStyles(theme => ({
  container: {
    marginTop: Spacing.spacing03
  },
  header: {
    fontWeight: FontWeights.medium,
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    marginBottom: Spacing.spacing00,
    display: 'flex',
    alignItems: 'center'
  },
  description: {
    color: theme.secondaryColor,
    fontSize: Typography.body1.size,
    marginBottom: Spacing.spacing02
  },
  automaticClassificationContainer: {
    padding: '20px 16px',
    width: 'fit-content',
    border: `1px solid ${theme.secondaryColor}`,
    borderRadius: '8px',
    color: theme.secondaryColor,
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight
  },
  automaticClassificationContainerSelected: {
    backgroundColor: theme.inputFillFocusColor,
    border: `1px solid ${theme.accentColor}`,
    borderRadius: '8px',
    color: theme.primaryColor
  },
  automaticClassificationTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  editPencilIcon: {
    marginLeft: Spacing.spacing02,
    cursor: 'pointer',

    '& path': {
      fill: theme.primaryColor
    }
  },
  errorContainer: {
    marginTop: `${Spacing.spacing01}px`
  }
}));

const ClassificationResult = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const { setIsChecklistModalOpen, setSelectedInvestorTypeItems } =
    useAutomaticClientClassificationStore();
  const {
    input: { value: investorType },
    meta: { error: investorTypeError, touched: investorTypeTouched }
  }: {
    input: {
      value: keyof typeof InvestorTypes | null;
    };
    meta: { error?: string; touched?: boolean };
  } = useField('kyc.automaticClientClassification.investorType');
  const { values: clientFormValues } = useClientForm();
  const {
    roboAdvice: {
      clientInformation: {
        automaticClientClassification: { checklistModal } = {}
      }
    }
  } = useCustomerConfig();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          {i18n(
            'roboAdvice.kyc.automaticClientClassification.automaticClassification.header'
          )}
        </div>

        <div className={classes.description}>
          {i18n(
            'roboAdvice.kyc.automaticClientClassification.automaticClassification.description'
          )}
        </div>

        <div className={classes.automaticClassificationTitle}>
          <div
            className={classNames(classes.automaticClassificationContainer, {
              [classes.automaticClassificationContainerSelected]: investorType
            })}
          >
            {!investorType
              ? i18n(
                  'roboAdvice.kyc.automaticClientClassification.selectClientTypeAbove'
                )
              : i18n(
                  `roboAdvice.kyc.automaticClientClassification.${investorType}`
                )}
          </div>
          {!isNil(
            clientFormValues?.kyc?.automaticClientClassification?.investorType
          ) &&
            !isEmpty(
              clientFormValues?.kyc?.automaticClientClassification?.investorType
            ) && (
              <EditPencilIcon
                className={classes.editPencilIcon}
                onClick={() => {
                  if (investorType === InvestorTypes.nonProfessionalInvestor) {
                    setSelectedInvestorTypeItems(
                      checklistModal?.professionalItems?.map(i => i18n(i)) || []
                    );
                  } else {
                    setSelectedInvestorTypeItems(
                      checklistModal?.nonProfessionalItems?.map(i => i18n(i)) ||
                        []
                    );
                  }
                  setIsChecklistModalOpen(true);
                }}
              />
            )}
        </div>
        {investorTypeTouched &&
          !isNil(investorTypeError) &&
          !isEmpty(investorTypeError.trim()) && (
            <div className={classes.errorContainer}>
              <ErrorMessage>* {i18n(investorTypeError)}</ErrorMessage>
            </div>
          )}
      </div>

      {clientFormValues?.kyc?.automaticClientClassification
        ?.isDecisionOverwritten &&
        checklistModal?.isCommentEnabled && (
          <div className={classes.container}>
            <AdvisorNotes
              name="kyc.automaticClientClassification.reclassificationRequestDocumentation"
              header={i18n(
                'roboAdvice.kyc.automaticClientClassification.reclassificationRequestDocumentation.header'
              )}
              subheader={
                checklistModal.isCommentRequired &&
                i18n(
                  'roboAdvice.kyc.automaticClientClassification.reclassificationRequestDocumentation.description'
                )
              }
              required={checklistModal?.isCommentRequired}
            />
          </div>
        )}
    </>
  );
};

export default ClassificationResult;
