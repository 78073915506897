import { CancelToken } from 'axios';

import { stateApi } from 'features/shared/utils/axios';

export type AdviceSessionPutResponse = {
  advice_type: string | null;
  channel: string;
  company_id: string | null;
  created_at: string;
  end_user_id: string | null;
  last_activity_date: string;
  name: string;
  session_id: string;
  status: string;
};

export type AdviceSessionGetResponse = AdviceSessionPutResponse & {
  advisor_name: string;
  client_name: string;
  deleted_at: string;
};

export const getDeletedAdviceSessions = (
  accessToken: string,
  cancelToken: CancelToken
) => {
  return stateApi.get<{ data: AdviceSessionGetResponse[] }>(
    `/deleted/sessions`,
    {
      cancelToken,
      headers: { Authorization: accessToken }
    }
  );
};

export const deleteAdviceSessions = (
  accessToken: string,
  sessionIds: string[]
) => {
  return stateApi.delete<{ deleted: string[]; failed: string[] }>(
    '/deleted/sessions/:bulk_delete',
    {
      data: { session_ids: sessionIds },
      headers: { Authorization: accessToken }
    }
  );
};

export const restoreAdviceSessions = (
  accessToken: string,
  sessionIds: string[]
) => {
  return stateApi.put<{ data: AdviceSessionPutResponse[] }>(
    '/deleted/session/:bulk_restore',
    { session_ids: sessionIds },
    {
      headers: { Authorization: accessToken }
    }
  );
};
