import * as R from 'ramda';

const DefaultCultureCode = 'no-no';

export const roundNumber = (value: number, decimalPlaces: number) => {
  const precision = Math.pow(10, decimalPlaces);

  return Math.round((value + Number.EPSILON) * precision) / precision;
};

export const areNumbersEqualWithPrecisionError = (
  a: number,
  b: number
): boolean => {
  return Math.abs(a - b) < Number.EPSILON * 1000;
};

export const formatNumber = (
  cultureCode: string,
  number: number | null | undefined,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
) => {
  if (R.isNil(number)) {
    return null;
  }

  if (R.isNil(cultureCode)) {
    cultureCode = DefaultCultureCode;
  }

  return new Intl.NumberFormat(cultureCode, {
    minimumFractionDigits,
    maximumFractionDigits
  }).format(number);
};

export const getNumberInputFormat = (cultureCode: string) => {
  if (R.isNil(cultureCode)) {
    cultureCode = DefaultCultureCode;
  }

  return {
    thousandSeparator: getThousandSeparator(cultureCode),
    decimalSeparator: getDecimalSeparator(cultureCode)
  };
};

function getThousandSeparator(cultureCode: string) {
  return Intl.NumberFormat(cultureCode).format(1111).replace(/1/g, '');
}

function getDecimalSeparator(cultureCode: string) {
  return Intl.NumberFormat(cultureCode).format(1.1).replace(/1/g, '');
}

export const getNumberFromLocaleStringNumber = (
  strNumber: string | number | undefined | null,
  cultureCode: string
) => {
  const { decimalSeparator, thousandSeparator } =
    getNumberInputFormat(cultureCode);

  if (R.isNil(strNumber)) {
    return null;
  }

  if (typeof strNumber === 'number') {
    return strNumber;
  }

  return Number(
    strNumber
      .replace(/\s/g, '')
      .replace(decimalSeparator, '.')
      .replace(thousandSeparator, '')
  );
};
