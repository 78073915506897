import classNames from 'classnames';

import SimpleTable from '../../components/table/simpleTable';
import { ReactComponent as CheckIcon } from 'assets/checkmark2.svg';
import { ReactComponent as CrossIcon } from 'assets/x-icon.svg';
import { FundNameForPresentation } from 'features/roboAdvice/adviceSession/shared/constants';
import { FundAnalysis } from 'features/roboAdvice/adviceSession/sustainability';
import Tooltip from 'features/shared/components/tooltip';
import { FontWeights } from 'features/shared/constants/typography';
import { formatNumber } from 'features/shared/utils/number';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  portfolioRow: {
    backgroundColor: `${theme.inputFillFocusColor} !important`,
    fontWeight: FontWeights.medium,
    lineHeight: '19px'
  },
  center: {
    textAlign: 'center',
    width: '33.33%'
  },
  right: {
    textAlign: 'right',
    width: '33.33%'
  },
  headerCell: {
    padding: '20px 16px'
  },
  cell: {
    padding: '17.5px 16px'
  },
  checkIcon: {
    fill: theme.successColor
  },
  crossIcon: {
    fill: theme.errorNegativeColor
  },
  tooltipRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 5
  },
  tooltipTitle: {
    textAlign: 'left'
  },
  tooltipScore: {
    fontWeight: FontWeights.bold,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

type Props = {
  alignmentValues: {
    name: string;
    title: string;
    preferenceScore: number | undefined;
  }[];
  cultureCode: string;
  fundAnalysisData: FundAnalysis[];
  portfolioScore: number;
  yourScore: number;
};

const WeightedTable = ({
  alignmentValues,
  cultureCode,
  fundAnalysisData,
  portfolioScore,
  yourScore
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const theme = useTheme();
  const {
    tenantSettings: { fundNameForPresentation }
  } = useCustomerConfig();

  return (
    <SimpleTable
      headers={({ headerCellClassName }) => (
        <>
          <th className={classNames(headerCellClassName, classes.headerCell)}>
            {i18n('roboAdvice.proposal.sustainability.weightedTable.fund')}
          </th>

          <th
            className={classNames(
              headerCellClassName,
              classes.headerCell,
              classes.right
            )}
          >
            {i18n('shared.score')}
          </th>

          <th
            className={classNames(
              headerCellClassName,
              classes.headerCell,
              classes.center
            )}
          >
            {i18n('roboAdvice.proposal.sustainability.weightedTable.alignment')}
          </th>
        </>
      )}
      body={({ rowClassName, cellClassName }) => (
        <>
          {fundAnalysisData.map(
            ({
              fundStandardName,
              name,
              fundWeightedSustainabilityScore,
              fundAlignmentStatus,
              isin,
              alignmentCriteria
            }) => (
              <tr key={isin} className={rowClassName}>
                <td className={classNames(cellClassName, classes.cell)}>
                  {fundNameForPresentation ===
                    FundNameForPresentation.fundStandardName && fundStandardName
                    ? fundStandardName
                    : name}
                </td>
                <td className={classNames(cellClassName, classes.cell)}>
                  <Tooltip
                    content={
                      <div>
                        {alignmentValues.map(({ title, name }) => (
                          <div className={classes.tooltipRow} key={name}>
                            <div className={classes.tooltipTitle}>{title}</div>
                            <div className={classes.tooltipScore}>
                              {alignmentCriteria[name]}
                            </div>
                          </div>
                        ))}
                      </div>
                    }
                    infoTooltip
                    trigger={
                      <span
                        style={{
                          color: fundAlignmentStatus
                            ? theme.successColor
                            : theme.errorNegativeColor
                        }}
                      >
                        {formatNumber(
                          cultureCode,
                          fundWeightedSustainabilityScore,
                          1,
                          1
                        )}
                      </span>
                    }
                    triggerButtonStyles={{ float: 'right' }}
                  />
                </td>
                <td
                  className={classNames(
                    cellClassName,
                    classes.cell,
                    classes.center
                  )}
                >
                  {fundAlignmentStatus ? (
                    <CheckIcon className={classes.checkIcon} />
                  ) : (
                    <CrossIcon className={classes.crossIcon} />
                  )}
                </td>
              </tr>
            )
          )}
          <tr className={classNames(rowClassName, classes.portfolioRow)}>
            <td className={classNames(cellClassName, classes.cell)}>
              {i18n(
                'roboAdvice.proposal.sustainability.weightedTable.portfolioScore'
              )}
            </td>
            <td
              className={classNames(cellClassName, classes.cell, classes.right)}
              style={{
                color:
                  portfolioScore >= yourScore
                    ? theme.successColor
                    : theme.errorNegativeColor
              }}
            >
              {formatNumber(cultureCode, portfolioScore, 1, 1)}
            </td>
            <td
              className={classNames(
                cellClassName,
                classes.cell,
                classes.center
              )}
            >
              {portfolioScore >= yourScore ? (
                <CheckIcon className={classes.checkIcon} />
              ) : (
                <CrossIcon className={classes.crossIcon} />
              )}
            </td>
          </tr>
        </>
      )}
    />
  );
};

export default WeightedTable;
