import { Colors } from 'features/shared/constants/colors.js';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const useIndexPageStyles = createUseStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative'
  },
  previewPage: {
    border: `2px solid ${theme.accentColor}`,
    borderRadius: '12px',
    minHeight: '400px',
    marginBottom: '20px',
    padding: '5px',
    pointerEvents: 'none'
  }
}));

const borderRadius = {
  [BorderRadiusTypes.rounded]: '15px',
  [BorderRadiusTypes.sharp]: '5px'
};

export const useProposalPreviewPageStyles = createUseStyles(theme => ({
  hideArrow: {},
  header: {
    padding: `${Spacing.spacing01}px 0`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  selectClientSection: {
    display: 'flex',
    alignItems: 'baseline'
  },
  currentClientInfo: {
    fontSize: Typography.heading2.size,
    lineHeight: Typography.heading2.lineHeight,
    marginRight: '24px',
    color: theme.secondaryColor
  },
  clientsListLink: {
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight
  },
  selectAdviceSessionSection: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  adviceSessionDropdown: {
    width: '340px'
  },
  adviceSessionDropdownLabel: {
    width: 'auto',
    color: theme.secondaryColor,
    fontSize: Typography.heading2.size,
    lineHeight: Typography.heading2.lineHeight
  },
  containerRiskScore: {
    backgroundColor: theme.boxBackgroundColor_2,
    borderRadius: borderRadius[theme.borderRadiusType],
    display: 'flex',
    padding: Spacing.spacing00,
    width: '312px'
  },
  sectionRiskScore: {
    display: 'flex',
    flexDirection: 'column'
  },
  scoreSectionRiskScore: {
    width: '33.3%'
  },
  headerRiskScore: {
    fontSize: Typography.body3.size,
    lineHeight: Typography.body3.lineHeight,
    color: theme.secondaryColor,
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  valueRiskScore: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    fontWeight: FontWeights.medium,
    textAlign: 'center'
  },
  section: {
    '& + &': {
      marginTop: '20px'
    }
  },
  label: {
    color: Colors.gray50
  },
  informationColumn: {
    flex: '0 0 auto',
    marginRight: Spacing.spacing04
  },
  chartsColumn: {
    flex: '1 1 auto',
    overflow: 'hidden',
    maxWidth: '900px'
  },
  row: {
    display: 'flex'
  },
  tabs: {
    padding: `0 0 ${Spacing.spacing02}px 0`
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: Spacing.spacing04
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& + &': {
      marginLeft: '2.4rem'
    }
  }
}));

export const useFinancialSituationPageStyles = createUseStyles(theme => ({
  header: {
    padding: `${Spacing.spacing01} 0`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  selectClientSection: {
    display: 'flex',
    alignItems: 'baseline'
  },
  currentClientInfo: {
    fontSize: Typography.heading2.size,
    lineHeight: Typography.heading2.lineHeight,
    marginRight: '24px',
    color: theme.secondaryColor
  },
  clientsListLink: {
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight
  },
  selectAdviceSessionSection: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  adviceSessionDropdown: {
    width: '340px'
  },
  adviceSessionDropdownLabel: {
    width: 'auto',
    color: theme.secondaryColor,
    fontSize: Typography.heading2.size,
    lineHeight: Typography.heading2.lineHeight
  },
  containerRiskScore: {
    backgroundColor: theme.boxBackgroundColor_2,
    borderRadius: borderRadius[theme.borderRadiusType],
    display: 'flex',
    padding: Spacing.spacing00,
    width: '312px'
  },
  sectionRiskScore: {
    display: 'flex',
    flexDirection: 'column'
  },
  scoreSectionRiskScore: {
    width: '33.3%'
  },
  headerRiskScore: {
    fontSize: Typography.body3.size,
    lineHeight: Typography.body3.lineHeight,
    color: theme.secondaryColor,
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  valueRiskScore: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    fontWeight: FontWeights.medium,
    textAlign: 'center'
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: Spacing.spacing02,
    marginBottom: Spacing.spacing04
  },
  tabs: {
    padding: `0 0 ${Spacing.spacing02}px 0`
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: Spacing.spacing04
  },
  actionButton: {
    '& + &': {
      marginLeft: '2.4rem'
    }
  }
}));
