import { isNil } from 'ramda';

import { ErrorMessage } from './types';

export const getNumberOfErrors = (
  errorsObj: Record<string, ErrorMessage[]>
): number => {
  return !isNil(errorsObj)
    ? Object.values(errorsObj).reduce((prev, curr) => prev + curr.length, 0)
    : 0;
};

export const getErrorMessageWithReplacedValues = (
  error:
    | string
    | [
        string,
        {
          [textToReplace: string]: string;
        }
      ],
  i18n: (value: string) => string
): string => {
  if (Array.isArray(error)) {
    const [errorName, textsToReplace] = error;
    const translatedError = i18n(errorName);

    return Object.entries(textsToReplace).reduce((prev, [key, value]) => {
      return prev.replace(key, value);
    }, translatedError);
  } else {
    return i18n(error);
  }
};
