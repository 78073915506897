export const getCurrentDateDay = (currentDate: Date) => {
  const currentDay = currentDate.getUTCDate();
  const currentDayStr = currentDay.toString();
  return currentDay < 10 ? `0${currentDayStr}` : currentDayStr;
};

export const getCurrentDateMonth = (currentDate: Date) => {
  const currentMonth = currentDate.getUTCMonth() + 1;
  const currentMonthStr = currentMonth.toString();
  return currentMonth < 10 ? `0${currentMonthStr}` : currentMonthStr;
};

export const getCurrentDateYear = (currentDate: Date) => {
  const currentYear = currentDate.getUTCFullYear();
  const currentYearStr = currentYear.toString();
  return currentYearStr.substring(currentYearStr.length - 2);
};

export const getCurrentDateFullYear = (currentDate: Date) => {
  const currentYear = currentDate.getUTCFullYear();
  const currentYearStr = currentYear.toString();
  return currentYearStr;
};
