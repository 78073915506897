import React from 'react';

import { useCheckIsOrgNumberValid } from '../useCheckIsOrgNumberValid';
import { useInitTableUBOInfo } from './useInitTableUBOInfo';
import { ReactComponent as RefreshIcon } from 'assets/refresh.svg';
import { Spacing } from 'features/shared/constants/spacing';
import { defaultConfirmation } from 'features/sharedInstances/defaultConfirmation';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  refreshIcon: {
    marginRight: '4px',
    cursor: 'pointer',

    '& path': {
      fill: theme.accentColor
    }
  }
}));

const UBOInfoRefresh = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const initTableUBOInfo = useInitTableUBOInfo();
  const defaultConfirmationStoreState =
    defaultConfirmation.useConfirmationStore.getState();
  const checkIsOrgNumberValid = useCheckIsOrgNumberValid();

  return (
    <RefreshIcon
      className={classes.refreshIcon}
      onClick={() => {
        defaultConfirmationStoreState.open(
          i18n('roboAdvice.kyc.enin.dataOverrideMessage'),
          () => {
            checkIsOrgNumberValid() && initTableUBOInfo(true);
          }
        );
      }}
    />
  );
};

export default UBOInfoRefresh;
