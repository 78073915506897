import * as R from 'ramda';

import { riskScoreToNumber } from 'features/roboAdvice/adviceSession/riskScore/services/shared';

const mapIndexed = R.addIndex(R.map);

const keyToNumber = riskClass =>
  R.isNil(riskClass) ? null : Number(riskClass.replace('r', ''));

const mapToTransposedMatrix = ({ data, rowKey, columnKey }) => {
  const matrix = [];
  const rows = data;
  const rowKeys = R.keys(rows);

  for (let i = 0; i < R.length(rowKeys); i++) {
    const originalRowKey = rowKeys[i];
    const originalRowNumber = keyToNumber(originalRowKey);
    const cells = rows[originalRowKey];

    for (let j = 0; j < R.length(cells); j++) {
      const score = cells[j];

      matrix.push({
        [rowKey]: j + 1,
        [columnKey]: originalRowNumber,
        score
      });
    }
  }

  return R.pipe(
    R.groupBy(R.pipe(R.prop(rowKey), R.toString)),
    R.toPairs,
    R.map(p => ({
      [rowKey]: Number(p[0]),
      items: R.pipe(
        R.map(R.dissoc(rowKey)),
        R.sort(R.ascend(R.prop(columnKey)))
      )(p[1])
    })),
    R.sort(R.ascend(R.prop(rowKey)))
  )(matrix);
};

const mapToDirectMatrix = ({
  data,
  rowKey,
  columnKey,
  rowsSortDirection = R.ascend,
  columnsSortDirection = R.ascend
} = {}) => {
  return R.pipe(
    R.toPairs,
    R.map(p => ({
      [rowKey]: keyToNumber(p[0]),
      items: R.pipe(
        mapIndexed((score, i) => ({
          [columnKey]: i + 1,
          score
        })),
        R.sort(columnsSortDirection(R.prop(columnKey)))
      )(p[1])
    })),
    R.sort(rowsSortDirection(R.prop(rowKey)))
  )(data);
};

const mapPortfolioItemToRiskClass = (riskClass, portfolioItem) => ({
  riskClass: riskScoreToNumber(riskClass),
  weight: Number(portfolioItem.Weight)
});

const transformMainAssetClass = mainAssetClass =>
  R.includes(mainAssetClass, ['Money Market', 'Fixed Income'])
    ? 'Bonds'
    : mainAssetClass;

const transformSubAssetClass = subAssetClass =>
  R.startsWith('Local/', subAssetClass)
    ? subAssetClass.replace('Local/', '')
    : subAssetClass;

export const mapToRiskScoreSettings = roboPortfolioData => {
  const defaultRiskClassesArray = Array.from(
    Array(roboPortfolioData.length)
  ).map((_, index) => ({ riskClass: index + 1, weight: 0 }));

  const assetClasses = roboPortfolioData
    .map(({ riskClass, Portfolio }) => {
      return R.map(portfolioItem => {
        const subAssetClass = transformSubAssetClass(
          portfolioItem.AssetClass.SubAssetClass
        );
        const mainAssetClass = transformMainAssetClass(
          portfolioItem.AssetClass.MainAssetClass
        );

        return {
          id: subAssetClass,
          category: portfolioItem.AssetClass.CategoryId,
          title: subAssetClass,
          assetClassGroupId: mainAssetClass,
          riskClasses: [mapPortfolioItemToRiskClass(riskClass, portfolioItem)],
          order: +portfolioItem?.AssetClass?.CategoryId?.slice(1)
        };
      }, Portfolio);
    })
    .flat(1)
    .reduce((acc, element) => {
      const index = R.findIndex(R.propEq('id', element.id))(acc);
      return index === -1
        ? [...acc, element]
        : acc.map(accItem => {
            return accItem.id === element.id
              ? {
                  ...accItem,
                  riskClasses: R.find(
                    R.propEq('riskClass', element.riskClasses[0].riskClass)
                  )(accItem.riskClasses)
                    ? accItem.riskClasses.map(i => {
                        return i.riskClass === element.riskClasses[0].riskClass
                          ? {
                              riskClass: i.riskClass,
                              weight: element.riskClasses[0].weight + i.weight
                            }
                          : i;
                      })
                    : [...accItem.riskClasses, ...element.riskClasses]
                }
              : accItem;
          });
    }, [])
    .map(item => {
      const riskClasses = defaultRiskClassesArray.map(defaultItem => {
        const index = R.findIndex(R.propEq('riskClass', defaultItem.riskClass))(
          item.riskClasses
        );
        return index === -1 ? defaultItem : item.riskClasses[index];
      });
      return { ...item, riskClasses: riskClasses };
    })
    .sort((a, b) => a.order - b.order);

  const assetClassGroups = R.pipe(
    R.map(({ assetClassGroupId }) => ({
      id: assetClassGroupId,
      title: assetClassGroupId
    })),
    R.uniqBy(R.prop('id'))
  )(assetClasses);

  return {
    assetClasses,
    assetClassGroups
  };
};

export const mapRiskScoreConfigServerDataToClient = (
  riskConfigData,
  roboPortfolioData
) => {
  const risk_horizon = R.prop('risk_horizon', riskConfigData);
  const risk_adj1 = R.prop('risk_adj1', riskConfigData);
  const risk_adj2 = R.prop('risk_adj2', riskConfigData);
  const experience = R.prop('experience', riskConfigData);

  return {
    purposeAndRiskScoreSettings: {
      riskHorizon:
        !R.isNil(risk_horizon) &&
        Object.values(risk_horizon).some(value => (value?.length ?? 0) > 0)
          ? mapToTransposedMatrix({
              data: risk_horizon,
              rowKey: 'timeHorizonClass',
              columnKey: 'riskClass'
            })
          : null
    },
    knowledgeAndExperienceScoreSettings: {
      dropInStd:
        !R.isNil(risk_adj1) &&
        Object.values(risk_adj1).some(value => (value?.length ?? 0) > 0)
          ? mapToDirectMatrix({
              data: risk_adj1,
              rowKey: 'dropClass',
              columnKey: 'riskClass'
            })
          : null,
      dropInPercent:
        !R.isNil(risk_adj2) &&
        Object.values(risk_adj2).some(value => (value?.length ?? 0) > 0)
          ? mapToDirectMatrix({
              data: risk_adj2,
              rowKey: 'dropClass',
              columnKey: 'riskClass'
            })
          : null,
      experience:
        !R.isNil(experience) &&
        Object.values(experience).some(value => (value?.length ?? 0) > 0)
          ? mapToDirectMatrix({
              data: experience,
              rowKey: 'experienceClass',
              columnKey: 'educationClass',
              rowsSortDirection: R.descend,
              columnsSortDirection: R.descend
            })
          : null
    },
    riskScoreSettings: !R.isNil(roboPortfolioData)
      ? mapToRiskScoreSettings(roboPortfolioData)
      : null
  };
};
