import debounce from 'lodash.debounce';
import * as R from 'ramda';
import React from 'react';
import { Form, FormSpy } from 'react-final-form';
import { Prompt } from 'react-router';

import { form } from '../services/translationsForm';
import { useTranslationsStore } from '../services/translationsStore';
import { usePageStyles } from './style';
import Table from './table';
import useInitPage from './useInitPage';
import { useSaveAdminTranslations } from './useSaveAdminTranslations';
import useSaveTranslations from './useSaveTranslations';
import useSearchTranslations from './useSearchTranslations';
import Button from 'features/shared/components/button';
import SearchInput from 'features/shared/components/textInput/search.js';
import { defaultConfirmation } from 'features/sharedInstances/defaultConfirmation';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { Dropdown } from 'features/sharedModules/finalForm/components/dropdown.js';
import { Switch } from 'features/sharedModules/finalForm/components/switch';
import { useSessionStore } from 'features/sharedModules/session/services/sessionStore';

const TextView = () => {
  const i18n = useI18n();
  const classes = usePageStyles();
  const { supportedLanguages, languageDescriptions } = useCustomerConfig();

  const languageOptions = React.useMemo(() => {
    return supportedLanguages.options.map(el => ({
      key: el,
      title: i18n(languageDescriptions[el])
    }));
  }, [supportedLanguages]);

  const [language, setLanguage] = React.useState(languageOptions[0].key);
  const saveTranslations = useSaveTranslations();
  const saveAdminTranslations = useSaveAdminTranslations();
  const { isSaveTranslationsPending, isAdminModeActive, setIsAdminModeActive } =
    useTranslationsStore();
  const searchTranslations = useSearchTranslations();
  const sessionStore = useSessionStore();
  const { open: openConfirmation } =
    defaultConfirmation.useConfirmationStore.getState();

  useInitPage(language, isAdminModeActive);

  const updateTranslations = () => {
    isAdminModeActive
      ? saveAdminTranslations(language)
      : saveTranslations(language);
  };

  const debouncedChangeHandler = debounce(
    query => searchTranslations(query),
    300
  );

  return (
    <Form form={form}>
      {() => (
        <FormSpy subscription={{ pristine: true, errors: true }}>
          {props => (
            <>
              <div className={classes.header}>
                <SearchInput
                  className={classes.searchInput}
                  containerClassName={classes.searchInputContainer}
                  placeholder={i18n(
                    'admin.appAdmin.advisorSolution.text.searchInput.placeholder'
                  )}
                  onChange={event => debouncedChangeHandler(event.target.value)}
                />

                {sessionStore.permissions.includes(
                  'qsa:translations:admin'
                ) && (
                  <Switch
                    label={i18n(
                      'admin.appAdmin.advisorSolution.text.switchLabel'
                    )}
                    labelClassName={classes.switchLabel}
                    sectionClassName={classes.switchSection}
                    value={isAdminModeActive}
                    onChange={val =>
                      props.pristine
                        ? setIsAdminModeActive(val)
                        : openConfirmation(
                            <>
                              <div>
                                {i18n(
                                  'admin.appAdmin.advisorSolution.text.confirmationMessage1'
                                )}
                              </div>
                              <div>
                                {i18n(
                                  'admin.appAdmin.advisorSolution.text.confirmationMessage2'
                                )}
                              </div>
                            </>,
                            () => setIsAdminModeActive(val)
                          )
                    }
                  />
                )}

                <Dropdown
                  className={classes.dropdown}
                  value={language}
                  onChange={lang =>
                    props.pristine
                      ? setLanguage(lang)
                      : openConfirmation(
                          <>
                            <div>
                              {i18n(
                                'admin.appAdmin.advisorSolution.text.confirmationMessage1'
                              )}
                            </div>
                            <div>
                              {i18n(
                                'admin.appAdmin.advisorSolution.text.confirmationMessage2'
                              )}
                            </div>
                          </>,
                          () => setLanguage(lang)
                        )
                  }
                  options={languageOptions}
                />
              </div>

              <div className={classes.table}>
                <Table isAdminModeActive={isAdminModeActive} />
              </div>

              <div className={classes.saveButtonContainer}>
                <Button
                  disabled={
                    props.pristine ||
                    isSaveTranslationsPending ||
                    !R.isEmpty(props.errors)
                  }
                  onClick={updateTranslations}
                >
                  {i18n('admin.appAdmin.advisorSolution.text.save')}
                </Button>
              </div>

              <Prompt when={!props.pristine} message={'confirm required'} />
            </>
          )}
        </FormSpy>
      )}
    </Form>
  );
};

export default TextView;
