import classNames from 'classnames';
import { useState } from 'react';

import { useHistoricalReturnChartStore } from './useReadHistoricalReturnData';
import Icon from 'features/shared/components/icon';
import Modal from 'features/shared/components/modal';
import { useModalBodyMaxDimensions } from 'features/shared/components/modal/useModalBodyMaxDimensions';
import Scrollbars from 'features/shared/components/scrollbars';
import SimpleTable from 'features/shared/components/table/simpleTable';
import { Typography } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  icon: {
    cursor: 'pointer',
    fontSize: 20
  },
  headerCell: {
    backgroundColor: theme.itemBackgroundColor_1,
    padding: '20px 16px'
  },
  row: {
    backgroundColor: theme.itemBackgroundColor_1,
    textAlign: 'left',

    '&:nth-child(odd)': {
      backgroundColor: theme.itemBackgroundColor_2
    }
  },
  cell: {
    padding: '12px 16px',
    lineHeight: Typography.body1.lineHeight,
    maxWidth: 230
  }
}));

type Props = {
  timeRangeChartDataKey: string;
};

const BenchmarkIcon = ({ timeRangeChartDataKey }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const historicalReturnChartStore = useHistoricalReturnChartStore();
  const { modalBodyMaxHeight, headerRef } = useModalBodyMaxDimensions();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const benchmarkData =
    historicalReturnChartStore.chartData[timeRangeChartDataKey]?.find(
      ({ goalId }) => goalId === 'benchmarkPortfolio'
    )?.data?.benchmarks || [];

  const doesBenchmarkDescriptionExist = benchmarkData.some(
    benchmarkData => benchmarkData.description
  );

  return (
    <>
      <Icon
        className={classes.icon}
        type="info"
        onClick={() => setIsModalOpen(true)}
      />

      <Modal
        header={i18n('roboAdvice.advisory.portfolio.benchmark.header')}
        headerRef={headerRef}
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
      >
        <Scrollbars autoHeightMax={modalBodyMaxHeight}>
          <SimpleTable
            headers={({ headerCellClassName }) => (
              <>
                <th
                  className={classNames(
                    headerCellClassName,
                    classes.headerCell
                  )}
                >
                  {i18n('shared.weight')}
                </th>

                <th
                  className={classNames(
                    headerCellClassName,
                    classes.headerCell
                  )}
                >
                  {i18n('shared.ticker')}
                </th>

                <th
                  className={classNames(
                    headerCellClassName,
                    classes.headerCell
                  )}
                >
                  {i18n('shared.name')}
                </th>

                {doesBenchmarkDescriptionExist && (
                  <th
                    className={classNames(
                      headerCellClassName,
                      classes.headerCell
                    )}
                  >
                    {i18n('shared.description')}
                  </th>
                )}
              </>
            )}
            body={() => (
              <>
                {benchmarkData.map(({ description, name, ticker, weight }) => (
                  <tr key={ticker} className={classes.row}>
                    <td className={classes.cell}>{weight} %</td>

                    <td className={classes.cell}>{ticker}</td>

                    <td className={classes.cell}>{name}</td>

                    {doesBenchmarkDescriptionExist && (
                      <td className={classes.cell}>{description}</td>
                    )}
                  </tr>
                ))}
              </>
            )}
          />
        </Scrollbars>
      </Modal>
    </>
  );
};

export default BenchmarkIcon;
