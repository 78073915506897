import classNames from 'classnames';
import * as R from 'ramda';
import { useReducer, useRef } from 'react';
import { connect } from 'react-redux';

import Header from '../../shared/components/header';
import { creators as customersTablePageActionCreators } from '../services/actions.js';
import { CustomersTableOrderKeys } from '../services/reducers/customersTable.js';
import { getCustomersTableRows } from '../services/selectors.js';
import Confirmation from 'features/shared/components/modal/confirmation';
import DeepAlphaTable from 'features/shared/components/table/connectedTable.js';
import TableRowMenu from 'features/shared/components/table/tableRowMenu.js';
import { UserPermissions } from 'features/shared/constants/session';
import sessionSelectors from 'features/shared/services/session/selectors';
import statusSelectors from 'features/shared/services/status/selectors.js';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { injectSheet } from 'features/sharedModules/styles/components/styles';
import { bindActionCreator } from 'framework/redux/utils.js';

const actionTypes = {
  TOGGLE_DELETE_CONFIRMATION_MODAL: 'TOGGLE_DELETE_CONFIRMATION_MODAL'
};

const initialState = {
  isDeleteConfirmationOpen: false,
  deleteConfirmationData: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_DELETE_CONFIRMATION_MODAL: {
      return R.pipe(
        R.assoc('isDeleteConfirmationOpen', action.payload.isOpen),
        R.assoc('deleteConfirmationData', action.payload.data)
      )(state);
    }
    default:
      return state;
  }
};

const styles = {
  actionsCell: {
    width: '8rem'
  }
};

const CustomersTable = ({
  history,
  rows,
  currentUserPermissions,
  readCustomersStatus,
  deleteCustomer,
  classes
}) => {
  const i18n = useI18n();
  const [state, dispatch] = useReducer(reducer, initialState);
  const currentUserHasUpdateCustomersPermission = R.includes(
    UserPermissions.updateCustomers,
    currentUserPermissions
  );
  const currentUserHasDeleteCustomersPermission = R.includes(
    UserPermissions.deleteCustomers,
    currentUserPermissions
  );
  const tableRef = useRef();
  const tableContainerRef = useRef();
  const { items, totalItemsNumber } = rows;

  return (
    <div>
      <Confirmation
        isOpen={state.isDeleteConfirmationOpen}
        onConfirm={() => {
          deleteCustomer(state.deleteConfirmationData.customerId);
          dispatch({
            type: actionTypes.TOGGLE_DELETE_CONFIRMATION_MODAL,
            payload: { isOpen: false }
          });
        }}
        onDecline={() => {
          dispatch({
            type: actionTypes.TOGGLE_DELETE_CONFIRMATION_MODAL,
            payload: { isOpen: false }
          });
        }}
      >
        <div>{i18n('customers.customersTable.youWantToDeleteTheCustomer')}</div>
        <div>{i18n('shared.areYouSure')}</div>
      </Confirmation>
      <Header />
      <div>
        <DeepAlphaTable
          forwardedTableRef={tableRef}
          forwardedTableContainerRef={tableContainerRef}
          id={'customersTable_state'}
          noDataTitle={i18n('customers.customersTable.noCustomers')}
          isLoading={readCustomersStatus.isPending}
          totalItemsNumber={totalItemsNumber}
          header={[
            {
              title: i18n('shared.name'),
              isOrderable: true,
              orderKey: CustomersTableOrderKeys.title
            },
            {
              title: ''
            }
          ]}
          items={R.map(item => {
            const createMenuItems = ({ toggleMenu }) =>
              R.pipe(
                R.filter(item => item[0]),
                R.map(item => item[1])
              )([
                [
                  currentUserHasUpdateCustomersPermission,
                  {
                    title: i18n('shared.edit'),
                    iconType: 'edit',
                    onClick: e => {
                      e.preventDefault();

                      history.push(
                        routeTemplates.customerManagement.build(item.id)
                      );

                      toggleMenu(false);
                    }
                  }
                ],
                [
                  currentUserHasDeleteCustomersPermission,
                  {
                    title: i18n('shared.delete'),
                    iconType: 'delete',
                    onClick: e => {
                      e.preventDefault();

                      dispatch({
                        type: actionTypes.TOGGLE_DELETE_CONFIRMATION_MODAL,
                        payload: {
                          isOpen: true,
                          data: { customerId: item.id }
                        }
                      });

                      toggleMenu(false);
                    }
                  }
                ]
              ]);

            return {
              id: item.id,
              cells: ({ bodyRowCellClassName }) => (
                <>
                  <td className={bodyRowCellClassName}>{item.title}</td>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.actionsCell
                    )}
                  >
                    <TableRowMenu
                      tableRef={tableRef}
                      tableContainerRef={tableContainerRef}
                      isMenuIconHidden={
                        !currentUserHasUpdateCustomersPermission &&
                        !currentUserHasDeleteCustomersPermission
                      }
                      createMenuItems={createMenuItems}
                    />
                  </td>
                </>
              )
            };
          }, items)}
        />
      </div>
    </div>
  );
};

export default R.compose(
  connect(
    state => {
      const rows = getCustomersTableRows(state);
      const currentUserPermissions =
        sessionSelectors.getCurrentUserPermissions(state);
      const readCustomersStatus = statusSelectors.getStatusOrDefault(
        'readCustomersStatus_state',
        state
      );

      return {
        rows,
        currentUserPermissions,
        readCustomersStatus
      };
    },
    dispatch => {
      return {
        deleteCustomer: bindActionCreator(
          customersTablePageActionCreators.deleteCustomer,
          dispatch
        )
      };
    }
  ),
  injectSheet(styles)
)(CustomersTable);
