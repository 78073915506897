import { isNil } from 'ramda';

import { useForm as useRoboAdviceForm } from '../../../roboAdvice/adviceSession/form/services/form';
import { useGoalsStore } from '../../../roboAdvice/adviceSession/shared/services/goalsStore';
import { useCustomerConfig } from '../../customerConfig/components/useCustomerConfig';
import { useI18n } from '../../customerConfig/components/useI18n';
import { ErrorMessage, PurposeAndRiskErrors } from '../types';

export const useGetPurposeAndRiskErrors = (): PurposeAndRiskErrors => {
  const i18n = useI18n();
  const roboAdviceFormState = useRoboAdviceForm();
  const {
    roboAdviceForm: {
      riskQuestions: { isRisk2Hidden },
      errorMessagesEnabled
    }
  } = useCustomerConfig();
  const { goals } = useGoalsStore();

  return {
    goals: getGoalsErrors({
      goals,
      i18n,
      errorMessagesEnabled
    }),
    ...getRiskErrors({
      roboAdviceFormState,
      isRisk2Hidden,
      i18n,
      errorMessagesEnabled
    })
  };
};

const getGoalsErrors = ({
  goals,
  i18n,
  errorMessagesEnabled
}): ErrorMessage[] => {
  const goalsErrors: ErrorMessage[] = [];

  if (!goals?.length && errorMessagesEnabled) {
    goalsErrors.push({
      alertMessage: i18n('roboAdvice.errors.missingGoalAlert'),
      listMessage: i18n('roboAdvice.errors.missingGoal'),
      elementId: 'goals-section'
    });
  }

  return goalsErrors;
};

const getRiskErrors = ({
  roboAdviceFormState,
  isRisk2Hidden,
  i18n,
  errorMessagesEnabled
}): { risk: ErrorMessage[]; risk2: ErrorMessage[] } => {
  const riskErrors: { risk: ErrorMessage[]; risk2: ErrorMessage[] } = {
    risk: [],
    risk2: []
  };

  if (errorMessagesEnabled) {
    if (!isNil(roboAdviceFormState?.errors?.expectationOfRisk)) {
      riskErrors.risk.push({
        alertMessage: i18n('roboAdvice.errors.missingAnswerAlert'),
        listMessage: i18n('roboAdvice.errors.missingAnswer').replace(
          '{0}',
          i18n('roboAdvice.purposeAndRisk.whatIsYourExpectationOfRisk')
        ),
        elementId: 'expectation-of-risk-cards-list'
      });
    }

    if (!isRisk2Hidden && !isNil(roboAdviceFormState?.errors?.riskStrategy)) {
      riskErrors.risk2.push({
        alertMessage: i18n('roboAdvice.errors.missingAnswerAlert'),
        listMessage: i18n('roboAdvice.errors.missingAnswer').replace(
          '{0}',
          i18n('roboAdvice.purposeAndRisk.whereShouldYouPutYourMoney')
        ),
        elementId: 'risk-strategy'
      });
    }
  }

  return riskErrors;
};
