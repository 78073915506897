import classNames from 'classnames';
import { isNil, mergeWith } from 'ramda';
import { useState, useEffect } from 'react';

import { FieldComponentTypes } from '../../declarativeForm/constants.js';
import { getErrorText } from './shared.js';
import { useField } from './useField';
import BaseDropdown from 'features/shared/components/dropdown/index';
import ErrorMessage from 'features/shared/components/errorMessage/index.js';
import Tooltip from 'features/shared/components/tooltip';
import { Colors } from 'features/shared/constants/colors.js';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { ConfigurableComponent } from 'features/sharedModules/declarativeForm/components';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  section: {
    display: 'flex',
    alignItems: 'baseline',
    '& + &': {
      marginTop: '3rem'
    }
  },
  vertical: {
    flexDirection: 'column'
  },
  label: {
    color: Colors.gray50,
    marginRight: '2.4rem',
    flex: '0 0 auto',
    width: '350px'
  },
  container: {
    display: 'flex'
  },
  extraInfo: {
    lineHeight: '4.4rem',
    marginLeft: '2.4rem'
  },
  textField: {
    margin: '15px 30px 15px 0'
  }
});

export const Dropdown = ({
  value,
  onChange,
  onFocus,
  onBlur,
  error,
  touched,
  afterChange,
  label,
  options = [],
  inputProps,
  menuProps,
  noDataTitle,
  extraInfo,
  disabled,
  className,
  labelClassName,
  dropdownExternalClasses,
  externalClasses,
  popoverText,
  layout,
  textFieldClassName,
  hasError,
  shouldFlip = true,
  ...restProps
}) => {
  const internalClasses = useStyles();
  const i18n = useI18n();
  const [selectedOption, setSelectedOption] = useState(null);
  const classes = isNil(externalClasses)
    ? internalClasses
    : mergeWith(
        (c1, c2) => classNames(c1, c2),
        internalClasses,
        externalClasses
      );

  const isErrorShown = isNil(hasError) ? touched && !isNil(error) : hasError;
  const errorText = getErrorText(i18n, error);
  const dropdownElement = (
    <BaseDropdown
      data-testid={restProps['data-testid']}
      className={className}
      value={isNil(value) ? null : value}
      onChange={(newValue, option) => {
        !isNil(onChange) && onChange(newValue);
        !isNil(afterChange) && afterChange(newValue, option, value);
      }}
      options={options}
      inputProps={{
        ...inputProps,
        placeholder: disabled ? undefined : inputProps?.placeholder,
        onFocus,
        onBlur,
        isInvalid: isErrorShown
      }}
      menuProps={menuProps}
      noDataTitle={noDataTitle}
      disabled={disabled}
      externalClasses={dropdownExternalClasses}
      popperProps={{
        modifiers: {
          flip: {
            enabled: shouldFlip
          }
        }
      }}
    />
  );

  useEffect(() => {
    setSelectedOption(options.find(({ key }) => key === value));
  }, [options, value]);

  return (
    <div
      className={classNames(classes.section, {
        [classes.vertical]: layout === 'vertical'
      })}
    >
      {!isNil(label) && (
        <span className={classNames(classes.label, labelClassName)}>
          {label}
        </span>
      )}
      <div>
        <div className={classes.container}>
          <div>
            {isNil(popoverText) ? (
              dropdownElement
            ) : (
              <Tooltip
                content={popoverText}
                infoTooltip
                trigger={dropdownElement}
              />
            )}
            {selectedOption?.textField && (
              <ConfigurableComponent
                name={selectedOption.textField.name}
                config={{
                  componentType: FieldComponentTypes.textInput
                }}
                className={classNames(classes.textField, textFieldClassName)}
                {...selectedOption.textField}
              />
            )}
            {options.find(option => option.key === value)?.isNoteActive && (
              <ConfigurableComponent
                key={value}
                name={value}
                config={{
                  componentType: FieldComponentTypes.textInput
                }}
                sectionClassName={classes.textField}
              />
            )}
            {isErrorShown && isNil(hasError) && (
              <ErrorMessage>* {errorText}</ErrorMessage>
            )}
          </div>
          {!isNil(value) && !isNil(extraInfo) && (
            <div className={classes.extraInfo}>{extraInfo}</div>
          )}
        </div>
      </div>
    </div>
  );
};

const FinalFormDropdown = ({ name, fieldConfig, ...restProps }) => {
  const {
    input: { value, onChange, onFocus, onBlur },
    meta: { error, touched }
  } = useField(name, fieldConfig);

  return (
    <Dropdown
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      error={error}
      touched={touched}
      {...restProps}
    />
  );
};

export default FinalFormDropdown;
