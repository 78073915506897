import axios from 'axios';
import * as R from 'ramda';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { succeedCreator } from '../../../../shared/utils/actions';
import { types } from '../../riskScore/services/actions';
import { mapRiskScoreConfigServerDataToClient } from '../../riskScore/services/sagas/mapRiskScoreToResult';
import { Statuses, useSessionStore } from '../../session/services/sessionStore';
import {
  getRiskConfig,
  getRoboPortfolioConfig,
  getRoboPortfolioMeta
} from '../../shared/api';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useReadRiskScoreSettings() {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const { roboPortfolioPrecision } = useCustomerConfig();
  const dispatch = useDispatch();

  const cancelTokenSourceRef = React.useRef<any>();
  const readRiskScoreSettings = async () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const sessionStore = useSessionStore.getState();

    try {
      sessionStore.setSessionStatus('readRiskScoreSettings', Statuses.pending);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const getRoboPortfolioMetaResponse = await getRoboPortfolioMeta(
        accessToken,
        cancelTokenSource.token
      );

      const getRoboPortfolioConfigResponses = await Promise.all(
        R.pathOr(
          [],
          ['allowed options', 'risk'],
          getRoboPortfolioMetaResponse.data
        ).map(riskScore =>
          getRoboPortfolioConfig(accessToken, riskScore, roboPortfolioPrecision)
        )
      );
      const { data: riskConfigData } = await getRiskConfig(accessToken);

      const portfolioConfigs = R.map(
        R.prop('data'),
        getRoboPortfolioConfigResponses
      );
      const riskScoreConfig = mapRiskScoreConfigServerDataToClient(
        riskConfigData,
        portfolioConfigs
      );

      dispatch(succeedCreator(types.READ_RISK_SCORE_CONFIG, riskScoreConfig));

      sessionStore.setSessionStatus('readRiskScoreSettings', Statuses.succeed);
    } catch (error) {
      if (!axios.isCancel(error)) {
        sessionStore.setSessionStatus('readRiskScoreSettings', Statuses.failed);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.adviceSession.readRiskScoreSettingsErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readRiskScoreSettings;
}
