import axios from 'axios';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import { deleteAdviceSession as deleteAdviceSessionApi } from '../../main/api';
import { clientForm } from '../../main/services/clientForm';
import { useClientPageStore } from '../../main/services/clientPageStore';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useDeleteAdviceSession() {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);

  const dispatch = useDispatch();

  const deleteAdviceSession = async adviceSessionId => {
    const clientPageStoreState = useClientPageStore.getState();
    const {
      values: { adviceSessions }
    } = clientForm.getState();

    if (adviceSessions == null) {
      return;
    }

    try {
      clientPageStoreState.setIsDeleteAdviceSessionPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        undefined
      );

      const adviceSessionIndex = R.findIndex(
        s => s.id === adviceSessionId,
        adviceSessions
      );
      clientForm.mutators.remove('adviceSessions', adviceSessionIndex);

      await deleteAdviceSessionApi(accessToken, undefined, adviceSessionId);

      clientPageStoreState.setIsDeleteAdviceSessionPending(false);

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n('roboAdvice.client.deleteAdviceSessionSuccessMessage'),
          type: NotificationTypes.success
        })
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        clientPageStoreState.setIsDeleteAdviceSessionPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.client.deleteAdviceSessionErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return deleteAdviceSession;
}
