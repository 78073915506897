import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { goalIcons } from 'modules/shared/constants/icons';
import WarningIcon from 'assets/report_problem_24px.svg';
import { useI18n } from 'modules/settings/components/settings.js';
import { formatNumber } from 'modules/shared/utils/number';
import { createUseStyles, useTheme } from 'modules/styles/components/styles';
import { TextFontSizes, FontWeights } from 'modules/shared/constants/fonts';

const useStyles = createUseStyles(theme => ({
  title: {
    fontWeight: FontWeights.bold,
    fontSize: TextFontSizes.large,
    lineHeight: TextFontSizes.largeLineHeight,
    marginBottom: '10px'
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  secondaryText: {
    color: theme.secondaryColor,
    lineHeight: '19px',
    marginTop: '7px'
  }
}));

const CashflowGoalChartVertical = ({
  cultureCode,
  chartData: { capitalNeed, expectedValue },
  title,
  iconName,
  color,
  chartSize
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const i18n = useI18n();

  const options = {
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    chart: {
      backgroundColor: 'transparent',
      events: {
        load: function () {
          const icon = goalIcons.find(({ name }) => name === iconName)?.urlIcon;
          let progress = 1;
          if (!Number.isNaN(+expectedValue) && !Number.isNaN(+capitalNeed)) {
            progress = expectedValue / capitalNeed;
          }
          if (progress < 1) {
            this.renderer.image(WarningIcon, 10, 10, 24, 24).add();
          }
          let innerSize = this.userOptions.plotOptions.pie.innerSize;
          if (icon) {
            this.renderer
              .image(
                icon,
                this.chartWidth / 2 - innerSize / 2,
                this.plotTop + this.plotSizeY / 2 - innerSize / 2 - 10,
                innerSize,
                innerSize
              )
              .add();
          }
          this.renderer
            .text(
              `<div style="
                  color: ${theme.secondaryColor};
                  font-weight: bold;
                  font-size: 16px;
                  text-align: center;
                  width: ${chartSize || 210}px
                ">${formatNumber(cultureCode, progress * 100, 0, 0)}%</div>`,
              0,
              icon
                ? this.plotTop + this.plotSizeY / 2 - innerSize / 2 + 40
                : this.plotTop + this.plotSizeY / 2 - innerSize / 2 + 20,
              true
            )
            .add();
        }
      },
      height: chartSize || 210,
      width: chartSize || 210,
      animation: false
    },
    title: {
      text: null
    },
    plotOptions: {
      pie: {
        innerSize: 35,
        enableMouseTracking: false,
        dataLabels: {
          enabled: false
        },
        colors: [color, theme.chartEmptyColor]
      }
    },
    series: [
      {
        type: 'pie',
        data: [
          {
            y:
              !Number.isNaN(expectedValue) && typeof expectedValue !== 'string'
                ? expectedValue
                : 1
          },
          {
            y:
              !Number.isNaN(capitalNeed) && !Number.isNaN(expectedValue)
                ? capitalNeed - expectedValue
                : 1
          }
        ],
        innerSize: '65%',
        borderWidth: 0,
        borderColor: theme.boxBackgroundColor_1
      }
    ]
  };

  return (
    <>
      <div className={classes.container} style={{ width: chartSize || 210 }}>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={'chart'}
          options={options}
        />

        <div className={classes.info}>
          <div className={classes.title}>{title}</div>

          <div className={classes.secondaryText}>
            {i18n('cashflow.capitalNeed')}:
          </div>
          <div>
            {typeof capitalNeed === 'string'
              ? capitalNeed
              : formatNumber(cultureCode, capitalNeed, 0, 0)}
          </div>

          <div className={classes.secondaryText}>
            {i18n('cashflow.expectedValue')}:
          </div>
          <div>
            {typeof expectedValue === 'string'
              ? expectedValue
              : formatNumber(cultureCode, expectedValue, 0, 0)}
          </div>
        </div>
      </div>
    </>
  );
};

export default CashflowGoalChartVertical;
