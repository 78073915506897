import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Table from '../../../../../shared/components/table/table';
import TableBody from '../../../../../shared/components/table/tableBody';
import TableBodyRow from '../../../../../shared/components/table/tableBodyRow';
import sessionSelectors from '../../../../../shared/services/session/selectors';
import { formatNumber } from '../../../../../shared/utils/number';
import { useCustomerConfig } from '../../../../../sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from '../../../../../sharedModules/customerConfig/components/useI18n';
import { useFieldArray } from '../../../../../sharedModules/finalForm/components/useFieldArray';
import { createUseStyles } from '../../../../../sharedModules/styles/components/styles';
import { useTotalValues } from '../../services/selectors';
import { useReadOrderData } from './useReadOrderData';
import { ReactComponent as CheckIcon } from 'assets/checkmark2.svg';
import { ReactComponent as CrossIcon } from 'assets/x-icon.svg';
import { useSessionStore } from 'features/roboAdvice/adviceSession/session/services/sessionStore';
import { ProductAttributeType } from 'features/roboAdvice/adviceSession/shared/constants';
import { useAdviceSessionStore } from 'features/roboAdvice/adviceSession/shared/services/adviceSessionStore';
import { getCustomAttributes } from 'features/roboAdvice/adviceSession/shared/services/selectors';
import { FontWeights } from 'features/shared/constants/typography';
import { getTranslation } from 'features/shared/utils/translations';

const AnyTableComponent: any = Table;
const AnyTableBody: any = TableBody;
const AnyTableBodyRow: any = TableBodyRow;

const useStyles = createUseStyles(theme => ({
  rowCell: {
    padding: '1.2rem 1.3rem',

    '&$summaryRowCell$summaryRowCell': {
      backgroundColor: theme.inputFillFocusColor
    }
  },
  numberCell: {
    textAlign: 'right',
    minWidth: '13rem',
    maxWidth: '16rem'
  },
  numberInput: {
    width: '100%'
  },
  summaryRowCell: {
    fontWeight: FontWeights.medium
  },
  checkIcon: {
    fill: theme.successColor
  },
  crossIcon: {
    fill: theme.errorNegativeColor
  },
  center: {
    textAlign: 'center'
  }
}));

const InstrumentsTable = () => {
  const i18n = useI18n();
  const classes = useStyles();
  const {
    roboOrderFlow: {
      proposal: {
        summaryTable: {
          showExpectedAnnualReturn,
          showExpectedValue,
          productAttributeTxt1,
          productAttributeTxt2,
          productAttributeTxt3,
          productAttributeTxt4,
          productAttributeTxt5,
          productAttributeBinary1,
          productAttributeBinary2,
          productAttributeBinary3,
          productAttributeBinary4,
          productAttributeBinary5
        }
      }
    },
    roboAdvice: {
      orderExecution: {
        instrumentsList: { showISINColumn }
      }
    }
  } = useCustomerConfig();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const [proposalData, setProposalData] = useState<
    Record<string, { expectedValue?: number; expectedAnnualReturn?: number }>
  >({});
  const sessionStoreState = useSessionStore.getState();

  const readOrderProposalData = useReadOrderData();
  const instrumentsSelected = useFieldArray(`instrumentsSelected`);
  const totalValues = useTotalValues();
  const productAttributes = getCustomAttributes({
    productAttributeTxt1,
    productAttributeTxt2,
    productAttributeTxt3,
    productAttributeTxt4,
    productAttributeTxt5,
    productAttributeBinary1,
    productAttributeBinary2,
    productAttributeBinary3,
    productAttributeBinary4,
    productAttributeBinary5
  });
  const { customAttributesData } = useAdviceSessionStore();

  const header = [
    { title: i18n('roboAdvice.proposal.orderSummary.fund'), isHidden: false },
    { title: i18n('shared.isin'), isHidden: !showISINColumn },
    ...productAttributes.map(({ label, type }) => ({
      title: getTranslation(label),
      className: type === ProductAttributeType.binary ? classes.center : null,
      isHidden: false
    })),
    {
      title: i18n('roboAdvice.orderExecution.initialDeposit'),
      className: classes.numberCell,
      isHidden: false
    },
    {
      title: i18n('roboAdvice.orderExecution.monthlyDeposit'),
      className: classes.numberCell,
      isHidden: false
    },
    {
      title: i18n('roboAdvice.proposal.expectedAnnualReturn'),
      className: classes.numberCell,
      isHidden: !showExpectedAnnualReturn
    },
    {
      title: i18n('roboAdvice.proposal.expectedValueInXYears').replace(
        '{0}',
        '10'
      ),
      className: classes.numberCell,
      isHidden: !showExpectedValue
    }
  ].filter(({ isHidden }) => !isHidden);

  useEffect(() => {
    const getProposalData = async () => {
      const data = await readOrderProposalData({
        instruments: instrumentsSelected.fields.value
      });
      setProposalData(data);
    };
    if (instrumentsSelected.fields.length) {
      getProposalData();
    }
  }, []);

  const totalExpectedValue = Object.values(proposalData).reduce(
    (acc, curr) => acc + (curr.expectedValue || 0),
    0
  );

  return (
    <AnyTableComponent header={header}>
      <AnyTableBody cellsNumber={header.length}>
        {instrumentsSelected.fields?.value.map(item => {
          const customAttributeData = customAttributesData[
            sessionStoreState.defaultConfigNamespaceId!
          ]?.find(({ ticker }) => ticker === item.id);

          return (
            <AnyTableBodyRow key={item.id} id={item.id} isCssParity>
              {({ bodyRowCellClassName }) => (
                <>
                  <td
                    className={classNames(
                      classes.rowCell,
                      bodyRowCellClassName
                    )}
                  >
                    {item.name}
                  </td>
                  {showISINColumn && (
                    <td
                      className={classNames(
                        classes.rowCell,
                        bodyRowCellClassName
                      )}
                    >
                      {item.isin}
                    </td>
                  )}

                  {productAttributes.map(({ type, name }) => {
                    if (type === ProductAttributeType.text) {
                      return (
                        <td
                          className={classNames(
                            classes.rowCell,
                            bodyRowCellClassName
                          )}
                          key={name}
                        >
                          {customAttributeData?.productAttributeText?.[name]}
                        </td>
                      );
                    }

                    if (type === ProductAttributeType.binary) {
                      return (
                        <td
                          className={classNames(
                            classes.rowCell,
                            bodyRowCellClassName,
                            classes.center
                          )}
                          key={name}
                        >
                          {customAttributeData?.productAttributeBinary?.[
                            name
                          ] ? (
                            <CheckIcon className={classes.checkIcon} />
                          ) : (
                            <CrossIcon className={classes.crossIcon} />
                          )}
                        </td>
                      );
                    }

                    return null;
                  })}

                  <td
                    className={classNames(
                      classes.rowCell,
                      classes.numberCell,
                      bodyRowCellClassName
                    )}
                  >
                    {formatNumber(cultureCode, item.initialDeposit ?? 0, 0, 2)}
                  </td>
                  <td
                    className={classNames(
                      classes.rowCell,
                      classes.numberCell,
                      bodyRowCellClassName
                    )}
                  >
                    {formatNumber(cultureCode, item.monthlySaving ?? 0, 0, 2)}
                  </td>
                  {showExpectedAnnualReturn && (
                    <td
                      className={classNames(
                        classes.rowCell,
                        classes.numberCell,
                        bodyRowCellClassName
                      )}
                    >
                      {proposalData[item.id]
                        ? `${formatNumber(
                            cultureCode,
                            proposalData[item.id]?.expectedAnnualReturn,
                            1,
                            1
                          )}%`
                        : ''}
                    </td>
                  )}
                  {showExpectedValue && (
                    <td
                      className={classNames(
                        classes.rowCell,
                        classes.numberCell,
                        bodyRowCellClassName
                      )}
                    >
                      {proposalData[item.id]
                        ? formatNumber(
                            cultureCode,
                            proposalData[item.id]?.expectedValue,
                            0,
                            0
                          )
                        : ''}
                    </td>
                  )}
                </>
              )}
            </AnyTableBodyRow>
          );
        })}
        {instrumentsSelected.fields.value?.length > 1 && (
          <AnyTableBodyRow>
            {({ bodyRowCellClassName }) => (
              <>
                <td
                  className={classNames(
                    bodyRowCellClassName,
                    classes.rowCell,
                    classes.summaryRowCell
                  )}
                >
                  {i18n('roboAdvice.orderExecution.instrumentsList.summaryRow')}
                </td>
                {showISINColumn && (
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.rowCell,
                      classes.summaryRowCell
                    )}
                  />
                )}
                {productAttributes.map(() => (
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.rowCell,
                      classes.summaryRowCell
                    )}
                  />
                ))}
                <td
                  className={classNames(
                    bodyRowCellClassName,
                    classes.rowCell,
                    classes.numberCell,
                    classes.summaryRowCell
                  )}
                >
                  {formatNumber(cultureCode, totalValues.initialDeposit, 0, 2)}
                </td>
                <td
                  className={classNames(
                    bodyRowCellClassName,
                    classes.rowCell,
                    classes.numberCell,
                    classes.summaryRowCell
                  )}
                >
                  {formatNumber(cultureCode, totalValues.monthlySaving, 0, 2)}
                </td>
                {showExpectedAnnualReturn && (
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.rowCell,
                      classes.summaryRowCell
                    )}
                  />
                )}
                {showExpectedValue && (
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.rowCell,
                      classes.numberCell,
                      classes.summaryRowCell
                    )}
                  >
                    {formatNumber(cultureCode, totalExpectedValue, 0, 0)}
                  </td>
                )}
              </>
            )}
          </AnyTableBodyRow>
        )}
      </AnyTableBody>
    </AnyTableComponent>
  );
};

export default InstrumentsTable;
