import { useParams } from 'react-router';

import { ClientParams } from '../../shared/types';
import { useClientForm } from './../services/clientForm';
import { NewClientId } from 'features/roboAdvice/shared/constants';
import { ClientTypes } from 'features/shared/constants/session';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';

export function useIsClientTabDisabled() {
  const {
    roboAdvice: {
      kyc: {
        company: { shouldKYCBlockAdviceSession: companySetting },
        person: { shouldKYCBlockAdviceSession: personSetting }
      }
    }
  } = useCustomerConfig();
  const { clientId } = useParams<ClientParams>();
  const { errors, values } = useClientForm();
  const shouldKYCBlockAdviceSession =
    values.clientInformation?.clientType === ClientTypes.company
      ? companySetting
      : personSetting;

  return {
    isKYCTabDisabled: clientId === NewClientId,
    isAdviceSessionsTabDisabled:
      clientId === NewClientId || (shouldKYCBlockAdviceSession && !!errors?.kyc)
  };
}
