import {
  mapServerDataToBoundsChart,
  mapServerDataToExpectedReturnChart
} from 'modules/expectedPathChart/default/services/expectedPathChartStore';
import create from 'zustand';

type PortfolioData = {
  forecast: {
    date: string;
    lower_bound: number;
    month: number;
    upper_bound: number;
    value: number;
    year: number;
  }[];
  id: string;
  title: string;
  color: string;
  timeHorizon: number;
};

export const createUseExpectedPathPortfoliosChartStore = () => {
  const defaultData = {
    chartData: []
  };

  return create(set => ({
    ...defaultData,
    reset: () => {
      set(defaultData);
    },
    setChartData(portfoliosData: PortfolioData[]) {
      const currentDate = new Date();

      const timeHorizon = Math.max(
        ...portfoliosData.map(({ timeHorizon }) => timeHorizon)
      );

      set({
        chartData: portfoliosData.map(({ id, color, title, forecast }) => {
          return {
            id,
            color,
            title,
            data: {
              expectedReturnChartData: mapServerDataToExpectedReturnChart(
                timeHorizon,
                currentDate,
                forecast
              ),
              boundsChartData: mapServerDataToBoundsChart(
                timeHorizon,
                currentDate,
                forecast
              )
            }
          };
        })
      });
    }
  }));
};
