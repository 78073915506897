import * as R from 'ramda';

import { types } from '../actions.js';
import { succeedType } from 'features/shared/utils/actions.js';
import { types as userProfileBasicPageActionTypes } from 'features/userProfile/services/actions';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case types.INITIALIZE: {
      const { auth0AccessToken, user, customerConfig } = action.payload;

      return R.pipe(
        R.assoc('auth0AccessToken', auth0AccessToken),
        R.assoc('currentUser', user),
        R.assoc('customerConfig', customerConfig)
      )(state);
    }
    case succeedType(types.LOGIN): {
      const { auth0AccessToken, user, customerConfig } = action.payload;

      return R.pipe(
        R.assoc('auth0AccessToken', auth0AccessToken),
        R.assoc('currentUser', user),
        R.assoc('customerConfig', customerConfig)
      )(state);
    }
    case succeedType(userProfileBasicPageActionTypes.UPDATE_USER_PICTURE): {
      const { customPicture } = action.payload;

      return R.set(
        R.lensPath(['currentUser', 'customPicture']),
        customPicture,
        state
      );
    }
    case userProfileBasicPageActionTypes.DELETE_USER_PICTURE: {
      return R.set(R.lensPath(['currentUser', 'customPicture']), null, state);
    }
    default:
      return state;
  }
};

export default reducer;
