import { Link as ReactRouterLink } from 'react-router-dom';

import ErrorPage from '../errorPage';
import { defaultTheme } from 'defaults/defaultTheme';
import { defaultTranslations } from 'defaults/defaultTranslations';
import { Spacing } from 'features/shared/constants/spacing';
import history from 'features/shared/utils/history';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  link: {
    textDecoration: 'none',
    color: defaultTheme.accentColor
  },
  backLinkText: {
    margin: `${Spacing.spacing02}px 0 0 0`
  }
});

const PageNotFound = () => {
  const classes = useStyles();
  const pathName = history.location.pathname;

  return (
    <ErrorPage header={defaultTranslations['system.message.error404']}>
      <>
        <p>
          {defaultTranslations[
            'system.message.requestedUrlWasNotFound'
          ].replace('{0}', pathName)}
        </p>
        <p className={classes.backLinkText}>
          <ReactRouterLink
            to={routeTemplates.home.build()}
            className={classes.link}
          >
            {defaultTranslations['navigation.goBack']}
          </ReactRouterLink>
          {' to '}
          {defaultTranslations['navigation.homePage'].toLowerCase()}
        </p>
      </>
    </ErrorPage>
  );
};

export default PageNotFound;
