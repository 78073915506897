import {
  SettingsProvider as QfUiSettingsProvider,
  ThemeProvider as QfUiThemeProvider
} from '@quantfoliorepo/ui-components';
import { Fragment, ReactNode } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';

import { CustomerConfigContext } from './context';
import sessionSelectors from 'features/shared/services/session/selectors';
import { CustomerConfigType } from 'features/shared/services/session/types';
import { ThemeProvider } from 'features/sharedModules/styles/components/styles';

type Props = {
  children: ReactNode;
  customerConfig: CustomerConfigType;
  shouldRenderHelmet?: boolean;
};

export const BaseCustomerConfigProvider = ({
  children,
  customerConfig,
  shouldRenderHelmet = true
}: Props) => {
  return (
    <Fragment>
      {shouldRenderHelmet && <Helmet link={customerConfig.styleSheets} />}
      <CustomerConfigContext.Provider value={customerConfig}>
        <ThemeProvider theme={customerConfig.styles}>
          <QfUiSettingsProvider value={customerConfig}>
            <QfUiThemeProvider theme={customerConfig.styles}>
              {children}
            </QfUiThemeProvider>
          </QfUiSettingsProvider>
        </ThemeProvider>
      </CustomerConfigContext.Provider>
    </Fragment>
  );
};

export const CustomerConfigProvider = ({
  children,
  shouldRenderHelmet = true
}) => {
  const customerConfig: CustomerConfigType = useSelector(
    sessionSelectors.getCustomerConfig
  );

  return (
    <BaseCustomerConfigProvider
      shouldRenderHelmet={shouldRenderHelmet}
      customerConfig={customerConfig}
    >
      {children}
    </BaseCustomerConfigProvider>
  );
};
