import create from 'zustand';
import { add, mergeWithKey, subtract } from 'ramda';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

type Forecast = {
  amount: number;
  date: string;
  deposits: number;
  withdrawals: number;
};

type GoalData = {
  goalId: string;
  goalIcon: string;
  goalName: string;
  capitalNeed: 'N/A' | number;
  estimatedGoalAchievement: number;
  forecast: Forecast[];
};

type CashflowGoalChartData = {
  goalId: string;
  goalIcon: string;
  goalName: string;
  chartData: {
    capitalNeed: 'N/A' | number;
    estimatedGoalAchievement: number;
  };
};

type CashflowForecastChartData = {
  savingPlan: [number, number][];
  savingPlanWithNoReturn: [number, number][];
  ranges: any;
};

type CashflowChartStoreData = {
  goalsData: GoalData[];
  tableData: Forecast[];
  cashflowGoalChartsData: CashflowGoalChartData[];
  cashflowForecastChartData: CashflowForecastChartData | null;
};

type CashflowChartStoreApi = {
  reset: () => void;
  addGoalData: (goalData: GoalData) => void;
  editGoalData: (goalData: GoalData) => void;
  setTableData: (selectedGoalsIds?: string[]) => void;
  setCashflowGoalChartsData: (selectedGoalsIds?: string[]) => void;
  setCashflowForecastChartData: (selectedGoalsIds?: string[]) => void;
};

type CashflowChartStoreState = CashflowChartStoreData & CashflowChartStoreApi;

export const createUseCashflowChartStore = (
  initialState = {
    goalsData: [],
    tableData: [],
    cashflowGoalChartsData: [],
    cashflowForecastChartData: null
  }
) =>
  create<CashflowChartStoreState>(set => ({
    ...initialState,
    reset: () => {
      set(initialState);
    },
    addGoalData: goalData => {
      set(state => ({ ...state, goalsData: [...state.goalsData, goalData] }));
    },
    editGoalData: goalData => {
      set(state => ({
        ...state,
        goalsData: state.goalsData.map(data => {
          if (goalData.goalId === data.goalId) {
            return goalData;
          }
          return data;
        })
      }));
    },
    setTableData: selectedGoalsIds => {
      set(state => {
        const selectedGoalsData = selectedGoalsIds
          ? state.goalsData.filter(({ goalId }) =>
              selectedGoalsIds.includes(goalId)
            )
          : state.goalsData;

        const tableData = calculateTableData(selectedGoalsData);

        return { tableData };
      });
    },
    setCashflowGoalChartsData: selectedGoalsIds => {
      set(state => {
        const selectedGoalsData = selectedGoalsIds
          ? state.goalsData.filter(({ goalId }) =>
              selectedGoalsIds.includes(goalId)
            )
          : state.goalsData;

        const cashflowGoalChartsData = selectedGoalsData.map(
          ({
            goalIcon,
            goalId,
            goalName,
            capitalNeed,
            estimatedGoalAchievement
          }) => ({
            goalIcon,
            goalId,
            goalName,
            chartData: {
              estimatedGoalAchievement,
              capitalNeed
            }
          })
        );

        return { cashflowGoalChartsData };
      });
    },
    setCashflowForecastChartData: selectedGoalsIds => {
      set(state => {
        const selectedGoalsData = selectedGoalsIds
          ? state.goalsData.filter(({ goalId }) =>
              selectedGoalsIds.includes(goalId)
            )
          : state.goalsData;
        const forecasts = selectedGoalsData
          .map(({ forecast }) => forecast)
          .flat();

        const cashflowForecastChartData = calculateCashflowForecastChartData(
          forecasts
        );

        return { cashflowForecastChartData };
      });
    }
  }));

const calculateTableData = (selectedGoalsData: GoalData[]) =>
  selectedGoalsData
    .map(({ forecast }) => forecast)
    .flat()
    .reduce((acc: Forecast[], curr) => {
      const index = acc.findIndex(({ date }) => date === curr.date);
      if (index !== -1) {
        return acc.map((item, i) =>
          i === index
            ? mergeWithKey(
                (k, l, r) => (k === 'date' ? r : add(l, r)),
                acc[index],
                curr
              )
            : item
        );
      }
      return [...acc, curr];
    }, []);

const calculateCashflowForecastChartData = (forecasts: Forecast[]) => {
  const savingPlan = forecasts.reduce<[number, number][]>((acc, curr) => {
    const currentDate = dayjs.utc(curr.date, 'YYYY-MM-DD').valueOf();

    const index = acc.findIndex(([date]) => date === currentDate);
    if (index !== -1) {
      return acc.map((item, i) =>
        i === index ? [item[0], add(item[1], curr.amount)] : item
      );
    }
    return [...acc, [currentDate, curr.amount]];
  }, []);

  const savingPlanWithNoReturn = forecasts
    .reduce<[number, number][]>((acc, curr) => {
      const currentDate = dayjs.utc(curr.date, 'YYYY-MM-DD').valueOf();

      const index = acc.findIndex(([date]) => date === currentDate);
      if (index !== -1) {
        return acc.map((item, i) =>
          i === index
            ? [item[0], add(item[1], curr.deposits - curr.withdrawals)]
            : item
        );
      }
      return [...acc, [currentDate, subtract(curr.deposits, curr.withdrawals)]];
    }, [])
    .map((_, index, arr) =>
      arr
        .slice(0, index + 1)
        .reduce((acc, [date, amount]) => [date, acc[1] + amount], [0, 0])
    );

  const ranges = savingPlanWithNoReturn
    .concat(savingPlan)
    .reduce<[number, number][] | [number, number, number][]>((acc, curr) => {
      const index = acc.findIndex(
        ([date]: [number, number] | [number, number, number]) =>
          date === curr[0]
      );
      if (index !== -1) {
        return acc.map((item, i) =>
          i === index ? [item[0], item[1], curr[1]] : item
        );
      }
      return [...acc, curr];
    }, []);

  return {
    savingPlan,
    savingPlanWithNoReturn,
    ranges
  };
};
