import { equals, isNil } from 'ramda';
import React, { useRef } from 'react';
import ReactSelect, { components, ControlProps, GroupBase } from 'react-select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';

import Icon from '../icon';
import { getMenuHeight, stylesValues } from './utils';
import { Theme } from 'defaults/defaultTheme';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { getErrorText } from 'features/sharedModules/finalForm/components/shared';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  error: {
    color: theme.errorNegativeColor,
    marginTop: Spacing.spacing00,
    fontSize: Typography.subtitle.size,
    lineHeight: Typography.subtitle.lineHeight
  },
  searchIcon: {
    marginLeft: Spacing.spacing01,
    fontSize: 18
  }
}));

const getCustomStyles = (theme: Theme, isErrorShown: boolean) => ({
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.isFocused
      ? theme.inputFillFocusColor
      : theme.defaultInputFillColor,
    borderColor: isErrorShown
      ? theme.errorNegativeColor
      : state.isFocused
      ? theme.accentColor
      : theme.defaultInputStrokeColor,
    borderRadius: 20,
    borderWidth: stylesValues.borderWidth,
    cursor: 'pointer',

    '&:hover': {
      borderColor: theme.hoverInputStrokeColor
    }
  }),
  dropdownIndicator: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isFocused ? theme.accentColor : theme.secondaryColor,
    padding: `8px ${stylesValues.inputPaddingX}px`
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: baseStyles => ({
    ...baseStyles,
    color: theme.primaryColor,
    margin: 0,
    paddingBottom: 0,
    paddingTop: 0
  }),
  menu: baseStyles => ({
    ...baseStyles,
    backgroundColor: theme.inputFillFocusColor,
    border: `${stylesValues.borderWidth}px solid ${theme.accentColor}`,
    borderRadius: 15,
    marginTop: stylesValues.menuPaddingY,
    marginBottom: stylesValues.menuPaddingY,
    overflow: 'hidden'
  }),
  menuList: baseStyles => ({
    ...baseStyles,
    paddingBottom: stylesValues.menuPaddingY,
    paddingTop: stylesValues.menuPaddingY,
    scrollbarColor: `${theme.accentColor} transparent`,
    overflow: 'overlay',
    scrollbarGutter: 'stable both-edges',

    '::-webkit-scrollbar': {
      width: 10
    },
    '::-webkit-scrollbar-thumb': {
      background: `${theme.accentColor}`,
      border: `${stylesValues.borderWidth}px solid ${theme.defaultInputStrokeColor}`,
      borderRadius: 15,
      cursor: 'pointer'
    }
  }),
  menuPortal: baseStyles => ({ ...baseStyles, zIndex: 9999 }),
  noOptionsMessage: baseStyles => ({
    ...baseStyles,
    color: theme.secondaryColor
  }),
  option: (baseStyles, { isFocused, isSelected }) => ({
    ...baseStyles,
    color: 'inherit',
    backgroundColor: isSelected
      ? theme.boxBackgroundColor_2
      : isFocused
      ? theme.itemBackgroundColor_1
      : 'inherit',
    cursor: 'pointer',
    marginTop: 4,
    marginBottom: 4,
    padding: `${stylesValues.menuItemPaddingY}px 18px`,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '&:first-of-type': {
      marginTop: 0
    },
    '&:last-child': {
      marginBottom: 0
    },

    '&:hover': {
      backgroundColor: theme.itemBackgroundColor_2
    }
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isDisabled ? theme.disabledTextColor : theme.secondaryColor,
    margin: 0,
    whiteSpace: 'nowrap'
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isDisabled ? theme.disabledTextColor : theme.primaryColor,
    marginLeft: 0,
    marginRight: 0
  }),
  valueContainer: baseStyles => ({
    ...baseStyles,
    padding: `8px ${stylesValues.inputPaddingX}px`
  })
});

export type Option<T> = {
  label: string;
  value: T;
};

export type SelectProps<T extends Option<string | object>> = {
  ariaLabelledBy?: string;
  components?: Partial<SelectComponents<T, false, GroupBase<T>>>;
  determinativeMenuPlacementElementTopOffset?: number | null;
  error?: string | Record<string, string> | null;
  id?: string;
  isDisabled?: boolean;
  isSearchable?: boolean;
  menuHeightMax?: number;
  options: T[];
  placeholder?: React.ReactNode;
  touched?: boolean;
  value?: string | object;
  width?: number | string | 'auto';
  onBlur?: (event?: React.FocusEvent<HTMLElement, Element> | undefined) => void;
  onChange?: (event) => void;
  onFocus?: (
    event?: React.FocusEvent<HTMLElement, Element> | undefined
  ) => void;
  hasError?: boolean;
};

const Select = <T extends Option<string | object>>({
  ariaLabelledBy,
  components: propsComponents = {},
  error,
  id,
  isDisabled = false,
  isSearchable = false,
  menuHeightMax = 184,
  options,
  placeholder,
  determinativeMenuPlacementElementTopOffset,
  touched,
  value: propsValue,
  width = 'auto',
  onBlur,
  onChange,
  onFocus,
  hasError
}: SelectProps<T>) => {
  const classes = useStyles();
  const i18n = useI18n();
  const theme = useTheme();
  const isErrorShown = isNil(hasError) ? touched && !isNil(error) : hasError;
  const customStyles = getCustomStyles(theme, !!isErrorShown);
  const errorText = getErrorText(i18n, error);
  const selectContainer = useRef<HTMLDivElement>(null);

  const menuHeight = getMenuHeight(options.length);

  const menuPlacement =
    determinativeMenuPlacementElementTopOffset &&
    selectContainer.current &&
    menuHeight + selectContainer.current.getBoundingClientRect().bottom >
      determinativeMenuPlacementElementTopOffset
      ? 'top'
      : 'bottom';

  const handleOnChange = (option: Option<string | object> | null) =>
    onChange && onChange(option?.value);

  return (
    <div ref={selectContainer} style={{ width: width }}>
      <ReactSelect
        aria-labelledby={ariaLabelledBy}
        components={{
          Control,
          NoOptionsMessage: props => (
            <components.NoOptionsMessage {...props}>
              {i18n('autocomplete.noResults')}
            </components.NoOptionsMessage>
          ),
          Option: props => {
            const { label } = props.data;

            return (
              <components.Option {...props}>
                <span title={label}>{label}</span>
              </components.Option>
            );
          },
          ...propsComponents
        }}
        value={options.find(option =>
          typeof propsValue === 'object'
            ? equals(option.value, propsValue)
            : option.value === propsValue
        )}
        inputId={id}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        maxMenuHeight={menuHeightMax}
        menuPlacement={menuPlacement}
        menuPortalTarget={document.body}
        menuShouldScrollIntoView={false}
        name={id}
        options={options}
        placeholder={placeholder}
        styles={customStyles}
        onBlur={onBlur}
        onChange={handleOnChange}
        onFocus={onFocus}
      />
      {isErrorShown && isNil(hasError) && (
        <div className={classes.error}>{errorText}</div>
      )}
    </div>
  );
};

const Control = <T,>({ children, ...props }: ControlProps<T, false>) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <components.Control {...props}>
      {props.selectProps.isSearchable && (
        <Icon
          className={classes.searchIcon}
          style={{
            color: props.isDisabled
              ? theme.disabledTextColor
              : props.isFocused
              ? theme.accentColor
              : theme.secondaryColor
          }}
          type="search"
        />
      )}
      {children}
    </components.Control>
  );
};

export default Select;
