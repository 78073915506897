import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';

export const getTranslation = (
  config: Record<string, any> | undefined | null
): string => {
  const lang = getUserPageLanguage();

  if (!lang || !config) {
    return '';
  }

  return config[lang];
};
