import * as R from 'ramda';

export const skipNilFields = model =>
  R.reduce(
    (acc, key) => (R.isNil(model[key]) ? acc : R.assoc(key, model[key], acc)),
    {},
    R.keys(model)
  );

export const mapServerCountryToClient = country => {
  return {
    id: country.id,
    title: country.title,
    code: country.code,
    cultureCode: country.culture_code,
    isMacroModelCountry: country.is_macro_model_country,
    isProfileCountry: country.is_profile_country,
    priority: country.priority
  };
};

export const mapServerCountriesToClient = countries =>
  countries.map(mapServerCountryToClient);

export const mapServerCustomerToClient = customer => ({
  id: customer.id,
  code: customer.code,
  advisoryKey: customer.advisory_key,
  title: customer.title,
  licenses: mapServerLicensesToClient(customer.licenses)
});

export const mapServerCustomersToClient = R.map(mapServerCustomerToClient);

export const mapServerLicenseToClient = license => ({
  moduleType: license.module_type,
  licenseType: license.license_type,
  licenseRenewalDate: license.license_renewal_date
});

export const mapServerLicensesToClient = R.map(mapServerLicenseToClient);

const mapServerPortfolioAssetStatisticsToClient = s =>
  R.isNil(s)
    ? null
    : {
        returnYtd: s.return_ytd,
        returnMtd: s.return_mtd,
        return3m: s.return_3m,
        return12m: s.return_12m,
        return3yr: s.return_3yr
      };

export const mapServerPortfolioToClient = p => ({
  id: p.id,
  title: p.title,
  authorId: p.author_id,
  buildingMethod: p.building_method,
  currency: p.currency,
  statistics: mapServerPortfolioAssetStatisticsToClient(p.statistics),
  computingStatus: p.computing_status,
  lastPrecomputedInfoCalculated: p.last_precomputed_info_calculated,
  created: p.created,
  pathProjectionSettings: R.isNil(p.path_projection_settings)
    ? null
    : {
        monthlyContribution: p.path_projection_settings.monthly_contribution,
        lookAheadPeriod: p.path_projection_settings.look_ahead_period
      }
});

export const mapServerPortfoliosToClient = R.map(mapServerPortfolioToClient);
