import * as React from 'react';

import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: theme.boxBackgroundColor_2,
    borderRadius: '15px',
    height: '300px'
  }
}));

const Placeholder = () => {
  const classes = useStyles();

  return <div className={classes.root} />;
};

export default Placeholder;
