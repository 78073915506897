import { Tabs as MuiTabs, Tab as MuiTab } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';

import {
  Typography,
  FontWeights,
  FontFamilies
} from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useTabsStyles = createUseStyles(theme => ({
  root: {
    minHeight: '40px'
  },
  indicator: {
    backgroundColor: theme.accentColor
  }
}));

type TabsProps = {
  rootClassName?: string;
} & React.ComponentProps<typeof MuiTabs>;

export const Tabs = ({ rootClassName, ...restProps }: TabsProps) => {
  const classes = useTabsStyles();

  return (
    <MuiTabs
      classes={{ ...classes, root: classNames(classes.root, rootClassName) }}
      {...restProps}
    />
  );
};

const useTabStyles = createUseStyles(theme => ({
  root: {
    minWidth: 'auto',
    minHeight: '40px',
    padding: '6px 10px',
    margin: '0 5px',
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,
    fontFamily: FontFamilies.roboto,
    fontWeight: FontWeights.normal,
    textTransform: 'none',
    color: theme.secondaryColor,
    opacity: 1,

    '&:first-child': {
      marginLeft: '0'
    },

    '&:last-child': {
      marginRight: '0'
    }
  },
  selected: {
    color: theme.primaryColor
  }
}));

type TabProps = {
  rootClassName?: string;
} & React.ComponentProps<typeof MuiTab>;

export const Tab = ({ rootClassName, ...restProps }: TabProps) => {
  const classes = useTabStyles();

  return (
    <MuiTab
      classes={{ ...classes, root: classNames(classes.root, rootClassName) }}
      {...restProps}
    />
  );
};
