import queryString, { ParseOptions, ParsedQuery } from 'query-string';
import { useLocation } from 'react-router-dom';

export function useQuery(
  options: { parseBooleans: true; parseNumbers: true } & ParseOptions
): ParsedQuery<string | boolean | number>;
export function useQuery(
  options: { parseBooleans: true } & ParseOptions
): ParsedQuery<string | boolean>;
export function useQuery(
  options: { parseNumbers: true } & ParseOptions
): ParsedQuery<string | number>;
export function useQuery(options?: ParseOptions): ParsedQuery;

export function useQuery(options) {
  return queryString.parse(useLocation().search, options);
}
