import debounce from 'lodash.debounce';
import React from 'react';

import { CountriesTaxNumberFormat } from '../../../shared/constants';
import { clientForm, useClientForm } from '../../main/services/clientForm';
import { useIsClientTypeFieldDisabled } from './useIsClientTypeFieldDisabled';
import useReadCompanyData from './useReadCompanyData';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { useCountryDropdownOptions } from 'features/roboAdvice/shared/components/useCountryDropdownOptions';
import { Layouts } from 'features/roboAdvice/shared/constants/index';
import ConfigurableLabel from 'features/shared/components/configurableLabel/';
import { ClientTypes } from 'features/shared/constants/session';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { FieldComponentTypes } from 'features/sharedModules/declarativeForm/constants';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const useStyles = createUseStyles(theme => ({
  standardLabel: {
    color: theme.secondaryColor,
    width: '350px'
  },
  sectionClassName: {
    display: 'flex'
  },
  standardInputWidth: {
    width: '230px'
  },
  nameField: {
    display: 'flex',
    alignItems: 'center'
  },
  radioButtons: {
    '& + &': {
      marginTop: '15px'
    }
  },
  textareaLabel: {
    color: theme.secondaryColor,
    display: 'inline-block',
    marginRight: 24,
    verticalAlign: 'top',
    width: 350
  }
}));

export const useInitPage = () => {
  const i18n = useI18n();
  const classes = useStyles();
  const readCompanyData = useReadCompanyData();
  const {
    roboAdvice: {
      clientInformation: { company, person }
    }
  } = useCustomerConfig();
  const { touched, values } = useClientForm();
  const isClientTypeFieldDisabled = useIsClientTypeFieldDisabled();
  const countryDropdownOptions = useCountryDropdownOptions();

  const afterClientTypeChange = (value, option, prevValue) => {
    if (value === prevValue) {
      return;
    }
    const { country, address, zipCode, city } = values.clientInformation!;

    clientForm.batch(() => {
      clientForm.change('clientInformation', {});
      clientForm.change('clientInformation', {
        clientType: value,
        country,
        address,
        zipCode,
        city
      });
    });
  };

  const afterOrgNumberChange = (value: number) => {
    readCompanyData(value);
  };

  const debouncedAfterOrgNumberChange = debounce(
    value => afterOrgNumberChange(value),
    300
  );

  const fields = React.useMemo(() => {
    const fields =
      values.clientInformation?.clientType === ClientTypes.person
        ? person
        : company;

    return fields
      .filter(({ isEnabled }) => isEnabled)
      .map(
        ({
          allowLeadingZeros,
          name,
          componentType,
          format,
          items,
          label,
          layout,
          options,
          placeholder,
          dateType,
          dateFormat,
          allowedYearsRange,
          required,
          popupInfo
        }) => {
          const languageKey = getUserPageLanguage();
          const labelContent = languageKey && label ? label[languageKey] : '';

          const config = {
            allowLeadingZeros,
            componentType,
            format,
            items,
            label,
            layout,
            options,
            placeholder,
            dateType,
            dateFormat,
            allowedYearsRange,
            required: name === 'clientInformation.country' ? true : required,
            popupInfo
          };

          const customLabel = (
            <div className={classes.standardLabel}>
              <ConfigurableLabel
                required={config.required}
                label={labelContent}
                popupInfo={
                  config.popupInfo &&
                  languageKey &&
                  config.popupInfo[languageKey]
                }
              />
            </div>
          );

          if (name === 'clientInformation.clientType') {
            return {
              name,
              config: {
                ...config,
                labelValue: customLabel
              },
              className: classes.standardInputWidth,
              labelClassName: classes.standardLabel,
              textFieldClassName: classes.standardInputWidth,
              afterChange: afterClientTypeChange,
              disabled: isClientTypeFieldDisabled,
              popoverText: isClientTypeFieldDisabled
                ? i18n('roboAdvice.client.typeCanNotBeChanged')
                : undefined
            };
          }

          if (name === 'clientInformation.orgNumber') {
            let countryFormat;
            const { values } = clientForm.getState();
            const country = values.clientInformation?.country;

            if (country) {
              countryFormat = CountriesTaxNumberFormat[country];
            }

            return {
              name,
              config: {
                ...config,
                labelValue: customLabel
              },
              labelClassName: classes.standardLabel,
              className: classes.standardInputWidth,
              afterChange: debouncedAfterOrgNumberChange,
              format: countryFormat
            };
          }

          if (
            name === 'clientInformation.companyName' ||
            name === 'clientInformation.name'
          ) {
            if (
              (name === 'clientInformation.name' &&
                !values?.clientInformation?.name &&
                !touched?.['clientInformation.name']) ||
              (name === 'clientInformation.companyName' &&
                !values?.clientInformation?.companyName &&
                !touched?.['clientInformation.companyName'])
            ) {
              return {
                name,
                config: {
                  ...config,
                  labelValue: customLabel
                },
                className: classes.standardInputWidth,
                labelClassName: classes.standardLabel,
                fieldClassName: classes.nameField
              };
            }
          }

          if (
            config.componentType === FieldComponentTypes.textInput ||
            config.componentType === FieldComponentTypes.numberInput
          ) {
            return {
              name,
              config: {
                ...config,
                labelValue: customLabel
              },
              className: classes.standardInputWidth,
              labelClassName: classes.standardLabel
            };
          }

          if (config.componentType === FieldComponentTypes.textarea) {
            return {
              name,
              config: {
                ...config,
                labelValue: customLabel
              },
              className: classes.standardInputWidth,
              sectionClassName: classes.sectionClassName,
              labelClassName: classes.textareaLabel
            };
          }

          if (config.componentType === FieldComponentTypes.standardInputWidth) {
            return {
              name,
              config: {
                ...config,
                labelValue: customLabel
              },
              className: classes.standardInputWidth,
              labelClassName: classes.standardLabel,
              textFieldClassName: classes.standardInputWidth
            };
          }

          if (
            config.componentType === FieldComponentTypes.radioButtons ||
            config.componentType === FieldComponentTypes.checkboxes
          ) {
            return {
              name,
              config: {
                ...config,
                layout: Layouts.horizontal,
                labelValue: customLabel
              },
              className: classes.radioButtons,
              afterChange: value => {
                config.items?.forEach(({ activeValue, textField }) => {
                  if (!!textField && !value.includes(activeValue)) {
                    clientForm.change(textField?.name as any, null);
                  }
                });
              }
            };
          }

          if (config.componentType === FieldComponentTypes.buttonSwitch) {
            return {
              name,
              config: {
                ...config,
                layout: Layouts.horizontal,
                labelValue: customLabel
              },
              labelClassName: classes.standardLabel
            };
          }

          if (config.componentType === FieldComponentTypes.datepicker) {
            return {
              name,
              config: {
                ...config,
                labelValue: customLabel
              },
              labelClassName: classes.standardLabel
            };
          }

          if (config.componentType === FieldComponentTypes.countryDropdown) {
            return {
              name,
              config: {
                ...config,
                componentType: FieldComponentTypes.dropdown,
                labelValue: customLabel,
                items: countryDropdownOptions.map(({ key }) => ({
                  activeValue: key,
                  titleKey: `roboAdvice.country.${key.toLowerCase()}`
                }))
              }
            };
          }

          if (config.componentType === FieldComponentTypes.dropdown) {
            return {
              name,
              config: {
                ...config,
                labelValue: customLabel
              },
              labelClassName: classes.standardLabel,
              afterChange: value => {
                config.items?.forEach(({ activeValue, textField }) => {
                  if (!!textField && !value.includes(activeValue)) {
                    clientForm.change(textField?.name as any, null);
                  }
                });
              }
            };
          }

          return {
            name,
            config: {
              ...config,
              labelValue: customLabel
            }
          };
        }
      );
  }, [
    company,
    isClientTypeFieldDisabled,
    person,
    touched,
    values.clientInformation?.clientType,
    values.clientInformation?.companyName,
    values.clientInformation?.name,
    values.clientInformation?.country
  ]);

  return fields;
};
