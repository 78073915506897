import { useState } from 'react';

import CashflowCharts from './cashflowCharts';
import CashflowTable from './table';
import ButtonGroup from 'features/shared/components/buttonGroup';
import LoadingIndicator from 'features/shared/components/loadingIndicator/loadingIndicator';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  buttons: {
    marginBottom: 48
  }
});

const CashflowChartVisual = ({
  cashflowGoalChartsData,
  cashflowForecastChartData,
  cashflowTableData,
  cultureCode,
  isLoading = false,
  isError = false
}) => {
  const i18n = useI18n();
  const classes = useStyles();
  const theme = useTheme();
  const [chartIndex, setChartIndex] = useState(0);

  if (isLoading) {
    return <LoadingIndicator color={theme.accentColor} />;
  }

  if (isError) {
    return (
      <div className={classes.noDataMessage}>
        {i18n('roboAdvice.proposal.noCashflowData')}
      </div>
    );
  }

  return (
    <>
      <div className={classes.buttons}>
        <ButtonGroup
          items={[
            {
              title: i18n('roboAdvice.proposal.graph'),
              isActive: chartIndex === 0,
              onClick: () => setChartIndex(0)
            },
            {
              title: i18n('roboAdvice.proposal.table'),
              isActive: chartIndex === 1,
              onClick: () => setChartIndex(1)
            }
          ]}
        />
      </div>

      {chartIndex === 0 && (
        <CashflowCharts
          cashflowGoalChartsData={cashflowGoalChartsData}
          cashflowForecastChartData={cashflowForecastChartData}
          cultureCode={cultureCode}
        />
      )}
      {chartIndex === 1 && (
        <CashflowTable
          cashflowTableData={cashflowTableData}
          cultureCode={cultureCode}
        />
      )}
    </>
  );
};

export default CashflowChartVisual;
