export const PortfolioIds = {
  customPortfolio: 'customPortfolio',
  existingPortfolio: 'existingPortfolio',
  modelPortfolio: 'modelPortfolio',
  suggestedPortfolio: 'suggestedPortfolio',
  benchmarkPortfolio: 'benchmarkPortfolio'
} as const;

export const CustomPortfolioAllocationMethods = {
  percentage: 'percentage',
  monthlyDeposit: 'monthlyDeposit',
  firstDeposit: 'firstDeposit'
} as const;
