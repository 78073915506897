import * as R from 'ramda';

import { types } from './actions.js';

const reducer = (state = { entities: {}, ids: [] }, action) => {
  switch (action.type) {
    case types.SHOW_NOTIFICATION: {
      const { notification } = action.payload;

      return {
        entities: R.assoc(notification.id, notification, state.entities),
        ids: R.append(notification.id, state.ids)
      };
    }
    case types.SHOW_NOTIFICATIONS: {
      const { notifications } = action.payload;

      return {
        entities: R.pipe(
          R.indexBy(R.prop('id')),
          R.merge(state.entities)
        )(notifications),
        ids: R.pipe(R.map(R.prop('id')), R.concat(state.ids))(notifications)
      };
    }
    case types.HIDE_NOTIFICATION: {
      const { id: notificationId } = action.payload;

      return {
        entities: R.dissoc(notificationId, state.entities),
        ids: R.reject(id => id === notificationId, state.ids)
      };
    }
    case types.HIDE_NOTIFICATIONS: {
      const { ids } = action.payload;

      return {
        entities: R.omit(ids, state.entities),
        ids: R.without(ids, state.ids)
      };
    }
    default:
      return state;
  }
};

export default reducer;
