import { ValidationErrors } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { isValidPhoneNumber } from 'react-phone-number-input';

import {
  RemindOrResendValue,
  RemindOrResendValues
} from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/constants';
import { isValidEmail } from 'features/shared/utils/email';
import {
  createForm,
  FormApi
} from 'features/sharedModules/finalForm/services/finalForm';
import {
  createUseFinalFormAdapter,
  FormState,
  UseFinalFormStore
} from 'features/sharedModules/zustand/components/finalFormAdapter';

export type PreMeetingSurveyFormValues = {
  isSelfAssessment: boolean;
  selfServiceFlow: string;
  isEmailToCustomerEnabled: boolean;
  isSmsToCustomerEnabled: boolean;
  emailContent: string;
  smsContent: string;
  doUseContactInformationFromInvestorCard: boolean;
  investorPhoneNumber: string;
  investorEmail?: string;
  remindOrResend?: RemindOrResendValue;
};

export type PreMeetingSurveyFormState = FormState<PreMeetingSurveyFormValues>;

export type PreMeetingSurveyForm = FormApi<PreMeetingSurveyFormValues>;

export const preMeetingSurveyForm = createForm<PreMeetingSurveyFormValues>({
  initialValues: {
    isSelfAssessment: false,
    selfServiceFlow: '',
    isEmailToCustomerEnabled: false,
    isSmsToCustomerEnabled: false,
    emailContent: '',
    smsContent: '',
    doUseContactInformationFromInvestorCard: false,
    investorPhoneNumber: '',
    investorEmail: '',
    remindOrResend: undefined
  },
  onSubmit: () => {},
  mutators: {
    ...arrayMutators
  },
  validate: values => {
    let errors: ValidationErrors = {};

    if (!values.investorPhoneNumber && !values.remindOrResend) {
      errors.investorPhoneNumber =
        'roboAdvice.client.preMeetingSurvey.contactInformation.phoneNumberRequiredError';
    }

    if (values.isEmailToCustomerEnabled && !values.investorEmail) {
      errors.investorEmail =
        'roboAdvice.client.preMeetingSurvey.contactInformation.emailRequiredError';
    }

    if (values.isEmailToCustomerEnabled && !values.emailContent) {
      errors.emailContent =
        'roboAdvice.client.preMeetingSurvey.contactInformation.contentRequiredError';
    }

    if (values.isSmsToCustomerEnabled && !values.smsContent) {
      errors.smsContent =
        'roboAdvice.client.preMeetingSurvey.contactInformation.contentRequiredError';
    }

    if (
      values.investorPhoneNumber &&
      !isValidPhoneNumber(values.investorPhoneNumber)
    ) {
      errors.investorPhoneNumber =
        'roboAdvice.client.preMeetingSurvey.contactInformation.phoneNumberInvalidError';
    }

    if (
      values.remindOrResend !== RemindOrResendValues.resend &&
      values.investorEmail &&
      !isValidEmail(values.investorEmail)
    ) {
      errors.investorEmail =
        'roboAdvice.client.preMeetingSurvey.contactInformation.emailInvalidError';
    }

    if (
      values.remindOrResend !== RemindOrResendValues.resend &&
      !values.isEmailToCustomerEnabled &&
      !values.isSmsToCustomerEnabled
    ) {
      errors.shareAssessmentLink =
        'roboAdvice.client.preMeetingSurvey.contactInformation.notificationRequiredError';
    }

    return errors;
  }
});

export type UsePreMeetingSurveyForm =
  UseFinalFormStore<PreMeetingSurveyFormValues>;

export const usePreMeetingSurveyForm = createUseFinalFormAdapter({
  form: preMeetingSurveyForm,
  formSubscription: {
    values: true,
    errors: true,
    touched: true,
    pristine: true
  }
});
