import { CreateInvestorRequestData } from '../types';
import { ClientFormValues } from './clientForm';
import { omitNilProps } from 'features/shared/utils/omitNilProps';

export const createInvestorPayload = (
  values: ClientFormValues,
  advisorId: string
): CreateInvestorRequestData =>
  omitNilProps({
    additionalData: omitNilProps(values.clientInformation?.additionalData),
    address: values.clientInformation?.address,
    city: values.clientInformation?.city,
    name:
      values.clientInformation?.companyName || values.clientInformation?.name!,
    contacts: values.clientInformation?.contacts,
    country: values.clientInformation?.country,
    organizationNumber: values.clientInformation?.orgNumber?.toString(),
    investorType: values.clientInformation?.clientType,
    email: values.clientInformation?.email,
    phone: values.clientInformation?.phoneNumber,
    ssn: values.clientInformation?.ssn,
    zipCode: values.clientInformation?.zipCode,
    advisorId
  });
