import axios, { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { createSessionGoal } from '../../shared/api';
import { useGoalsStore } from '../../shared/services/goalsStore';
import { mapServerSessionGoalToClient } from '../../shared/services/mapping';
import { goalForm } from '../services/goalForm';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export function useCreateGoal() {
  const i18n = useI18n();
  const dispatch = useDispatch();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const { adviceSessionId } = useParams<{ adviceSessionId: string }>();

  const createGoal = async () => {
    const goalsStore = useGoalsStore.getState();

    try {
      goalsStore.setIsGoalPending(true);
      const accessToken = await getQAuthAccessToken(auth0AccessToken);

      const response = await createSessionGoal(
        accessToken,
        adviceSessionId,
        goalForm.getState().values
      );

      const newGoal = {
        ...mapServerSessionGoalToClient(response.data.data),
        isGoalSelected: true
      };

      goalsStore.addGoal(newGoal);

      goalsStore.setIsGoalPending(false);

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n(
            'roboAdvice.adviceSession.purposeAndRisk.saveGoalSuccessMessage'
          ),
          type: NotificationTypes.success
        })
      );
    } catch (error: unknown | AxiosError) {
      if (!axios.isCancel(error)) {
        goalsStore.setIsGoalPending(false);

        let errorMessage =
          'roboAdvice.adviceSession.purposeAndRisk.createGoalErrorMessage';
        if (axios.isAxiosError(error) && error?.response?.status === 403) {
          errorMessage = 'roboAdvice.saveAdviceSessionDataErrorNotPermitted';
        }

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(errorMessage),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return createGoal;
}
