import React from 'react';

import { CardsList } from '../../form/components/finalFormCardsList.js';
import { useKnowledgeAndExperienceStore } from '../../knowledgeAndExperience/services/knowledgeAndExperienceStore';
import { useIsSessionReadOnly } from '../../main/services/selectors';
import { useUpdateGoal } from '../../purposeAndRisk/components/useUpdateGoal';
import { useSessionStore } from '../../session/services/sessionStore';
import { useGoalsStore } from '../../shared/services/goalsStore';
import { usePageStore } from '../services/pageStore';
import { themesIcons } from './themesIcons';
import { processImgURL } from 'features/shared/utils/processImgURL';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  root: {
    width: '740px'
  },
  imageContainer: {
    width: '164px',
    height: '58px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const Themes = ({
  goalId,
  themes,
  sectionHeaderClass,
  sectionSubheaderClass
}) => {
  const i18n = useI18n();
  const classes = useStyles();
  const pageStore = usePageStore();
  const updateGoal = useUpdateGoal();
  const { goals } = useGoalsStore.getState();
  const isSessionReadOnly = useIsSessionReadOnly();

  const {
    themes: customerConfigThemes = [],
    roboAdviceForm: {
      knowledgeAndExperience: {
        advancedSuitability: { enabled: isAdvancedSuitabilityEnabled }
      }
    }
  } = useCustomerConfig();
  const { advancedSuitabilityStatus } = useKnowledgeAndExperienceStore();

  const pageStoreThemes =
    pageStore.goalThemes && pageStore.goalThemes[goalId]
      ? pageStore.goalThemes[goalId]
      : [];

  const setImageComponent = t => {
    const theme = customerConfigThemes.find(({ id }) => id === t.id);
    return theme?.iconUrl ? (
      /*
       * backgroundImage is used instead of img tag because of the default behavior of the 'react-responsive-carousel' library.
       * If the img tag is present in the carousel body, the library will try to render carousel's height as image height,
       * so as a workaround we are using the div with backgroundImage styling to render correct height.
       *
       * Line from library causes the issue: https://github.com/leandrowd/react-responsive-carousel/blob/b8762d6627819fefbaa2c56fa3fcb8c614bcc4f0/src/components/Carousel/index.tsx#L606
       */
      <div
        aria-label={theme?.title}
        style={{
          height: '100%',
          width: '100%',
          backgroundImage: `url(${processImgURL(theme.iconUrl)})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center'
        }}
      />
    ) : (
      themesIcons[theme?.icon]
    );
  };

  const cards = React.useMemo(() => {
    const selectedGoal = goals.find(g => g.goalId === goalId);
    const { defaultConfigNamespaceId } = useSessionStore.getState();

    return pageStoreThemes.map(theme => ({
      id: theme.id,
      imageComponent: setImageComponent(theme),
      title: i18n(
        customerConfigThemes.find(({ id }) => id === theme.id)?.title
      ),
      disabled:
        isAdvancedSuitabilityEnabled &&
        !advancedSuitabilityStatus[
          selectedGoal.data.productPlatformNamespace || defaultConfigNamespaceId
        ]?.find(
          ({ id }) => id === pageStore.goalCategoriesSelection[goalId][theme.id]
        )?.productIsSuitable
    }));
  }, [pageStoreThemes, goals, goalId, advancedSuitabilityStatus]);

  return cards.length > 0 ? (
    <div className={classes.root} data-testid={'themes-form'}>
      <div className={sectionHeaderClass}>
        {i18n('roboAdvice.advisory.themes')}
      </div>

      <div className={sectionSubheaderClass}>
        {i18n('roboAdvice.advisory.selectYourThemes')}
      </div>

      <CardsList
        value={themes}
        onChange={v => updateGoal(goalId, { data: { themes: v } })}
        imageContainerClassName={classes.imageContainer}
        isMultiselect
        cards={cards}
        disabled={isSessionReadOnly}
      />
    </div>
  ) : null;
};

export default Themes;
