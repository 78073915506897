import { isNil, mergeDeepRight } from 'ramda';
import create from 'zustand';

import { CustomPortfolioAllocationMethod } from '../../advisory/types';
import { riskScoreToNumber } from '../../riskScore/services/shared';
import { WithdrawalPlans } from './../constants';

export type ProductPlatformQuestions = {
  question1?: boolean;
  question2?: boolean;
  question3?: boolean;
  question4?: boolean;
  question5?: boolean;
};

export type Instrument = {
  id: string;
  isin: string;
  title: string;
  weight?: number;
};

export type CustomPortfolioItem = {
  id: string;
  title: string;
  mainAssetClass: string;
  subAssetClass: string;
  CategoryId: string;
  CategoryIds: { id: string; weight: number }[];
  weight?: number;
  instruments: Instrument[];
};

export type GoalData = {
  timeHorizon: number;
  firstDeposit?: number;
  monthlyDeposit?: number;
  internalHolding?: number;
  portfolio?: string;
  customPortfolio?: CustomPortfolioItem[] | null;
  riskTemplate?: string | null;
  isPortfolioCustom?: boolean;
  themes?: string[];
  withdrawalPlan?: typeof WithdrawalPlans[keyof typeof WithdrawalPlans];
  monthlyWithdrawal?: number;
  capitalNeed?: number;
  withdrawalStart?: number;
  withdrawalEnd?: number;
  timeHorizonToDisplay?: number;
  productPlatformNamespace?: number | null;
  productPlatformReasoning?: string;
  productPlatformQuestions?: ProductPlatformQuestions;
  type?: string | null;
  purposeAndRiskScore?: string | null;
  riskScore?: string | null;
  isCustomPortfolioDirty?: boolean;
  shouldDisplayAlertInAssetAllocation?: boolean;
  shouldDisplayAlertInFundAllocation?: boolean;
  shouldRefreshCustomPortfolio?: boolean;
  oldRiskScore?: string | null;
  customPortfolioAllocationMethod?: CustomPortfolioAllocationMethod | null;
  followUpWithdrawal?: number;
  followUpAddOn?: number;
  shouldDisplayFollowUpMissingInstrumentsAlert?: boolean;
  isFollowUpModelPortfolioChangedChecked?: boolean;
  shouldDisplayFollowUpModelPortfolioRiskChangedAlert?: boolean;
  shouldDisplayFollowUpModelPortfolioInstrumentsChangedAlert?: boolean;
};

export type Goal = {
  goalId: string;
  name: string;
  sessionId: string;
  userId: number;
  description?: string;
  icon?: string;
  isGoalSelected: boolean;
  expectedValue?: number;
  data: Partial<GoalData>;
};

export type BackendGoal = {
  data: Record<string, unknown>;
  description: string | null;
  goal_id: string;
  icon: string | null;
  name: string;
  session_id: string;
  user_id: number;
};

export type GoalsStoreData = {
  goals: Goal[];
  isGoalPending: boolean;
};

export type GoalsStoreApi = {
  reset: () => void;
  setGoals: (goals: Goal[]) => void;
  addGoal: (goal: Goal) => void;
  editGoal: (goalId: string, data: Partial<Goal>) => void;
  setIsGoalPending: (isGoalPending: boolean) => void;
  removeGoal: (goalId: string) => void;
  setGoalStatus: (goalId: string, status: boolean) => void;
  setGoalExpectedValue: (expectedValue: number, goalId: string) => void;
  setGoalRiskScore: (
    riskScore: { purposeAndRiskScore: string | null; riskScore: string | null },
    goalId: string
  ) => void;
  getGoalRiskScore: (goalId: string) => string | null;
  getGoalPortfolioRiskScore: (goalId: string) => string | null;
};

export type GoalsStoreState = GoalsStoreData & GoalsStoreApi;

const defaultData = {
  goals: [],
  isGoalPending: false
};

export const useGoalsStore = create<GoalsStoreState>((set, get) => ({
  ...defaultData,
  reset() {
    set(defaultData);
  },
  setGoals(goals) {
    set({ goals });
  },
  addGoal(goal) {
    set(state => ({ goals: [...state.goals, goal] }));
  },
  editGoal(goalId: string, newGoalData: Partial<Goal>) {
    set(state => {
      const newGoals = [...state.goals];
      const indexToRemove = newGoals.findIndex(g => g.goalId === goalId);
      if (indexToRemove !== -1) {
        const oldGoal = newGoals[indexToRemove];

        const mergedGoalData = mergeDeepRight(oldGoal, newGoalData);
        newGoals.splice(indexToRemove, 1, mergedGoalData as Goal);
      }

      return { goals: newGoals };
    });
  },
  setIsGoalPending(isGoalPending) {
    set({ isGoalPending: isGoalPending });
  },
  removeGoal(goalId: string) {
    set(state => {
      return { goals: state.goals.filter(goal => goal.goalId !== goalId) };
    });
  },
  setGoalStatus(goalId: string, status: boolean) {
    set(state => {
      const newGoals = state.goals.map(goal => {
        if (goal.goalId === goalId) {
          goal.isGoalSelected = status;
        }
        return goal;
      });
      return { goals: newGoals };
    });
  },
  setGoalExpectedValue(expectedValue, goalId) {
    set(state => ({
      goals: state.goals.map(goal =>
        goal.goalId === goalId ? { ...goal, expectedValue } : goal
      )
    }));
  },
  setGoalRiskScore(riskScore, goalId) {
    set(state => ({
      goals: state.goals.map(goal =>
        goal.goalId === goalId
          ? { ...goal, data: { ...goal.data, ...riskScore } }
          : goal
      )
    }));
  },
  getGoalRiskScore(goalId: string) {
    return get().goals.find(g => goalId === g.goalId)?.data?.riskScore || null;
  },
  getGoalPortfolioRiskScore(goalId: string) {
    return get().goals.find(g => goalId === g.goalId)?.data?.portfolio || null;
  }
}));

export const selectors = {
  getSelectedGoalsIds: (state: GoalsStoreState) =>
    state.goals
      .filter(({ isGoalSelected }) => isGoalSelected)
      .map(({ goalId }) => goalId),
  getSelectedGoalsNames: (state: GoalsStoreState) =>
    state.goals.map(({ name }) => name),
  getTotalExpectedValue: (state: GoalsStoreState) =>
    state.goals.reduce((acc, current) => acc + (current.expectedValue || 0), 0),
  getTotalFirstDeposit: (state: GoalsStoreState) =>
    state.goals.reduce(
      (acc, current) => acc + (current.data.firstDeposit || 0),
      0
    ),
  getTotalMonthlyDeposit: (state: GoalsStoreState) =>
    state.goals.reduce(
      (acc, current) => acc + (current.data.monthlyDeposit || 0),
      0
    ),
  getTotalInternalHolding: (state: GoalsStoreState) =>
    state.goals.reduce(
      (acc, current) => acc + (current.data.internalHolding || 0),
      0
    ),
  getTotalFollowUpAddOn: (state: GoalsStoreState) =>
    state.goals.reduce(
      (acc, current) => acc + (current.data.followUpAddOn || 0),
      0
    ),
  getSelectedGoalsTotalFirstDeposit: (state: GoalsStoreState) =>
    state.goals
      .filter(({ isGoalSelected }) => isGoalSelected)
      .reduce((acc, current) => acc + (current.data.firstDeposit || 0), 0),
  getSelectedGoalsTotalMonthlyDeposit: (state: GoalsStoreState) =>
    state.goals
      .filter(({ isGoalSelected }) => isGoalSelected)
      .reduce((acc, current) => acc + (current.data.monthlyDeposit || 0), 0),
  getInitialRiskScore: (state: GoalsStoreState) => {
    if (!state.goals.length) return null;

    const initialRiskScores = state.goals.map(
      ({ data: { purposeAndRiskScore } }) =>
        riskScoreToNumber(purposeAndRiskScore) || 0
    );

    return calculateRiskScore(initialRiskScores);
  },
  getFinalRiskScore: (state: GoalsStoreState) => {
    if (!state.goals.length) return null;

    const finalRiskScores = state.goals.map(
      ({ data: { riskScore } }) => riskScoreToNumber(riskScore) || 0
    );

    return calculateRiskScore(finalRiskScores);
  },
  getAdjustmentScore: (state: GoalsStoreState) => {
    if (
      !state.goals.length ||
      isNil(state.goals[0].data?.purposeAndRiskScore) ||
      isNil(state.goals[0].data?.riskScore)
    )
      return null;

    const riskScores = state.goals.map(
      ({ data: { purposeAndRiskScore, riskScore } }) =>
        riskScoreToNumber(riskScore)! - riskScoreToNumber(purposeAndRiskScore)!
    );
    const minRisk = Math.min(...riskScores);
    const maxRisk = Math.max(...riskScores);

    return { minRisk, maxRisk };
  }
};

const calculateRiskScore = (riskScores: number[]) => {
  const minRisk = Math.min(...riskScores);
  const maxRisk = Math.max(...riskScores);

  if (minRisk === 0) {
    return null;
  }

  if (minRisk === maxRisk) {
    return minRisk;
  }

  return `${minRisk} - ${maxRisk}`;
};
