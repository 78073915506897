import {
  createUseExpectedValuePortfoliosChartStore,
  calculatorSimpleMapping
} from '@quantfoliorepo/ui-components';
import axios, { CancelTokenSource } from 'axios';
import * as R from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UseBoundStore } from 'zustand';

import { PortfolioIds } from '../../advisory/services/constants';
import { useExistingPortfolio } from '../../proposal/services/selectors';
import { useSessionStore } from '../../session/services/sessionStore';
import { PageStatuses } from '../../shared/components/useReadDataListener';
import { usePageStore as useRiskScorePageStore } from '../services/pageStore';
import { usePortfolioChartStore } from './useReadPortfolioChartData';
import {
  createRiskReturn,
  readCalculatorSimple
} from 'features/roboAdvice/adviceSession/shared/api';
import { getCurrentDate } from 'features/roboAdvice/shared/utils';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { useTheme } from 'features/sharedModules/styles/components/styles';

export const useExpectedValuePortfoliosChartStore: UseBoundStore<any> =
  createUseExpectedValuePortfoliosChartStore();

export function useReadExpectedValuePortfoliosChartData(
  selectedRisks,
  compareWithExistingPortfolio: boolean
) {
  const i18n = useI18n();
  const theme = useTheme();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();
  const portfolioChartStore = usePortfolioChartStore();
  const {
    analyticsComponents: {
      expectedValue: { bankReturn }
    }
  } = useCustomerConfig();
  const existingPortfolioData = useExistingPortfolio();
  const { categories } = useSessionStore();

  const cancelTokenSourceRef = React.useRef<CancelTokenSource>();
  const readExpectedValuePortfoliosChartData = async () => {
    const { chartData } = usePortfolioChartStore.getState();
    if (R.isNil(chartData) || chartData.length !== selectedRisks.length) {
      return;
    }
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const riskScorePageStore = useRiskScorePageStore.getState();
    const expectedValuePortfoliosChartStore =
      useExpectedValuePortfoliosChartStore.getState();

    try {
      riskScorePageStore.setPageStatus(
        'readExpectedValuePortfoliosChartData',
        PageStatuses.pending
      );

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const currentDate = getCurrentDate();

      expectedValuePortfoliosChartStore.reset();

      const timeHorizon = 10;
      const initialDeposit = 100_000;
      const monthlyDeposit = 0;
      const portfolios: any = [];

      for (const selectedRisk of selectedRisks) {
        const riskChartData = portfolioChartStore.getChartData(
          selectedRisk.risk,
          false
        );

        const analyzeRiskReturn = {
          risk: riskChartData.expectedVolatility,
          return: riskChartData.expectedAnnualReturn
        };

        const readCalculatorSimpleResponse = await readCalculatorSimple(
          qAuthAccessToken,
          cancelTokenSource.token,
          {
            expected_return: calculatorSimpleMapping.getReturn({
              dataSources: {
                analyzeRiskReturn
              }
            }),
            expected_return_bank: bankReturn,
            initial_deposit: initialDeposit,
            monthly_deposit: monthlyDeposit,
            time_horizon: timeHorizon
          }
        );

        const forecast = readCalculatorSimpleResponse.data.forecast;

        portfolios.push({
          forecast,
          currentDate,
          timeHorizon,
          firstDeposit: initialDeposit,
          goalId: selectedRisk.risk,
          goalName: selectedRisk.name,
          color: selectedRisk.color
        });
      }

      if (compareWithExistingPortfolio) {
        const existingPortfolio = existingPortfolioData?.[1].map(
          ({ name, weight }) => ({
            category_id: categories.find(
              ({ SubAssetClass }) => SubAssetClass === name
            )?.CategoryId,
            weight
          })
        );

        const createRiskReturnResponse = await createRiskReturn(
          qAuthAccessToken,
          cancelTokenSource.token,
          {
            portfolio: existingPortfolio
          }
        );

        const readCalculatorSimpleResponse = await readCalculatorSimple(
          qAuthAccessToken,
          cancelTokenSource.token,
          {
            expected_return: calculatorSimpleMapping.getReturn({
              dataSources: {
                analyzeRiskReturn: {
                  risk: createRiskReturnResponse.data.risk,
                  return: createRiskReturnResponse.data.return
                }
              }
            }),
            expected_return_bank: bankReturn,
            initial_deposit: initialDeposit,
            monthly_deposit: monthlyDeposit,
            time_horizon: timeHorizon
          }
        );

        portfolios.push({
          forecast: readCalculatorSimpleResponse.data.forecast,
          currentDate,
          timeHorizon,
          firstDeposit: initialDeposit,
          goalId: PortfolioIds.existingPortfolio,
          goalName: i18n('roboAdvice.advisory.portfolio.existingPortfolio'),
          color: theme.chartPortfolioColors[3]
        });
      }

      expectedValuePortfoliosChartStore.setChartData(portfolios);

      riskScorePageStore.setPageStatus(
        'readExpectedValuePortfoliosChartData',
        PageStatuses.succeed
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        expectedValuePortfoliosChartStore.reset();
        riskScorePageStore.setPageStatus(
          'readExpectedValuePortfoliosChartData',
          PageStatuses.failed
        );

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.proposal.readDataErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readExpectedValuePortfoliosChartData;
}
