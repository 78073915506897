import { HistoricalReturnTimeRangeChartData } from '@quantfoliorepo/ui-components';

import { HistoricalReturnStatsItem } from 'defaults/defaultAnalyticsComponents';
import { getTranslation } from 'features/shared/utils/translations';

type HistoricalReturnStatisticsData = {
  names: string[];
  data: { id: string; label: string; values: (number | null)[] }[];
};

export const getHistoricalReturnStatisticsData = (
  data: HistoricalReturnTimeRangeChartData[] | undefined,
  historicalReturnStatsItems: HistoricalReturnStatsItem[],
  numberOfAllowedStatistics: number
) => {
  return data
    ?.map(({ goalName, portfolioStatistics }) => ({
      name: goalName,
      data: historicalReturnStatsItems
        .filter(({ enabled }) => enabled)
        .sort((a, b) => a.order - b.order)
        .slice(0, numberOfAllowedStatistics)
        .map(({ id, label }) => ({
          id: id,
          label: getTranslation(label),
          values: portfolioStatistics?.[id] ? [portfolioStatistics[id]] : []
        }))
    }))
    ?.reduce(
      (acc, curr) => {
        return {
          names: [...acc.names, curr.name],
          data: [...acc.data, ...curr.data].reduce((acc, curr) => {
            const index = acc.findIndex(({ id }) => id === curr.id);

            const values = curr.values.length === 0 ? [null] : curr.values;
            if (index === -1) {
              return [...acc, { ...curr, values }];
            }

            return acc.map((item, i) =>
              i === index
                ? { ...item, values: [...item.values, ...values] }
                : item
            );
          }, [] as HistoricalReturnStatisticsData['data'])
        };
      },
      { names: [], data: [] } as HistoricalReturnStatisticsData
    );
};
