import React from 'react';

import { useClientForm, clientForm } from '../../main/services/clientForm';
import SignatureRightInfo from './eninElements/signatureRight/signatureRightInfo';
import { useCountryDropdownOptions } from 'features/roboAdvice/shared/components/useCountryDropdownOptions';
import ErrorMessage from 'features/shared/components/errorMessage';
import Icon from 'features/shared/components/icon/index';
import Table from 'features/shared/components/table/table.js';
import TableBody from 'features/shared/components/table/tableBody.js';
import TableBodyRow from 'features/shared/components/table/tableBodyRow.js';
import { LayoutBreakpoints } from 'features/shared/constants/layoutBreakpoints';
import { Spacing } from 'features/shared/constants/spacing';
import { defaultConfirmation } from 'features/sharedInstances/defaultConfirmation';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import FinalFormDropdown from 'features/sharedModules/finalForm/components/dropdown.js';
import FinalFormTextInput from 'features/sharedModules/finalForm/components/textInput';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const useStyles = createUseStyles({
  container: {
    marginTop: Spacing.spacing02
  },
  header: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '23px',
    marginBottom: Spacing.spacing01
  },
  deleteIcon: {
    padding: 0,
    fontSize: '2rem',
    lineHeight: '2.4rem',
    cursor: 'pointer',
    transform: 'rotate(0.03deg)' // fix of issue: icon small size causes pixelated icon
  },
  countryDropdown: {
    width: '180px'
  },
  identityTypeColumn: {
    minWidth: '90px'
  },
  deleteIconColumn: {
    minWidth: '52px'
  },
  [`@media (max-width: ${LayoutBreakpoints.tabletMin}px)`]: {
    countryDropdown: {
      width: '150px'
    }
  }
});

const SignatureRightTable = ({ inputClassName }) => {
  const classes = useStyles();
  const i18n = useI18n();
  const { values, errors, touched } = useClientForm();
  const { open: openConfirmation } =
    defaultConfirmation.useConfirmationStore.getState();
  const countryDropdownOptions = useCountryDropdownOptions();

  const {
    roboAdvice: {
      kyc: {
        company: { enin }
      }
    }
  } = useCustomerConfig();

  const showError =
    errors.kyc?.signatureRightTable && touched['kyc.signatureRight[0].email'];

  const headers = [
    {
      title: i18n('roboAdvice.clientInformation.contacts.name')
    },
    {
      title: i18n('roboAdvice.kyc.identityType'),
      className: classes.identityTypeColumn
    },
    { title: i18n('roboAdvice.kyc.identity') },
    { title: i18n('roboAdvice.kyc.country') },
    { title: i18n('roboAdvice.clientInformation.contacts.email') },
    { title: '', className: classes.deleteIconColumn }
  ];

  const contactsWithSignRights = React.useMemo(() => {
    return values.clientInformation?.contacts
      ? values.clientInformation.contacts
          .map((contact, index) => ({ ...contact, index }))
          .filter(({ signRight }) => signRight)
      : [];
  }, [values.clientInformation]);

  const rows = React.useMemo(() => {
    const signatureRight = values.kyc?.signatureRight;
    const newRow = { name: '', identity: '', country: '', email: '' };

    if (
      signatureRight &&
      signatureRight.length &&
      signatureRight.length < 10 &&
      Object.values(signatureRight[signatureRight.length - 1]).some(
        value => !!value
      )
    ) {
      return [...values.kyc.signatureRight, newRow];
    }

    return signatureRight ? signatureRight : [newRow];
  }, [values.kyc?.signatureRight]);

  const deleteRow = index => {
    clientForm.change(
      'kyc.signatureRight',
      values.kyc.signatureRight.length === 1
        ? [{}]
        : values.kyc.signatureRight.filter((_, i) => i !== index)
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {i18n('roboAdvice.kyc.signatureRight')}
      </div>

      <Table header={headers}>
        <TableBody>
          {contactsWithSignRights.length > 0 &&
            contactsWithSignRights.map(({ name, email, index }) => (
              <TableBodyRow key={email}>
                {({ bodyRowCellClassName }) => (
                  <>
                    <td className={bodyRowCellClassName}>{name}</td>
                    <td className={bodyRowCellClassName}>
                      {i18n('roboAdvice.kyc.ssn')}
                    </td>
                    <td className={bodyRowCellClassName}>
                      <FinalFormTextInput
                        className={inputClassName}
                        name={`clientInformation.contacts[${index}].identity`}
                        placeholder={i18n('roboAdvice.kyc.identity')}
                      />
                    </td>
                    <td className={bodyRowCellClassName}>
                      <FinalFormDropdown
                        className={inputClassName}
                        name={`clientInformation.contacts[${index}].country`}
                        options={countryDropdownOptions}
                        inputProps={{
                          placeholder: i18n('roboAdvice.kyc.countryPlaceholder')
                        }}
                      />
                    </td>
                    <td className={bodyRowCellClassName}>{email}</td>
                    <td className={bodyRowCellClassName}></td>
                  </>
                )}
              </TableBodyRow>
            ))}
          {rows.map((_, index) => (
            <TableBodyRow key={index}>
              {({ bodyRowCellClassName }) => (
                <>
                  <td className={bodyRowCellClassName}>
                    <FinalFormTextInput
                      className={inputClassName}
                      name={`kyc.signatureRight[${index}].name`}
                      placeholder={i18n('shared.name')}
                    />
                  </td>
                  <td className={bodyRowCellClassName}>
                    {i18n('roboAdvice.kyc.ssn')}
                  </td>
                  <td className={bodyRowCellClassName}>
                    <FinalFormTextInput
                      className={inputClassName}
                      name={`kyc.signatureRight[${index}].identity`}
                      placeholder={i18n('roboAdvice.kyc.identity')}
                    />
                  </td>
                  <td className={bodyRowCellClassName}>
                    <FinalFormDropdown
                      className={classes.countryDropdown}
                      name={`kyc.signatureRight[${index}].country`}
                      options={countryDropdownOptions}
                      inputProps={{
                        placeholder: i18n('roboAdvice.kyc.countryPlaceholder')
                      }}
                    />
                  </td>
                  <td className={bodyRowCellClassName}>
                    <FinalFormTextInput
                      className={inputClassName}
                      name={`kyc.signatureRight[${index}].email`}
                      placeholder={i18n('shared.email')}
                    />
                  </td>
                  <td className={bodyRowCellClassName}>
                    {index < rows.length - 1 && (
                      <Icon
                        className={classes.deleteIcon}
                        type="delete"
                        onClick={() =>
                          openConfirmation(
                            i18n(
                              'roboAdvice.kyc.signatureRight.deleteConfirmationMessage'
                            ),
                            () => deleteRow(index)
                          )
                        }
                      />
                    )}
                  </td>
                </>
              )}
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
      {enin.signatureRight.isEnabled && <SignatureRightInfo />}

      {showError && (
        <ErrorMessage>* {i18n(errors.kyc.signatureRightTable)}</ErrorMessage>
      )}
    </div>
  );
};

export default SignatureRightTable;
