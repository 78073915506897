import React from 'react';
import { Theme } from 'defaults/defaultTheme';
import Highcharts, { SeriesBarOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import {
  FontFamilies,
  FontWeights,
  TextFontSizes
} from 'modules/shared/constants/fonts';
import { formatNumber } from 'modules/shared/utils/number';
import { createUseStyles, useTheme } from 'modules/styles/components/styles';
import { useI18n } from 'modules/settings/components/settings';

export type ChartData = {
  name: string;
  data: [number, number];
};

type Props = {
  chartData: ChartData[];
  cultureCode: string;
  height: number;
};

const useStyles = createUseStyles(theme => ({
  totalValuesContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 64,
    fontWeight: FontWeights.medium,
    lineHeight: '19px',
    paddingTop: 15,

    '& span': {
      color: theme.secondaryColor
    }
  }
}));

const FinancialSituationAggregatedChart = ({
  chartData,
  cultureCode,
  height
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const theme: Theme = useTheme();

  const options = {
    chart: {
      type: 'bar',
      backgroundColor: 'transparent',
      height: height
    },
    colors: theme.chartPortfolioColors,
    credits: { enabled: false },
    exporting: { enabled: false },
    legend: {
      align: 'right' as const,
      backgroundColor: theme.itemBackgroundColor_2,
      borderRadius: 4,
      itemHoverStyle: { color: theme.primaryColor },
      itemMarginBottom: 8,
      itemMarginTop: 8,
      itemWidth: 150,
      itemStyle: {
        color: theme.primaryColor,
        cursor: 'default' as const,
        fontSize: TextFontSizes.xSmall,
        fontWeight: 'normal',
        fontFamily: FontFamilies.roboto
      },
      layout: 'vertical' as const,
      symbolHeight: 10,
      symbolPadding: 9,
      symbolRadius: 0,
      symbolWidth: 10,
      title: {
        text: i18n('financialSituationAggregatedChart.aggregated'),
        style: {
          color: theme.primaryColor,
          fontSize: TextFontSizes.normal,
          fontWeight: 'normal',
          fontFamily: FontFamilies.roboto
        }
      },
      verticalAlign: 'middle' as const
    },
    plotOptions: {
      bar: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          style: {
            color: theme.primaryColor,
            fontFamily: FontFamilies.roboto,
            fontSize: TextFontSizes.xxSmall,
            fontWeight: FontWeights.normal,
            lineHeight: '12px',
            textOutline: 'none'
          }
        },
        pointWidth: 8
      },
      series: {
        animation: false,
        enableMouseTracking: false,
        events: {
          legendItemClick: () => false
        },
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },
    series: chartData.map<SeriesBarOptions>(data => ({
      ...data,
      type: 'bar'
    })),
    title: { text: undefined },
    tooltip: {
      enabled: false
    },
    xAxis: {
      categories: [
        i18n('financialSituationAggregatedChart.totalAssets'),
        i18n('financialSituationAggregatedChart.totalDebt')
      ],
      labels: {
        style: {
          color: theme.secondaryColor,
          fontSize: TextFontSizes.medium,
          lineHeight: '18.75px',
          fontFamily: FontFamilies.roboto
        }
      }
    },
    yAxis: {
      labels: { enabled: false },
      title: { text: undefined },
      gridLineColor: '#ffffff20',
      gridLineDashStyle: 'Dash' as const,
      tickAmount: 6
    }
  };

  const totalAssets = React.useMemo(() => {
    const totalAssets = chartData.reduce((acc, curr) => acc + curr.data[0], 0);
    return formatNumber(cultureCode, totalAssets, 0, 0);
  }, [chartData, cultureCode]);

  const totalDebt = React.useMemo(() => {
    const totalDebt = chartData.reduce((acc, curr) => acc + curr.data[1], 0);
    return formatNumber(cultureCode, totalDebt, 0, 0);
  }, [chartData, cultureCode]);

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'chart'}
        options={options}
      />

      {chartData.length > 1 && (
        <div className={classes.totalValuesContainer}>
          <div>
            <span>
              {i18n('financialSituationAggregatedChart.totalAssets')}:
            </span>{' '}
            {totalAssets}
          </div>
          <div>
            <span>{i18n('financialSituationAggregatedChart.totalDebt')}:</span>{' '}
            {totalDebt}
          </div>
        </div>
      )}
    </>
  );
};

export default FinancialSituationAggregatedChart;
