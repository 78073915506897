import ErrorPage from '../errorPage';
import { defaultTheme } from 'defaults/defaultTheme';
import { Spacing } from 'features/shared/constants/spacing';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  body: {
    margin: 0,
    padding: 0
  },
  link: {
    '&, &:link, &:visited, &:hover, &:active': {
      textDecoration: 'none',
      color: defaultTheme.accentColor,
      cursor: 'pointer'
    }
  },
  backLinkText: {
    margin: `${Spacing.spacing02}px 0 0 0`
  }
});

const RandomErrorPage = () => {
  const classes = useStyles();

  return (
    <ErrorPage isLinkActive={false} header={'Oops!'}>
      <p>
        Something went wrong, try to refresh your page or click{' '}
        <a className={classes.link} href="/">
          here
        </a>
        . If the problem continues to exist, contact your internal system admin.
      </p>
    </ErrorPage>
  );
};

export default RandomErrorPage;
