import MaterialSlider from '@material-ui/core/Slider';
import classNames from 'classnames';
import * as R from 'ramda';

import { createUseStyles } from 'features/sharedModules/styles/components/styles';

let defaultConfig = {
  min: 0,
  max: 100,
  step: 1
};

const useStyles = createUseStyles(theme => ({
  slider: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  root: {
    color: theme.accentColor,
    height: '10px',
    padding: '10px 0',
    margin: '5px 0',

    '&.Mui-disabled': {
      opacity: '0.5',
      color: theme.accentColor,

      '& $track': {
        background: theme.accentColor
      }
    }
  },
  thumb: {
    height: '44px',
    width: '44px',
    backgroundColor: theme.primaryColor,
    marginLeft: '-10px',
    '&:focus, &:hover': {
      boxShadow: 'inherit'
    },
    '&:focus': {
      backgroundColor: theme.accentFocusColor
    },
    '&::after': {
      top: '0',
      bottom: '0',
      right: '0',
      left: '0'
    },
    '&.Mui-disabled': {
      height: '20px',
      width: '20px',
      backgroundColor: theme.primaryColor,
      marginTop: '-5px',
      marginLeft: '-10px'
    }
  },
  track: {
    height: '10px',
    borderRadius: '10px',
    background: theme.accentColor,
    marginTop: '12px'
  },
  rail: {
    opacity: '0.2',
    height: '10px',
    borderRadius: '10px',
    marginTop: '12px'
  },
  disabled: {}
}));

type Props = {
  disabled?: boolean;
  config?: {
    min?: number;
    max?: number;
    step?: number;
  };
  value: number;
  callbackFn: (value: number) => void;
  externalClasses?: ReturnType<typeof useStyles>;
  'data-testid'?: string;
};

const Slider = ({
  disabled,
  config,
  value,
  callbackFn,
  externalClasses,
  ...restProps
}: Props) => {
  let data = config ? config : defaultConfig;

  const internalClasses = useStyles();
  const classes = R.isNil(externalClasses)
    ? internalClasses
    : R.mergeWith(
        (c1, c2) => classNames(c1, c2),
        internalClasses,
        externalClasses
      );

  const handleChange = (event, newValue) => {
    callbackFn(newValue);
  };

  return (
    <div data-testid={restProps['data-testid']} className={classes.slider}>
      <MaterialSlider
        disabled={disabled || R.isNil(data.min) || R.isNil(data.max)}
        classes={{
          root: classes.root,
          thumb: classes.thumb,
          track: classes.track,
          rail: classes.rail,
          disabled: classes.disabled
        }}
        value={value}
        min={data.min}
        max={data.max}
        step={data.step}
        onChange={handleChange}
      />
    </div>
  );
};

export default Slider;
