import formReducer from 'features/main/services/form/reducer.js';
import statusReducer from 'features/main/services/status/reducer';
import tableReducer from 'features/main/services/table/reducer.js';
import roboAdviceAdviceSessionReducer from 'features/roboAdvice/adviceSession/main/services/reducer.js';
import customersReducer from 'features/shared/services/customers/reducer.js';
import notificationReducer from 'features/shared/services/notification/reducer.js';
import routerReducer from 'features/shared/services/router/reducer.js';
import { types as sessionActionTypes } from 'features/shared/services/session/actions.js';
import sessionReducer from 'features/shared/services/session/reducers/index.js';

const resetOnLogout = reducer => (state, action) => {
  if (action.type === sessionActionTypes.LOGOUT) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};

const sessionReducerDecorated = resetOnLogout(sessionReducer);
const formReducerDecorated = resetOnLogout(formReducer);
const tableReducerDecorated = resetOnLogout(tableReducer);
const customersReducerDecorated = resetOnLogout(customersReducer);
const roboAdviceAdviceSessionReducerDecorated = resetOnLogout(
  roboAdviceAdviceSessionReducer
);

export const reducers = (state = {}, action) => {
  return {
    // not sensitive data
    router_state: routerReducer(state.router_state, action),
    notification_state: notificationReducer(state.notification_state, action),
    status_state: statusReducer(state.status_state, action),
    // sensitive data
    form_state: formReducerDecorated(state.form_state, action),
    session_state: sessionReducerDecorated(state.session_state, action),
    table_state: tableReducerDecorated(state.table_state, action),
    customers_state: customersReducerDecorated(state.customers_state, action),
    roboAdvice_state: roboAdviceAdviceSessionReducerDecorated(
      state.roboAdvice_state,
      action
    )
  };
};
