export const types = {
  RUN: 'SAGA_DELAY/RUN',
  RESOLVED: 'SAGA_DELAY/RESOLVED'
};

export const creators = {
  run: (id, msTimeout) => ({
    type: types.RUN,
    payload: { id, msTimeout }
  }),
  resolved: id => ({
    type: types.RESOLVED,
    payload: {
      id
    }
  })
};
