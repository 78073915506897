import queryString from 'query-string';
import * as R from 'ramda';
import { put, call } from 'redux-saga/effects';

import { creators } from '../actions.js';
import {
  mapServerUserToClient,
  mapServerCustomerConfigToClient
} from '../mapping';
import { getAuth0AccessToken, setPublicKey, getPublicKey } from '../storage.js';
import {
  verifyAccessToken,
  parseAccessToken,
  getAuth0PublicKeyFunc,
  getCertFromPublickKey
} from './shared.js';
import { validateCustomerConfig } from './validateCustomerConfig';
import config from 'config/index.js';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import {
  getQAuthAccessToken,
  getUserInfo,
  getCustomerConfig
} from 'features/shared/api/index.js';

export function* initializeSession() {
  try {
    const queryStringParams = queryString.parse(document.location.search);

    let auth0AccessToken = getAuth0AccessToken();
    let publicKey = getPublicKey();

    if (R.isNil(auth0AccessToken)) {
      auth0AccessToken = R.prop('accessToken', queryStringParams);
    }

    if (R.isNil(auth0AccessToken)) {
      yield put(creators.initialize(null, null, null));
      return;
    }

    if (R.isNil(publicKey)) {
      publicKey = yield call(getAuth0PublicKeyFunc);
      setPublicKey(publicKey);
    }

    const cert = getCertFromPublickKey(publicKey, auth0AccessToken);

    const isValid = verifyAccessToken(cert, auth0AccessToken);
    const accessTokenPayload = parseAccessToken(auth0AccessToken);

    // TODO: validation does not work periodically, fix it
    // if (isValid) {

    // This call is used to get user info but also to verify license expiration
    const userInfoData = yield call(
      getUserInfo,
      auth0AccessToken,
      accessTokenPayload[config.AUTH0_ID_KEY]
    );

    const user = mapServerUserToClient(accessTokenPayload, userInfoData.data);

    const accessToken = yield call(
      getQAuthAccessToken,
      auth0AccessToken,
      undefined
    );
    const customerConfig = yield call(
      getCustomerConfig,
      accessToken,
      user.customerCode,
      getUserPageLanguage()
    );
    // }

    const mappedCustomerConfig = mapServerCustomerConfigToClient(
      customerConfig.data
    );

    validateCustomerConfig(mappedCustomerConfig);

    yield put(
      creators.initialize(auth0AccessToken, user, mappedCustomerConfig)
    );
  } catch (e) {
    yield put(creators.initialize(null, null, null));
  }
}
