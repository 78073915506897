import { isEmpty } from 'ramda';
import { useDispatch } from 'react-redux';

import { clientForm } from 'features/roboAdvice/client/main/services/clientForm';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

const useClientMissingFields = () => {
  const dispatch = useDispatch();
  const i18n = useI18n();

  const showError = (pageName: string) => {
    dispatch(
      notificationActionCreators.showNotification({
        message: i18n('roboAdvice.client.missingInformation').replace(
          '{0}',
          pageName
        ),
        type: NotificationTypes.error
      })
    );
  };

  const clientMissingFields = () => {
    clientForm.resumeValidation();
    const { errors } = clientForm.getState();

    const isClientInformationError =
      errors?.clientInformation && !isEmpty(errors?.clientInformation);
    const isKYCError = errors?.kyc && !isEmpty(errors?.kyc);

    if (isClientInformationError || isKYCError) {
      if (isClientInformationError) {
        showError(i18n('roboAdvice.tabs.clientInformation'));
      }

      if (isKYCError) {
        showError(i18n('roboAdvice.client.kyc'));
      }

      return true;
    }

    return false;
  };

  return clientMissingFields;
};

export default useClientMissingFields;
