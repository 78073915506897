import * as R from 'ramda';
import * as React from 'react';

import { TableFavoriteItems } from '../types';
import { useTable } from './context';
import FavoriteIconComponenet from 'features/shared/components/table/favoriteIcon';

type FavoriteIconProps = {
  id: string;
  onClick?: (event: any, isFavorite: boolean) => void;
};

const FavoriteIcon = ({ id, onClick }: FavoriteIconProps) => {
  const table = useTable();
  const tableStore = table.module.useTableStore();
  const isItemFavorite = R.propOr<boolean, TableFavoriteItems, boolean>(
    false,
    id,
    tableStore.favoriteItems
  );

  return (
    <FavoriteIconComponenet
      isFavorite={isItemFavorite}
      onClick={(event, isFavorite) => {
        tableStore.setFavoriteItem(id, isFavorite);
        onClick && onClick(event, isFavorite);
      }}
    />
  );
};

export default FavoriteIcon;
