import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { usePageStyles } from './shared.js';
import Table from 'features/shared/components/table/table.js';
import TableBody from 'features/shared/components/table/tableBody.js';
import TableBodyRow from 'features/shared/components/table/tableBodyRow.js';
import { ModuleTypes, LicenseTypes } from 'features/shared/constants/session';
import sessionSelectors from 'features/shared/services/session/selectors';
import { formatDate } from 'features/shared/utils/dateTime.js';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { useFieldArray } from 'features/sharedModules/finalForm/components/useFieldArray';

const ModuleTypeLabels = {
  [ModuleTypes.advisorSolution]: 'shared.advisorSolution'
};

const LicenseTypeLabels = {
  [LicenseTypes.professional]: 'shared.professional',
  [LicenseTypes.basic]: 'shared.basic',
  [LicenseTypes.trial]: 'shared.trial',
  [LicenseTypes.beta]: 'shared.beta'
};

const Subscription = () => {
  const i18n = useI18n();
  const pageClasses = usePageStyles();

  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);

  const { fields } = useFieldArray('licenses');

  return (
    <div>
      <div className={classNames(pageClasses.label, pageClasses.verticalLabel)}>
        {i18n('admin.manageCustomer.subscription')}
      </div>
      <div>
        <Table
          header={[
            {
              title: i18n('admin.manageCustomer.module')
            },
            {
              title: i18n('admin.manageCustomer.license')
            },
            {
              title: i18n('admin.manageCustomer.licenseRenewalDate')
            }
          ]}
        >
          <TableBody
            noDataTitle={i18n('admin.manageCustomer.noModules')}
            cellsNumber={3}
          >
            {fields.map((field, index) => {
              const license = fields.value[index];

              return (
                <TableBodyRow key={index}>
                  {({ bodyRowCellClassName }) => (
                    <>
                      <td className={bodyRowCellClassName}>
                        {i18n(ModuleTypeLabels[license.moduleType])}
                      </td>
                      <td className={bodyRowCellClassName}>
                        {i18n(LicenseTypeLabels[license.licenseType])}
                      </td>
                      <td className={bodyRowCellClassName}>
                        {formatDate(cultureCode, license.licenseRenewalDate)}
                      </td>
                    </>
                  )}
                </TableBodyRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default Subscription;
