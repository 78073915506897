import * as R from 'ramda';
import * as React from 'react';
import { useForm } from 'react-final-form';
import { useFieldArray as ffUseFieldArray } from 'react-final-form-arrays';

export function useFieldArray(name, config = {}) {
  const form = useForm('useFieldArray');

  const formMutators = form.mutators;
  const hasMutators = !!(formMutators && formMutators.push && formMutators.pop);
  if (!hasMutators) {
    throw new Error(
      'Array mutators not found. You need to provide the mutators from final-form-arrays to your form'
    );
  }

  const mutators = React.useMemo(
    () =>
      Object.keys(formMutators).reduce((result, key) => {
        result[key] = (...args) => formMutators[key](name, ...args);
        return result;
      }, {}),
    [name]
  );

  return R.over(
    R.lensProp('fields'),
    R.mergeLeft(mutators),
    ffUseFieldArray(name, config)
  );
}
