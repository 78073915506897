import React from 'react';
import { useParams } from 'react-router';

import { clientForm } from '../../main/services/clientForm';
import { ClientParams } from '../../shared/types';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { Layouts } from 'features/roboAdvice/shared/constants/index';
import ConfigurableLabel from 'features/shared/components/configurableLabel/';
import { ClientTypes } from 'features/shared/constants/session';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { FieldComponentTypes } from 'features/sharedModules/declarativeForm/constants';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const useStyles = createUseStyles(theme => ({
  standardLabel: {
    width: '350px'
  },
  standardInputWidth: {
    width: '230px'
  },
  textareaLabel: {
    width: 350,
    marginRight: Spacing.spacing02,
    fontWeight: FontWeights.medium,
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    display: 'flex',
    marginBottom: Spacing.spacing01
  },
  label: {
    color: theme.primaryColor,
    fontWeight: FontWeights.medium,
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    marginTop: 5,
    marginBottom: Spacing.spacing01
  },
  textareaSection: {
    display: 'inline-flex'
  }
}));

export const useInitPage = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const { clientType } = useParams<ClientParams>();
  const {
    roboAdvice: {
      kyc: {
        company: {
          fields: companyKYCFields,
          defaultFields: companyKYCDefaultFields
        },
        person: {
          fields: personKYCFields,
          defaultFields: personKYCDefaultFields
        }
      }
    }
  } = useCustomerConfig();

  React.useEffect(() => {
    clientForm.initialize(clientForm.getState().values);
    return () => {
      clientForm.reset();
    };
  }, []);

  const fields = React.useMemo(() => {
    const kycFields =
      clientType === ClientTypes.company ? companyKYCFields : personKYCFields;
    const kycDefaultFields =
      clientType === ClientTypes.company
        ? companyKYCDefaultFields
        : personKYCDefaultFields;

    const kycDefaultFieldsArray = Object.entries<any>(kycDefaultFields).map(
      ([key, value]) => ({
        name: `kyc.${key}`,
        ...value
      })
    );

    return kycFields
      .concat(kycDefaultFieldsArray)
      .filter(({ isEnabled }) => isEnabled)
      .sort((a, b) => a.order - b.order)
      .map(
        ({
          allowLeadingZeros,
          name,
          componentType,
          dateFormat,
          dateType,
          format,
          items,
          label,
          layout,
          options,
          placeholder,
          required,
          info,
          popupInfo
        }) => {
          const languageKey = getUserPageLanguage();
          const labelContent = languageKey && label ? label[languageKey] : '';

          const config = {
            allowLeadingZeros,
            componentType,
            dateFormat,
            dateType,
            format,
            items,
            layout,
            options,
            placeholder,
            required,
            info,
            popupInfo
          };

          const setCustomLabel = labelText => (
            <div className={classes.label}>
              <ConfigurableLabel
                required={config.required}
                label={labelText}
                info={config.info && languageKey && config.info[languageKey]}
                popupInfo={
                  config.popupInfo &&
                  languageKey &&
                  config.popupInfo[languageKey]
                }
              />
            </div>
          );

          if (name === 'kyc.clientRelationshipPurpose') {
            return {
              name,
              config: {
                ...config,
                componentType: 'textarea',
                placeholderKey:
                  'roboAdvice.kyc.clientRelationshipPurposePlaceholder',
                labelKey: 'roboAdvice.kyc.clientRelationshipPurposeLabel'
              },
              labelClassName: classes.textareaLabel
            };
          }

          if (name === 'kyc.moneyOrigin') {
            return {
              name,
              config: {
                ...config,
                layout: Layouts.horizontal,
                componentType: 'checkboxes',
                labelValue: setCustomLabel(
                  i18n('roboAdvice.clientInformation.moneyOrigin.header')
                )
              },
              afterChange: value => {
                config.items?.forEach(({ activeValue, textField }) => {
                  if (!!textField && !value.includes(activeValue)) {
                    clientForm.change(textField?.name as any, null);
                  }
                });
              }
            };
          }

          if (name === 'kyc.clientClassification') {
            return {
              name,
              config: {
                ...config,
                layout: Layouts.horizontal,
                componentType: 'radioButtons',
                labelValue: setCustomLabel(
                  i18n(
                    'roboAdvice.clientInformation.clientClassification.header'
                  )
                )
              }
            };
          }

          if (name === 'kyc.pep') {
            return {
              name,
              config: {
                ...config,
                componentType: 'buttonSwitch',
                labelValue: setCustomLabel(i18n('roboAdvice.kyc.pepLabel')),
                layout: Layouts.horizontal
              },
              labelClassName: classes.standardLabel
            };
          }

          if (
            config.componentType === FieldComponentTypes.textInput ||
            config.componentType === FieldComponentTypes.numberInput
          ) {
            return {
              name,
              config: {
                ...config,
                labelValue: setCustomLabel(labelContent)
              },
              labelClassName: classes.standardLabel
            };
          }

          if (config.componentType === FieldComponentTypes.textarea) {
            return {
              name,
              config: {
                ...config,
                labelValue: setCustomLabel(labelContent)
              },
              className: classes.standardInputWidth,
              labelClassName: classes.textareaLabel,
              sectionClassName: classes.textareaSection
            };
          }

          if (config.componentType === FieldComponentTypes.dropdown) {
            return {
              name,
              config: {
                ...config,
                labelValue: setCustomLabel(labelContent),
                layout: Layouts.horizontal
              },
              labelClassName: classes.standardLabel,
              afterChange: value => {
                config.items?.forEach(({ activeValue, textField }) => {
                  if (!!textField && !value.includes(activeValue)) {
                    clientForm.change(textField?.name as any, null);
                  }
                });
              }
            };
          }

          if (
            config.componentType === FieldComponentTypes.radioButtons ||
            config.componentType === FieldComponentTypes.checkboxes
          ) {
            return {
              name,
              config: {
                ...config,
                layout: Layouts.horizontal,
                labelValue: setCustomLabel(labelContent)
              },
              afterChange: value => {
                config.items?.forEach(({ activeValue, textField }) => {
                  if (!!textField && !value.includes(activeValue)) {
                    clientForm.change(textField?.name as any, null);
                  }
                });
              }
            };
          }

          if (
            config.componentType === FieldComponentTypes.buttonSwitch ||
            config.componentType === FieldComponentTypes.datepicker
          ) {
            return {
              name,
              config: {
                ...config,
                labelValue: setCustomLabel(labelContent),
                layout: Layouts.horizontal
              },
              labelClassName: classes.standardLabel
            };
          }

          return { name, config };
        }
      );
  }, [
    clientType,
    companyKYCDefaultFields,
    companyKYCFields,
    personKYCDefaultFields,
    personKYCFields
  ]);

  return fields;
};
