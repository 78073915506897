import { Component } from 'react';
import { ReactReduxContext } from 'react-redux';
import { Router as RouterRR } from 'react-router';

class Router extends Component {
  componentDidMount() {
    // Listen to history changes
    this.unlisten = this.props.history.listen((location, action) => {
      const prevLocation = this.props.getLocation(
        this.context.store.getState()
      );
      this.props.onLocationChanged(location, prevLocation, action);
    });

    const prevLocation = this.props.getLocation(this.context.store.getState());
    this.props.onLocationChanged(
      this.props.history.location,
      prevLocation,
      this.props.history.action
    );
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    let { history, children } = this.props;

    return <RouterRR history={history}>{children}</RouterRR>;
  }
}

Router.contextType = ReactReduxContext;

export default Router;
