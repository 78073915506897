import { isNil } from 'ramda';
import { useEffect, useState } from 'react';

import { useReadPortfolioChartData } from '../../proposal/components/portfolioChart/useReadPortfolioChartData';
import { usePageStore } from '../../proposal/services/pageStore';
import { useUpdateGoal } from '../../purposeAndRisk/components/useUpdateGoal';
import {
  PageStatuses,
  useReadDataListener
} from '../../shared/components/useReadDataListener';
import { Goal } from '../../shared/services/goalsStore';
import { useSustainabilityStore } from '../../sustainability';
import { useCustomPortfolioStore } from '../services/customPortfolioStore';
import { usePageStore as useAdvisoryPageStore } from '../services/pageStore';
import CustomPortfolio from './customPortfolio/customPortfolio';
import ModelPortfolio from './modelPortfolio';
import { useHistoricalReturnChartStore } from './useReadHistoricalReturnData';
import { PortfolioModes } from 'features/roboAdvice/adviceSession/shared/constants';
import Button, { ButtonType } from 'features/shared/components/button';
import {
  carouselIds,
  useCarouselStore
} from 'features/shared/components/carousel/carouselStore';
import Modal from 'features/shared/components/modal';
import Radio from 'features/shared/components/radio';
import { Spacing } from 'features/shared/constants/spacing';
import { FontWeights, Typography } from 'features/shared/constants/typography';
import { getTranslation } from 'features/shared/utils/translations';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { AdvisoryErrors } from 'features/sharedModules/errors/types';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  container: {
    backgroundColor: theme.boxBackgroundColor_1,
    border: `1px solid ${theme.boxBackgroundColor_2}`,
    borderRadius: 10,
    padding: Spacing.spacing02
  },
  header: {
    fontSize: Typography.heading1.size,
    fontWeight: FontWeights.medium,
    lineHeight: Typography.heading1.lineHeight,
    marginBottom: Spacing.spacing01
  },
  radiosContainer: {
    display: 'flex',
    gap: Spacing.spacing02,
    paddingBottom: Spacing.spacing02,
    borderBottom: `1px solid ${theme.disabledTextColor}`
  },
  radioContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.spacing01,
    cursor: 'pointer'
  },
  portfolio: {
    paddingTop: Spacing.spacing02
  }
}));

type Props = {
  selectedGoal: Goal;
  errors?: AdvisoryErrors;
};

const PortfolioInterface = ({ selectedGoal, errors }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const { advisoryComponents } = useCustomerConfig();
  const [activeTab, setActiveTab] = useState(
    advisoryComponents.portfolioModes.find(
      mode => mode === advisoryComponents.defaultPortfolioMode
    ) || advisoryComponents.portfolioModes[0]
  );
  const updateGoal = useUpdateGoal();
  const { pageStatuses } = usePageStore();
  const { setIsNewCustomPortfolioDataAvailable } = useCustomPortfolioStore();
  const carouselStore = useCarouselStore();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);

  useReadDataListener(
    pageStatuses.readPortfolioChartData,
    true,
    useReadPortfolioChartData()
  );

  useEffect(() => {
    carouselStore.setSelectedItemIndex(carouselIds.customPortfolio, 0);
    carouselStore.setSelectedItemIndex(carouselIds.modelPortfolio, 0);
  }, []);

  useEffect(() => {
    if (!isNil(selectedGoal.data?.isPortfolioCustom)) {
      setActiveTab(
        selectedGoal.data?.isPortfolioCustom
          ? PortfolioModes.customPortfolio
          : PortfolioModes.modelPortfolio
      );
    }
  }, [selectedGoal.data?.isPortfolioCustom]);

  // Changes in Custom Portfolio
  useEffect(() => {
    const changePageStatuses =
      useAdvisoryPageStore.getState().changePageStatuses;

    changePageStatuses({
      readHistoricalReturn3MChartData: PageStatuses.ready,
      readHistoricalReturnYTDChartData: PageStatuses.ready,
      readHistoricalReturn1YChartData: PageStatuses.ready,
      readHistoricalReturn3YChartData: PageStatuses.ready,
      readHistoricalReturn5YChartData: PageStatuses.ready,
      readHistoricalReturn10YChartData: PageStatuses.ready
    });

    useHistoricalReturnChartStore.getState().reset();

    return () => {
      setIsNewCustomPortfolioDataAvailable(true);
      changePageStatuses({
        readHistoricalReturn3MChartData: undefined,
        readHistoricalReturnYTDChartData: undefined,
        readHistoricalReturn1YChartData: undefined,
        readHistoricalReturn3YChartData: undefined,
        readHistoricalReturn5YChartData: undefined,
        readHistoricalReturn10YChartData: undefined
      });
    };
  }, [
    activeTab,
    selectedGoal.goalId,
    selectedGoal.data.customPortfolio,
    selectedGoal.data.productPlatformNamespace
  ]);

  // Changes in Model Portfolio
  useEffect(() => {
    const setPageStatus = useAdvisoryPageStore.getState().setPageStatus;

    setPageStatus('readSustainabilityModelPortfolioData', PageStatuses.ready);
    useSustainabilityStore.getState().reset();

    return () => {
      setPageStatus('readSustainabilityModelPortfolioData', undefined);
    };
  }, [
    activeTab,
    selectedGoal.data.portfolio,
    selectedGoal.data.productPlatformNamespace,
    selectedGoal.data.isPortfolioCustom
  ]);

  return (
    advisoryComponents.portfolioModes.length > 0 && (
      <>
        <div className={classes.container}>
          <div className={classes.header}>
            {i18n('roboAdvice.advisory.portfolioMode')}
          </div>

          <div className={classes.radiosContainer} id={'portfolio-container'}>
            {advisoryComponents.portfolioModes.map(mode => (
              <label
                key={mode}
                className={classes.radioContainer}
                htmlFor={`radio-${mode}`}
              >
                <Radio
                  width={25}
                  height={25}
                  checked={activeTab === mode}
                  id={`radio-${mode}`}
                  onChange={() => {
                    if (
                      mode === PortfolioModes.modelPortfolio &&
                      selectedGoal.data?.isCustomPortfolioDirty
                    ) {
                      setIsModalOpen(true);
                    } else {
                      setActiveTab(mode);
                      carouselStore.setSelectedItemIndex(
                        carouselIds.modelPortfolio,
                        0
                      );
                      updateGoal(selectedGoal.goalId, {
                        data: {
                          isPortfolioCustom:
                            mode === PortfolioModes.customPortfolio
                        }
                      });
                    }
                  }}
                />
                {getTranslation(advisoryComponents[`${mode}Label`])}
              </label>
            ))}
          </div>

          <div className={classes.portfolio}>
            {advisoryComponents.portfolioModes.includes(
              PortfolioModes.modelPortfolio
            ) &&
              activeTab === PortfolioModes.modelPortfolio && (
                <ModelPortfolio
                  advisoryComponents={advisoryComponents}
                  selectedGoal={selectedGoal}
                />
              )}
            {advisoryComponents.portfolioModes.includes(
              PortfolioModes.customPortfolio
            ) &&
              activeTab === PortfolioModes.customPortfolio && (
                <CustomPortfolio selectedGoal={selectedGoal} errors={errors} />
              )}
          </div>
        </div>

        <Modal
          footer={
            <>
              <Button onClick={closeModal} buttonType={ButtonType.secondary}>
                {i18n('shared.cancel')}
              </Button>

              <Button
                onClick={() => {
                  closeModal();
                  carouselStore.setSelectedItemIndex(
                    carouselIds.modelPortfolio,
                    0
                  );
                  setActiveTab(PortfolioModes.modelPortfolio);
                  updateGoal(selectedGoal.goalId, {
                    data: {
                      isPortfolioCustom: false
                    }
                  });
                }}
              >
                {i18n(
                  'roboAdvice.advisory.portfolioInterface.modal.confirmButton'
                )}
              </Button>
            </>
          }
          header={i18n('roboAdvice.advisory.portfolioInterface.modal.header')}
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          style={{
            content: {
              width: 0 // use modal min-width to prevent modal from being too wide
            }
          }}
        >
          <div>
            {i18n('roboAdvice.advisory.portfolioInterface.modal.message')}
          </div>
        </Modal>
      </>
    )
  );
};

export default PortfolioInterface;
