export const GOAL_TABLE_LINKS = [
  {
    config: 'roboAdviceForm.proposal.goalsTableColumns.equityPortion',
    translation: 'admin.appAdmin.advisorSolution.proposal.equityPortion'
  },
  {
    config: 'roboAdviceForm.proposal.goalsTableColumns.expectedAnnualReturn',
    translation: 'admin.appAdmin.advisorSolution.proposal.expectedAnnualReturn'
  },
  {
    config: 'roboAdviceForm.proposal.goalsTableColumns.expectedValueInYears',
    translation: 'admin.appAdmin.advisorSolution.proposal.expectedValueInYears'
  },
  {
    config: 'roboAdviceForm.proposal.goalsTableColumns.expectedVolatility',
    translation: 'admin.appAdmin.advisorSolution.proposal.expectedVolatility'
  },
  {
    config: 'roboAdviceForm.proposal.goalsTableColumns.firstDeposit',
    translation: 'admin.appAdmin.advisorSolution.proposal.firstDeposit'
  },
  {
    config: 'roboAdviceForm.proposal.goalsTableColumns.goalName',
    translation: 'admin.appAdmin.advisorSolution.proposal.goalName'
  },
  {
    config: 'roboAdviceForm.proposal.goalsTableColumns.monthlyDeposit',
    translation: 'admin.appAdmin.advisorSolution.proposal.monthlyDeposit'
  },
  {
    config: 'roboAdviceForm.proposal.goalsTableColumns.riskScore',
    translation: 'admin.appAdmin.advisorSolution.proposal.riskScore'
  },
  {
    config: 'roboAdviceForm.proposal.goalsTableColumns.years',
    translation: 'admin.appAdmin.advisorSolution.proposal.years'
  }
];

export const ANALYTICS_LINKS = [
  {
    config: 'analyticsComponents.isPortfolioHidden',
    translation: 'admin.appAdmin.advisorSolution.proposal.isPortfolioHidden'
  },
  {
    config: 'analyticsComponents.isExpectedValueHidden',
    translation: 'admin.appAdmin.advisorSolution.proposal.isExpectedValueHidden'
  },
  {
    config: 'analyticsComponents.isExpectedPathHidden',
    translation: 'admin.appAdmin.advisorSolution.proposal.isExpectedPathHidden'
  },
  {
    config: 'analyticsComponents.isCostHidden',
    translation: 'admin.appAdmin.advisorSolution.proposal.isCostHidden'
  },
  {
    config: 'analyticsComponents.isEfficientFrontierHidden',
    translation:
      'admin.appAdmin.advisorSolution.proposal.isEfficientFrontierHidden'
  },
  {
    config: 'analyticsComponents.isSustainabilityHidden',
    translation:
      'admin.appAdmin.advisorSolution.proposal.isSustainabilityHidden'
  },
  {
    config: 'analyticsComponents.isOrderSummaryHidden',
    translation: 'admin.appAdmin.advisorSolution.proposal.isOrderSummaryHidden'
  }
];
