import { z } from 'zod';

import { TimeHorizonTypes } from 'features/roboAdvice/adviceSession/purposeAndRisk/constants';

const labelSchema = z.object({
  label: z.record(z.string())
});

const itemSchema = z.object({
  riskHorizonValue: z.number(),
  analysisProjectionYearsValue: z.number(),
  label: z.record(z.string()),
  yearIntervalStart: z.number(),
  yearIntervalStop: z.number()
});

export const horizonConfigSchema = z.object({
  type: z.nativeEnum(TimeHorizonTypes),
  riskLevelLabels: z.record(labelSchema),
  items: z.array(itemSchema)
});

export type HorizonConfig = z.infer<typeof horizonConfigSchema>;

export const defaultTimeHorizonConfig: HorizonConfig = {
  type: TimeHorizonTypes.slider,
  riskLevelLabels: {
    risk1: { label: { en: 'Low Risk', no: 'Lav risiko' } },
    risk2: { label: { en: 'Medium Risk', no: 'Middels risko' } },
    risk3: { label: { en: 'High Risk', no: 'Høy risiko' } },
    risk4: { label: { en: 'Maximum Risk', no: 'Maksimum risiko' } }
  },
  items: [
    {
      riskHorizonValue: 1,
      analysisProjectionYearsValue: 1,
      label: {
        en: 'Up to 1 year',
        no: 'Opp til 1 år'
      },
      yearIntervalStart: 0,
      yearIntervalStop: 1
    },
    {
      riskHorizonValue: 2,
      analysisProjectionYearsValue: 3,
      label: {
        en: '1 to 3 years',
        no: '1 til 3 år'
      },
      yearIntervalStart: 2,
      yearIntervalStop: 3
    },
    {
      riskHorizonValue: 3,
      analysisProjectionYearsValue: 3,
      label: {
        en: '3 to 5 years',
        no: '3 til 5 år'
      },
      yearIntervalStart: 4,
      yearIntervalStop: 5
    },
    {
      riskHorizonValue: 4,
      analysisProjectionYearsValue: 5,
      label: {
        en: '5 to 10 years',
        no: '5 til 10 år'
      },
      yearIntervalStart: 6,
      yearIntervalStop: 9
    },
    {
      riskHorizonValue: 5,
      analysisProjectionYearsValue: 10,
      label: {
        en: '10 years+',
        no: '10 år+'
      },
      yearIntervalStart: 10,
      yearIntervalStop: 35
    }
  ]
};
