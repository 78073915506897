import { isNil } from 'ramda';
import { useState } from 'react';
import { Form } from 'react-final-form';

import CostSummarySection from './costSummarySection';
import OverrideCost from './overrideCost';
import { CostChartSummary } from './types';
import { costForm } from 'features/roboAdvice/adviceSession/proposal/services/costForm';
import { filterNil } from 'features/shared/utils/filters';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  tableContainer: {
    width: '62.5%'
  },
  tableHeader: {
    paddingTop: '30px',
    paddingBottom: '20px',
    textAlign: 'left'
  }
});

const overrideCosts = {
  platformFee: 'platformFee',
  custodyFeePercent: 'custodyFeePercent',
  custodyFeeAmount: 'custodyFeeAmount',
  advisoryOnboardingFee: 'advisoryOnboardingFee',
  advisoryPurchasingFee: 'advisoryPurchasingFee'
};

type Props = {
  summaryData: CostChartSummary;
  originalTableData: CostChartSummary;
};

const CostSummary = ({ summaryData, originalTableData }: Props) => {
  const i18n = useI18n();
  const classes = useStyles();
  const {
    roboAdviceForm: {
      proposal: {
        isOverrideSummaryPlatformFeeEnabled,
        isOverrideSummaryCustodyFeeEnabled,
        isOverrideSummaryCustodyFeeAmountEnabled,
        isOverrideSummaryPortfolioAdvisoryOnboardingFeeEnabled,
        isOverrideSummaryPortfolioAdvisoryPurchasingFeeEnabled,
        isOverrideSummaryAnnualOngoingFeesAdvisorNotesRequired,
        isOverrideSummaryCustodyFeesAdvisorNotesRequired,
        isOverrideSummaryOneTimeFeesAdvisorNotesRequired
      }
    }
  } = useCustomerConfig();

  const [
    isAnnualOngoingFeesEditModeActive,
    setIsAnnualOngoingFeesEditModeActive
  ] = useState(false);
  const [isCustodyFeesEditModeActive, setIsCustodyFeesEditModeActive] =
    useState(false);
  const [isOneTimeFeesEditModeActive, setIsOneTimeFeesEditModeActive] =
    useState(false);

  const isAnyEditModeActive =
    isAnnualOngoingFeesEditModeActive ||
    isCustodyFeesEditModeActive ||
    isOneTimeFeesEditModeActive;

  const overrideSummaryOngoingFeesCosts = [
    isOverrideSummaryPlatformFeeEnabled &&
    !isNil(
      summaryData?.annualOngoingFees.find(
        ({ name }) => name === overrideCosts.platformFee
      )?.percent
    )
      ? i18n('costChart.costTable.platformFee')
      : null,
    isOverrideSummaryCustodyFeeEnabled &&
    !isNil(
      summaryData?.annualOngoingFees.find(
        ({ name }) => name === overrideCosts.custodyFeePercent
      )?.percent
    )
      ? i18n('costChart.costTable.custodyFee')
      : null
  ].filter(filterNil);

  const overrideSummaryCustodyFeesCosts = [
    isOverrideSummaryCustodyFeeAmountEnabled &&
    !isNil(
      summaryData?.custodyFees.find(
        ({ name }) => name === overrideCosts.custodyFeeAmount
      )?.value
    )
      ? i18n('costChart.costTable.custodyFee')
      : null
  ].filter(filterNil);

  const overrideSummaryOneTimeFeesCosts = [
    isOverrideSummaryPortfolioAdvisoryOnboardingFeeEnabled &&
    !isNil(
      summaryData?.oneTimeFees.find(
        ({ name }) => name === overrideCosts.advisoryOnboardingFee
      )?.percent
    )
      ? i18n('costChart.costTable.advisoryOnboardingFee')
      : null,
    isOverrideSummaryPortfolioAdvisoryPurchasingFeeEnabled &&
    !isNil(
      summaryData?.oneTimeFees.find(
        ({ name }) => name === overrideCosts.advisoryPurchasingFee
      )?.percent
    )
      ? i18n('costChart.costTable.advisoryPurchaseFee')
      : null
  ].filter(filterNil);

  return (
    <Form form={costForm} onSubmit={() => {}}>
      {() => (
        <div className={classes.tableContainer}>
          <CostSummarySection
            sectionHeader={i18n('roboAdvice.proposal.cost.annualOngoingFees')}
            headerTitles={['', '', '%']}
            tableData={summaryData?.annualOngoingFees}
            dataKeys={['icon', 'field', 'percent']}
            highlightLastRow
            withIcon
            isAnyEditModeActive={isAnyEditModeActive}
            isEditModeActive={isAnnualOngoingFeesEditModeActive}
            setIsEditModeActive={setIsAnnualOngoingFeesEditModeActive}
            overrideCosts={overrideSummaryOngoingFeesCosts}
            originalTableData={originalTableData?.annualOngoingFees}
          />

          {isAnnualOngoingFeesEditModeActive &&
            overrideSummaryOngoingFeesCosts.length > 0 && (
              <OverrideCost
                advisorNotesId="override-summary-annual-ongoing-fees-advisor-notes"
                advisorNotesName="overrideSummaryAnnualOngoingFeesAdvisorNotes"
                errorKey="overrideCost"
                isOverrideAdvisorNotesRequired={
                  isOverrideSummaryAnnualOngoingFeesAdvisorNotesRequired
                }
                setIsEditModeActive={setIsAnnualOngoingFeesEditModeActive}
              />
            )}

          <CostSummarySection
            sectionHeader={i18n('costChart.costTable.custodyFee')}
            headerTitles={['', i18n('shared.amount')]}
            tableData={summaryData?.custodyFees}
            dataKeys={['field', 'value']}
            isAnyEditModeActive={isAnyEditModeActive}
            isEditModeActive={isCustodyFeesEditModeActive}
            setIsEditModeActive={setIsCustodyFeesEditModeActive}
            overrideCosts={overrideSummaryCustodyFeesCosts}
            originalTableData={originalTableData?.custodyFees}
            decimalScale={0}
          />

          {isCustodyFeesEditModeActive &&
            overrideSummaryCustodyFeesCosts.length > 0 && (
              <OverrideCost
                advisorNotesId="override-summary-custody-fees-advisor-notes"
                advisorNotesName="overrideSummaryCustodyFeesAdvisorNotes"
                errorKey="overrideCost"
                isOverrideAdvisorNotesRequired={
                  isOverrideSummaryCustodyFeesAdvisorNotesRequired
                }
                setIsEditModeActive={setIsCustodyFeesEditModeActive}
              />
            )}

          <CostSummarySection
            sectionHeader={i18n('roboAdvice.proposal.cost.oneTimeFees')}
            headerTitles={['', '%']}
            tableData={summaryData?.oneTimeFees}
            dataKeys={['field', 'percent']}
            isAnyEditModeActive={isAnyEditModeActive}
            isEditModeActive={isOneTimeFeesEditModeActive}
            setIsEditModeActive={setIsOneTimeFeesEditModeActive}
            overrideCosts={overrideSummaryOneTimeFeesCosts}
            originalTableData={originalTableData?.oneTimeFees}
          />

          {isOneTimeFeesEditModeActive &&
            overrideSummaryOneTimeFeesCosts.length > 0 && (
              <OverrideCost
                advisorNotesId="override-summary-one-time-fees-advisor-notes"
                advisorNotesName="overrideSummaryOneTimeFeesAdvisorNotes"
                errorKey="overrideCost"
                isOverrideAdvisorNotesRequired={
                  isOverrideSummaryOneTimeFeesAdvisorNotesRequired
                }
                setIsEditModeActive={setIsOneTimeFeesEditModeActive}
              />
            )}

          <CostSummarySection
            sectionHeader={i18n(
              'costChart.costTable.annualReturnCommissionPaidToAdvisor'
            )}
            headerTitles={['', '%']}
            tableData={summaryData?.annualReturnCommissionPaidToAdvisor}
            dataKeys={['field', 'percent']}
          />
        </div>
      )}
    </Form>
  );
};

export default CostSummary;
