import createActions from './services/actions.js';
import createSagas from './services/sagas.js';
import {
  createGetIsPageOpened,
  createGetIsPageClosed
} from './services/selectors.js';

export default ({ id, routeId, routeReloadParamKeys = [] } = {}) => {
  const actions = createActions({ id });

  const selectors = {
    getIsPageOpened: createGetIsPageOpened(routeId, routeReloadParamKeys),
    getIsPageClosed: createGetIsPageClosed(routeId, routeReloadParamKeys)
  };

  const sagas = createSagas({ actions, selectors });

  return {
    actions,
    selectors,
    sagas
  };
};
