import { isNil, path as rPath } from 'ramda';

import { isFieldProgressCalculationRequired } from '../adviceSession/shared/services/progressSelectors';
import { InvestorTypes } from 'features/roboAdvice/client/kyc/components/automaticClientClassification/constants';
import { ClientClassifications } from 'features/roboAdvice/shared/constants';
import { ClientClassification } from 'features/roboAdvice/shared/types';
import { FieldComponentTypes } from 'features/sharedModules/declarativeForm/constants';

export const getCurrentDate = () => new Date();

export const getFieldsWithTextFields = (fields, values) => {
  return fields
    .map(adv => {
      const path = adv.name.split('.');

      return adv.items
        ? adv.items
            .filter(a => {
              const value = rPath(path, values);
              return (
                (Array.isArray(value)
                  ? value.includes(a.activeValue)
                  : a.activeValue === value) && a.textField
              );
            })
            .map(a => a.textField)
        : [];
    })
    .flat()
    .filter(
      f =>
        f.required &&
        (isNil(f.when) || isFieldProgressCalculationRequired(f.when, values))
    )
    .map(f => ({ ...f, additionalTextField: true }));
};

export const getDefaultErrorMessage = (
  componentType: keyof typeof FieldComponentTypes,
  additionalTextField: true | undefined
): string => {
  if (additionalTextField) {
    return 'shared.error.additionalTextField';
  }
  switch (componentType) {
    case FieldComponentTypes.textInput:
    case FieldComponentTypes.numberInput:
    case FieldComponentTypes.textarea:
      return 'shared.error.requiredTextField';
    case FieldComponentTypes.buttonSwitch:
    case FieldComponentTypes.radioButtons:
      return 'shared.error.requiredRadio';
    case FieldComponentTypes.checkboxes:
      return 'shared.error.requiredCheckbox';
    case FieldComponentTypes.dropdown:
    case FieldComponentTypes.countryDropdown:
      return 'shared.error.requiredDropdown';
    default:
      return '';
  }
};

const investorTypeMap: Record<
  typeof InvestorTypes[keyof typeof InvestorTypes] | ClientClassification,
  number
> = {
  [InvestorTypes.nonProfessionalInvestor]: 1,
  [InvestorTypes.professionalInvestor]: 2,
  [ClientClassifications.retailClient]: 1,
  [ClientClassifications.professionalClient]: 2,
  [ClientClassifications.eligibleCounterparty]: 3
};

export const getInvestorTypeNumber = ({
  clientClassification,
  automaticClientClassification
}: {
  clientClassification?: ClientClassification | null;
  automaticClientClassification?:
    | typeof InvestorTypes[keyof typeof InvestorTypes]
    | null;
}) => {
  if (automaticClientClassification) {
    const automaticType = investorTypeMap[automaticClientClassification];
    if (automaticType !== undefined) {
      return automaticType;
    }
  }

  if (clientClassification) {
    const clientType = investorTypeMap[clientClassification];
    if (clientType !== undefined) {
      return clientType;
    }
  }

  return 1;
};
