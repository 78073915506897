import {
  HistoricalReturnChartData,
  HistoricalReturnTimeRangeChartData,
  createUseHistoricalReturnChartStore
} from '@quantfoliorepo/ui-components';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import { format } from 'date-fns';
import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

import { PortfolioIds } from '../../advisory/services/constants';
import { useSessionStore } from '../../session/services/sessionStore';
import { PageStatuses } from '../../shared/components/useReadDataListener';
import {
  usePageStore as useRiskScorePageStore,
  PageStatuses as ProposalPageStatuses
} from '../services/pageStore';
import { usePortfolioChartStore } from './useReadPortfolioChartData';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import {
  getLookbackStartDate,
  useExistingPortfolio
} from 'features/roboAdvice/adviceSession/proposal/services/selectors';
import {
  createHistoricalReturnBenchmark,
  createHistoricalReturnV2,
  getFundsList
} from 'features/roboAdvice/adviceSession/shared/api';
import { availableHistoricalReturnStatisticsMapping } from 'features/roboAdvice/adviceSession/shared/mapping';
import { getCurrentDate } from 'features/roboAdvice/shared/utils';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { filterNil } from 'features/shared/utils/filters';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { useTheme } from 'features/sharedModules/styles/components/styles';

export const useHistoricalReturnChartStore =
  createUseHistoricalReturnChartStore();

export function useReadHistoricalReturnChartData(
  statusKey: keyof ProposalPageStatuses,
  timeRangeChartDataKey: keyof HistoricalReturnChartData,
  selectedRisks,
  compareWithExistingPortfolio: boolean
) {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const theme = useTheme();
  const i18n = useI18n();
  const {
    roboAdvice: {
      riskScore: { showStats },
      riskFinder: {
        isHistoricalReturnBenchmarkEnabled,
        historicalReturnBenchmarkType
      }
    }
  } = useCustomerConfig();
  const portfolioChartStore = usePortfolioChartStore();
  const existingPortfolioData = useExistingPortfolio();
  const { categories } = useSessionStore();

  const cancelTokenSourceRef = React.useRef<CancelTokenSource>();
  const readHistoricalReturnChartData = async () => {
    const { chartData } = usePortfolioChartStore.getState();
    if (R.isNil(chartData)) {
      return;
    }

    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const riskScorePageStore = useRiskScorePageStore.getState();
    const historicalReturnChartStore = useHistoricalReturnChartStore.getState();

    try {
      riskScorePageStore.setPageStatus(statusKey, PageStatuses.pending);

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const currentDate = getCurrentDate();
      let historicalReturn: HistoricalReturnTimeRangeChartData[] = [];
      let portfoliosData: {
        color: string;
        id: string;
        name: string;
        portfolio: { id: string; weight: number; category_id?: string }[];
      }[] = [];

      for (const selectedRisk of selectedRisks) {
        const portfolio = portfolioChartStore
          .getChartData(selectedRisk.risk, false)
          .categorySelectionPortfolio.map(({ id, weight }) => ({ id, weight }));

        portfoliosData = [
          ...portfoliosData,
          {
            color: selectedRisk.color,
            id: selectedRisk.risk,
            name: selectedRisk.name,
            portfolio
          }
        ];
      }

      if (compareWithExistingPortfolio && existingPortfolioData) {
        const existingPortfolio = existingPortfolioData?.[1].map(
          ({ name, weight }) => ({
            category_id: categories.find(
              ({ SubAssetClass }) => SubAssetClass === name
            )?.CategoryId,
            weight
          })
        );

        portfoliosData = [
          ...portfoliosData,
          {
            color: theme.chartPortfolioColors[3],
            id: PortfolioIds.existingPortfolio,
            name: i18n('roboAdvice.advisory.portfolio.existingPortfolio'),
            portfolio: existingPortfolio
          }
        ];
      }

      for (const { portfolio, name, id, color } of portfoliosData) {
        let historicalReturnResponse: Awaited<
          ReturnType<
            | typeof createHistoricalReturnBenchmark
            | typeof createHistoricalReturnV2
          >
        >;

        if (isHistoricalReturnBenchmarkEnabled) {
          const historicalReturnBenchmarkPortfolio =
            id !== PortfolioIds.existingPortfolio
              ? portfolio
              : await getExistingPortfolioForHistoricalReturnBenchmark({
                  portfolio,
                  namespaceId: riskScorePageStore.productConfiguration,
                  qAuthAccessToken,
                  cancelToken: cancelTokenSource.token
                });

          historicalReturnResponse = await createHistoricalReturnBenchmark(
            qAuthAccessToken,
            cancelTokenSource.token,
            {
              portfolio: historicalReturnBenchmarkPortfolio,
              savingsplan: {
                monthly_savings: 0,
                starting_capital: 100
              },
              lookback: {
                start_date: format(
                  getLookbackStartDate(timeRangeChartDataKey, currentDate),
                  'yyyy-MM-dd'
                ),
                end_date: format(currentDate, 'yyyy-MM-dd')
              },
              showStats,
              benchmarkType: historicalReturnBenchmarkType
            },
            {
              namespace_id: riskScorePageStore.productConfiguration
            }
          );
        } else {
          historicalReturnResponse = await createHistoricalReturnV2(
            qAuthAccessToken,
            cancelTokenSource.token,
            {
              portfolio,
              savingsplan: {
                starting_capital: 100
              },
              lookback: {
                start_date: format(
                  getLookbackStartDate(timeRangeChartDataKey, currentDate),
                  'yyyy-MM-dd'
                ),
                end_date: format(currentDate, 'yyyy-MM-dd')
              },
              showStats
            },
            {
              namespace_id: riskScorePageStore.productConfiguration
            }
          );
        }

        const historicalReturnResponseData = historicalReturnResponse.data
          ?.portfolioStatistics
          ? {
              ...historicalReturnResponse.data,
              portfolioStatistics: Object.entries(
                historicalReturnResponse.data.portfolioStatistics
              ).reduce((acc, [key, value]) => {
                return {
                  ...acc,
                  [availableHistoricalReturnStatisticsMapping[key]]: value
                };
              }, {})
            }
          : historicalReturnResponse.data;

        historicalReturn = [
          ...historicalReturn,
          {
            ...historicalReturnResponseData,
            goalId: id,
            goalName: name,
            color
          }
        ];
      }

      historicalReturnChartStore.setTimeRangeChartData(
        timeRangeChartDataKey,
        historicalReturn.sort((a, b) => +a.goalId - +b.goalId)
      );

      riskScorePageStore.setPageStatus(statusKey, PageStatuses.succeed);
    } catch (error) {
      if (!axios.isCancel(error)) {
        if (
          R.path(['response', 'status'], error) === 400 &&
          R.path(['response', 'data', 'qf-api-error-code'], error) === 416
        ) {
          historicalReturnChartStore.resetTimeRangeChartData(
            timeRangeChartDataKey
          );
          riskScorePageStore.setPageStatus(statusKey, PageStatuses.succeed);
        } else {
          historicalReturnChartStore.resetTimeRangeChartData(
            timeRangeChartDataKey
          );
          riskScorePageStore.setPageStatus(statusKey, PageStatuses.failed);

          throwSafeError(error);
        }
      }
    }
  };

  return readHistoricalReturnChartData;
}

const getExistingPortfolioForHistoricalReturnBenchmark = async ({
  portfolio,
  namespaceId,
  qAuthAccessToken,
  cancelToken
}: {
  portfolio: { category_id?: string; weight: number }[];
  namespaceId: number | null;
  qAuthAccessToken: string;
  cancelToken: CancelToken;
}) => {
  const fundsListResponse = await getFundsList(qAuthAccessToken, cancelToken, {
    namespace_id: namespaceId,
    language: getUserPageLanguage()
  });
  const { funds } = fundsListResponse.data;

  const benchmarkExistingPortfolio = portfolio
    .map(({ category_id, weight }) => {
      const fund = funds.find(fund =>
        Object.keys(fund.SubAssetClasses).includes(category_id || '')
      );
      if (!fund) {
        return null;
      }

      return {
        id: fund.Ticker as string,
        weight
      };
    })
    .filter(filterNil);

  return benchmarkExistingPortfolio;
};
