import { useField as ffUseField, UseFieldConfig } from 'react-final-form';

export function useField<
  FieldValue = any,
  T extends HTMLElement = HTMLElement,
  InputValue = FieldValue
>(name: string, config?: UseFieldConfig<FieldValue, InputValue>) {
  return ffUseField<FieldValue, T, InputValue>(name, {
    format: (value?: any) => value,
    parse: (value?: any) => value,
    allowNull: true,
    ...config
  });
}
