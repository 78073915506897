import { isNil } from 'ramda';

import { SessionStatuses } from '../../../shared/constants';
import {
  AdviceSession,
  ClientFormState,
  useClientForm
} from '../../main/services/clientForm';
import {
  ClientPageStoreData,
  useClientPageStore
} from '../../main/services/clientPageStore';
import { mapChannelNameToTranslationKey } from '../services/mapping';
import { getTranslation } from 'features/shared/utils/translations';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export type AdviceSessionView = {
  channelKey?: string;
  id: string;
  index: number;
  newTimestamp: number;
  name?: string;
  created?: string;
  lastActivityDate?: string;
  channel?: string;
  status?: string;
  originalStatus?: string;
  adviceType?: string | null;
};

export function useAdviceSessions() {
  return getAdviceSessionsResult({
    i18n: useI18n(),
    clientForm: useClientForm(),
    clientPageStore: useClientPageStore()
  });
}

const statusToTranslation = {
  [SessionStatuses.open]: 'roboAdvice.client.adviceSessionStatuses.ongoing',
  [SessionStatuses.complete]:
    'roboAdvice.client.adviceSessionStatuses.completed'
};

export function getAdviceSessionsResult({
  i18n,
  clientForm
}: {
  i18n: any;
  clientForm: ClientFormState;
  clientPageStore: ClientPageStoreData;
}) {
  const {
    values: { adviceSessions }
  } = clientForm;

  if (isNil(adviceSessions)) {
    return [];
  }

  return adviceSessions
    .map((session: AdviceSession, index: number) => {
      let status;

      const statusTranslation = isNil(session.status)
        ? undefined
        : statusToTranslation[session.status];
      status = isNil(statusTranslation) ? '' : i18n(statusTranslation);

      const surveyStatusTranslation = isNil(session.surveyStatus)
        ? undefined
        : i18n(
            `roboAdvice.client.preMeetingSurvey.surveyStatus.${session.surveyStatus}`
          );

      const surveyStatusMessageTranslation = getSurveyStatusMessageTranslation({
        session,
        i18n
      });

      return {
        advisor: session.advisor,
        id: session.id,
        index,
        newTimestamp: session.newTimestamp || 0,
        name: session.name,
        created: session.created,
        lastActivityDate: session.lastActivityDate,
        channel: session.channel,
        channelKey: mapChannelNameToTranslationKey(session.channel),
        originalStatus: session.status,
        adviceType: session.adviceType,
        status,
        surveyStatus: session.surveyStatus,
        surveyStatusTranslated: surveyStatusTranslation,
        surveyStatusMessage: surveyStatusMessageTranslation
      };
    })
    .sort((a, b) => {
      if (a.newTimestamp > b.newTimestamp) {
        return -1;
      }
      if (a.newTimestamp < b.newTimestamp) {
        return 1;
      }
      if (a.lastActivityDate && b.lastActivityDate) {
        if (a.lastActivityDate > b.lastActivityDate) {
          return -1;
        }
        if (a.lastActivityDate < b.lastActivityDate) {
          return 1;
        }
      }
      return 0;
    });
}

const getSurveyStatusMessageTranslation = ({
  session,
  i18n
}: {
  session: AdviceSession;
  i18n: ReturnType<typeof useI18n>;
}) => {
  if (!isNil(session.surveyStatusMessageI18n)) {
    return getTranslation(session.surveyStatusMessageI18n);
  }

  if (!isNil(session.surveyStatusMessageKey)) {
    return i18n(session.surveyStatusMessageKey);
  }

  return session.surveyStatusMessage;
};
