import { getTime, parseJSON } from 'date-fns';
import queryString from 'query-string';
import * as R from 'ramda';

import config from 'config/index.js';
import {
  getQAuthApiAccessToken,
  setQAuthApiAccessToken
} from 'features/shared/services/session/storage.js';
import {
  customersApi,
  customersApiV2,
  deepAlphaApi,
  qAuthApi
} from 'features/shared/utils/axios';
import { useSessionStore } from 'features/sharedModules/session/services/sessionStore';

export const getQAuthAccessToken = async (auth0AccessToken, cancelToken) => {
  const accessToken = getQAuthApiAccessToken();
  const { permissions, setPermissions } = useSessionStore.getState();

  if (
    !R.isNil(accessToken) &&
    accessToken.auth0AccessToken === auth0AccessToken &&
    permissions.length
  ) {
    return accessToken.qAuthAccessToken;
  }

  const auth = await qAuthApi.post(
    '/auth/token/authenticated',
    {
      application: 'deepalpha'
    },
    {
      cancelToken,
      headers: { Authorization: auth0AccessToken }
    }
  );

  setPermissions(auth.data.permissions);

  setQAuthApiAccessToken(
    {
      qAuthAccessToken: auth.data.token,
      auth0AccessToken
    },
    getTime(parseJSON(auth.data.expire_utc))
  );

  return auth.data.token;
};

export const createAuthEvent = (currentCustomerId, accessToken, authEvent) => {
  return deepAlphaApi.post(`/auth/events`, authEvent, {
    headers: {
      'X-Customer-ID': currentCustomerId,
      Authorization: `Bearer ${accessToken}`
    }
  });
};

export const updateUserFavoriteItem = (
  currentCustomerId,
  accessToken,
  userFavoriteItemId,
  userFavoriteItem
) => {
  return deepAlphaApi.put(
    `/users/favorite-items/${userFavoriteItemId}`,
    {
      id: userFavoriteItem.id,
      favorite_item_id: userFavoriteItem.favoriteItemId,
      favorite_item_type: userFavoriteItem.favoriteItemType,
      user_id: userFavoriteItem.userId,
      is_favorite: userFavoriteItem.isFavorite
    },
    {
      headers: {
        'X-Customer-ID': currentCustomerId,
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

export const getUserInfo = (accessToken, currentUserId) => {
  return deepAlphaApi.get(`/users/${currentUserId}/info`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

export const getCustomerConfig = (
  accessToken,
  customerId,
  language,
  cancelToken
) => {
  const api = config.USE_LEGACY_CONFIGURATION_ENDPOINT
    ? customersApi
    : customersApiV2;

  return api.get(
    queryString.stringifyUrl(
      {
        url: `customers/${customerId}`,
        query: { language }
      },
      {
        skipNull: true
      }
    ),
    {
      cancelToken,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};
