import { useHistory, useParams } from 'react-router-dom';

import { RoboAdviceAdviceSessionPages } from '../../../shared/constants';
import { usePrevNextButtonsTabs } from '../../main/services/selectors';
import Button, { ButtonType } from 'features/shared/components/button';
import { Spacing } from 'features/shared/constants/spacing';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { useErrorsStore } from 'features/sharedModules/errors/services/errorsStore';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: `${Spacing.spacing01}px`
  },
  actionButton: {
    '& + &': {
      marginLeft: Spacing.spacing01
    }
  }
});

type Props = {
  pageId: typeof RoboAdviceAdviceSessionPages[keyof typeof RoboAdviceAdviceSessionPages];
};

const ActionButtons = ({ pageId }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const history = useHistory();
  const { clientType, clientId, adviceSessionId } = useParams<{
    clientType: string;
    clientId: string;
    adviceSessionId: string;
  }>();
  const {
    roboAdviceForm: { errorMessagesEnabled }
  } = useCustomerConfig();

  const { prevPage, nextPage } = usePrevNextButtonsTabs(pageId);
  const errorsStore = useErrorsStore();

  const nexButtonClickHandler = () => {
    if (nextPage) {
      if (nextPage.tab?.disabled) {
        errorsStore.showNextButtonMessage();
      } else {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        history.push(
          routeTemplates.roboAdviceAdviceSession.build(
            clientType,
            clientId,
            adviceSessionId,
            nextPage.pageId
          )
        );
      }
    }
  };

  return (
    <div className={classes.actionButtons}>
      {prevPage && (
        <Button
          className={classes.actionButton}
          buttonType={ButtonType.secondary}
          onClick={() => {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
            history.push(
              routeTemplates.roboAdviceAdviceSession.build(
                clientType,
                clientId,
                adviceSessionId,
                prevPage.pageId
              )
            );
          }}
        >
          {i18n('roboAdvice.navigation.back')}
        </Button>
      )}

      {nextPage && (
        <Button
          className={classes.actionButton}
          disabled={nextPage.tab?.disabled && !errorMessagesEnabled}
          onClick={nexButtonClickHandler}
        >
          {i18n('roboAdvice.navigation.next')}
        </Button>
      )}
    </div>
  );
};

export default ActionButtons;
