import axios from 'axios';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';

import { getUserPageLanguage } from '../../../../../pageLanguage/main/components/usePageLanguage';
import { resendOtpPin, resetOtpPin } from '../../../main/api';
import { usePreMeetingSurveyForm } from 'features/roboAdvice/client/adviceSessionsList/components/preMeetingSurvey/form';
import { usePreMeetingSurveyStore } from 'features/roboAdvice/client/adviceSessionsList/services/preMeetingSurveyStore';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export const useResendOneTimePassword = () => {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const i18n = useI18n();
  const dispatch = useDispatch();

  const resendOneTimePassword = async (sessionId: string) => {
    const { originalInvestorPhoneNumber } = usePreMeetingSurveyStore.getState();

    try {
      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        undefined
      );

      const { values: formValues } = usePreMeetingSurveyForm.getState();

      if (
        originalInvestorPhoneNumber &&
        formatPhoneNumberIntl(originalInvestorPhoneNumber) ===
          formatPhoneNumberIntl(formValues.investorPhoneNumber)
      ) {
        await resendOtpPin(accessToken, undefined, sessionId);
      } else {
        await resetOtpPin(accessToken, undefined, sessionId, {
          phoneNumber: formValues.investorPhoneNumber,
          language: getUserPageLanguage() ?? 'en'
        });
      }

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n(
            'roboAdvice.client.preMeetingSurvey.resendOneTimePasswordSuccessMessage'
          ),
          type: NotificationTypes.success
        })
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.client.preMeetingSurvey.resendOneTimePasswordErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );
      }
    }
  };

  return resendOneTimePassword;
};
