import * as R from 'ramda';
import React from 'react';

export function useTableSelectionWidthAligner({
  getCellWidth = c => c.offsetWidth
} = {}) {
  const tableRef = React.useRef();

  React.useEffect(() => {
    const table = tableRef.current;

    if (R.isNil(table)) {
      return;
    }

    const bodyRows = table.querySelectorAll('[data-id="table-body-row"]');
    const bodyColumns = [];

    for (let i = 0; i < R.length(bodyRows); i++) {
      const row = bodyRows[i];
      const rowCells = row.querySelectorAll(
        '[data-id="number-cell-value-container"][data-selected="true"]'
      );

      for (let j = 0; j < R.length(rowCells); j++) {
        const cell = rowCells[j];
        const cellWidth = getCellWidth(
          cell.querySelector('[data-id="number-cell-value"]')
        );

        if (R.isNil(bodyColumns[j])) {
          bodyColumns[j] = { cells: [cell], maxWidth: cellWidth };
        } else {
          bodyColumns[j].cells.push(cell);

          if (cellWidth > bodyColumns[j].maxWidth) {
            bodyColumns[j].maxWidth = cellWidth;
          }
        }
      }
    }

    for (let i = 0; i < R.length(bodyColumns); i++) {
      const column = bodyColumns[i];
      const columnCells = column.cells;

      for (let j = 0; j < R.length(columnCells); j++) {
        const cell = columnCells[j];
        cell.style.width = `${column.maxWidth}px`;
      }
    }
  });

  return tableRef;
}
