import { useField } from './useField';
import GranulationBar, {
  Props
} from 'features/shared/components/granulationBar';

type FinalFormProps = {
  name: string;
  fieldConfig?: any;
};

const FinalFormGranulationBar = ({
  name,
  fieldConfig,
  ...restProps
}: FinalFormProps & Omit<Props, 'value' | 'onChange'>) => {
  const {
    input: { value, onChange }
  } = useField(name, fieldConfig);

  return <GranulationBar value={value} onChange={onChange} {...restProps} />;
};

export default FinalFormGranulationBar;
