import { isNil } from 'ramda';

import { getYearsRange } from 'features/shared/components/datePicker/constants';
import { ClientTypes } from 'features/shared/constants/session';
import {
  RuleTypes,
  FieldComponentTypes
} from 'features/sharedModules/declarativeForm/constants';

export const FieldSections = {
  personAssets: 'personAssets',
  personDebt: 'personDebt',
  personLiquidity: 'personLiquidity',
  companyInvestableAssets: 'companyInvestableAssets',
  companyAccountingFigures: 'companyAccountingFigures',
  companyDebt: 'companyDebt',
  companyLiquidity: 'companyLiquidity'
};

export const companyClientTypeRule = {
  type: RuleTypes.allFieldsEqual,
  fields: [['clientInformation.clientType', ClientTypes.company]]
};

export const personClientTypeRule = {
  type: RuleTypes.allFieldsEqual,
  fields: [['clientInformation.clientType', ClientTypes.person]]
};

export const getFinancialSituationFields = (
  fields,
  fieldSection,
  clientType,
  isENINDataExternal = false,
  isEninEnabled = false
) => {
  return fields
    .filter(({ enabled }) => enabled)
    .sort(({ priority: p1 }, { priority: p2 }) => p1 - p2)
    .map(({ id, label, required, isNegativeNumberEnabled }) => {
      return [
        clientType === ClientTypes.person
          ? `personFinancialSituation.${id}`
          : `companyFinancialSituation.${id}`,
        {
          calcProgress: {
            when:
              clientType === ClientTypes.person
                ? personClientTypeRule
                : companyClientTypeRule
          },
          render:
            id === 'accountingYear'
              ? {
                  componentType: FieldComponentTypes.select,
                  fieldSection: fieldSection,
                  label,
                  required,
                  options: getYearsRange('history', 3, 0).map(year => ({
                    label: year,
                    value: year
                  })),
                  isDisabled: isENINDataExternal && isEninEnabled
                }
              : {
                  componentType: FieldComponentTypes.detalizedNumberInput,
                  fieldSection: fieldSection,
                  label,
                  required,
                  isNegativeNumberEnabled: !!isNegativeNumberEnabled
                },
          validate: {}
        }
      ];
    });
};

export const getPersonLiquidity = fields => {
  return fields
    .filter(({ enabled }) => enabled)
    .sort(({ priority: p1 }, { priority: p2 }) => p1 - p2)
    .map(
      ({
        id,
        label,
        type,
        layout,
        placeholder,
        items,
        expectingFinancialNoteRule,
        required,
        isNegativeNumberEnabled,
        dateType,
        dateFormat
      }) => {
        const fieldRequired = !isNil(required)
          ? required
          : id === 'expectingFinancialChangesNote';
        return [
          `personFinancialSituation.${id}`,
          {
            calcProgress: {
              when: personClientTypeRule
            },
            render: {
              fieldSection: FieldSections.personLiquidity,
              componentType: type,
              label,
              items: items?.map(item => {
                return item.textField
                  ? {
                      ...item,
                      textField: {
                        ...item.textField,
                        name: `personFinancialSituation.${item.textField.name}`
                      }
                    }
                  : item;
              }),
              layout,
              placeholder,
              when: expectingFinancialNoteRule,
              required: fieldRequired,
              dateType,
              dateFormat,
              isNegativeNumberEnabled:
                id === 'monthlySurplus' ? false : !!isNegativeNumberEnabled
            },
            validate: {}
          }
        ];
      }
    );
};

export const getCompanyLiquidity = fields => {
  return fields
    .filter(({ enabled }) => enabled)
    .sort(({ priority: p1 }, { priority: p2 }) => p1 - p2)
    .map(
      ({
        id,
        label,
        type,
        layout,
        items,
        placeholder,
        expectingFinancialNoteRule,
        required,
        isNegativeNumberEnabled,
        dateType,
        dateFormat
      }) => {
        const fieldRequired = !isNil(required)
          ? required
          : id === 'expectingFinancialChangesNote';
        return [
          `companyFinancialSituation.${id}`,
          {
            calcProgress: {
              when: companyClientTypeRule
            },
            render: {
              fieldSection: FieldSections.companyLiquidity,
              componentType: type,
              label,
              items: items?.map(item => {
                return item.textField
                  ? {
                      ...item,
                      textField: {
                        ...item.textField,
                        name: `companyFinancialSituation.${item.textField.name}`
                      }
                    }
                  : item;
              }),
              layout,
              placeholder,
              when: expectingFinancialNoteRule,
              required: fieldRequired,
              dateType,
              dateFormat,
              isNegativeNumberEnabled:
                id === 'monthlySurplus' ? false : !!isNegativeNumberEnabled
            },
            validate: {}
          }
        ];
      }
    );
};
