import MaterialSwitch from '@material-ui/core/Switch';

import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const uesStyles = createUseStyles(theme => ({
  switch: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  root: {
    width: 40,
    height: 20,
    padding: 0
  },
  switchBase: {
    padding: '4px',
    color: theme.boxBackgroundColor_1,
    '&$checked': {
      color: theme.boxBackgroundColor_1,
      '& + $track': {
        backgroundColor: theme.accentColor,
        opacity: 1,
        border: 'none'
      }
    },
    '&$disabled$checked + $track': {
      backgroundColor: theme.inputFillFocusColor
    }
  },
  thumb: {
    width: 12,
    height: 12
  },
  track: {
    borderRadius: 10,
    backgroundColor: theme.secondaryColor,
    opacity: 1
  },
  checked: {},
  disabled: {},
  focusVisible: {}
}));

const Switch = ({ checked, onChange, styles = {}, ...restProps }) => {
  const classes = uesStyles();

  return (
    <MaterialSwitch
      checked={checked}
      onChange={onChange}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled,
        ...styles
      }}
      {...restProps}
    />
  );
};

export default Switch;
