import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

export const useStyles = createUseStyles(theme => ({
  header: {
    color: theme.primaryColor,
    fontSize: Typography.heading2.size,
    lineHeight: Typography.heading2.lineHeight,
    marginBottom: Spacing.spacing00
  },
  description: {
    color: theme.secondaryColor,
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight
  },
  section: {
    marginBottom: Spacing.spacing01,
    paddingBottom: Spacing.spacing01,
    borderBottom: `1px solid ${theme.secondaryColor}`
  },
  contactInformationRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    gap: Spacing.spacing04
  },
  switchLabel: {
    color: theme.primaryColor,
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,
    marginLeft: Spacing.spacing01
  },
  textInputsContainer: {
    display: 'flex',
    gap: Spacing.spacing01,
    marginTop: Spacing.spacing01
  },
  textInput: {
    // Hack to replace !important
    '&$textInput': {
      marginTop: 0
    }
  },
  checkboxContainer: {
    marginLeft: 0
  },
  checkboxLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: Spacing.spacing00,
    color: theme.primaryColor,
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,
    marginLeft: 10
  },
  checkboxBox: {
    marginTop: Spacing.spacing01,
    marginBottom: Spacing.spacing01
  },
  textarea: {
    marginTop: Spacing.spacing01,
    width: '400px'
  },
  phoneTextareaCounter: {
    width: '400px'
  },
  switchLabelDisabled: {
    color: theme.disabledTextColor
  },
  errorMessage: {
    marginTop: Spacing.spacing01,
    color: theme.errorNegativeColor,
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight
  }
}));
