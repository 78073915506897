import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  useHolisticViewStore,
  selectors
} from '../../../services/holisticViewStore';
import { useReadCashflowData } from './useReadCashflowData';
import { useCashflowChartStore } from 'features/roboAdvice/adviceSession/cashflowChart';
import {
  useReadDataListener,
  PageStatuses
} from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import CashflowChartVisual from 'features/shared/analyticsComponents/cashflowChart/visual';
import sessionSelectors from 'features/shared/services/session/selectors';

const CashflowChart = ({ isSelected }) => {
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const {
    goalsData,
    cashflowGoalChartsData,
    cashflowForecastChartData,
    tableData,
    setCashflowGoalChartsData,
    setCashflowForecastChartData,
    setTableData
  } = useCashflowChartStore();
  const selectedGoalsIds = useHolisticViewStore(selectors.getSelectedGoalsIds);
  const status =
    useHolisticViewStore.getState().proposalSectionStatuses.readCashflowData;

  useReadDataListener(status, isSelected, useReadCashflowData());

  useEffect(() => {
    setCashflowGoalChartsData(selectedGoalsIds);
    setCashflowForecastChartData(selectedGoalsIds);
    setTableData(selectedGoalsIds);
  }, [goalsData, selectedGoalsIds]);

  return (
    <CashflowChartVisual
      cashflowGoalChartsData={cashflowGoalChartsData}
      cashflowForecastChartData={cashflowForecastChartData}
      cashflowTableData={tableData}
      cultureCode={cultureCode}
      isLoading={status === PageStatuses.pending}
      isError={status === PageStatuses.failed}
    />
  );
};

export default CashflowChart;
