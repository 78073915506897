import classNames from 'classnames';
import * as R from 'ramda';
import React, { Fragment } from 'react';

import Icon from 'features/shared/components/icon/index.js';
import Link from 'features/shared/components/link/index.js';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const indexedMap = R.addIndex(R.map);

const borderRadius = {
  [BorderRadiusTypes.rounded]: '4px',
  [BorderRadiusTypes.sharp]: '4px'
};

const useStyles = createUseStyles(theme => ({
  menu: {
    minWidth: '20rem',
    borderRadius: borderRadius[theme.borderRadiusType],
    backgroundColor: theme.inputFillFocusColor,
    overflow: 'hidden'
  },
  separator: {
    borderBottom: `1px solid ${theme.secondaryColor}`,
    margin: '0 2.4rem'
  },
  menuLink: {
    padding: '1.2rem 2.4rem',
    cursor: 'pointer',
    color: theme.primaryColor,
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      backgroundColor: theme.hoverInputStrokeColor
    }
  },
  menuLinkDisabled: {
    color: theme.disabledTextColor,

    cursor: 'default',

    '&:hover': {
      backgroundColor: theme.inputFillFocusColor
    }
  },
  menuLinkIcon: {
    marginRight: '1.6rem',
    fontSize: '2rem',
    lineHeight: '2.4rem',
    transform: 'rotate(0.03deg)' // fix of issue: icon small size causes pixelated icon
  }
}));

const Menu = ({
  items,
  classNames: classNamesProp = {},
  showSeparator = false
} = {}) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.menu, classNamesProp.menu)}>
      {indexedMap(
        (item, index, collection) => (
          <Fragment key={index}>
            <Link
              className={classNames(classes.menuLink, classNamesProp.menuLink, {
                [classes.menuLinkDisabled]: item.disabled
              })}
              to={item.to}
              onClick={
                item.disabled
                  ? e => {
                      e.preventDefault();
                    }
                  : item.onClick
              }
            >
              {item.iconType && (
                <Icon className={classes.menuLinkIcon} type={item.iconType} />
              )}
              <span>{item.title}</span>
            </Link>
            {showSeparator && index < collection.length - 1 && (
              <div className={classes.separator} />
            )}
          </Fragment>
        ),
        items
      )}
    </div>
  );
};

export default Menu;
