import classNames from 'classnames';

import { useGoalsStore } from '../../shared/services/goalsStore';
import Button from 'features/shared/components/button';
import { Spacing } from 'features/shared/constants/spacing';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  selectPortfolioButtons: {
    marginBottom: Spacing.spacing01,
    display: 'flex'
  },
  selectPortfolioButton: {
    marginRight: Spacing.spacing01,
    '&$disabled': {
      opacity: 0.2
    }
  },
  disabled: {
    color: theme.disabledTextColor
  },
  removeIcon: {
    verticalAlign: 'middle',
    lineHeight: '1.8em',
    marginLeft: Spacing.spacing00
  }
}));

const SelectPortfolioButtons = () => {
  const classes = useStyles();
  const { goals, setGoalStatus } = useGoalsStore();

  return (
    <div className={classes.selectPortfolioButtons}>
      {goals.map(goal => (
        <Button
          key={goal.goalId}
          className={classNames(classes.selectPortfolioButton, {
            [classes.disabled]: !goal.isGoalSelected
          })}
          onClick={() => {
            setGoalStatus(goal.goalId, !goal.isGoalSelected);
          }}
          withCrossIcon={goal.isGoalSelected}
        >
          {goal.name}
        </Button>
      ))}
    </div>
  );
};

export default SelectPortfolioButtons;
