import * as R from 'ramda';
import create from 'zustand';

import { PageStatus } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { Goal } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { ClientType } from 'features/shared/constants/session';

export type AdviceSession = {
  name: string;
  sessionId: string;
};

type FinancialSituationData = {
  adviceSessionId: string;
  name: string;
  assets: { name: string; y: number }[];
  totalAssets: number;
  totalDebt: number;
  dropInPercent: number;
  dropInStd: number;
  educationWork: number;
  expectationOfRisk: number;
  experience: number;
  riskStrategy: number;
};

export type Client = {
  id: string;
  type: ClientType;
  name: string;
  email: string | null;
  advisorId: string | null;
  advisorName: string | null;
  adviceSessions: AdviceSession[];
  selectedAdviceSessionId?: string;
  isSelected: boolean;
  goals?: Goal[];
  financialSituationData?: FinancialSituationData | null;
};

export type ProposalSectionStatuses = {
  readCashflowData?: PageStatus;
  readExpectedPathChartData?: PageStatus;
  readExpectedValueChartData?: PageStatus;
  readEfficientFrontierChartData?: PageStatus;
  readHistoricalReturn3MChartData?: PageStatus;
  readHistoricalReturnYTDChartData?: PageStatus;
  readHistoricalReturn1YChartData?: PageStatus;
  readHistoricalReturn3YChartData?: PageStatus;
  readHistoricalReturn5YChartData?: PageStatus;
  readHistoricalReturn10YChartData?: PageStatus;
  readCostChartData?: PageStatus;
};

type HolisticViewData = {
  availableClients: Client[];
  searchedClients: Client[];
  isAddCustomerPopupOpen: boolean;
  isSearchModeActive: boolean;
  isReadClientsPending: boolean;
  isReadAdviceSessionsPending: boolean;
  isReadGoalsDataPending: boolean;
  isReadSelectedAdviceSessionsPending: boolean;
  isReadRiskScoresPending: boolean;
  proposalSectionStatuses: ProposalSectionStatuses;
};

type HolisticViewApi = {
  initialize: (data: HolisticViewData) => void;
  reset: () => void;
  setAvailableClients: (clients: Client[]) => void;
  setSelectedClient: (clientId: string, value?: boolean) => void;
  setSearchedClients: (clientIds: string[]) => void;
  setAdviceSessions: (
    clientId: string,
    adviceSessions: AdviceSession[]
  ) => void;
  setSelectedAdviceSession: (
    clientId: string,
    adviceSessionId: string | undefined
  ) => void;
  setGoals: (clientId: string, goals: Goal[] | undefined) => void;
  setFinancialSituationData: (
    clientId: string,
    financialSituationData: FinancialSituationData | null
  ) => void;
  setGoalRiskScore: (
    clientId: string,
    goalId: string,
    riskScoreValues: {
      purposeAndRiskScore: string | null;
      riskScore: string | null;
    }
  ) => void;
  setIsGoalSelected: (
    clientId: string,
    goalId: string,
    status: boolean
  ) => void;
  setIsAddCustomerPopupOpen: (val: boolean) => void;
  setIsReadClientsPending: (val: boolean) => void;
  setIsSearchModeActive: (isSearchModeActive: boolean) => void;
  setIsReadAdviceSessionsPending: (val: boolean) => void;
  setIsReadGoalsDataPending: (val: boolean) => void;
  setIsReadSelectedAdviceSessionsPending: (val: boolean) => void;
  setIsReadRiskScoresPending: (val: boolean) => void;
  setProposalSectionStatus: <K extends keyof ProposalSectionStatuses>(
    key: K,
    value: ProposalSectionStatuses[K]
  ) => void;
  changeProposalSectionStatuses: (
    statuses: Partial<ProposalSectionStatuses>
  ) => void;
};

export type HolisticViewState = HolisticViewData & HolisticViewApi;

const defaultData = {
  availableClients: [],
  searchedClients: [],
  isAddCustomerPopupOpen: false,
  isReadClientsPending: false,
  isSearchModeActive: false,
  isReadAdviceSessionsPending: false,
  isReadGoalsDataPending: false,
  isReadSelectedAdviceSessionsPending: false,
  isReadRiskScoresPending: false,
  proposalSectionStatuses: {}
};

export const useHolisticViewStore = create<HolisticViewState>(set => ({
  ...defaultData,
  initialize(data: HolisticViewData) {
    set(data);
  },
  reset() {
    set(defaultData);
  },
  setIsAddCustomerPopupOpen(val) {
    set({ isAddCustomerPopupOpen: val });
  },
  setIsReadClientsPending(val) {
    set({ isReadClientsPending: val });
  },
  setIsSearchModeActive(isSearchModeActive) {
    set({ isSearchModeActive });
  },
  setIsReadAdviceSessionsPending(val) {
    set({ isReadAdviceSessionsPending: val });
  },
  setAvailableClients(clients) {
    set({ availableClients: clients });
  },
  setIsReadGoalsDataPending(clients) {
    set({ isReadGoalsDataPending: clients });
  },
  setIsReadSelectedAdviceSessionsPending(val) {
    set({ isReadSelectedAdviceSessionsPending: val });
  },
  setIsReadRiskScoresPending(val) {
    set({ isReadRiskScoresPending: val });
  },
  setProposalSectionStatus(key, value) {
    set(R.set(R.lensPath(['proposalSectionStatuses', key]), value), true);
  },
  changeProposalSectionStatuses(proposalSectionStatuses) {
    set(
      R.over(
        R.lensProp('proposalSectionStatuses'),
        R.mergeLeft(proposalSectionStatuses)
      ),
      true
    );
  },
  setSelectedClient(clientId, value = true) {
    set(state => {
      const foundClientIndex = state.availableClients.findIndex(
        c => c.id === clientId
      );
      if (foundClientIndex !== -1) {
        const newClients = [...state.availableClients];
        newClients.splice(foundClientIndex, 1, {
          ...state.availableClients[foundClientIndex],
          isSelected: value
        });
        return { availableClients: newClients };
      }
      return state;
    });
  },
  setSearchedClients(clientIds) {
    set(state => {
      const searchedClients = clientIds.map(
        index => state.availableClients.find(c => c.id === index)!
      );
      return { searchedClients };
    });
  },
  setAdviceSessions(clientId, adviceSessions) {
    set(state => {
      const foundClientIndex = state.availableClients.findIndex(
        c => c.id === clientId
      );
      if (foundClientIndex !== -1) {
        const newClients = [...state.availableClients];
        newClients.splice(foundClientIndex, 1, {
          ...state.availableClients[foundClientIndex],
          adviceSessions
        });
        return { availableClients: newClients };
      }
      return state;
    });
  },
  setSelectedAdviceSession(clientId, adviceSessionId) {
    set(state => {
      const foundClientIndex = state.availableClients.findIndex(
        c => c.id === clientId
      );
      if (foundClientIndex !== -1) {
        const newClients = [...state.availableClients];
        newClients.splice(foundClientIndex, 1, {
          ...state.availableClients[foundClientIndex],
          selectedAdviceSessionId: adviceSessionId,
          goals: []
        });
        return { availableClients: newClients };
      }
      return state;
    });
  },
  setGoals(clientId, goals) {
    set(state => {
      const foundClientIndex = state.availableClients.findIndex(
        c => c.id === clientId
      );
      if (foundClientIndex !== -1) {
        const newClients = [...state.availableClients];
        newClients.splice(foundClientIndex, 1, {
          ...state.availableClients[foundClientIndex],
          goals
        });
        return { availableClients: newClients };
      }
      return state;
    });
  },
  setFinancialSituationData(clientId, financialSituationData) {
    set(state => {
      const foundClientIndex = state.availableClients.findIndex(
        c => c.id === clientId
      );
      if (foundClientIndex !== -1) {
        const newClients = [...state.availableClients];
        newClients.splice(foundClientIndex, 1, {
          ...state.availableClients[foundClientIndex],
          financialSituationData
        });
        return { availableClients: newClients };
      }
      return state;
    });
  },
  setGoalRiskScore(clientId, goalId, riskScoreValues) {
    set(state => {
      const newClients = state.availableClients.map(c => {
        if (c.id === clientId && c.goals) {
          return {
            ...c,
            goals: c.goals.map(g => {
              if (g.goalId === goalId) {
                return {
                  ...g,
                  data: {
                    ...g.data,
                    ...riskScoreValues
                  }
                };
              }
              return g;
            })
          };
        }
        return c;
      });

      return { availableClients: newClients };
    });
  },
  setIsGoalSelected(clientId, goalId, status) {
    set(state => {
      const newClients = state.availableClients.map(c => {
        if (c.id === clientId && c.goals) {
          return {
            ...c,
            goals: c.goals.map(g => {
              if (g.goalId === goalId) {
                return {
                  ...g,
                  isGoalSelected: status
                };
              }
              return g;
            })
          };
        }
        return c;
      });

      return { availableClients: newClients };
    });
  }
}));

export const selectors = {
  getSelectedClients: (state: HolisticViewState) =>
    state.availableClients.filter(c => c.isSelected),

  getSelectedGoals: (state: HolisticViewState) =>
    state.availableClients
      .filter(client => client.isSelected)
      .map(client => client.goals || [])
      .flat()
      .filter(goal => goal.isGoalSelected),

  getSelectedGoalsIds: (state: HolisticViewState) =>
    state.availableClients
      .filter(client => client.isSelected)
      .map(client => client.goals || [])
      .flat()
      .filter(goal => goal.isGoalSelected)
      .map(goal => goal.goalId),

  getSelectedGoalsNames: (state: HolisticViewState) =>
    state.availableClients
      .filter(client => client.isSelected)
      .map(client => client.goals || [])
      .flat()
      .filter(goal => goal.isGoalSelected)
      .map(goal => goal.name)
};
