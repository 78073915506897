import React from 'react';

import {
  useHolisticViewStore,
  selectors
} from '../../../services/holisticViewStore';
import { useCostChartStore } from 'features/roboAdvice/adviceSession/costChart';
import { prepareCostChartData } from 'features/shared/analyticsComponents/costChart/mapping';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export const useInitPage = () => {
  const i18n = useI18n();
  const selectedGoalsIds = useHolisticViewStore(selectors.getSelectedGoalsIds);
  const costChartStore = useCostChartStore();

  React.useEffect(() => {
    const selectedGoalsData = costChartStore.goalsData.filter(({ goalId }) =>
      selectedGoalsIds.includes(goalId)
    );

    const { summary, chartData, firstYearMerged, lastYearMerged, fundDetails } =
      prepareCostChartData({ selectedGoalsData, i18n });

    costChartStore.setCostData({
      chartData,
      tableData: {
        firstYear: firstYearMerged,
        fundDetails,
        lastYear: lastYearMerged,
        summary
      }
    });
  }, [selectedGoalsIds, costChartStore.goalsData]);
};

export default useInitPage;
