import axios from 'axios';
import * as R from 'ramda';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Statuses } from '../../../shared/constants';
import { usePreviewStore } from '../services/previewStore';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import {
  getQAuthAccessToken,
  getCustomerConfig
} from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import { mapServerCustomerConfigToClient } from 'features/shared/services/session/mapping';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useReadCustomerConfig() {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const currentUserCustomerCode = useSelector(
    sessionSelectors.getCurrentUserCustomerCode
  );
  const dispatch = useDispatch();

  const cancelTokenSourceRef = React.useRef<any>();
  const readCustomerConfig = async () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const previewStore = usePreviewStore.getState();

    try {
      previewStore.setPageStatus('readCustomerConfig', Statuses.pending);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const response = await getCustomerConfig(
        accessToken,
        currentUserCustomerCode,
        getUserPageLanguage(),
        cancelTokenSource.token
      );

      previewStore.setCustomerConfig(
        mapServerCustomerConfigToClient(response.data)
      );

      previewStore.setPageStatus('readCustomerConfig', Statuses.succeed);
    } catch (error) {
      if (!axios.isCancel(error)) {
        previewStore.setPageStatus('readCustomerConfig', Statuses.failed);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'admin.appAdmin.advisorSolution.preview.readCustomerConfigErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  React.useEffect(() => {
    return () => {
      if (!R.isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
    };
  }, []);

  return readCustomerConfig;
}
