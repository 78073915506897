import axios from 'axios';
import * as R from 'ramda';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getSettings } from '../../../shared/api';
import { Statuses } from '../../../shared/constants';
import { visualsForm } from '../services/visualsForm';
import { useVisualsStore } from '../services/visualsStore';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useReadSettings() {
  const i18n = useI18n();
  const auth0AccessToken: string = useSelector(
    sessionSelectors.getAuth0AccessToken
  );
  const dispatch = useDispatch();

  const cancelTokenSourceRef = React.useRef<any>();
  const readSettings = async () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const visualsStore = useVisualsStore.getState();

    try {
      visualsStore.setPageStatus('readSettings', Statuses.pending);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const response = await getSettings(
        accessToken,
        cancelTokenSource.token,
        'deepalpha',
        'styles'
      );

      visualsForm.initialize(
        response.data.settings.reduce((acc, i) => {
          const name = i.name.replace('styles.', '');

          if (name === 'logoSrc') {
            return {
              ...acc,
              logo:
                R.isNil(i.value) || R.isEmpty(i.value)
                  ? undefined
                  : { url: i.value }
            };
          }

          return { ...acc, [name]: i.value };
        }, {})
      );

      visualsStore.setPageStatus('readSettings', Statuses.succeed);
    } catch (error) {
      if (!axios.isCancel(error)) {
        visualsStore.setPageStatus('readSettings', Statuses.failed);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'admin.appAdmin.advisorSolution.visuals.readSettingsErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  React.useEffect(() => {
    return () => {
      if (!R.isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
    };
  }, []);

  return readSettings;
}
