import classNames from 'classnames';
import * as R from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { clientsTable, OrderKeys } from '../../clientsTable';
import { Page, ActionButtons } from '../../shared/components/page';
import { useClientsStore } from '../services/clientsStore';
import { useClients } from './useClients';
import { useDeleteClients } from './useDeleteClients';
import { useInitPage } from './useInitPage';
import { useRestoreClients } from './useRestoreClients';
import CompanyIcon from 'features/shared/components/icon/companyIcon';
import PersonIcon from 'features/shared/components/icon/personIcon';
import { ClientType, ClientTypes } from 'features/shared/constants/session';
import sessionSelectors from 'features/shared/services/session/selectors';
import { formatDate } from 'features/shared/utils/dateTime.js';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import TableComponent from 'features/sharedModules/table/components/table';
import TableBody from 'features/sharedModules/table/components/tableBody';
import TableBodyRow from 'features/sharedModules/table/components/tableBodyRow';

const useStyles = createUseStyles({
  typeCell: {
    textAlign: 'center'
  }
});

const TypeIcon = ({ type }: { type: ClientType }) => {
  if (type === ClientTypes.person) {
    return <PersonIcon />;
  }

  if (type === ClientTypes.company) {
    return <CompanyIcon />;
  }

  return null;
};

const Clients = () => {
  const i18n = useI18n();
  const classes = useStyles();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const clientsStore = useClientsStore();
  const { items, allItemIds, totalItemsNumber } = useClients();
  const { selectedRowIds } = clientsTable.useTableStore();
  const deleteClients = useDeleteClients();
  const restoreClients = useRestoreClients();

  useInitPage();

  return (
    <Page>
      <ActionButtons
        selectedRowsNumber={selectedRowIds.length}
        isRestorePending={clientsStore.isRestoreClientsPending}
        isDeletePending={clientsStore.isDeleteClientsPending}
        restoreConfirmationMessage={i18n(
          'admin.trashCan.clients.restoreClientsConfirmationMessage'
        )}
        deleteConfirmationMessage={
          <>
            <div>
              {i18n(
                'admin.trashCan.clients.deleteClientsConfirmationMessage_1'
              )}
            </div>
            <div>
              {i18n(
                'admin.trashCan.clients.deleteClientsConfirmationMessage_2'
              )}
            </div>
          </>
        }
        restoreButtonOnClick={restoreClients}
        deleteButtonOnClick={deleteClients}
      />
      <TableComponent
        module={clientsTable}
        isPaginationShown={true}
        totalItemsNumber={totalItemsNumber}
        isSelectionShown={true}
        allRowIds={allItemIds}
        isLoading={clientsStore.isReadClientsPending}
        header={[
          {
            title: i18n('admin.trashCan.clients.table.type'),
            className: classes.typeCell,
            isOrderable: true,
            orderKey: OrderKeys.type
          },
          {
            title: i18n('admin.trashCan.clients.table.client'),
            isOrderable: true,
            orderKey: OrderKeys.name
          },
          {
            title: i18n('admin.trashCan.clients.table.advisor'),
            isOrderable: true,
            orderKey: OrderKeys.advisorName
          },
          {
            title: i18n('admin.trashCan.clients.table.lastActivityDate'),
            isOrderable: true,
            orderKey: OrderKeys.lastActivityDate
          },
          {
            title: i18n('admin.trashCan.clients.table.softDeleteDate'),
            isOrderable: true,
            orderKey: OrderKeys.softDeleteDate
          }
        ]}
      >
        <TableBody
          noDataTitle={i18n('admin.trashCan.clients.table.noClients')}
          cellsNumber={5}
        >
          {R.map(
            i => (
              <TableBodyRow key={i.id} id={i.id}>
                {({ bodyRowCellClassName }) => (
                  <>
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.typeCell
                      )}
                    >
                      <TypeIcon type={i.type} />
                    </td>
                    <td className={bodyRowCellClassName}>{i.name}</td>
                    <td className={bodyRowCellClassName}>{i.advisorName}</td>
                    <td className={bodyRowCellClassName}>
                      {formatDate(cultureCode, i.lastActivityDate)}
                    </td>
                    <td className={bodyRowCellClassName}>
                      {formatDate(cultureCode, i.softDeleteDate)}
                    </td>
                  </>
                )}
              </TableBodyRow>
            ),
            items
          )}
        </TableBody>
      </TableComponent>
    </Page>
  );
};

export default Clients;
