import { isEmpty, isNil } from 'ramda';

import RadiosVisual, { Props } from './visual';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { ConfigurableComponent } from 'features/sharedModules/declarativeForm/components';
import { FieldComponentTypes } from 'features/sharedModules/declarativeForm/constants';
import { getErrorText } from 'features/sharedModules/finalForm/components/shared';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  radioTextField: {
    margin: '15px 0'
  }
});

export const Radios = ({
  value,
  items,
  error,
  touched,
  additionalErrorCondition,
  ...restProps
}: Props) => {
  const i18n = useI18n();
  const classes = useStyles();

  const isErrorShown =
    touched &&
    !isNil(error) &&
    !isEmpty(error) &&
    (additionalErrorCondition === undefined || additionalErrorCondition);
  const errorText = isErrorShown ? getErrorText(i18n, error) : '';

  const itemsWithConfigurableComponents = items.map(item => ({
    ...item,
    component:
      item.textField && value === item.activeValue ? (
        <ConfigurableComponent
          key={item.textField.name}
          name={item.textField.name}
          config={{
            componentType: FieldComponentTypes.textInput
          }}
          sectionClassName={classes.radioTextField}
          {...item.textField}
        />
      ) : null
  }));

  return (
    <RadiosVisual
      {...restProps}
      value={value}
      errorText={errorText}
      items={itemsWithConfigurableComponents}
    />
  );
};

export { type Props };

export default Radios;
