import { z } from 'zod';
import create from 'zustand';

import { templateSchema } from 'defaults/defaultReportAdvice';

export type Template = z.infer<typeof templateSchema>;

type TemplateStore = {
  template: Template | null;
};

type TemplateStoreApi = {
  setTemplate: (template: Template | null) => void;
};

type TemplateStoreState = TemplateStore & TemplateStoreApi;

const defaultData = {
  template: null
};

export const useTemplateStore = create<TemplateStoreState>(set => ({
  ...defaultData,
  setTemplate(template) {
    set({ template });
  }
}));
