import classNames from 'classnames';
import { isNil } from 'ramda';
import { FormSpy } from 'react-final-form';
import { useSelector } from 'react-redux';

import { useCostForm } from 'features/roboAdvice/adviceSession/proposal/services/costForm';
import TableComponent from 'features/shared/components/table/table.js';
import TableBody from 'features/shared/components/table/tableBody.js';
import TableBodyRow from 'features/shared/components/table/tableBodyRow.js';
import { Colors } from 'features/shared/constants/colors';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import {
  formatNumber,
  getNumberFromLocaleStringNumber
} from 'features/shared/utils/number';
import { getNumberInputFormat } from 'features/shared/utils/number';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import FinalFormNumberInput from 'features/sharedModules/finalForm/components/numberInput';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  container: {
    flexGrow: 1,
    marginRight: '3rem'
  },
  cell: {
    height: 59,
    lineHeight: '150%',
    padding: '2rem 2.5rem !important',
    textAlign: 'right',

    '&:first-child': {
      textAlign: 'left'
    }
  },
  headerCell: {
    '&:not(:first-child)': {
      fontSize: Typography.body3.size
    }
  },
  withIcon: {
    '&:first-child': {
      width: 60
    },

    '&:nth-child(2)': {
      textAlign: 'left'
    }
  },
  asterisk: {
    color: Colors.gray50,
    fontSize: Typography.body3.size,
    lineHeight: '1.4rem',
    marginTop: '1.2rem',
    textAlign: 'left'
  },
  input: {
    width: 90,
    textAlign: 'right',
    padding: `${Spacing.spacing00}px ${Spacing.spacing01}px`
  },
  originalValueEditMode: {
    color: theme.disabledTextColor,
    lineHeight: '150%',
    marginRight: 10,
    textDecoration: 'line-through'
  },
  lastRowEditMode: {
    paddingRight: 'calc(2.5rem + 16px + 2px) !important'
  },
  originalValue: {
    color: theme.disabledTextColor,
    lineHeight: '150%',
    marginRight: Spacing.spacing01,
    textDecoration: 'line-through'
  }
}));

const CostTable = ({
  headerTitles,
  data,
  dataKeys,
  asterisk,
  highlightLastRow = false,
  withIcon = false,
  containerClassName,
  isEditModeActive,
  overrideCosts,
  isOverrideEnabled,
  originalTableData,
  ...restProps
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const i18n = useI18n();
  const { values } = useCostForm();
  const { thousandSeparator, decimalSeparator } =
    getNumberInputFormat(cultureCode);

  return (
    <div
      className={classNames(classes.container, containerClassName)}
      {...restProps}
    >
      <FormSpy subscription={{ values: true }}>
        {({ values: spyValues }) => (
          <TableComponent
            header={headerTitles
              .filter((title, index) =>
                data.some(
                  row =>
                    !isNil(row[dataKeys[index]]) &&
                    row.fund !== i18n('portfolioChart.default.portfolio')
                )
              )
              .map(title => ({
                title,
                className: classNames(classes.cell, classes.headerCell)
              }))}
          >
            <TableBody>
              {data.map((rowData, index) => (
                <TableBodyRow key={index} className={classes.bodyRow}>
                  {({ bodyRowCellClassName }) => (
                    <>
                      {dataKeys
                        .filter(dataKey =>
                          data.some(
                            row =>
                              !isNil(row[dataKey]) &&
                              row.fund !==
                                i18n('portfolioChart.default.portfolio')
                          )
                        )
                        .map(dataKey => (
                          <td
                            className={classNames(
                              bodyRowCellClassName,
                              classes.cell,
                              {
                                [classes.withIcon]: withIcon,
                                [classes.lastRowEditMode]:
                                  isEditModeActive &&
                                  index === data.length - 1 &&
                                  overrideCosts?.includes(dataKey)
                              }
                            )}
                            style={
                              highlightLastRow && index === data.length - 1
                                ? { backgroundColor: theme.inputFillFocusColor }
                                : {}
                            }
                            key={dataKey}
                          >
                            {isEditModeActive &&
                            index < data.length - 1 &&
                            overrideCosts?.includes(dataKey) ? (
                              <FinalFormNumberInput
                                additionalInputComponent={
                                  values.overrideFundCosts?.[rowData.fund]?.[
                                    dataKey
                                  ] !==
                                  getNumberFromLocaleStringNumber(
                                    originalTableData?.[index]?.[dataKey] ||
                                      rowData[dataKey],
                                    cultureCode
                                  ) ? (
                                    <span className={classes.originalValue}>
                                      {originalTableData?.[index]?.[dataKey] ||
                                        rowData[dataKey]}
                                    </span>
                                  ) : null
                                }
                                allowNegative={false}
                                className={classes.input}
                                decimalScale={2}
                                decimalSeparator={decimalSeparator}
                                isErrorMessageHidden
                                name={`overrideFundCosts.${rowData.fund}.${dataKey}`}
                                thousandSeparator={thousandSeparator}
                                isAllowed={values => {
                                  if (
                                    dataKey ===
                                      'fundReturnCommissionPaidToClient' &&
                                    values.floatValue >
                                      spyValues.overrideFundCosts[rowData.fund]
                                        .fundManagementFee
                                  ) {
                                    return false;
                                  }

                                  return true;
                                }}
                              />
                            ) : (
                              <>
                                {isOverrideEnabled &&
                                  index === data.length - 1 &&
                                  dataKey !== 'fund' &&
                                  rowData[dataKey] !==
                                    originalTableData?.[index]?.[dataKey] && (
                                    <span className={classes.originalValue}>
                                      {originalTableData?.[index]?.[dataKey]}
                                    </span>
                                  )}
                                <span
                                  className={
                                    isOverrideEnabled &&
                                    !isNil(
                                      values.overrideFundCosts?.[
                                        rowData.fund
                                      ]?.[dataKey]
                                    ) &&
                                    rowData[dataKey] !==
                                      originalTableData?.[index]?.[dataKey]
                                      ? classes.originalValue
                                      : ''
                                  }
                                >
                                  {isNil(
                                    values.overrideFundCosts?.[rowData.fund]?.[
                                      dataKey
                                    ]
                                  ) || !isOverrideEnabled
                                    ? rowData[dataKey]
                                    : originalTableData?.[index]?.[dataKey]}
                                  {dataKey === 'value' &&
                                    isNil(rowData[dataKey]) &&
                                    !isNil(rowData.percent) &&
                                    `${rowData.percent}%`}
                                  {dataKey === 'percent' ? '%' : null}
                                </span>

                                {isOverrideEnabled &&
                                  !isNil(
                                    values.overrideFundCosts?.[rowData.fund]?.[
                                      dataKey
                                    ]
                                  ) &&
                                  rowData[dataKey] !==
                                    originalTableData?.[index]?.[dataKey] && (
                                    <span>
                                      {formatNumber(
                                        cultureCode,
                                        values.overrideFundCosts?.[
                                          rowData.fund
                                        ]?.[dataKey],
                                        2,
                                        2
                                      )}
                                    </span>
                                  )}
                              </>
                            )}
                          </td>
                        ))}
                    </>
                  )}
                </TableBodyRow>
              ))}
            </TableBody>
          </TableComponent>
        )}
      </FormSpy>
      {asterisk && <div className={classes.asterisk}>{asterisk}</div>}
    </div>
  );
};

export default CostTable;
