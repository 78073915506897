import * as R from 'ramda';
import { takeEvery, takeLatest, put, call, select } from 'redux-saga/effects';

import { types, creators } from './actions.js';
import { mapServerCustomersToClient } from './mapping.js';
import {
  setPageSettings,
  getPageSettings,
  removePageSettings
} from './pageSettingsStorage.js';
import {
  getIsChildPage,
  getIsPageOpened,
  getIsPageClosed,
  getCustomersTableRows
} from './selectors.js';
import {
  getCustomers,
  deleteCustomer as deleteCustomerApi
} from 'features/customers/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import {
  types as tableActionTypes,
  creators as tableActionCreators
} from 'features/shared/services/table/actions.js';
import tableSelectors from 'features/shared/services/table/selector.js';
import { updatePageNumber } from 'features/shared/services/table/utils.js';
import {
  succeedType,
  startedCreator,
  succeedCreator,
  failedCreator
} from 'features/shared/utils/actions.js';
import { getI18n } from 'features/sharedModules/customerConfig/services/selectors.js';
import { types as routerActionTypes } from 'framework/router/services/actions.js';

function* onLocationInit(action) {
  const state = yield select();
  const isPageOpened = getIsPageOpened(state);

  if (isPageOpened) {
    yield put(creators.opened());
  }
}

function* onLocationCleanup(action) {
  const state = yield select();
  const isPageClosed = getIsPageClosed(state);

  if (isPageClosed) {
    yield put(creators.closed());
  }

  const isChildPage = getIsChildPage(state);

  if (!isChildPage) {
    removePageSettings();
  }
}

function* onPageOpened() {
  const pageSettings = getPageSettings();

  yield put(
    creators.settingsInitialized(R.isNil(pageSettings) ? {} : pageSettings)
  );

  yield put(creators.readCustomers());
}

function* readCustomers(action) {
  yield put(startedCreator(types.READ_CUSTOMERS));

  try {
    const stateBeforeRequest = yield select();
    const currentCustomerId =
      sessionSelectors.getCurrentUserCustomerId(stateBeforeRequest);
    const accessToken =
      sessionSelectors.getAuth0AccessToken(stateBeforeRequest);

    const result = yield call(getCustomers, currentCustomerId, accessToken);

    const data = mapServerCustomersToClient(result.data);

    yield put(succeedCreator(types.READ_CUSTOMERS, data));
  } catch (error) {
    yield put(failedCreator(types.READ_CUSTOMERS));
  }
}

function* deleteCustomer(action) {
  const { customerId } = action.payload;
  const i18n = yield select(getI18n);

  yield put(startedCreator(types.DELETE_CUSTOMER));

  try {
    const state = yield select();
    const currentCustomerId = sessionSelectors.getCurrentUserCustomerId(state);
    const accessToken = sessionSelectors.getAuth0AccessToken(state);

    yield call(deleteCustomerApi, currentCustomerId, accessToken, customerId);

    yield put(succeedCreator(types.DELETE_CUSTOMER));

    yield put(
      notificationActionCreators.showNotification({
        message: i18n('customers.deleteCustomerSuccessMessage'),
        type: NotificationTypes.success
      })
    );
  } catch (error) {
    yield put(failedCreator(types.DELETE_CUSTOMER));

    yield put(
      notificationActionCreators.showNotification({
        message: i18n('customers.deleteCustomerErrorMessage'),
        type: NotificationTypes.error
      })
    );
  }
}

function* savePageSettings(action) {
  const state = yield select();
  const tableState = tableSelectors.getTable('customersTable_state', state);

  setPageSettings({
    table: tableState
  });
}

function* fitTablePageNumber(action) {
  const state = yield select();

  const rows = getCustomersTableRows(state);

  const tableState = tableSelectors.getTable('customersTable_state', state);
  const updatedTableState = updatePageNumber(rows.totalItemsNumber, tableState);

  if (updatedTableState.pageNumber !== tableState.pageNumber) {
    yield put(
      tableActionCreators.changePageNumber(
        'customersTable_state',
        updatedTableState.pageNumber
      )
    );
  }
}

export default function* () {
  yield takeEvery(routerActionTypes.LOCATION_INIT, onLocationInit);

  yield takeEvery(routerActionTypes.LOCATION_CLEANUP, onLocationCleanup);

  yield takeEvery(types.OPENED, onPageOpened);

  yield takeLatest(types.READ_CUSTOMERS, readCustomers);

  yield takeEvery(types.DELETE_CUSTOMER, deleteCustomer);

  yield takeEvery(
    action =>
      R.includes(action.type, [
        tableActionTypes.CHANGE_ORDER,
        tableActionTypes.CHANGE_PAGE_NUMBER,
        tableActionTypes.CHANGE_PAGE_SIZE,
        tableActionTypes.CHANGE_ROW_SELECTION,
        tableActionTypes.CHANGE_ALL_ROWS_SELECTION
      ]) && action.payload.tableId === 'customersTable_state',
    savePageSettings
  );

  yield takeEvery(
    [succeedType(types.READ_CUSTOMERS), succeedType(types.DELETE_CUSTOMER)],
    fitTablePageNumber
  );
}
