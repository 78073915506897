import axios, { AxiosError } from 'axios';
import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';

import { useSessionStore } from '../../session/services/sessionStore';
import {
  postSessionData,
  putSessionData,
  putCompleteSession
} from '../../shared/api';
import { SessionPatchTypes } from '../../shared/constants';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function usePatchAdviceSession() {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();

  const patchAdviceSession = async patches => {
    try {
      const filteredPatches = filterPatches(patches);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        undefined
      );

      for (const p of filteredPatches) {
        if (
          p.type === SessionPatchTypes.saveSessionData ||
          p.type === SessionPatchTypes.saveFinishedSessionData
        ) {
          if (p.isNew) {
            await postSessionData(accessToken, undefined, p.adviceSessionId, {
              payload: p.payload,
              namespace: p.namespace
            });
          } else {
            await putSessionData(
              accessToken,
              undefined,
              p.adviceSessionId,
              p.namespace,
              {
                payload: p.payload
              }
            );
          }
        } else if (p.type === SessionPatchTypes.completeSession) {
          await putCompleteSession(accessToken, undefined, p.adviceSessionId);
        }
      }
      useSessionStore.getState().setIsAdviceSessionPatched(true);
    } catch (error: unknown | AxiosError) {
      if (!axios.isCancel(error)) {
        let errorMessage = 'roboAdvice.saveAdviceSessionDataErrorMessage';
        if (axios.isAxiosError(error) && error?.response?.status === 403) {
          useSessionStore.getState().setIsAdviceSessionPatched(false);
          errorMessage = 'roboAdvice.saveAdviceSessionDataErrorNotPermitted';
        }

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(errorMessage),
            type: NotificationTypes.error
          })
        );

        throw error;
      }
    }
  };

  return patchAdviceSession;
}

export const filterPatches = (patches: any[]): any[] => {
  const saveSessionDataPatches = R.filter(
    p => p.type === SessionPatchTypes.saveSessionData,
    patches
  );
  const finalSaveSessionDataPatchesById = R.pipe<
    any[],
    any[],
    any[],
    any[],
    { [key: string]: any }
  >(
    R.map(p => p.namespace),
    R.uniq,
    R.map(n => {
      const patch = R.findLast(p => p.namespace === n, saveSessionDataPatches);
      const isAnyNamespacePatchNew = R.any(
        p => p.namespace === n && p.isNew,
        saveSessionDataPatches
      );

      return {
        ...patch,
        isNew: isAnyNamespacePatchNew
      };
    }),
    R.indexBy(p => p.id)
  )(saveSessionDataPatches);

  return R.reduce<any, any[]>(
    (acc, patch) => {
      if (patch.type === SessionPatchTypes.saveSessionData) {
        const finalPatch = finalSaveSessionDataPatchesById[patch.id];

        if (R.isNil(finalPatch)) {
          return acc;
        }

        return R.append(finalPatch, acc);
      }

      return R.append(patch, acc);
    },
    [],
    patches
  );
};
