import { z } from 'zod';

export const settingsApplicationSchema = z.object({
  enabled: z.boolean()
});

export const defaultSettingsApplication: z.infer<
  typeof settingsApplicationSchema
> = {
  enabled: false
};
