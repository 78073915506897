import classNames from 'classnames';
import React from 'react';

import { useFinancialSituationPageStyles } from './style';
import useMocks from './useMocks';
import { RenderFields } from 'features/roboAdvice/adviceSession/financialSituation/components/shared.js';
import Tabs from 'features/roboAdvice/adviceSession/main/components/tabs/index';
import { InformationSection } from 'features/roboAdvice/adviceSession/proposal/components/informationSection';
import Widget from 'features/roboAdvice/adviceSession/shared/components/widget';
import Button, { ButtonType } from 'features/shared/components/button';
import Link from 'features/shared/components/link/index.js';
import { MainLayout } from 'features/shared/components/mainLayout/index.js';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { Dropdown } from 'features/sharedModules/finalForm/components/dropdown.js';

const AnyDropdown = Dropdown as any;

const FinancialSituationPreview = () => {
  const classes = useFinancialSituationPageStyles();
  const {
    mockTabsFinancialSituation,
    mockAdviceSessionOptions,
    mockPersonAssets,
    mockPersonDebt,
    mockPersonLiquidity
  } = useMocks();
  const i18n = useI18n();

  return (
    <MainLayout location={{ pathname: routeTemplates.roboAdvice.build() }}>
      <div className={classes.header}>
        <div>
          <div className={classes.selectClientSection}>
            <div className={classes.currentClientInfo}>
              {i18n('roboAdvice.adviceSession.client')}: Test Client
            </div>
            <Link className={classes.clientsListLink} to="/">
              {i18n('roboAdvice.adviceSession.changeClient')}
            </Link>
          </div>
          <div className={classes.selectAdviceSessionSection}>
            <AnyDropdown
              value="advice-session-3"
              onChange={() => {}}
              disabled={false}
              options={mockAdviceSessionOptions}
              className={classes.adviceSessionDropdown}
              label={i18n('roboAdvice.adviceSession.adviceSession')}
              labelClassName={classes.adviceSessionDropdownLabel}
            />
          </div>
        </div>
        <div>
          <InformationSection label={''}>
            <div className={classNames(classes.containerRiskScore)}>
              <div
                className={classNames(
                  classes.sectionRiskScore,
                  classes.scoreSectionRiskScore
                )}
              >
                <div className={classes.headerRiskScore}>
                  {i18n('roboAdvice.riskScore.initialRiskScore')}
                </div>
                <div className={classes.valueRiskScore}>3</div>
              </div>
              <div
                className={classNames(
                  classes.sectionRiskScore,
                  classes.scoreSectionRiskScore
                )}
              >
                <div className={classes.headerRiskScore}>
                  {i18n('roboAdvice.riskScore.adjustment')}
                </div>
                <div className={classes.valueRiskScore}>0</div>
              </div>
              <div
                className={classNames(
                  classes.sectionRiskScore,
                  classes.scoreSectionRiskScore
                )}
              >
                <div className={classes.headerRiskScore}>
                  {i18n('roboAdvice.riskScore.finalRiskScore')}
                </div>
                <div className={classes.valueRiskScore}>3</div>
              </div>
            </div>
          </InformationSection>
        </div>
      </div>
      <Tabs
        className={classes.tabs}
        value="financial-situation"
        onChange={() => {}}
        items={mockTabsFinancialSituation}
      />

      <div>
        <div className={classes.row}>
          <Widget
            header={i18n('roboAdvice.financialSituation.assets')}
            total={{
              label: i18n('roboAdvice.financialSituation.sumAssets'),
              value: 30000
            }}
          >
            <RenderFields
              fields={mockPersonAssets}
              adjustElement={(field, element) =>
                React.cloneElement(element, {
                  disabled: false
                })
              }
            />
          </Widget>

          <Widget
            header={i18n('roboAdvice.financialSituation.debt')}
            total={{
              label: i18n('roboAdvice.financialSituation.sumDebt'),
              value: 1500
            }}
          >
            <RenderFields
              fields={mockPersonDebt}
              adjustElement={(field, element) =>
                React.cloneElement(element, {
                  disabled: false
                })
              }
            />
          </Widget>
        </div>

        <div className={classes.row}>
          <Widget header={i18n('roboAdvice.financialSituation.liquidity')}>
            <RenderFields
              fields={mockPersonLiquidity}
              adjustElement={(field, element) =>
                React.cloneElement(element, {
                  disabled: false
                })
              }
            />
          </Widget>
        </div>
      </div>

      <div className={classes.actionButtons}>
        <Button
          className={classes.actionButton}
          buttonType={ButtonType.secondary}
        >
          {i18n('roboAdvice.navigation.back')}
        </Button>

        <Button className={classes.actionButton} onClick={() => {}}>
          {i18n('roboAdvice.navigation.next')}
        </Button>
      </div>
    </MainLayout>
  );
};

export default FinancialSituationPreview;
