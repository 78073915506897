import { ValidationErrors } from 'final-form';
import create, { UseBoundStore } from 'zustand';

import { FormApi } from 'features/sharedModules/finalForm/services/finalForm';

export type FormState<FormValues> = {
  values: FormValues;
  errors: ValidationErrors;
  touched?: { [key: string]: boolean };
  submitFailed?: boolean;
  pristine?: boolean;
};

export type UseFinalFormStore<FormValues> = UseBoundStore<
  FormState<FormValues>
>;

export function createUseFinalFormAdapter<FormValues>({
  form,
  formSubscription = { values: true, errors: true, touched: true }
}: {
  form: FormApi<FormValues>;
  formSubscription?: any;
}): UseFinalFormStore<FormValues> {
  const useStore = create<FormState<FormValues>>(set => ({
    ...form.getState()
  }));

  form.subscribe(formState => {
    useStore.setState(formState);
  }, formSubscription);

  Object.assign(useStore, {
    getState: () => form.getState()
  });

  return useStore;
}
