import { useEffect } from 'react';
import Highcharts from 'highcharts';

import { useI18n } from 'modules/settings/components/settings';

const MonthKeys = [
  'shared.month.january',
  'shared.month.february',
  'shared.month.march',
  'shared.month.april',
  'shared.month.may',
  'shared.month.june',
  'shared.month.july',
  'shared.month.august',
  'shared.month.september',
  'shared.month.october',
  'shared.month.november',
  'shared.month.december'
];

const ShortMonthKeys = [
  'shared.shortMonth.january',
  'shared.shortMonth.february',
  'shared.shortMonth.march',
  'shared.shortMonth.april',
  'shared.shortMonth.may',
  'shared.shortMonth.june',
  'shared.shortMonth.july',
  'shared.shortMonth.august',
  'shared.shortMonth.september',
  'shared.shortMonth.october',
  'shared.shortMonth.november',
  'shared.shortMonth.december'
];

export const useSetHighchartsLocaleMonths = () => {
  const i18n = useI18n();

  useEffect(() => {
    Highcharts.setOptions({
      lang: {
        shortMonths: ShortMonthKeys.map(monthKey => i18n(monthKey)),
        months: MonthKeys.map(monthKey => i18n(monthKey))
      }
    });
  }, []);
};
