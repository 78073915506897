import debounce from 'lodash.debounce';
import { isNil } from 'ramda';
import { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useIsSessionReadOnly } from '../../main/services/selectors';
import { useUpdateGoal } from '../../purposeAndRisk/components/useUpdateGoal';
import { useSessionStore } from '../../session/services/sessionStore';
import { PortfolioModes } from '../../shared/constants';
import { getAvailablePortfolios } from '../../shared/services/availablePortfoliosSelectors';
import { useGoalsStore } from '../../shared/services/goalsStore';
import { NamespaceStatus } from '../types';
import { useReadAdvisoryGoalsData } from './useReadAdvisoryGoalsData';
import { useReadProductChooserRecommendation } from './useReadProductChooserRecommendation';
import { ReactComponent as QuestionMarkIcon } from 'assets/questionMark.svg';
import { useIsFollowUpEnabled } from 'features/roboAdvice/shared/components/useIsFollowUpEnabled';
import { Layouts } from 'features/roboAdvice/shared/constants';
import {
  carouselIds,
  useCarouselStore
} from 'features/shared/components/carousel/carouselStore';
import ConfigurableLabel from 'features/shared/components/configurableLabel/';
import Radios from 'features/shared/components/radios';
import { Tabs, Tab } from 'features/shared/components/tabs';
import Textarea from 'features/shared/components/textArea/textareaAutosize';
import Tooltip from 'features/shared/components/tooltip';
import { NotificationTypes } from 'features/shared/constants/notification';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions';
import { getTranslation } from 'features/shared/utils/translations';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { useErrorsStore } from 'features/sharedModules/errors/services/errorsStore';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  sectionHeader: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    fontWeight: FontWeights.medium,
    marginBottom: Spacing.spacing01,
    display: 'flex',
    alignItems: 'center',
    gap: 2
  },
  productPlatformTitle: {
    marginBottom: Spacing.spacing01,
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    fontWeight: FontWeights.medium
  },
  section: {
    width: 436,
    '& + &': {
      marginTop: Spacing.spacing02
    }
  },
  textarea: {
    width: '437px',
    padding: `${Spacing.spacing00}px ${Spacing.spacing01}px`
  },
  tabs: {
    marginBottom: Spacing.spacing02
  },
  tab: {
    margin: `0 ${Spacing.spacing01}px`,
    padding: '0',
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,
    fill: theme.accentColor
  },
  tabLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: FontWeights.medium,
    columnGap: Spacing.spacing00
  },
  questionHeader: {
    marginBottom: Spacing.spacing01,
    fontWeight: FontWeights.medium
  },
  questionsRadios: {
    '& + &': {
      marginTop: Spacing.spacing00
    }
  },
  question: {
    '& + &': {
      marginTop: Spacing.spacing02
    }
  }
}));

type Props = {
  goalId: string;
  riskScore: string;
  namespace: number;
  reasoningForSelection: string;
  preferences: Record<string, boolean>;
  activeProductPlatformTab: number;
  setActiveProductPlatformTab: (tab: number) => void;
};

const ProductPlatform = ({
  goalId,
  riskScore,
  namespace: namespaceProp,
  reasoningForSelection: reasoningForSelectionProp,
  preferences: preferencesProp,
  activeProductPlatformTab,
  setActiveProductPlatformTab
}: Props) => {
  const i18n = useI18n();
  const classes = useStyles();
  const isSessionReadOnly = useIsSessionReadOnly();
  const updateGoal = useUpdateGoal();
  const [namespace, setNamespace] = useState(namespaceProp);
  const [questionAnswers, setQuestionAnswers] = useState(preferencesProp || {});
  const [reasoningForSelection, setReasoningForSelection] = useState(
    reasoningForSelectionProp
  );
  const readProductChooserRecommendation =
    useReadProductChooserRecommendation();
  const readAdvisoryGoalsData = useReadAdvisoryGoalsData();
  const { items, questions } = readProductChooserRecommendation({ goalId });
  const {
    roboAdvice: {
      productChooserRecommandation: { requireComment }
    },
    advisoryComponents
  } = useCustomerConfig();
  const dispatch = useDispatch();
  const isErrorModeEnabled = useErrorsStore(state => state.isErrorModeEnabled);
  const { setSelectedItemIndex } = useCarouselStore();
  const sessionStore = useSessionStore();
  const { goals } = useGoalsStore();
  const { isFollowUpEnabled } = useIsFollowUpEnabled();

  useEffect(() => {
    setNamespace(namespaceProp);
    setReasoningForSelection(reasoningForSelectionProp || '');

    const onlyAvailablePreferencesProp = Object.entries(
      preferencesProp || {}
    ).reduce((acc, [answeredQuestionName, answeredQuestionValue]) => {
      const foundConfigQuestion = questions.find(
        q => q.name === answeredQuestionName && q.enabled
      );

      if (foundConfigQuestion) {
        acc[answeredQuestionName] = answeredQuestionValue;
      }

      return acc;
    }, {});

    setQuestionAnswers(onlyAvailablePreferencesProp || {});

    if (
      questions.length ===
      Object.keys(onlyAvailablePreferencesProp || {}).length
    ) {
      setActiveProductPlatformTab(2);
    } else {
      setActiveProductPlatformTab(1);
    }
  }, [namespaceProp, reasoningForSelectionProp, preferencesProp]);

  useEffect(() => {
    if (items?.length && items.some(item => !item.labelString.length)) {
      dispatch(
        notificationActionCreators.showNotification({
          message: i18n(
            'roboAdvice.advisory.productChooser.platformNameMissingError'
          ),
          type: NotificationTypes.error
        })
      );
    }
  }, []);

  const handleValueChange = useCallback(
    async (name, value) => {
      if (name === 'productPlatformQuestions') {
        const onlyAvailableValues = Object.entries(value).reduce(
          (acc, [answeredQuestionName, answeredQuestionValue]) => {
            const foundConfigQuestion = questions.find(
              q => q.name === answeredQuestionName && q.enabled
            );

            if (foundConfigQuestion) {
              acc[answeredQuestionName] = answeredQuestionValue;
            }

            return acc;
          },
          {}
        );

        await updateGoal(goalId, {
          data: { [name]: onlyAvailableValues }
        });

        // If all questions are answered, change tab to Recommendation
        if (Object.keys(onlyAvailableValues).length === questions.length) {
          setActiveProductPlatformTab(2);
        }

        // Update the namespace according to the new answers
        const { items: newItems } = readProductChooserRecommendation({
          goalId,
          preferences: onlyAvailableValues
        });
        const firstAvailableItem = newItems.filter(
          i => i.status !== NamespaceStatus.NOT_AVAILABLE
        )[0];
        await handleValueChange(
          'productPlatformNamespace',
          firstAvailableItem.activeValue
        );
      } else if (name === 'productPlatformNamespace') {
        await readAdvisoryGoalsData(goalId, value);
        const availablePortfolios = getAvailablePortfolios(goalId, value);
        const matchingPortfolio = availablePortfolios.find(
          p => p.key === riskScore
        );

        const selectedGoal = goals.find(g => g.goalId === goalId);
        // If this is a follow-up session, set initially proper portfolio mode
        if (
          sessionStore.followUpId &&
          sessionStore.followUpData &&
          isFollowUpEnabled &&
          isNil(selectedGoal?.data.productPlatformNamespace)
        ) {
          const originalGoalData = sessionStore.followUpData.goals.find(
            originalGoal => originalGoal.newGoalId === goalId
          );
          await updateGoal(goalId, {
            data: {
              isPortfolioCustom: originalGoalData?.data.isPortfolioCustom,
              portfolio: matchingPortfolio
                ? matchingPortfolio.key
                : availablePortfolios[0].key,
              customPortfolio: null,
              [name]: value
            }
          });
        } else {
          const defaultPortfolioMode = advisoryComponents.portfolioModes.find(
            mode => mode === advisoryComponents.defaultPortfolioMode
          );

          setSelectedItemIndex(carouselIds.modelPortfolio, 0);
          await updateGoal(goalId, {
            data: {
              isPortfolioCustom:
                defaultPortfolioMode === PortfolioModes.customPortfolio,
              portfolio: matchingPortfolio
                ? matchingPortfolio.key
                : availablePortfolios[0].key,
              customPortfolio: null,
              [name]: value
            }
          });
        }
      } else {
        await updateGoal(goalId, {
          data: { [name]: value }
        });
      }
    },
    [
      goalId,
      questions.length,
      readAdvisoryGoalsData,
      readProductChooserRecommendation,
      riskScore,
      updateGoal
    ]
  );

  const debouncedHandleValueChange = useCallback(
    debounce((name, value) => handleValueChange(name, value), 300),
    [goalId]
  );

  const tabs = useMemo(() => {
    return [
      {
        label: (
          <ConfigurableLabel
            required
            label={i18n('roboAdvice.advisory.productPlatform.preferences')}
          />
        ),
        id: 1
      },
      {
        label: i18n('roboAdvice.advisory.productPlatform.recommendation'),
        id: 2
      }
    ];
  }, [i18n]);

  if (isNil(items) || items.length < 2) return null;

  const reasoningForSelectionTooltipText = i18n(
    'roboAdvice.advisory.productPlatform.reasoningForSelection.infoTooltip'
  );

  return (
    <div>
      <Tabs
        rootClassName={classes.tabs}
        value={activeProductPlatformTab}
        onChange={(_, newValue) => setActiveProductPlatformTab(newValue)}
      >
        {tabs.map(tab => (
          <Tab
            rootClassName={classes.tab}
            key={tab.id}
            value={tab.id}
            label={<div className={classes.tabLabel}>{tab.label}</div>}
          />
        ))}
      </Tabs>

      {activeProductPlatformTab === 1 && (
        <div className={classes.section}>
          {questions.map(question => (
            <div className={classes.question} key={question.name}>
              <div className={classes.questionHeader}>
                <ConfigurableLabel
                  required
                  label={getTranslation(question.label)}
                />
              </div>
              <Radios
                items={[
                  { activeValue: true, label: i18n('shared.yes') },
                  { activeValue: false, label: i18n('shared.no') }
                ]}
                layout={Layouts.vertical}
                disabled={isSessionReadOnly}
                value={questionAnswers[question.name]}
                className={classes.questionsRadios}
                onChange={val => {
                  setQuestionAnswers(answers => ({
                    ...answers,
                    [question.name]: val as boolean
                  }));
                  handleValueChange('productPlatformQuestions', {
                    ...questionAnswers,
                    [question.name]: val
                  });
                }}
              />
            </div>
          ))}
        </div>
      )}

      {activeProductPlatformTab === 2 && (
        <>
          <div className={classes.section}>
            <div className={classes.productPlatformTitle}>
              <ConfigurableLabel
                required
                label={i18n('roboAdvice.advisory.productPlatform.title')}
              />
            </div>
            <Radios
              items={items}
              layout={Layouts.vertical}
              disabled={isSessionReadOnly}
              className={classes.questionsRadios}
              value={namespace}
              onChange={val => {
                setNamespace(val as number);
                handleValueChange('productPlatformNamespace', val);
              }}
            />
          </div>
          {items.find(i => i.activeValue === namespace)?.status !==
            NamespaceStatus.RECOMMENDED && (
            <div className={classes.section}>
              <div className={classes.sectionHeader}>
                <ConfigurableLabel
                  required
                  label={i18n(
                    'roboAdvice.advisory.productPlatform.reasoningForSelection'
                  )}
                />

                {reasoningForSelectionTooltipText && (
                  <Tooltip
                    content={reasoningForSelectionTooltipText}
                    contentStyles={{ fontSize: Typography.body2.size }}
                    contentMaxWidth={350}
                    trigger={<QuestionMarkIcon />}
                  />
                )}
              </div>
              <Textarea
                placeholder={i18n(
                  'roboAdvice.advisory.productPlatform.reasoningForSelectionPlaceholder'
                )}
                className={classes.textarea}
                onChange={e => {
                  setReasoningForSelection(e.target.value);
                  debouncedHandleValueChange(
                    'productPlatformReasoning',
                    e.target.value
                  );
                }}
                disabled={isSessionReadOnly}
                value={reasoningForSelection}
                isInvalid={
                  requireComment &&
                  reasoningForSelection === '' &&
                  isErrorModeEnabled
                }
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProductPlatform;
