import { GOAL_TABLE_LINKS, ANALYTICS_LINKS } from '../constants';
import { createForm } from 'features/sharedModules/finalForm/services/finalForm';

export type ProposalFormValues = {
  [key: string]: boolean;
};

export const validate = (values: ProposalFormValues) => {
  let errors: { [key: string]: any } = {};

  const linksPaths: (string | string)[][] = [
    ...GOAL_TABLE_LINKS.map(({ config, translation }) => [config, translation]),
    ...ANALYTICS_LINKS.map(({ config, translation }) => [config, translation])
  ];
  return errors;
};

export const ProposalsForm = createForm<ProposalFormValues>({
  onSubmit: () => {},
  validate
});
