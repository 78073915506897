import axios, { CancelToken } from 'axios';

import {
  CreateInvestorRequestData,
  CreateInvestorResponse,
  GetInvestorInformationResponse,
  UpdateInvestorInformationRequestData,
  UpdateInvestorResponse,
  GetClientSessionsParams,
  GetClientSessionsResponse,
  CreateAdviceSessionRequestData,
  CreateAdviceSessionResponse,
  DeleteAdviceSessionResponse,
  UpdateAdviceSessionRequestData,
  UpdateAdviceSessionResponse,
  FlowType,
  SurveyFlowsResponse,
  CreatePreMeetingSessionPayload,
  GetActivePreMeetingOtpSessionResponse
} from './types';
import {
  digitalInvestorAssessmentApi,
  stateApi
} from 'features/shared/utils/axios';

export const getClientSessions = (
  accessToken: string,
  cancelToken: CancelToken,
  params: GetClientSessionsParams
) =>
  stateApi.get<GetClientSessionsResponse>('/session', {
    cancelToken,
    headers: { Authorization: accessToken },
    params
  });

export const createAdviceSession = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  data: CreateAdviceSessionRequestData
) =>
  stateApi.post<CreateAdviceSessionResponse>('/session', data, {
    cancelToken,
    headers: { Authorization: accessToken }
  });

export const deleteAdviceSession = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  sessionId: string
) =>
  stateApi.delete<DeleteAdviceSessionResponse>(`/session/${sessionId}`, {
    cancelToken,
    headers: { Authorization: accessToken }
  });

export const updateAdviceSession = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  sessionId: string,
  data: UpdateAdviceSessionRequestData
) =>
  stateApi.patch<UpdateAdviceSessionResponse>(`/session/${sessionId}`, data, {
    cancelToken,
    headers: { Authorization: accessToken }
  });

export const createInvestor = (
  accessToken: string,
  data: CreateInvestorRequestData
) =>
  stateApi.post<CreateInvestorResponse>('/investor', data, {
    headers: { Authorization: accessToken }
  });

export const getInvestorInformation = (
  accessToken: string,
  cancelToken: CancelToken,
  investorId: string
) =>
  stateApi.get<GetInvestorInformationResponse>(`/investor/${investorId}`, {
    cancelToken,
    headers: { Authorization: accessToken }
  });

export const updateInvestorInformation = (
  accessToken: string,
  data: UpdateInvestorInformationRequestData,
  investorId: string
) =>
  stateApi.patch<UpdateInvestorResponse>(`/investor/${investorId}`, data, {
    headers: { Authorization: accessToken }
  });

export const getCompanyData = (orgNumber: string, cancelToken: CancelToken) =>
  axios.get(`https://data.brreg.no/enhetsregisteret/api/enheter/${orgNumber}`, {
    cancelToken
  });

export const getSurveyFlows = (
  accessToken: string,
  cancelToken: CancelToken,
  flowType: FlowType
) =>
  digitalInvestorAssessmentApi.get<SurveyFlowsResponse>(
    '/advisory/surveyflows',
    {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: {
        flow_type: flowType
      },
      cancelToken
    }
  );

export const createPreMeetingSession = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  payload: CreatePreMeetingSessionPayload,
  authenticationType: string
) =>
  digitalInvestorAssessmentApi.post('/advisory/session/create', payload, {
    headers: { Authorization: `Bearer ${accessToken}` },
    cancelToken,
    params: {
      authentication_type: authenticationType
    }
  });

export const resendOtpPin = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  sessionId: string
) =>
  digitalInvestorAssessmentApi.post(
    `/advisory/session/${sessionId}/otp/pin/resend`,
    null,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
      cancelToken
    }
  );

export const resetOtpPin = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  sessionId: string,
  payload: {
    phoneNumber: string;
    language: string;
  }
) =>
  digitalInvestorAssessmentApi.post(
    `/advisory/session/${sessionId}/otp/pin/reset`,
    payload,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
      cancelToken
    }
  );

export const reminderSendSms = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  payload: {
    sessionId: string;
    recipient: string;
    message: string;
  }
) =>
  digitalInvestorAssessmentApi.post('/notification/sms', payload, {
    headers: { Authorization: `Bearer ${accessToken}` },
    cancelToken
  });

export const reminderSendEmail = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  payload: {
    sessionId: string;
    recipient: string;
    message: string;
    messageSubject: string;
  }
) =>
  digitalInvestorAssessmentApi.post('/notification/email', payload, {
    headers: { Authorization: `Bearer ${accessToken}` },
    cancelToken
  });

export const getActivePreMeetingOtpSession = (
  accessToken: string,
  cancelToken: CancelToken | undefined,
  sessionId: string
) =>
  digitalInvestorAssessmentApi.get<GetActivePreMeetingOtpSessionResponse>(
    `/advisory/session/${sessionId}/authentication/active`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
      cancelToken
    }
  );
