import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  useHolisticViewStore,
  selectors
} from '../../../services/holisticViewStore';
import { useReadHistoricalReturnChartData } from './useReadHistoricalReturnChartData';
import { useHistoricalReturnChartStore } from 'features/roboAdvice/adviceSession/historicalReturnChart';
import { useReadDataListener } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import HistoricalReturnChartCardVisual from 'features/shared/analyticsComponents/historicalReturnChart/visual';
import sessionSelectors from 'features/shared/services/session/selectors';

const HistoricalReturnChartCard = ({ isSelected }) => {
  const [activeItem, setActiveItem] = useState({
    statusKey: 'readHistoricalReturn3MChartData',
    timeRangeChartDataKey: '3M'
  });
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const chartStoreData = useHistoricalReturnChartStore();
  const selectedGoalsIds = useHolisticViewStore(selectors.getSelectedGoalsIds);
  const proposalSectionStatuses =
    useHolisticViewStore.getState().proposalSectionStatuses;

  const getItemStatus = useCallback(
    item => proposalSectionStatuses[item.statusKey],
    [proposalSectionStatuses]
  );

  useReadDataListener(
    proposalSectionStatuses.readHistoricalReturn3MChartData,
    isSelected && activeItem.statusKey === 'readHistoricalReturn3MChartData',
    useReadHistoricalReturnChartData('readHistoricalReturn3MChartData', '3M')
  );
  useReadDataListener(
    proposalSectionStatuses.readHistoricalReturnYTDChartData,
    isSelected && activeItem.statusKey === 'readHistoricalReturnYTDChartData',
    useReadHistoricalReturnChartData('readHistoricalReturnYTDChartData', 'YTD')
  );
  useReadDataListener(
    proposalSectionStatuses.readHistoricalReturn1YChartData,
    isSelected && activeItem.statusKey === 'readHistoricalReturn1YChartData',
    useReadHistoricalReturnChartData('readHistoricalReturn1YChartData', '1Y')
  );
  useReadDataListener(
    proposalSectionStatuses.readHistoricalReturn3YChartData,
    isSelected && activeItem.statusKey === 'readHistoricalReturn3YChartData',
    useReadHistoricalReturnChartData('readHistoricalReturn3YChartData', '3Y')
  );
  useReadDataListener(
    proposalSectionStatuses.readHistoricalReturn5YChartData,
    isSelected && activeItem.statusKey === 'readHistoricalReturn5YChartData',
    useReadHistoricalReturnChartData('readHistoricalReturn5YChartData', '5Y')
  );
  useReadDataListener(
    proposalSectionStatuses.readHistoricalReturn10YChartData,
    isSelected && activeItem.statusKey === 'readHistoricalReturn10YChartData',
    useReadHistoricalReturnChartData('readHistoricalReturn10YChartData', '10Y')
  );

  return (
    <HistoricalReturnChartCardVisual
      activeItem={activeItem}
      setActiveItem={setActiveItem}
      cultureCode={cultureCode}
      chartStoreChartData={chartStoreData.chartData}
      selectedGoalsIds={selectedGoalsIds}
      getItemStatus={getItemStatus}
    />
  );
};

export default HistoricalReturnChartCard;
