import classNames from 'classnames';
import React from 'react';

import Icon from '../../../../shared/components/icon';
import { useHolisticViewStore } from '../services/holisticViewStore';
import Button from 'features/shared/components/button';
import { Spacing } from 'features/shared/constants/spacing';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  selectPortfolioButtons: {
    padding: `${Spacing.spacing02}px 0`,
    display: 'flex'
  },
  selectPortfolioButton: {
    margin: ' 0 16px 8px 0',
    '&$disabled': {
      background: 'transparent'
    }
  },
  disabled: {},
  removeIcon: {
    verticalAlign: 'middle',
    lineHeight: '1.8em',
    marginLeft: '8px'
  }
});

const SelectPortfolioButtons = () => {
  const classes = useStyles();
  const holisticViewStore = useHolisticViewStore();

  return (
    <div className={classes.selectPortfolioButtons}>
      {holisticViewStore.availableClients
        .filter(client => client.isSelected)
        .map(client =>
          (client.goals || []).map(goal => ({ ...goal, clientId: client.id }))
        )
        .flat()
        .map(goal => (
          <Button
            key={goal.goalId}
            className={classNames(classes.selectPortfolioButton, {
              [classes.disabled]: !goal.isGoalSelected
            })}
            onClick={() => {
              holisticViewStore.setIsGoalSelected(
                goal.clientId,
                goal.goalId,
                !goal.isGoalSelected
              );
            }}
          >
            {goal.name}
            {goal.isGoalSelected && (
              <Icon type="clear" className={classes.removeIcon} />
            )}
          </Button>
        ))}
    </div>
  );
};

export default SelectPortfolioButtons;
