import axios, { CancelTokenSource } from 'axios';
import * as R from 'ramda';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { clientForm } from '../../../../main/services/clientForm';
import { getENINCompanyDetails } from 'features/roboAdvice/shared/api';
import {
  isDateToday,
  isVatNumberValid,
  getFullCompanyOrgNumber
} from 'features/roboAdvice/shared/helpers/enin';
import { SupportedCountryCode as SupportedCountryCodeType } from 'features/roboAdvice/shared/types';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useInitSignatureRightInfo() {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<CancelTokenSource>();
  const dispatch = useDispatch();
  const i18n = useI18n();

  const { values } = clientForm.getState();
  const orgNumber = values?.clientInformation?.orgNumber || null;
  const currentCountryCode =
    values.clientInformation?.country?.toLowerCase() || '';
  const eninUpdateDate = values.kyc?.enin?.signatureRight?.eninUpdateDate;
  const isLastCallToday =
    eninUpdateDate && isDateToday(new Date(eninUpdateDate));

  const isOrgNumberValid = isVatNumberValid(
    orgNumber,
    currentCountryCode as SupportedCountryCodeType
  );

  const fullOrgNumber = getFullCompanyOrgNumber(
    orgNumber,
    currentCountryCode as SupportedCountryCodeType
  );

  const initSignatureRightInfo = async () => {
    if (isLastCallToday || !isOrgNumberValid) {
      return;
    }

    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    try {
      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const { data } = await getENINCompanyDetails(
        accessToken,
        cancelTokenSource.token,
        fullOrgNumber
      );

      clientForm.change<any>('kyc.enin.signatureRight', {
        eninUpdateDate: new Date(),
        signatureRightData: mapServerSignatureRight(data)
      });
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.financialSituation.readENINDataErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  React.useEffect(() => {
    return () => {
      if (!R.isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
    };
  }, []);

  return initSignatureRightInfo;
}

const roleTranslationsKey = {
  ceo: 'roboAdvice.kyc.enin.ceo',
  board_chairman: 'roboAdvice.kyc.enin.boardChairman',
  board_member: 'roboAdvice.kyc.enin.boardMember'
};

const mapServerSignatureRight = data => ({
  legalRights: data?.signature_rights?.map(item => ({
    text: item?.company_legal_right?.legal_right_text,
    fromDate: item?.company_legal_right?.from_date,
    toDate: item?.company_legal_right?.to_date
  })),
  currentRoles: data?.company_roles?.map(item => {
    const roleKey = item?.role;

    return {
      fromDate: item?.from_date,
      toDate: item?.to_date,
      personFullName: item?.person_full_name,
      roleTranslationsKey: roleKey && roleTranslationsKey[roleKey]
    };
  })
});
