import * as R from 'ramda';
import { initialize, reset, getFormValues, touch } from 'redux-form';
import { takeEvery, put, call, select } from 'redux-saga/effects';
import { v4 } from 'uuid';

import {
  createCustomer as createCustomerApi,
  updateCustomer as updateCustomerApi
} from '../../../shared/api/index.js';
import { NewCustomerId } from '../../../shared/constants/index.js';
import { types, creators } from '../actions.js';
import {
  mapServerCustomerToClient,
  mapClientCustomerToServer
} from '../mapping.js';
import {
  getIsCustomerValid,
  getCustomerValidateableFields
} from '../selectors/editCustomerForm.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import formSelectors from 'features/shared/services/form/selectors.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import routerSelectors from 'features/shared/services/router/selectors.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import {
  startedCreator,
  succeedCreator,
  failedCreator
} from 'features/shared/utils/actions.js';
import history from 'features/shared/utils/history.js';
import { skipNilFields } from 'features/shared/utils/mapping.js';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { getI18n } from 'features/sharedModules/customerConfig/services/selectors.js';

function* createCustomer(action) {
  const { customer } = action.payload;
  yield put(startedCreator(types.CREATE_CUSTOMER));
  const i18n = yield select(getI18n);

  try {
    const stateBeforeRequest = yield select();
    const currentCustomerId =
      sessionSelectors.getCurrentUserCustomerId(stateBeforeRequest);
    const accessToken =
      sessionSelectors.getAuth0AccessToken(stateBeforeRequest);
    const cultureCode =
      sessionSelectors.getCurrentUserCultureCode(stateBeforeRequest);

    const customerData = mapClientCustomerToServer(
      cultureCode,
      skipNilFields(customer)
    );
    const result = yield call(
      createCustomerApi,
      currentCustomerId,
      accessToken,
      customerData
    );
    const data = mapServerCustomerToClient(result.data);

    // TODO: cancel async actions result handling on page close (for all relevant sagas in application)
    yield put(succeedCreator(types.CREATE_CUSTOMER, data));

    const { users } = data;

    const formValues = getFormValues(
      'editCustomerForm_state',
      formSelectors.getForms
    )(stateBeforeRequest);
    yield put(initialize('editCustomerForm_state', { ...formValues, users }));

    // const state_after_request = yield select();
    // const route = routerSelectors.getRoute(state_after_request);
    // const params = route.match.params;
    // if (params.id === NewCustomerId) {
    //   yield call(
    //     history.push,
    //     routeTemplates.customerManagement.build(customer.id)
    //   );
    // }
    yield call(history.push, routeTemplates.customersTable.build());

    yield put(
      notificationActionCreators.showNotification({
        message: i18n(
          'customers.customerManagement.createCustomerSuccessMessage'
        ),
        type: NotificationTypes.success
      })
    );
  } catch (error) {
    yield put(failedCreator(types.CREATE_CUSTOMER));

    yield put(
      notificationActionCreators.showNotification({
        message: i18n(
          'customers.customerManagement.createCustomerErrorMessage'
        ),
        type: NotificationTypes.error
      })
    );
  }
}

function* updateCustomer(action) {
  const { customerId, customer } = action.payload;
  const i18n = yield select(getI18n);

  yield put(startedCreator(types.UPDATE_CUSTOMER));

  try {
    const state = yield select();
    const currentCustomerId = sessionSelectors.getCurrentUserCustomerId(state);
    const accessToken = sessionSelectors.getAuth0AccessToken(state);
    const cultureCode = sessionSelectors.getCurrentUserCultureCode(state);

    const customerData = mapClientCustomerToServer(
      cultureCode,
      skipNilFields(customer)
    );

    const result = yield call(
      updateCustomerApi,
      currentCustomerId,
      accessToken,
      customerId,
      customerData
    );
    const data = mapServerCustomerToClient(result.data);

    yield put(succeedCreator(types.UPDATE_CUSTOMER, data));

    const { users } = data;

    const formValues = getFormValues(
      'editCustomerForm_state',
      formSelectors.getForms
    )(state);
    yield put(initialize('editCustomerForm_state', { ...formValues, users }));

    yield call(history.push, routeTemplates.customersTable.build());

    yield put(
      notificationActionCreators.showNotification({
        message: i18n(
          'customers.customerManagement.updateCustomerSuccessMessage'
        ),
        type: NotificationTypes.success
      })
    );
  } catch (error) {
    yield put(failedCreator(types.UPDATE_CUSTOMER));

    yield put(
      notificationActionCreators.showNotification({
        message: i18n(
          'customers.customerManagement.updateCustomerErrorMessage'
        ),
        type: NotificationTypes.error
      })
    );
  }
}

function* saveCustomerManagementForm(action) {
  const state = yield select();
  const route = routerSelectors.getRoute(state);
  const params = route.match.params;
  const { id: customerId } = params;
  const i18n = yield select(getI18n);

  const cultureCode = sessionSelectors.getCurrentUserCultureCode(state);

  const formValues = getFormValues(
    'editCustomerForm_state',
    formSelectors.getForms
  )(state);

  const id = customerId === NewCustomerId ? v4() : customerId;
  const preprocessedFormValues = R.isNil(formValues)
    ? formValues
    : {
        ...formValues,
        id
      };

  if (!getIsCustomerValid(cultureCode, formValues, i18n)) {
    yield put(
      touch(
        'editCustomerForm_state',
        ...getCustomerValidateableFields(formValues)
      )
    );
    return;
  }

  if (customerId === NewCustomerId) {
    yield put(creators.createCustomer(preprocessedFormValues));
  } else {
    yield put(creators.updateCustomer(customerId, preprocessedFormValues));
  }
}

function* cancelCustomerManagementFormChanges() {
  yield put(reset('editCustomerForm_state'));
}

export default function* () {
  yield takeEvery(types.CREATE_CUSTOMER, createCustomer);

  yield takeEvery(types.UPDATE_CUSTOMER, updateCustomer);

  yield takeEvery(
    types.SAVE_CUSTOMER_MANAGEMENT_FORM,
    saveCustomerManagementForm
  );

  yield takeEvery(
    types.CANCEL_CUSTOMER_MANAGEMENT_FORM_CHANGES,
    cancelCustomerManagementFormChanges
  );
}
