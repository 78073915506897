import { format, parseISO } from 'date-fns';

import { ClientTypes } from '../../../../shared/constants/session';
import { RoboAdvice } from 'defaults/defaultRoboAdvice';
import {
  InvestorTypes,
  NONE_OF_THE_ABOVE_ID
} from 'features/roboAdvice/client/kyc/components/automaticClientClassification/constants';
import { ClientFormValues } from 'features/roboAdvice/client/main/services/clientForm';
import { useCountryDropdownOptions } from 'features/roboAdvice/shared/components/useCountryDropdownOptions';
import { isObject } from 'features/shared/utils/object';
import { FieldComponentTypes } from 'features/sharedModules/declarativeForm/constants';

type PdfKYCPayload = {
  kyc?: Record<string, any> | null;
  kycFieldsConfig: Record<string, any>;
  language: string;
  clientType: string;
  i18n: (arg: string) => string;
};

export const useMapKycData = ({
  kyc,
  kycFieldsConfig,
  language,
  clientType,
  i18n
}: PdfKYCPayload) => {
  const countryDropdownOptions = useCountryDropdownOptions();

  const kycValues = Object.entries(kyc || {}).reduce((acc, [key, value]) => {
    if (isObject(value)) {
      const expandedValue = Object.entries(value).reduce(
        (acc, [subKey, subValue]) => {
          return { ...acc, [`${key}.${subKey}`]: subValue };
        },
        {}
      );
      return { ...acc, ...expandedValue };
    }
    return { ...acc, [key]: value };
  }, {} as Record<string, any>);

  const { company, person } = kycFieldsConfig;

  const kycFields = (
    clientType === ClientTypes.company ? company.fields : person.fields
  ).reduce((acc, curr) => {
    const array = curr.name.split('.');
    if (array.length > 1) {
      array.shift();
    }
    const key = array.join('.');
    acc[key] = curr;
    return acc;
  }, {});

  const kycDefaultFields =
    clientType === ClientTypes.company
      ? company.defaultFields
      : person.defaultFields;

  // TODO: KYC TRANSLATIONS SHOULD BE UNIFIED => MOVE DEFAULT FIELDS TRANSLATION TO CONFIG
  const kycDefaultFieldsWithTranslations = Object.entries(
    kycDefaultFields
  ).reduce((acc, [key, value]: [string, any]) => {
    let translation = '';
    if (key === 'clientRelationshipPurpose') {
      translation = 'roboAdvice.kyc.clientRelationshipPurposeLabel';
    }
    if (key === 'moneyOrigin') {
      translation = 'roboAdvice.clientInformation.moneyOrigin.header';
    }
    if (key === 'clientClassification') {
      translation = 'roboAdvice.clientInformation.clientClassification.header';
    }
    if (key === 'pep') {
      translation = 'roboAdvice.kyc.pepLabel';
    }
    if (key === 'signatureRight') {
      translation = 'roboAdvice.kyc.signatureRight';
    }
    if (key === 'ubo') {
      translation = 'roboAdvice.kyc.ubo';
    }
    if (key === 'automaticClientClassification') {
      return acc;
    }

    return {
      ...acc,
      [key]: { ...value, label: { [language]: i18n(translation) } }
    };
  }, {});

  const data = { ...kycFields, ...kycDefaultFieldsWithTranslations };

  const kycData = Object.entries(kycValues).reduce((acc, [key, value]) => {
    const fieldConfig = data[key];

    if (fieldConfig) {
      const label = data[key].label[language];

      if (Array.isArray(value)) {
        if (key === 'signatureRight' || key === 'ubo') {
          const values = kycValues[key].map(record => ({
            ...record,
            country: countryDropdownOptions.find(
              ({ key }) => key === record.country
            )?.title
          }));
          return { ...acc, [key]: { label: label, value: values } };
        }

        const values = value.map(v => {
          const answer = fieldConfig.items.find(
            ({ activeValue }) => activeValue === v
          );
          return answer?.textField
            ? kycValues[answer.textField.name.split('.')[1]]
            : answer?.label[language];
        });
        return { ...acc, [key]: { label: label, value: values } };
      }

      const configAnswer = fieldConfig.items?.find(
        ({ activeValue }) => activeValue === value
      );

      if (configAnswer) {
        const answer = configAnswer.textField
          ? kycValues[configAnswer.textField.name.split('.')[1]]
          : configAnswer.label[language];
        return { ...acc, [key]: { label: label, value: answer } };
      }

      if (fieldConfig.componentType === FieldComponentTypes.datepicker) {
        return {
          ...acc,
          [key]: {
            label: label,
            value: value ? format(parseISO(value), fieldConfig.dateFormat) : ''
          }
        };
      }

      if (value === true) {
        return { ...acc, [key]: { label: label, value: i18n('shared.yes') } };
      }

      if (value === false) {
        return { ...acc, [key]: { label: label, value: i18n('shared.no') } };
      }

      return { ...acc, [key]: { label: label, value: value } };
    }

    if (key === 'advisorNote') {
      return {
        ...acc,
        [key]: { label: i18n('roboAdvice.advisorNote'), value: value }
      };
    }

    return { ...acc };
  }, {} as Record<string, { label: string; value: string | string[] }>);

  return Object.entries(kycData)
    .map(([key, value]) => ({
      name: key,
      ...value
    }))
    .sort(
      (a, b) => (data[a.name]?.order || 99999) - (data[b.name]?.order || 99999)
    );
};

type GetAutomaticClientClassificationArgs = {
  kyc: ClientFormValues['kyc'];
  automaticClientClassificationConfig: RoboAdvice['clientInformation']['automaticClientClassification'];
  kycConfig: RoboAdvice['kyc'];
  clientType: ClientTypes;
  i18n: (arg: string | undefined) => string;
};

export const getAutomaticClientClassification = ({
  kyc,
  automaticClientClassificationConfig,
  kycConfig,
  clientType,
  i18n
}: GetAutomaticClientClassificationArgs) => {
  if (
    !kycConfig[clientType]?.defaultFields.automaticClientClassification
      .isEnabled
  ) {
    return;
  }

  const {
    clientClassificationId,
    investorType,
    answer,
    isDecisionOverwritten,
    reclassificationRequestDocumentation
  } = kyc?.automaticClientClassification || {};

  const { items, checklistModal: { isCommentRequired = undefined } = {} } =
    automaticClientClassificationConfig || {};

  const clientClassification =
    clientClassificationId === NONE_OF_THE_ABOVE_ID
      ? i18n('roboAdvice.kyc.automaticClientClassification.noneOfTheAbove')
      : i18n(items?.find(i => i.id === clientClassificationId)?.title);

  let originalInvestorType: string | undefined;
  if (isDecisionOverwritten) {
    originalInvestorType =
      investorType === InvestorTypes.professionalInvestor
        ? i18n(
            `roboAdvice.kyc.automaticClientClassification.${InvestorTypes.nonProfessionalInvestor}`
          )
        : i18n(
            `roboAdvice.kyc.automaticClientClassification.${InvestorTypes.professionalInvestor}`
          );
  }

  return {
    answer,
    clientClassification,
    investorType:
      investorType &&
      i18n(`roboAdvice.kyc.automaticClientClassification.${investorType}`),
    isDecisionOverwritten,
    originalInvestorType,
    reclassificationRequestDocumentation,
    isCommentRequired
  };
};
