import { ReactComponent as AlternativeEnergyIcon } from '../../shared/components/icons/alternative_energy.svg';
import { ReactComponent as ChinaIcon } from '../../shared/components/icons/china.svg';
import { ReactComponent as CommercialPropertyIcon } from '../../shared/components/icons/commercial_property.svg';
import { ReactComponent as EcologyIcon } from '../../shared/components/icons/ecology.svg';
import { ReactComponent as EmergingMarketsIcon } from '../../shared/components/icons/emerging_markets.svg';
import { ReactComponent as GlobalRealEstateIcon } from '../../shared/components/icons/global_real_estate.svg';
import { ReactComponent as HealthcareIcon } from '../../shared/components/icons/healthcare.svg';
import { ReactComponent as IndiaIcon } from '../../shared/components/icons/india.svg';
import { ReactComponent as InfrastructureIcon } from '../../shared/components/icons/infrastructure.svg';
import { ReactComponent as NordicIcon } from '../../shared/components/icons/nordic.svg';
import { ReactComponent as PreciousMetalsIcon } from '../../shared/components/icons/precious_metals.svg';
import { ReactComponent as PrivateEquityIcon } from '../../shared/components/icons/private_equity.svg';
import { ReactComponent as PublicPropertyIcon } from '../../shared/components/icons/public_property.svg';
import { ReactComponent as RealEstateIcon } from '../../shared/components/icons/real_estate.svg';
import { ReactComponent as ShippingIcon } from '../../shared/components/icons/shipping.svg';
import { ReactComponent as TechnologyIcon } from '../../shared/components/icons/technology.svg';

export const themesIcons = {
  healthcare: <HealthcareIcon />,
  technology: <TechnologyIcon />,
  ecology: <EcologyIcon />,
  alternativeEnergy: <AlternativeEnergyIcon />,
  china: <ChinaIcon />,
  india: <IndiaIcon />,
  preciousMetals: <PreciousMetalsIcon />,
  globalRealEstate: <GlobalRealEstateIcon />,
  realEstate: <RealEstateIcon />,
  privateEquity: <PrivateEquityIcon />,
  infrastructure: <InfrastructureIcon />,
  emergingMarkets: <EmergingMarketsIcon />,
  shipping: <ShippingIcon />,
  nordic: <NordicIcon />,
  publicProperty: <PublicPropertyIcon />,
  commercialProperty: <CommercialPropertyIcon />
};
