import {
  customerManagementUsersTableInitialState,
  reducer as customerManagementUsersTableReducer
} from 'features/customers/customerManagementPage/services/reducers/customerManagementUsersTable.js';
import {
  customersTableInitialState,
  reducer as customersTableReducer
} from 'features/customers/customersTablePage/services/reducers/customersTable.js';
import createTableReducer from 'features/shared/services/table/reducer.js';

const initialState = {
  customerManagementUsersTable_state: customerManagementUsersTableInitialState,
  customersTable_state: customersTableInitialState
};
export default createTableReducer({
  initialState,
  plugins: {
    customerManagementUsersTable_state: customerManagementUsersTableReducer,
    customersTable_state: customersTableReducer
  }
});
