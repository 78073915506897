import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { createUseStyles } from '../../../sharedModules/styles/components/styles';
import { Typography } from 'features/shared/constants/typography';
import routeTemplates from 'features/shared/utils/routeTemplates';

const useStyles = createUseStyles(theme => ({
  watermark: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '10px',
    paddingBottom: '20px',
    whiteSpace: 'nowrap',
    fontSize: Typography.body3.size,
    color: theme.secondaryColor
  },
  companyNameLink: {
    '&, &:link, &:visited, &:hover, &:active': {
      textDecoration: 'none',
      color: theme.secondaryColor,
      cursor: 'pointer'
    }
  }
}));

const Watermark = ({ isLinkActive = true }: { isLinkActive?: boolean }) => {
  const classes = useStyles();

  return (
    <footer className={classes.watermark}>
      {isLinkActive ? (
        <span>
          Powered by&nbsp;
          <ReactRouterLink
            to={routeTemplates.home.build()}
            className={classes.companyNameLink}
          >
            Quantfolio &reg;
          </ReactRouterLink>
        </span>
      ) : (
        <span>Powered by&nbsp; Quantfolio &reg;</span>
      )}
    </footer>
  );
};

export default Watermark;
