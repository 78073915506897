import * as R from 'ramda';

import { OrderDirections } from 'features/shared/constants/table';
import { globalizeSelectors } from 'framework/globalize/utils/index.js';

export const orderDirectionFuncs = {
  [OrderDirections.asc]: R.ascend,
  [OrderDirections.desc]: R.descend
};

export const getPage = R.curry((table, items) => {
  const fromIndex = table.pageNumber * table.pageSize;
  const toIndex = fromIndex + table.pageSize;

  return {
    items: R.slice(fromIndex, toIndex, items || []),
    totalItemsNumber: (items || []).length
  };
});

export default globalizeSelectors(['table_state'], {
  getTable: (id, state) => state[id]
});
