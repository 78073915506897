import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useSessionStore } from '../../session/services/sessionStore';
import { useForm as useRoboAdviceForm } from 'features/roboAdvice/adviceSession/form/services/form';
import { advancedSuitabilityTables } from 'features/roboAdvice/adviceSession/knowledgeAndExperience/constants';
import { useKnowledgeAndExperienceStore } from 'features/roboAdvice/adviceSession/knowledgeAndExperience/services/knowledgeAndExperienceStore';
import { useTargetMarketAssessmentStore } from 'features/roboAdvice/adviceSession/orderExecution/services/targetMarketAssessmentStore';
import { readAnalyzeAdvancedSuitabilityStatusV2 } from 'features/roboAdvice/adviceSession/shared/api';
import { ReadAnalyzeAdvancedSuitabilityStatusV2Payload } from 'features/roboAdvice/adviceSession/shared/api/types';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import {
  ClientFormValues,
  clientForm
} from 'features/roboAdvice/client/main/services/clientForm';
import { getInvestorTypeNumber } from 'features/roboAdvice/shared/utils';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

const mapInformationFields = (
  fields: Record<string, boolean> | undefined,
  clientFormValues: ClientFormValues
) => {
  if (fields === undefined) {
    return undefined;
  }

  return Object.entries(fields).reduce((acc, [fieldName, value]) => {
    if (value) {
      if (fieldName === 'investorType') {
        acc[fieldName] = getInvestorTypeNumber({
          clientClassification: clientFormValues?.kyc?.clientClassification,
          automaticClientClassification:
            clientFormValues?.kyc?.automaticClientClassification?.investorType
        });
      } else {
        acc[fieldName] = 1;
      }
    }
    return acc;
  }, {} as Record<string, number>);
};

export const useReadAdvancedSuitabilityStatus = () => {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const i18n = useI18n();
  const dispatch = useDispatch();
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const {
    roboOrderFlow: {
      targetMarketAssessment: { enabled: targetMarketAssessmentEnabled }
    },
    roboAdviceForm: {
      knowledgeAndExperience: { advancedSuitability }
    }
  } = useCustomerConfig();
  const { setAdvancedSuitabilityData, setAdvancedSuitabilityDataStatus } =
    useTargetMarketAssessmentStore();

  const readAdvancedSuitabilityStatus = async () => {
    const sessionStoreState = useSessionStore.getState();
    const { advancedSuitabilityDataStatus } =
      useTargetMarketAssessmentStore.getState();
    const enabledAdvancedSuitabilityTables = advancedSuitabilityTables.filter(
      ({ name }) => advancedSuitability[name].enabled
    );

    const { advancedSuitabilityConfig } =
      useKnowledgeAndExperienceStore.getState();

    const {
      values: { suitability: suitabilityValues }
    } = useRoboAdviceForm.getState();
    const areAllFieldsFilled = advancedSuitabilityConfig?.checks.every(key => {
      return enabledAdvancedSuitabilityTables.every(
        ({ name }) =>
          suitabilityValues?.[name] && !isNil(suitabilityValues[name][key])
      );
    });
    if (
      !targetMarketAssessmentEnabled ||
      advancedSuitabilityDataStatus !== PageStatuses.ready ||
      !areAllFieldsFilled
    ) {
      return;
    }

    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    try {
      setAdvancedSuitabilityDataStatus(PageStatuses.pending);
      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const checks = advancedSuitabilityConfig?.checks?.map(check => {
        const checkData = { check };
        for (const { name, input } of enabledAdvancedSuitabilityTables) {
          checkData[input] = suitabilityValues![name][check];
        }
        return checkData;
      }) as ReadAnalyzeAdvancedSuitabilityStatusV2Payload['checks'];

      const { values: clientFormValues } = clientForm.getState();

      const analyzeAdvancedSuitabilityPayload: ReadAnalyzeAdvancedSuitabilityStatusV2Payload =
        {
          checks,
          investorInformationFields: mapInformationFields(
            advancedSuitabilityConfig?.investorInformationFields,
            clientFormValues
          )
        };

      const { data: analyzeAdvancedSuitabilityData } =
        await readAnalyzeAdvancedSuitabilityStatusV2(
          accessToken,
          cancelTokenSource.token,
          analyzeAdvancedSuitabilityPayload,
          {
            namespace_id: sessionStoreState.defaultConfigNamespaceId
          }
        );

      setAdvancedSuitabilityData(analyzeAdvancedSuitabilityData);
      setAdvancedSuitabilityDataStatus(PageStatuses.succeed);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setAdvancedSuitabilityDataStatus(PageStatuses.failed);
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.orderExecution.readInstrumentsErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readAdvancedSuitabilityStatus;
};
