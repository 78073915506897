import { createUseStyles, useTheme } from 'modules/styles/components/styles';
import { TextFontSizes } from '../constants/fonts';
import { Theme } from 'defaults/defaultTheme';

const useStyles = createUseStyles(theme => ({
  legend: {
    display: 'flex',
    justifyContent: 'center',
    gap: 64,
    listStyle: 'none',
    margin: 0,
    padding: '18px 0 0',
    width: '100%',
    position: 'relative'
  },
  goal: {
    display: 'flex',
    alignItems: 'center',
    color: theme.secondaryColor,
    fontSize: TextFontSizes.xxSmall,
    lineHeight: TextFontSizes.xSmall,
    margin: 0
  },
  dot: {
    height: 16,
    width: 16,
    marginRight: 16,
    borderRadius: '50%'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 8
  }
}));

type Props = {
  items: { title: string | JSX.Element; style?: React.CSSProperties }[];
};

const Legend = ({ items }: Props) => {
  const classes = useStyles();
  const theme: Theme = useTheme();

  return (
    <ul className={classes.legend}>
      {items.map(({ title, style }, index) => (
        <li key={index} className={classes.goal}>
          <div
            className={classes.dot}
            style={{
              backgroundColor: theme.chartPortfolioColors[index],
              ...style
            }}
          />
          <div className={classes.title}>{title}</div>
        </li>
      ))}
    </ul>
  );
};

export default Legend;
