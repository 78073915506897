import { Document } from 'flexsearch';
import { uniq } from 'ramda';
import * as React from 'react';

import { clientsTable } from '../main/clientsTable';
import { useHolisticViewStore } from '../main/services/holisticViewStore';

const useSearchClients = () => {
  const holisticViewStore = useHolisticViewStore();
  const { useTableStore } = clientsTable;

  const index = React.useMemo(() => {
    const index = new Document({
      tokenize: 'forward',
      document: {
        index: ['name'],
        store: 'index'
      }
    });

    holisticViewStore.availableClients.forEach(({ id, name }) => {
      index.add({ id, name });
    });

    return index;
  }, [holisticViewStore.availableClients]);

  const searchTranslations = query => {
    const tableStore = useTableStore.getState();
    let matchingIndexes = [];
    const isQueryLongEnough = query.length >= 3;
    if (isQueryLongEnough) {
      const results = index.search(query, { suggest: true, enrich: true });
      tableStore.changePageNumber(0);
      if (results.length) {
        matchingIndexes = uniq(results[0].result.map(({ id }) => id));
      }
    }

    holisticViewStore.setIsSearchModeActive(isQueryLongEnough);
    holisticViewStore.setSearchedClients(matchingIndexes);
  };

  return searchTranslations;
};

export default useSearchClients;
