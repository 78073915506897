import axios from 'axios';
import * as R from 'ramda';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTranslations } from '../api';
import { useTranslationsStore } from '../services/translationsStore';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

const useReadTranslations = () => {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<any>();
  const dispatch = useDispatch();

  const readTranslations = async language => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const translationsStore = useTranslationsStore.getState();

    try {
      translationsStore.setIsReadTranslationsPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const response = await getTranslations(
        accessToken,
        cancelTokenSource.token,
        language
      );

      translationsStore.setTranslations(response.data.settings);
      translationsStore.setIsReadTranslationsPending(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        translationsStore.setIsReadTranslationsPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'admin.appAdmin.advisorSolution.text.redTranslationsErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  React.useEffect(() => {
    return () => {
      if (!R.isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
    };
  }, []);

  return readTranslations;
};

export default useReadTranslations;
