import { NewCustomerId } from '../constants/index.js';
import ButtonLink from 'features/shared/components/button/buttonLink';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  header: {
    display: 'flex',
    padding: '3rem 0 3rem 0'
  },
  headerSpace: {
    flex: '1 1 auto'
  }
});

const Header = () => {
  const i18n = useI18n();
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <ButtonLink to={routeTemplates.customerManagement.build(NewCustomerId)}>
        {'+ '}
        {i18n('customers.addCustomer')}
      </ButtonLink>
      <div className={classes.headerSpace} />
    </div>
  );
};

export default Header;
