import React from 'react';
import DatePicker from 'react-datepicker';

import { filterMonthDateByType, getYearsRange, dateFormats } from './constants';
import CustomDropdown from './customDropdown';
import { useStyles } from './styles';
import { useGetLocale } from './useGetLocale';
import Icon from 'features/shared/components/icon/index.js';

const YearMonthDatePicker = ({
  selected,
  onChange,
  placeholder,
  dateType,
  inputClassName,
  allowedYearsRange
}) => {
  const styles = useStyles();
  const locale = useGetLocale(dateFormats.monthYear);

  return (
    <DatePicker
      locale={locale}
      selected={selected}
      calendarClassName={styles.calendar}
      onChange={date => onChange(date)}
      dateFormat={dateFormats.monthYear}
      placeholderText={placeholder}
      monthClassName={() => styles.month}
      filterDate={date =>
        filterMonthDateByType(
          date,
          dateType,
          allowedYearsRange?.yearsBack,
          allowedYearsRange?.yearsForward
        )
      }
      showMonthYearPicker
      customInput={
        <input
          className={inputClassName}
          onChange={onChange}
          value={selected}
        />
      }
      renderCustomHeader={({ date, changeYear }) => (
        <div className={styles.header}>
          {getYearsRange(
            dateType,
            allowedYearsRange?.yearsBack,
            allowedYearsRange?.yearsForward
          ).includes(new Date(date).getFullYear() - 1) ? (
            <span
              className={styles.chevronLeft}
              onClick={() => changeYear(new Date(date).getFullYear() - 1)}
            >
              <Icon className={styles.navIcon} type="chevron_left" />
            </span>
          ) : (
            <span className={styles.chevronLeft}>
              <Icon className={styles.disabledNavIcon} type="chevron_left" />
            </span>
          )}

          <CustomDropdown
            selectedValue={new Date(date).getFullYear()}
            valueArray={getYearsRange(
              dateType,
              allowedYearsRange?.yearsBack,
              allowedYearsRange?.yearsForward
            )}
            changeFunction={year => changeYear(year)}
          />
          {getYearsRange(
            dateType,
            allowedYearsRange?.yearsBack,
            allowedYearsRange?.yearsForward
          ).includes(new Date(date).getFullYear() + 1) ? (
            <span
              className={styles.chevronRight}
              onClick={() => changeYear(new Date(date).getFullYear() + 1)}
            >
              <Icon className={styles.navIcon} type="chevron_right" />
            </span>
          ) : (
            <span className={styles.chevronRight}>
              <Icon className={styles.disabledNavIcon} type="chevron_right" />
            </span>
          )}
        </div>
      )}
    />
  );
};

export default YearMonthDatePicker;
