import classNames from 'classnames';
import * as R from 'ramda';

import Link from 'features/shared/components/link/index.js';
import Scrollbars from 'features/shared/components/scrollbars';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const borderRadius = {
  [BorderRadiusTypes.rounded]: '16px',
  [BorderRadiusTypes.sharp]: '5px'
};

const useStyles = createUseStyles(theme => ({
  menuContainer: {
    zIndex: 0,
    minWidth: '100%'
  },
  menu: {
    minWidth: '100%',
    backgroundColor: theme.inputFillFocusColor,
    border: `2px solid ${theme.hoverInputStrokeColor}`
  },
  scrollbarsContent: {},
  offsetBottom: {
    marginTop: '0.8rem',
    paddingTop: '1.2rem',
    paddingBottom: '1.2rem',
    borderRadius: borderRadius[theme.borderRadiusType]
  },
  offsetTop: {
    paddingBottom: '1.2rem',
    paddingTop: '1.2rem',
    borderRadius: borderRadius[theme.borderRadiusType]
  },
  option: {
    padding: '0.8rem 1.8rem',
    whiteSpace: 'nowrap',

    '&:hover': {
      backgroundColor: theme.hoverInputStrokeColor
    }
  },
  optionNoDataTitle: {
    cursor: 'default',
    color: theme.secondaryColor,

    '&:hover': {
      backgroundColor: theme.inputFillFocusColor
    }
  },
  link: {
    color: theme.primaryColor,
    textDecoration: 'none'
  }
}));

const NavigationDropdownMenu = ({
  forwardedRef,
  style,
  placement,
  options,
  onSelect,
  onMouseDown,
  className,
  offsetBottomClassName,
  offsetTopClassName,
  optionClassName,
  noDataTitle
}) => {
  const classes = useStyles();

  return (
    <div
      ref={forwardedRef}
      className={classes.menuContainer}
      style={style}
      data-placement={placement}
    >
      <div
        className={classNames(
          classes.menu,
          {
            [classes.offsetBottom]: placement === 'bottom',
            [offsetBottomClassName]:
              !R.isNil(offsetBottomClassName) && placement === 'bottom',
            [classes.offsetTop]: placement === 'top',
            [offsetTopClassName]:
              !R.isNil(offsetTopClassName) && placement === 'top'
          },
          className
        )}
        tabIndex="0"
        onMouseDown={onMouseDown}
      >
        <Scrollbars>
          <div className={classes.scrollbarsContent}>
            {options.map(option => {
              if (option.to) {
                return (
                  <Link
                    key={option.key}
                    to={option.to}
                    className={classes.link}
                  >
                    <div
                      className={classNames(classes.option, optionClassName)}
                      onClick={() => onSelect(option)}
                    >
                      {option.title}
                    </div>
                  </Link>
                );
              } else if (option.redirect) {
                return (
                  <a
                    key={option.key}
                    href={option.redirect}
                    className={classes.link}
                  >
                    <div
                      className={classNames(classes.option, optionClassName)}
                      onClick={() => onSelect(option)}
                    >
                      {option.title}
                    </div>
                  </a>
                );
              }

              return null;
            })}
            {R.isEmpty(options) && (
              <div
                className={classNames(
                  classes.option,
                  classes.optionNoDataTitle,
                  optionClassName
                )}
              >
                {noDataTitle}
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default NavigationDropdownMenu;
