import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import * as R from 'ramda';
import classNames from 'classnames';

import { useI18n } from 'modules/settings/components/settings.js';
import { createUseStyles, useTheme } from 'modules/styles/components/styles.js';
import {
  FontFamilies,
  FontWeights,
  TextFontSizes
} from 'modules/shared/constants/fonts.js';
import { formatNumber } from 'modules/shared/utils/number.js';
import { getColor } from '../services/portfolioChartStore';
import { mainAssetClassesI18nMapping } from '../constants';
import { goalIcons } from 'modules/shared/constants/icons';

const useStyles = createUseStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  verticalRoot: {
    flexDirection: 'column-reverse',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '16px'
  },
  chart: {
    marginRight: '13px'
  },
  tooltipRoot: {
    fontFamily: FontFamilies.roboto
  },
  tooltipHeader: {
    fontSize: TextFontSizes.xxSmall,
    lineHeight: TextFontSizes.xxSmallLineHeight
  },
  tooltipItem: {
    fontSize: TextFontSizes.xSmall,
    lineHeight: TextFontSizes.xSmallLineHeight,
    marginTop: '4px'
  },
  table: {
    borderCollapse: 'collapse',
    minWidth: '400px'
  },
  legendMarker: {
    width: '24px',
    height: '24px',
    borderRadius: '50%'
  },
  cell: {
    whiteSpace: 'nowrap',
    padding: '0 8px 0 0',
    textAlign: 'left',

    '&:first-child': {
      padding: '8px 2.4rem'
    },

    '&:last-child': {
      width: '100%'
    }
  },
  weightCell: {
    textAlign: 'right'
  },
  nameCell: {
    whiteSpace: 'normal',
    padding: '8px'
  },
  tableRow: {
    backgroundColor: theme.itemBackgroundColor_2,

    '&:nth-of-type(even)': {
      backgroundColor: theme.itemBackgroundColor_1
    }
  }
}));

const PortfolioChart = ({
  cultureCode,
  chartData,
  height,
  width,
  dataLabelsColor,
  backgroundSpacingColor,
  iconName,
  title,
  noLegend = false,
  isVertical = false,
  alignTitleBottom = true
}) => {
  const i18n = useI18n();
  const classes = useStyles();
  const theme = useTheme();
  const colors = theme.chartPortfolioColors;

  const chartOptions = {
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    chart: {
      backgroundColor: null,
      events: {
        load: function () {
          const icon = goalIcons.find(({ name }) => name === iconName)?.urlIcon;

          if (icon) {
            let innerSize = this.userOptions.plotOptions.pie.innerSize;
            this.renderer
              .image(
                icon,
                this.chartWidth / 2 - innerSize / 2,
                this.plotTop + this.plotSizeY / 2 - innerSize / 2,
                innerSize,
                innerSize
              )
              .add();
          }
        }
      },
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height: height !== undefined ? height + 20 : '130',
      width: width !== undefined ? width + 20: '130',
      spacing:
        (dataLabelsColor && width === null) || width >= 150
          ? [0, 75, 0, 75]
          : 0,
      animation: false
    },
    plotOptions: {
      series: {
        name: i18n('portfolioChart.default.portfolio'),
        animation: false
      },
      pie: {
        enableMouseTracking: chartData.some(
          ({ id }) => id === 'emptyFixedIncome'
        )
          ? false
          : true,
        innerSize: 35,
        dataLabels: {
          enabled:
            dataLabelsColor &&
            !chartData.some(({ id }) => id === 'emptyFixedIncome')
              ? true
              : false,
          formatter: function () {
            return this.y === 0
              ? null
              : `${formatNumber(cultureCode, this.y, 0, 1)} %`;
          },
          color: dataLabelsColor,
          connectorColor: dataLabelsColor,
          connectorShape: 'straight',
          padding: 0,
          crop: false,
          distance: width === null || width >= 150 ? 30 : -15,
          style: {
            fontSize: width === null || width >= 150 ? TextFontSizes.mediumPx : TextFontSizes.xxSmall,
            fontWeight: 'normal',
            fontFamily: FontFamilies.roboto,
            lineHeight: width === null || width >= 150 ? TextFontSizes.x1LineHeight : TextFontSizes.xSmall
          }
        },
        states: {
          enabled: false
        }
      }
    },
    tooltip: {
      useHTML: true,
      outside: true,
      formatter: function () {
        if (this.point.id === 'noToolbar') {
          return false;
        }
        return `
        <div class='${classes.tooltipRoot}'>
          <div class='${classes.tooltipHeader}'>${this.key}</div>
          <div class='${classes.tooltipItem}'>
          <span>${this.series.name}</span>
          <span>${formatNumber(cultureCode, this.y, 0, 1)}%</span>
          </div>
        </div>
        `;
      }
    },
    series: [
      {
        type: 'pie',
        data: chartData.map(i => ({
          id: i.id,
          name: i.name,
          y: i.weight,
          color: i.color
        })),
        size: '100%',
        innerSize: '50%',
        borderWidth:
          !backgroundSpacingColor ||
          R.isNil(chartData) ||
          R.isEmpty(chartData) ||
          chartData.some(
            ({ id, weight }) => id === 'emptyFixedIncome' || weight === 0
          )
            ? 0
            : 1,
        borderColor: backgroundSpacingColor
      }
    ],
    title: {
      text: title,
      style: {
        color: dataLabelsColor,
        fontFamily: FontFamilies.roboto,
        fontSize: TextFontSizes.medium,
        fontWeight: FontWeights.medium,
        whiteSpace: 'nowrap'
      }
    }
  };

  if (alignTitleBottom) {
    chartOptions.title.verticalAlign = 'bottom';
  } else {
    chartOptions.title.verticalAlign = 'top';
  }

  return (
    <div
      className={classNames(classes.root, {
        [classes.verticalRoot]: isVertical
      })}
    >
      {!noLegend && (
        <table className={classes.table}>
          <tbody>
            {chartData
              .filter(({ id }) => id !== 'noToolbar')
              .map(({ id, name, weight, color }, index) => (
                <tr key={id} className={classes.tableRow}>
                  <td className={classNames(classes.cell)}>
                    <div
                      className={classes.legendMarker}
                      style={{
                        background:
                          color || getColor(colors, index, chartData.length)
                      }}
                    />
                  </td>
                  <td className={classNames(classes.cell, classes.weightCell)}>
                    {formatNumber(cultureCode, weight, 0, 1)}%
                  </td>
                  <td className={classNames(classes.cell, classes.nameCell)}>
                    {i18n(mainAssetClassesI18nMapping[name]) || name}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      <div className={classes.chart}>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={'chart'}
          options={chartOptions}
        />
      </div>
    </div>
  );
};

export default PortfolioChart;
