import { isEmpty } from 'ramda';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import { creators } from '../../../riskScore/services/actions';
import { Goal, useGoalsStore } from '../../../shared/services/goalsStore';
import { GoalModalModeTypes } from '../../constants';
import { goalForm, useGoalForm } from '../../services/goalForm';
import { useCreateGoal } from '../useCreateGoal';
import { useUpdateGoal } from '../useUpdateGoal';
import GoalForm from './goalForm';
import { WithdrawalPlans } from 'features/roboAdvice/adviceSession/shared/constants';
import Button from 'features/shared/components/button';
import Modal from 'features/shared/components/modal/index';
import { useModalBodyMaxDimensions } from 'features/shared/components/modal/useModalBodyMaxDimensions';
import Scrollbars from 'features/shared/components/scrollbars';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  button: {
    alignSelf: 'flex-end'
  }
});

const GoalModal = ({ goalModalMode, setGoalModalMode, editGoalId }) => {
  const classes = useStyles();
  const i18n = useI18n();
  const {
    analyticsComponents: { isCashflowHidden }
  } = useCustomerConfig();

  const dispatch = useDispatch();
  const createGoal = useCreateGoal();
  const updateGoal = useUpdateGoal();
  const { isGoalPending } = useGoalsStore();
  const { values } = useGoalForm();
  const { modalBodyMaxHeight, headerRef, footerRef } =
    useModalBodyMaxDimensions();

  const handleGoalSubmit = async () => {
    const { errors } = goalForm.getState();

    if (!isEmpty(errors)) {
      goalForm.mutators.setFieldTouched('name', true);
      goalForm.mutators.setFieldTouched('data.timeHorizon', true);
      goalForm.mutators.setFieldTouched('data.timeHorizonToDisplay', true);
      if (!isCashflowHidden) {
        goalForm.mutators.setFieldTouched('data.withdrawalPlan', true);
        if (values.data?.withdrawalPlan === WithdrawalPlans.monthlyWithdrawal) {
          goalForm.mutators.setFieldTouched('data.monthlyWithdrawal', true);
          goalForm.mutators.setFieldTouched('data.withdrawalEnd', true);
        }
        if (values.data?.withdrawalPlan === WithdrawalPlans.oneTimeWithdrawal) {
          goalForm.mutators.setFieldTouched('data.capitalNeed', true);
        }
      }
      return;
    }

    if (goalModalMode === GoalModalModeTypes.new) {
      await createGoal();
    } else {
      await updateGoal(editGoalId, goalForm.getState().values as Partial<Goal>);
    }

    dispatch(creators.fieldChanged('timeHorizon', updateGoal));

    setGoalModalMode(null);

    goalForm.restart();
  };

  const closeHandler = () => {
    setGoalModalMode(null);
    goalForm.restart();
  };

  return (
    <Modal
      footer={
        <Button
          disabled={isGoalPending}
          onClick={handleGoalSubmit}
          className={classes.button}
          isLoading={isGoalPending}
        >
          {i18n(
            goalModalMode === GoalModalModeTypes.new
              ? 'roboAdvice.purposeAndRisk.addNewGoal'
              : 'shared.save'
          )}
        </Button>
      }
      header={i18n(
        goalModalMode === GoalModalModeTypes.new
          ? 'roboAdvice.purposeAndRisk.addNewGoal'
          : 'roboAdvice.purposeAndRisk.editGoal'
      )}
      isOpen={!!goalModalMode}
      onRequestClose={closeHandler}
      headerRef={headerRef}
      footerRef={footerRef}
    >
      <Form form={goalForm} onSubmit={() => {}}>
        {() => (
          <Scrollbars autoHeightMax={modalBodyMaxHeight}>
            <GoalForm />
          </Scrollbars>
        )}
      </Form>
    </Modal>
  );
};

export default GoalModal;
