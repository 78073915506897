import classNames from 'classnames';
import * as R from 'ramda';
import React from 'react';

import { Colors } from 'features/shared/constants/colors.js';
import { Typography } from 'features/shared/constants/typography';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const indexedMap = R.addIndex(R.map);

const useInformationSectionStyles = createUseStyles({
  root: {
    display: 'flex'
  },
  label: {
    color: Colors.gray50,
    marginRight: '2.4rem',
    flex: '0 0 auto',
    width: '15rem'
  }
});

export const InformationSection = ({ label, children }) => {
  const classes = useInformationSectionStyles();

  return (
    <div className={classes.root}>
      <div className={classes.label}>{label}</div>
      <div>{children}</div>
    </div>
  );
};

const borderRadius = {
  [BorderRadiusTypes.rounded]: '15px',
  [BorderRadiusTypes.sharp]: '5px'
};

const useInformationCardStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: theme.boxBackgroundColor_2,
    borderRadius: borderRadius[theme.borderRadiusType],
    padding: '12px',
    width: '320px',

    '&$pending': {
      opacity: '0.3'
    }
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  label: {
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight
  },
  value: {
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight
  },
  pending: {}
}));

export const InformationCard = ({ informationItems, isPending }) => {
  const classes = useInformationCardStyles();

  return (
    <div className={classNames(classes.root, { [classes.pending]: isPending })}>
      {indexedMap(
        (i, index) => (
          <div className={classes.item} key={index}>
            <div className={classes.label}>{i.label}</div>
            <div className={classes.value}>{i.value}</div>
          </div>
        ),
        informationItems
      )}
    </div>
  );
};
