import { CashflowGoalChartVertical } from '@quantfoliorepo/ui-components';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { useHolisticViewStore } from '../services/holisticViewStore';
import HolisticSection from './holisticSection';
import { useReadCashflowData } from './proposalSection/cashflowChart/useReadCashflowData';
import { useIsLoading } from './useIsLoading';
import { useCashflowChartStore } from 'features/roboAdvice/adviceSession/cashflowChart';
import {
  getCashflowWithdrawal,
  getCashflowYear
} from 'features/roboAdvice/adviceSession/proposal/components/cashflowTable';
import { TimeHorizonTypes } from 'features/roboAdvice/adviceSession/purposeAndRisk/constants';
import { riskScoreToNumber } from 'features/roboAdvice/adviceSession/riskScore/services/shared';
import { useReadDataListener } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { WithdrawalPlansClassToTitle } from 'features/roboAdvice/adviceSession/shared/constants';
import LoadingIndicator from 'features/shared/components/loadingIndicator/loadingIndicator';
import Table from 'features/shared/components/table/table.js';
import TableBody from 'features/shared/components/table/tableBody.js';
import TableBodyRow from 'features/shared/components/table/tableBodyRow.js';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { getColor } from 'features/shared/utils/colors';
import { formatNumber } from 'features/shared/utils/number';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

const AnyTableComponent: any = Table;
const AnyTableBody: any = TableBody;
const AnyTableBodyRow: any = TableBodyRow;

const useStyles = createUseStyles(theme => ({
  sectionHeader: {
    padding: `${Spacing.spacing02}px 0`,
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    fontWeight: FontWeights.medium
  },
  goalCharts: {
    display: 'flex',
    gap: Spacing.spacing02
  },
  legendMarker: {
    width: '24px',
    height: '24px',
    borderRadius: '50%'
  },
  legendMarkerCell: {
    width: '50px'
  },
  summaryRow: {
    backgroundColor: `${theme.inputFillFocusColor} !important;`
  },
  loadingIndicatorIcon: {
    color: theme.accentColor
  },
  numberCell: {
    textAlign: 'right'
  }
}));

const GoalsSection = () => {
  const i18n = useI18n();
  const classes = useStyles();
  const { timeHorizonConfig } = useCustomerConfig();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const holisticViewStore = useHolisticViewStore();
  const theme = useTheme();
  const isLoading = useIsLoading();
  const { goalsData: cashflowGoalsData } = useCashflowChartStore();

  useReadDataListener(
    holisticViewStore.proposalSectionStatuses.readCashflowData,
    true,
    useReadCashflowData()
  );

  const goalsData = React.useMemo(() => {
    const selectedClientsGoalIds = holisticViewStore.availableClients
      .filter(c => c.isSelected)
      .map(c => c.goals?.map(g => g.goalId))
      .flat();

    return cashflowGoalsData
      .filter(c => selectedClientsGoalIds.includes(c.goalId))
      .map(g => ({
        id: g.goalId,
        goalName: g.goalName,
        goalIcon: g.goalIcon,
        capitalNeed: g.capitalNeed,
        expectedValue: Number.isNaN(g.estimatedGoalAchievement * g.capitalNeed)
          ? 'N/A'
          : g.estimatedGoalAchievement * g.capitalNeed
      }));
  }, [cashflowGoalsData, holisticViewStore.availableClients]);

  const savingsPlanData = React.useMemo(() => {
    if (isLoading) return [];

    const data = holisticViewStore.availableClients
      .filter(c => c.isSelected)
      .map(c =>
        (c.goals || []).map(g => ({
          id: g.goalId,
          goalName: g.name,
          monthlyDeposit: g.data.monthlyDeposit || 0,
          firstDeposit: g.data.firstDeposit || 0,
          riskScore: riskScoreToNumber(g.data.riskScore),
          years:
            timeHorizonConfig.type === TimeHorizonTypes.radio
              ? g.data.timeHorizonToDisplay
              : g.data.timeHorizon,
          client: c.name
        }))
      )
      .flat();

    data.push({
      id: 'summary',
      goalName: i18n('roboAdvice.proposal.cost.summary'),
      monthlyDeposit: data.reduce((acc, val) => acc + val.monthlyDeposit, 0),
      firstDeposit: data.reduce((acc, val) => acc + val.firstDeposit, 0),
      riskScore: null,
      years: undefined,
      client: ''
    });

    return data;
  }, [holisticViewStore.availableClients, isLoading]);

  const withdrawalPlansData = React.useMemo(() => {
    if (isLoading) return [];

    return holisticViewStore.availableClients
      .filter(c => c.isSelected)
      .map(c =>
        (c.goals || []).map(g => ({
          id: g.goalId,
          goalName: g.name,
          withdrawal: getCashflowWithdrawal(g.data, cultureCode),
          frequency: g.data.withdrawalPlan
            ? i18n(WithdrawalPlansClassToTitle[g.data.withdrawalPlan])
            : '-',
          years: getCashflowYear(g.data, timeHorizonConfig),
          client: c.name
        }))
      )
      .flat();
  }, [
    cultureCode,
    holisticViewStore.availableClients,
    i18n,
    isLoading,
    timeHorizonConfig
  ]);

  // No selected clients
  if (
    holisticViewStore.availableClients.filter(c => c.isSelected).length === 0
  ) {
    return (
      <HolisticSection sectionHeader={i18n('roboAdvice.proposal.goals')} />
    );
  }

  // Not enough data for this section
  if (
    !isLoading &&
    holisticViewStore.availableClients
      .filter(c => c.isSelected)
      .every(
        c =>
          !c.financialSituationData ||
          !c.goals ||
          !c.goals.every(
            g =>
              ((g.data.portfolio && !g.data.isPortfolioCustom) ||
                (g.data.customPortfolio && g.data.isPortfolioCustom)) &&
              g.data.riskScore
          )
      )
  ) {
    return (
      <HolisticSection
        sectionHeader={i18n('roboAdvice.proposal.goals')}
        notEnoughData
      />
    );
  }

  return (
    <HolisticSection sectionHeader={i18n('roboAdvice.proposal.goals')}>
      {isLoading ? (
        <LoadingIndicator color={theme.accentColor} />
      ) : (
        <div className={classes.goalCharts}>
          {goalsData.map((goal, index) => (
            <CashflowGoalChartVertical
              key={goal.id}
              cultureCode={cultureCode}
              chartData={{
                capitalNeed: goal.capitalNeed,
                expectedValue: goal.expectedValue
              }}
              title={goal.goalName}
              iconName={goal.goalIcon}
              color={getColor(
                theme.chartPortfolioColors,
                index,
                savingsPlanData.length
              )}
            />
          ))}
        </div>
      )}

      <div className={classes.sectionHeader}>
        {i18n('tools.holisticView.savingPlans')}
      </div>
      <AnyTableComponent
        header={[
          {
            title: ''
          },
          {
            title: i18n('roboAdvice.purposeAndRisk.purposeTable.goal')
          },
          {
            title: i18n('roboAdvice.advisory.portfolioTable.monthlyDeposit'),
            className: classes.numberCell
          },
          {
            title: i18n('roboAdvice.advisory.portfolioTable.firstDeposit'),
            className: classes.numberCell
          },
          {
            title: i18n('roboAdvice.proposal.riskScore'),
            className: classes.numberCell
          },
          {
            title: i18n('roboAdvice.purposeAndRisk.purposeTable.years'),
            className: classes.numberCell
          },
          {
            title: i18n('tools.holisticView.client')
          }
        ]}
        isLoading={isLoading}
      >
        <AnyTableBody noDataTitle={'no data'} cellsNumber={7}>
          {savingsPlanData.map((data, index) => (
            <AnyTableBodyRow key={data.id}>
              {({ bodyRowCellClassName }) => (
                <>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.legendMarkerCell,
                      {
                        [classes.summaryRow]:
                          index === savingsPlanData.length - 1
                      }
                    )}
                  >
                    {index !== savingsPlanData.length - 1 ? (
                      <div
                        className={classes.legendMarker}
                        style={{
                          background: getColor(
                            theme.chartPortfolioColors,
                            index,
                            savingsPlanData.length
                          )
                        }}
                      />
                    ) : null}
                  </td>
                  <td
                    className={classNames(bodyRowCellClassName, {
                      [classes.summaryRow]: index === savingsPlanData.length - 1
                    })}
                  >
                    {data.goalName}
                  </td>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.numberCell,
                      {
                        [classes.summaryRow]:
                          index === savingsPlanData.length - 1
                      }
                    )}
                  >
                    {formatNumber(cultureCode, data.monthlyDeposit, 0, 0)}
                  </td>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.numberCell,
                      {
                        [classes.summaryRow]:
                          index === savingsPlanData.length - 1
                      }
                    )}
                  >
                    {formatNumber(cultureCode, data.firstDeposit, 0, 0)}
                  </td>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.numberCell,
                      {
                        [classes.summaryRow]:
                          index === savingsPlanData.length - 1
                      }
                    )}
                  >
                    {data.riskScore}
                  </td>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.numberCell,
                      {
                        [classes.summaryRow]:
                          index === savingsPlanData.length - 1
                      }
                    )}
                  >
                    {data.years}
                  </td>
                  <td
                    className={classNames(bodyRowCellClassName, {
                      [classes.summaryRow]: index === savingsPlanData.length - 1
                    })}
                  >
                    {data.client}
                  </td>
                </>
              )}
            </AnyTableBodyRow>
          ))}
        </AnyTableBody>
      </AnyTableComponent>

      <div className={classes.sectionHeader}>
        {i18n('tools.holisticView.withdrawalPlans')}
      </div>
      <AnyTableComponent
        header={[
          {
            title: ''
          },
          {
            title: i18n('roboAdvice.purposeAndRisk.purposeTable.goal')
          },
          {
            title: i18n('roboAdvice.proposal.cashflow.withdrawal')
          },
          {
            title: i18n('roboAdvice.proposal.cashflow.frequency')
          },
          {
            title: i18n('roboAdvice.purposeAndRisk.purposeTable.years')
          },
          {
            title: i18n('tools.holisticView.client')
          }
        ]}
        isLoading={isLoading}
      >
        <AnyTableBody noDataTitle={'no data'} cellsNumber={6}>
          {withdrawalPlansData.map((data, index) => (
            <AnyTableBodyRow key={data.id}>
              {({ bodyRowCellClassName }) => (
                <>
                  <td className={bodyRowCellClassName}>
                    <div
                      className={classes.legendMarker}
                      style={{
                        background: getColor(
                          theme.chartPortfolioColors,
                          index,
                          savingsPlanData.length
                        )
                      }}
                    />
                  </td>
                  <td className={bodyRowCellClassName}>{data.goalName}</td>
                  <td className={bodyRowCellClassName}>{data.withdrawal}</td>
                  <td className={bodyRowCellClassName}>{data.frequency}</td>
                  <td className={bodyRowCellClassName}>{data.years}</td>
                  <td className={bodyRowCellClassName}>{data.client}</td>
                </>
              )}
            </AnyTableBodyRow>
          ))}
        </AnyTableBody>
      </AnyTableComponent>
    </HolisticSection>
  );
};

export default GoalsSection;
