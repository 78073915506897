import { isNil } from 'ramda';

import config from 'config/index.js';
import { customersApi, stateApi } from 'features/shared/utils/axios';

const adviceTypes = {
  adviceSession: {
    pdfReportSource: 'report',
    params: {}
  },
  orderExecution: {
    pdfReportSource: 'report-order-execution',
    params: { 'order-execution': true }
  }
};

export const createPdfReport = (
  accessToken,
  cancelToken,
  customerId,
  sourceParams,
  sessionId,
  investorId,
  adviceType = 'adviceSession'
) => {
  return customersApi.post(
    `api/v2/pdf-report/`,
    {
      pdf_report_config: {
        sandbox: `${config.REACT_APP_PDF_SHIDT_API_SANDBOX}` === 'on',
        landscape: false,
        format: 'A4',
        zoom: 1,
        margin: 0,
        use_print: true,
        wait_for: 'getIsReportGenerated'
      },
      pdf_report_settings: sourceParams,
      pdf_report_source: `${config.REACT_APP_REPORT_FRONT_URL}/${adviceTypes[adviceType].pdfReportSource}/${customerId}/body`,
      customer_id: customerId,
      session_id: sessionId,
      investor_id: investorId
    },
    {
      cancelToken,
      responseType: 'blob',
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json'
      },
      params: adviceTypes[adviceType].params
    }
  );
};

export const createNewPdf2Report = (
  accessToken,
  cancelToken,
  sessionId,
  data
) => {
  return stateApi.post(`/session/${sessionId}/generate_pdf`, data, {
    cancelToken,
    headers: {
      Authorization: accessToken
    },
    responseType: 'blob'
  });
};

export const createNewPdfOrderExecutionReport = (
  accessToken,
  cancelToken,
  sessionId,
  data
) => {
  return stateApi.post(
    `/session/${sessionId}/generate_order_execution_pdf`,
    data,
    {
      cancelToken,
      headers: {
        Authorization: accessToken
      },
      responseType: 'blob'
    }
  );
};

export const getAvailableDocuments = (
  accessToken,
  cancelToken,
  clientId,
  sessionId,
  version
) => {
  return stateApi.get(
    `/report/${clientId}/${sessionId}${
      !isNil(version) ? '?version=' + version : ''
    }`,
    {
      cancelToken,
      headers: { Authorization: accessToken }
    }
  );
};

export const createDownloadReport = (
  accessToken,
  cancelToken,
  clientId,
  sessionId,
  data
) => {
  return stateApi.post(`/report/${clientId}/${sessionId}/download`, data, {
    cancelToken,
    headers: { Authorization: accessToken },
    responseType: 'blob'
  });
};

export const completePdfReport = (
  accessToken,
  cancelToken,
  clientId,
  sessionId,
  data
) => {
  return stateApi.put(`/report/${clientId}/${sessionId}/complete`, data, {
    cancelToken,
    headers: { Authorization: accessToken }
  });
};

export const cachePDFReportData = (accessToken, cancelToken, sessionId) => {
  return stateApi.post(`/session/${sessionId}/cache_pdf_data`, null, {
    cancelToken,
    headers: {
      Authorization: accessToken
    }
  });
};
