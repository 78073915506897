import { useTable } from './context';
import TableBodyRowComponent from 'features/shared/components/table/tableBodyRow.js';

// Temporary solution until types added to TableBodyRowComponent
const AnyTableBodyRowComponent = TableBodyRowComponent as any;

type TableBodyRowComponentProps = {
  children: ({
    bodyRowCellClassName
  }: {
    bodyRowCellClassName: string;
  }) => React.ReactNode;
  onClick?: () => void;
  id?: string;
  hoverEffect?: boolean;
};

type TableBodyRowProps = TableBodyRowComponentProps;

const TableBodyRow = ({ children, ...restProps }: TableBodyRowProps) => {
  const table = useTable();
  const tableStore = table.module.useTableStore();

  return (
    <AnyTableBodyRowComponent
      selectedRowIds={tableStore.selectedRowIds}
      changeRowSelection={(rowId, isSelected) => {
        tableStore.changeRowSelection(isSelected, rowId);
      }}
      {...restProps}
    >
      {children}
    </AnyTableBodyRowComponent>
  );
};

export default TableBodyRow;
