import { useCallback } from 'react';

import Card from 'features/shared/components/card';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 16,
    paddingRight: 'calc(100% - (6 * 263px))'
  }
});

type CardItem = {
  icon: string;
  id: string;
  title: string;
  isValueAdaptedIconVisible?: boolean;
  onCloseValueAdapted?: () => void;
};

export type Props = {
  items: CardItem[];
  values: string[];
  onChange: (id: string[]) => void;
  afterChange?: (ids: string[]) => void;
  disabled?: boolean;
};

const SelectionCards = ({
  items,
  values,
  onChange,
  afterChange,
  disabled
}: Props) => {
  const classes = useStyles();

  const selectionOnChange = useCallback(
    (id: string) => {
      let newValues: string[] = [];
      if (values) {
        newValues = [...values];
      }
      const index = newValues.indexOf(id);
      if (index > -1) {
        newValues.splice(index, 1);
      } else {
        newValues.push(id);
      }

      onChange(newValues);
      afterChange?.(newValues);
    },
    [values, onChange, afterChange]
  );

  return (
    <div className={classes.root}>
      {items.map(i => (
        <Card
          key={i.id}
          icon={i.icon}
          id={i.id}
          title={i.title}
          selected={values && values.includes(i.id)}
          onChange={disabled ? () => {} : selectionOnChange}
          disabled={disabled}
          isValueAdaptedIconVisible={i.isValueAdaptedIconVisible}
          onCloseValueAdapted={i.onCloseValueAdapted}
        />
      ))}
    </div>
  );
};

export default SelectionCards;
