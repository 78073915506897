import { CancelToken } from 'axios';

import {
  DeleteInvestorResponse,
  GetAdvisorsResponse,
  GetInvestorsResponse,
  TransferInvestorResponse
} from './types';
import { stateApi } from 'features/shared/utils/axios';

export const getAdvisors = (accessToken: string, cancelToken?: CancelToken) =>
  stateApi.get<GetAdvisorsResponse>('/advisor', {
    cancelToken,
    headers: { Authorization: accessToken }
  });

export const getInvestors = (accessToken: string, cancelToken?: CancelToken) =>
  stateApi.get<GetInvestorsResponse>('/investor', {
    cancelToken,
    headers: { Authorization: accessToken }
  });

export const deleteInvestor = (accessToken: string, investorId: string) =>
  stateApi.delete<DeleteInvestorResponse>(`/investor/${investorId}`, {
    headers: { Authorization: accessToken }
  });

export const transferInvestor = (
  accessToken: string,
  investorId: string,
  advisorId: string
) =>
  stateApi.put<TransferInvestorResponse>(
    `/investor/${investorId}/:transfer`,
    { advisorId },
    {
      headers: { Authorization: accessToken }
    }
  );
