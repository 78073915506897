import { format, parseISO } from 'date-fns';
import { isEmpty } from 'ramda';
import { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useElementSize } from 'usehooks-ts';

import { Layouts } from '../constants';
import { customDownloadForm } from '../services/customDownloadForm';
import { useCustomDownloadStore } from '../services/customDownloadStore';
import { useDownloadReportDocuments } from './useDownloadReportDocuments';
import Button from 'features/shared/components/button';
import Modal from 'features/shared/components/modal/index';
import { useModalBodyMaxDimensions } from 'features/shared/components/modal/useModalBodyMaxDimensions';
import Scrollbars from 'features/shared/components/scrollbars';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { formatDate } from 'features/shared/utils/dateTime';
import { filterNil } from 'features/shared/utils/filters';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import FinalFormCheckboxes from 'features/sharedModules/finalForm/components/checkboxes';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  attachmentList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    fontWeight: FontWeights.medium,
    paddingTop: Spacing.spacing02,
    paddingBottom: Spacing.spacing01
  },
  listItem: {
    marginTop: 7
  },
  subHeader: {
    color: theme.secondaryColor,
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight
  }
}));

const CustomDownloadPopup = ({
  isModalOpen,
  setIsModalOpen,
  selectedSessionId
}) => {
  const classes = useStyles();
  const i18n = useI18n();
  const [selectedAllAttachments, setSelectedAllAttachments] = useState(true);
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const downloadReportDocuments = useDownloadReportDocuments();
  const documents =
    useCustomDownloadStore.getState().availableDocuments[selectedSessionId] ||
    [];
  const { modalBodyMaxHeight, headerRef, footerRef } =
    useModalBodyMaxDimensions();
  const [subHeaderRef, { height: subHeaderHeight }] = useElementSize();
  const [reportRef, { height: reportHeight }] = useElementSize();
  const [attachmentsHeaderRef, { height: attachmentsHeaderHeight }] =
    useElementSize();

  const attachments = documents
    .filter(({ documentType }) => documentType === 'report_attachment')
    .sort((a, b) => a.createdAt.localeCompare(b.createdAt));

  const report = documents.find(
    ({ documentType }) => documentType === 'report'
  );
  const createdDate =
    documents && !isEmpty(documents)
      ? `${formatDate(cultureCode, documents[0].createdAt)} ${format(
          parseISO(documents[0].createdAt),
          'H:mm'
        )}`
      : null;

  const filesToDownload = () =>
    [...attachments, report]
      .filter(filterNil)
      .filter(({ documentId }) =>
        [
          ...customDownloadForm.getState().values.attachments,
          ...customDownloadForm.getState().values.report
        ].includes(documentId)
      );

  useEffect(() => {
    customDownloadForm.initialize({
      allAttachments: ['all'],
      report: [report?.documentId],
      attachments: documents
        .filter(({ documentType }) => documentType === 'report_attachment')
        .map(({ documentId }) => documentId)
    });
  }, [documents]);

  const handleChangeAll = value => {
    const isAllSelected = !!value.length;

    if (isAllSelected) {
      customDownloadForm.change(
        'attachments',
        attachments.map(a => a.documentId)
      );
    } else if (!isAllSelected) {
      customDownloadForm.change('attachments', []);
    }

    setSelectedAllAttachments(isAllSelected);
  };

  const handleSelectAttachment = value => {
    if (value.length === attachments.length && !selectedAllAttachments) {
      customDownloadForm.change('allAttachments', ['all']);
      setSelectedAllAttachments(true);
    } else if (value.length !== attachments.length && selectedAllAttachments) {
      customDownloadForm.change('allAttachments', []);
      setSelectedAllAttachments(false);
    }
  };

  return (
    <Modal
      footer={
        <Button
          disabled={filesToDownload().length === 0}
          onClick={() => {
            downloadReportDocuments(selectedSessionId, filesToDownload());
            setIsModalOpen(false);
          }}
        >
          {i18n('roboAdvice.client.adviceSessionsTable.downloadMergedPDF')}
        </Button>
      }
      header={i18n('roboAdvice.client.adviceSessionsTable.customDownload')}
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      headerRef={headerRef}
      footerRef={footerRef}
    >
      <div ref={subHeaderRef} className={classes.subHeader}>
        {`${i18n('shared.created')} ${createdDate}`}
      </div>

      <Form form={customDownloadForm} onSubmit={() => {}}>
        {() => (
          <>
            <div ref={reportRef}>
              <div className={classes.header}>
                {i18n('roboAdvice.proposal.generateReport.report')}
              </div>

              <FinalFormCheckboxes
                name="report"
                items={[
                  {
                    activeValue: report?.documentId,
                    label: i18n('roboAdvice.proposal.generateReport.report')
                  }
                ]}
                width={25}
                height={25}
                layout={Layouts.vertical}
              />
            </div>

            {!isEmpty(attachments) && (
              <>
                <div className={classes.header} ref={attachmentsHeaderRef}>
                  {i18n('roboAdvice.proposal.generateReport.attachments')}
                </div>

                <Scrollbars
                  autoHeightMax={
                    modalBodyMaxHeight -
                    subHeaderHeight -
                    reportHeight -
                    attachmentsHeaderHeight
                  }
                >
                  <div className={classes.attachmentList}>
                    <FinalFormCheckboxes
                      name="allAttachments"
                      items={[
                        {
                          activeValue: 'all',
                          label: i18n('shared.all')
                        }
                      ]}
                      width={25}
                      height={25}
                      layout={Layouts.vertical}
                      afterChange={handleChangeAll}
                    />

                    <FinalFormCheckboxes
                      name="attachments"
                      items={attachments.map(({ documentId, name }) => ({
                        activeValue: documentId,
                        label: name
                      }))}
                      width={25}
                      height={25}
                      layout={Layouts.vertical}
                      className={classes.listItem}
                      afterChange={handleSelectAttachment}
                    />
                  </div>
                </Scrollbars>
              </>
            )}
          </>
        )}
      </Form>
    </Modal>
  );
};

export default CustomDownloadPopup;
