import classNames from 'classnames';
import React, { useEffect } from 'react';

import { clientForm } from '../../../../main/services/clientForm';
import { useInitTableUBOInfo } from './useInitTableUBOInfo';
import ENINDate from 'features/shared/components/eninDate';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  eninWrapper: {
    display: 'flex',
    justifyContent: 'right'
  }
}));

type Props = {
  wrapClassName?: string;
};

const TableUBOInfo = ({ wrapClassName }: Props) => {
  const styles = useStyles();
  const initTableUBOInfo = useInitTableUBOInfo();

  const { values } = clientForm.getState();
  const eninUpdateDate = values.kyc?.enin?.ubo?.eninUpdateDate;

  useEffect(() => {
    initTableUBOInfo(false);
  }, []);

  return (
    <div className={classNames(styles.eninWrapper, wrapClassName)}>
      {eninUpdateDate && <ENINDate date={new Date(eninUpdateDate)} />}
    </div>
  );
};

export default TableUBOInfo;
