import classNames from 'classnames';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  link: {
    color: theme.accentColor,
    textDecoration: 'none',
    cursor: 'pointer',

    '&$disabled': {
      color: theme.disabledTextColor,
      cursor: 'default'
    }
  },
  disabled: {}
}));

const Link = ({ children, className, disabled = false, to, ...rest }) => {
  const classes = useStyles();

  return (
    <ReactRouterLink
      {...rest}
      to={disabled ? '' : to || ''}
      className={classNames(
        classes.link,
        { [classes.disabled]: disabled },
        className
      )}
    >
      {children}
    </ReactRouterLink>
  );
};

export default Link;
