import { Countries } from '../constants';
import { filterNil } from 'features/shared/utils/filters';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export const CountriesTypeOptions = {
  exclusive: 'exclusive',
  preferred: 'preferred'
} as const;

export const useCountryDropdownOptions = () => {
  const {
    roboAdvice: {
      countries: { type: configCountriesType, items: configCountriesItems }
    }
  } = useCustomerConfig();
  const i18n = useI18n();

  const formattedConfigCountriesItems = configCountriesItems.map(item =>
    item.toLowerCase()
  );
  const formattedCountries = Countries.map(({ key, title }) => {
    const translatedTitle = i18n(`roboAdvice.country.${key.toLowerCase()}`);
    if (translatedTitle) {
      return { key, title: translatedTitle };
    }

    return { key, title };
  });

  if (configCountriesType === CountriesTypeOptions.exclusive) {
    return formattedCountries.filter(({ key }) =>
      formattedConfigCountriesItems.includes(key)
    );
  } else if (configCountriesType === CountriesTypeOptions.preferred) {
    const preferredCountries = formattedConfigCountriesItems
      .map(item => formattedCountries.find(({ key }) => key === item))
      .filter(filterNil);
    const restCountries = formattedCountries.filter(
      ({ key }) => !formattedConfigCountriesItems.includes(key.toLowerCase())
    );
    return [...preferredCountries, ...restCountries];
  }

  return formattedCountries;
};
