import { all, takeEvery, call } from 'redux-saga/effects';
import { v4 } from 'uuid';

import { types } from './actions.js';

export function* watchInitialize({
  formApi,
  form,
  name,
  getDoInitializeDetails
}) {
  yield takeEvery(
    a =>
      a.type === types.DETAILS_OPENED &&
      a.meta.form === form &&
      a.meta.name === name,
    function* () {
      const doInitializeDetails = yield call(getDoInitializeDetails);

      if (doInitializeDetails) {
        const id = yield call(v4);
        yield call(
          { context: formApi.mutators, fn: formApi.mutators.push },
          name,
          { id }
        );
      }
    }
  );
}

export function* watchAddNewDetail({ formApi, form, name, getDoAddNewDetail }) {
  yield takeEvery(
    a =>
      a.type === types.DETAIL_CHANGED &&
      a.meta.form === form &&
      a.meta.name === name,
    function* () {
      const doAddNewDetail = yield call(getDoAddNewDetail);

      if (doAddNewDetail) {
        const id = yield call(v4);
        yield call(
          { context: formApi.mutators, fn: formApi.mutators.push },
          name,
          { id }
        );
      }
    }
  );
}

export default function* (settings) {
  yield all([watchInitialize(settings), watchAddNewDetail(settings)]);
}
