import classNames from 'classnames';

import { useClientForm } from '../../main/services/clientForm';
import { useClientPageStore } from '../../main/services/clientPageStore';
import { useSaveClientInformation } from './useSaveClientInformation';
import Button from 'features/shared/components/button';
import { LayoutBreakpoints } from 'features/shared/constants/layoutBreakpoints';
import { Spacing } from 'features/shared/constants/spacing';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: Spacing.spacing04
  },
  actionButton: {
    '& + &': {
      marginLeft: '2.4rem'
    }
  },
  shortActionButtons: {},
  longActionButtons: {},
  [`@media (max-width: ${LayoutBreakpoints.desktopMedium}px)`]: {
    longActionButtons: {
      marginTop: Spacing.spacing02,
      width: '973px'
    },
    shortActionButtons: {
      marginTop: Spacing.spacing02,
      width: '700px'
    }
  }
});
type Props = {
  isContactListEnabled: boolean;
};

const ActionButtons = ({ isContactListEnabled }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const saveClientInformation = useSaveClientInformation();
  const clientPageStore = useClientPageStore();
  const { errors } = useClientForm();

  return (
    <div
      className={classNames(
        classes.actionButtons,
        isContactListEnabled
          ? classes.longActionButtons
          : classes.shortActionButtons
      )}
    >
      <Button
        disabled={
          clientPageStore.isCreateClientPending ||
          clientPageStore.isUpdateClientPending ||
          (errors?.clientInformation &&
            Object.keys(errors.clientInformation).length !== 0)
        }
        className={classes.actionButton}
        onClick={() => {
          saveClientInformation();
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }}
      >
        {i18n('roboAdvice.client.save')}
      </Button>
    </div>
  );
};

export default ActionButtons;
