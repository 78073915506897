import classNames from 'classnames';
import * as R from 'ramda';
import React from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import ReactResizeDetector from 'react-resize-detector';
import { Carousel as RrCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { useCarouselStore } from './carouselStore';
import { LayoutBreakpoints } from 'features/shared/constants/layoutBreakpoints';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const indexedMap = R.addIndex(R.map);

const borderRadius = {
  [BorderRadiusTypes.rounded]: '15px',
  [BorderRadiusTypes.sharp]: '10px'
};

const useStyles = createUseStyles(theme => ({
  root: {
    display: 'flex',
    padding: `${Spacing.spacing02}px 0`,
    backgroundColor: theme.boxBackgroundColor_1,
    borderRadius: borderRadius[theme.borderRadiusType],

    '&$pending': {
      opacity: '0.3',
      pointerEvents: 'none'
    }
  },
  horizontalRoot: {
    display: 'flex',
    flexDirection: 'column'
  },
  pending: {},
  tabs: {
    paddingRight: Spacing.spacing04
  },
  horizontalTabs: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: Spacing.spacing02,
    padding: `0 ${Spacing.spacing02}px ${Spacing.spacing04}px`
  },
  tab: {
    fontWeight: FontWeights.medium,
    fontSize: Typography.body1.fontSize,
    lineHeight: Typography.body1.lineHeight,
    whiteSpace: 'nowrap',
    marginBottom: Spacing.spacing02,
    paddingBottom: '5px',
    color: theme.secondaryColor,
    cursor: 'pointer',

    '&$active': {
      width: 'max-content',
      color: theme.primaryColor,
      borderBottomColor: theme.accentColor,
      borderBottomWidth: '4px',
      borderBottomStyle: 'solid'
    }
  },
  horizontalTab: {
    marginBottom: 0
  },
  active: {},
  item: {
    minHeight: '512px',

    '& circle': {
      color: theme.accentColor
    }
  },
  body: {
    width: 'calc(100% - 300px)'
  },
  horizontalBody: {
    width: '100%'
  },
  [`@media (max-width: ${LayoutBreakpoints.desktopMedium}px)`]: {
    root: {
      display: 'flex',
      flexDirection: 'column'
    },
    tabs: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      gap: Spacing.spacing02,
      padding: `0 ${Spacing.spacing02}px ${Spacing.spacing04}px`
    },
    tab: {
      marginBottom: 0
    },
    body: {
      width: '100%'
    }
  }
}));

export const Carousel = ({
  children,
  getIsPending = selectedItemIndex => false,
  carouselId,
  forceHorizontalTabs = false,
  className = ''
}) => {
  const classes = useStyles();
  const carouselStore = useCarouselStore();

  return (
    <div
      className={classNames(classes.root, className, {
        [classes.pending]: getIsPending(
          carouselStore[carouselId]?.selectedItemIndex
        ),
        [classes.horizontalRoot]: forceHorizontalTabs
      })}
    >
      {children}
    </div>
  );
};

export const CarouselTabs = ({
  children,
  carouselId,
  forceHorizontalTabs = false,
  className = ''
}) => {
  const classes = useStyles();
  const carouselStore = useCarouselStore();

  const tabs = flattenChildren(children);

  return (
    <div
      className={classNames(classes.tabs, className, {
        [classes.horizontalTabs]: forceHorizontalTabs
      })}
    >
      {indexedMap(
        (tab, index) =>
          React.cloneElement(tab, {
            onClick: e => {
              carouselStore.setSelectedItemIndex(carouselId, index);
              tab.props.onClick && tab.props.onClick(e);
            },
            isActive: index === carouselStore[carouselId]?.selectedItemIndex,
            forceHorizontalTabs
          }),
        tabs
      )}
    </div>
  );
};

export const CarouselTab = ({
  children,
  onClick = () => {},
  isActive = false,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <div
      data-testid={restProps['data-testid']}
      className={classNames(classes.tab, {
        [classes.active]: isActive,
        [classes.horizontalTab]: restProps['forceHorizontalTabs']
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export const CarouselBody = ({
  children,
  carouselId,
  containerClassName = null,
  isSwipeable = true,
  forceHorizontalTabs = false
}) => {
  const classes = useStyles();
  const carouselStore = useCarouselStore();
  const [height, setHeight] = React.useState(); // height state change forces rerender of carousel and as the result dynamicHeight recalculation
  const onResize = height => {
    setHeight(height);
  };

  return (
    <div
      className={classNames(classes.body, containerClassName, {
        [classes.horizontalBody]: forceHorizontalTabs
      })}
    >
      <RrCarousel
        dynamicHeight={true}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        autoplay={false}
        swipeable={isSwipeable}
        selectedItem={carouselStore[carouselId]?.selectedItemIndex}
        onChange={index =>
          carouselStore.setSelectedItemIndex(carouselId, index)
        }
        renderItem={(item, options) =>
          React.cloneElement(item, { onResize, isSelected: options.isSelected })
        }
        showArrows={false}
      >
        {children}
      </RrCarousel>
    </div>
  );
};

export const CarouselItem = ({
  children,
  onResize = null,
  isSelected = false,
  className = null,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.item, className)}
      data-testid={restProps['data-testid']}
    >
      {React.cloneElement(children, { isSelected })}
      <ReactResizeDetector
        handleHeight
        onResize={(width, height) => {
          isSelected && onResize && onResize(height);
        }}
      />
    </div>
  );
};
