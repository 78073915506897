import { ValidationErrors } from 'final-form';
import * as R from 'ramda';

import { useTranslationsStore, Translation } from './translationsStore';
import { createForm } from 'features/sharedModules/finalForm/services/finalForm';
import { createUseFinalFormAdapter } from 'features/sharedModules/zustand/components/finalFormAdapter';

export type TranslationsFormValues = any;

export const getTranslationsPlaceholdersErrors = ({
  values,
  translationsStore,
  isAdminModeActive
}): ValidationErrors => {
  const errors: ValidationErrors = {};
  if (
    isAdminModeActive &&
    !R.isEmpty(values) &&
    values[Object.keys(values)[0]].hasOwnProperty('default')
  ) {
    Object.entries(values).forEach(
      ([name, { default: defaultValue }]: [string, any]) => {
        if (defaultValue === '') {
          errors[name] = {
            default:
              'admin.appAdmin.advisorSolution.text.emptyDefaultFieldError'
          };
        }
      }
    );
  } else if (
    !isAdminModeActive &&
    !R.isEmpty(values) &&
    values[Object.keys(values)[0]].hasOwnProperty('value')
  ) {
    Object.entries(values).forEach(([tName, tValue]: [string, any]) => {
      const regex = /-/g;
      const translationName = tName.replace(regex, '.');
      const translationData: Translation = translationsStore.find(
        (t: Translation) => t.name === translationName
      );
      if (!translationData || tValue.value === '') return;

      const placeholderRegex = /\{\d+\}/g;
      const sortFn = (a, b) => (a > b ? 1 : -1);
      const translationPlaceholderItems: string[] =
        translationData.default.match(placeholderRegex) || [];
      translationPlaceholderItems.sort(sortFn);

      const valuePlaceholderItems: string[] =
        tValue.value.match(placeholderRegex) || [];
      valuePlaceholderItems.sort(sortFn);
      if (!R.equals(translationPlaceholderItems, valuePlaceholderItems)) {
        errors[tName] = {
          value: 'admin.appAdmin.advisorSolution.wrongPlaceholders'
        };
      }
    });
  }
  return errors;
};

export const form = createForm<TranslationsFormValues>({
  onSubmit: () => {},
  validationDependencies: {
    translationsStore: [
      () => useTranslationsStore.getState().translations,
      useTranslationsStore.subscribe
    ],
    isAdminModeActive: [
      () => useTranslationsStore.getState().isAdminModeActive,
      useTranslationsStore.subscribe
    ]
  },
  validate: (values, { translationsStore, isAdminModeActive }) => {
    const errors = getTranslationsPlaceholdersErrors({
      translationsStore,
      values,
      isAdminModeActive
    });

    return errors;
  }
});

export const useForm = createUseFinalFormAdapter({
  form,
  formSubscription: {
    values: true,
    errors: true,
    touched: true,
    pristine: true
  }
});
