export const Colors = {
  black: '#000000',
  white: '#ffffff',
  white_006: 'rgba(255, 255, 255, 0.06)',
  blue25: '#164152',
  blue50: '#1a242a',
  blue50_07: 'rgba(26, 36, 42, 0.7)',
  blue75: '#1e2a30',
  blue100: '#233037',
  blue150: '#0bb3f3',
  blue150_05: 'rgba(11, 179, 243, 0.5)',
  blue170: '#4fb2ed',
  blue200: '#2a3840',
  blue250: '#222e35',
  blue300: '#28363d',
  blue325: '#34809d',
  blue350: '#3c4a54',
  blue400: '#04739e',
  blue500: '#173845',
  blue600: '#3d709b',
  blue650: '#56ccf2',
  blue650_03: 'rgba(86, 204, 242, 0.3)',
  blue700: '#9ecaf2',
  blue800: '#00bbf6',
  gray25: '#4c5e69',
  gray50: '#6a8595',
  gray50_05: 'rgba(106, 133, 149, 0.5)',
  gray50_06: 'rgba(106, 133, 149, 0.6)',
  gray100: '#707070',
  gray125: '#78909c',
  gray150: '#2b373f',
  gray200: '#526874',
  gray300: '#cccccc',
  gray400: '#2c373d',
  gray500: '#536067',
  gray600: '#73657B',
  gray700: '#FCFCFD',
  gray800: '#F8F8FB',
  gray900: '#2F2137',
  red50: '#f25b5b',
  red50_05: 'rgba(242, 91, 91, 0.5)',
  red50_025: 'rgba(242, 91, 91, 0.25)',
  red300: '#392226',
  red100: '#863f42',
  green50: '#41b851',
  green50_05: 'rgba(65, 184, 81, 0.5)',
  green50_025: 'rgba(65, 184, 81, 0.25)',
  green100: '#41bf50',
  green200: '#82A03F',
  green200_02: 'rgba(130, 160, 63, 0.2)',
  green300: '#0f4522',
  green400: '#56ad61',
  green400_05: 'rgba(86,173,97, 0.5)',
  green500: '#41B851',
  green500_02: 'rgba(65, 184, 81, 0.2)',
  green600: '#2D6E3D',
  yellow50: '#ffb01c',
  yellow50_05: 'rgba(255, 176, 28, 0.5)',
  yellow100: '#8d6a23'
};

export const hexOpacity = {
  50: '80',
  60: '99'
};
