import { isNil } from 'ramda';

import {
  useAmountForAdviceAssetsWithValue,
  useFinancialSituationValues
} from '../../services/selectors';
import { form } from 'features/roboAdvice/adviceSession/form/services/form';
import { ClientTypes } from 'features/shared/constants/session';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import Switch from 'features/sharedModules/finalForm/components/switch';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  switch: {
    margin: '0 4px'
  },
  switchLabel: {
    color: theme.secondaryColor,
    fontSize: Typography.body1.size,
    fontWeight: FontWeights.normal,
    lineHeight: '150%'
  },
  switchRoot: {
    width: 48,
    height: 24,
    padding: 0,

    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(24px)'
    }
  },
  track: {
    borderRadius: 1000,
    backgroundColor: `${theme.secondaryColor} !important`, // important used to overwrite switch styles
    opacity: '1 !important' // important used to overwrite switch styles
  },
  thumb: {
    backgroundColor: theme.defaultButtonTextColor,
    height: 16,
    width: 16
  }
}));

const AmountForAdviceSwitch = ({ clientType }) => {
  const amountForAdviceAssetsWithValue = useAmountForAdviceAssetsWithValue();
  const classes = useStyles();
  const financialSituation = useFinancialSituationValues();
  const i18n = useI18n();

  const keyName =
    clientType === ClientTypes.person
      ? 'personFinancialSituation'
      : 'companyFinancialSituation';

  const onChange = (switchValue: boolean) => {
    amountForAdviceAssetsWithValue
      .map(({ id }) => id)
      .forEach(name => {
        financialSituation[name].forEach(({ toAdvisory, value }, index) => {
          if (isNil(value) || isNil(toAdvisory)) return;
          form.change(
            `${keyName}.${name}.${index}.toAdvisory` as any,
            switchValue
              ? value === 0
                ? 100
                : (toAdvisory / value) * 100
              : (toAdvisory / 100) * value
          );
        });
      });
  };

  return (
    <div>
      <span className={classes.switchLabel}>
        {i18n('roboAdvice.financialSituation.amountForAdvice.amount')}
      </span>

      <Switch
        afterChange={onChange}
        name={`${keyName}.amountForAdviceSwitch`}
        sectionClassName={classes.switch}
        styles={{
          root: classes.switchRoot,
          thumb: classes.thumb,
          track: classes.track
        }}
      />

      <span className={classes.switchLabel}>%</span>
    </div>
  );
};

export default AmountForAdviceSwitch;
