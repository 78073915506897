import { isNil } from 'ramda';

import { advancedSuitabilityTables } from '../../../roboAdvice/adviceSession/knowledgeAndExperience/constants';
import { useCustomerConfig } from '../../customerConfig/components/useCustomerConfig';
import { useI18n } from '../../customerConfig/components/useI18n';
import { ErrorMessage, KnowledgeAndExperienceErrors } from '../types';
import { useForm as useRoboAdviceForm } from 'features/roboAdvice/adviceSession/form/services/form';
import { useKnowledgeAndExperienceStore } from 'features/roboAdvice/adviceSession/knowledgeAndExperience/services/knowledgeAndExperienceStore';
import { getTranslation } from 'features/shared/utils/translations';

export const useGetKnowledgeAndExperienceErrors =
  (): KnowledgeAndExperienceErrors => {
    const i18n = useI18n();
    const roboAdviceFormState = useRoboAdviceForm();
    const {
      roboAdviceForm: {
        knowledgeAndExperienceQuestions,
        knowledgeAndExperience: {
          advancedSuitability: advancedSuitabilityCustomerConfig
        },
        errorMessagesEnabled
      }
    } = useCustomerConfig();
    const { advancedSuitabilityConfig } = useKnowledgeAndExperienceStore();

    return {
      ...getQuestionsErrors({
        roboAdviceFormState,
        knowledgeAndExperienceQuestions,
        i18n,
        errorMessagesEnabled
      }),
      ...getAdvancedSuitabilityErrors({
        roboAdviceFormState,
        advancedSuitabilityCustomerConfig,
        advancedSuitabilityConfig,
        i18n,
        errorMessagesEnabled
      })
    };
  };

const getQuestionsErrors = ({
  roboAdviceFormState,
  knowledgeAndExperienceQuestions,
  i18n,
  errorMessagesEnabled
}): { [key: string]: ErrorMessage[] } => {
  const questionsErrors: { [key: string]: ErrorMessage[] } = {};

  if (errorMessagesEnabled) {
    knowledgeAndExperienceQuestions.questions.forEach(
      ({ name, translationKey, translations }) => {
        if (!isNil(roboAdviceFormState.errors[name])) {
          questionsErrors[name] = [
            {
              listMessage: i18n('roboAdvice.errors.missingAnswer').replace(
                '{0}',
                translations
                  ? getTranslation(translations)
                  : i18n(translationKey)
              ),
              alertMessage: i18n(
                'roboAdvice.errors.missingAnswerAlert'
              ).replace(
                '{0}',
                translations
                  ? getTranslation(translations)
                  : i18n(translationKey)
              ),
              elementId: `question-${name}`
            }
          ];
        }
      }
    );
  }

  return questionsErrors;
};

const getAdvancedSuitabilityErrors = ({
  roboAdviceFormState,
  advancedSuitabilityCustomerConfig,
  advancedSuitabilityConfig,
  i18n,
  errorMessagesEnabled
}): { [key: string]: ErrorMessage[] } => {
  const advancedSuitabilityErrors: { [key: string]: ErrorMessage[] } = {};
  if (isNil(roboAdviceFormState.values) || !errorMessagesEnabled) {
    return advancedSuitabilityErrors;
  }

  if (advancedSuitabilityCustomerConfig.enabled) {
    advancedSuitabilityTables
      .filter(({ name }) => advancedSuitabilityCustomerConfig[name]?.enabled)
      .forEach(({ name }) => {
        if (
          isNil(roboAdviceFormState.values?.suitability) ||
          isNil(roboAdviceFormState.values?.suitability[name]) ||
          advancedSuitabilityConfig.checks.some(check =>
            isNil(roboAdviceFormState.values?.suitability[name][check])
          )
        ) {
          advancedSuitabilityErrors[`advancedSuitability-${name}`] = [
            {
              listMessage: i18n('roboAdvice.errors.missingAnswer').replace(
                '{0}',
                getTranslation(advancedSuitabilityCustomerConfig[name].header)
              ),
              alertMessage: i18n(
                'roboAdvice.errors.missingAdvancedSuitabilityAlert'
              ),
              elementId: `advancedSuitability-${name}`
            }
          ];
        }
      });
  }

  return advancedSuitabilityErrors;
};
