import classNames from 'classnames';
import { isEmpty, isNil, mergeWith } from 'ramda';
import { Fragment, ReactNode } from 'react';

import { Layouts } from 'features/roboAdvice/shared/constants';
import ErrorMessage from 'features/shared/components/errorMessage/index.js';
import Radio from 'features/shared/components/radio';
import { Colors } from 'features/shared/constants/colors.js';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  section: {
    display: 'inline-block'
  },
  subSection: {
    '&$horizontal': {
      display: 'inline-flex'
    },

    '&$vertical': {}
  },
  label: {
    color: theme.primaryColor,
    marginRight: '24px',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.spacing00,
    marginBottom: Spacing.spacing01
  },
  horizontalLabel: {
    width: '350px'
  },
  controlSectionsWrap: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 0
  },
  controlSection: {
    '&$horizontal': {
      display: 'inline-flex'
    },

    '&$vertical': {
      display: 'flex'
    }
  },
  control: {},
  controlRadio: {
    position: 'relative'
  },
  controlText: {
    flex: '0 1 auto',
    cursor: 'pointer',
    marginLeft: Spacing.spacing01,
    alignSelf: 'center',
    '&$disabled': {
      cursor: 'default'
    }
  },
  controlLabel: {
    display: 'flex',
    alignItems: 'center',
    color: theme.primaryColor,

    '&$disabled': {
      color: theme.disabledTextColor
    }
  },
  controlDescription: {
    color: Colors.gray50,
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight
  },
  disabled: {},
  vertical: {},
  horizontal: {},
  textField: {
    margin: '15px 30px'
  },
  radioTextField: {
    margin: '15px 0'
  }
}));

export type Props = {
  /**
   * The value of the radio group.
   */
  value: string | number | boolean | null;
  /**
   * The items of the radio group.
   */
  items: {
    label?: ReactNode;
    description?: string;
    activeValue?: string | number | boolean;
    disabled?: boolean;
    textField?: {
      name?: string;
    };
    component?: ReactNode;
  }[];
  /**
   * The callback function that is triggered when the value of the radio group changes.
   */
  onChange: (value: string | number | boolean) => void;
  /**
   * The error message to display (as translation key)
   */
  error?: string;
  /**
   * Whether the radio group has been touched.
   */
  touched?: boolean;
  /**
   * The callback function that is triggered after the value of the radio group changes.
   */
  afterChange?: (value: string | number | boolean) => void;
  /**
   * The label of the radio group.
   */
  label?: string;
  /**
   * Whether the radio group is disabled.
   */
  disabled?: boolean;
  /**
   * The layout of the radio group.
   */
  layout?: keyof typeof Layouts;
  /**
   * The class name of container.
   */
  className?: string;
  /**
   * The class name of label.
   */
  labelClassName?: string;
  /**
   * The class name of control label.
   */
  controlLabelClassName?: string;
  /**
   * The class name of section.
   */
  sectionClassName?: string;
  /**
   * The class name of subsection.
   */
  subSectionClassName?: string;
  /**
   * The external classes.
   */
  externalClasses?: ReturnType<typeof useStyles>;
  /**
   * The additional label component.
   */
  additionalLabelComponent?: ReactNode;
  /**
   * The additional error condition.
   */
  additionalErrorCondition?: boolean;
  /**
   * The error text, not shown if it is empty string.
   */
  errorText?: string;
  /**
   * The id for section div
   */
  elementId?: string;
};

export const RadiosVisual = ({
  value,
  onChange,
  error,
  touched,
  afterChange,
  label,
  items,
  disabled,
  layout = Layouts.vertical,
  className,
  labelClassName,
  controlLabelClassName,
  sectionClassName,
  subSectionClassName,
  externalClasses,
  additionalLabelComponent,
  additionalErrorCondition,
  errorText,
  elementId,
  ...restProps
}: Props) => {
  const internalClasses = useStyles();
  const classes = isNil(externalClasses)
    ? internalClasses
    : mergeWith(
        (c1, c2) => classNames(c1, c2),
        internalClasses,
        externalClasses
      );

  const onChangeInternal = valueInternal => {
    onChange(valueInternal);
    !isNil(afterChange) && afterChange(valueInternal);
  };

  return (
    <div
      className={classNames(classes.section, sectionClassName)}
      id={elementId}
    >
      <div
        data-testid={restProps['data-testid']}
        className={classNames(classes.subSection, subSectionClassName, {
          [classes.horizontal]: layout === Layouts.horizontal,
          [classes.vertical]: layout === Layouts.vertical
        })}
      >
        {!isNil(label) && !isEmpty(label) && (
          <div
            className={classNames(classes.label, labelClassName, {
              [classes.horizontalLabel]: layout === Layouts.horizontal
            })}
          >
            {label}
          </div>
        )}
        {additionalLabelComponent}
        <div
          className={classNames(classes.label, labelClassName, {
            [classes.controlSectionsWrap]: layout === Layouts.horizontal
          })}
        >
          {items.map((i, index) => {
            const isLabelShown = !isNil(i.label) && !isEmpty(i.label);
            const isDescriptionShown =
              !isNil(i.description) && !isEmpty(i.description);

            return (
              <Fragment key={index}>
                <div
                  className={classNames(
                    classes.controlSection,
                    {
                      [classes.horizontal]: layout === Layouts.horizontal,
                      [classes.vertical]: layout === Layouts.vertical
                    },
                    className
                  )}
                >
                  <div className={classes.control}>
                    <Radio
                      data-testid={i.activeValue}
                      checked={value === i.activeValue}
                      onChange={e => {
                        onChangeInternal(i.activeValue);
                      }}
                      disabled={disabled || i.disabled}
                      className={classes.controlRadio}
                    />
                  </div>
                  {(isLabelShown || isDescriptionShown) && (
                    <label
                      className={classNames(classes.controlText, {
                        [classes.disabled]: disabled || i.disabled
                      })}
                      onClick={() => {
                        if (!disabled && !i.disabled) {
                          onChangeInternal(i.activeValue);
                        }
                      }}
                    >
                      {isLabelShown && (
                        <div
                          className={classNames(
                            classes.controlLabel,
                            { [classes.disabled]: disabled || i.disabled },
                            controlLabelClassName
                          )}
                        >
                          {i.label}
                        </div>
                      )}
                      {isDescriptionShown && (
                        <div className={classNames(classes.controlDescription)}>
                          {i.description}
                        </div>
                      )}
                    </label>
                  )}
                </div>

                {i.component}
              </Fragment>
            );
          })}
        </div>
      </div>
      {errorText && <ErrorMessage>{`* ${errorText}`}</ErrorMessage>}
    </div>
  );
};

export default RadiosVisual;
