import React from 'react';

import YearsTablesSection from './yearsTablesSection';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  tableContainer: {
    width: '62.5%'
  },
  tableHeader: {
    paddingTop: '30px',
    paddingBottom: '20px',
    textAlign: 'left'
  }
});

const YearsTables = ({ elementsData, numberOfYears }) => {
  const classes = useStyles();
  const i18n = useI18n();

  return (
    <div className={classes.root}>
      <div className={classes.tableContainer}>
        <YearsTablesSection
          tableData={elementsData?.aggregatedCostEffect}
          headerTitles={['', `${i18n('shared.amount')}/%`]}
          dataKeys={['field', 'value']}
          sectionHeader={
            numberOfYears === 1
              ? i18n('roboAdvice.proposal.cost.aggregatedCostAfter1Year')
              : i18n(
                  'roboAdvice.proposal.cost.aggregatedCostAfterYears'
                ).replace('{0}', numberOfYears)
          }
        />
        <YearsTablesSection
          tableData={elementsData?.aggregatedOngoingFees}
          headerTitles={['', '%*', i18n('shared.amount')]}
          dataKeys={['field', 'percent', 'value']}
          sectionHeader={i18n('roboAdvice.proposal.cost.aggregatedOngoingFees')}
          asterisk={i18n('roboAdvice.proposal.cost.asterisk')}
          highlightLastRow
        />
        <YearsTablesSection
          tableData={elementsData?.oneTimeFees}
          headerTitles={['', '%*', i18n('shared.amount')]}
          dataKeys={['field', 'percent', 'value']}
          sectionHeader={
            numberOfYears === 1
              ? i18n('roboAdvice.proposal.cost.oneTimeFees')
              : i18n('roboAdvice.proposal.cost.aggregatedOneTimeFee')
          }
          asterisk={i18n('roboAdvice.proposal.cost.asterisk')}
        />
        <YearsTablesSection
          tableData={elementsData?.aggregatedReturnCommissionPaidToAdvisor}
          headerTitles={['', '%*', i18n('shared.amount')]}
          dataKeys={['field', 'percent', 'value']}
          sectionHeader={
            numberOfYears === 1
              ? i18n('roboAdvice.proposal.cost.aggregatedCommissionAfter1Year')
              : i18n(
                  'roboAdvice.proposal.cost.aggregatedCommissionAfterYears'
                ).replace('{0}', numberOfYears)
          }
          asterisk={i18n('roboAdvice.proposal.cost.asterisk')}
        />
      </div>
    </div>
  );
};

export default YearsTables;
