import axios from 'axios';
import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';

import { transferInvestor } from '../api';
import { useClientsListStore } from '../services/clientsListStore';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useTakeOverClient() {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const currentUser: any = useSelector(sessionSelectors.getCurrentUser);
  const dispatch = useDispatch();

  const takeOverClient = async (clientId: string) => {
    const clientsListStore = useClientsListStore.getState();

    const client = R.find(c => c.id === clientId, clientsListStore.clients);

    if (R.isNil(client)) {
      return;
    }

    try {
      clientsListStore.setIsTransferClientPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        undefined
      );

      await transferInvestor(accessToken, client.id, currentUser.auth0Id);

      clientsListStore.takeOverClient(client.id, currentUser);
      clientsListStore.setIsTransferClientPending(false);

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n('roboAdvice.clientsList.transferClientSuccessMessage'),
          type: NotificationTypes.success
        })
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        clientsListStore.setIsTransferClientPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.clientsList.transferClientErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return takeOverClient;
}
