import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getClientSessions } from '../../main/api';
import { AdviceSession, clientForm } from '../../main/services/clientForm';
import { useClientPageStore } from '../../main/services/clientPageStore';
import { ClientParams } from '../../shared/types';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useReadAdviceSessions() {
  const { clientId } = useParams<ClientParams>();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const i18n = useI18n();

  const dispatch = useDispatch();

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const readAdviceSessions = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const clientInformationStoreState = useClientPageStore.getState();

    try {
      clientInformationStoreState.setIsReadAdviceSessionsPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const response = await getClientSessions(
        accessToken,
        cancelTokenSource.token,
        {
          investor_id: clientId
        }
      );

      const { sessions } = response.data;

      const { values } = clientForm.getState();
      clientForm.initialize({
        ...values,
        adviceSessions: mapServerAdviceSessionsToClient(sessions)
      });
      clientInformationStoreState.setIsReadAdviceSessionsPending(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        clientInformationStoreState.setIsReadAdviceSessionsPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.client.readAdviceSessionsErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (!isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
    };
  }, []);

  return readAdviceSessions;
}

const mapServerAdviceSessionsToClient = (
  advice_sessions: any
): AdviceSession[] => {
  return advice_sessions.map(mapServerAdviceSessionToClient);
};

const mapServerAdviceSessionToClient = (advice_session: any): AdviceSession => {
  const {
    advisor,
    channel,
    last_activity_date,
    name,
    session_id: id,
    status,
    created_at,
    advice_type,
    survey_status,
    message,
    message_key,
    message_i18n
  } = advice_session;

  return {
    advisor,
    channel,
    id,
    name,
    created: created_at,
    lastActivityDate: last_activity_date,
    status,
    adviceType: advice_type,
    surveyStatus: survey_status,
    surveyStatusMessage: message,
    surveyStatusMessageKey: message_key,
    surveyStatusMessageI18n: message_i18n
  };
};
