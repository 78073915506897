import { deepAlphaApi } from 'features/shared/utils/axios';

export const getCustomers = (currentCustomerId, accessToken) => {
  return deepAlphaApi.get(`/customers/`, {
    headers: {
      'X-Customer-ID': currentCustomerId,
      Authorization: `Bearer ${accessToken}`
    }
  });
};

export const getCustomer = (currentCustomerId, accessToken, customerId) => {
  return deepAlphaApi.get(`/customers/${customerId}`, {
    headers: {
      'X-Customer-ID': currentCustomerId,
      Authorization: `Bearer ${accessToken}`
    }
  });
};

export const createCustomer = (currentCustomerId, accessToken, customer) => {
  return deepAlphaApi.post(`/customers/`, customer, {
    headers: {
      'X-Customer-ID': currentCustomerId,
      Authorization: `Bearer ${accessToken}`
    }
  });
};

export const updateCustomer = (
  currentCustomerId,
  accessToken,
  customerId,
  customer
) => {
  return deepAlphaApi.put(`/customers/${customerId}`, customer, {
    headers: {
      'X-Customer-ID': currentCustomerId,
      Authorization: `Bearer ${accessToken}`
    }
  });
};

export const patchCustomer = (
  currentCustomerId,
  accessToken,
  customerId,
  data
) => {
  return deepAlphaApi.patch(`/customers/${customerId}`, data, {
    headers: {
      'X-Customer-ID': currentCustomerId,
      Authorization: `Bearer ${accessToken}`
    }
  });
};

export const deleteCustomer = (currentCustomerId, accessToken, customerId) => {
  return deepAlphaApi.delete(`/customers/${customerId}`, {
    headers: {
      'X-Customer-ID': currentCustomerId,
      Authorization: `Bearer ${accessToken}`
    }
  });
};
