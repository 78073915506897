import { isNil } from 'ramda';
import { ReactNode } from 'react';

import {
  ProductPlatformQuestions,
  useGoalsStore
} from '../../shared/services/goalsStore';
import {
  NamespaceConfig,
  NamespaceStatus,
  NamespaceStatusType,
  Question
} from '../types';
import { getRiskHorizonValue } from 'features/roboAdvice/adviceSession/proposal/services/selectors';
import Icon from 'features/shared/components/icon/index.js';
import RoundEdgeLabel from 'features/shared/components/label/roundEdgeLabel';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  recommended: {
    backgroundColor: theme.chartPortfolioColors[3],
    color: theme.defaultButtonTextColor
  },
  notRecommended: {
    backgroundColor: theme.disabledTextColor,
    color: theme.defaultButtonTextColor
  },
  notAvailable: {
    backgroundColor: theme.disabledTextColor,
    color: theme.defaultButtonTextColor
  },
  secondLabelIcon: {
    marginRight: Spacing.spacing00,
    fontSize: Typography.subtitle.size,
    fontWeight: FontWeights.bold
  }
}));

export const useReadProductChooserRecommendation = () => {
  const i18n = useI18n();
  const classes = useStyles();
  const { goals } = useGoalsStore();
  const {
    timeHorizonConfig: { type: timeHorizonType, items: timeHorizonItems },
    roboAdvice: {
      productChooserRecommandation: {
        namespaceConfig,
        question1,
        question2,
        question3,
        question4,
        question5
      }
    }
  } = useCustomerConfig();

  type ReadProductChooserRecommendationArguments = {
    goalId: string;
    preferences?: ProductPlatformQuestions;
  };

  const readProductChooserRecommendation = ({
    goalId,
    preferences
  }: ReadProductChooserRecommendationArguments) => {
    const goal = goals.find(g => g.goalId === goalId);
    const productPlatformQuestions =
      preferences || goal?.data?.productPlatformQuestions;

    if (!namespaceConfig) return { items: [], questions: [] };

    const questions = [
      {
        name: 'question1',
        ...question1
      },
      {
        name: 'question2',
        ...question2
      },
      {
        name: 'question3',
        ...question3
      },
      {
        name: 'question4',
        ...question4
      },
      {
        name: 'question5',
        ...question5
      }
    ].filter(q => q.enabled);

    const items = Object.values<NamespaceConfig>(namespaceConfig).map(
      namespace => {
        const statuses: NamespaceStatusType[] = [];

        // is_pension_product
        if (goal?.data?.type === 'pension' && namespace.is_pension_product) {
          statuses.push(NamespaceStatus.RECOMMENDED);
        } else if (
          (goal?.data?.type === 'pension' && !namespace.is_pension_product) ||
          (goal?.data?.type !== 'pension' && namespace.is_pension_product)
        ) {
          if (namespace.enforce?.is_pension_product) {
            statuses.push(NamespaceStatus.NOT_AVAILABLE);
          } else {
            statuses.push(NamespaceStatus.NOT_RECOMMENDED);
          }
        } else if (
          goal?.data?.type !== 'pension' &&
          !namespace.is_pension_product
        ) {
          statuses.push(NamespaceStatus.NEUTRAL);
        }

        // minimum_saving_horizon
        const timeHorizon = getRiskHorizonValue(
          goal?.data.timeHorizon,
          timeHorizonType,
          timeHorizonItems
        );
        if (!isNil(namespace.minimum_saving_horizon) && timeHorizon) {
          if (timeHorizon >= namespace.minimum_saving_horizon) {
            statuses.push(NamespaceStatus.NEUTRAL);
          } else if (namespace.enforce?.minimum_saving_horizon) {
            statuses.push(NamespaceStatus.NOT_AVAILABLE);
          } else {
            statuses.push(NamespaceStatus.NOT_RECOMMENDED);
          }
        }

        const monthlyDeposit = goal?.data.monthlyDeposit;
        // maximum_monthly_saving_amount
        if (!isNil(namespace.maximum_monthly_saving_amount) && monthlyDeposit) {
          if (monthlyDeposit < namespace.maximum_monthly_saving_amount) {
            statuses.push(NamespaceStatus.NEUTRAL);
          } else if (namespace.enforce?.maximum_monthly_saving_amount) {
            statuses.push(NamespaceStatus.NOT_AVAILABLE);
          } else {
            statuses.push(NamespaceStatus.NOT_RECOMMENDED);
          }
        }

        // minimum_monthly_saving_amount
        if (!isNil(namespace.minimum_monthly_saving_amount) && monthlyDeposit) {
          if (monthlyDeposit >= namespace.minimum_monthly_saving_amount) {
            statuses.push(NamespaceStatus.NEUTRAL);
          } else if (namespace.enforce?.minimum_monthly_saving_amount) {
            statuses.push(NamespaceStatus.NOT_AVAILABLE);
          } else {
            statuses.push(NamespaceStatus.NOT_RECOMMENDED);
          }
        }

        const initialDeposit = goal?.data.firstDeposit;
        // minimum_initial_deposit
        if (!isNil(namespace.minimum_initial_deposit) && initialDeposit) {
          if (initialDeposit >= namespace.minimum_initial_deposit) {
            statuses.push(NamespaceStatus.NEUTRAL);
          } else if (namespace.enforce?.minimum_initial_deposit) {
            statuses.push(NamespaceStatus.NOT_AVAILABLE);
          } else {
            statuses.push(NamespaceStatus.NOT_RECOMMENDED);
          }
        }

        // product_question_preference_[ID]
        if (productPlatformQuestions) {
          const answers = [
            productPlatformQuestions.question1,
            productPlatformQuestions.question2,
            productPlatformQuestions.question3,
            productPlatformQuestions.question4,
            productPlatformQuestions.question5
          ].filter(p => !isNil(p));
          const questionPreferences = [
            namespace.product_question_preference_1,
            namespace.product_question_preference_2,
            namespace.product_question_preference_3,
            namespace.product_question_preference_4,
            namespace.product_question_preference_5
          ].filter(p => !isNil(p));
          const enforceQuestionPreferences = [
            namespace.enforce?.product_question_preference_1,
            namespace.enforce?.product_question_preference_2,
            namespace.enforce?.product_question_preference_3,
            namespace.enforce?.product_question_preference_4,
            namespace.enforce?.product_question_preference_5
          ].filter(p => !isNil(p));

          for (let i = 0; i < questionPreferences.length; i++) {
            if (!isNil(questionPreferences[i])) {
              if (questionPreferences[i] === answers[i]) {
                statuses.push(NamespaceStatus.RECOMMENDED);
              } else if (enforceQuestionPreferences[i]) {
                statuses.push(NamespaceStatus.NOT_AVAILABLE);
              } else {
                statuses.push(NamespaceStatus.NOT_RECOMMENDED);
              }
            }
          }
        }

        let secondLabel: ReactNode | null = null;
        let finalStatus: NamespaceStatusType | null = null;
        if (statuses.includes(NamespaceStatus.NOT_AVAILABLE)) {
          secondLabel = (
            <RoundEdgeLabel
              className={classes.notAvailable}
              text={i18n(
                'roboAdvice.advisory.productChooser.notAvailableTranslation'
              ).toUpperCase()}
            />
          );
          finalStatus = NamespaceStatus.NOT_AVAILABLE;
        } else if (statuses.includes(NamespaceStatus.NOT_RECOMMENDED)) {
          secondLabel = (
            <RoundEdgeLabel
              className={classes.notRecommended}
              text={i18n(
                'roboAdvice.advisory.productChooser.notRecommendedTranslation'
              ).toUpperCase()}
            />
          );
          finalStatus = NamespaceStatus.NOT_RECOMMENDED;
        } else if (statuses.includes(NamespaceStatus.RECOMMENDED)) {
          secondLabel = (
            <RoundEdgeLabel
              className={classes.recommended}
              text={i18n(
                'roboAdvice.advisory.productChooser.recommendedTranslation'
              ).toUpperCase()}
              icon={<Icon type={'check'} className={classes.secondLabelIcon} />}
            />
          );
          finalStatus = NamespaceStatus.RECOMMENDED;
        } else {
          finalStatus = NamespaceStatus.NEUTRAL;
        }

        let labelString = i18n(namespace.labelKey);

        if (labelString?.length) {
          if (finalStatus === NamespaceStatus.RECOMMENDED) {
            labelString += ` (${i18n(
              'roboAdvice.advisory.productChooser.recommendedTranslation'
            )})`;
          } else if (finalStatus === NamespaceStatus.NOT_RECOMMENDED) {
            labelString += ` (${i18n(
              'roboAdvice.advisory.productChooser.notRecommendedTranslation'
            )})`;
          }
        }

        return {
          activeValue: namespace.namespace_id,
          label: (
            <>
              {i18n(namespace.labelKey)}
              {secondLabel ? ' ' : null}
              {secondLabel}
            </>
          ),
          disabled: finalStatus === NamespaceStatus.NOT_AVAILABLE,
          status: finalStatus,
          labelString
        };
      }
    );

    items.sort((a, b) => {
      if (
        a.status === NamespaceStatus.RECOMMENDED &&
        b.status !== NamespaceStatus.RECOMMENDED
      )
        return -1;
      if (
        b.status === NamespaceStatus.RECOMMENDED &&
        a.status !== NamespaceStatus.RECOMMENDED
      )
        return 1;
      if (
        a.status === NamespaceStatus.NOT_AVAILABLE &&
        b.status !== NamespaceStatus.NOT_AVAILABLE
      )
        return 1;
      if (
        b.status === NamespaceStatus.NOT_AVAILABLE &&
        a.status !== NamespaceStatus.NOT_AVAILABLE
      )
        return -1;
      if (
        a.status === NamespaceStatus.NOT_RECOMMENDED &&
        (b.status === NamespaceStatus.RECOMMENDED ||
          b.status === NamespaceStatus.NEUTRAL)
      )
        return 1;
      return 0;
    });

    return { items, questions };
  };

  return readProductChooserRecommendation;
};
