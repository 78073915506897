import axios, { CancelTokenSource } from 'axios';
import * as R from 'ramda';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { clientForm } from '../../../../main/services/clientForm';
import { flagNames } from './companyFlagNames';
import { getENINFlags } from 'features/roboAdvice/shared/api';
import {
  isVatNumberValid,
  getFullCompanyOrgNumber,
  isDateNotGreaterThanThreeYearsAgo
} from 'features/roboAdvice/shared/helpers/enin';
import { SupportedCountryCode as SupportedCountryCodeType } from 'features/roboAdvice/shared/types';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { filterNil } from 'features/shared/utils/filters';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useInitCompanyFlags() {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<CancelTokenSource>();
  const dispatch = useDispatch();
  const i18n = useI18n();

  const { values } = clientForm.getState();
  const orgNumber = values?.clientInformation?.orgNumber || null;

  const currentCountryCode =
    values.clientInformation?.country?.toLowerCase() || '';

  const isFirstCall = !values.kyc?.enin?.companyFlags?.eninUpdateDate;

  const isOrgNumberValid = isVatNumberValid(
    orgNumber,
    currentCountryCode as SupportedCountryCodeType
  );

  const fullOrgNumber = getFullCompanyOrgNumber(
    orgNumber,
    currentCountryCode as SupportedCountryCodeType
  );

  const initCompanyFlags = async (isForcedCall = false) => {
    if (!isForcedCall && !isFirstCall) {
      return;
    }

    if (!isOrgNumberValid) {
      return;
    }

    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    try {
      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const { data } = await getENINFlags(
        accessToken,
        cancelTokenSource.token,
        fullOrgNumber
      );

      clientForm.change<any>('kyc.enin.companyFlags', {
        flagList: mapServerCompanyFlags(data),
        eninUpdateDate: new Date()
      });
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.financialSituation.readENINDataErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  React.useEffect(() => {
    return () => {
      if (!R.isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
    };
  }, []);

  return initCompanyFlags;
}

const mapServerCompanyFlags = data =>
  data
    .map(flag =>
      flag.flag_date &&
      isDateNotGreaterThanThreeYearsAgo(new Date(flag.flag_date))
        ? {
            ...flagNames[flag.flag_name],
            flagDate: flag.flag_date
          }
        : null
    )
    .filter(filterNil);
