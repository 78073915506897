import { Form } from 'react-final-form';

import Placeholder from '../../../shared/components/placeholder';
import { Statuses } from '../../../shared/constants';
import { previewForm } from '../services/previewForm';
import { usePreviewStore } from '../services/previewStore';
import FinancialSituationPreview from './financialSituationPreview';
import ProposalPreview from './proposalPreview';
import { useIndexPageStyles } from './style';
import { useInitPage } from './useInitPage';
import { DefaultCustomerConfig } from 'features/shared/services/session/selectors';
import { BaseCustomerConfigProvider } from 'features/sharedModules/customerConfig/components/index';

const Preview = () => {
  const classes = useIndexPageStyles();
  const previewStore = usePreviewStore();

  useInitPage();

  return previewStore.pageStatuses.readCustomerConfig !== Statuses.succeed ? (
    <Placeholder />
  ) : (
    <BaseCustomerConfigProvider
      customerConfig={previewStore.customerConfig ?? DefaultCustomerConfig}
    >
      <Form form={previewForm} onSubmit={() => {}}>
        {() => (
          <div className={classes.root}>
            <div className={classes.previewPage}>
              <FinancialSituationPreview />
            </div>
            <div className={classes.previewPage}>
              <ProposalPreview />
            </div>
          </div>
        )}
      </Form>
    </BaseCustomerConfigProvider>
  );
};

export default Preview;
