import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  useHolisticViewStore,
  selectors
} from '../../../services/holisticViewStore';
import { useReadExpectedValueChartData } from './useReadExpectedValueChartData';
import { useExpectedValueChartStore } from 'features/roboAdvice/adviceSession/expectedValueChart';
import {
  PageStatuses,
  useReadDataListener
} from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import ExpectedValueChartCardVisual from 'features/shared/analyticsComponents/expectedValueChart/visual';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';

const ExpectedValueChartCard = ({ isSelected }) => {
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const expectedValueChartStore = useExpectedValueChartStore();
  const selectedGoalsIds = useHolisticViewStore(selectors.getSelectedGoalsIds);
  const { proposalSectionStatuses } = useHolisticViewStore();
  const {
    analyticsComponents: {
      expectedValue: { bankReturn, compareWithBank }
    }
  } = useCustomerConfig();

  useReadDataListener(
    proposalSectionStatuses.readExpectedValueChartData,
    isSelected,
    useReadExpectedValueChartData()
  );

  React.useEffect(() => {
    expectedValueChartStore.setChartData(selectedGoalsIds);
  }, [expectedValueChartStore.goalsData, selectedGoalsIds]);

  const expectedValueTableData = pathOr(
    [],
    ['chartData', 'expectedValueTable'],
    expectedValueChartStore
  );
  const savingPlanChartData = pathOr(
    [],
    ['chartData', 'savingPlan'],
    expectedValueChartStore
  );
  const savingInBankAccountChartData = pathOr(
    [],
    ['chartData', 'savingInBankAccount'],
    expectedValueChartStore
  );

  return (
    <ExpectedValueChartCardVisual
      expectedValueTableData={expectedValueTableData}
      savingPlanChartData={savingPlanChartData}
      savingInBankAccountChartData={savingInBankAccountChartData}
      cultureCode={cultureCode}
      isLoading={
        useHolisticViewStore.getState().proposalSectionStatuses
          .readExpectedValueChartData === PageStatuses.pending
      }
      bankReturn={bankReturn}
      savingPlanOnly={!compareWithBank}
    />
  );
};

export default ExpectedValueChartCard;
