import { useCallback, useEffect, useMemo } from 'react';

import { PageStatuses } from '../../shared/components/useReadDataListener';
import {
  getIsRiskFinderLoading,
  usePageStore as useRiskScorePageStore
} from '../services/pageStore';
import ExpectedPathPortfoliosChartCard from './expectedPathPortfoliosChartCard';
import ExpectedValuePortfoliosChart from './expectedValuePortfoliosChart';
import HistoricalReturnChart from './historicalReturnChart';
import PortfolioTable from './portfolioTable';
import Statistics from './statistics';
import { useInitRiskFinder } from './useInitRiskFinder';
import {
  carouselIds,
  useCarouselStore
} from 'features/shared/components/carousel/carouselStore';
import {
  Carousel,
  CarouselBody,
  CarouselItem,
  CarouselTab,
  CarouselTabs
} from 'features/shared/components/carousel/verticalNavigation';
import { Spacing } from 'features/shared/constants/spacing';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { Dropdown } from 'features/sharedModules/finalForm/components/dropdown.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24
  },
  dropdownSection: {
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.spacing01,
    marginBottom: Spacing.spacing01
  },
  dropdownLabel: {
    color: theme.secondaryColor
  },
  carouselBodyContainer: {
    width: '700px'
  },
  disabledTabsContainer: {
    pointerEvents: 'none',

    '& *': {
      color: theme.disabledTextColor
    }
  },
  tabs: {
    padding: `0 ${Spacing.spacing02}px`,
    minWidth: 0
  },
  carouselRoot: {
    padding: Spacing.spacing02,
    width: 'max-content'
  }
}));

const RiskFinder = ({ selectedRisks, compareWithExistingPortfolio }) => {
  const classes = useStyles();
  const i18n = useI18n();
  const riskScorePageStore = useRiskScorePageStore();
  const carouselStore = useCarouselStore();
  const {
    roboAdvice: {
      productChooserRecommandation: { namespaceConfig },
      riskScore: { showStats, showExpectedPath }
    }
  } = useCustomerConfig();

  useInitRiskFinder();

  const dropdownOptions = useMemo(
    () =>
      Object.values(namespaceConfig).map(namespace => ({
        key: namespace.namespace_id,
        title: i18n(namespace.labelKey)
      })),
    [namespaceConfig]
  );

  const carouselElements = [
    {
      tab: 'roboAdvice.proposal.portfolio',
      item: (
        <PortfolioTable
          selectedRisks={selectedRisks}
          compareWithExistingPortfolio={compareWithExistingPortfolio}
        />
      ),
      key: 'risk-finder-portfolio-chart-card',
      dataTestId: 'risk-finder-portfolio-chart-card',
      dataTestIdTab: 'risk-finder-portfolio-tab',
      statusKeys: ['readPortfolioChartData', 'readPortfolioTableData'],
      display: true
    },
    {
      tab: 'roboAdvice.proposal.historicalReturn',
      item: (
        <HistoricalReturnChart
          selectedRisks={selectedRisks}
          compareWithExistingPortfolio={compareWithExistingPortfolio}
        />
      ),
      key: 'historical-return-chart-card',
      dataTestId: 'historical-return-chart-card',
      statusKeys: [
        'readHistoricalReturn3MChartData',
        'readHistoricalReturnYTDChartData',
        'readHistoricalReturn1YChartData',
        'readHistoricalReturn3YChartData',
        'readHistoricalReturn5YChartData',
        'readHistoricalReturn10YChartData'
      ],
      display: true
    },
    {
      tab: 'roboAdvice.proposal.expectedValue',
      item: (
        <ExpectedValuePortfoliosChart
          selectedRisks={selectedRisks}
          compareWithExistingPortfolio={compareWithExistingPortfolio}
        />
      ),
      key: 'expected-value-chart-card',
      dataTestId: 'expected-value-chart-card',
      statusKeys: ['readExpectedValuePortfoliosChartData'],
      display: true
    },
    {
      tab: 'roboAdvice.proposal.expectedPath',
      item: (
        <ExpectedPathPortfoliosChartCard
          selectedRisks={selectedRisks}
          compareWithExistingPortfolio={compareWithExistingPortfolio}
        />
      ),
      key: 'expected-path-chart-card',
      dataTestId: 'expected-path-chart-card',
      statusKeys: ['readExpectedPathPortfoliosChartData'],
      display: showExpectedPath
    },
    {
      tab: 'shared.statistics',
      item: (
        <Statistics
          selectedRisks={selectedRisks}
          compareWithExistingPortfolio={compareWithExistingPortfolio}
        />
      ),
      key: 'statistics-card',
      dataTestId: 'statistics-card',
      statusKeys: ['readHistoricalReturn10YChartData'],
      display: showStats
    }
  ].filter(({ display }) => display);

  useEffect(() => {
    carouselStore.setSelectedItemIndex(carouselIds.riskFinder, 0);
  }, []);

  const getIsCarouselPending = useCallback(
    selectedItemIndex => {
      const statusKeys = carouselElements[selectedItemIndex]?.statusKeys;

      return !statusKeys
        ? false
        : statusKeys.some(
            statusKey =>
              riskScorePageStore.pageStatuses[statusKey] ===
              PageStatuses.pending
          );
    },
    [riskScorePageStore.pageStatuses]
  );

  return (
    <div className={classes.root}>
      <Carousel
        className={classes.carouselRoot}
        getIsPending={getIsCarouselPending}
        carouselId={carouselIds.riskFinder}
        forceHorizontalTabs
      >
        <div
          className={
            getIsRiskFinderLoading() ? classes.disabledTabsContainer : ''
          }
        >
          <CarouselTabs carouselId={carouselIds.riskFinder} forceHorizontalTabs>
            {carouselElements.map(({ tab, dataTestId, dataTestIdTab }) => (
              <CarouselTab
                className={classes.tabs}
                data-testid={dataTestIdTab}
                key={dataTestId}
              >
                {i18n(tab)}
              </CarouselTab>
            ))}
          </CarouselTabs>
        </div>
        <CarouselBody
          containerClassName={classes.carouselBodyContainer}
          carouselId={carouselIds.riskFinder}
          forceHorizontalTabs
        >
          {carouselElements.map(({ dataTestId, item }) => (
            <CarouselItem key={dataTestId} data-testid={dataTestId}>
              {item}
            </CarouselItem>
          ))}
        </CarouselBody>
      </Carousel>
      <div className={classes.dropdownSection}>
        <span className={classes.dropdownLabel}>
          {i18n('roboAdvice.riskScore.productConfiguration')}
        </span>
        <Dropdown
          options={dropdownOptions}
          disabled={Object.values(riskScorePageStore.pageStatuses).some(
            pageStatus => pageStatus === PageStatuses.pending
          )}
          data-testid={'product-configuration-dropdown'}
          onChange={v => {
            riskScorePageStore.setProductConfiguration(v);
            riskScorePageStore.changePageStatuses({
              readPortfolioChartData: PageStatuses.ready,
              readPortfolioTableData: PageStatuses.ready
            });
          }}
          value={riskScorePageStore.productConfiguration}
        />
      </div>
    </div>
  );
};

export default RiskFinder;
