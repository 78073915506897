import classNames from 'classnames';
import { format } from 'date-fns';
import React, { useEffect } from 'react';

import { useCheckIsOrgNumberValid } from '../useCheckIsOrgNumberValid';
import { useInitCompanyFlags } from './useInitCompanyFlags';
import { ReactComponent as BuildingsIcon } from 'assets/buildings.svg';
import { ReactComponent as PersonIcon } from 'assets/person.svg';
import { ReactComponent as RefreshIcon } from 'assets/refresh.svg';
import { ReactComponent as TreeStructureIcon } from 'assets/treeStructureFill.svg';
import { useClientForm } from 'features/roboAdvice/client/main/services/clientForm';
import ENINDate from 'features/shared/components/eninDate';
import SimpleTable from 'features/shared/components/table/simpleTable';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  companyFlagsBox: {
    width: 540,
    backgroundColor: theme.boxBackgroundColor_1,
    border: `1px solid ${theme.defaultInputStrokeColor}`,
    padding: '32px',
    borderRadius: '10px',
    marginBottom: '32px'
  },
  header: {
    fontWeight: FontWeights.medium,
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    marginBottom: Spacing.spacing02
  },
  refreshIcon: {
    marginLeft: Spacing.spacing01,
    cursor: 'pointer',

    '& path': {
      fill: theme.accentColor
    }
  },
  table: {
    maxWidth: 480
  },
  tableIcon: {
    '& path, & circle,': {
      fill: theme.secondaryColor
    }
  },
  noDataMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '77px',
    color: theme.secondaryColor
  },
  iconTableCell: {
    textAlign: 'center'
  },
  eninDate: {
    padding: `${Spacing.spacing02}px 0 0 0`
  },
  dateCell: {
    textAlign: 'center',
    width: '130px'
  },
  flagCell: {
    padding: `${Spacing.spacing00}px ${Spacing.spacing01}px`,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: FontWeights.normal,
    color: theme.secondaryColor
  }
}));

const CompanyFlags = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const initCompanyFlags = useInitCompanyFlags();
  const checkIsOrgNumberValid = useCheckIsOrgNumberValid();

  const {
    values: { kyc }
  } = useClientForm();

  const eninUpdateDate = kyc?.enin?.companyFlags?.eninUpdateDate;
  const flagList = kyc?.enin?.companyFlags?.flagList;

  const flagIcons = {
    buildings: <BuildingsIcon className={classes.tableIcon} />,
    person: <PersonIcon className={classes.tableIcon} />,
    treeStructure: <TreeStructureIcon className={classes.tableIcon} />
  };

  useEffect(() => {
    initCompanyFlags(false);
  }, []);

  return (
    <div className={classes.companyFlagsBox}>
      <div className={classes.header}>
        <span>{i18n('roboAdvice.kyc.enin.companyFlags')}</span>
        <RefreshIcon
          className={classes.refreshIcon}
          onClick={() => checkIsOrgNumberValid() && initCompanyFlags(true)}
        />
      </div>
      <SimpleTable
        className={classes.table}
        headers={({ headerCellClassName }) => (
          <>
            <th className={headerCellClassName}>{i18n('shared.date')}</th>
            <th>
              <span className={classes.flagCell}>
                {i18n('roboAdvice.kyc.enin.Flag')}
              </span>
            </th>
            <th className={headerCellClassName}>
              {i18n('roboAdvice.kyc.enin.flagName')}
            </th>
          </>
        )}
        body={({ rowClassName, cellClassName }) => (
          <>
            {flagList?.map((flag, id) => (
              <tr key={id} className={rowClassName}>
                <td className={classNames(cellClassName, classes.dateCell)}>
                  <span>
                    {flag?.flagDate &&
                      format(new Date(flag.flagDate), 'MMM d, yyyy').replaceAll(
                        ' ',
                        '\xA0'
                      )}
                  </span>
                </td>
                <td className={classes.iconTableCell}>
                  <span className={classes.flagCell}>
                    {flag?.icon && flagIcons[flag.icon]}
                  </span>
                </td>
                <td className={cellClassName}>
                  <span>{flag?.flagLabel && i18n(flag.flagLabel)}</span>
                </td>
              </tr>
            ))}
          </>
        )}
      />
      {!flagList?.length && (
        <span className={classes.noDataMessage}>
          {i18n('roboAdvice.kyc.enin.noDataMessage')}
        </span>
      )}
      {eninUpdateDate && (
        <ENINDate className={classes.eninDate} date={eninUpdateDate} />
      )}
    </div>
  );
};

export default CompanyFlags;
