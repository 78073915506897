import classNames from 'classnames';
import * as R from 'ramda';
import React, { useReducer } from 'react';

import Icon from 'features/shared/components/icon/index.js';
import {
  FontWeights,
  FontFamilies
} from 'features/shared/constants/typography';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const borderRadius = {
  [BorderRadiusTypes.rounded]: '80px',
  [BorderRadiusTypes.sharp]: '5px'
};

const useStyles = createUseStyles(theme => ({
  inputContainer: {
    position: 'relative'
  },
  input: {
    width: '100%',
    height: 'calc(4rem + 4px)',
    border: `2px solid ${theme.defaultInputStrokeColor}`,
    borderRadius: borderRadius[theme.borderRadiusType],
    padding: '0.8rem 6.7rem 0.8rem 2.4rem',
    lineHeight: '24px',
    color: theme.primaryColor,
    backgroundColor: theme.defaultInputFillColor,
    fontFamily: FontFamilies.roboto,
    fontWeight: FontWeights.normal,
    position: 'relative',
    zIndex: 1,
    cursor: 'pointer',
    display: 'inline-flex',
    outline: 'none',
    transition: '0.1s',

    '&$invalid': {
      border: `2px solid ${theme.errorNegativeColor}`
    },

    '&:hover, &$hover': {
      border: `2px solid ${theme.hoverInputStrokeColor}`
    },

    '&:focus, &$focus': {
      border: `2px solid ${theme.accentColor}`,
      backgroundColor: theme.inputFillFocusColor
    },

    '&$disabled': {
      backgroundColor: theme.defaultInputFillColor,
      color: theme.disabledTextColor,
      borderColor: theme.disabledBackgroundColor,
      cursor: 'default'
    }
  },
  disabled: {},
  invalid: {},
  hover: {},
  focus: {},
  rightBlock: {
    position: 'absolute',
    top: 'calc(0.8rem + 2px)',
    right: '2.4rem',
    zIndex: 1,
    pointerEvents: 'none'
  },
  icon: {
    cursor: 'pointer',
    color: theme.secondaryColor,
    fontSize: '2.2rem',
    lineHeight: '2.4rem',

    '&$focused': {
      color: theme.hoverInputStrokeColor
    },

    '&$disabled': {
      color: theme.disabledTextColor
    }
  },
  focused: {},
  placeholder: {
    color: theme.secondaryColor
  },
  content: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));

export const actionTypes = {
  TOGGLE_INPUT_FOCUS: 'TOGGLE_INPUT_FOCUS'
};

const initialState = {
  isInputFocused: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_INPUT_FOCUS: {
      return R.assoc('isInputFocused', action.payload, state);
    }
    default:
      return state;
  }
};

export type InputProps = {
  selectedOption?: { title: React.ReactNode; key: string };
  forwardedRef?: React.RefObject<HTMLDivElement>;
  internalForwardedRef?: React.RefObject<HTMLDivElement>;
  inputWrapRef: React.RefObject<HTMLDivElement>;
  placeholder?: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  isDropdownMenuOpen: boolean;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  className?: string;
  iconClassName?: string;
  isInvalid?: boolean;
  disabled?: boolean;
  externalClasses?: ReturnType<typeof useStyles>;
  alwaysPlaceholder?: boolean;
  hover?: boolean;
  focus?: boolean;
};

const Input = ({
  forwardedRef,
  internalForwardedRef,
  inputWrapRef,
  selectedOption,
  placeholder,
  onClick,
  isDropdownMenuOpen,
  onFocus,
  onBlur,
  className,
  iconClassName,
  isInvalid,
  disabled,
  externalClasses,
  alwaysPlaceholder,
  hover,
  focus
}: InputProps) => {
  const internalClasses = useStyles();
  const classes = R.isNil(externalClasses)
    ? internalClasses
    : R.mergeWith(
        (c1, c2) => classNames(c1, c2),
        internalClasses,
        externalClasses
      );

  const [state, dispatch] = useReducer(reducer, initialState);

  React.useEffect(() => {
    if (disabled && state.isInputFocused) {
      dispatch({
        type: actionTypes.TOGGLE_INPUT_FOCUS,
        payload: false
      });
    }
  });

  return (
    <div ref={inputWrapRef}>
      <div className={classes.inputContainer} ref={forwardedRef}>
        <div
          ref={internalForwardedRef}
          data-testid={'dropdown-input'}
          className={classNames(
            classes.input,
            {
              [classes.invalid]: isInvalid,
              [classes.hover]: hover,
              [classes.focus]: focus,
              [classes.disabled]: disabled
            },
            className
          )}
          tabIndex={disabled ? undefined : 0}
          onClick={disabled ? undefined : onClick}
          onFocus={e => {
            dispatch({
              type: actionTypes.TOGGLE_INPUT_FOCUS,
              payload: true
            });

            onFocus && onFocus(e);
          }}
          onBlur={e => {
            dispatch({
              type: actionTypes.TOGGLE_INPUT_FOCUS,
              payload: false
            });

            onBlur && onBlur(e);
          }}
        >
          {R.isNil(selectedOption) || alwaysPlaceholder ? (
            <div className={classNames(classes.content, classes.placeholder)}>
              {placeholder}
            </div>
          ) : (
            <div className={classes.content}>{selectedOption.title}</div>
          )}
        </div>
        <div className={classes.rightBlock}>
          <Icon
            className={classNames(
              classes.icon,
              {
                [classes.focused]: focus || state.isInputFocused,
                [classes.disabled]: disabled
              },
              iconClassName
            )}
            type={
              isDropdownMenuOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Input;
