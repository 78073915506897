import axios, { CancelTokenSource } from 'axios';
import { is, isNil } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useOrderPageStore } from '../../../services/orderPageStore';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { InstrumentSelected } from 'features/roboAdvice/adviceSession/form/services/form';
import { createAnalyzeUniverseCost } from 'features/roboAdvice/adviceSession/shared/api';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { useFieldArray } from 'features/sharedModules/finalForm/components/useFieldArray';

export type OrderExecutionCost = {
  Category?: string;
  FundStandardName?: string;
  ISIN?: string;
  Name: string;
  Ticker?: string;
  fund_advisory_onboarding_fee?: number | null;
  fund_advisory_onboarding_fee_amount?: number | null;
  fund_advisory_purchasing_fee?: number | null;
  fund_advisory_purchasing_fee_amount?: number | null;
  fund_return_commission_paid_to_advisor?: number | null;
  fund_return_commission_paid_to_client?: number | null;
  fund_custody_fee?: number | null;
  fund_custody_fee_amount?: number | null;
  fund_insurance_cost?: number | null;
  fund_insurance_cost_amount?: number | null;
  fund_management_fee?: number | null;
  fund_performance_fee?: number | null;
  fund_platform_fee?: number | null;
  fund_platform_fee_amount?: number | null;
  fund_purchasing_fee?: number | null;
  fund_total_ongoing_fees?: number | null;
  fund_transaction_cost?: number | null;
};

export function useReadOrderExecutionCostData() {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<CancelTokenSource>();
  const dispatch = useDispatch();
  const i18n = useI18n();
  const instrumentsSelected = useFieldArray(`instrumentsSelected`);

  const readOrderExecutionCostData = async () => {
    const orderPageStoreState = useOrderPageStore.getState();

    try {
      orderPageStoreState.setPageStatus('readCostData', PageStatuses.pending);

      if (!isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
      const cancelTokenSource = axios.CancelToken.source();
      cancelTokenSourceRef.current = cancelTokenSource;

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const selectedInstrumentsValues = (
        instrumentsSelected.fields.value as InstrumentSelected[]
      ).reduce(
        (data, instrument) => ({
          portfolio: [...data.portfolio, { id: instrument.id }],
          monthlySavings: data.monthlySavings + (instrument.monthlySaving ?? 0),
          startingCapital:
            data.startingCapital + (instrument.initialDeposit ?? 0)
        }),
        {
          portfolio: [],
          monthlySavings: 0,
          startingCapital: 0
        } as {
          portfolio: { id: string }[];
          monthlySavings: number;
          startingCapital: number;
        }
      );

      const payload = {
        savingsplan: {
          monthly_savings: selectedInstrumentsValues.monthlySavings,
          starting_capital: selectedInstrumentsValues.startingCapital
        },
        portfolio: selectedInstrumentsValues.portfolio
      };

      const analyzeCostResponse = await createAnalyzeUniverseCost(
        qAuthAccessToken,
        cancelTokenSource.token,
        payload,
        {
          language: getUserPageLanguage()
        }
      );

      const costData = analyzeCostResponse.data.summary.instruments.map(
        instrument => {
          Object.keys(instrument).forEach(key => {
            if (is(Number, instrument[key])) {
              instrument[key] = 100 * instrument[key];
            }
          });

          return instrument;
        }
      );

      orderPageStoreState.setCostData(costData);

      orderPageStoreState.setPageStatus('readCostData', PageStatuses.succeed);
    } catch (error) {
      orderPageStoreState.setPageStatus('readCostData', PageStatuses.failed);
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.orderExecution.readOrderExecutionCostDataErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  React.useEffect(() => {
    return () => {
      if (!isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
    };
  }, []);

  return readOrderExecutionCostData;
}
