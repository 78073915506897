import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getQAuthAccessToken } from '../../../../shared/api';
import { NotificationTypes } from '../../../../shared/constants/notification';
import { creators as notificationActionCreators } from '../../../../shared/services/notification/actions';
import sessionSelectors from '../../../../shared/services/session/selectors';
import { throwSafeError } from '../../../../shared/utils/throwSafeError';
import { useI18n } from '../../../../sharedModules/customerConfig/components/useI18n';
import { deleteSessionGoal } from '../../shared/api';
import { useGoalsStore } from '../../shared/services/goalsStore';

export function useDeleteGoal() {
  const { adviceSessionId } = useParams<{ adviceSessionId: string }>();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();
  const i18n = useI18n();

  const deleteGoal = async (goalId: string) => {
    const goalsStore = useGoalsStore.getState();

    try {
      const accessToken = await getQAuthAccessToken(auth0AccessToken);
      await deleteSessionGoal(accessToken, adviceSessionId, goalId);
      goalsStore.removeGoal(goalId);

      dispatch(
        notificationActionCreators.showNotification({
          message: i18n(
            'roboAdvice.adviceSession.purposeAndRisk.deleteGoalSuccessMessage'
          ),
          type: NotificationTypes.success
        })
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        throwSafeError(error);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.adviceSession.purposeAndRisk.deleteGoalErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );
      }
    }
  };

  return deleteGoal;
}
