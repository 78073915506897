import React from 'react';

import { CustomerConfigContext } from './context';
import { CustomerConfigType } from 'features/shared/services/session/types';

export function useCustomerConfig() {
  const customerConfig = React.useContext<CustomerConfigType>(
    CustomerConfigContext
  );

  return customerConfig;
}
