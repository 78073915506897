import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';

export const useIsFollowUpEnabled = () => {
  const {
    roboAdvice: {
      followUpFlow: { enabled: isFollowUpEnabled }
    },
    analyticsComponents: { isTransactionListHidden },
    roboAdviceForm: {
      financialSituation: {
        accounts: {
          sync: { enabled: isAccountsSyncEnabled }
        }
      }
    }
  } = useCustomerConfig();

  // Follow-up is enabled if all conditions are met
  // Without transaction list there is no "Value of internal holding to advisory" that is essential for follow-up
  // Follow-up is also disabled if accounts sync is disabled, because we need to have data from accounts to calculate the internal holding
  return {
    isFollowUpEnabled:
      isFollowUpEnabled && !isTransactionListHidden && isAccountsSyncEnabled
  };
};
