import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import { form as roboAdviceForm } from '../../form/services/form';
import { getInvestorAccountsData } from '../../shared/api';
import { Detail, mapAccountsData } from '../services/mapping';
import { useFinancialSituationValues } from '../services/selectors';
import { useSessionStore } from 'features/roboAdvice/adviceSession/session/services/sessionStore';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import {
  AdviceSessionParams,
  ClientTypes
} from 'features/shared/constants/session';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export const useReadAccountsData = () => {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const { clientType, clientId } = useParams<AdviceSessionParams>();
  const financialSituationValues = useFinancialSituationValues();
  const {
    roboAdviceForm: {
      financialSituation: {
        accounts: {
          sync: {
            enabled: isAccountsSyncEnabled,
            mapping: {
              company: accountsSyncCompanyMapping,
              person: accountsSyncPersonMapping
            }
          },
          automaticTaggingOfAssetClassEnabled
        }
      }
    },
    roboAdvice: { subAssetClassNameMapping }
  } = useCustomerConfig();
  const { categories } = useSessionStore();
  const accountsSyncMapping =
    clientType === ClientTypes.company
      ? accountsSyncCompanyMapping
      : accountsSyncPersonMapping;

  const readAccountsData = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    if (!isAccountsSyncEnabled) {
      return;
    }

    try {
      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const { data: investorAccountData } = await getInvestorAccountsData(
        qAuthAccessToken,
        cancelTokenSource.token,
        clientId
      );

      const newFinancialSituationAssets = await mapAccountsData({
        automaticTaggingOfAssetClassEnabled,
        investorAccountData,
        accountsSyncMapping,
        qAuthAccessToken,
        cancelTokenSource,
        categories,
        financialSituationValues,
        subAssetClassNameMapping
      });

      const financialSituationKey =
        clientType === ClientTypes.person
          ? `personFinancialSituation`
          : `companyFinancialSituation`;
      roboAdviceForm.batch(() => {
        // Remove old not sync values
        Object.entries(financialSituationValues || {}).forEach(
          ([key, values]) => {
            if (Object.keys(newFinancialSituationAssets).includes(key)) {
              return;
            }

            if (!Array.isArray(values)) {
              return;
            }

            roboAdviceForm.change(
              `${financialSituationKey}.${key}` as any,
              (values as Detail[]).filter(
                ({ accountNumber }) => accountNumber === undefined
              )
            );
          }
        );

        // Add new sync values
        Object.entries(newFinancialSituationAssets).forEach(
          ([accountValueTarget, syncValues]) => {
            const oldNotSyncValues = financialSituationValues?.[
              accountValueTarget
            ]
              ? financialSituationValues[accountValueTarget].filter(
                  ({ accountNumber, value: oldValue, toAdvisory, title }) =>
                    accountNumber === undefined &&
                    (oldValue !== undefined ||
                      toAdvisory !== undefined ||
                      title !== undefined)
                )
              : [];

            roboAdviceForm.change(
              `${financialSituationKey}.${accountValueTarget}` as any,
              [...syncValues, ...oldNotSyncValues, { id: v4() }]
            );
          }
        );
      });
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.financialSituation.accountsSync.errorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readAccountsData;
};
