export const types = {
  UPDATE_USER_PICTURE: 'USER_PROFILE_BASIC_PAGE/UPDATE_USER_PICTURE',

  DELETE_USER_PICTURE: 'USER_PROFILE_BASIC_PAGE/DELETE_USER_PICTURE'
};

export const creators = {
  updateUserPicture: (currentUserId, images) => ({
    type: types.UPDATE_USER_PICTURE,
    payload: { currentUserId, images }
  }),

  deleteUserPicture: currentUserId => ({
    type: types.DELETE_USER_PICTURE,
    payload: { currentUserId }
  })
};
