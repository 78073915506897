import { useEffect } from 'react';

function useOnClickOutside(refArray, onClick) {
  useEffect(() => {
    const listener = event => {
      const isRefElementBeingClicked = refArray.some(
        ref => !ref.current || ref.current.contains(event.target)
      );

      if (isRefElementBeingClicked) {
        return;
      }

      onClick(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [refArray, onClick]);
}

export default useOnClickOutside;
