import hoistStatics from 'hoist-non-react-statics';
import React from 'react';

export default staticProps => InnerComponent => {
  const WithStaticProps = props => (
    <InnerComponent {...staticProps} {...props} />
  );

  return hoistStatics(WithStaticProps, InnerComponent);
};
