import { z } from 'zod';

export const adviceFlowsSchema = z.array(
  z.object({
    label: z.record(z.string()),
    name: z.string()
  })
);

export type AdviceFlows = z.infer<typeof adviceFlowsSchema>;

export const defaultAdviceFlows: AdviceFlows = [];
