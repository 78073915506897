import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { v4 } from 'uuid';

import { usePageStore as useAdvisoryPageStore } from '../../../../advisory/services/pageStore';
import { useForm as useRoboAdviceForm } from '../../../../form/services/form';
import { populateGoalsWithAdvancedSuitabilityStatusData } from '../../../services/mappingSelectors';
import { usePageStore as useProposalPageStore } from '../../../services/pageStore';
import { MissingDocuments } from '../../generateReportModal/types';
import { usePageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { useKnowledgeAndExperienceStore } from 'features/roboAdvice/adviceSession/knowledgeAndExperience/services/knowledgeAndExperienceStore';
import { useQueuedPatchAdviceSession } from 'features/roboAdvice/adviceSession/main/components/useQueuedPatchAdviceSession';
import { createNewPdf2Report } from 'features/roboAdvice/adviceSession/proposal/api';
import { costForm } from 'features/roboAdvice/adviceSession/proposal/services/costForm';
import { saveFile } from 'features/roboAdvice/adviceSession/proposal/utils';
import {
  getFundsList,
  getRoboPortfolio
} from 'features/roboAdvice/adviceSession/shared/api';
import { Payload } from 'features/roboAdvice/adviceSession/shared/components/generateNewPdfReportModal/useBuildPayload';
import {
  SessionNamespaces,
  SessionPatchTypes
} from 'features/roboAdvice/adviceSession/shared/constants';
import {
  FlatAssetClass,
  groupAssetClasses
} from 'features/roboAdvice/adviceSession/shared/mapping';
import { useGoalsStore } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { useReadReportDocuments } from 'features/roboAdvice/shared/components/useReadReportDocuments';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import { AdviceSessionParams } from 'features/shared/constants/session';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { filterNil } from 'features/shared/utils/filters';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

const useGenerateReport = () => {
  const { adviceSessionId } = useParams<AdviceSessionParams>();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();
  const i18n = useI18n();
  const readReportDocuments = useReadReportDocuments();
  const queuedPatchAdviceSession = useQueuedPatchAdviceSession();
  const roboAdviceForm = useRoboAdviceForm();
  const { advancedSuitabilityStatus } = useKnowledgeAndExperienceStore();
  const { supportedLanguages, roboPortfolioPrecision } = useCustomerConfig();
  const { loadPageLanguage } = usePageLanguage();
  const language = loadPageLanguage()
    ? loadPageLanguage()
    : supportedLanguages.default;
  const { categoriesSelection: globalCategoriesSelection } =
    useAdvisoryPageStore();
  const proposalPageStore = useProposalPageStore();

  const generateReport = async (payload: Payload | null) => {
    if (!payload) {
      return;
    }

    const cancelTokenSource = axios.CancelToken.source();

    try {
      proposalPageStore.setIsReadNewPdfReportPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const response = await createNewPdf2Report(
        accessToken,
        cancelTokenSource.token,
        adviceSessionId,
        payload
      );

      saveFile(
        new Blob([response.data], { type: 'application/pdf; charset=utf-8' }),
        payload?.filename
      );

      const missingDocuments: MissingDocuments =
        response.headers['missing-docs'] &&
        response.headers['missing-docs'] !== 'None'
          ? JSON.parse(response.headers['missing-docs'])
          : [];

      if (missingDocuments.length) {
        missingDocuments.forEach(({ isin, document_name }) =>
          dispatch(
            notificationActionCreators.showNotification({
              message: i18n(
                'roboAdvice.proposal.generateReport.missingDocumentsErrorMessage'
              )
                .replace('{0}', document_name)
                .replace('{1}', isin),
              type: NotificationTypes.error
            })
          )
        );
      }

      await readReportDocuments(adviceSessionId, true);

      const { goals } = useGoalsStore.getState();
      let modelPortfolios: Record<string, FlatAssetClass[]> = {};

      for (const goal of goals) {
        const goalCategoriesSelection =
          useAdvisoryPageStore.getState().goalCategoriesSelection?.[
            goal.goalId
          ];
        const categoriesSelection =
          goalCategoriesSelection || globalCategoriesSelection;
        if (!goal.data.isPortfolioCustom) {
          const modelRoboPortfolio = await getRoboPortfolio(
            accessToken,
            cancelTokenSource.token,
            {
              risk_tolerance: goal.data.portfolio,
              precision: roboPortfolioPrecision,
              namespace_id: goal.data.productPlatformNamespace
            }
          );
          const fundsList = await getFundsList(
            accessToken,
            cancelTokenSource.token,
            {
              namespace_id: goal.data.productPlatformNamespace,
              language
            }
          );
          const availableInstruments = fundsList.data.funds.map(f => ({
            id: f.Ticker,
            isin: f.ISIN,
            title: f.Name
          }));
          const groupedModelRoboPortfolio = groupAssetClasses(
            modelRoboPortfolio.data.Portfolio
          );

          const assetClasses = groupedModelRoboPortfolio.map(
            ({ AssetClass, CategoryIds, Weight }) => {
              const assetClassCategoriesSelection = Object.entries(
                categoriesSelection
              )
                .filter(([categoryId]) =>
                  CategoryIds.find(c => c.id === categoryId)
                )
                .map(([categoryId, instrumentId]) => ({
                  categoryId,
                  instrumentId,
                  weight:
                    CategoryIds.find(c => c.id === categoryId)?.weight ?? 0
                }));

              const instruments = assetClassCategoriesSelection
                .map(categorySelection => {
                  const foundInstrument = availableInstruments.find(
                    availableInstrument =>
                      availableInstrument.id === categorySelection.instrumentId
                  );

                  if (!foundInstrument) return null;

                  return {
                    ...foundInstrument,
                    weight: categorySelection.weight
                  };
                })
                .filter(filterNil);

              return {
                CategoryId: AssetClass.CategoryId,
                CategoryIds,
                mainAssetClass: AssetClass.MainAssetClass,
                subAssetClass: AssetClass.SubAssetClass,
                id: AssetClass.SubAssetClass,
                title: AssetClass.SubAssetClass,
                instruments,
                weight: Weight || 0
              };
            }
          );

          modelPortfolios[goal.goalId] = assetClasses;
        }
      }

      const finishedSessionPayload = {
        roboAdviceForm: roboAdviceForm.values,
        goals: populateGoalsWithAdvancedSuitabilityStatusData(
          goals,
          advancedSuitabilityStatus,
          modelPortfolios
        ),
        costForm: {
          overrideCost: costForm.getState().values.overrideCost,
          overrideFundCosts: costForm.getState().values.overrideFundCosts
        }
      };
      await queuedPatchAdviceSession({
        id: v4(),
        type: SessionPatchTypes.saveFinishedSessionData,
        adviceSessionId,
        payload: finishedSessionPayload,
        namespace: SessionNamespaces.finishedSessionData
      });
      proposalPageStore.setFinishedPortfolioData(finishedSessionPayload);

      proposalPageStore.setIsReadNewPdfReportPending(false);
    } catch (error) {
      proposalPageStore.setIsReadNewPdfReportPending(false);

      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.proposal.generateReport.getPdfReportErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return generateReport;
};

export default useGenerateReport;
