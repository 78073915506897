import classNames from 'classnames';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useHolisticViewStore } from '../../services/holisticViewStore';
import HolisticSection from '../holisticSection';
import SelectPortfolioButtons from '../selectPortfolioButtons';
import { useIsLoading } from './../useIsLoading';
import CashflowChart from './cashflowChart';
import CostChartView from './costChart';
import EfficientFrontierChart from './efficientFrontierChart';
import ExpectedPathChart from './expectedPathChart/expectedPathChartCard';
import ExpectedValueChart from './expectedValueChart/expectedValueChart';
import HistoricalReturnChart from './historicalReturnChart';
import PortfolioChartGrid from './portfolioChart';
import { usePortfolioChartStore } from 'features/roboAdvice/adviceSession/portfolioChart';
import {
  carouselIds,
  useCarouselStore
} from 'features/shared/components/carousel/carouselStore';
import {
  Carousel,
  CarouselBody,
  CarouselItem,
  CarouselTab,
  CarouselTabs
} from 'features/shared/components/carousel/verticalNavigation';
import GoalTableIcon from 'features/shared/components/icon/goalTableIcon';
import Table from 'features/shared/components/table/table.js';
import TableBody from 'features/shared/components/table/tableBody.js';
import TableBodyRow from 'features/shared/components/table/tableBodyRow.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { formatNumber } from 'features/shared/utils/number';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const AnyTableComponent: any = Table;
const AnyTableBody: any = TableBody;
const AnyTableBodyRow: any = TableBodyRow;
const AnyCarouselTab: any = CarouselTab;
const AnyCarouselBody: any = CarouselBody;
const AnyCarouselItem: any = CarouselItem;

const useStyles = createUseStyles(theme => ({
  goalIconCell: {
    width: '80px',
    textAlign: 'center',
    fill: theme.accentColor,

    '& svg': {
      verticalAlign: 'middle'
    },

    '&:first-child': {
      paddingLeft: '1.6rem'
    }
  },
  carouselRoot: {
    padding: 0
  }
}));

const ProposalSection = () => {
  const i18n = useI18n();
  const classes = useStyles();
  const holisticViewStore = useHolisticViewStore();
  const portfolioChartStore = usePortfolioChartStore();
  const carouselStore = useCarouselStore();
  const { analyticsComponents } = useCustomerConfig();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const isLoading = useIsLoading();
  const {
    roboAdviceForm: {
      purposeAndRisk: { showGoalIcons = false }
    }
  } = useCustomerConfig();

  const tableHeader = [
    {
      title: i18n('roboAdvice.purposeAndRisk.purposeTable.goal')
    },
    {
      title: i18n('roboAdvice.proposal.expectedAnnualReturn')
    },
    {
      title: i18n('roboAdvice.proposal.expectedVolatility')
    },
    {
      title: i18n('tools.holisticView.equityShare')
    }
  ];

  const carouselElements = [
    {
      tab: 'roboAdvice.proposal.portfolio',
      item: <PortfolioChartGrid />,
      dataTestId: 'portfolio-chart-card',
      dataTestIdTab: 'portfolio-tab',
      display: !analyticsComponents.isPortfolioHidden,
      statusKeys: ['readPortfolioChartData']
    },
    {
      tab: 'roboAdvice.proposal.expectedPath',
      item: <ExpectedPathChart isSelected={false} />,
      dataTestId: 'expected-path-chart-card',
      display: !analyticsComponents.isExpectedPathHidden,
      statusKeys: ['readExpectedPathChartData']
    },
    {
      tab: 'roboAdvice.proposal.expectedValue',
      item: <ExpectedValueChart isSelected={false} />,
      key: 'expected-value-chart-card',
      dataTestId: 'expected-value-chart-card',
      display: !analyticsComponents.isExpectedValueHidden,
      statusKeys: ['readExpectedValueChartData']
    },
    {
      tab: 'roboAdvice.proposal.cost',
      item: <CostChartView />,
      key: 'cost-chart-card',
      dataTestId: 'cost-chart-card',
      display: !analyticsComponents.isCostHidden,
      statusKeys: ['readCostChartData']
    },
    {
      tab: 'roboAdvice.proposal.efficientFrontier',
      item: <EfficientFrontierChart isSelected={false} />,
      key: 'efficient-frontier-chart-card',
      dataTestId: 'efficient-frontier-chart-card',
      display: !analyticsComponents.isEfficientFrontierHidden,
      statusKeys: ['readEfficientFrontierChartData']
    },
    {
      tab: 'roboAdvice.proposal.historicalReturn',
      item: <HistoricalReturnChart isSelected={false} />,
      key: 'historical-return-chart-card',
      dataTestId: 'historical-return-chart-card',
      display: !analyticsComponents.isHistoricalReturnHidden,
      statusKeys: [
        'readHistoricalReturn3MChartData',
        'readHistoricalReturnYTDChartData',
        'readHistoricalReturn1YChartData',
        'readHistoricalReturn3YChartData',
        'readHistoricalReturn5YChartData',
        'readHistoricalReturn10YChartData'
      ]
    },
    {
      tab: 'roboAdvice.proposal.cashflow',
      item: <CashflowChart isSelected={false} />,
      key: 'cashflow-chart-card',
      dataTestId: 'cashflow-chart-card',
      display: !analyticsComponents.isCashflowHidden,
      statusKeys: ['readCashflowData']
    }
  ].filter(({ display }) => display);

  useEffect(() => {
    carouselStore.setSelectedItemIndex(carouselIds.holisticViewProposal, 0);
  }, []);

  if (
    holisticViewStore.availableClients.filter(c => c.isSelected).length === 0
  ) {
    return <HolisticSection sectionHeader={i18n('roboAdvice.tabs.proposal')} />;
  }

  return (
    <HolisticSection sectionHeader={i18n('roboAdvice.tabs.proposal')}>
      <AnyTableComponent
        header={showGoalIcons ? [{ title: '' }, ...tableHeader] : tableHeader}
        isLoading={isLoading}
      >
        <AnyTableBody noDataTitle={'no data'} cellsNumber={7}>
          {portfolioChartStore.chartData.map((data, index) => (
            <AnyTableBodyRow key={index}>
              {({ bodyRowCellClassName }) => (
                <>
                  {showGoalIcons && (
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.goalIconCell
                      )}
                    >
                      <GoalTableIcon icon={data.goalIcon} />
                    </td>
                  )}
                  <td className={bodyRowCellClassName}>{data.goalName}</td>
                  <td className={bodyRowCellClassName}>
                    {formatNumber(cultureCode, data.expectedAnnualReturn, 0, 1)}
                    %
                  </td>
                  <td className={bodyRowCellClassName}>
                    {formatNumber(cultureCode, data.expectedVolatility, 0, 1)}%
                  </td>
                  <td className={bodyRowCellClassName}>
                    {formatNumber(cultureCode, data.equityPortion, 0, 1)}%
                  </td>
                </>
              )}
            </AnyTableBodyRow>
          ))}
        </AnyTableBody>
      </AnyTableComponent>
      <SelectPortfolioButtons />
      {!!carouselElements.length && (
        <div>
          <Carousel
            getIsPending={() => isLoading}
            className={classes.carouselRoot}
            carouselId={carouselIds.holisticViewProposal}
          >
            <CarouselTabs carouselId={carouselIds.holisticViewProposal}>
              {carouselElements.map(({ tab, dataTestId, dataTestIdTab }) => (
                <AnyCarouselTab data-testid={dataTestIdTab} key={dataTestId}>
                  {i18n(tab)}
                </AnyCarouselTab>
              ))}
            </CarouselTabs>
            <AnyCarouselBody carouselId={carouselIds.holisticViewProposal}>
              {carouselElements.map(({ dataTestId, item }) => (
                <AnyCarouselItem key={dataTestId} data-testid={dataTestId}>
                  {item}
                </AnyCarouselItem>
              ))}
            </AnyCarouselBody>
          </Carousel>
        </div>
      )}
    </HolisticSection>
  );
};

export default ProposalSection;
