import { useHolisticViewStore } from '../services/holisticViewStore';

export const useIsLoading = () => {
  const holisticViewStore = useHolisticViewStore();

  return (
    holisticViewStore.isReadAdviceSessionsPending ||
    holisticViewStore.isReadClientsPending ||
    holisticViewStore.isReadSelectedAdviceSessionsPending ||
    holisticViewStore.isReadGoalsDataPending ||
    holisticViewStore.isReadRiskScoresPending
  );
};
