import * as React from 'react';

import { previewForm } from '../services/previewForm';
import { usePreviewStore } from '../services/previewStore';
import { useReadCustomerConfig } from './useReadCustomerConfig';

export function useInitPage() {
  const readCustomerConfig = useReadCustomerConfig();

  React.useEffect(() => {
    readCustomerConfig();

    return () => {
      usePreviewStore.getState().reset();
      previewForm.restartToInitialValues();
    };
  }, []);
}
