import axios from 'axios';
import { isNil } from 'ramda';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePageStore as useAdvisoryPageStore } from '../../../advisory/services/pageStore';
import {
  customPortfolioForm,
  useCustomPortfolioForm
} from '../../services/customPortfolioForm';
import { useCustomPortfolioStore } from '../../services/customPortfolioStore';
import { excludeNilFields } from 'features/roboAdvice/adviceSession/proposal/utils';
import { useUpdateGoal } from 'features/roboAdvice/adviceSession/purposeAndRisk/components/useUpdateGoal';
import { getRoboPortfolio } from 'features/roboAdvice/adviceSession/shared/api';
import { groupAssetClasses } from 'features/roboAdvice/adviceSession/shared/mapping';
import {
  CustomPortfolioItem,
  Goal,
  GoalData,
  useGoalsStore
} from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export const useHandleCustomPortfolio = () => {
  const i18n = useI18n();
  const dispatch = useDispatch();
  const updateGoal = useUpdateGoal();
  const goalsStore = useGoalsStore();
  const {
    setIsRiskTemplateLoading,
    assetClasses: storeAssetClasses,
    setIsNewCustomPortfolioDataAvailable
  } = useCustomPortfolioStore();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = useRef<any>();
  const { roboPortfolioPrecision } = useCustomerConfig();

  const { values } = useCustomPortfolioForm();

  type SaveCustomPortfolio = {
    goalId: string;
  };
  const saveCustomPortfolio = async ({ goalId }: SaveCustomPortfolio) => {
    const goal = goalsStore.goals.find(g => g.goalId === goalId);

    if (goal) {
      const oldCustomPortfolioData = goal.data?.customPortfolio || [];

      const categoriesSelection =
        goal && goal.goalId
          ? useAdvisoryPageStore.getState().goalCategoriesSelection![
              goal.goalId
            ]
          : useAdvisoryPageStore.getState()?.categoriesSelection;
      const newCustomPortfolioData: CustomPortfolioItem[] = (
        values.addAssetClass || []
      ).map(value => {
        const foundAssetClass = storeAssetClasses.find(
          ({ categoryId }) => categoryId === value
        );
        const availableInstruments =
          foundAssetClass?.availableInstruments ?? [];
        const instruments = availableInstruments.filter(
          ({ id }) => id === categoriesSelection[value]
        );

        return {
          mainAssetClass: foundAssetClass?.mainAssetClass ?? '',
          subAssetClass: foundAssetClass?.subAssetClass ?? '',
          id: foundAssetClass?.id ?? '',
          title: foundAssetClass?.title ?? '',
          CategoryId: value,
          CategoryIds:
            foundAssetClass?.categoryIds.map(id => ({ id, weight: 0 })) || [],
          instruments,
          weight: 0
        };
      });

      await updateGoal(goalId, {
        data: {
          customPortfolio: [
            ...oldCustomPortfolioData,
            ...newCustomPortfolioData
          ]
        }
      });
      setIsNewCustomPortfolioDataAvailable(true);
      customPortfolioForm.change('addAssetClass', []);
    }
  };

  type RemoveCustomAssetClasses = {
    goal: Goal;
    assetClassIdsToRemove: string[];
  };
  const removeCustomAssetClasses = async ({
    goal,
    assetClassIdsToRemove
  }: RemoveCustomAssetClasses) => {
    let filteredCustomPortfolioData = goal.data?.customPortfolio || [];
    if (assetClassIdsToRemove && assetClassIdsToRemove.length > 0) {
      filteredCustomPortfolioData = filteredCustomPortfolioData.filter(
        ({ CategoryId }) => !assetClassIdsToRemove.includes(CategoryId)
      );
    }

    await updateGoal(goal.goalId, {
      data: {
        customPortfolio: filteredCustomPortfolioData
      }
    });
  };

  type AddCustomAssetClasses = {
    goal: Goal;
    assetClasses: CustomPortfolioItem[];
    overrideAllAssets?: boolean;
    additionalGoalData?: Partial<GoalData>;
  };
  const addCustomAssetClasses = async ({
    goal,
    assetClasses,
    overrideAllAssets = false,
    additionalGoalData = {}
  }: AddCustomAssetClasses) => {
    const oldCustomPortfolioData = goal.data?.customPortfolio || [];
    const newCustomPortfolioData = assetClasses.filter(
      newAsset =>
        !oldCustomPortfolioData.find(
          oldAsset => oldAsset.CategoryId === newAsset.CategoryId
        )
    );

    await updateGoal(goal.goalId, {
      data: {
        ...additionalGoalData,
        customPortfolio: overrideAllAssets
          ? assetClasses
          : [...oldCustomPortfolioData, ...newCustomPortfolioData]
      }
    });
    setIsNewCustomPortfolioDataAvailable(true);
  };

  type HandleChangeRiskTemplate = {
    goalId: string;
    selectedRiskTemplate: string | null;
    additionalGoalData?: Partial<GoalData>;
  };
  const handleChangeRiskTemplate = async ({
    goalId,
    selectedRiskTemplate,
    additionalGoalData = {}
  }: HandleChangeRiskTemplate) => {
    setIsRiskTemplateLoading(true);
    const goal = goalsStore.goals.find(g => g.goalId === goalId);

    if (goal && selectedRiskTemplate) {
      try {
        if (!isNil(cancelTokenSourceRef.current)) {
          cancelTokenSourceRef.current.cancel();
        }
        const cancelTokenSource = axios.CancelToken.source();
        cancelTokenSourceRef.current = cancelTokenSource;

        const qAuthAccessToken = await getQAuthAccessToken(
          auth0AccessToken,
          cancelTokenSource.token
        );

        const { data: roboPortfolio } = await getRoboPortfolio(
          qAuthAccessToken,
          cancelTokenSource.token,
          excludeNilFields({
            risk_tolerance: selectedRiskTemplate,
            optionals: goal.data?.themes,
            precision: roboPortfolioPrecision,
            namespace_id: goal.data.productPlatformNamespace
          })
        );

        const categoriesSelection =
          goal && goal.goalId
            ? useAdvisoryPageStore.getState().goalCategoriesSelection![
                goal.goalId
              ]
            : useAdvisoryPageStore.getState()?.categoriesSelection;

        const groupedRoboPortfolio = groupAssetClasses(roboPortfolio.Portfolio);

        const customPortfolioAssets: CustomPortfolioItem[] =
          groupedRoboPortfolio.map(
            ({ Weight, AssetClass: { CategoryId }, CategoryIds }) => {
              const instruments = CategoryIds.map(
                ({ id: CatId, weight: instrumentWeight }) => {
                  const foundAssetClass = storeAssetClasses.find(
                    ({ categoryIds }) => categoryIds.includes(CatId)
                  );
                  const availableInstruments =
                    foundAssetClass?.availableInstruments ?? [];

                  return availableInstruments
                    .filter(({ id }) => id === categoriesSelection[CatId])
                    .map(i => ({ ...i, weight: instrumentWeight }));
                }
              ).flat();

              const overallAssetClass = storeAssetClasses.find(
                ({ categoryIds }) => categoryIds.includes(CategoryId)
              );

              const overallCategoryIds =
                overallAssetClass?.categoryIds?.map((id, index) => ({
                  id,
                  weight: index === 0 ? 100 : 0
                })) ?? [];

              return {
                CategoryId: overallAssetClass?.categoryIds?.[0] ?? CategoryId,
                CategoryIds:
                  overallCategoryIds.length > 0
                    ? overallCategoryIds
                    : CategoryIds,
                weight: Weight,
                instruments,
                mainAssetClass: overallAssetClass?.mainAssetClass ?? '',
                subAssetClass: overallAssetClass?.subAssetClass ?? '',
                id: overallAssetClass?.id ?? '',
                title: overallAssetClass?.title ?? ''
              };
            }
          );

        await addCustomAssetClasses({
          goal,
          assetClasses: customPortfolioAssets,
          overrideAllAssets: true,
          additionalGoalData: {
            riskTemplate: selectedRiskTemplate,
            ...additionalGoalData
          }
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          dispatch(
            notificationActionCreators.showNotification({
              message: i18n(
                'roboAdvice.advisory.customPortfolio.riskTemplateErrorMessage'
              ),
              type: NotificationTypes.error
            })
          );

          throwSafeError(error);
        }
      } finally {
        setIsRiskTemplateLoading(false);
        setIsNewCustomPortfolioDataAvailable(true);
      }
    }
  };

  return {
    saveCustomPortfolio,
    removeCustomAssetClasses,
    addCustomAssetClasses,
    handleChangeRiskTemplate
  };
};
