export const types = {
  OPENED: 'CUSTOMERS_TABLE_PAGE/OPENED',
  CLOSED: 'CUSTOMERS_TABLE_PAGE/CLOSED',

  SETTINGS_INITIALIZED: 'CUSTOMERS_TABLE_PAGE/SETTINGS_INITIALIZED',

  READ_CUSTOMERS: 'CUSTOMERS_TABLE_PAGE/READ_CUSTOMERS',

  DELETE_CUSTOMER: 'CUSTOMERS_TABLE_PAGE/DELETE_CUSTOMER'
};

export const creators = {
  opened: () => ({
    type: types.OPENED
  }),
  closed: () => ({
    type: types.CLOSED
  }),

  settingsInitialized: settings => ({
    type: types.SETTINGS_INITIALIZED,
    payload: {
      settings
    }
  }),

  readCustomers: () => ({
    type: types.READ_CUSTOMERS
  }),

  deleteCustomer: customerId => ({
    type: types.DELETE_CUSTOMER,
    payload: { customerId }
  })
};
