import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import {
  FontFamilies,
  FontWeights,
  TextFontSizes
} from 'modules/shared/constants/fonts';
import { Theme } from 'defaults/defaultTheme';
import { createUseStyles, useTheme } from 'modules/styles/components/styles.js';
import { formatNumber } from 'modules/shared/utils/number';
import CorporateFareIcon from 'assets/corporate_fare.svg';

const useStyles = createUseStyles(theme => ({
  chartContainer: {
    overflow: 'visible !important',
    padding: '32px 64px',
    border: `1px solid ${theme.defaultInputStrokeColor}`,
    borderRadius: 10,

    '& > div': {
      overflow: 'visible !important'
    }
  },
  dataLabelContainer: {
    color: theme.primaryColor,
    cursor: 'default',
    fontSize: TextFontSizes.normal,
    fontWeight: FontWeights.normal,
    fontFamily: FontFamilies.roboto,
    lineHeight: '16px',
    whiteSpace: 'nowrap'
  },
  dataLabelText: {
    color: theme.secondaryColor
  },
  legendLabelContainer: {
    color: theme.primaryColor,
    cursor: 'default',
    fontSize: TextFontSizes.medium,
    fontWeight: FontWeights.normal,
    fontFamily: FontFamilies.roboto,
    lineHeight: '19px'
  },
  legendLabelText: {
    color: theme.secondaryColor,
    marginBottom: 4
  }
}));

export type ChartData = {
  name: string;
  data: { name: string; y: number }[];
};

type Props = {
  chartData: ChartData;
  cultureCode: string;
};

const AssetsDrilldownChart = ({ chartData, cultureCode }: Props) => {
  const theme: Theme = useTheme();
  const classes = useStyles();

  const options = {
    chart: {
      backgroundColor: 'transparent',
      events: {
        redraw: function () {
          const chart = this as any;
          const { renderer, chartWidth, plotTop, plotSizeY } = chart;

          if (chart.icon) {
            chart.icon.destroy();
          }
          chart.icon = renderer
            .image(
              CorporateFareIcon,
              chartWidth / 2 - 15,
              plotTop + plotSizeY / 2 - 13,
              30,
              26
            )
            .add();
        }
      },
      height: 550 + chartData.data.length * 50,
      width: 370
    },
    colors: theme.chartPortfolioColors,
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    legend: {
      backgroundColor: theme.itemBackgroundColor_2,
      borderRadius: 10,
      itemMarginBottom: 8,
      itemMarginTop: 8,
      itemStyle: {
        cursor: 'default' as const,
        fontSize: '36px',
        fontWeight: 'normal',
        fontFamily: FontFamilies.roboto
      },
      labelFormatter: function () {
        const { name, y: value } = this as any;

        return `<div class='${classes.legendLabelContainer}'>
        <div class='${classes.legendLabelText}'>${name}</div>
        <div>${formatNumber(cultureCode, value, 0, 0)}</div>
        </div>`;
      },
      layout: 'vertical' as const,
      padding: 32,
      symbolHeight: 24,
      symbolPadding: 16,
      symbolWidth: 24,
      useHTML: true
    },
    plotOptions: {
      pie: {
        animation: false,
        center: ['50%', '50%'] as [string, string],
        size: '40%',
        borderWidth: 0,
        dataLabels: {
          alignTo: 'toPlotEdges',
          connectorColor: `${theme.secondaryColor}40`,
          connectorShape: 'crookedLine',
          connectorWidth: 2,
          crop: false,
          formatter: function () {
            const { key, y } = this as any;
            return `<div class='${classes.dataLabelContainer}'>
            <div class='${classes.dataLabelText}'>${key}</div>
          <div >${formatNumber(cultureCode, y, 0, 0)}</div>
            </div>`;
          },
          overflow: 'allow' as const,
          padding: 0,
          useHTML: true
        },
        innerSize: '50%',
        showInLegend: true,
        point: {
          events: { legendItemClick: () => false }
        },
        states: {
          hover: {
            enabled: false
          },
          inactive: {
            opacity: 1
          }
        }
      }
    },
    series: [
      {
        data: chartData.data,
        type: 'pie' as const
      }
    ],
    title: {
      align: 'left' as const,
      text: chartData.name,
      style: {
        color: theme.primaryColor,
        fontFamily: FontFamilies.roboto,
        fontSize: TextFontSizes.large,
        fontWeight: FontWeights.medium,
        lineHeight: '23px'
      }
    },
    tooltip: { enabled: false }
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={'chart'}
      containerProps={{ className: classes.chartContainer }}
      options={options}
    />
  );
};

export default AssetsDrilldownChart;
