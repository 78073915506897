import classNames from 'classnames';
import * as R from 'ramda';
import React, { Fragment, useReducer } from 'react';
import { connect } from 'react-redux';
import { v4 } from 'uuid';

import { CustomerManagementUsersTableOrderKeys } from '../services/reducers/customerManagementUsersTable.js';
import { getUsers } from '../services/selectors/index.js';
import Button from 'features/shared/components/button';
import Icon from 'features/shared/components/icon/index.js';
import Table from 'features/shared/components/table/connectedTable.js';
import TextareaAutosize from 'features/shared/components/textArea/textareaAutosize';
import { creators as formActionCreators } from 'features/shared/services/form/actions.js';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { injectSheet } from 'features/sharedModules/styles/components/styles';

const indexedMap = R.addIndex(R.map);

const actionTypes = {
  CHANGE_ADD_USERS_INPUT: 'CHANGE_ADD_USERS_INPUT'
};

const initialState = {
  addUsersInput: ''
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_ADD_USERS_INPUT: {
      return { ...state, addUsersInput: action.payload };
    }
    default:
      return state;
  }
};

const styles = {
  addUsersSection: {
    alignItems: 'flex-start'
  },
  addUsersLabel: {
    paddingTop: '1.6rem'
  },
  addUsersContent: {
    flex: '1 1 auto'
  },
  addUsersInput: {
    width: '100%',
    display: 'block'
  },
  addUsersActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1.6rem'
  },
  table: {
    marginTop: '3rem'
  },
  checkMarkIcon: {
    fontSize: '2rem',
    lineHeight: '2.4rem'
  },
  deleteIcon: {
    fontSize: '2rem',
    lineHeight: '2.4rem',
    cursor: 'pointer',
    transform: 'rotate(0.03deg)' // fix of issue: icon small size causes pixelated icon
  },
  deleteCell: {
    width: '8rem'
  },
  checkMarkHeaderRowCell: {
    textAlign: 'center'
  },
  checkMarkCell: {
    textAlign: 'center'
  }
};

const Users = ({ fields, users, addUsers, pageClasses, classes }) => {
  const i18n = useI18n();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { items, totalItemsNumber } = users;
  const allUsers = fields.getAll();

  return (
    <Fragment>
      <div className={classNames(pageClasses.section, classes.addUsersSection)}>
        <span className={classNames(pageClasses.label, classes.addUsersLabel)}>
          {i18n('customers.customerManagement.addUsers')}
        </span>
        <div className={classes.addUsersContent}>
          <TextareaAutosize
            value={state.addUsersInput}
            onChange={e =>
              dispatch({
                type: actionTypes.CHANGE_ADD_USERS_INPUT,
                payload: e.target.value
              })
            }
            placeholder={i18n(
              'customers.customerManagement.addUsersPlaceholder'
            )}
            className={classes.addUsersInput}
          />
          <div className={classes.addUsersActions}>
            <Button
              disabled={R.isEmpty(state.addUsersInput.trim())}
              onClick={() => {
                const users = R.pipe(
                  R.split(','),
                  R.map(R.trim),
                  R.reject(R.isEmpty),
                  indexedMap((email, index) => ({
                    id: v4(),
                    email,
                    doesAuth0UserExist: false,
                    isCustomerAdmin: index === 0 && R.length(allUsers) === 0
                  }))
                )(state.addUsersInput.trim());

                addUsers(users);

                dispatch({
                  type: actionTypes.CHANGE_ADD_USERS_INPUT,
                  payload: ''
                });
              }}
            >
              {i18n('shared.add')}
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.table}>
        <Table
          id={'customerManagementUsersTable_state'}
          noDataTitle={i18n('customers.customerManagement.noUsers')}
          totalItemsNumber={totalItemsNumber}
          header={[
            {
              title: i18n('shared.email'),
              orderKey: CustomerManagementUsersTableOrderKeys.email,
              isOrderable: true
            },
            {
              title: i18n('shared.admin'),
              className: classes.checkMarkHeaderRowCell
            },
            {
              title: i18n('shared.auth0'),
              className: classes.checkMarkHeaderRowCell
            },
            {
              title: ''
            }
          ]}
          items={R.map(user => {
            return {
              id: user.id,
              cells: ({ bodyRowCellClassName }) => (
                <Fragment>
                  <td className={bodyRowCellClassName}>{user.email}</td>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.checkMarkCell
                    )}
                  >
                    {user.isCustomerAdmin && (
                      <Icon className={classes.checkMarkIcon} type="check" />
                    )}
                  </td>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.checkMarkCell
                    )}
                  >
                    {user.doesAuth0UserExist && (
                      <Icon className={classes.checkMarkIcon} type="check" />
                    )}
                  </td>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.deleteCell
                    )}
                  >
                    <Icon
                      className={classes.deleteIcon}
                      onClick={() => fields.remove(user.index)}
                      type="delete"
                    />
                  </td>
                </Fragment>
              )
            };
          }, items)}
        />
      </div>
    </Fragment>
  );
};

export default R.compose(
  connect(
    state => {
      const users = getUsers(state);

      return { users };
    },
    dispatch => {
      return {
        addUsers: users => {
          dispatch(
            formActionCreators.arrayConcat(
              'editCustomerForm_state',
              'users',
              users
            )
          );
        }
      };
    }
  ),
  injectSheet(styles)
)(Users);
