import { useIsSessionReadOnly } from '../../../main/services/selectors';
import OrderExecutionActionButtons from '../orderExecutionActionButtons';
import InstrumentsTable from './instrumentsTable';
import { RoboAdviceOrderExecutionPages } from 'features/roboAdvice/shared/constants';
import AdvisorNotes from 'features/shared/components/advisorNotes';
import { Colors } from 'features/shared/constants/colors';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import ErrorsList from 'features/sharedModules/errors/components/errorsList';
import NextButtonError from 'features/sharedModules/errors/components/nextButtonError';
import { useGetAllOrderExecutionErrors } from 'features/sharedModules/errors/services/useGetAllOrderExecutionErrors';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  container: {
    background: Colors.blue75,
    padding: Spacing.spacing02,
    border: `1px solid ${theme.defaultInputStrokeColor}`,
    width: '500px'
  },
  title: {
    fontSize: Typography.heading1.size,
    fontWeight: FontWeights.medium,
    marginBottom: Spacing.spacing01
  },
  field: {
    '&:not(:first-child) $radioLabel': {
      marginTop: 22
    }
  },
  radioLabel: {
    fontWeight: FontWeights.normal,
    fontSize: Typography.body1.size,
    lineHeight: Typography.heading1.lineHeight,
    marginBottom: Spacing.spacing02
  },
  radioButtons: {
    '& + &': {
      marginTop: 25
    }
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: Spacing.spacing01
  },
  actionButton: {
    '& + &': {
      marginLeft: Spacing.spacing01
    }
  },
  advisorNotes: {
    marginTop: Spacing.spacing04
  },
  errors: {
    marginTop: Spacing.spacing04
  }
}));

const Instruments = ({ onNavButtonClick }) => {
  const classes = useStyles();
  const isSessionReadOnly = useIsSessionReadOnly();
  const i18n = useI18n();
  const allErrors = useGetAllOrderExecutionErrors();
  const errors = allErrors[RoboAdviceOrderExecutionPages.instruments];

  return (
    <>
      <ErrorsList
        pageName={i18n('roboAdvice.tabs.instruments')}
        errors={errors}
      />
      <div className={classes.title}>
        {i18n('roboAdvice.orderExecution.instrumentsToOrder')}:
      </div>
      <InstrumentsTable
        data-testid={'instrumentsTable'}
        errors={errors?.instruments}
      />
      <AdvisorNotes
        data-testid={'advisorNotes'}
        name={'instrumentsAdvisorNotes'}
        header={i18n('orderExecution.instruments.advisorNotes.header')}
        customPlaceholder={i18n(
          'orderExecution.instruments.advisorNotes.placeholder'
        )}
        infoTooltipText={i18n(
          'orderExecution.instruments.advisorNotes.infoTooltip'
        )}
        subheader={i18n('roboAdvice.advisory.minimumCharacter')}
        disabled={isSessionReadOnly}
        className={classes.advisorNotes}
        errors={errors?.advisorNotes}
        elementId={'instruments-advisor-notes'}
        required
      />
      <div className={classes.errors}>
        <NextButtonError allErrors={allErrors} />
      </div>

      <OrderExecutionActionButtons
        pageId={RoboAdviceOrderExecutionPages.instruments}
        onNavButtonClick={onNavButtonClick}
      />
    </>
  );
};

export default Instruments;
