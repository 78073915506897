import { useEffect, useMemo } from 'react';

import { form } from '../../form/services/form';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { form as roboAdviceForm } from 'features/roboAdvice/adviceSession/form/services/form';
import { useStyles } from 'features/roboAdvice/client/kyc/components/useInitPage';
import { useCountryDropdownOptions } from 'features/roboAdvice/shared/components/useCountryDropdownOptions';
import { Layouts } from 'features/roboAdvice/shared/constants/index';
import ConfigurableLabel from 'features/shared/components/configurableLabel/';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { FieldComponentTypes } from 'features/sharedModules/declarativeForm/constants';
import { useErrorsStore } from 'features/sharedModules/errors/services/errorsStore';

export const useInitPage = () => {
  const classes = useStyles();
  const {
    roboAdviceForm: {
      adviceInformation: { fields: adviceInformationFields }
    }
  } = useCustomerConfig();
  const isErrorModeEnabled = useErrorsStore(state => state.isErrorModeEnabled);
  const countryDropdownOptions = useCountryDropdownOptions();

  const fields = useMemo(() => {
    return adviceInformationFields
      .filter(({ isEnabled }) => isEnabled)
      .sort((a, b) => a.order - b.order)
      .map(
        ({
          allowLeadingZeros,
          name,
          componentType,
          dateFormat,
          dateType,
          format,
          items,
          label,
          layout,
          options,
          placeholder,
          required,
          info
        }) => {
          const languageKey = getUserPageLanguage();
          const labelContent = languageKey && label ? label[languageKey] : '';

          const config = {
            allowLeadingZeros,
            componentType,
            dateFormat,
            dateType,
            format,
            items,
            layout,
            options,
            placeholder,
            required,
            info
          };

          const customLabel = (
            <div className={classes.label}>
              <ConfigurableLabel
                required={config.required}
                label={labelContent}
                info={config.info && languageKey && config.info[languageKey]}
              />
            </div>
          );

          if (
            config.componentType === FieldComponentTypes.textInput ||
            config.componentType === FieldComponentTypes.numberInput
          ) {
            return {
              name,
              config: {
                ...config,
                labelValue: customLabel
              },
              labelClassName: classes.standardLabel
            };
          }

          if (config.componentType === FieldComponentTypes.textarea) {
            return {
              name,
              config: {
                ...config,
                labelValue: customLabel
              },
              className: classes.standardInputWidth,
              sectionClassName: classes.textareaSection,
              labelClassName: classes.textareaLabel
            };
          }

          if (config.componentType === FieldComponentTypes.countryDropdown) {
            return {
              name,
              config: {
                ...config,
                componentType: FieldComponentTypes.dropdown,
                labelValue: customLabel,
                items: countryDropdownOptions.map(({ key }) => ({
                  activeValue: key,
                  titleKey: `roboAdvice.country.${key.toLowerCase()}`
                }))
              },
              labelClassName: classes.label
            };
          }

          if (config.componentType === FieldComponentTypes.dropdown) {
            return {
              name,
              config: {
                ...config,
                labelValue: customLabel
              },
              labelClassName: classes.label,
              afterChange: value => {
                config.items?.forEach(({ activeValue, textField }) => {
                  if (!!textField && !value.includes(activeValue)) {
                    form.change(textField?.name as any, null);
                  }
                });
              }
            };
          }

          if (
            config.componentType === FieldComponentTypes.radioButtons ||
            config.componentType === FieldComponentTypes.checkboxes
          ) {
            return {
              name,
              config: {
                ...config,
                layout: Layouts.horizontal,
                labelValue: customLabel
              },
              afterChange: value => {
                config.items?.forEach(({ activeValue, textField }) => {
                  if (!!textField && !value.includes(activeValue)) {
                    form.change(textField?.name as any, null);
                  }
                });
              }
            };
          }

          if (
            config.componentType === FieldComponentTypes.buttonSwitch ||
            config.componentType === FieldComponentTypes.datepicker
          ) {
            return {
              name,
              config: {
                ...config,
                layout: Layouts.horizontal,
                labelValue: customLabel
              },
              labelClassName: classes.standardLabel
            };
          }

          return { name, config };
        }
      );
  }, [adviceInformationFields]);

  useEffect(() => {
    if (isErrorModeEnabled) {
      roboAdviceForm.submit();
    }
  }, []);

  return fields;
};
