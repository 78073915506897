import * as R from 'ramda';
import * as React from 'react';

import { getErrorText } from './shared.js';
import { useField } from './useField';
import ErrorMessage from 'features/shared/components/errorMessage/index.js';
import FileUpload from 'features/shared/components/fileUpload/index';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  errorMessage: {
    textAlign: 'left'
  }
});

type Props = {
  name: string;
  fieldConfig?: any;
  label: string;
  description?: string;
  accept?: string;
};

const FinalFormFileUpload = ({ name, fieldConfig, ...restProps }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const {
    input: { value, onChange, onFocus, onBlur },
    meta: { error, touched }
  } = useField<{ url?: string; file?: File } | undefined>(name, fieldConfig);

  const isErrorShown = touched && !R.isNil(error);
  const errorText = getErrorText(i18n, error);

  return (
    <div>
      <FileUpload
        value={value}
        onSelect={file => {
          onChange({ file });
        }}
        onDelete={() => {
          onChange(undefined);
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        {...restProps}
      />
      {isErrorShown && (
        <ErrorMessage
          className={classes.errorMessage}
        >{`* ${errorText}`}</ErrorMessage>
      )}
    </div>
  );
};

export default FinalFormFileUpload;
