import classNames from 'classnames';
import { isNil } from 'ramda';
import { useSelector } from 'react-redux';

import { useOrderPageStore } from '../../../services/orderPageStore';
import { useReadOrderExecutionCostData } from './useReadOrderExecutionCostData';
import { useReadDataListener } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import Table from 'features/shared/components/table/table';
import TableBody from 'features/shared/components/table/tableBody';
import TableBodyRow from 'features/shared/components/table/tableBodyRow';
import sessionSelectors from 'features/shared/services/session/selectors';
import { formatNumber } from 'features/shared/utils/number';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const AnyTableComponent: any = Table;
const AnyTableBody: any = TableBody;
const AnyTableBodyRow: any = TableBodyRow;

const useStyles = createUseStyles(theme => ({
  rowCell: {
    padding: '1.2rem 1.3rem',
    textAlign: 'left',
    '&$summaryRowCell$summaryRowCell': {
      backgroundColor: theme.inputFillFocusColor
    }
  },
  numberCell: {
    textAlign: 'right',
    minWidth: '13rem',
    maxWidth: '16rem'
  },
  numberInput: {
    width: '100%'
  }
}));

const CostTable = ({ isSelected }) => {
  const i18n = useI18n();
  const classes = useStyles();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const { costData, pageStatuses } = useOrderPageStore();

  useReadDataListener(
    pageStatuses.readCostData,
    isSelected,
    useReadOrderExecutionCostData()
  );

  const allHeaders = [
    { title: i18n('roboAdvice.proposal.orderSummary.fund') },
    {
      title: i18n('costChart.costTable.fundManagementFee'),
      className: classes.numberCell
    },
    {
      title: i18n('costChart.costTable.fundTransactionCost'),
      className: classes.numberCell
    },
    {
      title: i18n('costChart.costTable.fundPurchaseFee'),
      className: classes.numberCell
    },
    {
      title: i18n('costChart.costTable.custodyFee'),
      className: classes.numberCell
    },
    {
      title: i18n('costChart.costTable.platformFee'),
      className: classes.numberCell
    },
    {
      title: i18n('costChart.costTable.advisoryPurchasingFee'),
      className: classes.numberCell
    },
    {
      title: i18n('costChart.costTable.advisoryOnboardingFee'),
      className: classes.numberCell
    },
    {
      title: i18n('costChart.costTable.successFee'),
      className: classes.numberCell
    },
    {
      title: i18n('costChart.costTable.insuranceCost'),
      className: classes.numberCell
    },
    {
      title: i18n('costChart.costTable.fundReturnCommissionPaidToClient'),
      className: classes.numberCell
    },
    {
      title: i18n('costChart.costTable.fundReturnCommissionPaidToAdvisor'),
      className: classes.numberCell
    }
  ];

  const allCostDataKeys = [
    'fund_management_fee',
    'fund_transaction_cost',
    'fund_purchasing_fee',
    'fund_custody_fee',
    'fund_platform_fee',
    'fund_advisory_purchasing_fee',
    'fund_advisory_onboarding_fee',
    'fund_performance_fee',
    'fund_insurance_cost',
    'fund_return_commission_paid_to_client',
    'fund_return_commission_paid_to_advisor'
  ];

  let costDataKeys: string[] = [];
  let header = [allHeaders[0]];

  allCostDataKeys.forEach((key, index) => {
    if (costData.some(item => !isNil(item[key]))) {
      costDataKeys.push(key);
      header.push(allHeaders[index + 1]);
    }
  });

  return (
    <>
      {costData ? (
        <AnyTableComponent header={header}>
          <AnyTableBody cellsNumber={header.length}>
            {costData.map(item => {
              return (
                <AnyTableBodyRow key={item.Ticker} id={item.Ticker}>
                  {({ bodyRowCellClassName }) => (
                    <>
                      <td
                        className={classNames(
                          classes.rowCell,
                          bodyRowCellClassName
                        )}
                      >
                        {item.Name}
                      </td>
                      {costDataKeys.map(key => (
                        <td
                          key={key}
                          className={classNames(
                            classes.rowCell,
                            classes.numberCell,
                            bodyRowCellClassName
                          )}
                        >
                          {isNaN(item[key])
                            ? formatNumber(cultureCode, 0)
                            : formatNumber(cultureCode, item[key])}
                        </td>
                      ))}
                    </>
                  )}
                </AnyTableBodyRow>
              );
            })}
          </AnyTableBody>
        </AnyTableComponent>
      ) : (
        ''
      )}
    </>
  );
};

export default CostTable;
