import React from 'react';

import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  demoAlert: {
    width: '100%',
    padding: '5px',
    backgroundColor: 'firebrick',
    textAlign: 'center',
    userSelect: 'none'
  }
});

const DemoEnvironmentAlert = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const { system: { message } = { message: undefined } } = useCustomerConfig();

  return (
    <div
      className={classes.demoAlert}
      style={{
        backgroundColor: message?.bgcolor,
        color: message?.textcolor
      }}
    >
      {i18n('system.message.text')}
    </div>
  );
};

export default DemoEnvironmentAlert;
