import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { Layouts } from 'features/roboAdvice/shared/constants';
import { Spacing } from 'features/shared/constants/spacing';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import FinalFormRadios from 'features/sharedModules/finalForm/components/radios';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  label: {
    marginBottom: Spacing.spacing01
  },
  radioButtonsLabel: {
    gap: Spacing.spacing00
  }
});

const RadioTimeHorizon = ({ disabled, afterChange }) => {
  const i18n = useI18n();
  const classes = useStyles();
  const {
    timeHorizonConfig: { items }
  } = useCustomerConfig();
  const language = getUserPageLanguage();

  return (
    <div className={classes.section}>
      <div className={classes.label}>
        {i18n('roboAdvice.purposeAndRisk.timeHorizon')}
      </div>
      <FinalFormRadios
        name={'data.timeHorizon'}
        data-testid={'time-horizon'}
        items={items.map(({ riskHorizonValue, label }) => ({
          activeValue: riskHorizonValue,
          label: label[language]
        }))}
        layout={Layouts.vertical}
        disabled={disabled}
        labelClassName={classes.radioButtonsLabel}
        afterChange={afterChange}
      />
    </div>
  );
};

export default RadioTimeHorizon;
