import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import ExistingRow from './existingRow';
import NewRow from './newRow';
import SummaryRow from './summaryRow';
import { useReadInstruments } from './useReadInstruments';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import { useTargetMarketAssessmentStore } from 'features/roboAdvice/adviceSession/orderExecution/services/targetMarketAssessmentStore';
import { useSessionStore } from 'features/roboAdvice/adviceSession/session/services/sessionStore';
import { ProductAttributeType } from 'features/roboAdvice/adviceSession/shared/constants';
import { useAdviceSessionStore } from 'features/roboAdvice/adviceSession/shared/services/adviceSessionStore';
import {
  CustomAttributeFromConfig,
  getCustomAttributes
} from 'features/roboAdvice/adviceSession/shared/services/selectors';
import Table from 'features/shared/components/table/table';
import TableBody from 'features/shared/components/table/tableBody';
import Tooltip from 'features/shared/components/tooltip';
import { Spacing } from 'features/shared/constants/spacing';
import sessionSelectors from 'features/shared/services/session/selectors';
import { getNumberInputFormat } from 'features/shared/utils/number';
import { getTranslation } from 'features/shared/utils/translations';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import InputErrorMessage from 'features/sharedModules/errors/components/inputErrorMessage';
import { useFieldArray } from 'features/sharedModules/finalForm/components/useFieldArray';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const AnyTable: any = Table;
const AnyTableBody: any = TableBody;

const useStyles = createUseStyles(theme => ({
  headerCell: {
    minWidth: '150px'
  },
  errorsSection: {
    marginBottom: `${Spacing.spacing00}px`
  },
  numberCell: {
    textAlign: 'right',
    minWidth: '120px',
    maxWidth: '160px'
  },
  emptyCell: {
    width: '56px'
  },
  center: {
    textAlign: 'center'
  },
  warningIcon: {
    flexShrink: 0,
    rotate: '180deg',

    '& path': {
      fill: theme.warningColor
    }
  },
  headerWithWarning: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.spacing00
  }
}));

const InstrumentsTable = ({ errors }) => {
  const i18n = useI18n();
  const classes = useStyles();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const { thousandSeparator, decimalSeparator } =
    getNumberInputFormat(cultureCode);
  const instrumentsSelected = useFieldArray(`instrumentsSelected`);
  const [instruments, setInstruments] = useState([]);
  const readInstruments = useReadInstruments(setInstruments);

  const {
    roboAdvice: {
      orderExecution: {
        instrumentsList: {
          productAttributeTxt1,
          productAttributeTxt2,
          productAttributeTxt3,
          productAttributeTxt4,
          productAttributeTxt5,
          productAttributeBinary1,
          productAttributeBinary2,
          productAttributeBinary3,
          productAttributeBinary4,
          productAttributeBinary5,
          showISINColumn,
          showAssetClassColumn,
          showSuitabilityStatus
        }
      }
    },
    roboOrderFlow: {
      targetMarketAssessmentPage: { pageLabel }
    }
  } = useCustomerConfig();
  const { customAttributesData } = useAdviceSessionStore();
  const sessionStoreState = useSessionStore.getState();
  const { advancedSuitabilityData } = useTargetMarketAssessmentStore();

  useEffect(() => {
    readInstruments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const productAttributes = useMemo(() => {
    const allProductAttributes = getCustomAttributes({
      productAttributeTxt1,
      productAttributeTxt2,
      productAttributeTxt3,
      productAttributeTxt4,
      productAttributeTxt5,
      productAttributeBinary1,
      productAttributeBinary2,
      productAttributeBinary3,
      productAttributeBinary4,
      productAttributeBinary5
    });
    allProductAttributes.sort((a, b) => a.order - b.order);

    return allProductAttributes.reduce((acc, productAttribute) => {
      if (acc.length < 3) {
        acc.push(productAttribute);
      }

      return acc;
    }, [] as CustomAttributeFromConfig[]);
  }, []);

  const header = [
    {
      title: i18n('roboAdvice.clientInformation.contacts.name'),
      className: classes.headerCell,
      isHidden: false
    },
    {
      title: i18n('shared.isin'),
      className: classes.headerCell,
      isHidden: !showISINColumn
    },
    {
      title: i18n('shared.assetClass'),
      className: classes.headerCell,
      isHidden: !showAssetClassColumn
    },
    ...productAttributes.map(({ label, type }) => ({
      title: getTranslation(label),
      className: type === ProductAttributeType.binary ? classes.center : null,
      isHidden: false
    })),
    {
      component: (
        <div
          className={classNames(classes.headerWithWarning, classes.headerCell)}
        >
          <div>{i18n('roboAdvice.advisory.customPortfolio.suitability')}</div>

          {!advancedSuitabilityData && (
            <Tooltip
              content={i18n(
                'roboAdvice.orderExecution.instrumentsTable.targetAssessmentNotFilledWarning'
              ).replace('{0}', getTranslation(pageLabel))}
              infoTooltip
              trigger={<WarningIcon className={classes.warningIcon} />}
            />
          )}
        </div>
      ),
      className: classNames(classes.center, classes.headerCell),
      isHidden: !showSuitabilityStatus
    },
    {
      title: i18n('roboAdvice.orderExecution.initialDeposit'),
      className: classes.numberCell,
      isHidden: false
    },
    {
      title: i18n('roboAdvice.orderExecution.monthlyDeposit'),
      className: classes.numberCell,
      isHidden: false
    },
    { title: '', className: classes.emptyCell, isHidden: false }
  ].filter(({ isHidden }) => !isHidden);

  return (
    <>
      <div className={classes.errorsSection}>
        {errors?.map(error => (
          <InputErrorMessage message={error.alertMessage} />
        ))}
      </div>
      <AnyTable header={header} id="instruments-table">
        <AnyTableBody cellsNumber={header.length}>
          {instrumentsSelected.fields.map((name, index) => {
            const item = instrumentsSelected.fields.value[index];
            const customAttributeData = customAttributesData[
              sessionStoreState.defaultConfigNamespaceId!
            ]?.find(({ ticker }) => ticker === item.id);
            const instrumentSuitabilityStatus =
              advancedSuitabilityData?.data?.find(({ id }) => id === item.id);

            return (
              <ExistingRow
                index={index}
                item={item}
                key={item.id}
                instrumentsSelected={instrumentsSelected}
                decimalSeparator={decimalSeparator}
                thousandSeparator={thousandSeparator}
                productAttributes={productAttributes}
                customAttributeData={customAttributeData}
                showISINColumn={showISINColumn}
                showAssetClassColumn={showAssetClassColumn}
                showSuitabilityStatus={showSuitabilityStatus}
                instrumentSuitabilityStatus={instrumentSuitabilityStatus}
              />
            );
          })}

          <NewRow
            instruments={instruments}
            instrumentsSelected={instrumentsSelected}
            decimalSeparator={decimalSeparator}
            thousandSeparator={thousandSeparator}
            showISINColumn={showISINColumn}
            showAssetClassColumn={showAssetClassColumn}
            productAttributes={productAttributes}
            customAttributesData={customAttributesData}
            showSuitabilityStatus={showSuitabilityStatus}
            advancedSuitabilityData={advancedSuitabilityData}
          />

          <SummaryRow
            numberOfAdditionalColumns={productAttributes.length}
            showISINColumn={showISINColumn}
            showAssetClassColumn={showAssetClassColumn}
            showSuitabilityStatus={showSuitabilityStatus}
          />
        </AnyTableBody>
      </AnyTable>
    </>
  );
};

export default InstrumentsTable;
