import axios, { CancelTokenSource } from 'axios';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHolisticViewStore } from '../services/holisticViewStore';
import { getSessionData } from 'features/roboAdvice/adviceSession/shared/api';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { ClientTypes } from 'features/shared/constants/session';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { getTranslation } from 'features/shared/utils/translations';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

const useReadSelectedAdviceSessions = () => {
  const i18n = useI18n();
  const dispatch = useDispatch();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const {
    roboAdviceForm: {
      financialSituation: { assets: configAssets, debt: configDebt }
    }
  } = useCustomerConfig();

  const cancelTokenSourceRef = React.useRef<CancelTokenSource>();
  const readSelectedAdviceSessions = async () => {
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const holisticViewStoreState = useHolisticViewStore.getState();
    const selectedClients = holisticViewStoreState.availableClients.filter(
      c =>
        c.isSelected &&
        c.selectedAdviceSessionId !== c.financialSituationData?.adviceSessionId
    );

    try {
      holisticViewStoreState.setIsReadSelectedAdviceSessionsPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      for (const client of selectedClients) {
        const {
          data: {
            data: [{ payload }]
          }
        } = await getSessionData(
          accessToken,
          cancelTokenSource.token,
          client.selectedAdviceSessionId
        );

        const responseData =
          payload.personFinancialSituation || payload.companyFinancialSituation;

        const {
          dropInPercent,
          dropInStd,
          educationWork,
          expectationOfRisk,
          experience,
          riskStrategy
        } = payload;

        // Not enough data
        if (!responseData) {
          holisticViewStoreState.setFinancialSituationData(client.id, null);
          continue;
        }

        const assetsKeysAndTranslations =
          client.type === ClientTypes.person
            ? configAssets.person.map(({ id, label }) => ({
                id,
                label
              }))
            : configAssets.company.map(({ id, label }) => ({
                id,
                label
              }));

        const debtKeys =
          client.type === ClientTypes.person
            ? configDebt.person.map(({ id }) => id)
            : configDebt.company.map(({ id }) => id);

        const assets = Object.keys(responseData)
          .filter(key =>
            assetsKeysAndTranslations.map(({ id }) => id).includes(key)
          )
          .map(key => {
            const totalValue = responseData[key].reduce(
              (prev, acc) => prev + (acc.value || 0),
              0
            );

            return {
              name: getTranslation(
                assetsKeysAndTranslations.find(({ id }) => id === key)?.label
              ),
              y: totalValue
            };
          })
          .sort((a, b) => b.y - a.y);

        const totalAssets = assets.reduce((acc, curr) => {
          return acc + curr.y;
        }, 0);

        const totalDebt = Object.keys(responseData)
          .filter(key => debtKeys.includes(key))
          .reduce((acc, curr) => {
            return acc + (responseData[curr].defaultValue || 0);
          }, 0);

        const financialSituationData = {
          adviceSessionId: client.selectedAdviceSessionId!,
          name: client.name,
          assets,
          totalAssets,
          totalDebt,
          dropInPercent,
          dropInStd,
          educationWork,
          expectationOfRisk,
          experience,
          riskStrategy
        };

        holisticViewStoreState.setFinancialSituationData(
          client.id,
          financialSituationData
        );
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('tools.holisticView.getAdviceSessionsError'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    } finally {
      holisticViewStoreState.setIsReadSelectedAdviceSessionsPending(false);
    }
  };

  return readSelectedAdviceSessions;
};

export default useReadSelectedAdviceSessions;
