import { createUsePortfolioChartStore } from '@quantfoliorepo/ui-components';
import axios from 'axios';
import * as R from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UseBoundStore } from 'zustand';

import { PortfolioType } from '../../portfolioChart';
import { PageStatuses } from '../../shared/components/useReadDataListener';
import { usePageStore as useRiskScorePageStore } from '../services/pageStore';
import { readStandardPortfolio } from 'features/roboAdvice/adviceSession/proposal/components/portfolioChart/useReadPortfolioChartData';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { useTheme } from 'features/sharedModules/styles/components/styles';

export const usePortfolioChartStore: UseBoundStore<any> =
  createUsePortfolioChartStore();

export function useReadPortfolioChartData(selectedRisks) {
  const i18n = useI18n();
  const theme = useTheme();
  const portfolioChartColors = theme.chartPortfolioColors;
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();
  const {
    roboPortfolioPrecision,
    tenantSettings: { fundNameForPresentation },
    roboAdvice: { subAssetClassNameMapping = {} }
  } = useCustomerConfig();

  const cancelTokenSourceRef = React.useRef<any>();
  const readPortfolioChartData = async () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const riskScorePageStore = useRiskScorePageStore.getState();
    const portfolioChartStore = usePortfolioChartStore.getState();

    if (
      riskScorePageStore.pageStatuses.readPortfolioChartData !==
      PageStatuses.ready
    )
      return;

    try {
      riskScorePageStore.changePageStatuses({
        readPortfolioChartData: PageStatuses.pending,
        readExpectedValuePortfoliosChartData: PageStatuses.pending,
        readExpectedPathPortfoliosChartData: PageStatuses.pending,
        readHistoricalReturn3MChartData: PageStatuses.pending,
        readHistoricalReturnYTDChartData: PageStatuses.pending,
        readHistoricalReturn1YChartData: PageStatuses.pending,
        readHistoricalReturn3YChartData: PageStatuses.pending,
        readHistoricalReturn5YChartData: PageStatuses.pending,
        readHistoricalReturn10YChartData: PageStatuses.pending
      });

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      portfolioChartStore.resetChartData();
      const { productConfiguration } = riskScorePageStore;

      for (const selectedRisk of selectedRisks) {
        const portfolio = await readStandardPortfolio({
          qAuthAccessToken,
          cancelTokenSource,
          risk_tolerance: numberToRiskScore(selectedRisk.risk),
          optionals: [],
          precision: roboPortfolioPrecision,
          namespace_id: productConfiguration,
          portfolioChartColors,
          fundNameForPresentation,
          goalId: selectedRisk.risk,
          goalName: selectedRisk.name,
          goalIcon: null,
          subAssetClassNameMapping,
          portfolioType: PortfolioType.model
        });
        portfolioChartStore.addAssetClassAllocationPortfolioChartData({
          ...portfolio,
          i18n
        });
      }

      riskScorePageStore.changePageStatuses({
        readPortfolioChartData: PageStatuses.succeed,
        readExpectedValuePortfoliosChartData: PageStatuses.ready,
        readExpectedPathPortfoliosChartData: PageStatuses.ready,
        readHistoricalReturn3MChartData: PageStatuses.ready,
        readHistoricalReturnYTDChartData: PageStatuses.ready,
        readHistoricalReturn1YChartData: PageStatuses.ready,
        readHistoricalReturn3YChartData: PageStatuses.ready,
        readHistoricalReturn5YChartData: PageStatuses.ready,
        readHistoricalReturn10YChartData: PageStatuses.ready
      });
    } catch (error) {
      if (!axios.isCancel(error)) {
        portfolioChartStore.resetChartData();
        riskScorePageStore.setPageStatus(
          'readPortfolioChartData',
          PageStatuses.failed
        );

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.proposal.readDataErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readPortfolioChartData;
}

const numberToRiskScore = num => {
  return 'R' + (num < 10 ? '0' + num : num);
};
