import Page from './page.js';
import { useInitPage } from './useInitPage';

const Advisory = () => {
  useInitPage();

  return <Page />;
};

export default Advisory;
