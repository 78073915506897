import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RoboAdviceClientsListPages } from '../../../shared/constants';
import { clientsTable } from '../../clientsTable';
import {
  useClientsListStore,
  ClientsListStoreData,
  Client
} from '../services/clientsListStore';
import { ClientType } from 'features/shared/constants/session';
import sessionSelectors from 'features/shared/services/session/selectors';
import { TableStoreData } from 'features/sharedModules/table/types';
import { getSortFunc } from 'features/sharedModules/table/utils';

export type ClientTableView = {
  id: string;
  type: ClientType;
  name: string;
  email: string | null;
  advisorName: string | null;
  isOwnedByUser: boolean;
};

export function useClients(): ClientTableView[] {
  const clientsListStore = useClientsListStore();
  const clientsTableStore = clientsTable.useTableStore();
  const params = useParams<any>();
  const currentUser = useSelector(sessionSelectors.getCurrentUser);

  return getClientsResult({
    clientsTableStore,
    clientsListStore,
    params,
    currentUser
  });
}

export function getClientsResult({
  clientsTableStore,
  clientsListStore,
  params,
  currentUser
}: {
  clientsTableStore: TableStoreData;
  clientsListStore: ClientsListStoreData;
  params: any;
  currentUser: any;
}): ClientTableView[] {
  const { pageId } = params;
  const { search, clients: originalClients } = clientsListStore;

  if (R.isNil(currentUser)) {
    return [];
  }

  let clients: Client[] = [];

  if (pageId === RoboAdviceClientsListPages.allClients) {
    clients = originalClients;
  }

  if (pageId === RoboAdviceClientsListPages.myClients) {
    clients = R.filter(
      c => c.advisorId === currentUser.auth0Id,
      originalClients
    );
  }

  return R.pipe<
    Client[],
    ClientTableView[],
    ClientTableView[],
    ClientTableView[]
  >(
    R.map(c => ({
      id: c.id,
      type: c.type,
      name: c.name,
      email: c.email,
      advisorName: c.advisorName,
      isOwnedByUser: c.advisorId === currentUser.auth0Id
    })),
    getSortFunc<ClientTableView>({ tableStoreData: clientsTableStore }),
    R.isNil(search) || R.isEmpty(search)
      ? R.identity
      : R.filter<ClientTableView>(c =>
          R.includes(R.toLower(search), R.toLower(c.name || ''))
        )
  )(clients);
}
