import { useWindowSize, useElementSize } from 'usehooks-ts';

import { Spacing } from 'features/shared/constants/spacing';

export const modalMaxHeightPercent = 75; // vertical margin 12.5%
export const modalPadding = Spacing.spacing02;
export const modalHeaderMarginBottom = Spacing.spacing02;
export const modalFooterMarginTop = Spacing.spacing02;
export const modalMaxWidthPercent = 84; // vertical margin 8%
export const modalBorderWidth = 2;

export const useModalBodyMaxDimensions = () => {
  const { height, width } = useWindowSize();
  const [headerRef, { height: headerHeight }] = useElementSize();
  const [footerRef, { height: footerHeight }] = useElementSize();

  return {
    modalBodyMaxHeight:
      height * (modalMaxHeightPercent / 100) -
      modalPadding * 2 - // *2 top and bottom
      modalBorderWidth * 2 - // *2 top and bottom
      (headerHeight + modalHeaderMarginBottom) -
      (footerHeight ? footerHeight + modalFooterMarginTop : 0),
    modalBodyMaxWidth:
      width * (modalMaxWidthPercent / 100) -
      modalPadding * 2 - // *2 left and right
      modalBorderWidth * 2, // *2 left and right
    headerRef,
    footerRef
  };
};
