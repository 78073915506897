import { useSessionStore } from 'features/roboAdvice/adviceSession/session/services/sessionStore';
import { useIsFollowUpEnabled } from 'features/roboAdvice/shared/components/useIsFollowUpEnabled';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';

export const useIsExistingPortfolioVisible = () => {
  const {
    advisoryComponents: { existingPortfolioComparison }
  } = useCustomerConfig();
  const sessionStore = useSessionStore();
  const { isFollowUpEnabled } = useIsFollowUpEnabled();

  return {
    isExistingPortfolioVisible:
      existingPortfolioComparison ||
      (!!sessionStore.followUpId && isFollowUpEnabled)
  };
};
