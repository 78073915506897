import { isNil } from 'ramda';

import { CustomerConfigType } from 'features/shared/services/session/types';

export type CriteriaField = {
  name: string;
  textFieldName: string;
  alignmentCriteriaOrder: number;
  enabled: boolean;
  config: {
    title: Record<string, string>;
    content: Record<string, string>;
    isCommentFieldShown?: boolean;
    type: string;
    required?: boolean;
    isValueAdaptedRecorded?: boolean;
  };
};

export const getAlignmentCriteriaFields = ({
  customerConfig
}: {
  customerConfig: CustomerConfigType;
}): CriteriaField[] => {
  const {
    alignmentCriteria1,
    alignmentCriteria2,
    alignmentCriteria3,
    alignmentCriteria4,
    alignmentCriteria5
  } = customerConfig?.roboAdvice?.sustainability;

  return [
    alignmentCriteria1
      ? {
          ...alignmentCriteria1,
          name: 'sustainability.alignmentCriteria.alignmentCriteria1',
          textFieldName:
            'sustainability.alignmentCriteriaTextFields.alignmentCriteria1'
        }
      : null,
    alignmentCriteria2
      ? {
          ...alignmentCriteria2,
          name: 'sustainability.alignmentCriteria.alignmentCriteria2',
          textFieldName:
            'sustainability.alignmentCriteriaTextFields.alignmentCriteria2'
        }
      : null,
    alignmentCriteria3
      ? {
          ...alignmentCriteria3,
          name: 'sustainability.alignmentCriteria.alignmentCriteria3',
          textFieldName:
            'sustainability.alignmentCriteriaTextFields.alignmentCriteria3'
        }
      : null,
    alignmentCriteria4
      ? {
          ...alignmentCriteria4,
          name: 'sustainability.alignmentCriteria.alignmentCriteria4',
          textFieldName:
            'sustainability.alignmentCriteriaTextFields.alignmentCriteria4'
        }
      : null,
    alignmentCriteria5
      ? {
          ...alignmentCriteria5,
          name: 'sustainability.alignmentCriteria.alignmentCriteria5',
          textFieldName:
            'sustainability.alignmentCriteriaTextFields.alignmentCriteria5'
        }
      : null
  ].filter(a => !isNil(a) && a.enabled) as CriteriaField[];
};
