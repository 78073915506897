import { ExpectedValueChart } from '@quantfoliorepo/ui-components';
import { isEmpty, min } from 'ramda';
import { useState } from 'react';

import Table from './table.js';
import ButtonGroup from 'features/shared/components/buttonGroup';
import LoadingIndicator from 'features/shared/components/loadingIndicator/loadingIndicator';
import { Colors } from 'features/shared/constants/colors';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  noDataMessage: {
    padding: '40px 0',
    display: 'flex',
    justifyContent: 'center',
    color: Colors.gray50
  },
  body: {
    paddingTop: 40
  }
});

const ExpectedValueChartCardVisual = ({
  expectedValueTableData,
  savingPlanChartData,
  savingInBankAccountChartData,
  cultureCode,
  isLoading = false,
  bankReturn,
  savingPlanOnly
}) => {
  const classes = useStyles();
  const i18n = useI18n();
  const [chartIndex, setChartIndex] = useState(0);

  const isChartDataEmpty =
    isEmpty(expectedValueTableData) ||
    isEmpty(savingPlanChartData) ||
    isEmpty(savingInBankAccountChartData);

  const minYValue = isChartDataEmpty
    ? 0
    : Math.floor(
        savingPlanChartData
          .concat(savingInBankAccountChartData)
          .reduce((acc, val) => min(acc, val.y), savingPlanChartData[0].y)
      );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isChartDataEmpty) {
    return (
      <div className={classes.noDataMessage}>
        {i18n('roboAdvice.proposal.NoExpectedValueData')}
      </div>
    );
  }

  return (
    <>
      <ButtonGroup
        items={[
          {
            title: i18n('roboAdvice.proposal.graph'),
            isActive: chartIndex === 0,
            onClick: () => setChartIndex(0)
          },
          {
            title: i18n('roboAdvice.proposal.table'),
            isActive: chartIndex === 1,
            onClick: () => setChartIndex(1)
          }
        ]}
      />
      {chartIndex === 0 && (
        <div className={classes.body}>
          <ExpectedValueChart
            yMinValue={minYValue}
            cultureCode={cultureCode}
            chartData={{
              savingPlan: savingPlanChartData,
              savingInBankAccount: savingInBankAccountChartData
            }}
            bankReturn={bankReturn}
            savingPlanOnly={savingPlanOnly}
          />
        </div>
      )}
      {chartIndex === 1 && (
        <div className={classes.body}>
          <Table
            expectedValueTableData={expectedValueTableData}
            cultureCode={cultureCode}
          />
        </div>
      )}
    </>
  );
};

export default ExpectedValueChartCardVisual;
