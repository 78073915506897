import classNames from 'classnames';
import { mergeWithKey, add, omit, equals, isNil } from 'ramda';
import { useMemo } from 'react';

import SimpleTable from '../../components/table/simpleTable';
import { ReactComponent as CheckIcon } from 'assets/checkmark2.svg';
import { ReactComponent as CrossIcon } from 'assets/x-icon.svg';
import {
  BuyOrder,
  OrderSummaryData
} from 'features/roboAdvice/adviceSession/orderSummary';
import {
  FundNameForPresentation,
  ProductAttributeType
} from 'features/roboAdvice/adviceSession/shared/constants';
import {
  ProductAttributeBinary,
  ProductAttributeText
} from 'features/roboAdvice/adviceSession/shared/services/adviceSessionStore';
import { CustomAttributeFromConfig } from 'features/roboAdvice/adviceSession/shared/services/selectors';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { formatNumber } from 'features/shared/utils/number';
import { getTranslation } from 'features/shared/utils/translations';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  header: {
    fontWeight: FontWeights.medium,
    fontSize: Typography.heading1.size,
    lineHeight: '23px',
    textAlign: 'left',
    marginBottom: 32,

    '&:not(:first-child)': {
      margin: '96px 0 32px'
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 64
  },
  column: {
    flex: 1,

    '& > :not($subHeader):not(table)': {
      marginTop: 69
    }
  },
  subHeader: {
    textAlign: 'left',
    fontWeight: FontWeights.normal,
    fontSize: Typography.heading2.size,
    lineHeight: Typography.heading2.lineHeight,
    marginBottom: 16
  },
  isinColumn: {
    width: '20%'
  },
  numberColumn: {
    textAlign: 'right'
  },
  totalRow: {
    backgroundColor: `${theme.inputFillFocusColor} !important`
  },
  weightColumn: {
    textAlign: 'right',
    width: '10%'
  },
  checkIcon: {
    fill: theme.successColor
  },
  crossIcon: {
    fill: theme.errorNegativeColor
  },
  center: {
    textAlign: 'center'
  },
  wideColumn: {
    width: '40%'
  }
}));

type Props = {
  orderSummaryData: OrderSummaryData[];
  selectedGoalsIds: string[];
  cultureCode: string;
  isPercentColumnEnabled?: boolean;
  productAttributes?: CustomAttributeFromConfig[] | null;
};

const OneTimeOrders = ({
  cultureCode,
  orderSummaryData,
  selectedGoalsIds,
  isPercentColumnEnabled,
  productAttributes
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const {
    tenantSettings: { fundNameForPresentation }
  } = useCustomerConfig();

  const oneTimeOrdersData = useMemo(() => {
    const oneTimeOrdersForSelectedGoals = orderSummaryData
      .filter(({ goalId }) => selectedGoalsIds.includes(goalId))
      .map(({ data }) => data.oneTimeOrders);

    const buy = oneTimeOrdersForSelectedGoals
      .map(({ buy }) => buy)
      .flat()
      .reduce((acc, current) => {
        const index = acc.findIndex(({ id }) => id === current.id);

        if (
          index !== -1 &&
          equals(
            omit(['moneyAmount', 'weight'], acc[index]),
            omit(['moneyAmount', 'weight'], current)
          )
        ) {
          return acc.map((item, i) =>
            i === index
              ? mergeWithKey(
                  (k, l, r) => (k === 'moneyAmount' ? add(l, r) : r),
                  acc[index],
                  current
                )
              : item
          );
        }
        return [...acc, current];
      }, [] as (BuyOrder & Partial<ProductAttributeBinary & ProductAttributeText>)[]);

    return { buy };
  }, [orderSummaryData, selectedGoalsIds]);

  const buyTotal = useMemo(
    () =>
      oneTimeOrdersData.buy.reduce((acc, curr) => acc + curr.moneyAmount, 0),
    [oneTimeOrdersData.buy]
  );

  return (
    <>
      <div className={classes.header}>
        {i18n('roboAdvice.proposal.orderSummary.oneTimeOrders')}
      </div>

      <div className={classes.container}>
        <div className={classes.column}>
          <div className={classes.subHeader}>
            {i18n('roboAdvice.proposal.orderSummary.buy')}
          </div>

          <SimpleTable
            headers={({ headerCellClassName }) => (
              <>
                <th className={headerCellClassName}>
                  {i18n('roboAdvice.proposal.orderSummary.fund')}
                </th>

                <th
                  className={classNames(
                    headerCellClassName,
                    classes.isinColumn
                  )}
                >
                  {i18n('shared.isin')}
                </th>

                {productAttributes?.map(({ label, name, type }) => (
                  <th
                    key={name}
                    className={classNames(headerCellClassName, {
                      [classes.center]: type === ProductAttributeType.binary
                    })}
                  >
                    {getTranslation(label)}
                  </th>
                ))}

                <th
                  className={classNames(
                    headerCellClassName,
                    classes.numberColumn,
                    {
                      [classes.wideColumn]: !productAttributes?.length
                    }
                  )}
                >
                  {i18n('shared.amount')}
                </th>

                {isPercentColumnEnabled && (
                  <th
                    className={classNames(
                      headerCellClassName,
                      classes.weightColumn
                    )}
                  >
                    %
                  </th>
                )}
              </>
            )}
            body={({ rowClassName, cellClassName }) => (
              <>
                {oneTimeOrdersData.buy.map(
                  ({
                    FundStandardName,
                    name,
                    isin,
                    moneyAmount,
                    id,
                    ...orderData
                  }) => (
                    <tr
                      key={JSON.stringify({
                        FundStandardName,
                        name,
                        id,
                        orderData
                      })}
                      className={rowClassName}
                    >
                      <td className={cellClassName}>
                        {fundNameForPresentation ===
                          FundNameForPresentation.fundStandardName &&
                        FundStandardName
                          ? FundStandardName
                          : name}
                      </td>
                      <td
                        className={classNames(
                          cellClassName,
                          classes.isinColumn
                        )}
                      >
                        {isin}
                      </td>

                      {productAttributes?.map(({ type, name }) => {
                        if (type === ProductAttributeType.text) {
                          return (
                            <td className={cellClassName} key={name}>
                              {orderData?.[name]}
                            </td>
                          );
                        }

                        if (type === ProductAttributeType.binary) {
                          return (
                            <td
                              className={classNames(
                                cellClassName,
                                classes.center
                              )}
                              key={name}
                            >
                              {!isNil(orderData?.[name]) ? (
                                orderData[name] ? (
                                  <CheckIcon className={classes.checkIcon} />
                                ) : (
                                  <CrossIcon className={classes.crossIcon} />
                                )
                              ) : null}
                            </td>
                          );
                        }

                        return null;
                      })}

                      <td
                        className={classNames(
                          cellClassName,
                          classes.numberColumn
                        )}
                      >
                        {formatNumber(cultureCode, moneyAmount, 0, 0)}
                      </td>
                      {isPercentColumnEnabled && (
                        <td
                          className={classNames(
                            cellClassName,
                            classes.weightColumn
                          )}
                        >
                          {formatNumber(
                            cultureCode,
                            moneyAmount / (buyTotal / 100),
                            1,
                            1
                          )}
                          %
                        </td>
                      )}
                    </tr>
                  )
                )}
                {oneTimeOrdersData.buy.length > 1 && (
                  <tr className={classNames(rowClassName, classes.totalRow)}>
                    <td className={cellClassName}>{i18n('shared.total')}</td>
                    <td className={cellClassName} />
                    {productAttributes?.map(({ name }) => (
                      <td key={name} className={classNames(cellClassName)} />
                    ))}
                    <td
                      className={classNames(
                        cellClassName,
                        classes.numberColumn
                      )}
                    >
                      {formatNumber(cultureCode, buyTotal, 0, 0)}
                    </td>
                    {isPercentColumnEnabled && <td className={cellClassName} />}
                  </tr>
                )}
              </>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default OneTimeOrders;
