import { useState } from 'react';

import PageBuilder from '../../shared/components/pageBuilder';
import PageBuilderModalFooter from '../../shared/components/pageBuilderModalFooter';
import { Page } from '../../shared/types';
import Button from 'features/shared/components/button';
import Modal from 'features/shared/components/modal/index';
import { useModalBodyMaxDimensions } from 'features/shared/components/modal/useModalBodyMaxDimensions';
import Scrollbars from 'features/shared/components/scrollbars';
import { Spacing } from 'features/shared/constants/spacing';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  modalBodyContainer: {
    paddingRight: Spacing.spacing01
  },
  footerButton: {
    justifySelf: 'end',
    width: 'fit-content'
  }
});

type Props = {
  pages: Page[];
  isOpen: boolean;
  closeHandler: () => void;
  header: string;
};

const EducationalModal = ({ pages, isOpen, closeHandler, header }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const [selectedPage, setSelectedPage] = useState(0);
  const { modalBodyMaxHeight, headerRef, footerRef } =
    useModalBodyMaxDimensions();

  return (
    <Modal
      wideContent
      footer={
        pages.length > 1 && (
          <PageBuilderModalFooter
            pageNumber={selectedPage}
            totalPagesNumber={pages.length}
            changePageNumber={setSelectedPage}
            button={
              selectedPage + 1 === pages.length ? (
                <Button
                  className={classes.footerButton}
                  onClick={() => {
                    closeHandler();
                    setSelectedPage(0);
                  }}
                >
                  {i18n('roboAdvice.navigation.close')}
                </Button>
              ) : (
                <Button
                  className={classes.footerButton}
                  onClick={() => setSelectedPage(selectedPage + 1)}
                >
                  {i18n('shared.next')}
                </Button>
              )
            }
          />
        )
      }
      isOpen={isOpen}
      header={header}
      onRequestClose={() => {
        closeHandler();
        setSelectedPage(0);
      }}
      headerRef={headerRef}
      footerRef={footerRef}
    >
      <Scrollbars autoHeightMax={modalBodyMaxHeight}>
        <div className={classes.modalBodyContainer}>
          {pages.length > selectedPage && (
            <PageBuilder sections={pages[selectedPage].sections} />
          )}
        </div>
      </Scrollbars>
    </Modal>
  );
};

export default EducationalModal;
