import classNames from 'classnames';
import * as R from 'ramda';
import React from 'react';
import { PopperProps } from 'react-popper';

import Portal from 'features/shared/components/portal/index.js';
import Scrollbars from 'features/shared/components/scrollbars';
import { Spacing } from 'features/shared/constants/spacing';
import { BorderRadiusTypes } from 'features/sharedModules/customerConfig/constants';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const borderRadius = {
  [BorderRadiusTypes.rounded]: '16px',
  [BorderRadiusTypes.sharp]: '5px'
};

const useStyles = createUseStyles(theme => ({
  menuContainer: {
    zIndex: 30
  },
  menu: {
    backgroundColor: theme.inputFillFocusColor,
    border: `2px solid ${theme.accentColor}`
  },
  scrollbarsContent: {},
  offsetBottom: {
    overflow: 'hidden',
    marginTop: Spacing.spacing00,
    paddingTop: Spacing.spacing00,
    paddingBottom: Spacing.spacing00,
    borderRadius: borderRadius[theme.borderRadiusType]
  },
  offsetTop: {
    overflow: 'hidden',
    marginBottom: Spacing.spacing00,
    paddingBottom: Spacing.spacing00,
    paddingTop: Spacing.spacing00,
    borderRadius: borderRadius[theme.borderRadiusType]
  },
  option: {
    padding: '0.8rem 2.4rem',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.itemBackgroundColor_2
    }
  },
  optionNoDataTitle: {
    cursor: 'default',
    color: theme.secondaryColor,

    '&:hover': {
      backgroundColor: theme.inputFillFocusColor
    }
  }
}));

export type MenuProps = {
  forwardedRef: React.RefObject<HTMLDivElement>;
  width: string | number;
  menuRef: React.RefObject<HTMLDivElement>;
  style: React.CSSProperties;
  placement: PopperProps['placement'];
  options: { key: string; title: React.ReactNode }[];
  onSelect: (option: { key: string; title: React.ReactNode }) => void;
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
  offsetBottomClassName?: string;
  offsetTopClassName?: string;
  optionClassName?: string;
  noDataTitle: React.ReactNode;
};

const Menu = ({
  forwardedRef,
  width,
  menuRef,
  style,
  placement,
  options,
  onSelect,
  onMouseDown,
  className,
  offsetBottomClassName = '',
  offsetTopClassName = '',
  optionClassName,
  noDataTitle
}: MenuProps) => {
  const classes = useStyles();

  return (
    <Portal>
      <div
        ref={forwardedRef}
        className={classes.menuContainer}
        style={{ ...style, width: width }}
        data-placement={placement}
      >
        <div
          ref={menuRef}
          className={classNames(
            classes.menu,
            {
              [classes.offsetBottom]: placement === 'bottom',
              [offsetBottomClassName]:
                !R.isNil(offsetBottomClassName) && placement === 'bottom',
              [classes.offsetTop]: placement === 'top',
              [offsetTopClassName]:
                !R.isNil(offsetTopClassName) && placement === 'top'
            },
            className
          )}
          tabIndex={0}
          onMouseDown={onMouseDown}
          style={{ width: width }}
        >
          <Scrollbars>
            <div className={classes.scrollbarsContent}>
              {options.map(option => (
                <div
                  className={classNames(classes.option, optionClassName)}
                  key={option.key}
                  onClick={() => onSelect(option)}
                >
                  {option.title}
                </div>
              ))}
              {R.isEmpty(options) && (
                <div
                  className={classNames(
                    classes.option,
                    classes.optionNoDataTitle,
                    optionClassName
                  )}
                >
                  {noDataTitle}
                </div>
              )}
            </div>
          </Scrollbars>
        </div>
      </div>
    </Portal>
  );
};

export default Menu;
