import React from 'react';

import { tableType } from './constants';
import { mapCustomAttributesToSustainabilityTable } from './mapping';
import PreferenceCriteriaTable from './preferenceCriteriaTable';
import StandardTable from './standardTable';
import WeightedTableContainer from './weightedTableContainer';
import { useForm as useRoboAdviceForm } from 'features/roboAdvice/adviceSession/form/services/form';
import { CustomAttribute } from 'features/roboAdvice/adviceSession/shared/services/adviceSessionStore';
import { useGoalsStore } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { areProductAttributesEnabled } from 'features/roboAdvice/adviceSession/shared/services/selectors';
import {
  FundAnalysis,
  SustainabilityData
} from 'features/roboAdvice/adviceSession/sustainability';
import { CriteriaField } from 'features/roboAdvice/adviceSession/sustainability/components/utils';
import LoadingIndicator from 'features/shared/components/loadingIndicator/loadingIndicator';
import { FontWeights } from 'features/shared/constants/typography';
import { getTranslation } from 'features/shared/utils/translations';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  container: {
    marginLeft: 36
  },
  header: {
    fontWeight: FontWeights.medium,
    lineHeight: '19px',
    textAlign: 'left',

    '&:not(:first-child)': {
      marginTop: 128
    }
  }
});

type Props = {
  selectedGoalsIds: string[];
  sustainabilityData: SustainabilityData[];
  alignmentCriteriaFields: CriteriaField[];
  sustainabilityPreference: {
    icon: string;
    id: string;
    title: Record<string, string>;
  }[];
  cultureCode: string;
  isLoading?: boolean;
  isError?: boolean;
  customAttributesData?: Record<number, CustomAttribute[]>;
};

const SustainabilityVisual = ({
  alignmentCriteriaFields,
  cultureCode,
  isError = false,
  isLoading = false,
  selectedGoalsIds,
  sustainabilityData,
  sustainabilityPreference,
  customAttributesData
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const { goals } = useGoalsStore();
  const { values } = useRoboAdviceForm();
  const {
    roboAdviceForm: {
      proposal: { sustainability }
    }
  } = useCustomerConfig();

  const areAnyProductAttributesEnabled =
    areProductAttributesEnabled(sustainability);

  const alignmentValues = React.useMemo(() => {
    const genericAssessmentAnswer =
      values.sustainability?.genericAssessment?.answer;
    if (genericAssessmentAnswer === false) {
      return [];
    }

    const alignmentCriteriaNames = alignmentCriteriaFields.map(({ name }) => {
      return name.replace('sustainability.alignmentCriteria.', '');
    });

    return alignmentCriteriaNames
      .filter(
        alignmentCriteria =>
          values.sustainability?.alignmentCriteria?.[alignmentCriteria]
      )
      .map(alignmentCriteria => ({
        name: alignmentCriteria,
        title: getTranslation(
          alignmentCriteriaFields.find(
            a =>
              a.name === `sustainability.alignmentCriteria.${alignmentCriteria}`
          )?.config.title
        ),
        preferenceScore:
          values.sustainability?.alignmentCriteria?.[alignmentCriteria]
      }));
  }, [
    alignmentCriteriaFields,
    values.sustainability?.alignmentCriteria,
    values.sustainability?.genericAssessment?.answer
  ]);

  const areAnyAlignmentCriteriaValuesPositive = alignmentValues.some(
    ({ preferenceScore }) => preferenceScore && preferenceScore > 0
  );

  const fundAnalysisData = React.useMemo(
    () =>
      sustainabilityData
        .filter(({ goalId }) => selectedGoalsIds.includes(goalId))
        .map(({ data }) => data.fundAnalysis)
        .flat()
        .reduce<FundAnalysis[]>((acc, curr) => {
          const index = acc.findIndex(
            ({ ticker, isin }) =>
              (isin && isin === curr.isin) || (ticker && ticker === curr.ticker)
          );
          if (index !== -1) {
            return acc;
          }
          return [...acc, curr];
        }, []),
    [selectedGoalsIds, sustainabilityData]
  );

  const totalValue = React.useMemo(
    () =>
      goals
        .filter(({ goalId }) => selectedGoalsIds.includes(goalId))
        .reduce(
          (acc, curr) =>
            acc + (curr.data.firstDeposit || curr.data.monthlyDeposit || 0),
          0
        ),
    [goals, selectedGoalsIds]
  );

  const tableCustomAttributes = mapCustomAttributesToSustainabilityTable({
    customAttributesConfig: sustainability
  });

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <div>{i18n('roboAdvice.proposal.sustainability.noData')}</div>;
  }

  const isAlignmentCriteriaTableVisible =
    areAnyAlignmentCriteriaValuesPositive || areAnyProductAttributesEnabled;
  const isPreferenceCriteriaTableVisible =
    sustainability.showPreferenceCriteriaTable &&
    !!values.sustainability?.preferenceCriteria?.length;

  return (
    <div className={classes.container}>
      {!isAlignmentCriteriaTableVisible &&
        !isPreferenceCriteriaTableVisible &&
        i18n(
          'roboAdvice.sustainability.investorHadNoSustainabilityPreferences'
        )}

      {isAlignmentCriteriaTableVisible && (
        <>
          <div className={classes.header}>
            {i18n('roboAdvice.proposal.sustainabilityAssessmentAlignment')}
          </div>

          {sustainability.tableType === tableType.weighted && (
            <WeightedTableContainer
              alignmentValues={alignmentValues}
              cultureCode={cultureCode}
              fundAnalysisData={fundAnalysisData}
              goals={goals}
              selectedGoalsIds={selectedGoalsIds}
              sustainabilityData={sustainabilityData}
              totalValue={totalValue}
            />
          )}

          {sustainability.tableType === tableType.standard && (
            <StandardTable
              alignmentValues={alignmentValues}
              cultureCode={cultureCode}
              fundAnalysisData={fundAnalysisData}
              goals={goals}
              selectedGoalsIds={selectedGoalsIds}
              sustainabilityData={sustainabilityData}
              totalValue={totalValue}
              tableCustomAttributes={tableCustomAttributes}
              customAttributesData={customAttributesData}
            />
          )}
        </>
      )}

      {isPreferenceCriteriaTableVisible && (
        <>
          <div className={classes.header}>
            {i18n(
              'roboAdvice.proposal.sustainability.preferenceCriteriaAlignment'
            )}
          </div>

          <PreferenceCriteriaTable
            fundAnalysisData={fundAnalysisData}
            preferenceCriteria={values.sustainability?.preferenceCriteria}
            sustainabilityPreference={sustainabilityPreference}
          />
        </>
      )}
    </div>
  );
};

export default SustainabilityVisual;
