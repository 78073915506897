import classNames from 'classnames';
import React from 'react';

import TextInput from '../index';
import { injectSheet } from 'features/sharedModules/styles/components/styles';

const styles = {
  container: {
    position: 'relative'
  },
  textInput: {},
  rightBlock: {
    position: 'absolute',
    top: 'calc(0.8rem + 2px)',
    right: '2.4rem'
  }
};

const TextInputWithRightBlock = ({
  className,
  textInputProps,
  rightBlockProps,
  children,
  classes
}) => {
  return (
    <div className={classNames(classes.container, className)}>
      <TextInput
        {...textInputProps}
        className={classNames(classes.textInput, textInputProps.className)}
      />
      <div
        className={classNames(classes.rightBlock, rightBlockProps.className)}
      >
        {children}
      </div>
    </div>
  );
};

export default injectSheet(styles)(TextInputWithRightBlock);
