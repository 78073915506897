import { useParams } from 'react-router';

import { NewClientId } from '../../../shared/constants';
import { useReadAdviceSessions } from '../../adviceSessionsList/components/useReadAdviceSessions';
import { useReadClientInformation } from '../../clientInformation/components/useReadClientInformation';
import { ClientParams } from '../../shared/types';
import { clientForm } from '../services/clientForm';
import { useClientPageStore } from '../services/clientPageStore';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { ClientTypes } from 'features/shared/constants/session';

export function useInitPage() {
  const { clientId } = useParams<ClientParams>();
  const readClientInformation = useReadClientInformation();
  const readAdviceSessions = useReadAdviceSessions();
  const clientPageStore = useClientPageStore.getState();

  const initPage = () => {
    if (clientId === NewClientId) {
      clientForm.initialize({
        clientInformation: {
          clientType: ClientTypes.person
        }
      });
      clientPageStore.setReadClientInformationStatus(PageStatuses.succeed);
    } else {
      readClientInformation();
      readAdviceSessions();
    }
  };

  return initPage;
}
